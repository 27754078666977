import React, {Component} from "react";
import ExaminationEnum from "../../assessment/enums/examination/ExaminationEnum";
import Modal from "../../../../../components/modal/Modal";
import MSKDiagnosisFindingsModal from "../../assessment/modals/MSKDiagnosisFindingsModal";

export default class MSKDiagnosisEMRDashboardView extends Component {
    state = {
        MSKDiagnosisFindingsModalVisible: false,
    }

    render() {
        if (this.props.MSKDiagnosis == null
            || this.props.MSKDiagnosis.items == null
            || this.props.MSKDiagnosis.items.length == 0) {
            return null;
        }
        return (

            <div className="content-card">
                <div className="py-2 px-4">
                    {this.props.MSKDiagnosis.items.map((item, index) => {
                        const examinationLocation = item.notApplicableBool? "Not Applicable" : (item.leftBool? "Left" : item.rightBool? "Right" : null) ;
                        return (
                            <div key={index} style={{marginBottom: "15px"}}>
                                <div className={"row"} style={{marginRight: "0px"}}>
                                    <div className={"col-3"} style={{fontWeight: "bold", fontSize: "10px"}}>
                                        Presenting Complaint :
                                    </div>
                                    <div className={"col-9"}>
                                        {item.presentingComplaint}
                                    </div>
                                </div>
                                <div className={"row"} style={{marginRight: "0px"}}>
                                    <div className={"col-3"} style={{fontWeight: "bold", fontSize: "10px"}}>
                                        Examination :
                                    </div>
                                    {item.examination || item.examinationComment ?
                                        <div className={"col-9"}>
                                            {(item.examination ? ExaminationEnum[item.examination].name : item.examinationComment) + (examinationLocation? " - " +examinationLocation : "") }
                                        </div>
                                        :
                                        ''}
                                </div>
                                <div className={"row"} style={{marginRight: "0px"}}>
                                    <div className={"col-3"} style={{fontWeight: "bold", fontSize: "10px"}}>
                                        Findings :
                                    </div>
                                    <div className={"col-9"}>
                                        {item.findings?.length > 1024 ?
                                            <div>
                                                {item.findings?.substring(0, 1024)}
                                                {" ... "}
                                                <span
                                                    style={{color: "green"}}
                                                    onClick={() => this.setState({MSKDiagnosisFindingsModalVisible: true})}>
                                                    Show more
                                            </span>
                                            </div> : item.findings
                                        }
                                        <Modal visible={this.state.MSKDiagnosisFindingsModalVisible}
                                               submitContent="OK"
                                               contentStyle={{maxHeight: 600, overflowY: 'auto'}}
                                               submitAction={() => this.setState({MSKDiagnosisFindingsModalVisible: false})}
                                               closeAction={() => this.setState({MSKDiagnosisFindingsModalVisible: false})}
                                               header='MSK Diagnosis Findings'
                                               maxWidth={"xl"}
                                               contentStyle={{maxHeight: "140%", width: "75vw", overflowY: 'auto'}}>
                                            <MSKDiagnosisFindingsModal findings={item.findings}/>
                                        </Modal>
                                    </div>
                                </div>
                                <div className={"row"} style={{marginRight: "0px"}}>
                                    <div className={"col-3"} style={{fontWeight: "bold", fontSize: "10px"}}>
                                        Impression :
                                    </div>
                                    <div className={"col-9"}>
                                        {item.impression}
                                    </div>
                                </div>
                                <div className={"row"} style={{marginRight: "0px"}}>
                                    <div className={"col-3"} style={{fontWeight: "bold", fontSize: "10px"}}>
                                        Diagnosis :
                                    </div>
                                    <div className={"col-9"}>
                                        {item.diagnosisCode} - {item.diagnosisDescription}
                                    </div>
                                </div>
                                <div className={"row"} style={{marginRight: "0px"}}>
                                    <div className={"col-3"} style={{fontWeight: "bold", fontSize: "10px"}}>
                                        Recommendations :
                                    </div>
                                    <div className={"col-9"}>
                                        {item.recommendations}
                                    </div>
                                </div>
                            </div>

                        );
                    })
                    }
                </div>
            </div>
        );
    }
}