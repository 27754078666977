import React, {Component} from 'react';
import './FormBase.css';
import FormSection from "./FormSection";

class FormBase extends Component {

    render(){

        let formSection1Elements = [];
        let formSection2Elements = [];
        let formSection3Elements = [];
        let formSection4Elements = [];
        let formSection5Elements = [];
        let formSection6Elements = [];
        let formSection7Elements = [];
        let formSection8Elements = [];
        let formSection9Elements = [];
        let formSection10Elements = [];

        if(this.props.children) {

            let childrenArray = [];
            if(Array.isArray(this.props.children)) {
                childrenArray = this.props.children;
            } else {
                childrenArray.push(this.props.children);
            }

            childrenArray.forEach((child) => {
                let sectionId;
                let okToAdd = false;

                if (child instanceof Object) {
                    if (child["type"] === FormSection) {
                        okToAdd = true;
                        sectionId = child["props"]["sectionId"];
                    } else {
                        if ((child.props.children) && (child.props.children["type"] === FormSection)) {
                            okToAdd = true;
                            sectionId = child.props.children["props"]["sectionId"];
                        }
                    }
                }

                if (okToAdd) {
                    if(!sectionId){
                        sectionId = 1;
                    }

                    if(sectionId === 1){
                        formSection1Elements.push(child);
                    }
                    else if(sectionId === 2){
                        formSection2Elements.push(child);
                    }
                    else if(sectionId === 3){
                        formSection3Elements.push(child);
                    }
                    else if(sectionId === 4){
                        formSection4Elements.push(child);
                    }
                    else if(sectionId === 5){
                        formSection5Elements.push(child);
                    }
                    else if(sectionId === 6){
                        formSection6Elements.push(child);
                    }
                    else if(sectionId === 7){
                        formSection7Elements.push(child);
                    }
                    else if(sectionId === 8){
                        formSection8Elements.push(child);
                    }
                    else if(sectionId === 9){
                        formSection9Elements.push(child);
                    }
                    else if(sectionId === 10){
                        formSection10Elements.push(child);
                    }
                }
            });
        }

        return <div className={"dim14-form-base " + this.props.className}>
            <div className="dim14-form-row">
                <div className="dim14-col-12" >
                    {formSection1Elements}
                </div>
            </div>
            <div className="dim14-form-row">
                <div className="dim14-col-4" >
                    {formSection2Elements}
                </div>
                <div className="dim14-col-4" >
                    {formSection3Elements}
                </div>
                <div className="dim14-col-4" >
                    {formSection4Elements}
                </div>
            </div>
            <div className="dim14-form-row">
                <div className="dim14-col-6" >
                    {formSection5Elements}
                </div>
                <div className="dim14-col-6" >
                    {formSection6Elements}
                </div>
            </div>
            <div className="dim14-form-row">
                <div className="dim14-col-8" >
                    {formSection7Elements}
                </div>
                <div className="dim14-col-4" >
                    {formSection8Elements}
                </div>
            </div>
            <div className="dim14-form-row">
                <div className="dim14-col-12" >
                    {formSection9Elements}
                </div>
            </div>

            {formSection10Elements}

        </div>;
    }

}

export default FormBase;
