import {store} from "../../utils/store";
import {alertActions} from "../actions";

const showNotification = (growlObject) => {
    let {sticky, summary, detail} = growlObject;

    switch (growlObject.severity) {
        case 'error':
            store.dispatch(alertActions.error(summary, detail, sticky));
            break;
        case 'warn':
            store.dispatch(alertActions.warn(summary, detail, sticky));
            break;
        case 'success':
            store.dispatch(alertActions.success(summary, detail, sticky));
            break;
        default:
            throw new Error("Unexpected severity: " + growlObject.severity);
    }
    // TODO to be removed when alert.actions are used for all notifications.
};

const showWarning = (warning) => {
    showNotification({severity: 'warn', summary: 'Warning', detail: warning, sticky: false})
}

export {showNotification, showWarning};
export default {showNotification};
