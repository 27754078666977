import React, {Component} from "react";
import Header from "../header/Header";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import VisitHistory from "./VisitHistory";
import RemotingService from "../../../../services/remoting-service/RemotingService";

export default class PatientVisitHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patient: null
        }
    }

    componentDidMount() {
        const patientId = this.props.match.params.patientId;
        RemotingService.getRemoteCall(`api/patient/${patientId}`, null, (patient) => {
            this.setState({patient});
        });
    }

    render() {
        return (
            this.state.patient &&
            <div className={"emr-root"}>
                <div className="emr-header h-100 w-100 pl-4">
                    <Header patient={this.state.patient} emrHomeView/>
                </div>
                <Tabs defaultActiveKey="visithistory"
                      style={{marginLeft: 20, marginRight: 20, borderRadius: "10px"}}>
                    <Tab eventKey="visithistory" title="Visit History">
                        <VisitHistory patient={this.state.patient} visitMetaData={[]}/>
                    </Tab>
                </Tabs>
            </div>
        )
    }

}