import Enum from "../../../../../../enums/Enum";

const ResultEnum = new Enum({
    Limited: {name: "Limited"},
    Painful: {name: "Painful"},
    Dysfunction: {name: "Dysfunction"},
    Hypermobile: {name: "Hypermobile"},
    Hypomobile: {name: "Hypomobile"},
    Laxity: {name: "Laxity"},
});

export default ResultEnum;













