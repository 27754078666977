import React, {Component} from "react";
import "../../VisitHistory.css";
import CurrentPastEnum from "../../../subjective/enums/history/CurrentPastEnum";
import DiagnosisEnum from "../../../subjective/enums/history/DiagnosisEnum";
import DateUtil from "../../../../../../utils/DateUtil";
import VaccinationHistoryEnum from "../../../subjective/enums/history/VaccinationHistoryEnum";

export default class MedicalViewHistory extends Component {
    render() {
        if (this.props.medicalHistory == null) {
            return <div className="pl-3">No information available</div>;
        } else if (this.props.medicalHistory.none) {
            return <div className="pl-3">No Medical History</div>;
        }

        let diagnosis = this.getDiagnosisItems();
        let vaccination = this.getVaccinationHistory();

        return (
            <>
                <div className="pl-3 mb-3">
                    {diagnosis}
                </div>

                <div>
                    {vaccination}
                </div>
            </>
        );
    }

    getDiagnosisItems () {
        if (this.props.medicalHistory.diagnosisItems == null) {
            return null;
        }
        let diagnosisItemsDiv = [];

        this.props.medicalHistory.diagnosisItems.forEach((item, index) => {
            diagnosisItemsDiv.push(
                <div key={"medicalViewHistoryDiv" + index}>
                    <b>{CurrentPastEnum[item.currentPast]?.name}</b>:
                    {item.diagnosis}
                    {item.detail == null ? "" : (", " + item.detail)}
                    {item.date == null ? "" : (", " + DateUtil.formatDate(item.date, "YYYY"))}
                    {item.stableActive == null ? "" : "; " + DiagnosisEnum[item.stableActive].name}
                </div>);
        });

        return diagnosisItemsDiv;
    }

    getVaccinationHistory () {
        let text = "";
        if(this.props.medicalHistory.vaccinationHistory != null) {
            text += VaccinationHistoryEnum[this.props.medicalHistory.vaccinationHistory].name;
        }
        if(this.props.medicalHistory.vaccinationHistoryDetail != null
            && this.props.medicalHistory.vaccinationHistoryDetail !== "") {
            if(text !== "") {
                text += "; ";
            }
            text += this.props.medicalHistory.vaccinationHistoryDetail;
        }
        if(text !== "") {
            return (
                <div key={"medicalViewHistoryVaccination"}>
                    <b>Vaccination:</b> {text}
                </div>);
        }
        return null;
    }
}