import moment from "moment";
import TimezoneUtil from "./TimezoneUtil";

const now = () => {
    return moment();
};

const parse = (dateStr, format = "DD-MM-YYYY") => {
    return moment(dateStr, format);
};

/* https://momentjs.com/docs/#/displaying/ */
const formatDate = (date, format = "DD-MM-YYYY") => {
    return date ? moment(date).format(format) : null;
};

const formatTzDate = (date, zone, format) => {
    return date ? moment.tz(date, zone).format(format) : null;
}

const formatDateDayAndMonth = (date, format = "DD/MM") => {
    return date ? moment(date).format(format) : null;
};

const formatDateMonthAndYear = (date, format = "MMMM, YYYY") => {
    return date ? moment(date).format(format) : null;
};

const formatDateMMM = (date) => {
    return formatDate(date, "DD-MMM-YYYY");
};

const formatDateDo = (date) => {
    return formatDate(date, "MMMM Do YYYY");
};

const formatDateDoMMYY = (date) => {
    return formatDate(date, "Do MMMM YYYY");
};

const formatDateTime12H = (date) => {
    return formatDate(date, "DD-MMM-YYYY hh:mm A");
};

const formatDateTimeSeparately = (date) => {
    return formatDate(date, "DD MMMM YYYY | hh:mm A");
};

const formatDateTimeSeparatelyForReports = (date) => {
    return formatDate(date, "Do MMMM YYYY / hh:mm:ss A");
};

const formatDateTimeSeparatelyForFileStamp = (date) => {
    return formatDate(date, "YYYY-MM-DD'T'hh-mm-ss");
};

const getHourMinute12H = (date) => {
    return formatDate(date, "hh:mm A");
};

const getHourMinute24H = (date, timezone) => {
    if (!date) {
        return null;
    }
    return timezone ?
        moment.tz(date, timezone).format("HH:mm") :
        formatDate(date, "HH:mm");
};

const diff = (start, end, units) => {
    return moment(end).diff(moment(start), units);
};

const getAge = (birthDate) => {
    return diff(birthDate, now(), 'years');
};

const isToday = (date) => {
    const current = now();
    return current.isSame(date, 'day');
};

const getAgeWithMonths = (birthDate) => {
    const current = now();
    const months = current.diff(moment(birthDate), 'months', true);
    const birthSpan = {
        year: Math.floor(months / 12),
        month: Math.floor(months) % 12,
        day: Math.round((months % 1) * current.daysInMonth(), 0)
    };

    if (birthSpan.year < 1 && birthSpan.month < 1) {
        return birthSpan.day + ' day' + (birthSpan.day > 1 ? 's' : '')
    }
    if (birthSpan.year < 1) {
        return birthSpan.month + ' month' + (birthSpan.month > 1 ? 's ' : ' ') + birthSpan.day + ' day' + (birthSpan.day > 1 ? 's' : '')
    }
    if (birthSpan.month < 1) {
        return birthSpan.year + ' year' + (birthSpan.year > 1 ? 's' : '');
    }
    return birthSpan.year + ' year' + (birthSpan.year > 1 ? 's ' : ' ') + birthSpan.month + ' month' + (birthSpan.month > 1 ? 's ' : '')
};

const add = (date, value, unit) => {
    return moment(date).add(value, unit).toDate();
}

const addMinutes = (date, minutes) => {
    return moment(date).add(minutes, 'minute').toDate();
}

const addHours = (date, hours) => {
    return moment(date).add(hours, 'hour').toDate();
}

const addDays = (date, days) => {
    return moment(date).add(days, 'day').toDate();
}

const addWeeks = (date, weeks) => {
    return moment(date).add(weeks, 'week').toDate();
}

const addMonths = (date, months) => {
    return moment(date).add(months, 'month').toDate();
}

const addYears = (date, years) => {
    return moment(date).add(years, 'year').toDate();
}

const startOfDay = (date) => {
    return moment(date).startOf('day').toDate();
}

const endOfDay = (date) => {
    return moment(date).endOf('day').toDate();
}

// Note: startOf and endOf 'week' functions are local aware, do not use them
const startOfWeek = (date) => {
    return moment(date).day('Sunday').toDate();
}

const startOfPreviousWeek = (date) => {
    return startOfWeek(addDays(startOfWeek(date), -1));
}

const startOfNextWeek = (date) => {
    return addDays(endOfWeek(date), 1);
}

const endOfWeek = (date) => {
    return moment(date).day('Saturday').toDate();
}

const startOfMonth = (date) => {
    return moment(date).startOf('month').toDate();
}

const startOfPreviousMonth = (date) => {
    return startOfMonth(addDays(startOfMonth(date), -1));
}

const startOfNextMonth = (date) => {
    return addDays(endOfMonth(date), 1);
}

const endOfMonth = (date) => {
    return moment(date).endOf('month').toDate();
}

const yesterday = () => {
    return addDays(new Date(), -1);
}

const today = () => {
    return moment().startOf('day').utc(true);
}

const tomorrow = () => {
    return addDays(new Date(), 1);
}

const isYesterday = (date) => {
    return moment(yesterday()).isSame(moment(date), 'd');
}

const isTomorrow = (date) => {
    return moment(tomorrow()).isSame(moment(date), 'd');
}

const timeOf = (hour, minute, second = 0) => {
    return moment().hour(hour).minute(minute).second(second).toDate();
}

const parseTime = (timeStr, format = "HH:mm") => {
    return timeStr ? moment(timeStr, format).toDate() : null;
}

const isBefore = (date1, date2) => {
    return moment(date1).isBefore(date2);
}

const isAfter = (date1, date2) => {
    return moment(date1).isAfter(date2);
}

const isSameOrBefore = (date1, date2) => {
    return moment(date1).isSameOrBefore(date2);
}

const isSameOrAfter = (date1, date2) => {
    return moment(date1).isSameOrAfter(date2);
}

const isBetweenInclusive = (date, start, end) => {
    return isSameOrAfter(date, start) && isSameOrBefore(date, end);
}

const isInRange = (date, startDate, endDate) => {
    return moment(date).isSameOrAfter(startDate) &&
        moment(date).isBefore(endDate);
}

const isSame = (date1, date2, granularity = "millisecond") => {
    return moment(date1).isSame(date2, granularity);
}

const isSameDay = (date1, date2) => {
    return moment(date1).isSame(date2, 'day');
}

const currentYear = () => {
    return now().year();
}

const switchZone = (date, zone) => {
    const mmnt = moment(date);
    return moment.tz([mmnt.year(), mmnt.month(), mmnt.date(), mmnt.hour(), mmnt.minute(), mmnt.second()], zone);
}

const getUtcDateAtStartOfDay = (date) => {
    if (!date) {
        return null;
    }
    const momentDate = moment(date);
    return new Date(Date.UTC(momentDate.year(), momentDate.month(), momentDate.date()));
}

const getTimeStringAtZone = (date, zone) => {
    return formatTzDate(date, zone, "HH:mm")
}

const getTimeStringAtClinicZone = (date, clinicName, format = "HH:mm") => {
    const zone = TimezoneUtil.getClinicZoneInfo(clinicName).zone;
    return date ? moment.tz(date, zone).format(format) : null;
}

const getDurationInMinutes = (date1, date2) => {
    const m1 = moment(date1);
    const m2 = moment(date2);
    return Math.abs(moment.duration(m1.diff(m2)).as("minutes"));
}

const formatHoursDuration = (val) => {
    let hours = Math.floor(val);
    let minutes = Math.round((val - hours) * 60);

    let result = hours;
    if (minutes > 0) {
        result += ':';
        if (minutes < 10) {
            result += '0';
        }
        result += minutes;
    }
    return result;
}

const getDifferenceInDays = (date1, date2) => {
    const m1 = moment(date1);
    const m2 = moment(date2);
    return m1.diff(m2, 'days');
}

const getDay = (date) => {
    return moment(date).day();
}

const getWeekDay = (date) => {
    return moment(date).isoWeekday();
}

const getDayName = (date) => {
    return moment(date).format('dddd');
}

const createMomentWithZone = (timezone, year, month, date, hour = 0, minute = 0, second = 0) => {
    return moment.tz({
        year: year,
        month: month,
        date: date,
        hour: hour,
        minute: minute,
        second: second
    }, timezone);
};

const startOfDayInAnyUtc = (date) => {
    return moment(date).startOf('day').set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0).toDate();
    //TODO: To quickfix Extra Days issue i give this ones magic numbers. We need to make them work in any utc.
}

const endOfDayInAnyUtc = (date) => {
    return moment(date).startOf('day').set("hour", 19).set("minute", 59).set("second", 59).set("millisecond", 999).toDate();
    //TODO: To quickfix Extra Days issue i give this ones magic numbers. We need to make them work in any utc.
}

const formatIsoDate = (date) => {
    return formatDate(date, 'YYYY-MM-DD');
}

const formatDateTime = (date) => {
    return formatDate(moment(date), "YYYY-MM-DDTHH:mm:ssZ");
};

export default {
    now,
    moment,
    parse,
    diff,
    formatDate,
    formatTzDate,
    formatDateDayAndMonth,
    formatDateMonthAndYear,
    formatDateMMM,
    formatDateDo,
    formatDateTime12H,
    getHourMinute24H,
    formatDateTimeSeparately,
    getHourMinute12H,
    isToday,
    getAge,
    getAgeWithMonths,
    add,
    addMinutes,
    addHours,
    addDays,
    addWeeks,
    addMonths,
    addYears,
    startOfWeek,
    endOfWeek,
    startOfPreviousWeek,
    startOfNextWeek,
    startOfMonth,
    endOfMonth,
    yesterday,
    today,
    tomorrow,
    isYesterday,
    isTomorrow,
    startOfPreviousMonth,
    startOfNextMonth,
    timeOf,
    parseTime,
    isBefore,
    isAfter,
    isSameOrBefore,
    isSameOrAfter,
    isSame,
    isSameDay,
    currentYear,
    isInRange,
    formatDateDoMMYY,
    formatDateTimeSeparatelyForReports,
    switchZone,
    isBetweenInclusive,
    getUtcDateAtStartOfDay,
    getTimeStringAtZone,
    getTimeStringAtClinicZone,
    getDurationInMinutes,
    formatHoursDuration,
    getDifferenceInDays,
    getDay,
    getWeekDay,
    formatDateTimeSeparatelyForFileStamp,
    getDayName,
    createMomentWithZone,
    startOfDay,
    endOfDay,
    startOfDayInAnyUtc,
    endOfDayInAnyUtc,
    formatIsoDate,
    formatDateTime
};
