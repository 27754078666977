import Enum from "../../../../../../enums/Enum";

const AreaEnum = new Enum({
        NECK: {name: "Neck"},
        SHOULDER: {name: "Shoulder"},
        ELBOW: {name: "Elbow"},
        WRIST: {name: "Wrist"},
        LUMBAR_SPINE: {name: "Lumbar Spine"},
        HIP: {name: "Hip"},
        SACROILIAC: {name: "Sacroiliac"},
        KNEE: {name: "Knee"},
        ANKLE: {name: "Ankle"},
        VISCERAL: {name: "Visceral"},
        WADDELLS_SIGN: {name: "Waddell's Sign"}
});

export default AreaEnum;