import React, {Component} from "react";
import './BorderedCheckboxInput.css';
import TextInput from "../text-input/TextInput";
import GreenSwitch from "../switch/GreenSwitch";
import CheckboxInput from "./CheckboxInput";

export default class BorderedCheckboxInput extends Component {

    state = {};

    constructor(props) {
        super(props);
        this.state.values = this.props.values;
        this.state.selected = this.props.selected;
        this.state.switched = false;
        this.state.objval  = this.props.objval;
    }

    render() {
        const items = []

        for (const enumObject of this.state.values) {
            if(this.state.selected?.includes(enumObject.key)) {
                items.push(
                    <div key={enumObject.key}
                         className={"bordered-checkbox-selected"}
                         style={this.props.itemStyle}
                         onClick={()=> {
                             this.setState((state) =>
                             {
                                 state.selected.splice(state.selected.indexOf(enumObject.key), 1);
                                 return state;
                             });
                         }}>
                        {enumObject.name}
                    </div>)
            }else {
                items.push(
                    <div key={enumObject.key}
                         className={"bordered-checkbox-unselected"}
                         style={this.props.itemStyle}
                         onClick={()=> {
                             this.setState((state) => {
                                 state.selected.push(enumObject.key);
                                 return state;
                             });
                         }}>
                        {enumObject.name}
                    </div>)
            }
        }
        return (<>
                {this.props.title &&
                <div style={{position: "relative", display: "flex"}}>
                    <div className={"title-vertical-center mb-5"}>
                        <h5 className="font-weight-bold">{this.props.title}</h5>
                    </div>

                    <div className="d-flex align-items-baseline" style={{display: "flex", marginLeft: "auto"}}>


                        <div style={{marginTop: "13px", fontSize: "10px"}}>
                            {this.getChecked() && this.props.emptyMessage}
                        </div>
                        <div style={{marginLeft: "auto"}}>
                            <GreenSwitch checked={this.getChecked()}
                                    onChange={()=> {
                                        if(this.getChecked()) {
                                            if(this.props.obj != undefined) {
                                                this.props.obj[this.props.field] = '';
                                            }
                                            this.setState((state) => {
                                                state.selected.splice(0, state.selected.length);
                                                state.switched = false;
                                                return state;
                                            });
                                        } else {
                                            this.setState({
                                                switched: true
                                            });
                                        }
                                    }}/>
                        </div>
                    </div>
                </div>
                }

            {this.props.normalCheckbox &&
            <div className={"row m-0 ml-1 mb-1"}>
                <div className={"d-flex"}>
                    <CheckboxInput value={this.props.normalCheckboxValue}
                                   onChange={(value) => {
                                       this.props.onChangeNormalCheckbox(value);
                                       if(value) {
                                           if(this.props.obj != undefined) {
                                               this.props.obj[this.props.field] = '';
                                           }
                                           this.setState((state) => {
                                               state.selected.splice(0, state.selected.length);
                                               return state;
                                           });
                                       }
                                   }}/>
                    <div style={{marginTop: "-5px", marginLeft: "5px", marginRight: "5px"}}>{this.props.normalCheckboxLabel}</div>
                </div>
            </div>}

            <div className={this.props.normalCheckbox && this.props.normalCheckboxValue ? "not-allowed" : ""}>
                <div className={this.props.normalCheckbox && this.props.normalCheckboxValue ? "disable-pointer" : ""}>
                    <div className={"disc-checkbox-container"}>
                    {items}
                    </div>
                    {this.props.detail &&
                    <div>
                        detail
                    </div>
                    }

                    {this.props.obj &&
                    <div>
                        <TextInput value={this.props.obj[this.props.field] || ''}
                                   placeholder={"Details"}
                                   className="mt-2"
                                   style={{width: '-webkit-fill-available'}}
                                   onChange={value =>  this.setState(state => {
                                       this.props.obj[this.props.field] = value;
                                       return state;
                                   })
                                   }
                        />
                    </div>
                    }
                </div>
            </div>
            </>);
        }

        getChecked = () => {
            return (this.state.switched
                    || (this.state.selected != null && this.state.selected.length != 0)
                    || (this.props.obj != undefined && this.props.obj[this.props.field] != null
                            && this.props.obj[this.props.field] != '')) || (this.props.normalCheckbox && this.props.normalCheckboxValue);
        }

}
