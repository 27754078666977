import React, {Component} from 'react';
import DateUtil from "../../../utils/DateUtil";
import TimezoneUtil from "../../../utils/TimezoneUtil";
import AppointmentUtil from "../../../utils/AppointmentUtil";
import RemotingService from '../../../services/remoting-service/RemotingService';
import {Modal, TextareaInput, TextInput, PhoneInput} from "../../../components";
import NotificationService from "../../../services/notification-service/NotificationService";
import InsuranceEligibility from "./InsuranceEligibility";
import PatientSearchModal from "./PatientSearchModal";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {noop} from "lodash-es";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import FormField from "../../../components/form/FormField";
import PrimeDateTimeInput from "../../../components/date-input/PrimeDateTimeInput";
import PrimeDateInput from "../../../components/date-input/PrimeDateInput";
import {Dropdown} from "primereact/dropdown";
import EnumAppointmentType from "../enums/EnumAppointmentType";
import PatientUtil from "../../../utils/PatientUtil";
import moment from "moment";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import DirectBillingStatus from "../../insurance/enums/DirectBillingStatus";
import {Checkbox} from "primereact/checkbox";
import EnumInsuranceReferralType from "../../insurance/eligibility/referral/enums/EnumInsuranceReferralType";
import AuthService from "../../../services/auth-service/AuthService";
import EnumDirectBillingType from "../../insurance/eligibility/direct/EnumDirectBillingType";
import Tooltip from "../../../components/tooltip/Tooltip";
import {isEmpty, onlyUnique} from "../../../utils/ArrayUtil";
import confirmDialog from "../../../components/dialog/ConfirmDialog";

class AddAppointment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            desiredStartTime: null,
            appointment: {
                patient: {},
                patientType: props.patientType,
                insuranceExists: false
            },
            selectedPatient: null,
            insuranceEligibilityModalVisible: false,
            findPatientModalVisible: false,
            errorMessages: [],
            dbInsuranceRecords: [],
            erInsuranceRecords: [],
            selectedInsuranceRecord: null,
            eidError:false
        };
        this.uaeSelected = AuthService.getSelectedClinicCountriesInfo().uaeSelected;
    }

    componentDidMount() {
        this.getProviderCountry();
    }

    handleMobilePhoneChange = (value, dialCode) => {
        let appointment = {...this.state.appointment};
        appointment.patient.mobilePhone = value;
        appointment.patient.dialCodeLength = dialCode.length + 1;
        this.setState({appointment: appointment});
    }

    handlePatientFirstNameChange = (value) => {
        let appointment = {...this.state.appointment};
        appointment.patient.firstName = value;
        this.setState({appointment: appointment});
    }

    handlePatientMiddleNameChange = (value) => {
        let appointment = {...this.state.appointment};
        appointment.patient.middleName = value;
        this.setState({appointment: appointment});
    }

    handlePatientLastNameChange = (value) => {
        let appointment = {...this.state.appointment};
        appointment.patient.lastName = value;
        this.setState({appointment: appointment});
    }

    handleEidChange = (value) => {
        const regex = new RegExp("^$|^[0-9]+(-[0-9]+)*-?$")
        if(regex.test(value)){
            let appointment = {...this.state.appointment};
            appointment.patient.eid = value;
            this.setState({appointment: appointment});
        }
    }

    handleBirthdayChange = (value) => {
        let appointment = {...this.state.appointment};
        appointment.patient.birthday = value;

        this.setState({
            appointment: appointment
        });
    }

    retrieveDirectBillinginsuranceRecords = () => {
        RemotingService.getRemoteCall(
            'api/insurance/coordinator/direct-billing/patient',
            {
                patientId: this.state.selectedPatient.id,
                date: moment(this.props.start).format('YYYY-MM-DD'),
            },
            (dbInsuranceRecords) => {
                this.setState({dbInsuranceRecords});
            });
    }

    retrieveExternalReferralInsuranceRecords = () => {
        RemotingService.getRemoteCall(
            'api/insurance/coordinator/external-referral/patient',
            {
                patientId: this.state.selectedPatient.id,
                date: moment(this.props.start).format('YYYY-MM-DD'),
            },
            (erInsuranceRecords) => {
                this.setState({erInsuranceRecords});
            });
    }

    handlePatientSearchResultSelect = (patient) => {
        let appointment = {...this.state.appointment};
        appointment.patient = patient;

        this.setState({
                selectedPatient: patient,
                appointment: appointment,
            },
            () => {
                this.retrieveDirectBillinginsuranceRecords();
                this.retrieveExternalReferralInsuranceRecords();
                this.checkSpecialStatus(patient);
                this.getActiveRestrictionOfSelectedPatient(patient.id);
            }
        );
    }

    deselectPatientSearchResult = () => {
        let appointment = {...this.state.appointment};
        appointment.patient.firstName = "";
        appointment.patient.middleName = "";
        appointment.patient.lastName = "";
        appointment.patient.mobilePhone = "";
        appointment.patient.eid = null;
        appointment.patient.birthday = null;
        appointment.insuranceExists = false;
        appointment.insurerId = null;
        appointment.serviceCategoryId = null;
        appointment.consultationCategoryId = null;

        this.setState({
            selectedPatient: null,
            errorMessages: [],
            specialStatusError: null,
            appointment: appointment,
            dbInsuranceRecords: [],
            erInsuranceRecords: [],
            selectedInsuranceRecord: null,
        });
    }

    getActiveRestrictionOfSelectedPatient = (patientId) => {
        let params = {
            patientId: patientId,
            startDate: this.props.start,
            staffId: this.props.staff.id
        };
        RemotingService.postRemoteCall("api/restriction/check", params, noop, noop, true, false, (messages) => {
            let errorMessages = [];
            if (messages && messages.length > 0) {
                errorMessages = RemotingService.extractMessages(messages, "ERROR");
            }
            this.setState({errorMessages: errorMessages});
        });
    }

    checkSpecialStatus = (patient) => {
        let isFemaleClinicanOnly = false;
        let isMaleClinicanOnly = false;
        let languageLimits = [];

        patient.specialStatusList.map(specialStatusItem => {
            if (specialStatusItem.specialStatus === "FEMALE")
                isFemaleClinicanOnly = true;
            else if (specialStatusItem.specialStatus === "MALE")
                isMaleClinicanOnly = true;
            else if (specialStatusItem.specialStatus === "LANGUAGE")
                languageLimits = specialStatusItem.languageLimits.split(", ")
        })

        RemotingService.getRemoteCall('api/staff/' + this.props.staff.id, null, (selectedStaff) => {
            if (selectedStaff.gender === "MALE" && isFemaleClinicanOnly)
                this.setState({specialStatusError: "This patient prefers only female clinicians."})
            if (selectedStaff.gender === "FEMALE" && isMaleClinicanOnly)
                this.setState({specialStatusError: "This patient prefers only male clinicians."})
            if (languageLimits.length) {
                if (this.hasLanguageLimitBetween(languageLimits, selectedStaff.languages)) {
                    this.setState({specialStatusError: "This patient prefers " + languageLimits.join(", ") + " speaker clinicians."})
                }
            }
        });
    }

    hasLanguageLimitBetween = (patientLanguageLimits, staffLanguages) => {
        let isLimited = true;
        patientLanguageLimits.map(patientLanguageLimit => {
            if (staffLanguages.filter(language => language.name === patientLanguageLimit).length !== 0) {
                isLimited = false;
            }
        })
        return isLimited;
    }

    setTime = (date, hour, minute, second, milliseconds) => {
        let editedDate = date;
        editedDate.setHours(hour);
        editedDate.setMinutes(minute);
        editedDate.setSeconds(second);
        editedDate.setMilliseconds(milliseconds);
        return editedDate;
    }

    handleDesiredStartTimeChange = (desiredStartTime) => {
        let desiredDate = desiredStartTime;
        if (!this.state.desiredStartTime) {
            if (DateUtil.isToday(desiredDate) && DateUtil.now().hour() < 12) {
                desiredDate = this.setTime(desiredDate, 12, 0, 0, 0)
            } else if (DateUtil.isToday(desiredDate) && DateUtil.now().hour() >= 12) {
                desiredDate = this.setTime(desiredDate, DateUtil.now().hour() + 1, 0, 0, 0)
            } else if (!DateUtil.isToday(desiredDate)) {
                desiredDate = this.setTime(desiredDate, 12, 0, 0, 0)
            }
        } else if (desiredDate) {
            if (DateUtil.isToday(this.state.desiredStartTime) && !DateUtil.isSameDay(this.state.desiredStartTime, desiredDate)) {
                desiredDate = this.setTime(desiredDate, 12, 0, 0, 0)
            }
            if (!DateUtil.isToday(this.state.desiredStartTime) && DateUtil.isSameDay(DateUtil.now(), desiredDate)) {
                desiredDate = this.setTime(desiredDate, DateUtil.now().hour() + 1, 0, 0, 0)
            }
        }

        this.setState({desiredStartTime: desiredDate});
        let offsetInHours = TimezoneUtil.getClinicZoneOffsetWithLocalTime(this.props.room.clinicName) / 60;

        let value = DateUtil.addHours(desiredDate, -offsetInHours);

        let appointment = this.state.appointment;
        appointment.desiredStartTime = value;
        this.setState({appointment});
    }

    handleInsuranceOptionSelect = (insuranceExists) => {
        let appointment = {...this.state.appointment};
        appointment.insuranceExists = insuranceExists;
        let newState = {
            appointment: appointment
        }

        if (!insuranceExists) {
            appointment.insurerId = null;
            appointment.serviceCategoryId = null;
            appointment.consultationCategoryId = null;
        } else {
            newState.insuranceEligibilityModalVisible = true;
        }

        this.setState(newState);
    }

    handleNoteChange = (value) => {
        let appointment = {...this.state.appointment};
        appointment.note = value;

        this.setState({
            appointment: appointment
        });
    }

    handleEligible = (eligibility) => {
        let appointment = {...this.state.appointment};
        appointment.insurerId = eligibility.insurerId;
        appointment.serviceCategoryId = eligibility.serviceCategoryId;
        appointment.consultationCategoryId = eligibility.consultationCategoryId;
        appointment.insuranceExists = true;

        this.setState({
            appointment: appointment,
            insuranceEligibilityModalVisible: false
        });
    }

    handleNotEligible = () => {
        let appointment = {...this.state.appointment};
        appointment.insurerId = null;
        appointment.serviceCategoryId = null;
        appointment.consultationCategoryId = null;
        appointment.insuranceExists = false;

        this.setState({
            appointment: appointment,
            insuranceEligibilityModalVisible: false
        });
    }

    handleAddNewAppointmentComplete = () => {
        this.checkEid();

        if (!ValidationUtil.isValid(this.formFields)) {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'There are missing fields.'
            });
            return;
        }

        let existingPatient = this.state.selectedPatient ? true : false;
        let appointment = this.state.appointment;
        let appointmentAddObject = {
            startTime: this.props.start,
            endTime: this.props.end,
            roomId: this.props.room.id,
            staffId: this.props.staff.id,
            insuranceExists: appointment.insuranceExists,
            note: appointment.note,
            desiredStartTime: appointment.desiredStartTime,
            insurerId: appointment.insurerId,
            serviceCategoryId: appointment.serviceCategoryId,
            consultationCategoryId: appointment.consultationCategoryId,
            patientType: appointment.patientType
        };

        let url;
        if (existingPatient) { // Add appointment for a selected patient and refresh calendar
            appointmentAddObject.patientId = this.state.selectedPatient.id;
            url = 'api/appointment/create-for-existing-patient';

            appointmentAddObject.insuranceRecordId = this.state.selectedInsuranceRecord ? this.state.selectedInsuranceRecord.id : null;

        } else { // Add appointment for a new patient and load appointment-summary page afterwards.
            appointmentAddObject.patientMobilePhone = appointment.patient.mobilePhone;
            appointmentAddObject.patientFirstName = appointment.patient.firstName;
            appointmentAddObject.patientMiddleName = appointment.patient.middleName;
            appointmentAddObject.patientLastName = appointment.patient.lastName;
            appointmentAddObject.patientEid = appointment.patient.eid;
            appointmentAddObject.patientBirthday = appointment.patient.birthday ? appointment.patient.birthday : null;
            appointmentAddObject.patientClinicCountry = this.state.providerCountry;
            url = 'api/appointment/create-for-new-patient';
        }

        RemotingService.postRemoteCall(url, appointmentAddObject, (appointment) => {
            this.props.close();
            this.props.onAppointmentAdded(appointment, existingPatient);
        });
    }

    renderModalBody() {
        this.formFields = [];
        return <div>
            <div className={"container"} style={{maxWidth: '100%'}}>
                <div className="row">
                    <div className="col-md-3 dim14-form-label">
                        Staff Name
                    </div>
                    <div className="col-md-9">
                        {this.props.staff.name}
                        {this.props.staff.clinicianLicense ? " (" + this.props.staff.clinicianLicense + ")" : ""}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3 dim14-form-label">
                        Start - End
                    </div>
                    <div className="col-md-9">
                        {DateUtil.formatDate(this.props.start, "Do MMMM YYYY")
                        + ' ' + AppointmentUtil.appointmentTimeSlot({
                            start: this.props.start,
                            end: this.props.end, room: this.props.room
                        })}
                    </div>
                </div>

                <div className={"dim14-form-row-align"} style={{
                    border: "1px solid #D3D3D3",
                    marginRight: -10,
                    marginLeft: -15,
                    borderRadius: 8,
                    padding: 5,
                    paddingBottom: 12
                }}>
                    <div className="row ml-0 pl-0">
                        <div className="col-md-3 dim14-form-label">
                            <h3>Patient</h3>
                        </div>
                        <div className="col-md-9 mt-2">
                            {this.state.selectedPatient ?
                                <div style={{color: "red", cursor: "pointer"}}
                                     onClick={this.deselectPatientSearchResult}>
                                    ⓧ Deselect Patient
                                </div> :
                                <Button variant={"outline-secondary"} onClick={this.handleFindPatientModalOpen}
                                        size={"sm"}>
                                    <FontAwesomeIcon icon={["fas", "search"]}/>
                                    Search in Existing Patients
                                </Button>
                            }
                        </div>
                    </div>
                    <div className="row" style={{marginLeft: "0px"}}>
                        <div className="col-md-3 dim14-form-label">
                            First Name
                        </div>
                        <div className="col-md-9">
                            <FormField ref={formField => this.formFields.push(formField)} required
                                       validateOn={this.state.appointment.patient.firstName}>
                                <div style={{display: "flex"}}>
                                    <TextInput value={this.state.appointment.patient.firstName}
                                               style={{width: "95%", padding: "5px 11px"}}
                                               placeholder="Enter patient first name"
                                               alphabeticOnly
                                               onChange={(e) => this.handlePatientFirstNameChange(e)}
                                               onKeyPress={event => {
                                                   if (event.charCode == 13) {
                                                       event.preventDefault();
                                                       event.stopPropagation();
                                                       this.handleFindPatientModalOpen()
                                                   }
                                               }}
                                               disabled={this.state.selectedPatient ? true : false}
                                               className="AddModalAutoComplete"
                                    />
                                </div>
                            </FormField>
                        </div>
                    </div>

                    <div className="row" style={{marginLeft: "0px"}}>
                        <div className="col-md-3 dim14-form-label">
                            Middle Name
                        </div>
                        <div className="col-md-9">
                            <div style={{display: "flex"}}>
                                <TextInput value={this.state.appointment.patient.middleName}
                                           style={{width: "95%", padding: "5px 11px"}}
                                           placeholder="Enter patient middle name"
                                           alphabeticOnly
                                           onChange={(e) => this.handlePatientMiddleNameChange(e)}
                                           onKeyPress={event => {
                                               if (event.charCode == 13) {
                                                   event.preventDefault();
                                                   event.stopPropagation();
                                                   this.handleFindPatientModalOpen()
                                               }
                                           }}
                                           disabled={this.state.selectedPatient ? true : false}
                                           className="AddModalAutoComplete"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{marginLeft: "0px"}}>
                        <div className="col-md-3 dim14-form-label">
                            Last Name
                        </div>
                        <div className="col-md-9">
                            <FormField ref={formField => this.formFields.push(formField)} required
                                       validateOn={this.state.appointment.patient.lastName}>
                                <div style={{display: "flex"}}>
                                    <TextInput value={this.state.appointment.patient.lastName}
                                               style={{width: "95%", padding: "5px 11px"}}
                                               placeholder="Enter patient last name"
                                               alphabeticOnly
                                               onChange={(e) => this.handlePatientLastNameChange(e)}
                                               onKeyPress={event => {
                                                   if (event.charCode == 13) {
                                                       event.preventDefault();
                                                       event.stopPropagation();
                                                       this.handleFindPatientModalOpen()
                                                   }
                                               }}
                                               disabled={this.state.selectedPatient ? true : false}
                                               className="AddModalAutoComplete"
                                    />
                                </div>
                            </FormField>
                        </div>
                    </div>

                    <div className="row" style={{marginLeft: "0px"}}>
                        <div className="col-md-3 dim14-form-label">
                            Mobile Phone
                        </div>
                        <div className="col-md-9">
                            <FormField ref={formField => this.formFields.push(formField)} phoneRequired={this.state.appointment.patient.dialCodeLength}
                                       validateOn={this.state.appointment.patient.mobilePhone} disabled={this.state.selectedPatient}>
                                <div className="d-flex w-100">
                                    <PhoneInput value={this.state.appointment.patient.mobilePhone}
                                                country={this.uaeSelected ? 'ae' : 'kw'}
                                                placeholder="Enter mobile phone"
                                                containerClass="AddModalAutoComplete"
                                                inputClass="AddModalMobilePhone"
                                                style={{width: "95%"}}
                                                onKeyDown={event => {
                                                    if (event.keyCode == 13) {
                                                        event.preventDefault();
                                                        event.stopPropagation();
                                                        this.handleFindPatientModalOpen()
                                                    }
                                                }}
                                                disabled={!!this.state.selectedPatient}
                                                onChange={(value, data) => {
                                                    this.handleMobilePhoneChange(value, data.dialCode)
                                                }}/>
                                </div>
                            </FormField>
                        </div>
                    </div>

                    <div className="row" style={{marginLeft: "0px"}}>
                        <div className="col-md-3 dim14-form-label">
                            ID No
                        </div>
                        <div className="col-md-9">
                            <div style={{display: "flex"}}>
                                <TextInput value={this.state.appointment.patient.eid || ""}
                                           placeholder="Enter ID No"
                                           onKeyPress={event => {
                                               if (event.charCode == 13) {
                                                   event.preventDefault();
                                                   event.stopPropagation();
                                                   this.handleFindPatientModalOpen()
                                               }
                                           }}
                                           style={{width: "95%"}}
                                           onChange={(e) => this.handleEidChange(e)}
                                           disabled={this.state.selectedPatient ? true : false}
                                           className="AddModalAutoComplete"
                                />
                                {this.state.eidError ?
                                    <span style={{width: "41%", color: "red"}}> &nbsp;ID No cannot end with '-'</span> : null}
                            </div>
                        </div>
                    </div>

                    <div className="row" style={{marginLeft: "0px"}}>
                        <div className="col-md-3 dim14-form-label">
                            Date of Birth
                        </div>
                        <div className="col-md-9">
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                {this.state.selectedPatient ?
                                    this.state.appointment.patient.birthday ? DateUtil.formatDateMMM(this.state.appointment.patient.birthday) : '-' :
                                    <PrimeDateInput
                                        value={this.state.appointment.patient.birthday ? this.state.appointment.patient.birthday : null}
                                        onChange={val => this.handleBirthdayChange(val)} disableFuture="true" clearable showCalendarIcon
                                    />
                                }
                                <b className="pl-5 pr-2">Age</b> {this.state.appointment.patient.birthday ? DateUtil.getAgeWithMonths(this.state.appointment.patient.birthday) : '-'}
                            </div>
                        </div>
                    </div>

                </div>
                {this.state.errorMessages.length > 0 &&
                <div className="row">
                    <div className="col-md-12">
                        {
                            this.state.errorMessages.map(errorMessage => {
                                return <span style={{color: "red", fontWeight: "bold"}}>{errorMessage}</span>
                            })
                        }
                    </div>
                </div>
                }

                {this.state.specialStatusError &&
                <div className="row">
                    <div className="col-md-12">
                            <span style={{color: "red", fontWeight: "bold"}}>
                                {this.state.specialStatusError}
                            </span>
                    </div>
                </div>
                }

                <div className="row">
                    <div className="col-md-3 dim14-form-label">
                        Desired Date of Appointment
                    </div>
                    <div className="col-md-9">
                        <PrimeDateTimeInput
                            value={this.state.desiredStartTime}
                            onChange={val => this.handleDesiredStartTimeChange(val)}
                            disablePast="true"
                            style={{width: "100%"}}
                            viewDate={DateUtil.now().hour() < 12 ? DateUtil.timeOf(12, 0) : DateUtil.timeOf(DateUtil.now().hour() + 1, 0)}
                            clearable
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-3 dim14-form-label">
                        Type
                    </div>
                    <div className="col-md-9">
                        <FormField ref={formField => this.formFields.push(formField)} required
                                   validateOn={this.state.appointment.patientType}>
                            <Dropdown optionLabel="name"
                                      optionValue="key"
                                      options={EnumAppointmentType.selectableTypes}
                                      value={this.state.appointment.patientType}
                                      onChange={e => this.setState(state => {
                                          state.appointment.patientType = e.value;
                                          state.selectedInsuranceRecord = null;
                                          return {...state};
                                      })}/>
                        </FormField>
                    </div>
                </div>

                {this.state.selectedPatient && this.isFollowUp() &&
                <div className="row">
                    <div className="col-md-3 dim14-form-label">
                        Direct Billing
                    </div>
                    <div className="col-md-9">
                        <DataTable responsive emptyMessage={"No items found"} value={this.state.dbInsuranceRecords}>
                            <Column field="checkbox" header=" " body={this.checkboxTemplate} style={{width: 30}}/>
                            <Column header="ICD" field="diagnosisItem" body={this.icdDBTemplate}/>
                            <Column header="Ref" body={this.referenceNoTemplate}/>
                            <Column header="Type"
                                    body={(item) => item.speciality ? EnumDirectBillingType[item.speciality].name : ''}
                                    style={{width: '100px'}}/>
                            <Column header="Provider" field="referral.providerName"/>
                            <Column header="Used Sessions" field="noOfUsedSessions"/>
                            <Column header="Approved Sessions" field="noOfApprovedSessions"/>
                            <Column header="Valid Until" field="validUntil"
                                    body={(item) => DateUtil.formatDate(item.validUntil, 'Do MMM. YYYY')}/>
                            <Column header="Status" field="status"
                                    body={(item) => DirectBillingStatus[item.status].name}/>
                        </DataTable>
                    </div>
                </div>
                }

                {this.state.selectedPatient &&
                <div className="row">
                    <div className="col-md-3 dim14-form-label">
                        External Referral
                    </div>
                    <div className="col-md-9">
                        <DataTable responsive emptyMessage={"No items found"} value={this.state.erInsuranceRecords}>
                            <Column field="checkbox" header=" " body={this.checkboxTemplate} style={{width: 30}}/>
                            <Column header="ICD" field="diagnosisItem" body={this.icdERTemplate}/>
                            <Column className="text-break" header="Ref" field="referenceNo"/>
                            <Column header="Type"
                                    body={(item) => item.referralType ? EnumInsuranceReferralType[item.referralType].name : ''}/>
                            <Column header="Used Sessions" field="noOfUsedSessions"/>
                            <Column header="Approved Sessions" field="noOfSessions"/>
                            <Column header="Valid Until" field="validUntil"
                                    body={(item) => DateUtil.formatDate(item.validUntil, 'Do MMM. YYYY')}/>
                            <Column header="Status" field="status"
                                    body={(item) => DirectBillingStatus[item.status].name}/>
                        </DataTable>
                    </div>
                </div>
                }

                <div className="row">
                    <div className="col-md-3 dim14-form-label">
                        Note
                    </div>
                    <div className="col-md-9">
                        <FormField ref={formField => this.formFields.push(formField)} maxLength={500}
                                   validateOn={this.state.appointment.note}>
                            <TextareaInput value={this.state.appointment.note}
                                           style={{width: "100%", maxHeight: "200px"}}
                                           onChange={(val) => this.handleNoteChange(val)}
                                           className="AddModalInput"
                                           id="addappointmentnotetextarea"/>
                        </FormField>
                    </div>
                </div>
            </div>
            {this.renderInsuranceEligibilty()}
            {this.renderFindPatient()}
        </div>;
    }

    checkboxTemplate = (data, column) => {
        return <Checkbox inputId={"entry" + column.rowIndex}
                         checked={this.state.selectedInsuranceRecord && this.state.selectedInsuranceRecord.id === data.id && this.state.selectedInsuranceRecord.type === data.type}
                         onChange={event => this.setState({
                             selectedInsuranceRecord: event.checked ? data : null
                         })}/>
    }

    icdDBTemplate = (data) => {
        const {diagnosisItem} = data;
        return <Tooltip title={diagnosisItem.description}><span>{diagnosisItem.code}</span></Tooltip>
    }

    icdERTemplate = (data) => {
        const {icd} = data;
        return <Tooltip title={icd.description}><span>{icd.code}</span></Tooltip>
    }

    referenceNoTemplate = (item) => {
        return isEmpty(item.directBillingItemList) ? null :
                <span className="text-break">
                    {
                        item.directBillingItemList
                            .map(i => i.referenceNo)
                            .filter(i => !!i)
                            .filter(onlyUnique)
                            .join(", ")
                    }
                </span>
    }

    isFollowUp = () => {
        return 'FOLLOW_UP' === this.state.appointment.patientType;
    }

    render() {
        let recordIsNotSelected = (this.state.selectedInsuranceRecord == null && (this.state.erInsuranceRecords.length !== 0 || (this.isFollowUp() && this.state.dbInsuranceRecords.length !== 0)))
        return <Modal visible={true}
                      className="AddAppointmentModal"
                      header="Add Appointment"
                      submitContent="Confirm"
                      submitAction={() => recordIsNotSelected ? this.renderConfirmDialog() : this.handleAddNewAppointmentComplete()}
                      submitDisabled={this.state.errorMessages.length > 0}
                      closeAction={this.props.close}
                      contentStyle={{maxHeight: 800, overflowY: 'auto', width: "1000px", maxWidth: "90vw"}}
        >
            {this.renderModalBody()}
        </Modal>;
    }

    renderConfirmDialog(){
        return confirmDialog("Confirm Appointment",
            "You have approved sessions from Insurance. Do you want to proceed without selecting the approved session?",
            () => this.handleAddNewAppointmentComplete(),undefined,undefined,"Yes","No")
    }

    renderInsuranceEligibilty() {
        return this.state.insuranceEligibilityModalVisible &&
            <InsuranceEligibility
                emiratesId={this.state.selectedPatient ? this.state.selectedPatient.eid : this.state.appointment.patient.eid}
                mobilePhone={this.state.selectedPatient ? this.state.selectedPatient.mobilePhone : this.state.appointment.patient.mobilePhone}
                staffId={this.props.staff.id}
                onEligible={this.handleEligible}
                onNotEligible={this.handleNotEligible}
                close={this.handleNotEligible}
            />;
    }

    handleFindPatientModalOpen = () => {
        ValidationUtil.clear(this.formFields);
        this.setState({
            findPatientModalVisible: true
        })
    }

    handleFindPatientModalClose = () => {
        this.setState({
            findPatientModalVisible: false
        });
    }

    handleFindPatientModalSelect = (selectedPatient) => {
        this.handleFindPatientModalClose()
        this.handlePatientSearchResultSelect(selectedPatient)
    }

    renderFindPatient() {
        const icon = (<FontAwesomeIcon icon={["fas", "user-plus"]} size={"2x"} color={"#b1d93f"}
                                       style={{marginTop: "3px"}}/>)

        return this.state.findPatientModalVisible &&
            <PatientSearchModal
                header={"Find a Patient"}
                selectIcon={icon}
                handleFindPatientModalSelect={this.handleFindPatientModalSelect}
                patientIdSearchText={this.state.appointment.patient.eid}
                patientNameSearchText={PatientUtil.getPatientName(this.state.appointment.patient.firstName,
                    this.state.appointment.patient.middleName,
                    this.state.appointment.patient.lastName)}
                patientMobilePhoneSearchText={this.state.appointment.patient.mobilePhone}
                close={this.handleFindPatientModalClose}
            />;
    }

    getProviderCountry = ()=> {
        RemotingService.getRemoteCall('api/staff/' + this.props.staff.id, null, (selectedStaff) => {
           this.setState({providerCountry: selectedStaff.departments[0].clinic.country});
        });
    }

    checkEid() {
        let eid = this.state.appointment.patient.eid;
        if (eid == null)
            return;
        if (eid.charAt(eid.length - 1) === "-")
            this.setState({eidError: true})
        else
            this.setState({eidError: false})
    }
}

export default AddAppointment;
