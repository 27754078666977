import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "../expansion-panel/CustomExpansionPanel";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            backgroundColor: 'rgba(243, 244, 246)',
            minHeight: 56,
        }
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
        display: 'inline-flex !important'
    },
    heading: {
        fontSize: theme.typography.pxToRem(24),
        fontWeight: "bold",
        textAlign: "left"
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(24),
        fontWeight: "bold",
        textAlign: props => props.secondHeaderAlignment === undefined ? " " : props.secondHeaderAlignment,
        paddingLeft: "20px"
    },
    thirdHeading: {
        fontSize: theme.typography.pxToRem(24),
        fontWeight: "bold",
        textAlign: "left",
        paddingLeft: "25px"
    },
    icon: {
        verticalAlign: "bottom",
        height: 20,
        width: 20
    },
    details: {
        alignItems: props => props.alignItems === undefined ? "center" : props.alignItems
    },
    column: {
        flexBasis: props => props.flexBasis === undefined ? "100%" : props.flexBasis
    },
    helper: {
        borderLeft: `2px solid ${theme.palette.divider}`,
        padding: theme.spacing(1, 2)
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: "none",
        "&:hover": {
            textDecoration: "underline"
        }
    }
}));


const MultipleHeaderAccordion = (props) => {
    const classes = useStyles(props);
    const defaultExpanded = props.defaultExpanded == true || props.defaultExpanded == undefined;
    return (
        <div className={clsx(classes.root, props.className)} id={"prevent-split"} style={{marginTop: 20}}>
            <ExpansionPanel defaultExpanded={defaultExpanded} expanded={props.expanded} onChange={props.onChange}>
                <MuiExpansionPanelSummary
                    classes={{content: classes.content,
                        root: classes.root}}
                    expandIcon={!props.hideIcon && <ExpandMoreIcon/>}>
                    <div className={classes.column}>
                        <Typography className={classes.heading}>
                            {props.headerText.firstHeaderText}
                        </Typography>
                    </div>

                    {props.headerExist.secondHeader && (
                        <div className={classes.column}>
                            <Typography className={classes.secondaryHeading}>
                                {props.headerText.secondHeaderText}
                            </Typography>
                        </div>)}

                    {props.headerExist.thirdHeader && (
                        <div className={classes.column}>
                            <Typography className={classes.thirdHeading}>
                                {props.headerText.thirdHeaderText}
                            </Typography>
                        </div>)}
                </MuiExpansionPanelSummary>

                <div className={classes.column}>
                    <MuiExpansionPanelDetails className={classes.details}>
                        {props.children}
                    </MuiExpansionPanelDetails>
                </div>
            </ExpansionPanel>
        </div>
    )};

MultipleHeaderAccordion.defaultProps = {
    alignItems: "center",
    secondHeaderAlignment: " ",
    flexBasis: "100%"
};
export {MultipleHeaderAccordion};

