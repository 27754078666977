import React from 'react';
import DateUtil from "../../../utils/DateUtil";
import RoomCalendarEventModal from "./RoomCalendarEventModal";
import TimezoneUtil from "../../../utils/TimezoneUtil";
import RemotingService from "../../../services/remoting-service/RemotingService";

class RoomCalendarView extends React.Component {

    state = {
        closedLeaves :[]
    };

    componentDidMount() {
        const {selectedDate, selectedView} = this.props;

        let startDate = selectedDate.clone().startOf(selectedView.id).toISOString();
        let endDate = selectedDate.clone().endOf(selectedView.id).toISOString();

        RemotingService.getRemoteCall('api/hr/leave/retrieveClosedLeaves', {startDate: startDate, endDate: endDate}, (result) => {
            this.setState({closedLeaves: result})
        })
    }

    getVisibleDates = () => {
        const {selectedDate, selectedView} = this.props;

        let result = [];
        let startDate = selectedDate.clone().startOf(selectedView.id);
        let endDate = selectedDate.clone().endOf(selectedView.id);
        while (startDate.isSameOrBefore(endDate, 'day')) {
            result.push(startDate.clone());
            startDate.add(1, "day");
        }
        return result;
    };

    renderHeaderCell = (date) => {
        return (
            <div className="RoomCalendarCol">
                <strong>{DateUtil.formatDate(date, "dddd")}</strong>
                <br/>
                {DateUtil.formatDate(date, "MMM DD, YYYY")}
            </div>
        );
    };

    renderHeaderRow = (visibleDates) => {
        return (
            <div className="RoomCalendarRow RoomCalendarColHeader">
                <div className="RoomCalendarRowHeader">&nbsp;</div>
                {
                    visibleDates.map(date => this.renderHeaderCell(date))
                }
            </div>
        );
    };

    retrieveUnclosedRoomSchedules = (schedule) => {
        let result = schedule.filter(item => this.state.closedLeaves.filter(closedLeave =>
            DateUtil.isSameOrBefore(closedLeave.startTime, item.start) &&
            DateUtil.isSameOrAfter(closedLeave.endTime, item.end) &&
            closedLeave.clinicId === item.room.clinic.id &&
            (closedLeave.allProviders || closedLeave.providerIds.filter(providerId => providerId === item.staffId).length > 0)).length === 0);
        return result;
    }

    renderCell = (schedule, date, room) => {
        const filteredSchedule = schedule.filter(record => record.startMoment.isSame(date, 'day'));
        const sortedSchedule = filteredSchedule.sort((r1, r2) => r1.startMoment.diff(r2.startMoment))
        const mergedSchedule = Object.values(sortedSchedule.reduce((result, r) => {
            if (result[r.staffId]) {
                result[r.staffId].mergedWith.push(r);
            } else {
                result[r.staffId] = r;
                result[r.staffId].mergedWith = [];
            }
            return result;
        }, {}));
        const closedClinicsFilteredSchedule = this.retrieveUnclosedRoomSchedules(mergedSchedule)
        const isTwoStaffOnOneRoom = closedClinicsFilteredSchedule.length === 2
        const fontSize = isTwoStaffOnOneRoom ? "8px" : "9px";
        return (
            <div className="RoomCalendarCol">
                <div className="RoomCalendarCellWrapper">
                    {
                        closedClinicsFilteredSchedule.map(record => {
                            const staff = this.props.staffList.find(staff => staff.id === record.staff.id);
                            let staffShortenedName = `${staff.name.substr(0, staff.name.indexOf(' '))} ${staff.name[staff.name.indexOf(' ') + 1]}.`;
                            const schedule = record;
                            return (
                                <RoomCalendarEventModal room={room} staff={staff} schedule={record} init={
                                    <div className={`RoomCalendarEvent ${this.props.isGenerated ? 'Generated' : ''} Item${closedClinicsFilteredSchedule.length}`}
                                         style={{backgroundColor: staff.color,
                                             display: isTwoStaffOnOneRoom ? "flex": "",
                                             justifyContent: isTwoStaffOnOneRoom ? "center": ""}}>
                                        <span className={isTwoStaffOnOneRoom ? "Item2StaffName" : ""}>{staffShortenedName}</span>
                                        {this.getRoomUsageTime(schedule, room, fontSize, isTwoStaffOnOneRoom)}
                                    </div>
                                }/>
                            );
                        })
                    }
                </div>
            </div>
        );
    };

    getRoomUsageTime = (schedule, room, fontSize, isTwoStaffOnOneRoom) => {
        const lastBlock = schedule.mergedWith && schedule.mergedWith.length > 0 ? schedule.mergedWith[schedule.mergedWith.length - 1] : schedule;

        return (<div className={isTwoStaffOnOneRoom ? "Item2Time" : "Item1Time"}>
            {DateUtil.formatDate(TimezoneUtil.atClinicZone(schedule.startMoment, room.clinicName), "HH:mm")}-
            {DateUtil.formatDate(TimezoneUtil.atClinicZone(lastBlock.endMoment, room.clinicName), "HH:mm")}
        </div>)
    }

    renderRows = (schedule, visibleDates, roomList) => {
        return roomList.map(room => {
            const filteredSchedule = schedule.filter(record => record.room && record.room.id === room.id);
            return (
                <div className="RoomCalendarRow">
                    <div className="RoomCalendarRowHeader"><label>{room.info}</label></div>
                    {
                        visibleDates.map(date => this.renderCell(filteredSchedule, date, room))
                    }
                </div>
            );
        });
    };

    render() {
        const schedule = this.props.data;
        const roomList = this.props.roomList || [];
        const visibleDates = this.getVisibleDates();

        return (
            <div className="RoomCalendarView">
                {this.renderHeaderRow(visibleDates)}
                {this.renderRows(schedule, visibleDates, roomList)}
            </div>
        )
    }
}

export default RoomCalendarView;
