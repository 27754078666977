import React, {Component} from 'react';
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import {RadioInput, TextInput} from "../../../components";
import IntakeFormYesNoEnum from "./enum/IntakeFormYesNoEnum";

class HadAnyXray extends Component {
    render() {
        const {
            selectedLanguage, readOnly, enumLabel, onChange,
            hadAnyXraysTaken, hadAnyXraysTakenDetails
        } = this.props

        return (
            <div className="container dim14-form-row-align">
                <div className="row">
                    <div className="col-6 dim14-form-label">
                        {IntakeFormTextEnum.Had_Any_Xray[selectedLanguage]}
                    </div>
                    <div className="col-6">
                        <div className="d-flex">
                            <RadioInput
                                id="hadAnyXraysTakenRadioInput"
                                value={hadAnyXraysTaken}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()}
                                displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) =>
                                    onChange("hadAnyXraysTaken", value)
                                }
                            />
                            {hadAnyXraysTaken === "Yes" ? (
                                <TextInput
                                    className="mx-2"
                                    placeholder={IntakeFormTextEnum.Detail[selectedLanguage]}
                                    value={hadAnyXraysTakenDetails}
                                    disabled={readOnly}
                                    onChange={(value) =>
                                        onChange("hadAnyXraysTakenDetails", value)
                                    }
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HadAnyXray;