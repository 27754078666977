import React from 'react';
import {withRouter} from "react-router";
import Menu from "../components/menu/Menu";
import MenuItem from "../components/menu/MenuItem";

const menu = (props) => {

    return (
        <div className="Menu">
            <Menu history={props.history}>
                <MenuItem label="Home" url="/"/>
                <MenuItem label="Appointments" url="/appointment" authority="VIEW_APPOINTMENT" />
                <MenuItem label="Patient Search" url="/patient" authority="VIEW_PATIENT_DETAIL"/>
                <MenuItem label="Providers" url="/staff" authority="VIEW_PROVIDER"/>
                <MenuItem label="Rooms" url="/room" authority="VIEW_ROOM"/>
                <MenuItem label="Schedule" url="/roomSchedule" authority="VIEW_SCHEDULING"/>
                <MenuItem label="Billing" url="/billing" authority="VIEW_BILLING_INVOICE" />
                <MenuItem label="EMR" url="/emr" authority="VIEW_EMR"/>
                <MenuItem label="Insurance" url="/insurance" authority="VIEW_INSURANCE"/>
                <MenuItem label="HR">
                    <MenuItem label="Apply For Leaves" url="/hr" authority="VIEW_HR_APPLY_LEAVE"/>
                    <MenuItem label="Manage Leave Requests" url="/hr/manageLeaveRequests" authority="VIEW_HR_MANAGE_LEAVE"/>
                    <MenuItem label="Leave Setup" url="/hr/leaveSetup" authority="VIEW_HR_MANAGE_LEAVE"/>
                    <MenuItem label="User Setup" url="/hr/userSetup" authority="VIEW_ALL_USERS"/>
                </MenuItem>
            </Menu>
        </div>
    )
};

export default withRouter(menu);

