import React, {Component} from "react";
import "../../visithistory/VisitHistory.css";
import DateUtil from "../../../../../utils/DateUtil";
import ReportViewUtils from "./ReportViewUtils";

export default class SurgicalHistoryReportView extends Component {
    render() {
        const surgicalHistory = this.props.surgicalHistory;

        if (ReportViewUtils.isSurgicalHistoryNull(surgicalHistory)) {
            return null;
        }

        if(surgicalHistory.none){
            return (<div>No Surgical History</div>)
        }

        return (
            <div style={{paddingLeft: 10}}>
                {surgicalHistory.items &&
                surgicalHistory.items.map(item =>
                    <div className={"row"}>
                        <div className={"col-3"}>
                            <b>{item.condition}</b>
                        </div>
                        <div className={"col-9"}>
                            :
                            {
                                [
                                    item.procedure != null ? ["Procedure:", item.procedure].join(' ') : null,
                                    item.date != null ? DateUtil.formatDate(item.date) : null,
                                    item.detail
                                ].filter(Boolean).join(", ")
                            }
                        </div>
                    </div>
                )}
            </div>
        );
    }

}
