import React, {Component} from 'react';
import moment from "moment";
import StringUtil from "../../utils/StringUtil";
import {TextInput, Wizard} from "../../components";
import RemotingService from "../../services/remoting-service/RemotingService";
import FormField from "../../components/form/FormField";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import LogoDubai from "../../layout/LogoDubai";
import SignatureInput from "../../components/signature-input/SignatureInput";
import PatientUtil from "../../utils/PatientUtil";
import FormLanguages from "./enum/FormLanguages";
import CancellationPolicyDxbTextEnum from "./cancellationpolicy/enum/CancellationPolicyDxbTextEnum";
import CancellationPolicyPartsDxb from "./cancellationpolicy/CancellationPolicyPartsDxb";

class CancellationPolicyDxb extends Component {

    constructor(props) {
        super(props)

        this.state = {
            consentDate: new Date()
        }
    }

    onSubmit = () => {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        if (this.props.form !== null && this.props.form !== undefined) {
            this.props.close();

        } else {
            let patient = this.props.patient;
            let patientFullName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
            let patientMrn = StringUtil.mrn(patient.mrn);
            const {consentDate, therapistName, guardianName, guardianRelationship, patientSignature} = this.state;

            RemotingService.postRemoteCall('api/patient/' + this.props.patient.id + '/cancellation-policy-dxb',
                {
                    consentDate, therapistName, guardianName,
                    guardianRelationship, patientSignature, patientFullName, patientMrn
                },
                (result) => {
                    this.props.afterSave(result);
                    this.props.close();
                });
        }
    }

    onSavePatientSignature = (signature) => {
        this.setState({patientSignature: signature})
    }

    render() {
        if (!this.props.show)
            return null;

        let {patient, form} = this.props;
        let readOnly = form !== null && form !== undefined;
        let mrnNo = StringUtil.mrn(patient.mrn);
        let patientName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
        this.formFields = [];

        const selectedLanguage = this.props.selectedLanguage.name;
        const formBodyClass = this.props.selectedLanguage == FormLanguages.ARABIC ? "arabic-form" : "";

        return (
            <Wizard
                show={this.props.show}
                close={this.props.close}
                title={CancellationPolicyDxbTextEnum.Cancellation_Policy_Wizard_Title[selectedLanguage]}
                onSubmit={this.onSubmit}
                isReadOnly={readOnly}
                previousBtnText={CancellationPolicyDxbTextEnum.Wizard_Previous[selectedLanguage]}
                nextBtnText={CancellationPolicyDxbTextEnum.Wizard_Next[selectedLanguage]}
                saveBtnText={CancellationPolicyDxbTextEnum.Wizard_Save[selectedLanguage]}
            >
                <Wizard.Page>
                    <div className={formBodyClass} style={{maxWidth: '950px'}}>
                        <div style={{
                            marginTop: '15px', marginBottom: '15px', width: '100%', display: 'flex',
                            flexFlow: 'row', justifyContent: 'space-between', alignItems: 'center'
                        }}>
                            <LogoDubai/>
                            <div>
                                <h2>{CancellationPolicyDxbTextEnum.Cancellation_Policy_Title[selectedLanguage]}</h2>
                            </div>
                        </div>
                        <div style={{textAlign: "right"}}>
                            <p><b>{CancellationPolicyDxbTextEnum.MRN[selectedLanguage]} :</b> {mrnNo}</p>
                        </div>

                        <hr style={{
                            borderTop: "2px solid #000000",
                            marginTop: "-15px",
                            boxShadow: "0px 2px 2px #797979"
                        }}/>

                        <CancellationPolicyPartsDxb.Part1 selectedLanguage={selectedLanguage}/>

                        <div style={{border: "1px solid #000000", borderRadius: "35px"}}>
                            <CancellationPolicyPartsDxb.Part2 selectedLanguage={selectedLanguage}/>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page>
                    <div className={formBodyClass} style={{maxWidth: '950px'}}>
                        <CancellationPolicyPartsDxb.Part3 selectedLanguage={selectedLanguage}
                                                                               patientName={patientName}/>

                        <div className={"container"}>
                            <div className={"row"} style={{marginBottom: "20px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    {CancellationPolicyDxbTextEnum.Date[selectedLanguage]}
                                </div>
                                <div className="col-md-3 dim14-base-input" style={{textAlign: "center"}}>
                                    {moment(readOnly ? new Date(form.consentDate) : this.state.consentDate).format('LL')}
                                </div>
                            </div>

                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    {CancellationPolicyDxbTextEnum.Legal_Guardian_Name[selectedLanguage]}
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    <TextInput value={readOnly ? form.guardianName : this.state.guardianName}
                                               disabled={readOnly} alphabeticOnly style={{textAlign: "center"}}
                                               onChange={(val) => this.setState({guardianName: val})}/>
                                </div>

                                <div className="col-md-3 dim14-form-label">
                                    {CancellationPolicyDxbTextEnum.Relationship_To_Patient[selectedLanguage]}
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    <TextInput
                                        value={readOnly ? form.guardianRelationship : this.state.guardianRelationship}
                                        disabled={readOnly} alphabeticOnly style={{textAlign: "center"}}
                                        onChange={(val) => this.setState({guardianRelationship: val})}/>
                                </div>
                            </div>

                            <div className="row" style={{marginTop: "15px"}}>
                                <div className="col-md-6 dim14-form-label">
                                    {CancellationPolicyDxbTextEnum.Signature_of_Patient[selectedLanguage]}
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <FormField ref={formField => this.formFields.push(formField)}
                                               disabled={readOnly}
                                               required requiredMessage="Signature required"
                                               validateOn={this.state.patientSignature}>
                                        <SignatureInput
                                            selectedLanguage={selectedLanguage}
                                            textEnum={CancellationPolicyDxbTextEnum}
                                            signature={readOnly ? form.patientSignature : this.state.patientSignature}
                                            onSave={this.onSavePatientSignature} readOnly={readOnly}/>
                                    </FormField>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>
            </Wizard>
        );
    }
}

export default CancellationPolicyDxb;
