import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Calendar} from "primereact/calendar";
import "./PrimeDateTimeInput.css"
import DateUtil from "../../utils/DateUtil";

class PrimeDateTimeInput extends Component {

    constructor(props) {
        super(props);
        this.calendarRef = React.createRef();
    }

    handleOnChange(event) {
        let date = event.value;
        if (!this.props.value && !!this.props.viewDate) {
            date.setHours(this.props.viewDate.getHours());
            date.setMinutes(this.props.viewDate.getMinutes());
            date.setSeconds(this.props.viewDate.getSeconds());
            date.setMilliseconds(this.props.viewDate.getMilliseconds());
        }
        this.props.onChange(date);
    }

    handleClear(event) {
        this.calendarRef.current.updateModel(event, null);
        this.calendarRef.current.updateInputfield(null);
        this.calendarRef.current.hideOverlay();
    }

    render() {
        const {disablePast, disableFuture, minDate, maxDate, clearable, style, inputStyle, ...rest} = this.props;

        const dialogContainer = document.getElementById("p-calendar-container");

        return <div className="dim14-calendar-container">
            <Calendar ref={this.calendarRef}
                      dateFormat="dd-M-yy"
                      yearRange={`${DateUtil.currentYear()-100}:${DateUtil.currentYear()+100}`}
                      readOnlyInput={true}
                      appendTo={dialogContainer}
                      panelClassName="dim14-calendar"
                      {...rest}
                      style={{width: 'inherit', ...style}}
                      inputStyle={{width: 'inherit', ...inputStyle}}
                      baseZIndex={1400}
                      showTime={true} monthNavigator={true} yearNavigator={true}
                      minDate={disablePast ? DateUtil.now().toDate() : minDate}
                      maxDate={disableFuture ? DateUtil.now().toDate() : maxDate}
                      onChange={event => this.handleOnChange(event)}
            />
            {(clearable && this.props.value) ?
                <FontAwesomeIcon className="dim14-calendar-clear-icon"
                                 icon={["fas", "times"]}
                                 size={"sm"}
                                 onClick={(event) => this.handleClear(event)}/>
                : null}
        </div>
    }

}

export default PrimeDateTimeInput;