import React, {Component} from "react";
import EnumLeaveType from "./EnumLeaveType";
import EnumLeaveStatus from "./EnumLeaveStatus";
import {isEmpty} from "../../utils/ArrayUtil";
import {OverlayTrigger, Popover} from "react-bootstrap";
import EnumLeaveErrorCode from "./EnumLeaveErrorCode";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tooltip} from "@material-ui/core";
import EnumExtraDayType from "./EnumExtraDayType";

export default class LeaveEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showPreview: false
        }
    }

    render() {
        const {event} = this.props;
        const previewEnabled = this.myDiv?.offsetWidth < 100;

        return (
            <div className={"leave-event leave-" + event.type + "-" + event.id}
                 style={{
                     backgroundColor: EnumLeaveType[event.type].bgColor
                 }}
                 ref={el => this.myDiv = el}
                 onMouseEnter={() => this.setState({showPreview: true})}
                 onMouseLeave={() => this.setState({showPreview: false})}
                 onMouseMove={e => this.setState({mouseX: e.clientX, mouseY: e.clientY})}
            >
            <div className={"d-flex align-items-start day-content"}>
    <div className={"d-flex flex-column align-items-center"}>



                <div>
                    <span className={"day-content-header"}>{EnumLeaveType[event.type].name.toUpperCase()}</span>
                    <div className="ml-5 mt-1">
                        {event.extraDayType === EnumExtraDayType.EXTRA_PAY.key && <FontAwesomeIcon icon={["fas", "dollar-sign"]} style={{color: EnumLeaveType.EXTRA.textColor}}/>}
                        {event.extraDayType === EnumExtraDayType.FLEXIBLE.key && <FontAwesomeIcon icon={["fas", "clock"]} style={{color: EnumLeaveType.EXTRA.textColor}}/>}
                    </div>
                </div>
                {event.type !== "CLOSED" ? <div class={EnumLeaveStatus[event.status].class}>{EnumLeaveStatus[event.status].name}</div> : null}
                {event.type === "CLOSED" && !isEmpty(event.comments) ? <div>{event.comments[0].comment}</div> : null}
                <span className={"mb-2"}>{event.staffName}</span>
                {(event.errors && event.errors.length) ?
                    <OverlayTrigger key="errorsTrigger" trigger={["hover"]} placement="top-start"
                                    overlay={(props) =>
                                        <Popover {...props} id="errorsPopover">
                                            <Popover.Content style={{backgroundColor: '#efd3d6'}}>
                                                <div style={{color: '#dc3545', fontSize: 11, paddingLeft: 5}}>
                                                    {event.errors.map((error, index) =>
                                                        <li key={index}>{EnumLeaveErrorCode[error].name}</li>)}
                                                </div>
                                            </Popover.Content>
                                        </Popover>}>
                        <FontAwesomeIcon icon={["fas", "exclamation-circle"]}
                                         style={{color: "#dc3545", width: 50}}/>
                    </OverlayTrigger>
                    : <></>}
                <br/>
                <Tooltip open={previewEnabled && this.state.showPreview}
                         PopperProps={{
                             anchorEl: {
                                 clientHeight: 0,
                                 clientWidth: 0,
                                 getBoundingClientRect: () => ({
                                     top: this.state.mouseY,
                                     left: this.state.mouseX,
                                     right: this.state.mouseX,
                                     bottom: this.state.mouseY,
                                     width: 0,
                                     height: 0,
                                 })
                             }
                         }}
                         title={<div style={{width: 150, height: 100}}>
                             <LeaveEvent event={event}/>
                         </div>}
                >
                    <div/>
                </Tooltip>
    </div>
            </div>
            </div>
        )
    }

}