import React, {Component} from 'react';
import PropTypes from "prop-types";
import {fabric} from "fabric"
import "./BodySilhouette.css";

class BodySilhouetteStaffViewCanvas extends Component {

    static defaultProps = {
        width: 692,
        height: 575,
        zoom: 1,
    }

    static propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        zoom: PropTypes.number,
    }

    state = {
        canvas: null
    }

    constructor(props) {
        super(props);
        this.screenRatio = window.innerWidth / 1920;
    }

    componentDidMount() {
        this.initializeCanvas();
    }

    initializeCanvas() {
        const canvas = new fabric.StaticCanvas(this.c);
        canvas.setBackgroundImage(process.env.PUBLIC_URL + '/assets/forms/4body.png', canvas.renderAll.bind(canvas));
        canvas.setZoom(this.props.zoom * this.screenRatio);
        canvas.selection = false;

        this.setState({canvas})
    }

    clearCanvas = () => {
        this.state.canvas.remove(...this.state.canvas.getObjects());
    }

    render() {
        if(this.state.canvas != null && !this.props.dashboardView){
            this.clearCanvas();
        }

        let children = React.Children.map(this.props.children, child => {
            return child ? React.cloneElement(child, {
                canvas: this.state.canvas,
                selectable: true
            }) : null;
        });

        const width = this.props.width * this.screenRatio;
        const height = this.props.height * this.screenRatio;

        const divStyle = {textAlign: "center"}
        return (
            <div style={{...divStyle}}>
                <canvas ref={c => (this.c = c)} width={width} height={height} disabled={true}/>
                {this.state.canvas != null && children}
            </div>
        )
    }
}

export default BodySilhouetteStaffViewCanvas;
