import React, {Component} from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import RemotingService from '../../../services/remoting-service/RemotingService';
import StaffProfileCompletion from './topbar/StaffProfileCompletion';
import StaffProfileLeftPart from './leftpanel/StaffProfileLeftPart';
import StaffProfileTopBar from './topbar/StaffProfileTopBar';
import "./StaffProfile.css"
import StaffProfileSkills from "./middlepanel/StaffProfileSkills";
import StaffPreference from "./preference/StaffPreference";
import StaffProfileBiography from "./middlepanel/StaffProfileBiography";
import StaffProfileSpecializedConditions from "./middlepanel/StaffProfileSpecializedConditions";
import AuthService from "../../../services/auth-service/AuthService";
import dialog from "../../../components/dialog/Dialog";
import ReplaceProviderModal from "./modal/ReplaceProviderModal";

class StaffProfile extends Component {

    constructor(props) {
        super(props);

        this.state = {
            editable: false,
            editableByAdmin: false,
            staffDTO: null,
            nationalities: null,
            staffPreference: this.createDummyPreference(),
            replacementStaffList: []
        };
    }

    createDummyPreference() {
        return {dayPreferences: []};
    }

    componentDidMount() {
        this.retrieveStaffData();
        this.retrieveNationalities();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let staffId = this.props.match.params.staffId;
        if(prevProps.match.params.staffId != staffId){
            window.location.reload(false);
        }
    }

    onStaffProfileUpdate = () => {
        this.retrieveStaffData();
    }

    retrieveStaffData = () => {
        this.retrieveStaff();
        this.retrieveStaffPreferences();
    }

    retrieveStaff = () => {
        let id = this.props.match.params.staffId;
        RemotingService.getRemoteCall('/api/staff/' + id, null, (staffDTO) => {
            this.setState({
                staffDTO,
                editable: AuthService.userHasAuthority("UPDATE_ANY_PROVIDER")
                    || (AuthService.userHasAuthority("UPDATE_SELF_PROVIDER") && AuthService.getUser().staffId === staffDTO.id),
                editableByAdmin: AuthService.userHasAuthority("UPDATE_ANY_PROVIDER")
            });
            this.retrieveReplacementStaffListByClinic();
        });
    }

    retrieveNationalities = () => {
        RemotingService.getRemoteCall('api/reference/nationality', null, (nationalities) => {
            this.setState({...this.state, nationalities: nationalities})
        });
    };

    retrieveStaffPreferences = () =>{
        let id = this.props.match.params.staffId;
        RemotingService.getRemoteCall(`api/staff/${id}/preference`, null, staffPreference => {
            staffPreference = staffPreference || this.createDummyPreference();
            this.setState({staffPreference: staffPreference});
        });
    }

    handleReplaceProviderClick = () => {
        dialog({
            title: "Replace Provider",
            component: <ReplaceProviderModal staffId={this.props.match.params.staffId}
                                             replacementStaffList={this.state.replacementStaffList}/>
        });
    }

    retrieveReplacementStaffListByClinic = () => {
        let clinicIdList = this.state.staffDTO.departments.map(department => department.clinic.id);
        RemotingService.getRemoteCall('api/staff/list-base', {clinicIds: clinicIdList}, (result) => {
            let replacementStaffList = result.items.filter(staff => staff.id !== this.state.staffDTO.id)
            this.setState({replacementStaffList})
        });
    }

    render() {
        const {staffDTO, editable, editableByAdmin} = this.state;
        return staffDTO && (
            <>
                <StaffProfileTopBar staffFullName={staffDTO.name} handleReplaceProviderClick={this.handleReplaceProviderClick}/>
                <Container style={{maxWidth: '98%'}}>
                    <Row>
                        <Col lg={6} xl={3}>
                            <StaffProfileLeftPart staff={staffDTO} editable={editable} editableByAdmin={editableByAdmin}
                                                  nationalities={this.state.nationalities}
                                                  onStaffProfileUpdate={this.onStaffProfileUpdate}/>
                            <StaffProfileCompletion staff={staffDTO} staffPreference={this.state.staffPreference}/>
                        </Col>
                        <Col lg={6} xl={9}>
                            <StaffProfileBiography staffId={staffDTO.id} editable={editable}
                                                   biography={staffDTO.biography}
                                                   onStaffProfileUpdate={this.onStaffProfileUpdate}/>
                            <StaffProfileSkills skills={staffDTO.skills} editable={editable} staffId={staffDTO.id}
                                                onStaffProfileUpdate={this.onStaffProfileUpdate}/>
                            <StaffProfileSpecializedConditions specializedConditions={staffDTO.specializedConditions}
                                                               editable={editable} staffId={staffDTO.id}
                                                               onStaffProfileUpdate={this.onStaffProfileUpdate}/>
                            <StaffPreference staffId={staffDTO.id} staffName={staffDTO.name} staffType={staffDTO.staffType} onStaffProfileUpdate={this.onStaffProfileUpdate}/>
                        </Col>
                    </Row>
                </Container>
            </>);
    }
}

export default StaffProfile;