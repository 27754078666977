import React, {Component} from 'react';
import Logo from "../../../layout/Logo";
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import DateUtil from "../../../utils/DateUtil";

class IntakeFormHeader extends Component {
    render() {
        const {selectedLanguage, photoSrc, mrnNo, title, consentDate} = this.props;
        let headerClass = selectedLanguage === "Arabic"
            ? "arabic-form d-flex justify-content-between align-items-center"
            : "d-flex justify-content-between align-items-center";
        return (
            <>
                <div className="d-flex justify-content-between align-items-center mt-3 w-100">
                    <Logo/>
                    <img
                        className="profile-image"
                        src={photoSrc}
                        alt=""
                        style={{height: "100px"}}
                    />
                    <div>
                        <h2>{title}</h2>
                    </div>
                </div>
                <div className={headerClass}>
                    <p className="mb-0">
                        <b>{IntakeFormTextEnum.Date[selectedLanguage]} :</b><span dir="ltr" >{DateUtil.formatDateMMM(consentDate)}</span>
                    </p>
                    <p className="mb-0">
                        <b>{IntakeFormTextEnum.MRN[selectedLanguage]} :</b> {mrnNo}
                    </p>
                </div>
                <hr className="header-line mt-0"/>
            </>
        );
    }
}

export default IntakeFormHeader;
