import React, {Component} from "react";
import '../../EMR.css';
import FunctionEnum from "../enums/functionalTesting/FunctionEnum";
import ResultEnum from "../enums/functionalTesting/ResultEnum";

export default class FunctionalTestingView extends Component {

    render() {
        if (this.props.functionalTesting == null
            || this.props.functionalTesting.items == null
            || this.props.functionalTesting.items.length === 0) {
            return null;
        }

        return (
            <div>
                <div className={"content-card"}>
                    <table className={"emr-table"}>
                        <tbody>
                        <tr>
                            <th>Function</th>
                            <th>Result</th>
                            <th>Detail</th>
                        </tr>
                        {this.props.functionalTesting.items.map((item, index) => {
                            return (<tr key={"functionalTestingView" + index} style={{backgroundColor: "white"}}>
                                <td>{FunctionEnum[item.function].name}</td>
                                <td>{ResultEnum[item.result].name}</td>
                                <td>{item.detail}</td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </div>
            </div>);
    }
}