import validator from 'validator';

const StringUtil = validator;
/* https://www.npmjs.com/package/validator#validators */

StringUtil.pad = (n, width, z) => {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

StringUtil.mrn = (mrn) => {
    if (!mrn) {
        return "-";
    }
    const {mrnNo, country} = mrn;

    const paddedMRN = StringUtil.pad(mrnNo, 6);
    switch (country) {
        case 'UAE':
            return 'UAE' + paddedMRN;
        case 'KUWAIT':
            return 'KWI' + paddedMRN;
        default:
            return paddedMRN;
    }
};

StringUtil.shorten = (str, length) => {
    if(str) {
        if(str.length > length) {
            return str.substring(0, length) + '...';
        } else {
            return str;
        }
    }

    return "";

};

StringUtil.isNullOrEmpty = (str) => {
    return str == null || str == "";
}

StringUtil.getFileExtension = (fileName) => {
    const regExp = /(?:\.([^.]+))?$/;
    const fileExtension = regExp.exec(fileName)[1];

    return fileExtension;
}

StringUtil.camelCaseToWords = (str) => {
    if (str === null || str === undefined || str === "") {
        return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1).replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1");
}

export default StringUtil;
