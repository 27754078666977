import DateUtil from "../../../utils/DateUtil";

export default class MinDateValidator {

    getErrorMessage(prop) {
        return "Field should be after than or equal to " + DateUtil.formatDateMMM(prop);
    }

    validate(value, prop) {
        if (value === null || value === undefined) {
            return true;
        }
        if (prop === null || prop === undefined) {
            return true;
        }

        return DateUtil.isSameOrAfter(value, prop);
    }

}