import React, {Component} from "react";
import SeverityEnum from "../../assessment/enums/observation/SeverityEnum";
import ObservationFindingEnum from "../../assessment/enums/observation/ObservationFindingEnum";
import PalpationLocationEnum from "../../assessment/enums/palpation/PalpationLocationEnum";
import PalpationFindingEnum from "../../assessment/enums/palpation/PalpationFindingEnum";
import MuscleGroupsEnum from "../../assessment/enums/rangeofmotion/MuscleGroupsEnum";
import LocationEnum from "../../assessment/enums/rangeofmotion/LocationEnum";
import GradeEnum from "../../assessment/enums/rangeofmotion/GradeEnum";
import IndividualMusclesEnum from "../../assessment/enums/rangeofmotion/IndividualMusclesEnum";
import DermatomesDropdownValues from "../../assessment/enums/dermatomes/DermatomesDropdownValues";
import MyotomesDropdownValues from "../../assessment/enums/dermatomes/MyotomesDropdownValues";
import DermatomesSacralEnum from "../../assessment/enums/dermatomes/DermatomesSacralEnum";
import DermatomesThoracicEnum from "../../assessment/enums/dermatomes/DermatomesThoracicEnum";
import DermatomesLumbarEnum from "../../assessment/enums/dermatomes/DermatomesLumbarEnum";
import DermatomesCervicalEnum from "../../assessment/enums/dermatomes/DermatomesCervicalEnum";
import MyotomesCervicalEnum from "../../assessment/enums/dermatomes/MyotomesCervicalEnum";
import MyotomesThoracicEnum from "../../assessment/enums/dermatomes/MyotomesThoracicEnum";
import MyotomesLumbarEnum from "../../assessment/enums/dermatomes/MyotomesLumbarEnum";
import MyotomesSacralEnum from "../../assessment/enums/dermatomes/MyotomesSacralEnum";
import MovementEnum from "../../assessment/enums/rangeofmotion/MovementEnum";
import ReportViewUtils from "./ReportViewUtils";
import * as ArrayUtils from "../../../../../utils/ArrayUtil";
import StringUtil from "../../../../../utils/StringUtil";
import MuscleStrengthResultEnum from "../../assessment/enums/rangeofmotion/MuscleStrengthResultEnum";

export default class AssessmentReportView extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    getObservationSummary = (observation) => {
        if (!observation || (ArrayUtils.isEmpty(observation.items) && observation.none == false)) {
            return null;
        }

        if(observation.none){
            return "Normal";
        }

        return observation.items
            .map(item =>
                [
                    (item.finding == null ? null : ObservationFindingEnum[item.finding].name),
                    (item.severity == null ? null : SeverityEnum[item.severity].name),
                    item.location,
                    (item.details == null ? null : item.details)
                ].filter(Boolean).join(", ")
            )
            .join('. ');
    }

    getPalpationSummary = (palpation) => {
        if (!palpation || (ArrayUtils.isEmpty(palpation.items) && palpation.none == false)) {
            return null;
        }

        if(palpation.none){
            return "Normal";
        }

        return palpation.items
            .map(item =>
                [
                    item.area,
                    [
                        item.locationRight != null ? ("Right-" + PalpationLocationEnum[item.locationRight]?.name) : null,
                        item.locationLeft != null ? ("Left-" + PalpationLocationEnum[item.locationLeft]?.name) : null
                    ].filter(Boolean).join(", "),
                    item.finding != null ? PalpationFindingEnum[item.finding].name : null,
                    item.pain ? item.pain + '/10' : null,
                    (StringUtil.isNullOrEmpty(item.detail) ? null : item.detail)
                ].filter(Boolean).join(", ")
            )
            .join('. ');
    }

    getMuscleGroupSummary = (muscleGroup) => {
        if (!muscleGroup || (ArrayUtils.isEmpty(muscleGroup.items) && muscleGroup.none == false)) {
            return null;
        }

        if(muscleGroup.none){
            return "Normal";
        }

        return muscleGroup.items
            .map(item =>
                [
                    (item.muscle ? MuscleGroupsEnum[item.muscle].name : null),
                    (item.location ? LocationEnum[item.location].name : null),
                    (item.result ? MuscleStrengthResultEnum[item.result].name : null),
                    (item.grade ? GradeEnum[item.grade].name : null),
                    (item.details ? item.details : null)
                ].filter(Boolean).join(" ")
            )
            .join('. ');
    }

    getIndividualMuscleSummary = (individualMuscle) => {
        if (!individualMuscle || (ArrayUtils.isEmpty(individualMuscle.items) && individualMuscle.none == false)) {
            return null;
        }

        if(individualMuscle.none){
            return "Normal";
        }

        return individualMuscle.items
            .map(item =>
                [
                    (item.muscle ? IndividualMusclesEnum[item.muscle].name : null),
                    (item.location ? LocationEnum[item.location].name : null),
                    (item.result ? MuscleStrengthResultEnum[item.result].name : null),
                    (item.grade ? GradeEnum[item.grade].name : null),
                    (item.details ? item.details : null)
                ].filter(Boolean).join(" ")
            )
            .join('. ');
    }

    getDermatomesSummary = (dermatomes) => {
        if (!dermatomes || (ArrayUtils.isEmpty(dermatomes.items) && dermatomes.none == false)) {
            return null;
        }

        if(dermatomes.none){
            return "Normal";
        }

        return dermatomes.items
            .map(item =>
                [
                    (item.name == null ? null :
                        (DermatomesCervicalEnum[item.name] || DermatomesThoracicEnum[item.name] ||
                            DermatomesLumbarEnum[item.name] || DermatomesSacralEnum[item.name])['value']),
                    DermatomesDropdownValues[item.rightValue]?.name,
                    DermatomesDropdownValues[item.leftValue]?.name,
                    item.details
                ].filter(Boolean).join(" ")
            )
            .join('. ');
    }

    getMyotomesSummary = (mytomes) => {
        if (!mytomes || (ArrayUtils.isEmpty(mytomes.items) && mytomes.none == false)) {
            return null;
        }

        if(mytomes.none){
            return "Normal";
        }

        return mytomes.items
            .map(item =>
                [
                    (item.name == null ? "" :
                        (MyotomesCervicalEnum[item.name] || MyotomesThoracicEnum[item.name] ||
                            MyotomesLumbarEnum[item.name] || MyotomesSacralEnum[item.name])['value']),
                    MyotomesDropdownValues[item.rightValue]?.name,
                    MyotomesDropdownValues[item.leftValue]?.name,
                    item.details
                ].filter(Boolean).join(" ")
            )
            .join('. ');
    }

    getRangeOfMotion = (rangeOfMotion) => {
        if (!rangeOfMotion || (ArrayUtils.isEmpty(rangeOfMotion.items) && rangeOfMotion.none == false)) {
            return null;
        }

        if(rangeOfMotion.none){
            return "Normal";
        }

        return rangeOfMotion.items
            .map(item =>
                (<>
                    <div className={"col-2"}>
                        <b>{(item.area + " " + (item.movement == null && item.movementComment == null ?
                            "" :
                            MovementEnum[item.movement] != null ?
                                MovementEnum[item.movement].name :
                                item.movementComment)
                        )}</b>
                    </div>
                    <div className={"col-10"}>
                        {this.isRangeOfMotionSideChanged(item.leftDetails, item.leftEndFeel,
                            item.leftQuality, item.leftPain, item.leftAROM, item.leftPROM) ? ": Left: " +
                            [
                                (item.leftPROM == null ? null : "PROM: " + item.leftPROM),
                                (item.leftAROM == null ? null : "AROM: " + item.leftAROM),
                                (item.leftPain == null ? null : "Pain: " + item.leftPain),
                                (item.leftQuality == null ? null : "Quality: " + item.leftQuality),
                                (item.leftEndFeel == null ? null : "End Feel: " + item.leftEndFeel),
                                (item.leftDetails == null ? null : "Details: " + item.leftDetails),
                            ].filter(Boolean).join(", ")
                        : ""}

                        {this.isRangeOfMotionSideChanged(item.leftDetails, item.leftEndFeel,
                            item.leftQuality, item.leftPain, item.leftAROM, item.leftPROM) ? (<><br/>&nbsp;&nbsp;</>) : ": "}

                        {this.isRangeOfMotionSideChanged(item.rightDetails, item.rightEndFeel,
                            item.rightQuality, item.rightPain, item.rightAROM, item.rightPROM) ? "Right: " +
                            [
                                (item.rightPROM == null ? null : "PROM: " + item.rightPROM),
                                (item.rightAROM == null ? null : "AROM: " + item.rightAROM),
                                (item.rightPain == null ? null : "Pain: " + item.rightPain),
                                (item.rightQuality == null ? null : "Quality: " + item.rightQuality),
                                (item.rightEndFeel == null ? null : "End Feel: " + item.rightEndFeel),
                                (item.rightDetails == null ? null : "Details: " + item.rightDetails),
                            ].filter(Boolean).join(", ")
                            : ""}
                    </div>
                </>))
    }

    isRangeOfMotionSideChanged = (details, endFeel, quality, pain, AROM, PROM) => {
        return (details != null || endFeel != null
            || quality != null || pain != null
            || AROM != null || PROM != null)
    }

    render() {
        const assessment = this.props.assesment;

        return (
            <div className={"container ml-0"}>
                {!ReportViewUtils.isAssessmentEntityNull(assessment.observation) && <div className={"row"}>
                    <div className={"col-2"}>
                        <b>Observation</b>
                    </div>
                    <div className={"col-10"}>
                        : {this.getObservationSummary(assessment.observation)}
                    </div>
                </div>}
                {!ReportViewUtils.isAssessmentEntityNull(assessment.palpation) && <div className={"row"}>
                    <div className={"col-2"}>
                        <b>Palpation</b>
                    </div>
                    <div className={"col-10"}>
                        : {this.getPalpationSummary(assessment.palpation)}
                    </div>
                </div>}
                {!ReportViewUtils.isAssessmentEntityNull(assessment.muscleGroup) && !ReportViewUtils.isAssessmentEntityNull(assessment.individualMuscle) &&
                <>
                    <br/>
                    <div className={"row"}>
                        <b><u>Muscle Strength</u></b>
                    </div>
                </>}
                {!ReportViewUtils.isAssessmentEntityNull(assessment.muscleGroup) && <div className={"row"}>
                    <div className={"col-2"}>
                        <b>Muscle Group</b>
                    </div>
                    <div className={"col-10"}>
                        : {this.getMuscleGroupSummary(assessment.muscleGroup)}
                    </div>
                </div>}
                {!ReportViewUtils.isAssessmentEntityNull(assessment.individualMuscle) && <div className={"row"}>
                    <div className={"col-2"}>
                        <b>Individual Muscle</b>
                    </div>
                    <div className={"col-10"}>
                        : {this.getIndividualMuscleSummary(assessment.individualMuscle)}
                    </div>
                </div>}
                {!ReportViewUtils.isAssessmentEntityNull(assessment.rangeOfMotion) && <>
                    <br/>
                    <div className={"row"}>
                        <b><u>Range Of Motion</u></b>
                    </div>
                </>}
                {!ReportViewUtils.isAssessmentEntityNull(assessment.rangeOfMotion) && <div className={"row"}>
                    {this.getRangeOfMotion(assessment.rangeOfMotion)}
                </div>}
                {!ReportViewUtils.isAssessmentEntityNull(assessment.dermatomes) && <div className={"row"}>
                    <div className={"col-2"}>
                        <b>Dermatomes</b>
                    </div>
                    <div className={"col-10"}>
                        : {this.getDermatomesSummary(assessment.dermatomes)}
                    </div>
                </div>}
                {!ReportViewUtils.isAssessmentEntityNull(assessment.myotomes) && <div className={"row"}>
                    <div className={"col-2"}>
                        <b>Myotomes</b>
                    </div>
                    <div className={"col-10"}>
                        : {this.getMyotomesSummary(assessment.myotomes)}
                    </div>
                </div>}
            </div>
        );
    }

}
