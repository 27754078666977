import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Calendar} from "primereact/calendar";
import "./PrimeDateTimeInput.css"
import DateUtil from "../../utils/DateUtil";

class PrimeDateInput extends Component {

    constructor(props) {
        super(props);
        this.calendarRef = React.createRef();
    }

    handleOnChange(event) {
        const utcDate = DateUtil.getUtcDateAtStartOfDay(event.value);
        this.props.onChange(utcDate);
    }

    handleClear(event) {
        this.calendarRef.current.updateModel(event, null);
        this.calendarRef.current.updateInputfield(null);
        this.calendarRef.current.hideOverlay();
    }

    render() {
        const {disablePast, disableFuture, minDate, maxDate, clearable, showCalendarIcon, style, inputStyle, containerStyle, ...rest} = this.props;

        const dialogContainer = document.getElementById("p-calendar-container");

        return <div className="dim14-calendar-container" style={containerStyle}>
            <Calendar ref={this.calendarRef}
                      dateFormat="dd-M-yy"
                      yearRange={`${DateUtil.currentYear()-100}:${DateUtil.currentYear()+100}`}
                      readOnlyInput={true}
                      appendTo={dialogContainer}
                      panelClassName="dim14-calendar"
                      {...rest}
                      style={{width: 'inherit', ...style}}
                      inputStyle={{width: 'inherit', ...inputStyle}}
                      baseZIndex={1400}
                      showTime={false} monthNavigator={true} yearNavigator={true}
                      minDate={disablePast ? DateUtil.now().toDate() : minDate}
                      maxDate={disableFuture ? DateUtil.now().toDate() : maxDate}
                      onChange={event => this.handleOnChange(event)}
            />

            {(showCalendarIcon && !(clearable && this.props.value)) ? <FontAwesomeIcon className="dim14-calendar-calendar-icon"
                                                 icon={["fas", "calendar-alt"]}
                                                 size={"sm"} /> : null}

            {(clearable && this.props.value) ?
                <FontAwesomeIcon className="dim14-calendar-clear-icon"
                                 icon={["fas", "times"]}
                                 size={"sm"}
                                 onClick={(event) => {this.handleClear(event)}}/> : null}
        </div>
    }

}

export default PrimeDateInput;