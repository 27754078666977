import React from 'react';
import {Card} from 'react-bootstrap';
import ReactTagInput from "@pathofdev/react-tag-input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './ReactTagInput.scss'
import dialog from "../../../../components/dialog/Dialog";
import EditSkillModal from "../modal/EditSkillModal";

class StaffProfileSkills extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            skills: props.skills
        };
    }

    render() {
        return (
            <>
                <Card className="StaffMedium" style={{width: '100%'}}>
                    <Card.Header style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            Skills
                        </div>
                        <div>
                            {(this.props.editable &&
                                <FontAwesomeIcon icon={["fas", "edit"]}
                                                 onClick={this.handleEditSkills}/>
                            )}
                        </div>
                    </Card.Header>
                    <Card.Body style={{display: 'flex', margin: 'auto'}}>
                        <Card.Text>
                            <div>
                                <ReactTagInput
                                    tags={this.state.skills.map(skill => skill.name)}
                                    readOnly
                                />
                            </div>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </>
        )
    }

    handleEditSkills = () => {
        dialog({
            title: "Skills",
            component: <EditSkillModal staffId={this.props.staffId}
                                       skills={this.state.skills}
                                       onSkillsUpdated={skills => this.onSkillsUpdated(skills)}/>
        });
    };

    onSkillsUpdated(skills) {
        this.setState({skills});
        this.props.onStaffProfileUpdate();
    }

}

export default StaffProfileSkills;