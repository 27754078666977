import React, {Component} from "react";
import '../../../EMR.css';

export default class TreatmentPlanComplianceHistoryView extends Component {

    render() {
        const compliance = this.props.treatmentPlanCompliance;

        if (!compliance || (compliance.value == null && !compliance.details)) {
            return <div className="pl-3">No information available</div>;
        }

        return (
            <div className="pl-3">
                {(compliance.value !== null || compliance.details) &&
                <div>
                    {compliance.value !== null && compliance.value > 0 ? compliance.value : "0"}% {compliance.details ? "- " + compliance.details : ""}
                </div>}
            </div>
        );
    }
}
