import Enum from "../../../enums/Enum";

const EnumStaffType = new Enum({
    SUPER_USER: {name: "Super User"},
    DOCTOR: {name: "Medical Doctor"},
    THERAPIST: {name: "Therapist"},
    NURSE: {name: "Nurse"},
    ADMIN: {name: "Receptionist"},
    PHYSICIAN: {name: "Physician"},
    CLINIC_MANAGER: {name: "Clinic Manager"},
    MEDICAL_DIRECTOR: {name: "Medical Director"},
    MANAGEMENT: {name: "Management"}
});

EnumStaffType.selectableTypes = [
    EnumStaffType.DOCTOR,
    EnumStaffType.THERAPIST,
    EnumStaffType.NURSE,
    EnumStaffType.ADMIN,
    EnumStaffType.PHYSICIAN,
    EnumStaffType.CLINIC_MANAGER,
    EnumStaffType.MEDICAL_DIRECTOR,
    EnumStaffType.MANAGEMENT
];

export default EnumStaffType;