import React, {Component} from "react";
import '../Assessment.css';
import '../../EMR.css';
import TextInput from "../../../../../components/text-input/TextInput";

import GreenSwitchWithEmptyMessage from "../../../../../components/switch/GreenSwitchWithEmptyMessage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import RemotingService from "../../../../../services/remoting-service/RemotingService";
import TextareaInput from "../../../../../components/textarea-input/TextareaInput";
import ExaminationEnum from "../enums/examination/ExaminationEnum";
import CheckboxInput from "../../../../../components/checkbox-input/CheckboxInput";

export default class MSKDiagnosisModal extends Component {
    state = {
        presentingComplaint: null,
        examination:null,
        examinationComment:null,
        leftBool: false,
        rightBool:false,
        notApplicableBool:false,
        findings:null,
        impression:null,
        recommendations:null,
        icd10Code: {
            code: null,
            description: null
        }
    };

    constructor(props) {
        super(props);
        this.MINIMUM_SEARCH_LENGTH = 2;
        this.state.MSKDiagnosis = props.MSKDiagnosis;
        this.customStyles = {
            option: (provided, state) => ({
                ...provided,
                textAlign:'left',
                whiteSpace:'nowrap',

            }),

            menu:(provided, state)=>({
                ...provided,
                position:'absolute',
                width:'100wv',

            }),
            menuList: (provided, state) => ({
                ...provided,


            }),


        }
    }

    render() {
        let MSKDiagnosisDivs = this.getMSKDiagnosisDivs();

        return (
            <div className={"px-4 content-row emr-background"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px"}}>
                    <div>
                        <div className={"row"}>
                                <div className="content-label font-weight-bold" style={{margin:"auto", marginLeft:"0px"}}>MSK Diagnostic Ultrasound</div>
                                <GreenSwitchWithEmptyMessage
                                    checked=
                                        {
                                            this.props.assessment.mskDiagnosis != null
                                            && (this.props.assessment.mskDiagnosis.items != null
                                                && this.props.assessment.mskDiagnosis.items.length != 0 )
                                         }
                                    onChange={() => {
                                        if(this.props.assessment.mskDiagnosis == null) {
                                            this.props.assessment.mskDiagnosis = {};
                                            this.props.assessment.mskDiagnosis.items = [];

                                        } else {
                                            this.props.assessment.mskDiagnosis = null;
                                        }
                                        this.setState({
                                            presentingComplaint: null,
                                            examination: null,
                                            findings: null,
                                            impression: null,
                                            recommendations: null,
                                            icd10Code: {
                                                code:null,
                                                description:null
                                            }
                                        });
                                        this.forceUpdate();
                                    }}
                                />
                        </div>
                        <div className={"row MSKTextboxTitleDiv"}>
                            <div className={"col-4 MSKTextboxTitle"}>
                                Presenting Complaint
                            </div>
                            <div className={"col-4 MSKTextboxTitle"}>
                                Examination
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-4"}>
                                <TextInput value={this.state.presentingComplaint || ''}
                                           placeholder={"Presenting Complaint"}
                                           className={"MSKTextBox"}
                                           onChange={value =>
                                               {
                                                   this.setState(state => {
                                                       state.presentingComplaint = value;
                                                       return state;
                                                   })
                                               }
                                           }
                                />
                            </div>
                            <div className={"col-2"}>
                                <AsyncSelect
                                    className={"emr-in-table-select-container"}
                                    classNamePrefix={"emr-in-table-select"}
                                    styles={this.customStyles}
                                    placeholder={"Examination"}
                                    noOptionsMessage={this.noOptionsMessage.bind(this)}
                                    loadOptions={this.getExaminationOptions}
                                    menuPlacement={"bottom"}
                                    value={this.state.examination != null || this.state.examinationComment != null ?
                                        (ExaminationEnum[this.state.examination] != null ?
                                            {
                                                value: ExaminationEnum[this.state.examination].key,
                                                label: ExaminationEnum[this.state.examination].name
                                            } :
                                            {
                                                value: this.state.examinationComment,
                                                label: this.state.examinationComment
                                            }) : null}
                                    onChange={(event) => {
                                        if (ExaminationEnum[event.value] != null) {
                                            this.state.examination = event.value;
                                            this.state.examinationComment = null;
                                        } else {
                                            this.state.examinationComment = event.value;
                                            this.state.examination = null;
                                        }
                                        this.forceUpdate();
                                    }}
                                />
                            </div>
                            <div className={"col-1"} style={{display: "flex", marginLeft: "5px"}}>
                                <CheckboxInput value={this.state.leftBool}
                                               onChange={(value) => {
                                                   this.state.leftBool = value;
                                                   this.state.rightBool = false;
                                                   this.state.notApplicableBool = false;
                                                   this.forceUpdate();
                                               }}/>
                                <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Left</div>
                            </div>
                            <div className={"col-1"} style={{display: "flex", marginLeft: "2px"}}>
                                <CheckboxInput value={this.state.rightBool}
                                               onChange={(value) => {
                                                   this.state.rightBool = value;
                                                   this.state.leftBool = false;
                                                   this.state.notApplicableBool = false;
                                                   this.forceUpdate();
                                               }}/>
                                <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Right</div>
                            </div>
                            <div className={"col-3"} style={{display: "flex", marginLeft: "5px"}}>
                                <CheckboxInput value={this.state.notApplicableBool}
                                               onChange={(value) => {
                                                   this.state.notApplicableBool = value;
                                                   this.state.rightBool = false;
                                                   this.state.leftBool = false;
                                                   this.forceUpdate();
                                               }}/>
                                <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Not Applicable</div>
                            </div>
                        </div>
                        <div className={"row  MSKTextboxTitleDiv"}>
                            <div className={"col-6 MSKTextboxTitle"}>
                            Findings
                            </div>
                        </div>
                        <div className={"row MSKFindingsdiv"}>
                            <div className={"col-12"}>
                                <TextareaInput
                                           value={this.state.findings  || ''}
                                           placeholder={"Findings"}
                                           className ={"MSKFindingsTextBox"}
                                           style={{maxHeight: "14px", width: "98%",minHeight: "30px"}}
                                           onChange={value =>
                                               {
                                                   this.setState(state => {
                                                       state.findings = value;
                                                       return state;
                                                   })
                                               }
                                           }
                                />
                            </div>
                        </div>
                        <div className={"row MSKTextboxTitleDiv"}>
                            <div className={"col-6 MSKTextboxTitle"}>
                                Impression
                            </div>
                            <div className={"col-6 MSKTextboxTitle"}>
                                Diagnosis
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-6 "}>
                                <TextInput value={this.state.impression  || ''}
                                           placeholder={"Impression"}
                                           onChange={value =>
                                               {
                                                   this.setState(state => {
                                                       state.impression = value;
                                                       return state;
                                                   })
                                               }
                                           }
                                           className={"MSKTextBox"}
                                />
                            </div>
                            <div className={"col-6 "} style={{marginTop:"-10px"}}>
                                <div className={"row"}>
                                    <div className={"col-4"} style={{marginLeft:"10px"}}>
                                        <AsyncSelect
                                            className={"emr-white-resized-select-container MSKDiagnosisDropdown"}
                                            classNamePrefix={"emr-white-resized-select"}
                                            style={{width:"80%",    maxHeight: "120px !important"}}

                                            noOptionsMessage={this.noOptionsMessage.bind(this)}
                                            loadOptions={this.loadICD10CodesByCode}
                                            value={this.state.icd10Code!= null?
                                                this.state.icd10Code.code != null ?
                                                {
                                                    value: this.state.icd10Code,
                                                    label: this.state.icd10Code.code
                                                } : {
                                                    value: " ",
                                                    label: "Code"
                                                }: null}
                                            onChange={(event) => {
                                                this.setState({icd10Code: event.value});
                                            }}
                                        />
                                    </div>
                                    <div className={"col-8"} style={{marginLeft:"-10px"}}>
                                        <AsyncSelect
                                            className={"emr-white-resized-select-container MSKDiagnosisDropdown"}
                                            classNamePrefix={"emr-white-resized-select"}
                                            styles={this.customStyles}
                                            noOptionsMessage={this.noOptionsMessage.bind(this)}
                                            loadOptions={this.loadICD10CodesByDescription}
                                            style={{width:"80%"}}
                                            value={this.state.icd10Code!= null?
                                                this.state.icd10Code.description != null ?
                                                {
                                                    value: this.state.icd10Code,
                                                    label: this.state.icd10Code.description
                                                } : {
                                                    value: " ",
                                                    label: "Description"
                                                } : null}
                                            onChange={(event) => {
                                                this.setState({icd10Code: event.value});
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row MSKTextboxTitleDiv"}>
                            <div className={"col-6 MSKTextboxTitle"}>
                                    Recommendations
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-11 "}>
                                <TextInput
                                    value={this.state.recommendations || ''}
                                    placeholder={"Recommendations"}
                                    onChange={value =>
                                               {
                                                   this.setState(state => {
                                                       state.recommendations = value;
                                                       return state;
                                                   })
                                               }
                                           }
                                    className={"MSKTextBox"}
                                    style={{width:"100%"}}
                                />
                            </div>
                            <div className={"col-1"}>
                                <Button className={"emr-plus-button"}
                                        variant="success"
                                        style={{marginTop:"-5px"}}
                                        disabled={
                                            this.state.recommendations == null &&
                                                this.state.impression == null &&
                                                this.state.findings == null &&
                                                this.state.examination == null &&
                                                this.state.examinationComment == null &&
                                                this.state.presentingComplaint == null &&
                                            ( this.state.icd10Code.code == null) &&
                                            (this.state.icd10Code.description == null)

                                        }
                                        onClick={this.addMSKDiagnosis}
                                >
                                    <FontAwesomeIcon icon={["fas", "plus"]}/>
                                </Button>
                            </div>
                        </div>
                        <div className={"row "} style={{width: "101%", marginTop: "15px"}}>
                            <div className={"content-row div-list-wrapper MSKList"}>
                                {MSKDiagnosisDivs}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
    loadICD10CodesByDescription = (inputValue, callback) => {
        if (inputValue.length < this.MINIMUM_SEARCH_LENGTH) {
            return callback(null);
        }
        RemotingService.getRemoteCall('api/icd10/description/' + inputValue, null, (response) => {
            if(response == null || response.length == 0) {
                return callback(null);
            }
            let options = response.map(r => {return {value: r, label: r.description}});
            return callback(options);
        });
    }

    loadICD10CodesByCode = (inputValue, callback) => {
        if (inputValue.length < this.MINIMUM_SEARCH_LENGTH) {
            return callback(null);
        }
        RemotingService.getRemoteCall('api/icd10/code/' + inputValue, null, (response) => {
            if(response == null || response.length == 0) {
                return callback(null);
            }
            let options = response.map(r => {return {value: r, label: r.code}});
            return callback(options);
        });
    }
    noOptionsMessage(input) {
        if(input.inputValue == null || input.inputValue.length < this.MINIMUM_SEARCH_LENGTH) {
            return <div>Type {this.MINIMUM_SEARCH_LENGTH} characters</div>;
        }
        else {
            return <div>No options</div>;
        }
    }

    getExaminationOptions = (inputValue,callback) => {
        let options = [];
        options = ExaminationEnum.toArray().map(item => {
            if((inputValue == null || inputValue.length == 0) || item.name.toLowerCase().includes(inputValue.toLowerCase())){
                return {value: item.key, label: item.name};
            }
        }).filter(Boolean);
        options.unshift({value: inputValue, label: inputValue});
        callback(options);
    }

    addMSKDiagnosis = () => {
        this.createMSKDiagnosisIfNecessary();
        (this.state.icd10Code != null && this.state.icd10Code.code != null &&this.state.icd10Code.description != null ) ?
        this.props.assessment.mskDiagnosis.items.push({
            presentingComplaint: this.state.presentingComplaint,
            examination:this.state.examination,
            examinationComment:this.state.examinationComment,
            leftBool:this.state.leftBool,
            rightBool:this.state.rightBool,
            notApplicableBool:this.state.notApplicableBool,
            findings:this.state.findings,
            impression:this.state.impression,
            recommendations:this.state.recommendations,
            diagnosisCode: this.state.icd10Code.code,
            diagnosisDescription: this.state.icd10Code.description
        }) :
            this.props.assessment.mskDiagnosis.items.push({
                presentingComplaint: this.state.presentingComplaint,
                examination:this.state.examination,
                examinationComment:this.state.examinationComment,
                leftBool:this.state.leftBool,
                rightBool:this.state.rightBool,
                notApplicableBool:this.state.notApplicableBool,
                findings:this.state.findings,
                impression:this.state.impression,
                recommendations:this.state.recommendations
            })
        this.setState({
            presentingComplaint: null,
            examination: null,
            examinationComment: null,
            leftBool:null,
            rightBool:null,
            notApplicableBool: null,
            findings: null,
            impression: null,
            recommendations: null,
            icd10Code: {
                code:null,
                description:null
            }
        });
    }
    createMSKDiagnosisIfNecessary() {
        if(this.props.assessment.mskDiagnosis == null) {
            this.props.assessment.mskDiagnosis = {};
            this.props.assessment.mskDiagnosis.items = [];
        }
        else if (this.props.assessment.mskDiagnosis.items == null){
            this.props.assessment.mskDiagnosis.items = [];
        }
    }
    getMSKDiagnosisDivs = () => {

        if (this.props.assessment.mskDiagnosis == null
            || this.props.assessment.mskDiagnosis.items == null) {
            return;
        }
        let divs = [];
        this.props.assessment.mskDiagnosis.items.forEach((item, index) => {
            const examinationLocation = item.notApplicableBool? "Not Applicable" : (item.leftBool? "Left" : item.rightBool? "Right" : null) ;
            divs.push(
            <div className={"row div-list-item"} key={"mskDiagnosisItem" + index}>
                <div style={{ position: "relative"}}>
                    {
                        item.presentingComplaint != null ?
                            <div className={"col-"} style={{marginLeft:"10px",marginBottom:"-10px",display: "block",width:"600px"}}>
                                <div className={"row"}>
                                    <div className={"col-3"} style={{fontWeight:"bold",}}>
                                        Presenting Complaint:
                                    </div>
                                    <div className={"col-9"}>
                                        {item.presentingComplaint}
                                    </div>
                                </div>
                            </div>
                            : ''
                    }
                    {
                        item.examination != null || item.examinationComment != null ?
                            <div className={"col-"} style={{marginLeft:"10px",marginBottom:"-10px",display: "block",width:"600px"}}>
                                <div className={"row"}>
                                    <div className={"col-3"} style={{fontWeight:"bold",}}>
                                        Examination:
                                    </div>
                                    <div className={"col-9"}>
                                        {(item.examination ? ExaminationEnum[item.examination].name : item.examinationComment) + (examinationLocation? " - " +examinationLocation : "") }
                                    </div>
                                </div>
                            </div>
                            : ''
                    }
                    {
                        item.findings != null ?
                            <div className={"col-"} style={{marginLeft:"10px",marginBottom:"-10px",display: "block",width:"600px"}}>
                                <div className={"row"}>
                                    <div className={"col-3"} style={{fontWeight:"bold",}}>
                                        Findings:
                                    </div>
                                    <div className={"col-9"}>
                                        {item.findings}
                                    </div>
                                </div>
                            </div>
                            : ''
                    }
                    {
                        item.impression != null ?
                            <div className={"col-"} style={{marginLeft:"10px",marginBottom:"-10px",display: "block",width:"600px"}}>
                                <div className={"row"}>
                                    <div className={"col-3"} style={{fontWeight:"bold",}}>
                                        Impression:
                                    </div>
                                    <div className={"col-9"}>
                                        {item.impression}
                                    </div>
                                </div>
                            </div>
                            : ''
                    }
                    {
                        item.diagnosisCode != null ?
                            <div className={"col-"} style={{marginLeft:"10px",marginBottom:"-10px",display: "block",width:"600px"}}>
                                <div className={"row"}>
                                    <div className={"col-3"} style={{fontWeight:"bold",}}>
                                        Diagnosis:
                                    </div>
                                    <div className={"col-9"}>
                                        {item.diagnosisCode} - {item.diagnosisDescription}
                                    </div>

                                </div>
                            </div>

                            : ''

                    }
                    {
                        item.recommendations != null ?
                            <div className={"col-"} style={{marginLeft:"10px",marginBottom:"-10px",display: "block",width:"600px"}}>
                                <div className={"row"}>
                                    <div className={"col-3"} style={{fontWeight:"bold",}}>
                                        Recommendations:
                                    </div>
                                    <div className={"col-9"}>
                                        {item.recommendations}
                                    </div>
                                </div>
                            </div>

                            : ''
                    }

                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{position:"absolute", top:"0", right:"0"}}
                        onClick={() => {
                            this.props.assessment.mskDiagnosis.items.splice(index, 1);
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas","times"]}/>
                </Button>


        </div>
            </div>
        )});
        return divs;
    }
}