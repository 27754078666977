import React, {Component} from 'react';
import {connect} from 'react-redux';
import './LoadingComponent.css';

class LoadingComponent extends Component {

    render(){

        let loadingIcon = this.props.remotingOperationCount > 0 ? (<div>
            <div className="loading-component-wrapper">
                <div className="loader"></div>
            </div>
            <div className="loading-page-cover"></div>
        </div>) : undefined;

        let appClassName = this.props.remotingOperationCount > 0 ? "apploading" : "appready";

        return (<div className={appClassName} >
            {loadingIcon}
            {this.props.children}
        </div>);
    }

}

const mapStateToProps = state =>{
    return {
        remotingOperationCount: state.base.remotingOperationCount
    };
};

export default connect(mapStateToProps, null)(LoadingComponent);
