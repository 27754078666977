import React, {Component} from "react";
import _uniqueId from "lodash/uniqueId";
import PropTypes from "prop-types";

export default class NumberInput extends Component {

    static defaultProps = {
        id: null,
        allowDecimal: false,
        maxFractionDigits: null,
        value: "",
        disabled: false,
        readOnly: false,
        placeholder: null,
        title: null,
        hidden: false,
        autoFocus: false,
        style: null,
        spellCheck: null,
        step: 1,
        min: null,
        max: null
    };

    static propTypes = {
        id: PropTypes.string,
        allowDecimal: PropTypes.bool,
        maxFractionDigits: PropTypes.number,
        value: PropTypes.number,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        placeholder: PropTypes.string,
        title: PropTypes.string,
        hidden: PropTypes.bool,
        autoFocus: PropTypes.bool,
        style: PropTypes.object,
        spellCheck: PropTypes.bool,
        step: PropTypes.number,
        min: PropTypes.number,
        max: PropTypes.number
    }
    
    static decimalSeperatorCharCode = 0.1.toLocaleString().replace(/\d/g, "").charCodeAt(0);

    constructor(props) {
        super(props);
        if (props.id) {
            this.id = props.id;
        } else {
            this.id = _uniqueId("textinput-id-");
        }
    }

    handleOnChange(e) {
        let value = e.target.value;

        // Manipulate fraction digits
        if (value !== null
            && value !== undefined
            && value !== ""
            && this.props.allowDecimal
            && this.props.maxFractionDigits
            && this.decimalPlaceCount(value) > Number(this.props.maxFractionDigits)
        ) {
            const regex = new RegExp("^-?\\d+(?:\\.\\d{0,"+this.props.maxFractionDigits+"})?");
            value = value.toString().match(regex)[0];
        }

        // Remove leading zeros
        if (value !== null
            && value !== undefined
            && value !== ""
            && typeof value === "string") {
            value = Number(value).toString();
        }

        this.props.onChange(value);
    }

    handleKeyPress(e) {
        // 0:null, 8:backspace, 48-57: numbers
        if (e.which >= 48 && e.which <= 57) { // allow numbers
            return;
        }
        
        if (e.which === 0) { // allow null
            return;
        }

        if (e.which === 8) { // allow backspace
            return;
        }
        
        if (this.props.allowDecimal && e.which === NumberInput.decimalSeperatorCharCode) { // allow decimal seperator
            return;
        }
        
        e.preventDefault();
    }

    decimalPlaceCount(number) {
        const str = number + "";
        const index = str.indexOf(".");
        if (index >= 0) {
            return str.length - index - 1;
        } else {
            return 0;
        }
    }

    render() {
        let componentClassName = "dim14textinput";
        if (this.props.className) {
            componentClassName += " " + this.props.className;
        }

        const step = this.props.allowDecimal ? this.props.step : Math.ceil(this.props.step);

        return (
            <input
                id={this.id}
                className={componentClassName}
                value={this.props.value}
                disabled={this.props.disabled}
                readOnly={this.props.readOnly}
                placeholder={this.props.placeholder}
                title={this.props.title}
                type="number"
                hidden={this.props.hidden}
                autoFocus={this.props.autoFocus}
                onChange={e => this.handleOnChange(e)}
                onKeyPress={e => this.handleKeyPress(e)}
                style={this.props.style}
                spellCheck={this.props.spellCheck}
                step={step}
                min={this.props.min}
                max={this.props.max}
            />
        )
    }
}
