export class GenericChecker {
    static isMissing(entity) {
        return !this.isToggleOn(entity);
    }

    static isToggleOn(entity) {
        return this.isDataEntered(entity) || this.isNone(entity);
    }

    static isDataEntered(entity) {
        throw('abstract method')
    }

    static isNone(entity) {
        return entity == null ? false : entity.none == true;
    }
}

