import Enum from "../../../../../../enums/Enum";

const ObservationFindingEnum = new Enum({
        SWELLING: {name: "Swelling"},
        REDNESS: {name: "Redness"},
        BRUISING: {name: "Bruising"},
        OPEN_WOUND: {name: "Open Wound"},
        DISCOLORATION: {name: "Discoloration"},
        CONTRACTURE: {name: "Contracture"},
        OTHER: {name: "Other"}
});

export default ObservationFindingEnum;