import React, {Component} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import EnumDayOfWeek from "./enums/EnumDayOfWeek";

export default class ScheduleGenerationMissingDataScreen extends Component{

    constructor(props) {
        super(props);
    }

    prepareMissingValues(dayPreference){
        let resultString = "";
        if(dayPreference.breakTimeAM == null) resultString += "Break Time AM, ";
        if(dayPreference.breakTimePM == null) resultString += "Break Time PM, ";
        if(dayPreference.noOfPatientPerDay == null) resultString += "Number of Patient Per Day, ";
        if(dayPreference.followUp == null) resultString += "Follow Up Duration, ";
        if(dayPreference.newPatient == null) resultString += "New Patient Duration, ";

        return resultString.slice(0, -2);
    }

    retrieveDayPreferencesContainMissingData() {
        let tableData = [];

        this.props.preferencesContainMissingData.forEach(preference => {
            preference.dayPreferences.forEach(dayPreference => {
                let tableItem = {}
                tableItem.staffName = preference.staff.name;
                tableItem.preferenceDay = EnumDayOfWeek[dayPreference.dayOfWeek];
                tableItem.missingValues = this.prepareMissingValues(dayPreference);
                if(tableItem.missingValues.length > 0) tableData.push(tableItem);
            });
        });

        return tableData;
    }
    render() {
        let tableData = this.retrieveDayPreferencesContainMissingData();
        return(
            <div style={{marginLeft: '10px', marginRight: '30px'}}>
                <div style={{textAlign: "center", margin:"5px", color:"red"}}>In order to generate schedule, the missing values should be filled.</div>
                <DataTable
                    className="StaffTable"
                    value={tableData} responsive autoLayout
                    emptyMessage={<div style={{textAlign: "center"}}>There are no missing data</div>} style={{minWidth:'450px', height:"100%", width:"100%"}}>
                    <Column field="staffName" header="Staff Name" sortable={true} style={{width: "125px"}} />
                    <Column field="preferenceDay" header="Preference Day" sortable={true} style={{width: "100px"}}/>
                    <Column field="missingValues" header="Missing Values" />
                </DataTable>
            </div>
        )
    }
}