import React, {Component} from 'react';
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import {RadioInput, TextInput} from "../../../components";
import TypeEnum from "../../emr/recordpage/subjective/enums/lifestyle/TypeEnum";
import SleepingPatternEnum from "../enum/SleepingPatternEnum";
import ExerciseTypeEnum from "../enum/ExerciseTypeEnum";
import IntakeFormYesNoEnum from "./enum/IntakeFormYesNoEnum";

class IntakeFormCurrentHealthPage2 extends Component {
    render() {
        const {
            selectedLanguage, readOnly, enumLabel, onChange, formFields,
            lifestylePattern, sleepingPattern, exerciseType, doYouSmoke, doYouSmokeHowOften,
            haveYouEverSmoked, doYouConsumeAlcohol, doYouConsumeAlcoholHowOften
        } = this.props

        return (
            <div className="container dim14-form-row-align">
                <div className="row">
                    <div
                        className="col-4 dim14-form-label">{IntakeFormTextEnum.Lifestyle_Pattern[selectedLanguage]}
                    </div>
                    <div className="col-8">
                        <RadioInput
                            id="lifestylePatternRadioInput"
                            value={lifestylePattern}
                            options={TypeEnum.toArray()}
                            valueProperty={"key"}
                            displayProperty={enumLabel}
                            disabled={readOnly}
                            onChange={(value) =>
                                onChange("lifestylePattern", value)
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div
                        className="col-4 dim14-form-label">{IntakeFormTextEnum.Sleeping_Pattern[selectedLanguage]}
                    </div>
                    <div className="col-8">
                        <RadioInput
                            id="sleepingPatternRadioInput"
                            value={sleepingPattern}
                            options={SleepingPatternEnum.toArray()}
                            valueProperty={"key"}
                            displayProperty={enumLabel}
                            disabled={readOnly}
                            onChange={(value) =>
                                onChange("sleepingPattern", value)
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div
                        className="col-4 dim14-form-label">{IntakeFormTextEnum.Exercise_Type[selectedLanguage]}
                    </div>
                    <div className="col-8">
                        <RadioInput
                            id="exerciseTypeRadioInput"
                            value={exerciseType}
                            options={ExerciseTypeEnum.toArray()}
                            valueProperty={"key"}
                            displayProperty={enumLabel}
                            disabled={readOnly}
                            onChange={(value) =>
                                onChange("exerciseType", value)
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div
                        className="col-4 dim14-form-label">{IntakeFormTextEnum.Do_You_Smoke[selectedLanguage]}</div>
                    <div className="col-8">
                        <div className="d-flex">
                            <RadioInput
                                id="doYouSmokeRadioInput"
                                value={doYouSmoke}
                                options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                                valueProperty={"key"}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("doYouSmoke", value)
                                }
                            />
                            {doYouSmoke === "Yes" ? (
                                <div className="d-flex">
                                    <p className="mx-2">
                                        {IntakeFormTextEnum.Packs_per_Day[selectedLanguage]}
                                    </p>
                                    <TextInput
                                        value={doYouSmokeHowOften}
                                        disabled={readOnly}
                                        onChange={(value) =>
                                            onChange("doYouSmokeHowOften", value)
                                        }
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Have_You_Ever_Smoked[selectedLanguage]}
                    </div>
                    <div className="col-8">
                        <RadioInput
                            id="haveYouEverSmokedRadioInput"
                            value={haveYouEverSmoked}
                            options={IntakeFormYesNoEnum.toArray()}
                            displayProperty={enumLabel}
                            valueProperty={"key"}
                            disabled={readOnly}
                            onChange={(value) =>
                                onChange("haveYouEverSmoked", value)
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Do_You_Consume_Alcohol[selectedLanguage]}
                    </div>
                    <div className="col-8">
                        <div className="d-flex">
                            <RadioInput
                                id="doYouConsumeAlcoholRadioInput"
                                value={doYouConsumeAlcohol}
                                options={IntakeFormYesNoEnum.toArray()}
                                displayProperty={enumLabel}
                                valueProperty={"key"}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("doYouConsumeAlcohol", value)
                                }
                            />
                            {doYouConsumeAlcohol === "Yes" ? (
                                <div className="d-flex">
                                    <p className="mx-2">
                                        {IntakeFormTextEnum.Units_per_Week[selectedLanguage]}
                                    </p>
                                    <TextInput
                                        value={doYouConsumeAlcoholHowOften}
                                        disabled={readOnly}
                                        onChange={(value) =>
                                            onChange(
                                                "doYouConsumeAlcoholHowOften",
                                                value
                                            )
                                        }
                                    />
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default IntakeFormCurrentHealthPage2;