import React, {Component} from 'react'
import RemotingService from "../../services/remoting-service/RemotingService";
import {withRouter} from "react-router";
import {Button, Container, Image} from "react-bootstrap";
import DateUtil from "../../utils/DateUtil";
import {DataTable} from "primereact/datatable";
import {NumberInput, TextInput} from "../../components";
import TextareaInput from "../../components/textarea-input/TextareaInput";
import {Checkbox, withStyles} from '@material-ui/core'
import NotificationService from "../../services/notification-service/NotificationService";
import moment from "moment";
import './Billing.css'
import BillingService from "../../services/billing/BillingService";
import {isEmpty} from "../../utils/ArrayUtil";
import EnumItemCategory from "./enums/EnumItemCategory";
import {Carousel} from "primereact/carousel";
import ObjectUtil from "../../utils/ObjectUtil";
import MoneyUtil from "../../utils/MoneyUtil";
import NumberUtils from "../../utils/NumberUtil";

const CUSTOM_GREEN = '#7AAE00';

const CheckBox = withStyles({
    root: {
        padding: '0px',
        '&$checked': {
            color: CUSTOM_GREEN,
        },
    },
    checked: {},
})((props) => <Checkbox size="small" {...props} />);

class Bill extends Component {

    constructor(props) {
        super(props);

        const {patientId, patientName, patientEmail, invoices = []} = this.props.location;
        const total = invoices.reduce(((a, b) => a + b.patientPayable), 0);
        const country = invoices[0]?.clinic?.country;
        const currency = invoices[0]?.clinic?.currency;

        this.state = {
            patientId, patientName, patientEmail, invoices, total, country, currency,
            discountAppliedInvoices: ObjectUtil.clone(invoices),
            credits: [],
            usedCredits: [],
            cashEnabled: true,
            checkEnabled: false,
            cardEnabled: false,
            onlineEnabled: false,
            dwEnabled: false,
            dwSelectable: this.isDiscWalletSelectable(invoices),
            cashDue: total,
            cashReceived: "",
            cashExchange: "",
            checkDue: "",
            cardDue: "",
            cardKnet: "",
            onlineDue: "",
            onlineEmail: patientEmail,
            dwDue: "",
            dwValidityExpired: false
        };
    }

    componentDidMount() {
        if (isEmpty(this.state.invoices)) {
            setTimeout(() => this.props.history.push('/patient'), 1000);
            return;
        }

        this.retrieveRetailItems();

        RemotingService.getRemoteCall('api/credit/list', {patientId: this.state.patientId}, (credits) => {
            let filteredCredits = credits
                .filter(credit => credit.status === 'BILLED' && credit.balance > 0 && credit.isActiveMode === true
                    && credit.country == this.state.country)
                .sort((c1, c2) => moment(c1.creationDate).diff(moment(c2.creationDate)));
            this.setState({
                credits: filteredCredits,
                dwValidityExpired: (filteredCredits.length > 0 ? !moment(filteredCredits[0].validityDate).isAfter(moment()) : false)
            });
        });
    }

    retrieveRetailItems() {
        RemotingService.getRemoteCall('api/billing/item', null, (items) => {
            items.forEach(i => {
                i.detail = i.code + ' - ' + i.description;
            });
            this.setState({items}, this.retrievePendingInvoiceItems);
        });
    }

    isDiscWalletSelectable(invoices) {
        if (isEmpty(invoices)) {
            return true;
        }

        const lateCancellationCodes = ["KWI-ACCT-001", "DXB-ACCT-001"];
        const noShowCodes = ["KWI-ACCT-002", "DXB-ACCT-002"];

        for (const invoice of invoices) {
            if (!isEmpty(invoice.items)) {
                for (const item of invoice.items) {
                    if (EnumItemCategory[item.category] === EnumItemCategory.CREDIT) {
                        return false;
                    }

                    if (EnumItemCategory[item.category] === EnumItemCategory.RETAIL) {
                        return false;
                    }

                    if (EnumItemCategory[item.category] === EnumItemCategory.FEE && lateCancellationCodes.includes(item.code)) {
                        return false;
                    }

                    if (EnumItemCategory[item.category] === EnumItemCategory.FEE && noShowCodes.includes(item.code)) {
                        return false;
                    }
                }
            }
        }

        return true;
    }

    goToPatientDetail = () => {
        this.props.history.push({
            pathname: '/patientdetail/' + this.state.patientId,
            selectedView: 'billing'
        })
    }

    cancel = () => {
        this.goToPatientDetail();
    }

    save = () => {
        if (this.props.location.mrn == null){
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Patient Problem', detail: 'Patients without MRN can not be billed.'
            });
            return;
        }

        if (this.getRemainingBillAmount(true) !== 0) {
            NotificationService.showNotification({
                severity: 'warn',
                summary: 'Payment Problem', detail: 'Payments should sum up to bill total.'
            });
            return;
        }

        const dto = {
            patientId: this.state.patientId,
            usedCredits: this.state.usedCredits,
            cashEnabled: this.state.cashEnabled,
            cashDue: this.state.cashDue,
            cashReceived: this.state.cashReceived ? this.state.cashReceived : 0,
            checkEnabled: this.state.checkEnabled,
            checkDue: this.state.checkDue ? this.state.checkDue : 0,
            cardEnabled: this.state.cardEnabled,
            cardDue: this.state.cardDue ? this.state.cardDue : 0,
            cardKnet: this.state.cardKnet ? this.state.cardKnet : 0,
            onlineEnabled: this.state.onlineEnabled,
            onlineDue: this.state.onlineDue ? this.state.onlineDue : 0,
            onlineEmail: this.state.onlineEmail,
            dwEnabled: this.state.dwEnabled,
            dwDue: this.state.dwDue ? this.state.dwDue : 0,
            invoiceIds: this.state.invoices.map(i => i.id),
            note: this.state.note
        };

        RemotingService.postRemoteCall(
            'api/billing/' + this.state.patientId + '/bill/',
            dto,
            (bill) => {
                const invoices = bill.invoices.map(i => BillingService.hydrateInvoice(i, this.state.items));

                this.setState({invoices: invoices});

                bill.invoices.forEach(invoice => RemotingService.instance.get('api/file/download/' + invoice.pdfPath, {responseType: 'blob'})
                    .then((response) => {
                        let invoicePdfs = this.state.invoicePdfs || {};
                        invoicePdfs[invoice.id] = response.data;
                        this.setState({invoicePdfs: invoicePdfs})
                    }));
            });
    }

    useCredits = () => {
        let remainingPayable = parseFloat(this.state.dwDue);

        if (remainingPayable <= 0) {
            return;
        }

        let creditPayable;
        let usedCredits = [];
        let creditLeft = 0;

        let credit = this.state.credits[0];

        if (!credit) {
            this.setState({dwDue: 0})
            return
        }

        if (credit.balance >= remainingPayable) {
            const creditAmount = NumberUtils.toFixedByCurrency(remainingPayable * (100 - credit.discountRate) / 100, this.state.currency);
            creditPayable = remainingPayable;
            usedCredits.push({
                id: credit.id,
                walletId: credit.walletId,
                creditAmount: creditAmount,
                discountRate: credit.discountRate,
                discountAmount: NumberUtils.toFixedByCurrency(remainingPayable - creditAmount, this.state.currency)
            });
            creditLeft = credit.balance >= creditAmount ? (credit.balance - creditAmount) : credit.balance;

        } else {
            const usedAmount = credit.balance;
            creditPayable = usedAmount;
            usedCredits.push({
                id: credit.id,
                walletId: credit.walletId,
                creditAmount: credit.balance,
                discountRate: credit.discountRate,
                discountAmount: NumberUtils.toFixedByCurrency(usedAmount - (credit.balance * (100 - credit.discountRate) / 100), this.state.currency)
            });
        }

        this.setState({dwDue: creditPayable, usedCredits, creditLeft}, () => this.applyDiscounts());
    }

    getDwCreditAmount = () => {
        const dwCreditAmount = this.state.usedCredits.reduce(((a, b) => a + b.creditAmount), 0);
        return NumberUtils.toFixedByCurrency(dwCreditAmount, this.state.currency);
    }

    getDiscountAmount = () => {
        const discountAmount = this.state.usedCredits.reduce(((a, b) => a + b.discountAmount), 0);
        return NumberUtils.toFixedByCurrency(discountAmount, this.state.currency);
    }

    applyDiscounts = () => {
        const discountAmount = this.getDiscountAmount();
        let invoices = ObjectUtil.clone(this.state.invoices);
        if (discountAmount > 0) {
            const discountRatio = discountAmount / this.state.total;
            invoices.forEach(inv => {
                inv.dwDiscount = inv.net * discountRatio;
                inv.net -= inv.dwDiscount;
                inv.patientPayable -= inv.dwDiscount;
            });
        }
        this.setState({discountAppliedInvoices: invoices});
    }

    getRemainingBillAmount = (enabled, method) => {
        if (!enabled) {
            return 0;
        }

        if (method) {
            if (method === 'online') {
                this.state.cashEnabled = false;
                this.state.cashDue = 0;
                this.state.checkEnabled = false;
                this.state.checkDue = 0;
                this.state.cardEnabled = false;
                this.state.cardDue = 0;
                this.state.dwEnabled = false;
                this.state.dwDue = 0;
                this.state.usedCredits = [];

            } else {
                this.state.onlineEnabled = false;
                this.state.onlineDue = 0;
            }
        }

        const remaining = this.state.total - this.state.cashDue - this.state.checkDue - this.state.cardDue - this.state.onlineDue - this.getDwCreditAmount() - this.getDiscountAmount();
        return NumberUtils.toFixedByCurrency(Math.max(remaining, 0), this.state.currency);
    }

    invoicePreviewTemplate = (invoice) => {
        return this.state.invoicePdfs[invoice.id] ?
            <iframe id={'invoice' + invoice.id} src={window.URL.createObjectURL(this.state.invoicePdfs[invoice.id])}
                    width="800px" height="600px"></iframe> : ""
    }

    prepareDiscWalletValidityMessage() {
        return this.state.credits.length > 0 &&
            (moment(this.state.credits[0].validityDate).isSameOrAfter(moment(DateUtil.yesterday())) ?
            (<div style={{
                    color: 'green',
                    fontSize: 10
                }}>Validity: {DateUtil.formatDate(this.state.credits[0].validityDate, 'DD-MMM-YYYY')}
            </div>) :
            (<div style={{
                    color: 'red',
                    fontSize: 10
                }}>Validity: {DateUtil.formatDate(this.state.credits[0].validityDate, 'DD-MMM-YYYY')}
            </div>))
    }

    render() {
        const creditAmount = this.getDwCreditAmount();
        const discountAmount = this.getDiscountAmount();
        const received = NumberUtils.toFixedByCurrency(
            Number(this.state.cashDue) + Number(this.state.checkDue) + Number(this.state.cardDue) + Number(this.state.onlineDue) + creditAmount,
            this.state.currency
        );
        const invoices = this.state.dwEnabled ? this.state.discountAppliedInvoices : this.state.invoices;

        if (isEmpty(this.state.invoices)) {
            return null;
        }

        return (
            <div className="container-fluid pt-2">
                <div className="row">
                    <div className="col-4">
                        {this.state.invoicePdfs &&
                        <Carousel value={invoices} numVisible={1}
                                  itemTemplate={this.invoicePreviewTemplate}></Carousel>
                        }
                    </div>
                    <div className="col-8 px-5">
                        <div className="row d-flex p-justify-center w-100 font-weight-bold" style={{fontSize: '30px'}}>
                            <div>Customer Payment</div>
                        </div>
                        <div className="row">
                            <div className="col-2"><strong>Date:</strong></div>
                            <div className="col-3">{DateUtil.formatDate(new Date(), "DD/MM/YYYY")}</div>
                        </div>
                        <div className="row" style={{paddingBottom: '30px'}}>
                            <div className="col-2"><strong>Received From:</strong></div>
                            <div className="col-2">{this.state.patientName}</div>
                        </div>
                        <div className="row">

                            <div className="col-12">
                                <DataTable className="InvoiceTable"
                                           value={invoices}
                                           rowHover={true}
                                           scrollable
                                           style={{ maxWidth: '100%' }}
                                >
                                    {BillingService.invoiceColumns(false, true)}
                                </DataTable>
                            </div>
                        </div>

                        {this.state.invoicePdfs ?
                            <Button variant={"danger"} onClick={() => this.cancel()}>Close</Button>
                            : <>
                                <div className="row">
                                    <div className="col-12 text-right">
                                        <strong>Total: {MoneyUtil.format(this.state.total, this.state.currency) + ' ' + this.state.currency}</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <strong>Payment Method:</strong>
                                    </div>
                                    <div className="col-3">
                                        <strong>DISC Wallet:</strong>
                                    </div>
                                    <div className="col-5 pl-3">
                                        <strong>Payment:</strong>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-4">
                                        <div className="d-flex justify-content-start">
                                            <div className="col-6">
                                                <div
                                                    className={"PaymentMethod " + (this.state.cashEnabled ? " PaymentMethodSelected" : "")}>
                                                    <div className="PaymentMethodHeader">
                                                        <CheckBox checked={this.state.cashEnabled}
                                                                  onChange={(event) => this.setState({
                                                                      cashEnabled: event.target.checked,
                                                                      cashDue: this.getRemainingBillAmount(event.target.checked, 'cash')
                                                                  })}/>
                                                        <div className="PaymentIcon">
                                                            <Image src={'/assets/payment/cash.png'} rounded
                                                                   width={"20px"}/>
                                                            &nbsp;&nbsp;Cash
                                                        </div>
                                                    </div>
                                                    <NumberInput
                                                        className={"PaymentInput " + (this.state.cashEnabled ? " PaymentMethodSelected" : "")}
                                                        min={0}
                                                        value={this.state.cashDue}
                                                        placeholder="Amount Due"
                                                        disabled={!this.state.cashEnabled}
                                                        onChange={(value) => this.setState({cashDue: value})}/>
                                                    <NumberInput
                                                        className={"PaymentInput " + (this.state.cashEnabled ? " PaymentMethodSelected" : "")}
                                                        value={this.state.cashReceived}
                                                        min={0}
                                                        placeholder="Amount Received"
                                                        disabled={!this.state.cashEnabled}
                                                        onChange={(value) => this.setState({
                                                            cashReceived: value,
                                                            cashExchange: (this.state.cashDue && value > this.state.cashDue) ? value - this.state.cashDue : 0
                                                        })}/>
                                                    <NumberInput
                                                        className={"PaymentInput " + (this.state.cashEnabled ? " PaymentMethodSelected" : "")}
                                                        value={this.state.cashExchange}
                                                        placeholder="Exchange"
                                                        disabled/>
                                                </div>
                                                <div
                                                    className={"PaymentMethod " + (this.state.checkEnabled ? " PaymentMethodSelected" : "")}>
                                                    <div className="PaymentMethodHeader">
                                                        <CheckBox checked={this.state.checkEnabled}
                                                                  onChange={(event) => this.setState({
                                                                      checkEnabled: event.target.checked,
                                                                      checkDue: this.getRemainingBillAmount(event.target.checked, 'check')
                                                                  })}/>
                                                        <div className="PaymentIcon">
                                                            <Image src={'/assets/payment/check.png'} rounded
                                                                   width={"20px"}/>
                                                            &nbsp;&nbsp;Cheque
                                                        </div>
                                                    </div>
                                                    <NumberInput
                                                        className={"PaymentInput " + (this.state.checkEnabled ? " PaymentMethodSelected" : "")}
                                                        value={this.state.checkDue}
                                                        min={0}
                                                        placeholder="Amount Due"
                                                        disabled={!this.state.checkEnabled}
                                                        onChange={(value) => this.setState({checkDue: value})}/>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div
                                                    className={"PaymentMethod " + (this.state.cardEnabled ? " PaymentMethodSelected" : "")}>
                                                    <div className="PaymentMethodHeader">
                                                        <CheckBox checked={this.state.cardEnabled}
                                                                  onChange={(event) => this.setState({
                                                                      cardEnabled: event.target.checked,
                                                                      cardDue: this.getRemainingBillAmount(event.target.checked, 'card')
                                                                  })}/>
                                                        <div className="PaymentIcon">
                                                            <Image src={'/assets/payment/card.png'} rounded
                                                                   width={"20px"}/>
                                                            &nbsp;&nbsp;Card
                                                        </div>
                                                    </div>
                                                    <NumberInput
                                                        className={"PaymentInput " + (this.state.cardEnabled ? " PaymentMethodSelected" : "")}
                                                        value={this.state.cardDue}
                                                        min={0}
                                                        placeholder="Amount Due"
                                                        disabled={!this.state.cardEnabled}
                                                        onChange={(value) => this.setState({cardDue: value})}/>
                                                    <NumberInput
                                                        className={"PaymentInput " + (this.state.cardEnabled ? " PaymentMethodSelected" : "")}
                                                        value={this.state.cardKnet}
                                                        min={0}
                                                        placeholder="Ref"
                                                        disabled={!this.state.cardEnabled}
                                                        onChange={(value) => this.setState({cardKnet: value})}/>
                                                </div>
                                                <div
                                                    className={"PaymentMethod " + (this.state.onlineEnabled ? " PaymentMethodSelected" : "")}>
                                                    <div className="PaymentMethodHeader">
                                                        <CheckBox checked={this.state.onlineEnabled}
                                                                  onChange={(event) => this.setState({
                                                                      onlineEnabled: event.target.checked,
                                                                      onlineDue: this.getRemainingBillAmount(event.target.checked, 'online')
                                                                  })}/>
                                                        <div className="PaymentIcon">
                                                            <Image src={'/assets/payment/online.png'} rounded
                                                                   width={"20px"}/>
                                                            &nbsp;&nbsp;Online
                                                        </div>
                                                    </div>
                                                    <NumberInput
                                                        className={"PaymentInput " + (this.state.onlineEnabled ? " PaymentMethodSelected" : "")}
                                                        value={this.state.onlineDue}
                                                        min={0}
                                                        placeholder="Amount Due"
                                                        disabled
                                                        onChange={(value) => this.setState({onlineDue: value})}/>
                                                    <TextInput
                                                        className={"PaymentInput " + (this.state.onlineEnabled ? " PaymentMethodSelected" : "")}
                                                        value={this.state.onlineEmail}
                                                        placeholder="Email"
                                                        disabled={!this.state.onlineEnabled}
                                                        onChange={(value) => this.setState({onlineEmail: value})}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div
                                            className={`d-flex justify-content-start ${!this.state.dwSelectable ? 'not-allowed disabled-pointer' : ''}`}>
                                            <div className="col-12">
                                                <div
                                                    className={"PaymentMethod " + (this.state.dwEnabled ? " PaymentMethodSelected" : "")}
                                                    style={{marginBottom: 2}}>
                                                    <div className="PaymentMethodHeader">
                                                        <CheckBox disabled={!this.state.dwSelectable || this.state.dwValidityExpired}
                                                                  checked={this.state.dwEnabled}
                                                                  onChange={(event) => this.setState({
                                                                      dwEnabled: event.target.checked,
                                                                      dwDue: this.getRemainingBillAmount(event.target.checked, 'dw'),
                                                                      usedCredits: event.target.checked ? this.state.usedCredits : []
                                                                  }, () => {
                                                                      this.useCredits()
                                                                  })}/>
                                                        <div className="PaymentIcon">
                                                            <Image src={'/assets/payment/dw.png'} rounded
                                                                   width={"20px"}/>
                                                            &nbsp;&nbsp;DISC Wallet
                                                        </div>
                                                    </div>
                                                    <NumberInput
                                                        className={"PaymentInput " + (this.state.dwEnabled ? " PaymentMethodSelected" : "")}
                                                        value={this.state.dwDue}
                                                        placeholder="Amount Due"
                                                        disabled={!this.state.dwEnabled || !this.state.dwSelectable}
                                                        type="number"
                                                        onChange={(value) => this.setState({dwDue: value}, () => {
                                                            this.useCredits()
                                                        })}/>
                                                    {this.prepareDiscWalletValidityMessage()}
                                                    <Container style={{
                                                        minWidth: '200px',
                                                        maxWidth: '98%',
                                                        paddingLeft: '10px',
                                                        paddingRight: '5px'
                                                    }}>
                                                        {
                                                            (this.state.usedCredits.length > 0) && this.state.usedCredits.map(c => {
                                                                return (
                                                                    <div className="row" style={{fontSize: 11}}>
                                                                        <div className="col-5" style={{
                                                                            fontWeight: 'bold',
                                                                            fontSize: 12
                                                                        }}>Wallet {c.walletId}:
                                                                        </div>
                                                                        <div
                                                                            className="col text-left">{MoneyUtil.format(c.creditAmount, this.state.currency)}</div>
                                                                        <div
                                                                            className="col text-right">{this.state.currency}</div>
                                                                        <div className="col text-right">{c.discountRate}%
                                                                        </div>
                                                                    </div>);
                                                            })
                                                        }
                                                    </Container>
                                                    <div style={{
                                                        fontSize: '12px',
                                                        fontWeight: 'bold',
                                                        padding: '10px 0px'
                                                    }}>Discounted Amount:
                                                    </div>
                                                    <NumberInput
                                                        className={"PaymentInput " + (this.state.dwEnabled ? " PaymentMethodSelected" : "")}
                                                        style={{marginTop: '1px'}}
                                                        value={this.state.dwEnabled ? this.getDwCreditAmount() : ""}
                                                        disabled/>
                                                    <div style={{
                                                        fontSize: '12px',
                                                        fontWeight: 'bold',
                                                        padding: '0px'
                                                    }}>Credit
                                                        Left:
                                                    </div>
                                                    <NumberInput
                                                        className={"PaymentInput " + (this.state.dwEnabled ? " PaymentMethodSelected" : "")}
                                                        style={{marginTop: '1px'}}
                                                        value={MoneyUtil.formatNumeric(this.state.creditLeft, this.state.currency)}
                                                        disabled/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" className="PaymentHeader mb-0"><strong>Note:</strong></div>
                                        <div className="d-flex justify-content-start">
                                            <div className="col">
                                                <TextareaInput className="w-100" value={this.state.note}
                                                               onChange={(value) => this.setState({note: value})}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-5 pl-3">
                                        <div className="d-flex justify-content-start">
                                            <div className="card-body">
                                                <div className="row p-2">
                                                    <div className="col-4 font-weight-bold">Total</div>
                                                    <div className="col-8">
                                                        {MoneyUtil.format(this.state.total, this.state.currency)}
                                                    </div>
                                                    <div className="col-4 font-weight-bold">Discount</div>
                                                    <div className="col-8">
                                                        {MoneyUtil.format(discountAmount, this.state.currency)}
                                                    </div>
                                                    <div className="col-4 font-weight-bold">Due</div>
                                                    <div className="col-8">
                                                        {MoneyUtil.format(this.state.total - discountAmount, this.state.currency)}
                                                    </div>
                                                    <div className="col-4 font-weight-bold">Received</div>
                                                    <div className="col-8">
                                                        {MoneyUtil.format(received, this.state.currency)}
                                                    </div>
                                                    <div className="col-4 font-weight-bold">Balance</div>
                                                    <div className="col-8">
                                                        {MoneyUtil.format(this.getRemainingBillAmount(true), this.state.currency)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-5">
                                                <Button variant={"danger"}
                                                        style={{'width': '100%'}}
                                                        onClick={() => this.cancel()}>
                                                    Cancel
                                                </Button>
                                            </div>
                                            <div className="col-7">
                                                <Button variant={"success"}
                                                        style={{'width': '100%'}}
                                                        disabled={this.state.total - discountAmount != received}
                                                        onClick={() => this.save()}>
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(Bill);
