import BMIEnum from "../enums/vitalSigns/BMIEnum";

class VitalSignsUtil {
    getBMIStatus(bmi) {
        let status = null;
        if (bmi < 18.5) {
            status = BMIEnum.UNDERWEIGHT.key;
        } else if (bmi < 25.0 && bmi >= 18.5) {
            status = BMIEnum.NORMAL.key;
        } else if (bmi >= 25.0 && bmi < 30.0) {
            status = BMIEnum.OVERWEIGHT.key;
        } else if (bmi >= 30.0) {
            status = BMIEnum.OBESE.key;
        }
        return status;
    }
}

export default new VitalSignsUtil();