import React, {Component} from "react";
import "./UpdateGeneralStaffPreferences.css";
import {RadioInput} from "../../../../components";
import {AutoComplete} from "primereact/autocomplete";
import EnumRotation from "../../enums/EnumRotation";
import EnumConsecutiveDaysOrSplitWeek from "../../enums/EnumConsecutiveDaysOrSplitWeek";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MultiSelect} from "primereact/multiselect";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import {FormField} from "../../../../components/form";

class UpdateGeneralAdminPreferences extends Component {

    constructor(props) {
        super(props);
        this.state = {
            staffPreference: props.staffPreference,
            availableRooms: [],
            allStaffs: [],
            rotationWithStaffIds: []
        };
    }

    componentDidMount() {
        RemotingService.getRemoteCall('api/room/available-rooms-by-staff', {staffId: this.props.staffId}, (availableRooms) => {
            this.setState({availableRooms}, () => ValidationUtil.clear(this.formFields));
        });
        RemotingService.getRemoteCall('api/staff/list-base', null, (result) => {
            const allStaffs = result.items.filter(item => item.id !== this.props.staffId);
            const allStaffIds = allStaffs.map(s => s.id);
            const rotationWithStaffIds = this.state.staffPreference.rotationWithStaffIds.filter(staffId => allStaffIds.includes(staffId));

            this.setState(prevState => {
                prevState.allStaffs = allStaffs;
                prevState.staffPreference.rotationWithStaffIds = rotationWithStaffIds;
                return {...prevState}
            });
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.staffPreference != this.props.staffPreference) {
            this.setState({
                staffPreference: this.props.staffPreference,
                autoCompleted: !!this.props.staffPreference.synchronizeWith
            });
        }
    }

    handleFieldChange = (field, value) => {
        this.state.staffPreference[field] = value;
        this.props.onChange(this.state.staffPreference); // state is updated by props.onChange()
    }

    staffNameCompleteMethod(event) {
        RemotingService.getRemoteCall(`api/staff/list-base?name=${event.query}`, null, (result) => {
            this.setState({ staffNameSuggestions: result.items});
        });
    }

    handleStaffSearchResultSelect = (staff) => {
        this.state.staffPreference.synchronizeWithStaffId = staff.id;
        this.state.staffPreference.synchronizeWith = staff;
        this.props.onChange(this.state.staffPreference); // state is updated by props.onChange()
    }

    deselectStaffSearchResult = () => {
        this.setState(prevState => {
            prevState.staffPreference.synchronizeWithStaffId = null;
            prevState.staffPreference.synchronizeWith = null;
            prevState.autoCompleted = false;
            return {...prevState}
        });
    }

    validate() {
        return ValidationUtil.isValid(this.formFields);
    }

    clear() {
        ValidationUtil.clear(this.formFields);
    }

    render() {
        this.formFields = [];
        return <>
                <div className="container" style={{fontSize: 'small', width: 500, marginLeft: 0, marginTop: 25}}>
                    <div className="row">
                        <div className="col-6 dim14-form-label">
                            Allowed Number of Days Per Weeks
                        </div>
                        <div className="col-6">
                            <FormField ref={formField => this.formFields.push(formField)}
                                       required
                                       validateOn={this.state.staffPreference.allowedNoOfDaysPerWeeks}>
                                <MultiSelect options={[2,3,4,5,6]}
                                             value={this.state.staffPreference.allowedNoOfDaysPerWeeks}
                                             onChange={event => this.handleFieldChange("allowedNoOfDaysPerWeeks", event.value)}/>
                            </FormField>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 dim14-form-label">
                            Allowed Working Hours Per Days
                        </div>
                        <div className="col-6">
                            <FormField ref={formField => this.formFields.push(formField)}
                                       required
                                       validateOn={this.state.staffPreference.allowedWorkingHoursPerDays}>
                                <MultiSelect options={[1,2,3,4,5,6,7,8,9,10]}
                                             value={this.state.staffPreference.allowedWorkingHoursPerDays}
                                             onChange={event => this.handleFieldChange("allowedWorkingHoursPerDays", event.value)}/>
                            </FormField>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 dim14-form-label">
                            Consecutive Days / Split Week
                        </div>
                        <div className="col-6">
                            {EnumConsecutiveDaysOrSplitWeek[this.state.staffPreference.consecutiveOrSplit]}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 dim14-form-label">
                            Allowed Rooms
                        </div>
                        <div className="col-6">
                            <FormField ref={formField => this.formFields.push(formField)}
                                       required
                                       validateOn={this.state.staffPreference.allowedRoomIds}>
                                <MultiSelect filter={true} filterPlaceholder="Search" placeholder="Choose"
                                             options={this.state.availableRooms}
                                             optionValue="id"
                                             optionLabel="info"
                                             value={this.state.staffPreference.allowedRoomIds}
                                             onChange={event => this.handleFieldChange("allowedRoomIds", event.value)} />
                            </FormField>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 dim14-form-label">
                            Rotation
                        </div>
                        <div className="col-6">
                            <FormField ref={formField => this.formFields.push(formField)}
                                       required
                                       validateOn={this.state.staffPreference.rotationPreference}>
                                <RadioInput options={EnumRotation.toArray()}
                                            valueProperty="key"
                                            displayProperty="name"
                                            value={this.state.staffPreference.rotationPreference}
                                            onChange={value => {
                                                if (value === "FIXED") {
                                                    this.state.staffPreference.rotationWithStaffIds = [];
                                                }
                                                this.handleFieldChange("rotationPreference", value)
                                            }}/>
                            </FormField>
                        </div>
                    </div>
                    {   this.state.staffPreference.rotationPreference === "IN_ROTATION" ?
                        <div className="row">
                            <div className="col-6 dim14-form-label">
                                Rotation With
                            </div>
                            <div className="col-6">
                                <FormField ref={formField => this.formFields.push(formField)}
                                           required maxSelection={2}
                                           validateOn={this.state.staffPreference.rotationWithStaffIds}>
                                    <MultiSelect filter={true} filterPlaceholder="Search" placeholder="Choose"
                                                 options={this.state.allStaffs}
                                                 optionValue="id"
                                                 optionLabel="name"
                                                 value={this.state.staffPreference.rotationWithStaffIds}
                                                 onChange={event => this.handleFieldChange("rotationWithStaffIds", event.value)}/>
                                </FormField>
                            </div>
                        </div> : null
                    }
                    <div className="row">
                        <div className="col-6 dim14-form-label">
                            Synchronize With
                        </div>
                        <div className="col-6">
                            <div className="p-inputgroup">
                                <AutoComplete disabled={this.state.autoCompleted}
                                              placeholder="Search"
                                              suggestions={this.state.staffNameSuggestions}
                                              itemTemplate={staff => <>{staff.name}</>}
                                              completeMethod={this.staffNameCompleteMethod.bind(this)}
                                              onSelect={event => this.handleStaffSearchResultSelect(event.value)}
                                              value={this.state.staffPreference.synchronizeWith ? this.state.staffPreference.synchronizeWith.name : null}
                                />
                                {this.state.autoCompleted ? <FontAwesomeIcon icon={["fas", "times-circle"]} size={"2x"} color={"red"}
                                                                             style={{marginTop:"5px", marginLeft:"5px"}}
                                                                             onClick={() => this.deselectStaffSearchResult()}/> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </>
    }

}

export default UpdateGeneralAdminPreferences;
