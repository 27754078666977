import React, {Component} from "react";
import "../../VisitHistory.css"
import ConservativeTreatmentReceivedEnum from "../../../subjective/enums/treatmentreceived/ConservativeTreatmentReceivedEnum";
import ResponseEnum from "../../../subjective/enums/treatmentreceived/ResponseEnum";
import OtherTreatmentReceivedEnum from "../../../subjective/enums/treatmentreceived/OtherTreatmentReceivedEnum";
import * as ArrayUtil from "../../../../../../utils/ArrayUtil";

export default class TreatmentReceivedViewHistory extends Component {

    render() {
        let conservativeDivs = [];
        let otherDivs = [];

        if(this.props.treatmentReceived == null
            || (this.props.treatmentReceived.conservative == null
                && this.props.treatmentReceived.other == null)
            || ((this.props.treatmentReceived.conservative != null && this.props.treatmentReceived.conservative.length == 0)
                && (this.props.treatmentReceived.other != null && this.props.treatmentReceived.other.length == 0))) {
            return <div className="pl-3">
                {this.props.treatmentReceived.none ? "No Treatment Received" : "No information available"}
            </div>;
        }

        this.getTreatmentReceivedDivs(conservativeDivs, this.props.treatmentReceived.conservative, ConservativeTreatmentReceivedEnum);
        this.getTreatmentReceivedDivs(otherDivs, this.props.treatmentReceived.other, OtherTreatmentReceivedEnum);

        return (
            <>
                {!ArrayUtil.isEmpty(conservativeDivs) && (<>
                    <u className="font-weight-bold mb-3">Conservative:</u>
                    {conservativeDivs}</>)
                }

                {!ArrayUtil.isEmpty(otherDivs) && (<>
                    <u className="font-weight-bold mb-3">Other:</u>
                    {otherDivs}</>)
                }
            </>
        );
    }

    getTreatmentReceivedDivs(divs, value, relatedEnum) {
        value.forEach((treatment, index) => {
            divs.push(
                <div key={"treatmentReceivedViewHistory" + index}>
                    <div className={"display-flex m-1"}>
                        <div
                            className={"col-sm-3 no-padding dim14-form-label overflow-wrap-anywhere"}>{relatedEnum[treatment.treatmentReceived].name}:
                        </div>
                        <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>{treatment.detail}</div>
                    </div>
                    <div className={"display-flex m-1"}>
                        <div className={"col-sm-3 no-padding dim14-form-label"}>Response:</div>
                        <div
                            className={"col-sm-9 no-padding overflow-wrap-anywhere"}>{ResponseEnum[treatment.response]?.name}</div>
                    </div>
                </div>
            );
        });
    }
}