import Enum from "../../../../../../enums/Enum";

const OccupationEnum = new Enum({
    OFFICE_WORKER: {name: "Office Worker", nameInArabic: "موظف مكتبي"},
    FIELD_WORKER: {name: "Field Worker", nameInArabic: "عامل ميداني"},
    UNEMPLOYED: {name: "Unemployed", nameInArabic: "غير موظف"},
    STUDENT: {name: "Student", nameInArabic: "طالب"},
    HOUSE_WIFE: {name: "Housewife", nameInArabic: "ربة منزل"},
    ARMED_FORCES: {name: "Armed Forces", nameInArabic: "أحد أفراد القوات المسلحة"},
    RETIRED: {name: "Retired", nameInArabic: "متقاعد"}
});

export default OccupationEnum;