import React, {Component} from "react";
import "../../VisitHistory.css"
import YesNoEnum from "../../../../../genericenums/YesNoEnum";
import DateUtil from "../../../../../../utils/DateUtil";
import InjuryTypeEnum from "../../../subjective/enums/treatmentreceived/InjuryTypeEnum";
import MechanismEnum from "../../../subjective/enums/treatmentreceived/MechanismEnum";

export default class OtherInjuriesViewHistory extends Component {

    render() {
        let divs = [];

        if(this.props.otherInjuries == null
            || this.checkOtherInjuryDataAvailability(this.props.otherInjuries)) {
            return <div className={this.props.reportView ? "" : "pl-3"}>No information available</div>;
        }

        if(this.props.otherInjuries.none){
            return (<div className={this.props.reportView ? "" : "pl-3"}>No Other Injuries</div>)
        }

        let oi = this.props.otherInjuries;
        divs.push(
            <div key={"otherInjuriesViewHistory"}>
                <div className={"display-flex"} style={{margin: "3px"}}>
                    <div className={"col-sm-3 no-padding dim14-form-label"}>Related Injury:</div>
                    <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>{oi.relatedInjuries != null
                        ? YesNoEnum[oi.relatedInjuries].name: ""}</div>
                </div>
                <div className={"display-flex"} style={{margin: "3px"}}>
                    <div className={"col-sm-3 no-padding dim14-form-label"}>Date:</div>
                    <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>{oi.relatedInjuriesTime != null
                        ? DateUtil.formatDate(oi.relatedInjuriesTime): ""}</div>
                </div>
                <div className={"display-flex"} style={{margin: "3px"}}>
                    <div className={"col-sm-3 no-padding dim14-form-label"}>Type:</div>
                    <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>{this.enumString(InjuryTypeEnum, oi.type, oi.typeDetail)}</div>
                </div>
                <div className={"display-flex"} style={{margin: "3px"}}>
                    <div className={"col-sm-3 no-padding dim14-form-label"}>Mechanism:</div>
                    <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>{this.enumString(MechanismEnum, oi.mechanism, oi.mechanismDetail)}</div>
                </div>
            </div>
        );

        return(
            <div className={"visit-history-multiple-content-wrapper pl-3"}>
                {divs}
            </div>
        );
    }

    enumString(_class, enumObject, detail) {
        if(enumObject == null || enumObject.length == 0) {
            return (detail && detail != null) ? detail : 'NA';
        }
        let res;
        if(Array.isArray(enumObject)) {
            res = enumObject.map(e => _class[e].name);
        } else {
            res = [];
            res.push(_class[enumObject].name);
        }
        if(detail && detail != null && detail != '') {
            res.push(detail);
        }
        return res.join(', ');
    }

    checkOtherInjuryDataAvailability (otherInjury) {
        return otherInjury.mechanism.length === 0 && otherInjury.mechanismDetail == null
            && otherInjury.relatedInjuries == null && otherInjury.relatedInjuriesTime == null
            && otherInjury.type.length === 0 && otherInjury.typeDetail == null && otherInjury.none == false;

    }
}