import Enum from "../../../../../../enums/Enum";

const MoodEnum = new Enum({
        HAPPY: {name: "Happy"},
        DISMISSIVE: {name: "Dismissive"},
        COMPLAINING: {name: "Complaining"},
        ANGRY: {name: "Angry"},
        NO_REACTION: {name: "No Reaction"},
        OTHER: {name: "Other"}
});

export default MoodEnum;