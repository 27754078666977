import Enum from "../../../enums/Enum";
import React from "react";

const EnumCreditStatus = new Enum({
    PENDING: {name: "Pending", icon: <img className="DISCIconStatus" src={process.env.PUBLIC_URL + "/assets/forms/Icons/Pending.png"}/>},
    CONFIRMED: {name: "Confirmed", icon: <img className="DISCIconStatus" src={process.env.PUBLIC_URL + "/assets/forms/Icons/Confirmed.png"}/>},
    REFUNDED: {name: "Refunded", icon: <img className="DISCIconStatus" src={process.env.PUBLIC_URL + "/assets/forms/Icons/Refunded.png"}/>},
    GIFT_PENDING: {name: "Gift pending", icon: <img className="DISCIconStatus" src={process.env.PUBLIC_URL + "/assets/forms/Icons/PendingGiftIcon.png"}/>},
    GIFTED: {name: "Gifted", icon: <img className="DISCIconStatus" src={process.env.PUBLIC_URL + "/assets/forms/Icons/GiftIcon.png"}/>},
    BILLED: {name: "Billed", icon: <img className="DISCIconStatus" src={process.env.PUBLIC_URL + "/assets/forms/Icons/Confirmed.png"}/>}
});

export default EnumCreditStatus;