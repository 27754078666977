import React, {Component} from 'react'
import './InsuranceEligibilityScreen.css'
import {Image} from "react-bootstrap";

export default class InsuranceDocumentLegend extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {

        return <div className="display-flex flex-row" style={{marginTop: '20px',  fontSize: '12px'}}>
            <Image src={`/assets/insurance/form-pending.png`} width={"13px"}/>
            <div style={{marginLeft: '5px'}}>Pending Claim Form</div>
            <Image src={`/assets/insurance/form-completed.png`} width={"13px"} style={{marginLeft: '20px'}}/>
            <div style={{marginLeft: '5px'}}>Complete Claim Form</div>
            <Image src={`/assets/insurance/report-pending.png`} width={"13px"} style={{marginLeft: '20px'}}/>
            <div style={{marginLeft: '5px'}}>Pending Medical Report</div>
            <Image src={`/assets/insurance/report-completed.png`} width={"13px"} style={{marginLeft: '20px'}}/>
            <div style={{marginLeft: '5px'}}>Complete Medical Report</div>
        </div>
    }
}
