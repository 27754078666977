export default class MinValidator {

    getErrorMessage(prop) {
        return "Field should be more than " + prop;
    }

    validate(value, prop) {
        if (value === null || value === undefined) {
            return true;
        }
        if (prop === null || prop === undefined) {
            return true;
        }
        return value > prop;
    }

}