import React, {Component} from 'react';
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import CheckboxInput from "../../../components/checkbox-input/CheckboxInput";
import FormField from "../../../components/form/FormField";
import {TextInput} from "../../../components";

class MakesSymptomsBetter extends Component {
    render() {
        const {
            selectedLanguage, readOnly, onChange, formFields,
            symptomBetterMovement, symptomBetterMedication, symptomBetterRest, symptomBetterSleep, symptomBetterHeat,
            symptomBetterMassage, symptomBetterOther, symptomBetterOtherDetail,
        } = this.props

        return (
            <div className="container">
                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Make_Symptoms_Better[selectedLanguage]}
                    </div>
                    <div className="col-8 d-flex">
                        <div className="d-flex">
                            <CheckboxInput
                                value={symptomBetterMovement}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomBetterMovement", value)
                                }
                            />
                            <label className="mx-2">
                                {IntakeFormTextEnum.Movement_Exercise[selectedLanguage]}
                            </label>
                        </div>
                        <div className="d-flex">
                            <CheckboxInput
                                value={symptomBetterMedication}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomBetterMedication", value)
                                }
                            />
                            <label className="mx-2">
                                {IntakeFormTextEnum.Medication[selectedLanguage]}
                            </label>
                        </div>
                        <div className="d-flex">
                            <CheckboxInput
                                value={symptomBetterRest}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomBetterRest", value)
                                }
                            />
                            <label className="mx-2">
                                {IntakeFormTextEnum.Rest[selectedLanguage]}
                            </label>
                        </div>
                        <div className="d-flex">
                            <CheckboxInput
                                value={symptomBetterSleep}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomBetterSleep", value)
                                }
                            />
                            <label className="mx-2">
                                {IntakeFormTextEnum.Sleep[selectedLanguage]}
                            </label>
                        </div>
                        <div className="d-flex">
                            <CheckboxInput
                                value={symptomBetterHeat}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomBetterHeat", value)
                                }
                            />
                            <label className="mx-2">
                                {IntakeFormTextEnum.Heat[selectedLanguage]}
                            </label>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-4 dim14-form-label"></div>
                    <div className="col-8 d-flex pt-0">
                        <div className="d-flex">
                            <CheckboxInput
                                value={symptomBetterMassage}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomBetterMassage", value)
                                }
                            />
                            <label className="mx-2">
                                {IntakeFormTextEnum.Massage[selectedLanguage]}
                            </label>
                        </div>
                        <CheckboxInput
                            value={symptomBetterOther}
                            disabled={readOnly}
                            onChange={(value) =>
                                onChange("symptomBetterOther", value)
                            }
                        />
                        <label className="mx-2">
                            {IntakeFormTextEnum.Symptoms_Other[selectedLanguage]}
                        </label>
                        {symptomBetterOther ? (
                            <FormField
                                ref={(formField) => formFields.push(formField)}
                                required
                                validateOn={
                                    readOnly ? true : symptomBetterOtherDetail
                                }
                            >
                                <TextInput
                                    className="mx-2"
                                    value={symptomBetterOtherDetail}
                                    disabled={readOnly}
                                    onChange={(value) =>
                                        onChange("symptomBetterOtherDetail", value)
                                    }
                                />
                            </FormField>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default MakesSymptomsBetter;