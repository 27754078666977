import React, {Component} from "react";
import {Button} from "react-bootstrap";
import {DialogActions} from "@material-ui/core";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import {FormField} from "../../../../components/form";
import PrimeDateInput from "../../../../components/date-input/PrimeDateInput";
import moment from "moment";
import DateUtil from "../../../../utils/DateUtil";

export default class IntraDaySelectionModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: DateUtil.isBefore(props.startDate, new Date()) ? new Date() : moment.utc(props.startDate).toDate(),
            selectedDate: DateUtil.isBefore(props.startDate, new Date()) ? new Date() : moment.utc(props.startDate).toDate(),
        }
    }

    selectDate() {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        this.props.close();
        this.props.onSelect(this.state.selectedDate);
    }

    render() {
        this.formFields = [];

        return (
            <>
                <div className="container" style={{minWidth: 300}}>
                    <div className="row">
                        <div className="col-12">
                            Daily schedule can be updated after last appointment date
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            Start Date
                        </div>
                        <div className="col-8">
                            <FormField ref={formField => this.formFields.push(formField)}
                                       required validateOn={this.state.selectedDate}>
                                <PrimeDateInput containerStyle={{width: "100%"}}
                                                minDate={this.state.startDate} value={this.state.selectedDate}
                                                onChange={value => this.setState({selectedDate: value})}/>
                            </FormField>
                        </div>
                    </div>
                </div>
                <DialogActions>
                    <Button variant="light" onClick={() => this.props.close()}>Cancel</Button>
                    <Button variant="success" onClick={() => this.selectDate()}>Select Date</Button>
                </DialogActions>
            </>
        )
    }

}