import React from 'react';
import {connect} from 'react-redux';
import {userActions} from '../../../services/actions';
import "./Login.css";

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.props.dispatch(userActions.logout());

        this.state = {
            username: '',
            password: '',
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        const {name, value} = e.target;
        this.setState({[name]: value});
    }

    handleSubmit(e) {
        e.preventDefault();
        this.setState({submitted: true});
        const {username, password} = this.state;
        const {dispatch} = this.props;
        if (username && password) {
            dispatch(userActions.login(username.trim(), password));
        }
    }

    render() {
        const {username, password, submitted} = this.state;
        return (
            <div className="login-page">
                <div className="container">
                    <div className="row">
                    <div className="col-md-6 col-xl-4 mx-auto col-12">
                    <div className={"login-form"}>
                        <form name="form" onSubmit={this.handleSubmit}>
                            <div className={'form-group' + (submitted && !username ? ' has-error' : '')}>
                                <label htmlFor="username">E-mail</label>
                                <input type="text" className="form-control" name="username" value={username}
                                       onChange={this.handleChange}/>
                                {submitted && !username &&
                                <div className="help-block">Username is required</div>
                                }
                            </div>
                            <div className={'mb-0 form-group' + (submitted && !password ? ' has-error' : '')}>
                                <label htmlFor="password">Password</label>
                                <input type="password" className="form-control" name="password" value={password}
                                       onChange={this.handleChange}/>
                                {submitted && !password &&
                                <div className="help-block">Password is required</div>
                                }
                            </div>
                            <div className="form-group">
                                <a href="/forgotPassword">Forgot password?</a>
                            </div>
                            <div className="form-group mt-4">
                                <button className="btn btn-success btn-block">Login</button>
                            </div>
                        </form>
                    </div>
                    </div>
                    </div>
                </div>
           </div>
        );
    }
}

function mapStateToProps(state) {
    return {
    };
}

const connectedLoginPage = connect(mapStateToProps)(LoginPage);

export {connectedLoginPage as LoginPage};
