import React, {Component} from 'react';
import {Tab, Tabs} from 'react-bootstrap';
import StaffCard from './StaffCard';
import TextInput from "../../../components/text-input/TextInput";

class StaffCardView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchText: '',
            staffList: this.props.staffList,
            filteredStaffList: this.props.staffList
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.staffList !== this.props.staffList) {
            this.setState({
                searchText: '',
                staffList: this.props.staffList,
                filteredStaffList: this.props.staffList
            });
        }
    }

    handleSearch = (val) => {
        if (val) {
            this.setState({searchText: val});
            const lowerCaseVal = val.toLowerCase();
            let filteredStaffsByVal = this.state.staffList.filter(staff => {
                return (staff.name.toLowerCase().includes(lowerCaseVal) ||
                    staff.speciality.name.toLowerCase().includes(lowerCaseVal) ||
                    staff.mobilePhone.includes(lowerCaseVal) ||
                    staff.departments.map(d => d.name.toLowerCase()).includes(lowerCaseVal) ||
                    staff.departments.map(d => d.clinic.name.toLowerCase()).includes(lowerCaseVal) ||
                    staff.departments.map(d => d.clinic.country.toLowerCase()).includes(lowerCaseVal)
                );
            });
            this.setState({filteredStaffList: filteredStaffsByVal});
        } else {
            this.setState({searchText: '',filteredStaffList: this.state.staffList});
        }
    }

    render() {
        let departments = [...new Set(this.state.filteredStaffList.flatMap(staff => staff.departments).map(department => department.name))];
        return (
            <div>
                <div style={{display: 'flex', justifyContent: 'flex-end', marginBottom: '5px', marginRight: '20px'}}>
                    <TextInput className="p-1" placeholder="Search ..."
                               value={this.state.searchText}
                               onChange={this.handleSearch}/>
                </div>
                <div style={{marginLeft: '20px', marginRight: '20px'}}>
                    <Tabs defaultActiveKey="all" id="staffcardview">
                        <Tab eventKey="all" title="All Departments">
                            <div className={"container-fluid"}>
                                <div className={"row"}>
                                    {
                                        this.state.filteredStaffList.map((staff, idx) =>
                                            <StaffCard
                                                key={idx}
                                                name={staff.name}
                                                speciality={staff.speciality.name}
                                                departments={staff.departments}
                                                mobilePhone={staff.mobilePhone}
                                                photo={staff.thumbnail}
                                                id={staff.id}
                                                email={staff.email}
                                            />)
                                    }
                                </div>

                            </div>
                        </Tab>
                        {
                            departments.map((department, idx) => (
                                <Tab key={idx} eventKey={department} title={department}>
                                    <div style={{
                                        display: 'flex',
                                        flexWrap: 'wrap',
                                        alignContent: 'space-between',
                                        justifyContent: 'center',
                                        marginTop: '5px'
                                    }}>
                                        {
                                            this.state.filteredStaffList
                                                .filter(staff => staff.departments.map(d => d.name).includes(department))
                                                .map((staff, idx) =>
                                                    <StaffCard
                                                        key={idx}
                                                        name={staff.name}
                                                        speciality={staff.speciality.name}
                                                        departments={staff.departments}
                                                        mobilePhone={staff.mobilePhone}
                                                        photo={staff.thumbnail}
                                                        id={staff.id}
                                                        email={staff.email}
                                                    />)
                                        }
                                    </div>
                                </Tab>
                            ))
                        }
                    </Tabs>
                </div>
            </div>
        );
    }

}

export default StaffCardView;