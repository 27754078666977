import React, {Component} from "react";
import '../../EMR.css';
import GreenSwitchWithEmptyMessage from "../../../../../components/switch/GreenSwitchWithEmptyMessage";
import ScheduledForEnum from "../enums/treatmentschedule/ScheduledForEnum";
import NextAppointmentInEnum from "../enums/treatmentschedule/NextAppointmentInEnum";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GreenSwitchWithMessage from "../../../../../components/switch/GreenSwitchWithMessage";
import DateUtil from "../../../../../utils/DateUtil";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";

export default class TreatmentScheduleView extends Component {

    state = {}

    render() {
        return (
            <div>
                {this.treatmentSchedule()}
                {this.treatmentPlanCompliance()}
                {this.riskOfHarmFromTreatment()}
            </div>
        );
    }

    treatmentSchedule = () => {
        return (
            <div className="w-100 mb-4">
                <div>
                    <div style={{display: "flex"}}>
                        <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Treatment Schedule <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/>
                        </div>
                        <GreenSwitchWithEmptyMessage
                            emptyMessage={""}
                            checked={this.props.treatmentSchedule && this.isTreatmentScheduleChanged()}/>
                    </div>
                    {this.props.treatmentSchedule &&
                    (
                        this.props.treatmentSchedule.numberOfRecommendedSessions != null ||
                        (this.props.treatmentSchedule.numberOfScheduledSessions != null ) ||
                        (this.props.treatmentSchedule.nextAppointmentIsIn != null ) ||
                        (this.props.treatmentSchedule.numberOfReassessedSessions != null || this.props.treatmentSchedule.reassessedSessionsDate != null)
                    ) &&
                    <div className="w-100 content-card pl-2 mb-2 pt-2">
                        {this.props.treatmentSchedule && this.props.treatmentSchedule.numberOfRecommendedSessions != null &&
                        <div className={"row m-0"}>
                            <div className={"col-4 font-weight-bold"}>The patient is recommended for</div>
                            <div className={"col-1 font-weight-bold"}>:</div>
                            <div
                                className={"col-7"}>{this.props.treatmentSchedule.numberOfRecommendedSessions} Sessions
                            </div>
                        </div>
                        }

                        {this.props.treatmentSchedule && this.props.treatmentSchedule.numberOfScheduledSessions != null &&
                        <div className={"row m-0"}>
                            <div className={"col-4 font-weight-bold"}>The patient is scheduled for</div>
                            <div className={"col-1 font-weight-bold"}>:</div>
                            <div
                                className={"col-7"}>{this.props.treatmentSchedule.numberOfScheduledSessions} Sessions {this.props.treatmentSchedule.scheduledSessionsFrequency && <>/{ScheduledForEnum[this.props.treatmentSchedule.scheduledSessionsFrequency].name}</>}
                            </div>
                        </div>
                        }

                        {this.props.treatmentSchedule && this.props.treatmentSchedule.nextAppointmentIsIn != null &&
                        <div className={"row m-0"}>
                            <div className={"col-4 font-weight-bold"}>Next appointment is in</div>
                            <div className={"col-1 font-weight-bold"}>:</div>
                            <div
                                className={"col-7"}>{this.props.treatmentSchedule.nextAppointmentIsIn}   {this.props.treatmentSchedule.nextAppointmentFrequency && <>/{NextAppointmentInEnum[this.props.treatmentSchedule.nextAppointmentFrequency].name}</>}
                            </div>
                        </div>
                        }

                        {this.props.treatmentSchedule && (
                            (this.props.treatmentSchedule.numberOfReassessedSessions != null && this.props.treatmentSchedule.numberOfReassessedSessions !== "") || this.props.treatmentSchedule.reassessedSessionsDate != null) &&
                        <div className={"row m-0"}>
                            <div className={"col-4 font-weight-bold"}>The patient will be reassessed in</div>
                            <div className={"col-1 font-weight-bold"}>:</div>
                            <div className={"col-7 d-flex flex-column"}>
                                {this.props.treatmentSchedule.numberOfReassessedSessions && (<>After {this.props.treatmentSchedule.numberOfReassessedSessions} sessions <br/></>)}
                                {DateUtil.formatDateDoMMYY(this.props.treatmentSchedule.reassessedSessionsDate)}
                            </div>
                        </div>
                        }
                    </div>
                    }

                </div>
            </div>
        )
    }

    treatmentPlanCompliance = () => {
        const compliance = this.props.treatmentPlanCompliance;
        return (
            <div className="w-100 mb-4">
                <div style={{display: "flex"}}>
                    <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Compliance with
                        Treatment Plan
                    </div>
                    <GreenSwitchWithEmptyMessage
                        emptyMessage={""}
                        checked={compliance && this.isTreatmentPlanComplianceChanged()}/>
                </div>
                {compliance && (compliance.value !== null || compliance.details) &&
                <div className="w-100 content-card pl-2 mb-2">
                    <div className={"row m-0"}>
                        <div className={"col-12"}>
                            {compliance.value !== null && compliance.value  > 0 ? compliance.value : "0"}%
                            {compliance.details ? "- "
                                + compliance.details : ""}
                        </div>
                    </div>
                </div>}
            </div>
        )
    }

    riskOfHarmFromTreatment = () => {
        let isAllGood = this.props.riskOfHarmFromTreatment && this.props.riskOfHarmFromTreatment.isAllGood;
        let riskOfHarmFromTreatmentToggleOn = isAllGood || (this.props.riskOfHarmFromTreatment && this.isRiskOfHarmChanged());

        return (
            <div className="w-100">
                <div style={{display: "flex"}}>
                    <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Is there Risk of Harm
                        from planned Care of
                        Treatment ? <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/>
                    </div>
                    <GreenSwitchWithMessage
                        message={isAllGood ? "No Risk" : ""}
                        checked={this.props.riskOfHarmFromTreatment && riskOfHarmFromTreatmentToggleOn}/>
                </div>
                {this.props.riskOfHarmFromTreatment &&
                (
                    this.props.riskOfHarmFromTreatment.whatRisk ||
                    this.props.riskOfHarmFromTreatment.hasPatientInformed != null ||
                    this.props.riskOfHarmFromTreatment.didPatientGiveConsent != null ||
                    this.props.riskOfHarmFromTreatment.arePrecautionsNecessary
                ) &&
                <div className="w-100 content-card pl-2 mb-2">
                    {this.props.riskOfHarmFromTreatment && this.props.riskOfHarmFromTreatment.whatRisk &&
                    <div className={"row m-0"}>
                        <div className={"col-4 font-weight-bold"}>If yes, what risk?</div>
                        <div className={"col-8 display-flex"}>{this.props.riskOfHarmFromTreatment.whatRisk}</div>
                    </div>
                    }

                    {this.props.riskOfHarmFromTreatment && this.props.riskOfHarmFromTreatment.hasPatientInformed != null &&
                    <div className={"row m-0"}>
                        <div className={"col-4 font-weight-bold"}>If yes, has the patient been informed?</div>
                        <div className={"col-2"}>
                            {this.riskOfHarmYesNoTemplate(this.props.riskOfHarmFromTreatment.hasPatientInformed)}
                        </div>
                        <div className={"col-6"}>{this.props.riskOfHarmFromTreatment.patientInformedDetail}</div>
                    </div>
                    }

                    {this.props.riskOfHarmFromTreatment && this.props.riskOfHarmFromTreatment.didPatientGiveConsent != null &&
                    <div className={"row m-0"}>
                        <div className={"col-4 font-weight-bold"}>If yes, did the patient give consent?</div>
                        <div className={"col-2"}>
                            {this.riskOfHarmYesNoTemplate(this.props.riskOfHarmFromTreatment.didPatientGiveConsent)}
                        </div>
                        <div className={"col-6"}>{this.props.riskOfHarmFromTreatment.patientGiveConsentDetail}</div>
                    </div>
                    }

                    {this.props.riskOfHarmFromTreatment && this.props.riskOfHarmFromTreatment.arePrecautionsNecessary != null &&
                    <div className={"row m-0"}>
                        <div className={"col-4 font-weight-bold"}>Are extra precautions necessary?</div>
                        <div className={"col-2"}>
                            {this.riskOfHarmYesNoTemplate(this.props.riskOfHarmFromTreatment.arePrecautionsNecessary)}
                        </div>
                        <div className={"col-6"}>{this.props.riskOfHarmFromTreatment.precautionsNecessaryDetail}</div>
                    </div>
                    }
                </div>
                }
            </div>
        )
    }

    riskOfHarmYesNoTemplate = (field) => {
        let yesNoDiv;
        if (field) {
            yesNoDiv = (
                <div>
                    <FontAwesomeIcon icon={["fas", "check"]} color={"#7aae00"} style={{marginRight: "5px"}}/>
                    Yes
                </div>
            )
        } else {
            yesNoDiv = (
                <div>
                    <FontAwesomeIcon icon={["fas", "times"]} color={"red"} style={{marginRight: "8px"}}/>
                    No
                </div>
            )
        }

        return yesNoDiv;
    }

    isTreatmentScheduleChanged = () => {
        return (
            (this.props.treatmentSchedule.numberOfRecommendedSessions != null && this.props.treatmentSchedule.numberOfRecommendedSessions.length != 0) ||
            (this.props.treatmentSchedule.numberOfScheduledSessions != null && this.props.treatmentSchedule.numberOfScheduledSessions.length != 0) ||
            (this.props.treatmentSchedule.nextAppointmentIsIn != null && this.props.treatmentSchedule.nextAppointmentIsIn.length != 0) ||
            (this.props.treatmentSchedule.numberOfReassessedSessions != null && this.props.treatmentSchedule.numberOfReassessedSessions.length != 0) ||
            this.props.treatmentSchedule.scheduledSessionsFrequency != null ||
            this.props.treatmentSchedule.nextAppointmentFrequency != null ||
            this.props.treatmentSchedule.reassessedSessionsDate != null
        );
    }

    isTreatmentPlanComplianceChanged = () => {
        return (
            (this.props.treatmentPlanCompliance.details != null && this.props.treatmentPlanCompliance.details.length != 0) ||
            this.props.treatmentPlanCompliance.value != null
        );
    }

    isRiskOfHarmChanged = () => {
        return (
            (this.props.riskOfHarmFromTreatment.whatRisk != null && this.props.riskOfHarmFromTreatment.whatRisk.length != 0) ||
            (this.props.riskOfHarmFromTreatment.patientInformedDetail != null && this.props.riskOfHarmFromTreatment.patientInformedDetail.length != 0) ||
            (this.props.riskOfHarmFromTreatment.patientGiveConsentDetail != null && this.props.riskOfHarmFromTreatment.patientGiveConsentDetail.length != 0) ||
            (this.props.riskOfHarmFromTreatment.precautionsNecessaryDetail != null && this.props.riskOfHarmFromTreatment.precautionsNecessaryDetail.length != 0) ||
            this.props.riskOfHarmFromTreatment.hasPatientInformed != null ||
            this.props.riskOfHarmFromTreatment.didPatientGiveConsent != null ||
            this.props.riskOfHarmFromTreatment.arePrecautionsNecessary != null
        )
    }
}