import React, {Component} from "react";
import '../../EMR.css';
import PostureEnum from "../enums/posture/PostureEnum";

export default class PostureView extends Component {

    render() {
        if (this.props.posture == null
            || this.props.posture.items == null
            || this.props.posture.items.length == 0){
            return null;
        }

        return(
            <div className={"row content-card"} style={{marginTop:"20px"}}>
                <table className={"emr-table"}>
                    <tbody>
                        <tr>
                            <th>Standing</th>
                            <th>Sitting</th>
                            <th>Spine</th>
                            <th>Prone</th>
                        </tr>
                        {this.props.posture.items.length != 0 ?
                        this.props.posture.items.map((item,index) =>
                        {
                            return (
                            <tr key={"specialTestsModal" + index} style={{backgroundColor: "white"}}>
                                <td> {item.standing == null && item.standingComment == null ? "" : PostureEnum[item.standing] != null? PostureEnum[item.standing].name : item.standingComment}</td>
                                <td> {item.sitting == null && item.sittingComment == null ? "" : PostureEnum[item.sitting] != null? PostureEnum[item.sitting].name : item.sittingComment}</td>
                                <td> {item.spine == null && item.spineComment == null ? "" : PostureEnum[item.spine] != null? PostureEnum[item.spine].name : item.spineComment}</td>
                                <td> {item.prone == null && item.proneComment == null ? "" : PostureEnum[item.prone] != null? PostureEnum[item.prone].name : item.proneComment}</td>
                            </tr> );
                        })
                         : ''
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}