import Enum from "../../../../../../enums/Enum";

const DermatomesScreenRowColorsEnum = new Enum({
    DERMATOMESCERVICAL: {color: "#f0ecff"},
    DERMATOMESTHORACIC: {color: "#dbe6f7"},
    DERMATOMESLUMBAR: {color: "#b2d4eb"},
    DERMATOMESSACRAL: {color: "#e3d7ca"},
    MYOTOMESCERVICAL: {color: "#f0ecff"},
    MYOTOMESTHORACIC: {color: "#dce6f7"},
    MYOTOMESLUMBAR: {color: "#b3d4eb"},
    MYOTOMESSACRAL: {color: "#e4d8cb"},
    REFLEXLEFTSIDE: {color: "#f1ecff"},
    CARNIALNERVES: {color: "#f1ecff"},
    REFLEXRIGHTSIDE: {color: "#b4d5ec"},
    NEURODYNAMICSUPPERLIMB: {color: "#f1ecff"},
    NEURODYNAMICSLOWERLIMB: {color: "#b4d5ec"},
});

export default DermatomesScreenRowColorsEnum;