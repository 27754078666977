import React, {Component} from 'react';
import RemotingService from '../../../services/remoting-service/RemotingService';
import {Col, Container, Row} from 'react-bootstrap';
import {BreadCrumb, BreadCrumbItem} from "../../../components";
import References from './reference/References';
import SponsorshipPanel from "../../sponsorship/SponsorshipPanel";
import InsuranceInformation from '../../insurance/InsuranceInformation';
import PersonalInformation from './personal/PersonalInformation';
import PatientCreditPanel from "./credit/PatientCreditPanel";
import Restrictions from "../../restriction/Restrictions";
import ConsentForms from "../../consentform/ConsentForms";
import './PatientDetail.css';
import PatientAppointmentTable from "./appointment/PatientAppointmentTable";
import PatientBillingInfo from "./billing/PatientBillingInfo";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import AuthService from "../../../services/auth-service/AuthService";


class PatientDetail extends Component {

    constructor(props) {
        super(props);
        this.appointmentTableRef = React.createRef();
        this.patientId = this.props.match.params.patientId;
        this.state = {
            editable: AuthService.userHasAuthority("UPDATE_PATIENT_DETAIL"),
            patientDTO: null,
            selectedView: this.props.location.selectedView || 'appointments',
            updateInvoicesToggle:false,
            formUpdateSwitch:false
        };
    }

    componentDidMount() {
        this.retrieveStaffs();
    }

    retrieveStaffs() {
        RemotingService.getRemoteCall('api/staff/list-base', null, (result) => {
            let staffs = result.items;
            staffs.map(s => {
                s.label = s.name;
                s.value = s.id;
            });

            this.setState({staffs}, () => {this.retrievePatient()});
        });
    }

    retrievePatient = () => {
        RemotingService.getRemoteCall('api/patient/' + this.patientId, null, (patientDTO) => {
            this.setState({patientDTO, formUpdateSwitch: !this.state.formUpdateSwitch});
        });
    };

    scrollToAppointmentTable = () => {
        window.scrollTo({
            top: this.appointmentTableRef.current.offsetTop,
            behavior: 'smooth'
        });
    }

    onConfirmWalletCredit= () => {
        this.setState(prevState => ({
            updateInvoicesToggle: !prevState.updateInvoicesToggle
        }));
    }

    render() {
        return this.state.patientDTO && (
            <div>
                <Container className="patient-detail-page" style={{maxWidth: '100%'}}>

                    <Row>
                        <Col xs={12}>
                            <div style={{margin: '5px', display: 'flex', justifyContent: 'space-between'}}>
                                {this.state.appointmentId ?
                                    <BreadCrumb>
                                        <BreadCrumbItem to="/appointment" label="Appointments"/>
                                        <BreadCrumbItem to={"/appointment?selectedView=" + this.state.selectedView}
                                              label={this.state.selectedView == 'list' ? 'List View' : 'Calendar View'}/>
                                        <BreadCrumbItem active label="Patient Details"/>
                                    </BreadCrumb> :
                                    <BreadCrumb>
                                        <BreadCrumbItem to="/patient" label="Patients"/>
                                        <BreadCrumbItem active label="Patient Details"/>
                                    </BreadCrumb>
                                }
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={12} lg={4} xl={4}>
                            <PersonalInformation personalInformation={this.state.patientDTO}
                                                 editable={this.state.editable}
                                                 formUpdateSwitch={this.state.formUpdateSwitch}
                                                 onPatientInformationUpdate={this.retrievePatient}/>
                        </Col>
                        <Col md={12} lg={8} xl={8}>
                            <Row className="lg-row-margin">
                                <Col className="px-1" md={8}>
                                    <InsuranceInformation patientId={this.state.patientDTO.id}
                                                          editable={this.state.editable}/>
                                </Col>
                                <Col className="pl-2 pr-1 md-row-margin" md={4}>
                                    <ConsentForms patient={this.state.patientDTO}
                                                  onFormUpdate={() => this.retrievePatient()}/>
                                </Col>
                            </Row>
                            <Row style={{'marginTop': '25px'}}>
                                <Col className="px-1" xl={6}>
                                    <SponsorshipPanel patientId={this.state.patientDTO.id}
                                                      staffs={this.state.staffs}
                                                      editable={this.state.editable}/>
                                </Col>
                                <Col className="px-1" xl={6}>
                                    <References reference={this.state.patientDTO.reference}
                                                patientId={this.state.patientDTO.id}
                                                editable={this.state.editable}/>
                                </Col>
                            </Row>
                            <Row style={{'marginTop': '25px'}}>
                                <Col className="px-1" lg={12} xl={6}>
                                    <PatientCreditPanel patient={this.state.patientDTO}
                                                        editable={this.state.editable}
                                                        onConfirmWalletCredit={() => this.onConfirmWalletCredit()}/>
                                </Col>
                                <Col className="pl-2 pr-1" lg={12} xl={6}>
                                    <Restrictions patientId={this.state.patientDTO.id}
                                                  editable={this.state.editable}/>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row ref={this.appointmentTableRef}>
                        <Col xs={12} style={{marginTop: '20px'}}>
                            <Tabs defaultActiveKey={this.state.selectedView} id="patient-detail-tabs">
                                <Tab eventKey="appointments" title="Appointments">
                                    <PatientAppointmentTable patientId={this.state.patientDTO.id}
                                                             appointmentId={this.props.match.params.appointmentId}
                                                             selectedView={this.props.match.params.selectedView}
                                                             selectedDate={this.props.match.params.selectedDate}
                                                             history={this.props.history}
                                                             scrollToAppointmentTable={this.scrollToAppointmentTable}/>
                                </Tab>
                                <Tab eventKey="billing" title="Billing">
                                    <PatientBillingInfo patientId={this.state.patientDTO.id}
                                                        patientName={this.state.patientDTO.name}
                                                        patientEmail={this.state.patientDTO.email}
                                                        mrn={this.state.patientDTO.mrn}
                                                        staffs={this.state.staffs}
                                                        updateInvoicesToggle={this.state.updateInvoicesToggle}/>
                                </Tab>
                            </Tabs>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default PatientDetail;
