import "./MedicalReports.css"
import React, {Component} from "react";
import ReportHeader from "./ReportHeader";
import ReportFooter from "./ReportFooter";
import {Button} from "react-bootstrap";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import VisitNavigator from "../common/VisitNavigator";
import AuthService from "../../../../services/auth-service/AuthService";
import PropTypes from "prop-types";

export default class ReportTemplate extends Component {

    static propTypes = {
        currentVisitIndex: PropTypes.number,
        emr: PropTypes.object,
        appointment: PropTypes.object,
        readOnly: PropTypes.bool,
        generateUrl: PropTypes.string,
        onReportGenerated: PropTypes.func,
        onVisitSelected: PropTypes.func,
        extraParams: PropTypes.func
    }

    static defaultProps = {
        extraParams: () => {return {}}
    }

    constructor(props) {
        super(props);

        const visits = this.props.emr.visits;
        const index = this.props.currentVisitIndex;
        this.state = {visits, index};

        this.props.onVisitSelected(this.state.visits[index]);
    }

    render() {
        const visit = this.state.visits[this.state.index];

        return (
            <div className={"container medical-reports"} style={{margin: "10px 0px 10px 0px", display: "block"}}>
                <div className={"row"} style={{marginTop: "10px"}}>
                    <div className={"col-7 mt-2"} style={{textAlign: "left", fontSize: 18}}>
                        <b>Report Summary</b>
                    </div>

                    <div className="col-5 m-0 p-0">
                        <VisitNavigator
                            visits={this.state.visits}
                            currentVisitIndex={this.props.currentVisitIndex}
                            selectedIndex={this.state.index}
                            onDateChange={(index) => {
                                this.setState({index});
                                this.props.onVisitSelected(this.state.visits[index]);
                            }}
                            showProvider={false}
                        />
                    </div>
                </div>

                <div className={"row"} style={{marginTop: "10px", paddingLeft: "0px", display: "block"}}>
                    <ReportHeader emr={this.props.emr} visit={visit} staff={visit.appointment.staff}/>
                </div>

                <div className={"row"} style={{marginTop: "20px", display: "block", fontSize: 16, fontWeight: "bold"}}>
                    To Whom It May Concern:
                </div>

                <div className={"row"} style={{marginTop: "10px"}}>
                    {this.props.children}
                </div>

                <div className={"row"} style={{marginTop: "10px"}}>
                    <ReportFooter value={this.state.doctorNotes}
                                  readOnly={this.props.readOnly}
                                  onChange={(doctorNotes) => {
                                      this.setState({doctorNotes})
                                  }} clinicAdress={this.props.appointment.room.clinic.address}
                                  staff={visit.appointment.staff}/>
                </div>

                <div className={"row"} style={{marginTop: "10px", float: "right"}}>
                    {AuthService.userHasAuthority("GENERATE_EMR_REPORT") &&
                    <Button variant="success"
                            disabled={this.state.index != this.props.currentVisitIndex || AuthService.getUser().name !== visit.appointment.staff.name}
                            onClick={() => {
                                if (!visit) {
                                    this.props.onReportGenerated();

                                } else {
                                    let params = {
                                        visitId: visit.id,
                                        doctorNotes: this.state.doctorNotes,
                                        ...this.props.extraParams()
                                    };

                                    RemotingService.postRemoteCall(
                                        this.props.generateUrl,
                                        params,
                                        this.props.onReportGenerated
                                    )
                                }
                            }}>
                        Generate Report
                    </Button>
                    }
                </div>

            </div>
        );
    }

}
