import {history} from '../../utils/history';
import {alertActions} from './index';
import {UserConstants} from '../constants';
import AuthService from '../auth-service/AuthService';
import {onlyUnique} from "../../utils/ArrayUtil";
import RemotingService from "../remoting-service/RemotingService";
import groupBy from "lodash/groupBy";

export const userActions = {
    login,
    logout
};

function login(username, password) {
    return dispatch => {
        dispatch(request({username, name: username}));

        AuthService.login(username, password)
            .then(
                user => {
                    AuthService.retrieveUserData(userData => {
                        Object.assign(user, userData);

                        localStorage.setItem('user', JSON.stringify(user));
                        localStorage.setItem('clinics', JSON.stringify(user.clinics.map(c => c.id)));
                        localStorage.setItem('countries', JSON.stringify(user.clinics.map(c => c.country).filter(onlyUnique)));
                        dispatch(success(user));

                        RemotingService.getRemoteCall('api/clinic/listAll', null, (result) => {
                            localStorage.setItem('clinicsByCountry', JSON.stringify(groupBy(result.items, c => c.country)));

                            history.push('/appointment');
                            window.location.reload(true);
                        });
                    });
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error('Authentication Error', error));
                }
            );
    };

    function request(user) {
        return {type: UserConstants.LOGIN_REQUEST, user}
    }

    function success(user) {
        return {type: UserConstants.LOGIN_SUCCESS, user}
    }

    function failure(error) {
        return {type: UserConstants.LOGIN_FAILURE, error}
    }
}

function logout() {
    AuthService.logout();
    return {type: UserConstants.LOGOUT};
}