import * as ArrayUtils from "../../../../../utils/ArrayUtil";
import {GenericChecker} from "../GenericChecker";

export class PreviousTreatmentReceivedChecker extends GenericChecker {
    static isDataEntered(entity) {
        if (entity == null) {
            return false;
        }
        return !ArrayUtils.isEmpty(entity.conservative)
            || !ArrayUtils.isEmpty(entity.other);
    }
}