import React from 'react'

const LogoKuwait = () => {
    return (
        <div className="Logo">
            <img width={175} height={55} src={process.env.PUBLIC_URL + '/assets/disc-kuwait.png'} alt=""/>
        </div>
    )
};

export default LogoKuwait;
