class EventAppointmentMap {

    map = {};

    put(key, value) {
        this.map[key] = value;
    }

    get(key) {
        return this.map[key];
    }

    clear() {
        this.map = {};
    }

}

export default new EventAppointmentMap();