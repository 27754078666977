import Enum from "../../../../../../enums/Enum";

const ExaminationEnum = new Enum({
    SHOULDER: {name: "Shoulder"},
    ELBOW: {name: "Elbow"},
    WRIST: {name: "Wrist"},
    MUSCLE: {name: "Muscle"},
    HIP: {name: "Hip"},
    BACK: {name: "Back"},
    KNEE: {name: "Knee"},
    ANKLE: {name: "Ankle"},
    FOOT: {name: "Foot"}
});

export default ExaminationEnum;