import Enum from "../../../../../../enums/Enum";

const FrequencyEnum = new Enum({
    DAILY: {name: "Daily"},
    WEEKLY: {name: "Weekly"},
    MONTHLY: {name: "Monthly"},
    OTHER: {name: "Other"},
    UNSURE: {name: "Unsure"}
});

export default FrequencyEnum;