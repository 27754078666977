import Enum from "../../../../../../enums/Enum";

const JobPhysicalityEnum = new Enum({
    LIGHT_LIFTING: {name: "Light movement and lifting <10kgs", nameInArabic: "حركة خفيفة ورفع أغراض (أقل من 10 كيلوجرام)"},
    MODERATE_LIFTING: {name: "Moderate lifting and bending", nameInArabic: "رفع أغراض متوسطة مع الانحناء"},
    HEAVY_LIFTING: {name: "Heavy lifting and bending >30kgs", nameInArabic: "رفع أغراض ثقيلة مع الانحناء (أكثر من 30 كيلوجرام)"},
    PROLONGED_SITTING: {name: "Prolonged sitting", nameInArabic: "الجلوس لفترة طويلة"},
    PROLONGED_STANDING: {name: "Prolonged standing", nameInArabic: "الوقوف لفترة طويلة"}
});

export default JobPhysicalityEnum;