import React, {Component} from 'react'
import "./EMR.css"
import RemotingService from "../../../services/remoting-service/RemotingService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Subjective from "./subjective/Subjective";
import Assessment from "./assessment/Assessment";
import Treatment from "./treatment/Treatment";
import Diagnosis from "./diagnosis/Diagnosis";
import Header from "./header/Header";
import NotificationService from "../../../services/notification-service/NotificationService";
import AuthService from "../../../services/auth-service/AuthService";
import confirmDialog from "../../../components/dialog/ConfirmDialog";
import VisitHistory from "./visithistory/VisitHistory";
import Prescription from "./prescription/Prescription";
import MedicalReports from "./report/MedicalReports";
import EMRDashboard from "./dashboard/EMRDashboard";
import DateUtil from "../../../utils/DateUtil";
import EmrForms from "./visitforms/EmrForms";
import MissingDataChecker from "../home/header/MissingDataChecker";
import queryString from "query-string";
import Result from "./result/Result";

export default class EMR extends Component {

    state = {
        queried: false,
        formFields: new Map(),
        disabled: false
    }

    componentDidMount() {
        if (AuthService.isAdminOrManager()) {
            this.setState({selectedTab: "visithistory"});
        }
        const {mode, patientid: patientId, appointmentid} = this.props.match.params;
        const urlParams = queryString.parse(this.props.location.search);

        const visitMode = mode === "visit";
        const url = `api/emr/${visitMode ? 'visit' : 'view'}/${patientId}/${appointmentid}/`;

        this.setState({disabled: !visitMode, selectedTab: this.state.selectedTab || urlParams.tab});

        const params = {
            id: appointmentid
        };


        const appointmentPromise = new Promise((resolve, reject) => {
            RemotingService.getRemoteCall('api/appointment/retrieve', params, (appointment) => {
                resolve(appointment);
            });
        });

        const emrPromise = new Promise((resolve, reject) => {
            RemotingService.postRemoteCall(url, null, (emr) => {
                resolve(emr);
            })
        });

        Promise.all([appointmentPromise, emrPromise]).then((values) => {
            const appointment = values[0];
            const emr = values[1];

            RemotingService.getRemoteCall('api/visit_history', {emrId: emr.id}, (result) => {
                this.setState({ visitMetaData: result });
            });

            this.setState((state) => {
                const currentVisitIndex = emr.visits.findIndex(visit => visit.appointment.id == appointmentid);
                const currentVisit = emr.visits[currentVisitIndex]
                if (!!currentVisit && (currentVisit.missingPoints == null || currentVisit.totalPoints == null)) {
                    const [missingPoints, totalPoints] = MissingDataChecker.getMissingPointsForVisit(currentVisit);
                    currentVisit.missingPoints = missingPoints;
                    currentVisit.totalPoints = totalPoints;
                }

                return {
                    ...state,
                    appointment: appointment,
                    emr: emr,
                    queried: true,
                    currentVisitIndex: currentVisitIndex,
                    currentVisit: currentVisit
                };
            });
        });
    }

    render() {
        if (this.state.queried == false) {
            return (<div></div>);
        }

        const {emr, currentVisitIndex, currentVisit, appointment, disabled, formFields , visitMetaData} = this.state;
        const visits = emr.visits;
        const lastVisit = emr.visits.length > 0 ? emr.visits[emr.visits.length - 1] : null;
        const patientId = this.props.match.params.patientid;

        if (this.props.match.params.mode === "visit" && visits.length === 0) {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Start Visit Error',
                detail: 'The status of the appointment is not suitable for starting a new visit,' +
                    ' or it has been 24 hours since the appointment started.'
            });
        }

        const commonProps = {
            visits,
            currentVisit,
            currentVisitIndex,
            formFields,
            disabled,
            onModalSave: this.onModalSave
        };

        return (<div className={"emr-root"}>
            <div className="emr-header h-100 w-100 pl-4">
                <Header visit={currentVisit} appointment={appointment} patient={appointment.patient}/>
            </div>

            {visits.length === 0 &&
                <div className="no-visit-text">This is the patient's first visit</div>
            }
            {visits.length > 0 && !currentVisit &&
            <div className=" d-flex flex-column text-center">
                <div className="no-visit-text">Visit not started.</div>
                <a href="#" onClick={() => this.goToLastVisit()}>
                    Go to last visit ({DateUtil.formatDateMMM(lastVisit.appointment.startTime)} / {DateUtil.getHourMinute12H(lastVisit.appointment.startTime)})
                </a>
            </div>
            }
            {currentVisit &&
                <>
                    <div className="pl-4">
                        <div className="w-100 mt-2 d-flex flex-row-reverse">
                            {!disabled && AuthService.userHasAuthority("VIEW_EMR_DASHBOARD") &&
                            <div className="d-flex justify-content-center" style={{width: "8%"}}>
                                <Button style={{width: 90}} variant="success"
                                        onClick={this.onModalSave}>
                                    <FontAwesomeIcon icon={["fas", "save"]}/>
                                    {'  Save '}
                                </Button>
                            </div>
                            }
                            {!disabled && AuthService.userHasAuthority("VIEW_EMR_DASHBOARD") && currentVisit.appointmentType == 'FOLLOW_UP' &&
                            <div className="d-flex justify-content-end" style={{width: "8%"}}>
                                <Button style={{minWidth: '160px'}} variant="success"
                                        onClick={this.convertFollowUpToNewComplaintPopUp}>
                                    <FontAwesomeIcon icon={["fas", "plus"]}/>
                                    <span className="ml-1">New Complaint</span>
                                </Button>
                            </div>
                            }
                        </div>
                    </div>

                    <div className="emr-content">
                        <div className="emr-form">
                            <Tabs defaultActiveKey={AuthService.isAdminOrManager() ? 'visithistory' : 'dashboard'} id="emr-tabs"
                                  activeKey={this.state.selectedTab}
                                  onSelect={(key) => {this.setState({selectedTab: key})}}
                                  style={{marginLeft: 20, marginRight: 20, borderRadius: "10px"}}>
                                {AuthService.userHasAuthority("VIEW_EMR_DASHBOARD") &&
                                <Tab eventKey="dashboard" title="Dashboard">
                                    <EMRDashboard emr={emr} appointment={appointment}/>
                                </Tab>
                                }
                                {AuthService.userHasAuthority("VIEW_EMR_SUBJECTIVE") &&
                                <Tab eventKey="subjective" title="Subjective">
                                    <Subjective {...commonProps}
                                                subjective={currentVisit.subjective}
                                    />
                                </Tab>
                                }
                                {AuthService.userHasAuthority("VIEW_EMR_ASSESSMENT") &&
                                <Tab eventKey="assessment" title="Assessment">
                                    <Assessment {...commonProps}
                                                assessment={currentVisit.assessment}
                                                diagnosis={currentVisit.diagnosis}
                                    />
                                </Tab>
                                }
                                {AuthService.userHasAuthority("VIEW_EMR_DIAGNOSIS_BILLING") &&
                                <Tab eventKey="diagnosis" title="Diagnosis&Billing">
                                    <Diagnosis {...commonProps}
                                               diagnosis={currentVisit.diagnosis}
                                               insurance={emr.patient.insurance}
                                    />
                                </Tab>
                                }
                                {AuthService.userHasAuthority("VIEW_EMR_TREATMENT") &&
                                <Tab eventKey="treatment" title="Treatment">
                                    <Treatment {...commonProps}
                                               treatment={currentVisit.treatment}
                                               insurance={emr.patient.insurance}
                                    />
                                </Tab>
                                }
                                {AuthService.userHasAuthority("VIEW_EMR_PRESCRIPTION") &&
                                <Tab eventKey="prescription" title="Prescription">
                                    <Prescription {...commonProps}
                                                  subjective={currentVisit.subjective}
                                                  patientId={patientId}
                                    />
                                </Tab>
                                }
                                {AuthService.userHasAuthority("VIEW_EMR_RESULT") &&
                                    <Tab eventKey="result" title="Addendum & Results">
                                    <Result {...commonProps}
                                            treatment={currentVisit.treatment}
                                            emrId={emr.id}
                                            patientId={patientId}
                                            visitMetaData={visitMetaData}
                                            appointmentid={this.props.match.params.appointmentid}
                                    />
                                </Tab>
                                }
                                {AuthService.userHasAuthority("VIEW_EMR_VISIT_HISTORY") &&
                                <Tab eventKey="visithistory" title="Visit History">
                                    <VisitHistory patient={emr.patient} visitMetaData={visitMetaData} selected={this.state.selectedTab === "visithistory"}/>
                                </Tab>
                                }
                                {AuthService.userHasAuthority("VIEW_EMR_REPORT") &&
                                <Tab eventKey="report-generation" title="Reports">
                                    <MedicalReports emr={emr} appointment={appointment}
                                                    currentVisit={currentVisit}
                                                    currentVisitIndex={currentVisitIndex}/>
                                </Tab>
                                }
                                {AuthService.userHasAuthority("VIEW_EMR_FORMS") &&
                                <Tab eventKey="consent-forms" title="Forms">
                                    <EmrForms {...commonProps} patientId={patientId}/>
                                </Tab>
                                }
                            </Tabs>
                        </div>
                    </div>
                </>
            }
        </div>);
    }

    save = () => {
        const [url, params] = this.getUrlAndParamsForSave();
        RemotingService.postRemoteCall(url, params, (result) => {
            this.props.history.push('/emr');
        }, this.onSaveError);
    }

    onModalSave = () => {
        const [url, params] = this.getUrlAndParamsForSave();
        RemotingService.postRemoteCall(url, params, (result) => {
            this.componentDidMount();
        }, this.onSaveError);
    }

    getUrlAndParamsForSave = () => {
        const url = 'api/emr/save/' + this.props.match.params.patientid
            + '/' + this.props.match.params.appointmentid;

        this.state.currentVisit.doneBy = AuthService.getUser().name;
        let [missingPoints, totalPoints] = MissingDataChecker.getMissingPointsForVisit(this.state.currentVisit);
        this.state.currentVisit.missingPoints = missingPoints;
        this.state.currentVisit.totalPoints = totalPoints;

        const params = {
            visit: this.state.currentVisit,
            emrVersion: this.state.emr.version,
            changedBy: AuthService.getUser().name,
            changedTime: DateUtil.now().toDate()
        }

        return [url, params];
    }

    onSaveError = (response) => {
        console.log(response);
        if (response.status !== 200) {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'EMR Save failed.',
                detail: 'Please refresh the page or try again later.'
            });
        }
    }

    convertFollowUpToNewComplaintPopUp = () => {
        confirmDialog("New Complaint",
            "Are you sure this is a new complaint?",
            () => this.convertFollowUpToNewComplaint());
    }

    convertFollowUpToNewComplaint = () => {
        const url = 'api/emr/convert_follow_up_to_new_complaint/'
            + this.props.match.params.patientid
            + '/' + this.props.match.params.appointmentid;

        RemotingService.postRemoteCall(url, null, (result) => {
            window.location.reload(true);
        });
    }

    goToLastVisit() {
        const lastVisit = this.state.emr.visits[this.state.emr.visits.length - 1];

        RemotingService.getRemoteCall('api/appointment/retrieve', {id: lastVisit.appointment.id}, (appointment) => {
            window.history.replaceState(null, null, lastVisit.appointment.id); // Change browser url to the visit without reloading page
            this.setState((state) => {
                const currentVisitIndex = state.emr.visits.length - 1;
                return {
                    ...state,
                    appointment: appointment,
                    currentVisitIndex: currentVisitIndex,
                    currentVisit: lastVisit
                };
            });
        });
    }

}
