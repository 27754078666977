import React, {Component} from "react";
import Tooltip from "../../../../../components/tooltip/Tooltip";
import PropTypes from "prop-types";

export default class DiagnosisGroup extends Component {

    static propTypes = {
        diagnosisGroup: PropTypes.object,
        showSecondaryItems: PropTypes.bool
    }

    static defaultProps = {
        diagnosisGroup: null,
        showSecondaryItems: false
    }

    constructor(props) {
        super(props);
        this.state = {
            ...this.getDiagnosisItems()
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.diagnosisGroup !== this.props.diagnosisGroup) {
            this.setState({...this.getDiagnosisItems()});
        }
    }

    getDiagnosisItems() {
        if (!this.props.diagnosisGroup) {
            return {};
        }

        let principalItem = null;
        let secondaryItems = [];
        this.props.diagnosisGroup.diagnosisItems.forEach(item => {
            if (item.level == "PRINCIPAL") {
                principalItem = item;
            } else {
                secondaryItems.push(item);
            }
        });

        return {principalItem, secondaryItems};
    }

    render() {
        const {principalItem, secondaryItems} = this.state;
        const {showSecondaryItems} = this.props;

        if (principalItem) {
            let principalTooltip = (
                <div className="d-flex flex-column p-0">
                    <span>{principalItem.code} {principalItem.description}</span>
                    {
                        !showSecondaryItems && secondaryItems.map(item =>
                            <span className="ml-3 font-italic">{item.code} {item.description}</span>
                        )
                    }
                </div>
            );

            return (
                <div className="d-flex flex-column text-left">
                    <Tooltip title={principalTooltip}>{principalItem.code}</Tooltip>
                    {
                        showSecondaryItems && secondaryItems.map(item =>
                            <Tooltip title={`${item.code} ${item.description}`}>
                                <span className="ml-2 font-italic">{item.code}</span>
                            </Tooltip>
                        )
                    }
                </div>
            )
        }

        return null;
    }

}