import React, { Component } from "react";
import './GradientSlider10Mark3Category.css';
import Slider from "@material-ui/core/Slider";

export default class GradientSlider10Mark3Category extends Component {

    constructor(props) {
        super(props);
        this.sliderDefaultValue = props.value;
    }

    render() {
        const marks = [
            {
                value: 0,
                label: "",
            },
            {
                value: 1,
                label: "",
            },
            {
                value: 2,
                label: "",
            },
            {
                value: 3,
                label: "",
            },
            {
                value: 4,
                label: 4,
            },
            {
                value: 5,
                label: "",
            },
            {
                value: 6,
                label: "",
            },
            {
                value: 7,
                label: 7,
            },{
                value: 8,
                label: "",
            },
            {
                value: 9,
                label: "",
            },
            {
                value: 10,
                label: 10,
            }
        ];

        return (
            <div className={"gradient-slider"}>
                <div className={"gradient-area"} style={{height: "30px"}}>
                    <div className={"row no-margin"} style={{textAlign: "center", height: "100%"}}>
                        <div className={"col-4 no-padding mild-col"}>
                            <div>
                                Mild
                            </div>
                        </div>
                        <div className={"col-4 no-padding moderate-col"} >
                            <div>
                                Moderate
                            </div>
                        </div>
                        <div className={"col-4 no-padding severe-col"} style={{height: "100%", borderRight: "1px white solid"}}>
                            <div>
                                Severe
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"gradient-slider-input"}>
                    <Slider
                        disabled={this.props.disabled}
                        defaultValue={this.sliderDefaultValue}
                        aria-labelledby="discrete-slider-always"
                        step={1}
                        marks={marks}
                        valueLabelFormat={(value) => <div className="label-text">{value}</div>}
                        valueLabelDisplay="on"
                        min={0}
                        max={10}
                        value={this.props.value}
                        onChangeCommitted={this.props.onChange}
                    />
                </div>
        </div>
    );
    }
}
