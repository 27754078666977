import React from 'react'
import {Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import DateUtil from "../../../../utils/DateUtil";
import StringUtil from "../../../../utils/StringUtil";

import PatientUtil from "../../../../utils/PatientUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SpecialStatusIcons from "../../../patient/SpecialStatusIcons";
import InsuranceIcon from "../../../insurance/InsuranceIcon";


export class PatientInfo extends React.Component {

    static LANGUAGE = "LANGUAGE";
    static MALE = "MALE";
    static FEMALE = "FEMALE";

    constructor(props) {
        super(props);
    }

    render() {

        if(this.props.patient == null)
            return (<></>)

        let photoSrc = process.env.PUBLIC_URL + '/assets/patient_profile.png';
        if (this.props.patient.thumbnail) {
            photoSrc = 'data:image/jpeg;base64,' + this.props.patient.thumbnail;
        }
        let insurance;
        if (this.props.patient.insurance) {
            if (this.props.patient.insurance.company) {
                insurance = this.props.patient.insurance.company.name;
            } else if (this.props.patient.insurance.insurer) {
                insurance = this.props.patient.insurance.insurer.name;
            } else {
                insurance = this.props.patient.insurance.customCompany;
            }
        }

        return (
            <>
                <div className={"row"}>

                    <div className={"col-2 text-center"}>
                        <span className={"disc-green d-block"}>{StringUtil.mrn(this.props.patient.mrn)}</span>
                        <Image className={"img-fluid"} style={{width:"96px"}}src={photoSrc} rounded/>
                    </div>
                    <div className={"col-auto"}>
                        <ul className={"list-unstyled mb-0"}>
                            <li>
                                <Link
                                    className={"patient-link"}
                                    style={{whiteSpace: "normal"}}
                                    to={'/patientdetail/' + this.props.patient.id}>{PatientUtil.getPatientName(this.props.patient.firstName,
                                    this.props.patient.middleName,
                                    this.props.patient.lastName)}
                                </Link>
                            </li>
                            <li>
                                <span><b className={"font-weight-bold disc-grey"}>DOB / Age:</b> {DateUtil.formatDateMMM(this.props.patient.birthday)} / {` ${this.props.patient.birthday ? DateUtil.getAge(this.props.patient.birthday) : "-"}`}</span>
                            </li>
                            <li>
                                <span>{`${this.props.patient.gender ? this.props.patient.gender : "-"}`} / {this.props.patient.nationality?.name || "-"}</span>
                            </li>
                            <li>
                                <span><FontAwesomeIcon icon={["fas", "phone"]}/> {this.props.patient.mobilePhone}</span>
                            </li>
                            <li>
                                <div className="display-flex" style={{margin: "0 5px", flexWrap:"wrap"}}>
                                    <SpecialStatusIcons specialStatusList={this.props.specialStatusList}/>
                                </div>
                            </li>
                        </ul>

                    </div>
                    <div className={"col"}>
                        <div className={"row mx-0"}>
                            <div className="col-auto">
                                <ul className={"list-unstyled"}>
                                    <li>
                                        <label className={"mb-0 font-weight-bold disc-grey"}>Occupation</label>
                                    </li>
                                    <li>
                                        <label className={"mb-0 font-weight-bold disc-grey"}>ID No:</label>
                                    </li>
                                    <li>
                                        <label className={"mb-0 font-weight-bold disc-grey"}>Insurance:</label>
                                    </li>
                                </ul>
                            </div>
                            <div className={"col-6"}>
                                <ul className={"list-unstyled"}>
                                    <li>
                                     <span>{this.props.occupation || "-"}</span>
                                    </li>
                                    <li>
                                        <span>{PatientUtil.getPatientId(this.props.patient) || "-"}</span>
                                    </li>
                                    <li>
                                        <span>{this.insuranceExists (this.props.patient.insuranceExists)}</span> <span> {insurance || "-"}</span>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    insuranceExists() {
        const insurance = this.props.patient.insurance;
        let insuranceExists = undefined;
        let isCustomInsurance = false;
        if (insurance) {
            insuranceExists = true;
            isCustomInsurance = (insurance.customTpa != null || !insurance.tpa) && insurance.customCompany != null && insurance.customNetwork != null;
        }

        return <InsuranceIcon insuranceExists={insuranceExists} isCustomInsurance={isCustomInsurance}/>
    }

}
