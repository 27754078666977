import Enum from "../../../../../../enums/Enum";

const BloodPressurePositionEnum = new Enum({

        SITTING: {name: "Sitting"},
        STANDING: {name: "Standing"},
        PRONE: {name: "Prone"},
        SUPINE: {name: "Supine"}
});

export default BloodPressurePositionEnum;