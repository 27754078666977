import React, {Component} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DirectBillingObservationTypeEnum from "../../enums/DirectBillingObservationTypeEnum";
import {FileUploader, SelectInput, TextareaInput, TextInput} from "../../../../components";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import {base64ToBlob, downloadBlob} from "../../../../utils/FileDownloader";
import confirmDialog from "../../../../components/dialog/ConfirmDialog";
import {FormField} from "../../../../components/form";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import NotificationService from "../../../../services/notification-service/NotificationService";

export default class ObservationsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            observations: [],
            newObservation: {_addMode: true}
        };
    }

    componentDidMount() {
        this.retrieveObservations();
    }

    retrieveObservations() {
        RemotingService.getRemoteCall(`api/insurance/coordinator/direct-billing-item/${this.props.directBillingItemId}/observation`, null, observations => {
            this.setState({observations});
        });
    }

    showAttachmentTypeErrorMessage() {
        NotificationService.showNotification({
            severity: 'error',
            summary: 'Validation Error',
            detail: 'Attachment to observation should be in pdf format.'
        });
    }

    saveObservation(observation) {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        const params = {
            type: observation.type,
            code: observation.code,
            value: observation.type === "FILE" ? observation.fileValue.content : observation.value,
            filename: observation.type === "FILE" ? observation.fileValue.name : null,
            valueType: observation.valueType
        }

        if (observation.type === "FILE" && observation.fileValue.mimeType !== "application/pdf") {
            this.showAttachmentTypeErrorMessage();
            return;
        }

        RemotingService.postRemoteCall(`api/insurance/coordinator/direct-billing-item/${this.props.directBillingItemId}/observation`, params, () => {
            this.retrieveObservations();
            this.setState({newObservation: {_addMode: true}});
        });
    }

    updateObservation(observation) {
        const params = {
            value: observation.type === "FILE" ? observation.fileValue.content : observation.value,
            filename: observation.type === "FILE" ? observation.fileValue.name : null,
            valueType: observation.valueType
        };

        if (observation.type === "FILE" && observation.fileValue.mimeType !== "application/pdf") {
            this.showAttachmentTypeErrorMessage();
            return;
        }

        RemotingService.postRemoteCall(`api/insurance/coordinator/direct-billing-item/observation/${observation.id}/update`, params, () => {
            this.retrieveObservations();
        });
    }

    deleteObservation(observation) {
        confirmDialog("Delete Observation", "Do you want to delete observation?", () => {
            RemotingService.deleteRemoteCall(`api/insurance/coordinator/direct-billing-item/observation/${observation.id}`, null, () => {
                this.retrieveObservations();
            });
        });
    }

    editObservation(observation) {
        observation._editMode = true;
        if (observation.type === "FILE") {
            observation.fileValue = {
                content: observation.value,
                name: observation.filename,
                mimeType: "application/pdf"
            };
        }
        this.forceUpdate();
    }

    cancelEdit() {
        this.retrieveObservations();
    }

    getCode(observationType) {
        if (observationType === "TEXT") {
            return "Text";
        } else if (observationType === "FILE") {
            return "PDF";
        } else {
            return null;
        }
    }

    addTypeTemplate(observation) {
        return (
            <FormField ref={formField => this.formFields.push(formField)}
                       required validateOn={observation.type}>
                <SelectInput searchEnabled={false}
                             displayProperty="name"
                             valueProperty="key"
                             options={DirectBillingObservationTypeEnum.toArray()}
                             value={observation.type}
                             onChange={(type) => {
                                 observation.value = null;
                                 observation.fileValue = null;
                                 observation.code = this.getCode(type);
                                 observation.type = type;
                                 this.forceUpdate();
                             }}/>
            </FormField>
        )
    }

    addValueTemplate(observation) {
        if (observation.type === "TEXT") {
            return (
                <FormField ref={formField => this.formFields.push(formField)}
                           required validateOn={observation.value}>
                    <TextareaInput className="w-100"
                                   value={observation.value}
                                   onChange={(value) => {
                                       observation.value = value;
                                       this.forceUpdate();
                                   }}/>
                </FormField>
            )
        } else if (observation.type === "FILE") {
            return (
                <FormField ref={formField => this.formFields.push(formField)}
                           required validateOn={observation.fileValue}>
                    <FileUploader basic accept=".pdf"
                                  value={observation.fileValue}
                                  onChange={(file) => {
                                      observation.fileValue = file;
                                      this.forceUpdate();
                                  }}/>
                </FormField>
            )
        }
    }

    addValueTypeTemplate(observation) {
        return (
            <FormField ref={formField => this.formFields.push(formField)}
                       required validateOn={observation.valueType}>
                <TextInput className="w-100"
                           value={observation.valueType}
                           onChange={(valueType) => {
                               observation.valueType = valueType;
                               this.forceUpdate();
                           }}/>
            </FormField>
        )
    }

    typeTemplate(observation) {
        return DirectBillingObservationTypeEnum[observation.type]?.name;
    }

    valueTemplate(observation) {
        if (observation.type === "TEXT") {
            return observation.value;
        } else if (observation.type === "FILE") {
            return <span className="cursor-pointer"
                        onClick={() => this.downloadFile(observation)}>
                <u>{observation.filename}</u>
                <FontAwesomeIcon className="ml-1" icon="file-download"/>
            </span>
        }
    }

    downloadFile(observation) {
        downloadBlob(base64ToBlob(observation.value, "application/pdf"), observation.filename);
    }

    valueTypeTemplate(observation) {
        return observation.valueType;
    }

    actionTemplate(observation) {
        if (observation._addMode) {
            return <FontAwesomeIcon icon="plus" onClick={() => this.saveObservation(observation)}/>
        } else if (observation._editMode) {
            return (
                <div>
                    <FontAwesomeIcon icon="check" onClick={() => this.updateObservation(observation)}/>
                    <FontAwesomeIcon className="ml-1" icon="times" onClick={() => this.cancelEdit(observation)}/>
                </div>
            )
        } else {
            return (
                <div>
                    <FontAwesomeIcon icon="edit" onClick={() => this.editObservation(observation)}/>
                    <FontAwesomeIcon className="ml-1" icon="trash" onClick={() => this.deleteObservation(observation)}/>
                </div>
            )
        }
    }

    render() {
        this.formFields = [];

        return (
            <div style={{width: 800}}>
                <DataTable emptyMessage={"No items found"}
                           value={[...this.state.observations, this.state.newObservation]}
                           columnResizeMode="fit"
                           dataKey="id">
                    <Column header="Type" style={{width: 100}}
                            body={(rowData) => rowData._addMode ? this.addTypeTemplate(rowData) : this.typeTemplate(rowData)}/>
                    <Column header="Code" style={{width: "30%" , wordWrap : "break-word"}} field="code"/>
                    <Column header="Value"
                            body={(rowData) => (rowData._addMode || rowData._editMode) ? this.addValueTemplate(rowData) : this.valueTemplate(rowData)}/>
                    <Column header="Value Type" style={{width: 150}}
                            body={(rowData) => (rowData._addMode || rowData._editMode) ? this.addValueTypeTemplate(rowData) : this.valueTypeTemplate(rowData)}/>
                    <Column style={{width: 60, textAlign: "center"}}
                            body={(rowData) => this.actionTemplate(rowData)}/>
                </DataTable>
            </div>
        )
    }
}