import React, {Component} from 'react';
import EnumAppointmentType from "../../modules/appointment/enums/EnumAppointmentType";

class RedAsterisk extends Component {

    static defaultProps = {
        followUp: false,
        newComplaint: false,
        firstVisit: false,
        renderForAllTypes: false,
        appointmentType: null,
        className: ""
    };

    render() {

        if (!this.props.renderForAllTypes && this.isValidToRender())
            return null;

        return (
            <span className={"text-danger " + this.props.className}>*</span>
        );
    }

    isValidToRender = () => {

        if(this.props.appointmentType == null || EnumAppointmentType[this.props.appointmentType] == null)
            return false;

        switch (EnumAppointmentType[this.props.appointmentType]) {
            case EnumAppointmentType.FOLLOW_UP:
                return this.props.followUp == false;

            case EnumAppointmentType.NEW_COMPLAINT:
                return this.props.newComplaint == false

            case EnumAppointmentType.NEW_PATIENT:
                return this.props.firstVisit == false
        }
    }
}

export default RedAsterisk;