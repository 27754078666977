import DateUtil from "../../utils/DateUtil";
import EnumLeaveType from "./EnumLeaveType";
import AuthService from "../../services/auth-service/AuthService";
import moment from "moment";


const NOT_APPLICABLE_TEXT = "NA ";
const KUWAIT_CLINIC_ID = 3;

class DurationCalculator {

    getDuration = (leave, startTime, endTime, blockEventsByDate) => {
        if (leave == null)
            return 0;

        if (DateUtil.isAfter(startTime, endTime))
            return NOT_APPLICABLE_TEXT;

        const durationCalculatorFunction = this.getDurationFunction(leave.type, leave.clinicIds);
        return durationCalculatorFunction ? durationCalculatorFunction(startTime, endTime, blockEventsByDate, leave.staffId) : '-';
    }

    getDurationFunction = (leaveType, clinicIds) => {
        switch (leaveType) {
            case EnumLeaveType.FLEXIBLE.key:
                return this.getDurationInHours;

            case EnumLeaveType.UNPAID.key:
                return this.getDurationNotIncludingNonWorkingDays;

            case EnumLeaveType.MATERNITY.key:
            case EnumLeaveType.HAJJ.key:
            case EnumLeaveType.SICK.key:
            case EnumLeaveType.COMPASSIONATE.key:
                return this.isKuwaitiStaff(clinicIds) ? this.getDurationNotIncludingNonWorkingDays : this.getDurationIncludingNonWorkingDays;

            case EnumLeaveType.ANNUAL.key:
                return this.getWorkingDays;

            case EnumLeaveType.PATERNITY.key:
                return this.getDurationIncludingNonWorkingDays;
        }
    }

    getDurationIncludingNonWorkingDays = (startTime, endTime) => {
        return DateUtil.getDifferenceInDays(endTime, startTime) + 1;
    }

    getDurationNotIncludingNonWorkingDays = (startTime, endTime) => {
        return this.getDurationIncludingNonWorkingDays(startTime, endTime) - this.getNumberOfNonWorkingDays(startTime, endTime);
    }

    getDurationInHours = (startTime, endTime) => {
        return DateUtil.formatHoursDuration(DateUtil.getDurationInMinutes(startTime, endTime) / 60);
    }

    getNumberOfNonWorkingDays = (startTime, endTime) => {
        if (DateUtil.isAfter(startTime, endTime))
            return 0;

        let workingDays = [];

        AuthService.getUser().clinics.map(clinic => {
            let items = clinic.workingDays.filter(item => !workingDays.some(workingDay => workingDay === item));
            workingDays = [...workingDays, ...items];
        })

        let numberOfNonWorkingDays = 0;
        let tempDate = startTime;

        while (DateUtil.isSameOrBefore(tempDate, endTime)) {
            if (!workingDays.some(i => i === DateUtil.getWeekDay(tempDate))) {
                numberOfNonWorkingDays++;
            }
            tempDate = DateUtil.addDays(tempDate, 1);
        }

        return numberOfNonWorkingDays;
    }

    getWorkingDays = (startTime, endTime, blockEventsByDate) => {
        const start = moment(startTime).startOf('day');
        const end = moment(endTime).startOf('day');
        let count = 0;
        for (let date = moment(start).startOf('day'); date.diff(end, 'days',true) <= 0; date.add(1, 'days')) {
            const key = DateUtil.formatDate(date);
            if (blockEventsByDate?.has(key)) {
                count++;
            }
        }
        return count;
    }

    isKuwaitiStaff = (clinicIds) => {
        if (clinicIds) {
            return !!clinicIds.find(id => id === KUWAIT_CLINIC_ID); // existing leave
        } else {
            return !!AuthService.getUser().clinics.find(clinic => clinic.name === "Kuwait"); // new leave
        }
    }
}

export default new DurationCalculator();