import Enum from "../../../../../../enums/Enum";

const NeurologicGaitEnum = new Enum({
            HEMIPLEGIC: { name:"Hemiplegic"},
            DIPLEGIC : { name:"Diplegic"},
            NEUROPATHIC: {name: "Neuropathic"},
            MYOPATHIC_TRENDELENBURG : {name: "Myopathic Trendelenburg"},
            HYPERKINETIC : { name:"Hyperkinetic"},
            ATAXIC_CEREBELLAR : { name:"Ataxic Cerebellar"},
            PARKINSONIAN : {name: "Parkinsonian"},
            ANTALGIC: { name:"Antalgic"},
            SENSORY_STOMPING: { name:"Sensory Stomping"},
            OTHER: { name:"Other"}
});

export default NeurologicGaitEnum;