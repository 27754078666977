import React, {Component} from "react";
import YesNoNotAvailableEnum from "../../../../../genericenums/YesNoNotAvailableEnum";
import YesNoEnum from "../../../../../genericenums/YesNoEnum";
import BirthTypeEnum from "../../../subjective/enums/history/BirthTypeEnum";

export default class BirthViewHistory extends Component {
    render() {
        if (this.props.birthHistory == null) {
            return <div className="pl-3">No information available</div>;
        }

        let birthHistoryData = this.getBirthHistoryData();
        return (
            <div className="pl-3">
                {birthHistoryData}
            </div>
        )
    }

    getBirthHistoryData() {
        let divs = [];
        if (this.props.birthHistory.children != null) {
            divs.push(
                <div key={"birthViewHistoryDivChildren"}>
                    <b>Children:</b> {this.props.birthHistory.children}
                </div>);
        }

        if (this.props.birthHistory.currentlyPregnant != null) {
            divs.push(
                <div key={"birthViewHistoryDivPregnant"}>
                    <b>Currently
                        Pregnant:</b> {YesNoNotAvailableEnum[this.props.birthHistory.currentlyPregnant].name}{this.props.birthHistory.currentlyPregnantDetail == null ? '' : (", " + this.props.birthHistory.currentlyPregnantDetail)}
                </div>);
        }

        if (this.props.birthHistory.items != null
            && this.props.birthHistory.items.length !== 0) {
            this.props.birthHistory.items.forEach((item, index) => {
                divs.push(
                    <div key={"birthViewHistoryDiv" + index}>
                        {index === 0 ? <b>Birth
                            Type: </b> : <></>}{BirthTypeEnum[item.birthType].name}{item.detail == null ? "" : (", " + item.detail)}
                    </div>);
            });
        }

        if (this.props.birthHistory.birthComplication != null) {
            divs.push(
                <div key={"birthViewHistoryDivCompl"}>
                    <b>Birth
                        Complication:</b> {YesNoEnum[this.props.birthHistory.birthComplication]?.name}{this.props.birthHistory.currentlyPregnantDetail == null ? '' : (", " + this.props.birthHistory.currentlyPregnantDetail)}
                </div>);
        }

        if (this.props.birthHistory.otherDetail != null && this.props.birthHistory.otherDetail !== "") {
            divs.push(
                <div key={"birtViewHistoryDivOtherDetail"}>
                    <b>Other:</b> {this.props.birthHistory.otherDetail}
                </div>);
        }

        return divs;
    }
}