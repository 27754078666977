import React, {Component} from "react";
import '../../EMR.css';
import PalpationLocationEnum from "../enums/palpation/PalpationLocationEnum";
import PalpationFindingEnum from "../enums/palpation/PalpationFindingEnum";
import StringUtil from "../../../../../utils/StringUtil";

export default class PalpationView extends Component {

    state = {
        list: [],
        index: null,
        listSize: null
    }

    render() {
        if (this.props.palpation == null
            || this.props.palpation.items == null
            || this.props.palpation.items.length == 0) {
            return null;
        }

        return (
            <div className={"content-card"}>
                <table className={"emr-table"}>
                    <tbody>
                    <tr>
                        <th>Area</th>
                        <th>Location</th>
                        <th>Finding</th>
                        <th>Pain</th>
                        <th>Details</th>
                    </tr>
                    {this.props.palpation.items.map((item, index) => {
                        return (<tr key={"palpationView" + index} style={{backgroundColor: "white"}}>
                            <td>{StringUtil.isNullOrEmpty(item.area) ? null : item.area}</td>
                            <td>{
                                [
                                    item.locationRight != null ? "Right-" + PalpationLocationEnum[item.locationRight].name : null,
                                    item.locationLeft != null ? "Left-" + PalpationLocationEnum[item.locationLeft].name : null,
                                ].filter(Boolean).join(", ")
                            }</td>
                            <td>{item.finding != null ? PalpationFindingEnum[item.finding].name : null}</td>
                            <td>{item.pain != null ? item.pain + "/10" : null}</td>
                            <td>{StringUtil.isNullOrEmpty(item.detail) ? null : item.detail}</td>
                        </tr>)
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
}