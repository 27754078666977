import "../MedicalReports.css"
import React, {Component} from "react";
import ReportTemplate from "../ReportTemplate";
import ReportSection from "../ReportSection";
import MSKDiagnosisReportView from "../view/MSKDiagnosisReportView";
import ReportViewUtils from "../view/ReportViewUtils";

export default class UltrasoundReportTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {visit: this.props.emr.visits[this.props.emr.visits.length - 1]}; // TODO remove
    }

    render() {
        const visit = this.state.visit;
        const assessment = visit.assessment;

        return (
            <ReportTemplate {...this.props} generateUrl={'api/medical-report/generate/ultrasound'}
                            onVisitSelected={(visit) => {
                                this.setState({visit})
                            }}>

                <ReportSection header1={"MSK Diagnostic Ultrasound"}>
                    {!ReportViewUtils.isMskDiagnosisNull(assessment.mskDiagnosis) && <MSKDiagnosisReportView mskDiagnosis={assessment.mskDiagnosis}/>}
                </ReportSection>

            </ReportTemplate>
        );
    }

}
