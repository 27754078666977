import React, {Component} from "react";
import queryString from "query-string";
import RemotingService from "../../../../services/remoting-service/RemotingService";

export default class CreditGiftQR extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        const secret = values.secret;

        if (secret) {
            RemotingService.postRemoteCall(`api/credit/credit-gift-form/${secret}`, null, () => {
                this.setState({info: "Gift code accepted, please follow patient detail screen."});
            });
        } else {
            this.setState({error: "Gift code is missing."});
        }
    }

    render() {
        return (
            <div className="text-center">
                <div className="disc-green">{this.state.info}</div>
                <div className="disc-danger">{this.state.error}</div>
            </div>
        )
    }

}