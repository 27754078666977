import React, {Component} from 'react';
import IntakeFormTextEnum from "../enum/IntakeFormTextEnum";
import {YesNoRadioInput} from "../../public/YesNoRadioInput";

class PublicIntakeFormHaveYouExperienced extends Component {
    state = {}

    render() {
        const {
            selectedLanguage, enumLabel, form, yesNoDescribeField,
            forceFormUpdate
        } = this.props;

        return (
            <>
                <div className="row">
                    <h5>{IntakeFormTextEnum.Have_You_Had_Or_Do_You_Experience[selectedLanguage]}:</h5>
                </div>

                <div className="row">
                    <div className="col-8 d-flex justify-content-end align-items-center">
                        <label
                            className="font-weight-bold">{IntakeFormTextEnum.Mark_All[selectedLanguage]}</label>
                    </div>
                    <div className="col d-flex align-items-center">
                        <YesNoRadioInput
                            value={this.state.markAllDescribe}
                            onValueChange={(value) => {
                                form.changeInGeneralHealth = value;
                                form.haveFeverChillsNightSweats = value;
                                form.unexplainedWeightChange = value;
                                form.numbnessOrTingling = value;
                                form.bowelIncontinence = value;
                                form.difficultySleeping = value;
                                form.unexplainedFalls = value;
                                forceFormUpdate();
                            }}
                            enumLabel={enumLabel}
                        />
                    </div>
                </div>

                {yesNoDescribeField(
                    form,
                    "changeInGeneralHealth",
                    IntakeFormTextEnum.Change_In_Your_General_Health[selectedLanguage],
                    enumLabel
                )}
                {yesNoDescribeField(
                    form,
                    "haveFeverChillsNightSweats",
                    IntakeFormTextEnum.Fever_Chills_Night_Sweats[selectedLanguage],
                    enumLabel
                )}
                {yesNoDescribeField(
                    form,
                    "unexplainedWeightChange",
                    IntakeFormTextEnum.Unexplained_Weight_Change[selectedLanguage],
                    enumLabel
                )}
                {yesNoDescribeField(
                    form,
                    "numbnessOrTingling",
                    IntakeFormTextEnum.Numbness_or_Tingling[selectedLanguage],
                    enumLabel
                )}
                {yesNoDescribeField(
                    form,
                    "bowelIncontinence",
                    IntakeFormTextEnum.Bowel_Bladder_Incontinence_or_Changes[selectedLanguage],
                    enumLabel
                )}
                {yesNoDescribeField(
                    form,
                    "difficultySleeping",
                    IntakeFormTextEnum.Difficulty_Sleeping_Due_to_Pain[selectedLanguage],
                    enumLabel
                )}
                {yesNoDescribeField(
                    form,
                    "unexplainedFalls",
                    IntakeFormTextEnum.Unexplained_Falls_Decreased_Balance[selectedLanguage],
                    enumLabel
                )}
            </>
        );
    }
}

export default PublicIntakeFormHaveYouExperienced;