import React, {Component} from "react";
import './GradientSlider10Mark3Category.css';
import Slider from "@material-ui/core/Slider";

export default class GradientSlider10Mark4Category extends Component {

    render() {
        const marks = [
            {value: 0, label: 0},
            {value: 1, label: ""},
            {value: 2, label: ""},
            {value: 3, label: 3},
            {value: 4, label: ""},
            {value: 5, label: 5},
            {value: 6, label: ""},
            {value: 7, label: 7},
            {value: 8, label: ""},
            {value: 9, label: ""},
            {value: 10, label: 10}
        ];
        return (
            <div className={"gradient-slider"}>
                <div className={"gradient-area"} style={{height: "30px"}}>
                    <div className={"row no-margin"} style={{textAlign: "center", height: "100%"}}>
                        <div className={"col-3 no-padding quarter-col"}>
                            <div>
                            </div>
                        </div>
                        <div className={"col-3 no-padding quarter-col"}>
                            <div>
                            </div>
                        </div>
                        <div className={"col-3 no-padding quarter-col"}>
                            <div>
                            </div>
                        </div>
                        <div className={"col-3 no-padding quarter-col"}
                             style={{height: "100%", borderRight: "1px white solid"}}>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"gradient-slider-input"}>
                    <Slider
                        defaultValue={this.props.value}
                        aria-labelledby="discrete-slider-always"
                        step={1}
                        marks={marks}
                        valueLabelFormat={(value) => <div className="label-text">{value}</div>}
                        valueLabelDisplay="on"
                        min={0}
                        max={10}
                        onChangeCommitted={this.props.onChange}
                    />
                </div>
            </div>
        );
    }
}
