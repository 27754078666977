import Enum from "../../../../../../enums/Enum";

const MedicationTypeEnum = new Enum({
    PRESCRIBED: {name: "Prescribed"},
    OTC: {name: "OTC"},
    ANTIBIOTICS: {name: "Antibiotics"},
    ANTIINFLAMMATORY: {name: "Anti-Inflammatory"},
    SUPPLEMENTS: {name: "Supplements"},
    ANALGESICPAINKILLER: {name: "Analgesic/Pain Killer"},
    OTHER: {name: "Other"}
});

export default MedicationTypeEnum;