import React, {Component} from 'react';
import {FormSectionHeader} from "../../public/FormSectionHeader";
import IntakeFormTextEnum from "../enum/IntakeFormTextEnum";

class PublicIntakeFormDescribeSymptoms extends Component {
    render() {
        const {form, selectedLanguage, checkBoxField, validatedTextField} = this.props;

        return (
            <>
                <FormSectionHeader header={IntakeFormTextEnum.Describe_Your_Symptoms[selectedLanguage]}/>

                <div className="row">
                    <label className="col-form-label">
                        {IntakeFormTextEnum.Describe_Your_Pain_Symptoms[selectedLanguage]}:
                    </label>
                    <div class="col-12">
                        {" "}
                        {checkBoxField(form, "symptomsConstant", IntakeFormTextEnum.Constant[selectedLanguage])}
                        {checkBoxField(
                            form,
                            "symptomsComesAndGoes",
                            IntakeFormTextEnum.Comes_And_Goes[selectedLanguage]
                        )}
                        {checkBoxField(
                            form,
                            "symptomsStartedSuddenly",
                            IntakeFormTextEnum.Started_Suddenly[selectedLanguage]
                        )}
                        {checkBoxField(form, "symptomsImproving", IntakeFormTextEnum.Improving[selectedLanguage])}
                        {checkBoxField(
                            form,
                            "symptomsStayingTheSame",
                            IntakeFormTextEnum.Staying_Same[selectedLanguage]
                        )}
                        {checkBoxField(
                            form,
                            "symptomsStartedSlowly",
                            IntakeFormTextEnum.Started_Slowly[selectedLanguage]
                        )}
                        {checkBoxField(form, "symptomsWorsening", IntakeFormTextEnum.Worsening[selectedLanguage])}
                        {checkBoxField(form, "symptomsThrobbing", IntakeFormTextEnum.Throbbing[selectedLanguage])}
                        {checkBoxField(form, "symptomsNumbness", IntakeFormTextEnum.Numbness[selectedLanguage])}
                        {checkBoxField(form, "symptomsAching", IntakeFormTextEnum.Aching[selectedLanguage])}
                        {checkBoxField(form, "symptomsBurning", IntakeFormTextEnum.Burning[selectedLanguage])}
                        {checkBoxField(form, "symptomsShooting", IntakeFormTextEnum.Shooting[selectedLanguage])}
                        {checkBoxField(form, "symptomsDull", IntakeFormTextEnum.Dull[selectedLanguage])}
                        {checkBoxField(
                            form,
                            "symptomsPinsAndNeedles",
                            IntakeFormTextEnum.Pins_Needles[selectedLanguage]
                        )}
                        {checkBoxField(form, "symptomsSharp", IntakeFormTextEnum.Sharp[selectedLanguage])}
                        {checkBoxField(form, "symptomsTightness", IntakeFormTextEnum.Tightness[selectedLanguage])}
                        {checkBoxField(form, "symptomsStiffness", IntakeFormTextEnum.Stiffness[selectedLanguage])}
                        {checkBoxField(form, "symptomsOther", IntakeFormTextEnum.Other[selectedLanguage])}
                    </div>
                </div>
                {form.symptomsOther && (
                    <div className="row">
                        <div className="container-fluid p-0">
                            <div className="panel bg-grey px-3 py-0 mb-3">
                                <div className="form-group">
                                    <label
                                        className="col-form-label">{IntakeFormTextEnum.Other[selectedLanguage]}</label>
                                    {validatedTextField(form, "symptomsOtherDescription")}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default PublicIntakeFormDescribeSymptoms;