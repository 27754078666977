import React, {Component} from "react";
import {SelectInput} from "../../../../components";
import {DialogActions} from "@material-ui/core";
import {Button} from "react-bootstrap";
import DateUtil from "../../../../utils/DateUtil";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import PrimeDateInput from "../../../../components/date-input/PrimeDateInput";

export default class ReplaceProviderModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            leaveDate: DateUtil.now().toDate(),
            replacementStaff: {name: ""}
        }
    }

    replaceProvider = () => {
        const params = {
            leaveDate: DateUtil.formatDate(this.state.leaveDate, "YYYY-MM-DD"),
            replacedStaffId: this.state.replacementStaff.id
        }

        RemotingService.postRemoteCall(`api/staff/${this.props.staffId}/replace?leaveDate=${params.leaveDate}&replacedStaffId=${params.replacedStaffId}`, null, (result) => {
            this.props.close();
            this.refreshScreen();
        });

    }

    refreshScreen() {
        setTimeout(() => window.location.reload(false), 1000);
    }

    render() {
        return(
            <div className="ReplaceProviderModal">
                <div className="row">
                    <div className="col-4">
                        <div className="font-weight-bold text-nowrap">Leave Date</div>
                    </div>
                    <div className="col-8">
                        <PrimeDateInput containerStyle={{width: "100%"}}
                                        value={this.state.leaveDate}
                                        onChange={leaveDate => this.setState({leaveDate})}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className="font-weight-bold text-nowrap">Replacement</div>
                    </div>
                    <div className="col-8">
                        <SelectInput displayProperty="name" valueProperty="value" searchEnabled={false}
                                     options={this.props.replacementStaffList.map(s => {return {name: s.name, value: s}})}
                                     value={this.state.replacementStaff}
                                     onChange={value => this.setState({replacementStaff: value})}/>
                    </div>
                </div>
                <DialogActions>
                    <Button variant="success"
                            onClick={() => this.replaceProvider()}
                            disabled={this.state.replacementStaff == null || this.state.replacementStaff.name.length < 1}>Save</Button>
                </DialogActions>
            </div>
        )
    }
}