import {
    RequiredValidator,
    MinLengthValidator,
    MaxLengthValidator,
    MinValidator,
    MaxValidator,
    EqualValidator,
    GreaterValidator,
    EmailValidator,
    MaxSelectionValidator,
    MinTimeValidator,
    MaxTimeValidator,
    MinDateValidator,
    MaxDateValidator,
    RequiredPhoneValidator,
    CustomValidator
} from ".";

export const ValidatorRegistry = {
    required: new RequiredValidator(),
    minLength: new MinLengthValidator(),
    maxLength: new MaxLengthValidator(),
    min: new MinValidator(),
    max: new MaxValidator(),
    equal: new EqualValidator(),
    greater: new GreaterValidator(),
    maxSelection: new MaxSelectionValidator(),
    minTime: new MinTimeValidator(),
    maxTime: new MaxTimeValidator(),
    minDate: new MinDateValidator(),
    maxDate: new MaxDateValidator(),
    email: new EmailValidator(),
    phoneRequired: new RequiredPhoneValidator(),
    customValidator: new CustomValidator()
};