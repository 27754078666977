import React from "react";
import queryString from "query-string";
import DateUtil from "../../../utils/DateUtil";
import RoomCalendarView from "./RoomCalendarView";
import RoomCalendarTopBar from "./RoomCalendarTopBar";
import RoomCalendarFilter from "./RoomCalendarFilter";
import RemotingService from "../../../services/remoting-service/RemotingService";
import "./RoomCalendar.css";
import {onlyUnique} from "../../../utils/ArrayUtil";
import CalendarEventService from "../../appointment/CalendarEventService";
import moment from "moment";
import {connect} from "react-redux";
import {getSchedules} from "../../../services/actions";
import AuthService from "../../../services/auth-service/AuthService";

class RoomCalendar extends React.Component {

    static VIEW_TYPES = [
        {id: "week", label: "Week", unit: "weeks"},
        {id: "month", label: "Month", unit: "months"}
    ];

    state = {
        generatedSchedules: [],
        newScheduleGenerated: false,

        roomList: [],
        staffList: [],
        specialityList: [],

        selectedDate: DateUtil.today(),
        selectedView: RoomCalendar.VIEW_TYPES[0],
        selectedStaffList: [],
        selectedSpecialityList: [],

        currentRoomEvents: [],
        generatedRoomEvents: [],
    };

    componentDidMount() {
        this.loadUrl();


        this.retrieveInitialData(({rooms, specialities, staffs}) => {
            staffs.forEach(s => s.color = s.clinicanColor);

            this.setState({
                specialityList: specialities,
                rooms,
                staffList: staffs,
                selectedStaffList: [staffs[0]]

            }, () => {
                this.refreshGeneratedSchedules();
            });
        })
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.schedules && this.props.schedules && prevProps.schedules.length !== this.props.schedules.length) {
            this.refreshGeneratedSchedules();
        }
    }

    retrieveInitialData(callback) {
        const specialityPromise = new Promise((resolve, reject) => {
            RemotingService.getRemoteCall('api/speciality/list', null, (specialities) => {
                resolve(specialities);
            });
        });

        const roomPromise = new Promise((resolve, reject) => {
            RemotingService.getRemoteCall('api/room/list-by-country', null, (rooms) => {
                resolve(rooms);
            })
        });

        const staffPromise = new Promise((resolve, reject) => {
            RemotingService.getRemoteCall('api/staff/list-base', null, (result) => {
                resolve(result.items);
            })
        });

        Promise.all([specialityPromise, roomPromise, staffPromise]).then((values) => {
            callback({specialities: values[0], rooms: values[1], staffs: values[2]});
        });
    }

    onScheduleJobConfirm = () => {
        this.props.dispatch(getSchedules());
    }

    refreshGeneratedSchedules = () => {
        RemotingService.getRemoteCall('api/scheduling-secure/generated-schedule', null, (generatedSchedules) => {
            const newScheduleGenerated = generatedSchedules.length > 0;
            const selectedDate = newScheduleGenerated ? moment(generatedSchedules[0].startDate).startOf('day').utc(true) : this.state.selectedDate;

            this.setState({generatedSchedules, newScheduleGenerated, selectedDate}, () => {
                this.refreshCalendarData();
            })
        });
    }

    refreshCalendarData = () => {
        const {generatedSchedules, rooms, selectedView, selectedDate, staffList, selectedStaffList, selectedSpecialityList} = this.state;
        const {schedules} = this.props;

        const selectedSpecialityNames = selectedSpecialityList.map(s => s.name);
        let selectedStaffs = [...selectedStaffList];
        const staffsWithSelectedSpecialities = staffList.filter(s => selectedSpecialityNames.includes(s.specialityName));

        selectedStaffs.push(...staffsWithSelectedSpecialities);
        const selectedStaffIds = selectedStaffs.map(s => s.id).filter(onlyUnique);

        const periodStart = selectedDate.clone().startOf(selectedView.id);
        const periodEnd = selectedDate.clone().endOf(selectedView.id);

        let generatedRoomEvents = [];

        selectedStaffs.forEach(staff => {
            const {events} = CalendarEventService.createEvents(rooms, staff, generatedSchedules, [], periodStart, periodEnd);
            generatedRoomEvents.push(...events);
        })

        this.setState({generatedRoomEvents});

        let currentRoomEvents = [];

        if (generatedRoomEvents.length === 0) {
            const leavesPromise = new Promise((resolve, reject) => {
                RemotingService.getRemoteCall('api/hr/leave/list', {
                    startTime: periodStart?.toJSON(),
                    endTime: periodEnd?.toJSON(),
                    staffIds: selectedStaffIds,
                    ignoredStatuses: ['PENDING', 'REJECTED','SUBMITTED'],
                    clinicIds: AuthService.getClinics()
                }, (result) => {
                    resolve(result);
                })
            });

            const roomEventPromise = new Promise((resolve, reject) => {
                RemotingService.postRemoteCall(
                    'api/staff/rotation/manual',
                    {startDate: periodStart, endDate: periodEnd, staffIds: selectedStaffIds},
                    (rotations) => {
                        selectedStaffs.forEach(staff => {
                            const manualRotationsForStaff = rotations.filter(rotation => rotation.staffId === staff.id);
                            const {events} = CalendarEventService.createEvents(rooms, staff, schedules, manualRotationsForStaff, periodStart, periodEnd);
                            currentRoomEvents.push(...events);
                        });

                        resolve(currentRoomEvents);
                    });
            });

            Promise.all([leavesPromise, roomEventPromise]).then((values) => {
                const leaves = values[0];
                const currentRoomEvents = values[1];

                const filteredCurrentRoomEvents = currentRoomEvents.filter(event => {
                    return leaves.items.filter(leave => {
                        return ((leave.staffId == event.staffId) && leave.type !== "EXTRA" && DateUtil.isBefore(leave.startTime, event.start) && DateUtil.isAfter(leave.endTime, event.end))
                    }).length == 0
                })

                this.setState({currentRoomEvents: filteredCurrentRoomEvents});
            });
        } else {
            this.setState({currentRoomEvents});
        }
    };

    loadUrl = () => {
        const values = queryString.parse(this.props.location.search);
        const selectedView = this.getViewType(values.selectedView) || RoomCalendar.VIEW_TYPES[0];
        const selectedDate = values.selectedDate ? DateUtil.parse(values.selectedDate).startOf('day').utc(true) : DateUtil.today();
        this.setState({selectedView, selectedDate});
    };

    refreshUrl = () => {
        const {selectedView, selectedDate} = this.state;
        const urlQuery = "selectedView=" + selectedView.id + "&selectedDate=" + DateUtil.formatDate(selectedDate);
        this.props.history.push({search: urlQuery});
    };

    getViewType = (id) => {
        return RoomCalendar.VIEW_TYPES.find(t => t.id === id);
    };

    handleViewChange = (selectedView) => {
        this.setState({selectedView}, () => {
            this.refreshUrl();
            this.refreshCalendarData();
        });
    };

    handleDateChange = (selectedDate) => {
        this.setState({selectedDate}, () => {
            this.refreshUrl();
            this.refreshCalendarData();
        });
    };

    handleStaffSelectionChange = (selectedStaffList) => {
        this.setState({selectedStaffList}, () => {
            this.refreshCalendarData();
        });
    };

    handleSpecialitySelectionChange = (selectedSpecialityList) => {
        this.setState({selectedSpecialityList}, () => {
            this.refreshCalendarData();
        });
    };

    handleSwitchSettingsMode = () => {
        this.props.history.push('/roomCalendarSettings')
    }

    render() {
        return (
            <>
                <RoomCalendarTopBar
                    staffList={this.state.staffList}
                    selectedView={this.state.selectedView}
                    selectedDate={this.state.selectedDate}
                    onViewChange={this.handleViewChange}
                    onDateChange={this.handleDateChange}
                    onScheduleJobUpdate={this.refreshGeneratedSchedules}
                    onScheduleJobConfirm={this.onScheduleJobConfirm}
                    onSwitchSettingsMode={this.handleSwitchSettingsMode}
                />
                <div className="RoomCalendarBody">
                    <RoomCalendarFilter staffList={this.state.staffList}
                                        selectedStaffList={this.state.selectedStaffList}
                                        onStaffSelectionChange={this.handleStaffSelectionChange}
                                        specialityList={this.state.specialityList}
                                        selectedSpecialityList={this.state.selectedSpecialityList}
                                        onSpecialitySelectionChange={this.handleSpecialitySelectionChange}/>
                    <RoomCalendarView
                        data={this.state.newScheduleGenerated ? this.state.generatedRoomEvents : this.state.currentRoomEvents}
                        isGenerated={this.state.newScheduleGenerated}
                        selectedView={this.state.selectedView}
                        selectedDate={this.state.selectedDate}
                        roomList={this.state.rooms}
                        staffList={this.state.staffList}/>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        schedules: state.base.schedules
    };
}

export default connect(mapStateToProps)(RoomCalendar);
