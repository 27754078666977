import React, {Component} from "react";
import "../../VisitHistory.css"
import ExaminationEnum from "../../../assessment/enums/examination/ExaminationEnum";
import Modal from "../../../../../../components/modal/Modal";
import MSKDiagnosisFindingsModal from "../../../assessment/modals/MSKDiagnosisFindingsModal";

export default class MSKDiagnosisViewHistory extends Component {
    state = {
        MSKDiagnosisFindingsModalVisible: false
    }

    render() {
        if (this.props.mskDiagnosis?.items?.length > 0) {
            return (
                <>
                    {this.props.mskDiagnosis.items.map((item, index) => {
                        const examinationLocation = item.notApplicableBool? "Not Applicable" : (item.leftBool? "Left" : item.rightBool? "Right" : null) ;
                        return (
                            <div className="pl-3 mb-3">
                                <div className={"display-block"}>
                                    <span> <b>Presenting Complaint: </b> {item.presentingComplaint} </span>
                                </div>

                                <div className={"display-block"}>
                                    <span> <b> Examination: </b>
                                        {item.examination || item.examinationComment ?
                                                <>{(item.examination ? ExaminationEnum[item.examination].name : item.examinationComment) + (examinationLocation? " - " +examinationLocation : "") }</>
                                            :
                                            ''}
                                    </span>
                                </div>

                                <div className={"display-block"}>
                                    <span> <b> Findings: </b> {item.findings?.length > 1024 ?
                                        <div>
                                            {item.findings?.substring(0, 1024)}
                                            {" ... "}
                                            <span
                                                style={{color: "green"}}
                                                onClick={() => this.setState({MSKDiagnosisFindingsModalVisible: true})}>
                                                    Show more
                                            </span>
                                        </div> : item.findings
                                    }
                                        <Modal visible={this.state.MSKDiagnosisFindingsModalVisible}
                                               submitContent="OK"
                                               contentStyle={{maxHeight: 600, overflowY: 'auto'}}
                                               submitAction={() => this.setState({MSKDiagnosisFindingsModalVisible: false})}
                                               closeAction={() => this.setState({MSKDiagnosisFindingsModalVisible: false})}
                                               header='MSK Diagnosis Findings'
                                               maxWidth={"xl"}
                                               contentStyle={{maxHeight: "140%", width: "75vw", overflowY: 'auto'}}>
                                            <MSKDiagnosisFindingsModal findings={item.findings}/>
                                        </Modal> </span>
                                </div>

                                <div className={"display-block"}>
                                    <span> <b> Impression: </b> {item.impression} </span>
                                </div>

                                <div className={"display-block"}>
                                    <span> <b> Diagnosis: </b> {item.diagnosisCode} - {item.diagnosisDescription} </span>
                                </div>

                                <div className={"display-block"}>
                                    <span> <b> Recommendations: </b> {item.recommendations} </span>
                                </div>
                            </div>

                        );
                    })
                    }
                </>
            );
        } else {
            return <div className="pl-3">No information available</div>;
        }
    }
}