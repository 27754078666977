import React, { Component } from "react";
import _uniqueId from "lodash/uniqueId";

export default class CheckboxInput extends Component {

    constructor(props){
        super(props);
        if(props.id){
            this.id = props.id;
        }
        else{
            this.id = _uniqueId("dim14-checkbox-id-");
        }
    }

    handleOnChange = (e) => {
        let value = e.target.checked;
        this.props.onChange(value);
    };

    render() {

        let componentClassName = "dim14checkboxinput " + this.props.className;

        return <input type="checkbox"
                id={this.id}
                className={componentClassName}
                checked={this.props.value}
                disabled={this.props.disabled}
                title={this.props.title}
                hidden={this.props.hidden}
                onChange={(e) => this.handleOnChange(e)}
                style={this.props.style}
            />;
    }
}
