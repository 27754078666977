import {store} from "../../utils/store";
import {userActions} from "../actions";

const incrementRemotingOperationCount = () => {
    store.dispatch({type: 'INCREMENT_REMOTING_OPERATION_COUNT'});
};

const decrementRemotingOperationCount = () => {
    store.dispatch({type: 'DECREMENT_REMOTING_OPERATION_COUNT'});
};

const redirectToLogin = () => {
    store.dispatch(userActions.logout());
};

export default  {incrementRemotingOperationCount, decrementRemotingOperationCount, redirectToLogin};
