import RiskLevelEnum from "../enums/fallriskmanagement/RiskLevelEnum";
import LowRiskInterventionEnum from "../enums/fallriskmanagement/LowRiskInterventionEnum";
import HighRiskInterventionEnum from "../enums/fallriskmanagement/HighRiskInterventionEnum";
import React from "react";

const getFallRiskItemDiv = (item) => {
    return <>
        {
            item.lowRiskIntervention != null || item.highRiskIntervention != null ?
                <div className={"col-"} style={{
                    marginLeft: "10px",
                    marginBottom: "-10px",
                    fontSize: "14px",
                    display: "block",
                    width: "100%"
                }}>
                    <div className={"row"} style={{fontSize: 14}}>
                        <div className={"col-4 font-weight-bold d-flex justify-content-between"}>
                            <div>Risk Level</div>
                            <div>:</div>
                        </div>
                        <div className={"col-7"}>
                            {(item.lowRiskIntervention ? RiskLevelEnum.LOW_RISK.name : "") +
                            (item.lowRiskIntervention && item.highRiskIntervention ? ", " : "") +
                            (item.highRiskIntervention ? RiskLevelEnum.HIGH_RISK.name : "")}
                        </div>
                    </div>
                </div>
                : ''
        }
        {
            item.lowRiskIntervention != null || item.highRiskIntervention != null ?
                <div className={"col-"} style={{
                    marginLeft: "10px",
                    marginBottom: "-10px",
                    fontSize: "14px",
                    display: "block",
                    width: "100%"
                }}>
                    <div className={"row"}>
                        <div className={"col-4 d-flex justify-content-between font-weight-bold"}>
                            <div>Intervention</div>
                            <div>:</div>
                        </div>
                        <div className={"col-7"}>
                            {(item.lowRiskIntervention ? LowRiskInterventionEnum[item.lowRiskIntervention].name : "") +
                            (item.lowRiskIntervention && item.highRiskIntervention ? ", " : "") +
                            (item.highRiskIntervention ? HighRiskInterventionEnum[item.highRiskIntervention].name : "")}
                        </div>
                    </div>
                </div>
                : ''
        }
        {
            item.details != null ?
                <div className={"col-"} style={{
                    marginLeft: "10px",
                    marginBottom: "-10px",
                    fontSize: "14px",
                    display: "block",
                    width: "100%"
                }}>
                    <div className={"row"}>
                        <div className={"col-3 d-flex justify-content-between font-weight-bold"}>
                            <div>Details</div>
                            <div>:</div>
                        </div>
                        <div className={"col-8"}>
                            {item.details}
                        </div>
                    </div>
                </div>
                : ''
        }
    </>;
}

export default {getFallRiskItemDiv};