import React, {Component} from "react";
import '../Subjective.css';
import '../../EMR.css';
import './ComplaintView.css';
import ActivityLimitationEnum from "../enums/treatmentreceived/ActivityLimitationEnum";
import SeverityEnum from "../enums/treatmentreceived/SeverityEnum";

export default class ActivityLimitationView extends Component {

    render() {
        let divs = [];

        if(this.props.activityLimitation == null
            || this.props.activityLimitation.items == null
            || this.props.activityLimitation.items.length == 0) {
            return (<div style={{padding: "15px 15px"}}></div>);
        }

        this.props.activityLimitation.items.forEach((activity,index) => {
           divs.push(
               <div key={"activityLimitationViewL2" + index} className="px-3">
                   <div className={"display-flex m-1 mb-2 w-100 flex-column"} style={{fontSize: "14px"}}>
                       <div className="row">
                           <div className={"col-3 no-padding overflow-wrap-anywhere font-weight-bold"}>{ActivityLimitationEnum[activity.activityLimitation].name}:</div>
                           <div className={"col-9 no-padding overflow-wrap-anywhere"}>{SeverityEnum[activity.severity].name}</div>
                       </div>
                       <div className="row">
                           <div className={"col-3 no-padding overflow-wrap-anywhere font-weight-bold"}>Detail:</div>
                           <div className={"col-9 no-padding overflow-wrap-anywhere"}>{activity.detail}</div>
                       </div>
                   </div>
               </div>
           );
        });

        return(
          <div style={{padding: "15px 15px"}}>
              {divs}
          </div>
        );
    }
}