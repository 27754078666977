import React, {Component} from 'react';
import moment from "moment";
import StringUtil from "../../utils/StringUtil";
import {NumberInput, TextInput, Wizard} from "../../components";
import RemotingService from "../../services/remoting-service/RemotingService";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import SignatureInput from "../../components/signature-input/SignatureInput";
import Logo from "../../layout/Logo";
import PatientUtil from "../../utils/PatientUtil";
import ConsentFormsUtil from "./utils/ConsentFormsUtil";
import FormField from "../../components/form/FormField";

class IVInfusionConsentForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            healthProfessionalDate: new Date(),
            patientDate: new Date(),
            guardianDate: new Date(),
            providerName: this.props.staff.name,
            providerSpeciality: this.props.staff.speciality.name
        }
    }

    onSubmit = () => {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        if (this.props.form !== null && this.props.form !== undefined) {
            this.props.close();

        } else {
            let patient = this.props.patient;
            let patientFullName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
            let patientMrn = StringUtil.mrn(patient.mrn);
            let patientDOB = patient.birthday;
            const {
                feeForProposedProcedure,
                healthProfessionalDate,
                patientDate,
                guardianDate,
                guardianName,
                guardianRelationship,
                patientSignature,
                providerSignature,
                guardianSignature,
                providerName, providerSpeciality
            } = this.state;

            RemotingService.postRemoteCall('api/visit/form/iv-infusion-consent-form/' + this.props.visitId,
                {
                    feeForProposedProcedure,
                    healthProfessionalDate,
                    patientDate,
                    guardianDate,
                    guardianName,
                    guardianRelationship,
                    patientSignature,
                    providerSignature,
                    guardianSignature,
                    patientFullName,
                    patientMrn,
                    patientDOB,
                    providerName, providerSpeciality
                },
                (result) => {
                    this.props.afterSave(result);
                    this.props.close();
                });
        }
    }

    onSaveGuardianSignature = (signature) => {
        this.setState({guardianSignature: signature})
    }

    onSavePatientSignature = (signature) => {
        this.setState({patientSignature: signature})
    }

    onSaveProviderSignature = (signature) => {
        this.setState({providerSignature: signature})
    }

    render() {
        if (!this.props.show)
            return null;

        const {providerName, providerSpeciality} = this.state;

        let {patient, form} = this.props;
        let readOnly = form !== null && form !== undefined;
        let mrnNo = StringUtil.mrn(patient.mrn);
        let patientName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
        this.formFields = []

        return (
            <Wizard
                show={this.props.show}
                close={this.props.close}
                title="IV Infusion Consent Form"
                onSubmit={this.onSubmit}
                isReadOnly={readOnly}
            >
                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}>
                    <div style={{width: '1000px'}}>
                        <div style={{
                            marginTop: '15px', marginBottom: '15px', width: '100%', display: 'flex',
                            flexFlow: 'row', justifyContent: 'space-between', alignItems: 'center'
                        }}>
                            <Logo/>
                            <div>
                                <h2>IV INFUSION CONSENT FORM</h2>
                            </div>
                        </div>

                        <hr style={{
                            borderTop: "2px solid #000000",
                            marginTop: "-10px",
                            boxShadow: "0px 2px 2px #797979"
                        }}/>

                        <div style={{border: "1px solid #000000"}}>
                            <div style={{margin: "15px 15px"}}>
                                <h5>Patient information:</h5>
                                <div className={"row"} style={{marginTop: "10px", marginLeft: "0px"}}>
                                    <div className="col-md-5">
                                        <b>Name:</b> {patientName}
                                    </div>
                                    <div className="col-md-4">
                                        <b>MRN:</b> {mrnNo}
                                    </div>
                                    <div className="col-md-3">
                                        <b>D.O.B:</b> {patient.birthday}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                            <div className={"row"} style={{marginLeft: "0px", margin: "15px 15px"}}>
                                <p>Fee for the proposed procedure:</p>
                                <NumberInput
                                    value={readOnly ? form.feeForProposedProcedure : this.state.feeForProposedProcedure}
                                    disabled={readOnly} style={{textAlign: "center"}}
                                    style={{marginLeft: "10px"}}
                                    min={0}
                                    allowDecimal
                                    maxFractionDigits={patient.mrn.country === "UAE" ? 2 : 3}
                                    onChange={(val) => this.setState({feeForProposedProcedure: val})}/>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>
                    <div style={{width: '1000px'}}>
                        <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                            <div style={{margin: "10px 15px"}}>
                                <h4 className={"mb-0"}>
                                    Statement of Health Professional:
                                </h4>
                                <p style={{fontSize: "12px", marginTop: "-5px"}}>(to be filled in by health professional
                                    with appropriate knowledge of proposed procedure) </p>
                                <p> I have explained the procedure to the patient. In particular.<br/>
                                    <u>The intended benefits:</u> reduce the stress hormones, improve immune system,
                                    restoring antioxidant levels. <br/>
                                    <u>The side effects may include:</u> <br/>
                                    <u>Common:</u> pain at the site of injection, headache, nausea, vomiting,
                                    bruising.<br/>
                                    <u>Uncommon:</u> altered skin coloration at the site of injection, phlebitis of
                                    the injected vein, temporary ﬂuid retention/swelling/increased blood
                                    pressure, mood changes.<br/>
                                    <u>Very rarely encountered:</u> shock, anaphylactic reactions.<br/>
                                    I have confirmed with the patient that he/she has no further questions
                                    and wishes the procedure to go ahead.
                                </p>
                            </div>
                        </div>
                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    PROVIDER NAME:
                                </div>
                                <div className="col-md-3">
                                    {providerName}
                                </div>

                                <div className="col-md-3 dim14-form-label">
                                    JOB TITLE:
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    {providerSpeciality}
                                </div>
                            </div>

                            <div className="row" style={{marginTop: "15px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    DATE/TIME:
                                </div>

                                <div className="col-md-3">
                                    {moment(readOnly && form.healthProfessionalDate ?
                                        new Date(form.healthProfessionalDate)
                                        :
                                        this.state.healthProfessionalDate).format('MMMM DD YYYY, h:mm:ss a')
                                    }
                                </div>

                                <div className="col-md-6 dim14-form-label">
                                    PROVIDER SIGNATURE:
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <FormField ref={(ref) => this.formFields.push(ref)}
                                               disabled={readOnly}
                                               required requiredMessage="Signature required"
                                               validateOn={this.state.providerSignature}>
                                        <SignatureInput
                                            signature={readOnly ? form.providerSignature : this.state.providerSignature}
                                            onSave={this.onSaveProviderSignature} readOnly={readOnly}/>
                                    </FormField>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>
                    <div style={{width: '1000px'}}>
                        <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                            <div style={{margin: "10px 15px"}}>
                                <h4 className={"mb-0"}>Statement of patient:</h4>
                                <p>Please read this form carefully.
                                    <br/>If you have any further questions, do ask.
                                    <br/>You have the right to change your mind at any time, including after you have signed this form.
                                </p>

                                <p><b>I agree</b> to the procedure or course of treatment described on this form.</p>
                                <p> <b>I agree</b> that information and/or surgical images kept in my records may be used anonymously for education,
                                    audit and research approved by an ethics committee, to improve the quality of patient care. </p>
                                <p><b>I understand</b> that the person performing the procedure has the appropriate experience or is training under direct supervision.</p>
                                <p><b>I understand</b> that any procedure in addition to those described on this form will only be carried out if it is necessary to save my life or to prevent serious harm to my health.</p>
                                <p><b>I agree</b> that I have had a chance to ask appropriate questions.</p>
                                <p><b>I agree</b> to the contents of this form and I agree to receive the treatment. </p>
                            </div>
                        </div>
                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    DATE/TIME:
                                </div>

                                <div className="col-md-3">
                                    {moment(readOnly && form.patientDate ?
                                        new Date(form.patientDate)
                                        :
                                        this.state.patientDate).format('MMMM DD YYYY, h:mm:ss a')
                                    }
                                </div>
                            </div>

                            <div className="row" style={{marginTop: "15px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    PATIENT NAME:
                                </div>
                                <div className="col-md-3">
                                    {patientName}
                                </div>

                                <div className="col-md-6 dim14-form-label">
                                    PATIENT SIGNATURE:
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <SignatureInput
                                        signature={readOnly ? form.patientSignature : this.state.patientSignature}
                                        onSave={this.onSavePatientSignature} readOnly={readOnly}/>
                                </div>
                            </div>
                        </div>

                    </div>
                </Wizard.Page>

                <Wizard.Page onPrevious={() => this.formFields = []}>
                    <div style={{width: '1000px'}}>
                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <p><b>A witness should sign below if the patient is unable to sign but has indicated
                                    his or her consent. <br/>
                                    Young people (under {ConsentFormsUtil.getWitnessAgeText()} years of age) and children should also have a Parent
                                    or Guardian sign here.</b></p>
                            </div>

                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    PRINT NAME:
                                </div>
                                <div className="col-md-3">
                                    <TextInput value={readOnly ? form.guardianName : this.state.guardianName}
                                               alphabeticOnly disabled={readOnly} style={{textAlign: "center"}}
                                               onChange={(val) => this.setState({guardianName: val})}/>
                                </div>

                                <div className="col-md-3 dim14-form-label">
                                    Relationship to Patient
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    <TextInput
                                        value={readOnly ? form.guardianRelationship : this.state.guardianRelationship}
                                        alphabeticOnly disabled={readOnly} style={{textAlign: "center"}}
                                        onChange={(val) => this.setState({guardianRelationship: val})}/>
                                </div>
                            </div>


                            <div className={"row"} style={{marginTop: "15px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    DATE/TIME:
                                </div>

                                <div className="col-md-3">
                                    {moment(readOnly && form.guardianDate ?
                                        new Date(form.guardianDate)
                                        :
                                        this.state.guardianDate).format('MMMM DD YYYY, h:mm:ss a')
                                    }
                                </div>

                                <div className="col-md-6 dim14-form-label">
                                    SIGNATURE OF WITNESS/PARENT OR GUARDIAN:
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <FormField ref={(ref) => this.formFields.push(ref)}
                                               disabled={readOnly || this.state.patientSignature != null}
                                               required requiredMessage="Signature required"
                                               validateOn={this.state.guardianSignature}>
                                        <SignatureInput
                                            signature={readOnly ? form.guardianSignature : this.state.guardianSignature}
                                            onSave={this.onSaveGuardianSignature} readOnly={readOnly}/>
                                    </FormField>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>
            </Wizard>
        );
    }
}

export default IVInfusionConsentForm;
