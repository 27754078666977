import React, {Component} from "react";
import {DateInput, SelectInput, TextareaInput} from "../../components";
import TimeInput from "../../components/date-input/TimeInput";
import GreenSwitch from "../../components/switch/GreenSwitch";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "react-bootstrap";
import RemotingService from "../../services/remoting-service/RemotingService";
import DateUtil from "../../utils/DateUtil";
import AuthService from "../../services/auth-service/AuthService";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import {FormField} from "../../components/form";
import TimezoneUtil from "../../utils/TimezoneUtil";
import moment from "../../utils/moment";
import HRRemoteService from "./HRRemoteService";
import {currentTimezone} from "../../utils/CalendarDateUtil";

export default class CloseClinicModal extends Component {

    constructor(props) {
        super(props);
        this.state = props.leave ?
            {
                leaveId: props.leave.id,
                allProviders: props.leave.allProviders,
                providerIds: props.leave.providerIds,
                startDate: moment(props.leave.startTime).toDate(),
                endDate: moment(props.leave.endTime).toDate(),
                startTime: this.getTimeForClinicZone(props.leave.untouchedStartTime, props.leave.clinicId),
                endTime: this.getTimeForClinicZone(props.leave.untouchedEndTime, props.leave.clinicId),
                clinicId: props.leave.clinicId,
                description: props.leave.comments[0]?.comment,
                clinics: AuthService.getUser().clinics,
                providers: [],
                updateMode: true
            } :
            {
                allProviders: true,
                providerIds: [],
                startDate: new Date(),
                endDate: new Date(),
                startTime: DateUtil.parseTime("05:00"),
                endTime: DateUtil.parseTime("23:00"),
                clinicId: null,
                description: null,
                clinics: AuthService.getUser().clinics,
                providers: [],
                updateMode: false
            };
    }

    componentDidMount() {
        if (this.state.clinicId) {
            this.retrieveProviders(this.state.clinicId, providers => {
                this.setState({providers});
            });
        }
    }

    getTimeForClinicZone(dateTime, clinicId) {
        const clinic = AuthService.getUser().clinics.find(c => c.id === clinicId);
        const timezone = TimezoneUtil.getTimeZoneForCountry(clinic.country);

        const momentAtClinic = moment.tz(dateTime, timezone);
        return DateUtil.switchZone(momentAtClinic, currentTimezone).toDate();
    }

    retrieveProviders(clinicId, callback) {
        RemotingService.getRemoteCall('api/staff/list-base', {clinicIds: [clinicId]}, (result) => {
            callback(result.items);
        });
    }

    handleClinicChanged(clinicId) {
        if (!this.state.allProviders) {
            this.retrieveProviders(clinicId, providers => {
                this.setState({clinicId: clinicId, providers: providers, providerIds: []});
            });
        } else {
            this.setState({clinicId: clinicId, providerIds: []});
        }
    }

    handleAllProvidersChanged(event) {
        const checked = event.target.checked;

        if (checked) {
            this.setState({allProviders: checked, providers: [], providerIds: []});
        } else if (!!this.state.clinicId) {
            this.retrieveProviders(this.state.clinicId, providers => {
                this.setState({allProviders: checked, providers: providers, providerIds: []});
            });
        }
    }

    delete = () => {
        const leave = this.props.leave;

        HRRemoteService.delete(leave, () => {
            this.props.close();
            this.props.onDelete(leave);
        })
    }

    createLeaveParams() {
        const clinic = this.state.clinics.find(c => c.id === this.state.clinicId);
        const timezone = TimezoneUtil.getTimeZoneForCountry(clinic.country);

        const startDate = moment(this.state.startDate);
        const startTime = moment(this.state.startTime);
        const startMoment = DateUtil.createMomentWithZone(timezone, startDate.year(), startDate.month(), startDate.date(), startTime.hour(), startTime.minute());

        const endDate = moment(this.state.endDate);
        const endTime = moment(this.state.endTime);
        const endMoment = DateUtil.createMomentWithZone(timezone, endDate.year(), endDate.month(), endDate.date(), endTime.hour(), endTime.minute());

        return {
            id: this.state.updateMode ? this.state.leaveId : undefined,
            startTime: startMoment.toDate(),
            endTime: endMoment.toDate(),
            clinicId: this.state.clinicId,
            allProviders: this.state.allProviders,
            providerIds: this.state.providerIds,
            description: this.state.description
        };
    }

    update() {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        const params = this.createLeaveParams();

        HRRemoteService.updateClinicClosedLeave(params, (leave) => {
            this.props.close();
            this.props.onUpdate(leave);
        });
    }

    save() {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        const params = this.createLeaveParams();

        HRRemoteService.createClinicClosedLeave(params, (leave) => {
            this.props.close();
            this.props.onSave(leave);
        });
    }

    render() {
        this.formFields = [];

        return (
            <div style={{width: 300}}>
                <div className="row flex-column">
                    <span>Clinic</span>
                    <FormField ref={formField => this.formFields.push(formField)}
                               required validateOn={this.state.clinicId}>
                        <SelectInput className="w-100"
                                     options={this.state.clinics}
                                     displayProperty="name" valueProperty="id"
                                     placeholder="Select clinic"
                                     searchEnabled={false}
                                     value={this.state.clinicId}
                                     onChange={value => this.handleClinicChanged(value)}/>
                    </FormField>
                </div>
                <div className="row align-items-center">
                    <span>Closed for all providers</span>
                    <GreenSwitch checked={this.state.allProviders}
                                 onChange={event => this.handleAllProvidersChanged(event)}/>
                </div>
                {!this.state.allProviders &&
                <div className="row flex-column">
                    <span>Providers</span>
                    <FormField ref={formField => this.formFields.push(formField)}
                               required validateOn={this.state.providerIds}>
                        <SelectInput className="w-100"
                                     options={this.state.providers}
                                     displayProperty="name" valueProperty="id"
                                     placeholder="Select provider(s)"
                                     multiSelect
                                     value={this.state.providerIds}
                                     onChange={(value => {
                                         this.setState({providerIds: value})
                                     })}/>
                    </FormField>
                </div>
                }
                <div className="row">
                    <div className="col-6 pl-0 d-flex flex-column">
                        <span>Start Date</span>
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.startDate}>
                            <DateInput value={this.state.startDate}
                                       onChange={value => this.setState({startDate: value})}/>
                        </FormField>
                    </div>
                    <div className="col-6 d-flex flex-column">
                        <span>End Date</span>
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.endDate}>
                            <DateInput value={this.state.endDate}
                                       onChange={value => this.setState({endDate: value})}/>
                        </FormField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-6 pl-0 d-flex flex-column">
                        <span>Start Time</span>
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.startTime}>
                            <TimeInput stepMinute={5}
                                       readOnlyInput={true}
                                       value={this.state.startTime}
                                       onChange={e => this.setState({startTime: e.value})}/>
                        </FormField>
                    </div>
                    <div className="col-6 d-flex flex-column">
                        <span>End Time</span>
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.endTime}>
                            <TimeInput stepMinute={5}
                                       readOnlyInput={true}
                                       value={this.state.endTime}
                                       onChange={e => this.setState({endTime: e.value})}/>
                        </FormField>
                    </div>
                </div>
                <div className="row mb-2">
                    <span>Description</span>
                    <TextareaInput className="w-100"
                                   value={this.state.description}
                                   onChange={value => this.setState({description: value})}/>
                </div>
                <DialogActions className="row">
                    {this.state.updateMode && <Button variant="light" size="sm" onClick={() => this.delete()}>Delete</Button>}
                    {this.state.updateMode && <Button variant="success" size="sm" onClick={() => this.update()}>Update</Button>}
                    {!this.state.updateMode && <Button variant="success" size="sm" onClick={() => this.save()}>Save</Button>}
                </DialogActions>
            </div>
        )
    }
}