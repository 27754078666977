import Enum from "../../../../../../enums/Enum";

const MovementEnum = new Enum({
    Flexion: {name: "Flexion"},
    Extension: {name: "Extension"},
    Abduction: {name: "Abduction"},
    Adduction: {name: "Adduction"},
    Internal_Rotation: {name: "Internal Rotation"},
    External_Rotation: {name: "External Rotation"},
    Supination: {name: "Supination"},
    Pronation: {name: "Pronation"},
    Plantarflexion: {name: "Plantarflexion"},
    Dorsiflexion: {name: "Dorsiflexion"},
    Ulnar_Deviation: {name: "Ulnar Deviation"},
    RadialDeviation: {name: "Radial Deviation"},
    Inversion: {name: "Inversion"},
    Eversion: {name: "Eversion"},
    Horizontal_Abduction: {name: "Horizontal Abduction"},
    Horizontal_Adduction: {name: "Horizontal Adduction"},
});

export default MovementEnum;