import React, {Component} from "react";
import LevelEnum from "../../diagnosis/enums/LevelEnum";
import TypeEnum from "../../diagnosis/enums/TypeEnum";
import BillTypeEnum from "../../diagnosis/enums/BillTypeEnum";

export default class DiagnosisTableEMRDashboardView extends Component {

    render() {
        if (this.props.diagnosisGroups == null
            || this.props.diagnosisGroups.length === 0) {
            return null;
        }

        let diagnosisItems = this.props.diagnosisGroups[0].diagnosisItems;

        if (diagnosisItems == null
            || diagnosisItems.length === 0) {
            return null;
        }
        let tableRows = [];
        diagnosisItems.forEach((diagnosisItem, index) => {
            tableRows.push(
                <tr key={index} style={{backgroundColor: "white"}}>
                    <td>{diagnosisItem?.code}</td>
                    <td>{diagnosisItem?.description}</td>
                    <td>{LevelEnum[diagnosisItem.level]?.name}</td>
                    <td>{TypeEnum[diagnosisItem.type]?.name}</td>
                    <td>{BillTypeEnum[diagnosisItem.billType]?.name}</td>
                </tr>
            );
        });

        return (
            <div className={"content-wrapper"}>
                <div className={"content-card"}>
                    <table className={"emr-table"}>
                        <tbody>
                        <tr>
                            <th>ICD</th>
                            <th>Description</th>
                            <th>Level</th>
                            <th>Type</th>
                            <th>Bill Type</th>
                        </tr>
                        {tableRows}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}