import React, {Component} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

export default class VersionModal extends Component {

    VersionTemplate(rowData) {
        return "1." + rowData.version;
    }

    xmlTemplate(rowData) {
       return <>{rowData.content}</>
    }


    render() {
        return (
            <div style={{width: 800}}>
                <DataTable emptyMessage={"No items found"}
                           value={this.props.versions}
                           columnResizeMode="fit"
                           dataKey="id">
                    <Column header="Version" style={{width: 100}} body={(rowData) => this.VersionTemplate(rowData)}/>
                    <Column header="Xml" className="text-break"  body={(rowData) => this.xmlTemplate(rowData)}/>
                </DataTable>
            </div>
        )
    }
}