import Enum from "../../../../enums/Enum";
import InternalSpecialityEnum from "../../../emr/recordpage/treatment/enums/referral/InternalSpecialityEnum";

const directBillingTypes = {
    CONSULTATION: {name: "Consultation"},
    PROCEDURE: {name: "Procedure"},
    VALIDITY_EXTENSION: {name: "Validity Extension"},
    ADDITIONAL_SESSIONS: {name: "Additional Sessions"},
};

const specialityTypes = {};

InternalSpecialityEnum.toArray().forEach(i => {
    specialityTypes[i.key] = i;
});

const EnumDirectBillingType = new Enum({
    ...specialityTypes,
    ...directBillingTypes
});

EnumDirectBillingType.directPaySelectableTypes = [
    EnumDirectBillingType.CHIROPRACTOR,
    EnumDirectBillingType.OSTEOPATH,
    EnumDirectBillingType.PHYSIOTHERAPIST,
    EnumDirectBillingType.KINESIOLOGY,
    EnumDirectBillingType.MOVEMENT_THERAPIST,
    EnumDirectBillingType.SPORTS_THERAPIST,
    EnumDirectBillingType.CONSULTATION,
    EnumDirectBillingType.PROCEDURE,
    EnumDirectBillingType.VALIDITY_EXTENSION,
    EnumDirectBillingType.ADDITIONAL_SESSIONS
];

export default EnumDirectBillingType;
