import Enum from "../../../../../../enums/Enum";

const PsychoSocialHistoryEnum = new Enum({
    STRESSED: {name: "Stressed"},
    DEPRESSED: {name: "Depressed"},
    PHYSICAL_ABUSE: {name: "Physical Abuse"},
    EMOTIONAL_ABUSE: {name: "Emotional Abuse"},
    PRESENCE_OF_FAMILY: {name: "Presence of family / Social Support"},
    FEEL_GOOD_SPIRITUALLY: {name: "Feel good spiritually"},
    ADEQUATE_TREATMENT_ADHERENCE: {name: "Adequate Treatment Adherence"},
    LOSS_OF_INTEREST: {name: "Loss of Interest"},
    NONE_REPORTED: {name: "None Reported"}
});

export default PsychoSocialHistoryEnum;