import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ListItemText} from "@material-ui/core";
import {AccordionBody, AccordionHeader, AccordionPanel, List} from "../../../components";

class RoomCalendarFilter extends React.Component {

    state = {
        expandedFilterPanel: 'staff',
    };

    handleExpand = (panel) => (event, newExpanded) => {
        this.setState({expandedFilterPanel: newExpanded ? panel : false});
    };

    renderStaff = (staff, index) => {
        return (
            <>
                {/*<ListItemAvatar><Avatar src={`${process.env.PUBLIC_URL}/assets/user.png`}/></ListItemAvatar>*/}
                <ListItemText id={`list-item-${index}`} primary={staff.name} secondary={staff.specialityName}/>
            </>
        );
    };

    render() {
        return (
            <div className="RoomCalendarFilter">
                <AccordionPanel square expanded={this.state.expandedFilterPanel === 'jlt'} onChange={this.handleExpand('jlt')}>
                    <AccordionHeader expandIcon={<FontAwesomeIcon icon={["fas", "chevron-down"]}/>}>Speciality</AccordionHeader>
                    <AccordionBody>
                        <List data={this.props.specialityList}
                              selectable={true} selected={this.props.selectedSpecialityList}
                              onChange={(values) => this.props.onSpecialitySelectionChange(values)}
                              labelField="name"/>
                    </AccordionBody>
                </AccordionPanel>
                <AccordionPanel square expanded={this.state.expandedFilterPanel === 'staff'} onChange={this.handleExpand('staff')}>
                    <AccordionHeader expandIcon={<FontAwesomeIcon icon={["fas", "chevron-down"]}/>}>Staff</AccordionHeader>
                    <AccordionBody>
                        <List data={this.props.staffList} selectAll={true}
                              selectable={true} selected={this.props.selectedStaffList}
                              onChange={(values) => this.props.onStaffSelectionChange(values)}
                              listItemFunc={(staff, index) => this.renderStaff(staff, index)}/>
                    </AccordionBody>
                </AccordionPanel>
            </div>
        )
    }
}

export default RoomCalendarFilter;