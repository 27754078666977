import React, {Component} from 'react';
import {FormSectionHeader} from "../../public/FormSectionHeader";
import IntakeFormTextEnum from "../enum/IntakeFormTextEnum";
import {FormLine} from "../../public/FormLine";
import FormField from "../../../../components/form/FormField";
import {FormPhoneInput} from "../../public/FormPhoneInput";
import {YesNoRadioInput} from "../../public/YesNoRadioInput";
import {SelectInput} from "../../../../components";

class PublicIntakeFormContactInformation extends Component {
    render() {
        const {
            selectedLanguage, enumLabel, form, onChange, textField, formFields
        } = this.props;

        return (
            <>
                <FormSectionHeader header={IntakeFormTextEnum.Contact_Information[selectedLanguage]}/>
                <FormLine label={IntakeFormTextEnum.Phone_Number[selectedLanguage]}>
                    <FormField
                        ref={(ref) => formFields.push(ref)}
                        required
                        validateOn={form.contactMobilePhone}
                    >
                        <FormPhoneInput
                            value={form.contactMobilePhone}
                            onValueChange={(value) =>
                                onChange("contactMobilePhone", value)
                            }
                        />
                    </FormField>
                </FormLine>
                <div className="row form-group">
                    <label className="col-6 col-form-label">
                        {IntakeFormTextEnum.Do_You_Use_This_Number_For_Whatsapp[selectedLanguage]}
                    </label>
                    <div className="col d-flex justify-content-end">
                            <YesNoRadioInput
                                value={form.doYouUseThisNumberForWhatsapp}
                                onValueChange={(value) => {
                                    if ("Yes" === value) {
                                        form.contactOtherPhone = "";
                                    }
                                    onChange("doYouUseThisNumberForWhatsapp", value);
                                }}
                                enumLabel={enumLabel}
                            />
                    </div>
                </div>
                {"No" === form.doYouUseThisNumberForWhatsapp && (
                    <FormLine label={IntakeFormTextEnum.Other_Phone[selectedLanguage]}>
                        <FormPhoneInput
                            value={form.contactOtherPhone}
                            onValueChange={(value) =>
                                onChange("contactOtherPhone", value)
                            }
                        />
                    </FormLine>
                )}
                <FormLine label={IntakeFormTextEnum.Email[selectedLanguage]}>
                    {textField(form, "contactEmail")}
                </FormLine>
                <FormLine
                    label={IntakeFormTextEnum.Address[selectedLanguage]}>{textField(form, "address")}</FormLine>
                <FormLine label={IntakeFormTextEnum.Country[selectedLanguage]}>
                    <SelectInput value={form.country}
                                 options={this.props.countries}
                                 className="DropdownBorderColor w-100"
                                 displayProperty={"name"}
                                 onChange={(val) => {
                                     onChange('country', val)

                                     if (val.name === 'United Arab Emirates') {
                                         onChange('stateOfResidence', 'Emirates')
                                     } else if (val.name === 'Kuwait') {
                                         onChange('stateOfResidence', 'Governate')
                                     } else {
                                         onChange('stateOfResidence', '')
                                     }
                                 }}
                    />

                </FormLine>
                <FormLine label={IntakeFormTextEnum.State[selectedLanguage]}>
                    {form.country?.name === 'United Arab Emirates' ?
                        <SelectInput value={form.stateOfResidence}
                                     className="w-100"
                                     options={["Abu Dhabi", "Dubai", "Sharjah", "Ajman", "Ras al Khaimah", "Fujairah", "Um Al Quwain", "Not Applicable"]}
                                     onChange={(val) => {
                                         onChange('stateOfResidence', val)
                                     }}
                        /> :
                        textField(form, "stateOfResidence")}
                </FormLine>
                <FormLine label={IntakeFormTextEnum.City[selectedLanguage]}>
                    {textField(form, "city")}
                </FormLine>
                <FormLine label={IntakeFormTextEnum.Postal_Code[selectedLanguage]}>
                    {textField(form, "postalCode")}
                </FormLine>
            </>
        );
    }
}

export default PublicIntakeFormContactInformation;
