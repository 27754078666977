import React, {Component} from 'react';
import {PhoneInput} from "../../../components";

export class FormPhoneInput extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <PhoneInput inputClass="w-100"
                           disabled={this.props.disabled}
                           value={this.props.value || ""}
                           onChange={(value) => this.props.onValueChange(value)}/>
    }

}
