import React from 'react'
import Notification from './notification/Notification';
import UserMenu from './usermenu/UserMenu';
import ClinicSelect from "./clinic/ClinicSelect";

const UserInfo = () => {
    return (
        <div className="UserInfo">
            <ClinicSelect/>
            <Notification/>
            <UserMenu/>
        </div>
    )
};

export default UserInfo;
