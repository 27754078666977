const riskEntered = (risk) => {
    return risk
        && (risk.none === true
            || risk.medical > 0
            || risk.surgical > 0
            || risk.activity > 0
            || risk.social > 0
        )
}

export default {riskEntered}