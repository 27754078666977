import React, { Component } from "react";
import './GradientSlider10Mark3Category.css';
import Slider from "@material-ui/core/Slider";

export default class GradientSlider100Mark4Category extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const marks = [];

        for(let i=0 ; i<=100; i++){
            marks.push({
                value: i,
                label: i%25 == 0 ? i : ""
            })
        }

        return (
            <div className={"gradient-slider"}>
                <div className={"reverse-colored-gradient-area"} style={{height: "30px"}}>
                    <div className={"row no-margin"} style={{textAlign: "center", height: "100%"}}>
                        <div className={"col-3 no-padding quarter-col"}>
                            <div>
                            </div>
                        </div>
                        <div className={"col-3 no-padding quarter-col"}>
                            <div>
                            </div>
                        </div>
                        <div className={"col-3 no-padding quarter-col"} >
                            <div>
                            </div>
                        </div>
                        <div className={"col-3 no-padding quarter-col"} style={{height: "100%", borderRight: "1px white solid"}}>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"gradient-slider-input"}>
                    <Slider
                        className={"z-index"}
                        defaultValue={this.props.value}
                        value={this.props.value}
                        aria-labelledby="discrete-slider-always"
                        step={1}
                        marks={marks}
                        valueLabelFormat={(value) => <div className="label-text">{value}</div>}
                        valueLabelDisplay="on"
                        min={0}
                        max={100}
                        onChangeCommitted={this.props.onChange}
                    />
                </div>
            </div>
        );
    }
}
