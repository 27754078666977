import React, {Component} from "react";
import InternalSpecialityEnum from "../../treatment/enums/referral/InternalSpecialityEnum";
import ExternalSpecialityEnum from "../../treatment/enums/referral/ExternalSpecialityEnum";
import InterventionTypeEnum from "../../treatment/enums/referral/InterventionTypeEnum";

export default class ReferralEMRDashboardView extends Component {

    render() {
        return (
            <>
                {
                    this.props.referral != null && this.props.referral.internals.length > 0 &&
                    <div>
                        <h6 style={{fontWeight: "bold"}}>Internal</h6>
                        <div className={"row content-card"}>
                            <table className={"emr-table"}>
                                <tbody>
                                <tr>
                                    <th>Speciality</th>
                                    <th>Provider Name</th>
                                    <th>CPT Code</th>
                                    <th>No of Sessions</th>
                                    <th>Remarks</th>
                                    <th>Approval Required</th>
                                </tr>
                                {
                                    this.props.referral.internals.map((item, index) => {
                                            return (
                                                <tr key={index} style={{backgroundColor: "white"}}>
                                                    <td>{InternalSpecialityEnum[item.speciality]?.name}</td>
                                                    <td>{item.provider?.name}</td>
                                                    <td>{
                                                        item.cptCodeList.map((cpt, index) => {
                                                            return (index === 0 ? "" : ", ") + cpt.cptCode + " " + cpt.description
                                                        })
                                                    }</td>
                                                    <td>{item.noOfSessions}</td>
                                                    <td>{item.remarks}</td>
                                                    <td>{true === item.needsApproval ? 'Yes' : 'No'}</td>
                                                </tr>);
                                        }
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                {
                    this.props.referral != null && this.props.referral.externals.length > 0 &&
                    <div>
                        <h6 style={{fontWeight: "bold", marginTop: "20px"}}>External</h6>
                        <div className={"row content-card"}>
                            <table className={"emr-table"}>
                                <tbody>
                                <tr>
                                    <th>Speciality</th>
                                    <th>Provider Name</th>
                                    <th>Recommendations</th>
                                    <th>Remarks</th>
                                </tr>
                                {
                                    this.props.referral.externals.map((item, index) => {
                                            return (
                                                <tr key={index} style={{backgroundColor: "white"}}>
                                                    <td>{item.speciality ? ExternalSpecialityEnum[item.speciality].name : null}</td>
                                                    <td>{item.provider}</td>
                                                    <td>{item.recommendations}</td>
                                                    <td>{item.remarks}</td>
                                                </tr>);
                                        }
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                {
                    this.props.referral != null && this.props.referral.interventions.length > 0 &&
                    <div>
                        <h6 style={{fontWeight: "bold", marginTop: "20px"}}>Interventions</h6>
                        <div className={"row content-card"}>
                            <table className={"emr-table"}>
                                <tbody>
                                <tr>
                                    <th>Type</th>
                                    <th>Details</th>
                                    <th>Recommendations</th>
                                    <th>Remarks</th>
                                </tr>
                                {
                                    this.props.referral.interventions.map((item, index) => {
                                            return (
                                                <tr key={index} style={{backgroundColor: "white"}}>
                                                    <td>{item.type ? InterventionTypeEnum[item.type].name : null}</td>
                                                    <td>{item.details}</td>
                                                    <td>{item.recommendations}</td>
                                                    <td>{item.remarks}</td>
                                                </tr>);
                                        }
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </>
        )
    }

}
