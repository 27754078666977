import React from 'react';
import {Card} from 'react-bootstrap';
import ReactTagInput from "@pathofdev/react-tag-input";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './ReactTagInput.scss'
import dialog from "../../../../components/dialog/Dialog";
import EditSpecializedConditionsModal from "../modal/EditSpecializedConditionsModal";

class StaffProfileSpecializedConditions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            specializedConditions: props.specializedConditions
        };
    }

    render() {
        return (
            <>
                <Card className="StaffMedium" style={{width: '100%'}}>
                    <Card.Header style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            Conditions I specialize in
                        </div>
                        <div>
                            {(this.props.editable &&
                                <FontAwesomeIcon icon={["fas", "edit"]}
                                                 onClick={this.handleEditSpecializedConditions}/>
                            )}
                        </div>
                    </Card.Header>
                    <Card.Body style={{display: 'flex', margin: 'auto'}}>
                        <Card.Text>
                            <div style={{width:'60vw'}}>
                                <ReactTagInput
                                    tags={this.state.specializedConditions.map(specializedCondition => specializedCondition.name)}
                                    readOnly
                                />
                            </div>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </>
        )
    }

    handleEditSpecializedConditions = () => {
        dialog({
            title: "Conditions I specialize in",
            component: <EditSpecializedConditionsModal staffId={this.props.staffId}
                                                       specializedConditions={this.state.specializedConditions}
                                                       onSpecializedConditionsUpdated={specializedConditions => this.onSpecializedConditionsUpdated(specializedConditions)}/>
        });
    };

    onSpecializedConditionsUpdated(specializedConditions) {
        this.setState({specializedConditions});
        this.props.onStaffProfileUpdate();
    }

}

export default StaffProfileSpecializedConditions;