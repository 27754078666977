import RemotingService from "../remoting-service/RemotingService";
import {noop} from "lodash-es";

class ParameterService {

    getParameters(callback) {
        RemotingService.getRemoteCall('api/parameter', null, (parameterMap) => {
            callback(parameterMap);
        }, noop, false);
    }

}

export default new ParameterService();