import {AlertConstants} from '../constants';

export function alert(state = {}, action) {
    switch (action.type) {
        case AlertConstants.SUCCESS:
            return {
                severity: 'success',
                sticky: action.message.sticky,
                summary: action.message.summary,
                detail: action.message.detail
            };
        case AlertConstants.WARN:
            return {
                severity: 'warn',
                sticky: action.message.sticky,
                summary: action.message.summary,
                detail: action.message.detail
            };
        case AlertConstants.ERROR:
            return {
                severity: 'error',
                sticky: action.message.sticky,
                summary: action.message.summary,
                detail: action.message.detail
            };
        case AlertConstants.CLEAR:
            return {};
        default:
            return state
    }
}