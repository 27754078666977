const UAE = "UAE";

const isPatientIdReadFromUAE = (patient) => {
    return patient?.patientIdReadFrom == UAE;
}

const getPatientName = (firstName, middleName, lastName) => {
    return (firstName != null && firstName.length != 0 ? firstName : "") +
           (middleName != null && middleName.length != 0 ? " " + middleName : "") +
           (lastName != null && lastName.length != 0 ? " " + lastName : "")
}

const getPatientsName = (patient) => {
    return getPatientName(patient.firstName, patient.middleName, patient.lastName);
}

const getPatientId = (patient) => {
    if(isPatientIdReadFromUAE(patient)){
        return patient.eid?.replace(/(\d{3})(\d{4})(\d{7})(\d)/, '$1-$2-$3-$4');
    }

    return patient.eid
}


export default {isPatientIdReadFromUAE, getPatientName, getPatientsName, getPatientId};
