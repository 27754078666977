import React from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import EnumScheduleGenerationPreference from "../enums/EnumScheduleGenerationPreference";
import {SelectInput, TextInput} from "../../../components";
import './RoomCalendarSettings.css'
import RemotingService from "../../../services/remoting-service/RemotingService";
import StringUtil from "../../../utils/StringUtil";
import AuthService from "../../../services/auth-service/AuthService";
import {Button, ButtonGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import intersectionWith from "lodash-es/intersectionWith";
import NotificationService from "../../../services/notification-service/NotificationService";
import {cloneDeep} from "lodash-es";

class RoomCalendarSettings extends React.Component {

    state = {
        staffList: [],
        specialityNameList: [],
        clinicNameList: [],
        tableData: [],
        filteredData: [],
        providerFilter: "",
        clinicFilterList: [],
        specialityFilterList: [],
        selectedPreference: {},
        attachedPreferences: []
    }

    componentDidMount() {
        this.retrieveSettingsData();
    }

    retrieveSettingsData() {
        RemotingService.getRemoteCall('api/speciality/list', null, (specialities) => {
            const clinicNames = new Set(AuthService.getUser().clinics.map(c => c.name));
            RemotingService.postRemoteCall('api/staff/schedule-generation-preferences/create', null, result => {
                let updatedResult = result.map(resultItem => {
                    return {
                        ...resultItem,
                        clinics: new Array(...new Set(resultItem.staff.departments.map(d => d.clinic.name))).sort(),
                        preference: resultItem.preference === "" ? null : resultItem.preference
                    }
                })
                this.setState({
                    clinicNameList: Array.from(clinicNames),
                    selectedPreference: {},
                    filteredData: updatedResult,
                    tableData: updatedResult,
                    specialityNameList: specialities.map(item => item.name),
                    attachedPreferences: cloneDeep(updatedResult)
                })
            })
        });
    }

    prepareScheduleGenerationPreferenceData(preferenceList) {
        let preferenceData = [];
        let clinicIds = AuthService.getClinics();
        preferenceList.map(p => {
            const staffClinicIds = p.staff.departments.map(d => d.clinic.id);
            if (intersectionWith(clinicIds, staffClinicIds).length > 0) {
                preferenceData.push(p);
            }
        });

        this.setState({attachedPreferences: preferenceData});
        return preferenceData;
    }

    handleMultiselectFilterChange(valueList, field) {
        field === 'specialtyName' ?
            this.setState({
                specialityFilterList: StringUtil.isNullOrEmpty(valueList) ? [] : valueList,
                selectedPreference: {}
            }) :
            this.setState({
                clinicFilterList: StringUtil.isNullOrEmpty(valueList) ? [] : valueList,
                selectedPreference: {}
            });
    }

    handleTextFilterChange(val) {
        this.setState({providerFilter: StringUtil.isNullOrEmpty(val) ? "" : val, selectedPreference: {}});
    }

    handlePreferenceFilterChange(preference) {
        let itemsExcludedFromScheduling = this.state.attachedPreferences.filter(item => item.preference === "EXCLUDE_FROM_SCHEDULING")
        if(itemsExcludedFromScheduling.length > 0 && preference !== "CHANGE_SCHEDULE_FREELY") {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'There is at least one staff having "Excluded from Scheduling" preference, only "Change Schedule Freely" can be selected'
            });
            return;
        }

        this.getFilteredRows().forEach(p => p.preference = preference);
        this.setState({selectedPreference: preference});
    }

    settingsDropdownChange(item, value) {
        let attachedItem = this.state.attachedPreferences.filter(preference => preference.id === item.id)[0];
        if(attachedItem.preference === "EXCLUDE_FROM_SCHEDULING" &&
            value !== "CHANGE_SCHEDULE_FREELY") {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Only "Change Schedule Freely" can be selected for a staff having "Excluded from Scheduling" preference'
            });
            return;
        }

        let updatedTableData = this.state.tableData.map(data => {
            if (data.staff.id === item.staff.id) {
                data.preference = value;
            }
            return data;
        })

        this.setState({tableData: updatedTableData, selectedPreference: {}});
    }

    settingsDropdownTemplate = (item, field) => {
        return (
            <SelectInput className="w-100" value={item[field]}
                         options={EnumScheduleGenerationPreference.toArray()}
                         onChange={(val) => this.settingsDropdownChange(item, val)} displayProperty="name"
                         valueProperty="key"
                         placeholder="Apply preference to providers"/>
        )
    }

    getFilteredRows() {
        let filteredRows = this.state.tableData;

        if (this.state.clinicFilterList.length > 0) {
            filteredRows = filteredRows.filter(p => intersectionWith(this.state.clinicFilterList, p.clinics).length > 0);
        }

        if (this.state.specialityFilterList.length > 0) {
            filteredRows = filteredRows.filter(p => this.state.specialityFilterList.includes(p.staff.specialityName));
        }

        if (this.state.providerFilter.length > 0) {
            filteredRows = filteredRows.filter(p => p.staff.name.toLowerCase().includes(this.state.providerFilter.toLowerCase()));
        }

        return filteredRows;
    }

    handleScheduleGenerationSaveClick() {
        let resultData = this.state.tableData.map(item => {
            return {
                id: item.id,
                staff: item.staff,
                clinic: item.clinic,
                preference: item.preference
            }
        })
        RemotingService.postRemoteCall('api/staff/schedule-generation-preferences/update', resultData, result => {
            this.prepareScheduleGenerationPreferenceData(result);
        });
    }

    renderTopButtons() {
        return <div className="room-calendar-settings-top-bar">
            <ButtonGroup>
                <Button variant="outline-secondary"
                        className="top-bar-button"
                        onClick={() => this.handleSwitchScheduleMode()}>
                    <FontAwesomeIcon icon={["fas", "exchange-alt"]}/> {'Schedule'}
                </Button>
            </ButtonGroup>
            {AuthService.userHasAuthority("GENERATE_SCHEDULE_PREFERENCE") && <ButtonGroup>
                <Button variant="success"
                        className="top-bar-button"
                        onClick={() => this.handleScheduleGenerationSaveClick()}>
                    <FontAwesomeIcon icon={["fas", "save"]}/> {'Save'}
                </Button>
            </ButtonGroup>}
            {AuthService.userHasAuthority("GENERATE_SCHEDULE_PREFERENCE") && <ButtonGroup>
                <Button variant="danger"
                        className="top-bar-button"
                        onClick={() => this.retrieveSettingsData()}>
                    <FontAwesomeIcon icon={["fas", "undo-alt"]}/> {'Reset'}
                </Button>
            </ButtonGroup>}
        </div>
    }

    renderTableHeaders() {
        return <div className="query-fields">
            <div className="col-md-3 dim14-form-label table-header">
                Provider
            </div>
            <div className="col-md-3 dim14-form-label table-header">
                Clinic
            </div>
            <div className="col-md-3 dim14-form-label table-header">
                Specialty
            </div>
            <div className="col-md-3 dim14-form-label">
                Schedule Generation Preference
            </div>
        </div>;
    }

    renderFilterFields() {
        return <div className="query-fields">
            <div className="col-md-3 dim14-base-input table-header">
                <TextInput value={this.state.providerFilter}
                           onChange={(val) => this.handleTextFilterChange(val)}
                           placeholder="Filter by provider name"/>
            </div>
            <div className="col-md-3 dim14-base-input table-header">
                <SelectInput value={this.state.clinicFilterList}
                             options={this.state.clinicNameList} searchEnabled={true}
                             multiSelect={true} onChange={(val) => this.handleMultiselectFilterChange(val, "clinic")}
                             placeholder="Filter by clinic name"/>
            </div>
            <div className="col-md-3 dim14-base-input table-header">
                <SelectInput value={this.state.specialityFilterList}
                             options={this.state.specialityNameList} searchEnabled={true}
                             multiSelect={true}
                             onChange={(val) => this.handleMultiselectFilterChange(val, "specialtyName")}
                             placeholder="Filter by specialty"/>
            </div>
            <div className="col-md-3 dim14-base-input">
                {AuthService.userHasAuthority("GENERATE_SCHEDULE_PREFERENCE") &&
                <SelectInput value={this.state.selectedPreference}
                             options={EnumScheduleGenerationPreference.toArray()} searchEnabled={true}
                             onChange={p => this.handlePreferenceFilterChange(p)} displayProperty="name"
                             valueProperty="key"
                             placeholder="Apply preference to providers"/>}
            </div>
        </div>;
    }

    handleSwitchScheduleMode() {
        this.props.history.push('/roomSchedule');
    }

    render() {
        let filteredData = this.getFilteredRows();

        return (
            <>
                {this.renderTopButtons()}

                <div className="settings">
                    {this.renderTableHeaders()}
                    {this.renderFilterFields()}

                    <DataTable className="settings-table"
                               emptyMessage={"No items found"}
                               value={filteredData}
                               rows={20}
                               paginator={true}
                               paginatorPosition="bottom"
                               scrollable>
                        <Column field="staff.name" sortable/>
                        <Column field="clinics" body={p => p.clinics.join()} sortable/>
                        <Column field="staff.specialityName" sortable/>
                        {AuthService.userHasAuthority("GENERATE_SCHEDULE_PREFERENCE") ?
                            <Column field="preference" body={p => this.settingsDropdownTemplate(p, "preference")}/> :
                            <Column field="preference" body={p => EnumScheduleGenerationPreference[p.preference]}
                                    sortable/>}
                    </DataTable>
                </div>
            </>
        );
    }
}

export default RoomCalendarSettings;
