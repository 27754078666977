import React, {Component} from 'react';
import FormField from "../../../components/form/FormField";
import {SelectInput} from "../../../components";
import InformedConsentYesNoEnum from "./enum/InformedConsentYesNoEnum";

class InformedConsentYesNoInput extends Component {
    render() {
        const {readOnly, traineeConsent, onChange, enumLabel, pushFormFieldRef} = this.props;

        return (
            <FormField ref={ref => pushFormFieldRef(ref)} required
                       validateOn={readOnly ? true : traineeConsent}>
                <SelectInput
                    value={traineeConsent?.toString()}
                    options={InformedConsentYesNoEnum.toArray()}
                    displayProperty={enumLabel} valueProperty="key"
                    disabled={readOnly}
                    style={{width: '120px'}}
                    searchEnabled={false}
                    showClear={false}
                    onChange={(value) => onChange('traineeConsent', value)}/>
            </FormField>
        );
    }
}

export default InformedConsentYesNoInput;