import React from 'react';
import Logo from './Logo';
import Menu from './Menu';
import UserInfo from '../modules/user/UserInfo';
import {connect} from "react-redux";
import {getCountries, getLanguages, getNationalities, getRelationshipTypes} from "../services/actions";

class Header extends React.Component {

    componentDidMount() {
        this.appInit();
    }

    appInit = () => {
        this.props.dispatch(getNationalities());
        this.props.dispatch(getCountries());
        this.props.dispatch(getRelationshipTypes());
        this.props.dispatch(getLanguages());
    };

    render() {
        const user = this.props.user && this.props.user.token;
        return (
            <>
            <div className="Header">
                <Logo/>
                <div  className={"d-none d-xl-flex col"}>
                  {user && <Menu/>}
                </div>
                {user && <UserInfo/>}
            </div>
                <div className="d-xl-none d-flex">
                      {user && <Menu/>}
                </div>
            </>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.authentication.user
    };
};

export default connect(mapStateToProps)(Header);
