import React, {Component} from "react";
import '../../VisitHistory.css'
import ShortTermGoalEnum from "../../../treatment/enums/goal/ShortTermGoalEnum";
import TimelinePeriodEnum from "../../../treatment/enums/goal/TimelinePeriodEnum";
import ProgressEnum from "../../../treatment/enums/goal/ProgressEnum";

export default class TreatmentGoalsHistoryView extends Component {

    render() {
        const goal = this.props.goal;

        if (!goal) {
            return <div className="pl-3">No information available</div>;
        }

        const hasDetails = goal.details;
        const hasSpecialRemarks = goal.specialRemarks;

        return (
            <div>
                {hasDetails &&
                <div>
                    <b>Patient Goal: </b> {goal.details}
                </div>}

                {this.renderGoalsTable(goal, 'short')}
                {this.renderGoalsTable(goal, 'long')}

                {hasSpecialRemarks &&
                <div>
                    <br/>
                    <b>Special Remarks: </b> {goal.specialRemarks}
                </div>}
            </div>
        );
    }

    renderGoalsTable = (goal, goalType) => {
        const forShortGoals = 'short' === goalType;
        const goalItems = forShortGoals ? goal.shortTermItems : goal.longTermItems;

        if (!goalItems || !goalItems.length) {
            return null;
        }

        return <>
            <br/>
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Area of Focus</th>
                    <th>{forShortGoals ? 'Short' : 'Long'} Term Goals</th>
                    <th>Target</th>
                    <th colSpan="2">Timeline</th>
                    <th>%Ach</th>
                    <th>Progress</th>
                </tr>
                {goalItems.map((item, index) => {
                    return (<tr key={"shortTermGoalItem" + index}
                                style={{backgroundColor: "white"}}>
                        <td>{item.areaOfConcern}</td>
                        <td style={{maxWidth: '150px'}}>{item.shortTermGoal && ShortTermGoalEnum[item.shortTermGoal].name || item.longTermGoal}</td>
                        <td>{item.target ? `${item.target}%` : null}</td>
                        <td>{item.duration}</td>
                        <td>{item.period && TimelinePeriodEnum[item.period].name}</td>
                        <td>{item.achieved ? `${item.achieved}%` : null}</td>
                        <td>{item.progress && ProgressEnum[item.progress].name}</td>
                    </tr>)
                })}
                </tbody>
            </table>
        </>
    }
}
