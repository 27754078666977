import React, {Component} from "react";
import '../../EMR.css';
import Select from "react-select";
import TextInput from "../../../../../components/text-input/TextInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import TopicEnum from "../enums/patienteducation/TopicEnum";
import FocusEnum from "../enums/patienteducation/FocusEnum";
import AddButton from "../../common/table/AddButton";
import RemoveButton from "../../common/table/RemoveButton";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import MaterialRadioButton from "../../../../../components/radio-input/MaterialRadioButton";
import StringUtil from "../../../../../utils/StringUtil";

export default class PatientEducationModal extends Component {
    constructor(props) {
        super(props);
        this.customStyles = {
            option: (provided, state) => ({
                ...provided,
                textAlign:'left',
                whiteSpace:'nowrap',

            }),

            menu:(provided, state)=>({
                ...provided,
                position:'absolute',
                width:'100wv',

            }),
            menuList: (provided, state) => ({
                ...provided,
            }),


        }
    }

    state = {
        activePatientEducation: {}
    }

    focusMap = {
        ERGONOMICS: ["PROPER_DESK", "POSTURAL_SUPPORT_LUMBAR", "POSTURAL_SUPPORT_WRIST"],
        SMR: ["BACK", "HIPS", "NECK", "SHOULDERS", "LEGS"],
        CRYOTHERAPY: ["APPLY_ICE_10_MINUTES_OFF_ON", "APPLY_ICE_10_MINUTES"],
        THERMOTHERAPY: ["APPLY_HEAT_10_MINUTES_OFF_ON", "APPLY_HEAT_10_MINUTES"],
        AVOID_AGGRAVATING_ACTIVITIES: ["HOURS_24_48", "WEEK_1", "WEEKS_2"],
        REST: ["HOURS_24_48", "WEEK_1", "WEEKS_2"]
    }

    render() {
        return (
            <div className={"emr-modal content-row emr-background"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px", minHeight: "20vw"}}>
                    <div style={{display: "flex"}}>
                        <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Patient Education <RedAsterisk renderForAllTypes/>
                        </div>
                    </div>

                    <div style={{minHeight: "150px"}}>
                        <div className={"content-card"}>
                            <table className={"emr-table"}>
                                <tbody>
                                <tr>
                                    <th colSpan={2}>Education</th>
                                    <th colSpan={2}>Rationale</th>
                                </tr>
                                <tr style={{backgroundColor: "rgb(250,250,250)"}}>
                                    <th style={{minWidth: "150px"}}>Topic</th>
                                    <th style={{minWidth: "150px"}}>Focus</th>
                                    <th></th>
                                    <th style={{color: "red"}}>ⓧ</th>
                                </tr>
                                {(this.props.patientEducation == null || this.props.patientEducation.items.length == 0)
                                    ? null : this.props.patientEducation.items.map((m, index) => {
                                        return (<tr key={"patientEducationModal" + index}
                                                    style={{backgroundColor: "white"}}>
                                            <td>{m.topic != null ? TopicEnum[m.topic].name : ""}</td>
                                            <td>{m.focus != null ? FocusEnum[m.focus].name : ""}</td>
                                            <td style={{wordWrap: "break-word"}}>{m.topic != null ? TopicEnum[m.topic].rationale : ""}</td>
                                            <td style={{width: "10px"}}>
                                                <RemoveButton
                                                    onClick={() => {
                                                        this.props.patientEducation.items.splice(index, 1);
                                                        this.forceUpdate();
                                                    }}
                                                />
                                            </td>
                                        </tr>)
                                    })}
                                <tr style={{backgroundColor: "white"}}>
                                    <td style={{minWidth: "80px"}}>
                                        <Select
                                            className={"emr-in-table-select-container"}
                                            classNamePrefix={"emr-in-table-select"}
                                            styles={this.customStyles}
                                            placeholder={"Type"}
                                            value={this.state.activePatientEducation.topic != null ?
                                                {
                                                    value: this.state.activePatientEducation.topic,
                                                    label: TopicEnum[this.state.activePatientEducation.topic].name
                                                } : null}
                                            onChange={(event) => {
                                                this.setState((state) => {
                                                    state.activePatientEducation.topic = event.value;
                                                    return state;
                                                });
                                            }}
                                            options={TopicEnum.toArray().map(v => {
                                                    return {value: v.key, label: v.name}
                                                }
                                            )}
                                        />
                                    </td>
                                    <td>
                                        {this.state.activePatientEducation.topic != null &&
                                        this.state.activePatientEducation.topic != 'DYNAMIC_MOBILITY_WARM_UP' &&
                                        this.state.activePatientEducation.topic != 'CORE_MUSCLE_ACTIVATION' &&
                                        this.state.activePatientEducation.topic != 'CORRECTIVE_MOVEMENT' &&
                                        <Select
                                            className={"emr-in-table-select-container"}
                                            classNamePrefix={"emr-in-table-select"}
                                            placeholder={"Type"}
                                            value={this.state.activePatientEducation.focus != null ?
                                                {
                                                    value: this.state.activePatientEducation.focus,
                                                    label: FocusEnum[this.state.activePatientEducation.focus].name
                                                } : null}
                                            onChange={(event) => {
                                                this.setState((state) => {
                                                    state.activePatientEducation.focus = event.value;
                                                    return state;
                                                });
                                            }}
                                            options={this.focusMap[this.state.activePatientEducation.topic].map(v => {
                                                return {value: v, label: FocusEnum[v].name}
                                            })}
                                        />
                                        }
                                    </td>
                                    <td>
                                        {this.state.activePatientEducation.topic != null ? TopicEnum[this.state.activePatientEducation.topic].rationale : ""}
                                    </td>
                                    <td>

                                        <AddButton
                                            disabled={StringUtil.isNullOrEmpty(this.state.activePatientEducation.topic)}
                                            onClick={() => {
                                                this.props.patientEducation.items.push(this.state.activePatientEducation);
                                                this.setState({activePatientEducation: {}});
                                            }}
                                        />
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <br/>
                    <br/>

                    <div>
                        <div className={"row no-margin"}>
                            <div className={"col-6"}>
                                <div style={{fontSize: "13px"}}>
                                    Has the patient understood the education content provided?
                                </div>
                            </div>
                            <div className={"col-2 no-padding no-margin"}>
                                <MaterialRadioButton name="understoodContentYesNo"
                                                     value={this.props.patientEducation.understoodContent == undefined ? null : this.props.patientEducation.understoodContent}
                                                     onChange={(value) => {
                                                         let r = (value != null ? (value === 'true') : null);
                                                         this.props.patientEducation.understoodContent = r;
                                                         this.forceUpdate();
                                                     }}>
                                    <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "10px"}}
                                                      value={true} control={<Radio/>}
                                                      label="Yes"
                                    />
                                    <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "10px"}}
                                                      value={false} control={<Radio/>}
                                                      label="No"
                                    />
                                </MaterialRadioButton>
                            </div>
                            <div className={"col-4"}>
                                <TextInput
                                    value={this.props.patientEducation.understoodContentDetail || ''}
                                    placeholder={"Details"}
                                    onChange={value => {
                                        this.props.patientEducation.understoodContentDetail = value;
                                        this.forceUpdate();
                                    }}
                                    style={{fontSize: "14px", width: "100%"}}
                                />
                            </div>
                        </div>

                        <div className={"row no-margin"}>
                            <div className={"col-6"}>
                                <div style={{fontSize: "13px"}}>
                                    Does the patient need more explanation during the next visit?
                                </div>
                            </div>
                            <div className={"col-2 no-padding no-margin"}>
                                <MaterialRadioButton name="moreExplanationYesNo"
                                                     value={this.props.patientEducation.moreExplanation == undefined ?
                                                         null : this.props.patientEducation.moreExplanation
                                                     }
                                                     onChange={(value) => {
                                                         let r = (value != null ? (value === 'true') : null);
                                                         this.props.patientEducation.moreExplanation = r;
                                                         this.forceUpdate();
                                                     }}>
                                    <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "10px"}}
                                                      value={true} control={<Radio/>}
                                                      label="Yes"
                                    />
                                    <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "10px"}}
                                                      value={false} control={<Radio/>}
                                                      label="No"
                                    />
                                </MaterialRadioButton>
                            </div>
                            <div className={"col-4"}>
                                <TextInput
                                    value={this.props.patientEducation.moreExplanationDetail || ''}
                                    placeholder={"Details"}
                                    onChange={value => {
                                        this.props.patientEducation.moreExplanationDetail = value;
                                        this.forceUpdate();
                                    }}
                                    style={{fontSize: "14px", width: "100%"}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}