import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import Tooltip from "../../../../../components/tooltip/Tooltip";

const AddButton = ({onClick, disabled = false}) => {
    function getButton() {
        return <Button className={"emr-plus-button emr-add-button"}
                       variant="success"
                       disabled={disabled}
                       onClick={onClick}
        >
            <FontAwesomeIcon icon={["fas", "plus"]}/>
        </Button>;
    }

    return (
        !disabled ?
            <Tooltip title="Add record" placement="bottom">
                {getButton()}
            </Tooltip> : getButton()
    );
}


export default AddButton;