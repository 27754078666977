import React, {Component} from "react";
import {Button} from "react-bootstrap";
import {Modal, TextareaInput} from "../../../components";
import RemotingService from "../../../services/remoting-service/RemotingService";
import FormField from "../../../components/form/FormField";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import NotificationService from "../../../services/notification-service/NotificationService";
import AuthService from "../../../services/auth-service/AuthService";

class AddNoteButton extends Component {

    constructor(props) {
        super(props);

        this.state = {
            newNoteText: null,
            newNoteModalVisible: false
        };
    }

    render() {
        this.formFields = [];
        return <>
                    <Button variant={"primary"}
                            size={"sm"}
                            className={this.props.className}
                            onClick={this.showNewNoteModal}
                            disabled={!AuthService.userHasAuthority("UPDATE_APPOINTMENT")}>
                        Add Note
                        {this.props.appointment.notes.length > 0 ? '(' + this.props.appointment.notes.length + ')' : ''}
                    </Button>
                    {
                    this.state.newNoteModalVisible &&
                    <Modal visible={this.state.newNoteModalVisible}
                           header="New Note"
                           submitContent="Save"
                           submitAction={this.handleNewNoteSubmit}
                           closeAction={() => this.setState({newNoteModalVisible: false, newNoteText: null})}
                    >
                        <FormField ref={formField => this.formFields.push(formField)} required maxLength={500} validateOn={this.state.newNoteText}>
                            <TextareaInput className="BiographyRichTextInput" style={{minWidth:"450px"}}
                                           onChange={(value) => this.setState({newNoteText: value})}/>
                        </FormField>
                    </Modal>
                    }
                </>
    }

    showNewNoteModal = () => {
        this.setState({newNoteModalVisible: true});
    }

    handleNewNoteSubmit = () => {
        if (!ValidationUtil.isValid(this.formFields)) {
            NotificationService.showNotification({ severity: 'error', summary: 'Validation Error', detail: 'There are missing fields.' });
            return;
        }

        const addNoteDto = {appointmentId: this.props.appointment.id, staffId: AuthService.getStaffId(), text: this.state.newNoteText};

        RemotingService.postRemoteCall('api/appointment/note', addNoteDto, (note) => {
            this.setState({newNoteText: '', newNoteModalVisible: false}, () => {
                this.props.onNoteAdded(note)
            });
        });
    }
}

export default AddNoteButton;