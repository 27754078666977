import React, {Component} from "react";
import InternalSpecialityEnum from "../../../treatment/enums/referral/InternalSpecialityEnum";
import ExternalSpecialityEnum from "../../../treatment/enums/referral/ExternalSpecialityEnum";
import InterventionTypeEnum from "../../../treatment/enums/referral/InterventionTypeEnum";

export default class ReferralHistory extends Component {

    render() {
        return (
            <>
                {
                    this.props.referral != null && this.props.referral.internals.length > 0 &&
                    <div className="pt-3">
                        <div>
                            <h5 className="font-weight-bold">Internal</h5>
                            <table className={"visit-history-table"}>
                                <tbody>
                                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                                    <th>Speciality</th>
                                    <th>Provider Name</th>
                                    <th>CPT Code</th>
                                    <th>No of Sessions</th>
                                    <th>Remarks</th>
                                </tr>
                                {
                                    this.props.referral.internals.map((item, index) => {
                                            return (
                                                <tr key={index} style={{backgroundColor: "white"}}>
                                                    <td>{InternalSpecialityEnum[item.speciality]?.name}</td>
                                                    <td>{item.provider?.name}</td>
                                                    <td>{item.cptCodeList.map(cpt => cpt.cptCode).join(", ")}</td>
                                                    <td>{item.noOfSessions}</td>
                                                    <td>{item.remarks}</td>
                                                </tr>);
                                        }
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                {
                    this.props.referral != null && this.props.referral.externals.length > 0 &&
                    <div className="pt-3">
                        <div>
                            <h5 className="font-weight-bold">External</h5>
                            <table className={"visit-history-table"}>
                                <tbody>
                                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                                    <th>Speciality</th>
                                    <th>Provider Name</th>
                                    <th>Recommendations</th>
                                    <th>Remarks</th>
                                </tr>
                                {
                                    this.props.referral.externals.map((item, index) => {
                                            return (
                                                <tr key={index} style={{backgroundColor: "white"}}>
                                                    <td>{ExternalSpecialityEnum[item.speciality]?.name}</td>
                                                    <td>{item.provider}</td>
                                                    <td>{item.recommendations}</td>
                                                    <td>{item.remarks}</td>
                                                </tr>);
                                        }
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                {
                    this.props.referral != null && this.props.referral.interventions.length > 0 &&
                    <div className="pt-3">
                        <div>
                            <h5 className="font-weight-bold">Interventions</h5>
                            <table className={"visit-history-table"}>
                                <tbody>
                                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                                    <th>Type</th>
                                    <th>Details</th>
                                    <th>Recommendations</th>
                                    <th>Remarks</th>
                                </tr>
                                {
                                    this.props.referral.interventions.map((item, index) => {
                                            return (
                                                <tr key={index} style={{backgroundColor: "white"}}>
                                                    <td>{InterventionTypeEnum[item.type]?.name}</td>
                                                    <td>{item.details}</td>
                                                    <td>{item.recommendations}</td>
                                                    <td>{item.remarks}</td>
                                                </tr>);
                                        }
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </>
        )
    }

}
