import React, {Component} from "react";
import './BorderedRadioInput.css';

export default class BorderedRadioLookupInput extends Component {

    render() {
        const items = []

        this.props.values.map((lookup) => {
            const isSelected = this.props.selected?.id === lookup.id
            items.push(
                <div className={"bordered-radiobutton" + (isSelected ? " selected" : "")}
                     style={this.props.itemStyle}
                     key={lookup.id}
                     onClick={() => {
                         this.props.onSelect(isSelected ? null : lookup);
                         this.forceUpdate();
                     }}>
                    {lookup.name}
                </div>);
        });

        return (<div className={"disc-radiobutton-container"}>
            {items}
        </div>);
    }
}
