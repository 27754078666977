import React, {Component} from "react";
import DateUtil from "../../../../../utils/DateUtil";
import ReportViewUtils from "./ReportViewUtils";

export default class DischargePlanReadinessReportView extends Component {

    render() {
        const plan = this.props.dischargePlan;

        if (ReportViewUtils.isDischargePlanReadinessNull(plan)) {
            return null;
        }

        return (
            <div>
                {plan.anticipatedDischargeDate &&
                <div className="d-flex flex-column">
                    <div>Anticipated Date of Discharge: {DateUtil.formatDateDoMMYY(plan.anticipatedDischargeDate)}</div>
                    <div>{plan.anticipatedDischargeDetails}</div>
                </div>}

                {plan.goalAchieved && <>
                    <br/>
                    <div className="d-flex flex-column">
                        <div><b>Treatment Goal % Achieved</b></div>
                        {plan.goalAchieved}%{plan.goalAchievedDetails ? "; " + plan.goalAchievedDetails : null}
                    </div>
                </>}
            </div>
        );
    }
}
