import React from 'react';
import {connect} from "react-redux";
import {
    getAllergenReactionTypes,
    getAllergenTypes,
    getAllergySeverityTypes,
    getAllergyTypes,
    getDrinkingStatuses,
    getEducationLevels,
    getEmploymentStatuses,
    getGenders,
    getMaritalStatuses,
    getMedicationRoutes,
    getMedicationUnits,
    getParameters,
    getReligions,
    getSchedules,
    getSmokingDevices,
    getSmokingStatuses,
    getSubstanceAbuseStatuses
} from "../services/actions";
import AuthService from "../services/auth-service/AuthService";

class LoginData extends React.Component {

    componentDidMount() {
        this.appInit();
    }

    appInit = () => {
        // can retrieve and cache application wide data that requires login here
        this.props.dispatch(getSchedules());
        this.props.dispatch(getParameters());
        this.props.dispatch(getReligions());
        this.props.dispatch(getMaritalStatuses());
        this.props.dispatch(getAllergyTypes());
        this.props.dispatch(getAllergenTypes());
        this.props.dispatch(getAllergySeverityTypes());
        this.props.dispatch(getAllergenReactionTypes());
        this.props.dispatch(getGenders());
        this.props.dispatch(getEmploymentStatuses());
        this.props.dispatch(getEducationLevels());
        this.props.dispatch(getSmokingDevices());
        this.props.dispatch(getSmokingStatuses());
        this.props.dispatch(getDrinkingStatuses());
        this.props.dispatch(getSubstanceAbuseStatuses());
        this.props.dispatch(getMedicationRoutes());
        this.props.dispatch(getMedicationUnits());
        this.props.loginTrigger(AuthService.getUser().email);
    };

    render() {
        return (
            <>
            </>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.authentication.user
    };
};

export default connect(mapStateToProps)(LoginData);
