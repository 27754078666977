import React, {Component} from "react";
import {Modal, TextInput} from "../../../components";
import DateUtil from "../../../utils/DateUtil";
import {InputText} from "primereact/inputtext";
import {FormPhoneInput} from "./FormPhoneInput";
import GiftIcon from "../../../components/action-menu/icons/GiftIcon";
import RemotingService from "../../../services/remoting-service/RemotingService";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {Button} from "react-bootstrap";
import {FormField} from "../../../components/form";
import MoneyUtil from "../../../utils/MoneyUtil";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import CreditGiftDataTable from "../../patient/patientdetail/credit/CreditGiftDataTable";

export default class PublicCreditGiftForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            giftCreditModalVisible: true,
            formDisabled: false
        };
    }

    close() {
        this.setState({giftCreditModalVisible: false}, () => this.props.close());
    }

    render() {
        const {form, consentFormCode} = this.props;

        return (
            this.state.giftCreditModalVisible &&
            <Modal visible={this.state.giftCreditModalVisible}
                   fullScreen={true}
                   maxWidth={"lg"}
                   paperStyle={{overflowY: "auto", backgroundColor: "#f5f5f5"}}
                   scroll={'paper'}
                   header="SEND DISC CREDIT GIFT"
                   closeAction={() => this.close()}>
                <Tabs defaultActiveKey="SEND_GIFT"
                      activeKey={this.state.selectedTab}
                      onSelect={(key) => {this.setState({selectedTab: key})}}>
                    <Tab eventKey="SEND_GIFT" title="Send Gift">
                        <SendGiftCredit form={form}
                                        code={consentFormCode}
                                        onSent={() => this.setState({selectedTab: "GIFT_HISTORY"})}/>
                    </Tab>
                    <Tab eventKey="GIFT_HISTORY" title="Gift History">
                        {this.state.selectedTab === "GIFT_HISTORY" && <GiftHistory code={consentFormCode}
                                                                                   patientId={this.props.form.patientId}/>}
                    </Tab>
                </Tabs>
            </Modal>
        )
    }
}

class SendGiftCredit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            form: props.form,
            formDisabled: false,
            currency: MoneyUtil.getCurrency(props.form.credit.country)
        };
    }

    sendGift() {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        const {code, onSent} = this.props;
        const form = this.state.form;

        RemotingService.postRemoteCall(`/api/public/consent-form/code/${code}/credit-gift-form`, form, () => {
            this.setState({formDisabled: true});
            onSent();
        });
    }

    fieldChanged(field, value) {
        this.setState(prevState => {
            prevState.form[field] = value;
            return {...prevState};
        });
    }

    render() {
        this.formFields = [];
        let {form, formDisabled} = this.state;

        return (
            <div className="d-flex flex-column align-items-center">
                <div className="card w-50 mt-4">
                    <div className="card-header">
                        <div className="card-header-title font-weight-bold">
                            My DISC Wallet
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col font-weight-bold">
                                Validity
                            </div>
                            <div className="col font-weight-bold">
                                Discount
                            </div>
                            <div className="col font-weight-bold text-right">
                                Amount
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                {DateUtil.formatDateMMM(form.credit.validityDate)}
                            </div>
                            <div className="col">
                                {form.credit.discountRate}<small>%</small>
                            </div>
                            <div className="col d-flex flex-column text-right">
                                <span>{MoneyUtil.format(form.credit.balance, this.state.currency)}<small>{this.state.currency}</small></span>
                                <span className="text-danger">{MoneyUtil.format(-form.credit.balance, this.state.currency)}<small>{this.state.currency}</small></span>
                                <span className="text-success">{MoneyUtil.format(0, this.state.currency)}<small>{this.state.currency}</small></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card w-50 mt-4">
                    <div className="card-header">
                        <div className="card-header-title font-weight-bold">
                            Send Gift
                        </div>
                        <GiftIcon/>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col d-flex flex-column">
                                First Name
                                <FormField ref={formField => this.formFields.push(formField)}
                                           required validateOn={form.firstName}>
                                    <TextInput disabled={formDisabled}
                                               value={form.firstName}
                                               onChange={(value) => this.fieldChanged("firstName", value)} />
                                </FormField>
                            </div>
                            <div className="col d-flex flex-column">
                                Last Name
                                <FormField ref={formField => this.formFields.push(formField)}
                                           required validateOn={form.lastName}>
                                    <TextInput disabled={formDisabled}
                                               value={form.lastName}
                                               onChange={(value) => this.fieldChanged("lastName", value)} />
                                </FormField>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col d-flex flex-column">
                                Phone
                                <FormField ref={formField => this.formFields.push(formField)}
                                           required validateOn={form.phone}>
                                    <FormPhoneInput disabled={formDisabled}
                                                    value={form.phone}
                                                    onValueChange={(value) => this.fieldChanged("phone", value)}/>
                                </FormField>
                            </div>
                            <div className="col d-flex flex-column">
                                E-Mail
                                <FormField ref={formField => this.formFields.push(formField)}
                                           required email validateOn={form.email}>
                                    <TextInput disabled={formDisabled}
                                               value={form.email}
                                               onChange={(value) => this.fieldChanged("email", value)} />
                                </FormField>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col d-flex flex-column">
                                Amount
                                <FormField ref={formField => this.formFields.push(formField)}
                                           required validateOn={form.credit.balance}>
                                    <div className="p-inputgroup">
                                        <InputText disabled style={{textAlign: 'end', width: 'calc(100% - 55px)'}}
                                                   value={MoneyUtil.format(form.credit.balance, this.state.currency)}/>
                                        <span className="p-inputgroup-addon">
                                            {this.state.currency}
                                        </span>
                                    </div>
                                </FormField>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer text-right">
                        <Button variant="success"
                                disabled={formDisabled}
                                onClick={() => this.sendGift()}>
                            Send
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

}

class GiftHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            creditGiftForms: []
        };
    }

    componentDidMount() {
        const {code} = this.props;

        RemotingService.getRemoteCall(`/api/public/consent-form/code/${code}/credit-gift-form`, null, (creditGiftForms) => {
            this.setState({creditGiftForms});
        });
    }

    render() {
        return (
            <CreditGiftDataTable data={this.state.creditGiftForms}/>
        )
    }

}