import Enum from "../../../../enums/Enum";

const MedicalReportTypeEnum = new Enum({

    MEDICAL_REPORT: {name: "Medical Report"},
    PROGRESS_REPORT: {name: "Progress Report"},
    PROCEDURE_REPORT: {name: "Procedure Report"},
    ULTRASOUND_REPORT: {name: "Ultrasound Report"},
    RADIOLOGY_REQUEST: {name: "Radiology Request"},
    REFERRAL_LETTER: {name: "Referral Letter"},
    MEDICAL_EXCUSE: {name: "Medical Excuse"},
    DISCHARGE_SUMMARY: {name: "Discharge Summary"}
});

export default MedicalReportTypeEnum;
