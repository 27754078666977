import React, {Component} from "react";
import "../../VisitHistory.css"
import TypeEnum from "../../../assessment/enums/dermatomes/TypeEnum";
import DermatomesCervicalEnum from "../../../assessment/enums/dermatomes/DermatomesCervicalEnum";
import DermatomesDropdownValues from "../../../assessment/enums/dermatomes/DermatomesDropdownValues";
import DermatomesThoracicEnum from "../../../assessment/enums/dermatomes/DermatomesThoracicEnum";
import DermatomesLumbarEnum from "../../../assessment/enums/dermatomes/DermatomesLumbarEnum";
import DermatomesSacralEnum from "../../../assessment/enums/dermatomes/DermatomesSacralEnum";


export default class DermatomesViewHistory extends Component{

    constructor(props) {
        super(props);

        this.createDermatomesTable = this.createDermatomesTable.bind(this);
    }

    createDermatomesTable() {
        let tableEntriesCervical = [];
        let tableEntriesThoracic = [];
        let tableEntriesLumbar = [];
        let tableEntriesSacral = [];
        let headerState = {
            cervicalHeader: false,
            thoracicHeader: false,
            lumbarHeader: false,
            sacralHeader: false
        };
        this.props.dermatomes.items.forEach((item, index) => {
            if (item.type === TypeEnum.CERVICAL) {
                if (!headerState.cervicalHeader) {
                    tableEntriesCervical.push(
                        <tr key={"cervicalHeaderDermatomes" + index} style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                            <th>{TypeEnum[item.type]}</th>
                            <th>Right</th>
                            <th>Left</th>
                            <th>Details</th>
                        </tr>
                    );
                    headerState.cervicalHeader = true;
                }
                tableEntriesCervical.push(
                    <tr key={"cervicalTableDermatomes" + index} style={{backgroundColor: "white"}}>
                        <td>{DermatomesCervicalEnum[item.name]?.value}</td>
                        <td>{DermatomesDropdownValues[item.rightValue]?.name}</td>
                        <td>{DermatomesDropdownValues[item.leftValue]?.name}</td>
                        <td>{item.details}</td>
                    </tr>
                );
            } else if (item.type === TypeEnum.THORACIC) {
                if (!headerState.thoracicHeader) {
                    tableEntriesThoracic.push(
                        <tr key={"thoracicHeaderDermatomes" + index} style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                            <th>{TypeEnum[item.type]}</th>
                            <th>Right</th>
                            <th>Left</th>
                            <th>Details</th>
                        </tr>
                    );
                    headerState.thoracicHeader = true;
                }
                tableEntriesThoracic.push(
                    <tr key={"thoracicTableDermatomes" + index} style={{backgroundColor: "white"}}>
                        <td>{DermatomesThoracicEnum[item.name]?.value}</td>
                        <td>{DermatomesDropdownValues[item.rightValue]?.name}</td>
                        <td>{DermatomesDropdownValues[item.leftValue]?.name}</td>
                        <td>{item.details}</td>
                    </tr>
                );

            } else if (item.type === TypeEnum.LUMBAR) {
                if (!headerState.lumbarHeader) {
                    tableEntriesLumbar.push(
                        <tr key={"lumbarHeaderDermatomes" + index} style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                            <th>{TypeEnum[item.type]}</th>
                            <th>Right</th>
                            <th>Left</th>
                            <th>Details</th>
                        </tr>
                    );
                    headerState.lumbarHeader = true;
                }
                tableEntriesLumbar.push(
                    <tr key={"lumbarTableDermatomes" + index} style={{backgroundColor: "white"}}>
                        <td>{DermatomesLumbarEnum[item.name]?.value}</td>
                        <td>{DermatomesDropdownValues[item.rightValue]?.name}</td>
                        <td>{DermatomesDropdownValues[item.leftValue]?.name}</td>
                        <td>{item.details}</td>
                    </tr>
                );

            } else if (item.type === TypeEnum.SACRAL) {
                if (!headerState.sacralHeader) {
                    tableEntriesSacral.push(
                        <tr key={"sacralHeaderDermatomes" + index} style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                            <th>{TypeEnum[item.type]}</th>
                            <th>Right</th>
                            <th>Left</th>
                            <th>Details</th>
                        </tr>
                    );
                    headerState.sacralHeader = true;
                }
                tableEntriesSacral.push(
                    <tr key={"sacralTableDermatomes" + index} style={{backgroundColor: "white"}}>
                        <td>{DermatomesSacralEnum[item.name]?.value}</td>
                        <td>{DermatomesDropdownValues[item.rightValue]?.name}</td>
                        <td>{DermatomesDropdownValues[item.leftValue]?.name}</td>
                        <td>{item.details}</td>
                    </tr>
                );

            }
        });
        return tableEntriesCervical.concat(tableEntriesThoracic, tableEntriesLumbar, tableEntriesSacral);
    };

    render() {
        if (!this.props.dermatomes || !this.props.dermatomes.items || this.props.dermatomes.items.length === 0) {
            return <div className="pl-3">
                {this.props.dermatomes && this.props.dermatomes.none ? "Normal" : "No information available"}
            </div>;
        }

        return (
            <table className={"visit-history-table"}>
                <tbody>
                    {this.createDermatomesTable()}
                </tbody>
            </table>
        );
    }
}