import React, {Component} from "react";
import StringUtil from "../../../../utils/StringUtil";
import moment from "moment/moment";

export default class AllergyInfo extends Component {

    render() {
        if (this.props.allergy == null) {
            return null;
        }
        if (this.props.allergy.none) {
            return (
                <>
                    <div className={"font-weight-bold mb-1"}>Alerts:</div>
                    <div style={{display: "inline-block", fontWeight: "bold"}}>
                        Allergy: <span style={{paddingLeft: 5, color: "#7BAC16"}}>None Known</span>
                    </div>
                </>
            )
        }

        let allergyDivs = this.props.allergy.items.map((item) =>
            <div className="d-flex">
                <div className="font-weight-bold d-inline-block text-nowrap" style={{minWidth: 110, color: "#B6B6B6"}}>
                    {StringUtil.shorten([item.otherAllergyType || item.allergyType?.name,
                        item.otherAllergen || item.allergen?.name,
                        item.medicationBrand,
                        item.otherAllergenSeverity || item.allergenSeverity?.name,
                        moment(item.discoveredDate).format('YYYY-MM-DD'),
                        item.otherAllergenReaction || item.allergenReaction?.name
                    ].join(', '), 30)}
                </div>
            </div>
        );


        if (allergyDivs.length === 0) {
            return null;
        }

        return (
            <>
                <div className={"font-weight-bold mb-1"}>Alerts:</div>
                <div style={{display: "inline-block", color: "red", fontWeight: "bold"}}>
                    Allergy Alert
                </div>
                {allergyDivs}
            </>

        );
    }
}
