import React, {Component} from 'react';
import IntakeFormTextEnum from "../enum/IntakeFormTextEnum";

class PublicMakesSymptomsWorse extends Component {

    render() {
        const {form, selectedLanguage, checkBoxField, validatedTextField} = this.props;

        return (
            <>
                <div className="row">
                    <label className="col-form-label">
                        {IntakeFormTextEnum.Make_Symptoms_Worse[selectedLanguage]}
                    </label>
                </div>
                <div className="row">
                    <div className="col-12">
                        {checkBoxField(
                            form,
                            "symptomWorseMovement",
                            IntakeFormTextEnum.Movement_Exercise[selectedLanguage]
                        )}
                        {checkBoxField(form, "symptomWorseCold", IntakeFormTextEnum.Cold[selectedLanguage])}
                        {checkBoxField(form, "symptomWorseStanding", IntakeFormTextEnum.Standing[selectedLanguage])}
                        {checkBoxField(form, "symptomWorseSleep", IntakeFormTextEnum.Sleeping[selectedLanguage])}
                        {checkBoxField(form, "symptomWorseDriving", IntakeFormTextEnum.Driving[selectedLanguage])}
                        {checkBoxField(form, "symptomWorseSitting", IntakeFormTextEnum.Sitting[selectedLanguage])}
                        {checkBoxField(
                            form,
                            "symptomWorseClimbingStairs",
                            IntakeFormTextEnum.Climbing_Stairs[selectedLanguage]
                        )}
                        {checkBoxField(form, "symptomWorseOther", IntakeFormTextEnum.Other[selectedLanguage])}
                    </div>
                </div>
                {form.symptomWorseOther && (
                    <div className="row">
                        <div className="container-fluid p-0">
                            <div className="panel bg-grey px-3 py-0 mb-3">
                                <div className="form-group">
                                    <label
                                        className="col-form-label">{IntakeFormTextEnum.Other[selectedLanguage]}</label>
                                    {validatedTextField(form, "symptomsWorseOtherDetail")}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default PublicMakesSymptomsWorse;