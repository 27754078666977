import {GenericChecker} from "../../subjective/GenericChecker";
import * as ArrayUtils from "../../../../../utils/ArrayUtil";

export class DiagnosisChecker extends GenericChecker {
    static isDataEntered(entity) {
        if (entity == null) {
            return false;
        }
        return entity[0] != null && !ArrayUtils.isEmpty(entity[0].diagnosisItems);
    }
}