import React, { Component } from "react";
import './BooleanCheckboxInput.css';

export default class BooleanCheckboxInput extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let item = null;
        if(this.props.value != null && this.props.value) {
            item = (
                <div key={"key"}
                     className={"bordered-checkbox-selected"}
                     style={this.props.style}
                     onClick={()=> {
                         this.props.onChange(false);
                     }}>
                    {this.props.label}
                </div>
            );
        } else {
            item = (
                <div key={"key"}
                     className={"bordered-checkbox-unselected"}
                     style={this.props.style}
                     onClick={()=> {
                         this.props.onChange(true);
                     }}>
                    {this.props.label}
                </div>
            );
        }

        return (<>
                <div className={"disc-checkbox-container"}>
                    {item}
                </div>
            </>);
    }
}
