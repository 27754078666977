import React, {Component} from 'react';
import GreenSwitchWithEmptyMessage from "../../../../components/switch/GreenSwitchWithEmptyMessage";
import TreatmentInfoView from "../treatment/views/TreatmentInfoView";
import RiskOfHarmFromTreatmentEMRDashboardView from "./diagnosis/RiskOfHarmFromTreatmentEMRDashboardView";
import ReferralEMRDashboardView from "./diagnosis/ReferralEMRDashboardView";
import DiagnosisTableEMRDashboardView from "./diagnosis/DiagnosisTableEMRDashboardView";
import GoalsEMRDashboardView from "./diagnosis/GoalsEMRDashboardView";
import RiskEmrDashboardView from "./diagnosis/RiskEMRDashboardView";
import EMRDashboardUtil from "./utils/EMRDashboardUtil";
import ResultsEMRDashboardView from "./diagnosis/ResultsEMRDashboardView";
import MSKDiagnosisEMRDashboardView from "./diagnosis/MSKDiagnosisEMRDashboardView";
import TreatmentInfoEnum from "../treatment/modals/TreatmentInfoEnum";
import DiagnosisUtil from "../diagnosis/DiagnosisUtil";

class EMRDashboardDiagnosis extends Component {
    render() {

        const lastVisit = this.props.visits[this.props.visits.length - 1];

        let diagnosisGroups = [...lastVisit.diagnosis.diagnosisGroups]
        diagnosisGroups[0] = EMRDashboardUtil.getFilledDiagnosisEntity(this.props.visits, diagnosisGroups);

        const clinicalImpression = EMRDashboardUtil.getFilledClinicalImpressionString(this.props.visits, lastVisit.diagnosis.clinicalImpression);
        const risk = EMRDashboardUtil.getFilledRiskEntity(this.props.visits, lastVisit.diagnosis.risk);
        const treatmentReceived = EMRDashboardUtil.getFilledTreatmentReceivedEntity(this.props.visits, lastVisit.treatment.treatmentReceived);
        const goal = EMRDashboardUtil.getFilledGoalsEntity(this.props.visits, lastVisit.treatment.goal);
        const treatmentPlan = EMRDashboardUtil.getFilledTreatmentPlanEntity(this.props.visits, lastVisit.treatment.treatmentPlan);
        const riskOfHarmFromTreatment = EMRDashboardUtil.getFilledRiskOfHarmFromTreatmentEntity(this.props.visits, lastVisit.treatment.riskOfHarmFromTreatment);
        const referral = EMRDashboardUtil.getFilledReferralsEntity(this.props.visits, lastVisit.treatment.referral);
        const results = EMRDashboardUtil.getFilledResultsEntity(this.props.visits, lastVisit.treatment.results);

        return (
            <div className="mt-3">
                <div className={"content-label large"}>Diagnosis</div>
                {this.getDiagnosisView(diagnosisGroups)}
                {this.getClinicalImpression(clinicalImpression)}
                {this.getRiskView(risk)}
                {this.getMSKDiagnosisView(lastVisit.assessment.mskDiagnosis)}
                {this.getTreatmentReceivedView(treatmentReceived)}
                {this.getGoalsView(goal)}
                {this.getTreatmentPlanView(treatmentPlan)}
                {this.getRiskOfHarmFromTreatmentView(riskOfHarmFromTreatment)}
                {this.getReferralView(referral)}
                {this.getResultsView(results)}
            </div>
        );
    }

    getDiagnosisView = (diagnosisGroups) => {
        return <DiagnosisTableEMRDashboardView diagnosisGroups={diagnosisGroups}/>;
    }

    getClinicalImpression = (clinicalImpression) => {
        return (
            <div className={"content-wrapper"}>
                <div className="content-label ml-0">Clinical Impression</div>
                {clinicalImpression || <div className="pl-2">-</div>}
            </div>);
    }

    getRiskView = (risk) => {
        return (
            <div className={"content-wrapper"}>
                <div className={"d-flex"}>
                    <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Risk</div>
                    <GreenSwitchWithEmptyMessage
                        message={(risk && risk.none) ? "No Risk" : ""}
                        checked={DiagnosisUtil.riskEntered(risk)}/>
                </div>
                <div className={"d-flex"}>
                    <p>Patient clinical risk including presence of co-morbidities</p>
                </div>
                <div>
                    <RiskEmrDashboardView risk={risk}/>
                </div>
            </div>);
    }

    getMSKDiagnosisView = (mskDiagnosis) => {
        return (
            <div className={"content-wrapper"}>
                <div style={{display: "flex"}}>
                    <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>MSK Diagnostic Ultra Sound</div>
                    <GreenSwitchWithEmptyMessage
                        emptyMessage={""}
                        checked={mskDiagnosis != null && mskDiagnosis.items != null}/>
                </div>
                <div>
                    <MSKDiagnosisEMRDashboardView MSKDiagnosis={mskDiagnosis}/>
                </div>
            </div>
        );
    }

    getTreatmentReceivedView = (treatmentReceived) => {
        return (
            <div className={"content-wrapper"}>
                <div style={{display: "flex"}}>
                    <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Treatment Received</div>
                    <GreenSwitchWithEmptyMessage
                        emptyMessage={""}
                        checked={this.isTreatmentReceivedChanged(treatmentReceived)}/>
                </div>
                {this.isTreatmentReceivedChanged(treatmentReceived) &&
                <div>
                    <TreatmentInfoView type={TreatmentInfoEnum.TREATMENT_RECEIVED} treatmentInfo={treatmentReceived}/>
                </div>
                }
            </div>
        );
    }

    getTreatmentPlanView = (treatmentPlan) => {
        return (
            <div className={"content-wrapper"}>
                <div style={{display: "flex"}}>
                    <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Treatment Plan</div>
                    <GreenSwitchWithEmptyMessage
                        emptyMessage={""}
                        checked={this.isTreatmentReceivedChanged(treatmentPlan)}/>
                </div>
                {this.isTreatmentReceivedChanged(treatmentPlan) &&
                <div>
                    <TreatmentInfoView type={TreatmentInfoEnum.TREATMENT_PLAN} treatmentInfo={treatmentPlan}/>
                </div>
                }
            </div>
        );
    }

    getGoalsView = (goal) => {
        const hasGoal = goal && goal != null && (
            goal.details || goal.specialRemarks ||
            goal.shortTermItems && goal.shortTermItems.length ||
            goal.longTermItems && goal.longTermItems.length);

        return (
            <div className={"content-wrapper"}>
                <div style={{display: "flex"}}>
                    <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Goals</div>
                    <GreenSwitchWithEmptyMessage
                        emptyMessage={""}
                        checked={hasGoal}/>
                </div>
                <GoalsEMRDashboardView goal={goal}/>
            </div>
        );
    }

    getRiskOfHarmFromTreatmentView = (riskOfHarmFromTreatment) => {
        return (
            <div className={"content-wrapper"}>
                <RiskOfHarmFromTreatmentEMRDashboardView riskOfHarmFromTreatment={riskOfHarmFromTreatment}/>
            </div>
        );
    }

    getReferralView = (referral) => {
        return (
            <div className={"content-wrapper"}>
                <div style={{display: "flex"}}>
                    <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Referrals</div>
                    <GreenSwitchWithEmptyMessage
                        emptyMessage={""}
                        checked={referral != null && (
                            referral.internals.length > 0
                            || referral.externals.length > 0
                            || referral.interventions.length > 0
                        )
                        }
                    />
                </div>
                <div>
                    <ReferralEMRDashboardView referral={referral}/>
                </div>
            </div>
        );
    }

    getResultsView = (results) => {
        return (
            <div className={"content-wrapper"}>
                <div style={{display: "flex"}}>
                    <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Result</div>
                    <GreenSwitchWithEmptyMessage
                        emptyMessage={""}
                        checked={results && results.length !== 0}/>
                </div>
                {results != null
                && results.length > 0 &&
                <div>
                    <ResultsEMRDashboardView results={results}/>
                </div>
                }
            </div>
        );
    }

    isTreatmentReceivedChanged(treatmentInfo) {
        if (treatmentInfo == null) {
            return false;
        }
        return (treatmentInfo.acupuncture && treatmentInfo.acupuncture.length != 0) ||
            (treatmentInfo.breathingPatterns && treatmentInfo.breathingPatterns.length != 0) ||
            (treatmentInfo.cupping && treatmentInfo.cupping.length != 0) ||
            (treatmentInfo.dryNeedling && treatmentInfo.dryNeedling.length != 0) ||
            (treatmentInfo.electrotherapy && treatmentInfo.electrotherapy.length != 0) ||
            (treatmentInfo.gaitTraining && treatmentInfo.gaitTraining.length != 0) ||
            (treatmentInfo.lymphaticDrainage && treatmentInfo.lymphaticDrainage.length != 0) ||
            (treatmentInfo.manipulation && treatmentInfo.manipulation.length != 0) ||
            (treatmentInfo.mobilization && treatmentInfo.mobilization.length != 0) ||
            (treatmentInfo.other && treatmentInfo.other.length != 0) ||
            (treatmentInfo.procedures && treatmentInfo.procedures.length != 0) ||
            (treatmentInfo.shockwave && treatmentInfo.shockwave.length != 0) ||
            (treatmentInfo.stretching && treatmentInfo.stretching.length != 0) ||
            (treatmentInfo.taping && treatmentInfo.taping.length != 0) ||
            (treatmentInfo.therapeuticExercise && treatmentInfo.therapeuticExercise.length != 0) ||
            (treatmentInfo.therapeuticMassage && treatmentInfo.therapeuticMassage.length != 0) ||
            (treatmentInfo.thermalTherapy && treatmentInfo.thermalTherapy.length != 0);
    }
}

export default EMRDashboardDiagnosis;