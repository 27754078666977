import React from 'react';
import {Provider} from 'react-redux';
import {store} from './utils/store';
import {ErrorBoundary} from "react-error-boundary";
// font-awesome setup
import {fab} from "@fortawesome/free-brands-svg-icons";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";
// material-ui/pickers setup
// common layout setup
import Growl from './components/growl/Growl';
import Layout from './layout/Layout';
import LoadingComponent from './components/loading/LoadingComponent';

import {checkForTimeDifferenceFromServer} from './services/time-checker-service/TimeCheckerService';
// root-level css imports
import './App.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import "react-datepicker/dist/react-datepicker.css";
import {Button} from "react-bootstrap";
import DialogContainer from "./components/dialog/DialogContainer";

library.add(fab, fas);

class App extends React.Component {

    componentDidMount() {
        checkForTimeDifferenceFromServer();
    }

    render() {

        return (
            <Provider store={store}>
                <LoadingComponent>
                    <div className="App MeldaApp">
                        <Growl></Growl>
                        <DialogContainer/>
                        <div id="p-calendar-container"/>
                        <ErrorBoundary FallbackComponent={this.ErrorFallback}>
                            <Layout/>
                        </ErrorBoundary>
                    </div>
                </LoadingComponent>
            </Provider>
        );
    };


    ErrorFallback({error}) {
        return (
            <>
            <div className="error-body">
                <div className="container ">
                    <div className="row vh-100 align-items-center">
                        <div className="col-md-8 offset-md-2">
                            <div className="card text-center">

                                <div className="card-body">
                                    <h4 className="display-4">We have encountered an error!</h4>
                                    <div className="alert alert-danger" role="alert">
                                        {error.message}
                                    </div>
                                    <p className="card-text">Please contact the technical support with the above error message.</p>
                                    <Button className="btn btn-success" onClick={() => window.location.reload(false)}>Refresh Page</Button>
                                </div>

                            </div>
                        </div>


                    </div>

                </div>
            </div>

            </>
        );
    }

}

export default App;
