import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Calendar} from "primereact/calendar";
import "./TimeInput.css"

class TimeInput extends Component {

    constructor(props) {
        super(props);
        this.calendarRef = React.createRef();
    }

    handleClear(event) {
        this.calendarRef.current.updateModel(event, null);
        this.calendarRef.current.updateInputfield(null);
        this.calendarRef.current.hideOverlay();
    }

    render() {
        const {clearable, style, inputStyle, ...rest} = this.props;
        return <div className="dim14-timepicker-container">
                    <Calendar ref={this.calendarRef}
                              {...rest}
                              style={{width: 'inherit', ...style}}
                              inputStyle={{width: 'inherit', ...inputStyle}}
                              readOnlyInput={true}
                              timeOnly={true}/>
                    {(clearable && this.props.value) ?
                        <FontAwesomeIcon className="dim14-calendar-clear-icon"
                                         icon={["fas", "times"]}
                                         size={"sm"}
                                         onClick={(event) => {this.handleClear(event)}}/> : null}
                </div>
    }

}

export default TimeInput;