import {GenericChecker} from "../../subjective/GenericChecker";
import * as ArrayUtils from "../../../../../utils/ArrayUtil";

export class TreatmentInfoChecker extends GenericChecker {
    static isDataEntered(entity) {
        if (entity == null) {
            return false;
        }
        return !ArrayUtils.isEmpty(entity.manipulation)
            || !ArrayUtils.isEmpty(entity.mobilization)
            || !ArrayUtils.isEmpty(entity.stretching)
            || !ArrayUtils.isEmpty(entity.dryNeedling)
            || !ArrayUtils.isEmpty(entity.acupuncture)
            || !ArrayUtils.isEmpty(entity.electrotherapy)
            || !ArrayUtils.isEmpty(entity.shockwave)
            || !ArrayUtils.isEmpty(entity.cupping)
            || !ArrayUtils.isEmpty(entity.taping)
            || !ArrayUtils.isEmpty(entity.therapeuticMassage)
            || !ArrayUtils.isEmpty(entity.lymphaticDrainage)
            || !ArrayUtils.isEmpty(entity.thermalTherapy)
            || !ArrayUtils.isEmpty(entity.therapeuticExercise)
            || !ArrayUtils.isEmpty(entity.gaitTraining)
            || !ArrayUtils.isEmpty(entity.breathingPatterns)
            || !ArrayUtils.isEmpty(entity.procedures)
            || !ArrayUtils.isEmpty(entity.other);
    }
}