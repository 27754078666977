import React from 'react'
import "./AppoinmentInfo.css";
import DateUtil from "../../../../utils/DateUtil";
import EnumAppointmentType from "../../../appointment/enums/EnumAppointmentType";

export class AppointmentInfo extends React.Component {

    render() {
        const {appointment} = this.props;
        const hourMinute12H = DateUtil.getTimeStringAtClinicZone(appointment.start, appointment.room.clinicName, "hh:mm A");

        const appointmentType = appointment.visit ? appointment.visit.appointmentType : appointment.patientType;
        const patientType = EnumAppointmentType[appointmentType].name;
        const patientTypeColor = EnumAppointmentType[appointmentType].bgColor;

        return(
            <>
                <div className="text-right">
                    <div style={{fontSize: "16px"}} className="font-weight-bold">{hourMinute12H}</div>
                    <div style={{fontSize: "16px"}} className="font-weight-bold">
                        {appointment.room.clinicName} / {appointment.room.name}
                    </div>
                    <div style={{fontSize: "16px", color: `${patientTypeColor}`, fontWeight: "bold"}}>{patientType}</div>
                </div>
            </>
        );
    }
}