import React, {Component} from "react";
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/high-res.css'

export default class PhoneInput extends Component {

    handleChange(value, country, event, formattedValue) {
        let val = value;
        if (value !== null && value !== undefined && !value.startsWith('+')) {
            val = '+' + value;
        }
        this.props.onChange(val, country);
    }

    render() {
        return (
            <ReactPhoneInput preferredCountries={[ 'ae', 'kw', 'bh', 'om', 'sa', 'qa']}
                             countryCodeEditable={false}
                             country={'ae'}
                             masks={{ae: '.. ... ....'}}
                             preserveOrder={['preferredCountries']} enableSearch
                             {...this.props}
                             value={this.props.value ?? ""}
                             onChange={(value, country, event, formattedValue) =>
                                 this.handleChange(value, country, event, formattedValue)
                             }
            />
        )
    }
}
