import React, {Component} from "react";
import "../../visithistory/VisitHistory.css"
import LevelEnum from "../../diagnosis/enums/LevelEnum";
import ReportViewUtils from "./ReportViewUtils";

export default class DiagnosisReportView extends Component {

    render() {
        const diagnosis = this.props.diagnosis;

        if (ReportViewUtils.isDiagnosisNull(diagnosis)) {
            return null;
        }

        const diagnosisItems = diagnosis.diagnosisGroups[0].diagnosisItems;

        if (ReportViewUtils.isDiagnosisItemsNull(diagnosisItems)) {
            return null;
        }

        const tableRows = [];
        diagnosisItems.forEach((diagnosisItem) => {
            tableRows.push(
                <tr>
                    <td>{LevelEnum[diagnosisItem.level]?.name}</td>
                    <td>{diagnosisItem?.code}</td>
                    <td>{diagnosisItem?.description}</td>
                </tr>
            );
        });

        return (
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Level</th>
                    <th>ICD</th>
                    <th>Description</th>
                </tr>
                {tableRows}
                </tbody>
            </table>
        );
    }
}
