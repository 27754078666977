import React, { Component } from 'react'
import {NavLink as RouterNavLink} from 'react-router-dom';

export default class NavLink extends Component {

    render() {
        let relativeTo = undefined;
        if(this.props.baseUrl){
            if(this.props.to.pathname){
                relativeTo = this.props.to;
                relativeTo.pathname = `${this.props.baseUrl}${relativeTo.pathname}`;
            }
            else{
                relativeTo = `${this.props.baseUrl}${this.props.to}`;
            }
        }
        else{
            relativeTo = this.props.to;
        }
        return <RouterNavLink exact={this.props.exact} className={this.props.className} activeClassName={this.props.activeClassName}
                        to={relativeTo}
                        target={this.props.target} >
            {this.props.children}
        </RouterNavLink>;
    }
}
