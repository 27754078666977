import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Tooltip from "../../../../../components/tooltip/Tooltip";

const emergencyContactItem = (props) => {
    return (
        <div className="row" style={{marginBottom:"15px"}}>
            <div className="col-4" style={{overflow: "hidden", textOverflow: "ellipsis"}}>
                {props.emergencyContact.name}</div>
            <div className="col-4">{props.emergencyContact.mobilePhone}</div>
            <div className="col-3">{props.emergencyContact.relationshipType?.name}</div>
            <div className="col-1">
                <div className={'d-flex justify-content-around'} style={{display: 'flex', justifyContent: 'left'}}>
                    <Tooltip title={`Edit`}>
                        <span>
                            <FontAwesomeIcon icon={["fas", "edit"]}
                                             onClick={() => props.openUpdateModalBody()}/>
                        </span>
                    </Tooltip>
                    <Tooltip title={`Delete`}>
                        <span>
                            <FontAwesomeIcon icon={["fas", "trash"]}
                                             onClick={() => props.onDelete(props.emergencyContact.id)}/>
                        </span>
                    </Tooltip>
                </div>

            </div>
        </div>
    );
};

export default emergencyContactItem;
