import Enum from "../../enums/Enum";

const EnumLeaveStatus = new Enum({
    PENDING: {name: "Waiting For Submission", class:"hr-pending"},
    SUBMITTED: {name: "Waiting For Approval", class:"hr-submitted"},
    APPROVED: {name: "Approved",  class:"hr-approved"},
    REJECTED: {name: "Rejected", class:"hr-rejected"}
});

export default EnumLeaveStatus;
