class MoneyUtil {

    format(amount, currency) {
        return parseFloat(amount).toFixed(this.getFractionDigits(currency)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    formatNumeric(amount, currency) {
        return amount?.toFixed(this.getFractionDigits(currency));
    }

    getCurrency(country) {
        switch (country) {
            case 'UAE':
                return 'AED';
            case 'KUWAIT':
                return 'KWD';
            default:
                return 'Unknown'
        }
    }

    getFractionDigits(currency) {
        switch (currency) {
            case 'AED':
                return 2;
            case 'KWD':
                return 3;
            default:
                return 2
        }
    }

    getFractionDigitsWithCountry(country) {
        const currency = this.getCurrency(country);
        return this.getFractionDigits(currency);
    }
}

export default new MoneyUtil();