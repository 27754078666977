import React, {Component} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DirectBillingObservationTypeEnum from "../../enums/DirectBillingObservationTypeEnum";
import {base64ToBlob, downloadBlob} from "../../../../utils/FileDownloader";

export default class ViewObservationsModal extends Component {

    typeTemplate(observation) {
        return DirectBillingObservationTypeEnum[observation.type]?.name;
    }

    valueTemplate(observation) {
        if (observation.type === "TEXT") {
            return observation.value;
        } else if (observation.type === "FILE") {
            return <span className="cursor-pointer"
                         onClick={() => this.downloadFile(observation)}>
                <u>{observation.filename}</u>
                <FontAwesomeIcon className="ml-1" icon="file-download"/>
            </span>
        }
    }

    downloadFile(observation) {
        downloadBlob(base64ToBlob(observation.value, "application/pdf"));
    }

    valueTypeTemplate(observation) {
        return observation.valueType;
    }

    render() {
        return (
            <div style={{width: 800}}>
                <DataTable emptyMessage={"No items found"}
                           value={this.props.observations}
                           columnResizeMode="fit"
                           dataKey="id">
                    <Column header="Type" style={{width: 100}}
                            body={(rowData) => this.typeTemplate(rowData)}/>
                    <Column header="Code" style={{width: "30%" , wordWrap : "break-word"}} field="code"/>
                    <Column header="Value"
                            body={(rowData) => this.valueTemplate(rowData)}/>
                    <Column header="Value Type" style={{width: 150}}
                            body={(rowData) => this.valueTypeTemplate(rowData)}/>
                </DataTable>
            </div>
        )
    }
}