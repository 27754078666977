import Enum from "../../../../../../enums/Enum";

const ReflexesLeftSideEnum = new Enum({
    RLS_BICEPS: {value: "Biceps - C5, C6"},
    RLS_BRACHIORADIALIS: {value: "Brachioradialis - C5,C6"},
    RLS_TRICEPS: {value: "Triceps - C7,C8"},
    RLS_EXTENSOR_DIGITORUM: {value: "Extensor Digitorum - C6,C7"},
    RLS_OTHER: {value: "Other"},
});

export default ReflexesLeftSideEnum;