import React, {Component} from "react";
import ReactDOM from "react-dom";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import {Calendar, Views} from "react-big-calendar";
import PropTypes from "prop-types";
import "./BigCalendar.css";
import DateUtil from "../../utils/DateUtil";
import clsx from "clsx";

const DragAndDropCalendar = withDragAndDrop(Calendar);

export default class BigCalendar extends Component {

    static propTypes = {
        events: PropTypes.array,
        backgroundEvents: PropTypes.array,
        eventPropGetter: PropTypes.func,
        dragDrop: PropTypes.bool,
        dayLayoutAlgorithm: PropTypes.string,
        drilldownView: PropTypes.string,
        monthViewResource: PropTypes.object,
        ignoreOverlaps: PropTypes.bool,
        toolbarActions: PropTypes.any
    };

    static defaultProps = {
        events: [],
        backgroundEvents: [],
        eventPropGetter: () => {
            return {}
        },
        dragDrop: false,
        dayLayoutAlgorithm: "no-overlap",
        drilldownView: null,
        monthViewResource: null,
        ignoreOverlaps: true,
        toolbarActions: null
    };

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.timeIndicator = ReactDOM.findDOMNode(this).getElementsByClassName('rbc-current-time-indicator')[0];

        if (this.props.view == Views.MONTH
            && this.props.monthViewResource
            && this.props.resourceTitleAccessor
            && !ReactDOM.findDOMNode(this).getElementsByClassName('rbc-month-view-header')[0]) {

            const monthViewElement = ReactDOM.findDOMNode(this).getElementsByClassName('rbc-month-view')[0];
            const monthViewHeader = document.createElement('div');
            monthViewHeader.setAttribute('class', 'rbc-header');
            const monthViewHeaderRow = document.createElement('div');
            monthViewHeaderRow.setAttribute('class', 'rbc-month-view-header rbc-row rbc-row-resource');
            monthViewHeaderRow.append(monthViewHeader);
            monthViewElement.prepend(monthViewHeaderRow);

            this.monthViewHeader = monthViewHeader;
        }

        if (prevProps.toolbarActions !== this.props.toolbarActions) {
            if (this.toolbarActionsDiv) {
                this.toolbarActionsDiv.remove();
            }
            const toolbar = ReactDOM.findDOMNode(this).getElementsByClassName('rbc-toolbar')[0];
            const toolbarActions = document.createElement('div');
            toolbarActions.setAttribute('class', 'rbc-toolbar-actions');
            toolbar.prepend(toolbarActions);
            this.toolbarActionsDiv = toolbarActions;

            this.forceUpdate(); // TODO toolbarActions disappeared after updating react-big-calendar. Fix the problem and remove 'forceUpdate()'
        }
    }

    defaultEventPropGetter(event) {
        const eventProps = this.props.eventPropGetter(event);
        const defaultStyle = {
            background: 'none',
            border: 'none',
            padding: 0,
            paddingBottom: 1,
        }

        eventProps.style = eventProps.style ? {...defaultStyle, ...eventProps.style} : defaultStyle;
        eventProps.className =  clsx(eventProps.className, this.props.ignoreOverlaps ? "ignore-overlaps" : "");

        return eventProps;
    }

    render() {
        const {
            eventPropGetter,
            dragDrop,
            ...props
        } = this.props;
        const BigCalendar = dragDrop ? DragAndDropCalendar : Calendar;

        return (
            <>
                <BigCalendar ref={el => this.bigCalendar = el}
                             {...props}
                             eventPropGetter={event => this.defaultEventPropGetter(event)}
                />
                {this.timeIndicator && ReactDOM.createPortal(<BigCalendarTimeText localizer={this.props.localizer}/>, this.timeIndicator)}
                {this.monthViewHeader && ReactDOM.createPortal(
                    typeof this.props.resourceTitleAccessor === "function" ?
                        this.props.resourceTitleAccessor(this.props.monthViewResource) :
                        <>{this.props.monthViewResource[this.props.resourceTitleAccessor]}</>,
                    this.monthViewHeader)}
                {this.toolbarActionsDiv && ReactDOM.createPortal(this.props.toolbarActions, this.toolbarActionsDiv)}
            </>
        )
    }

}

class BigCalendarTimeText extends Component {

    static TIME_FORMAT = "hh:mm A";

    constructor(props) {
        super(props);
        this.state = {
            time: this.getTime()
        }
    }

    componentDidMount() {
        setInterval(() => this.setState({time: this.getTime()}), 1000);
    }

    getTime() {
        return this.props.localizer ?
            this.props.localizer.format(new Date(), BigCalendarTimeText.TIME_FORMAT) :
            DateUtil.formatDate(new Date(), BigCalendarTimeText.TIME_FORMAT);
    }

    render() {
        return (
            <div className="rbc-current-time-text">{this.state.time}</div>
        )
    }

}