import {GenericChecker} from "../GenericChecker";
import * as ArrayUtils from "../../../../../utils/ArrayUtil";

export class RelievingFactorsChecker extends GenericChecker {
    static isDataEntered(entity) {
        if (entity == null) {
            return false;
        }
        return !ArrayUtils.isEmpty(entity.relievingFactors) || entity.relievingFactorsNormal;
    }
}