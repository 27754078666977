import Enum from "../../../enums/Enum";

const EnumAppointmentStatus = new Enum({
    BOOKED: {name: "Booked", bgColor: "#BADA55", textColor: "#ffffff", cutBgColor: "#DAFA75", order: 4},
    CONFIRMED: {name: "Confirmed", bgColor: "#BADA55", textColor: "#ffffff", cutBgColor: "#DAFA75", order: 4},
    ARRIVED: {name: "Checked In", bgColor: "#CAD2D3", textColor: "#666666", cutBgColor: "#EAF2F3", order: 2},
    BEINGSEEN: {name: "Being Seen", bgColor: "#CAD2D3", textColor: "#666666", cutBgColor: "#EAF2F3", order: 1},
    CONSULTED: {name: "Consulted", bgColor: "#CAD2D3", textColor: "#666666", cutBgColor: "#EAF2F3", order: 3},
    COMPLETED: {name: "Completed", bgColor: "#6A6F73", textColor: "#FFFFFF", cutBgColor: "#8A8F93", order: 5},
    CANCELLED: {name: "Cancelled", bgColor: "#6A6F73", textColor: "#FFFFFF", cutBgColor: "#8A8F93", order: 5},
    NO_SHOW: {name: "No Show", bgColor: "#6A6F73", textColor: "#FFFFFF", cutBgColor: "#8A8F93", order: 5}
});



export default EnumAppointmentStatus;