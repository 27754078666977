import Enum from "./Enum";

const EnumLanguage = new Enum({
    Arabic: {name: "Arabic", code: "AR"},
    English: {name: "English", code: "EN"},
    French: {name: "French", code: "FR"},
    German: {name: "German", code: "DE"},
    Spanish: {name: "Spanish", code: "SP"}
});

export default EnumLanguage;