import Enum from "../../../../../enums/Enum";

const EnumInsuranceReferralType = new Enum({ //todo: refactor, replace with EnumDirectBillingType
    REFERRAL: {name: "Referral"},
    CONSULTATION: {name: "Consultation"},
    PROCEDURE: {name: "Procedure"},
    VALIDITY_EXTENSION: {name: "Validity Extension"},
    ADDITIONAL_SESSIONS: {name: "Additional Sessions"},

    PHYSIOTHERAPIST: {name: "Physiotherapy"},
    CHIROPRACTOR: {name: "Chiropractic"},
    OSTEOPATH: {name: "Osteopathy"},
    KINESIOLOGY: {name: "Kinesiology"},//todo KINESIOLOGY -> KINESIOLOGIST
    SPORTS_FAMILY_MEDICINE :{name:"Sports and Family Medicine Specialist"},
    FAMILY_MEDICINE:{name:"Family Medicine Specialist"},
    SPORTS_THERAPIST:{name:"Sports Therapist"},
    MOVEMENT_THERAPIST:{name:"Movement Therapist"}
});

export default EnumInsuranceReferralType;