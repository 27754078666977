import React, {Component} from 'react';
import {FormSectionHeader} from "../../public/FormSectionHeader";
import IntakeFormTextEnum from "../enum/IntakeFormTextEnum";
import {TextareaInput} from "../../../../components";
import {Dropdown} from "primereact/dropdown";
import DurationTimeEnum from "../../../emr/recordpage/subjective/enums/complaint/DurationTimeEnum";
import {PainSlider, PainValueLabelComponent} from "../../PainSlider";
import PainSliderOptions from "../../PainSliderOptions";

class PublicIntakeFormComplaint extends Component {
    render() {
        const {selectedLanguage, enumLabel, form, onChange, forceFormUpdate, textField} = this.props;

        return (
            <>
                <FormSectionHeader header={IntakeFormTextEnum.Complaint[selectedLanguage]}/>
                <div class="row">
                    <div className="col-12">
                        <label class="col-form-label">
                            {IntakeFormTextEnum.What_Is_Your_Main_Complaint[selectedLanguage]}
                        </label>
                        <TextareaInput
                            className="form-control"
                            value={form.describeMainProblem}
                            onChange={(value) =>
                                onChange("describeMainProblem", value)
                            }
                        />
                    </div>
                </div>
                <div class="row">
                    <form className="form-inline col mt-4">
                        <label
                            className="col-form-label mr-2">{IntakeFormTextEnum.When_Did_This_Start[selectedLanguage]}</label>
                        {textField(form, "whenDidItStart")}
                        <label
                            className=" col-form-label mx-2">{IntakeFormTextEnum.Duration[selectedLanguage]}</label>
                        <Dropdown
                            className="DropdownBorderColor WhenDidItStartDropdown"
                            value={form.whenDidItStartOption}
                            options={DurationTimeEnum.toArray().map(v => {
                                return {value: v.key, label: v[enumLabel]}
                            })}
                            optionLabel={"label"}
                            onChange={(event) =>
                                onChange("whenDidItStartOption", event.value)
                            }
                        />
                    </form>
                </div>
                <div className="form-row mt-4 mb-4">
                    <div class="col">
                        <label class="col-form-label">
                            {IntakeFormTextEnum.Please_Rate_Your_Pain[selectedLanguage]}
                        </label>
                    </div>
                    <div class="col-7">
                        <PainSlider
                            ValueLabelComponent={PainValueLabelComponent}
                            aria-label="custom thumb label"
                            min={0}
                            max={10}
                            step={null}
                            marks={PainSliderOptions.painMarks(enumLabel)}
                            valueLabelDisplay="auto"
                            value={form.painAmount}
                            enumLabel={enumLabel}
                            onChange={(e, value) => {
                                form.painAmount = PainSliderOptions.getPainInfo(value);
                                forceFormUpdate();
                            }}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default PublicIntakeFormComplaint;