import React, {Component} from 'react'
import "./InsuranceAppointment.css"
import {DateNavigation} from "../../../../components/datenavigation/DateNavigation";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import PageTitle from "../../../../layout/PageTitle";
import DateUtil from "../../../../utils/DateUtil";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import YesNoNotAvailableEnum from "../../../genericenums/YesNoNotAvailableEnum";
import EligibilityStatusEnum from "../../enums/EligibilityStatusEnum";
import moment from "moment";
import {Button, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal, TextareaInput} from "../../../../components";
import {Dropdown as PrimeDropdown} from "primereact/components/dropdown/Dropdown";
import {noop, pick} from "lodash-es";
import InsuranceDocumentLegend from "../InsuranceDocumentLegend";
import EnumInsuranceReferralType from "../referral/enums/EnumInsuranceReferralType";
import EnumDirectBillingType from "../direct/EnumDirectBillingType";

export default class InsuranceAppointment extends Component {
    constructor(props) {
        super(props);

        this.state = {
            items: [],
            selectedItem: null,
            errorMessages: [],
            selectedDate:null
        };
    }

    componentDidMount() {
        if (this.props.selected) {
            this.retrieveTodaysAppointments();
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (!prevProps.selected && this.props.selected) {
            this.retrieveTodaysAppointments(this.state.selectedDate == null ? undefined : this.state.selectedDate);
        }
    }

    handleDateStateChange = (date) => {
        this.retrieveTodaysAppointments(date);
        this.setState({selectedDate: date});
    }

    retrieveTodaysAppointments(date = DateUtil.today()) {
        RemotingService.getRemoteCall('api/insurance/coordinator/insurance-approval',
            {
                date: moment(date).format('YYYY-MM-DD'),
            },
            (result) => {
                this.setState({items: result});
            });
    }

    renderNotesModal = () => {
        return (
            <>
                <Modal visible={this.state.notesModalVisible}
                       header="Notes"
                       submitContent="Save"
                       submitAction={() => this.updateItemNotes()}
                       closeAction={() => this.setState({notesModalVisible: false})}
                       submitDisabled={this.state.errorMessages.length > 0}
                       contentStyle={{minWidth: 400}}>

                    <Container className={"InputModal"}>
                        <Row>
                            <Col xs={12}>
                                <TextareaInput style={{width: "100%", maxHeight: "100px"}}
                                               value={this.state.notes}
                                               onChange={(value) => this.setState({notes: value})}/>
                            </Col>

                        </Row>
                    </Container>
                </Modal>
            </>
        )
    }

    openNotesModal = (item) => {
        this.setState({
            selectedItem: item,
            notes: item.notes,
            notesModalVisible: true
        });
    }

    updateItemNotes = () => {
        const {selectedItem: item, notes} = this.state;
        this.updateItem(item, 'notes', notes, () => {
            this.setState({notesModalVisible: false});
        });
    }

    notesTemplate = (item) => {
        return <Button variant={"link"} style={{color: "#84BE56", fontSize: "12px"}}
                       onClick={() => this.openNotesModal(item)}>
            <FontAwesomeIcon icon={["fas", "edit"]} size={"sm"}/> Add Note
        </Button>;
    }

    dateTimeTemplate(item) {
        return item.visitTime ? DateUtil.formatDate(item.visitTime, "Do MMMM YYYY / hh:mm A") : "";
    }

    dateTemplate(item) {
        return item.insuranceRecord.validUntil ? DateUtil.formatDateMMM(item.insuranceRecord.validUntil) : "";
    }

    sessionsTemplate(item) {
        return `${item.insuranceRecord.noOfUsedSessions}/${item.insuranceRecord.noOfSessions}`;
    }

    updateItem = (item, updatedField, newValue, callback = noop) => {
        const updateDto = pick(item, ['eligibilityStatus', 'patientInformed', 'notes']);

        updateDto[updatedField] = newValue;

        RemotingService.postRemoteCall(
            `api/insurance/coordinator/insurance-approval/${item.id}`,
            updateDto,
            () => {
                item[updatedField] = newValue && Array.isArray(newValue) ? [...newValue] : newValue;
                callback();
                this.forceUpdate();
            }, null, true, false);
    }

    specialityTemplate(item) {
        return item.insuranceRecord.speciality ? EnumDirectBillingType[item.insuranceRecord.speciality].name :
            item.insuranceRecord.referralType ? EnumInsuranceReferralType[item.insuranceRecord.referralType].name : '';
    }

    listInputTemplate = (item, field, options) => {
        return (
            <PrimeDropdown optionLabel="name" optionValue="key"
                           options={options} filter={true}
                           value={item[field]}
                           appendTo={"body", document.body}
                           onChange={event => this.updateItem(item, field, event.value)}
                           style={{width: '100%'}}
            />
        )
    }

    render() {
        return (
            <>
                <div style={{padding: '10px'}}
                     className="data-table-header-wrapper"> {/*Remove this class and date navigation won't work.}*/}
                    <div className="float-left">
                        <PageTitle title="Appointments" className="bg-white"/>
                    </div>
                    <div className="float-right">
                        <DateNavigation onDateChange={this.handleDateStateChange} displayDate={true}/>
                    </div>
                </div>
                <div style={{padding: '10px'}}>
                    <DataTable className="InsuranceTable" responsive
                               emptyMessage={"No items found"}
                               value={this.state.items}
                               rows={20}
                               scrollable
                               paginator={true}
                               paginatorPosition="bottom">
                        <Column header="Visit Date/Time"  field="visitTime" sortable body={(item) => DateUtil.formatDate(item.visitTime, "Do MMMM YYYY / hh:mm A")}/>
                        <Column header="Provider" field="provider" filter={true} filterMatchMode="contains" sortable/>
                        <Column header="Type" body={this.specialityTemplate}/>
                        <Column header="Sessions" body={this.sessionsTemplate}/>
                        <Column header="Patient" field="insuranceRecord.patientName" filter={true} filterMatchMode="contains"
                                sortable/>
                        <Column header="MRN" field="insuranceRecord.patientMrn" filter={true} filterMatchMode="contains" sortable/>
                        <Column header="Insurance" field="insuranceRecord.patientInsurance" filter={true} filterMatchMode="contains" sortable/>
                        <Column header="Ref No." field="insuranceRecord.referenceNo" filter={true} filterMatchMode="contains" sortable/>
                        <Column header="Referred By" field="insuranceRecord.referral.referrerName"/>
                        <Column header="Valid Until" field="insuranceRecord.validUntil" sortable body={(item) => DateUtil.formatDateMMM(item.insuranceRecord.validUntil)}/>
                        <Column header="Status" field="eligibilityStatus"
                                body={(item) => this.listInputTemplate(item, 'eligibilityStatus', EligibilityStatusEnum.toArray())}/>
                        <Column header="Patient Informed" field="patientInformed"
                                body={(item) => this.listInputTemplate(item, 'patientInformed', YesNoNotAvailableEnum.toArray())}/>
                        <Column header="Note" body={this.notesTemplate}/>
                    </DataTable>
                    <InsuranceDocumentLegend/>
                </div>
                {this.renderNotesModal()}
            </>
        )
    }
}
