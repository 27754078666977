import React, {Component} from "react";
import DermatomesScreenUtil from "../../assessment/utils/DermatomesScreenUtil";
import TypeEnum from "../../assessment/enums/dermatomes/TypeEnum";
import ReflexesLeftSideEnum from "../../assessment/enums/dermatomes/ReflexesLeftSideEnum";
import ReflexesDropdownValues from "../../assessment/enums/dermatomes/ReflexesDropdownValues";
import DermatomesScreenRowColorsEnum from "../../assessment/enums/dermatomes/DermatomesScreenRowColorsEnum";
import ReflexesRightSideEnum from "../../assessment/enums/dermatomes/ReflexesRightSideEnum";
import DermatomesUtil from "../../assessment/utils/DermatomesUtil";
import CranialNervesEnum from "../../assessment/enums/dermatomes/CranialNervesEnum";
import CranialNervesDropdownValues from "../../assessment/enums/dermatomes/CranialNervesDropdownValues";

export default class ReflexesEMRDashboardView extends Component {


    constructor(props) {
        super(props);
        this.state = {
            reflexes: this.splitReflexes(props)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.reflexes !== this.props.reflexes) {
            const reflexes = this.splitReflexes(this.props);
            this.setState({reflexes});
        }
    }

    splitReflexes = (props) => {
        let reflexes = {};

        if (!props.reflexes || !props.reflexes.items) {
            return {...props.reflexes};
        }

        props.reflexes.items.forEach(item => {
            if (item.type === TypeEnum.REFLEX_LEFT) {
                if (reflexes.reflexLeftSide == null)
                    reflexes.reflexLeftSide = []
                reflexes.reflexLeftSide.push(item)
            } else if (item.type === TypeEnum.REFLEX_RIGHT) {
                if (reflexes.reflexRightSide == null)
                    reflexes.reflexRightSide = []
                reflexes.reflexRightSide.push(item)
            } else if (item.type === TypeEnum.CRANIAL_NERVES) {
                if (reflexes.cranialNerves == null)
                    reflexes.cranialNerves = []
                reflexes.cranialNerves.push(item)
            }
        })

        return {...reflexes};
    }

    render() {
        return (
            <div>
                <div className={"content-row"} style={{display: "block"}}>
                    {DermatomesScreenUtil.DermatomesViewTableHeader(
                        "Reflexes",
                        DermatomesUtil.isReflexesEntered(this.state.reflexes),
                        {marginTop: "10px"},
                        this.state.reflexes.none ? "Normal" : null
                    )}
                    <div className={"row"} style={{margin: "-5px"}}>
                        <div className={"col-6"}>
                            {this.state.reflexes && (this.state.reflexes.reflexLeftSide || this.state.reflexes.cranialNerves) &&
                            <table className={"emr-table content-card"}>
                                {DermatomesScreenUtil.DermatomesViewTable(this.state.reflexes.reflexLeftSide, "Reflex", ReflexesLeftSideEnum, ReflexesDropdownValues, DermatomesScreenRowColorsEnum.REFLEXLEFTSIDE.color)}
                                {DermatomesScreenUtil.DermatomesViewTable(this.state.reflexes.cranialNerves, "Cranial Nerves", CranialNervesEnum, CranialNervesDropdownValues, DermatomesScreenRowColorsEnum.CARNIALNERVES.color, true, "Result")}
                            </table>}
                        </div>

                        <div className={"col-6"}>
                            {this.state.reflexes && (this.state.reflexes.reflexRightSide || (this.props.reflexesExtra && this.props.reflexesExtra.items)) &&
                            <table className={"emr-table content-card"}>
                                {DermatomesScreenUtil.DermatomesViewTable(this.state.reflexes.reflexRightSide, "Reflex", ReflexesRightSideEnum, ReflexesDropdownValues, DermatomesScreenRowColorsEnum.REFLEXRIGHTSIDE.color)}
                                {DermatomesScreenUtil.DermatomesViewReflexExtraTable(this.props.reflexesExtra, DermatomesScreenRowColorsEnum.REFLEXRIGHTSIDE.color,
                                    this.state.reflexes.reflexRightSide == null || this.state.reflexes.reflexRightSide.length === 0, ReflexesDropdownValues)}
                            </table>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
