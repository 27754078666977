import React from 'react'

const Logo = () => {
    return (
        <div>
            <img width={125} height={35} src={process.env.PUBLIC_URL + '/assets/disc.png'} alt=""/>
        </div>
    )
};

export default Logo;
