import Enum from "../../../../../../enums/Enum";

const ReflexesRightSideEnum = new Enum({
    RRS_PATELLAR: {value: "Patellar - L3, L4"},
    RRS_TIBIALIS_POSTERIOR_TENDON: {value: "Tibialis Posterior Tendon - L4,L5"},
    RRS_ACHILLES: {value: "Achilles - S1, S2"},
    RRS_PLANTAR: {value: "Plantar - S1"},
    RRS_OTHER: {value: "Other"},
});

export default ReflexesRightSideEnum;