import Enum from "../../../../../../enums/Enum";

const InterventionTypeEnum = new Enum({
    RADIOLOGY: {name: "Radiology"},
    LABORATORY: {name: "Laboratory"},
    HOSPITALIZATION: {name: "Hospitalization"},
    SURGERY: {name: "Surgery"},
    DIAGNOSTICS: {name: "Diagnostics"},
    EXAMINATIONS: {name: "Examinations"},
    GENETICS: {name: "Genetics"},
    VACCINATION: {name: "Vaccination"},
    NUTRITION: {name: "Nutrition"},
    MEDICAL_DEVICES: {name: "Medical Devices"}
});

export default InterventionTypeEnum;