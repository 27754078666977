import React, {Component} from "react";
import "../../VisitHistory.css";
import PsychoSocialHistoryEnum from "../../../subjective/enums/lifestyle/PsychoSocialHistoryEnum";

export default class PsychoSocialViewHistory extends Component {
    render() {
        if (this.props.lifestyle == null || this.checkPsychoSocialHistoryDataAvailability(this.props.lifestyle)) {
            return <div className="pl-3">No information available</div>;
        }

        let psychoSocialHistory = this.getPsychoSocialHistoryItems();

        return (
            <div className={"visit-history-multiple-content-wrapper pl-3"}>
                <div className={"display-flex"} style={{marginRight: "20px"}}>
                    <div className={"col-sm-12 no-padding overflow-wrap-anywhere"}>
                        {psychoSocialHistory}
                    </div>
                </div>
            </div>
        )

    }

    getPsychoSocialHistoryItems () {

        let text = "";
        if (this.props.lifestyle.psychoSocialHistory.length !== 0) {

            let psychoSocialHistoryItems = [];
            this.props.lifestyle.psychoSocialHistory.forEach((item) => {
                psychoSocialHistoryItems.push(PsychoSocialHistoryEnum[item].name);
            });

            text += psychoSocialHistoryItems.join(", ");
            text += (this.props.lifestyle.psychoSocialHistoryDetail == null || this.props.lifestyle.psychoSocialHistoryDetail === "")
                    ? "" : ("; " + this.props.lifestyle.psychoSocialHistoryDetail);
        } else {
            text += (this.props.lifestyle.psychoSocialHistoryDetail == null || this.props.lifestyle.psychoSocialHistoryDetail === "")
                ? "" : this.props.lifestyle.psychoSocialHistoryDetail;
        }

        if (text !== "") {
            return text;
        }

        return "No information available";

    }

    checkPsychoSocialHistoryDataAvailability (historyData) {
        return (historyData.psychoSocialHistory == null || historyData.psychoSocialHistory.length === 0)
            && (historyData.psychoSocialHistoryDetail == null || historyData.psychoSocialHistoryDetail === " ")
    }
}