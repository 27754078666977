import React from 'react';
import EnumReferenceType from "../../enums/EnumReferenceType";
import ActionMenu from "../../../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../../../components/action-menu/ActionMenuItem";
import ViewIcon from "../../../../components/action-menu/icons/ViewIcon";
import EditIcon from "../../../../components/action-menu/icons/EditIcon";
import DeleteIcon from "../../../../components/action-menu/icons/DeleteIcon";

class Reference extends React.Component {

    state = {
        reference: this.props.reference || {},
    };

    getRefName = () => {
        const {reference} = this.props;
        return reference.referenceType === 'DRHOSPITAL' ? [reference.hospital, reference.name].filter(Boolean).join("/") : reference.name;
    };

    handleReferenceUpdate = (reference) => {
        this.setState({reference});
    };

    render() {
        const {reference} = this.props;
        return (
            <div className="row" style={{...this.props.style}}>
                <div className="col-4">{reference.referenceType ? EnumReferenceType[reference.referenceType] : ''}</div>
                <div className="col-5" style={{overflow: "hidden", textOverflow: "ellipsis"}}>{this.getRefName()}</div>
                <div className="col-2 text-center">
                    <ActionMenu id={"referenceactionmenu"}>
                        <ActionMenuItem text="View Reference" icon={<ViewIcon/>}
                                        onClick={() => this.props.onClickViewReference(reference)}/>
                        {this.props.editable &&
                        <ActionMenuItem text="Edit Reference" icon={<EditIcon/>}
                                         onClick={() => this.props.onClickEditReference(reference)}/>
                        }
                        {this.props.editable &&
                        <ActionMenuItem text="Delete Reference" icon={<DeleteIcon/>}
                                        onClick={() => this.props.handleDelete(reference.id)}
                                        isLastItem={true}/>
                        }
                    </ActionMenu>
                </div>
            </div>
        )
    };

}


export default Reference;

