import Enum from "../../../../../../enums/Enum";

const DermatomesThoracicEnum = new Enum({
    D_T1: {value: "T1 - Medial side of forearm to base of little finger"},
    D_T2: {value: "T2 - Medial side of upper arm to medial elbow, pectoral and midscapular areas"},
    D_T3_T6: {value: "T3–T6, upper thorax;"},
    D_T5_T7: {value: "T5–T7, costal margin;"},
    D_T8_T12: {value: "T8–T12, abdomen and lumbar region"},
});

export default DermatomesThoracicEnum;