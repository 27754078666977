import DateUtil from "../../utils/DateUtil";
import moment from "moment";
import AuthService from "../../services/auth-service/AuthService";

const getSessions = (sponsorship) => {
    return sponsorship.numberOfSessions == -1 ? 'Unlimited' : sponsorship.numberOfSessions;
}

const getValidityDate = (sponsorship) => {
    return sponsorship.period == -1 ? 'Unlimited' :
        DateUtil.formatDateMMM(moment(sponsorship.startDate).add(sponsorship.period, 'day'));
}

const getCapabilities = (sponsorship) => {
    const loginUser = AuthService.getUser();
    const isManager = AuthService.isManagerOrSuperUser();
    const isProvider = AuthService.isProvider();
    let editable = true;

    if (sponsorship) {
        editable = isManager || isProvider || loginUser.staffId == sponsorship.sponsoredBy; // TODO isManager of staff that created this
    }

    return {editable, isManager};
}

export default {getSessions, getValidityDate, getCapabilities}