import Enum from "../../enums/Enum";

const EnumLeaveErrorCode = new Enum({
    QUOTA_EXCEEDED: {name: "Quota exceed for this leave type."},
    ANNUAL_LEAVE_NOTIFICATION_PERIOD_MISSED: {name: "Annual leave notification period missed."},
    HAJJ_LEAVE_NOT_ACCRUED: {name: "Hajj leave not accrued yet."},
    MIN_PHYSIOTHERAPIST_COUNT_NOT_MATCHED: {name: "Min physiotherapist count is not matched."},
    MIN_MALE_PHYSIOTHERAPIST_COUNT_NOT_MATCHED: {name: "Min male physiotherapist count is not matched."},
    MIN_FEMALE_PHYSIOTHERAPIST_COUNT_NOT_MATCHED: {name: "Min female physiotherapist count is not matched."},
    MAX_NURSE_ON_LEAVE_NOT_MATCHED: {name: "Max nurse on leave count is not matched."},
    MIN_DOCTOR_COUNT_NOT_MATCHED: {name: "Min doctor count is not matched."},
    MIN_SPORTS_MEDICINE_DOCTOR_COUNT_NOT_MATCHED: {name: "Min sports medicine doctor count is not matched."},
    MIN_CHIROPRACTOR_COUNT_NOT_MATCHED: {name: "Min chiropractor count is not matched."},
    MIN_OSTEOPATH_COUNT_NOT_MATCHED: {name: "Min osteopath count is not matched."},
});

export default EnumLeaveErrorCode;
