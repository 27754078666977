import React, {Component} from "react";
import "../VisitHistory.css"
import {default as Title} from "../GrayPageTitle";
import {MultipleHeaderAccordion} from "../../../../../components/accordion/MultipleHeaderAccordion";
import StringUtil from "../../../../../utils/StringUtil";
import PropTypes from "prop-types";

const Column = props => {
    return (
        <div className={"col"}>
            {props.children}
        </div>
    );
};

const Section = props => {
    return (
        <div className="row">
            <Column>
                <Title title={props.header1}/>
                <div className="pl-3">
                {
                    React.isValidElement(props.prop1) ? props.prop1 : (
                        StringUtil.isNullOrEmpty(props.visit[props.prop1])
                            ? "No information available"
                            : props.visit[props.prop1]
                    )
                }
                </div>
            </Column>

            {props.header2 &&
            <Column>
                <Title title={props.header2}/>
                <div className="pl-3">
                {
                    React.isValidElement(props.prop2) ? props.prop2 : (
                        StringUtil.isNullOrEmpty(props.visit[props.prop2])
                            ? "No information available"
                            : props.visit[props.prop2]
                    )
                }
                </div>
            </Column>}

            {props.header3 &&
            <Column>
                <Title title={props.header3}/>
                <div className="pl-3">
                {
                    React.isValidElement(props.prop3) ? props.prop3 : (
                        StringUtil.isNullOrEmpty(props.visit[props.prop3])
                            ? "No information available"
                            : props.visit[props.prop3]
                    )
                }
                </div>
            </Column>}
        </div>
    );
};

export default class LegacyVisitHistory extends Component {

    render() {
        const visit = {...this.props.legacyVisit};

        return (
            <div className={"visit-history-body"}>
                <MultipleHeaderAccordion
                    headerText={{firstHeaderText: "Allergies", secondHeaderText: "Vital Signs"}}
                    headerExist={{secondHeader: true, thirdHeader: false}}
                    secondHeaderAlignment={"left"}
                    flexBasis={"50%"}
                    expanded={true}
                    hideIcon>

                    <div className={"col-6"}>
                        {visit.allergies || "No information available"}
                    </div>
                    <div className={"col-6 pl-3"}>
                        {visit.vitalSigns ?
                            visit.vitalSigns.split('\n').map(sign => <li>{sign}</li>)
                            : "No information available"}
                    </div>
                </MultipleHeaderAccordion>

                <MultipleHeaderAccordion
                    headerText={{firstHeaderText: "Diagnosis", secondHeaderText: "Billing"}}
                    headerExist={{secondHeader: true, thirdHeader: false}}
                    secondHeaderAlignment={"left"}
                    flexBasis={"50%"}
                    expanded={true}
                    hideIcon>

                    <div className={"col-6"}>
                        {visit.diagnosis || "No information available"}
                    </div>
                    <div className={"col-6 pl-3"}>
                        {visit.billing || "No information available"}
                    </div>
                </MultipleHeaderAccordion>

                <MultipleHeaderAccordion
                    headerText={{firstHeaderText: "Subjective"}}
                    headerExist={{secondHeader: false, thirdHeader: false}}
                    defaultExpanded={false}>

                    <div className="col-12">
                        <Section visit={visit} header1={"Complaint"} header2={"Symptoms"} header3={" "}
                                 prop1={
                                     <>
                                         {visit.complaint}
                                         <Field label="Other complaint:">{visit.otherComplaint}</Field>
                                     </>
                                 }
                                 prop2={
                                     <>
                                         <Field label="Pain:">{visit.pain}</Field>
                                         <Field label="Onset:">{visit.onset}</Field>
                                         <Field label="Duration:">{visit.duration}</Field>
                                         <Field label="Character:">{visit.character}</Field>
                                     </>
                                 }
                                 prop3={
                                     <>
                                         <Field label="Aggravating Factors:">{visit.aggravativeFactors}</Field>
                                         <Field label="Relieving Factors:">{visit.relievingFactors}</Field>
                                         <Field label="Previous Episode:">{visit.previousEpisodes}</Field>
                                         <Field label="History of trauma:">{visit.historyOfTrauma}</Field>
                                         <Field label="Previous Treatment Received:">{visit.previousTreatmentReceived}</Field>
                                     </>
                                 }/>

                        <Section visit={visit} header1={"Labs/Imaging"} header2={"Review of Systems"}
                              prop1={"labImaging"} prop2={"systemReview"}/>

                        <Section visit={visit} header1={"Medical History"} header2={"Family History"}
                              prop1={"medicalHistory"} prop2={"familyHistory"}/>

                        <Section visit={visit} header1={"Lifestyle"}
                              prop1={"diet"}/>

                        <Section visit={visit} header1={"Medications"}
                              prop1={"medication"}/>

                        <Section visit={visit} header1={"Prescriptions"}
                                 prop1={"prescriptions"}/>
                    </div>
                </MultipleHeaderAccordion>

                <MultipleHeaderAccordion
                    headerText={{firstHeaderText: "Assessment"}}
                    headerExist={{secondHeader: false, thirdHeader: false}}
                    defaultExpanded={false}>

                    <div className="col-12">
                        <Section visit={visit} header1={"Observation"} header2={"Palpation"}
                                 prop1={"observation"} prop2={"palpation"}/>

                        <Section visit={visit} header1={"Fall Risk Assessment"}
                                 prop1={"fallRiskAssessment"}/>

                        <Section visit={visit} header1={"Range of Motion"}
                                 prop1={"rangeOfMotion"}/>

                        <Section visit={visit} header1={"Joint Integrity"}
                                 prop1={"jointIntegrity"}/>

                        <Section visit={visit} header1={"Special Tests"}
                                 prop1={"specialTests"}/>

                        <Section visit={visit} header1={"Dermatomes"} header2={"Myotomes"}
                                 prop1={"dermatomes"} prop2={"myotomes"}/>

                        <Section visit={visit} header1={"Reflexes"}
                                 prop1={"reflexes"}/>

                        <Section visit={visit} header1={"Risk"}
                                 prop1={"risk"}/>
                    </div>
                </MultipleHeaderAccordion>

                <MultipleHeaderAccordion
                    headerText={{firstHeaderText: "Treatment"}}
                    headerExist={{secondHeader: false, thirdHeader: false}}
                    defaultExpanded={false}>

                    <div className="col-12">
                        <Section visit={visit} header1={"Treatment Received"} header2={"Response To Treatment"}
                                 prop1={"treatmentReceived"}
                                 prop2={
                                    <Column>
                                        <li>{`Overall Improve By: ${visit.overallImprove}`}</li>
                                        <li>{`ROM Improve By: ${visit.romImprovedBy}`}</li>
                                        <li>{`Pain Score: ${visit.painScore}`}</li>
                                    </Column>
                                 }
                        />

                        <Section visit={visit} header1={"Patient Education"} header2={"Fall Risk Management"}
                                 prop1={"patientEducation"} prop2={"fallRiskManagement"}/>
                    </div>
                </MultipleHeaderAccordion>
                <MultipleHeaderAccordion
                    headerText={{firstHeaderText: "Treatment Plan"}}
                    headerExist={{secondHeader: false, thirdHeader: false}}
                    defaultExpanded={false}>

                    <div className="col-12">
                        <Section visit={visit} header1={"Treatment Goals"} header2={"Treatment Plan"}
                                 prop1={
                                     <>
                                         <li>{visit.goal}</li>
                                         <li>{visit.specialRemarks}</li>
                                     </>}
                                 prop2="treatmentPlan"/>

                        <Section visit={visit} header1={"Treatment Schedule"} header2={"Risk of Harm"}
                                 prop1={
                                     <>
                                         <li>{visit.treatmentSchedule}</li>
                                         {visit.nextAppointment && <li>{visit.nextAppointment}</li>}
                                         {visit.nextAppointment && <li>{visit.reassessment}</li>}
                                     </>
                                 }
                                 prop2={"riskOfHarm"}/>

                        <Section visit={visit} header1={"Referrals"}
                                 prop1={
                                     <>
                                         <div>{visit.referral}</div>
                                         <div>{visit.continumOfCareReferral}</div>
                                     </>
                                 }/>
                    </div>
                </MultipleHeaderAccordion>
            </div>
        );
    }
}


class Field extends Component {

    static propTypes = {
        label: PropTypes.string,
        showIfNotEmpty: PropTypes.bool
    }

    static defaultProps = {
        label: null,
        showIfNotEmpty: true
    }

    render() {
        const {label, showIfNotEmpty, children} = this.props;
        return (
            (!showIfNotEmpty || (showIfNotEmpty && children)) ?
            <div>
                <span className="font-weight-bold mr-1">{label}</span>
                {children}
            </div> : null
        )
    }

}
