import React, {Component} from 'react'
import Logo from "./Logo";
import moment from "moment";
import confirmDialog from "../../../components/dialog/ConfirmDialog";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import RemotingService from "../../../services/remoting-service/RemotingService";
import {TextInput, Wizard} from "../../../components";
import FormField from "../../../components/form/FormField";
import SignatureInput from "../../../components/signature-input/SignatureInput";
import ConsentFormsUtil from "../utils/ConsentFormsUtil";

export default class PublicPerinealPhysiotherapyConsentForm extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    createHeader = (form) => {
        return <div style={{padding: "0px 20px 0px 20px"}}>
            <div className="row text-left">
                <div className="col-12 no-padding">
                    <Logo/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center no-padding" style={{fontSize: 20, fontWeight: "bold"}}>
                    PERINEAL PHYSIOTHERAPY CONSENT FORM
                </div>
            </div>
            <div className="row" style={{fontSize: 11}}>
                <div className="col-4 text-left no-padding">
                    <b>Patient Name: </b> {form.patientFullName}
                </div>
                <div className="col-4 text-center no-padding">
                    <b>MRN No: </b> {form.patientMrn}
                </div>

                <div className="col-4 text-right no-padding">
                    <b>D.O.B: </b> {form.patientDOB}
                </div>
            </div>
        </div>
    };

    fieldChanged = (fieldName, value) => {
        this.props.form[fieldName] = value;
        this.forceUpdate();
    };

    close = () => {
        confirmDialog(
            'Confirmation',
            'Are you sure you want to close this form? All input will be lost.',
            this.props.close);
    };

    submitForm = () => {
        if (ValidationUtil.checkWithNotification(this.formFields)) {
            const {consentFormCode: code, form} = this.props;
            RemotingService.postRemoteCall(`api/public/consent-form/code/${code}/perineal-physiotherapy-consent-form`, form);
            this.props.onFormSubmit(form);
        }
    };

    render() {
        this.formFields = [];
        const {form} = this.props;
        return (
            <Wizard
                show={true} fullScreen
                footerStyle={{backgroundColor: '#f5f5f5'}}
                buttonStyle={{backgroundColor: '#84BE56', border: 'none'}}
                header={this.createHeader(form)}
                close={this.close}
                onSubmit={this.submitForm}>

                <Wizard.Page>
                    <div style={{margin: "20px 20px 20px 20px"}}>

                        <div style={{backgroundColor: "white", marginBottom: "20px", padding: "20px"}}>
                            <div style={{margin: "0px 15px"}}>
                                <p><b> Perineal Physiotherapy </b></p>
                            </div>
                            <div style={{margin: "0px 15px"}}>
                                <p> is a therapeutic discipline that allows a trained healthcare professional to evaluate
                                    and treat pelvic floor dysfunctions (urinary and/or anal incontinence,
                                    pelvic pain, pelvic organ prolapses, etc.), sexual conditions (dyspareunia, vaginismus, etc.)
                                    and especially during pregnancy, postpartum and menopause.</p>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>

                    <div style={{margin: "20px 20px 20px 20px"}}>

                        <div style={{backgroundColor: "white", marginBottom: "20px", padding: "20px"}}>
                            <div style={{margin: "0px 15px"}}>
                                <h4 className={"pb-0 font-weight-bold"}>Statement of Health Professional:</h4>
                                <small>(to be filled in by health professional
                                    with appropriate knowledge of proposed investigation)
                                </small>

                                <p>I have explained the procedure to the patient, in particular:</p>
                                <p>
                                    To evaluate the condition, it may be necessary, initially,
                                    and periodically, to perform an internal pelvic floor muscle examination.
                                    This examination is performed by observing and/or palpating the perineal region including the vagina and/or rectum.
                                    This evaluation will assess skin condition, reflexes, muscle tone, length, strength, coordination and endurance,
                                    scar mobility and function of the pelvic floor region. Such evaluation may include vaginal or rectal sensors for muscle biofeedback.
                                </p>
                                <p>
                                    Treatment may include, but not limited to the following: observation, palpation, use of vaginal weights, internal muscle release techniques,
                                    vaginal or rectal sensors for biofeedback and/or electrical stimulation, strengthening exercises,
                                    soft tissue and/or joint mobilization and educational instruction.
                                </p>
                            </div>

                            <div style={{margin: "0px 15px"}}>
                                <div className={"row"}>
                                    <div className={"col-6 flex-column"}>
                                     <div className={"border border-dark h-100 p-3"}>
                                         <h5 className={"font-weight-bold"}>Risks</h5>
                                         <p>Most of the techniques used in Perineal Physiotherapy have no adverse effects. Risks associated with doing an internal pelvic floor exam include: </p>
                                         <ul>
                                             <li>Pain/discomfort (during or after the assessment)</li>
                                             <li>Spotting/bleeding (during or after the assessment) </li>
                                             <li>Anxiety/emotional response</li>
                                             <li>Skin reaction /irritation (from gloves and/or lubricant) </li>
                                             <li>Vaso-vagal response (nausea or light-headedness)</li>
                                         </ul>
                                     </div>
                                    </div>
                                    <div className={"col-6 flex-column"}>
                                        <div className={"border border-dark h-100 p-3"}>
                                            <h5 className={"font-weight-bold"}>Benefits</h5>
                                            <p>Pelvic floor treatment may improve your symptoms: </p>
                                            <ul>
                                                <li>Reduced pelvic pain</li>
                                                <li>Reduced symptoms of other conditions caused by pelvic floor problems, such as urinary and fecal incontinence,
                                                    painful intercourse, and sexual dysfunction
                                                </li>
                                                <li>Increase in ability to perform daily activities</li>
                                                <li>Increased strength, awareness, flexibility and endurance in movements</li>

                                            </ul>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    Provider Name
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    {": " + (form.providerName ? form.providerName : " ")}
                                </div>

                                <div className="col-md-3 dim14-form-label">
                                    Job Title
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    {": " + (form.providerSpeciality ? form.providerSpeciality : " ")}
                                </div>
                            </div>

                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    Date/Time
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    {": " + moment(new Date(form.healthProfessionalDate)).format('MMMM DD YYYY, h:mm:ss a')}
                                </div>

                                <div className="col-md-6">
                                    <div className="col-md-12 dim14-form-label">
                                        Signature of Provider
                                    </div>

                                    <div className="col-md-12">
                                        <FormField
                                            ref={(ref) => this.formFields.push(ref)}
                                            required requiredMessage="Signature required"
                                            validateOn={form.providerSignature}
                                        >
                                            <SignatureInput signature={form.providerSignature}
                                                            onClear={() => {
                                                                form.providerSignature = null;
                                                                this.forceUpdate();
                                                            }}
                                                            onSave={(signature) => {
                                                                form.providerSignature = signature;
                                                                this.forceUpdate();
                                                            }}/>
                                        </FormField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>

                    <div style={{margin: "20px 20px 20px 20px"}}>

                        <div style={{backgroundColor: "white", marginBottom: "20px", padding: "20px"}}>
                            <h4 className={"mb-0"}>Statement of patient:</h4>
                            <small>Please read this form carefully. If you have any further questions,
                                do ask. You have the right to change your mind at any time, including after you have signed this form.</small>
                            <p className={"font-weight-bolder"}>I understand and agree to the following: </p>
                            <ul>
                                <li><b>I understand</b> the investigation which has been explained to me.</li>
                                <li><b>I understand</b> that I can terminate the procedure at any time. </li>
                                <li><b>I understand</b> that I am responsible for immediately telling the examiner if I am having any discomfort or unusual symptoms during the procedure and if I wish to terminate the examination or treatment.</li>
                                <li><b>I understand</b> that I am responsible in case I chose to have a second person present in the room during the procedure </li>
                                <li><b>I verify</b> that I have not been deemed a high-risk pregnancy by my physician, and I understand that if I am, I will notify my therapist immediately. </li>
                                <li><b>I understand</b>that if I have experienced past physical or emotional trauma related to the pelvic region it is best if I share this information with my treating therapist. </li>
                                <li>If I am pregnant, I understand it is my responsibility to be cleared by my physician before an internal vaginal examination takes place</li>
                                <li><b>I understand</b> that the person performing the investigation has the appropriate experience or is training under direct supervision. </li>
                                <li><b>I agree</b> that I have had a chance to ask appropriate questions.</li>
                            </ul>
                            <p>I have <b>informed</b> my Therapist that I <b>DO NOT</b> have a Pacemaker, suspected current infection, pregnancy, tumors, implants or any other condition that may contraindicate this treatment</p>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 dim14-form-label">
                                            Date/Time
                                        </div>
                                        <div className="col-md-6 dim14-base-input">
                                            {": " + moment(new Date(form.patientDate)).format('MMMM DD YYYY, h:mm:ss a')}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 dim14-form-label">
                                            Patient Name
                                        </div>
                                        <div className="col-md-6 dim14-base-input">
                                            {": " + form.patientFullName}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="col-md-12 dim14-form-label">
                                        Signature of Patient
                                    </div>

                                    <div className="col-md-12">
                                        <SignatureInput signature={form.patientSignature}
                                                        onClear={() => {
                                                            form.patientSignature = null;
                                                            this.forceUpdate();
                                                        }}
                                                        onSave={(signature) => {
                                                            form.patientSignature = signature;
                                                            this.forceUpdate();
                                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onPrevious={() => this.formFields = []}>

                    <div style={{margin: "20px 20px 20px 20px"}}>
                        <div className={"row"}>
                       <p className={"font-weight-bolder"}>
                           A witness should sign below if the patient is unable to sign but has indicated his or her consent. <br/>Young people (under {ConsentFormsUtil.getWitnessAgeText()} years of age) and children should also have a Parent or Guardian sign here.
                       </p>
                        </div>
                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    Print Name
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    <TextInput value={form.guardianName} alphabeticOnly
                                               onChange={(value) => this.fieldChanged('guardianName', value)}/>
                                </div>

                                <div className="col-md-3 dim14-form-label">
                                    Relationship to Patient
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    <TextInput value={form.guardianRelationship} alphabeticOnly
                                               onChange={(value) => this.fieldChanged('guardianRelationship', value)}/>
                                </div>
                            </div>

                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    Date/Time
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    {": " + moment(new Date(form.guardianDate)).format('MMMM DD YYYY, h:mm:ss a')}
                                </div>

                                <div className="col-md-6">
                                    <div className="col-md-12 dim14-form-label">
                                        Signature of Witness/Parent or Guardian
                                    </div>

                                    <div className="col-md-12">
                                        <FormField
                                            ref={(ref) => this.formFields.push(ref)}
                                            disabled={form.patientSignature != null}
                                            required requiredMessage="Signature required"
                                            validateOn={form.guardianSignature}
                                        >
                                            <SignatureInput signature={form.guardianSignature}
                                                            onClear={() => {
                                                                form.guardianSignature = null;
                                                                this.forceUpdate();
                                                            }}
                                                            onSave={(signature) => {
                                                                form.guardianSignature = signature;
                                                                this.forceUpdate();
                                                            }}/>
                                        </FormField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>
            </Wizard>
        )
    }
}
