import React from "react";
import Home from "./modules/home/Home";
import Billing from "./modules/billing/Billing";
import Bill from "./modules/billing/Bill";
import EMR from "./modules/emr/recordpage/EMR";
import EMRHome from "./modules/emr/home/EMRHome";
import Department from "./modules/department/Department";
import Patient from "./modules/patient/Patient";
import RoomList from "./modules/room/RoomList";
import RoomCalendar from "./modules/room/calendar/RoomCalendar";
import Staff from "./modules/staff/Staff";
import StaffProfile from "./modules/staff/staffprofile/StaffProfile";
import PatientDetail from "./modules/patient/patientdetail/PatientDetail";
import Appointment from "./modules/appointment/Appointment";
import PatientConsent from "./modules/consentform/public/PatientConsent";
import InsuranceEligibilityScreen from "./modules/insurance/eligibility/InsuranceEligibilityScreen";
import LeaveCalendar from "./modules/hr/LeaveCalendar";
import LeaveSetup from "./modules/hr/LeaveSetup";
import RoomCalendarSettings from "./modules/room/calendar/RoomCalendarSettings";
import CreditGiftQR from "./modules/patient/patientdetail/credit/CreditGiftQR";
import PatientVisitHistory from "./modules/emr/recordpage/visithistory/PatientVisitHistory";
import UserSetup from "./modules/hr/usersetup/UserSetup";
import ForgotPassword from "./modules/staff/password/ForgotPassword";
import ResetPassword from "./modules/staff/password/ResetPassword";

const routes = [
    {path: "/", exact: true, authority: "DEFAULT", component: Home},
    {path: "/appointment", exact: true, authority: "VIEW_APPOINTMENT", component: Appointment},
    {path: "/billing", exact: true, authority: "VIEW_BILLING_INVOICE", component: Billing},
    {path: "/bill", exact: true, authority: "VIEW_BILLING_INVOICE", component: Bill},
    {path: "/insurance", exact: true, authority: "VIEW_INSURANCE", component: InsuranceEligibilityScreen},
    {path: "/emr", exact: true, authority: "VIEW_EMR", component: EMRHome},
    {path: "/emr/:mode/:patientid/:appointmentid", exact: true, authority: "VIEW_EMR", component: EMR},
    {path: "/emr/visithistory/:patientId", exact: true, authority: "VIEW_EMR", component: PatientVisitHistory},
    {path: "/department", exact: true, authority: "DEFAULT", component: Department},
    {path: "/patient", exact: true, authority: "DEFAULT", component: Patient},
    {path: "/room", exact: true, authority: "DEFAULT", component: RoomList},
    {path: "/roomSchedule", exact: true, authority: "DEFAULT", component: RoomCalendar},
    {path: "/staff", exact: true, authority: "VIEW_PROVIDER", component: Staff},
    {path: "/staff/:view", exact: true, authority: "VIEW_PROVIDER", component: Staff},
    {path: "/staffprofile/:staffId", exact: true, authority: "VIEW_PROVIDER", component: StaffProfile},
    {path: "/patientdetail/:patientId/:appointmentId?/:selectedView?/:selectedDate?", exact: true, authority: "DEFAULT", component: PatientDetail},
    {path: "/forms", exact: true, component: PatientConsent},
    {path: "/hr", exact: true, authority: "DEFAULT", component: LeaveCalendar},
    {path: "/hr/manageLeaveRequests", exact: true, authority: "VIEW_HR_MANAGE_LEAVE", component: props => <LeaveCalendar {...props} page={'MANAGE_LEAVE_REQUESTS'}/>},
    {path: "/hr/leaveSetup", exact: true, authority: "VIEW_HR_MANAGE_LEAVE", component: LeaveSetup},
    {path: "/hr/userSetup", exact: true, authority: "VIEW_ALL_USERS", component: UserSetup},
    {path: "/roomCalendarSettings", exact: true, authority: "DEFAULT", component: RoomCalendarSettings},
    {path: "/gift", exact: true, authority: "DEFAULT", component: CreditGiftQR},
    {path: "/forgotPassword", exact: true, component: ForgotPassword},
    {path: "/resetPassword", exact: true, component: ResetPassword}
];

export default routes;
