import Enum from "../../../../../../enums/Enum";

const MechanismEnum = new Enum({
        UNKNOWN: {name: "Unknown"},
        SPORTS: {name: "Sports"},
        WORK: {name: "Work"},
        SLIP_FALL: {name: "Slip-Fall"},
        SURGERY: {name: "Surgery"},
        ACCIDENT: {name: "Accident"},
        OTHER: {name: "Other"}
});

export default MechanismEnum;