const EMAIL_VALIDATOR = /^([a-zA-Z\d]|([a-zA-Z\d][\w\.\-]*[a-zA-Z\d]))@[a-zA-Z\d][\w\.\-]*[a-zA-Z\d]\.[a-zA-Z]+$/;

export default class EmailValidator {

    getErrorMessage(prop) {
        return "Field should be a valid email";
    }

    validate(value, prop) {
        if (value === null || value === undefined || value === "") {
            return true;
        }
        return EMAIL_VALIDATOR.test(value);
    }
}