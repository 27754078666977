import Enum from "../../../../../../enums/Enum";

const DermatomesLumbarEnum = new Enum({
    D_L1: {value: "L1 - Back, over trochanter and groin"},
    D_L2: {value: "L2 - Back, front of thigh to knee"},
    D_L3: {value: "L3 - Back, upper buttock, anterior thigh and knee, medial lower leg"},
    D_L4: {value: "L4 - Medial buttock, latera thigh, medial leg, dorsum of foot, big toe"},
    D_L5: {value: "L5 - Buttock, posterior and lateral thigh, lateral aspect of leg, dorsum of foot, medial half of sole, first, second, and third toes"},
});

export default DermatomesLumbarEnum;