import Enum from "../../../../../../enums/Enum";

const FunctionEnum = new Enum({
        BILATERAL_SQUATTING: {name: "Bilateral squatting"},
        SINGLE_LEG_STANCE: {name: "Single leg stance"},
        LUNGES: {name: "Lunges"},
        DOUBLE_LEG_STANCE: {name: "Double leg stance"},
        SINGLE_HEEL_RAISE: {name: "Single heel raise"},
        DOUBLE_LEG_RAISE: {name: "Double leg raise"},
        JUMPING_HOPPING: {name: "Jumping/Hopping"},
        THROWING: {name: "Throwing"},
        KICKING: {name: "Kicking"},
        PUNCHING: {name: "Punching"},
        LATERAL_HOP: {name: "Lateral hop"},
        DOUBLE_TOE_RAISE: {name: "Double toe raise"},
        STEP_UP_STEP_DOWN: {name: "Step up/Step down"},
        PUSH_UP: {name: "Push up"},
        WRIST: {name: "Wrist (opening jar)"},
        HURDLE_STEP: {name: "Hurdle Step"},
        THORACIC_ROTATION: {name: "Thoracic Rotation"},
        OTHER: {name: "Other"}
});

export default FunctionEnum;