function Enum(enumObj) {
    for (let prop in enumObj) {
        if (enumObj.hasOwnProperty(prop)) {
            this[prop] = enumObj[prop];
            if (typeof enumObj[prop] === "object") {
                this[prop].key = prop;
                this[prop].toJSON = () => prop;
            }
        }
    }
    this.enumObj = enumObj;
}

Enum.prototype.keys = function () {
    const result = [];
    for (let prop in this.enumObj) {
        if (this.enumObj.hasOwnProperty(prop)) {
            result.push(prop);
        }
    }
    return result;
};

Enum.prototype.values = function () {
    const result = [];
    for (let prop in this.enumObj) {
        if (this.enumObj.hasOwnProperty(prop)) {
            result.push(this.enumObj[prop]);
        }
    }
    return result;
};

Enum.prototype.toArray = function () {
    const result = [];
    for (let prop in this.enumObj) {
        if (this.enumObj.hasOwnProperty(prop)) {

            if (typeof this.enumObj[prop] === 'object') {
                this.enumObj[prop].key = prop;
                result.push(this.enumObj[prop]);
            } else {
                result.push({key: prop, name: this.enumObj[prop]});
            }
        }
    }
    return result;
};

export default Enum;