import React from 'react';
import {connect} from 'react-redux';
import {Image} from 'react-bootstrap';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {userActions} from "../../../services/actions";
import "./UserMenu.css";
import AuthService from "../../../services/auth-service/AuthService";
import {withRouter} from "react-router";

class UserMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };
    }

    handleClick = event => {
        this.setState({anchorEl: event.currentTarget})
    };

    handleClose = () => {
        this.setState({anchorEl: null})
    };

    getUserName = () => {
        return this.props.user && this.props.user.name ? this.props.user.name: "...";
    };

    handleViewProfile = () => {
        const id = AuthService.getUser().staffId;
        this.props.history.push("/staffprofile/" + id);
        this.handleClose();
    };

    handleLogout = () => {
        this.props.dispatch(userActions.logout());
        this.props.history.push("/");
    };

    render() {
        const {thumbnail} = this.props.user;

        return (
            <div className="user-menu-wrapper">
                <label>{this.getUserName()}</label>

                <Image src={thumbnail ? 'data:image/jpeg;base64,' + thumbnail : process.env.PUBLIC_URL + '/assets/user-genderless.png'}
                       className={'rounded-circle ' + (thumbnail ? '' : 'default')}
                       onClick={this.handleClick} />

                <Popper id="user-menu"
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                        role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <MenuList autoFocusItem={Boolean(this.state.anchorEl)} id="menu-list-grow">
                                        <MenuItem onClick={this.handleViewProfile}>View Profile</MenuItem>
                                        <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user
    };
}

export default connect(mapStateToProps)(withRouter(UserMenu));
