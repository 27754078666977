import React, {Component} from 'react';
import PropTypes from "prop-types";
import {fabric} from "fabric"
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./BodySilhouette.css";
import noop from "lodash-es/noop";

class BodySilhouetteStaffCanvas extends Component {

    state = {}

    static defaultProps = {
        width: 692,
        height: 575,
        zoom: 1,
        onClickFunction: noop,
    }

    static propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        zoom: PropTypes.number,
        onClickFunction: PropTypes.func
    }

    constructor(props) {
        super(props);
        this.screenRatio = window.innerWidth / 1920;
    }

    componentDidMount() {
        this.initializeCanvas();
    }

    initializeCanvas() {
        const canvas = new fabric.Canvas(this.c);
        canvas.setBackgroundImage(process.env.PUBLIC_URL + '/assets/forms/4body.png', canvas.renderAll.bind(canvas));
        canvas.setZoom(this.props.zoom * this.screenRatio)
        canvas.selection = false;

        this.setEventsToCanvas(canvas);

        let self = this;

        canvas.on('after:render', function (e) {
            self.props.onPageChange(canvas.toJSON().objects);
        });

        this.setState({canvas})
    }

    setEventsToCanvas(canvas) {
        let self = this;
        canvas.upperCanvasEl.onclick = function (event) {
            //for proper selection
            const xOffset = 4 * self.screenRatio;
            const yOffset = 3 * self.screenRatio;

            self.props.onClickFunction((event.offsetY / (self.props.zoom * self.screenRatio)) - yOffset, (event.offsetX / (self.props.zoom * self.screenRatio)) - xOffset);
        }
        canvas.upperCanvasEl.onmouseleave = function (event) {
            self.props.onLeave();
            self.resetAllSettings();
        }
    }

    makeItemsSelectable = () => {
        this.state.canvas.forEachObject(function (object) {
            object.selectable = true;
        });
        this.state.canvas.moveCursor = "pointer";
        this.state.canvas.hoverCursor = "pointer";
    }

    makeItemsUnselectable = () => {
        this.state.canvas.forEachObject(function (object) {
            object.selectable = false;
        });
        this.state.canvas.moveCursor = "default";
        this.state.canvas.hoverCursor = "default";
    }

    resetAllSettings = () => {
        this.makeItemsSelectable();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.symptomsCursorClassName == null && this.props.symptomsCursorClassName !== null) {
            this.makeItemsUnselectable();
        }
    }

    render() {
        this.children = React.Children.map(this.props.children, child => {
            return child ? React.cloneElement(child, {
                canvas: this.state.canvas,
                selectable: true
            }) : null;
        });

        if (this.state.canvas) {
            this.labels = [
  /*              <CanvasLabel canvas={this.state.canvas} label="Right" />,
                <CanvasLabel canvas={this.state.canvas} label="Front" left={207} />,
                <CanvasLabel canvas={this.state.canvas} label="Back" left={434} />,
                <CanvasLabel canvas={this.state.canvas} label="Left" left={615} />*/
            ];

            this.state.canvas.upperCanvasEl.className = this.props.symptomsCursorClassName;
        }

        const width = this.props.width * this.screenRatio;
        const height = this.props.height * this.screenRatio;

        return (
            <div>
                <canvas ref={c => (this.c = c)} width={width} height={height} disabled={true}/>
                {this.state.canvas && this.children}
     {/*           {this.state.canvas && this.labels}*/}
                <div>
                    <div style={{display: "flex", flexDirection: "row-reverse"}}>
                        <Button style={{
                            width: '40px',
                            marginRight: "10px",
                        }}
                                size={"sm"}
                                variant="danger"
                                onClick={() => {
                                    const {canvas} = this.state;
                                    const activeObject = canvas.getActiveObject();
                                    canvas.discardActiveObject();
                                    canvas.remove(activeObject);
                                    this.props.onRemoveItem();
                                }}>
                            <FontAwesomeIcon icon={["fas", "trash"]} size={"sm"}/>
                        </Button>
                    </div>
                </div>
            </div>
        )
    }
}

export default BodySilhouetteStaffCanvas;
