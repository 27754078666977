export default class MinLengthValidator {

    getErrorMessage(prop) {
        return "Field should be more than or equal to " + prop + " characters";
    }

    validate(value, prop) {
        if (value === null || value === undefined) {
            return true;
        }
        if (prop === null || prop === undefined) {
            return true;
        }
        return value.length >= prop;
    }

}