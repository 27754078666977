import React, {Component} from "react";
import '../../EMR.css';
import BloodPressureLocationEnum from "../enums/vitalSigns/BloodPressureLocationEnum";
import PulsePositionEnum from "../enums/vitalSigns/PulsePositionEnum";
import TemperatureLocationEnum from "../enums/vitalSigns/TemperatureLocationEnum";
import PulseLocationEnum from "../enums/vitalSigns/PulseLocationEnum";
import BloodPressurePositionEnum from "../enums/vitalSigns/BloodPressurePositionEnum";
import BMIEnum from "../enums/vitalSigns/BMIEnum";
import VitalSignsUtil from "../utils/VitalSignsUtil";

export default class VitalSignsView extends Component {
    state = {
        list: [],
        index: null,
        listSize: null
    }

    render() {
        let {bmi, bmiStatus} = this.props.vitalSigns;
        if (bmi != null && bmiStatus == null) {
            bmiStatus = VitalSignsUtil.getBMIStatus(bmi);
        }

        return (
            <div>
                <table className={"emr-table "}>
                    <tbody>
                    <tr>
                        <th>Temperature <br/>(°C)</th>
                        <th>Pulse <br/>(Beats/Min)</th>
                        <th>Blood Pressure <br/> (mm/Hg)</th>
                        <th>Respiratory Rate <br/>(Breaths/Min)</th>
                        <th>Height <br/>(cm - ft)</th>
                        <th>Weight <br/>(kg - lbs)</th>
                        <th>BMI <br/>(kg/㎡)</th>
                        <th>Body Fat<br/> (%)</th>
                    </tr>
                    {this.props.vitalSigns != null ?
                        <tr key={"vitalSignsView"} style={{backgroundColor: "white"}}>
                            {this.renderTemperatureValueColumn()}
                            {this.renderPulseValueColumn()}
                            {this.renderBloodPressureValueColumn()}
                            {this.renderRespirationValueColumn()}
                            {this.renderHeightValueColumn()}
                            {this.renderWeightValueColumn()}
                            {this.renderBMIValueColumn(bmi, bmiStatus)}
                            {this.renderBodyFatValueColumn()}
                        </tr> : " "}
                    </tbody>
                </table>
            </div>
        );
    }

    renderBodyFatValueColumn() {
        return (
            <td>
                {this.renderColumn([
                    this.props.vitalSigns.bodyFat,
                    this.props.vitalSigns.bodyFatDetails
                ])}
            </td>);
    }

    renderBMIValueColumn(bmi, bmiStatus) {
        return (
            <td>
                {this.renderColumn([
                    bmi,
                    bmiStatus != null ? BMIEnum[bmiStatus].name : null
                ])}
            </td>);
    }

    renderWeightValueColumn() {
        return (
            <td>
                {this.renderColumn([
                    this.props.vitalSigns.weight,
                    this.props.vitalSigns.weightDetails
                ])}
            </td>);
    }

    renderHeightValueColumn() {
        return (
            <td>
                {this.renderColumn([
                    this.props.vitalSigns.height,
                    this.props.vitalSigns.heightDetails
                ])}
            </td>);
    }

    renderRespirationValueColumn() {
        return (
            <td>
                {this.renderColumn([
                    this.props.vitalSigns.respiration,
                    this.props.vitalSigns.respirationDetails
                ])}
            </td>);
    }

    renderBloodPressureValueColumn() {
        return (
            <td>
                {this.renderColumn([
                    [this.props.vitalSigns.bloodPressureAbove, this.props.vitalSigns.bloodPressureBelow].filter(Boolean).join(" / "),
                    this.props.vitalSigns.bloodPressureLocation != null ?
                        BloodPressureLocationEnum[this.props.vitalSigns.bloodPressureLocation].name : null,
                    this.props.vitalSigns.bloodPressurePosition != null ?
                        BloodPressurePositionEnum[this.props.vitalSigns.bloodPressurePosition].name : null,
                    this.props.vitalSigns.bloodPressureDetails
                ])}
            </td>);
    }

    renderPulseValueColumn() {
        return (
            <td>
                {this.renderColumn([
                    this.props.vitalSigns.pulse,
                    this.props.vitalSigns.pulseLocation != null ?
                        PulseLocationEnum[this.props.vitalSigns.pulseLocation].name : null,
                    this.props.vitalSigns.pulsePosition != null ?
                        PulsePositionEnum[this.props.vitalSigns.pulsePosition].name : null,
                    this.props.vitalSigns.pulseDetails
                ])}
            </td>);
    }

    renderTemperatureValueColumn() {
        return (
            <td>
                {this.renderColumn([
                    this.props.vitalSigns.temperature,
                    this.props.vitalSigns.temperatureLocation != null ?
                        TemperatureLocationEnum[this.props.vitalSigns.temperatureLocation].name : null,
                    this.props.vitalSigns.temperatureDetails
                ])}
            </td>);
    }

    renderColumn = (itemList) => {
        return (itemList.filter(Boolean).map(item => <p className="m-0">{item}</p>))
    }
}