import React, {Component} from "react";
import DateUtil from "../../../../../utils/DateUtil";
import CommunicationMethod from "../../../../../components/radio-input/CommunicationMethod";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TypeEnum from "../../treatment/enums/result/TypeEnum";

export default class ResultsEMRDashboardView extends Component {

    render() {
        if(this.props.results == null
            || this.props.results.length == 0) {
            return null;
        }

        return (
            <div>
                <div className="content-card">
                    <table className={"emr-table"}>
                        <tbody>
                        <tr>
                            <th>Type</th>
                            <th>Details</th>
                            <th>Findings</th>
                            <th>Remarks</th>
                            <th>Date Ordered</th>
                            <th>Ordered By</th>
                            <th>Result Received</th>
                            <th>Patient Informed</th>
                            <th>Attach</th>
                        </tr>
                        {this.props.results.map((m, index) => {
                            return (<tr key={"resultView" + index} style={{backgroundColor: "white"}}>
                                <td>{m.type == null ? "" : TypeEnum[m.type].name}</td>
                                <td>{m.detail}</td>
                                <td>{m.findings}</td>
                                <td>{m.remarks}</td>
                                <td>{m.dateOrdered == null ? "" : DateUtil.formatDateMMM(m.dateOrdered)}</td>
                                <td>{m.orderedBy}</td>
                                <td>{m.resultReceived == null ? "" : DateUtil.formatDateMMM(m.resultReceived)}</td>
                                <td>
                                    <CommunicationMethod value={m.patientInformed}
                                                         informedDate={m.informedDate}
                                                         disabled={true}/>
                                </td>
                                <td style={{padding:"5px 0px"}}>
                                    {this.attachmentTable(m.files)}
                                </td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }

    attachmentTable = (files) => {
        if(files == null || files.length == 0) {
            return null;
        }
        return (
            <div>
                <FontAwesomeIcon icon={["fas", "paperclip"]} size={"lg"} style={{marginLeft: '5px'}}/>
                <table className={"attachment-list"}>
                    <tbody>
                    {files.map((f) => {
                        return (
                            <tr>
                                <td className={"link-column"}>
                                    {f.name}
                                </td>
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
}