import React, {Component} from 'react';
import {Image} from "react-bootstrap";

class ReportSignature extends Component {
    render() {
        let {signature} = this.props;

        if (signature == null)
            return <div style={{minHeight: "100px", display: "flex", alignItems: "center"}}>No Signature Information
                Available</div>;

        let photoSrc = 'data:image/jpeg;base64,' + signature;

        return (
            <div className={"pt-2"}>
                <Image rounded src={photoSrc} onmouseover='' style={{cursor: 'pointer',  height: "80px"}}/>
            </div>
        );
    }
}

export default ReportSignature;