import React, {Component} from "react";
import '../../EMR.css';
import FallRiskManagementUtils from "../utils/FallRiskManagementUtils";

export default class FallRiskManagementView extends Component {

    state = {}

    render() {
        const {fallRiskManagement} = this.props;

        if (!fallRiskManagement ||
            !fallRiskManagement.items ||
            fallRiskManagement.items.length === 0)
            return <div className="pl-3">-</div>;

        let fallRiskManagementItems = this.getFallRiskManagementItems(fallRiskManagement);

        return (
            <div>
                {fallRiskManagementItems}
            </div>
        );
    }

    getFallRiskManagementItems = (fallRiskManagement) => {
        let divs = []
        fallRiskManagement.items.forEach((item, index) => divs.push(
            <div className="d-flex w-100 m-0 p-1 content-card pb-3 pl-2" style={{height: "fit-content"}}
                 key={"fallRiskManagementItem" + index}>
                {FallRiskManagementUtils.getFallRiskItemDiv(item)}
            </div>
        ));
        return divs;
    }
}