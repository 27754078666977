import React, {Component} from 'react';
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import {TextareaInput, TextInput} from "../../../components";
import {Dropdown} from "primereact/dropdown";
import DurationTimeEnum from "../../emr/recordpage/subjective/enums/complaint/DurationTimeEnum";
import {PainSlider, PainValueLabelComponent} from "../PainSlider";
import PainSliderOptions from "../PainSliderOptions";
import {FormSectionHeader} from "../public/FormSectionHeader";

class IntakeFormComplaint extends Component {

    setPainInfo(value) {
        const painValue = PainSliderOptions.getPainInfo(value);
        this.props.onChange("painAmount", painValue);
    }

    render() {
        const {
            selectedLanguage, readOnly, enumLabel, onChange,
            describeMainProblem, whenDidItStart, whenDidItStartOption, painAmount
        } = this.props

        return (
            <>
                <FormSectionHeader header={IntakeFormTextEnum.Complaint[selectedLanguage]}/>
                <div className="form-group">
                    <label>{IntakeFormTextEnum.What_Is_Your_Main_Complaint[selectedLanguage]}</label>
                    <TextareaInput
                        className="w-100"
                        value={describeMainProblem}
                        disabled={readOnly}
                        onChange={(value) =>
                            onChange("describeMainProblem", value)
                        }
                    />
                </div>

                <div className="form-group">
                    <label>
                        <b>{IntakeFormTextEnum.When_Did_This_Start[selectedLanguage]}</b>
                    </label>
                    <div className="form-inline">
                        <TextInput
                            className="form-control"
                            value={whenDidItStart}
                            disabled={readOnly}
                            onChange={(value) =>
                                onChange("whenDidItStart", value)
                            }
                        />
                        <label
                            className=" col-form-label mx-2">{IntakeFormTextEnum.Duration[selectedLanguage]}</label>
                        <Dropdown
                            className={`WhenDidItStartDropdown mx-3 ${
                                readOnly
                                    ? "DropdownDisabledBackground"
                                    : "DropdownBorderColor"
                            }`}
                            value={whenDidItStartOption}
                            options={DurationTimeEnum.toArray().map(v => {
                                return {value: v.key, label: v[enumLabel]}
                            })}
                            disabled={readOnly}
                            optionLabel={"label"}
                            onChange={(event) =>
                                onChange(
                                    "whenDidItStartOption",
                                    event.value
                                )
                            }
                        />
                    </div>
                </div>

                <div className="dim14-form-label mt-5">
                    {IntakeFormTextEnum.Please_Rate_Your_Pain[selectedLanguage]}
                </div>
                <div>
                    <PainSlider
                        ValueLabelComponent={PainValueLabelComponent}
                        aria-label="custom thumb label"
                        min={0}
                        max={10}
                        step={null}
                        marks={PainSliderOptions.painMarks(enumLabel)}
                        disabled={readOnly}
                        valueLabelDisplay="auto"
                        enumLabel={enumLabel}
                        value={painAmount ? painAmount : 0}
                        onChange={(event, value) => {
                            this.setPainInfo(value);
                        }}
                    />
                </div>
            </>
        );
    }
}

export default IntakeFormComplaint;