import React, {Component} from "react";
import '../EMR.css';
import './Subjective.css';
import ComplaintModal from "./modals/ComplaintModal";
import Modal from "../../../../components/modal/Modal";
import ComplaintView from "./views/ComplaintView";
import AllergyView from "./views/AllergyView";
import AllergyModal from "./modals/AllergyModal";
import MedicationUseView from "./views/MedicationUseView";
import MedicationUseModal from "./modals/MedicationUseModal";
import LifestyleView from "./views/LifestyleView";
import LifestyleModal from "./modals/LifestyleModal";
import ReviewOfSystemModal from "./modals/ReviewOfSystemModal";
import ReviewOfSystemView from "./views/ReviewOfSystemView";
import FamilyHistoryView from "./views/FamilyHistoryView";
import HistoryModal from "./modals/HistoryModal";
import MedicalHistoryView from "./views/MedicalHistoryView";
import SurgicalHistoryView from "./views/SurgicalHistoryView";
import BirthHistoryView from "./views/BirthHistoryView";
import PsychoSocialHistoryView from "./views/PsychoSocialHistoryView";
import TreatmentReceivedModal from "./modals/TreatmentReceivedModal";
import TreatmentReceivedView from "./views/TreatmentReceivedView";
import ActivityLimitationView from "./views/ActivityLimitationView";
import OtherInjuriesView from "./views/OtherInjuriesView";
import LabImagingView from "./views/LabImagingView";
import GreenSwitchWithMessage from "../../../../components/switch/GreenSwitchWithMessage";
import {AllergyChecker} from "./checkers/AllergyChecker";
import {MedicationUseChecker} from "./checkers/MedicationUseChecker";
import {TreatmentReceivedChecker} from "./checkers/TreatmentReceivedChecker";
import {ActivityLimitationChecker} from "./checkers/ActivityLimitationChecker";
import {OtherInjuriesChecker} from "./checkers/OtherInjuriesChecker";
import {LabsImagingChecker} from "./checkers/LabsImagingChecker";
import {ReviewOfSystemChecker} from "./checkers/ReviewOfSystemChecker";
import StringUtil from "../../../../utils/StringUtil";
import VisitNavigator from "../common/VisitNavigator";
import RedAsterisk from "../../../../components/asterisk/RedAsterisk";
import {PrescriptionChecker} from "./checkers/PrescriptionChecker";
import {connect} from "react-redux";
import cloneDeep from 'lodash/cloneDeep';
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";

class Subjective extends Component {

    state = {
        complaintModalVisible: false,
        allergyModalVisible: false,
        medicationUseModalVisible: false,
        lifestyleModalVisible: false,
        reviewOfSystemModalVisible: false,
        historyModalVisible: false,
        treatmentReceivedModalVisible: false,
        closeConfirmModalVisible: false,
        activeMedicationUse: {},
        closeModalFunctionCallback: null,
        index: 0
    }

    componentDidMount() {
        let lastSubjective = this.props.visits[this.props.visits.length - 1].subjective;
        if (lastSubjective.complaint == null || StringUtil.isNullOrEmpty(lastSubjective.complaint.complaint)) {
            this.setState({
                index: Math.max((this.props.currentVisitIndex - 1), 0)
            });
        } else {
            this.setState({index: this.props.currentVisitIndex});
        }
    }

    render() {
        let visit = this.props.visits[this.state.index];
        let subjective = this.props.subjective;
        let prescriptions = this.props.prescriptions;
        return (
            <div>
                <VisitNavigator
                    visits={this.props.visits}
                    currentVisitIndex={this.props.currentVisitIndex}
                    selectedIndex={this.state.index}
                    onDateChange={(index) => {
                        this.setState({index: index});
                    }}
                />
                <div className={this.props.disabled ? "content-row disable-pointer" : "content-row"}>
                    <div className={"content-col-6"}>
                        <div className={"content-wrapper"}>
                            <div onClick={this.openComplaintModal}
                                 className={"content-clickable mb-2"}>
                                <ComplaintView complaint={subjective.complaint}
                                               complaintModalVisible={this.state.complaintModalVisible}/>
                            </div>

                            <div className={"content-clickable"}
                                 onClick={this.openTreatmentReceivedModal}>
                                <div style={{display: "flex"}}>
                                    <div className="content-label">Activity Limitation</div>
                                    <GreenSwitchWithMessage
                                        message={ActivityLimitationChecker.isNone(subjective.activityLimitation) ? "No Activity Limitation" : ""}
                                        checked={ActivityLimitationChecker.isToggleOn(subjective.activityLimitation)}/>
                                </div>
                                {ActivityLimitationChecker.isDataEntered(subjective.activityLimitation) &&
                                <div className={"content-card"}>
                                    <ActivityLimitationView activityLimitation={subjective.activityLimitation}/>
                                </div>
                                }
                            </div>

                            <div className={"content-clickable"}
                                 onClick={this.openTreatmentReceivedModal}>
                                <div style={{display: "flex"}}>
                                    <div className="content-label">Treatment Received
                                        <RedAsterisk appointmentType={visit.appointmentType} firstVisit newComplaint/>
                                    </div>
                                    <GreenSwitchWithMessage
                                        message={TreatmentReceivedChecker.isNone(subjective.treatmentReceived) ? "No Treatment Received" : ""}
                                        checked={TreatmentReceivedChecker.isToggleOn(subjective.treatmentReceived)}/>
                                </div>
                                {TreatmentReceivedChecker.isDataEntered(subjective.treatmentReceived) &&
                                <div className={"content-card"}>
                                    <TreatmentReceivedView treatmentReceived={subjective.treatmentReceived}/>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={"content-col-6"}>
                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openAllergyModal}>
                            <div style={{display: "flex"}}>
                                <div className="content-label">Allergies
                                    <RedAsterisk appointmentType={visit.appointmentType} firstVisit newComplaint/>
                                </div>
                                <GreenSwitchWithMessage
                                    message={AllergyChecker.isNone(this.props.subjective.allergy) ? "None Known" : ""}
                                    checked={AllergyChecker.isToggleOn(this.props.subjective.allergy)}
                                />
                            </div>
                            {AllergyChecker.isDataEntered(this.props.subjective.allergy) &&
                            <div className={"content-card"}>
                                <AllergyView allergy={this.props.subjective.allergy}/>
                            </div>
                            }
                        </div>

                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openMedicationUseModal}>
                            <div style={{display: "flex"}}>
                                <div className="content-label">Medications <RedAsterisk
                                    appointmentType={visit.appointmentType} firstVisit newComplaint/></div>
                                <GreenSwitchWithMessage
                                    message={(MedicationUseChecker.isNone(subjective.medicationUse) && !PrescriptionChecker.isDataEntered(prescriptions)) ? "No Medication" : ""}
                                    checked={MedicationUseChecker.isToggleOn(subjective.medicationUse) || PrescriptionChecker.isDataEntered(prescriptions)}
                                />
                            </div>
                            {(MedicationUseChecker.isDataEntered(subjective.medicationUse) || PrescriptionChecker.isDataEntered(prescriptions)) &&
                            <div className={"content-card"}>
                                <MedicationUseView medicationUse={subjective.medicationUse}/>
                            </div>
                            }
                        </div>
                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openTreatmentReceivedModal}>
                            <div style={{display: "flex"}}>
                                <div className="content-label" className="content-label">Lab/Imaging <RedAsterisk
                                    appointmentType={visit.appointmentType} firstVisit newComplaint/></div>
                                <GreenSwitchWithMessage
                                    message={LabsImagingChecker.isNone(subjective.labsImaging) ? "No Lab/Imaging" : ""}
                                    checked={LabsImagingChecker.isToggleOn(subjective.labsImaging)}/>
                            </div>
                            {LabsImagingChecker.isDataEntered(subjective.labsImaging) &&
                            <div className={"content-card"}>
                                <LabImagingView labImaging={subjective.labsImaging}/>
                            </div>
                            }
                        </div>

                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openTreatmentReceivedModal}>
                            <div style={{display: "flex"}}>
                                <div className="content-label" className="content-label">Other Injuries <RedAsterisk
                                    appointmentType={visit.appointmentType} firstVisit newComplaint/></div>
                                <GreenSwitchWithMessage
                                    message={OtherInjuriesChecker.isNone(subjective.otherInjuries) ? "No Other Injuries" : ""}
                                    checked={OtherInjuriesChecker.isToggleOn(subjective.otherInjuries)}/>
                            </div>
                            {OtherInjuriesChecker.isDataEntered(subjective.otherInjuries) &&
                            <div>
                                <OtherInjuriesView otherInjuries={subjective.otherInjuries}/>
                            </div>
                            }
                        </div>

                        <div className={"content-wrapper mt-0 pt-0"}>
                            <div className={"row content-clickable"}
                                 style={{margin: "5px 0px"}}
                                 onClick={this.openHistoryModal}>
                                <MedicalHistoryView medicalHistory={subjective.medicalHistory}
                                                    appointmentType={visit.appointmentType}/>
                            </div>
                            <div className={"row content-clickable"}
                                 style={{margin: "5px 0px"}}
                                 onClick={this.openHistoryModal}>
                                <FamilyHistoryView familyHistory={subjective.familyHistory}
                                                   appointmentType={visit.appointmentType}/>
                            </div>

                            <div className={"row content-clickable"}
                                 style={{margin: "5px 0px"}}
                                 onClick={this.openHistoryModal}>
                                <SurgicalHistoryView surgicalHistory={subjective.surgicalHistory}
                                                     appointmentType={visit.appointmentType}/>
                            </div>

                            <div className={"row content-clickable"}
                                 style={{margin: "5px 0px"}}
                                 onClick={this.openLifestyleModal}>
                                <PsychoSocialHistoryView lifestyle={subjective.lifestyle}
                                                         appointmentType={visit.appointmentType}/>
                            </div>
                            <div className={"row content-clickable"}
                                 style={{margin: "5px 0px"}}
                                 onClick={this.openHistoryModal}>
                                <BirthHistoryView birthHistory={subjective.birthHistory}/>
                            </div>

                            <div className={"row content-clickable"}
                                 style={{margin: "5px 0px"}}
                                 onClick={this.openLifestyleModal}>
                                <LifestyleView lifestyle={subjective.lifestyle}
                                               appointmentType={visit.appointmentType}/>
                            </div>
                        </div>

                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openReviewOfSystemModal}>
                            <div style={{display: "flex"}}>
                                <div className="content-label" className="content-label">Review of System <RedAsterisk
                                    appointmentType={visit.appointmentType} firstVisit newComplaint/></div>
                                <GreenSwitchWithMessage
                                    message={ReviewOfSystemChecker.isNone(subjective.reviewOfSystem) ? "All Normal" : ""}
                                    checked={ReviewOfSystemChecker.isToggleOn(subjective.reviewOfSystem)}/>
                            </div>
                            {ReviewOfSystemChecker.isDataEntered(subjective.reviewOfSystem) &&
                            <div>
                                <ReviewOfSystemView reviewOfSystem={subjective.reviewOfSystem}/>
                            </div>
                            }
                        </div>
                    </div>

                    {this.renderComplaintModal()}
                    {this.renderAllergyModal()}
                    {this.renderMedicationUseModal()}
                    {this.renderLifestyleModal()}
                    {this.renderReviewOfSystemModal()}
                    {this.renderHistoryModal()}
                    {this.renderTreatmentReceivedModal()}
                    {this.renderCloseConfirmModal()}

                </div>
            </div>
        );
    }

    openComplaintModal = () => {
        if (this.props.subjective.complaint) {
            this.copiedComplaint = cloneDeep(this.props.subjective.complaint);
        }
        this.setState({complaintModalVisible: true});
    }

    saveComplaintModal = () => {
        const bodySilhouetteMarkList = [];
        const bodySilhouetteObject = [...this.props.subjective.complaint.bodySilhouetteObjects];
        bodySilhouetteObject.filter(obj => obj.type !== "textbox").forEach((mark, index) =>
            bodySilhouetteMarkList.push({
                type: mark.type,
                topDistance: mark.top,
                leftDistance: mark.left,
                angle: mark.angle,
                scaleX: mark.scaleX,
                scaleY: mark.scaleY
            }))

        this.props.subjective.complaint.bodySilhouetteMarkList = bodySilhouetteMarkList;

        this.props.onModalSave();
        this.setState({complaintModalVisible: false, index: this.props.currentVisitIndex});
    }

    closeComplaintModal = () => {
        this.props.subjective.complaint = this.copiedComplaint;
        this.setState({complaintModalVisible: false});
    }

    renderComplaintModal() {
        return (
            <Modal visible={this.state.complaintModalVisible}
                   className="ComplaintModal"
                   submitContent="Save"
                   header=' '
                   scroll='body'
                   headerTextVisible={false}
                   submitAction={this.saveComplaintModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeComplaintModal);
                   }}
                   maxWidth={"xl"}
      >
                <ComplaintModal complaint={this.props.subjective.complaint}
                                appointmentType={this.props.currentVisit.appointmentType}/>
            </Modal>
        );
    }

    openAllergyModal = () => {
        if (this.props.subjective.allergy) {
            this.copiedAllergy = cloneDeep(this.props.subjective.allergy);
        } else {
            this.props.subjective.allergy = {};
        }

        this.setState({allergyModalVisible: true});
    }

    saveAllergyModal = () => {
        this.props.onModalSave();
        this.setState({allergyModalVisible: false, index: this.props.currentVisitIndex});
    }

    closeAllergyModal = () => {
        this.props.subjective.allergy = this.copiedAllergy;
        this.setState({allergyModalVisible: false});
    }

    renderAllergyModal() {
        if (!this.state.allergyModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.allergyModalVisible}
                   className="AllergyModal"
                   submitContent="Save"
                   submitAction={this.saveAllergyModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeAllergyModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   contentStyle={{overflowY: 'auto', maxHeight: "95%", width: "50vw"}}>
                <AllergyModal subjective={this.props.subjective}
                              appointmentType={this.props.currentVisit.appointmentType}/>
            </Modal>
        );
    }

    openCloseConfirmModal = (functionToBeExecuted) => {
        this.setState({closeConfirmModalVisible: true, closeModalFunctionCallback: functionToBeExecuted});
    }
    SubmitCloseConfirmModal = () => {
        this.state.closeModalFunctionCallback();
        this.setState({closeConfirmModalVisible: false});
    }
    closeCloseConfirmModal = () => {
        this.setState({closeConfirmModalVisible: false});
    }

    renderCloseConfirmModal() {
        return (
            <Modal visible={this.state.closeConfirmModalVisible}
                   submitContent="Yes"
                   closeContent="No"
                   closeAction={this.closeCloseConfirmModal}
                   submitAction={this.SubmitCloseConfirmModal}
                   header='Are you sure? '
                   maxWidth={"xl"}
                   contentStyle={{maxHeight: "95%", width: "20vw"}}>
                Unsaved changes may be lost!
            </Modal>

        );
    }

    openMedicationUseModal = () => {

        if (this.props.subjective.medicationUse) {
            this.copiedMedicationUse = cloneDeep(this.props.subjective.medicationUse);
        } else {
            this.props.subjective.medicationUse = {};
        }
        this.setState({medicationUseModalVisible: true, activeMedicationUse: {}});
    }

    saveMedicationUseModal = () => {
        if (this.state.activeMedicationUse != null
            && this.props.subjective.medicationUse != null
            && this.state.activeMedicationUse.name != null) {
            this.props.subjective.medicationUse.push(this.state.activeMedicationUse);
        }
        if (this.props.subjective.medicationUse != null
            && this.props.subjective.medicationUse.length == 0) {
            this.props.subjective.medicationUse = null;
        }
        this.props.onModalSave();
        this.setState({
            medicationUseModalVisible: false,
            activeMedicationUse: {},
            index: this.props.currentVisitIndex
        });
    }

    closeMedicationUseModal = () => {
        this.props.subjective.medicationUse = this.copiedMedicationUse;
        this.setState({medicationUseModalVisible: false, activeMedicationUse: {}});
    }

    renderMedicationUseModal() {
        if (!this.state.medicationUseModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.medicationUseModalVisible}
                   className="MedicationUseModal"
                   submitContent="Save"
                   submitAction={this.saveMedicationUseModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeMedicationUseModal);
                   }}
                   headerTextVisible={false}
                   contentStyle={{minHeight: "75vh"}}
                   header=' '
                   fullWidth
                   maxWidth={"xl"}
                  >
                <MedicationUseModal subjective={this.props.subjective}
                                    appointmentType={this.props.currentVisit.appointmentType}/>
            </Modal>
        );
    }

    openLifestyleModal = () => {
        this.lifeStyleFormFields = [];
        if (this.props.subjective.lifestyle != null) {
            this.copiedLifestyle = cloneDeep(this.props.subjective.lifestyle);
        } else {
            this.props.subjective.lifestyle = {};
        }

        this.setState({lifestyleModalVisible: true});
    }

    saveLifestyleModal = () => {
        if (ValidationUtil.checkWithNotification(this.lifeStyleFormFields)) {
            this.props.onModalSave();
            this.setState({lifestyleModalVisible: false, index: this.props.currentVisitIndex});
        }
    }

    closeLifestyleModal = () => {
        this.props.subjective.lifestyle = this.copiedLifestyle;
        this.setState({lifestyleModalVisible: false});
    }

    renderLifestyleModal() {
        if (!this.state.lifestyleModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.lifestyleModalVisible}
                   className="LifestyleModal"
                   submitContent="Save"
                   submitAction={this.saveLifestyleModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeLifestyleModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   contentStyle={{minHeight: "75vh"}}
                   fullWidth
                   maxWidth={"xl"}

            >
                <LifestyleModal lifestyle={this.props.subjective.lifestyle}
                                formFields={this.lifeStyleFormFields}
                                appointmentType={this.props.currentVisit.appointmentType}/>
            </Modal>
        );
    }

    openReviewOfSystemModal = () => {
        if (this.props.subjective.reviewOfSystem) {
            this.copiedReviewOfSystem = cloneDeep(this.props.subjective.reviewOfSystem);
        } else {
            this.props.subjective.reviewOfSystem = {};
        }

        this.setState({reviewOfSystemModalVisible: true});
    }

    saveReviewOfSystemModal = () => {
        this.props.onModalSave();
        this.setState({reviewOfSystemModalVisible: false, index: this.props.currentVisitIndex});
    }

    closeReviewOfSystemModal = () => {
        this.props.subjective.reviewOfSystem = this.copiedReviewOfSystem;
        this.setState({reviewOfSystemModalVisible: false});
    }

    renderReviewOfSystemModal() {
        if (!this.state.reviewOfSystemModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.reviewOfSystemModalVisible}
                   className="ReviewOfSystemModal"
                   submitContent="Save"
                   submitAction={this.saveReviewOfSystemModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeReviewOfSystemModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   contentStyle={{maxHeight: "95%", overflowY: "auto", width: "50vw"}}>
                <ReviewOfSystemModal subjective={this.props.subjective}
                                     appointmentType={this.props.currentVisit.appointmentType}/>
            </Modal>
        );
    }

    openHistoryModal = () => {
        if (this.props.subjective.familyHistory) {
            this.copiedFamilyHistory = cloneDeep(this.props.subjective.familyHistory);
        }
        if (this.props.subjective.medicalHistory) {
            this.copiedMedicalHistory = cloneDeep(this.props.subjective.medicalHistory);
        }
        if (this.props.subjective.surgicalHistory) {
            this.copiedSurgicalHistory = cloneDeep(this.props.subjective.surgicalHistory);
        }
        if (this.props.subjective.birthHistory) {
            this.copiedBirthHistory = cloneDeep(this.props.subjective.birthHistory);
        }
        this.setState({historyModalVisible: true});
    }

    saveHistoryModal = () => {
        this.props.onModalSave();
        this.setState({historyModalVisible: false, index: this.props.currentVisitIndex});
    }

    closeHistoryModal = () => {
        this.props.subjective.familyHistory = this.copiedFamilyHistory;
        this.props.subjective.medicalHistory = this.copiedMedicalHistory;
        this.props.subjective.surgicalHistory = this.copiedSurgicalHistory;
        this.props.subjective.birthHistory = this.copiedBirthHistory;
        this.setState({historyModalVisible: false});
    }

    renderHistoryModal() {
        return (
            <Modal visible={this.state.historyModalVisible}
                   className="HistoryModal"
                   submitContent="Save"
                   submitAction={this.saveHistoryModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeHistoryModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   contentStyle={{maxHeight: "95%", overflowY: "auto", width: "70vw"}}>
                <HistoryModal subjective={this.props.subjective}
                              appointmentType={this.props.currentVisit.appointmentType}/>
            </Modal>
        );
    }

    openTreatmentReceivedModal = () => {
        if (this.props.subjective.treatmentReceived) {
            this.copiedTreatmentReceived = cloneDeep(this.props.subjective.treatmentReceived);
        }
        if (this.props.subjective.activityLimitation) {
            this.copiedActivityLimitation = cloneDeep(this.props.subjective.activityLimitation);
        }
        if (this.props.subjective.otherInjuries) {
            this.copiedOtherInjuries = cloneDeep(this.props.subjective.otherInjuries);
        }
        if (this.props.subjective.labsImaging) {
            this.copiedLabsImaging = cloneDeep(this.props.subjective.labsImaging);
        }
        if (this.props.subjective.otherInjuries == null) {
            this.props.subjective.otherInjuries = {};
            this.props.subjective.otherInjuries.type = [];
            this.props.subjective.otherInjuries.mechanism = [];
        }
        if (this.props.subjective.labsImaging == null) {
            this.props.subjective.labsImaging = {};
            this.props.subjective.labsImaging.reportStatus = [];
        }
        this.setState({treatmentReceivedModalVisible: true});
    }

    saveTreatmentReceivedModal = () => {
        this.props.onModalSave();
        this.setState({treatmentReceivedModalVisible: false, index: this.props.currentVisitIndex});
    }

    closeTreatmentReceivedModal = () => {
        this.props.subjective.treatmentReceived = this.copiedTreatmentReceived;
        this.props.subjective.activityLimitation = this.copiedActivityLimitation;
        this.props.subjective.otherInjuries = this.copiedOtherInjuries;
        this.props.subjective.labsImaging = this.copiedLabsImaging;
        this.setState({treatmentReceivedModalVisible: false});
    }

    renderTreatmentReceivedModal() {
        if (!this.state.treatmentReceivedModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.treatmentReceivedModalVisible}
                   className="TreatmentReceivedModal"
                   submitContent="Save"
                   submitAction={this.saveTreatmentReceivedModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeTreatmentReceivedModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   contentStyle={{maxHeight: "95%", overflowY: "auto", width: "70vw"}}>
                <TreatmentReceivedModal subjective={this.props.subjective}
                                        appointmentType={this.props.currentVisit.appointmentType}/>
            </Modal>
        );
    }

}

function mapStateToProps(state) {
    return {
        prescriptions: state.base.prescriptions
    };
}

export default connect(mapStateToProps)(Subjective);
