import React, {Component} from "react";
import TypeEnum from "../../assessment/enums/dermatomes/TypeEnum";
import DermatomesScreenUtil from "../../assessment/utils/DermatomesScreenUtil";
import MyotomesCervicalEnum from "../../assessment/enums/dermatomes/MyotomesCervicalEnum";
import MyotomesThoracicEnum from "../../assessment/enums/dermatomes/MyotomesThoracicEnum";
import MyotomesLumbarEnum from "../../assessment/enums/dermatomes/MyotomesLumbarEnum";
import MyotomesSacralEnum from "../../assessment/enums/dermatomes/MyotomesSacralEnum";
import MyotomesDropdownValues from "../../assessment/enums/dermatomes/MyotomesDropdownValues";
import DermatomesScreenRowColorsEnum from "../../assessment/enums/dermatomes/DermatomesScreenRowColorsEnum";
import DermatomesUtil from "../../assessment/utils/DermatomesUtil";

export default class MyotomesEMRDashboardView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            myotomes: this.splitMyotomes(props)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.myotomes !== this.props.myotomes) {
            const myotomes = this.splitMyotomes(this.props);
            this.setState({myotomes});
        }
    }

    splitMyotomes = (props) => {
        let myotomes = {};

        if (!props.myotomes || !props.myotomes.items) {
            return {...props.myotomes};
        }

        let cervical = props.myotomes.items.filter(item => item.type === TypeEnum.CERVICAL);
        let thoracic = props.myotomes.items.filter(item => item.type === TypeEnum.THORACIC);
        let lumbar = props.myotomes.items.filter(item => item.type === TypeEnum.LUMBAR);
        let sacral = props.myotomes.items.filter(item => item.type === TypeEnum.SACRAL);

        Object.assign(myotomes, {cervical, thoracic, lumbar, sacral});

        return {...myotomes};
    }

    render() {
        return (
            <div>
                <div className={"content-row"} style={{display: "block"}}>
                    {DermatomesScreenUtil.DermatomesViewTableHeader(
                        "Myotomes",
                        DermatomesUtil.isMyotomesEntered(this.state.myotomes),
                        {},
                        this.state.myotomes.none ? "Normal" : null
                    )}
                    {this.state.myotomes && <table className={"emr-table content-card"}>
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.myotomes.cervical, "Cervical", MyotomesCervicalEnum, MyotomesDropdownValues, DermatomesScreenRowColorsEnum.DERMATOMESCERVICAL.color)}
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.myotomes.thoracic, "Thoracic", MyotomesThoracicEnum, MyotomesDropdownValues, DermatomesScreenRowColorsEnum.DERMATOMESTHORACIC.color)}
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.myotomes.lumbar, "Lumbar", MyotomesLumbarEnum, MyotomesDropdownValues, DermatomesScreenRowColorsEnum.DERMATOMESLUMBAR.color)}
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.myotomes.sacral, "Sacral", MyotomesSacralEnum, MyotomesDropdownValues, DermatomesScreenRowColorsEnum.DERMATOMESSACRAL.color)}
                    </table>}
                </div>
            </div>
        );
    }
}
