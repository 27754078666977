import React, {Component} from "react";
import "../../VisitHistory.css"
import ObservationFindingEnum from "../../../assessment/enums/observation/ObservationFindingEnum";
import SeverityEnum from "../../../assessment/enums/observation/SeverityEnum";

export default class ObservationViewHistory extends Component {

    render() {
        if (!this.props.observation || !this.props.observation.items || this.props.observation.items.length === 0) {
            return <div className="pl-3">
                {this.props.observation && this.props.observation.none ? "Normal" : "No information available"}
            </div>;
        }

        return(
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Finding</th>
                    <th>Severity</th>
                    <th>Location</th>
                    <th>Details</th>
                </tr>
                {this.props.observation.items.map((item,index) =>
                    {
                        return (
                            <tr key={"specialTestsModal" + index} style={{backgroundColor: "white"}}>
                                <td>  {item.finding == null? "" : ObservationFindingEnum[item.finding].name}</td>
                                <td>  {item.severity == null? "": SeverityEnum[item.severity].name}</td>
                                <td>  {item.location}</td>
                                <td>  {item.details}</td>
                            </tr> );
                    }
                    )}
                </tbody>
            </table>
        );
    }
}