import React, {Component} from "react";
import '../../EMR.css';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TextInput from "../../../../../components/text-input/TextInput";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GreenSwitchWithMessage from "../../../../../components/switch/GreenSwitchWithMessage";
import CheckboxInput from "../../../../../components/checkbox-input/CheckboxInput";
import {ReviewOfSystemChecker} from "../checkers/ReviewOfSystemChecker";
import ExpansionPanel from "../../../../../components/expansion-panel/CustomExpansionPanel";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";

export default class ReviewOfSystemModal extends Component {

    state = {}

    render() {
        let toggleOn = ReviewOfSystemChecker.isToggleOn(this.props.subjective.reviewOfSystem);
        let isNone = ReviewOfSystemChecker.isNone(this.props.subjective.reviewOfSystem);

        const expansionPanels = [
            {title: 'Cardiovascular / Circulatory', content: this.contentDiv("cardiovascularCirculatory")},
            {title: 'Respiratory', content: this.contentDiv("respiratory")},
            {title: 'Head, Neck, Eyes', content: this.contentDiv("headNeckEyes")},
            {title: 'ENT', content: this.contentDiv("ent")},
            {title: 'Skin, Hair, Nails', content: this.contentDiv("skinHairNails")},
            {title: 'Lymphatic / Immune', content: this.contentDiv("lymphaticImmune")},
            {title: 'Endocrine', content: this.contentDiv("endocrine")},
            {title: 'Gastrointestinal', content: this.contentDiv("gastrointestinal")},
            {title: 'Genitourinary', content: this.contentDiv("genitourinary")},
            {title: 'Musculoskeletal', content: this.contentDiv("musculoskeletal")},
            {title: 'Neurological', content: this.contentDiv("neurological")},
        ]

        return (
            <div className={"emr-modal content-row emr-background"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px"}}>
                    <div style={{display: "flex"}}>
                        <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Review of Systems <RedAsterisk appointmentType={this.props.appointmentType} firstVisit
                                                                                                                                  newComplaint/>
                        </div>
                        <GreenSwitchWithMessage
                            message={isNone ? "All Normal" : ""}
                            checked={toggleOn}
                            onChange={() => {
                                if (toggleOn) {
                                    let id = this.props.subjective.reviewOfSystem.id;
                                    this.props.subjective.reviewOfSystem = {id: id, none: false};
                                    this.forceUpdate();
                                }
                            }}/>
                    </div>

                    <div style={{display: "flex", marginLeft: "5px"}}>
                        <div>
                            <CheckboxInput value={isNone}
                                           onChange={(value) => {
                                               if (this.props.subjective.reviewOfSystem == null) {
                                                   this.props.subjective.reviewOfSystem = {};
                                               }
                                               if (value) {
                                                   let id = this.props.subjective.reviewOfSystem.id;
                                                   this.props.subjective.reviewOfSystem = {id: id};
                                               }
                                               this.props.subjective.reviewOfSystem.none = value;
                                               this.forceUpdate();
                                           }}/>
                        </div>
                        <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>All Normal</div>
                    </div>

                    <br/>
                    <br/>

                    {
                        expansionPanels.map(expansionPanel => this.wrapperPanel(expansionPanel.title, expansionPanel.content))
                    }
                </div>
            </div>
        );
    }

    wrapperPanel = (title, content) => {
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography>{title}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {content}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }

    contentDiv(field) {
        if (this.props.subjective.reviewOfSystem == null) {
            return (<div>All Normal</div>);
        }

        if (this.props.subjective.reviewOfSystem[field] == null) {
            this.props.subjective.reviewOfSystem[field] = [];
        }

        if (this.state[field] == null) {
            this.setState({[field]: ""});
        }

        const list = this.props.subjective.reviewOfSystem[field];
        let divs = this.getDivs(list, field);
        return (
            <div style={{width: "100%"}}>
                <div className={"row no-margin"}>
                    <div className={"col-11"} style={{padding: "5px 0px"}}>
                        <TextInput value={this.state[field]}
                                   placeholder={"Details"}
                                   onChange={value => this.setState((state) => {
                                       state[field] = value;
                                       return state;
                                   })}
                                   style={{fontSize: "14px", width: "100%"}}
                        />
                    </div>
                    <div className={"col-1"} style={{flexFlow: "row-reverse", display: "flex", padding: "5px 0px"}}>
                        <Button className={"emr-plus-button"}
                                variant="success"
                                disabled={!(this.state[field] != "")}
                                style={{fontSize: "12px"}}
                                onClick={() => {
                                    list.push(this.state[field]);
                                    this.setState((state) => {
                                        state[field] = "";
                                        return state;
                                    })
                                }}>
                            <FontAwesomeIcon icon={["fas", "plus"]}/>
                        </Button>
                    </div>
                </div>
                <div className={"row no-margin"}>
                    <div className={"div-list-wrapper"}>
                        {divs}
                    </div>
                </div>
            </div>
        );
    }

    getDivs(list, field) {
        let divs = [];
        if (list == null || list.length == 0) {
            return null;
        }

        list.forEach((d, index) => {
            divs.push(
                <div className={"div-list-item"} key={field + index} style={{backgroundColor: "rgb(250,250,250)"}}>
                    <div>
                        {d}
                    </div>
                    <Button className={"emr-transparent-delete-button"}
                            variant="warning"
                            style={{fontSize: "10px", marginLeft: "2px"}}
                            onClick={() => {
                                this.props.subjective.reviewOfSystem[field].splice(index, 1);
                                this.forceUpdate();
                            }}>
                        <FontAwesomeIcon icon={["fas", "times"]}/>
                    </Button>
                </div>
            );
        })

        return divs;
    }
}