import React, {Component} from "react";
import ReportSection from "../ReportSection";
import ReportViewUtils from "./ReportViewUtils";
import TreatmentReceivedEnum from "../../treatment/enums/treatmentrecieved/TreatmentReceivedEnum";
import DateUtil from "../../../../../utils/DateUtil";

export default class TreatmentReceivedReportView extends Component {

    render() {
        if (ReportViewUtils.isTreatmentInfoNull(this.props.treatmentInfo)) {
            return null;
        }

        const tables = [
            this.treatmentInfoTypeATable(TreatmentReceivedEnum.MANIPULATION),
            this.treatmentInfoTypeATable(TreatmentReceivedEnum.MOBILIZATION),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.STRETCHING),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.DRY_NEEDLING),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.ACUPUNCTURE),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.ELECTROTHERAPY),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.SHOCKWAVE),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.CUPPING),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.TAPING),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.THERAPEUTIC_MASSAGE),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.LYMPHATIC),
            this.treatmentInfoTypeATable(TreatmentReceivedEnum.THERMAL),
            this.treatmentInfoTypeCTable(TreatmentReceivedEnum.THERAPEUTIC_EXERCISE),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.GAIT),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.BREATHING),
            this.treatmentInfoTypeATable(TreatmentReceivedEnum.PROCEDURES),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.OTHER)
        ].filter(table => !!table);

        return (
            <div className="container" style={{maxWidth: '100%', marginLeft: '-5px'}}>
                <div className="row">
                    {
                        tables.map((table) => {
                                return <>
                                    <div className={"col-12"} style={{padding: "0px"}}>
                                        {table}
                                    </div>
                                </>;
                            }
                        )
                    }
                </div>
            </div>
        )
    }

    renderTreatmentReceivedCptList(treatmentType) {
        return this.props.procedureList?.filter(cpt => cpt.treatments?.includes(treatmentType.key)).map((procedure) =>
            <>
                <div className="row no-margin w-100">
                    <div className="col-8"><span className="font-weight-bold">Cpt Code:</span>
                        &nbsp;{procedure.cpt.cptCode} - {procedure.cpt.description}
                    </div>
                    <div className="col-2"><span className="font-weight-bold">Start:</span>
                        &nbsp;{DateUtil.getHourMinute24H(procedure.startTime)}
                    </div>
                    <div className="col-2"><span className="font-weight-bold">End:</span>
                        &nbsp;{DateUtil.getHourMinute24H(procedure.endTime)}
                    </div>
                </div>
            </>
        )
    }

    treatmentInfoTypeATable = (treatmentType) => {
        const field = treatmentType.field
        const dropdownEnum = treatmentType.enum
        const fieldVal = this.props.treatmentInfo && this.props.treatmentInfo[field];

        if (fieldVal == null || fieldVal.length === 0) {
            return null;
        }

        return <ReportSection header1={treatmentType.name}>
            <div>
                <table className={"visit-history-table"}>
                    <tbody>
                    <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                        <th>Type</th>
                        <th>Area of Focus</th>
                        <th>Details</th>
                    </tr>
                    {
                        fieldVal.map((item, index) => {
                                return (
                                    <tr key={field + index} style={{backgroundColor: "white"}}>
                                        <td>{dropdownEnum[item.type] ? dropdownEnum[item.type].name : item.type}</td>
                                        <td>{item.areaOfFocus}</td>
                                        <td>{item.details}</td>
                                    </tr>);
                            }
                        )
                    }
                    </tbody>
                </table>
                {this.renderTreatmentReceivedCptList(treatmentType)}
            </div>
        </ReportSection>
    }

    treatmentInfoTypeBTable = (treatmentType) => {
        const field = treatmentType.field
        const fieldVal = this.props.treatmentInfo && this.props.treatmentInfo[field];

        if (fieldVal == null || fieldVal.length === 0) {
            return null;
        }

        return <ReportSection header1={treatmentType.name}>
            <div>
                <table className={"visit-history-table"}>
                    <tbody>
                    <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                        <th>Area of Focus</th>
                        <th>Details</th>
                    </tr>
                    {
                        fieldVal.map((item, index) => {
                                return (
                                    <tr key={field + index} style={{backgroundColor: "white"}}>
                                        <td>{item.areaOfFocus}</td>
                                        <td>{item.details}</td>
                                    </tr>);
                            }
                        )
                    }
                    </tbody>
                </table>
                {this.renderTreatmentReceivedCptList(treatmentType)}
            </div>
        </ReportSection>
    }

    treatmentInfoTypeCTable = (treatmentType) => {
        const field = treatmentType.field
        const dropdownEnum = treatmentType.enum
        const fieldVal = this.props.treatmentInfo && this.props.treatmentInfo[field];

        if (fieldVal == null || fieldVal.length === 0) {
            return null;
        }

        return <ReportSection header1={treatmentType.name}>
            <div>
                <table className={"visit-history-table"}>
                    <tbody>
                    <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                        <th>Focus</th>
                        <th>Exercise</th>
                        <th>Weight</th>
                        <th>Sets</th>
                        <th>Reps</th>
                        <th>Details</th>
                    </tr>
                    {
                        fieldVal.map((item, index) => {
                                return (
                                    <tr key={field + index} style={{backgroundColor: "white"}}>
                                        <td>{dropdownEnum[item.focus]?.name}</td>
                                        <td>{item.exercise}</td>
                                        <td>{item.weight}</td>
                                        <td>{item.sets}</td>
                                        <td>{item.reps}</td>
                                        <td>{item.details}</td>
                                    </tr>);
                            }
                        )
                    }
                    </tbody>
                </table>
                {this.renderTreatmentReceivedCptList(treatmentType)}
            </div>
        </ReportSection>
    }

}
