import React, {Component} from "react";
import '../../EMR.css';
import DateUtil from "../../../../../utils/DateUtil";
import ReasonForDiscontinuationEnum from "../enums/dischargeplan/ReasonForDiscontinuationEnum";
import ReferralSpecialityEnum from "../enums/dischargeplan/ReferralSpecialityEnum";

export default class DischargePlanView extends Component {

    render() {
        if (this.props.dischargePlan == null)
            return null;

        return (
            <div>
                <div className="content-card pl-2 mb-3">
                    {this.props.dischargePlan.anticipatedDischargeDate && <>
                        <div className={"row no-margin"}>
                            <div className={"col-12 font-weight-bold"}
                                 style={{paddingBottom: "0px", marginBottom: "-5px"}}>Discharge Readiness
                            </div>
                        </div>
                        <div className={"row no-margin"}>
                            <div className={"col-3 font-weight-bold"}>Anticipated Discharge Date</div>
                            <div className={"col-1 font-weight-bold"}>:</div>
                            <div className={"col-8"}>
                                {DateUtil.formatDate(this.props.dischargePlan.anticipatedDischargeDate)}{this.props.dischargePlan.anticipatedDischargeDetails ? ", " + this.props.dischargePlan.anticipatedDischargeDetails : null}
                            </div>
                        </div>
                    </>}

                    {this.props.dischargePlan.dateOfDischarge && <>
                        <div className={"row no-margin"}>
                            <div className={"col-12 font-weight-bold"}
                                 style={{paddingBottom: "0px", marginBottom: "-5px"}}>Discharge Plan
                            </div>
                        </div>
                        <div className={"row no-margin"}>
                            <div className={"col-3 font-weight-bold"}>Date of Discharge</div>
                            <div className={"col-1 font-weight-bold"}>:</div>
                            <div className={"col-8"}>
                                {DateUtil.formatDate(this.props.dischargePlan.dateOfDischarge)}{this.props.dischargePlan.dischargePlanDetails ? ", " + this.props.dischargePlan.dischargePlanDetails : null}
                            </div>
                        </div>
                    </>}

                    {this.props.dischargePlan.dateOfDiscontinuation && <>
                        <div className={"row no-margin"}>
                            <div className={"col-12 font-weight-bold"}
                                 style={{paddingBottom: "0px", marginBottom: "-5px"}}>Discontinuation Plan
                            </div>
                        </div>
                        <div className={"row no-margin"}>
                            <div className={"col-3 font-weight-bold"}>Date of Discontinuation
                            </div>
                            <div className={"col-1 font-weight-bold"}>:</div>
                            <div className={"col-8"}>
                                {DateUtil.formatDate(this.props.dischargePlan.dateOfDiscontinuation)}{this.props.dischargePlan.discontinuationPlanDetails ? ", " + this.props.dischargePlan.discontinuationPlanDetails : null}
                            </div>
                        </div>
                    </>}

                    {this.props.dischargePlan.reasonForDiscontinuation && <div className={"row no-margin"}>
                        <div className={"col-3 font-weight-bold"}>Reason for Discontinuation</div>
                        <div className={"col-1 font-weight-bold"}>:</div>
                        <div className={"col-8"}>
                            {ReasonForDiscontinuationEnum[this.props.dischargePlan.reasonForDiscontinuation].name}
                        </div>
                    </div>}

                    {this.props.dischargePlan.goalAchieved && <div className={"row no-margin"}>
                        <div className={"col-3 font-weight-bold"}>Treatment Goal % Achieved</div>
                        <div className={"col-1 font-weight-bold"}>:</div>
                        <div className={"col-8"}>
                            {this.props.dischargePlan.goalAchieved}%{this.props.dischargePlan.goalAchievedDetails ? ", " + this.props.dischargePlan.goalAchievedDetails : null}
                        </div>
                    </div>}

                    {this.props.dischargePlan.reasonForGoalNotAchieved && <div className={"row no-margin"}>
                        <div className={"col-3 font-weight-bold"}>Reason for goal not achieved</div>
                        <div className={"col-1 font-weight-bold"}>:</div>
                        <div className={"col-8"}>
                            {this.props.dischargePlan.reasonForGoalNotAchieved}
                        </div>
                    </div>}
                </div>

                {this.props.dischargePlan.homeProgram && this.props.dischargePlan.homeProgram != null && this.props.dischargePlan.homeProgram.length != 0 &&
                <div className={"content-card content-row treatment-received-card"}>
                    <div className={"col-4 content-card-title"}>
                        <h6 className={"no-margin"}>Home Program</h6>
                    </div>
                    <div className={"col-8"}>
                        <div style={{borderLeft: "1px gainsboro solid", paddingLeft: "10px"}}>
                            <div>
                                <div className={"row no-margin"}>
                                    <div className={"col-3 font-weight-bold"}>Home Program</div>
                                    <div className={"col-1"}>:</div>
                                    <div
                                        className={"col-8"}>{this.props.dischargePlan.homeProgram.map(item => item.details).join(", ")}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

                {this.props.dischargePlan.referrals && this.props.dischargePlan.referrals != null && this.props.dischargePlan.referrals.length != 0 &&
                <div className={"content-card content-row treatment-received-card"}>
                    <div className={"col-4 content-card-title"}>
                        <h6 className={"no-margin"}>Referrals</h6>
                    </div>
                    <div className={"col-8"}>
                        <div style={{borderLeft: "1px gainsboro solid", paddingLeft: "10px"}}>
                            {this.props.dischargePlan.referrals.map((item, index) => {
                                let paddingBetweenItems = this.props.dischargePlan.referrals.length != 1 &&
                                index != this.props.dischargePlan.referrals.length - 1 ? {paddingBottom: "10px"} : {};
                                return (
                                    <div style={{...paddingBetweenItems}}>
                                        <div className={"row no-margin"}>
                                            <div className={"col-3 font-weight-bold"}>Speciality</div>
                                            <div className={"col-1"}>:</div>
                                            <div
                                                className={"col-8"}>{ReferralSpecialityEnum[item.referralSpeciality].name}</div>
                                        </div>
                                        {(item.referralName != null && item.referralName != 0) &&
                                        <div className={"row no-margin"}>
                                            <div className={"col-3 font-weight-bold"}>Name</div>
                                            <div className={"col-1"}>:</div>
                                            <div className={"col-8"}>{item.referralName}</div>
                                        </div>}
                                        {(item.referralRecommendation != null && item.referralRecommendation != 0) &&
                                        <div className={"row no-margin"}>
                                            <div className={"col-3 font-weight-bold"}>Recommendation</div>
                                            <div className={"col-1"}>:</div>
                                            <div className={"col-8"}>{item.referralRecommendation}</div>
                                        </div>}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>}

                {this.props.dischargePlan.recommendations && this.props.dischargePlan.recommendations != null && this.props.dischargePlan.recommendations.length != 0 &&
                <div className={"content-card content-row treatment-received-card"}>
                    <div className={"col-4 content-card-title"}>
                        <h6 className={"no-margin"}>Recommendations</h6>
                    </div>
                    <div className={"col-8"}>
                        <div style={{borderLeft: "1px gainsboro solid", paddingLeft: "10px"}}>
                            <div>
                                <div className={"row no-margin"}>
                                    <div className={"col-3 font-weight-bold"}>Recommendations</div>
                                    <div className={"col-1"}>:</div>
                                    <div
                                        className={"col-8"}>{this.props.dischargePlan.recommendations.map(item => item.details).join(", ")}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}

            </div>
        );
    }
}