import React, {Component} from "react";
import {Column} from "primereact/column";
import QueryPanel from "../../../../components/query/QueryPanel";
import ResultTable from "../../../../components/query/ResultTable";
import CriteriaPanel from "../../../../components/query/CriteriaPanel";
import {DateInput, NumberInput, SelectInput, TextInput} from "../../../../components";
import DateUtil from "../../../../utils/DateUtil";
import ClaimStatus from "../claimList/ClaimStatus";
import dialog from "../../../../components/dialog/Dialog";
import ClaimRemittanceDetail from "./ClaimRemittanceDetail";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import {Dropdown} from "primereact/dropdown";

const initialQueryOptions = {
    id: "",
    fileName: "",
    status: null,
    senderId: null,
    startDate: DateUtil.startOfDay(),
    endDate: DateUtil.endOfDay()
};

const noTpaOption = {id: null, name: "Not Applicable"};

export default class ClaimRemittance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tpas: [],
            selectedTpa: null,
            insuranceCompanies: [],
            selectedInsuranceCompany: null,
            queryOptions: {
                ...initialQueryOptions
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!prevProps.selected && this.props.selected) {
            this.claimRemittanceQueryPanel.query();
        }
    }

    componentDidMount() {
        RemotingService.getRemoteCall('api/insurance/tpa/list', null, result => {
            result.unshift(noTpaOption);
            this.setState({tpas: result});
        });
    }

    handleTpaChanged(tpa) {
        if (tpa) {
            RemotingService.getRemoteCall('api/insurance/tpa/company', {tpaId: tpa.id}, result => {
                this.setState((state) => {
                    let newState = {...state};
                    newState.selectedTpa = tpa;
                    newState.selectedInsuranceCompany = null;
                    newState.insuranceCompanies = result;
                    newState.queryOptions.senderId = tpa.eclaimlinkId;
                    return newState;
                });
            });
        } else {
            this.setState((state) => {
                let newState = {...state};
                newState.selectedTpa = null;
                newState.selectedInsuranceCompany = null;
                newState.insuranceCompanies = null;
                newState.queryOptions.senderId = null;
                return newState;
            });
        }
    }

    handleInsuranceCompanyChanged(insuranceCompany) {
        if (insuranceCompany) {
            this.setState((state) => {
                let newState = {...state};
                newState.selectedInsuranceCompany = insuranceCompany;
                newState.queryOptions.senderId = insuranceCompany.eclaimlinkId;
                return newState;
            });
        } else {
            this.setState((state) => {
                let newState = {...state};
                newState.selectedInsuranceCompany = null;
                newState.queryOptions.senderId = this.state.selectedTpa?.eclaimlinkId;
                return newState;
            });
        }
    }

    openRemittanceDetail(claimRemittance) {
        dialog({
            title: "Remittance Detail",
            component: <ClaimRemittanceDetail claimRemittanceId={claimRemittance.id}/>
        });
    }

    clearQueryOptions = () => {
        this.setState({
            selectedTpa: null,
            insuranceCompanies: [],
            selectedInsuranceCompany: null,
            queryOptions: {...initialQueryOptions}
        });
    }

    idTemplate(claimRemittance) {
        return <a href="#" onClick={() => this.openRemittanceDetail(claimRemittance)}>{claimRemittance.id}</a>
    }

    dateTimeTemplate(dateStr) {
        return DateUtil.formatDateTime12H(dateStr);
    }

    statusTemplate(claimRemittance) {
        return ClaimStatus[claimRemittance.status]?.name;
    }

    render() {
        return (
            <div>
                <QueryPanel ref={el => this.claimRemittanceQueryPanel = el}
                            url="api/insurance/coordinator/claim-remittance/list"
                            queryOptions={this.state.queryOptions}
                            onClear={this.clearQueryOptions}>
                    <CriteriaPanel>
                        <div className="container text-left w-100 ml-0" style={{maxWidth: "inherit"}}>
                            <div className="row">
                                <div className="col-1">Date</div>
                                <div className="col-3">
                                    <DateInput className="w-50" utcTime={false}
                                               maxDate={this.state.queryOptions.endDate}
                                               value={this.state.queryOptions.startDate}
                                               onChange={date =>
                                                   this.setState((state) => {
                                                       let newState = {...state};
                                                       newState.queryOptions.startDate = DateUtil.startOfDay(date);
                                                       return newState;
                                                   })
                                               }/>
                                    <DateInput className="w-50" utcTime={false}
                                               minDate={this.state.queryOptions.startDate}
                                               value={this.state.queryOptions.endDate}
                                               onChange={date =>
                                                   this.setState((state) => {
                                                       let newState = {...state};
                                                       newState.queryOptions.endDate = DateUtil.endOfDay(date);
                                                       return newState;
                                                   })
                                               }/>
                                </div>
                                <div className="col-1">Insurance Company</div>
                                <div className="col-3">
                                    <Dropdown className="w-100" showClear
                                              optionLabel="name"
                                              options={this.state.tpas}
                                              value={this.state.selectedTpa}
                                              onChange={e => this.handleTpaChanged(e.value)}/>
                                </div>
                                <div className="col-1">TPA</div>
                                <div className="col-3">
                                    <Dropdown className="w-100" showClear
                                              optionLabel="name"
                                              options={this.state.insuranceCompanies}
                                              value={this.state.selectedInsuranceCompany}
                                              onChange={e => this.handleInsuranceCompanyChanged(e.value)}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-1">Remittance ID</div>
                                <div className="col-3">
                                    <NumberInput className="w-100"
                                                 value={this.state.queryOptions.id}
                                                 onChange={value =>
                                                     this.setState((state) => {
                                                         let newState = {...state};
                                                         newState.queryOptions.id = value;
                                                         return newState;
                                                     })
                                                 }/>
                                </div>
                                <div className="col-1">File Name</div>
                                <div className="col-3">
                                    <TextInput className="w-100"
                                               value={this.state.queryOptions.fileName}
                                               onChange={value =>
                                                   this.setState((state) => {
                                                       let newState = {...state};
                                                       newState.queryOptions.fileName = value;
                                                       return newState;
                                                   })
                                               }/>
                                </div>
                                <div className="col-1">Status</div>
                                <div className="col-3">
                                    <SelectInput className="w-100" options={ClaimStatus.remittanceStatus}
                                                 displayProperty="name" valueProperty="key" searchEnabled={false}
                                                 value={this.state.queryOptions.status}
                                                 onChange={value =>
                                                     this.setState((state) => {
                                                         let newState = {...state};
                                                         newState.queryOptions.status = value;
                                                         return newState;
                                                     })
                                                 }/>
                                </div>
                            </div>
                        </div>
                    </CriteriaPanel>
                    <ResultTable scrollable responsive>
                        <Column field="id" header="Rem No" body={claimRemittance => this.idTemplate(claimRemittance)}/>
                        <Column field="senderId" header="Sender ID"/>
                        <Column field="fileName" header="File Name"/>
                        <Column field="claimAmount" header="Claim Amount"/>
                        <Column field="paidAmount" header="Paid Amount"/>
                        <Column field="rejectedAmount" header="Rejected Amount"/>
                        <Column field="claimCount" header="No of Claims"/>
                        <Column field="transactionDate" header="Transaction Date" body={claimRemittance => this.dateTimeTemplate(claimRemittance.transactionDate)}/>
                        <Column field="fileUploadDate" header="File Upload Date" body={claimRemittance => this.dateTimeTemplate(claimRemittance.fileUploadDate)}/>
                        <Column field="denialCode" header="Denial Code"/>
                        <Column field="status" header="Status" body={claimRemittance => this.statusTemplate(claimRemittance)}/>
                    </ResultTable>
                </QueryPanel>
            </div>
        )
    }

}