import Enum from "../../../../../../enums/Enum";

const FurtherAssessmentEnum = new Enum({
        FUNCTIONAL_REACH_TEST: {name: "Functional Reach Test"},
        TIMED_UP_AND_GO_TEST: {name: "Timed Up and Go Test"},
        TIMED_CHAIR_STAND_TEST: {name: "Timed Chair Stand (30 sec) Test"},
        TINNETI_SCALE_OR_BERG_BALANCE_SCALE_TEST: {name:"Tinneti Scale or Berg Balance Scale Test"},
        OTHERS: {name: "Others"}

});

export default FurtherAssessmentEnum;