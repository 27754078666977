import {GenericChecker} from "../../subjective/GenericChecker";
import StringUtil from "../../../../../utils/StringUtil";

export class NotEmptyChecker extends GenericChecker {
    static isDataEntered(value) {
        if (typeof value == "string")
            return !StringUtil.isNullOrEmpty(value)
        else if (Array.isArray(value))
            return value != null && value.length !== 0
        else
            return value != null
    }
}
