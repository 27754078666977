import React from "react";
import RemotingService from "../../../../../services/remoting-service/RemotingService";
import {downloadBlob} from "../../../../../utils/FileDownloader";
import dialog from "../../../../../components/dialog/Dialog";
import ReduxService from "../../../../../services/redux-service/ReduxService";
import {noop} from "lodash-es";

const download = (file) => {
    RemotingService.instance.get('api/file/download/' + file.uuid, {responseType: 'blob'})
        .then((response) => {
            downloadBlob(new Blob([response.data]), file.name);
        });
}

const downloadAsBlob = (uuid, successCallback = noop) => {
    ReduxService.incrementRemotingOperationCount();

    RemotingService.instance.get(`api/file/download/${uuid}`, {responseType: 'blob'})
        .then((response) => {
            const blob = new Blob([response.data], {type: response.headers['content-type']});
            successCallback(blob);
        })
        .finally(() => {
            ReduxService.decrementRemotingOperationCount();
        });
}

function canView(fileName, allowedFileExtensions = getDefaultFileExtensions()) {
    return allowedFileExtensions == null || allowedFileExtensions.some(extension => fileName.endsWith("." + extension));
}

function getDefaultFileExtensions() {
    return ["png", "jpg", "jpeg", "pdf"]
}

function viewFile({
                      fileName, blob, id = "documentViewer", windowWidth = "800px", windowHeight = "600px",
                      allowedFileExtensions = getDefaultFileExtensions()
                  }) {
    const blobUrl = window.URL.createObjectURL(blob);
    if (canView(fileName, allowedFileExtensions)) {
        dialog({
            title: fileName,
            component: <>
                {
                    isImage(fileName) ?
                        <img src={blobUrl} alt="" style={{maxWidth: 900}}/>
                        : <iframe title={id} id={id} src={blobUrl} width={windowWidth} height={windowHeight}></iframe>
                }
            </>
        });
    }
}

const isImage = (fileNameLowercase) => {
    return fileNameLowercase.endsWith('.jpg')
        || fileNameLowercase.endsWith('.jpeg')
        || fileNameLowercase.endsWith('.png');
}

export default {download, downloadAsBlob, viewFile, canView};
