import React from "react";
import RemotingService from "../../services/remoting-service/RemotingService";
import {FormBase, FormField, FormSection} from "../../components/form";
import {NumberInput, SelectInput, TextInput} from "../../components";
import EnumTemperature from "./enums/EnumTemperature";
import Modal from "../../components/modal/Modal";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import ObjectUtil from "../../utils/ObjectUtil";

class EditRoom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            clinic:{},
            room: ObjectUtil.clone(props.room)
        };
    }

    fieldChanged = (fieldName, value) => {
        const {room} = this.state;
        room[fieldName] = value;
        this.setState({room});
    };

    submit = () => {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        let dto = {
            ...this.state.room,
            clinic: this.state.clinic
        }

        RemotingService.postRemoteCall('api/room/' + this.state.room.id + '/update', dto, (result) => {
            this.props.hideModal();
            this.props.onRoomEdited(result);
        });
    };

    componentDidMount() {
        const clinic = this.props.clinicList.find(clinic => clinic.name === this.state.room.clinicName)
        this.setState({clinic : clinic})
    }

    render() {
        const room = this.state.room;
        this.formFields = [];

        return (
            <>
                <Modal visible={true}
                       header="Update Room"
                       submitContent="Update"
                       submitAction={this.submit}
                       closeAction={this.props.hideModal}>
                    <div className={"dim14-dialog-body"}>
                        <FormBase>
                            <FormSection sectionId={1} labelPercent={5} inputPercent={7} label="Name">
                                <FormField ref={formField => this.formFields.push(formField)}
                                           required validateOn={room.name}>
                                    <TextInput value={room.name}
                                               onChange={(value) => this.fieldChanged('name', value)}/>
                                </FormField>
                            </FormSection>

                            <FormSection sectionId={1} labelPercent={5} inputPercent={7} label="Clinic">
                                <FormField ref={formField => this.formFields.push(formField)}
                                           required validateOn={this.state.clinic ? this.state.clinic.name : ""}>
                                    <SelectInput value={this.state.clinic}
                                                 options={this.props.clinicList} showClear={false}
                                                 displayProperty="name" searchEnabled={false}
                                                 onChange={(val) => {
                                                     this.setState({clinic: val})
                                                 }}
                                    />
                                </FormField>
                            </FormSection>

                            <FormSection sectionId={1} labelPercent={5} inputPercent={7} label="Temperature">
                                <FormField ref={formField => this.formFields.push(formField)}
                                           required validateOn={room.temperature}>
                                    <SelectInput value={room.temperature}
                                                 options={EnumTemperature.toArray()} showClear={false}
                                                 searchEnabled={false}
                                                 displayProperty="name" valueProperty="key"
                                                 onChange={(value) => this.fieldChanged('temperature', value)}
                                    />
                                </FormField>
                            </FormSection>

                            <FormSection sectionId={1} labelPercent={5} inputPercent={7} label="Specs">
                                <TextInput value={room.specs}
                                           onChange={(value) => this.fieldChanged('specs', value)}/>
                            </FormSection>

                            <FormSection sectionId={1} labelPercent={5} inputPercent={7} label="Provider Capacity">
                                <FormField ref={formField => this.formFields.push(formField)}
                                           required validateOn={room.providerCapacity}>
                                    <NumberInput value={room.providerCapacity} min={1} max={5}
                                                 onChange={(value) => this.fieldChanged('providerCapacity', value)}/>
                                </FormField>
                            </FormSection>
                        </FormBase>
                    </div>
                </Modal>
            </>
        );
    }

}

export default EditRoom;
