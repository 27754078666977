import React from 'react';
import {RadioInput} from "../../../components";
import IntakeFormYesNoEnum from "../intakeform/enum/IntakeFormYesNoEnum";

export class YesNoRadioInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    static defaultProps = {
        enumLabel: "name",
    };

    render() {
        const {enumLabel} = this.props;

        return <RadioInput
            id={"relatedRadioInput" + Math.floor(Math.random() * 10000)}
            value={this.props.value}
            disabled={this.props.readOnly}
            options={IntakeFormYesNoEnum.toArray()}
            displayProperty={enumLabel}
            valueProperty={"key"}
            onChange={(value) => this.props.onValueChange(value)}/>
    }

}
