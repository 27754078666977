import Enum from "../../../../../../enums/Enum";

const CharacterEnum = new Enum({
        ACHING: {name: "Aching"},
        DULL: {name: "Dull"},
        SHARP: {name: "Sharp"},
        SHOOTING: {name: "Shooting"},
        BURNING: {name: "Burning"},
        THROBBING: {name: "Throbbing"},
        STIFFNESS: {name: "Stiffness"},
        NUMBNESS: {name: "Numbness"},
        TIGHTNESS: {name: "Tightness"},
        PINSNEEDLES: {name: "Pins & Needles"},
        OTHER: {name: "Other"}
});

export default CharacterEnum;