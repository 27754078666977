import React, {Component} from "react";
import FormBase from "../../components/form/FormBase";
import FormSection from "../../components/form/FormSection";
import {Modal, NumberInput, SelectInput, TextInput} from "../../components";
import EnumTemperature from "./enums/EnumTemperature";
import RemotingService from "../../services/remoting-service/RemotingService";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import {FormField} from "../../components/form";

class AddRoom extends Component {

    constructor(props) {
        super(props);
        this.state = {
            newRoomName: "",
            newClinic: {},
            newTemperature: "",
            newSpecs: "",
            newPreferredBy: []
        };
    }

    addRoom = () => {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        let dto = {
            name: this.state.newRoomName,
            specs: this.state.newSpecs,
            temperature: this.state.newTemperature,
            clinic: this.state.newClinic,
            providerCapacity: this.state.newProviderCapacity,
            preferences: this.state.newPreferredBy
        }

        RemotingService.postRemoteCall('api/room/add', dto, (result) => {
            this.props.onRoomAdded(result);
        });
    }

    handleClose = () => {
        this.props.closeAction();
        this.setState({
            newRoomName: "",
            newClinic: {},
            newProviderCapacity: "",
            newTemperature: "",
            newSpecs: "",
            newPreferredBy: []
        });
    }

    render() {
        this.formFields = [];

        return this.props.visible ? (<Modal visible="true"
                                            header="Add Room"
                                            submitContent="Add"
                                            submitAction={this.addRoom}
                                            closeAction={this.handleClose}>
            {this.addRoomModalBody()}
        </Modal>) : null;
    }

    addRoomModalBody = () => {
        return (
            <div id="add-room" className={"dim14-dialog-body"}>
                <FormBase>
                    <FormSection sectionId={1} labelPercent={5} inputPercent={7} label="Name">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.newRoomName}>
                            <TextInput className={"form-control"} value={this.state.newRoomName}
                                       onChange={(value) => this.setState({newRoomName: value})}/>
                        </FormField>
                    </FormSection>
                    <FormSection sectionId={1} labelPercent={5} inputPercent={7} label="Clinic">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.newClinic ? this.state.newClinic.name : ""}>
                            <SelectInput value={this.state.newClinic}
                                         placeholder="Select"
                                         options={this.props.clinicList}
                                         showClear={false}
                                         displayProperty="name"
                                         searchEnabled={false}
                                         onChange={(val) => {
                                             this.setState({newClinic: val})
                                         }}
                            />
                        </FormField>
                    </FormSection>
                    <FormSection sectionId={1} labelPercent={5} inputPercent={7} label="Temperature">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.newTemperature}>
                            <SelectInput value={this.state.newTemperature}
                                         placeholder={"Select"}
                                         options={EnumTemperature.toArray()}
                                         showClear={false}
                                         searchEnabled={false}
                                         displayProperty="name"
                                         valueProperty="key"
                                         onChange={(val) => {
                                             this.setState({newTemperature: val})
                                         }}
                            />
                        </FormField>
                    </FormSection>
                    <FormSection sectionId={1} labelPercent={5} inputPercent={7} label="Specs">
                        <TextInput className={"form-control"} value={this.state.newSpecs}
                                   onChange={(value) => this.setState({newSpecs: value})}/>
                    </FormSection>
                    <FormSection sectionId={1} labelPercent={5} inputPercent={7} label="Provider Capacity">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required validateOn={this.state.newProviderCapacity}>
                            <NumberInput value={this.state.newProviderCapacity}
                                         onChange={(val) => {
                                             this.setState({newProviderCapacity: val})
                                         }}
                            />
                        </FormField>
                    </FormSection>
                    <FormSection sectionId={1} labelPercent={5} inputPercent={7} label="Preferred By">
                        <SelectInput value={this.state.newPreferredBy}
                                     placeholder="Select"
                                     options={this.props.staffList}
                                     showClear={true}
                                     multiSelect={true}
                                     displayProperty="name"
                                     onChange={(val) => {
                                         this.setState({newPreferredBy: val})
                                     }}
                        />
                    </FormSection>
                </FormBase>
            </div>
        )
    }

}

export default AddRoom;