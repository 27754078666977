import Enum from "../../../../enums/Enum";

const EnumTimeOfDay = new Enum({
  EARLY_MORNING: {name: "Early", dayPart: "Morning", timeRange: "07:00 - 09:00"},
  MID_MORNING: {name: "Mid", dayPart: "Morning", timeRange: "09:00 - 11:00"},
  LATE_MORNING: {name: "Late", dayPart: "Morning", timeRange: "11:00 - 12:00"},
  EARLY_AFTERNOON: {name: "Early", dayPart: "Afternoon", timeRange: "13:00 - 15:00"},
  MID_AFTERNOON: {name: "Mid", dayPart: "Afternoon", timeRange: "15:00 - 17:00"},
  LATE_AFTERNOON: {name: "Late", dayPart: "Afternoon", timeRange: "17:00 - 19:00"},
});

export default EnumTimeOfDay;