import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PatientUtil from "../../../utils/PatientUtil";
import {Image} from "react-bootstrap";
import EnumAppointmentType from "../enums/EnumAppointmentType";
import DeleteIcon from "../../../components/action-menu/icons/DeleteIcon";
import ContextMenu from "../../../components/context-menu/ContextMenu";
import ContextMenuItem from "../../../components/context-menu/ContextMenuItem";
import DateUtil from "../../../utils/DateUtil";
import CovidGap from "./CovidGap";
import "./CalendarEvent.css";
import CalendarEventService from "../CalendarEventService";
import {connect} from "react-redux";
import AuthService from "../../../services/auth-service/AuthService";
import SpecialStatusIcons from "../../patient/SpecialStatusIcons";

class AppointmentEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.scrollIfHighlighted();
    }

    scrollIfHighlighted() {
        if (this.props.highlighted) {
            if (this.currentElement) {
                this.currentElement.scrollIntoView();
            }
        }
    }

    render() {
        const {
            event,
            appointment,
            highlighted,
            timeZoneGetter,
            smallView: small,
            covidGapExists,
            showAppointmentContextMenu,
            eventContextMenuVisible,
            appointmentActionsVisible,
            deleteVisible,
            deleteFunc
        } = this.props;

        const timeZone = timeZoneGetter();
        const covidGapDuration = CalendarEventService.getCovidGapDuration(event, this.props.parameterMap);
        const eventDuration = DateUtil.getDurationInMinutes(event.start, event.end);
        const smallView = small || eventDuration <= 15;
        const renderCovidGap = covidGapExists && eventDuration > covidGapDuration && covidGapDuration > 0;
        const eventStart = event.startMoment;
        const eventEnd = renderCovidGap ? DateUtil.moment(event.endMoment).subtract(covidGapDuration, 'minute') : event.endMoment;
        const covidGapStart = eventEnd;
        const covidGapEnd = event.endMoment;

        return <>
            <div ref={el => this.currentElement = el}
                 className="calendar-event"
                 style={{
                     height: renderCovidGap ? 'calc(100% - 18px)' : 'inherit',
                     color: EnumAppointmentType[appointment.patientType].textColor,
                     backgroundColor: EnumAppointmentType[appointment.patientType].bgColor,
                     ...(highlighted ? {borderColor: 'rgba(81, 203, 238, 1)'} : {}),
                     ...(smallView ?
                         {
                             fontSize: 'x-small',
                             height: 'inherit',
                             flexDirection: 'row-reverse',
                             justifyContent: 'space-around',
                             paddingLeft: 10,
                             paddingRight: 10,
                         } : {})
                 }}
                 onContextMenu={e => {
                     if (appointmentActionsVisible && showAppointmentContextMenu) {
                         showAppointmentContextMenu(e, appointment);
                     }
                 }}
                 onMouseEnter={() => this.setState({mouseover: true})}
                 onMouseLeave={() => this.setState({mouseover: false})}
            >
                <div style={{
                    display: 'flex',
                    position: 'absolute',
                    top: smallView ? 0 : 5,
                    right: smallView ? 0 : 5
                }}>

                    {!smallView && event.isMerged &&
                    <Image src={process.env.PUBLIC_URL + '/assets/merged.png'} style={{height: 12, width: 12}}/>}
                    {appointmentActionsVisible &&
                    <div onClick={e => {
                        this.setState({...this.state});
                        this.contextMenu.show(e);
                    }}>
                        <FontAwesomeIcon icon={["fas", "ellipsis-v"]} style={{marginRight: 4, marginLeft: 4}}/>
                    </div>}
                    {appointmentActionsVisible && this.renderActionMenu()}
                    {this.state.mouseover && deleteVisible &&
                    <FontAwesomeIcon icon={["fas", "trash"]} size="sm"
                                     onClick={() => deleteFunc(event)}/>}
                </div>
                <div style={{marginRight: smallView ? 5 : null}}>
                    {eventStart.tz(timeZone).format("HH:mm")} - {eventEnd.tz(timeZone).format("HH:mm")}
                </div>
                <div className="font-weight-bold">
                    {PatientUtil.getPatientName(appointment.patient.firstName,
                        appointment.patient.middleName,
                        appointment.patient.lastName)}
                </div>
                {!smallView &&
                <div className="d-flex flex-wrap" style={{margin: "0 -3px"}}>
                    <SpecialStatusIcons specialStatusList={appointment.patient.specialStatusList}
                                        iconSize="1rem" iconColor="white"
                                        fontColor="white" fontSize="0.5rem"/>
                </div>
                }
                {appointment.status === "CONFIRMED" &&
                <span className="rounded-circle bg-white cell-badge">
                        <FontAwesomeIcon className={"yellow-700"} icon={["fas", "check"]}/>
                    </span>
                }
                {appointment.status === "ARRIVED" &&
                <span className="rounded-circle bg-white cell-badge">
                     <FontAwesomeIcon className={"blue-700"}  icon={["fas", "calendar-check"]}/>
                    </span>
                }
            </div>
            {!smallView && renderCovidGap && <CovidGap start={covidGapStart} end={covidGapEnd} timeZone={timeZone}/>}
        </>
    }

    renderActionMenu() {
        const {appointment, event, leaveExists} = this.props;

        return (
            <ContextMenu ref={el => this.contextMenu = el}>
                {AuthService.userHasAuthority("UPDATE_APPOINTMENT") &&
                <ContextMenuItem icon={<FontAwesomeIcon icon={["fas", "check-circle"]} style={{color: "green"}}/>}
                                 label="Confirm"
                                 onClick={() => this.props.confirmAppointment(appointment)}
                                 visible={appointment.status === "BOOKED" && !leaveExists(event)}/>
                }
                {AuthService.userHasAuthority("UPDATE_APPOINTMENT") &&
                <ContextMenuItem icon={<FontAwesomeIcon icon={["fas", "check-circle"]} style={{color: "blue"}}/>}
                                 label="Check-in"
                                 onClick={() => this.props.checkInAppointment(appointment)}
                                 visible={appointment.status === "CONFIRMED" && !leaveExists(event)}/>
                }
                {AuthService.userHasAuthority("UPDATE_APPOINTMENT") &&
                <ContextMenuItem icon={<FontAwesomeIcon icon={["fas", "check-circle"]} style={{color: "gray"}}/>}
                                 label="Undo Check-in"
                                 onClick={() => this.props.uncheckInAppointment(appointment)}
                                 visible={appointment.status === "ARRIVED" && !leaveExists(event)}/>
                }
                {AuthService.userHasAuthority("CANCEL_APPOINTMENT") &&
                <ContextMenuItem icon={<FontAwesomeIcon icon={["fas", "times-circle"]} style={{color: "red"}}/>}
                                 label="Cancel"
                                 onClick={() => this.props.cancelAppointment(appointment)}/>
                }
                {AuthService.userHasAuthority("CANCEL_APPOINTMENT") &&
                <ContextMenuItem icon={<DeleteIcon/>}
                                 label="Delete"
                                 onClick={() => this.props.deleteAppointment(appointment)}
                                 visible={!leaveExists(event)}/>
                }
            </ContextMenu>
        )
    }

}

function mapStateToProps(state) {
    return {
        parameterMap: state.base.parameterMap
    };
}

export default connect(mapStateToProps)(AppointmentEvent);