import React, {Component} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import {Dropdown as PrimeDropdown} from "primereact/components/dropdown/Dropdown";
import {FormField} from "../../../../components/form";
import {NumberInput, TextInput} from "../../../../components";
import PrimeDateInput from "../../../../components/date-input/PrimeDateInput";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import EnumInsuranceReferralStatus from "./enums/EnumInsuranceReferralStatus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PatientSearchModal from "../../../appointment/modals/PatientSearchModal";
import PatientUtil from "../../../../utils/PatientUtil";
import EnumCoPayUnit from "../../enums/EnumCoPayUnit";
import IcdCodeSelector from "../component/IcdCodeSelector";
import CptCodeSelector from "../component/CptCodeSelector";
import DateUtil from "../../../../utils/DateUtil";

export default class InsuranceReferralModal extends Component {

    constructor(props) {
        super(props);
        const insuranceReferralStatus =
            props.referralItem?.patientInsurance === 'None' ?
                EnumInsuranceReferralStatus.toArray().filter(status => status !== EnumInsuranceReferralStatus.APPROVED) :
                EnumInsuranceReferralStatus.toArray();

        this.state = {
            insuranceReferral: props.referralItem ? {...props.referralItem
            }: {
                date: new Date()
            },
            sendToOptions: this.props.sendToOptions,
            selectedPatient: null,
            patientSearchVisible: false,
            insuranceReferralStatus: insuranceReferralStatus
        }
    }

    updateField(value, field) {
        this.setState(state => {
            let newState = {...state}
            newState.insuranceReferral[field] = value;
            return newState;
        });
    }

    save() {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        const dto = {
            ...this.state.insuranceReferral,
            patientId: this.state.selectedPatient?.id
        };

        const url = this.props.editMode ? `api/insurance/coordinator/external-referral/${dto.id}` : 'api/insurance/coordinator/external-referral';

        RemotingService.postRemoteCall(url, dto, () => {
            this.props.onInsuranceReferralSaved(this.props.selectedDate);
        });
    }

    openPatientSearch = () => {
        ValidationUtil.clear(this.formFields);
        this.setState({patientSearchVisible: true});
    }

    closePatientSearch = () => {
        this.setState({patientSearchVisible: false});
    }

    handleSelectedPatient = (patient) => {
        this.setState({selectedPatient: patient, patientSearchVisible: false});
        if (!patient.insurance) {
            this.setState({insuranceReferralStatus: this.state.insuranceReferralStatus.filter(status => status !== EnumInsuranceReferralStatus.APPROVED)});
        } else {
            this.setState({insuranceReferralStatus: EnumInsuranceReferralStatus.toArray()});
        }
    }

    clearSelectedPatient = () => {
        this.setState({selectedPatient: null});
    }

    renderPatientSearch = () => {
        return this.state.patientSearchVisible &&
            <PatientSearchModal
                header={"Find a Patient"}
                selectIcon={<FontAwesomeIcon icon={["fas", "user-plus"]} size={"2x"} color={"#b1d93f"}
                                             style={{marginTop: "3px"}}/>}
                handleFindPatientModalSelect={this.handleSelectedPatient}
                close={this.closePatientSearch}
            />;
    }

    listInputTemplate = (field, options) => {
        const item = this.state.insuranceReferral;
        return (
            <PrimeDropdown optionLabel="name" optionValue="key"
                           options={options} filter={true}
                           value={item[field]}
                           onChange={event => this.updateField(event.value, field)}
                           style={{width: '100%'}}/>
        )
    }

    render() {
        this.formFields = [];

        return (
            <div>
                <Container className={"InputModal"} style={{width: 600}}>
                    <Row>
                        <Col>
                            <Container>
                                {!this.props.editMode && <Field label="Patient"
                                       body={this.state.selectedPatient ?
                                           <div className="display-flex flex-row justify-content-between">
                                               {PatientUtil.getPatientName(this.state.selectedPatient.firstName, this.state.selectedPatient.middleName, this.state.selectedPatient.lastName)}
                                               <div style={{color: "red", cursor: "pointer", textAlign: "right"}}
                                                    onClick={this.clearSelectedPatient}>
                                                   ⓧ
                                               </div>
                                           </div> :
                                           <FormField ref={formField => this.formFields.push(formField)} required
                                                      validateOn={this.state.selectedPatient}>
                                               <Button variant={"outline-secondary w-100"}
                                                       onClick={this.openPatientSearch}
                                                       size={"sm"}>
                                                   <FontAwesomeIcon icon={["fas", "search"]}/>
                                                   Search Patients
                                               </Button>
                                           </FormField>
                                       }/>}

                                {this.props.editMode && <Field label="Patient"
                                                               body={
                                                                   <div
                                                                       className="display-flex flex-row justify-content-between">
                                                                       {this.state.insuranceReferral.patientName}
                                                                   </div>
                                                               }/>}
                                <Field label="Type"
                                       body={
                                           <FormField ref={formField => this.formFields.push(formField)}
                                                      required validateOn={this.state.insuranceReferral.referralType}>
                                               <PrimeDropdown optionLabel="name" optionValue="key"
                                                              options={this.props.typeOptions}
                                                              value={this.state.insuranceReferral.referralType}
                                                              onChange={event => this.updateField(event.value, "referralType")}/>
                                           </FormField>
                                       }/>

                                <Field label="Clinic"
                                       body={
                                           <FormField ref={formField => this.formFields.push(formField)}
                                                      required validateOn={this.state.insuranceReferral.clinicId}>
                                               <PrimeDropdown optionLabel="name" optionValue="id"
                                                              options={this.props.clinicOptions}
                                                              value={this.state.insuranceReferral.clinicId}
                                                              onChange={event => this.updateField(event.value, "clinicId")}/>
                                           </FormField>
                                       }/>
                                <Field label="Status"
                                       body={
                                           <FormField ref={formField => this.formFields.push(formField)}
                                                      required validateOn={this.state.insuranceReferral.status}>
                                               <PrimeDropdown optionLabel="name" optionValue="key"
                                                              options={this.state.insuranceReferralStatus}
                                                              value={this.state.insuranceReferral.status}
                                                              onChange={event => this.updateField(event.value, "status")}/>
                                           </FormField>
                                       }/>
                                <Field label="Date"
                                       body={
                                           <FormField ref={formField => this.formFields.push(formField)}
                                                      required validateOn={this.state.insuranceReferral.date}>
                                               <PrimeDateInput clearable
                                                               value={DateUtil.moment(this.state.insuranceReferral.date).toDate()}
                                                               onChange={value => this.updateField(value, "date")}/>
                                           </FormField>
                                       }/>
                                <Field label="Co-Pay"
                                       body={
                                           <TextInput value={this.state.insuranceReferral.coPay}
                                                      onChange={value => this.updateField(value, "coPay")}/>
                                       }/>
                                <Field label="Co-Pay Unit"
                                       body={
                                           this.listInputTemplate('coPayUnit', EnumCoPayUnit.toArray())}/>
                                <Field label="Co-Pay Max"
                                       body={
                                           <TextInput value={this.state.insuranceReferral.coPayMax}
                                                      onChange={value => this.updateField(value, "coPayMax")}/>
                                       }/>
                                <Field label="License No."
                                       body={
                                           <TextInput value={this.state.insuranceReferral.licenseNo}
                                                      onChange={value => this.updateField(value, "licenseNo")}/>
                                       }/>
                            </Container>
                        </Col>
                        <Col>
                            <Container>
                                <Field label="ICD Code"
                                       body={
                                           <FormField ref={formField => this.formFields.push(formField)}
                                                      required validateOn={this.state.insuranceReferral.icd}>
                                               <IcdCodeSelector icd={this.state.insuranceReferral.icd}
                                                                onIcdSelected={(icd) => {
                                                                    this.state.insuranceReferral.icd = icd;
                                                                    this.forceUpdate();
                                                                }}/>
                                           </FormField>
                                       }/>
                                <Field label="CPT Codes"
                                       body={
                                           <FormField ref={formField => this.formFields.push(formField)}
                                                      required validateOn={this.state.insuranceReferral.cptList}>
                                               <CptCodeSelector cptList={this.state.insuranceReferral.cptList}
                                                                onCptSelected={(cptList) => {
                                                                    this.state.insuranceReferral.cptList = cptList;
                                                                    this.forceUpdate();
                                                                }}/>
                                           </FormField>}
                                />
                                <Field label="Requested Sessions"
                                       body={
                                           <FormField ref={formField => this.formFields.push(formField)}
                                                      required min={1} max={20}
                                                      validateOn={this.state.insuranceReferral.noOfSessions}>
                                               <NumberInput value={this.state.insuranceReferral.noOfSessions}
                                                            onChange={value => this.updateField(value, "noOfSessions")}/>
                                           </FormField>
                                       }/>
                                <Field label="Send To"
                                       body={
                                           <FormField ref={formField => this.formFields.push(formField)} required
                                                      validateOn={this.state.insuranceReferral.sendTo}>
                                               {this.listInputTemplate('sendTo', this.state.sendToOptions)}
                                           </FormField>
                                       }/>
                                <Field label="Reference Number"
                                       body={
                                           <TextInput value={this.state.insuranceReferral.referenceNo}
                                                      onChange={value => this.updateField(value, "referenceNo")}/>
                                       }/>
                                <Field label="Valid Until"
                                       body={
                                           <PrimeDateInput clearable
                                                           value={DateUtil.moment(this.state.insuranceReferral.validUntil).toDate()}
                                                           onChange={value => this.updateField(value, "validUntil")}/>

                                       }/>
                                <Field label="Note"
                                       body={
                                           <TextInput value={this.state.insuranceReferral.notes}
                                                      onChange={value => this.updateField(value, "notes")}/>
                                       }/>
                                <Field label="Referred By"
                                       body={
                                           <TextInput value={this.state.insuranceReferral.referredBy}
                                                      onChange={value => this.updateField(value, "referredBy")}/>
                                       }/>
                            </Container>
                        </Col>
                    </Row>
                </Container>
                {this.renderPatientSearch()}
            </div>
        );
    }
}

class Field extends Component {

    render() {
        const {label, body} = this.props;
        return <div style={{display: 'grid', marginBottom: 8}}>
            <div><b>{label}</b></div>
            {body}
        </div>
    }

}
