import React from 'react';
import "./PatientMissingData.css"
import {Image} from "react-bootstrap";
import PatientUtil from "../../../../../utils/PatientUtil";
import dialog from "../../../../../components/dialog/Dialog";
import MissingPointsModal from "./MissingPointsModal";

export class PatientMissingData extends React.Component {

    render() {
        let missingPercentage = this.props.missingPercentage;
        missingPercentage = missingPercentage ? Math.round(missingPercentage) : missingPercentage;
        let sortedEmrList = this.props.emrList
            .sort((a, b) => (a.visit?.missingPoints > b.visit?.missingPoints) ? -1 : 1)
            .filter(item => item.visit != null)
            .filter(item => item.visit.missingPoints > 0);

        return (
            <div className={"missing-patient-data"}>
                <div className="container h-100">
                    <div className="row">
                        <div className="col-4 h-100 d-flex flex-column p-justify-center"
                             style={{borderRight: "1px solid rgba(0,0,0,.125)"}}>
                            <div className="align-items-center text-center">
                                <div className="text-color-red">
                                    <h3>{missingPercentage}%</h3>
                                </div>
                                <div>of patients'</div>
                                <div>summary record is missing</div>
                            </div>
                        </div>
                        <div className="col-8 h-100 flex-column overflow-auto">
                            {sortedEmrList.map(item => {
                                if(item.visit?.missingPoints != null && item.visit?.totalPoints != null){
                                    return (
                                        <div className={"row patient-row"}>
                                            <PatientInfoRow patient={item.patient}
                                                            visit={item.visit}
                                                            missingPoints={item.visit?.missingPoints}/>
                                        </div>
                                    )}
                                }
                            )}
                        </div>
                    </div>
                </div>
            </div>);
    }

}

const PatientInfoRow = (props) => {

    let photoSrc = process.env.PUBLIC_URL + '/assets/patient_profile.png';
    if (props.patient.thumbnail) {
        photoSrc = 'data:image/jpeg;base64,' + props.patient.thumbnail;
    }

    const showMissingPointsDetail = () => {
        dialog({
            title: "Missing Points",
            component: <MissingPointsModal patient={props.patient}
                                           visit={props.visit}/>
        });
    }

    return (
        <>
            <div className={"col-2"} style={{overflow: "hidden"}}>
                <div className={"row"}>
                    <Image src={photoSrc} rounded width={"40px"} height={"40px"} style={{marginLeft: "15px", marginTop: "5px",
                        marginBottom: "5px"}}/>
                </div>
            </div>
            <div className={"col-6"}>
                <div className={"patient-mrn"}>
                    {PatientUtil.getPatientId(props.patient)}
                </div>
                <div className={"patient-name"}>
                    {PatientUtil.getPatientName(props.patient.firstName, props.patient.middleName, props.patient.lastName)}
                </div>
            </div>
            <div className={"col-4"}>
                <div className="patient-missing-info text-right cursor-pointer"
                     onClick={showMissingPointsDetail}>
                    <span className={"patient-data-count"}>{props.missingPoints}</span>
                    <span className={"patient-missing-point"}> Missing Point</span>
                </div>
            </div>
        </>
    );
}
