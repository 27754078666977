import React, {Component} from "react";
import '../Subjective.css';
import '../../EMR.css';
import './ComplaintView.css';
import DateUtil from "../../../../../utils/DateUtil";
import {SurgicalHistoryChecker} from "../checkers/SurgicalHistoryChecker";
import {Grid} from "@material-ui/core";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";

export default class SurgicalHistoryView extends Component {

    render() {
        let divs = this.getDivs();
        return (
            <Grid container alignItems={"center"} className={"content-card content-row m-0 w-100 p-2"}>
                <Grid item xs style={{maxWidth: 200}} className={"content-card-title"}>
                    <h6 className={"no-margin"}>Surgical History {!this.props.dashboardView && <RedAsterisk className={"font-weight-bold"}
                                                                               appointmentType={this.props.appointmentType}
                                                                               firstVisit newComplaint/>}</h6>
                </Grid>
                <Grid item xs>
                    <div className="p-2 pl-3" style={{borderLeft: "1px gainsboro solid"}}>
                        {SurgicalHistoryChecker.isNone(this.props.surgicalHistory) ?
                            <div>
                                No Surgical History
                            </div> : (
                                divs != null ? divs :
                                    <div style={{minHeight: "1rem"}}>
                                    </div>
                            )}
                    </div>
                </Grid>
            </Grid>
        );
    }

    getDivs() {
        if (this.props.surgicalHistory == null
            || this.props.surgicalHistory.items == null
            || this.props.surgicalHistory.items.length == 0) {
            return null;
        }
        let divs = [];
        this.props.surgicalHistory.items.forEach((item, index) => {
            divs.push(
                <div key={"surgicalHistoryViewDiv" + index}>
                    {
                        [
                            item.condition,
                            item.procedure,
                            item.date != null ? DateUtil.formatDate(item.date) : null,
                            item.detail
                        ].filter(Boolean).join(", ")
                    }
                </div>);
        });
        return divs;
    }
}