import React from 'react'
import moment from "moment";
import {connect} from 'react-redux';
import SockJsClient from "react-stomp";
import Popover from '@material-ui/core/Popover';
import {Button, Col} from 'react-bootstrap';
import RemotingService from '../../../services/remoting-service/RemotingService';
import {showNotification} from "../../../services/notification-service/NotificationService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Notification.css";
import {getSchedules} from "../../../services/actions";

class Notification extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            notifications: []
        };
    }

    componentDidMount() {
        this.fetchNotifications();
    }

    handleClick = event => {
        this.setState({anchorEl: event.currentTarget})
    };

    handleClose = () => {
        this.setState({anchorEl: null})
    };

    fetchNotifications = () => {
        RemotingService.getRemoteCall('/api/notification/list', null, (result) => {
            this.setState({notifications: result});
        });
    };

    handleNotification = (msg) => {
        this.fetchNotifications();

        const data = msg.body.data;

        showNotification({severity: 'success', summary: 'Notification', detail: data});
        this.handleNotificationForSpecificActions(data);

    }

    handleNotificationForSpecificActions = (msgContent) => {
        if (msgContent.includes('Schedule updated for')) {
            // Notification text is important till we implement a more structured server pushed event on web application.
            this.props.dispatch(getSchedules());
        }
    }

    handleClearNotification = notificationId => {
        RemotingService.postRemoteCall('/api/notification/clear/' + notificationId, null, () => {
            this.fetchNotifications();
        });
    };

    handleClearAllNotifications = () => {
        RemotingService.postRemoteCall('/api/notification/clear', null, () => {
            this.fetchNotifications();
        });
    };

    render() {
        let notificationsExist = this.state.notifications && this.state.notifications.length !== 0;

        return (
            <>
                <svg className="bi bi-bell"
                     onClick={this.handleClick}
                     viewBox="0 0 16 16"
                     fill="currentColor"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 16a2 2 0 002-2H6a2 2 0 002 2z"/>
                    <path fillRule="evenodd"
                          d="M8 1.918l-.797.161A4.002 4.002 0 004 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 00-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 111.99 0A5.002 5.002 0 0113 6c0 .88.32 4.2 1.22 6z"
                          clipRule="evenodd"/>

                    {
                        notificationsExist &&
                        <circle cx="11" cy="4" r="2.5" fill="#d51024"/>
                    }
                </svg>

                <Popover
                    open={Boolean(this.state.anchorEl)}
                    className={"pop-over"}
                    anchorEl={this.state.anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    onClose={this.handleClose}
                >

                    {notificationsExist ?

                        <>
                            <div className={"notification-header"}>
                                <div xs={12}>
                                    <h4 className={"font-weight-bold"}>Notifications</h4>
                                </div>
                            </div>
                            <div className={"notification-container"}>
                                {this.state.notifications.map((notification, i) => {
                                    return (
                                        <div className="notification-item" key={"notification-item" + i}>
                                            <Col xs={10} className={"p-0"}>
                                                {notification.content + ' (' + moment(notification.creationDate).format('LLL') + ')'}
                                            </Col>

                                            <Button variant="light">
                                                <FontAwesomeIcon icon={["fas", "trash"]} onClick={() => this.handleClearNotification(notification.id)}/>
                                            </Button>

                                        </div>
                                    )
                                })}
                            </div>
                            <div className={"notification-footer"}>

                                <div xs={12}>
                                    <Button id="clear-button" variant="outline-primary" style={{fontSize: '13px'}}
                                            onClick={() => this.handleClearAllNotifications()}>
                                        Clear All
                                    </Button>
                                </div>


                            </div>
                        </>
                        :
                        <div className="notification-container">
                            <div className="notification-item">No unread notifications.</div>
                        </div>
                    }
                </Popover>
                <SockJsClient
                    url={RemotingService.getApiBaseUrl() + "websocket"}
                    topics={["/topic/user/" + this.props.user.email]}
                    onMessage={this.handleNotification}
                    ref={client => {
                        this.clientRef = client;
                    }}
                />
            </>
        );
    }

}

const mapStateToProps = state => {
    return {
        user: state.authentication.user
    };
};

export default connect(mapStateToProps)(Notification);
