import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {Slider} from "@material-ui/core";
import Tooltip from "../../../../components/tooltip/Tooltip";
import CreditOptions from "./PatientCreditOptions"

export const ValueLabelComponent = (country) => {
    return (props) => {
        const {children, open, value} = props;
        const creditInfo = CreditOptions.getCreditInfo(value, country);
        const title = <>
            <div><b>Credit:</b> {value}</div>
            <div><b>Discount Rate:</b> {creditInfo.creditDiscountRate}%</div>
            <div><b>Validity Period: </b> {creditInfo.creditValidityPeriod} weeks</div>
        </>

        return (
            <Tooltip open={open} enterTouchDelay={0} placement="top" title={title}>
                {children}
            </Tooltip>
        );
    };
};

export const CreditSlider = withStyles({
    root: {
        color: '#52af77',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -2,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    markLabel: {
        paddingTop: "10px",
        fontSize: "12px",
    },
    track: {
        height: 20,
        borderRadius: 5,
    },
    rail: {
        height: 20,
        borderRadius: 5,
    },
})(Slider);

export default CreditSlider;