import Enum from "../../../../../../enums/Enum";

const PostureEnum = new Enum({
    KYPHOSIS:{name: "Kyphosis"},
    LORDOSIS: {name: "Lordosis"},
    SWAY_BACK: {name: "Sway Back"},
    LATERAL_TILT: {name: "Lateral Tilt"},
    PELVIC_TILT: {name: "Pelvic Tilt"},
    FORWARD_HEAD: {name: "Forward Head"},
    FORWARD_SHOULDERS:{name: "Forward Shoulders"},
    FORWARD_LEAN: {name: "Forward Lean"},
    SCOLIOSIS:{name: "Scoliosis"},
    HIP_RETROVERSION: {name: "Hip Retroversion"},
    HIP_ANTEVERSION: {name: "Hip Anteversion"},
    FEMUR_INTERNAL_ROTATION: {name: "Femur Internal Rotation"},
    KNOCKED_KNEES:{name: "Knocked Knees"},
    BOW_LEGS: {name: "Bow Legs"},
    FLAT_FEET: {name: "Flat Feet"},
    FOOT_DROP: {name: "Foot Drop"},
    PRONATED_FEET: {name: "Pronated Feet"}
});


export default PostureEnum;