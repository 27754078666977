import moment from "moment";
import TimezoneUtil from "./TimezoneUtil";
import {Views} from "react-big-calendar";
import DateUtil from "./DateUtil";

const NOT_MOVABLE_APPOINTMENT_STATES = ["BEINGSEEN", "CONSULTED", "COMPLETED"];

const isAppointmentMovable = (appointmentState) => {
    return !NOT_MOVABLE_APPOINTMENT_STATES.includes(appointmentState);
};

const formatWithClinicTime = (date, clinicName) => {
    return TimezoneUtil.atClinicZone(date, clinicName).format('hh:mm A');
};

const appointmentStartTime = (appointment) => {
    return formatWithClinicTime(appointment.start, appointment.room.clinicName);
};

const appointmentEndTime = (appointment) => {
    return formatWithClinicTime(appointment.end, appointment.room.clinicName);
};

const appointmentTimeSlot = (appointment) => {
    return appointmentStartTime(appointment) + " - " + appointmentEndTime(appointment) + ` (${TimezoneUtil.getClinicZoneInfo(appointment.room.clinicName).code})`;
};

const appointmentDuration = (appointment) => {
    return moment(appointment.end).diff(moment(appointment.start), "minutes");
};

const calculateStartEnd = (selectedView, selectedDate) => {
    let startTime = new Date(selectedDate);
    let endTime = new Date(selectedDate);

    if (selectedView === Views.WEEK) {
        startTime = DateUtil.startOfWeek(selectedDate);
        endTime = DateUtil.endOfWeek(selectedDate);
    } else if (selectedView === Views.MONTH) {
        startTime = DateUtil.startOfMonth(selectedDate);
        endTime = DateUtil.endOfMonth(selectedDate);
    }

    startTime.setHours(0, 0, 0, 0);
    endTime.setHours(23, 59, 59, 0);

    return {startTime, endTime};
}

export default {calculateStartEnd, isAppointmentMovable, formatWithClinicTime, appointmentStartTime, appointmentEndTime, appointmentTimeSlot, appointmentDuration};
