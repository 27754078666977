import StringUtil from "./StringUtil";

const FileType = {
    PDF: "PDF",
    XLS: "XLS"
};

const saveReportAs = (fileName, reportFile) => {
    return saveAs(fileName, reportFile.bytes, reportFile.type);
}

const saveAs = (fileName, data, type) => {
    if (type === FileType.PDF) {
        fileName = fileName + '.pdf';
        saveAsPDF(fileName, data)

    } else if (type === FileType.XLS) {
        fileName = fileName + '.xls';
        saveAsXLS(fileName, data)

    } else {
        throw Error('Unknown file type!');
    }
}

const saveAsPDF = (fileName, data) => {
    const sampleArr = base64ToArrayBuffer(data);
    saveByteArray(fileName, sampleArr, "application/pdf");
}

const saveAsXLS = (fileName, data) => {
    const sampleArr = base64ToArrayBuffer(data);
    saveByteArray(fileName, sampleArr, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8");
}

const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);

    for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i); // ascii
    }

    return bytes;
}

const saveByteArray = (reportName, byte, type) => {
    const blob = new Blob([byte], {type: type});
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const fileName = reportName;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const getFileIconName = (fileName) => {
    switch (StringUtil.getFileExtension(fileName)) {
        case 'pdf':
            return 'file-pdf';
        case 'doc':
        case 'docx':
            return 'file-word';
        case 'xls':
        case 'xlsx':
            return 'file-excel';
        case 'csv':
            return 'file-csv';
        case 'png':
        case 'jpg':
        case 'jpeg':
            return 'file-image';
        case 'txt':
            return 'file-alt';
        default:
            return 'file';
    }
}

export default {
    FileType, saveReportAs, saveAs, getFileIconName
};
