import React, {Component} from "react";
import "../../VisitHistory.css"
import YesNoEnum from "../../../assessment/enums/morseFallRisk/YesNoEnum";
import AmbulatoryAidEnum from "../../../assessment/enums/morseFallRisk/AmbulatoryAidEnum";
import GaitEnum from "../../../assessment/enums/morseFallRisk/GaitEnum";
import MentalStatusEnum from "../../../assessment/enums/morseFallRisk/MentalStatusEnum";
import RiskLevelEnum from "../../../assessment/enums/morseFallRisk/RiskLevelEnum";
import FurtherAssessmentEnum from "../../../assessment/enums/morseFallRisk/FurtherAssessmentEnum";

export default class FallRiskViewHistory extends Component {
    constructor(props) {
        super(props);

        this.state = {
            riskLevelClassName: " "
        };

        this.specifyRiskLevelClass = this.specifyRiskLevelClass.bind(this);
    }

    componentDidMount() {
        this.specifyRiskLevelClass();
    }

    render() {
        if (this.props.fallRisk == null) {
            return "No information available";
        }

        return (
            <div>
                {this.props.showMainAssessment &&
                <div className={"visit-history-content"}>
                    <ol>
                        <li key={"fallingHistory"}>
                            1. History of Falls:
                            <span style={{fontWeight: "normal"}}>
                            {this.props.fallRisk?.fallingHistory != null ? " " + YesNoEnum[this.props.fallRisk.fallingHistory].name : " NA"}
                            </span>
                        </li>

                        <li key={"secondaryDiagnosis"}>
                            2. Secondary Diagnosis:
                            <span style={{fontWeight: "normal"}}>
                                {this.props.fallRisk?.secondaryDiagnosis != null ? " " + YesNoEnum[this.props.fallRisk.secondaryDiagnosis].name : " NA"}
                            </span>
                        </li>

                        <li key={"ambulatoryAid"}>
                            3. Ambulatory Aid:
                            <span style={{fontWeight: "normal"}}>
                                {this.props.fallRisk?.ambulatoryAid ? " " + AmbulatoryAidEnum[this.props.fallRisk.ambulatoryAid].name : " NA"}
                            </span>
                        </li>

                        <li key={"heparinLockIVTherapy"}>
                            4. IV / Heparin Lock:
                            <span style={{fontWeight: "normal"}}>
                                {this.props.fallRisk?.heparinLockIVTherapy != null ? " " + YesNoEnum[this.props.fallRisk.heparinLockIVTherapy].name : " NA"}
                            </span>
                        </li>

                        <li key={"gait"}>
                            5. Gait / Transferring:
                            <span style={{fontWeight: "normal"}}>
                                {this.props.fallRisk?.gait ? " " + GaitEnum[this.props.fallRisk.gait].name : " NA"}
                            </span>
                        </li>

                        <li key={"mentalStatus"}>
                            6. Mental Status:
                            <span style={{fontWeight: "normal"}}>
                                {this.props.fallRisk?.mentalStatus ? " " + MentalStatusEnum[this.props.fallRisk.mentalStatus].name : " NA"}
                            </span>
                        </li>

                        <li key={"riskScore"}>
                            Total:
                            <span style={{fontWeight: "normal"}}>
                                {this.props.fallRisk?.riskScore != null ? " " + this.props.fallRisk.riskScore : " NA"}
                            </span>
                        </li>

                        <li key={"riskLevel"}>
                            <div className={this.state.riskLevelClassName}>
                                Risk Level:
                                <span style={{fontWeight: "normal"}}>
                                    {this.props.fallRisk?.riskLevel ? " " + RiskLevelEnum[this.props.fallRisk.riskLevel].name : " NA"}
                                </span>
                            </div>
                        </li>

                        <li key={"physiotherapistReferral"}>
                            Physiotherapist Referral Required:
                            <span style={{fontWeight: "normal"}}>
                                {this.props.fallRisk?.referralRequired != null ? " " + YesNoEnum[this.props.fallRisk.referralRequired].name : " No"}
                            </span>
                        </li>
                    </ol>
                </div>}
                {this.props.showFurtherAssessment &&
                <div className={"visit-history-content"}>
                    <div className={"furtherAssessmentHeader"}>
                        <span>Further Assessment</span>
                    </div>
                    {this.props.fallRisk?.furtherAssessmentItem.map((item, index) =>
                        <div key={"furtherAssessmentItem" + index} className={"furtherAssessmentContent"}>
                            <span>
                                {FurtherAssessmentEnum[item.furtherAssessment].name} </span>
                            <span>
                                Result: {item.result} </span>
                        </div>
                    )}
                </div>}
            </div>
        );
    }

    specifyRiskLevelClass = () => {
        if (this.props.fallRisk) {
            const riskLevel = RiskLevelEnum[this.props.fallRisk.riskLevel]?.name;
            if (riskLevel === "Low Risk") {
                this.setState({riskLevelClassName: "riskLevelVisitHistoryLow"});
            } else if (riskLevel === "Moderate") {
                this.setState({riskLevelClassName: "riskLevelVisitHistoryModerate"});
            } else {
                this.setState({riskLevelClassName: "riskLevelVisitHistoryHigh"});
            }
        }
    };
}
