import React from "react";
import {FormBase, FormSection} from "../../../../components/form";
import DateUtil from "../../../../utils/DateUtil";
import EnumReferenceType from "../../enums/EnumReferenceType";
import Modal from "../../../../components/modal/Modal";

class ViewReferenceModal extends React.Component {
  state = {
    reference: Object.assign({}, this.props.reference),
  };

  render() {
    const { reference } = this.state;

    return (
      <>
        <Modal
          visible={this.props.visible}
          header="View Reference"
          closeAction={this.props.hideModal}
        >
          <div className={"dim14-dialog-body"}>
            <FormBase>
              <FormSection
                sectionId={1}
                labelPercent={4}
                inputPercent={8}
                label="Reference Type"
              >
                <div> {EnumReferenceType[reference.referenceType]} </div>
              </FormSection>
              {reference.referenceType && (
                <FormSection
                  sectionId={1}
                  labelPercent={4}
                  inputPercent={8}
                  label={EnumReferenceType.getLabel(reference.referenceType)}
                >
                  <div> {reference.name} </div>
                </FormSection>
              )}
              {reference.referenceType === "DRHOSPITAL" && (
                <FormSection
                  sectionId={1}
                  labelPercent={4}
                  inputPercent={8}
                  label="Hospital Name"
                >
                  <div>{reference.hospital}</div>
                </FormSection>
              )}
              {/* {
                                reference.referenceType && reference.referenceType !== "OTHER" &&
                                <FormSection sectionId={1} labelPercent={4} inputPercent={8} label="Subject">
                                    <div>{reference.subject}</div>
                                </FormSection>
                            } */}
              <FormSection
                sectionId={1}
                labelPercent={4}
                inputPercent={8}
                label="Date"
              >
                <div>{DateUtil.formatDateMMM(reference.updateDate)}</div>
              </FormSection>
            </FormBase>
          </div>
        </Modal>
      </>
    );
  }
}

export default ViewReferenceModal;
