import React, {Component} from "react";
import {Image} from "react-bootstrap";
import CovidGap from "./CovidGap";
import DateUtil from "../../../utils/DateUtil";
import "./CalendarEvent.css";
import EnumBlockType from "../enums/EnumBlockType";
import CalendarEventService from "../CalendarEventService";
import ContextMenu from "../../../components/context-menu/ContextMenu";
import ContextMenuItem from "../../../components/context-menu/ContextMenuItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RotationBlockRemoteService from "../RotationBlockRemoteService";
import {connect} from "react-redux";
import dialog from "../../../components/dialog/Dialog";
import SplitAvailableSlotModal from "../modals/SplitAvailableSlotModal";

class AvailableSlotEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            event,
            timeZoneGetter,
            showEventContextMenu,
            eventContextMenuVisible,
            smallView,
            covidGapExists,
            appointmentActionsVisible,
            deleteVisible,
            deleteFunc
        } = this.props;


        const timeZone = timeZoneGetter();
        const covidGapDuration = CalendarEventService.getCovidGapDuration(event, this.props.parameterMap);
        const eventDuration = DateUtil.getDurationInMinutes(event.start, event.end);
        const renderCovidGap = covidGapExists && eventDuration > covidGapDuration && covidGapDuration > 0;
        const eventStart = event.startMoment;
        const eventEnd = (renderCovidGap && covidGapDuration > 0) ? DateUtil.moment(event.endMoment).subtract(covidGapDuration, 'minute') : event.endMoment;
        const covidGapStart = eventEnd;
        const covidGapEnd = event.endMoment;

        return (
            <>
                <div className="calendar-event"
                     style={{
                         height: renderCovidGap ? 'calc(100% - 18px)' : 'inherit',
                         borderStyle: 'solid',
                         borderWidth: 2,
                         borderColor: EnumBlockType[event.type].textColor,
                         color: EnumBlockType[event.type].textColor,
                         background: 'none',
                         ...(smallView ?
                             {
                                 fontSize: 'small',
                                 height: 'inherit',
                                 flexDirection: 'row-reverse',
                                 justifyContent: 'space-around',
                                 paddingLeft: 10,
                                 paddingRight: 10,
                             } : {})
                     }}
                     onContextMenu={e => {
                         if (eventContextMenuVisible && showEventContextMenu) {
                             e.preventDefault();
                             showEventContextMenu(e, event);
                         }
                     }}
                     onMouseEnter={() => this.setState({mouseover: true})}
                     onMouseLeave={() => this.setState({mouseover: false})}
                >
                    <div style={{
                        display: 'flex',
                        position: 'absolute',
                        top: smallView ? 0 : 5,
                        right: smallView ? 0 : 5
                    }}>
                        {!smallView && event.isMerged &&
                        <Image src={process.env.PUBLIC_URL + '/assets/merged.png'} style={{height: 12, width: 12}}/>}
                        {appointmentActionsVisible &&
                        <div onClick={e => this.contextMenu.show(e)}>
                            <FontAwesomeIcon icon={["fas", "ellipsis-v"]} style={{marginRight: 4, marginLeft: 4}}/>
                        </div>}
                        {appointmentActionsVisible && this.renderActionMenu()}
                        {this.state.mouseover && deleteVisible &&
                        <FontAwesomeIcon icon={["fas", "trash"]} size="sm"
                                         onClick={() => deleteFunc(event)}/>}
                    </div>
                    <div style={{marginRight: smallView ? 5 : null}}>
                        {eventStart.tz(timeZone).format("HH:mm")} - {eventEnd.tz(timeZone).format("HH:mm")}
                    </div>
                </div>
                {!smallView && renderCovidGap && <CovidGap start={covidGapStart} end={covidGapEnd} timeZone={timeZone}/>}
            </>
        )
    }

    renderActionMenu() {
        const {event} = this.props;
        const eventDurationInMinutes = DateUtil.getDurationInMinutes(event.startMoment, event.endMoment);

        return (
            <ContextMenu ref={el => this.contextMenu = el}>
                <ContextMenuItem icon={<FontAwesomeIcon icon={["fas", "check-circle"]} style={{color: EnumBlockType.MANAGEMENT.textColor}}/>}
                                 label="Set as Management Time"
                                 onClick={() => {
                                     RotationBlockRemoteService.updateBlockType(event, "MANAGEMENT", () => this.props.refreshCalendarData());
                                 }}/>
                <ContextMenuItem icon={<FontAwesomeIcon icon={["fas", "project-diagram"]} style={{color: EnumBlockType[event.type].textColor}}/>}
                                 label="Split"
                                 visible={eventDurationInMinutes >= 30}
                                 onClick={() => {
                                     this.showSplitBlockModal(event)
                                 }}/>
            </ContextMenu>
        )
    }

    showSplitBlockModal = (event) => {
        dialog({
            title: " ",
            component: <SplitAvailableSlotModal event={event}
                                                onSplit={() => this.props.refreshCalendarData()}/>
        });
    }

}

function mapStateToProps(state) {
    return {
        parameterMap: state.base.parameterMap
    };
}

export default connect(mapStateToProps)(AvailableSlotEvent);