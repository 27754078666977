import Enum from "../../../../../../enums/Enum";

const ConservativeTreatmentReceivedEnum = new Enum({
    PHYSIOTHERAPY: {name: "Physiotherapy"},
    CHIROPRACTIC: {name: "Chiropractic"},
    REST: {name: "Rest"},
    MEDICATION: {name: "Medication"},
    HOME_THERAPY: {name: "Home Therapy"},
    OTHER: {name: "Other"}
});

export default ConservativeTreatmentReceivedEnum;