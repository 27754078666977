import React, {Component} from "react";
import DateUtil from "../../../../utils/DateUtil";
import {getMoment, getNow} from "../../../../utils/CalendarDateUtil";
import {momentLocalizer, Views} from "react-big-calendar";
import CalendarEventService from "../../../appointment/CalendarEventService";
import NotificationService from "../../../../services/notification-service/NotificationService";
import {Button} from "react-bootstrap";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import AppointmentCalendarFormats from "../../../appointment/AppointmentCalendarFormats";
import TimezoneUtil from "../../../../utils/TimezoneUtil";
import AppointmentCalendarEventFactory from "../../../appointment/calendarevent/AppointmentCalendarEventFactory";
import {getSchedules} from "../../../../services/actions";
import {connect} from "react-redux";
import {Dropdown} from "primereact/dropdown";
import AuthService from "../../../../services/auth-service/AuthService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {extract} from "../../../../utils/ArrayUtil";
import dialog from "../../../../components/dialog/Dialog";
import {AddRotationBlockModal} from "./AddRotationBlockModal";
import "./StaffRotation.css";
import BigCalendar from "../../../../components/big-calendar/BigCalendar";

class StaffRotation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rotation: props.rotation,
            periods: props.rotation.periods,
            timeZone: TimezoneUtil.getTimeZoneForCountry(props.rotation.country),
            events: [],
            allowedRooms: [],
            roomMapByDate: {},
            editMode: false
        }
        this.moment = getMoment(this.state.timeZone);
        this.localizer = momentLocalizer(this.moment);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.rotation !== this.props.rotation) {
            this.setState({rotation: this.props.rotation}, () => this.refresh());
        }
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        const staffId = this.props.staffId;
        const periodStart = this.moment().startOf('week');
        const periodEnd = this.moment(periodStart).add(1, 'week');

        RemotingService.getRemoteCall(`api/staff/${staffId}/preference`, null, staffPreference => {
            const {events, roomMapByDate} = CalendarEventService.createEventsInPeriod(
                this.props.rooms, this.state.rotation, periodStart, periodEnd, {id: staffId});
            const allowedRoomIds = staffPreference ? extract(staffPreference.allowedRooms, "id") : [];
            this.setState({
                events,
                roomMapByDate,
                allowedRooms: this.props.rooms.filter(r => allowedRoomIds.includes(r.id))
            });
        });
    }

    moveEvent = ({event, start, end}) => {
        const startMoment = this.moment(start);
        const endMoment = this.moment(end);

        const theOtherEvents = this.state.events.filter(e => e !== event);
        for (let anotherEvent of theOtherEvents) {
            if (startMoment.isBefore(anotherEvent.endMoment) && endMoment.isAfter(anotherEvent.startMoment)) {
                NotificationService.showNotification({
                    severity: 'error',
                    summary: 'Validation Error',
                    detail: 'Overlapping blocks are not allowed.'
                });
                return;
            }
        }

        event.start = start;
        event.end = end;
        event.startMoment = this.moment(start);
        event.endMoment = this.moment(end);
        if (event.room || CalendarEventService.eventTypeRequiresRoom(event.type)) {
            event.room = this.getRoomByDate(start);
        }

        this.setState({
            events: [...theOtherEvents, {...event}]
        });
    }

    createEvent({start, end}) {
        if (DateUtil.getDurationInMinutes(start, end) < 10) {
            return;
        }

        dialog({
            title: "Add",
            component: <AddRotationBlockModal staffId={this.props.staffId}
                                              room={this.getRoomByDate(start)}
                                              start={start}
                                              end={end}
                                              timezone={this.state.timeZone}
                                              events={this.state.events}
                                              onAdd={(event) => {
                                                  if (!this.getRoomByDate(start)) {
                                                      this.setRoomToDate(start, event.room);
                                                  }
                                                  this.state.events.push(event);
                                                  this.setState({
                                                      events: [...this.state.events],
                                                      roomMapByDate: {...this.state.roomMapByDate}
                                                  })
                                              }}/>
        });
    }

    deleteEvent(event) {
        const events = this.state.events.filter(e => e !== event);
        this.setState({events});
    }

    getRoomByDate(date) {
        const dateString = DateUtil.formatDate(date, "YYYYMMDD");

        return this.state.roomMapByDate[dateString];
    }

    setRoomToDate(date, room) {
        const dateString = DateUtil.formatDate(date, "YYYYMMDD");
        this.state.roomMapByDate[dateString] = room;
    }

    startEditMode() {
        this.setState({editMode: true});
    }

    save() {
        const blocks = this.state.events.map(event => {
            return {
                type: event.type,
                dayOffset: CalendarEventService.toDayOffset(event.start),
                startTime: DateUtil.getHourMinute24H(event.startMoment),
                endTime: DateUtil.getHourMinute24H(event.endMoment),
                roomId: event.room ? event.room.id : null,
                isMerged: event.isMerged
            }
        });

        RemotingService.postRemoteCall(`api/staff/rotation/${this.state.rotation.id}/update`, blocks, rotation => {
            this.props.dispatch(getSchedules());
            this.setState({rotation, editMode: false}, () => this.refresh());
        });
    }

    cancel() {
        this.setState({editMode: false}, () => this.refresh());
    }

    render() {
        const timeZoneGetter = () => this.state.timeZone;
        const viewGetter = () => Views.WEEK;
        const currentPeriodIndex = this.props.currentPeriodIndex;
        const currentPeriod = this.state.periods[currentPeriodIndex];
        return (
            <div className="staff-rotation container" style={{fontSize: 'small', marginTop: 10, marginLeft: 10}}>
                <div className="row">
                    <div className="col d-flex justify-content-end">
                        {AuthService.userHasAuthority("UPDATE_ANY_PROVIDER") && (
                            this.state.editMode ?
                                <>
                                    <Button variant="primary" size="sm"
                                            onClick={this.save.bind(this)}>Save</Button>
                                    <Button variant="secondary" size="sm" className="ml-1"
                                            onClick={this.cancel.bind(this)}>Cancel</Button>
                                </> :
                                <FontAwesomeIcon icon={["fas", "edit"]} onClick={this.startEditMode.bind(this)}/>)
                        }
                    </div>
                </div>
                {this.props.isFixedRotation ? (
                    <div className="row">
                        <div className="col-2">
                            <div className="row">
                                <div className="col-6 dim14-form-label">
                                    Effective after:
                                </div>
                                <div className="col-6">
                                    {DateUtil.formatDateMMM(this.state.rotation.startDate)}
                                </div>
                            </div>
                        </div>
                    </div>) : (
                    <div className="row">
                        <div className="col-2">
                            <div className="row">
                                <div className="col-6 dim14-form-label">
                                    Period:
                                </div>
                                <div className="col-6">
                                    {currentPeriodIndex + 1}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 dim14-form-label">
                                    Effective after:
                                </div>
                                <div className="col-6">
                                    {DateUtil.formatDateMMM(this.state.rotation.startDate)}
                                </div>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="row">
                                <div className="col-6 dim14-form-label">
                                    Start Date:
                                </div>
                                <div className="col-6">
                                    {DateUtil.formatDateMMM(currentPeriod?.startDate)}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 dim14-form-label">
                                    End Date:
                                </div>
                                <div className="col-6">
                                    {DateUtil.formatDateMMM(currentPeriod?.endDate)}
                                </div>
                            </div>
                        </div>
                    </div>
                )
                }
                <div className="row">
                    <div className="col-12">
                        <BigCalendar
                            dragDrop={this.state.editMode}
                            style={{height: 1200}}
                            className="rbc-today-bg-none"
                            titleAccessor="patientName"
                            localizer={this.localizer}
                            formats={AppointmentCalendarFormats}
                            getNow={() => getNow(new Date(), this.moment)}
                            min={this.moment().hour(7).minute(0).toDate()}
                            max={this.moment().hour(21).minute(0).toDate()}
                            events={this.state.events}
                            defaultView={Views.WEEK}
                            date={this.moment().toDate()}
                            step={5}
                            timeslots={12}
                            resizableAccessor="resizable"
                            onEventDrop={this.moveEvent.bind(this)}
                            selectable={this.state.editMode}
                            onSelectSlot={this.createEvent.bind(this)}
                            components={{
                                toolbar: () => <></>,
                                event: AppointmentCalendarEventFactory({
                                    timeZoneGetter,
                                    viewGetter,
                                    covidGapExists: false,
                                    appointmentActionsVisible: false,
                                    eventContextMenuVisible: false,
                                    deleteVisible: this.state.editMode,
                                    deleteFunc: (event) => this.deleteEvent(event)
                                }),
                                week: {
                                    header: ({date, localizer}) => {
                                        const dateString = localizer.format(date, "YYYYMMDD");

                                        return <div>
                                            <div>{localizer.format(date, "dddd")}</div>
                                            {this.state.editMode ?
                                                <Dropdown appendTo={document.body}
                                                          style={{width: "100%", padding: 0}}
                                                          optionLabel="info"
                                                          options={this.state.allowedRooms}
                                                          value={this.state.roomMapByDate[dateString]}
                                                          onChange={e => this.setState(state => {
                                                              const selectedRoom = e.value;
                                                              state.roomMapByDate[dateString] = selectedRoom;
                                                              state.events
                                                                  .filter(event => (event.room || CalendarEventService.eventTypeRequiresRoom(event.type))
                                                                      && this.moment(event.start).isSame(date, "day"))
                                                                  .forEach(event => event.room = selectedRoom);
                                                              return {...state};
                                                          })}/> :
                                                <div>{this.state.roomMapByDate[dateString] ? this.state.roomMapByDate[dateString].info : null}</div>
                                            }
                                        </div>
                                    }
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

}

export default connect(null)(StaffRotation);
