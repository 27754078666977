import React, {Component} from 'react';
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import {RadioInput} from "../../../components";
import TimingEnum from "../../emr/recordpage/subjective/enums/complaint/TimingEnum";

class SymptomsWorseIn extends Component {
    render() {
        const {
            selectedLanguage, readOnly, enumLabel, onChange, symptomsAreWorseIn,
        } = this.props

        return (
            <div className="container">
                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Symptoms_Worse_In[selectedLanguage]}:
                    </div>
                    <div className="col-8 d-flex">
                        <RadioInput
                            id="symptomWorseRadioInputid"
                            value={symptomsAreWorseIn}
                            options={TimingEnum.toArray()}
                            valueProperty={"key"}
                            displayProperty={enumLabel}
                            disabled={readOnly}
                            onChange={(value) =>
                                onChange("symptomsAreWorseIn", value)
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default SymptomsWorseIn;