import React, {Component} from "react";
import '../../EMR.css';
import ExaminationEnum from "../enums/examination/ExaminationEnum";

export default class MSKDiagnosisView extends Component {
    state = {
    }

    render() {
        if(this.props.MSKDiagnosis == null
            || this.props.MSKDiagnosis.items == null
            || this.props.MSKDiagnosis.items.length == 0) {
            return null;
        }

        return (

            <div className="content-card">
                <div className="py-2 px-4" style={{fontSize:"14px"}}>
                    {this.props.MSKDiagnosis.items.map((item, index) => {
                        const examinationLocation = item.notApplicableBool? "Not Applicable" : (item.leftBool? "Left" : item.rightBool? "Right" : null) ;
                            return (
                                <div key={index} style={{marginBottom:"15px"}}>
                                    <div className={"row"} >
                                        <div className={"font-weight-bold col-3"}>
                                            Presenting Complaint :
                                        </div>
                                        <div className={"col-9"}>
                                            {item.presentingComplaint}
                                        </div>
                                    </div>
                                    <div className={"row"} >
                                        <div className={"font-weight-bold col-3"}>
                                            Examination :
                                        </div>
                                        {item.examination || item.examinationComment ?
                                            <div className={"col-9"}>
                                                {(item.examination ? ExaminationEnum[item.examination].name : item.examinationComment) + (examinationLocation? " - " + examinationLocation : "") }
                                            </div>
                                        :
                                        ''}
                                    </div>
                                    <div className={"row"} >
                                        <div className={"font-weight-bold col-3"}>
                                            Findings :
                                        </div>
                                        <div className={"col-9"}>
                                            {item.findings?.length > 1024 ? item.findings?.substring(0, 1024) + " ..." : item.findings}
                                        </div>
                                    </div>
                                    <div className={"row"} >
                                        <div className={"font-weight-bold col-3"}>
                                            Impression :
                                        </div>
                                        <div className={"col-9"}>
                                            {item.impression}
                                        </div>
                                    </div>
                                    <div className={"row"} >
                                        <div className={"font-weight-bold col-3"}>
                                            Diagnosis :
                                        </div>
                                        <div className={"col-9"}>
                                            {item.diagnosisCode} - {item.diagnosisDescription}
                                        </div>
                                    </div>
                                    <div className={"row"} >
                                        <div className={"font-weight-bold col-3"}>
                                            Recommendations :
                                        </div>
                                        <div className={"col-9"}>
                                            {item.recommendations}
                                        </div>
                                    </div>
                                </div>

                            );
                        })
                    }
                </div>
            </div>
        );
    }
}