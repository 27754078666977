import React, {Component} from "react";
import DateUtil from "../../../utils/DateUtil";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "react-bootstrap";
import RemotingService from "../../../services/remoting-service/RemotingService";
import {base64ToBlob, downloadBlob} from "../../../utils/FileDownloader";
import PrimeDateInput from "../../../components/date-input/PrimeDateInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class DayWiseReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: DateUtil.now()
        };
    }

    dailyReport() {
        const params = {
            date: DateUtil.formatIsoDate(DateUtil.startOfDay(this.state.date))
        };

        RemotingService.getRemoteCall('api/appointment/daily-report', params, (file) => {
            downloadBlob(base64ToBlob(file.data, file.contentType), file.name);
        });
    }

    render() {
        return (
            <div style={{width: 450}}>
                <div className="container">
                    <div className="row">
                        <div className="col-3 align-self-center">
                            Date
                        </div>
                        <div className="col-9">
                            <PrimeDateInput containerStyle={{width: "100%"}}
                                            value={this.state.date}
                                            onChange={date => this.setState({date})}/>
                        </div>
                    </div>
                </div>
                <DialogActions>
                    <Button variant="success" size="sm" onClick={() => this.dailyReport()}>
                        <FontAwesomeIcon icon={["fas", "file-invoice"]}/>
                        <span className="ml-2">Get Day Wise Report</span>
                    </Button>
                </DialogActions>
            </div>
        )
    }
}