import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RemotingService from '../../services/remoting-service/RemotingService';
import StaffCardView from './staffcardview/StaffCardView';
import StaffTableView from './stafftableview/StaffTableView';
import {Dropdown} from "primereact/dropdown";

export default class Staff extends Component {

    static CARD_VIEW = "card";
    static LIST_VIEW = "list";

    constructor(props) {
        super(props);
        this.state = {
            staffList: [],
            isActive: true
        };
    }

    componentDidMount() {
        this.retrieveStaffs();
    }

    retrieveStaffs() {
        const params = {
            isActive: this.state.isActive
        };

        RemotingService.getRemoteCall('/api/staff/list', params, (result) => {
            this.setState({staffList: result.items});
        });
    }

    render() {
        const selectedView = this.props.match.params.view ? this.props.match.params.view : Staff.CARD_VIEW;

        return (
            <>
                <div className={"container-fluid"}>
                <div className={"row"}>


                <div className={"col"}>
                    <h2>Providers</h2>
                </div>

                    <div className={"col-auto"}>
                        <ul className="list-unstyled list-inline">
                            <li className={"list-inline-item"}>
                                <Dropdown
                                options={[{label: "Active Users", value: true},{label: "Inactive Users", value: false}]}
                                optionLabel="label" optionValue="value"
                                value={this.state.isActive}
                                onChange={
                                    e => this.setState(
                                        {isActive: e.value},
                                        () => this.retrieveStaffs()
                                    )
                                }/>
                            </li>
                            <li className={"list-inline-item"}>
                                <Link to="/staff" className={"btn btn-outline-secondary " + (selectedView === Staff.CARD_VIEW ? "active" : "")}>
                                <FontAwesomeIcon icon={["fas", "address-card"]}/>
                                 </Link>
                            </li>
                            <li className={"list-inline-item"}>
                                <Link to="/staff/list" className={"btn btn-outline-secondary " + (selectedView === Staff.LIST_VIEW ? "active" : "")}>
                                    <FontAwesomeIcon icon={["fas", "list"]}/>
                                </Link>
                            </li>
                        </ul>


                    </div>



                </div>
                </div>
                {this.state.staffList.length ? this.renderView(selectedView) : null}
            </>
        )
    }

    renderView(selectedView) {
        return selectedView === Staff.LIST_VIEW ? <StaffTableView staffList={this.state.staffList}/> : <StaffCardView staffList={this.state.staffList}/>;
    }
}
