import "./VisitForms.css"
import React, {Component} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import VisitFormTypeEnum from "./VisitFormTypeEnum";
import DateUtil from "../../../../utils/DateUtil";
import DoubleSessionRequestForm from "../../../consentform/DoubleSessionRequestForm";
import UltrasoundDiagnosticForm from "../../../consentform/UltrasoundDiagnosticForm";
import PerinealPhysiotherapyConsentForm from "../../../consentform/PerinealPhysiotherapyConsentForm";
import IVInfusionConsentForm from "../../../consentform/IVInfusionConsentForm";
import ShockwaveTherapyConsentForm from "../../../consentform/ShockwaveTherapyConsentForm";
import MedicalPhotographyConsentForm from "../../../consentform/MedicalPhotographyConsentForm";
import InjectionConsentForm from "../../../consentform/InjectionConsentForm";
import {SelectInput} from "../../../../components";
import {Button} from "react-bootstrap";
import ViewIcon from "../../../../components/action-menu/icons/ViewIcon";
import QrIcon from "../../../../components/action-menu/icons/QrIcon";
import dialog from "../../../../components/dialog/Dialog";
import EditIcon from "../../../../components/action-menu/icons/EditIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ConsentFormCode from "../../../consentform/ConsentFormCode";
import FormLanguages from "../../../consentform/enum/FormLanguages";

export default class VisitForms extends Component {

    constructor(props) {
        super(props);

        this.state = {
            language: FormLanguages.ENGLISH,
            forms: []
        };
    }

    componentDidMount() {
        this.retrievePatient();
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.visitId != this.props.visitId) {
            this.retrieveVisitForms();
        }
    }

    retrievePatient = () => {
        RemotingService.getRemoteCall('api/patient/' + this.props.patientId, null, (patient) => {
            this.setState({patient}, this.retrieveVisitForms);
        });
    };

    retrieveVisitForms = () => {
        const {visitId} = this.props;
        RemotingService.getRemoteCall('api/visit/form/' + visitId, null, (visitFormsDto) => {
            const {forms, provider} = visitFormsDto; // To make this more readable
            this.setState({forms, provider});
        });
    }

    getForm = (item) => {
        return this.state.forms.find(form => form.type === item.key);
    }

    closeFormModal = () => {
        this.setState({selectedForm: undefined, selectedFormType: undefined});
    }

    nameTemplate = (form) => {
        return VisitFormTypeEnum[form.type].name;
    }

    viewTemplate = (form) => {
        return (
            <Button variant="light" onClick={() => this.setState({selectedForm: form, selectedFormType: form.type})}>
                <ViewIcon/> View
            </Button>
        );
    }

    formUpdateTimeTemplate = (form) => {
        return DateUtil.formatDateMonthAndYear(form.updateDate, 'DD MMMM YYYY');
    }


    statusTemplate = (item) => {
        const form = this.getForm(item);

        return form ? <FontAwesomeIcon icon={["fas", "check"]}/> : <></>;
    }

    viewEditTemplate = (item) => {
        const selectedForm = this.getForm(item);

        return (
            <Button variant="light" onClick={() => this.setState({selectedForm, selectedFormType: item.key})}>
                {selectedForm ? <><ViewIcon/> View</> : <><EditIcon/> Edit</>}
            </Button>
        );
    }

    generateCodeTemplate = (item) => {
        const formType = item.key;
        const {patientId, visitId} = this.props;
        const {language} = this.state;

        return (
            <Button variant="light"
                    onClick={() => {
                        RemotingService.postRemoteCall(
                            "api/consent-form/code",
                            {formType, patientId, visitId},
                            (code) => {
                                dialog({
                                    style: {width: 450, height: 150},
                                    component: <ConsentFormCode code={code} formName={item.name} language={language}/>,
                                    disableBackdropClick: false
                                });
                            }
                        );
                    }}>
                <QrIcon/> Generate Tablet Code
            </Button>);
    }

    lastUpdateTimeTemplate = (item) => {
        const form = this.getForm(item);
        return !form ? '-' :
            DateUtil.formatDateMonthAndYear(form.updateDate, 'DD MMMM YYYY');
    }

    render() {
        const {
            language: selectedLanguage,
            forms,
            selectedForm: form,
            selectedFormType: type,
            patient,
            provider: staff
        } = this.state;
        const {visitId, viewOnly} = this.props;

        const commonProps = {
            show: true,
            close: this.closeFormModal,
            afterSave: this.retrieveVisitForms,
            selectedLanguage, form, patient, staff, visitId
        };

        return (
            <div className="display-flex flex-column">
                <SelectInput
                    className="my-1"
                    showClear={false}
                    searchEnabled={false}
                    value={selectedLanguage}
                    options={FormLanguages.toArray()}
                    displayProperty="name"
                    onChange={(language) => this.setState({language})}
                />
                {viewOnly ?
                    <DataTable responsive autoLayout value={forms}>
                        <Column className="visit-reports-table col-4 border-0" header="Form"
                                body={this.nameTemplate}/>
                        <Column className="visit-reports-table col-2 border-0 text-center"
                                body={this.viewTemplate}/>
                        <Column className="visit-reports-table col-6 border-0 text-center" header="Last Updated"
                                body={this.formUpdateTimeTemplate}/>
                    </DataTable>
                    :
                    <DataTable responsive autoLayout value={VisitFormTypeEnum.toArray().filter(form => form[selectedLanguage?.name])}>
                        <Column className="visit-reports-table col-3 border-0" field="name" header="Form"/>
                        <Column className="visit-reports-table col-1 border-0  text-center" header="Status"
                                body={this.statusTemplate}/>
                        <Column className="visit-reports-table col-1 border-0"
                                body={this.viewEditTemplate}/>
                        <Column className="visit-reports-table col-2 border-0"
                                body={viewOnly ? <>></> : this.generateCodeTemplate}/>
                        <Column className="visit-reports-table col-5 border-0 text-center" header="Last Updated"
                                body={this.lastUpdateTimeTemplate}/>
                    </DataTable>
                }
                {type === 'DOUBLE_SESSION_REQUEST_FORM' && <DoubleSessionRequestForm {...commonProps}/>}
                {type === 'ULTRASOUND_DIAGNOSTIC_FORM' && <UltrasoundDiagnosticForm {...commonProps}/>}
                {type === 'INJECTION_CONSENT_FORM' && <InjectionConsentForm {...commonProps}/>}
                {type === 'PERINEAL_PHYSIOTHERAPY_CONSENT_FORM' && <PerinealPhysiotherapyConsentForm {...commonProps}/>}
                {type === 'IV_INFUSION_CONSENT_FORM' && <IVInfusionConsentForm {...commonProps}/>}
                {type === 'SHOCKWAVE_THERAPY_CONSENT_FORM' && <ShockwaveTherapyConsentForm {...commonProps}/>}
                {type === 'MEDICAL_PHOTOGRAPHY_CONSENT_FORM' && <MedicalPhotographyConsentForm {...commonProps}/>}
            </div>
        );
    }

}
