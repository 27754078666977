import React, {Component} from "react";
import {DateInput, Modal, NumberInput, SelectInput, TextInput} from "../../../components";
import RemotingService from "../../../services/remoting-service/RemotingService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";
import StringUtil from "../../../utils/StringUtil";
import DateUtil from "../../../utils/DateUtil";
import EnumGender from "../../../enums/EnumGender";
import PatientUtil from "../../../utils/PatientUtil";
import {connect} from "react-redux";

class PatientSearchModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            patientSuggestions: [],
            patientNameSearchText: this.props.patientNameSearchText ? this.props.patientNameSearchText : "",
            patientIdSearchText: this.props.patientIdSearchText ? this.props.patientIdSearchText : "",
            patientMobilePhoneSearchText: this.props.patientMobilePhoneSearchText ? this.props.patientMobilePhoneSearchText : "",
            patientBirthdaySearchText: this.props.patientBirthdaySearchText? this.props.patientBirthdaySearchText : null,
            queryOptions: {
                sortOrder: 1,
                orderDirection: "ASC",
                orderBy: "mrn.id",
                size: 10,
                page: 1
            }
        };
    }

    componentDidMount() {
        this.requestPatientData(1, 0);
    }

    requestPatientData = (page, first) => {

        let queryOptions = {...this.state.queryOptions};
        queryOptions.page = page;
        queryOptions.name = this.state.patientNameSearchText;
        queryOptions.mrn = this.state.patientMrnSearchText;
        queryOptions.mobilePhone = this.state.patientMobilePhoneSearchText;
        queryOptions.eid = this.state.patientIdSearchText;
        queryOptions.gender = this.state.patientGenderSearchText;
        queryOptions.age = this.state.patientAgeSearchText;
        queryOptions.birthday = this.state.patientBirthdaySearchText;
        queryOptions.nationality = this.state.patientNationalitySearchText?.id;

        RemotingService.getRemoteCall(`api/patient/list`,
            queryOptions, (result) => {

                let mappedPatientList = result.items.map((patient) => {
                    let mappedPatient = {...patient};
                    mappedPatient.age = patient.birthday ? DateUtil.getAge(patient.birthday) : '-';

                    return mappedPatient;
                });
                this.setState(state => {
                    let newState = {...state};
                    newState.queried = true;
                    newState.first = first;
                    newState.patientSuggestions = mappedPatientList;
                    newState.queryOptions.page = page;
                    newState.totalRecords = result.totalCount;
                    return newState;
                });
            });
    }

    handleQueryOptionChange(key, val, requestData=false) {
        this.setState((state) => {
            let newState = {...state};
            newState.queryOptions[key] = parseInt(val);
            return newState;
        }, () => {
            if(requestData){
                this.requestPatientData(1, 0);
            }
        });
    }

    clearQueryTerms = () => {
        this.setState(state =>{
            let newState = {...state};
            newState.patientSuggestions = [];
            newState.patientNameSearchText = "";
            newState.patientMrnSearchText = "";
            newState.patientMobilePhoneSearchText = "";
            newState.patientIdSearchText = "";
            newState.patientGenderSearchText = "";
            newState.patientAgeSearchText = "";
            newState.patientBirthdaySearchText = null;
            newState.patientNationalitySearchText = null;
            newState.queryOptions.name = "";
            newState.queryOptions.mrn = "";
            newState.queryOptions.id = "";
            newState.queryOptions.mobilePhone = "";
            newState.queryOptions.eid = "";
            newState.queryOptions.gender = "";
            newState.queryOptions.age = "";
            newState.queryOptions.birthday = "";
            newState.queryOptions.nationality = "";
            newState.queryOptions.page = 1;
            newState.totalRecords = 0;
            newState.first = 0;
            return newState;
        })
    }

    onPage(event) {
        let first = event.first;
        let page = Math.floor(first / this.state.queryOptions.size) + 1;
        this.requestPatientData(page, first)
    }

    onSort(event) {
        let queryOptions = {...this.state.queryOptions};
        queryOptions.orderBy = event.sortField;
        queryOptions.sortOrder = event.sortOrder;
        if (event.sortOrder === 1) {
            queryOptions.orderDirection = "ASC";
        } else if (event.sortOrder === -1) {
            queryOptions.orderDirection = "DESC";
        } else {
            queryOptions.orderDirection = null;
        }
        this.setState({queryOptions: queryOptions},() => {
            this.requestPatientData(this.state.queryOptions.page, this.state.first);
        });
    }

    render() {
        return <Modal visible
                      id="patient-search-modal"
                      header={this.props.header}
                      submitDisabled={!this.state.selectedPatient}
                      closeAction={this.props.close}
                      maxWidth={'lg'} >
            {this.renderModalBody()}
        </Modal>;
    }

    actionItemTemplate = (rowData) => {
        const SelectedIcon = this.props.selectIcon;
        rowData.isSamePatient = rowData.id == this.props.patientId;
        return (
            <div>
                {React.cloneElement(SelectedIcon,{onClick: () => this.props.handleFindPatientModalSelect(rowData)})}
            </div>)
    }

    genderTemplate(rowData, column) {
        return <>{EnumGender[rowData.gender]}</>;
    }


    nationalityTemplate(rowData, column) {
        return rowData.nationality?.name;
    }

    patientNameTemplate(rowData, column) {
        return PatientUtil.getPatientName(rowData.firstName, rowData.middleName, rowData.lastName)
    }

    renderModalBody() {
        return(
            <div className="FindPatientTable" >
                <div className={"container"}>
                    <div className={"row align-items-center"} style={{marginLeft: "-65px"}}>
                        <div className="col-md-1 dim14-form-label">
                            Patient Name
                        </div>
                        <div className="col-md-2 dim14-base-input">
                            <TextInput style={{maxWidth:250}} value={this.state.patientNameSearchText}
                                       alphabeticOnly
                                       onChange={(value) => this.setState({patientNameSearchText: value})}
                                       onKeyPress={event => {
                                           if(event.charCode == 13){
                                               event.preventDefault();
                                               event.stopPropagation();
                                               this.requestPatientData(1,0)
                                           }
                                       }}/>
                        </div>

                        <div className="col-md-1 dim14-form-label">
                            ID Number
                        </div>
                        <div className="col-md-2 dim14-base-input">
                            <TextInput style={{maxWidth:"150px"}} value={this.state.patientIdSearchText}
                                       onChange={(value) => this.setState({patientIdSearchText: value})}
                                       onKeyPress={event => {
                                           if(event.charCode == 13){
                                               event.preventDefault();
                                               event.stopPropagation();
                                               this.requestPatientData(1,0)
                                           }
                                       }}/>
                        </div>

                        <div className="col-md-1 dim14-form-label">
                            MRN
                        </div>
                        <div className="col-md-2 dim14-base-input">
                            <TextInput latinNumericOnly="true" style={{maxWidth:"150px"}} value={this.state.patientMrnSearchText}
                                       onChange={(value) => this.setState({patientMrnSearchText: value})}
                                       onKeyPress={event => {
                                           if(event.charCode == 13){
                                               event.preventDefault();
                                               event.stopPropagation();
                                               this.requestPatientData(1,0)
                                           }
                                       }}/>
                        </div>

                        <div className="col-md-1 dim14-form-label" style={{marginLeft: "-10px"}}>
                            Mobile Phone
                        </div>
                        <div className="col-md-2 dim14-base-input">
                            <TextInput style={{maxWidth:"250px"}} value={this.state.patientMobilePhoneSearchText}
                                       onChange={(value) => this.setState({patientMobilePhoneSearchText: value})}
                                       onKeyPress={event => {
                                           if(event.charCode == 13){
                                               event.preventDefault();
                                               event.stopPropagation();
                                               this.requestPatientData(1,0)
                                           }
                                       }}/>
                        </div>
                    </div>
                    <div className={"row align-items-center"} style={{marginLeft: "-65px"}}>
                        <div className="col-md-1 dim14-form-label">
                            Gender
                        </div>
                        <div className="col-md-2 dim14-base-input">
                            <SelectInput value={this.state.patientGenderSearchText}
                                         showClear={false}
                                         options={EnumGender.toArray()}
                                         displayProperty="name"
                                         valueProperty="key"
                                         onChange={(val) => this.setState({patientGenderSearchText: val})}
                            />
                        </div>


                        <div className="col-md-1 dim14-form-label">
                            Age
                        </div>
                        <div className="col-md-2 dim14-base-input">
                            <NumberInput style={{maxWidth: "150px"}} value={this.state.patientAgeSearchText}
                                         onChange={(value) => this.setState({patientAgeSearchText: value})}
                                         min={0}
                                         onKeyPress={event => {
                                             if (event.charCode == 13) {
                                                 event.preventDefault();
                                                 event.stopPropagation();
                                                 this.requestPatientData(1, 0)
                                             }
                                         }}/>
                        </div>

                        <div className="col-md-1 dim14-form-label">
                            Date of Birth
                        </div>
                        <div className="col-md-2 dim14-base-input">
                            <DateInput value={this.state.patientBirthdaySearchText} style={{minWidth:"150px", maxWidth:"150px"}}
                                       onChange={value => this.setState({patientBirthdaySearchText: value.format("YYYY-MM-DD")})} disableFuture="true"
                                       TextFieldComponent={
                                           ({onClick , value}) =>
                                               <Button style={{ width: '150px', height: 'auto !important', textAlign: 'left'}}
                                                       variant="outline-secondary" onClick={onClick}>
                                                   <FontAwesomeIcon icon={["fas", "calendar-alt"]} style={{paddingRight: '2px'}}/>{value}
                                               </Button>
                                       }/>
                        </div>

                        <div className="col-md-1 dim14-form-label" style={{marginLeft: "-10px"}}>
                            Nationality
                        </div>
                        <div className="col-md-2 dim14-base-input">
                            <SelectInput value={this.state.patientNationalitySearchText}
                                         style={{maxWidth:"250px"}}
                                         options={this.props.nationalities}
                                         displayProperty={"name"}
                                         onChange={(val) => this.setState({patientNationalitySearchText: val})}
                            />
                        </div>
                    </div>
                </div>
                <div style={{display:"flex", justifyContent:"flex-end", marginBottom:"5px"}}>
                    <Button variant={"primary"} size={"sm"} style={{minWidth:"120px", marginRight:"5px"}}
                            onClick={() => this.requestPatientData(1,0)}>
                        <FontAwesomeIcon icon={["fas", "search"]} size={"lg"}/> Query</Button>
                    <Button variant={"danger"} size={"sm"} style={{minWidth:"120px", marginLeft:"5px"}}
                            onClick={this.clearQueryTerms}>

                        <FontAwesomeIcon icon={["fas", "times-circle"]} size={"lg"}/> Clear</Button>
                </div>
                <DataTable id="FindPatientTable" value={this.state.patientSuggestions} rows={this.state.queryOptions.size}
                           paginator={true} paginatorPosition="bottom" lazy={true} first={this.state.first}
                           paginatorLeft={
                               <div className="findPatientPaginatorLeftDiv" >
                                   <span>Show:&nbsp;&nbsp;</span><SelectInput className="pageSizeSelectInput" value={this.state.queryOptions.size.toString()} options={["10", "25", "50", "100"]} searchEnabled={false}
                                                                              showClear={false} onChange={(value) => this.handleQueryOptionChange("size", value, true)} />
                                   <span>&nbsp;&nbsp;Total: {this.state.totalRecords} Records</span>
                               </div>
                           }
                           onPage={this.onPage.bind(this)} totalRecords={this.state.totalRecords}
                           onSort={this.onSort.bind(this)} sortField={this.state.queryOptions.orderBy} sortOrder={this.state.queryOptions.sortOrder}
                           emptyMessage={"There are no patients for selected criteria"}>

                    <Column field="mrn.id" header="MRN"  sortable style={{width: "100px"}}
                            body={(patient) => StringUtil.mrn(patient.mrn)}/>
                    <Column field="eid" header="ID" sortable body={(r) => PatientUtil.getPatientId(r)} style={{width: "175px", "word-wrap": "break-word"}}/>
                    <Column field="firstName" header="Patient Name" body={this.patientNameTemplate} sortable
                            style={{width: "175px"}}/>
                    <Column field="age" header="Age"  style={{width: "50px"}}/>
                    <Column field="gender" header="Gender" body={this.genderTemplate} sortable style={{width: "70px"}}/>
                    <Column field="birthday" header="Date of Birth" sortable style={{width: "110px"}}/>
                    <Column field="nationality.name" body={this.nationalityTemplate} header="Nationality"
                            style={{width: "150px"}} sortable/>
                    <Column field="mobilePhone" header="Mobile Phone" sortable style={{width: "150px"}}/>
                    <Column header="Action" body={this.actionItemTemplate}
                            style={{textAlign: 'center', width: "100px"}}/>
                </DataTable>
            </div>)
    }
}

function mapStateToProps(state) {
    return {
        nationalities: state.base.lookupNationalities
    };
}

export default connect(mapStateToProps)(PatientSearchModal);
