import React, {Component} from 'react';
import {FormSectionHeader} from "../../public/FormSectionHeader";
import IntakeFormTextEnum from "../enum/IntakeFormTextEnum";
import {YesNoRadioInput} from "../../public/YesNoRadioInput";

class PublicHadAnyXray extends Component {
    render() {
        const {enumLabel, textField, selectedLanguage, onChange, form} = this.props

        return (
            <>
                <FormSectionHeader header={IntakeFormTextEnum.Describe_Your_Symptoms[selectedLanguage]}/>

                <div className="row">
                    <div className="form-group">
                        <label className="col-form-label">
                            {IntakeFormTextEnum.Had_Any_Xray[selectedLanguage]}
                        </label>

                        <YesNoRadioInput
                            value={form.hadAnyXraysTaken}
                            onValueChange={(value) => {
                                onChange("hadAnyXraysTaken", value);
                            }}
                            enumLabel={enumLabel}
                        />
                    </div>
                </div>
                {"Yes" === form.hadAnyXraysTaken && (
                    <div className="row">
                        <div className="container-fluid p-0">
                            <div className="panel bg-grey px-2 py-0">
                                <div className="form-group">
                                    <label
                                        className="col-form-label">{IntakeFormTextEnum.Detail[selectedLanguage]}</label>
                                    {textField(form, "hadAnyXraysTakenDetails")}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default PublicHadAnyXray;