import "./LeaveCalendar.css";
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import React, {Component} from "react";
import {Button} from "react-bootstrap";
import RemotingService from "../../services/remoting-service/RemotingService";
import {DateInput, RadioInput, SelectInput, TextareaInput} from "../../components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EnumLeaveErrorCode from "./EnumLeaveErrorCode";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import EnumLeaveType from "./EnumLeaveType";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DateUtil from "../../utils/DateUtil";
import HRRemoteService from "./HRRemoteService";
import CalendarEventService from "../appointment/CalendarEventService";
import {noop} from "lodash-es";
import moment from "moment";
import {currentTimezone} from "../../utils/CalendarDateUtil";
import {FormField} from "../../components/form";
import LeaveCommentTable from "./LeaveCommentTable";
import ExtraDayUtil from "./util/ExtraDayUtil";

export default class FlexibleLeaveModal extends Component {

    constructor(props) {
        super(props);

        let {type, startTime, endTime, description, attachment, room} = this.props.leave;

        this.state = {
            type,
            startTime,
            endTime,
            description,
            attachment,
            room,
            isFlexibleHoursCompensation: false,
            blockEventsByDate: new Map(),
            flexibleStartTimes: [],
            flexibleEndTimes: [],
            durationExcludingLunch: null
        };
    }

    componentDidMount() {
        const start = DateUtil.startOfDay(this.state.startTime);
        const end = DateUtil.endOfDay(this.state.startTime);

        this.retrieveRoomList(this.state.startTime, this.state.endTime);
        this.retrieveBlockEvents(start, end, blockEventsByDate => {
            const {flexibleStartTimes, flexibleEndTimes} = this.getFlexibleOptions(this.state.startTime, blockEventsByDate);
            const startOption = flexibleStartTimes.find(s => DateUtil.isSame(this.state.startTime, s.value));
            const endOption = flexibleEndTimes.find(s => DateUtil.isSame(this.state.endTime, s.value));

            const newState = {
                blockEventsByDate,
                flexibleStartTimes,
                flexibleEndTimes
            };

            if (startOption) {
                newState.startTime = startOption.value;
            }

            if (endOption) {
                newState.endTime = endOption.value;
            }

            this.setState(newState);
        });
        this.calculateDurationExcludingBreaks();
    }

    calculateDurationExcludingBreaks = (field, val) => {
        const params = {
            startTime: field === "startTime" ? DateUtil.formatDateTime(val) : DateUtil.formatDateTime(this.state.startTime),
            endTime: field === "endTime" ? DateUtil.formatDateTime(val) : DateUtil.formatDateTime(this.state.endTime)};
        HRRemoteService.getDurationExcludingBreaks(this.props.leave.staffId, params, (result) => {
            this.setState({ durationExcludingLunch: DateUtil.formatHoursDuration(result / 60)});
        });
    }


    retrieveRoomList = (startDate, endDate) => {
        const params = {
            startDate: DateUtil.formatDate(startDate, "YYYY-MM-DD"),
            endDate: DateUtil.formatDate(endDate, "YYYY-MM-DD")
        };

        RemotingService.getRemoteCall('api/room/available-rooms', params, (resultList) => {
            let roomList = resultList.map(item => item.room)
            this.setState({roomList, room: null});
        });
    }

    retrieveBlockEvents = (periodStart, periodEnd, callback = noop) => {
        const staffId = this.props.leave.staffId;

        RemotingService.postRemoteCall(
            'api/staff/rotation/manual',
            {
                startDate: DateUtil.getUtcDateAtStartOfDay(periodStart),
                endDate: DateUtil.getUtcDateAtStartOfDay(periodEnd),
                staffIds: [staffId]
            },
            (manualRotations) => {
                const result = CalendarEventService.createEvents([], {id: staffId}, this.props.schedules, manualRotations, periodStart, periodEnd);

                let events = result.events;
                let blockEventsByDate = new Map();
                events.forEach(e => {
                    const key = DateUtil.formatDate(e.start);
                    if (blockEventsByDate.has(key)) {
                        blockEventsByDate.get(key).push(e);
                    } else {
                        blockEventsByDate.set(key, [e]);
                    }
                });

                callback(blockEventsByDate);
            });
    }

    setStartEndDates(date) {
        const {start, end} = ExtraDayUtil.calculateStartEndDates(date, this.props.timezone);
        this.setState({startTime: start, endTime: end, room: null});
        this.retrieveRoomList(date, date);
    }

    getFlexibleOptions(date, blockEventsByDate) {
        const flexibleStartTimes = [];
        const flexibleEndTimes = [];

        const key = DateUtil.formatDate(date);
        const events = blockEventsByDate.get(key);

        events && events.forEach(event => {
            const start = DateUtil.switchZone(moment.tz(event.start, event.nativeTimezone), currentTimezone).toDate();
            const end = DateUtil.switchZone(moment.tz(event.end, event.nativeTimezone), currentTimezone).toDate();
            flexibleStartTimes.push({
                label: DateUtil.getHourMinute24H(start),
                value: start
            });
            flexibleEndTimes.push({
                label: DateUtil.getHourMinute24H(end),
                value: end
            });
        });

        flexibleStartTimes.sort((o1, o2) => o1.label.localeCompare(o2.label));
        flexibleEndTimes.sort((o1, o2) => o1.label.localeCompare(o2.label));

        return {flexibleStartTimes, flexibleEndTimes};
    }

    openAnnualLeaveModal() {
        const {start, end} = ExtraDayUtil.calculateStartEndDates(this.state.startTime, this.props.timezone);

        this.props.openLeaveModal({
            type: "ANNUAL",
            status: 'PENDING',
            staffId: this.props.leave.staffId,
            meta: this.props.getMetaDataForType("ANNUAL", this.props.leave.staffId),
            startTime: start,
            endTime: end,
            start: start,
            end: end,
            description: "Flexi hours compensation",
            isFlexibleHoursCompensation: true
        });
        this.props.close();
    }

    handleDateInputChange = (field, val) => {
        this.setState({[field]: val});
        this.calculateDurationExcludingBreaks(field, val);
    }

    renderFlexibleSelectInput(field, options, className = "w-100") {
        return <SelectInput showClear={false}
                            searchEnabled={false}
                            disabled={this.props.leave.status !== "PENDING"}
                            className={className}
                            options={options}
                            displayProperty="label"
                            valueProperty="value"
                            value={this.state[field]}
                            onChange={(val) => this.handleDateInputChange(field, val)}/>
    }

    renderFlexibleCompensation() {
        const annualOption = EnumLeaveType.ANNUAL;
        const extraDayOption = EnumLeaveType.EXTRA;
        return <div style={{minWidth: '500px'}}>
            <div style={{marginBottom: "5px"}} className="col-12">
                You have <span className="font-weight-bold">0 Flexible Hours</span> available.<br/>
                Please select a compensation option for the used hours.
            </div>
            <div className="display-flex mb-1 align-items-center">
                <span className="circle-background"
                      style={{background: extraDayOption.color}}>{extraDayOption.code}</span>
                <RadioInput
                    id="extraDayOption"
                    value={this.state.compensationOption}
                    options={[{value: extraDayOption.key, label: "Apply for Extra Day"}]}
                    valueProperty={"value"}
                    displayProperty={"label"}
                    onChange={(value) => {
                        const {start, end} = ExtraDayUtil.calculateStartEndDates(this.state.startTime, this.props.timezone);
                        this.setState({
                            startTime: start,
                            endTime: end,
                            compensationOption: value,
                            isFlexibleHoursCompensation: true,
                        })
                    }}
                />
                <span className="circle-background" style={{background: annualOption.color}}>{annualOption.code}</span>
                <RadioInput
                    id="annualOption"
                    value={this.state.compensationOption}
                    options={[{value: annualOption.key, label: "Apply for Annual Leave"}]}
                    valueProperty={"value"}
                    displayProperty={"label"}
                    onChange={(value) => {
                        if (value) {
                            this.openAnnualLeaveModal();
                        }
                    }}
                />
            </div>
            {this.state.compensationOption === extraDayOption.key &&
            [
                <div className="display-flex mb-1 align-items-center">
                    <div style={{paddingLeft: 5}}>
                        <DateInput value={this.state.startTime}
                                   disabled={this.state.compensationOption === annualOption.key}
                                   onChange={(date) => this.setStartEndDates(date)}/>
                    </div>
                    <div style={{textAlign: "center", fontWeight: "bold"}}>to</div>
                    <div>
                        <DateInput value={this.state.endTime}
                                   disabled={this.state.compensationOption === annualOption.key}
                                   onChange={(date) => this.setStartEndDates(date)}/>
                    </div>
                </div>,
                <div className="ml-1 mb-1">
                    <div className="font-weight-bold">Select Room</div>
                    <FormField ref={formField => this.formFields.push(formField)}
                               required
                               validateOn={this.state.room}>
                        <SelectInput showClear={false}
                                     options={this.state.roomList}
                                     displayProperty="info"
                                     value={this.state.room}
                                     onChange={room => this.setState({room})}/>
                    </FormField>
                </div>
            ]
            }
        </div>
    }

    onSave = () => {
        if (ValidationUtil.checkWithNotification(this.formFields)) {
            if (this.state.compensationOption === EnumLeaveType.EXTRA.key) {
                this.props.leave.type = EnumLeaveType.EXTRA.key;
            }

            this.props.close();
            this.props.onClose();
            this.props.onSave({
                ...this.state,
                startTime: DateUtil.switchZone(this.state.startTime, this.props.timezone).toDate(),
                endTime: DateUtil.switchZone(this.state.endTime, this.props.timezone).toDate()
            });
        }
    }

    reject = () => {
        const id = this.props.leave.id;
        const params = {description: this.state.description};

        HRRemoteService.reject(id, params, (leave) => {
            this.props.close();
            this.props.onClose();
            this.props.onRejected(leave);
        });
    }

    delete = () => {
        const leave = this.props.leave;

        HRRemoteService.delete(leave, () => {
            this.props.close();
            this.props.onClose();
            this.props.onDeleted(leave);
        })
    }

    render() {
        const leave = this.props.leave;
        const newLeave = !leave.id;
        const params = EnumLeaveType[leave.type];
        const isAttachmentEnable = "PENDING" == leave.status || ("SUBMITTED" == leave.status && "SICK" == leave.type);

        const descriptionReadOnly = !this.props.isAdminPage && !newLeave && (leave.status === "SUBMITTED" || leave.status === "REJECTED" || leave.status === "APPROVED");
        const showRemaining = params.showRemaining !== false;
        const hasRemaining = leave.meta.remaining > 0;

        this.formFields = [];

        return (
            <div>
                <div className={"row justify-content-end mt-n2 mb-2"}>
                    <IconButton className={"p-0"} aria-label="close" onClick={() => {this.props.close(); this.props.onClose();}}>
                        <CloseIcon/>
                    </IconButton>
                </div>

                <div className="display-flex flex-column p-overflow-hidden LeaveModal" style={{minWidth: 300}}>

                    <div className="display-flex p-justify-between border-bottom border-top py-2 mb-3">
                        <div>
                                <span className="circle-background" style={{background: params.color}}>{params.code}
                                </span> <b>{params.name}</b>
                        </div>
                        {showRemaining ?
                            <div className="display-flex flex-column text-center"
                                 style={{backgroundColor: "#F9FBFC", fontWeight: "bold"}}>
                                <div className={'text-nowrap'}>
                                    <span>
                                        <span>{this.state.durationExcludingLunch}</span>
                                        <span>/</span>
                                        <span>{DateUtil.formatHoursDuration(Math.max(leave.meta.remaining, 0))}{params.unit}</span>
                                    </span>
                                </div>
                                <div style={{color: "#CDCFD0", fontSize: 10}}>
                                    REMAINING
                                </div>
                            </div> :
                            <div></div>
                        }
                    </div>

                    {!hasRemaining && leave.status === "PENDING" ?
                        this.renderFlexibleCompensation()
                        :
                        <div className="display-flex w-100 mb-1 align-items-center">
                            <div style={{paddingLeft: 5, width: '45%'}}>
                                {this.renderFlexibleSelectInput('startTime', this.state.flexibleStartTimes)}
                            </div>
                            <div className="mx-3" style={{textAlign: "center", fontWeight: "bold"}}>to</div>
                            <div style={{width: '45%'}}>
                                {this.renderFlexibleSelectInput('endTime', this.state.flexibleEndTimes)}
                            </div>
                        </div>
                    }
                    {leave.comments && <LeaveCommentTable comments={leave.comments}/>}
                    <div className="display-flex p-justify-between mb-2">
                        <div className="col-9" style={{fontSize: 13}}>
                            <div className={'font-weight-bold mb-1'}>Add Description</div>
                            <TextareaInput className="w-100" style={{minHeight: "100px"}}
                                           prefix={!!this.state.compensationOption ? "Flexi hours compensation" : undefined}
                                           disabled={descriptionReadOnly} value={this.state.description || ""}
                                           onChange={(description) => this.setState({description})}/>
                        </div>
                        <div className="col-3">
                            <label>
                                <input type="file" disabled={!isAttachmentEnable}
                                       id="upload-attachment"
                                       accept=".jpg, .jpeg, .png, .pdf, .xls, .xlsx, .doc, .docx, .txt"
                                       onChange={(e) => {
                                           const fileName = e.target.files[0].name;
                                           RemotingService.uploadFile(e.target.files[0], (response, err) => {
                                               if (err == null) {
                                                   this.setState({
                                                       attachment: {name: fileName, uuid: response.data}
                                                   });
                                               }
                                           });
                                       }}/>
                                <FontAwesomeIcon icon={["fas", "paperclip"]} size={"sm"}
                                                 className={isAttachmentEnable ? "cursor-pointer" : ""}
                                                 style={{float: "right", marginTop: 5}}/>
                                <br/>
                                <div style={{fontSize: 12, textAlign: "right"}}>
                                    {this.state.attachment ? this.state.attachment.name : 'Attachment'}</div>
                            </label>
                        </div>
                    </div>
                    {(leave.errors && leave.errors.length) ? <>
                        <div style={{padding: 0}}>
                            <hr className="solid"/>
                        </div>
                        <div style={{color: '#dc3545', fontSize: 12, paddingLeft: 5, marginBottom: 10}}>
                            {leave.errors.map((error, index) => <li key={index}>{EnumLeaveErrorCode[error].name}</li>)}
                        </div>
                    </> : <></>}

                    <DialogActions>
                        {!this.props.isAdminPage && newLeave &&
                        <Button variant={"light"} size={"sm"} onClick={() => {this.props.close(); this.props.onClose();}}>Discard</Button>
                        }
                        {!this.props.isAdminPage && leave.status === "PENDING" && (hasRemaining || this.state.compensationOption === EnumLeaveType.EXTRA.key) &&
                        <Button variant={"success"} size={"sm"} onClick={this.onSave}>Save</Button>
                        }
                        {this.props.isAdminPage && (leave.status === "SUBMITTED" || leave.status === "APPROVED" || leave.status === "REJECTED") &&
                        <Button variant={"light"} size={"sm"} onClick={this.delete}>Delete</Button>
                        }
                        {this.props.isAdminPage && (leave.status === "SUBMITTED" || leave.status === "APPROVED") &&
                        <Button variant={"light"} size={"sm"} onClick={this.reject}>Reject</Button>
                        }
                    </DialogActions>
                </div>

            </div>
        );
    }
}
