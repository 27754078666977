import Enum from "../../../../../../enums/Enum";

const ResultEnum = new Enum({
        NORMAL: {name: "Normal"},
        ABNORMAL: {name: "Abnormal"},
        POOR: {name: "Poor"},
        UNABLE: {name: "Unable"},
        SEVERE_DIFFICULTY: {name: "Severe difficulty"},
        PAINFUL: {name: "Painful"}
});

export default ResultEnum;