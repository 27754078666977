import React, {Component} from "react";
import DateUtil from "../../../../../utils/DateUtil";
import ObjectUtil from "../../../../../utils/ObjectUtil";

export default class ProcedureViewHistory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            procedures: this.getProcedures(props)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.procedures !== this.props.procedures) {
            this.setState({procedures: this.getProcedures(this.props)});
        }
    }

    getProcedures(props) {
        const procedures = ObjectUtil.clone(props.procedures);
        procedures.forEach(item => this.setGrossAndNet(item, item.cpt));
        return procedures;
    }

    setGrossAndNet(item, cpt) {
        item.gross = cpt.cash;
        item.net = cpt.cash;

        const {insurance, visit} = this.props;

        if (item.type == 'DB') {
            let cptInsurance;

            if (insurance && this.insuranceValid(insurance, visit.appointment.startTime) && insurance.tpa) {
                const insuranceName = insurance.tpa.name;
                cptInsurance = cpt.insuranceList.find(i => i.type === 'COMPANY' && i.insuranceCompanyId != null && i.insuranceCompanyId == insurance.company?.id); // company cpt is priority
                if (!cptInsurance) {
                    cptInsurance = cpt.insuranceList.find(i => i.insurance.toUpperCase() === insuranceName);
                }
            } else {
                cptInsurance = cpt.insuranceList.find(i => i.insuranceCompanyId != null && i.insuranceCompanyId == insurance.company?.id);
            }

            if (cptInsurance) {
                item.gross = cptInsurance.gross;
                item.net = cptInsurance.net;
            }
        }
    }

    insuranceValid = (insurance, date) => {
        return insurance.validityFrom && insurance.validityTo &&
            DateUtil.isBetweenInclusive(date, insurance.validityFrom, insurance.validityTo);
    }

    render() {
        return (
            <div className="mt-3">
                <div className="d-flex justify-content-between">
                    <h6 className={"dim14-form-label m-0"}>Procedure</h6>
                    <div style={{color: "#7BAC16", fontWeight: "bold"}}>
                        Bill Type: {this.props.billType}
                    </div>
                </div>
                <table className={"visit-history-table"}>
                    <tbody>
                    <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                        <th>Code</th>
                        <th>Description</th>
                        <th>Gross</th>
                        <th>Net</th>
                        <th>Quantity</th>
                        <th>Select For Billing</th>
                    </tr>
                    {this.state.procedures.map((procedure, index) =>
                        <tr key={"procedures" + index}>
                            <td>{procedure.cpt.cptCode}</td>
                            <td>{procedure.cpt.description}</td>
                            <td>{procedure.gross}</td>
                            <td>{procedure.net}</td>
                            <td>{procedure.quantity}</td>
                            <td>{procedure.approved ? "✓" : "X"}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        )
    }

}