import React, {Component} from 'react';
import {momentLocalizer, Views} from "react-big-calendar";
import moment from "moment";
import DateUtil from "../../utils/DateUtil";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./Appointment.css";
import TimeZoneSelector from "../../components/timezone/TimeZoneSelector";
import {Button, Col, Row} from "react-bootstrap";
import RemotingService from "../../services/remoting-service/RemotingService";
import {currentTimezone, dayFormat, getMoment, getNow} from '../../utils/CalendarDateUtil';
import AddAppointment from "./modals/AddAppointment";
import ViewAppointment from "./appointmentdetail/ViewAppointment";
import CancelAppointment from "./modals/CancelAppointment";
import confirmDialog from "../../components/dialog/ConfirmDialog";
import AgendaView from "./AgendaView";
import AppointmentClipBoard, {ClipOperation} from "./AppointmentClipBoard";
import AppointmentFilter from "./AppointmentFilter";
import {DateInput} from "../../components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ContextMenu from "../../components/context-menu/ContextMenu";
import ContextMenuItem from "../../components/context-menu/ContextMenuItem";
import CalendarEventService from "./CalendarEventService";
import queryString from 'query-string';
import {extract, onlyUnique} from "../../utils/ArrayUtil";
import ObjectUtil from "../../utils/ObjectUtil";
import NotificationService from "../../services/notification-service/NotificationService";
import AppointmentCalendarFormats from "./AppointmentCalendarFormats";
import EventAppointmentMap from "./EventAppointmentMap";
import AppointmentCalendarEventFactory from "./calendarevent/AppointmentCalendarEventFactory";
import RotationBlockRemoteService from "./RotationBlockRemoteService";
import PatientUtil from "../../utils/PatientUtil";
import AppointmentUtil from "../../utils/AppointmentUtil";
import StaffEventsMap from "./StaffEventsMap";
import AuthService from "../../services/auth-service/AuthService";
import EnumTimeZone from "../../enums/EnumTimeZone";
import clsx from "clsx";
import DeleteIcon from "../../components/action-menu/icons/DeleteIcon";
import dialog from "../../components/dialog/Dialog";
import AppointmentCalendarRoomInput from "./AppointmentCalendarRoomInput";
import {AddRotationBlockModal} from "../staff/staffprofile/schedule/AddRotationBlockModal";
import StaffRoomMap from "./StaffRoomMap";
import TimezoneUtil from "../../utils/TimezoneUtil";
import BigCalendar from "../../components/big-calendar/BigCalendar";
import EnumLeaveType from "../hr/EnumLeaveType";
import ActionMenu from "../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../components/action-menu/ActionMenuItem";
import CopyScheduleWizard from "./modals/CopyScheduleWizard";
import WaitingTimeReport from "./modals/WaitingTimeReport";
import DayWiseReport from "./modals/DayWiseReport";
import {connect} from "react-redux";

class Appointment extends Component {

    constructor(props) {
        super(props);
        const selectedDate = DateUtil.today().toDate();
        const selectedView = Views.AGENDA;
        const {startTime, endTime} = this.calculateStartEnd(selectedView, selectedDate);

        let schedulePromiseResolve; // to wait for schedules to arrive from redux
        this.schedulePromise = new Promise((resolve, reject) => schedulePromiseResolve = resolve);

        this.state = {
            loggedInUser: AuthService.getUser(),
            timeZone: AuthService.getSelectedClinicCountriesInfo().uaeSelected ? EnumTimeZone.GST.zone : EnumTimeZone.AST.zone,
            selectedDate: selectedDate,
            selectedView: selectedView,
            selectedStartTime: startTime,
            selectedEndTime: endTime,
            events: [],
            leaveEvents: [],
            appointments: [],
            rooms: [],
            selectedRooms: [], // room.info list
            specialities: [],
            selectedSpecialities: [], // speciality.name list
            staffs: [],
            selectedStaffs: [],
            displayedStaffs: [],
            appointment: [],
            scheduledOnly: false,
            staffRoomMap: new StaffRoomMap(),
            schedulePromiseResolve: schedulePromiseResolve
        }
    }

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        const selectedView = values.selectedView || Views.AGENDA;
        const highlightedAppointmentId = values.id;
        const selectedDate = values.selectedDate ? DateUtil.parse(values.selectedDate).utc(true).toDate() : DateUtil.today().toDate();
        const selectedStaffIds = this.getSelectedStaffIdsFromUrlParams(values);
        const {startTime, endTime} = this.calculateStartEnd(selectedView, selectedDate);

        this.retrieveInitialData(({rooms, specialities, staffs}) => {
            const selectedStaffs = selectedStaffIds.length > 0 ? staffs.filter(s => selectedStaffIds.includes(s.id)) :
                this.isAdmin() ? staffs : [{...this.state.loggedInUser, id: this.state.loggedInUser.staffId}];
            // Need to add speciality when selectedStaff exists ?

            this.setState({
                specialities,
                rooms,
                staffs,
                selectedStaffs,
                highlightedAppointmentId,
                selectedDate: selectedDate,
                selectedView: selectedView,
                selectedStartTime: startTime,
                selectedEndTime: endTime,
                filterHidden: values.filterHidden
            }, () => {
                this.refreshCalendarData();
            });
        })
    }

    static getDerivedStateFromProps(props, state) { // componentWillReceiveProps is deprecated
        if (state.schedulePromiseResolve && props.schedules?.length > 0) {
            state.schedulePromiseResolve();

            return {
                schedulePromiseResolve: undefined
            }
        }

        return state;
    }

    getSelectedStaffIdsFromUrlParams(values) {
        if (!values || Object.keys(values).length === 0) {
            return [];
        } else if (Array.isArray(values.selectedStaff)) {
            return values.selectedStaff.map(s => Number(s));
        } else {
            return [Number(values.selectedStaff)];
        }
    }

    getDisplayedStaffs = (selectedStaffs) => {
        return this.isDayViewSelected() ?
            ObjectUtil.sort(selectedStaffs, 'name') :
            selectedStaffs.length > 0 ? [selectedStaffs[0]] : [];
    }

    retrieveInitialData(callback) {
        const specialityPromise = new Promise((resolve, reject) => {
            RemotingService.getRemoteCall('api/speciality/list', null, (specialities) => {
                resolve(specialities);
            });
        });

        const roomPromise = new Promise((resolve, reject) => {
            RemotingService.getRemoteCall('api/room/list-by-country', null, (rooms) => {
                resolve(rooms);
            })
        });

        const staffPromise = new Promise((resolve, reject) => {
            RemotingService.getRemoteCall('api/staff/list-base', null, (result) => {
                resolve(result.items);
            })
        });

        Promise.all([specialityPromise, roomPromise, staffPromise, this.schedulePromise]).then((values) => {
            callback({specialities: values[0], rooms: values[1], staffs: values[2]});
        });
    }

    refreshCalendarData = () => {
        this.retrieveAppointments();
        this.retrieveLeaves();
        this.retrieveAvailableRoomList()

        if (this.isListViewSelected()) {
            this.setState({events: []});
            return;
        }

        const selectedStaffs = this.state.selectedStaffs.length > 0 ? this.state.selectedStaffs : [this.state.staffs[0]];
        let displayedStaffs = this.getDisplayedStaffs(selectedStaffs);
        const staffIds = displayedStaffs.map(staff => staff.id);
        const periodStart = moment(this.state.selectedStartTime).startOf('day');
        const periodEnd = moment(this.state.selectedEndTime).startOf('day');

        RemotingService.postRemoteCall(
            'api/staff/rotation/manual',
            {startDate: DateUtil.getUtcDateAtStartOfDay(periodStart), endDate: DateUtil.getUtcDateAtStartOfDay(periodEnd), staffIds: staffIds},
            (rotations) => {
                const {rooms} = this.state;
                const {schedules} = this.props;
                if (!schedules) {

                }

                let events = [];
                this.state.staffRoomMap.clear();
                StaffEventsMap.clear();

                displayedStaffs.forEach(staff => {
                    const manualRotationsForStaff = rotations.filter(rotation => rotation.staffId === staff.id);
                    const result = CalendarEventService.createEvents(rooms, staff, schedules, manualRotationsForStaff, periodStart, periodEnd);
                    events.push(...result.events);
                    this.state.staffRoomMap.putAll(staff.id, result.roomMapByDate);
                    StaffEventsMap.put(staff.id, result.events);
                });

                this.setState({
                    events: events,
                    staffRoomMap: this.state.staffRoomMap.clone(),
                    selectedStaffs: [...selectedStaffs],
                    displayedStaffs: [...(this.state.scheduledOnly ? this.filterOnDuty(displayedStaffs) : displayedStaffs)]
                });
            });
    }

    retrieveAppointments = () => {
        let params = {
            startTime: this.state.selectedStartTime.toISOString(),
            endTime: this.state.selectedEndTime.toISOString(),
            staffIds: extract(this.state.selectedStaffs, "id")
        };

        RemotingService.getRemoteCall('api/appointment/list', params, (result) => {
            const appointments = result.items;

            this.setState({
                appointments,
                selectedRooms: appointments.map(appointment => appointment.room.info).filter(onlyUnique),
                selectedSpecialities: appointments.map(appointment => appointment.staff.speciality.name).filter(onlyUnique),
                selectedPatients: appointments.map(appointment => PatientUtil.getPatientName(appointment.patient.firstName,
                    appointment.patient.middleName,
                    appointment.patient.lastName)).filter(onlyUnique)
            });
        });
    }

    retrieveLeaves = () => {
        const params = {
            staffIds: this.state.selectedStaffs.length > 0 ? extract(this.state.selectedStaffs, "id") : [this.state.staffs[0].id],
            //staffSpecialities: extract(this.state.selectedSpecialityList, "name") : null,
            startTime: this.state.selectedStartTime.toISOString(),
            endTime: this.state.selectedEndTime.toISOString(),
            ignoredStatuses: ['PENDING', 'REJECTED'],
            clinicIds: AuthService.getClinics()
        };

        RemotingService.getRemoteCall('api/hr/leave/list', params, (result) => {
            const leaves = result.items;

            const leaveEvents = [];
            leaves.forEach(leave => {
                const shouldEventCreated = !(leave.type === "EXTRA" && leave.status === "APPROVED");
                if (leave.type === "CLOSED") { // Create clinic closed leave events for multiple providers
                    const staffIds = leave.allProviders ? this.state.staffs.map(s => s.id) : leave.providerIds;
                    staffIds.forEach(staffId => {
                        leaveEvents.push(this.createLeaveEvent(leave, staffId));
                    });
                } else {
                    shouldEventCreated && leaveEvents.push(this.createLeaveEvent(leave));
                }
            });

            this.setState({leaveEvents: leaveEvents});
        });
    }

    retrieveAvailableRoomList = () => {
        let date = this.state.selectedDate;
        const params = {
            startDate: DateUtil.formatDate(date, "YYYY-MM-DD"),
            endDate: DateUtil.formatDate(date, "YYYY-MM-DD")
        };

        RemotingService.getRemoteCall('api/room/available-rooms', params, (resultList) => {
            let availableRooms = resultList.map(item => item.room)
            this.setState({availableRooms});
        });
    }

    createLeaveEvent(leave, staffId) {
        const isHourly = EnumLeaveType[leave.type].hourly;
        const isClose = leave.type === "CLOSED";
        return {
            staffId: isClose ? staffId : leave.staffId,
            start: moment(leave.startTime).toDate(),
            end: isHourly ?  // Work around fix for overflowing leave to next day.
                moment(leave.endTime).toDate() :
                moment(leave.endTime).add(-1, "millisecond").toDate(),
            type: leave.type,
            status: leave.status,
            isLeaveOverlayEvent: true,
            isCloseEvent: isClose
        };
    }

    updateSearchUrl = (selectedView, selectedDate, selectedStaffs) => {
        if (!this.props.history) {
            return; // should be in embedded mode (staff profile etc)
        }

        const selectedStaffUrlParam = selectedStaffs ? selectedStaffs.map(s => s.id).join("&selectedStaff=") : "";

        const searchText = `selectedView=${selectedView}&selectedDate=${selectedDate}&selectedStaff=${selectedStaffUrlParam}`;

        this.setState({highlightedAppointmentId: undefined}, () => {
            this.props.history.push({search: searchText});
        });
    }

    onFilterChange = (header, value) => {
        if (header === "Provider") {
            this.setState(
                {selectedStaffs: this.state.staffs.filter(s => value.includes(s.name))},
                () => {
                    this.updateSearchUrl(this.state.selectedView, DateUtil.formatDate(this.state.selectedDate), this.state.selectedStaffs);
                    this.refreshCalendarData();
                }
            );

        } else if (header === "Room") {
            this.setState({selectedRooms: [...value]});

        } else if (header === "Speciality") {
            this.setState({selectedSpecialities: [...value]});

        } else if (header === "Patient") {
            this.setState({selectedPatient: value});
        }
    }

    handleScheduledOnlyChanged(scheduledOnly) {
        const selectedStaffs = this.state.selectedStaffs.length > 0 ? this.state.selectedStaffs : [this.state.staffs[0]];
        let displayedStaffs = this.getDisplayedStaffs(selectedStaffs);

        if (scheduledOnly) {
            displayedStaffs = this.filterOnDuty(displayedStaffs);
        }

        this.setState({scheduledOnly, displayedStaffs});
    }

    filterOnDuty(staffs) {
        return staffs.filter(staff => {
            const events = StaffEventsMap.get(staff.id);
            return events && events.length > 0;
        });
    }

    calculateStartEnd(selectedView, selectedDate) {
        return AppointmentUtil.calculateStartEnd(selectedView, selectedDate);
    }

    handleViewChange(view) {
        let selectedStaffs = this.isAdmin() ? this.state.staffs : [{ ...this.state.loggedInUser, id: this.state.loggedInUser.staffId }];
        if(this.props.isStaffPreferencePage){
            const values = queryString.parse(this.props.location.search);
            const selectedStaffId = this.getSelectedStaffIdsFromUrlParams(values);
            selectedStaffs = [...this.state.staffs.filter(s => selectedStaffId.includes(s.id))]
        }
        if (view === Views.WEEK || view === Views.MONTH) {
            selectedStaffs = [selectedStaffs[0]];
        }

        const {startTime, endTime} = this.calculateStartEnd(view, this.state.selectedDate);
        this.setState({
            selectedStaffs: selectedStaffs,
            selectedView: view,
            selectedStartTime: startTime,
            selectedEndTime: endTime
        }, () => {
            this.updateSearchUrl(view, DateUtil.formatDate(this.state.selectedDate), this.state.selectedStaffs);
            this.refreshCalendarData();
        });
    }

    handleNavigation(date) {
        this.updateSearchUrl(this.state.selectedView, DateUtil.formatDate(date), this.state.selectedStaffs);
        const {startTime, endTime} = this.calculateStartEnd(this.state.selectedView, date);

        this.setState({
            selectedDate: date,
            selectedStartTime: startTime,
            selectedEndTime: endTime
        }, () => {
            this.refreshCalendarData();
        });
    }

    handleDrillDown(date, view) {
        this.handleViewChange(view);
        this.handleNavigation(this.convertClinicToLocalTimeZone(date));
    }

    convertClinicToLocalTimeZone = (date) => {
        const momentAtClinic = moment.tz(date, this.state.timeZone);
        return DateUtil.switchZone(momentAtClinic, currentTimezone).toDate();
    }

    viewAppointment = (appointment) => {
        this.setState({
            appointmentViewModalVisible: true,
            selectedAppointment: appointment
        });
    }

    handleEventSelected = (event) => {
        const appointment = EventAppointmentMap.get(event.id);
        if (appointment) {
            this.viewAppointment(appointment);
            return;
        }

        if (this.state.selectedView !== Views.DAY && this.state.selectedView !== Views.WEEK) {
            return;
        }

        if (this.leaveExists(event)) {
            return;
        }

        if (!AuthService.userHasAuthority("ADD_APPOINTMENT")) {
            return;
        }

        if (!event.room) {
            return;
        }

        if (event.type === "LUNCH"){
            return;
        }

        const {start, end, room} = event;
        const selectedAppointment = {
            start, end, room,
            staff: {...event.staff}
        };

        this.setState({
            appointmentAddModalVisible: true,
            selectedType: event.type,
            selectedAppointment: selectedAppointment
        });
    };

    handleAppointmentAdded = (appointment, existingPatient) => {
        if (existingPatient) {
            this.refreshCalendarData();

        } else {
            this.props.history.push(
                `/patientdetail/${appointment.patient.id}/${appointment.id}/${this.state.selectedView}/${DateUtil.formatDate(this.state.selectedDate)}`);
        }
    }

    closeAddAppointmentModal = () => {
        this.setState({appointmentAddModalVisible: false, selectedType: null, selectedAppointment: null});
    };

    handlePasteAppointment = (event) => {
        const clippedAppointment = this.state.clippedAppointment;
        const clipOperation = this.state.clipOperation;
        const {start, end, staff, room} = event;

        let dto = {
            startTime: start,
            endTime: end,
            staffId: staff.id,
            roomId: room.id,
            insuranceRecordId : clippedAppointment.insuranceRecordId
        };

        if (clipOperation === ClipOperation.COPY) {
            dto.patientId = clippedAppointment.patient.id;
            dto.patientType = clippedAppointment.patientType;
            dto.insuranceExists = clippedAppointment.insuranceExists;
            dto.note = clippedAppointment.note;
            dto.desiredStartTime = clippedAppointment.desiredStart;

            RemotingService.postRemoteCall('api/appointment/create-for-existing-patient', dto, () => {
                this.refreshCalendarData();
            });

        } else if (clipOperation === ClipOperation.CUT) {
            RemotingService.postRemoteCall('api/appointment/' + clippedAppointment.id + '/move', dto, () => {
                this.deleteClippedAppointment();
                this.refreshCalendarData();
            });
        }
    }

    handleViewAppointmentClose = () => {
        this.setState({appointmentViewModalVisible: false, selectedAppointment: null});
    }

    cancelAppointment = (appointment) => {
        this.setState({appointmentCancelModalVisible: true, selectedAppointment: appointment});
    }

    closeCancelAppointmentModal = () => {
        this.setState({appointmentCancelModalVisible: false, selectedAppointment: null});
    }

    handleAppointmentCancelled = () => {
        this.refreshCalendarData();
    }

    deleteAppointment = (appointment) => {
        confirmDialog("Delete Appointment",
            "Do you want to delete the appointment?",
            () => this.handleDeleteAppointment(appointment));
    }

    handleDeleteAppointment = (appointment) => {
        RemotingService.postRemoteCall(`api/appointment/${appointment.id}/delete`, null, () => {
            this.refreshCalendarData();
        });
    }

    confirmAppointment = (appointment) => {
        confirmDialog("Confirm Appointment",
            "Do you want to set appointment as Confirmed?",
            () => this.handleConfirmAppointment(appointment));
    }

    handleConfirmAppointment = (appointment) => {
        RemotingService.postRemoteCall(`api/appointment/${appointment.id}/confirm`, null, () => {
            appointment.status = "CONFIRMED";
            this.setState({
                events: [...this.state.events]
            });
        });
    }

    checkInAppointment = (appointment) => {
        confirmDialog("Check-in Appointment",
            "Do you want to set appointment as Checked-in?",
            () => this.handleCheckInAppointment(appointment));
    }

    handleCheckInAppointment = (appointment) => {
        RemotingService.postRemoteCall(`api/appointment/${appointment.id}/check-in`, null, () => {
            appointment.status = "ARRIVED";
            this.setState({
                events: [...this.state.events]
            });
        });
    }

    uncheckInAppointment = (appointment) => {
        confirmDialog("Undo Check-in Appointment",
            "Do you want to set appointment as Booked?",
            () => this.handleUncheckInAppointment(appointment));
    }

    handleUncheckInAppointment = (appointment) => {
        RemotingService.postRemoteCall(`api/appointment/${appointment.id}/uncheck-in`, null, () => {
            appointment.status = "BOOKED";
            this.setState({
                events: [...this.state.events]
            });
        });
    }

    isAppointmentCopied = (appointment) => {
        return this.state.clippedAppointment && this.state.clippedAppointment.id === appointment.id && ClipOperation.COPY === this.state.clipOperation;
    }

    isAppointmentCut = (appointment) => {
        return this.state.clippedAppointment && this.state.clippedAppointment.id === appointment.id && ClipOperation.CUT === this.state.clipOperation;
    }

    clipAppointment = (appointment, operation) => {
        this.setState({
            clippedAppointment: appointment,
            clipOperation: operation
        });
    }

    clippedAppointmentExists = () => {
        return !!this.state.clippedAppointment;
    }

    deleteClippedAppointment = () => {
        this.setState({
            clippedAppointment: undefined,
            clipOperation: undefined
        });
    }

    isAdmin() {
        return AuthService.isManagerOrSuperUser();
    }

    isListViewSelected = () => {
        return Views.AGENDA === this.state.selectedView;
    }

    isDayViewSelected = () => {
        return Views.DAY === this.state.selectedView;
    }

    isDayOrWeekViewSelected = () => {
        return this.state.selectedView === Views.DAY || this.state.selectedView === Views.WEEK;
    }

    moveEvent = ({event, resourceId: staffId, start, end}) => {
        const eventHasAppointment = EventAppointmentMap.get(event.id);

        const hostBlock = CalendarEventService.getHostBlockForStaff(this.state.events, start, staffId);
        if (hostBlock && this.leaveExists(hostBlock)) {
            return;
        }

        if (eventHasAppointment) {
            this.moveAppointment(event, staffId, start);

        } else {
            this.moveOrMergeBlock(event, staffId, start);
        }
    }

    moveAppointment = (selectedEvent, newStaffId, newStart) => {
        if (selectedEvent.staff.id == newStaffId && selectedEvent.startMoment.isSame(newStart)) {
            return;
        }

        const hostBlock = CalendarEventService.getHostBlockForStaff(this.state.events, newStart, newStaffId);

        if (selectedEvent === hostBlock || !hostBlock) { // Not moved or moved to empty date
            return;
        }

        if (hostBlock.type === "LUNCH" || hostBlock.type === "MANAGEMENT") {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Invalid slot.'
            });
            return;
        }

        const appointment = EventAppointmentMap.get(selectedEvent.id);
        const {start, end, staff, room} = hostBlock;
        const dto = {
            startTime: start,
            endTime: end,
            staffId: staff.id,
            roomId: room.id,
            insuranceRecordId : appointment.insuranceRecordId
        };

        RemotingService.postRemoteCall(`api/appointment/${appointment.id}/move`, dto, () => {
            this.refreshCalendarData();
        });
    }

    moveOrMergeBlock(selectedEvent, newStaffId, newStart) {
        if (selectedEvent.staff.id !== newStaffId || !selectedEvent.startMoment.isSame(newStart, "day")) {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Cannot move to another staff or day.'
            });
            return;
        }

        const hostBlock = CalendarEventService.getHostBlockForSameStaffAndDay(this.state.events, selectedEvent, newStart);

        if (selectedEvent === hostBlock) { // Not moved
            return;
        }

        if (!hostBlock) { // Moved to empty space
            return;
        }

        if (selectedEvent.startMoment.isSame(hostBlock.endMoment, 'minute')
            || selectedEvent.endMoment.isSame(hostBlock.startMoment, 'minute')) {
            const mouseEvent = {clientX: this.mouseX, clientY: this.mouseY}
            this.moveOrMergeMenu.show(mouseEvent, {selectedEvent, hostBlock, newStaffId, newStart});
        } else {
            this.moveBlock(selectedEvent, newStaffId, newStart);
        }
    }

    mergeBlock = (selectedEvent, hostBlock) => {
        RotationBlockRemoteService.mergeBlock(selectedEvent, hostBlock, () => {
            this.refreshCalendarData();
        });
    }

    moveBlock = (selectedEvent, newStaffId, newStart) => {
        RotationBlockRemoteService.moveBlock(this.state.events, selectedEvent, newStaffId, newStart, () => {
            this.refreshCalendarData();
        });
    }

    addBlock({start, end, resourceId}) {
        if (DateUtil.getDurationInMinutes(start, end) <= 5) {
            return;
        }

        dialog({
            scroll:"body",
            title: "Add Block",
            component: <AddRotationBlockModal staffId={resourceId}
                                              room={this.state.staffRoomMap.get(resourceId, DateUtil.formatTzDate(start, this.state.timeZone, "YYYYMMDD"))}
                                              start={start}
                                              end={end}
                                              timezone={this.state.timeZone}
                                              events={this.state.events}
                                              onAdd={(event) => {
                                                  if (this.leaveExists(event)) {
                                                      NotificationService.showNotification({
                                                          severity: 'error',
                                                          summary: 'Validation Error',
                                                          detail: 'Leave exists at selected time.'
                                                      });
                                                      return;
                                                  }

                                                  RotationBlockRemoteService.addBlock({
                                                      date: start,
                                                      timezone: event.room ?
                                                          TimezoneUtil.getClinicZoneInfo(event.room.clinicName).zone :
                                                          this.state.timeZone,
                                                      ...event
                                                  }, () => {
                                                      this.refreshCalendarData();
                                                  });
                                              }}/>
        });
    }

    changeRoom(staffId, room, date, localizer) {
        if (room) {
            RotationBlockRemoteService.changeRoom(staffId, room.id, localizer.format(date, 'YYYY-MM-DD'), () => {
                this.refreshCalendarData();
            });
        }
    }

    deleteBlock(event) {
        confirmDialog("Delete Block",
            "Do you want to delete the block?",
            () => {
                RotationBlockRemoteService.deleteBlock(event, () => {
                    this.refreshCalendarData();
                });
            });
    }

    showCopyScheduleWizard() {
        this.setState({copyScheduleVisible: true});
    }

    closeCopyScheduleWizard() {
        this.setState({copyScheduleVisible: false}, () => this.refreshCalendarData());
    }

    waitingTimeReport() {
        dialog({
            title: "Waiting Time Report",
            component: <WaitingTimeReport/>
        });
    }

    dailyReport() {
        dialog({
            title: "Day Wise Report",
            component: <DayWiseReport/>
        });
    }

    showAppointmentContextMenu = (e, appointment) => {
        this.appointmentContextMenu.show(e, appointment);
    }

    showEventContextMenu = (e, event) => {
        this.eventContextMenu.show(e, event);
    }

    handleMouseMove(e) {
        this.mouseX = e.clientX;
        this.mouseY = e.clientY;
    }

    handleDragStart() {
        document.body.addEventListener("mousemove", this.handleMouseMove.bind(this));
    }

    handleDragOver() {
        document.body.removeEventListener("mousemove", this.handleMouseMove.bind(this));
    }

    leaveExists({start, end, staffId}) {
        for (const leave of this.state.leaveEvents) {
            if (staffId === leave.staffId
                && DateUtil.isBefore(start, leave.end)
                && DateUtil.isAfter(end, leave.start)) {

                return true;
            }
        }
        return false;
    }

    eventPropGetter(event) {
        const eventProps = {
            style: {}
        };

        if (event.isLeaveOverlayEvent) {
            eventProps.style.zIndex = 2;
            if (event.isCloseEvent) {
                eventProps.style.zIndex = 3;
            }
        } else if (EventAppointmentMap.get(event.id)) {
            eventProps.style.zIndex = 3;
        }

        return eventProps;
    }

    render() {
        const moment = getMoment(this.state.timeZone);
        const localizer = momentLocalizer(moment);
        const appointmentActions = {
            cancelAppointment: this.cancelAppointment,
            confirmAppointment: this.confirmAppointment,
            checkInAppointment: this.checkInAppointment,
            uncheckInAppointment: this.uncheckInAppointment,
            deleteAppointment: this.deleteAppointment,
            showAppointmentContextMenu: this.showAppointmentContextMenu,
            showEventContextMenu: this.showEventContextMenu
        };

        let filteredEvents = this.state.events; // filtering events results in unlogical flows !

        // Beware Array.includes fails sometimes for string items
        const filteredAppointments = this.state.appointments.filter(appointment =>
            (!this.state.selectedPatient || PatientUtil.getPatientName(appointment.patient.firstName,
                appointment.patient.middleName, appointment.patient.lastName).toLowerCase().includes(this.state.selectedPatient.toLowerCase()))
            && this.state.selectedStaffs.filter(s => s.name === appointment.staff.name).length > 0
            && this.state.selectedRooms.filter(r => r === appointment.room.info).length > 0
            && this.state.selectedSpecialities.filter(s => s === appointment.staff.speciality.name).length > 0
        );

        EventAppointmentMap.clear();

        filteredAppointments.forEach(appointment => {
            filteredEvents.forEach((event, eventIndex) => {
                event.id = eventIndex;
                if (appointment.status !== 'NO_SHOW' && appointment.status !== 'CANCELLED'
                    && event.staff.id == appointment.staff.id
                    && !!event.room
                    && event.room.id == appointment.room.id
                    && DateUtil.isSame(event.start, appointment.start, "minute")
                    && DateUtil.isSame(event.end, appointment.end, "minute")
                ) {
                    EventAppointmentMap.put(event.id, appointment);
                }
            });
        });

        filteredEvents = [...filteredEvents, ...this.state.leaveEvents];

        const filterEnabled = !this.state.filterHidden;

        return (
            <>
                <Row style={{marginRight: 0, marginLeft: 0}}>
                    <Col sm={4}>
                        <div style={{display: "inline-block", fontWeight: 'bold', fontSize: '18px'}}>
                            {DateUtil.formatDate(getNow(new Date(), moment), "dddd MMMM Do YYYY")}
                        </div>
                    </Col>
                    <Col sm={2}>
                        <AppointmentClipBoard
                            appointment={this.state.clippedAppointment}
                            operation={this.state.clipOperation}
                            onClick={this.viewAppointment}
                            onDelete={this.deleteClippedAppointment}
                        />
                    </Col>
                    <Col sm={6} className="d-flex justify-content-end">
                        <TimeZoneSelector
                            value={this.state.timeZone === "Asia/Dubai" ? "GST" : "AST"}
                            onChange={(value) => {
                                if (value) {
                                    this.setState({
                                        events: ObjectUtil.clone(this.state.events),
                                        timeZone: EnumTimeZone[value].zone
                                    });
                                }
                            }}/>
                        <DateInput variant="dialog" value={this.state.selectedDate}
                                   onChange={date => this.handleNavigation(date.toDate())}
                                   TextFieldComponent={
                                       ({onClick}) =>
                                           <Button style={{borderRadius: '35px', height: '35px', marginTop: '3px'}}
                                                   variant="outline-secondary" onClick={onClick}>
                                               <FontAwesomeIcon icon={["fas", "calendar-alt"]}/> Go to Date
                                           </Button>
                                   }>
                        </DateInput>
                    </Col>
                </Row>

                <Row style={{marginRight: 0, marginLeft: 0}}>
                    {filterEnabled &&
                    <Col sm={2}>
                        <AppointmentFilter view={this.state.selectedView}
                                           selectedPatients={this.state.selectedPatients}
                                           rooms={this.state.rooms}
                                           selectedRooms={this.state.selectedRooms}
                                           specialities={this.state.specialities}
                                           selectedSpecialities={this.state.selectedSpecialities}
                                           staffs={this.state.staffs}
                                           selectedStaffs={this.state.selectedStaffs.map(s => s.name)}
                                           onFilterChange={this.onFilterChange}
                                           additionalItemLabel={this.isDayViewSelected() ? "Scheduled Only" : null}
                                           additionalItemChecked={this.state.scheduledOnly}
                                           additionalItemOnClick={event => this.handleScheduledOnlyChanged(event.target.checked)}
                        />
                    </Col>}
                    <Col className={"appointment-wrapper"} sm={filterEnabled ? 10 : 12}>
                        <BigCalendar
                            dragDrop={this.state.selectedView !== Views.MONTH}
                            showMultiDayTimes
                            style={{minWidth: 1150, minHeight: this.state.selectedView === Views.MONTH ? 1400 : 2800}}
                            className={clsx("appointment-calendar", "rbc-show-header-single-day", this.state.selectedView === Views.DAY ? "rbc-today-bg-none" : null)}
                            titleAccessor="patientName"
                            localizer={localizer}
                            formats={AppointmentCalendarFormats}
                            getNow={() => getNow(new Date(), moment)}
                            min={moment().hour(7).minute(0).utcOffset('GST' === this.state.timeZone ? 4 : 3).toDate()}
                            max={moment().hour(23).minute(0).utcOffset('GST' === this.state.timeZone ? 4 : 3).toDate()}
                            events={filteredEvents}
                            appointments={filteredAppointments}
                            appointmentActions={appointmentActions}
                            view={this.state.selectedView}
                            selectedView={this.state.selectedView}
                            defaultView={this.state.selectedView}
                            date={this.state.selectedDate}
                            defaultDate={this.state.selectedDate}
                            onView={view => this.handleViewChange(view)}
                            onNavigate={date => this.handleNavigation(date)}
                            onDrillDown={(date, view) => this.handleDrillDown(date, view)}
                            step={5}
                            timeslots={12}
                            onSelectEvent={event => this.handleEventSelected(event)}
                            resizableAccessor={event => false}
                            draggableAccessor={event => !event.isLeaveOverlayEvent && !this.leaveExists(event)}
                            eventPropGetter={event => this.eventPropGetter(event)}
                            onEventDrop={this.moveEvent.bind(this)}
                            onDragStart={this.handleDragStart.bind(this)}
                            onDragOver={this.handleDragOver.bind(this)}
                            selectable={this.isAdmin() && this.isDayOrWeekViewSelected()}
                            onSelectSlot={this.addBlock.bind(this)}
                            views={{agenda: AgendaView, day: true, week: true, month: true}}
                            messages={{
                                agenda: 'List'
                            }}
                            components={{
                                event: AppointmentCalendarEventFactory({
                                    timeZoneGetter: () => this.state.timeZone,
                                    viewGetter: () => this.state.selectedView,
                                    highlightedAppointmentIdGetter: () => this.state.highlightedAppointmentId,
                                    refreshCalendarData: () => this.refreshCalendarData(),
                                    appointmentActionsVisible: this.state.selectedView !== Views.MONTH,
                                    eventContextMenuVisible: this.state.selectedView !== Views.MONTH,
                                    leaveExists: this.leaveExists.bind(this),
                                    staffRoomMapGetter: () => this.state.staffRoomMap,
                                    ...appointmentActions
                                }),
                                week: {
                                    header: ({date, localizer}) =>
                                        <div>
                                            <div>{dayFormat(date, null, localizer)}</div>
                                            <AppointmentCalendarRoomInput
                                                disabled={!this.isAdmin()}
                                                rooms={this.state.availableRooms}
                                                date={date} timezone={this.state.timeZone} staffRoomMap={this.state.staffRoomMap}
                                                onChange={(room, staffId) => this.changeRoom(staffId, room, date , localizer)}/>
                                        </div>
                                },
                                day: {
                                    header: ({date, localizer}) =>
                                        <AppointmentCalendarRoomInput
                                            disabled={!this.isAdmin()}
                                            rooms={this.state.availableRooms}
                                            date={date} timezone={this.state.timeZone} staffRoomMap={this.state.staffRoomMap}
                                            onChange={(room, staffId) => this.changeRoom(staffId, room, date , localizer)}/>
                                }
                            }}
                            toolbarActions={this.isAdmin() &&
                                <ActionMenu icon={<FontAwesomeIcon icon={["fas", "cog"]}/>}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}>
                                    <ActionMenuItem text="Copy Schedule" icon={<FontAwesomeIcon icon={["fas", "calendar-alt"]}/>}
                                                    onClick={() => this.showCopyScheduleWizard()}/>
                                    <ActionMenuItem text="Waiting Time Report" icon={<FontAwesomeIcon icon={["fas", "file-excel"]}/>}
                                                    onClick={() => this.waitingTimeReport()}/>
                                    <ActionMenuItem text="Day Wise Report" icon={<FontAwesomeIcon icon={["fas", "file-invoice"]}/>}
                                                    onClick={() => this.dailyReport()}/>
                                </ActionMenu>
                            }
                            resources={this.state.displayedStaffs}
                            monthViewResource={this.state.displayedStaffs[0]}
                            resourceAccessor="staffId"
                            resourceIdAccessor="id"
                            resourceTitleAccessor={staff => <div className="custom-resource-header" id={staff.id}>
                                <div>{staff.specialityName}</div>
                                <div>{staff.name}</div>
                            </div>}
                        />
                    </Col>
                </Row>

                {this.state.appointmentAddModalVisible &&
                <AddAppointment
                    staff={this.state.selectedAppointment.staff}
                    room={this.state.selectedAppointment.room}
                    start={this.state.selectedAppointment.start}
                    end={this.state.selectedAppointment.end}
                    patientType={this.state.selectedType}
                    onAppointmentAdded={this.handleAppointmentAdded}
                    close={this.closeAddAppointmentModal}
                />
                }

                {this.state.appointmentViewModalVisible &&
                <ViewAppointment
                    selectedAppointment={this.state.selectedAppointment}
                    selectedView={this.state.selectedView}
                    selectedDate={this.state.selectedDate}
                    handleViewAppointmentClose={this.handleViewAppointmentClose}
                />
                }

                {this.state.appointmentCancelModalVisible &&
                <CancelAppointment
                    appointmentId={this.state.selectedAppointment.id}
                    onAppointmentCancelled={this.handleAppointmentCancelled}
                    close={this.closeCancelAppointmentModal}
                />
                }

                {this.state.copyScheduleVisible &&
                <CopyScheduleWizard close={() => this.closeCopyScheduleWizard()}
                                    timeZoneGetter={() => this.state.timeZone}/>
                }

                <ContextMenu ref={el => this.appointmentContextMenu = el}>
                    <ContextMenuItem icon={<FontAwesomeIcon icon={["fas", "copy"]} style={{color: "gray"}}/>}
                                     label="Copy"
                                     onClick={appointment => this.clipAppointment(appointment, ClipOperation.COPY)}
                                     visible={appointment => !this.isAppointmentCopied(appointment)}/>
                    <ContextMenuItem icon={<FontAwesomeIcon icon={["fas", "copy"]} style={{color: "lightgray"}}/>}
                                     label="Undo Copy"
                                     onClick={this.deleteClippedAppointment}
                                     visible={appointment => this.isAppointmentCopied(appointment)}/>
                    <ContextMenuItem icon={<FontAwesomeIcon icon={["fas", "cut"]} style={{color: "gray"}}/>}
                                     label="Cut"
                                     onClick={appointment => this.clipAppointment(appointment, ClipOperation.CUT)}
                                     visible={appointment => !this.isAppointmentCut(appointment)}/>
                    <ContextMenuItem icon={<FontAwesomeIcon icon={["fas", "cut"]} style={{color: "lightgray"}}/>}
                                     label="Undo Cut"
                                     onClick={this.deleteClippedAppointment}
                                     visible={appointment => this.isAppointmentCut(appointment)}/>
                </ContextMenu>

                <ContextMenu ref={el => this.eventContextMenu = el}>
                    <ContextMenuItem icon={<DeleteIcon/>}
                                     label="Delete Block"
                                     onClick={(event) => this.deleteBlock(event)}
                                     visible={this.isAdmin() && this.isDayOrWeekViewSelected()}/>
                    <ContextMenuItem icon={<FontAwesomeIcon icon={["fas", "paste"]}/>}
                                     label="Paste Appointment"
                                     onClick={(event) => this.handlePasteAppointment(event)}
                                     visible={this.clippedAppointmentExists()}/>
                </ContextMenu>

                <ContextMenu ref={el => this.moveOrMergeMenu = el}>
                    <ContextMenuItem icon={<FontAwesomeIcon icon={["fa", "clone"]}/>}
                                     label="Move"
                                     onClick={({selectedEvent, newStaffId, newStart}) => this.moveBlock(selectedEvent, newStaffId, newStart)}/>
                    <ContextMenuItem icon={<FontAwesomeIcon icon={["fa", "compress"]}/>}
                                     label="Merge"
                                     onClick={({selectedEvent, hostBlock}) => this.mergeBlock(selectedEvent, hostBlock)}/>
                </ContextMenu>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        schedules: state.base.schedules
    };
};

export default connect(mapStateToProps)(Appointment);
