import React, {Component} from "react";
import RotationBlockRemoteService from "../RotationBlockRemoteService";
import {DialogActions, Slider} from "@material-ui/core";
import {Button} from "react-bootstrap";
import DateUtil from "../../../utils/DateUtil";
import FormField from "../../../components/form/FormField";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";

export default class SplitAvailableSlotModal extends Component {

    constructor(props) {
        super(props);
        const eventDurationInMinutes = DateUtil.getDurationInMinutes(props.event.startMoment, props.event.endMoment);

        this.state = {
            minutesToSplit: 15,
            eventDurationInMinutes: eventDurationInMinutes,
            minSplitTime: DateUtil.getHourMinute24H(DateUtil.moment(props.event.startMoment).add(15, "minutes")),
            maxSplitTime: DateUtil.getHourMinute24H(DateUtil.moment(props.event.endMoment).subtract(15, "minutes"))
        }

        this.marks = [
            {
                value: 0,
                label: DateUtil.getHourMinute24H(props.event.startMoment),
            },
            {
                value: eventDurationInMinutes,
                label: DateUtil.getHourMinute24H(props.event.endMoment),
            }
        ];
    }

    split() {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        RotationBlockRemoteService.splitBlock(this.props.event, this.state.minutesToSplit, () => {
            this.props.close();
            this.props.onSplit();
        });
    }

    render() {
        this.formFields = [];
        const {event} = this.props;

        return (
            <div className="container mt-4" style={{width: 400}}>
                <div className="row">
                    <div className="col-4 dim14-form-label">Split time</div>
                    <div className="col-8">
                        <FormField ref={field => this.formFields.push(field)}
                                   validateOn={this.state.minutesToSplit}
                                   required min={15} max={this.state.eventDurationInMinutes - 15}
                                   minMessage={"Field should be after or equal to " + this.state.minSplitTime}
                                   maxMessage={"Field should be before or equal to " + this.state.maxSplitTime}>
                            <Slider defaultValue={15}
                                    step={5}
                                    min={0}
                                    max={this.state.eventDurationInMinutes}
                                    valueLabelDisplay="on"
                                    valueLabelFormat={value => DateUtil.getHourMinute24H(DateUtil.moment(event.startMoment).add(value, "minutes"))}
                                    marks={this.marks}
                                    value={this.state.minutesToSplit}
                                    onChange={(event, value) => {
                                        this.setState({minutesToSplit: value});
                                    }}/>
                        </FormField>
                    </div>
                </div>
                <DialogActions>
                    <Button onClick={() => this.split()}>Split</Button>
                </DialogActions>
            </div>
        )
    }

}