import React, {Component} from "react";
import {Dropdown} from "primereact/dropdown";
import RemotingService from "../../../services/remoting-service/RemotingService";
import PrimeDateInput from "../../../components/date-input/PrimeDateInput";
import {Wizard} from "../../../components";
import BigCalendar from "../../../components/big-calendar/BigCalendar";
import {momentLocalizer, Views} from "react-big-calendar";
import AppointmentCalendarFormats from "../AppointmentCalendarFormats";
import {getMoment, getNow} from "../../../utils/CalendarDateUtil";
import {FormField} from "../../../components/form";
import DateUtil from "../../../utils/DateUtil";
import SchedulingService from "../../../services/scheduling-service/SchedulingService";
import AppointmentCalendarEventFactory from "../calendarevent/AppointmentCalendarEventFactory";
import ContextMenu from "../../../components/context-menu/ContextMenu";
import ContextMenuItem from "../../../components/context-menu/ContextMenuItem";
import DeleteIcon from "../../../components/action-menu/icons/DeleteIcon";
import dialog from "../../../components/dialog/Dialog";
import {AddRotationBlockModal} from "../../staff/staffprofile/schedule/AddRotationBlockModal";
import "./CopyScheduleWizard.css";
import TimezoneUtil from "../../../utils/TimezoneUtil";
import CalendarEventService from "../CalendarEventService";
import moment from "moment";
import ReduxService from "../../../services/redux-service/ReduxService";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";

export default class CopyScheduleWizard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            providers: [],
            availableRooms: [],
            events: [],
            provider: null,
            selectedDate: null,
            room: null,
            selectedView: Views.DAY,
            timezone: null
        };
    }

    componentDidMount() {
        RemotingService.getRemoteCall('api/staff/list-base', null, (result) => {
            this.setState({providers: result.items})
        });

        SchedulingService.getSchedules((schedules) => {
            this.setState({schedules});
        });
    }

    addBlock({start, end}) {
        if (DateUtil.getDurationInMinutes(start, end) <= 5) {
            return;
        }

        dialog({
            title: "Add Block",
            component: <AddRotationBlockModal staffId={this.state.provider.id}
                                              room={this.state.room}
                                              start={start}
                                              end={end}
                                              timezone={this.state.timezone}
                                              events={this.state.events}
                                              onAdd={(event) => {
                                                  const events = [...this.state.events, event];
                                                  this.setState({events});
                                              }}/>
        });
    }

    deleteBlock = (selectedEvent) => {
        const events = this.state.events.filter(e => e !== selectedEvent);
        this.setState({events});
    }

    save() {
        const blocks = this.state.events.map(event => {
            return {
                type: event.type,
                startTime: DateUtil.getTimeStringAtZone(event.start, this.state.timezone),
                endTime: DateUtil.getTimeStringAtZone(event.end, this.state.timezone)
            }
        });

        const params = {
            roomId: this.state.room.id,
            date: this.state.selectedDate,
            blocks: blocks
        };

        RemotingService.postRemoteCall(`api/staff/${this.state.provider.id}/copy-rotation-block`, params, (result) => {
            this.props.close();
        });
    }

    providerChanged(selectedProvider) {
        const country = selectedProvider.departments[0].clinic.country;
        const timezone = TimezoneUtil.getTimeZoneForCountry(country);
        this.setState({
            provider: selectedProvider,
            timezone: timezone
        });
    }

    dateChanged(selectedDate) {
        const params = {
            startDate: DateUtil.formatDate(selectedDate, "YYYY-MM-DD"),
            endDate: DateUtil.formatDate(selectedDate, "YYYY-MM-DD")
        };

        RemotingService.getRemoteCall('api/room/available-rooms', params, (roomAvailabilities) => {
            this.setState({
                selectedDate,
                availableRooms: roomAvailabilities.map(item => item.room)
            });
        });
    }

    onCalendarStep(event) {
        const providerId = this.state.provider.id;
        RemotingService.getRemoteCall(`api/scheduling-secure/daySchedule/${providerId}`, null, (daySchedules) => {
            const {selectedDate, room, provider, timezone} = this.state;
            const tzDate = moment.tz(timezone)
                .year(selectedDate.getFullYear())
                .month(selectedDate.getMonth())
                .date(selectedDate.getDate());

            const day = DateUtil.getDayName(this.state.selectedDate).toLocaleUpperCase();
            const blocks = daySchedules?.data ? daySchedules.data[day] : [];
            const events = blocks.map(block =>
                CalendarEventService.createEvent([room], tzDate, {...block, roomId: room.id}, provider, timezone)
            );

            this.setState({events}, () => ReduxService.decrementRemotingOperationCount());
        });
    }

    render() {
        this.formFields = [];
        const moment = getMoment(this.state.timezone);
        const localizer = momentLocalizer(moment);

        return (
            <Wizard contentStyle={{width: 600}}
                                  show={true}
                                  close={() => this.props.close()}
                                  header="Copy Schedule"
                                  onSubmit={() => this.save()}>

                <Wizard.Page
                    onNext={(event) => !ValidationUtil.isValid(this.formFields) ?
                        ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields) :
                        this.onCalendarStep(event)}
                    onPrevious={() => (this.formFields = [])}>
                    <div className="container">
                        <div className="row">
                            <div className="col d-flex flex-column">
                                <span>Provider</span>
                                <FormField ref={f => this.formFields.push(f)}
                                           required validateOn={this.state.provider}>
                                    <Dropdown optionLabel="name"
                                              options={this.state.providers}
                                              value={this.state.provider}
                                              onChange={event => this.providerChanged(event.value)}/>
                                </FormField>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col d-flex flex-column">
                                <span>Date</span>
                                <FormField ref={f => this.formFields.push(f)}
                                           required validateOn={this.state.selectedDate}>
                                    <PrimeDateInput containerStyle={{width: "100%"}}
                                                    value={this.state.selectedDate}
                                                    onChange={value => this.dateChanged(value)}/>
                                </FormField>
                            </div>
                            <div className="col d-flex flex-column">
                                <span>Available Rooms</span>
                                <FormField ref={f => this.formFields.push(f)}
                                           required validateOn={this.state.room}>
                                    <Dropdown optionLabel="info"
                                              options={this.state.availableRooms}
                                              value={this.state.room}
                                              onChange={event => this.setState({room: event.value})}/>
                                </FormField>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>
                <Wizard.Page onPrevious={() => this.formFields = []}>
                    <BigCalendar
                        className="copy-schedule-calendar no-current-time-indicator"
                        style={{height: 2000}}
                        dragDrop={true}
                        toolbar={false}
                        defaultView={this.state.selectedView}
                        localizer={localizer}
                        formats={AppointmentCalendarFormats}
                        getNow={() => getNow(new Date(), moment)}
                        step={5}
                        timeslots={12}
                        date={this.state.selectedDate}
                        min={moment().hour(7).minute(0).utcOffset('GST' === this.state.timezone ? 4 : 3).toDate()}
                        max={moment().hour(21).minute(0).utcOffset('GST' === this.state.timezone ? 4 : 3).toDate()}
                        events={this.state.events}
                        resizableAccessor={event => false}
                        draggableAccessor={event => false}
                        selectable onSelectSlot={event => this.addBlock(event)}
                        components={{
                            event: AppointmentCalendarEventFactory({
                                timeZoneGetter: () => this.state.timezone,
                                viewGetter: () => this.state.selectedView,
                                refreshCalendarData: () => this.refreshCalendarData(),
                                appointmentActionsVisible: false,
                                eventContextMenuVisible: true,
                                showEventContextMenu: (e, event) => this.eventContextMenu.show(e, event)
                            }),
                            timeGutterHeader: () =>
                                <div className="font-size-small">
                                    <div>{DateUtil.formatDate(this.state.selectedDate)}</div>
                                    <div className="font-weight-bold">{DateUtil.getDayName(this.state.selectedDate)}</div>
                                    <div>{this.state.room?.info}</div>
                                </div>
                        }}
                        resources={[this.state.provider]}
                        resourceAccessor="staffId"
                        resourceIdAccessor="id"
                        resourceTitleAccessor={staff =>
                            <div className="custom-resource-header" id={staff.id}>
                                <div>{staff.specialityName}</div>
                                <div>{staff.name}</div>
                            </div>
                        }
                    />
                    <ContextMenu ref={el => this.eventContextMenu = el}>
                        <ContextMenuItem icon={<DeleteIcon/>}
                                         label="Delete Block"
                                         onClick={(event) => this.deleteBlock(event)}/>
                    </ContextMenu>
                </Wizard.Page>
            </Wizard>
        )
    }

}
