import React from "react";
import {extract, onlyUnique} from "../../utils/ArrayUtil";
import NotificationService from "../../services/notification-service/NotificationService";
import RemotingService from "../../services/remoting-service/RemotingService";
import Modal from "../../components/modal/Modal";
import {CheckboxInput, FileUploader, NumberInput, SelectInput, TextareaInput, TextInput} from "../../components";
import {connect} from "react-redux";
import SponsorshipUtil from "./SponsorshipUtil";
import {FormField} from "../../components/form";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import AuthService from "../../services/auth-service/AuthService";

class EditSponsorship extends React.Component {

    cleanState = {
        discountAmountDisabled: false,
        discountAmount: 0,
        discountCurrency: null,
        discountRate: 10,
        startDate: new Date(),
        period: 30,
        numberOfSessions: -1,
        staffIds: [],
        departmentIds: [],
        coverAll: false
    };

    state = {};

    componentDidMount() {
        const sponsorship = this.props.sponsorship;
        const loginUser = AuthService.getUser();
        const {editable, isManager} = SponsorshipUtil.getCapabilities(sponsorship);

        if (sponsorship) {
            // Update sponsorship
            const {discountAmount, ...sponsorshipWithoutDiscountAmount} = sponsorship;
            this.cleanState = {
                ...this.cleanState,
                ...sponsorshipWithoutDiscountAmount,
                discountAmount: discountAmount ? discountAmount.amount : null,
                discountCurrency: discountAmount ? discountAmount.currency : null
            };
            this.cleanState.selectedClinics = this.props.sponsorData.clinics.filter(c => sponsorship.clinicIds.includes(c.id));
            Object.assign(this.cleanState, this.handleDiscountAmountByClinicSelect(this.cleanState, this.cleanState.selectedClinics));

            if (isManager) {
                this.cleanState.sponsoredBy = {id: loginUser.staffId, name: loginUser.name};
            }

            if (sponsorship.attachmentContent) {
                const {attachmentName, attachmentType, attachmentContent} = sponsorship;
                this.cleanState.attachment = {name:attachmentName, mimeType:attachmentType, content:attachmentContent};
            }

        } else {
            // Create sponsorship
            this.cleanState.sponsoredBy = {id: loginUser.staffId, name: loginUser.name};

            if (AuthService.isProvider()) {
                this.cleanState.staffIds.push(loginUser.staffId);
            }
        }

        this.setState({sponsorship, ...this.cleanState, visible: this.props.visible, editable, isManager});
    }

    handleAttachmentChange(file) {
        this.setState({attachment: file})
    }

    handleSponsorshipUpdate() {
        if (!ValidationUtil.isValid(this.formFields)) {
            ValidationUtil.showNotification();
            return;
        }

        const {discountAmount, discountCurrency, discountRate, numberOfSessions, startDate, period, sponsoredBy, staffIds, departmentIds, coverAll, selectedClinics, notes} = this.state;
        const dto = {
            discountAmount: discountAmount ? {
                amount: discountAmount,
                currency: discountCurrency
            } : null,
            discountRate,
            numberOfSessions,
            startDate,
            period,
            sponsoredBy,
            staffIds,
            departmentIds,
            coverAll,
            notes,
            clinicIds: extract(selectedClinics, "id")
        };

        if (this.state.attachment) {
            dto.attachmentName =  this.state.attachment.name;
            dto.attachmentType =  this.state.attachment.mimeType;
            dto.attachmentContent =  this.state.attachment.content;
        }

        const url = 'api/patient/' + this.props.patientId + '/sponsorship/' + (this.props.sponsorship ? this.props.sponsorship.id : '');

        if ((!staffIds || staffIds.length == 0) && (!departmentIds || departmentIds == 0) && !coverAll) {
            // TODO Use validations for these checks.
            NotificationService.showNotification({ severity: 'warn', summary: 'Invalid Coverage', detail: 'Please select coverage details.' })
            return;
        }

        RemotingService.postRemoteCall(url , dto, () => {
            this.props.handleSponsorshipUpdate();
        });
    }

    handleClinicSelect(selectedClinics) {
        this.setState(prevState => {
            return {selectedClinics, ...this.handleDiscountAmountByClinicSelect(prevState, selectedClinics)}
        });
    }

    handleDiscountAmountByClinicSelect(prevState, selectedClinics) {
        let currencies = extract(selectedClinics, "currency").filter(onlyUnique);
        let discountAmount, discountCurrency, discountAmountDisabled;
        if (currencies.length == 1) {
            discountAmount = prevState.discountAmount;
            discountCurrency = currencies[0];
            discountAmountDisabled = false;
        } else {
            discountAmount = 0;
            discountCurrency = null;
            discountAmountDisabled = true;
        }
        return {discountAmount, discountCurrency, discountAmountDisabled};
    }

    render() {
        this.formFields = [];
        return <Modal visible={this.props.visible}
                      header="Sponsorship"
                      submitContent="Save"
                      contentStyle={{height: 600, overflowY: 'auto'}}
                      submitAction={() => this.handleSponsorshipUpdate()} submitDisabled={!this.state.editable}
                      closeAction={() => this.props.handleSponsorshipUpdate()}>
            <div className="container padding-10" style={{width: '550px'}}>
                <div className="row mt-1">
                    <div className="col-5 border">
                        <div>Sponsored By</div>
                    </div>
                    <div className="col-7 border">
                        {this.state.sponsoredBy ? this.state.sponsoredBy.name : null}
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-5 border">Coverage</div>
                    <div className="col-7 border">
                        <div className="d-flex flex-column p-justify-between">
                            <SelectInput className={"w-100 light-color-bordered"} value={this.state.staffIds}
                                         options={this.props.sponsorData.staffs}
                                         displayProperty="label" valueProperty="id" searchEnabled={true} multiSelect
                                         disabled={!(this.state.isManager || AuthService.isProvider())} placeholder="Select Provider"
                                         onChange={(value => {
                                             this.setState({staffIds: value, coverAll: false})
                                         })}/>
                            <SelectInput className={"w-100 light-color-bordered"} value={this.state.departmentIds}
                                         options={this.props.sponsorData.departments}
                                         displayProperty="combinedName" valueProperty="id" searchEnabled={true}
                                         multiSelect
                                         disabled={!(this.state.isManager || AuthService.isProvider())} placeholder="Select Department"
                                         onChange={(value => {
                                             this.setState({departmentIds: value, coverAll: false})
                                         })}/>
                            <div className="w-100 text-right">
                                <CheckboxInput value={this.state.coverAll}
                                               title="Cover All Departments and Staffs"
                                               disabled={!(this.state.isManager || AuthService.isProvider())}
                                               onChange={(value) => this.setState({
                                                   coverAll: value,
                                                   staffIds: value ? [] : this.state.staffIds,
                                                   departmentIds: value ? [] : this.state.departmentIds
                                               })}/>
                                &nbsp;All
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-5 border">Clinic</div>
                    <div className="col-7 border">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required
                                   validateOn={this.state.selectedClinics}>
                            <SelectInput className={"w-100 light-color-bordered"} value={this.state.selectedClinics}
                                         options={this.props.sponsorData.clinics}
                                         displayProperty="name" searchEnabled={true}
                                         multiSelect
                                         placeholder="Select Clinic"
                                         onChange={(value) => this.handleClinicSelect(value)}/>
                        </FormField>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-5 border">Discount Amount / Percentage</div>
                    <div className="col-7 border">
                        <FormField required validateOn={(this.state.discountAmount > 0 || this.state.discountRate > 0) ? "valid" : null}
                                   ref={formField => this.formFields.push(formField)}>
                            <div className="d-flex p-justify-between">
                                <div>
                                    <FormField min={0} validateOn={this.state.discountAmount}
                                               ref={formField => this.formFields.push(formField)}>
                                        <NumberInput style={{width: '100px'}} value={this.state.discountAmount} min={0}
                                                     disabled={!this.state.editable || this.state.discountAmountDisabled}
                                                     onChange={(value) => {
                                                         value = Number.isInteger(Number(value)) ? value : "";
                                                         this.setState({
                                                             discountAmount: value,
                                                             discountRate: 0
                                                         });
                                                     }
                                                     }/>
                                        &nbsp;
                                        {this.state.discountCurrency}
                                    </FormField>
                                </div>
                                <div>
                                    <FormField min={0} max={100} validateOn={this.state.discountRate}
                                               ref={formField => this.formFields.push(formField)}>
                                        <NumberInput style={{width: '60px'}} value={this.state.discountRate} min={0}
                                                     max={100}
                                                     disabled={!this.state.editable}
                                                     onChange={(value) => {
                                                         value = Number.isInteger(Number(value)) ? value : "";
                                                         this.setState({
                                                             discountAmount: 0,
                                                             discountRate: value
                                                         });
                                                     }}/>
                                        &nbsp;%
                                    </FormField>
                                </div>
                            </div>
                        </FormField>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-5 border">Number of Sessions</div>
                    <div className="col-7 border">
                        <div className="d-flex p-justify-between">
                            <div className={this.state.numberOfSessions == -1 ? "d-none" : "d-flex"}>
                                <FormField required validateOn={this.state.numberOfSessions}
                                           ref={formField => this.formFields.push(formField)}>
                                    <TextInput style={{width: '30px'}}
                                               value={this.state.numberOfSessions}
                                               disabled={!this.state.editable}
                                               onChange={(value) => this.setState({
                                                   numberOfSessions: value
                                               })}/>
                                </FormField>
                                <div style={{paddingTop: '7px'}}>&nbsp;sessions</div>
                            </div>
                            <div style={{paddingTop: '7px'}}>
                                <CheckboxInput value={this.state.numberOfSessions == -1} title="Unlimited"
                                               disabled={!this.state.editable}
                                               onChange={(value) => this.setState({
                                                   numberOfSessions: value ? -1 : 5
                                               })}/>
                                &nbsp;Unlimited
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-5 border">Validity</div>
                    <div className="col-7 border">
                        <div className="d-flex p-justify-between">
                            <div className={this.state.period == -1 ? "d-none" : "d-flex"}>
                                <FormField required validateOn={this.state.period}
                                           ref={formField => this.formFields.push(formField)}>
                                    <TextInput style={{width: '50px'}}
                                               value={this.state.period}
                                               disabled={!this.state.editable}
                                               onChange={(value) => this.setState({
                                                   period: value
                                               })}/>
                                </FormField>
                                <div style={{paddingTop: '7px'}}>&nbsp;days</div>
                            </div>
                            <div style={{paddingTop: '7px'}}><b>{SponsorshipUtil.getValidityDate(this.state)}</b></div>
                            <div style={{paddingTop: '7px'}}>
                                <CheckboxInput value={this.state.period == -1} title="Unlimited"
                                               disabled={!this.state.editable}
                                               onChange={(value) => this.setState({
                                                   period: value ? -1 : 30
                                               })}/>
                                &nbsp;Unlimited
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-5 border">Attachment</div>
                    <div className="col-7 border">
                        <div style={{maxWidth: '300px', padding: '10px'}}>
                            <FileUploader
                                value={this.state.attachment}
                                onChange={(value) => this.handleAttachmentChange(value)}/>
                        </div>
                    </div>
                </div>

                <div className="row mt-1">
                    <div className="col-5 border">Notes</div>
                    <div className="col-7 border">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required
                                   validateOn={this.state.notes}>
                            <TextareaInput
                                className={"w-100"}
                                value={this.state.notes}
                                onChange={(value) => this.setState({
                                    notes: value
                                })}
                            />
                        </FormField>
                    </div>
                </div>
            </div>
        </Modal>

    }
}

const mapStateToProps = state => {
    return {
        user: state.authentication.user
    };
};

export default connect(mapStateToProps)(EditSponsorship);
