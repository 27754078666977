import React, {Component} from "react";
import "../../VisitHistory.css";
import CurrentPreviousEnum from "../../../subjective/enums/complaint/CurrentPreviousEnum";
import DurationEnum from "../../../subjective/enums/complaint/DurationEnum";
import DateUtil from "../../../../../../utils/DateUtil";
import TimingEnum from "../../../subjective/enums/complaint/TimingEnum";
import TimingSensitivityEnum from "../../../subjective/enums/complaint/TimingSensitivityEnum";
import CharacterEnum from "../../../subjective/enums/complaint/CharacterEnum";
import OnsetEnum from "../../../subjective/enums/complaint/OnsetEnum";
import AggravatingFactorEnum from "../../../subjective/enums/complaint/AggravatingFactorEnum";
import DescriptionEnum from "../../../subjective/enums/complaint/DescriptionEnum";
import RelievingFactorsEnum from "../../../subjective/enums/complaint/RelievingFactorsEnum";
import FrequencyEnum from "../../../subjective/enums/complaint/FrequencyEnum";
import RedFlagEnum from "../../../subjective/enums/complaint/RedFlagEnum";
import EtiologyEnum from "../../../subjective/enums/complaint/EtiologyEnum";
import FeelEnum from "../../../subjective/enums/complaint/FeelEnum";
import NatureEnum from "../../../subjective/enums/complaint/NatureEnum";
import DurationTimeEnum from "../../../subjective/enums/complaint/DurationTimeEnum";
import StringUtil from "../../../../../../utils/StringUtil";

export default class SymptomViewHistory extends Component {
    render() {
        if (this.props.complaint == null || this.checkSymptomDataAvailability(this.props.complaint)) {
            return <div className="pl-3">No information available</div>;
        }

        let [symptomsCol1, symptomsCol2, symptomsCol3] = this.getSymptoms();

        return (
            <div className={"visit-history-symptoms-wrapper pl-3"}>
                <div className={"d-block"}>
                    {symptomsCol1}
                </div>
                <div className={"d-block"}>
                    {symptomsCol2}
                </div>
                <div className={"d-block"}>
                    {symptomsCol3}
                </div>
            </div>
        );
    }

    getSymptomHistoryViewDiv(label, value) {
        return (
            <>
                <span> <b>{label}: </b> {value} </span>
            </>
        );
    }

    getSymptoms() {
        let col1 = [];
        let col2 = [];
        let col3 = [];
        let complaint = this.props.complaint;
        //Column-1
        (complaint.pain == null || complaint.pain === " ") ? col1.push(this.getSymptomHistoryViewDiv("Pain", "NA"))
            : col1.push(this.getSymptomHistoryViewDiv("Pain", complaint.pain));

        (complaint.swelling == null || complaint.swelling === " ") ? col1.push(this.getSymptomHistoryViewDiv("Swelling", 'NA'))
            : col1.push(this.getSymptomHistoryViewDiv("Swelling", complaint.swelling));

        (complaint.referral == null || complaint.referral === " ") ? col1.push(this.getSymptomHistoryViewDiv("Referral", "NA"))
            : col1.push(this.getSymptomHistoryViewDiv("Referral", complaint.referral));

        (complaint.nature == null || complaint.nature === " ") ? col1.push(this.getSymptomHistoryViewDiv("Nature", "NA"))
            : col1.push(this.getSymptomHistoryViewDiv("Nature", NatureEnum[complaint.nature]?.name));

        (complaint.currentPrevious == null || complaint.currentPrevious === " ") ? col2.push(this.getSymptomHistoryViewDiv("Episode", "NA"))
            : col2.push(this.getSymptomHistoryViewDiv("Episode", CurrentPreviousEnum[complaint.currentPrevious]?.name));

        if (complaint.onset != null
            || (complaint.onsetDetail != null
                && complaint.onsetDetail !== "")) {

            let detail = complaint.onsetDetail;
            let onset = complaint.onset == null ? "" : OnsetEnum[complaint.onset].name;
            let text = onset === "" ? detail : ((detail == null || detail === "") ? onset : (onset + ", " + detail));
            col2.push(this.getSymptomHistoryViewDiv("Onset", text));
        } else {
            col2.push(this.getSymptomHistoryViewDiv("Onset", "NA"));
        }

        if (complaint.description != null
            || (complaint.descriptionDetail != null
                && complaint.descriptionDetail !== "")) {

            let detail = complaint.descriptionDetail;
            let description = complaint.description == null ? "" : DescriptionEnum[complaint.description].name;
            let text = description === "" ? detail : ((detail == null || detail === "") ? description : (description + ", " + detail));
            col2.push(this.getSymptomHistoryViewDiv("Description", text));
        } else {
            col2.push(this.getSymptomHistoryViewDiv("Description", "NA"));
        }

        if (complaint.frequency.length > 0
            || complaint.frequencyDetail !== null) {
            col2.push(this.getSymptomHistoryViewDiv("Frequency",
                this.enumString(FrequencyEnum, complaint.frequency, complaint.frequencyDetail)));
        }

        if (complaint.etiology.length > 0
            || complaint.etiologyDetail !== null
            || complaint.historyOfTrauma != null) {
            let etiologyText = "";
            if (complaint.historyOfTrauma != null) {
                let historyOfTraumaText = complaint.historyOfTrauma ? "Yes" : "No";
                etiologyText += `History of Trauma (${historyOfTraumaText})`;
            }

            const enumDetail = this.enumString(EtiologyEnum,
                complaint.etiology,
                complaint.etiologyDetail);
            if (enumDetail != 'NA') {
                if (StringUtil.isNullOrEmpty(etiologyText)) {
                    etiologyText = enumDetail;
                } else {
                    etiologyText += ", " + enumDetail;
                }
            }

            col2.push(this.getSymptomHistoryViewDiv("Etiology", etiologyText));
        }

        //Column-3
        //TODO: Test this properly!!
        if (complaint.durationType !== null || complaint.durationTime !== null
            || complaint.durationTimeEnum !== null) {

            let durationTimeEnumStr = "";

            if (complaint.durationNumber > 1  && complaint.durationTimeEnum !== null) {
                durationTimeEnumStr =
                    String(complaint.durationNumber) + " " + DurationTimeEnum[complaint.durationTimeEnum].name + "s, "
            } else if (complaint.durationNumber === 1 && complaint.durationTimeEnum !== null) {
                durationTimeEnumStr =
                    String(complaint.durationNumber) + " " + DurationTimeEnum[complaint.durationTimeEnum].name + ", "
            }

            let text = complaint.durationType == null ? durationTimeEnumStr
                : (DurationEnum[complaint.durationType]?.name + (durationTimeEnumStr === "" ? "" : (", " + durationTimeEnumStr)));

            text = complaint.durationTime == null
                ? text : (text === "" ? DateUtil.formatDateMMM(complaint.durationTime)
                    : (text + " " + DateUtil.formatDateMMM(complaint.durationTime) + " "));

            col3.push(this.getSymptomHistoryViewDiv(
                "Duration", text));
        } else {
            col3.push(this.getSymptomHistoryViewDiv(
                "Duration", "NA"))
        }

        if (complaint.timing.length > 0 || complaint.timingDetail != null) {
            let timingStr = this.enumString(TimingEnum, complaint.timing, complaint.timingDetail);

            if (complaint.timingSensitivity.length > 0) {
                timingStr += ', ' + this.enumString(TimingSensitivityEnum, complaint.timingSensitivity);
            }
            col3.push(this.getSymptomHistoryViewDiv("Timing", timingStr));
        } else {
            col3.push(this.getSymptomHistoryViewDiv("Timing", "NA"));
        }

        if (complaint.character.length > 0 || complaint.characterDetail != null) {

            col3.push(this.getSymptomHistoryViewDiv("Character",
                this.enumString(CharacterEnum, complaint.character, complaint.characterDetail)));
        } else {
            col3.push(this.getSymptomHistoryViewDiv("Character", "NA"));
        }

        if (complaint.aggravatingFactor.length > 0
            || (complaint.aggravatingFactorDetail != null
                && complaint.aggravatingFactorDetail !== "")) {

            col3.push(this.getSymptomHistoryViewDiv(
                "Aggravating Factors",
                this.enumString(AggravatingFactorEnum, complaint.aggravatingFactor, complaint.aggravatingFactorDetail)));
        } else {
            col3.push(this.getSymptomHistoryViewDiv(
                "Aggravating Factors", "NA"));
        }

        if(complaint.relievingFactorsNormal) {
            col3.push(this.getSymptomHistoryViewDiv("Relieving Factors", "No Relieving Factors"));
        } else if (complaint.relievingFactors.length > 0
            || (complaint.relievingFactorsDetail !== null
                && complaint.relievingFactorsDetail !== "")) {

            col3.push(this.getSymptomHistoryViewDiv("Relieving Factors",
                this.enumString(RelievingFactorsEnum, complaint.relievingFactors, complaint.relievingFactorsDetail)));
        } else {
            col3.push(this.getSymptomHistoryViewDiv("Relieving Factors", "NA"));
        }


        if(complaint.redFlagNormal){
            col3.push(this.getSymptomHistoryViewDiv("Red Flags", "No Red Flags"));
        } else if (complaint.redFlag.length > 0
            || (complaint.redFlagDetail !== null
                && complaint.redFlagDetail !== "")) {

            col3.push(this.getSymptomHistoryViewDiv("Red Flags",
                this.enumString(RedFlagEnum, complaint.redFlag, complaint.redFlagDetail)));
        } else {
            col3.push(this.getSymptomHistoryViewDiv("Red Flags", "NA"));
        }

        if (complaint.feel !== null) {
            col3.push(this.getSymptomHistoryViewDiv("How does this make you feel?",
              this.enumString(FeelEnum, complaint.feel)));
        } else {
            col3.push(this.getSymptomHistoryViewDiv("How does this make you feel?", "NA"));
        }

        return [col1, col2, col3];
    }

    //TODO: Copied from ComplaintView.js. Find out how to re-use??
    enumString(_class, enumObject, detail) {
        if (enumObject == null || enumObject.length === 0) {
            return (detail && detail !== null) ? detail : 'NA';
        }
        let res = enumObject.map(e => _class[e].name);
        if (detail && detail !== null && detail !== '') {
            res.push(detail);
        }
        return res.join(', ');
    }

    checkSymptomDataAvailability (symptomData) {
        return symptomData.pain == null && symptomData.painDetail == null
            && symptomData.swelling == null && symptomData.swellingDetail == null
            && symptomData.referral == null
            && symptomData.nature == null && symptomData.natureDetail == null
            && symptomData.currentPrevious == null
            && symptomData.onset == null && symptomData.onsetDetail == null
            && symptomData.description == null && symptomData.descriptionDetail == null
            && symptomData.frequency.length === 0 && symptomData.frequencyDetail == null
            && symptomData.etiology.length === 0 && symptomData.etiologyDetail == null && symptomData.historyOfTrauma == null
            && symptomData.durationType == null && symptomData.durationTime == null && symptomData.durationTimeEnum == null
            && symptomData.timing.length === 0 && symptomData.timingDetail == null
            && symptomData.character.length === 0 && symptomData.characterDetail == null
            && symptomData.aggravatingFactor.length === 0 && symptomData.aggravatingFactorDetail == null
            && symptomData.redFlag.length === 0 && symptomData.redFlagDetail == null
            && symptomData.feel == null;
    }
}