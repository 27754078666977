import moment from "moment";
import DateUtil from "../../../utils/DateUtil";

const calculateStartEndDates = (_date, timezone) => {
    const date = moment(_date);
    const start = DateUtil.createMomentWithZone(timezone, date.year(), date.month(), date.date(), 5).toDate();
    date.add(-1, 'minutes');
    const end = DateUtil.createMomentWithZone(timezone, date.year(), date.month(), date.date(), 23).toDate();

    return {start, end};
}

export default {calculateStartEndDates};