import React, {Component} from "react";
import ReportViewUtils from "./ReportViewUtils";

export default class ResponseToTreatmentReportView extends Component {

    state = {};

    render() {
        const response = this.props.response;

        if (ReportViewUtils.isResponseToTreatmentNull(response)) {
            return null;
        }

        let responseToTreatmentText =
            [
                response.overallImproveValue ? "Overall Improve By: " + response.overallImproveValue + "%" : null,
                response.romImproveValue ? "ROM Improve By: " + response.romImproveValue + "%" : null,
                response.painScoreValue ? "Pain Score (VAS): " + response.painScoreValue : null,
            ].filter(Boolean).join(". ")

        let responseToTreatmentDetailsText =
            [
                response.overallImproveDetails ? response.overallImproveDetails : null,
                response.romImproveDetails ? response.romImproveDetails : null,
                response.painScoreDetails ? response.painScoreDetails : null,
            ].filter(Boolean).join("; ")

        return (
            <div className='row no-margin'>
                <div className="col-12 d-flex" style={{padding: "0px"}}>
                    {responseToTreatmentText + (responseToTreatmentDetailsText != null ? ". " + responseToTreatmentDetailsText : null)}
                </div>
            </div>
        );
    }

}
