import React, {Component} from "react";
import '../../EMR.css';
import TextInput from "../../../../../components/text-input/TextInput";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";
import PalpationLocationEnum from "../enums/palpation/PalpationLocationEnum";
import PalpationFindingEnum from "../enums/palpation/PalpationFindingEnum";
import StringUtil from "../../../../../utils/StringUtil";
import GreenSwitchWithEmptyMessage from "../../../../../components/switch/GreenSwitchWithEmptyMessage";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import {CheckboxInput} from "../../../../../components";

export default class PalpationModal extends Component {

    state = {
        area: null,
        locationRight: null,
        locationLeft: null,
        finding: null,
        pain: null,
        detail: null
    }

    constructor(props) {
        super(props);
        this.customStyles = {
            option: (provided, state) => ({
                ...provided,
                textAlign:'left',
                whiteSpace:'nowrap',

            }),

            menu:(provided, state)=>({
                ...provided,
                position:'absolute',
                width:'100wv',

            }),
            menuList: (provided, state) => ({
                ...provided,


            }),


        }

    }

    render() {
        let valueLabelUntilTen = this.generateValueLabelArray(10);
        let palpationDivs = this.getPalpationDivs();

        return (
            <div className={"emr-modal content-row emr-background"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px"}}>
                    <div>
                        <div style={{display: "flex"}}>
                            <div className="content-label" style={{margin:"auto", marginLeft:"0px"}}>Palpation <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                            <GreenSwitchWithEmptyMessage
                                checked={this.props.assessment.palpation
                                && (this.props.assessment.palpation.none || this.props.assessment.palpation.items.length != 0)}
                                onChange={() => {
                                    if(this.props.assessment.palpation == null) {
                                        this.props.assessment.palpation = {none: false};
                                        this.props.assessment.palpation.items = [];
                                    } else {
                                        this.props.assessment.palpation = null;
                                    }
                                    this.forceUpdate();
                                }}/>
                        </div>
                        <div className="d-flex align-items-center">
                            <div>
                                <CheckboxInput value={this.props.assessment.palpation && this.props.assessment.palpation.none}
                                               onChange={value => {
                                                   if (this.props.assessment.palpation == null) {
                                                       this.props.assessment.palpation = {};
                                                   }
                                                   if (value) {
                                                       this.props.assessment.palpation.items = [];
                                                   }
                                                   this.props.assessment.palpation.none = value;
                                                   this.forceUpdate();
                                               }}/>
                            </div>
                            <div className="ml-1">Normal</div>
                        </div>
                        <div className={this.props.assessment.palpation
                        && this.props.assessment.palpation.none ? "not-allowed disable-pointer" : ""}>
                            <div className={"row no-margin"}>
                                <div className={"col-4"}>
                                    <div className="font-weight-bold">Area</div>
                                </div>
                                <div className={"col-2"}>
                                    <div className="font-weight-bold">Right</div>
                                </div>
                                <div className={"col-2"}>
                                    <div className="font-weight-bold">Left</div>
                                </div>
                                <div className={"col-2"}>
                                    <div className="font-weight-bold">Finding</div>
                                </div>
                                <div className={"col-2"}>
                                    <div className="font-weight-bold">Pain</div>
                                </div>
                            </div>
                            <div className={"row no-margin"}>
                                <div className={"col-4"}>
                                    <TextInput value={this.state.area || ''}
                                               placeholder={"Area"}
                                               onChange={value =>  this.setState({area: value})}
                                               style={{fontSize: "14px", width: "100%"}}
                                    />
                                </div>
                                <div className={"col-2"}>
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        placeholder={"Right"}
                                        styles={this.customStyles}
                                        value={this.state.locationRight != null ?
                                            {value: PalpationLocationEnum[this.state.locationRight].key,
                                                label: PalpationLocationEnum[this.state.locationRight].name}: null}
                                        onChange={(event) => {
                                            let r = event.value;
                                            this.setState({ locationRight: r });
                                        }}
                                        options={PalpationLocationEnum.toArray().map(v=> {
                                                return {value: v.key, label:v.name}
                                            }
                                        )}
                                    />
                                </div>
                                <div className={"col-2"}>
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        placeholder={"Left"}
                                        styles={this.customStyles}
                                        value={this.state.locationLeft != null ?
                                            {value: PalpationLocationEnum[this.state.locationLeft].key,
                                                label: PalpationLocationEnum[this.state.locationLeft].name}: null}
                                        onChange={(event) => {
                                            let r = event.value;
                                            this.setState({ locationLeft: r });
                                        }}
                                        options={PalpationLocationEnum.toArray().map(v=> {
                                                return {value: v.key, label:v.name}
                                            }
                                        )}
                                    />
                                </div>
                                <div className={"col-2"}>
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        placeholder={"Finding"}
                                        styles={this.customStyles}
                                        value={this.state.finding != null ?
                                            {value: PalpationFindingEnum[this.state.finding].key,
                                                label: PalpationFindingEnum[this.state.finding].name}: null}
                                        onChange={(event) => {
                                            let r = event.value;
                                            this.setState({ finding: r });
                                        }}
                                        options={PalpationFindingEnum.toArray().map(v=> {
                                                return {value: v.key, label:v.name}
                                            }
                                        )}
                                    />
                                </div>
                                <div className={"col-2"}>
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        placeholder={"Pain"}
                                        styles={this.customStyles}
                                        value={this.state.pain != null ?
                                            {
                                                value: this.state.pain,
                                                label: this.state.pain
                                            }: null}
                                        onChange={(event) => {
                                            let r = event.value;
                                            this.setState({ pain: r });
                                        }}
                                        options={valueLabelUntilTen}
                                    />
                                </div>
                            </div>
                            <div className={"row no-margin"}>
                                <div className={"col-11"}>
                                    <TextInput value={this.state.detail || ''}
                                               placeholder={"Details"}
                                               onChange={value =>  this.setState({detail: value})}
                                               style={{fontSize: "14px", width: "100%"}}
                                    />
                                </div>
                                <div className={"col-1"} style={{flexFlow: "row-reverse", display: "flex"}}>
                                    <Button className={"emr-plus-button"}
                                            variant="success"
                                            disabled={StringUtil.isNullOrEmpty(this.state.area)
                                            && (this.state.locationRight == null && this.state.locationLeft == null)
                                            && this.state.finding == null
                                            && this.state.pain == null}
                                            style={{fontSize:"12px"}}
                                            onClick={this.addPalpation}>
                                        <FontAwesomeIcon icon={["fas", "plus"]}/>
                                    </Button>
                                </div>
                            </div>
                            <br/>
                            <div className={"row no-margin"} style={{width: "100%", marginTop: "10px !important"}}>
                                <div className={"div-list-wrapper"}>
                                    {palpationDivs}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    generateValueLabelArray(arraySize) {
        const foo = new Array(arraySize);
        for (let i = 1; i <= arraySize; i++) {
            foo.push({value: i, label: i});
        }
        return foo;
    }

    addPalpation = () => {
        this.createPalpationIfNecessary();
        this.props.assessment.palpation.date = new Date();
        this.props.assessment.palpation.items.push({
            area: this.state.area,
            locationRight: this.state.locationRight,
            locationLeft: this.state.locationLeft,
            finding: this.state.finding,
            pain: this.state.pain,
            detail: this.state.detail
        });
        this.setState({
            area: null,
            locationRight: null,
            locationLeft: null,
            finding: null,
            pain: null,
            detail: null
        });
    }

    createPalpationIfNecessary() {
        if(this.props.assessment.palpation == null) {
            this.props.assessment.palpation = {};
            this.props.assessment.palpation.items = [];
        }
    }

    getPalpationDivs = () => {
        if (this.props.assessment.palpation == null
            || this.props.assessment.palpation.length == 0) {
            return;
        }
        let divs = [];
        this.props.assessment.palpation.items.forEach((item, index) => divs.push(
            <div className={"div-list-item"} key={"palpationItem" + index}>
                <div>
                    {this.getPalpationText(item)}
                </div>
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize:"10px", marginLeft: "2px"}}
                        onClick={() => {
                            this.props.assessment.palpation.items.splice(index, 1);
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas","times"]}/>
                </Button>
            </div>
        ));
        return divs;
    }

    getPalpationText = (item) => {
        return [
            StringUtil.isNullOrEmpty(item.area) ? null : item.area,
            item.locationRight != null ? "Right-" + PalpationLocationEnum[item.locationRight].name : null,
            item.locationLeft != null ? "Left-" + PalpationLocationEnum[item.locationLeft].name : null,
            item.finding != null ? PalpationFindingEnum[item.finding].name : null,
            item.pain != null ? item.pain + "/10" : null,
            StringUtil.isNullOrEmpty(item.detail) ? null : item.detail
        ].filter(Boolean).join(", ")
    }
}