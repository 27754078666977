import {GenericChecker} from "../GenericChecker";
import * as ArrayUtils from "../../../../../utils/ArrayUtil";

export class AggravatingFactorChecker extends GenericChecker {
    static isDataEntered(entity) {
        if (entity == null) {
            return false;
        }
        return !ArrayUtils.isEmpty(entity.aggravatingFactor) || entity.aggravatingFactorNormal;
    }
}