import React, {Component} from "react";
import '../../EMR.css';
import {TextInput} from "../../../../../components";
import GradientSlider100Mark4Category from "../../../../../components/slider/GradientSlider100Mark4Category";
import GradientSlider10Mark4Category from "../../../../../components/slider/GradientSlider10Mark4Category";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";

export default class TreatmentResponseModal extends Component {

    state = {};

    render() {
        const response = this.props.treatment.response;

        return (
            <div className={" content-row emr-background"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px"}}>
                    <div className={"row"} style={{marginBottom: "20px"}}>
                        <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Response To Treatment <RedAsterisk renderForAllTypes/></div>
                    </div>
                    {this.renderValue('GradientSlider100Mark4Category', response,
                        'Overall Improve By:', 'overallImproveValue', 'overallImproveDetails')}
                    {this.renderValue('GradientSlider100Mark4Category', response,
                        'ROM Improve By:', 'romImproveValue', 'romImproveDetails')}
                    {this.renderValue('GradientSlider10Mark4Category', response,
                        'Pain Score (VAS):', 'painScoreValue', 'painScoreDetails')}
                </div>
            </div>
        );
    }

    renderValue = (sliderComponent, response, label, valueProp, detailsProp) => {

        const SliderComponent = sliderComponent === 'GradientSlider100Mark4Category' ?
            GradientSlider100Mark4Category : sliderComponent === 'GradientSlider10Mark4Category' ?
                GradientSlider10Mark4Category : null;

        return <div className={"row"}>
            <div className={"col-7 "}>
                <div style={{paddingBottom: '15px'}}>
                    {label}
                </div>
                <SliderComponent
                    value={response[valueProp] || 0}
                    onChange={(e, val) => {
                        response[valueProp] = val;
                        this.forceUpdate();
                    }}
                />
            </div>
            <div className={"col-5 "} style={{paddingLeft: "20px"}}>
                <div style={{paddingBottom: '15px'}}>
                   Details
                </div>
                <TextInput
                    value={response && response[detailsProp] || ''}
                    placeholder={"Details"}
                    style={{fontSize: "14px", maxHeight: "14px", width: "98%", minHeight: "30px"}}
                    onChange={value => {
                        response[detailsProp] = value;
                        this.forceUpdate();
                    }}
                />
            </div>
        </div>
    }

}
