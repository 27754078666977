import React, {Component} from "react";
import '../../EMR.css';
import DateUtil from "../../../../../utils/DateUtil";
import TreatmentReceivedEnum from "../enums/treatmentrecieved/TreatmentReceivedEnum";
import {MultiSelect} from "primereact/multiselect";
import {FormField} from "../../../../../components/form";

export default class TreatmentReceivedCptModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            procedureList: props.procedureList
        }
    }

    treatmentsChanged = (procedure, treatments) => {
        procedure.treatments = treatments;
        this.forceUpdate();
    }

    render() {
        const receivedTreatmentsWithData = TreatmentReceivedEnum.toArray()
            .filter(treatment => this.props.treatmentReceived[treatment.field]?.length > 0)

        return (
            <div>
                <div className={"content-card"}>
                    <table className={"emr-table"}>
                        <tbody>
                        <tr>
                            <th>Cpt Code</th>
                            <th>Time</th>
                            <th>Treatment Received</th>
                        </tr>
                        {this.state.procedureList?.map((procedure, index) =>
                            <tr key={"procedures" + index} style={{backgroundColor: "white"}}>
                                <td><span
                                    className="font-weight-bold">{procedure.cpt.cptCode}</span> - {procedure.cpt.description}
                                </td>
                                <td className="text-nowrap"><span
                                    className="font-weight-bold">Start:</span> {DateUtil.getHourMinute24H(procedure.startTime)} &nbsp;
                                    <span
                                        className="font-weight-bold">End:</span> {DateUtil.getHourMinute24H(procedure.endTime)} &nbsp;
                                </td>
                                <td><FormField ref={formField => this.props.formFields.push(formField)}
                                               required validateOn={procedure.treatments}>
                                    <MultiSelect value={procedure.treatments}
                                                 options={receivedTreatmentsWithData}
                                                 optionLabel="name" optionValue="key"
                                                 onChange={e => this.treatmentsChanged(procedure, e.value)}/>
                                </FormField></td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
