import React from 'react';

export class FormSectionHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return <div className="row FormSectionHeader">
            <div className="col-10 col-md-12">{this.props.header}</div>
        </div>
    }

}
