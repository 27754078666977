import React, {Component} from "react";
import VisitNavigator from "../common/VisitNavigator";
import VisitForms from "./VisitForms";

export default class EmrForms extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedVisitIndex: this.props.currentVisitIndex,
            selectedVisitId: this.getSelectedVisitId(this.props.currentVisitIndex)
        };
    }

    getSelectedVisitId = (index) => {
        return this.props.visits[index].id;
    }

    render() {
        return (
            <div className={"px-4"}>
                <div className="d-flex flex-row p-justify-end">
                    <VisitNavigator
                        showProvider={false}
                        visits={this.props.visits}
                        currentVisitIndex={this.props.currentVisitIndex}
                        selectedIndex={this.state.selectedVisitIndex}
                        onDateChange={(index) => {
                            this.setState({
                                selectedVisitIndex: index,
                                selectedVisitId: this.getSelectedVisitId(index)
                            })
                        }}
                    />
                </div>
                <div style={{marginTop: "10px"}}>
                    <VisitForms
                        patientId={this.props.patientId}
                        visitId={this.state.selectedVisitId}
                    />
                </div>
            </div>
        );
    }

}
