import DateUtil from "../../../utils/DateUtil";
import AuthService from "../../../services/auth-service/AuthService";

const isPatientOver18YearsOld = (birthday) => {
    const {uaeSelected, kuwaitSelected} = AuthService.getSelectedClinicCountriesInfo();
    if (kuwaitSelected && uaeSelected)
        return birthday && DateUtil.getAge(birthday) >= 18;
    else if (kuwaitSelected && !uaeSelected)
        return birthday && DateUtil.getAge(birthday) >= 21;
    else // We assumed that uae is the default
        return birthday && DateUtil.getAge(birthday) >= 18;
}

const getWitnessAgeText = () => {
    const {uaeSelected, kuwaitSelected} = AuthService.getSelectedClinicCountriesInfo();
    if (!kuwaitSelected && uaeSelected)
        return "18";
    else if (kuwaitSelected && !uaeSelected)
        return "21";
    else // If both clinics selected
        return "18";
}

export default {isPatientOver18YearsOld, getWitnessAgeText};
