import Enum from "../../../enums/Enum";

const EnumScheduleJobStatus = new Enum({
    IN_PROGRESS: "In Progress",
    COMPLETED: "Completed",
    CONFIRMED: "Confirmed",
    CANCELLED: "Cancelled",
    REJECTED: "Rejected"
});

export default EnumScheduleJobStatus;
