import React, {Component} from "react";
import "../../VisitHistory.css";

export default class ComplaintViewHistory extends Component {
    render() {
        if (this.props.complaint == null
            ||
            ((this.props.complaint.complaint == null || this.props.complaint.complaint === " ")
                && (this.props.complaint.secondaryComplaint == null || this.props.complaint.secondaryComplaint === " "))) {
            return <div className="pl-3">No information available</div>;
        }
        return (
            <div className="pl-3">
                <div className={"visit-history-content"}>
                    <span className="d-block mb-3">
                        {this.props.complaint.complaint == null || this.props.complaint.complaint === " " ? "NA"
                            : this.props.complaint.complaint}
                    </span>

                    <span className="d-block mb-1 font-weight-bold">
                            Secondary Complaint:
                    </span>

                    <span className="d-block">
                        {this.props.complaint.secondaryComplaint == null || this.props.complaint.secondaryComplaint === " " ? "NA"
                            : this.props.complaint.secondaryComplaint.name}
                    </span>
                </div>
            </div>
        );
    }
}
