import "./LeaveCalendar.css";
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import React, {Component} from "react";
import moment from "moment";
import {momentLocalizer, Views} from "react-big-calendar";
import {Button, Col, Container, Row} from "react-bootstrap";
import AuthService from "../../services/auth-service/AuthService";
import AppointmentCalendarFormats from "../appointment/AppointmentCalendarFormats";
import AppointmentUtil from "../../utils/AppointmentUtil";
import RemotingService from "../../services/remoting-service/RemotingService";
import HrEventWrapper from "./HrEventWrapper";
import DateUtil from "../../utils/DateUtil";
import {noop} from "lodash-es";
import LeaveModal from "./LeaveModal";
import EnumLeaveType from "./EnumLeaveType";
import SpecialityAndStaffFilter from "./SpecialityAndStaffFilter";
import {extract, isEmpty} from "../../utils/ArrayUtil";
import LeaveCalendarAgenda from "./LeaveCalendarAgenda";
import {currentTimezone} from "../../utils/CalendarDateUtil";
import BigCalendar from "../../components/big-calendar/BigCalendar";
import {connect} from "react-redux";
import CalendarEventService from "../appointment/CalendarEventService";
import NotificationService from "../../services/notification-service/NotificationService";
import HRRemoteService from "./HRRemoteService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import dialog from "../../components/dialog/Dialog";
import CloseClinicModal from "./CloseClinicModal";
import NumberUtil from "../../utils/NumberUtil";
import DurationCalculator from "./DurationCalculator";
import AvailableRooms from "../room/AvailableRooms";
import FlexibleLeaveModal from "./FlexibleLeaveModal";
import AvailableRoomModal from "./AvailableRoomModal";
import {DateInput} from "../../components";
import PrimeDateInput from "../../components/date-input/PrimeDateInput";

const HOLIDAY_DATE_BACKGROUND_COLOR = '#cff9d4';

const _5AM = {hour: 5, minute: 0, second: 0, millisecond: 0};
const _1PM = {hour: 13, minute: 0, second: 0, millisecond: 0};
const _2PM = {hour: 14, minute: 0, second: 0, millisecond: 0};
const _11PM = {hour: 23, minute: 0, second: 0, millisecond: 0};

const DUBAI_ZONE = 'Asia/Dubai';
const KUWAIT_ZONE = 'Asia/Kuwait';
const DXB_CALENDAR_DAY_PERCENTAGE = 1.75;
const KWI_CALENDAR_DAY_PERCENTAGE = 1.5;


class LeaveCalendar extends Component {

    constructor(props) {
        super(props);

        const loginUser = AuthService.getUser();
        this.isKuwaitiStaff = !!loginUser.clinics.find(clinic => clinic.name === "Kuwait");
        this.isDubaiStaff = !!loginUser.clinics.find(clinic => clinic.name === "JLT" || clinic.name === "DHCC");

        const date = DateUtil.today().toDate();
        const view = Views.MONTH;

        this.state = {
            staffId: loginUser.staffId,

            timeZone: AuthService.getSelectedClinicCountriesInfo().uaeSelected ? DUBAI_ZONE : KUWAIT_ZONE,
            selectedDate: date,
            selectedView: view,

            publicHolidays: [],
            metaDatas: [],
            leaves: [],
            appointments: [],
            blockEvents: [],
            blockSummaryEvents: [],
            appointmentsByDate: new Map(),
            blockEventMapByStaffId: new Map(),
            selectedLeaveDetails: [],
            selectedStaffList: [],
            dateRangeStart: new Date(),
            dateRangeEnd: new Date()
        };

        const {startTime, endTime} = this.calculateStartEnd(view, date);
        this.state.selectedStartTime = startTime;
        this.state.selectedEndTime = endTime;

        this.localizer = momentLocalizer(moment);
    }

    componentDidMount() {
        if(this.props.schedules && this.props.schedules.length !== 0){
            RemotingService.getRemoteCall('api/hr/leave/parameters', null, (leaveParameters) => {
                const publicHolidays = this.isKuwaitiStaff ? leaveParameters.kuwaitPublicHolidays : leaveParameters.dubaiPublicHolidays;
                this.setState({leaveParameters, publicHolidays}, () => {
                    this.refreshCalendarData();
                });
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
        if (prevProps.schedules && this.props.schedules && prevProps.schedules.length !== this.props.schedules.length) {
            this.refreshCalendarData();
        }
    }

    // Utility

    isAdminPage() {
        return this.props.page === "MANAGE_LEAVE_REQUESTS";
    }

    calculateStartEnd = (selectedView, selectedDate) => {
        const {startTime, endTime} = AppointmentUtil.calculateStartEnd(selectedView, selectedDate);

        return {
            startTime: this.createTimeAtClinicTimeZone(startTime),
            endTime: this.createTimeAtClinicTimeZone(endTime)
        }
    }

    getMetaDataForType = (type, staffId) => {
        const metaData = this.state.metaDatas.find(i => i.type === type && i.staffId === staffId);
        return metaData || {remaining: '...'};
    }

    publicHolidaysAtDate = (date) => {
        return this.state.publicHolidays
            .filter(h => moment(date).isBetween(h.start, h.end, undefined, '[]'))
            .map(h => h.name)
            .join(', ');
    }

    createTimeAtClinicTimeZone = (date) => {
        return DateUtil.switchZone(date, this.state.timeZone).toDate();
    }

    convertClinicToLocalTimeZone = (date) => {
        const momentAtClinic = moment.tz(date, this.state.timeZone);
        return DateUtil.switchZone(momentAtClinic, currentTimezone).toDate();
    }

    changeTimezone(timeZone) {
        this.setState({timeZone}, () => this.refreshCalendarData());
    }

    // API Operations
    refreshCalendarData = () => {
        if (this.isAdminPage() && isEmpty(this.state.selectedStaffList )) {
            this.setState({
                metaDatas: [],
                leaves: [],
                appointments: [],
                blockEvents: [],
                blockSummaryEvents: [],
                appointmentsByDate: new Map(),
                blockEventMapByStaffId: new Map()
            });
            return;
        }

        this.retrieveLeaveMetaData(() => {
            this.retrieveBlockEvents();
            this.retrieveAppointments();
            this.retrieveLeaves();
        });
    }

    retrieveLeaveMetaData = (callback = noop) => {
        const staffIds = this.isAdminPage() ? extract(this.state.selectedStaffList, "id") : [this.state.staffId];

        RemotingService.getRemoteCall(
            'api/hr/leave/meta', {staffIds}, (metaDatas) => {
                this.setState({metaDatas}, callback);
            });
    }

    retrieveBlockEvents = () => {
        const staffIds = this.isAdminPage() ? extract(this.state.selectedStaffList, "id") : [this.state.staffId];
        const periodStart = this.state.selectedStartTime;
        const periodEnd = this.state.selectedEndTime;

        RemotingService.postRemoteCall(
            'api/staff/rotation/manual',
            {startDate: DateUtil.getUtcDateAtStartOfDay(periodStart), endDate: DateUtil.getUtcDateAtStartOfDay(periodEnd), staffIds: staffIds},
            (rotations) => {
                const {schedules} = this.props;
                let events = [];
                let blockEventMapByStaffId = new Map();

                staffIds.forEach(staffId => {
                    const manualRotationsForStaff = rotations.filter(rotation => rotation.staffId === staffId);
                    const result = CalendarEventService.createEvents([], {id: staffId}, schedules, manualRotationsForStaff, periodStart, periodEnd);
                    events.push(...result.events);

                    let blockEventsByDate = new Map();
                    result.events.forEach(e => {
                        e.start = this.convertClinicToLocalTimeZone(e.start);
                        e.end = this.convertClinicToLocalTimeZone(e.end);
                        e.isBlockEvent = true;

                        const key = DateUtil.formatDate(e.start);
                        if (blockEventsByDate.has(key)) {
                            blockEventsByDate.get(key).push(e);
                        } else {
                            blockEventsByDate.set(key, [e]);
                        }
                    });

                    blockEventMapByStaffId.set(staffId, blockEventsByDate);
                });

                this.setState({
                    blockEvents: events,
                    blockEventMapByStaffId: blockEventMapByStaffId,
                    blockSummaryEvents: this.createSummaryEvents(events)
                });
            });
    }

    retrieveAppointments = () => {
        if (this.isAdminPage()) {
            this.setState({appointments: []});
            return;
        }

        RemotingService.getRemoteCall(
            'api/appointment/list',
            {
                staffIds: [this.state.staffId],
                staffSpecialities: this.isAdminPage() ? extract(this.state.selectedSpecialityList, "name") : null,
                startTime: this.state.selectedStartTime.toISOString(),
                endTime: this.state.selectedEndTime.toISOString(),
                ignoredStatuses: ['NO_SHOW', 'CANCELLED']
            },
            (result) => {
                const appointments = result.items;
                const appointmentsByDate = new Map();

                appointments.forEach(a => {
                    a.startMoment = moment(a.start);
                    a.endMoment = moment(a.end);
                    a.start = this.convertClinicToLocalTimeZone(a.start);
                    a.end = this.convertClinicToLocalTimeZone(a.end);
                    a.isAppointmentEvent = true;

                    const key = DateUtil.formatDate(a.start);
                    if (appointmentsByDate.has(key)) {
                        appointmentsByDate.get(key).push(a);
                    } else {
                        appointmentsByDate.set(key, [a]);
                    }
                });
                this.setState({appointments, appointmentsByDate});
            });
    };

    retrieveLeaves = () => {
        RemotingService.getRemoteCall(
            'api/hr/leave/list',
            {
                staffIds: this.isAdminPage() ? extract(this.state.selectedStaffList, "id") : [this.state.staffId],
                staffSpecialities: this.isAdminPage() ? extract(this.state.selectedSpecialityList, "name") : null,
                startTime: this.state.selectedStartTime.toISOString(),
                endTime: this.state.selectedEndTime.toISOString(),
                clinicIds: AuthService.getClinics()
            },
            (result) => {
                const leaves = result.items;
                leaves.forEach(leave => {
                    this.hydrateLeave(leave);
                });
                this.setState({leaves});
            });
    }

    createSummaryEvents(events) {
        const summaryEventsMap = new Map();
        events.filter(e => e.type !== "LUNCH" && e.type !== "BREAK" && e.type !== "PROCEDURE")
            .forEach(e => {
                const key = DateUtil.formatDate(e.start) + "-" + e.type;
                const summaryEvent = summaryEventsMap.get(key);
                if (summaryEvent) {
                    summaryEvent.count++;
                } else {
                    summaryEventsMap.set(key, {
                        start: moment(e.start).hours(7).toDate(),
                        end: moment(e.end).hours(22).toDate(),
                        type: e.type,
                        count: 1,
                        isSummaryEvent: true
                    });
                }
            });
        return Array.from(summaryEventsMap.values());
    }

    hydrateLeave = (leave) => { // This should be called on data fetched by remote
        leave.untouchedStartTime = leave.startTime;
        leave.untouchedEndTime = leave.endTime;
        leave.startTime = this.convertClinicToLocalTimeZone(leave.startTime);
        leave.endTime = (this.isHourly(leave.type) || leave.type === "CLOSED") ?
                            this.convertClinicToLocalTimeZone(leave.endTime) :
                            DateUtil.add(this.convertClinicToLocalTimeZone(leave.endTime), -1, "millisecond");
        leave.meta = this.getMetaDataForType(leave.type, leave.staffId);
        leave.start = leave.startTime;
        leave.end = leave.endTime;
    }

    filterLeave = (leave) => {
        return this.state.leaves.filter(i => i.id !== leave.id);
    }

    isDaily = (leaveType) => {
        return !this.isHourly(leaveType);
    }

    isHourly = (leaveType) => {
        return EnumLeaveType[leaveType].hourly;
    }

    isResizable = (leaveType) => {
        return (EnumLeaveType[leaveType].resizable != false);
    }

    isPendingLeave = (item) => {
        return item.type && 'PENDING' === item.status; // leaves have 'type's , appointments do not
    }

    isFollowUpEvent = (event) =>{
        return event.type === "FOLLOW_UP" || event.patientType === "FOLLOW_UP";
    }

    isNewPatientEvent = (event) =>{
        return event.type === "NEW_PATIENT" || event.patientType === "NEW_PATIENT";
    }

    addLeave = (staffId, type, startTime, endTime, description, attachment, room, isFlexibleHoursCompensation, extraDayType) => {
        if(type === EnumLeaveType.EXTRA.key){
            startTime = DateUtil.getUtcDateAtStartOfDay(startTime);
            endTime = DateUtil.endOfDayInAnyUtc(endTime);
        }

        const params = {
            startTime,
            endTime,
            staffId,
            type,
            description,
            attachment,
            room,
            isFlexibleHoursCompensation,
            extraDayType
        };

        RemotingService.postRemoteCall('api/hr/leave', params, (leave) => {
            this.hydrateLeave(leave);
            this.refreshCalendarData();
        });
    }

    updateLeave = (id, startTime, endTime, description, attachment, room) => {
        const startTimeAtClinicTimeZone = this.createTimeAtClinicTimeZone(startTime);
        const endTimeAtClinicTimeZone = this.createTimeAtClinicTimeZone(endTime);

        const params = {
            startTime: startTimeAtClinicTimeZone,
            endTime: endTimeAtClinicTimeZone,
            id,
            description,
            attachment,
            room
        };

        RemotingService.postRemoteCall('api/hr/leave/update', params, (leave) => {
            this.hydrateLeave(leave);
            this.refreshCalendarData();
            this.setState({
                leaves: [...this.filterLeave(leave), leave],
            })
        });
    }

    handleSubmitted = (leave) => {
        this.replaceLeaveInState(leave);
        this.refreshCalendarData();
    }

    handleApproved = (leave) => {
        this.replaceLeaveInState(leave);
    }

    handleRejected = (leave) => {
        this.replaceLeaveInState(leave);
    }

    handleDeleted = (leave) => {
        this.setState({
            leaves: this.filterLeave(leave),
        });
        this.refreshCalendarData();
    }

    replaceLeaveInState = (leave) => {
        this.hydrateLeave(leave);
        this.setState({
            leaves: [...this.filterLeave(leave), leave],
        });
    }

    // Calendar Operations

    onEventUpdate = (event, startTime, endTime, description, attachment, room, isFlexibleHoursCompensation, extraDayType) => {
        if (!event.id) {
            this.addLeave(event.staffId, event.type, startTime, endTime, description, attachment, room, isFlexibleHoursCompensation, extraDayType);
        } else {
            this.updateLeave(event.id, startTime, endTime, description, attachment, room);
        }
    }

    onEventResize = ({event, start, end}) => {
        if (this.isDaily(event.type)) {
            start = moment(start).set(_5AM).toDate();
            end = moment(end).add(-1, 'minutes').set(_11PM).toDate();
        }

        if(this.isResizable(event.type)){
            this.updateLeave(event.id, start, end, event.description, event.attachment, event.room);
        }
    }

    onEventDrop = ({event, start, end}) => {
        if (this.isDaily(event.type)) {
            start = moment(start).set(_5AM).toDate();
            const dayDiff = DateUtil.diff(event.startTime, start, 'days');
            end = moment(event.endTime).add(dayDiff, 'day');
        }

        this.updateLeave(event.id, start, end, event.description, event.attachment, event.room);
    }

    onDropFromOutside = ({start, end}) => {
        if (this.state.selectedView === Views.MONTH
            && !DateUtil.isBetweenInclusive(start, this.state.selectedStartTime, this.state.selectedEndTime)) {
            return; // prevent drag&drop to another month
        }

        const type = this.state.draggedType;

        let startTime = start;
        let endTime = end;
        if (this.isHourly(type)) {
            if (type === "FLEXIBLE" || type === "COURSE") {
                const {hourlyStart, hourlyEnd} = this.getStartEndForHourly(startTime);

                if (!hourlyStart || !hourlyEnd) {
                    return;
                }

                startTime = hourlyStart;
                endTime = hourlyEnd;
            } else if (Views.MONTH === this.state.selectedView) {
                startTime = moment(startTime).set(_1PM).toDate();
                endTime = moment(startTime).set(_2PM).toDate();
            }
        } else {
            startTime = moment(startTime).set(_5AM).toDate();
            endTime = moment(startTime).set(_11PM).toDate();
        }

        const newLeave = {
            staffId: this.state.staffId,
            meta: this.getMetaDataForType(type, this.state.staffId),
            status: 'PENDING',
            type,
            startTime,
            endTime,
            start: startTime,
            end: endTime
        };

        this.setState({newLeave});
        this.openLeaveModal(newLeave);
    }

    getStartEndForHourly(startTime) {
        const sortedEvents = this.state.blockEvents
                                .filter(e => DateUtil.isSame(e.start, startTime, 'day') && e.staffId === this.state.staffId)
                                .sort((e1, e2) => e1.start - e2.start);

        if (!sortedEvents || sortedEvents.length === 0) {
            return {};
        }

        const firstEvent = sortedEvents[0];
        const lastEvent = sortedEvents[sortedEvents.length - 1];

        if (DateUtil.isBefore(startTime, firstEvent.start) || DateUtil.isAfter(startTime, lastEvent.end)) {
            return {};
        }

        let eventIndex = sortedEvents.findIndex(e => DateUtil.isSameOrAfter(startTime, e.start)
                                            && DateUtil.isBefore(startTime, e.end));

        if (eventIndex < 0) {
            return {};
        }

        let blockEvent = sortedEvents[eventIndex];
        const appointment = this.findFirstAppointmentAfter(blockEvent.start);

        let hourlyStart, hourlyEnd;
        if (appointment) {
            if (DateUtil.isSame(blockEvent.start, appointment.start) && DateUtil.isSame(blockEvent.end, appointment.end)) {
                NotificationService.showNotification({
                    severity: 'error',
                    summary: 'Validation Error',
                    detail: 'Cannot select slot with appointment.'
                });
                return {};
            }

            hourlyStart = blockEvent.start;
            hourlyEnd = appointment.start;
        } else {
            hourlyStart = blockEvent.start;
            hourlyEnd = lastEvent.end;
        }

        return {hourlyStart, hourlyEnd};
    }

    findFirstAppointmentAfter(startTime) {
        const sortedAppointments = this.state.appointments
                                                .filter(a => DateUtil.isSame(a.start, startTime, 'day') && a.staff.id === this.state.staffId)
                                                .sort((a1, a2) => a1.start - a2.start);

        for (const appointment of sortedAppointments) {
            if (DateUtil.isSameOrBefore(startTime, appointment.start)) {
                return appointment;
            }
        }
        return null;
    }

    handleEventSelected = (event) => {
        if (this.isFollowUpEvent(event) || this.isNewPatientEvent(event) || event.type === "MANAGEMENT" || event.type === "LUNCH") {
            if (this.state.selectedView == Views.DAY || this.state.selectedView == Views.WEEK) {
                this.props.history.push(
                    '/appointment?id=' + event.id +
                    '&selectedStaff=' + this.state.staffId +
                    '&selectedView=' + this.state.selectedView +
                    '&selectedDate=' + DateUtil.formatDate(new Date(event.start)));

                this.props.history.go(0);
            }
        }
        else if (event.type !== "CLOSED") {
            this.openLeaveModal(event);
        } else if (this.isAdminPage()) {
            this.closeClinicModal(event);
        }
    }

    handleViewChange(view) {
        const {startTime, endTime} = this.calculateStartEnd(view, this.state.selectedDate);
        this.setState({
            selectedView: view,
            selectedStartTime: startTime,
            selectedEndTime: endTime
        }, this.refreshCalendarData);
    }

    handleNavigation(date) {
        const selectedDate = DateUtil.startOfDay(date);
        const {startTime, endTime} = this.calculateStartEnd(this.state.selectedView, selectedDate);
        this.setState({
            selectedDate: selectedDate,
            selectedStartTime: startTime,
            selectedEndTime: endTime,
            dateRangeMode: false
        }, this.refreshCalendarData);
    }

    handleDateRangeNavigation() {
        const {dateRangeStart, dateRangeEnd} = this.state;
        if (DateUtil.isSameOrBefore(dateRangeStart, dateRangeEnd)) {
            this.setState({
                dateRangeMode: true,
                selectedStartTime: this.createTimeAtClinicTimeZone(DateUtil.startOfDay(dateRangeStart)),
                selectedEndTime: this.createTimeAtClinicTimeZone(DateUtil.endOfDay(dateRangeEnd))
            }, this.refreshCalendarData);
        } else {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Invalid Date Range.'
            });
        }
    }

    handleSpecialitySelectionChange = (selectedSpecialityList) => {
        this.setState({selectedSpecialityList}, this.refreshCalendarData);
    };

    handleStaffSelectionChange = (selectedStaffList) => {
        this.setState({selectedStaffList}, this.refreshCalendarData);
    };

    renderLeaveType = (params) => {
        const type = params.key;
        const metaData = this.getMetaDataForType(type, this.state.staffId);
        const remaining = type === 'ANNUAL' ? metaData.remaining : Math.max(metaData.remaining, 0);
        const disabled = metaData.quota <= 0
            || this.isFlexibleHoursInMonthView(type)
            || this.isCourseLeaveInMonthView(type)
            || (type == EnumLeaveType.COURSE.key && remaining === 0);

        let remainingLabel , remainingCalendarDaysLabel;
        if (params.isUnlimited) {
            remainingLabel = "-/-"
        } else if (params.hourly) {
            remainingLabel = `${DateUtil.formatHoursDuration(remaining)}/${metaData.quota}${params.unit}`;
        } else {
            remainingLabel = `${NumberUtil.toFixed(remaining,2)}/${metaData.quota}${params.unit}`;
        }
        if(type === 'ANNUAL') {
            const percentage = this.isDubaiStaff ? DXB_CALENDAR_DAY_PERCENTAGE  : KWI_CALENDAR_DAY_PERCENTAGE;
            const remainingCalendarDays = ((metaData.quota * percentage) - (metaData.used * percentage));
            remainingCalendarDaysLabel = `${NumberUtil.toFixed(remainingCalendarDays,2)}/${metaData.quota * percentage}${params.unit}`;
        }

        return (
            <div key={type} className="row light-border"
                 style={{marginTop: '10px', padding: '3px', borderRadius: 0, backgroundColor: `${disabled ? '#f4f4f4' : ''}`}}>
                <div className="col-4 text-center"
                     style={{borderRight: '1px solid lightgray', wordBreak: "break-word"}}>
                    {remainingCalendarDaysLabel && <div style={{ height:40}}></div>}
                   <div>{remainingLabel}</div>
                   <div className={"mt-2"}>{remainingCalendarDaysLabel && <div  className={"border-top pt-1"}></div>} {remainingCalendarDaysLabel} </div>

                </div>
                <div key={type + "Leave"} draggable={!disabled}
                     className={`col-8 ${disabled ? '' : 'draggable'}`}
                     onDragStart={() => {
                         this.setState({draggedType: type});
                     }}>
                    <span className="circle-background" style={{background: params.color}}>{params.code}</span> {params.name}
                    {remainingCalendarDaysLabel && <div className={"mt-2"}>Working Days</div>}
                    {remainingCalendarDaysLabel && <div className={"mt-2 border-top pt-1"}>Calendar Days</div>}

                </div>
            </div>
        );
    }

    isFlexibleHoursInMonthView = (type) => {
        return (this.state.selectedView == Views.MONTH && type == EnumLeaveType.FLEXIBLE.key);
    }

    isCourseLeaveInMonthView = (type) => {
        return (this.state.selectedView == Views.MONTH && type == EnumLeaveType.COURSE.key);
    }

    closeLeaveUpdateView = () => {
        this.setState({newLeave: null});
    }

    openLeaveModal = (leave) => {
        const Modal = leave.type == "FLEXIBLE" ? FlexibleLeaveModal : LeaveModal;
        dialog({
            component: <Modal leave={leave}
                              comments={this.state.comments}
                              isAdminPage={this.isAdminPage()}
                              schedules={this.props.schedules}
                              timezone={this.state.timeZone}
                              onClose={this.closeLeaveUpdateView}
                              onSave={({startTime, endTime, description, attachment, room, isFlexibleHoursCompensation, extraDayType}) => {
                                  this.onEventUpdate(leave, startTime, endTime, description, attachment, room, isFlexibleHoursCompensation, extraDayType);
                              }}
                              onSubmitted={this.handleSubmitted}
                              onApproved={this.handleApproved}
                              onRejected={this.handleRejected}
                              onDeleted={this.handleDeleted}
                              openLeaveModal={this.openLeaveModal}
                              getMetaDataForType={this.getMetaDataForType}/>
        })
    }

    closeClinicModal = (leave) => {
        dialog({
            title: "Close Clinic",
            component: <CloseClinicModal leave={leave}
                                         onSave={leave => {
                                            this.hydrateLeave(leave);
                                            this.setState({
                                                leaves: [...this.state.leaves, leave]
                                            });
                                         }}
                                         onUpdate={leave => {
                                             this.hydrateLeave(leave);
                                             this.setState({
                                                 leaves: [...this.filterLeave(leave), leave],
                                             });
                                         }}
                                         onDelete={this.handleDeleted}/>
        });
    }

    dayDateHeader = (date, culture, localizer) => {
        const label = localizer.format(date, 'dddd, MMMM DD, YYYY', culture);
        const publicHolidaysAtDate = this.publicHolidaysAtDate(moment(date).set({hour: 0, minute: 0, second: 0, millisecond: 0}));
        return publicHolidaysAtDate.length ? `${label} (${publicHolidaysAtDate})` : label;
    }

    weekDateHeader = ({date, localizer}) => {
        const publicHolidaysAtDate = this.publicHolidaysAtDate(date);
        return publicHolidaysAtDate.length ?
            <div style={{backgroundColor: HOLIDAY_DATE_BACKGROUND_COLOR}}>
                {`${localizer.format(date, 'ddd')} (${publicHolidaysAtDate})`}
            </div> :
            localizer.format(date, 'ddd');
    }

    monthDateHeader = ({date, label}) => {
        const publicHolidaysAtDate = this.publicHolidaysAtDate(date);
        return publicHolidaysAtDate.length ?
            <div style={{backgroundColor: HOLIDAY_DATE_BACKGROUND_COLOR}}>
                {`${label} (${publicHolidaysAtDate})`}
            </div> : label;
    };

    eventPropGetter = (event) => {
        const eventProps = {
            style: {}
        };

        if (event.isBlockEvent) {
            eventProps.style.zIndex = 0;
        } else if (event.isAppointmentEvent) {
            eventProps.style.zIndex = 1;
        } else if (event.type !== "CLOSED"){
            eventProps.style.zIndex = 2;
        } else {
            eventProps.style.zIndex = 3;
        }

        return eventProps;
    }

    dayPropGetter(date) {
        if (this.isAdminPage()) {
            return;
        }

        const key = DateUtil.formatDate(date);
        const hasAppointment = this.state.appointmentsByDate.has(key);
        const hasSchedule = this.state.blockEventMapByStaffId.get(this.state.staffId)?.has(key);
        const backgroundColor = hasAppointment ? "#E6E6E6" : (hasSchedule ? "#DDFFDD" : undefined);

        return {
            style: {backgroundColor}
        };
    }

    doesLeaveSelected = (leave) => {
        return this.state.selectedLeaveDetails.some(selectedLeaveDetail => selectedLeaveDetail.id===leave.id)
    }

    updateSelectedLeaveDetails = (leave) => {
        let updatedLeaveDetails = this.state.selectedLeaveDetails
        this.doesLeaveSelected(leave) ? updatedLeaveDetails = this.state.selectedLeaveDetails.filter(leaveDetail => leaveDetail.id!==leave.id) : updatedLeaveDetails.push(leave);
        this.setState({selectedLeaveDetails: updatedLeaveDetails});
    }

    renderLeaveDetails = (leave) => {
        const type = EnumLeaveType[leave.type];
        const leaveComment = leave.comments != null && leave.comments.length>0 ? leave.comments[leave.comments.length-1]?.comment : ""
        const duration = DurationCalculator.getDuration(leave, leave.startTime, leave.endTime, this.state.blockEventMapByStaffId.get(leave.staffId));

        return (
            <div key={type} className="row light-border"
                 onClick={() => {
                     leave.status==="PENDING" && this.updateSelectedLeaveDetails(leave)
                 }}
                 style={{marginTop: '10px', padding: '3px', borderRadius: 0, backgroundColor: `${this.doesLeaveSelected(leave) ? '#52af77' : '#f4f4f4'}`}}>
                <div draggable={false} className="column margin5">
                    <div draggable={false} className="row margin5">
                        {leave.status==="SUBMITTED" &&
                        <div className="row">
                            <FontAwesomeIcon icon={["fas", "thumbs-up"]} style={{color: '#F5BB43', marginLeft:"15px", marginTop:"3px"}}/>
                            <div style={{color: '#F5BB43', marginLeft:"10px"}}>Submitted {EnumLeaveType[leave.type].name}</div>
                        </div>
                        }
                        {leave.status==="APPROVED" &&
                        <div className="row">
                            <FontAwesomeIcon icon={["fas", "thumbs-up"]} style={{color: '#70A363', marginLeft:"15px", marginTop:"3px"}}/>
                            <div style={{color: '#70A363', marginLeft:"10px"}}>Approved {EnumLeaveType[leave.type].name}</div>
                        </div>
                        }
                        {leave.status==="REJECTED" &&
                        <div className="row">
                            <FontAwesomeIcon icon={["fas", "thumbs-down"]} style={{color: "red", marginLeft:"15px", marginTop:"3px"}}/>
                            <div style={{color: 'red', marginLeft:"10px"}}>Rejected {EnumLeaveType[leave.type].name}</div>
                        </div>
                        }
                    </div>
                    {leave.status!=="REJECTED" &&
                    <div draggable={false} className="row margin5">
                        {leave.status==="PENDING" && <span className="circle-background margin5" style={{background: type.color}}>{type.code}</span>}
                        <div className="column">
                            <div className="leave-date">
                                {DateUtil.formatDate(leave.startTime, 'DD-MM-YYYY')}
                            </div>
                            <div className="leave-day">
                                {DateUtil.getDayName(leave.startTime)}
                            </div>
                        </div>
                        <div className="leave-separator">
                            ->
                        </div>
                        <div className="column">
                            <div className="leave-date">
                                {DateUtil.formatDate(leave.endTime, 'DD-MM-YYYY')}
                            </div>
                            <div className="leave-day">
                                {DateUtil.getDayName(leave.endTime)}
                            </div>
                        </div>
                        {leave.status==="PENDING" &&
                        <div className="leave-date margin5">
                            {NumberUtil.isNumeric(duration) ? `${duration}d` : null}
                        </div>
                        }
                    </div>}
                    {leave.status==="APPROVED" && <div style={{fontWeight: "bold", marginLeft: "5px"}}>{leaveComment}</div>}
                    {leave.status==="REJECTED" && <div style={{color: "red", marginLeft: "5px"}}>{leaveComment}</div>}
                    </div>
            </div>
        );
    }

    renderTotalLeaveCount = () => {
        let totalDayCount = this.state.leaves
            .filter(leave => leave.status === "PENDING")
            .reduce(
                (acc, leave) => {
                    const duration = DurationCalculator.getDuration(leave, leave.startTime, leave.endTime, this.state.blockEventMapByStaffId.get(leave.staffId));
                    return acc + (NumberUtil.isNumeric(duration) ? duration : 0);
                }, 0);
        return(
            <div className="row margin5">
                <div className="total-count">Requested Days</div>
                <div className="total-count" style={{marginLeft: "100px"}}>{totalDayCount}d</div>
            </div>
        )
    }

    renderLeaveDetailButtons = () => {
        return(
            <div className="row margin5">
                <Button className="clear-all-button"
                        onClick={() => {
                            this.state.leaves.filter(leave => leave.status==="PENDING").length>0 && this.handleDeleteAllButtonClicked()
                        }}>Clear All</Button>
                <Button className="submit-for-approval-button"
                        onClick={() => {
                            this.state.selectedLeaveDetails.length>0 && this.handleSubmitForApprovalButtonClicked()
                        }}>Submit For Approval</Button>
            </div>
        )
    }

    handleSubmitForApprovalButtonClicked = () => {
        HRRemoteService.submitList(this.state.selectedLeaveDetails, () => {
            this.setState({selectedLeaveDetails: []});
            this.retrieveLeaves();
        })
    }

    handleDeleteAllButtonClicked = () => {
        HRRemoteService.deleteList(this.state.leaves.filter(leave => leave.status==="PENDING"), () => {
            this.setState({selectedLeaveDetails: []});
            this.retrieveLeaves();
        })
    }

    availableRoomModal = (availability) => {
        dialog({
            title: "",
            component: <AvailableRoomModal availability={availability}
                                           handleAvailableRoomModalSaved={this.handleAvailableRoomModalSaved}
                                           metaDatas={this.state.metaDatas}/>
        });
    }

    handleAvailableRoomModalSaved = (extraDayType, availability) => {
        const startTime = moment(availability.date).set(_5AM).toDate();
        const endTime = moment(availability.date).set(_11PM).toDate();

        this.addLeave(
            this.state.staffId,
            EnumLeaveType.EXTRA.key,
            startTime,
            endTime,
            null,
            null,
            availability.room,
            false,
            extraDayType
        );
    }

    getDateRangeDiff = (start , end) =>{
       return DateUtil.diff(DateUtil.startOfDay(start) , DateUtil.endOfDay(end) , 'days');
    }

    render() {
        const formats = {
            ...AppointmentCalendarFormats,
            dayHeaderFormat: this.dayDateHeader
        };
        const {selectedView, blockEvents, leaves, appointments, newLeave, blockSummaryEvents, dateRangeStart , dateRangeEnd} = this.state;
        const selectedDate = selectedView === Views.AGENDA && this.state.dateRangeMode? dateRangeStart : this.state.selectedDate;
        const timeZoneGetter = () => this.state.timeZone;
        const events = [...leaves];

        if (newLeave) {
            events.push(newLeave);
        }

        const backgroundEvents = [];
        if (!this.isAdminPage()) {
            backgroundEvents.push(...blockEvents);
            backgroundEvents.push(...appointments);
        }

        if (!this.isAdminPage() && selectedView === Views.MONTH) {
            events.push(...blockSummaryEvents);
        }

        return (
            <Container className="LeaveCalendar" style={{maxWidth: '100%', fontSize: 16}}>
                <Row>
                    <Col xs={2}>
                        {
                            this.isAdminPage() &&
                            <div>
                                <SpecialityAndStaffFilter selectedSpecialityList={this.state.selectedSpecialityList}
                                                          selectedStaffList={this.state.selectedStaffList}
                                                          onSpecialitySelectionChange={this.handleSpecialitySelectionChange}
                                                          onStaffSelectionChange={this.handleStaffSelectionChange}
                                />
                                <div className="mt-4"/>
                                <AvailableRooms startDate={this.convertClinicToLocalTimeZone(this.state.selectedStartTime)}
                                                endDate={this.convertClinicToLocalTimeZone(this.state.selectedEndTime)}
                                                staffId={this.state.selectedStaffList?.length === 1 ? this.state.selectedStaffList[0].id : null}
                                                isAdmin={this.isAdminPage()}
                                                leaves={this.state.leaves}/>
                            </div>
                        }
                        {
                            !this.isAdminPage() &&
                            <div>
                                <div className={"container"}
                                     style={{
                                         border: '1px solid lightgray',
                                         fontSize: 14,
                                         paddingLeft: 14,
                                         paddingRight: 14,
                                     }}>
                                    <div className="row" style={{
                                        fontWeight: 'bold',
                                        fontSize: 16,
                                        padding: 5,
                                        paddingLeft: 10,
                                        borderBottom: '1px solid lightgray'
                                    }}>Leave Types
                                    </div>
                                    {EnumLeaveType.toArray()
                                        .filter(leaveType => !leaveType.hidden)
                                        .map((leaveType) => this.renderLeaveType(leaveType))
                                    }
                                    <div className="row" style={{fontSize: 12, color: '#636470'}}>
                                        Drag & Drop on calendar to take leave
                                    </div>
                                </div>
                                <div style={{marginTop: "50px"}}/>
                                <div className={"container"}
                                     style={{
                                         border: '1px solid lightgray',
                                         fontSize: 14,
                                         paddingLeft: 14,
                                         paddingRight: 14,
                                     }}>
                                    <div className="row" style={{
                                        fontWeight: 'bold',
                                        fontSize: 16,
                                        padding: 5,
                                        paddingLeft: 10,
                                        borderBottom: '1px solid lightgray'
                                    }}>Leave Details
                                    </div>
                                    {this.state.leaves.filter(leave => leave.status==="PENDING").length===0 ?
                                        <div>Add leave type to submit for approval</div>:
                                        this.state.leaves.filter(leave => leave.status==="PENDING").map((leave) => this.renderLeaveDetails(leave))}
                                    <div className="row margin5">
                                        {this.state.leaves.filter(leave => leave.status==="PENDING").length > 0 && this.renderTotalLeaveCount()}
                                    </div>
                                </div>
                                <div className="row margin5">
                                    {this.renderLeaveDetailButtons()}
                                </div>
                                <div style={{marginTop: "50px"}}/>
                                <div className={"container"}
                                     style={{
                                         border: '1px solid lightgray',
                                         fontSize: 14,
                                         paddingLeft: 14,
                                         paddingRight: 14,
                                     }}>
                                    <div className="row" style={{
                                        fontWeight: 'bold',
                                        fontSize: 16,
                                        padding: 5,
                                        paddingLeft: 10,
                                        borderBottom: '1px solid lightgray'
                                    }}>Leave Status
                                    </div>
                                    {this.state.leaves.filter(leave => leave.status!=="PENDING").length===0 ?
                                        <div>You don't have any submitted leave yet</div> :
                                        this.state.leaves.filter(leave => leave.status!=="PENDING").map((leave) => this.renderLeaveDetails(leave))}
                                </div>
                                <div className="mt-4"/>
                                <AvailableRooms startDate={this.convertClinicToLocalTimeZone(this.state.selectedStartTime)}
                                                endDate={this.convertClinicToLocalTimeZone(this.state.selectedEndTime)}
                                                staffId={this.state.staffId}
                                                availableRoomClicked={this.availableRoomModal}
                                                isAdmin={this.isAdminPage()}/>
                            </div>
                        }
                    </Col>
                    <Col xs={10}>
                        {this.isAdminPage() &&
                        <div className="rbc-toolbar justify-content-end">
                            <span className="rbc-btn-group">
                                <Button variant={this.state.timeZone === DUBAI_ZONE ? "success" : null}
                                        onClick={() => this.changeTimezone(DUBAI_ZONE)}>GST (UAE)</Button>
                                <Button variant={this.state.timeZone === KUWAIT_ZONE ? "success" : null}
                                        onClick={() => this.changeTimezone(KUWAIT_ZONE)}>AST (Kuwait)</Button>
                            </span>
                            <DateInput variant="dialog" value={this.state.selectedDate}
                                       onChange={date => this.handleNavigation(date.toDate())}
                                       TextFieldComponent={
                                           ({onClick}) =>
                                               <Button style={{borderRadius: '35px', height: '35px', marginTop: '3px'}}
                                                       variant="outline-secondary" onClick={onClick}>
                                                   <FontAwesomeIcon icon={["fas", "calendar-alt"]}/> Go to Date
                                               </Button>
                                       }>
                            </DateInput>
                        </div>
                        }

                        <BigCalendar
                            dragDrop={true}
                            localizer={this.localizer}
                            formats={formats}
                            getNow={() => this.convertClinicToLocalTimeZone(new Date())}
                            min={moment({hour: 7}).toDate()}
                            max={moment({hour: 22}).toDate()}
                            leaves={leaves}
                            events={events}
                            refreshCalendarData={this.refreshCalendarData}
                            backgroundEvents={backgroundEvents}
                            date={selectedDate}
                            length={this.getDateRangeDiff(selectedDate, dateRangeEnd)}
                            onNavigate={date => this.handleNavigation(date)}
                            views={{
                                ...(this.isAdminPage() ? {agenda: LeaveCalendarAgenda} : null),
                                day: true,
                                week: true,
                                month: true
                            }}
                            drilldownView={Views.DAY}
                            messages={{agenda: 'List'}}
                            view={selectedView}
                            onView={view => this.handleViewChange(view)}
                            onEventResize={this.onEventResize}
                            resizableAccessor={(event) => this.isPendingLeave(event) && !this.isHourly(event.type) && Views.MONTH === selectedView && this.isResizable(event.type)}
                            draggableAccessor={(event) => this.isPendingLeave(event)}
                            onEventDrop={this.onEventDrop}
                            onDropFromOutside={this.onDropFromOutside}
                            onSelectEvent={this.handleEventSelected}
                            step={5}
                            timeslots={12}
                            showMultiDayTimes
                            ignoreOverlaps={!this.isAdminPage()}
                            eventPropGetter={this.eventPropGetter}
                            dayPropGetter={date => this.dayPropGetter(date)}
                            slotPropGetter={date => this.dayPropGetter(date)}
                            components={{
                                event: (props) => <HrEventWrapper {...props}
                                                                  timeZoneGetter={timeZoneGetter}
                                                                  viewGetter={() => this.state.selectedView}/>,
                                dateHeader: this.monthDateHeader,
                                week: {
                                    header: this.weekDateHeader
                                }
                            }}
                            toolbarActions={this.isAdminPage() ? <>
                                <button onClick={() => this.closeClinicModal()}>Close Clinic</button>
                                <PrimeDateInput showCalendarIcon variant="dialog" value={dateRangeStart}
                                                onChange={date => this.setState({dateRangeStart: date},()=> this.handleDateRangeNavigation())}
                                           disabled ={selectedView !== Views.AGENDA}>
                                </PrimeDateInput>

                                <PrimeDateInput showCalendarIcon variant="dialog" value={dateRangeEnd}
                                           onChange={date => this.setState({dateRangeEnd: date},()=> this.handleDateRangeNavigation())}
                                           disabled ={selectedView !== Views.AGENDA}>
                                </PrimeDateInput>
                            </>: null}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }

}

function mapStateToProps(state) {
    return {
        schedules: state.base.schedules
    };
}

export default connect(mapStateToProps)(LeaveCalendar);
