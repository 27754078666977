import React, {Component} from "react";

const DIALOG_CONTAINER_ID = "dialog-container";

export default class DialogContainer extends Component {

    componentDidMount() {
        const dialogContainer = document.getElementById(DIALOG_CONTAINER_ID);

        this.observer = new MutationObserver(this.callback);

        this.observer.observe(dialogContainer, {childList: true});
    }

    componentWillUnmount() {
        this.observer.disconnect();
    }

    callback = (mutationsList) => {
        for (const mutation of mutationsList) {
            if (mutation.type === "childList") {
                const dialogContainer = document.getElementById(DIALOG_CONTAINER_ID)
                if (dialogContainer.childElementCount > 0) {
                    document.body.style.overflow = "hidden";
                } else {
                    document.body.style.overflow = "auto";
                }
            }
        }
    }

    render() {
        return <div id={DIALOG_CONTAINER_ID}/>
    }

}