export default class RequiredPhoneValidator {

    getErrorMessage(prop) {
        return "Field cannot be left blank";
    }

    validate(value, prop) {
        if (value == null || prop == null) {
            return false;
        }

        return value.length > prop;
    }

}