import React, {Component} from "react";
import '../EMR.css';
import GreenSwitchWithEmptyMessage from "../../../../components/switch/GreenSwitchWithEmptyMessage";
import GradientSlider10Mark3Category from "../../../../components/slider/GradientSlider10Mark3Category";
import CheckboxInput from "../../../../components/checkbox-input/CheckboxInput";
import RedAsterisk from "../../../../components/asterisk/RedAsterisk";
import DiagnosisUtil from "./DiagnosisUtil";
import TextareaInput from "../../../../components/textarea-input/TextareaInput";

export default class Risk extends Component {

    constructor(props) {
        super(props);
        props.diagnosis.risk = props.diagnosis.risk || {none: false, medical: 0, surgical: 0, activity: 0, social: 0};
    }

    render() {
        return (
            <div className="py-3">
                <div className="d-flex">
                    <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Risk <RedAsterisk renderForAllTypes/></div>
                    <GreenSwitchWithEmptyMessage
                        message={(this.props.diagnosis.risk && this.props.diagnosis.risk.none) ? "No Risk" : ""}
                        checked={DiagnosisUtil.riskEntered(this.props.diagnosis.risk)}
                        onChange={this.riskSwitchChanged.bind(this)}/>
                </div>
                <div>Patient clinical risk including presence of co-morbidities</div>
                {
                    !this.props.diagnosis.risk.none &&
                    <>

                        <div className="d-flex align-items-center">
                            <div>
                                <CheckboxInput value={this.props.diagnosis.risk.none}
                                               onChange={this.noRiskChanged.bind(this)}/>
                            </div>
                            <div className="ml-1">No Risk</div>
                        </div>
                        <br/>
                        <div>
                            <div className={"row no-margin"}>
                                <div className={"col"} style={{paddingRight: "40px"}}>
                                    <div>Medical Risk</div>
                                    <br/>
                                    <GradientSlider10Mark3Category
                                        value={this.props.diagnosis.risk.medical}
                                        onChange={(e, val) => {
                                            this.props.diagnosis.risk.medical = val;
                                            this.forceUpdate();
                                        }}
                                    />
                                </div>
                                <div className={"col"} style={{paddingLeft: "40px"}}>
                                    <div>Surgical Risk</div>
                                    <br/>
                                    <GradientSlider10Mark3Category
                                        value={this.props.diagnosis.risk.surgical}
                                        onChange={(e, val) => {
                                            this.props.diagnosis.risk.surgical = val;
                                            this.forceUpdate();
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={"row no-margin"}>
                                <div className={"col"} style={{paddingRight: "40px"}}>
                                    <div>Activity Risk</div>
                                    <br/>
                                    <GradientSlider10Mark3Category
                                        value={this.props.diagnosis.risk.activity}
                                        onChange={(e, val) => {
                                            this.props.diagnosis.risk.activity = val;
                                            this.forceUpdate();
                                        }}
                                    />
                                </div>
                                <div className={"col"} style={{paddingLeft: "40px"}}>
                                    <div>Social Risk</div>
                                    <br/>
                                    <GradientSlider10Mark3Category
                                        value={this.props.diagnosis.risk.social}
                                        onChange={(e, val) => {
                                            this.props.diagnosis.risk.social = val;
                                            this.forceUpdate();
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={"row no-margin"}>
                                <TextareaInput
                                    className="w-100"
                                    value={this.props.diagnosis.risk.details || ''}
                                    disabled={!DiagnosisUtil.riskEntered(this.props.diagnosis.risk)}
                                    onChange={value => {
                                        this.props.diagnosis.risk.details = value;
                                        this.forceUpdate();
                                    }}
                                    maxLength={4000}
                                    style={{minHeight: '3rem'}}
                                />
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    }

    noRiskChanged(checked) {
        this.clearRisk(this.props.diagnosis.risk);
        this.props.diagnosis.risk.none = checked;
        this.forceUpdate();
    }

    riskSwitchChanged(event) {
        if (!event.target.checked) {
            this.clearRisk(this.props.diagnosis.risk);
            this.forceUpdate();
        }
    }

    clearRisk(risk) {
        risk.none = false;
        risk.medical = 0;
        risk.surgical = 0;
        risk.activity = 0;
        risk.social = 0
        risk.details = null;
    }
}
