import Enum from "../../../../../../enums/Enum";

const LocationEnum = new Enum({
    Right: {name: "Right"},
    Left: {name: "Left"},
    Bilateral: {name: "Bilateral"},
});

export default LocationEnum;



