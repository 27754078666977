import {MultipleHeaderAccordion} from "../../../../../../components/accordion/MultipleHeaderAccordion";
import React from "react";
import ObservationViewHistory from "./ObservationViewHistory";
import PalpationViewHistory from "./PalpationViewHistory";
import PostureViewHistory from "./PostureViewHistory";
import GaitAnalysisViewHistory from "./GaitAnalysisViewHistory";
import NeurologicGaitViewHistory from "./NeurologicGaitViewHistory";
import FallRiskViewHistory from "./FallRiskViewHistory";
import RangeOfMotionViewHistory from "./RangeOfMotionViewHistory";
import JointIntegrityViewHistory from "./JointIntegrityViewHistory";
import SpecialTestsViewHistory from "./SpecialTestsViewHistory";
import FunctionalTestingViewHistory from "./FunctionalTestingViewHistory";
import DermatomesViewHistory from "./DermatomesViewHistory";
import MyotomesViewHistory from "./MyotomesViewHistory";
import ReflexesViewHistory from "./ReflexesViewHistory";
import NeurodynamicsViewHistory from "./NeurodynamicsViewHistory";
import MoodViewHistory from "./MoodViewHistory";
import RespiratoryPatternViewHistory from "./RespiratoryPatternViewHistory";
import MSKDiagnosisViewHistory from "./MSKDiagnosisViewHistory";
import RiskViewHistory from "./RiskViewHistory";
import MuscleStrengthViewHistory from "./MuscleStrengthViewHistory";
import GrayPageTitle from "../../GrayPageTitle";

export class AssessmentHistory extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    render = () => {
        const visit = this.props.visit;

        return <>
            <MultipleHeaderAccordion
                headerText={{firstHeaderText: "Assessment"}}
                headerExist={{secondHeader: false, thirdHeader: false}}
                defaultExpanded={false}>
                {visit ?
                    <>
                        <div className="col-12">
                            <div className="row">
                                <div className="col-6">
                                    <GrayPageTitle title="Observation"/>
                                    <ObservationViewHistory observation={visit.assessment.observation}/>
                                </div>

                                <div className="col-6">
                                    <GrayPageTitle title="Palpation"/>
                                    <PalpationViewHistory palpation={visit.assessment.palpation}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <GrayPageTitle title="Posture"/>
                                </div>
                                <div className="col-6">
                                    <GrayPageTitle title="Gait Analysis"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <PostureViewHistory posture={visit.assessment.posture}/>
                                </div>

                                <div className="col-6">
                                    <GaitAnalysisViewHistory gaitAnalysis={visit.assessment.gaitAnalysis}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <GrayPageTitle title="Neurologic Gait"/>
                                </div>
                                <div className="col-6">
                                    <NeurologicGaitViewHistory neurologicGait={visit.assessment.neurologicGait}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <GrayPageTitle title="Fall Risk Assessment"/>
                                </div>

                                <div className="col-6">
                                    <div className="pl-3">
                                        <FallRiskViewHistory
                                            fallRisk={visit.assessment.fallRisk}
                                            showMainAssessment={true}
                                            showFurtherAssessment={false}/>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <div className="pl-3">
                                        <FallRiskViewHistory
                                            fallRisk={visit.assessment.fallRisk}
                                            showMainAssessment={false}
                                            showFurtherAssessment={true}/>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <GrayPageTitle title="Range of Motion"/>
                                    <RangeOfMotionViewHistory rangeOfMotion={visit.assessment.rangeOfMotion}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <GrayPageTitle title="Joint Integrity"/>
                                    <JointIntegrityViewHistory jointIntegrity={visit.assessment.jointIntegrity}/>
                                </div>

                                <div className="col-6">
                                    <GrayPageTitle title="Muscle Strength"/>
                                    <MuscleStrengthViewHistory
                                        muscleGroup={visit.assessment.muscleGroup}
                                        individualMuscle={visit.assessment.individualMuscle}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <GrayPageTitle title="Special Tests"/>
                                    <SpecialTestsViewHistory specialTests={visit.assessment.specialTests}/>
                                </div>

                                <div className="col-6">
                                    <GrayPageTitle title="Functional Testing"/>
                                    <FunctionalTestingViewHistory
                                        functionalTesting={visit.assessment.functionalTesting}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <GrayPageTitle title="Dermatomes"/>
                                    <DermatomesViewHistory dermatomes={visit.assessment.dermatomes}/>
                                </div>

                                <div className="col-6">
                                    <GrayPageTitle title="Myotomes"/>
                                    <MyotomesViewHistory myotomes={visit.assessment.myotomes}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <GrayPageTitle title="Reflexes"/>
                                    <ReflexesViewHistory
                                        reflexes={visit.assessment.reflexes}
                                        reflexesExtra={visit.assessment.reflexesExtra}/>
                                </div>

                                <div className="col-6">
                                    <GrayPageTitle title="Neurodynamics"/>
                                    <NeurodynamicsViewHistory neurodynamics={visit.assessment.neurodynamics}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <GrayPageTitle title="Mood and Well-Being of the Patient"/>
                                    <MoodViewHistory mood={visit.assessment.mood}/>
                                </div>

                                <div className="col-6">
                                    <GrayPageTitle title="Respiratory Pattern"/>
                                    <RespiratoryPatternViewHistory
                                        respiratoryPattern={visit.assessment.respiratoryPattern}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <GrayPageTitle title="MSK Diagnostic Ultrasound"/>
                                    <MSKDiagnosisViewHistory mskDiagnosis={visit.assessment.mskDiagnosis}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <GrayPageTitle title="Risk"/>
                                    <RiskViewHistory risk={visit.diagnosis.risk}/>
                                </div>
                            </div>
                        </div>
                    </> : <div className="font-italic">No visit selected!</div>
                }
            </MultipleHeaderAccordion>
        </>
    }
}
