import React, {Component} from 'react';
import {withRouter} from "react-router";
import {Button, Image} from 'react-bootstrap';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Badge, NavLink, SelectInput} from "../../../components";
import DateUtil from "../../../utils/DateUtil";
import AppointmentUtil from "../../../utils/AppointmentUtil";
import StringUtil from "../../../utils/StringUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EnumGender from "../../../enums/EnumGender";
import EnumAppointmentType from "../enums/EnumAppointmentType";
import EnumAppointmentStatus from "../enums/EnumAppointmentStatus";
import PatientUtil from "../../../utils/PatientUtil";
import "./AppointmentTableView.css";
import InsuranceIcon from "../../insurance/InsuranceIcon";

class AppointmentTableView extends Component {

    state = {};

    patientNameTemplate(rowData, column) {
        let photoSrc = process.env.PUBLIC_URL + '/assets/patient_profile.png';
        if (rowData.patient.thumbnail) {
            photoSrc = 'data:image/jpeg;base64,' + rowData.patient.thumbnail;
        }
        return (<div className="d-flex flex-row align-items-center">
            <Image src={photoSrc} rounded width={"40px"} style={{paddingRight: 5}}/>
            <NavLink to={'/patientdetail/' + rowData.patient.id}>{rowData.patientName}</NavLink>
        </div>);
    }

    staffNameTemplate(rowData, column) {
        return (<NavLink to={'/staffprofile/' + rowData.staff.id}>{rowData.staff.name}</NavLink>);
    }

    genderTemplate(rowData, column) {
        return <>{EnumGender[rowData.patient.gender]}</>;
    }

    patientTypeTemplate(rowData, column) {
        let color = "3B86FF";
        if (rowData.patientType === "NEW_PATIENT") {
            color = "#3B86FF";
        } else if (rowData.patientType === "FOLLOW_UP") {
            color = "0BC304";
        }
        return <>
            <FontAwesomeIcon icon={["fas", "circle"]} size={"2x"}
                             style={{color: color, maxHeight:"20px", maxWidth:"20px"}}/>
            {" " + EnumAppointmentType[rowData.patientType].name}</>;
    }

    insuranceStatusTemplate(rowData) {
        return <InsuranceIcon insuranceExists={rowData.insuranceExists} isCustomInsurance={rowData.isCustomInsurance}/>
    }

    checkInStatusTemplate(rowData, column) {
        if (rowData.status === "CONFIRMED") {
            return (
                <div style={{textAlign: 'center', cursor: 'pointer', display: 'block'}} onClick={() => {
                    this.props.handleCheckInOnTable(rowData.id)
                }}>
                    <Badge className="TableColBadge" style={{backgroundColor: "#358ab3", color: "white", paddingTop: 6, paddingBottom: 11}}>
                        <FontAwesomeIcon icon={["fas", "calendar-check"]} style={{color: "white"}} size={"2x"}/>
                        <span style={{fontSize: "14px", verticalAlign: "middle", marginTop:"-5px",
                            display: "inline-block", marginLeft:"5px"}}>
                            {" Check-In"}
                        </span>
                    </Badge>
                </div>
            );
        } else if (rowData.status === "BOOKED") {
            return (
                <div style={{textAlign: 'center', cursor: 'pointer', display: 'block'}} onClick={() => {
                    this.props.handleConfirmOnTable(rowData.id)
                }}>
                    <Badge className="TableColBadge" style={{backgroundColor: "#e5a223", color: "white", paddingTop: 6, paddingBottom: 11}}>
                        <FontAwesomeIcon icon={["fas", "check"]} style={{color: "white"}} size={"2x"}/>
                        <span style={{fontSize: "14px", verticalAlign: "middle",  marginTop:"-5px",
                            display: "inline-block", marginLeft:"5px"}}>
                            {" Confirm"}
                        </span>
                    </Badge>
                </div>
            );
        } else {
            return "-"
        }
    }

    statusTemplate(rowData, column) {
        let rowColor = EnumAppointmentStatus[rowData.status];
        return <Badge className="TableColBadge" style={{
            paddingTop: 11,
            paddingBottom: 12,
            backgroundColor: rowColor.bgColor,
            color: rowColor.textColor,
            fontSize: 15
        }}>{EnumAppointmentStatus[rowData.status].name}</Badge>;
    }

    onGenderFilterChange(val) {
        this.appointmentsDt.filter(val, 'patient.gender', 'equals');
        this.setState({genderFilterVal: val});
    }

    onAppointmentTypeFilterChange(val) {
        this.appointmentsDt.filter(val, 'patientType', 'equals');
        this.setState({appointmentTypeFilterVal: val});
    }

    onInsuranceStatusFilterChange(val) {
        this.appointmentsDt.filter(val, 'insuranceExists', 'equals');
        this.setState({insuranceStatusFilterVal: val});
    }

    getAppointmentDetailLink(appointment) {
        return "/patientdetail/" + appointment.patient.id + "/" + appointment.id + "/" +
            this.props.selectedView + "/" + DateUtil.formatDate(this.props.selectedDate);
    }

    actionTemplate(rowData, column) {
        return <Button className="w-100" onClick={() => {
            this.props.history.push(this.getAppointmentDetailLink(rowData));
        }}>Edit</Button>;
    }

    appointmentLinkTemplate = (rowData, column) => {
        return (<NavLink to={this.getAppointmentDetailLink(rowData)}>{rowData.id}</NavLink>);
    }

    render() {

        const dayOfState = this.props.selectedDate.getDate();
        const monthOfState = this.props.selectedDate.getMonth();
        const yearOfState = this.props.selectedDate.getFullYear();
        const filteredListAppointments = this.props.mappedAppointments.filter((appointment) => {
            const startEqual = appointment.start.getDate() === dayOfState && appointment.start.getMonth() === monthOfState && appointment.start.getFullYear() === yearOfState;
            const endEqual = appointment.end.getDate() === dayOfState && appointment.end.getMonth() === monthOfState && appointment.end.getFullYear() === yearOfState;
            return startEqual || endEqual;
        });

        let filteredAndFilterMappedListAppointments = filteredListAppointments.map((appointment) => {
            const mappedAppointment = {...appointment};
            mappedAppointment.age = appointment.patient.birthday ? DateUtil.getAge(appointment.patient.birthday) : '-';
            const startTime = DateUtil.formatDate(appointment.start, "hh:mm a");
            const endTime = DateUtil.formatDate(appointment.end, "hh:mm a");
            mappedAppointment.timeSlot = startTime + " - " + endTime;
            mappedAppointment.patientName = PatientUtil.getPatientsName(mappedAppointment.patient);
            return mappedAppointment;
        });

        let genderFilter = <SelectInput className="w-100" value={this.state.genderFilterVal} options={EnumGender.toArray()}
                                        displayProperty="name" valueProperty="key" searchEnabled={false}
                                        onChange={(val) => this.onGenderFilterChange(val)}/>;

        let appointmentTypeFilter = <SelectInput className="w-100" value={this.state.appointmentTypeFilterVal}
                                                 options={EnumAppointmentType.selectableTypes}
                                                 displayProperty="name" valueProperty="key" searchEnabled={false}
                                                 onChange={(val) => this.onAppointmentTypeFilterChange(val)}/>;

        let insuranceStatusFilter = <SelectInput className="w-100" value={this.state.insuranceStatusFilterVal}
                                                 options={[{key: false, name: "No"}, {key: true, name: "Yes"}]}
                                                 displayProperty="name" valueProperty="key" searchEnabled={false}
                                                 onChange={(val) => this.onInsuranceStatusFilterChange(val)}/>;

        let mrnFilterFunction = (value, filter) => {
            if (filter === "-") {
                return value == null;
            }

            value = StringUtil.pad(value, 6);

            let country = getPatientCountryByMrnNo(value);
            if (country === "UAE") {
                value = "UAE" + value;
            } else if (country === "KUWAIT") {
                value= "KWI" + value
            }

            return String(value).indexOf(filter.toUpperCase()) >= 0;
        };

        let getPatientCountryByMrnNo = (mrnNo) => {
            const patientMrnMap = filteredListAppointments.map(a => a.patient.mrn);
            return patientMrnMap.find(p => p?.mrnNo == mrnNo)?.country;
        };

        const selectedDateStr = DateUtil.formatDateDo(this.props.selectedDate);

        return (
            <div className="appointment-table-view">

                <DataTable value={filteredAndFilterMappedListAppointments} className="AppointmentTable"
                           ref={(el) => this.appointmentsDt = el}
                           globalFilter={this.state.appointmentsDataGridGlobalFilter}
                           emptyMessage={"There are no appointments for selected filters on day " + selectedDateStr}>
                    <Column field="id" header="ID" body={this.appointmentLinkTemplate} filter={true}
                            filterMatchMode="equals" sortable={true} style={{width: "35px", textAlign: 'center'}}/>
                    <Column field="start" header="TIME SLOT" body={AppointmentUtil.appointmentTimeSlot}
                            sortable={true} style={{width: "110px"}}/>
                    <Column field="patient.mrn.mrnNo" header="MRN" filter={true} filterMatchMode="custom"
                            sortable={true} style={{width: "80px", textAlign: 'center'}}
                            filterFunction={mrnFilterFunction} body={(r) => StringUtil.mrn(r.patient.mrn)}/>
                    <Column field="patientName" header="PATIENT NAME" body={this.patientNameTemplate} filter={true}
                            filterMatchMode="contains" sortable={true} style={{width: "110px"}}/>
                    <Column field="age" header="AGE" filter={true} filterMatchMode="equals" sortable={true}
                            style={{width: "40px"}}/>
                    <Column field="patient.gender" header="GENDER" body={this.genderTemplate} filter={true}
                            filterElement={genderFilter} sortable={true} style={{width: "70px"}}/>
                    <Column field="patient.mobilePhone" header="MOBILE" filter={true} filterMatchMode="contains"
                            style={{width: "90px"}}/>
                    <Column field="patientType" header="TYPE" body={this.patientTypeTemplate} filter={true}
                            filterElement={appointmentTypeFilter} sortable={true} style={{width: "100px"}}/>
                    <Column field="insuranceExists" header="INSURANCE"
                            body={this.insuranceStatusTemplate} filter={true}
                            filterElement={insuranceStatusFilter} sortable={true} style={{width: "90px"}}
                            className="appointmentTableInsurance"/>
                    <Column field="staff.name" header="PROVIDER" body={this.staffNameTemplate} filter={true}
                            filterMatchMode="contains" sortable={true} style={{width: "110px"}}/>
                    <Column field="checkInStatus" header="ACTION" body={this.checkInStatusTemplate.bind(this)}
                            style={{width: "90px"}}/>
                    <Column field="status" header="STATUS" body={this.statusTemplate} sortable={true} style={{width: "90px"}}/>
                    <Column header="EDIT" body={this.actionTemplate.bind(this)} style={{width: "60px"}}/>
                </DataTable>
            </div>
        );
    }
}

export default withRouter(AppointmentTableView);