import React from "react";
import {Table} from "react-bootstrap";
import InformedConsentFormDxbTextEnum from "../informedconsent/enum/InformedConsentFormDxbTextEnum";
import InformedConsentYesNoInput from "../informedconsent/InformedConsentYesNoInput";

const Title = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <h2>{InformedConsentFormDxbTextEnum.Informed_Consent[selectedLanguage]}</h2>
        </>
    )
}

const Introduction = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <p>{InformedConsentFormDxbTextEnum.Introduction_Paragraph_1[selectedLanguage]}</p>
            <p>{InformedConsentFormDxbTextEnum.Introduction_Paragraph_2[selectedLanguage]}</p>
        </>
    )
}

const Benefits = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <p class="font-weight-bold">{InformedConsentFormDxbTextEnum.Benefits_Title[selectedLanguage]}</p>
            <p>{InformedConsentFormDxbTextEnum.Benefits_Paragraph_1[selectedLanguage]}</p>
        </>
    )
}

const Risks = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <RisksIntro selectedLanguage={selectedLanguage}/>
            <ul>
                <RisksItem1 selectedLanguage={selectedLanguage}/>
                <RisksItem2 selectedLanguage={selectedLanguage}/>
                <RisksItem3 selectedLanguage={selectedLanguage}/>
                <RisksItem4 selectedLanguage={selectedLanguage}/>
                <RisksItem5 selectedLanguage={selectedLanguage}/>
                <RisksItem6 selectedLanguage={selectedLanguage}/>
                <RisksItem7 selectedLanguage={selectedLanguage}/>
            </ul>
        </>
    )
}

const RisksIntro = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <p className="font-weight-bold">{InformedConsentFormDxbTextEnum.Risks_Title[selectedLanguage]}</p>
            <p>{InformedConsentFormDxbTextEnum.Risks_Intro_Paragraph[selectedLanguage]}</p>
        </>
    )
}

const RisksItem1 = (props) => {
    const {selectedLanguage} = props;

    return (
        <li>
            <b>{InformedConsentFormDxbTextEnum.Risks_Item1_Title[selectedLanguage]}</b> {InformedConsentFormDxbTextEnum.Risks_Item1_Paragraph[selectedLanguage]}
        </li>
    )
}

const RisksItem2 = (props) => {
    const {selectedLanguage} = props;

    return (
        <li>
            <b>{InformedConsentFormDxbTextEnum.Risks_Item2_Title[selectedLanguage]}</b> {InformedConsentFormDxbTextEnum.Risks_Item2_Paragraph[selectedLanguage]}
        </li>
    )
}

const RisksItem3 = (props) => {
    const {selectedLanguage} = props;

    return (
        <li>
            <b>{InformedConsentFormDxbTextEnum.Risks_Item3_Title[selectedLanguage]}</b> {InformedConsentFormDxbTextEnum.Risks_Item3_Paragraph[selectedLanguage]}
        </li>
    )
}

const RisksItem4 = (props) => {
    const {selectedLanguage} = props;

    return (
        <li>
            <b>{InformedConsentFormDxbTextEnum.Risks_Item4_Title[selectedLanguage]}</b> {InformedConsentFormDxbTextEnum.Risks_Item4_Paragraph1[selectedLanguage]}<br/>
            {InformedConsentFormDxbTextEnum.Risks_Item4_Paragraph2[selectedLanguage]}<br/>
            {InformedConsentFormDxbTextEnum.Risks_Item4_Paragraph3[selectedLanguage]}
        </li>
    )
}

const RisksItem5 = (props) => {
    const {selectedLanguage} = props;

    return (
        <li>
            <b>{InformedConsentFormDxbTextEnum.Risks_Item5_Title[selectedLanguage]}</b> {InformedConsentFormDxbTextEnum.Risks_Item5_Paragraph1[selectedLanguage]}<br/>
            {InformedConsentFormDxbTextEnum.Risks_Item5_Paragraph2[selectedLanguage]}<br/>
            {InformedConsentFormDxbTextEnum.Risks_Item5_Paragraph3[selectedLanguage]}<br/>
            {InformedConsentFormDxbTextEnum.Risks_Item5_Paragraph4[selectedLanguage]}
        </li>
    )
}

const RisksItem6 = (props) => {
    const {selectedLanguage} = props;

    return (
        <li>
            <b>{InformedConsentFormDxbTextEnum.Risks_Item6_Title[selectedLanguage]}</b> {InformedConsentFormDxbTextEnum.Risks_Item6_Paragraph1[selectedLanguage]}
        </li>
    )
}

const RisksItem7 = (props) => {
    const {selectedLanguage} = props;

    return (
        <li>
            <b>{InformedConsentFormDxbTextEnum.Risks_Item7_Title[selectedLanguage]}</b> {InformedConsentFormDxbTextEnum.Risks_Item7_Paragraph1[selectedLanguage]}
        </li>
    )
}

const Alternatives = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <p className="font-weight-bold">{InformedConsentFormDxbTextEnum.Alternatives_Title[selectedLanguage]}</p>
            <p>{InformedConsentFormDxbTextEnum.Alternatives_Paragraph1[selectedLanguage]}</p>
        </>
    )
}

const InjectionAndMedication = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <p className="font-weight-bold">{InformedConsentFormDxbTextEnum.Injection_And_Medication_Title[selectedLanguage]}</p>
            <p>{InformedConsentFormDxbTextEnum.Injection_And_Medication_Paragraph1[selectedLanguage]}</p>
        </>
    )
}

const DataCollectionAndPrivacy = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <DataCollectionAndPrivacyTitle selectedLanguage={selectedLanguage}/>
            <DataCollectionAndPrivacyParagraph1 selectedLanguage={selectedLanguage}/>
            <DataCollectionAndPrivacyParagraph2 selectedLanguage={selectedLanguage}/>
            <DataCollectionAndPrivacyParagraph3 selectedLanguage={selectedLanguage}/>
            <DataCollectionAndPrivacyParagraph4 selectedLanguage={selectedLanguage}/>
            <DataCollectionAndPrivacyParagraph5 selectedLanguage={selectedLanguage}/>
            <DataCollectionAndPrivacyParagraph6 selectedLanguage={selectedLanguage}/>
        </>
    )
}

const DataCollectionAndPrivacyTitle = (props) => {
    const {selectedLanguage} = props;

    return (
        <p className="font-weight-bold">{InformedConsentFormDxbTextEnum.Data_Collection_And_Privacy_Title[selectedLanguage]}</p>
    )
}

const DataCollectionAndPrivacyParagraph1 = (props) => {
    const {selectedLanguage} = props;

    return (
        <p>{InformedConsentFormDxbTextEnum.Data_Collection_And_Privacy_Paragraph1[selectedLanguage]}</p>
    )
}

const DataCollectionAndPrivacyParagraph2 = (props) => {
    const {selectedLanguage} = props;

    return (
        <p>{InformedConsentFormDxbTextEnum.Data_Collection_And_Privacy_Paragraph2[selectedLanguage]}</p>
    )
}

const DataCollectionAndPrivacyParagraph3 = (props) => {
    const {selectedLanguage} = props;

    return (
        <p>{InformedConsentFormDxbTextEnum.Data_Collection_And_Privacy_Paragraph3[selectedLanguage]}
            <ul>
                <li>{InformedConsentFormDxbTextEnum.Data_Collection_And_Privacy_Paragraph3_ListItem1[selectedLanguage]}</li>
                <li>{InformedConsentFormDxbTextEnum.Data_Collection_And_Privacy_Paragraph3_ListItem2[selectedLanguage]}</li>
                <li>{InformedConsentFormDxbTextEnum.Data_Collection_And_Privacy_Paragraph3_ListItem3[selectedLanguage]}</li>
                <li>{InformedConsentFormDxbTextEnum.Data_Collection_And_Privacy_Paragraph3_ListItem4[selectedLanguage]}</li>
                <li>{InformedConsentFormDxbTextEnum.Data_Collection_And_Privacy_Paragraph3_ListItem5[selectedLanguage]}</li>
            </ul>
        </p>
    )
}

const DataCollectionAndPrivacyParagraph4 = (props) => {
    const {selectedLanguage} = props;

    return (
        <p>{InformedConsentFormDxbTextEnum.Data_Collection_And_Privacy_Paragraph4[selectedLanguage]}</p>
    )
}

const DataCollectionAndPrivacyParagraph5 = (props) => {
    const {selectedLanguage} = props;

    return (
        <p>{InformedConsentFormDxbTextEnum.Data_Collection_And_Privacy_Paragraph5[selectedLanguage]}</p>
    )
}

const DataCollectionAndPrivacyParagraph6 = (props) => {
    const {selectedLanguage} = props;

    return (
        <p>{InformedConsentFormDxbTextEnum.Data_Collection_And_Privacy_Paragraph6[selectedLanguage]}
            <ul>
                <li>{InformedConsentFormDxbTextEnum.Data_Collection_And_Privacy_Paragraph6_ListItem1[selectedLanguage]}</li>
                <li>{InformedConsentFormDxbTextEnum.Data_Collection_And_Privacy_Paragraph6_ListItem2[selectedLanguage]}</li>
                <li>{InformedConsentFormDxbTextEnum.Data_Collection_And_Privacy_Paragraph6_ListItem3[selectedLanguage]}</li>
                <li>{InformedConsentFormDxbTextEnum.Data_Collection_And_Privacy_Paragraph6_ListItem4[selectedLanguage]}</li>
                <li>{InformedConsentFormDxbTextEnum.Data_Collection_And_Privacy_Paragraph6_ListItem5[selectedLanguage]}</li>
            </ul>
        </p>
    )
}

const QuestionsOfConcerns = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <p className="font-weight-bold">{InformedConsentFormDxbTextEnum.Questions_Of_Concerns_Title[selectedLanguage]}</p>
            <p>{InformedConsentFormDxbTextEnum.Questions_Of_Concerns_Paragraph[selectedLanguage]}</p>
        </>
    )
}

const Complaints = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <p className="font-weight-bold">{InformedConsentFormDxbTextEnum.Complaints_Title[selectedLanguage]}</p>
            <p>{InformedConsentFormDxbTextEnum.Complaints_Paragraph1[selectedLanguage]} <a
                href="https://www.dha.gov.ae/" target="_blank">(https://www.dha.gov.ae/)</a></p>
        </>
    )
}

const ConsentTable = (props) => {
    const {selectedLanguage, formFields, readOnly, traineeConsent, onChange, enumLabel,pushFormFieldRef} = props;

    return (
        <>
            <Table bordered hover size="sm">
                <tbody>
                <tr>
                    <td>{InformedConsentFormDxbTextEnum.Consent_Table_Text[selectedLanguage]}</td>
                    <td>
                        <InformedConsentYesNoInput formFields={formFields}
                                                   readOnly={readOnly}
                                                   traineeConsent={traineeConsent}
                                                   onChange={onChange}
                                                   enumLabel={enumLabel}
                                                   pushFormFieldRef={pushFormFieldRef}
                        />
                    </td>
                </tr>
                </tbody>
            </Table>
        </>
    )
}

const Conclusion = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <p className="font-weight-bold">{InformedConsentFormDxbTextEnum.Conclusion_Paragraph1[selectedLanguage]}</p>
            <p className="font-weight-bold">{InformedConsentFormDxbTextEnum.Conclusion_Paragraph2[selectedLanguage]}</p>
            <p className="font-weight-bold">{InformedConsentFormDxbTextEnum.Conclusion_Paragraph3[selectedLanguage]}</p>
        </>
    )
}

export default {
    Title,
    Introduction,
    Benefits,
    Risks,
    RisksIntro,
    RisksItem1,
    RisksItem2,
    RisksItem3,
    RisksItem4,
    RisksItem5,
    RisksItem6,
    RisksItem7,
    Alternatives,
    InjectionAndMedication,
    DataCollectionAndPrivacy,
    DataCollectionAndPrivacyTitle,
    DataCollectionAndPrivacyParagraph1,
    DataCollectionAndPrivacyParagraph2,
    DataCollectionAndPrivacyParagraph3,
    DataCollectionAndPrivacyParagraph4,
    DataCollectionAndPrivacyParagraph5,
    DataCollectionAndPrivacyParagraph6,
    QuestionsOfConcerns,
    Complaints,
    ConsentTable,
    Conclusion
};
