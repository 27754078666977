import React, {Component} from 'react';
import DiagnosisUtil from "../../diagnosis/DiagnosisUtil";

class RiskEmrDashboardView extends Component {
    render() {

        if (!DiagnosisUtil.riskEntered(this.props.risk) || this.props.risk.none)
            return null;

        return (
            <div>
                {this.props.risk.medical != null && <div className={"content-row"}>
                    <div className={"col-2 font-weight-bold"}>Medical Risk</div>
                    <div className={"col-1 font-weight-bold"}>:</div>
                    <div className={"col-9"}>{this.props.risk.medical + "/10"}</div>
                </div>
                }
                {this.props.risk.surgical != null && <div className={"content-row"}>
                    <div className={"col-2 font-weight-bold"}>Surgical Risk</div>
                    <div className={"col-1 font-weight-bold"}>:</div>
                    <div className={"col-9"}>{this.props.risk.surgical + "/10"}</div>
                </div>
                }
                {this.props.risk.activity != null && <div className={"content-row"}>
                    <div className={"col-2 font-weight-bold"}>Activity Risk</div>
                    <div className={"col-1 font-weight-bold"}>:</div>
                    <div className={"col-9"}>{this.props.risk.activity + "/10"}</div>
                </div>
                }
                {this.props.risk.social != null && <div className={"content-row"}>
                    <div className={"col-2 font-weight-bold"}>Social Risk</div>
                    <div className={"col-1 font-weight-bold"}>:</div>
                    <div className={"col-9"}>{this.props.risk.social + "/10"}</div>
                </div>
                }
                {this.props.risk.details != null && <div className={"content-row"}>
                    <div className={"col"}>{this.props.risk.details}</div>
                </div>
                }
            </div>
        );
    }
}

export default RiskEmrDashboardView;