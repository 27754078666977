import React, {Component} from "react";
import "../../VisitHistory.css"
import MoodEnum from "../../../assessment/enums/mood/MoodEnum";

export default class MoodViewHistory extends Component {

    render() {
        if (this.props.mood) {
            return (
                <div className="pl-3">
                    {this.props.mood.type == null ? ""
                        :
                        (MoodEnum[this.props.mood?.type]?.name + (this.props.mood.detail ? ": " : ""))}{this.props.mood.detail}
                </div>
            );
        } else {
            return <div className="pl-3">No information available</div>;
        }
    }
}