export const creditMarks = (country) => {
    return country === 'UAE' ?
        [
            {value: 1000, label: '1,000AED', rate:'(1%)'},
            {value: 3000, label: '3,000AED', rate:' (8%)'},
            {value: 5000, label: '5,000AED ', rate:' (11%)'},
            {value: 7000, label: '7,000AED ', rate:' (14%)'},
            {value: 10000, label: '10,000AED ', rate:' (16%)'}
        ] :
        country === 'KUWAIT' ?
            [
                {value: 100, label: '100KWD', rate:'(1%)'},
                {value: 300, label: '300KWD', rate:'(8%)'},
                {value: 500, label: '500KWD', rate:'(11%)'},
                {value: 700, label: '700KWD', rate:'(15%)'},
                {value: 800, label: '800KWD', rate:'(15%)'}
            ]
            :
            []
};

export const getCreditInfo = (value, country) => {
    let amount = value;
    let period = 8;
    let rate = 0;

    if (country === 'UAE') {
        if (value > 1000 && value <= 8000) {
            period = 8 + 3 * (value - 1000) / 1000;
        }

        if (value > 8000) {
            period = 29 + 2 * (value - 8000) / 1000;
        }

        rate = value <= 999 ? 0 : (-44.4123 + 6.6226 * Math.log(value));

    } else if (country === 'KUWAIT') {
        if (value > 100 && value <= 400) {
            period = 8 + 3 * (value - 100) / 100;
        }

        if (value > 400) {
            period = 29 + 2 * (value - 400) / 100;
        }

        rate = value <= 99 ? 0 : (-30 + 6.75 * Math.log(value));
    }

    return {creditAmount: amount, creditDiscountRate: Math.floor(rate), creditValidityPeriod: Math.floor(period)};
};

export default {creditMarks, getCreditInfo};