import React, {Component} from 'react';
import {Accordion} from 'react-accessible-accordion';
import './AppointmentFilter.css';
import {AccordionBody, AccordionHeader, AccordionPanel, List} from "../../components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Views} from "react-big-calendar";
import {AutoComplete} from "primereact/autocomplete";

//https://springload.github.io/react-accessible-accordion/
export default class AppointmentFilter extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [
                {header: 'Room'},
                {header: 'Speciality'},
                {header: 'Provider'}
            ],
            expandedFilterPanel: 'Provider',
            patients: []
        }
    }

    handleFilterChange = (header, itemList) => {
        this.props.onFilterChange(header, itemList);
    };

    suggestPatients = (event) => {
        this.setState({
            patientSuggestions: this.props.selectedPatients.filter(
                patient => patient.toLowerCase().startsWith(event.query.toLowerCase()))
        });
    }

    handleExpand = (panel) => (event, newExpanded) => {
        this.setState({expandedFilterPanel: newExpanded ? panel : false});
    };

    expanded = (index) => {
        return this.state.expandedFilterPanel === this.state.items[index].header;
    }

    chevronDown = () => {
        return <FontAwesomeIcon icon={["fas", "chevron-down"]}/>;
    }

    render() {
        const roomHeader = this.state.items[0].header;
        const specialityHeader = this.state.items[1].header;
        const staffHeader = this.state.items[2].header;
        const {rooms, specialities, staffs, additionalItemLabel, additionalItemOnClick, additionalItemChecked} = this.props;

        return (
            <div style={{marginLeft: '10px'}}>
                <AutoComplete value={this.state.selectedPatient} placeholder="Patient Search ..."
                                    onChange={(event) => {
                                        this.setState({selectedPatient: event.value});
                                        this.handleFilterChange('Patient', event.value);
                                    }}
                                    suggestions={this.state.patientSuggestions}
                                    completeMethod={this.suggestPatients}
                                    className="PatientSearchAutocomplete"/>
                <Accordion style={{marginTop: '10px'}} preExpanded={["Provider"]}>
                    <div className="AppointmentFilter">
                        <AccordionPanel square expanded={this.expanded(0)}
                                        onChange={this.handleExpand(roomHeader)}>
                            <AccordionHeader expandIcon={this.chevronDown()}>
                                {roomHeader}
                            </AccordionHeader>
                            <AccordionBody>
                                <List data={rooms.map(item => item.info)}
                                      selectable={true} selected={this.props.selectedRooms}
                                      onChange={(value) => this.handleFilterChange(roomHeader, value)}/>
                            </AccordionBody>
                        </AccordionPanel>
                        <AccordionPanel square expanded={this.expanded(1)}
                                        onChange={this.handleExpand(specialityHeader)}>
                            <AccordionHeader expandIcon={this.chevronDown()}>
                                {specialityHeader}
                            </AccordionHeader>
                            <AccordionBody>
                                <List data={specialities.map(item => item.name)}
                                      selectable={true} selected={this.props.selectedSpecialities}
                                      onChange={(value) => this.handleFilterChange(specialityHeader, value)}/>
                            </AccordionBody>
                        </AccordionPanel>
                        <AccordionPanel square expanded={this.expanded(2)}
                                        onChange={this.handleExpand(staffHeader)}>
                            <AccordionHeader expandIcon={this.chevronDown()}>
                                {staffHeader}
                            </AccordionHeader>
                            <AccordionBody>
                                <List data={staffs.map(item => item.name)}
                                      singleSelection={this.props.view === Views.WEEK || this.props.view === Views.MONTH}
                                      selectAll={this.props.view === Views.DAY || this.props.view === Views.AGENDA}
                                      selectable={true} selected={this.props.selectedStaffs}
                                      onChange={(value) => this.handleFilterChange(staffHeader, value)}
                                      additionalItemLabel={additionalItemLabel}
                                      additionalItemOnClick={additionalItemOnClick}
                                      additionalItemChecked={additionalItemChecked}
                                />
                            </AccordionBody>
                        </AccordionPanel>
                    </div>
                </Accordion>
            </div>
        )
    }
}
