import Enum from "../../../../../../enums/Enum";

const RedFlagEnum = new Enum({
    FEVERCHILLNIGHTSWEATS: {name: "Fever, Chill, Night Sweats"},
    NIGHTPAIN: {name: "Night Pain"},
    UNEXPLAINEDWEIGHTLOSS: {name: "Unexplained Weight Loss"},
    GENERALMALAISE: {name: "General Malaise"},
    DIZZINESS: {name: "Dizziness"},
    UNEXPLAINEDFALLSDECREASEDBALANCE: {name: "Unexplained Falls/Decreased Balance"},
    NEUROLOGICALSYMPTOMS: {name: "Neurological Symptoms"},
    JAWCLAUDICATION: {name: "Jaw Claudication"},
    DYSPHAGIA: {name: "Dysphagia"},
    DYSARTHRIA: {name: "Dysarthria"},
    BLADDERBOWELSYMPTOMS: {name: "Bladder/Bowel Symptoms"},
    LOSSOFCONSCIOUSNESS: {name: "Loss of Consciousness"},
    CYANOSIS: {name: "Cyanosis"},
    NYSTAGMUS: {name: "Nystagmus"},
    DIPLOPIA: {name: "Diplopia"},
    OTHER: {name: "Other"}
});

export default RedFlagEnum;