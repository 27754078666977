import React, {Component} from "react";
import '../../EMR.css';
import GreenSwitch from "../../../../../components/switch/GreenSwitch";
import BorderedRadioEnumInput from "../../../../../components/radio-input/BorderedRadioEnumInput";
import MoodEnum from "../enums/mood/MoodEnum";
import TextInput from "../../../../../components/text-input/TextInput";
import StringUtil from "../../../../../utils/StringUtil";

export default class MoodModal extends Component {

    render() {
        return (
            <div className={"emr-modal content-row emr-background"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px"}}>
                    <div>
                        <div style={{display: "flex"}}>
                            <div className="content-label" style={{margin:"auto", marginLeft:"0px"}}>Mood and Well-being of the Patient</div>
                            <GreenSwitch
                                checked={this.props.assessment.mood != null
                                            && (this.props.assessment.mood.type != null ||
                                                    !StringUtil.isNullOrEmpty(this.props.assessment.mood.detail))}
                                onChange={() => {
                                    this.props.assessment.mood = null;
                                    this.forceUpdate();
                                }}/>
                        </div>
                    </div>
                    <br/>
                    <div className="font-weight-bold">Describe the Patient:</div>
                    <br/>
                    <div>
                        <BorderedRadioEnumInput
                            values={MoodEnum.toArray()}
                            selected={this.props.assessment.mood != null ? this.props.assessment.mood.type : null}
                            onSelect={(value) => {
                                this.createEntityIfNecessary();
                                this.props.assessment.mood.type = value;
                                this.forceUpdate();
                            }}
                        />
                    </div>
                    <br/>
                    <TextInput value={this.props.assessment.mood == null ? '' :
                                        this.props.assessment.mood.detail || ''}
                               placeholder={"Details"}
                               style={{width: '-webkit-fill-available'}}
                               onChange={value =>  {
                                   this.createEntityIfNecessary();
                                   this.props.assessment.mood.detail = value;
                                   this.forceUpdate();
                               }}
                    />
                </div>
            </div>
        );
    }

    createEntityIfNecessary() {
        if(this.props.assessment.mood == null) {
            this.props.assessment.mood = {};
        }
    }
}
