import React, {Component} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import DateUtil from "../../utils/DateUtil";

export default class LeaveCommentTable extends Component {

    render() {
        const {comments} = this.props;
        return (
            <DataTable value={comments}>
                <Column field="commenterName" header="User" style={{width: 150, marginBottom: 10}}/>
                <Column field="logDate" header="Log Date" body={this.logDateTemplate} style={{width: 180}}/>
                <Column field="comment" header="Comment"/>
            </DataTable>
        )
    }

    logDateTemplate(rowData) {
        if (rowData.logDate) {
            return DateUtil.formatDateTimeSeparately(rowData.logDate);
        }
        return "";
    }

}