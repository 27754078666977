import React, {Component} from "react";
import "../../VisitHistory.css"

export default class ReviewOfSystemViewHistory extends Component {

    dataFields = [
        {
            title: "Cardiovascular / Circulatory",
            field: "cardiovascularCirculatory"
        },{
            title: "Respiratory",
            field: "respiratory"
        },{
            title: "Head, Neck, Eyes",
            field: "headNeckEyes"
        },{
            title: "ENT",
            field: "ent"
        },{
            title: "Skin, Hair, Nails",
            field: "skinHairNails"
        },{
            title: "Lymphatic / Immune",
            field: "lymphaticImmune"
        },{
            title: "Endocrine",
            field: "endocrine"
        },{
            title: "Gastrointestinal",
            field: "gastrointestinal"
        },{
            title: "Genitourinary",
            field: "genitourinary"
        },{
            title: "Musculoskeletal",
            field: "musculoskeletal"
        },{
            title: "Neurological",
            field: "neurological"
        },
    ];

    render() {
        if(this.props.reviewOfSystem == null) {
            return <div className={this.props.reportView ? "" : "pl-3"}>No information available</div>;
        }

        if(this.props.reviewOfSystem?.none) {
            return <div className={this.props.reportView ? "" : "pl-3"}>All Normal</div>;
        }

        let contents = this.getContents();

        return(
            <div className={"visit-history-multiple-content-wrapper"}>
                {contents}
            </div>
        );
    }

    getContents() {
        let contents = [];
        this.dataFields.forEach((data, index) => {
            if (this.props.reviewOfSystem && this.props.reviewOfSystem[data.field].length > 0) {
                contents.push(
                    <div key={"reviewOfSystemViewHistory" + index}>
                        <div className={"display-flex"} style={{margin: "3px"}}>
                            <div className={"col-sm-3 no-padding dim14-form-label"}>{data.title}</div>
                            <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>{`: ${this.props.reviewOfSystem[data.field].join(", ")}`}</div>
                        </div>
                    </div>
                );
            }
        });
        return contents;
    }
}