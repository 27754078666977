import React, {Component} from 'react'
import Logo from "./Logo";
import DateUtil from "../../../utils/DateUtil";
import confirmDialog from "../../../components/dialog/ConfirmDialog";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import RemotingService from "../../../services/remoting-service/RemotingService";
import {TextInput, Wizard} from "../../../components";
import FormField from "../../../components/form/FormField";
import SignatureInput from "../../../components/signature-input/SignatureInput";
import CancellationPolicyPartsDxb from "../cancellationpolicy/CancellationPolicyPartsDxb";
import CancellationPolicyDxbTextEnum from "../cancellationpolicy/enum/CancellationPolicyDxbTextEnum";
import FormLanguages from "../enum/FormLanguages";

export default class PublicCancellationPolicyDxb extends Component {

    constructor(props) {
        super(props);

        this.state = {
            patientSignature: null,
        }
    }

    createHeader = (form, selectedLanguage) => {
        return <div style={{padding: "0px 20px 0px 20px"}}>
            <div className="row text-left">
                <div className="col-12 no-padding">
                    <Logo/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center no-padding" style={{fontSize: 20, fontWeight: "bold"}}>
                    {CancellationPolicyDxbTextEnum.Cancellation_Policy_Title[selectedLanguage]}
                </div>
            </div>
            <div className="row" style={{fontSize: 11}}>
                <div className="col-12 text-right no-padding">
                    <b>{CancellationPolicyDxbTextEnum.MRN[selectedLanguage]}: </b> {form.patientMrn}
                </div>
            </div>
        </div>
    };

    fieldChanged = (fieldName, value) => {
        this.props.form[fieldName] = value;
        this.forceUpdate();
    };


    close = () => {
        confirmDialog(
            'Confirmation',
            'Are you sure you want to close this form? All input will be lost.',
            this.props.close);
    };

    submitForm = () => {
        if (ValidationUtil.checkWithNotification(this.formFields)) {
            const {consentFormCode: code, form} = this.props;
            RemotingService.postRemoteCall(`api/public/consent-form/code/${code}/cancellation-policy-dxb`, form);
            this.props.onFormSubmit(form);
        }
    };

    render() {
        this.formFields = [];
        const {form} = this.props;
        const selectedLanguage = this.props.language;
        const formBodyClass = selectedLanguage == FormLanguages.ARABIC.name ? "arabic-form" : "";

        return (
            <Wizard
                show={true} fullScreen
                footerStyle={{backgroundColor: '#f5f5f5'}}
                buttonStyle={{backgroundColor: '#84BE56', border: 'none'}}
                header={this.createHeader(form, selectedLanguage)}
                close={this.close}
                onSubmit={this.submitForm}
                previousBtnText={CancellationPolicyDxbTextEnum.Wizard_Previous[selectedLanguage]}
                nextBtnText={CancellationPolicyDxbTextEnum.Wizard_Next[selectedLanguage]}
                saveBtnText={CancellationPolicyDxbTextEnum.Wizard_Save[selectedLanguage]}>

                <Wizard.Page>
                    <div className={formBodyClass} style={{margin: "20px 20px 20px 20px"}}>

                        <CancellationPolicyPartsDxb.Part1 selectedLanguage={selectedLanguage}/>

                        <div style={{backgroundColor: "white"}}>
                            <CancellationPolicyPartsDxb.Part2 selectedLanguage={selectedLanguage}/>
                        </div>

                        <CancellationPolicyPartsDxb.Part3 selectedLanguage={selectedLanguage}
                                                                               patientName={form.patientFullName}/>


                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 dim14-form-label">
                                            {CancellationPolicyDxbTextEnum.Date[selectedLanguage]}
                                        </div>
                                        <div className="col-md-6 dim14-base-input">
                                            {": " + DateUtil.formatDateDo(form.consentDate)}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 dim14-form-label">
                                            {CancellationPolicyDxbTextEnum.Legal_Guardian_Name[selectedLanguage]}
                                        </div>
                                        <div className="col-md-6 dim14-base-input">
                                            <TextInput value={form.guardianName} alphabeticOnly
                                                       onChange={(value) => this.fieldChanged('guardianName', value)}/>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 dim14-form-label">
                                            {CancellationPolicyDxbTextEnum.Relationship_To_Patient[selectedLanguage]}
                                        </div>
                                        <div className="col-md-6 dim14-base-input">
                                            <TextInput value={form.guardianRelationship} alphabeticOnly
                                                       onChange={(value) => this.fieldChanged('guardianRelationship', value)}/>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="col-md-12 dim14-form-label">
                                        {CancellationPolicyDxbTextEnum.Signature_of_Patient[selectedLanguage]}
                                    </div>

                                    <div className="col-md-12">
                                        <FormField
                                            ref={(ref) => this.formFields.push(ref)}
                                            required requiredMessage="Signature required"
                                            validateOn={form.patientSignature}
                                        >
                                            <SignatureInput signature={form.patientSignature}
                                                            selectedLanguage={selectedLanguage}
                                                            textEnum={CancellationPolicyDxbTextEnum}                                                            onClear={() => {
                                                                form.patientSignature = null;
                                                                this.forceUpdate();
                                                            }}
                                                            onSave={(signature) => {
                                                                form.patientSignature = signature;
                                                                this.forceUpdate();
                                                            }}/>
                                        </FormField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>
            </Wizard>
        );
    }

}
