import React, {Component} from "react";
import {FormField} from "../../../../components/form";
import {TextInput} from "../../../../components";
import {DialogActions} from "@material-ui/core";
import {Button} from "react-bootstrap";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import RemotingService from "../../../../services/remoting-service/RemotingService";

export default class DelayReasonModal extends Component {

    constructor(props) {
        super(props);
        this.state = {delayReason: props.invoice.delayReason};
    }

    confirm() {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        RemotingService.postRemoteCall(
            `api/billing/invoice/${this.props.invoice.id}/delay-reason?reason=${this.state.delayReason}`,
            null,
            (invoice) => {
                this.props.close();
                this.props.onSave(invoice);
            }
        );
    }

    render() {
        this.formFields = [];

        return (
            <>
                <div className="container padding-10" style={{width: '600px'}}>
                    <div className="row mt-1">
                        <div className="col-4 border">
                            <div>Reason:</div>
                        </div>
                        <div className="col-8 border">
                            <FormField ref={formField => this.formFields.push(formField)}
                                       required
                                       validateOn={this.state.delayReason}>
                                <TextInput value={this.state.delayReason}
                                           onChange={(value) => this.setState({delayReason: value})}/>
                            </FormField>
                        </div>
                    </div>
                </div>
                <DialogActions>
                    <Button variant="success" onClick={() => this.confirm()}>Confirm</Button>
                </DialogActions>
            </>
        );
    }

}