import React, {Component} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "react-bootstrap";
import RemotingService from "../../../services/remoting-service/RemotingService";

class AppointmentNotes extends Component {
    constructor(props) {
        super(props);

        this.state = {
            notes: this.props.appointment.notes
        };
    }

    render() {
        return (
            <fieldset style={{padding: "20px", border: "3px solid #CCC"}}>
                <legend style={{width: "auto", padding: "0 10px"}}>Notes</legend>
                <DataTable value={this.state.notes}
                           emptyMessage={<div style={{textAlign: "center"}}>There are no notes.</div>}>
                    {/*<Column field="id" header="ID" sortable={true} style={{ width: "50px" }} />*/}
                    <Column field="text" header="Text" style={{minWidth: "150px"}}/>
                    <Column field="staffName" header="Added By" sortable={true} style={{width: "125px"}}/>
                    {/*<Column field="creationDate" header="Creation Time" sortable={true} style={{ width: "200px" }} />*/}
                    {/*<Column field="updateDate" header="UPDATE TIME" sortable={true} style={{ width: "200px" }} />*/}
                    <Column field="state" header="Status" sortable={true} style={{width: "75px"}}/>
                    <Column header="" body={this.openDoneActionTemplate} style={{width: "100px"}}/>
                    <Column header="" body={this.deleteActionTemplate} style={{width: "80px"}}/>
                </DataTable>
            </fieldset>
        );
    }

    openDoneActionTemplate = (rowData) => {
        let action = (rowData.state === "OPEN") ?
            <Button style={{fontSize: 11}} variant="outline-secondary" onClick={() => this.setNoteAsDone(rowData.id)}>Set
                As Done</Button> :
            <Button style={{fontSize: 11}} variant="outline-primary" onClick={() => this.setNoteAsOpen(rowData.id)}>Set
                As Open</Button>;

        return (<div style={{position: 'relative', float: 'right'}}> {action} </div>);
    };

    deleteActionTemplate = (rowData) => {
        let action = <Button style={{fontSize: 11}} variant="outline-danger"
                             onClick={() => this.deleteNote(rowData.id)}>Delete</Button>;
        return (rowData.state === "OPEN") ? (
            <div style={{position: 'relative', float: 'right'}}> {action} </div>) : null;
    };

    deleteNote = (noteId) => {
        RemotingService.deleteRemoteCall('/api/appointment/note/' + noteId, null, this.refreshNotes);
    };

    setNoteAsDone = (noteId) => {
        RemotingService.postRemoteCall('/api/appointment/note/' + noteId + '/done', null, this.refreshNotes);
    };

    setNoteAsOpen = (noteId) => {
        RemotingService.postRemoteCall('/api/appointment/note/' + noteId + '/open', null, this.refreshNotes);
    };

    refreshNotes = () => {
        RemotingService.getRemoteCall('/api/appointment/' + this.props.appointment.id + '/note', null, (notes) => {
            this.props.onRefreshNotes(notes);
            this.setState({notes});
        });
    };
}

export default AppointmentNotes;