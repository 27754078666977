import React, {Component} from "react";
import "../../VisitHistory.css";
import DateUtil from "../../../../../../utils/DateUtil";

export default class SurgicalViewHistory extends Component {
    render() {
        if (this.props.surgicalHistory == null) {
            return <div className="pl-3">No information available</div>;
        } else if (this.props.surgicalHistory.none) {
            return <div className="pl-3">No Surgical History</div>;
        }

        let surgicalHistory = this.getSurgicalHistoryItems();

        return (
            <div className="pl-3">
                {surgicalHistory}
            </div>
        );
    }

    getSurgicalHistoryItems() {
        if (this.props.surgicalHistory.items == null
            || this.props.surgicalHistory.items.length === 0) {
            return null;
        }

        let surgicalHistoryItems = [];

        this.props.surgicalHistory.items.forEach((item, index) => {
            surgicalHistoryItems.push(
                <div id={"surgicalViewHistoryDiv" + index}>
                    <b>Condition: </b>
                    {item.condition},
                    <b>Procedure: </b>
                    {
                        [
                            item.procedure,
                            item.date != null ? DateUtil.formatDate(item.date) : null,
                            item.detail
                        ].filter(Boolean).join(", ")
                    }
                </div>
            );
        });

        return surgicalHistoryItems;
    }
}