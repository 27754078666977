import React from "react";
import ReactDOM from "react-dom";
import Modal from "../modal/Modal";

export default function confirmDialog(title, text, confirmFunction, rejectFunction, component, submitContent = "Confirm", closeContent) {

    const dialogContainer = document.getElementById('dialog-container');
    const confirmationDialogDiv = dialogContainer.appendChild(document.createElement('div'));

    function dispose() {
        setTimeout(() => {
            ReactDOM.unmountComponentAtNode(confirmationDialogDiv);
            setTimeout(() => {
                if (dialogContainer.contains(confirmationDialogDiv)) {
                    dialogContainer.removeChild(confirmationDialogDiv)
                }
            });
        }, 200);
    }

    function renderModal() {
        return <Modal visible={true}
                      header={title}
                      submitContent={submitContent}
                      closeContent={closeContent}
                      submitAction={() => {
                          confirmFunction();
                          dispose();
                      }}
                      closeAction={() => {
                          if (rejectFunction) {
                              rejectFunction();
                          }
                          dispose();
                      }}>
            {text}
            {component &&
                React.cloneElement(component)
            }
        </Modal>
    }

    ReactDOM.render(renderModal(), confirmationDialogDiv);

}
