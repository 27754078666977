import React, {Component} from "react";
import ReportSection from "../ReportSection";
import ReportViewUtils from "./ReportViewUtils";

export default class DischargePlanInstructionsReportView extends Component {

    render() {
        const plan = this.props.dischargePlan;

        if (ReportViewUtils.isDischargePlanInstructionsNull(plan)) {
            return null;
        }

        return (
            <div className="w-100">
                <div className="d-flex flex-column">

                    {plan.homeProgram && plan.homeProgram != null && plan.homeProgram.length != 0 &&
                    <ReportSection header1={"Home Program"}>
                        <div>
                            {plan.homeProgram.map(item => item.details).join(", ")}
                        </div>
                    </ReportSection>}

                    {plan.recommendations && plan.recommendations != null && plan.recommendations.length != 0 &&
                    <div className={"col-12"}>
                        <b>Recommendations</b>
                        <div>{plan.recommendations.map(item => item.details).join(", ")}</div>
                    </div>}
                </div>
            </div>
        );
    }
}
