import React, {Component} from 'react';
import moment from "moment";
import StringUtil from "../../utils/StringUtil";
import {TextInput, Wizard} from "../../components";
import RemotingService from "../../services/remoting-service/RemotingService";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import SignatureInput from "../../components/signature-input/SignatureInput";
import Logo from "../../layout/Logo";
import PatientUtil from "../../utils/PatientUtil";
import ConsentFormsUtil from "./utils/ConsentFormsUtil";
import FormField from "../../components/form/FormField";

class PerinealPhysiotherapyConsentForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            healthProfessionalDate: new Date(),
            patientDate: new Date(),
            guardianDate: new Date(),
            providerName: this.props.staff.name,
            providerSpeciality: this.props.staff.speciality.name
        }
    }

    onSubmit = () => {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        if (this.props.form !== null && this.props.form !== undefined) {
            this.props.close();

        } else {
            let patient = this.props.patient;
            let patientFullName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
            let patientMrn = StringUtil.mrn(patient.mrn);
            let patientDOB = patient.birthday;
            const {
                healthProfessionalDate, patientDate, guardianDate, guardianName, guardianRelationship,
                patientSignature, providerSignature, guardianSignature, providerName, providerSpeciality
            } = this.state;

            RemotingService.postRemoteCall('api/visit/form/perineal-physiotherapy-consent-form/' + this.props.visitId,
                {
                    healthProfessionalDate,
                    patientDate,
                    guardianDate,
                    guardianName,
                    guardianRelationship,
                    patientSignature,
                    providerSignature,
                    guardianSignature,
                    patientFullName,
                    patientMrn,
                    patientDOB,
                    providerName, providerSpeciality
                },
                (result) => {
                    this.props.afterSave(result);
                    this.props.close();
                });
        }
    }

    onSaveGuardianSignature = (signature) => {
        this.setState({guardianSignature: signature})
    }

    onSavePatientSignature = (signature) => {
        this.setState({patientSignature: signature})
    }

    onSaveProviderSignature = (signature) => {
        this.setState({providerSignature: signature})
    }

    render() {
        if (!this.props.show)
            return null;

        const {providerName, providerSpeciality} = this.state;
        let {patient, form} = this.props;
        let readOnly = form !== null && form !== undefined;
        let mrnNo = StringUtil.mrn(patient.mrn);
        let patientName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
        this.formFields = []

        return (
            <Wizard
                show={this.props.show}
                close={this.props.close}
                title="Perineal Physiotherapy Consent Form"
                onSubmit={this.onSubmit}
                isReadOnly={readOnly}
            >
                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}>
                    <div style={{width: '1000px'}}>
                        <div style={{
                            marginTop: '15px', marginBottom: '15px', width: '100%', display: 'flex',
                            flexFlow: 'row', justifyContent: 'space-between', alignItems: 'center'
                        }}>
                            <Logo/>
                            <div>
                                <h2>PERINEAL PHYSIOTHERAPY CONSENT FORM</h2>
                            </div>
                        </div>

                        <hr style={{
                            borderTop: "2px solid #000000",
                            marginTop: "-10px",
                            boxShadow: "0px 2px 2px #797979"
                        }}/>

                        <div style={{border: "1px solid #000000"}}>
                            <div style={{margin: "15px 15px"}}>
                                <h5>Patient information:</h5>
                                <div className={"row"} style={{marginTop: "10px", marginLeft: "0px"}}>
                                    <div className="col-md-5">
                                        <b>Name:</b> {patientName}
                                    </div>
                                    <div className="col-md-4">
                                        <b>MRN:</b> {mrnNo}
                                    </div>
                                    <div className="col-md-3">
                                        <b>D.O.B:</b> {patient.birthday}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                            <div style={{margin: "15px 15px"}}>
                                <h5>Perineal Physiotherapy</h5>
                                <p>
                                    is a therapeutic discipline that allows a trained healthcare professional to evaluate
                                    and treat pelvic floor dysfunctions (urinary and/or anal incontinence,
                                    pelvic pain, pelvic organ prolapses, etc.), sexual conditions (dyspareunia, vaginismus, etc.)
                                    and especially during pregnancy, postpartum and menopause.
                                </p>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>
                    <div style={{width: '1000px'}}>
                        <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                            <div style={{margin: "10px 15px"}}>
                                <h5 className={"font-weight-bold"}>
                                    Statement of Health Professional:
                                </h5>
                                <p style={{fontSize: "12px", marginTop: "-5px"}}>(to be filled in by health professional
                                    with appropriate
                                    knowledge of proposed investigation)</p>
                                <p>I have explained the procedure to the patient, in particular:</p>
                                <p> To evaluate the condition, it may be necessary, initially, and periodically,
                                    to perform an internal pelvic floor muscle examination.
                                    This examination is performed by observing and/or palpating the perineal region including the vagina and/or rectum.
                                    This evaluation will assess skin condition, reflexes, muscle tone, length, strength,
                                    coordination and endurance, scar mobility and function of the pelvic floor region.
                                    Such evaluation may include vaginal or rectal sensors for muscle biofeedback. </p>
                                <p style={{marginBottom: "0px"}}>
                                    Treatment may include, but not limited to the following: observation, palpation,
                                    use of vaginal weights, internal muscle release techniques,
                                    vaginal or rectal sensors for biofeedback and/or electrical stimulation, strengthening exercises,
                                    soft tissue and/or joint mobilization and educational instruction.


                                </p>
                                <div className={"row no-margin"}>
                                    <div className={"col-6 flex-column"} style={{padding: "1px", paddingRight: "2px"}}>
                                        <div style={{border: "1px solid #000000", marginTop: "5px", height:"100%"}}>
                                            <div style={{margin: "5px 10px"}}>
                                                <b>Risks</b>
                                                <p>
                                                    Most of the techniques used in Perineal Physiotherapy have no adverse effects.
                                                    Risks associated with doing an internal pelvic floor exam include:
                                                    <ul>
                                                        <li>Pain/discomfort (during or after the assessment)</li>
                                                        <li>Spotting/bleeding (during or after the assessment)</li>
                                                        <li>Anxiety/emotional response</li>
                                                        <li>Skin reaction/irritation (from gloves and/or lubricant)</li>
                                                        <li>Vaso-vagal response (nausea or light-headedness)</li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-6 flex-column"} style={{padding: "1px", paddingLeft: "2px"}}>
                                        <div style={{border: "1px solid #000000", marginTop: "5px", height:"100%"}}>
                                            <div style={{margin: "5px 10px"}}>
                                                <b>Benefits</b>
                                                <p>Pelvic floor treatment may improve your symptoms:
                                                    <ul>
                                                        <li>Reduced pelvic pain</li>
                                                        <li>Reduced symptoms of other conditions caused by pelvic floor problems,
                                                            such as urinary and fecal incontinence, painful intercourse, and sexual dysfunction
                                                        </li>
                                                        <li>Increase in ability to perform daily activities</li>
                                                        <li>Increased strength, awareness, flexibility and endurance in movements</li>
                                                    </ul>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    PROVIDER NAME:
                                </div>
                                <div className="col-md-3">
                                    {providerName}
                                </div>

                                <div className="col-md-3 dim14-form-label">
                                    JOB TITLE:
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    {providerSpeciality}
                                </div>
                            </div>

                            <div className="row" style={{marginTop: "15px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    DATE/TIME:
                                </div>

                                <div className="col-md-3">
                                    {moment(readOnly && form.healthProfessionalDate ?
                                        new Date(form.healthProfessionalDate)
                                        :
                                        this.state.healthProfessionalDate).format('MMMM DD YYYY, h:mm:ss a')
                                    }
                                </div>

                                <div className="col-md-6 dim14-form-label">
                                    PROVIDER SIGNATURE:
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <FormField ref={(ref) => this.formFields.push(ref)}
                                               disabled={readOnly}
                                               required requiredMessage="Signature required"
                                               validateOn={this.state.providerSignature}>
                                        <SignatureInput
                                            signature={readOnly ? form.providerSignature : this.state.providerSignature}
                                            onSave={this.onSaveProviderSignature} readOnly={readOnly}/>
                                    </FormField>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>
                    <div style={{width: '1000px'}}>
                        <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                            <div style={{margin: "10px 15px"}}>
                                <h5>
                                    Statement of patient:
                                </h5>
                                <p style={{fontSize: "12px", marginTop: "-5px"}}>Please read this form carefully. If you
                                    have any further questions, do ask. You have the right to change your mind at any
                                    time, including after you have signed this form.</p>
                                <p>
                                    <p><b>I understand and agree to the following: </b></p>
                                    <ul>
                                        <li><b>I understand</b> the investigation which has been explained to me.</li>
                                        <li><b>I understand</b> that I can terminate the procedure at any time.</li>
                                        <li><b>I understand</b> that I am responsible for immediately telling the
                                            examiner if I
                                            am having any discomfort or unusual symptoms during the procedure and if I
                                            wish to
                                            terminate the examination or treatment.
                                        </li>
                                        <li><b>I understand</b> that I am responsible in case I chose to have a second
                                            person
                                            present in the room during the procedure
                                        </li>
                                        <li><b>I verify</b> that I have not been deemed a high-risk pregnancy by my
                                            physician,
                                            and I understand that if I am, I will notify my therapist immediately.
                                        </li>
                                        <li><b>I understand</b> that if I have experienced past physical or emotional
                                            trauma
                                            related to the pelvic region it is best if I share this information with my
                                            treating
                                            therapist.
                                        </li>
                                        <li>If I am pregnant, I understand it is my responsibility to be cleared by my
                                            physician before an internal vaginal examination takes place
                                        </li>
                                        <li><b>I understand</b> that the person performing the investigation has the
                                            appropriate experience or is training under direct supervision
                                        </li>
                                        <li><b>I agree</b> that I have had a chance to ask appropriate questions.</li>
                                    </ul>
                                </p>
                                <p>I have <b>informed</b> my Therapist that I <b>DO NOT</b> have a Pacemaker, suspected
                                    current
                                    infection, pregnancy, tumors, implants or any other condition that may
                                    contraindicate this treatment</p>
                            </div>
                        </div>
                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    DATE/TIME:
                                </div>

                                <div className="col-md-3">
                                    {moment(readOnly && form.patientDate ?
                                        new Date(form.patientDate)
                                        :
                                        this.state.patientDate).format('MMMM DD YYYY, h:mm:ss a')
                                    }
                                </div>
                            </div>

                            <div className="row" style={{marginTop: "15px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    PATIENT NAME:
                                </div>
                                <div className="col-md-3">
                                    {patientName}
                                </div>

                                <div className="col-md-6 dim14-form-label">
                                    PATIENT SIGNATURE:
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <SignatureInput
                                        signature={readOnly ? form.patientSignature : this.state.patientSignature}
                                        onSave={this.onSavePatientSignature} readOnly={readOnly}/>
                                </div>
                            </div>
                        </div>

                    </div>
                </Wizard.Page>

                <Wizard.Page onPrevious={() => this.formFields = []}>
                    <div style={{width: '1000px'}}>
                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <p><b>A witness should sign below if the patient is unable to sign but has indicated
                                    his or her consent. <br/>
                                    Young people (under {ConsentFormsUtil.getWitnessAgeText()} years of age) and children should also have a Parent
                                    or Guardian sign here.</b></p>
                            </div>

                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    PRINT NAME:
                                </div>
                                <div className="col-md-3">
                                    <TextInput value={readOnly ? form.guardianName : this.state.guardianName}
                                               disabled={readOnly} alphabeticOnly style={{textAlign: "center"}}
                                               onChange={(val) => this.setState({guardianName: val})}/>
                                </div>

                                <div className="col-md-3 dim14-form-label">
                                    Relationship to Patient
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    <TextInput
                                        value={readOnly ? form.guardianRelationship : this.state.guardianRelationship}
                                        disabled={readOnly} alphabeticOnly style={{textAlign: "center"}}
                                        onChange={(val) => this.setState({guardianRelationship: val})}/>
                                </div>
                            </div>


                            <div className={"row"} style={{marginTop: "15px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    DATE/TIME:
                                </div>

                                <div className="col-md-3">
                                    {moment(readOnly && form.guardianDate ?
                                        new Date(form.guardianDate)
                                        :
                                        this.state.guardianDate).format('MMMM DD YYYY, h:mm:ss a')
                                    }
                                </div>

                                <div className="col-md-6 dim14-form-label">
                                    SIGNATURE OF WITNESS/PARENT OR GUARDIAN:
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <FormField ref={(ref) => this.formFields.push(ref)}
                                               disabled={readOnly || this.state.patientSignature != null}
                                               required requiredMessage="Signature required"
                                               validateOn={this.state.guardianSignature}>
                                        <SignatureInput
                                            signature={readOnly ? form.guardianSignature : this.state.guardianSignature}
                                            onSave={this.onSaveGuardianSignature} readOnly={readOnly}/>
                                    </FormField>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>
            </Wizard>
        );
    }
}

export default PerinealPhysiotherapyConsentForm;
