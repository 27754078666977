import React, {Component} from "react";
import InternalSpecialityEnum from "../enums/referral/InternalSpecialityEnum";
import ExternalSpecialityEnum from "../enums/referral/ExternalSpecialityEnum";
import InterventionTypeEnum from "../enums/referral/InterventionTypeEnum";
import DiagnosisGroup from "./DiagnosisGroup";

export default class ReferralView extends Component {

    render() {
        return (
            <>
                {
                    this.props.referral != null && this.props.referral.internals.length > 0 &&
                    <div className="mt-3">
                        <div className={"row no-margin py-1 mt-2 w-100"} style={{marginTop: "20px"}}>
                            <div className="content-label ml-0" style={{fontSize: "18px", fontWeight: "bold"}}>Internal</div>

                            <div className={"content-card m-0"}>
                                <table className={"emr-table"}>
                                    <tbody>
                                    <tr>
                                        <th>Diagnosis</th>
                                        <th>Speciality</th>
                                        <th>Provider Name</th>
                                        <th>CPT Code</th>
                                        <th>No of Sessions</th>
                                        <th>Remarks</th>
                                        <th>Approval Required</th>
                                    </tr>
                                    {
                                        this.props.referral.internals.map((item, index) => {
                                                return (
                                                    <tr key={index} style={{backgroundColor: "white"}}>
                                                        <td><DiagnosisGroup diagnosisGroup={item.diagnosisGroup} showSecondaryItems/></td>
                                                        <td>{InternalSpecialityEnum[item.speciality]?.name}</td>
                                                        <td>{item.provider?.name}</td>
                                                        <td>
                                                            {
                                                                item.cptCodeList.map((cpt, index) => {
                                                                    return (index === 0 ? "" : ", ") + cpt.cptCode + " " + cpt.description
                                                                })
                                                            }
                                                        </td>
                                                        <td>{item.noOfSessions}</td>
                                                        <td>{item.remarks}</td>
                                                        <td>{true === item.needsApproval ? 'Yes' : 'No'}</td>
                                                    </tr>);
                                            }
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.props.referral != null && this.props.referral.externals.length > 0 &&
                    <div>
                        <div className={"row no-margin py-1 mt-3 w-100"} style={{marginTop: "20px"}}>
                            <div className="content-label ml-0 mb-3" style={{fontSize: "18px", fontWeight: "bold"}}>External</div>

                            <div className={"content-card m-0"}>
                                <table className={"emr-table"}>
                                    <tbody>
                                    <tr>
                                        <th>Speciality</th>
                                        <th>Provider Name</th>
                                        <th>Recommendations</th>
                                        <th>Remarks</th>
                                    </tr>
                                    {
                                        this.props.referral.externals.map((item, index) => {
                                                return (
                                                    <tr key={index} style={{backgroundColor: "white"}}>
                                                        <td>{item.speciality ? ExternalSpecialityEnum[item.speciality].name : null}</td>
                                                        <td>{item.provider}</td>
                                                        <td>{item.recommendations}</td>
                                                        <td>{item.remarks}</td>
                                                    </tr>);
                                            }
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.props.referral != null && this.props.referral.interventions.length > 0 &&
                    <div>
                        <div className={"row no-margin py-1 w-100"}>
                            <div className="content-label ml-0 mt-4" style={{fontSize: "18px", fontWeight: "bold"}}>Interventions</div>

                            <div className={"content-card m-0"}>
                                <table className={"emr-table"}>
                                    <tbody>
                                    <tr>
                                        <th>Type</th>
                                        <th>Details</th>
                                        <th>Recommendations</th>
                                        <th>Remarks</th>
                                    </tr>
                                    {
                                        this.props.referral.interventions.map((item, index) => {
                                                return (
                                                    <tr key={index} style={{backgroundColor: "white"}}>
                                                        <td>{item.type ? InterventionTypeEnum[item.type].name : null}</td>
                                                        <td>{item.details}</td>
                                                        <td>{item.recommendations}</td>
                                                        <td>{item.remarks}</td>
                                                    </tr>);
                                            }
                                        )
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }

}
