import Enum from "../../../../../../enums/Enum";

const MobilizationEnum = new Enum({
    Spinal: {name: "Spinal"},
    Joint: {name: "Joint"},
    Soft_Tissue: {name: "Soft Tissue"},
    Visceral: {name: "Visceral"},
    Cranial: {name: "Cranial"},
    Other: {name: "Other"},
});

export default MobilizationEnum;