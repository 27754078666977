import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AppointmentEvent from "../appointment/calendarevent/AppointmentEvent";
import EnumLeaveType from "./EnumLeaveType";
import AppointmentCalendarEventFactory from "../appointment/calendarevent/AppointmentCalendarEventFactory";
import EnumBlockType from "../appointment/enums/EnumBlockType";
import {Badge} from "../../components";
import LeaveEvent from "./LeaveEvent";

export default class HrEventWrapper extends Component {

    render() {
        const {event, timeZoneGetter, viewGetter} = this.props;
        const newLeaveEvent = !event.id;

        if (event.isBlockEvent === true) {
            return AppointmentCalendarEventFactory({
                timeZoneGetter,
                viewGetter,
                covidGapExists: false,
                appointmentActionsVisible: false,
                eventContextMenuVisible: false
            })(this.props);
        } if (event.isAppointmentEvent === true) {
            return <AppointmentEvent {...this.props} appointment={event} event={event} timeZoneGetter={timeZoneGetter}
                                     smallView={true} readOnly={true} covidGapExists={false}/>
        } else if (event.isSummaryEvent === true) {
            return (
                <div className="calendar-event"
                     style={{
                         backgroundColor: EnumBlockType[event.type].bgColor,
                         border: "none"
                     }}>
                    <div className="d-flex align-items-center">
                        <Badge pill variant="light" className="mr-2">{event.count}</Badge>
                        <div>{EnumBlockType[event.type].name}</div>
                    </div>
                </div>
            )
        } else if (newLeaveEvent) {
            return (
                <div className="leave-event"
                     style={{
                         backgroundColor: EnumLeaveType[event.type].bgColor
                     }}>
                    <FontAwesomeIcon size={'2x'} icon={["fas", "calendar-plus"]}/>
                </div>
            )
        } else {
            return (
                <LeaveEvent event={event}/>
            );
        }
    }
}