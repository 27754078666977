import React, {Component} from 'react';
import {FormSectionHeader} from "../../public/FormSectionHeader";
import IntakeFormTextEnum from "../enum/IntakeFormTextEnum";
import {FormLine} from "../../public/FormLine";
import FormField from "../../../../components/form/FormField";
import {FormPhoneInput} from "../../public/FormPhoneInput";
import {SelectInput} from "../../../../components";
import {connect} from "react-redux";

class PublicIntakeFormEmergencyContactInformation extends Component {
    render() {
        const {selectedLanguage, enumLabel, form, onChange, validatedTextField, pushFormFieldRef} = this.props;

        return (
            <>
                <FormSectionHeader header={IntakeFormTextEnum.Emergency_Contact_Information[selectedLanguage]}/>
                <FormLine label={IntakeFormTextEnum.Emergency_Contact_Name[selectedLanguage]}>
                    {validatedTextField(form, "emergencyContactName")}
                </FormLine>
                <FormLine label={IntakeFormTextEnum.Emergency_Contact_Mobile_Number[selectedLanguage]}>
                    <FormField
                        ref={(ref) => pushFormFieldRef(ref)}
                        required
                        validateOn={form.emergencyContactMobilePhone}
                    >
                        <FormPhoneInput
                            value={form.emergencyContactMobilePhone}
                            onValueChange={(value) =>
                                onChange("emergencyContactMobilePhone", value)
                            }
                        />
                    </FormField>
                </FormLine>
                <FormLine label={IntakeFormTextEnum.Emergency_Contact_Relationship[selectedLanguage]}>
                    <SelectInput value={form.emergencyContactRelationshipType}
                                 options={this.props.relationshipTypes}
                                 displayProperty={"name"}
                                 searchEnabled={false}
                                 className="DropdownBorderColor w-100"
                                 onChange={(val) =>
                                     onChange(
                                         "emergencyContactRelationshipType",
                                         val
                                     )
                                 }
                    />
                </FormLine>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        relationshipTypes: state.base.relationshipTypes
    };
}

export default connect(mapStateToProps)(PublicIntakeFormEmergencyContactInformation);
