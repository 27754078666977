import React, {Component} from "react";
import '../Subjective.css';
import '../../EMR.css';
import './ComplaintView.css';
import YesNoNotAvailableEnum from "../../../../genericenums/YesNoNotAvailableEnum";
import YesNoEnum from "../../../../genericenums/YesNoEnum";
import BirthTypeEnum from "../enums/history/BirthTypeEnum";
import {Grid} from "@material-ui/core";

export default class BirthHistoryView extends Component {

    render() {
        let divs = this.getDivs();
        return (
            <Grid container alignItems={"center"} className={"content-card content-row m-0 w-100 p-2"}>
                <Grid item xs style={{maxWidth: 200}} className={"content-card-title"}>
                    <h6 className={"no-margin"}>Birth History</h6>
                </Grid>
                <Grid item xs>
                    <div className="p-2 pl-3" style={{borderLeft: "1px gainsboro solid"}}>
                        {(divs != null && divs.length != 0) ? divs :
                            <div>
                                No information
                            </div>
                        }
                    </div>
                </Grid>
            </Grid>
        );
    }

    getDivs() {
        if (this.props.birthHistory == null) {
            return null;
        }
        let divs = [];
        if (this.props.birthHistory.children != null) {
            divs.push(
                <div key={"birthHistoryViewDivChildren"}>
                    Children: {this.props.birthHistory.children}
                </div>);
        }

        if (this.props.birthHistory.currentlyPregnant != null) {
            divs.push(
                <div key={"birthHistoryViewDivPregnant"}>
                    Currently
                    Pregnant: {YesNoNotAvailableEnum[this.props.birthHistory.currentlyPregnant].name}{this.props.birthHistory.currentlyPregnantDetail == null ? '' : (", " + this.props.birthHistory.currentlyPregnantDetail)}
                </div>);
        }

        if (this.props.birthHistory.items != null
            && this.props.birthHistory.items.length != 0) {
            this.props.birthHistory.items.forEach((item, index) => {
                divs.push(
                    <div key={"birthHistoryViewDiv" + index}>
                        {BirthTypeEnum[item.birthType].name}{item.detail == null ? "" : (", " + item.detail)}
                    </div>);
            });
        }

        if (this.props.birthHistory.birthComplication != null) {
            divs.push(
                <div key={"birthHistoryViewDivCompl"}>
                    Birth
                    Complication: {YesNoEnum[this.props.birthHistory.birthComplication].name}{this.props.birthHistory.currentlyPregnantDetail == null ? '' : (", " + this.props.birthHistory.currentlyPregnantDetail)}
                </div>);
        }

        if (this.props.birthHistory.otherDetail != null && this.props.birthHistory.otherDetail != "") {
            divs.push(
                <div key={"birthHistoryViewDivOtherDetail"}>
                    Other: {this.props.birthHistory.otherDetail}
                </div>);
        }

        return divs;
    }
}