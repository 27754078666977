import Enum from "../../../../enums/Enum";

const CancellationPolicyKwtTextEnum = new Enum({

    Cancellation_Policy_Wizard_Title: {English: "Appointment Cancellation Policy", Arabic: "سياسة إلغاء المواعيد"},
    Cancellation_Policy_Title: {English: "APPOINTMENT CANCELLATION POLICY", Arabic: "سياسة إلغاء المواعيد"},
    Cancellation_Policy_Public_Title: {English: "CANCELLATION POLICY", Arabic: "سياسة إلغاء المواعيد"},

    MRN: {English: "MRN", Arabic: "السجل الطبي الإلكتروني"},

    Paragraph1: {
        English: "We want to thank you for choosing us as your health provider. We strive to render excellent care to you and the rest of our patients. Your care and treatment are a priority to us. We also ask that you respect your therapist’s time and expertise as well.",
        Arabic: "نود أن نشكركم على اختياركم لنا كمقدمي الرعاية الصحية. اننا نسعى جاهدين لتقديم رعاية ممتازة لكم ولبقية مرضانا. رعايتكم وعلاجكم هي أولوية بالنسبة لنا. نطلب كذلك أن تحترم وقت المعالج وخبرته أيضًا."
    },

    Paragraph2_Part1: {
        English: "In an attempt to be consistent with this, we have an",
        Arabic: "في محاولة بالتوافق مع هذا، لدينا "
    },

    Paragraph2_Bold_Part: {
        English: "Appointment Cancellation Policy",
        Arabic: "سياسة إلغاء المواعيد"
    },

    Paragraph2_Part2: {
        English: "that allows us to schedule appointments for our patients, with respect for your time, the next patient’s time, and the doctor’s time.",
        Arabic: "التي تسمح لنا بتحديد مواعيد لمرضانا، مع احترام وقتكم، ووقت المريض التالي، ووقت الطبيب"
    },

    Title3: {
        English: "OUR POLICY IS AS FOLLOWS:",
        Arabic: "سياستنا هي على النحو التالي"
    },

    Paragraph3_Part1: {
        English: "We request that you give",
        Arabic: "نطلب منكم اعطائنا "
    },

    Paragraph3_Bold_Part: {
        English: "6 hours' notice",
        Arabic: "إشعاراً 6 ساعات قبل موعد كم المقرر٬"
    },

    Paragraph3_Part2: {
        English: "in the event that you cannot make it to your scheduled appointment. This will enable us to offer your cancelled time to other patients that desire to get their treatment completed. If a patient misses an appointment without contacting our office, it is considered a \"missed\" or \"no show\" appointment.",
        Arabic: "في حال عدم قدرتكم على الوصول في الوقت المحدد. سيخولنا هذا بتقديم وقتكم الملغى للمرضى الآخرين الذين يرغبون في إكمال علاجهم. في حال غاب المريض عن موعدًا دون الاتصال بمكتبنا، فإنه يعتبر موعدًا \"فائتًا\" أو \"عدم الحضور\"."
    },

    Paragraph4: {
        English: "The first missed appointment is forgiven, the second thereafter you will be charged a fee of",
        Arabic: "يتم التغاضي عن أول موعد فائت، والثاني بعد ذلك سيتم فرض رسوم "
    },

    Paragraph4_Bold_Part: {
        English: "20KWD.",
        Arabic: "20 دينار كويتي"
    },

    Paragraph5_Part1: {
        English: "Additionally, if you miss more than",
        Arabic: "بالإضافة إلى ذلك، إذا فاتتكم أكثر من"
    },

    Paragraph5_Bold_Part: {
        English: "3",
        Arabic: "3"
    },

    Paragraph5_Part2: {
        English: "appointments, Diversified Integrated Sports Clinic-Kuwait reserves the right to discharge you from the practice for failing to follow treatment recommendations.",
        Arabic: "مواعيد، تحتفظ عيادة ديفيرسفيد إنتغرتد سبورتس كلينك - الكويت بحق رفض معالجتكم لعدم الإتباع بتوصيات العلاج."
    },

    Paragraph6: {
        English: "If you have any questions regarding this policy, please let our staff know, and we will be happy to clarify the policy for you. We look forward to being a continued part of your wellness.",
        Arabic: "إذا كانت لديكم أي أسئلة بخصوص سياستنا هذه، يرجى إعلام فريق العمل، وسيسعدنا توضيح السياسة لكم. نتطلع إلى أن نكون جزءًا مستمرًا من صحتكم."
    },

    Paragraph7: {
        English: "I have read and understood the Appointment Cancellation Policy of Diversified Integrated Sports Clinic-Kuwait, and I agree to be bound by its terms.",
        Arabic: "لقد قرأت وفهمت سياسة إلغاء المواعيد الخاصة بعيادة ديفيرسفيد إنتغرتد سبورتس كلينك - الكويت، وأوافق على الالتزام بشروطها"
    },

    Paragraph8_Part1: {
        English: "I,",
        Arabic: "أنا الموقع أدناه, "
    },

    Paragraph8_Part2: {
        English: ", have received a copy of Diversified Integrated Sports Clinic-Kuwait Appointment Cancellation Policy.",
        Arabic: ",تسلمت نسخة عن سياسة إلغاء المواعيد لدى عيادة ديفيرسفيد إنتغرتد سبورتس كلينك- الكويت"
    },

    Date: {English: "Date", Arabic: "التاريخ:"},
    Legal_Guardian_Name: {English: "Legal Guardian Name", Arabic: "اسم الوصي القانوني"},
    Relationship_To_Patient: {English: "Relationship to Patient", Arabic: "العلاقة مع المريض"},
    Signature_of_Patient: {English: "Signature of Patient (or legal guardian)", Arabic: "توقيع المريض (أو الوصي القانوني)"},
    Signature_input_Clear: {English: "Clear", Arabic: "حذف"},
    Undo: {English: "Undo", Arabic: "الغاء"},
    Wizard_Previous: {English: "Previous", Arabic: "السابق"},
    Wizard_Next: {English: "Next", Arabic: "التالي"},
    Wizard_Save: {English: "Save", Arabic: "حفظ"}
});

export default CancellationPolicyKwtTextEnum;