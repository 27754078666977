import Enum from "../../../../../../enums/Enum";

const ExternalSpecialityEnum = new Enum({
    ORTHOPEDIC_SURGERY: {name: "Orthopedic Surgery"},
    RHEUMATOLOGY: {name: "Rheumatology"},
    GENERAL_SURGERY: {name: "General Surgery"},
    NEUROLOGY: {name: "Neurology"},
    ENDOCRINOLOGY: {name: "Endocrinology"},
    OBS_AND_GYN: {name: "Obs&Gyn"},
    PSYCHIATRY: {name: "Psychiatry"},
    PSYCHOLOGY: {name: "Psychology"},
    ONCOLOGY: {name: "Oncology"},
    GASTROENTEROLOGY: {name: "Gastroenterology"},
    CARDIOLOGY: {name: "Cardiology"},
    PULMONARY: {name: "Pulmonary"},
    SLEEP_MEDICINE: {name: "Sleep Medicine"},
    ANESTHESIOLOGY: {name: "Anesthesiology"},
    VASCULAR_SURGERY: {name: "Vascular Surgery"},
    UROLOGY: {name: "Urology"},
    ALLERGY_AND_IMMUNOLOGY: {name: "Allergy & Immunology"},
    DERMATOLOGY: {name: "Dermatology"},
    HEMATOLOGY: {name: "Hematology"},
    PEDIATRICS: {name: "Pediatrics"},
    OPHTHALMOLOGY: {name: "Ophthalmology"},
    MEDICAL_GENETICS: {name: "Medical Genetics"},
    SURGERY: {name: "Surgery"},
    NUTRITION: {name: "Nutrition"}
});

export default ExternalSpecialityEnum;