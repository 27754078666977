import React, {Component} from "react";
import {Card} from "react-bootstrap";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import "./StaffPreference.css";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UpdateStaffPreference from "./UpdateStaffPreference";
import EnumBreakTimeAM from "../../enums/EnumBreakTimeAM";
import EnumBreakTimePM from "../../enums/EnumBreakTimePM";
import GeneralStaffPreferences from "./GeneralStaffPreferences";
import StaffSchedule from "../schedule/StaffSchedule";
import StaffPreferenceAppointmentCalendar from "./StaffPreferenceAppointmentCalendar";
import UpdateAdminPreference from "./UpdateAdminPreference";
import AuthService from "../../../../services/auth-service/AuthService";
import LeavePolicy from "../../../hr/LeavePolicy";
import SchedulingService from "../../../../services/scheduling-service/SchedulingService";
import NotificationService from "../../../../services/notification-service/NotificationService";
import DateUtil from "../../../../utils/DateUtil";

class StaffPreference extends Component {

    constructor(props) {
        super(props);
        this.state = {
            staffPreference: this.createDummyPreference(),
            refreshStaffScheduleToggle: false
        }
    }

    createDummyPreference() {
        return {dayPreferences: []};
    }

    componentDidMount() {
        RemotingService.getRemoteCall(`api/staff/${this.props.staffId}/preference`, null, staffPreference => {
            staffPreference = staffPreference || this.createDummyPreference();
            this.setState({staffPreference: staffPreference});
        });
    }

    updateStaffPreference = () => {
        this.setState({updateStaffPreferenceVisible: true});
    }

    handleUpdateStaffPreferenceClose = (staffPreference) => {
        let newState = {updateStaffPreferenceVisible: false};
        if (staffPreference) {
            newState.staffPreference = staffPreference;
        }
        this.setState(newState);
    };

    updateAdminPreference = () => {
        this.setState({updateAdminPreferenceVisible: true});
    }

    handleUpdateAdminPreferenceClose = (staffPreference) => {
        let newState = {updateAdminPreferenceVisible: false};
        if (staffPreference) {
            newState.staffPreference = staffPreference;
        }
        this.setState(newState);
    };

    refreshSchedule() {
        this.setState({refreshStaffScheduleToggle: !this.state.refreshStaffScheduleToggle});
    }

    updateIntraDaySchedule = (selectedDate) => {
        RemotingService.postRemoteCall(`api/scheduling-secure/daySchedule/${this.props.staffId}?startDate=${DateUtil.formatIsoDate(selectedDate)}`, null, staffPreference => {
            this.refreshSchedule();

            NotificationService.showNotification({
                severity: 'success',
                summary: 'Within day schedule updated'
            });
        });
    };

    render() {
        return <Card className="StaffMedium" style={{width: '100%'}}>
            <Card.Header>
                Preferences
            </Card.Header>
            <Card.Body>
                <Tabs defaultActiveKey="preferences" id="preferences-tabs" onSelect={(key) => {
                    this.setState({selectedTab: key})
                }}>
                    <Tab eventKey="preferences" title="Preferences">
                        <div className="float-right">
                            {AuthService.userHasAuthority("UPDATE_ANY_PROVIDER")
                            && AuthService.getStaffId() != this.props.staffId
                            && <FontAwesomeIcon icon={["fas", "edit"]} onClick={this.updateAdminPreference}/>}
                            {AuthService.userHasAuthority("UPDATE_SELF_PROVIDER")
                            && AuthService.getStaffId() == this.props.staffId
                            && <FontAwesomeIcon icon={["fas", "edit"]} onClick={this.updateStaffPreference}/>}
                        </div>

                        <GeneralStaffPreferences staffPreference={this.state.staffPreference}/>

                        <table id="dayPrefTable" className="table mt-3">

                            <thead>
                            <tr className={"text-center first-line align-middle"}>
                                <th colSpan={2} className={"bg-white"}></th>
                                <th colSpan={4}>Lunch Time</th>
                                <th colSpan={2}>Break Time</th>
                                <th rowSpan={2} width={100}>Number of Patient <br/> <small className={"text-muted"}>(Per
                                    Day)</small></th>
                                <th colSpan={2} className="pb-0">New Patient / New Complaint <br/><small
                                    className={"text-muted"}>(Per Day)</small></th>
                                <th colSpan={2}>Duration Of Session</th>
                            </tr>
                            <tr className={"second-line text-center"}>
                                <th className="merge-cell"></th>
                                <th>Start Time</th>
                                <th>Time</th>
                                <th>Duration</th>
                                <th>Time</th>
                                <th>Duration</th>
                                <th>AM</th>
                                <th>PM</th>
                                <th>%</th>
                                <th><span className={"font-weight-light"}>(% of) </span> Extra Time</th>
                                <th><FontAwesomeIcon className="new-complaint-color" icon={["fas", "circle"]}/> New
                                    Patient
                                </th>
                                <th><FontAwesomeIcon className="follow-up-color" icon={["fas", "circle"]}/> Follow Up
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <StaffDayPreferenceRow name="Sunday" staffPreference={this.state.staffPreference}
                                                   dayPreference={this.state.staffPreference.dayPreferences.find(item => item.dayOfWeek === "SUNDAY")}/>
                            <StaffDayPreferenceRow name="Monday" staffPreference={this.state.staffPreference}
                                                   dayPreference={this.state.staffPreference.dayPreferences.find(item => item.dayOfWeek === "MONDAY")}/>
                            <StaffDayPreferenceRow name="Tuesday" staffPreference={this.state.staffPreference}
                                                   dayPreference={this.state.staffPreference.dayPreferences.find(item => item.dayOfWeek === "TUESDAY")}/>
                            <StaffDayPreferenceRow name="Wednesday" staffPreference={this.state.staffPreference}
                                                   dayPreference={this.state.staffPreference.dayPreferences.find(item => item.dayOfWeek === "WEDNESDAY")}/>
                            <StaffDayPreferenceRow name="Thursday" staffPreference={this.state.staffPreference}
                                                   dayPreference={this.state.staffPreference.dayPreferences.find(item => item.dayOfWeek === "THURSDAY")}/>
                            <StaffDayPreferenceRow name="Friday" staffPreference={this.state.staffPreference}
                                                   dayPreference={this.state.staffPreference.dayPreferences.find(item => item.dayOfWeek === "FRIDAY")}/>
                            <StaffDayPreferenceRow name="Saturday" staffPreference={this.state.staffPreference}
                                                   dayPreference={this.state.staffPreference.dayPreferences.find(item => item.dayOfWeek === "SATURDAY")}/>
                            </tbody>
                        </table>

                    </Tab>
                    <Tab eventKey="schedule" title="My Schedule">
                        <StaffSchedule staffId={this.props.staffId} refresh={this.state.refreshStaffScheduleToggle}/>
                    </Tab>
                    {['DOCTOR', 'THERAPIST'].includes(this.props.staffType) &&
                        <Tab eventKey="calendar" title="Calendar">
                            {this.state.selectedTab === "calendar" && // This is to force re-render calendar view
                                <StaffPreferenceAppointmentCalendar staffId={this.props.staffId}
                                                                    staffName={this.props.staffName}/>
                            }
                        </Tab>}
                    <Tab eventKey="leavePolicy" title="Leave Policy">
                        <LeavePolicy staffId={this.props.staffId}/>
                    </Tab>
                </Tabs>
            </Card.Body>
            {
                this.state.updateStaffPreferenceVisible &&
                <UpdateStaffPreference staffId={this.props.staffId}
                                       closeAction={this.handleUpdateStaffPreferenceClose}
                                       intraDayAction={this.updateIntraDaySchedule}
                                       onStaffProfileUpdate={this.props.onStaffProfileUpdate}/>
            }
            {
                this.state.updateAdminPreferenceVisible &&
                <UpdateAdminPreference staffId={this.props.staffId}
                                       closeAction={this.handleUpdateAdminPreferenceClose}
                                       onStaffProfileUpdate={this.props.onStaffProfileUpdate}/>
            }
        </Card>
    }

}

export default StaffPreference;


class StaffDayPreferenceRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dayPreference: props.dayPreference ? props.dayPreference : {lunchBreak: {}},
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.dayPreference != this.props.dayPreference) {
            this.setState({dayPreference: this.props.dayPreference ? this.props.dayPreference : {}});
        }
    }

    render() {
        const {
            newPatient,
            followUp
        } = SchedulingService.calculateSessionDurations(this.props.staffPreference, this.state.dayPreference);
        return <>
            <tr className={"preference-day-row"}>
                <td className={"text-left"}>{this.props.name}</td>
                <td>
                    {this.state.dayPreference.startTime}
                </td>
                <td>
                    {this.state.dayPreference.lunchBreak ? this.state.dayPreference.lunchBreak.lunch1Time : null}
                </td>
                <td>
                    {this.state.dayPreference.lunchBreak ? this.state.dayPreference.lunchBreak.lunch1Duration : null}
                </td>
                <td>
                    {this.state.dayPreference.lunchBreak ? this.state.dayPreference.lunchBreak.lunch2Time : null}
                </td>
                <td>
                    {this.state.dayPreference.lunchBreak ? this.state.dayPreference.lunchBreak.lunch2Duration : null}
                </td>
                <td>
                    {EnumBreakTimeAM[this.state.dayPreference.breakTimeAM]}
                </td>
                <td>
                    {EnumBreakTimePM[this.state.dayPreference.breakTimePM]}
                </td>
                <td>
                    {this.state.dayPreference.noOfPatientPerDay}
                </td>
                <td>
                    %{this.state.dayPreference.percentageOfNewPatientPerDay}
                </td>
                <td>
                    %{this.state.dayPreference.percentageOfExtraTimeForPatient}
                </td>
                <td>{newPatient}</td>
                <td>{followUp}</td>
            </tr>
        </>
    }

}
