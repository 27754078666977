import React, {Component} from 'react';
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import {Dropdown} from "primereact/dropdown";
import IntakeFormReferenceTypeEnum from "./enum/IntakeFormReferenceTypeEnum";
import {AutoComplete} from "primereact/autocomplete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TextInput} from "../../../components";
import RemotingService from "../../../services/remoting-service/RemotingService";

class IntakeFormReferralInformation extends Component {

    state = {}

    referenceTypeTemplate = (option) => {
        let itemIcon = <div style={{marginLeft: "19px"}}></div>;

        if (option.value === IntakeFormReferenceTypeEnum.TWITTER.key) {
            itemIcon = (
                <FontAwesomeIcon
                    icon={["fab", "twitter"]}
                    style={{marginTop: "4px"}}
                    size={"lg"}
                />
            );
        } else if (option.value === IntakeFormReferenceTypeEnum.FACEBOOK.key) {
            itemIcon = (
                <FontAwesomeIcon
                    icon={["fab", "facebook"]}
                    style={{marginTop: "2px"}}
                    size={"lg"}
                />
            );
        } else if (option.value === IntakeFormReferenceTypeEnum.INSTAGRAM.key) {
            itemIcon = (
                <FontAwesomeIcon
                    icon={["fab", "instagram-square"]}
                    style={{marginLeft: "2px", marginTop: "2px"}}
                    size={"lg"}
                />
            );
        }

        return (
            <div className="d-flex">
                {itemIcon}
                <div className="mx-2">{option.label}</div>
            </div>
        );
    };

    getReferenceLabelFormAccordingToReferenceType(referenceType, selectedLanguage) {
        switch (referenceType) {
            case IntakeFormReferenceTypeEnum.FAMILYFRIENDS.key:
                return IntakeFormTextEnum.Family_Friend_Name[selectedLanguage];

            case IntakeFormReferenceTypeEnum.DRHOSPITAL.key:
                return IntakeFormTextEnum.Doctor_Name[selectedLanguage];

            case IntakeFormReferenceTypeEnum.INSTAGRAM.key:
            case IntakeFormReferenceTypeEnum.FACEBOOK.key:
            case IntakeFormReferenceTypeEnum.TWITTER.key:
                return IntakeFormTextEnum.Account_name[selectedLanguage];

            case IntakeFormReferenceTypeEnum.OTHER.key:
                return IntakeFormTextEnum.Other[selectedLanguage];

            default:
        }
    }

    staffNameSearchItemTemplate = (staff) => {
        return <>{staff.name + (staff.hospital ? " - " + staff.hospital  : "")}</>;
    };

    staffNameCompleteMethod(event) {
        setTimeout(() => {
            let name = event.query;
            let patientId = this.props.patientId;
            RemotingService.getRemoteCall(
                `api/patient/list-references?name=${name}&patientId=${patientId}`,
                null,
                (result) => {
                    this.setState({staffNameSuggestions: result});
                }
            );
        }, 250);
    }

    handleStaffSearchResultSelect = (event) => {
        let staff = event.value;

        this.props.onFieldGroupChanged({
            referenceName: staff.name,
            referenceHospital: staff.hospital,
            autoCompleted: true,
        });
    };

    deselectStaffSearchResult = () => {
        this.props.onFieldGroupChanged({
            referenceName: "",
            referenceHospital: "",
            autoCompleted: false,
        });
    };

    render() {
        const {
            selectedLanguage, readOnly, enumLabel, onChange, onFieldGroupChanged,
            referenceType, referenceName, autoCompleted, referenceHospital
        } = this.props

        return (

            <div className="container dim14-form-row-align">
                <h3 className="text-center mb-3">
                    <b>{IntakeFormTextEnum.Referral_Information[selectedLanguage]}</b>
                </h3>
                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.How_Did_You_Hear_About_The_Clinic[selectedLanguage]}
                    </div>
                    <div className="col-8">
                        <Dropdown
                            className={`reference-dropdown DropdownBorderColor w-100 ${
                                readOnly
                                    ? "DropdownDisabledBackground"
                                    : "DropdownBorderColor"
                            }`}
                            value={referenceType}
                            disabled={readOnly}
                            options={IntakeFormReferenceTypeEnum.toArray().map(v => {
                                return {value: v.key, label: v[enumLabel]}
                            })}
                            showClear
                            itemTemplate={this.referenceTypeTemplate}
                            displayProperty="label"
                            valueProperty="value"
                            onChange={(event) => {
                                onFieldGroupChanged({
                                    referenceName: "",
                                    referenceHospital: "",
                                    referenceSubject: "",
                                    referenceType: event.value,
                                })
                            }}
                        />
                    </div>
                </div>
                {referenceType ? (
                    <div className="row">
                        <div className="col-4 dim14-form-label">
                            {this.getReferenceLabelFormAccordingToReferenceType(
                                referenceType, selectedLanguage
                            )}
                        </div>
                        <div className="col-8">
                            {referenceType === IntakeFormReferenceTypeEnum.DRHOSPITAL.key ? (
                                <div className="d-flex">
                                    <AutoComplete
                                        id={"referenceNameAutoComplete"}
                                        value={referenceName}
                                        placeholder={IntakeFormTextEnum.Doctor_Name[selectedLanguage]}
                                        onChange={(e) => {
                                            onChange("referenceName", e.value)
                                        }}
                                        suggestions={this.state.staffNameSuggestions}
                                        itemTemplate={this.staffNameSearchItemTemplate}
                                        completeMethod={this.staffNameCompleteMethod.bind(
                                            this
                                        )}
                                        onSelect={this.handleStaffSearchResultSelect}
                                        disabled={autoCompleted || readOnly}
                                        className="AddReferenceAutoComplete"
                                        style={{minWidth: "100px"}}
                                    />
                                    {autoCompleted ? (
                                        <FontAwesomeIcon
                                            icon={["fas", "times-circle"]}
                                            size={"2x"}
                                            color={"red"}
                                            style={{
                                                marginTop: "5px",
                                                marginLeft: "5px",
                                            }}
                                            onClick={() => this.deselectStaffSearchResult()}
                                        />
                                    ) : null}
                                </div>
                            ) : (
                                <TextInput
                                    className="form-control"
                                    value={referenceName}
                                    disabled={readOnly}
                                    placeholder={
                                        this.getReferenceLabelFormAccordingToReferenceType(
                                            referenceType, selectedLanguage
                                        )
                                    }
                                    onChange={(value) =>
                                        onChange("referenceName", value)
                                    }
                                />
                            )}
                        </div>
                    </div>
                ) : null}
                {referenceType === IntakeFormReferenceTypeEnum.DRHOSPITAL.key ? (
                    <div className="row">
                        <div
                            className="col-4 dim14-form-label">{IntakeFormTextEnum.Hospital_Name[selectedLanguage]}</div>

                        <div className="col-8">
                            <div className="d-flex">
                                <AutoComplete
                                    id={"referenceHospitalAutoComplete"}
                                    value={referenceHospital}
                                    placeholder={IntakeFormTextEnum.Hospital_Name[selectedLanguage]}
                                    onChange={(e) => {
                                        onChange("referenceHospital", e.value)
                                    }}
                                    disabled={autoCompleted || readOnly}
                                    className="AddReferenceAutoComplete"
                                />
                                {autoCompleted ? (
                                    <FontAwesomeIcon
                                        icon={["fas", "times-circle"]}
                                        size={"2x"}
                                        color={"red"}
                                        style={{marginTop: "5px", marginLeft: "5px"}}
                                        onClick={() => this.deselectStaffSearchResult()}
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default IntakeFormReferralInformation;