import React, {Component} from 'react';
import Appointment from "../../../appointment/Appointment";

class StaffPreferenceAppointmentCalendar extends Component {

    render() {
        return (
            <div style={{
                overflowY: "auto",
                marginTop: 10
            }}>
                <Appointment
                    isStaffPreferencePage
                    location={{search: `selectedStaff=${this.props.staffId}&selectedView=week&filterHidden=true`}}
                />
            </div>
        );
    }
}

export default StaffPreferenceAppointmentCalendar;
