import RemotingService from "../remoting-service/RemotingService";
import {noop} from "lodash-es";
import {isEmpty} from "../../utils/ArrayUtil";
import confirmDialog from "../../components/dialog/ConfirmDialog";
import React from "react";

function getSchedules(callback) {
    RemotingService.getRemoteCall('api/scheduling-secure/schedule', null, (schedules) => {
        callback(schedules);
    }, noop, false);
}

function getScheduleJob(callback) {
    RemotingService.getRemoteCall('api/scheduling-secure/schedule-job', null, (job) => {
        callback(job);
    });
}

function createScheduleJob(startDate, callback) {
    RemotingService.postRemoteCall('api/scheduling-secure/schedule-job', {startDate}, (job) => {
        callback(job);
    });
}

function confirmScheduleJob(jobId, force, callback) {
    const messageCallback = messages => {
        const manualRotationErrors = RemotingService.filterMessagesByCode(messages, "MANUAL_ROTATIONS_EXISTS");

        if (isEmpty(manualRotationErrors)) {
            const errorMessages = RemotingService.extractMessages(messages, "ERROR");
            const infoMessages = RemotingService.extractMessages(messages, "INFO");
            const warningMessages = RemotingService.extractMessages(messages, "WARNING");

            RemotingService.showErrors(errorMessages);
            RemotingService.showInfos(infoMessages);
            RemotingService.showWarnings(warningMessages);
        } else {
            const confirmationText =
                <div style={{width: 600}} className="d-flex flex-column">
                    <div className="px-2 mb-2" style={{whiteSpace: "pre-wrap"}}>
                        {manualRotationErrors.map(error => <span>{error.text}</span>)}
                    </div>
                    <span>These rotations will be deleted and (if there any) extra day leaves will be rejected.</span>
                    <span>Do you want to continue anyway?</span>
                </div>;

            confirmDialog("Confirm Schedule", confirmationText, () => confirmScheduleJob(jobId, true, callback));
        }
    };

    RemotingService.postRemoteCall(`api/scheduling-secure/schedule-job/${jobId}/confirm?force=${force}`,
        null, (job) => callback(job), noop, true, false, messageCallback);
}

function rejectScheduleJob(jobId, callback) {
    RemotingService.postRemoteCall(`api/scheduling-secure/schedule-job/${jobId}/reject`, null, (job) => {
        callback(job);
    });
}

function calculateSessionDurations(staffPreference, dayPreference) {
    const {workingHoursPerDay} = staffPreference;
    const {
        noOfPatientPerDay: noOfPatientsPerDay,
        percentageOfNewPatientPerDay: percentageOfNewPatientsPerDay,
        percentageOfExtraTimeForPatient: percentageOfExtraTimeForNewPatient
    } = dayPreference;

    const timeSlotOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

    const workDuration = workingHoursPerDay * 60;
    const targetRatio = 100 / (100 + percentageOfExtraTimeForNewPatient);
    const noOfNewPatients = Math.round(noOfPatientsPerDay * percentageOfNewPatientsPerDay / 100 + 0.01);
    let durationReturn = 0, durationNew = 0;
    let fitness = 10000;

    var i;
    var patientOptions = []
    if ((noOfNewPatients == 0) || (noOfNewPatients == noOfPatientsPerDay)) {
        patientOptions.push([noOfPatientsPerDay - noOfNewPatients, noOfNewPatients]);
    } else {
        for (i = noOfNewPatients - 1; i <= noOfNewPatients + 1; i++) {
            if (0 <= i <= noOfPatientsPerDay) {
                patientOptions.push([noOfPatientsPerDay - i, i]);
            }
        }
    }

    timeSlotOptions.forEach(optReturn => {
        timeSlotOptions.forEach(optNew => {
            patientOptions.forEach(optPat => {
                // either   : percentageOfExtraTimeForNewPatient=0 and optReturn == optNew
                // otherwise: optReturn <= optNew
                if ((percentageOfExtraTimeForNewPatient == 0 && optReturn == optNew) || (percentageOfExtraTimeForNewPatient != 0 && optReturn <= optNew)) {
                    const incumbentRatio = optReturn / optNew;
                    const ratioDiff = Math.abs(incumbentRatio - targetRatio);

                    const nbReturn = optPat[0];
                    const nbNew = optPat[1];
                    const incumbentTotalDuration = optReturn * nbReturn + optNew * nbNew;
                    const newFitness = Math.abs(-ratioDiff) + Math.abs(incumbentTotalDuration - workDuration);

                    if (incumbentTotalDuration <= workDuration && newFitness < fitness) {
                        fitness = newFitness;
                        durationReturn = optReturn;
                        durationNew = optNew;
                    }
                }
            })
        })
    })
    return {followUp: durationReturn, newPatient: durationNew};
}

export default {
    getSchedules,
    getScheduleJob,
    createScheduleJob,
    confirmScheduleJob,
    rejectScheduleJob,
    calculateSessionDurations
};
