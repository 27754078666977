import React, {Component} from 'react';
import DateUtil from "../../utils/DateUtil";
import RemotingService from "../../services/remoting-service/RemotingService";
import InsuranceCardStatus from "./InsuranceCardStatus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AddInsurance from "./AddInsurance";
import './InsuranceInformation.css'
import EnumCoPayUnit from "./enums/EnumCoPayUnit";
import ActionMenu from "../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../components/action-menu/ActionMenuItem";
import ViewIcon from "../../components/action-menu/icons/ViewIcon";
import ViewInsurance from "./ViewInsurance";
import {Button} from "react-bootstrap";

class InsuranceInformation extends Component {

    constructor(props) {
        super(props);
        this.state = {
            insurance: null,
            addInsuranceModalVisible: false,
            viewInsuranceModalVisible: false
        };
    }

    componentDidMount() {
        this.retrieveInsurance();
    }

    retrieveInsurance = () => {
        RemotingService.getRemoteCall(`api/insurance/${this.props.patientId}/insurance`, null, (insurance) => {
            if (insurance && insurance.validityFrom) {
                insurance.validityFrom = DateUtil.moment(insurance.validityFrom).toDate();
            }
            if (insurance && insurance.validityTo) {
                insurance.validityTo = DateUtil.moment(insurance.validityTo).toDate();
            }
            this.setState({insurance})
        });
    }

    render() {
        return (
            <>
                <div className="card insurance-panel" style={{minWidth:"400px", maxWidth:"1300px"}}>
                    <div className="card-header py-2 align-items-center">
                        <div className="card-header-title">
                            <strong style={{verticalAlign: "middle"}}>Insurance</strong>
                        </div>
                        <div className="card-header-actions" style={{display: 'flex'}}>
                            <InsuranceCardStatus patientId={this.props.patientId}
                                                 editable={this.props.editable}/>
                            <div className="card-header-actions">
                                {this.props.editable &&
                                    <Button variant={"primary"} size={"sm"} className={'w-100 h-100'}
                                            onClick={() => this.showAddInsuranceModal()}
                                            style={{
                                                marginRight: '10px',
                                                padding: '0.2rem .5rem',
                                                borderRadius: '0'
                                            }}>
                                        Edit
                                    </Button>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="card-body line-wrapper small-font">
                        <div className="row">
                            <div className="col-6 d-flex pr-0 w-100">
                                <div className="col-3">TPA</div>
                                <div className="col-3">Insurance</div>
                                <div className="col-3">Network</div>
                                <div className="col-3">Policy No</div>
                            </div>
                            <div className="col-6 d-flex p-0 w-100">
                                <div className="col-4">Member ID</div>
                                <div className="col-3">Validity</div>
                                <div className="col-3" style={{whiteSpace: "nowrap"}}>Co-Pay</div>
                                <div className="col-2 d-flex p-justify-center">Actions</div>
                            </div>
                        </div>
                        <hr />
                        {this.renderInsurance()}
                    </div>
                </div>

                {this.state.addInsuranceModalVisible &&
                <AddInsurance patientId={this.props.patientId}
                              insurance={this.state.insurance}
                              onInsuranceAdded={this.onInsuranceAdded}
                              closeAction={() =>
                                  this.setState({addInsuranceModalVisible: false})
                              }/>}

                {this.state.viewInsuranceModalVisible &&
                <ViewInsurance insuranceId={this.state.selectedInsuranceId}
                               patientId={this.props.patientId}
                               closeAction={() =>
                                   this.setState({viewInsuranceModalVisible: false})
                               }/>}
            </>
        );
    }

    renderInsurance() {
        return this.state.insurance ?
            <InsuranceRow insurance={this.state.insurance}
                          retrieveInsurance={this.retrieveInsurance}
                          viewAction={insuranceId => this.showViewInsuranceModal(insuranceId)}/>
            :
            <div className="row">
                <div className="col-12">
                    No record found.
                </div>
            </div>
    }

    showAddInsuranceModal() {
        this.setState({
            addInsuranceModalVisible: true
        });
    }

    showViewInsuranceModal(insuranceId) {
        this.setState({
            selectedInsuranceId: insuranceId,
            viewInsuranceModalVisible: true
        });
    }

    onInsuranceAdded = (insurance) => {
        this.setState({
            addInsuranceModalVisible: false,
            insurance: insurance
        });
    }
}

class InsuranceRow extends Component {

    render() {
        const insurance = this.props.insurance;
        const validityFrom = insurance.validityFrom ? DateUtil.formatDateMMM(insurance.validityFrom) : '';
        const validityTo = insurance.validityTo ? DateUtil.formatDateMMM(insurance.validityTo) : '';

        return (
            <div className="row">
                <div className="col-6 d-flex pr-0 w-100">
                    <div className="col-3" style={{whiteSpace: "pre-wrap"}}>{this.tpaTemplate(insurance)}</div>
                    <div className="col-3" style={{whiteSpace: "pre-wrap"}}>{insurance.company ? insurance.company.name : insurance.customCompany}</div>
                    <div className="col-3" style={{whiteSpace: "pre-wrap"}}>{insurance.network ? insurance.network.name : insurance.customNetwork}</div>
                    <div className="col-3" style={{whiteSpace: "pre-wrap"}}>{insurance.policyNumber}</div>
                </div>
                <div className="col-6 d-flex p-0 w-100">
                    <div className="col-4 text-wrap">{insurance.memberId}</div>
                    <div className="col-3" style={{whiteSpace: "pre-wrap"}}>{validityFrom} to {validityTo}</div>
                    <div className="col-3" style={{whiteSpace: "pre-wrap"}}>{insurance.coPay} {EnumCoPayUnit[insurance.coPayUnit]}
                        {insurance.coPayMax ? ` (Max: ${insurance.coPayMax})` : ''}</div>
                    <div className="col-2 d-flex p-justify-center">{this.actionsTemplate(insurance)}</div>
                </div>
            </div>
        );
    }

    tpaTemplate(insurance) {
        if (insurance.tpa) {
            return insurance.tpa.name;
        } else if (insurance.insurer) {
            return insurance.insurer.name;
        } else if (insurance.customTpa) {
            return insurance.customTpa;
        } else {
            return "";
        }
    }

    actionsTemplate = (insurance) => {
        return <ActionMenu id="insuranceActionMenu">
                    <ActionMenuItem text="View"
                                    onClick={() => this.props.viewAction(insurance.id)}
                                    icon={<ViewIcon/>}/>
                    <ActionMenuItem text="Sync"
                                    disabled={insurance.type === "MANUAL"}
                                    onClick={() => this.sync(insurance.id)}
                                    icon={<FontAwesomeIcon icon={["fas", "sync-alt"]}/>} />
                </ActionMenu>
    }

    sync = (insuranceId) => {
        RemotingService.postRemoteCall(`api/insurance/${insuranceId}/sync`, null, () => {
            this.props.retrieveInsurance();
        });
    }

}

export default InsuranceInformation;
