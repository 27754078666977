import RemotingService from '../remoting-service/RemotingService';
import NotificationService from '../notification-service/NotificationService';

const checkForTimeDifferenceFromServer = () => {
    RemotingService.getRemoteCall('/api/server/time', null, (serverTime) => {
        let serverTimeUtc = new Date(serverTime).getTime();
        let now = new Date();
        let browserTimeUtc = now.getTime() + (now.getTimezoneOffset() * 60000);

        let serverTimeUtcToMinutes = Math.trunc(serverTimeUtc / 60000);
        let browserTimeUtcToMinutes = Math.trunc(browserTimeUtc / 60000);

        if (serverTimeUtcToMinutes < (browserTimeUtcToMinutes - 5)) {
            let diff = browserTimeUtcToMinutes - serverTimeUtcToMinutes;
            NotificationService.showNotification({
                sticky: true,
                severity: 'warn',
                summary: 'Time Problem',
                detail: `Local time of device is ${diff} minutes ahead of server. Please check your local time of your device.`
            });

        } else if (serverTimeUtcToMinutes > (browserTimeUtcToMinutes + 5)) {
            let diff = serverTimeUtcToMinutes - browserTimeUtcToMinutes;
            NotificationService.showNotification({
                sticky: true,
                severity: 'warn',
                summary: 'Time Problem',
                detail: `Local time of device is ${diff} minutes ahead of server. Please check your local time of your device.`
            });

        } else {
            //NotificationService.showNotification({severity: 'success', summary: 'Time Synced', detail: 'Local time of device is synced with server.'});
        }

    });

};

export {checkForTimeDifferenceFromServer};

