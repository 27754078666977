import React, {Component} from "react";

export default class CovidGap extends Component {

    render() {
        const  {start, end, timeZone} = this.props;

        return (
            <div className="calendar-event"
                 style={{
                     height: 18,
                     flexDirection: 'row',
                     justifyContent: 'space-between',
                     fontSize: 'small',
                     paddingTop: 2,
                     paddingLeft: 4,
                     paddingRight: 4,
                     border: 'none',
                     color: '#787878',
                     background: 'repeating-linear-gradient(120deg, rgba(200,200,200,0.5), rgba(200,200,200,0.5) 5px, transparent 5px, transparent 6px)'
                 }}>
                <div>
                    <b>EMR Doc.</b>
                </div>
                <div>
                    {start.tz(timeZone).format("HH:mm")} - {end.tz(timeZone).format("HH:mm")}
                </div>
            </div>
        )
    }

}
