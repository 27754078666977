import React, {Component} from "react";
import SeverityEnum from "../../assessment/enums/observation/SeverityEnum";
import ObservationFindingEnum from "../../assessment/enums/observation/ObservationFindingEnum";
import PalpationLocationEnum from "../../assessment/enums/palpation/PalpationLocationEnum";
import PalpationFindingEnum from "../../assessment/enums/palpation/PalpationFindingEnum";
import ReportViewUtils from "./ReportViewUtils";
import * as ArrayUtils from "../../../../../utils/ArrayUtil";
import StringUtil from "../../../../../utils/StringUtil";

export default class AssessmentObservationPalpationReportView extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }


    getObservationSummary = (observation) => {
        if (!observation || (ArrayUtils.isEmpty(observation.items) && observation.none == false)) {
            return null;
        }

        if(observation.none){
            return "Normal";
        }

        return observation.items
            .map(item =>
                [
                    (item.finding == null ? null : ObservationFindingEnum[item.finding].name),
                    (item.severity == null ? null : SeverityEnum[item.severity].name),
                    item.location,
                    (item.details == null ? null : item.details)
                ].filter(Boolean).join(", ")
            )
            .join('. ');
    }

    getPalpationSummary = (palpation) => {
        if (!palpation || (ArrayUtils.isEmpty(palpation.items) && palpation.none == false)) {
            return null;
        }

        if(palpation.none){
            return "Normal";
        }

        return palpation.items
            .map(item =>
                [
                    item.area,
                    [
                        item.locationRight != null ? ("Right-" + PalpationLocationEnum[item.locationRight]?.name) : null,
                        item.locationLeft != null ? ("Left-" + PalpationLocationEnum[item.locationLeft]?.name) : null
                    ].filter(Boolean).join(", "),
                    item.finding != null ? PalpationFindingEnum[item.finding].name : null,
                    item.pain ? item.pain + '/10' : null,
                    (StringUtil.isNullOrEmpty(item.detail) ? null : item.detail)
                ].filter(Boolean).join(", ")
            )
            .join('. ');
    }

    render() {
        const assessment = this.props.assesment;

        return (
            <div className={"container ml-0"}>
                {!ReportViewUtils.isAssessmentEntityNull(assessment.observation) && <div className={"row"}>
                    <div className={"col-2"}>
                        <b>Observation</b>
                    </div>
                    <div className={"col-10"}>
                        : {this.getObservationSummary(assessment.observation)}
                    </div>
                </div>}
                {!ReportViewUtils.isAssessmentEntityNull(assessment.palpation) && <div className={"row"}>
                    <div className={"col-2"}>
                        <b>Palpation</b>
                    </div>
                    <div className={"col-10"}>
                        : {this.getPalpationSummary(assessment.palpation)}
                    </div>
                </div>}
            </div>
        );
    }

}
