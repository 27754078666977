import Enum from "../../../../../../enums/Enum";

const MyotomesCervicalEnum = new Enum({
    M_C1_C2: {value: "C1/C2 - Neck Flexion"},
    M_C3: {value: "C3 - Lateral Flexion of Neck"},
    M_C4: {value: "C4 - Shoulder Elevation"},
    M_C5: {value: "C5 - Shoulder Abduction"},
    M_C6: {value: "C6 - Elbox Flexion and/or Wrist Extension"},
    M_C7: {value: "C7 - Elbox Extension and/or Wrist Flexion"},
    M_C8: {value: "C8 - Finger Flexion"},
});

export default MyotomesCervicalEnum;