import React, {Component} from 'react';
import IntakeFormTextEnum from "../enum/IntakeFormTextEnum";

class PublicSymptomsInterfereWith extends Component {
    render() {
        const {form, selectedLanguage, checkBoxField, validatedTextField} = this.props;

        return (
            <>
                <div className="row">
                    <label className="col-form-label">
                        {IntakeFormTextEnum.Symptoms_Interfere_With[selectedLanguage]}
                    </label>
                </div>
                <div className="row">
                    <div className="col-12">
                        {checkBoxField(form, "interfereWithSleep", IntakeFormTextEnum.Sleep[selectedLanguage])}
                        {checkBoxField(form, "interfereWithWork", IntakeFormTextEnum.Work[selectedLanguage])}
                        {checkBoxField(form, "interfereWithRest", IntakeFormTextEnum.Rest[selectedLanguage])}
                        {checkBoxField(
                            form,
                            "interfereWithDailyRoutine",
                            IntakeFormTextEnum.Daily_Routine[selectedLanguage]
                        )}
                        {checkBoxField(
                            form,
                            "interfereWithSports",
                            IntakeFormTextEnum.Sports_Exercise[selectedLanguage]
                        )}
                        {checkBoxField(form, "interfereWithOther", IntakeFormTextEnum.Symptoms_Other[selectedLanguage])}
                    </div>
                </div>
                {form.interfereWithOther && (
                    <div className="row">
                        <div className="container-fluid p-0">
                            <div className="panel bg-grey px-3 py-0 mb-3">
                                <div className="form-group">
                                    <label
                                        className="col-form-label">{IntakeFormTextEnum.Other[selectedLanguage]}</label>
                                    {validatedTextField(form, "interfereWithOtherDetail")}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default PublicSymptomsInterfereWith;