import React, { Component } from "react";
import './PosNegRadioInput.css';

export default class PosNegRadioInput extends Component {

    render() {
        return (
            <div className={"pos-neg-container"}>
                <div className={"row no-margin d-flex align-items-center justify-content-center h-100"}>
                    {(this.props.readOnly == null || this.props.readOnly == false) && this.edibleMode()}
                    {this.props.readOnly && this.readOnlyMode()}
                </div>
            </div>);
    }

    edibleMode = () => {
        return (
            <>
            {this.props.value != true &&
            <div className={"col-6 p-0 pointer w-100 h-100 d-flex justify-content-center align-items-center"} onClick={this.posClicked}>
                ⚪ Pos
            </div>
            }
            {this.props.value == true &&
            <div className={"col-6 p-0 pos-selected pointer w-100 h-100 d-flex justify-content-center align-items-center"} onClick={this.posClicked}>
                ⚫ Pos
            </div>
            }

            {this.props.value != false &&
            <div className={"col-6 p-0 pointer w-100 h-100 d-flex justify-content-center align-items-center"} onClick={this.negClicked}>
                ⚪ Neg
            </div>
            }
            {this.props.value == false &&
            <div className={"col-6 p-0 neg-selected pointer w-100 h-100 d-flex justify-content-center align-items-center"} onClick={this.negClicked}>
                ⚫ Neg
            </div>
            }
            </>);
    }

    readOnlyMode = () => {
        if(this.props.value == null) {
            return (<></>);
        }

        if(this.props.value) {
            return (
                <div className={"col-12 p-0 pos-selected w-100 h-100 d-flex justify-content-center align-items-center"} onClick={this.posClicked}>
                    ⚫ Pos
                </div>
            );
        }

        return (
                <div className={"col-12 p-0 neg-selected w-100 h-100 d-flex justify-content-center align-items-center"} onClick={this.negClicked}>
                    ⚫ Neg
                </div>
        );

    }

    posClicked = () => {
        if(this.props.onChange) {
            this.props.onChange(this.props.value != true ? true : null); // If the same button clicked again, we changed it as unselected
        }
    }

    negClicked = () => {
        if(this.props.onChange) {
            this.props.onChange(this.props.value != false ? false : null); // If the same button clicked again, we changed it as unselected
        }
    }
}
