import React, {Component} from "react";
import moment from "moment";
import {DialogActions} from "@material-ui/core";
import {Button} from "react-bootstrap";
import TimeInput from "../../../../components/date-input/TimeInput";
import {Dropdown} from "primereact/dropdown";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import {FormField} from "../../../../components/form";
import DateUtil from "../../../../utils/DateUtil";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import PropTypes from "prop-types";
import RotationBlockRemoteService from "../../../appointment/RotationBlockRemoteService";

export class UpdateRotationBlockModal extends Component {

    static propTypes = {
        blockEvent: PropTypes.object,
        type: PropTypes.string,
        timezone: PropTypes.string,
        onSave: PropTypes.func
    };

    constructor(props) {
        super(props);
        const offsetDiff = this.getOffsetDiff(props.timezone);
        this.state = {
            offsetDiff: offsetDiff,
            start: DateUtil.addMinutes(props.blockEvent.start, offsetDiff), // TimeInput works with local timezone only
            end: DateUtil.addMinutes(props.blockEvent.end, offsetDiff)
        };
    }

    componentDidMount() {
        this.retrieveAvailableRooms();
    }

    retrieveAvailableRooms() {
        const params = {
            startDate: DateUtil.formatDate(this.state.start, "YYYY-MM-DD"),
            endDate: DateUtil.formatDate(this.state.end, "YYYY-MM-DD")
        };

        RemotingService.getRemoteCall('api/room/available-rooms', params, (availableRooms) => {
            this.setState({rooms: availableRooms.map(ar => ar.room)});
        });
    }

    getOffsetDiff(timezone) {
        const localOffset = moment().utcOffset();
        const timezoneOffset = moment.tz(new Date(), timezone).utcOffset();

        return timezoneOffset - localOffset;
    }

    save() {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        const event = {
            ...this.props.blockEvent,
            room: this.state.room
        };

        RotationBlockRemoteService.updateBlockType(event, this.props.type.key, () => {
            this.props.close();
            this.props.onSave();
        });
    }

    render() {
        this.formFields = [];

        return (
            <>
                <div className="container" style={{width: 400}}>
                    <div className="row">
                        <div className="col-5">Start</div>
                        <div className="col-7">
                            <TimeInput stepMinute={5}
                                       viewDate={this.state.start}
                                       disabled
                                       value={this.state.start}
                                       onChange={e => this.setState({start: e.value})}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5">End</div>
                        <div className="col-7">
                            <TimeInput stepMinute={5}
                                       viewDate={this.state.end}
                                       disabled
                                       value={this.state.end}
                                       onChange={e => this.setState({end: e.value})}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5">Type</div>
                        <div className="col-7">
                            <Dropdown disabled optionLabel="name" optionValue="key"
                                      options={[this.props.type]}
                                      value={this.props.type.key}
                                      onChange={e => this.setState({type: e.value})}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5">Room</div>
                        <div className="col-7">
                            <FormField ref={formField => this.formFields.push(formField)}
                                       required validateOn={this.state.room}>
                                <Dropdown optionLabel="info"
                                          options={this.state.rooms}
                                          value={this.state.room}
                                          appendTo={document.body}
                                          onChange={e => this.setState({room: e.value})}/>
                            </FormField>
                        </div>
                    </div>
                </div>
                <DialogActions>
                    <Button variant="success" onClick={() => this.save()}>Save</Button>
                </DialogActions>
            </>
        );
    }

}