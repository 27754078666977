import {GenericChecker} from "../../subjective/GenericChecker";
import * as ArrayUtils from "../../../../../utils/ArrayUtil";

export class RiskOfHarmChecker extends GenericChecker {
    static isDataEntered(entity) {
        if (entity == null) {
            return false;
        }
        return !ArrayUtils.isEmpty(entity.whatRisk)
            || !ArrayUtils.isEmpty(entity.patientInformedDetail)
            || !ArrayUtils.isEmpty(entity.patientGiveConsentDetail)
            || !ArrayUtils.isEmpty(entity.precautionsNecessaryDetail)
            || !ArrayUtils.isEmpty(entity.hasPatientInformed)
            || !ArrayUtils.isEmpty(entity.didPatientGiveConsent)
            || !ArrayUtils.isEmpty(entity.arePrecautionsNecessary);
    }

    static isNone(entity) {
        return entity == null ? false : entity.isAllGood == true;
    }
}