import React, {Component} from "react";
import "../../visithistory/VisitHistory.css";
import CurrentPreviousEnum from "../../subjective/enums/complaint/CurrentPreviousEnum";
import DurationEnum from "../../subjective/enums/complaint/DurationEnum";
import CharacterEnum from "../../subjective/enums/complaint/CharacterEnum";
import AggravatingFactorEnum from "../../subjective/enums/complaint/AggravatingFactorEnum";
import RelievingFactorsEnum from "../../subjective/enums/complaint/RelievingFactorsEnum";
import RedFlagEnum from "../../subjective/enums/complaint/RedFlagEnum";
import EtiologyEnum from "../../subjective/enums/complaint/EtiologyEnum";
import DurationTimeEnum from "../../subjective/enums/complaint/DurationTimeEnum";
import DateUtil from "../../../../../utils/DateUtil";
import ReportViewUtils from "./ReportViewUtils";
import OnsetEnum from "../../subjective/enums/complaint/OnsetEnum";
import DescriptionEnum from "../../subjective/enums/complaint/DescriptionEnum";
import FrequencyEnum from "../../subjective/enums/complaint/FrequencyEnum";
import TimingEnum from "../../subjective/enums/complaint/TimingEnum";
import TimingSensitivityEnum from "../../subjective/enums/complaint/TimingSensitivityEnum";

export default class ComplaintReportView extends Component {

    render() {
        const complaint = this.props.complaint;

        if (ReportViewUtils.isComplaintNull(complaint)) {
            return null;
        }

        const text = (complaint.complaint || '') + ' ' + (complaint.secondaryComplaint?.name || '');

        let durationText = 'NA';
        if (complaint.durationType != null || complaint.durationTime != null
            || complaint.durationTimeEnum != null) {

            let durationTimeEnumStr = "";

            if (complaint.durationNumber > 1 && complaint.durationTimeEnum != null) {
                durationTimeEnumStr =
                    String(complaint.durationNumber) + " " + DurationTimeEnum[complaint.durationTimeEnum].name + "s, "
            } else if (complaint.durationNumber === 1 && complaint.durationTimeEnum != null) {
                durationTimeEnumStr =
                    String(complaint.durationNumber) + " " + DurationTimeEnum[complaint.durationTimeEnum].name + ", "
            }

            let text = complaint.durationType == null ? durationTimeEnumStr
                : (DurationEnum[complaint.durationType]?.name + (durationTimeEnumStr === "" ? "" : (", " + durationTimeEnumStr)));

            text = complaint.durationTime == null
                ? text : (text === "" ? DateUtil.formatDateMMM(complaint.durationTime)
                    : (text + " " + DateUtil.formatDateMMM(complaint.durationTime) + " "));

            durationText = text;
        }

        return (
            <div style={{paddingLeft: 10}}>
                <div className="row">
                    <div className={"col-12"}>
                        {text}
                    </div>
                </div>
                <div className={"row"} style={{paddingLeft: 10}}>
                    <div className={"col-4"}>
                        <div className={"row"}>
                            <div className={"col-3"}>
                                <b>Episode</b>
                            </div>
                            <div className={"col-9"}>
                                : {complaint.currentPrevious ? CurrentPreviousEnum[complaint.currentPrevious].name : 'NA'}
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-3"}>
                                <b>Onset</b>
                            </div>
                            <div className={"col-9"}>
                                : {complaint.onset ? OnsetEnum[complaint.onset].name : 'NA'}
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-3"}>
                                <b>Description</b>
                            </div>
                            <div className={"col-9"}>
                                : {complaint.description ? DescriptionEnum[complaint.description].name : 'NA'}
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-3"}>
                                <b>Frequency</b>
                            </div>
                            <div className={"col-9"}>
                                : {(complaint.frequency.length > 0 || complaint.frequencyDetail != null) ?
                                this.enumString(FrequencyEnum, complaint.frequency, complaint.frequencyDetail) : 'NA'}
                            </div>
                        </div>
                    </div>
                    <div className={"col-4"}>
                        <div className={"row"}>
                            <div className={"col-4"}>
                                <b>Etiology</b>
                            </div>
                            <div className={"col-8"}>
                                : {(complaint.etiology.length > 0
                                || complaint.etiologyDetail != null) ?
                                this.enumString(EtiologyEnum, complaint.etiology, complaint.etiologyDetail) : 'NA'}
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-4"}>
                                <b>History Of Trauma</b>
                            </div>
                            <div className={"col-8"}>
                                : {complaint.historyOfTrauma ? "Yes" : "No"}
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-4"}>
                                <b>Duration</b>
                            </div>
                            <div className={"col-8"}>
                                : {durationText}
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-4"}>
                                <b>Timing</b>
                            </div>
                            <div className={"col-8"}>
                                : {(complaint.timing.length > 0 || complaint.timingDetail != null || complaint.timingSensitivity.length > 0) ?
                                (complaint.timing && complaint.timing.length > 0 ? this.enumString(TimingEnum, complaint.timing, complaint.timingDetail) : "") +
                                (complaint.timingSensitivity && complaint.timingSensitivity.length > 0 ? "(" + this.enumString(TimingSensitivityEnum, complaint.timingSensitivity, null) + ")" : "")
                                : 'NA'}
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-4"}>
                                <b>Character</b>
                            </div>
                            <div className={"col-8"}>
                                : {(complaint.character.length > 0 || complaint.characterDetail != null) ?
                                this.enumString(CharacterEnum, complaint.character, complaint.characterDetail) : 'NA'}
                            </div>
                        </div>

                    </div>
                    <div className={"col-4"}>
                        <div className={"row"}>
                            <div className={"col-4"}>
                                <b>Aggravating Factors</b>
                            </div>
                            <div className={"col-8"}>
                                : {complaint.aggravatingFactorNormal ? 'No Aggravating Factors' : (complaint.aggravatingFactor.length > 0
                                || (complaint.aggravatingFactorDetail != null
                                    && complaint.aggravatingFactorDetail !== "")) ?
                                this.enumString(AggravatingFactorEnum, complaint.aggravatingFactor, complaint.aggravatingFactorDetail) : 'NA'}
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-4"}>
                                <b>Relieving Factors</b>
                            </div>
                            <div className={"col-8"}>
                                : {complaint.relievingFactorsNormal ? 'No Relieving Factors' : ((complaint.relievingFactors.length > 0
                                    || (complaint.relievingFactorsDetail != null
                                        && complaint.relievingFactorsDetail !== "")) ?
                                    this.enumString(RelievingFactorsEnum, complaint.relievingFactors, complaint.relievingFactorsDetail) : 'NA')}
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col-4"}>
                                <b>Red Flag</b>
                            </div>
                            <div className={"col-8"}>
                                : {complaint.redFlagNormal ? 'No Red Flags' : ((complaint.redFlag.length > 0
                                    || (complaint.redFlagDetail != null
                                        && complaint.redFlagDetail !== "")) ?
                                    this.enumString(RedFlagEnum, complaint.redFlag, complaint.redFlagDetail) : 'NA')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    //TODO: Copied from ComplaintView.js. Find out how to re-use??
    enumString(_class, enumObject, detail) {
        if (enumObject == null || enumObject.length === 0) {
            return detail || 'NA';
        }
        let res = enumObject.map(e => _class[e]?.name);
        if (detail && detail !== '') {
            res.push(detail);
        }
        return res.join(', ');
    }

}
