import React, {Component} from "react";

export default class MSKDiagnosisFindingsModal extends Component {
    state = {
        findings: ""
    };

    constructor(props) {
        super(props);
        this.state.findings = props.findings
    }

    render() {
        return (
            <div>
                <div className={"container"} style={{maxWidth: "100%"}}>
                    <div style={{marginBottom: "5px"}}>
                        <div style={{fontWeight: "bold"}}>Findings:</div>
                        <div>
                            {this.state.findings}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}