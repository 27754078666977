import React, {Component} from "react";
import "../../VisitHistory.css"
import PalpationLocationEnum from "../../../assessment/enums/palpation/PalpationLocationEnum";
import PalpationFindingEnum from "../../../assessment/enums/palpation/PalpationFindingEnum";
import StringUtil from "../../../../../../utils/StringUtil";

export default class PalpationViewHistory extends Component {

    state = {
        list: [],
        index: null,
        listSize: null
    };

    render() {
        if (!this.props.palpation
            || !this.props.palpation.items
            || this.props.palpation.items.length === 0) {
            return <div className="pl-3">
                {this.props.palpation && this.props.palpation.none ? "Normal" : "No information available"}
            </div>;
        }

        return(
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Area</th>
                    <th>Location</th>
                    <th>Finding</th>
                    <th>Pain</th>
                    <th>Details</th>
                </tr>
                {this.props.palpation.items.map((item, index) => {
                    return (<tr key={"palpationViewHistory" + index} style={{backgroundColor: "white"}}>
                        <td>{StringUtil.isNullOrEmpty(item.area) ? null : item.area}</td>
                        <td>{
                            [
                                item.locationRight != null ? "Right-" + PalpationLocationEnum[item.locationRight].name : null,
                                item.locationLeft != null ? "Left-" + PalpationLocationEnum[item.locationLeft].name : null,
                            ].filter(Boolean).join(", ")
                        }</td>
                        <td>{item.finding != null ? PalpationFindingEnum[item.finding].name : null}</td>
                        <td>{item.pain != null ? item.pain + "/10" : null}</td>
                        <td>{StringUtil.isNullOrEmpty(item.detail) ? null : item.detail}</td>
                    </tr>)
                })}
                </tbody>
            </table>
        );
    }
}