import React, {Component} from 'react';
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import {RadioInput, TextInput} from "../../../components";
import IntakeFormAnyMedicationSupplementEnum from "./enum/IntakeFormAnyMedicationSupplementsEnum";
import FormField from "../../../components/form/FormField";
import IntakeFormYesNoEnum from "./enum/IntakeFormYesNoEnum";
import StringUtil from "../../../utils/StringUtil";

class IntakeFormHealthHistoryPage2 extends Component {
    render() {
        const {
            selectedLanguage, readOnly, enumLabel, onChange, onFieldGroupChanged, formFields,
            anyMedicationSupplements, anyMedicationSupplementsIfYes, hadSurgery, hadSurgeryIfYes,
            receivingHealthCare, receivingHealthCareIfYes
        } = this.props

        return (
            <div className="container dim14-form-row-align">
                <div className="row">
                    <div className="col-6 dim14-form-label">
                        {IntakeFormTextEnum.Are_You_on_Any_Medication_Supplements[selectedLanguage]}
                    </div>
                    <div className="col-6">
                        <RadioInput
                            id="anyMedicationSupplementsRadioInput"
                            value={anyMedicationSupplements}
                            options={IntakeFormAnyMedicationSupplementEnum.toArray()}
                            displayProperty={enumLabel} valueProperty="key"
                            disabled={readOnly}
                            onChange={(value) =>
                                onChange("anyMedicationSupplements", value)
                            }
                        />
                    </div>
                </div>
                {(anyMedicationSupplements !== "No" &&
                !StringUtil.isNullOrEmpty(anyMedicationSupplements)) && (
                    <div className="row">
                        <div className="col-6 dim14-form-label">
                            {IntakeFormTextEnum.If_Yes_Name_of_Medication_Supplement[selectedLanguage]}
                        </div>
                        <div className="col-6">
                            <FormField
                                ref={(formField) => formFields.push(formField)}
                                required
                                validateOn={readOnly ? true : anyMedicationSupplementsIfYes}
                            >
                                <TextInput
                                    value={anyMedicationSupplementsIfYes}
                                    disabled={readOnly}
                                    onChange={(value) =>
                                        onChange("anyMedicationSupplementsIfYes", value)
                                    }
                                />
                            </FormField>
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-6 dim14-form-label">
                        {IntakeFormTextEnum.Ever_Had_Surgery[selectedLanguage]}
                    </div>
                    <div className="col-6">
                        <RadioInput
                            id="anyMedicationSupplementsIfYesRadioInput"
                            value={hadSurgery}
                            options={IntakeFormYesNoEnum.toArray()}
                            displayProperty={enumLabel}
                            valueProperty={"key"}
                            disabled={readOnly}
                            onChange={(value) => onChange("hadSurgery", value)}
                        />
                    </div>
                </div>

                {hadSurgery === "Yes" && (
                    <div className="row">
                        <div className="col-6 dim14-form-label">
                            {IntakeFormTextEnum.If_Yes_When_And_What_Surgery[selectedLanguage]}
                        </div>
                        <div className="col-6">
                            <FormField
                                ref={(formField) => formFields.push(formField)}
                                required
                                validateOn={readOnly ? true : hadSurgeryIfYes}
                            >
                                <TextInput
                                    value={hadSurgeryIfYes}
                                    disabled={readOnly}
                                    onChange={(value) =>
                                        onChange("hadSurgeryIfYes", value)
                                    }
                                />
                            </FormField>
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-6 dim14-form-label">
                        {IntakeFormTextEnum.Receiving_Health_Care[selectedLanguage]}
                    </div>
                    <div className="col-6">
                        <RadioInput
                            id="symptomWorseRadioInputid"
                            value={receivingHealthCare}
                            options={IntakeFormYesNoEnum.toArray()}
                            displayProperty={enumLabel}
                            valueProperty={"key"}
                            disabled={readOnly}
                            onChange={(value) =>
                                onChange("receivingHealthCare", value)
                            }
                        />
                    </div>
                </div>

                {receivingHealthCare === "Yes" && (
                    <div className="row">
                        <div className="col-6 dim14-form-label">
                            {IntakeFormTextEnum.If_Yes_Please_Specify[selectedLanguage]}
                        </div>
                        <div className="col-6">
                            <FormField
                                ref={(formField) => formFields.push(formField)}
                                required
                                validateOn={
                                    readOnly ? true : receivingHealthCareIfYes
                                }
                            >
                                <TextInput
                                    value={receivingHealthCareIfYes}
                                    disabled={readOnly}
                                    onChange={(value) =>
                                        onChange("receivingHealthCareIfYes", value)
                                    }
                                />
                            </FormField>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default IntakeFormHealthHistoryPage2;