import React, {Component} from "react";
import '../../EMR.css';
import ObservationFindingEnum from "../enums/observation/ObservationFindingEnum";
import SeverityEnum from "../enums/observation/SeverityEnum";

export default class ObservationView extends Component {

    render() {
        if(this.props.observation == null
            || this.props.observation.items == null
            || this.props.observation.items.length == 0) {
            return null;
        }

        return(
            <div className={"row content-card"} style={{marginTop:"20px"}}>
                <table className={"emr-table"}>
                    <tbody>
                        <tr>
                            <th>Finding</th>
                            <th>Severity</th>
                            <th>Location</th>
                            <th>Details</th>
                        </tr>
                        {this.props.observation.items.map((item,index) =>
                        {
                            return (
                            <tr key={"specialTestsModal" + index} style={{backgroundColor: "white"}}>
                                <td>  {item.finding == null? "" : ObservationFindingEnum[item.finding].name}</td>
                                <td>  {item.severity == null? "": SeverityEnum[item.severity].name}</td>
                                <td>  {item.location}</td>
                                <td>  {item.details}</td>

                            </tr> );
                        }
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}