import React, {Component} from "react";
import _uniqueId from "lodash/uniqueId";
import {DelayInput} from "react-delay-input/lib/Component";

export default class DelayedTextInput extends Component {

    render() {
        const {id, className, value, onChange, style, ...rest} = this.props;

        let componentClassName = "dim14textinput";
        if (className) {
            componentClassName += " " + className;
        }

        return (
            <DelayInput id={id || _uniqueId("delayed-text-input-id-")}
                        style={style}
                        minLength={0} delayTimeout={400}
                        className={componentClassName}
                        value={value}
                        onChange={(event) => onChange(event.target.value)}
                        {...rest}
            />)
    }

}
