import Enum from "../../../../../../enums/Enum";

const DermatomesCervicalEnum = new Enum({
    D_C2: {value: "C2 - Temple, forehead, occiput"},
    D_C3: {value: "C3 - Entire neck, posterior cheek, temporal area, prolongation forward under mandible"},
    D_C4: {value: "C4 - Shoulder area, clavicular area, upper scapular area"},
    D_C5: {value: "C5 - Deltoid area, anterior aspect of entire arm to base of thumb"},
    D_C6: {value: "C6 - Anterior arm, radial side of hand to thumb and index finger"},
    D_C7: {value: "C7 - Lateral arm and forearm to index, long, and ring fingers"},
    D_C8: {value: "C8 - Medial arm and forearm to long, ring, and little fingers"},
});

export default DermatomesCervicalEnum;