import Enum from "../../../../../../enums/Enum";

const BloodPressureLocationEnum = new Enum({

        RIGHT_ARM: {name: "Right Arm"},
        LEFT_ARM: {name: "Left Arm"},
        RIGHT_CALF: {name: "Right Calf"},
        LEFT_CALF: {name: "Left Calf"},
        RIGHT_ANKLE: {name: "Right Ankle"},
        LEFT_ANKLE: {name: "Left Ankle"}

});

export default BloodPressureLocationEnum;