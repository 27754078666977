import React from 'react';
import { lighten, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const StyledProgressBar = withStyles({
    root: {
        height: 20,
        backgroundColor: lighten('#32b432', 0.5),
    },
    bar: {
        borderRadius: 5,
        backgroundColor: '#32b432',
    },
})(LinearProgress);

const ProgressBar = (props) => {
    return <StyledProgressBar {...props}/>
};

export default ProgressBar;