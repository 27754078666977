import React, {Component} from 'react'
import Logo from "./Logo";
import moment from "moment";
import confirmDialog from "../../../components/dialog/ConfirmDialog";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import RemotingService from "../../../services/remoting-service/RemotingService";
import {NumberInput, TextInput, Wizard} from "../../../components";
import FormField from "../../../components/form/FormField";
import SignatureInput from "../../../components/signature-input/SignatureInput";
import InjectionConsentFormTextEnum from "../injectionconsent/InjectionConsentFormTextEnum";
import FormLanguages from "../enum/FormLanguages";

export default class PublicInjectionConsentForm extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    createHeader = (form, selectedLanguage) => {
        let headerClass = "row d-flex justify-content-between align-items-center ";
        headerClass = selectedLanguage === FormLanguages.ARABIC.name ? (headerClass + "arabic-form") : headerClass;
        return <div style={{padding: "0px 20px 0px 20px"}}>
            <div className="row text-left">
                <div className="col-12 no-padding">
                    <Logo/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center no-padding" style={{fontSize: 20, fontWeight: "bold"}}>
                    {InjectionConsentFormTextEnum.Injection_Consent_Form[selectedLanguage]}
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center" style={{fontSize: "18px"}}>
                    {InjectionConsentFormTextEnum.Patient_Information[selectedLanguage]}
                </div>
            </div>
            <div className={headerClass} style={{fontSize: 11}}>
                <div className="col-4 text-left no-padding">
                    <b>{InjectionConsentFormTextEnum.Name[selectedLanguage]}: </b> {form.patientFullName}
                </div>
                <div className="col-4 text-center no-padding">
                    <b>{InjectionConsentFormTextEnum.MRN[selectedLanguage]}: </b> {form.patientMrn}
                </div>

                <div className="col-4 text-right no-padding">
                    <b>{InjectionConsentFormTextEnum.DOB[selectedLanguage]}: </b> {form.patientDOB}
                </div>
            </div>
        </div>
    };

    fieldChanged = (fieldName, value) => {
        this.props.form[fieldName] = value;
        this.forceUpdate();
    };

    close = () => {
        confirmDialog(
            'Confirmation',
            'Are you sure you want to close this form? All input will be lost.',
            this.props.close);
    };

    submitForm = () => {
        if (ValidationUtil.checkWithNotification(this.formFields)) {
            const {consentFormCode: code, form} = this.props;
            RemotingService.postRemoteCall(`api/public/consent-form/code/${code}/injection-consent-form`, form);
            this.props.onFormSubmit(form);
        }
    };

    render() {
        this.formFields = [];
        const {form} = this.props;
        const selectedLanguage = this.props.language;
        const formBodyClass = selectedLanguage == FormLanguages.ARABIC.name ? "arabic-form" : "";

        return (
            <Wizard
                show={true} fullScreen
                footerStyle={{backgroundColor: '#f5f5f5'}}
                buttonStyle={{backgroundColor: '#84BE56', border: 'none'}}
                header={this.createHeader(form, selectedLanguage)}
                close={this.close}
                onSubmit={this.submitForm}
                previousBtnText={InjectionConsentFormTextEnum.Wizard_Previous[selectedLanguage]}
                nextBtnText={InjectionConsentFormTextEnum.Wizard_Next[selectedLanguage]}
                saveBtnText={InjectionConsentFormTextEnum.Wizard_Save[selectedLanguage]}>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}>
                    <div className={formBodyClass} style={{margin: "20px 20px 20px 20px"}}>

                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className={"col-md-6"}>
                                    <div className="col-md-12 dim14-form-label">
                                        {InjectionConsentFormTextEnum.Name_Of_Proposed_Procedure[selectedLanguage]}
                                    </div>
                                    <div className="col-md-12 dim14-base-input">
                                        <FormField
                                            ref={(ref) => this.formFields.push(ref)}
                                            required
                                            validateOn={form.nameOfProposedInvestigation}
                                        >
                                            <TextInput value={form.nameOfProposedInvestigation}
                                                       maxLength={255}
                                                       onChange={(value) => this.fieldChanged('nameOfProposedInvestigation', value)}/>
                                        </FormField>
                                    </div>
                                </div>

                                <div className={"col-md-6"}>
                                    <div className="col-md-12 dim14-form-label">
                                        {InjectionConsentFormTextEnum.Fee_Of_Proposed_Procedure[selectedLanguage]}
                                    </div>
                                    <div className="col-md-12 dim14-base-input">
                                        <NumberInput value={form.feeForProposedProcedure}
                                                     min={0}
                                                     allowDecimal
                                                     maxFractionDigits={form.patientCountry === "UAE" ? 2 : 3}
                                                     onChange={(value) => this.fieldChanged('feeForProposedProcedure', value)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>

                    <div className={formBodyClass} style={{margin: "20px 20px 20px 20px"}}>

                        <div style={{backgroundColor: "white", marginBottom: "20px", padding: "20px"}}>
                            <div style={{margin: "0px 15px"}}>
                                <h5>
                                    {(form.patientCountry == "UAE" ? InjectionConsentFormTextEnum.Statement_Of_Health_Professional_DXB[selectedLanguage]
                                        : InjectionConsentFormTextEnum.Statement_Of_Health_Professional_KWI[selectedLanguage])}:
                                </h5>
                                <p style={{
                                    fontSize: "12px",
                                    marginTop: "-5px"
                                }}>{InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Paragraph_In_Parentheses[selectedLanguage]}</p>
                                <p> {InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Paragraph1[selectedLanguage]}:<br/>
                                    <u>{InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Item1_Underlined[selectedLanguage]}:</u> {InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Item1_Paragraph[selectedLanguage]}<br/>
                                    <u>{InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Item2_Underlined[selectedLanguage]}:</u> {InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Item2_Paragraph[selectedLanguage]}<br/>
                                    <u>{InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Item3_Underlined[selectedLanguage]}:</u> {InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Item3_Paragraph[selectedLanguage]}<br/>
                                </p>
                                <p>
                                    {InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Paragraph4[selectedLanguage]}<br/>
                                    {InjectionConsentFormTextEnum.Statement_Of_Health_Professional_Paragraph5[selectedLanguage]}
                                </p>

                            </div>
                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 dim14-form-label">
                                            {InjectionConsentFormTextEnum.Provider_Name[selectedLanguage]}
                                        </div>
                                        <div className="col-md-6 dim14-base-input">
                                            {": " + (form.providerName ? form.providerName : " ")}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 dim14-form-label">
                                            {InjectionConsentFormTextEnum.Job_Title[selectedLanguage]}
                                        </div>
                                        <div className="col-md-6 dim14-base-input">
                                            {": " + (form.providerSpeciality ? form.providerSpeciality : " ")}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 dim14-form-label">
                                            {InjectionConsentFormTextEnum.Date_Time[selectedLanguage]}
                                        </div>
                                        <div className="col-md-6 dim14-base-input">
                                            {": " + moment(new Date(form.healthProfessionalDate)).format('MMMM DD YYYY, h:mm:ss a')}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="col-md-12 dim14-form-label">
                                        {form.patientCountry == "UAE" ? InjectionConsentFormTextEnum.Provider_Signature_DXB[selectedLanguage] : InjectionConsentFormTextEnum.Provider_Signature_KWI[selectedLanguage]}
                                    </div>

                                    <div className="col-md-12">
                                        <FormField
                                            ref={(ref) => this.formFields.push(ref)}
                                            required requiredMessage="Signature required"
                                            validateOn={form.providerSignature}
                                        >
                                            <SignatureInput  selectedLanguage={selectedLanguage}
                                                             textEnum={InjectionConsentFormTextEnum}
                                                             signature={form.providerSignature}
                                                            onClear={() => {
                                                                form.providerSignature = null;
                                                                this.forceUpdate();
                                                            }}
                                                            onSave={(signature) => {
                                                                form.providerSignature = signature;
                                                                this.forceUpdate();
                                                            }}/>
                                        </FormField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>

                    <div className={formBodyClass} style={{margin: "20px 20px 20px 20px"}}>

                        <div style={{backgroundColor: "white", marginBottom: "20px", padding: "20px"}}>

                            <div style={{margin: "0px 15px"}}>
                                <h5>
                                    {InjectionConsentFormTextEnum.Statement_Of_Patient[selectedLanguage]}:
                                </h5>
                                <p>
                                    {InjectionConsentFormTextEnum.Please_Read_Carefully[selectedLanguage]}</p>

                                <div className={"row"} style={{marginLeft: "0px", marginBottom: "10px"}}>
                                    <div className={"col-auto"}>
                                        <p>
                                            <b>{InjectionConsentFormTextEnum.For_Self_Paying_Clients_I_Understand[selectedLanguage]}</b> {InjectionConsentFormTextEnum.Statement_Of_Patient_Item1[selectedLanguage]}
                                        </p>
                                    </div>
                                    <div className={"col"}>
                                        <NumberInput
                                            value={form.selfPayingClientCost}
                                            style={{maxWidth: "25%"}}
                                            onChange={(value) => this.fieldChanged('selfPayingClientCost', value)}/>
                                    </div>
                                </div>

                                <p style={{marginTop: "10px"}}>
                                    <b>{InjectionConsentFormTextEnum.I_Understand[selectedLanguage]}</b> {InjectionConsentFormTextEnum.Statement_Of_Patient_Item2[selectedLanguage]}<br/>
                                    <b>{InjectionConsentFormTextEnum.I_Agree[selectedLanguage]}</b> {InjectionConsentFormTextEnum.Statement_Of_Patient_Item3[selectedLanguage]}<br/>
                                    <b>{InjectionConsentFormTextEnum.I_Agree[selectedLanguage]}</b> {InjectionConsentFormTextEnum.Statement_Of_Patient_Item4[selectedLanguage]}<br/>
                                    <b>{InjectionConsentFormTextEnum.I_Understand[selectedLanguage]}</b> {InjectionConsentFormTextEnum.Statement_Of_Patient_Item5[selectedLanguage]}<br/>
                                    <b>{InjectionConsentFormTextEnum.I_Understand[selectedLanguage]}</b> {InjectionConsentFormTextEnum.Statement_Of_Patient_Item6[selectedLanguage]}<br/>
                                    <b>{InjectionConsentFormTextEnum.I_Understand[selectedLanguage]}</b> {InjectionConsentFormTextEnum.Statement_Of_Patient_Item7[selectedLanguage]}<br/>
                                    <b>{InjectionConsentFormTextEnum.I_Agree[selectedLanguage]}</b> {InjectionConsentFormTextEnum.Statement_Of_Patient_Item8[selectedLanguage]}<br/>
                                    <b>{InjectionConsentFormTextEnum.I_Agree[selectedLanguage]}</b> {InjectionConsentFormTextEnum.Statement_Of_Patient_Item9[selectedLanguage]}
                                </p>
                            </div>

                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 dim14-form-label">
                                            {InjectionConsentFormTextEnum.Date_Time[selectedLanguage]}
                                        </div>
                                        <div className="col-md-6 dim14-base-input">
                                            {": " + moment(new Date(form.patientDate)).format('MMMM DD YYYY, h:mm:ss a')}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 dim14-form-label">
                                            {InjectionConsentFormTextEnum.Patient_Name[selectedLanguage]}
                                        </div>
                                        <div className="col-md-6 dim14-base-input">
                                            {": " + form.patientFullName}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="col-md-12 dim14-form-label">
                                        {InjectionConsentFormTextEnum.Patient_Signature[selectedLanguage]}
                                    </div>

                                    <div className="col-md-12">
                                        <SignatureInput  selectedLanguage={selectedLanguage}
                                                         textEnum={InjectionConsentFormTextEnum}
                                                         signature={form.patientSignature}
                                                        onClear={() => {
                                                            form.patientSignature = null;
                                                            this.forceUpdate();
                                                        }}
                                                        onSave={(signature) => {
                                                            form.patientSignature = signature;
                                                            this.forceUpdate();
                                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onPrevious={() => this.formFields = []}>

                    <div className={formBodyClass} style={{margin: "20px 20px 20px 20px"}}>
                        <p>
                            {InjectionConsentFormTextEnum.Witness_Paragraph1[selectedLanguage]}<br/>
                            {InjectionConsentFormTextEnum.Witness_Paragraph2[selectedLanguage]}
                        </p>

                        <div style={{border: "1px solid", borderRadius: "5px", padding: "30px"}}>

                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                {InjectionConsentFormTextEnum.Print_Name[selectedLanguage]}
                                            </div>
                                            <div className="col-md-6 dim14-base-input">
                                                <TextInput value={form.guardianName} alphabeticOnly
                                                           onChange={(value) => this.fieldChanged('guardianName', value)}/>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                {InjectionConsentFormTextEnum.Relationship_To_Patient[selectedLanguage]}
                                            </div>
                                            <div className="col-md-6 dim14-base-input">
                                                <TextInput value={form.guardianRelationship} alphabeticOnly
                                                           onChange={(value) => this.fieldChanged('guardianRelationship', value)}/>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                {InjectionConsentFormTextEnum.Date_Time[selectedLanguage]}
                                            </div>
                                            <div className="col-md-6 dim14-base-input">
                                                {": " + moment(new Date(form.guardianDate)).format('MMMM DD YYYY, h:mm:ss a')}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="col-md-12 dim14-form-label">
                                            {InjectionConsentFormTextEnum.Signature_Of_Witness[selectedLanguage]}
                                        </div>
                                        <div className="col-md-12">
                                            <FormField
                                                ref={(ref) => this.formFields.push(ref)}
                                                disabled={form.patientSignature != null}
                                                required requiredMessage="Signature required"
                                                validateOn={form.guardianSignature}
                                            >
                                                <SignatureInput  selectedLanguage={selectedLanguage}
                                                                 textEnum={InjectionConsentFormTextEnum}
                                                                 signature={form.guardianSignature}
                                                                onClear={() => {
                                                                    form.guardianSignature = null;
                                                                    this.forceUpdate();
                                                                }}
                                                                onSave={(signature) => {
                                                                    form.guardianSignature = signature;
                                                                    this.forceUpdate();
                                                                }}/>
                                            </FormField>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>
            </Wizard>
        )
    }
}
