import React, {Component} from 'react';
import moment from "moment";
import StringUtil from "../../utils/StringUtil";
import {Wizard} from "../../components";
import RemotingService from "../../services/remoting-service/RemotingService";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import LogoDubai from "../../layout/LogoDubai";
import PatientUtil from "../../utils/PatientUtil";
import ConsentFormPartsDxb from "./utils/ConsentFormPartsDxb";
import FormLanguages from "./enum/FormLanguages";
import InformedConsentHeader from "./informedconsent/InformedConsentHeader";
import InformedConsentSignatureInputs from "./informedconsent/InformedConsentSignatureInputs";
import InformedConsentFormDxbTextEnum from "./informedconsent/enum/InformedConsentFormDxbTextEnum";
import IntakeFormTextEnum from "./intakeform/enum/IntakeFormTextEnum";

class InformedConsentFormDxb extends Component {

    constructor(props) {
        super(props)

        this.state = {
            consentDate: new Date()
        }
    }

    componentDidMount() {
        const {patient, form} = this.props;
        const readOnly = form !== null && form !== undefined;

        RemotingService.getRemoteCall('api/appointment/first-provider/UAE/' + patient.id, {},
            (firstProvider) => {
                if (firstProvider) {
                    this.setState({providerName: firstProvider.name, providerSignature: firstProvider.signature});
                }
            });
    }

    onSubmit = () => {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        if (this.props.form !== null && this.props.form !== undefined) {
            this.props.close();

        } else {
            let patient = this.props.patient;
            let patientFullName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
            let patientDOB = patient.birthday;
            let patientEId = patient.eid;
            const {
                consentDate,
                providerName,
                guardianName,
                guardianRelationship,
                providerSignature,
                patientSignature,
                traineeConsent
            } = this.state;

            RemotingService.postRemoteCall('api/patient/' + this.props.patient.id + '/general-info-consent-dxb',
                {
                    consentDate, providerName, guardianName,
                    guardianRelationship, providerSignature, patientSignature,
                    patientFullName, patientDOB, patientEId, traineeConsent
                },
                (result) => {
                    this.props.afterSave(result);
                    this.props.close();
                });
        }
    }

    onSavePatientSignature = (signature) => {
        this.setState({patientSignature: signature})
    }

    fieldChanged = (fieldName, value, validationCallback) => {
        this.setState({[fieldName]: value}, () => {
            if (validationCallback) {
                validationCallback(this);
            }
        });
    };

    pushFormFieldRef = (ref) => this.formFields.push(ref);

    render() {
        if (!this.props.show)
            return null;

        let {patient, form, selectedLanguage} = this.props;
        let readOnly = form !== null && form !== undefined;
        let mrnNo = StringUtil.mrn(patient.mrn);
        let patientName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
        this.formFields = []

        const formBodyClass = selectedLanguage == FormLanguages.ARABIC ? "arabic-form form-body" : "form-body";
        const enumLabel = selectedLanguage == FormLanguages.ARABIC ? "nameInArabic" : "name";
        const commonProps = {
            selectedLanguage: selectedLanguage.name,
            pushFormFieldRef: this.pushFormFieldRef
        }

        return (
            <>
                <Wizard
                    show={this.props.show}
                    close={this.props.close}
                    title={InformedConsentFormDxbTextEnum.Informed_Consent_Form[selectedLanguage.name]}
                    onSubmit={this.onSubmit}
                    isReadOnly={readOnly}
                    previousBtnText={IntakeFormTextEnum.Wizard_Previous[selectedLanguage.name]}
                    nextBtnText={IntakeFormTextEnum.Wizard_Next[selectedLanguage.name]}
                    saveBtnText={IntakeFormTextEnum.Wizard_Save[selectedLanguage.name]}
                >
                    <Wizard.Page>
                        <div className={formBodyClass}>
                            <InformedConsentHeader Logo={LogoDubai} mrnNo={mrnNo} patient={patient} {...commonProps}/>
                            <ConsentFormPartsDxb.Introduction {...commonProps}/>
                            <ConsentFormPartsDxb.Benefits {...commonProps}/>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page>
                        <div className={formBodyClass}>

                            <ConsentFormPartsDxb.RisksIntro {...commonProps}/>
                            <ul>
                                <ConsentFormPartsDxb.RisksItem1 {...commonProps}/>
                                <ConsentFormPartsDxb.RisksItem2 {...commonProps}/>
                                <ConsentFormPartsDxb.RisksItem3 {...commonProps}/>
                                <ConsentFormPartsDxb.RisksItem4 {...commonProps}/>
                            </ul>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page>
                        <div className={formBodyClass}>
                            <ul>
                                <ConsentFormPartsDxb.RisksItem5 {...commonProps}/>
                                <ConsentFormPartsDxb.RisksItem6 {...commonProps}/>
                                <ConsentFormPartsDxb.RisksItem7 {...commonProps}/>
                            </ul>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page>
                        <div className={formBodyClass}>
                            <ConsentFormPartsDxb.Alternatives {...commonProps}/>
                            <ConsentFormPartsDxb.InjectionAndMedication {...commonProps}/>
                            <ConsentFormPartsDxb.DataCollectionAndPrivacyTitle {...commonProps}/>
                            <ConsentFormPartsDxb.DataCollectionAndPrivacyParagraph1 {...commonProps}/>
                            <ConsentFormPartsDxb.DataCollectionAndPrivacyParagraph2 {...commonProps}/>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page>
                        <div className={formBodyClass}>
                            <ConsentFormPartsDxb.DataCollectionAndPrivacyParagraph3 {...commonProps}/>
                            <ConsentFormPartsDxb.DataCollectionAndPrivacyParagraph4 {...commonProps}/>
                            <ConsentFormPartsDxb.DataCollectionAndPrivacyParagraph5 {...commonProps}/>
                            <ConsentFormPartsDxb.DataCollectionAndPrivacyParagraph6 {...commonProps}/>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page
                        onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                        onPrevious={() => (this.formFields = [])}>
                        <div className={formBodyClass}>
                            <ConsentFormPartsDxb.QuestionsOfConcerns {...commonProps}/>
                            <ConsentFormPartsDxb.Complaints {...commonProps}/>
                            <ConsentFormPartsDxb.ConsentTable {...commonProps}
                                                              formFields={this.formFields}
                                                              readOnly={readOnly}
                                                              traineeConsent={readOnly ? form.traineeConsent : this.state.traineeConsent}
                                                              onChange={this.fieldChanged}
                                                              enumLabel={enumLabel}/>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page onPrevious={() => (this.formFields = [])}>
                        <div className={formBodyClass}>
                            <ConsentFormPartsDxb.Conclusion {...commonProps}/>

                            <InformedConsentSignatureInputs {...commonProps}
                                                            readOnly={readOnly}
                                                            formFields={this.formFields}
                                                            textEnum={InformedConsentFormDxbTextEnum}
                                                            consentDate={moment(readOnly ? new Date(form.consentDate) : this.state.consentDate).format('LL')}
                                                            patientName={patientName}
                                                            providerName={readOnly ? form.providerName : this.state.providerName}
                                                            guardianName={readOnly ? form.guardianName : this.state.guardianName}
                                                            guardianRelationship={readOnly ? form.guardianRelationship : this.state.guardianRelationship}
                                                            patientSignature={readOnly ? form.patientSignature : this.state.patientSignature}
                                                            providerSignature={readOnly ? form.providerSignature : this.state.providerSignature}
                                                            onSavePatientSignature={this.onSavePatientSignature}
                                                            onChange={this.fieldChanged}/>
                        </div>
                    </Wizard.Page>
                </Wizard>
            </>
        );
    }

}

export default InformedConsentFormDxb;
