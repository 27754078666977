import React from "react";
import AppointmentTableView from "./tableview/AppointmentTableView";
import DateUtil from "../../utils/DateUtil";
import PatientUtil from "../../utils/PatientUtil";

export default class AgendaView extends React.Component {

    state = {};

    findAppointment = (id) => {
        return this.props.appointments.find(a => a.id === id);
    }

    render() {
        const {date, selectedView} = this.props;
        const {confirmAppointment, checkInAppointment, uncheckInAppointment} = this.props.appointmentActions;
        const appointments = this.props.appointments.map(a => {
            return {
                ...a,
                start: new Date(a.start),
                end: new Date(a.end),
                staffId: a.staff.id,
                patientName: PatientUtil.getPatientName(a.patient.firstName, a.patient.middleName, a.patient.lastName),
                checkInStatus: a.status === "BOOKED",
                specialityName: a.staff.speciality.name,
                insuranceExists: !!a.insuranceExists
            }
        });

        return (
            <AppointmentTableView
                selectedDate={date}
                selectedView={selectedView}
                mappedAppointments={appointments}
                handleConfirmOnTable={(appointmentId) => confirmAppointment(this.findAppointment(appointmentId))}
                handleCheckInOnTable={(appointmentId) => checkInAppointment(this.findAppointment(appointmentId))}
                handleUndoCheckInOnTable={(appointmentId) => uncheckInAppointment(this.findAppointment(appointmentId))}
            />
        );
    }

}

AgendaView.range = date => {
    return [date];
}

AgendaView.navigate = (date, action) => {
    switch (action) {
        case 'PREV':
            return DateUtil.addDays(date, -1);

        case 'NEXT':
            return DateUtil.addDays(date, 1);

        default:
            return date
    }
}

AgendaView.title = (date, {localizer}) => localizer.format(date, 'dayHeaderFormat')