import React from 'react';
import {Button, Card} from 'react-bootstrap';
import {Modal} from '../../../../components';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RemotingService from '../../../../services/remoting-service/RemotingService';
import {Editor} from 'primereact/editor';

class StaffProfileBiography extends React.Component {

    constructor(props) {
        super(props);
        let initialText = this.props.biography ? this.props.biography : '';
        this.state = {
            text: initialText,
            newText: initialText,
            staffId: this.props.staffId,
            updateBiographyModalVisible: false,
            showMoreBiography: false
        };
        this.bioRef = React.createRef();
    }

    componentDidMount() {
        this.bioRef.current.innerHTML = this.state.text.substring(0, 300) + '...';
    }

    render() {
        return (
            <>
                <Card style={{width: '100%'}} className='small-screen-margin'>
                    <Card.Header style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div>
                            Biography
                        </div>
                        <div>
                            {(this.props.editable &&
                                <FontAwesomeIcon icon={["fas", "edit"]}
                                                 onClick={() => this.setState({updateBiographyModalVisible: true})}/>
                            )}
                        </div>
                    </Card.Header>
                    <Card.Body style={{display: 'flex', margin: 'auto'}}>
                        <Card.Text>
                            <div ref={this.bioRef}></div>
                        </Card.Text>
                    </Card.Body>
                    {!this.state.showMoreBiography && (<Button variant="link" onClick={() => this.showMore()}>
                        Show More...
                    </Button>)}
                    {this.state.showMoreBiography && (<Button variant="link" onClick={() => this.showLess()}>
                        ...Show Less
                    </Button>)}
                </Card>
                {this.renderUpdateBiographyModal()}
            </>
        );
    }

    renderUpdateBiographyModal = () => {
        return this.state.updateBiographyModalVisible ?
            (<Modal visible={this.state.updateBiographyModalVisible}
                    header="Biography"
                    submitContent="Update"
                    submitAction={this.handleUpdate}
                    closeAction={() => this.setState({updateBiographyModalVisible: false})}>
                {this.updateModalBody()}
            </Modal>)
            : null;
    }

    handleUpdate = () => {
        const staffDTO = {
            id: this.state.staffId,
            biography: this.state.newText
        };
        RemotingService.postRemoteCall('api/staff/update', staffDTO, () => {
            this.setState({updateBiographyModalVisible: false, text: this.state.newText});
            this.props.onStaffProfileUpdate();
        });
    };

    updateModalBody = () => {
        return (
            <div style={{minWidth:"520px"}}>
                <Editor style={{height:'320px'}} value={this.state.newText} onTextChange={(e) => this.setState({newText: e.htmlValue})} />
            </div>);
    };

    showMore = () => {
        this.setState({showMoreBiography: true});
        this.bioRef.current.innerHTML = this.state.text;
    }
    showLess = () => {
        this.setState({showMoreBiography: false});
        this.bioRef.current.innerHTML = this.state.text.substring(0, 300) + '...';
    }
}

export default StaffProfileBiography;
