import Enum from "../../../../../../enums/Enum";

const TemperatureLocationEnum = new Enum({
        EAR: {name: "Ear"},
        FOREHEAD: {name: "Forehead"},
        ORAL: {name: "Oral"},
        ARMPIT: {name: "Armpit"},
        RECTUM: {name: "Rectum"}
});

export default TemperatureLocationEnum;