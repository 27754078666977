import Enum from "../../../../../../enums/Enum";

const TestEnum = new Enum({
        //NECK
        ADSONS_TEST	: { name: "Adson's Test"},
        ROOS_TEST	: { name: "Roos Test"},
        CERVICAL_COMPRESSION_TEST	: { name: "Cervical compression test"},
        CERVICAL_DISTRACTION_TEST	: { name: "Cervical distraction Test"},
        ALAR_LIGAMENT_TEST	: { name: "Alar ligament Test"},
        CERVICAL_FLEXION_ROTATION_TEST	: { name: "Cervical flexion rotation Test"},
        SHARPS_PURSER_TEST	: { name: "Sharp's Purser Test"},

        //SHOULDER
        CROSS_ARM_ADDUCTION_TEST_AC_JOINT	: { name: "Cross arm adduction test (AC Joint)"},
        APPREHENSION_RELOCATION	: { name: "Apprehension & Relocation"},
        CLUNKY_TEST_LABRUM	: { name: "Clunky Test (Labrum)"},
        PASSIVE_COMPRESSION_TEST	: { name: "Passive Compression test"},
        EMPTY_CAN_TEST_SUPRASPINATUS	: { name: "Empty can test (Supraspinatus)"},
        FULL_CAN_TEST	: { name: "Full can Test"},
        HAWKINS_KENNEDY_TEST	: { name: "Hawkins-Kennedy Test"},
        NEER_IMPINGEMENT_TEST	: { name: "NEER impingement Test"},
        OBRIENS_TEST_SLAP_LESION	: { name: "O'Briens Test - SLAP lesion"},
        RESISTED_ER_ROTATOR_CUFF_LESION	: { name: "Resisted ER - Rotator Cuff lesion"},
        RESISTED_IR_ROTATOR_CUFF_LESION	: { name: "Resisted IR - Rotator Cuff lesion"},
        SPEEDS_TEST_BICEPS	: { name: "Speed's Test (Biceps)"},
        TOS	: { name: "TOS"},
        PAINFUL_ARC_TEST	: { name: "Painful Arc Test"},
        ER_STRENGTH_TEST	: { name: "ER Strength Test"},
        DROP_ARM_TEST	: { name: "Drop Arm Test"},
        LIFT_OFF_TEST	: { name: "Lift off Test"},
        LOAD_AND_SHIT_TEST	: { name: "Load and Shift Test"},
        ADDUCTION_TEST	: { name: "Adduction Test"},
        CRANK_TEST	: { name: "Crank Test"},
        BICEPS_LOAD_TEST	: { name: "Biceps load Test"},

        //ELBOW
        GOLFERS_ELBOW_MEDIAL_EPICONDYLITIS	: { name: "Golfer's Elbow (Medial Epicondylitis)"},
        RESISTED_MIDDLE_FINGER_EXTENSION_LATERAL_EPICONDYLITIS	: { name: "Resisted middle finger extension (Lateral Epicondylitis)"},
        COZENS_TEST_LATERAL_EPICONDYLITIS	: { name: "Cozen's Test (Lateral Epicondylitis)"},

        //WRIST
        ALLEN_TEST_VASCULAR	: { name: "Allen Test (Vascular)"},
        FINKELSTEIN_TEST_DEQUERVAIN_SYNDROME	: { name: "Finkelstein Test (Dequervain Syndrome)"},
        TINEL_SIGN_CTS	: { name: "Tinel Sign (CTS)"},
        WATSON_TEST	: { name: "Watson Test"},
        PIANO_KEY_TEST_TFCC	: { name: "Piano key Test (TFCC)"},

        //LUMBAR_SPINE
        SLUMP_TEST	: { name: "Slump Test"},
        SEATED_KEMPS_TEST	: { name: "Seated Kemp's Test"},
        SAHRMANN_ABDOMINAL_TEST	: { name: "Sahrmann abdominal Test"},

        //HIP
        FABER_TEST	: { name: "FABER Test"},
        PELVIC_COMPRESSION_TEST	: { name: "Pelvic Compression Test"},
        OBER_TEST_ITB	: { name: "Ober Test (ITB)"},
        PELVIC_ROCKING	: { name: "Pelvic Rocking"},
        QUADRANT_TEST_LABRUM	: { name: "Quadrant Test (Labrum)"},
        THOMAS_TEST_PSOAS	: { name: "Thomas Test (Psoas)"},
        ANTERIOR_IMPINGEMENT_TEST	: { name: "Anterior impingement Test"},
        FLEXION_INTERNATIONAL_ROTATION_TEST	: { name: "Flexion international rotation test"},
        FADIR_TEST	: { name: "FADIR Test"},
        SCOUR_TEST	: { name: "Scour Test"},

        //SACROILIAC
        GAENSLENS_TEST	: { name: "Gaenslen's Test"},
        SI_DISTRACTION_TEST	: { name: "SI Distraction Test"},
        THIGH_THRUST_TEST	: { name: "Thigh thrust Test"},
        SI_COMPRESSION_TEST	: { name: "SI compression Test"},
        SACRAL_THRUST_TEST	: { name: "Sacral thrust Test"},

        //KNEE
        ANTERIOR_DRAWER_TEST	: { name: "Anterior Drawer Test"},
        POSTERIOR_DRAWER_TEST	: { name: "Posterior Drawer Test"},
        APLEYS_TEST_MENISCUS	: { name: "Apley's Test (Meniscus)"},
        LACHMANNS_TEST	: { name: "Lachmann's Test"},
        MCMURRYS_TEST	: { name: "McMurry's Test"},
        PATELLAR_APPREHENSION_TEST	: { name: "Patellar apprehension Test"},
        VALGUS_STRESS_TEST	: { name: "Valgus stress Test"},
        VARUS_STRESS_TEST	: { name: "Varus stress Test"},

        //ANKLE
        KNEE_TO_WALL_TEST_MOBILITY	: { name: "Knee to Wall Test (mobility)"},
        SYNDESMOSIS_SQUEEZE_TEST	: { name: "Syndesmosis squeeze Test"},
        EXTERNAL_ROTATION_TEST_DELTOID_SYNDESMOSIS	: { name: "External Rotation Test (Deltoid syndesmosis)"},
        TALUS_TILT_TEST	: { name: "Talus TILT Test"},
        ANTERIO_DRAWER_TEST	: { name: "Anterio drawer Test"},
        FORCED_DORSIFLEXION_TEST	: { name: "Forced dorsiflexion Test "},

        //VISCERAL
        MCBURNEYS_POINT_REBOUND_TENDERNESS_TEST	: { name: "McBurney's point rebound tenderness test"},
        ROSENBACH_SIGN	: { name: "Rosenbach Sign"},
        MURPHY_SIGN	: { name: "Murphy Sign"},
        VOCAL_FREMITUS	: { name: "Vocal fremitus"},
        BOAS_POINT	: { name: "Boas Point"},

        //WADDELLS_SIGN
        TENDERNESS	: { name: "Tenderness"},
        SIMULATION	: { name: "Simulation"},
        DISTRACTION	: { name: "Distraction"},
        REGIONAL	: { name: "Regional"},
        OVERREACTION	: { name: "Overreaction"}
});

export default TestEnum;