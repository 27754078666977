import React, {Component} from "react";
import TypeEnum from "../../assessment/enums/dermatomes/TypeEnum";
import DermatomesScreenUtil from "../../assessment/utils/DermatomesScreenUtil";
import DermatomesCervicalEnum from "../../assessment/enums/dermatomes/DermatomesCervicalEnum";
import DermatomesDropdownValues from "../../assessment/enums/dermatomes/DermatomesDropdownValues";
import DermatomesScreenRowColorsEnum from "../../assessment/enums/dermatomes/DermatomesScreenRowColorsEnum";
import DermatomesThoracicEnum from "../../assessment/enums/dermatomes/DermatomesThoracicEnum";
import DermatomesLumbarEnum from "../../assessment/enums/dermatomes/DermatomesLumbarEnum";
import DermatomesSacralEnum from "../../assessment/enums/dermatomes/DermatomesSacralEnum";
import DermatomesUtil from "../../assessment/utils/DermatomesUtil";

export default class DermatomesEMRDashboardView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dermatomes: this.splitDermatomes(props)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.dermatomes !== this.props.dermatomes) {
            const dermatomes = this.splitDermatomes(this.props);
            this.setState({dermatomes});
        }
    }

    splitDermatomes = (props) => {
        let dermatomes = {};

        if (!props.dermatomes || !props.dermatomes.items) {
            return {...props.dermatomes};
        }

        let cervical = props.dermatomes.items.filter(item => item.type === TypeEnum.CERVICAL);
        let thoracic = props.dermatomes.items.filter(item => item.type === TypeEnum.THORACIC);
        let lumbar = props.dermatomes.items.filter(item => item.type === TypeEnum.LUMBAR);
        let sacral = props.dermatomes.items.filter(item => item.type === TypeEnum.SACRAL);

        Object.assign(dermatomes, {cervical, thoracic, lumbar, sacral});

        return {...dermatomes};
    }

    render() {
        return (
            <div>
                <div className={"content-row"} style={{display: "block"}}>
                    {DermatomesScreenUtil.DermatomesViewTableHeader(
                        "Dermatomes",
                        DermatomesUtil.isDermatomesEntered(this.state.dermatomes),
                        {},
                        this.state.dermatomes.none ? "Normal" : null
                    )}
                    {this.state.dermatomes && <table className={"emr-table content-card"}>
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.dermatomes.cervical, "Cervical", DermatomesCervicalEnum, DermatomesDropdownValues, DermatomesScreenRowColorsEnum.DERMATOMESCERVICAL.color)}
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.dermatomes.thoracic, "Thoracic", DermatomesThoracicEnum, DermatomesDropdownValues, DermatomesScreenRowColorsEnum.DERMATOMESTHORACIC.color)}
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.dermatomes.lumbar, "Lumbar", DermatomesLumbarEnum, DermatomesDropdownValues, DermatomesScreenRowColorsEnum.DERMATOMESLUMBAR.color)}
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.dermatomes.sacral, "Sacral", DermatomesSacralEnum, DermatomesDropdownValues, DermatomesScreenRowColorsEnum.DERMATOMESSACRAL.color)}
                    </table>}
                </div>
            </div>
        );
    }
}
