import React, {Component} from "react";
import {Modal} from "../../components";
import RemotingService from "../../services/remoting-service/RemotingService";
import EnumInsuranceType from "./enums/EnumInsuranceType";
import DateUtil from "../../utils/DateUtil";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import InsuranceCardStatus from "./InsuranceCardStatus";
import {base64ToBlob} from "../../utils/FileDownloader";
import "./ViewInsurance.css"
import EnumCoPayUnit from "./enums/EnumCoPayUnit";

class ViewInsurance extends Component {

    constructor(props) {
        super(props);
        this.state = {
            insurance: {}
        };
    }

    componentDidMount() {
        RemotingService.getRemoteCall(`api/insurance/${this.props.insuranceId}`, null, (insurance) => {
            this.setState({insurance});
        });
        RemotingService.getRemoteCall(`api/patient/${this.props.patientId}/document/${InsuranceCardStatus.INSURANCE_FRONT}`, null, (file) => {
            if (file) {
                this.setState({cardFrontUrl: window.URL.createObjectURL(base64ToBlob(file.content, file.mimeType))});
            }
        });
        RemotingService.getRemoteCall(`api/patient/${this.props.patientId}/document/${InsuranceCardStatus.INSURANCE_BACK}`, null, (file) => {
            if (file) {
                this.setState({cardBackUrl: window.URL.createObjectURL(base64ToBlob(file.content, file.mimeType))});
            }
        });
    }

    render() {
        return <Modal visible={true} header="Insurance Details" closeAction={this.props.closeAction} contentStyle={{maxHeight: 600, overflowY: 'auto'}}>
            <div className="dim14-dialog-body" style={{width:"600px"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-6 insurance-card-container">
                            <img className="insurance-card-image" src={this.state.cardFrontUrl} alt=""/>
                        </div>
                        <div className="col-6 insurance-card-container">
                            <img className="insurance-card-image" src={this.state.cardBackUrl}  alt=""/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 dim14-form-label">TPA</div>
                        <div className="col-8">
                            {this.state.insurance.tpa ?
                                this.state.insurance.tpa.name :
                                (this.state.insurance.insurer ? this.state.insurance.insurer.name : this.state.insurance.customTpa)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-4 dim14-form-label">Insurance</div>
                        <div className="col-8">{this.state.insurance.company ? this.state.insurance.company.name : this.state.insurance.customCompany}</div>
                    </div>
                    <div className="row">
                        <div className="col-4 dim14-form-label">Network</div>
                        <div className="col-8">{this.state.insurance.network ? this.state.insurance.network.name : this.state.insurance.customNetwork}</div>
                    </div>
                    {
                        this.state.insurance.network && this.state.insurance.network.policyRequired &&
                        <div className="row">
                            <div className="col-4 dim14-form-label">Policy Name</div>
                            <div className="col-8">{this.state.insurance.policyName}</div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-4 dim14-form-label">Policy Number</div>
                        <div className="col-8">{this.state.insurance.policyNumber}</div>
                    </div>
                    <div className="row">
                        <div className="col-4 dim14-form-label">Member ID</div>
                        <div className="col-8">{this.state.insurance.memberId}</div>
                    </div>
                    <div className="row">
                        <div className="col-4 dim14-form-label">Validity From</div>
                        <div className="col-8">{this.state.insurance.validityFrom ? DateUtil.formatDateMMM(this.state.insurance.validityFrom) : null}</div>
                    </div>
                    <div className="row">
                        <div className="col-4 dim14-form-label">Validity To</div>
                        <div className="col-8">{this.state.insurance.validityTo ? DateUtil.formatDateMMM(this.state.insurance.validityTo) : null}</div>
                    </div>
                    {
                        this.state.insurance.type === "MANUAL" ?
                            <>
                                <div className="row">
                                    <div className="col-4 dim14-form-label">Co-Pay</div>
                                    <div className="col-8">{this.state.insurance.coPay}</div>
                                </div>
                                <div className="row">
                                    <div className="col-4 dim14-form-label">Co-Pay Unit</div>
                                    <div className="col-8">{EnumCoPayUnit[this.state.insurance.coPayUnit]}</div>
                                </div>
                                <div className="row">
                                    <div className="col-4 dim14-form-label">Max Co-Pay</div>
                                    <div className="col-8">{this.state.insurance.coPayMax}</div>
                                </div>
                            </> : null
                    }
                    <div className="row">
                        <div className="col-4 dim14-form-label">Type</div>
                        <div className="col-8">{EnumInsuranceType[this.state.insurance.type]}</div>
                    </div>
                    {
                        this.state.insurance.type === "AUTOMATIC" ?
                            <div className="row">
                                <DataTable value={this.state.insurance.benefits}
                                           header="Benefits"
                                           emptyMessage="No records">
                                    <Column field="code" header="Code" style={{width:"150px"}}/>
                                    <Column field="value" header="Value"/>
                                </DataTable>
                            </div> : null
                    }
                </div>
            </div>
        </Modal>
    }

}

export default ViewInsurance;
