import React, {Component} from "react";
import "./FamilySelect.css";
import {connect} from "react-redux";
import {SelectInput} from "../../components";

class FamilySelect extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {disabled} = this.props;

        return (
            <SelectInput value={this.props.value}
                         options={this.props.relationshipTypes}
                         optionLabel="test"
                         displayProperty={"name"}
                         disabled={disabled}
                         searchEnabled={false}
                         className="family-select"
                         style={{width: '100%', ...this.props.style}}
                         onChange={(val) => {
                             this.props.onChange(val)
                         }}
            />
        );
    }
}

function mapStateToProps(state) {
    return {
        relationshipTypes: state.base.relationshipTypes
    };
}

export default connect(mapStateToProps)(FamilySelect);
