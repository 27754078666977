import Enum from "../../../../../../enums/Enum";

const FocusEnum = new Enum({
        PROPER_DESK: {name: "Proper desk set up and modifications"},
        POSTURAL_SUPPORT_LUMBAR: {name: "Postural Support / Lumbar"},
        POSTURAL_SUPPORT_WRIST: {name: "Postural Support / Wrist"},
        BACK: {name: "Back"},
        HIPS: {name: "Hips"},
        NECK: {name: "Neck"},
        SHOULDERS: {name: "Shoulders"},
        LEGS: {name: "Legs"},
        APPLY_ICE_10_MINUTES_OFF_ON: {name: "Apply ice 10 minutes on/10 minutes off/10 minutes on 2-3x per day"},
        APPLY_ICE_10_MINUTES: {name: "Apply ice 10-15 minutes 2-3x per day"},
        APPLY_HEAT_10_MINUTES_OFF_ON: {name: "Apply heat 10 minutes on/10 minutes off/10 minutes on 2-3x per day"},
        APPLY_HEAT_10_MINUTES: {name: "Apply heat 15-20 minutes 2-3x per day"},
        HOURS_24_48: {name: "24-48 hrs"},
        WEEK_1: {name: "1 week"},
        WEEKS_2: {name: "2 weeks"},
        OTHER: {name: "Apply heat 15-20 minutes 2-3x per day"}
});

export default FocusEnum;
