import {ExpansionPanel as MuiExpansionPanel, withStyles} from "@material-ui/core";

const ExpansionPanel = withStyles({
    root: {
        position: "inherit",
        boxShadow: "0px 2px 15px rgba(0,0,0,.15)",
        borderRadius: 8,
        marginBottom: 15,
    }
})(MuiExpansionPanel);

export default ExpansionPanel;