import React, {Component} from 'react';
import Modal from '../../components/modal/Modal';
import {Button} from 'react-bootstrap';
import FileUploader from '../../components/file-uploader/FileUploader';
import RemotingService from "../../services/remoting-service/RemotingService";

class InsuranceCardStatus extends Component {

    static INSURANCE_FRONT = 'INSURANCE_FRONT';
    static INSURANCE_BACK = 'INSURANCE_BACK';

    state = {
        showInsuranceModalVisible: false,
        insuranceFrontfileValue: null,
        insuranceFrontfileNewValue: null,
        insuranceBackfileValue: null,
        insuranceBackfileNewValue: null,
        insuranceFrontfileChanged: false,
        insuranceBackfileChanged: false
    };

    componentDidMount() {
        if (!this.props.patientId) {
            return;
        }

        RemotingService.getRemoteCall('api/patient/' + this.props.patientId + '/card-status', null, (cardStatusDTO) => {
            this.retrieveFile(cardStatusDTO.insuranceFrontUploaded, InsuranceCardStatus.INSURANCE_FRONT,
                (dto) => this.setState({insuranceFrontfileValue: dto}));

            this.retrieveFile(cardStatusDTO.insuranceBackUploaded, InsuranceCardStatus.INSURANCE_BACK,
                (dto) => this.setState({insuranceBackfileValue: dto}));

            this.setState({...cardStatusDTO});
        });
    }

    retrieveFile(isUploaded, documentType, callBack) {
        if (isUploaded) {
            RemotingService.getRemoteCall('api/patient/' + this.props.patientId + '/document/' + documentType, null, callBack);
        }
    };

    showInsuranceModal() {
        this.setState({
            showInsuranceModalVisible: true,
            insuranceFrontfileChanged: false,
            insuranceBackfileChanged: false,
            insuranceFrontfileNewValue: this.state.insuranceFrontfileValue,
            insuranceBackfileNewValue: this.state.insuranceBackfileValue
        });
    }

    render() {
        const insuranceCardUploaded = this.state.insuranceFrontfileValue || this.state.insuranceBackfileValue;
        const publicURL = process.env.PUBLIC_URL;
        return (
            <>
                <Button variant="outline-primary" className={'w-100'} size={"sm"} onClick={() => this.showInsuranceModal()}>
                    {
                        insuranceCardUploaded ?
                            <img width="50px" src={publicURL + "/assets/insurance/ins_card_added.png"}/>
                            :
                            <img width="50px" src={publicURL + "/assets/insurance/ins_card_not_added.png"}/>
                    }
                </Button>
                {this.renderInsuranceModal()}
            </>
        );
    }

    renderInsuranceModal() {
        return this.state.showInsuranceModalVisible ?
            (<Modal visible={this.state.showInsuranceModalVisible}
                    header="Insurance Card"
                    submitContent="Upload"
                    submitAction={() => this.handleUpdateInsuranceModal()}
                    submitDisabled={!this.props.editable}
                    closeAction={() => this.setState({showInsuranceModalVisible: false})}
            >
                {this.renderInsuranceModalBody()}
            </Modal>)
            : null;
    }

    renderInsuranceModalBody() {
        return (
            <div className={"dim14-dialog-body"} style={{paddingTop: '5px'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignContent: 'space-between',
                        justifyContent: 'center'
                    }}>
                        <FileUploader
                            value={this.state.insuranceFrontfileNewValue}
                            onChange={(newValue) => this.handleinsuranceFrontfileChange(newValue)}/>

                    </div>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignContent: 'space-between',
                        justifyContent: 'center',
                        paddingTop: '15px'
                    }}>
                        <FileUploader
                            value={this.state.insuranceBackfileNewValue}
                            onChange={(newValue) => this.handleinsuranceBackfileChange(newValue)}/>
                    </div>
                </div>
            </div>
        );
    }

    handleinsuranceFrontfileChange(newValue) {
        this.setState({insuranceFrontfileNewValue: newValue, insuranceFrontfileChanged: true});
    };

    handleinsuranceBackfileChange(newValue) {
        this.setState({insuranceBackfileNewValue: newValue, insuranceBackfileChanged: true});
    };

    handleUpdateInsuranceModal() {
        if (this.state.insuranceFrontfileChanged) {
            this.uploadFile(InsuranceCardStatus.INSURANCE_FRONT, this.state.insuranceFrontfileNewValue,
                () => {
                    this.setState({insuranceFrontfileValue: this.state.insuranceFrontfileNewValue})
                });
        }
        if (this.state.insuranceBackfileChanged) {
            this.uploadFile(InsuranceCardStatus.INSURANCE_BACK, this.state.insuranceBackfileNewValue,
                () => {
                    this.setState({insuranceBackfileValue: this.state.insuranceBackfileNewValue})
                });
        }
        this.setState({showInsuranceModalVisible: false});
    }

    uploadFile(documentType, fileValue, callBack) {
        if (fileValue) {
            RemotingService.postRemoteCall('api/patient/' + this.props.patientId + '/document/' + documentType, fileValue, () => {
                this.setState({showInsuranceModalVisible: false});
                callBack(fileValue);
            });
        } else {
            RemotingService.deleteRemoteCall('api/patient/' + this.props.patientId + '/document/' + documentType, null, () => {
                this.setState({showInsuranceModalVisible: false});
                callBack(fileValue);
            });
        }
    }
}

export default InsuranceCardStatus;
