import React from 'react';
import {BreadCrumb, Item} from '../../../../components/bread-crumb/BreadCrumb';
import {Button} from "react-bootstrap";
import AuthService from "../../../../services/auth-service/AuthService";

const StaffProfileTopBar = props => {
    return (
        <div className="row" style={{margin:'5px', display:'flex',  justifyContent: 'space-between'}}>
            <div className="col-3">
                <BreadCrumb>
                    <Item to="/staff" label="Providers"/>
                    <Item to="/" active label={props.staffFullName}/>
                </BreadCrumb>
            </div>
            {AuthService.isManagerOrSuperUser() &&
                (
                    <div className="col-2">
                        <Button id="replaceProviderButton" variant="success" style={{width: '150px', marginLeft: '100px', marginTop: '50px'}}
                                onClick={props.handleReplaceProviderClick}>Replace Provider</Button>
                    </div>
                )
            }
        </div>
    );
};

export default StaffProfileTopBar;