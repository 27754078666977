import {GenericChecker} from "../../subjective/GenericChecker";
import ObjectUtil from "../../../../../utils/ObjectUtil";

export function OrChecker(metas) {

    let checker = new GenericChecker();

    checker.isMissing = (entity) => {
        let result = true;
        for (const meta of metas) {
            const obj = ObjectUtil.get(entity, meta.path);
            result = result && meta.checker.isMissing(obj); // at least one item is not missing
            if (!result) {
                break;
            }
        }
        return result;
    }

    return checker;
}