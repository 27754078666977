import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SpecialStatusIcons from "../../../SpecialStatusIcons";
import dialog from "../../../../../components/dialog/Dialog";
import SpecialStatusUpdateModal from "./SpecialStatusUpdateModal";
import RemotingService from "../../../../../services/remoting-service/RemotingService";

class PersonalInformationSpecialStatus extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            specialStatusList: this.props.specialStatusList,
            languages: []
        };
    }

    componentDidMount() {
        this.retrieveLanguages();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.specialStatusList !== this.props.specialStatusList) {
            this.setState({specialStatusList: this.props.specialStatusList});
        }
    }

    retrieveLanguages() {
        RemotingService.getRemoteCall('api/reference/language', null, (result) => {
            this.setState({languages: result.map(l => l.name)})
        });
    }

    showUpdateModal() {
        dialog({
            title: "Special Status",
            component: <SpecialStatusUpdateModal patientId={this.props.patientId}
                                                 specialStatusList={this.state.specialStatusList}
                                                 languages={this.state.languages}
                                                 onUpdate={specialStatusList => this.setState({specialStatusList})}/>
        });
    }

    render() {
        return (
            <>
                <div className="card-body special-status-wrapper small-font">
                    <div className="display-flex" style={{maxWidth:"100%"}}>
                        <label style={{margin: "5px -5px", fontWeight: 500}}>Special Status:</label>
                        <div className="display-flex" style={{margin: "0 10px", flexWrap:"wrap"}}>
                            <SpecialStatusIcons specialStatusList={this.state.specialStatusList}/>
                        </div>
                    </div>
                    {this.props.editable && <FontAwesomeIcon icon={["fa", "edit"]} onClick={() => this.showUpdateModal()}/>}
                </div>
            </>
        );
    }
}

export default PersonalInformationSpecialStatus;