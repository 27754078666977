import MoneyUtil from "./MoneyUtil";

class NumberUtil {

    toFixed(value, fractionDigits) {
        return Number(value).toFixed(fractionDigits);
    }

    isNumeric(value) {
        return !isNaN(parseFloat(value)) && isFinite(value);
    }

    toFixedByCurrency(value, currency) {
        return Number(this.toFixed(value, MoneyUtil.getFractionDigits(currency)));
    }

    toFixedMaxLength(value, maxDecimalLength) {
        const currDecimalPlaces = value.toString().split('.')[1]? value.toString().split('.')[1].length : 0;
        const decimalPlaces = currDecimalPlaces > maxDecimalLength ? maxDecimalLength : currDecimalPlaces;
        return parseFloat(value).toFixed(decimalPlaces);
    }
}

export default new NumberUtil();