const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
}

const onlyUniqueBasedOnField = (fieldName) => {
    return (value, index, self) => {
        return self.map(i => i[fieldName]).indexOf(value[fieldName]) === index;
    }
}

const extract = (arr, prop) => {
    if (!arr) {
        return [];
    }
    let result = [];
    for (let i = 0; i < arr.length; i++) {
        result.push(arr[i][prop]);
    }
    return result;
}

const isEmpty = (value) => {
    return (value == null || value.length === 0);
}

export {onlyUnique, onlyUniqueBasedOnField, extract, isEmpty};