import Enum from "../../../../../../enums/Enum";

const ManipulationEnum = new Enum({
    Spinal: {name: "Spinal"},
    Joint: {name: "Joint"},
    Visceral: {name: "Visceral"},
    Cranial: {name: "Cranial"},
    Other: {name: "Other"},
});

export default ManipulationEnum;