import React, {Component} from "react";
import "../../visithistory/VisitHistory.css";
import ReportViewUtils from "./ReportViewUtils";
import ConditionActiveEnum from "../../subjective/enums/history/ConditionActiveEnum";

export default class FamilyHistoryReportView extends Component {
    render() {
        const familyHistory = this.props.familyHistory;

        if (ReportViewUtils.isFamilyHistoryNull(familyHistory)) {
            return null;
        }

        if (familyHistory.none) {
            return (<div>No Family History</div>)
        }

        return (
            <div style={{paddingLeft: 10}}>
                {familyHistory.items &&
                    familyHistory.items.map(item =>
                        <div className={"row"}>
                            <div className={"col-3"}>
                                <b>{item.conditionActive ? ConditionActiveEnum[item.conditionActive].name : ''} - {item.snomedCode?.name}</b>
                            </div>
                            <div className={"col-9"}>
                                {item.relationship ? ": " + item.relationship.name : ""}
                                {item.relationshipGender ? ", " + item.relationshipGender.name : ""}
                                {item.detail == null ? "" : (", " + item.detail)}
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}
