import React from "react";
import CancellationPolicyKwtTextEnum from "./enum/CancellationPolicyKwtTextEnum";

const Part1 = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <p>{CancellationPolicyKwtTextEnum.Paragraph1[selectedLanguage]}</p>
            <p>{CancellationPolicyKwtTextEnum.Paragraph2_Part1[selectedLanguage]}
                <b> {CancellationPolicyKwtTextEnum.Paragraph2_Bold_Part[selectedLanguage]} </b>
                {CancellationPolicyKwtTextEnum.Paragraph2_Part2[selectedLanguage]}
            </p>
        </>
    )
}

const Part2 = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <h4 className="text-center">{CancellationPolicyKwtTextEnum.Title3[selectedLanguage]}</h4>
            <div style={{margin: "0px 15px"}}>
                <p>{CancellationPolicyKwtTextEnum.Paragraph3_Part1[selectedLanguage]}
                    <b><u> {CancellationPolicyKwtTextEnum.Paragraph3_Bold_Part[selectedLanguage]} </u></b>
                    {CancellationPolicyKwtTextEnum.Paragraph3_Part2[selectedLanguage]}</p>
                <p>{CancellationPolicyKwtTextEnum.Paragraph4[selectedLanguage]}
                    <b><u
                        style={{fontSize: "18px"}}> {CancellationPolicyKwtTextEnum.Paragraph4_Bold_Part[selectedLanguage]}</u></b>
                </p>
                <p>{CancellationPolicyKwtTextEnum.Paragraph5_Part1[selectedLanguage]}
                    <b> {CancellationPolicyKwtTextEnum.Paragraph5_Bold_Part[selectedLanguage]} </b>
                    {CancellationPolicyKwtTextEnum.Paragraph5_Part2[selectedLanguage]}</p>
            </div>
        </>
    )
}

const Part3 = (props) => {
    const {selectedLanguage, patientName} = props;

    return (
        <>
            <p>{CancellationPolicyKwtTextEnum.Paragraph6[selectedLanguage]}</p>
            <p>{CancellationPolicyKwtTextEnum.Paragraph7[selectedLanguage]}</p>
            <p>{CancellationPolicyKwtTextEnum.Paragraph8_Part1[selectedLanguage]}
                <b> {patientName} </b>
                {CancellationPolicyKwtTextEnum.Paragraph8_Part2[selectedLanguage]}</p>
        </>
    )
}

export default {
    Part1,
    Part2,
    Part3
};