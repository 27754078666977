import React from 'react';
import "./AppointmentProgress.css"
import { Progress } from 'react-sweet-progress';
import "react-sweet-progress/lib/style.css";

export class AppointmentProgress extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        let completed = this.props.completedAppointmentCount;
        let total = this.props.totalAppointmentCount;

        return (
            <div className="card-info-holder">
                <strong>Appointment Progress</strong>
                <div className="col height-90 vertical-align-center">
                    <div style={{width: "inherit"}}>
                        <div style={{fontWeight: "bold"}}>
                            {completed} / {total}
                        </div>
                        <div>
                            Appointments has been completed
                        </div>
                        <div>
                            <Progress percent={~~(completed/total * 100)}
                                      theme={{
                                          active: {
                                              color: '#2ec928'
                                          }
                                      }}/>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}