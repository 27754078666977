import React, { Component } from "react";
import PropTypes from "prop-types";
import "./RadioInput.css";
import _uniqueId from "lodash/uniqueId";

export default class RadioInput extends Component {
    static defaultProps = {
        id: null,
        className: "",
        disabled: false,
        title: null,
        hidden: false,
        onChange: null,
        style: null,
        options: null,
        value: "",
        orientation: "Horizontal",
        displayProperty: null,
        valueProperty: null,
        itemTemplate: null
    };

    static propTypes = {
        id: PropTypes.string,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        title: PropTypes.string,
        hidden: PropTypes.bool,
        onChange: PropTypes.func,
        style: PropTypes.object,
        options: PropTypes.array,
        value: PropTypes.any,
        orientation: PropTypes.string,
        displayProperty: PropTypes.string,
        valueProperty: PropTypes.string,
        itemTemplate: PropTypes.func
    };

    constructor(props){
        super(props);
        if(props.id){
            this.id = props.id;
        } else{
            this.id = _uniqueId("dim14-radio-id-");
        }
    }

    handleOnChange = (e) => {
        let value = e.target.value;
        this.props.onChange(value);
    };

    handleOnClick = (e) => {
        const target = e.currentTarget;
        if(target.checked){
            this.props.onChange(null);
        }
    }

    buttonRenderer(){

        let componentOrientation = "Dim14RadioDiv " + this.props.orientation;

        let isAdvanced = this.props.displayProperty && this.props.valueProperty;

        return this.props.options.map((choice, i) => {

            let checked;
            let value;
            let displayText;
            if(isAdvanced){
                checked = (this.props.value ? this.props.value.toString() : null) === choice[this.props.valueProperty].toString();
                value = choice[this.props.valueProperty];
                displayText = choice[this.props.displayProperty];
            } else {
                checked = this.props.value != null && this.props.value.toString() === choice.toString();
                value = choice;
                displayText = choice.toString();
            }

            let elementId = this.id + "-" + i.toString();

            let displayLabel = <label htmlFor={elementId} >
                {displayText}
            </label>;

            if(this.props.itemTemplate){
                displayLabel = this.props.itemTemplate(choice, elementId);
            }

            return <div key={i} className={componentOrientation} title={this.props.title}>
                <input
                    type="radio"
                    id={elementId}
                    name={this.id}
                    disabled={this.props.disabled}
                    onChange={(e) => this.handleOnChange(e)}
                    onClick={(e) => this.handleOnClick(e)}
                    style={this.props.style}
                    checked={checked}
                    value={value}
                />
                {displayLabel}
            </div>;
        });

    }

    render() {
        let componentClassName = "Dim14RadioInput " + this.props.className;
        return (
            <div id={this.id} className={componentClassName} hidden={this.props.hidden}>
                {this.buttonRenderer()}
            </div>
        )
    }
}
