import Enum from "../../../enums/Enum";

const EnumScheduleGenerationPreference = new Enum({
    FREEZE_CURRENT_SCHEDULE_INCLUDING_ROOMS: "Freeze current schedule including rooms",
    FREEZE_CURRENT_SCHEDULE_WORK_DAYS: "Freeze current schedule work days",
    MINIMUM_CHANGE: "Minimum change",
    CHANGE_SCHEDULE_FREELY: "Change schedule freely",
    EXCLUDE_FROM_SCHEDULING: "Exclude from scheduling"
});

export default EnumScheduleGenerationPreference;