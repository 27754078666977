import Enum from "../../../../../../enums/Enum";

const PalpationFindingEnum = new Enum({
        SWELLING: {name: "Swelling"},
        TENDER: {name: "Tender"},
        WARMTH: {name: "Warmth"},
        TRIGGER_POINT: {name: "Trigger Point"},
        PULSATING: {name: "Pulsating"},
        PITTING_EDEMA: {name: "Pitting Edema"},
        MUSCLE_TIGHTNESS: {name: "Muscle Tightness"},
        OTHER: {name: "Other"}
});

export default PalpationFindingEnum;