import React, {Component} from "react";
import "../../visithistory/VisitHistory.css";
import NatureEnum from "../../subjective/enums/complaint/NatureEnum";
import ReportViewUtils from "./ReportViewUtils";

export default class SymptomReportView extends Component {
    render() {
        const complaint = this.props.complaint;

        if (ReportViewUtils.isSymptomsNull(complaint)) {
            return null;
        }

        return (
            <div style={{paddingLeft: 10}}>
                <div className={"row"}>
                    <div className={"col-3"}>
                        <b>Pain (VAS)</b>
                    </div>
                    <div className={"col-9"}>
                        : {complaint.pain ? complaint.pain + '/10.' : 'NA'} {complaint.painDetail}
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-3"}>
                        <b>Swelling</b>
                    </div>
                    <div className={"col-9"}>
                        : {complaint.swelling || 'NA'} {complaint.swellingDetail}
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-3"}>
                        <b>Referral</b>
                    </div>
                    <div className={"col-9"}>
                        : {complaint.referral || 'NA'}
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-3"}>
                        <b>Nature</b>
                    </div>
                    <div className={"col-9"}>
                        : {complaint.nature ? NatureEnum[complaint.nature].name : 'NA'}
                    </div>
                </div>
            </div>
        );
    }

}
