import React, {Component} from "react";
import ConservativeTreatmentReceivedEnum
    from "../../subjective/enums/treatmentreceived/ConservativeTreatmentReceivedEnum";
import ResponseEnum from "../../subjective/enums/treatmentreceived/ResponseEnum";
import OtherTreatmentReceivedEnum from "../../subjective/enums/treatmentreceived/OtherTreatmentReceivedEnum";
import ReportViewUtils from "./ReportViewUtils";

export default class PreviousTreatmentReceivedReportView extends Component {

    render() {
        let divs = [];

        if (ReportViewUtils.isPreviousTreatmentReceivedNull(this.props.treatmentReceived)) {
            return null;
        }

        if (this.props.treatmentReceived.none) {
            return <div>No Treatment Received</div>;
        }

        this.props.treatmentReceived.conservative.forEach((treatment, index) => {
            divs.push(
                [
                    [
                        index == 0 ? "Conservative" : null,
                        ConservativeTreatmentReceivedEnum[treatment.treatmentReceived]?.name ,
                        treatment.detail
                    ].filter(Boolean).join(": "),
                    treatment.response ? "Response: " + ResponseEnum[treatment.response]?.name : null
                ].filter(Boolean).join(", ")
            )
        });

        this.props.treatmentReceived.other.forEach((treatment, index) => {
            divs.push(
                [
                    [
                        index == 0 ? "Other" : null,
                        OtherTreatmentReceivedEnum[treatment.treatmentReceived]?.name , treatment.detail].filter(Boolean).join(": "),
                    treatment.response ? "Response: " + ResponseEnum[treatment.response]?.name : null
                ].filter(Boolean).join(", ")
            )
        });


        return (
            <div className={"visit-history-multiple-content-wrapper"}>
                {divs.join(", ")}
            </div>
        );
    }
}