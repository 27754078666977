import "./MedicalReports.css"
import React, {Component} from "react";
import {DataTable} from "primereact/datatable";
import {Checkbox} from "primereact/checkbox";
import {Column} from "primereact/column";
import DateUtil from "../../../../utils/DateUtil";
import MedicalReportTypeEnum from "./MedicalReportTypeEnum";
import MedicalReportTemplate from "./medical/MedicalReportTemplate";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import MedicalExcuseTemplate from "./exuce/MedicalExcuseTemplate";
import UltrasoundReportTemplate from "./ultrasound/UltrasoundReportTemplate";
import ReferralLetterTemplate from "./referralletter/ReferralLetterTemplate";
import RadiologyRequestTemplate from "./radiology/RadiologyRequestTemplate";
import DischargeSummaryReportTemplate from "./dischargesummary/DischargeSummaryReportTemplate";
import ProcedureReportTemplate from "./procedure/ProcedureReportTemplate";
import ProgressReportTemplate from "./progress/ProgressReportTemplate";
import AttachmentUtils from "../treatment/utils/AttachmentUtils";
import AuthService from "../../../../services/auth-service/AuthService";

export default class MedicalReports extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reportMetaData: [],
            metaVisits: [],
            visits: [...this.props.emr.visits],
        };
    }

    componentDidMount() {
        RemotingService.getRemoteCall('api/visit_history', {emrId: this.props.emr.id}, (metaVisits) => {
            this.setState({metaVisits});

            this.refreshReportMetaData();
        });
    }

    refreshReportMetaData = () => {
        RemotingService.getRemoteCall('api/medical-report/' + this.props.currentVisit.id, null,
            (reportMetaData) => {
                this.setState({reportMetaData});
            });
    }

    checkboxTemplate = (data, column) => {
        return <Checkbox inputId={"entry" + column.rowIndex}
                         checked={this.state.selected === column.rowIndex}
                         onChange={event => this.setState({
                             selectedReportType: event.checked ? data : null,
                             selected: event.checked ? column.rowIndex : -1
                         })}/>
    }

    typeTemplate = (data) => {
        return MedicalReportTypeEnum[data.key].name;
    }

    lastGeneratedByTemplate = (data) => {
        const reportMetaDataForType = this.state.reportMetaData.find(i => i.type === data.key);
        return !reportMetaDataForType ? '' : reportMetaDataForType.lastGeneratedBy;
    }

    lastGenerationTimeTemplate = (data) => {
        const reportMetaDataForType = this.state.reportMetaData.find(i => i.type === data.key);
        return !reportMetaDataForType ? '' :
            DateUtil.formatDateMonthAndYear(reportMetaDataForType.lastGenerationTime, 'DD MMMM YYYY');
    }

    render() {
        this.props.emr.visits.forEach(visit => {
            visit.metaData = this.state.metaVisits.find(m => !m.legacyVisit && m.id === visit.id);
        });

        return (
            <div className={"medical-reports px-4"}>
                <div style={{marginTop: "10px"}}>
                    <h6 className="font-weight-bold my-4">Reports{this.state.selectedReportType ? ' / ' + MedicalReportTypeEnum[this.state.selectedReportType.key].name : ''}</h6>
                    <DataTable responsive autoLayout
                               emptyMessage="No reports"
                               value={MedicalReportTypeEnum.toArray()}
                               lazy={true}>
                        <Column className="medical-reports-table-col-1 border-0" field="checkbox" header=" "
                                body={this.checkboxTemplate}/>
                        <Column className="medical-reports-table-col-7 border-0 text-left" field="type" header="Report"
                                body={this.typeTemplate}/>
                        <Column className="medical-reports-table-col-2 border-0 text-left" header="Generated By"
                                body={this.lastGeneratedByTemplate}/>
                        <Column className="medical-reports-table-col-2 border-0 text-left" header="Last Generated"
                                body={this.lastGenerationTimeTemplate}/>
                    </DataTable>
                </div>
                {this.renderReportTemplate()}
            </div>
        );
    }

    renderReportTemplate = () => {
        if (!this.state.selectedReportType) {
            return null;
        }

        const commonProps = {
            emr: this.props.emr,
            readOnly: !AuthService.userHasAuthority("VIEW_EMR_DASHBOARD"),
            currentVisitIndex: this.props.currentVisitIndex,
            appointment: this.props.appointment,
            onReportGenerated: this.handleReportGenerated
        };

        switch (this.state.selectedReportType.key) {
            case "DISCHARGE_SUMMARY":
                return <DischargeSummaryReportTemplate {...commonProps}/>;
            case "MEDICAL_EXCUSE":
                return <MedicalExcuseTemplate {...commonProps}/>;
            case "MEDICAL_REPORT":
                return <MedicalReportTemplate {...commonProps}/>;
            case "PROCEDURE_REPORT":
                return <ProcedureReportTemplate {...commonProps}/>;
            case "PROGRESS_REPORT":
                return <ProgressReportTemplate {...commonProps}/>;
            case "RADIOLOGY_REQUEST":
                return <RadiologyRequestTemplate {...commonProps}/>;
            case "REFERRAL_LETTER":
                return <ReferralLetterTemplate {...commonProps}/>;
            case "ULTRASOUND_REPORT":
                return <UltrasoundReportTemplate {...commonProps}/>;
        }
    }

    handleReportGenerated = (file) => {
        this.refreshReportMetaData();
        this.setState({selectedReportType: null, selected: -1});
        AttachmentUtils.download(file);
    }

}
