import React, {Component} from "react";
import "../../VisitHistory.css"
import TypeEnum from "../../../assessment/enums/dermatomes/TypeEnum";
import MyotomesCervicalEnum from "../../../assessment/enums/dermatomes/MyotomesCervicalEnum";
import MyotomesDropdownValues from "../../../assessment/enums/dermatomes/MyotomesDropdownValues";
import MyotomesThoracicEnum from "../../../assessment/enums/dermatomes/MyotomesThoracicEnum";
import MyotomesLumbarEnum from "../../../assessment/enums/dermatomes/MyotomesLumbarEnum";
import MyotomesSacralEnum from "../../../assessment/enums/dermatomes/MyotomesSacralEnum";

export default class MyotomesViewHistory extends Component {

    constructor(props) {
        super(props);

        this.createMyotomesTable = this.createMyotomesTable.bind(this);
    }

    createMyotomesTable() {
        let tableEntriesCervical = [];
        let tableEntriesThoracic = [];
        let tableEntriesLumbar = [];
        let tableEntriesSacral = [];
        let headerState = {
            cervicalHeader: false,
            thoracicHeader: false,
            lumbarHeader: false,
            sacralHeader: false
        };
        this.props.myotomes.items.forEach((item, index) => {
            if (item.type === TypeEnum.CERVICAL) {
                if (!headerState.cervicalHeader) {
                    tableEntriesCervical.push(
                        <tr key={"cervicalHeaderMyotomes" + index} style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                            <th>{TypeEnum[item.type]}</th>
                            <th>Right</th>
                            <th>Left</th>
                            <th>Details</th>
                        </tr>
                    );
                    headerState.cervicalHeader = true;
                }
                tableEntriesCervical.push(
                    <tr key={"cervicalTableMyotomes" + index} style={{backgroundColor: "white"}}>
                        <td>{MyotomesCervicalEnum[item.name]?.value}</td>
                        <td>{MyotomesDropdownValues[item.rightValue]?.name}</td>
                        <td>{MyotomesDropdownValues[item.leftValue]?.name}</td>
                        <td>{item.details}</td>
                    </tr>
                );
            } else if (item.type === TypeEnum.THORACIC) {
                if (!headerState.thoracicHeader) {
                    tableEntriesThoracic.push(
                        <tr key={"thoracicHeaderMyotomes" + index} style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                            <th>{TypeEnum[item.type]}</th>
                            <th>Right</th>
                            <th>Left</th>
                            <th>Details</th>
                        </tr>
                    );
                    headerState.thoracicHeader = true;
                }
                tableEntriesThoracic.push(
                    <tr key={"thoracicTableMyotomes" + index} style={{backgroundColor: "white"}}>
                        <td>{MyotomesThoracicEnum[item.name]?.value}</td>
                        <td>{MyotomesDropdownValues[item.rightValue]?.name}</td>
                        <td>{MyotomesDropdownValues[item.leftValue]?.name}</td>
                        <td>{item.details}</td>
                    </tr>
                );

            } else if (item.type === TypeEnum.LUMBAR) {
                if (!headerState.lumbarHeader) {
                    tableEntriesLumbar.push(
                        <tr key={"lumbarHeaderMyotomes" + index} style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                            <th>{TypeEnum[item.type]}</th>
                            <th>Right</th>
                            <th>Left</th>
                            <th>Details</th>
                        </tr>
                    );
                    headerState.lumbarHeader = true;
                }
                tableEntriesLumbar.push(
                    <tr key={"lumbarTableMyotomes" + index} style={{backgroundColor: "white"}}>
                        <td>{MyotomesLumbarEnum[item.name]?.value}</td>
                        <td>{MyotomesDropdownValues[item.rightValue]?.name}</td>
                        <td>{MyotomesDropdownValues[item.leftValue]?.name}</td>
                        <td>{item.details}</td>
                    </tr>
                );

            } else if (item.type === TypeEnum.SACRAL) {
                if (!headerState.sacralHeader) {
                    tableEntriesSacral.push(
                        <tr key={"sacralHeaderMyotomes" + index} style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                            <th>{TypeEnum[item.type]}</th>
                            <th>Right</th>
                            <th>Left</th>
                            <th>Details</th>
                        </tr>
                    );
                    headerState.sacralHeader = true;
                }
                tableEntriesSacral.push(
                    <tr key={"sacralTableMyotomes" + index} style={{backgroundColor: "white"}}>
                        <td>{MyotomesSacralEnum[item.name]?.value}</td>
                        <td>{MyotomesDropdownValues[item.rightValue]?.name}</td>
                        <td>{MyotomesDropdownValues[item.leftValue]?.name}</td>
                        <td>{item.details}</td>
                    </tr>
                );

            }
        });
        return tableEntriesCervical.concat(tableEntriesThoracic, tableEntriesLumbar, tableEntriesSacral);
    };

    render() {
        if (!this.props.myotomes || !this.props.myotomes.items || this.props.myotomes.items.length === 0) {
            return <div className="pl-3">
                {this.props.myotomes && this.props.myotomes.none ? "Normal" : "No information available"}
            </div>;
        }

        return (
            <table className={"visit-history-table"}>
                <tbody>
                {this.createMyotomesTable()}
                </tbody>
            </table>
        );
    }
}