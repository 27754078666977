import React, {Component} from 'react'
import Logo from "./Logo";
import DateUtil from "../../../utils/DateUtil";
import confirmDialog from "../../../components/dialog/ConfirmDialog";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import RemotingService from "../../../services/remoting-service/RemotingService";
import {NumberInput, TextInput, Wizard} from "../../../components";
import SignatureInput from "../../../components/signature-input/SignatureInput";
import FormField from "../../../components/form/FormField";

export default class PublicDoubleSessionRequestForm extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    createHeader = (form) => {
        return <div style={{padding: "0px 20px 0px 20px"}}>
            <div className="row text-left">
                <div className="col-12 no-padding">
                    <Logo/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center no-padding" style={{fontSize: 20, fontWeight: "bold"}}>
                    DOUBLE SESSION REQUEST FORM
                </div>
            </div>
            <div className="row" style={{fontSize: 11}}>
                <div className="col-3 text-left no-padding">
                    <b>Patient Name: </b> {form.patientFullName}
                </div>
                <div className="col-3 text-left no-padding">
                    <b>Date: </b> {DateUtil.formatDateMMM(form.consentDate)}
                </div>
                <div className="col-3 text-right no-padding">
                    <b>MRN No: </b> {form.patientMrn}
                </div>
                <div className="col-3 text-right no-padding">
                    <b>Provider Name: </b> {form.providerName}
                </div>
            </div>
        </div>
    };

    fieldChanged = (fieldName, value) => {
        this.props.form[fieldName] = value;
        this.forceUpdate();
    };

    close = () => {
        confirmDialog(
            'Confirmation',
            'Are you sure you want to close this form? All input will be lost.',
            this.props.close);
    };

    submitForm = () => {
        if (ValidationUtil.checkWithNotification(this.formFields)) {
            const {consentFormCode: code, form} = this.props;
            RemotingService.postRemoteCall(`api/public/consent-form/code/${code}/double-session-request-form`, form);
            this.props.onFormSubmit(form);
        }
    };

    render() {
        this.formFields = [];
        const {form} = this.props;
        return (
            <Wizard
                show={true} fullScreen
                footerStyle={{backgroundColor: '#f5f5f5'}}
                buttonStyle={{backgroundColor: '#84BE56', border: 'none'}}
                header={this.createHeader(form)}
                close={this.close}
                onSubmit={this.submitForm}>

                <Wizard.Page>
                    <div style={{margin: "20px 20px 20px 20px"}}>

                        <div style={{backgroundColor: "white"}}>
                            <div style={{margin: "0px 15px"}}>
                                <p>I hereby certify that I request my healthcare provider to perform 2 consecutive
                                    sessions on the present day for more thorough treatment of more than one area
                                    of complaint.</p>
                                <div className={"row"} style={{marginLeft: "0px", marginBottom: "10px"}}>
                                    <div className="col-md-12">
                                        I understand, and I consent that these 2 sessions will be billed at the
                                        following rate:

                                        <NumberInput
                                            value={form.billRate}
                                            style={{maxWidth: "25%"}}
                                            min={0}
                                            allowDecimal
                                            maxFractionDigits={form.patientCountry === "UAE" ? 2 : 3}
                                            onChange={(value) => this.fieldChanged('billRate', value)}/>
                                    </div>
                                </div>
                                <p>I’m also aware that some insurance policies may not accept to reimburse the second
                                    session depending on my policy coverage and I take full responsibility for the
                                    costs incurred.</p>
                            </div>
                        </div>

                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    Legal Guardian Name
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    <TextInput value={form.guardianName}
                                               alphabeticOnly
                                               onChange={(value) => this.fieldChanged('guardianName', value)}/>
                                </div>

                                <div className="col-md-3 dim14-form-label">
                                    Relationship to Patient
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    <TextInput value={form.guardianRelationship} alphabeticOnly
                                               onChange={(value) => this.fieldChanged('guardianRelationship', value)}/>
                                </div>
                            </div>

                            <div className="row" style={{marginTop: "15px"}}>
                                <div className="col-md-6 dim14-form-label">
                                    Signature of Patient (or legal guardian)
                                </div>

                                <div className="col-md-6 dim14-form-label">
                                    Signature of Provider
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <FormField ref={formField => this.formFields.push(formField)}
                                               required requiredMessage="Signature required"
                                               validateOn={form.patientSignature}>
                                        <SignatureInput signature={form.patientSignature}
                                                        onClear={() => {
                                                            form.patientSignature = null;
                                                            this.forceUpdate();
                                                        }}
                                                        onSave={(signature) => {
                                                            form.patientSignature = signature;
                                                            this.forceUpdate();
                                                        }}/>
                                    </FormField>
                                </div>

                                <div className="col-md-6">
                                    <FormField ref={formField => this.formFields.push(formField)}
                                               required requiredMessage="Signature required"
                                               validateOn={form.providerSignature}>
                                        <SignatureInput signature={form.providerSignature}
                                                        onClear={() => {
                                                            form.providerSignature = null;
                                                            this.forceUpdate();
                                                        }}
                                                        onSave={(signature) => {
                                                            form.providerSignature = signature;
                                                            this.forceUpdate();
                                                        }}/>
                                    </FormField>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>
            </Wizard>
        )
    }
}
