import Enum from "../../../../../../enums/Enum";

const ResponseEnum = new Enum({
    VERY_POOR: {name: "Very Poor"},
    POOR: {name: "Poor"},
    AVERAGE: {name: "Average"},
    GOOD: {name: "Good"},
    EXCELLENT: {name: "Excellent"}
});

export default ResponseEnum;