import React, {Component} from "react";
import "../../VisitHistory.css"
import TypeEnum from "../../../assessment/enums/respiratorypattern/TypeEnum";
import AreaEnum from "../../../assessment/enums/respiratorypattern/AreaEnum";
import FindingEnum from "../../../assessment/enums/respiratorypattern/FindingEnum";
import StringUtil from "../../../../../../utils/StringUtil";

export default class RespiratoryPatternViewHistory extends Component {
    render() {
        if (this.props.respiratoryPattern?.items?.length > 0) {
            return(
                <div className={"visit-history-content pl-3"}>
                    {this.props.respiratoryPattern.items.map(item => {
                        return (
                            <div>
                                {TypeEnum[item.type].name},
                                {AreaEnum[item.area].name},
                                {FindingEnum[item.finding].name}{StringUtil.isNullOrEmpty(item.detail) ? "" : (", " + item.detail)}
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return <div className="pl-3">No information available</div>;
        }
    }
}