import React, {Component} from "react";
import "../../VisitHistory.css"

export default class gaitAnalysisView extends Component {


    render() {
        if (this.checkGaitAnalysisEmpty(this.props.gaitAnalysis) ||
            this.props.gaitAnalysis.none) {
            return <div className="pl-3">
                {this.props.gaitAnalysis && this.props.gaitAnalysis.none ? "Normal" : "No information available"}
            </div>;
        }

        return (
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th></th>
                    <th>Heel Strike</th>
                    <th>Foot Flat</th>
                    <th>Mid Stance</th>
                    <th>Heel Off</th>
                    <th>Toe Off</th>
                    <th>Early Swing</th>
                    <th>Mid Swing</th>
                    <th>Late Swing</th>

                </tr>
                <tr>
                    <td className={"gaitAnalysisRowHeader"}>
                        Upper Back & Shoulders
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[32].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[33].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[34].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[35].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[36].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[37].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[38].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[39].details}
                    </td>

                </tr>
                <tr>
                    <td className={"gaitAnalysisRowHeader"}> Arms</td>
                    <td>
                        {this.props.gaitAnalysis.items[24].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[25].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[26].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[27].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[28].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[29].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[30].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[31].details}
                    </td>
                </tr>
                <tr>
                    <td className={"gaitAnalysisRowHeader"}> Pelvis & Hips</td>
                    <td>
                        {this.props.gaitAnalysis.items[16].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[17].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[18].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[19].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[20].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[21].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[22].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[23].details}
                    </td>
                </tr>
                <tr>
                    <td className={"gaitAnalysisRowHeader"}>Knee</td>
                    <td>
                        {this.props.gaitAnalysis.items[8].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[9].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[10].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[11].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[12].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[13].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[14].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[15].details}
                    </td>
                </tr>
                <tr>
                    <td className={"gaitAnalysisRowHeader"}> Ankle</td>
                    <td>
                        {this.props.gaitAnalysis.items[0].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[1].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[2].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[3].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[4].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[5].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[6].details}
                    </td>
                    <td>
                        {this.props.gaitAnalysis.items[7].details}
                    </td>
                </tr>
                </tbody>
            </table>
        );
    }

    checkGaitAnalysisEmpty = (gaitAnalysis) => {
        if (gaitAnalysis != null && gaitAnalysis.items != null && gaitAnalysis.items.length != 0) {
            for (const item of gaitAnalysis.items) {
                if (item.details != "") {
                    return false;
                }
            }
        }
        return true;
    }
}