import React, {Component} from "react";
import '../Subjective.css';
import '../../EMR.css';
import './ComplaintView.css';
import StringUtil from "../../../../../utils/StringUtil";
import * as ArrayUtils from "../../../../../utils/ArrayUtil";

export default class LabImagingView extends Component {

    render() {
        let divs = [];

        if(this.props.labImaging == null) {
            return (<div style={{padding: "15px 15px"}}></div>);
        }

        let li = this.props.labImaging;
        divs.push(
           <div key={"labImagingViewL2"} style={{fontSize: "14px"}}>
               {li.labs != null && <div className={"display-flex"} style={{margin: "3px"}}>
                   <div className={"col-sm-3 no-padding dim14-form-label"}>Lab:</div>
                   <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>{li.labs == true
                       ? "Yes" : "No"}{StringUtil.isNullOrEmpty(li.labDetail) ? "" : (", " + li.labDetail)}</div>
               </div>}
               {li.radiology != null && <div className={"display-flex"} style={{margin: "3px"}}>
                   <div className={"col-sm-3 no-padding dim14-form-label"}>Radiology:</div>
                   <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>{li.radiology == true
                       ? "Yes" : "No"}{StringUtil.isNullOrEmpty(li.radiologyDetail) ? "" : (", " + li.radiologyDetail)}</div>
               </div>}
               {!ArrayUtils.isEmpty(li.reportStatus) && <div className={"display-flex"} style={{margin: "3px"}}>
                   <div className={"row no-margin"} style={{width: "100%"}}>
                        <div className={"col-sm-3 no-padding display-flex"}>
                            <div className={"col no-padding dim14-form-label"}>Report Present: </div>
                        </div>
                       <div className={"col-sm-9 no-padding display-flex"}>
                           <div className={"col-sm-4 no-padding overflow-wrap-anywhere"}>{li.reportStatus.includes("REPORT_PRESENT") ? "Yes" : "No"}</div>
                           <div className={"col-sm-4 no-padding dim14-form-label"}>Report Scanned: </div>
                           <div className={"col-sm-4 no-padding overflow-wrap-anywhere"}>{li.reportStatus.includes("REPORT_SCANNED") ? "Yes" : "No"}</div>
                       </div>
                    </div>
               </div>}
               {!StringUtil.isNullOrEmpty(li.reportDetail) &&
                   <div className={"display-flex"} style={{margin: "3px"}}>
                       <div className={"col-sm-3 no-padding dim14-form-label"}>Report Detail:</div>
                       <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>{li.reportDetail}</div>
                   </div>
               }
           </div>
        );

        return(
          <div style={{padding: "15px 15px"}}>
              {divs}
          </div>
        );
    }

    enumString(_class, enumObject, detail) {
        if(enumObject == null || enumObject.length == 0) {
            return (detail && detail != null) ? detail : 'NA';
        }
        let res;
        if(Array.isArray(enumObject)) {
            res = enumObject.map(e => _class[e].name);
        } else {
            res = [];
            res.push(_class[enumObject].name);
        }
        if(detail && detail != null && detail != '') {
            res.push(detail);
        }
        return res.join(', ');
    }
}