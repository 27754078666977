import Enum from "../../../enums/Enum";
import ConsentFormsUtil from "../utils/ConsentFormsUtil";

const InjectionConsentFormTextEnum = new Enum({
    Injection_Consent_Title: {English: "Injection Consent Form", Arabic: "استمارة الموافقة على الحقنة"},
    Injection_Consent_Form: {English: "INJECTION CONSENT FORM", Arabic: "استمارة الموافقة على الحقنة"},

    MRN: {English: "MRN", Arabic: "السجل الطبي الإلكتروني"},
    Name: {English: "Name", Arabic: "الاسم"},
    DOB: {English: "D.O.B", Arabic: "تاريخ الميلاد"},

    Patient_Information: {English: "Patient Information", Arabic: "معلومات عن المريض"},

    Name_Of_Proposed_Procedure: {
        English: "Name of proposed procedure",
        Arabic: "اسم الإجراء المقترح: (شاملاً الإجراءات الجانبية)"
    },
    Fee_Of_Proposed_Procedure: {English: "Fee for the proposed procedure", Arabic: "رسوم الإجراء المقترح"},

    Statement_Of_Health_Professional_DXB: {English: "Statement of health professional", Arabic: "تصريح اخصائي طب الریاضة/العائلة"},
    Statement_Of_Health_Professional_KWI: {English: "Statement of health professional", Arabic: "تصريح مقدم الرعایة الصحیة"},

    Statement_Of_Health_Professional_Paragraph_In_Parentheses: {
        English: "(to be filled in by health professional with appropriate knowledge of proposed procedure)",
        Arabic: "(يتم ملأه من قبل خبير الصحة مع معرفة مناسبة بالإجراء المقترح)"
    },

    Statement_Of_Health_Professional_Paragraph1: {
        English: "I have explained the procedure to the patient. In particular",
        Arabic: "شرحت الإجراء للمريض. بشكل خاص"
    },

    Statement_Of_Health_Professional_Item1_Underlined: {English: "The intended benefits", Arabic: "المنافع المرجوة"},
    Statement_Of_Health_Professional_Item1_Paragraph: {
        English: "pain reduction and/or functional improvement.",
        Arabic: "الحد من الالم و/أو تحسين الوظيفة الحركية"
    },

    Statement_Of_Health_Professional_Item2_Underlined: {
        English: "The side effects may include",
        Arabic: "يمكن أن تشمل الآثار الجانبية"
    },
    Statement_Of_Health_Professional_Item2_Paragraph: {
        English: "Headache, no decrease in pain, worsening of pain, fluid retention/swelling/increased blood pressure, increased blood sugars (if diabetic), mood changes, lowered resistance to infection, menstrual irregularities (for women) and facial flushing.",
        Arabic: "الصداع، عدم انخفاض الألم، زيادة الألم، احتباس السوائل/التورم/زيادة ضغط الدم، زيادة سكر الدم (لمرضى السكر)، تغيرات المزاج، مقاومة أقل للإلتهاب، اضطرابات الدورة الشهرية (بالنسبة للنساء)، احمرار الوجه"
    },

    Statement_Of_Health_Professional_Item3_Underlined: {
        English: "Rare but serious side effects may include",
        Arabic: "الآثار الجانبية الخطيرة النادرة الحدوث"
    },
    Statement_Of_Health_Professional_Item3_Paragraph: {
        English: "1:70,000 chance including but not limited to allergy, infection, bleeding and nerve injury.",
        Arabic: "1:70,000 فرصة حدوث ما يلي على سبيل المثال الحساسية، الإلتهاب، النزيف واصابة الأعصاب."
    },

    Statement_Of_Health_Professional_Paragraph4: {
        English: "I have confirmed with the patient that he/she has no further questions and wishes the procedure to go ahead.",
        Arabic: "تأكدت من ان المريض لا يوجد لديه/لديها مزيد من الأسئلة ويرجو المضي قدماً في الإجراء."
    },
    Statement_Of_Health_Professional_Paragraph5: {
        English: "If having radio frequency denervation - I have checked that the patient does not have a pacemaker or an artificial hip.",
        Arabic: "في حال كان الإجراء يتضمن تشويش على التردد اللاسلكي ، فقد تأكدت من أن المريض ليس لديه جهاز تنظيم ضربات القلب أو الورك الاصطناعي."
    },

    Provider_Name: {English: "PROVIDER NAME", Arabic: "الإسم"},
    Job_Title: {English: "SPECIALITY", Arabic: "التخصص"},
    Date_Time: {English: "DATE/TIME", Arabic: "التاريخ / الوقت"},
    Provider_Signature_DXB: {English: "PROVIDER SIGNATURE", Arabic: "توقيع اخصائي طب الریاضة/العائلة"},
    Provider_Signature_KWI: {English: "PROVIDER SIGNATURE", Arabic: "توقيع مقدم الرعایة الصحیة"},


    Statement_Of_Patient: {English: "Statement of patient", Arabic: "تصريح المريض"},
    Please_Read_Carefully: {
        English: "Please read this form carefully. If your treatment has been planned in advance, you should already have a leaflet which describes the benefits and risks of the proposed treatment. If not, you will be offered a copy and a copy of this consent form now. If you have any further questions, do ask. You have the right to change your mind at any time, including after you have signed this form.",
        Arabic: "يرجى قراءة هذا النموذج بعناية. في حال تم التخطيط لعلاجك مقدماً، يفترض أنك قد حصلت على دليل يصف مزايا ومخاطر العلاج المقترح، وفي حال عدم حصولك على ذلك الدليل فسوف تحصل على نسخة منه بالإضافة إلى نسخة من نموذج الموافقة هذا الآن. في حال وجود المزيد من الأسئلة، يرجى طرحها الآن. لديك الحق في تغيير رأيك في أي وقت حتى بعد التوقيع على هذا النموذج."
    },
    For_Self_Paying_Clients_I_Understand: {
        English: "For self-paying clients I understand",
        Arabic: "بالنسبة للمرضى الذين يدفعون الرسوم بأنفسهم،"
    },
    Statement_Of_Patient_Item1: {English: "the cost of my treatment today is", Arabic: "أتفهم أن كلفة علاجي اليوم هي"},

    I_Understand: {English: "I understand", Arabic: "أتفهم"},
    I_Agree: {English: "I agree", Arabic: "أوافق"},

    Statement_Of_Patient_Item2: {
        English: "my diagnosis which has been explained to me.",
        Arabic: "تشخيصي الذي تم شرحه لي"
    },
    Statement_Of_Patient_Item3: {
        English: "to the procedure or course of treatment described on this form.",
        Arabic: "على الإجراء أو خطة العلاج الموصوفة في هذا النموذج."
    },
    Statement_Of_Patient_Item4: {
        English: "that information and/or surgical images kept in my records may be used anonymously for education, audit and research approved by an ethics committee, to improve the quality of patient care.",
        Arabic: "على استخدام المعلومات/الصور الجراحية المحفوظة في سجلاتي لأغراض التعليم، والتدقيق، والأبحاث  الموافق عليها من قبل اللجنة الأخلاقية لغرض تطوير مدى العناية بالمريض دون التصريح عن صاحبها."
    },
    Statement_Of_Patient_Item5: {
        English: "that the person performing the procedure has the appropriate experience or is training under direct supervision.",
        Arabic: "أن الشخص الذي ينفذ الإجراء لديه الخبرة المناسبة أو أنه متدرب تحت الإشراف المباشر."
    },
    Statement_Of_Patient_Item6: {
        English: "that any procedure in addition to those described on this form will only be carried out if it is necessary to save my life or to prevent serious harm to my health.",
        Arabic: "أن أي إجراء بالإضافة إلى تلك الإجراءات الموصوفة في هذا النموذج سيتم تنفيذه فقط في حال كان ضرورياً لإنقاذ حياتي أو لمنع الأذى الكبير على صحتي."
    },
    Statement_Of_Patient_Item7: {
        English: "the alternatives to the procedure to be: medication, physical therapy, exercise therapy or do nothing.",
        Arabic: "أن بديل الإجراء هو الأدوية، العلاج الفيزيائي أو عدم فعل شيء."
    },
    Statement_Of_Patient_Item8: {
        English: "that I have had a chance to ask appropriate questions.",
        Arabic: "على أنه قد تمت إتاحة الفرصة لي لطرح الأسئلة."
    },
    Statement_Of_Patient_Item9: {
        English: "to the contents of this form and I agree to receive the treatment.",
        Arabic: "على محتوى هذا النموذج وأوافق على تلقي العلاج."
    },

    Patient_Name: {English: "PATIENT NAME", Arabic: "اسم المريض"},
    Patient_Signature: {English: "PATIENT SIGNATURE", Arabic: "توقيع المريض"},

    Witness_Paragraph1: {
        English: "A witness should sign below if the patient is unable to sign but has indicated his or her consent.",
        Arabic: "يتعين أن يقوم شاهد بالتوقيع أدناه في حال كان المريض غير قادراً على التوقيع بنفسه ولكن المريض قد أبدى موافقته/موافقتها"
    },

    Witness_Paragraph2: {
        English: `Young people (under ${ConsentFormsUtil.getWitnessAgeText()} years of age) and children should also have a Parent or Guardian sign here.`,
        Arabic: `يتعين على الوالد التوقيع هنا في حالة المرضى الصغار (تحت سن 18 سنة) والأطفال.`
    },

    Print_Name: {English: "PRINT NAME", Arabic: "الاسم المطبوع"},
    Relationship_To_Patient: {English: "RELATIONSHIP TO PATIENT", Arabic: "الصلة بالمريض"},
    Signature_Of_Witness: {English: "SIGNATURE OF WITNESS/PARENT OR GUARDIAN", Arabic: "توقيع الشاهد/الوالد أو الولي"},
    Signature_input_Clear: {English: "Clear", Arabic: "حذف"},
    Wizard_Previous: {English: "Previous", Arabic: "السابق"},
    Wizard_Next: {English: "Next", Arabic: "التالي"},
    Wizard_Save: {English: "Save", Arabic: "حفظ"}
});

export default InjectionConsentFormTextEnum;
