import React, {Component} from "react";
import '../../EMR.css';
import TextInput from "../../../../../components/text-input/TextInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";
import GreenSwitchWithMessage from "../../../../../components/switch/GreenSwitchWithMessage";
import CheckboxInput from "../../../../../components/checkbox-input/CheckboxInput";
import {AllergyChecker} from "../checkers/AllergyChecker";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import {connect} from "react-redux";
import Select from "react-select";
import {DateInput} from "../../../../../components";
import Tooltip from "../../../../../components/tooltip/Tooltip";
import FormField from "../../../../../components/form/FormField";
import AsyncSelect from "react-select/async";
import RemotingService from "../../../../../services/remoting-service/RemotingService";
import ValidationUtil from "../../../../../components/form/validator/ValidationUtil";
import moment from "moment/moment";

class AllergyModal extends Component {
    state = {
        switch: true,
        items: this.props.subjective.allergy.items || [],
        allergyType: null,
        otherAllergyType: null,
        allergen: null,
        otherAllergen: null,
        selectedMedication: null,
        allergenSeverity: null,
        otherAllergenSeverity: null,
        allergenReaction: null,
        otherAllergenReaction: null,
        discoveredDate: null,
        details: null
    };

    constructor(props) {
        super(props);
        this.MINIMUM_SEARCH_LENGTH = 2;
    }

    loadMedicationsWithName = (inputValue, callback) => {
        if (inputValue.length < this.MINIMUM_SEARCH_LENGTH) {
            return callback(null);
        }
        RemotingService.getRemoteCall('api/medication/', {name: inputValue}, (medications) => {
            const result = [{value: inputValue, label: inputValue}];

            medications.forEach(m => {
                result.push({value: m, label: m.name});
            });

            callback(result);
        });
    }

    loadMedicationsWithActiveIngredient = (inputValue, callback) => {
        if (inputValue.length < this.MINIMUM_SEARCH_LENGTH) {
            return callback(null);
        }
        RemotingService.getRemoteCall('api/medication/ingredient/', {activeIngredient: inputValue}, (medications) => {
            const result = [{value: inputValue, label: inputValue}];

            medications.forEach(m => {
                result.push({value: m, label: m.activeIngredient});
            });

            callback(result);
        });
    }

    handleMedicationSelected = (medication) => {
        this.state.selectedMedication = medication;
        this.forceUpdate();
    }

    handleMedicationBrandSelected = (name) => {
        if (this.state.selectedMedication) {
            this.state.selectedMedication.name = name;
        } else {
            this.state.selectedMedication = {name: name};
        }
        this.forceUpdate();
    }

    handleMedicationIngredientSelected = (activeIngredient) => {
        if (this.state.selectedMedication) {
            this.state.selectedMedication.activeIngredient = activeIngredient;
        } else {
            this.state.selectedMedication = {activeIngredient: activeIngredient};
        }
        this.forceUpdate();
    }

    addAllergy = () => {
        if (ValidationUtil.checkWithNotification(this.formFields)) {
            this.props.subjective.allergy.items.push({
                allergyType: this.state.allergyType,
                otherAllergyType: this.state.otherAllergyType,
                allergen: this.state.allergen,
                otherAllergen: this.state.otherAllergen,
                allergenSeverity: this.state.allergenSeverity,
                otherAllergenSeverity: this.state.otherAllergenSeverity,
                allergenReaction: this.state.allergenReaction,
                otherAllergenReaction: this.state.otherAllergenReaction,
                discoveredDate: this.state.discoveredDate,
                medicationBrand: this.state.selectedMedication?.name,
                activeIngredient: this.state.selectedMedication?.activeIngredient,
                details: this.state.details
            });

            this.setState({
                allergyType: null,
                otherAllergyType: null,
                allergen: null,
                otherAllergen: null,
                allergenSeverity: null,
                otherAllergenSeverity: null,
                allergenReaction: null,
                otherAllergenReaction: null,
                discoveredDate: null,
                selectedMedication: null,
                details: null
            });
        }
    }

    getAllergyDivs() {
        if (this.props.subjective.allergy == null || this.props.subjective.allergy.items == null) {
            return;
        }
        let divs = [];
        this.props.subjective.allergy.items.forEach((v, index) => divs.push(
            <div className={"div-list-item"} key={v + index}>
                <div>
                    {v.allergyType?.name}{v.otherAllergyType ? ", " + v.otherAllergyType : ""}
                    {v.allergen ? ", " + v.allergen.name : ""}{v.otherAllergen ? ", " + v.otherAllergen : ""}
                    {v.selectedMedication ? ", " + v.selectedMedication.name : ""}
                    {v.allergenSeverity ? ", " + v.allergenSeverity.name : ""}{v.otherAllergenSeverity ? ", " + v.otherAllergenSeverity : ""}
                    {v.allergenReaction ? ", " + v.allergenReaction.name : ""}{v.otherAllergenReaction ? ", " + v.otherAllergenReaction : ""}
                    {v.discoveredDate ? ", " + moment(v.discoveredDate).format('DD-MMM-YYYY') : ""}{v.details ? ", " + v.details : ""}
                </div>
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize: "10px", marginLeft: "2px"}}
                        onClick={() => {
                            let i = this.props.subjective.allergy.items.indexOf(v);
                            this.props.subjective.allergy.items.splice(i, 1);

                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </Button>
            </div>
        ));
        return divs;
    }

    render() {
        let allergyDivs = this.getAllergyDivs();
        this.formFields = [];
        const toggleOn = AllergyChecker.isToggleOn(this.props.subjective.allergy);
        const isNone = AllergyChecker.isNone(this.props.subjective.allergy);
        const basicDataNotEntered = !(this.state.allergyType || this.state.allergen || this.state.selectedMedication);
        const addButtonDisabled = !(this.state.allergyType || this.state.otherAllergyType || this.state.allergen ||
            this.state.otherAllergen || this.state.selectedMedication || this.state.allergenSeverity ||
            this.state.otherAllergenSeverity || this.state.allergenReaction || this.state.otherAllergenReaction ||
            this.state.details);

        function isOtherOption(option) {
            return option != null && ("OTHER" === option.name.toUpperCase() || "OTHERS" === option.name.toUpperCase())
        }

        return (
            <div className={" content-row emr-background"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px"}}>
                    <div style={{display: "flex"}}>
                        <div className="content-label"
                             style={{margin: "auto", marginLeft: "0px"}}>Allergies <RedAsterisk
                            appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                        <GreenSwitchWithMessage
                            message={isNone ? "None Known" : ""}
                            checked={toggleOn}
                            onChange={() => {
                                if (toggleOn) {
                                    let id = this.props.subjective.allergy.id;
                                    this.props.subjective.allergy = {id: id, none: false};
                                    this.forceUpdate();
                                }
                            }}/>
                    </div>

                    <div style={{display: "flex", marginLeft: "5px"}}>
                        <div>
                            <CheckboxInput value={isNone}
                                           onChange={(value) => {
                                               if (this.props.subjective.allergy == null) {
                                                   this.props.subjective.allergy = {};
                                               }
                                               if (value) {
                                                   let id = this.props.subjective.allergy.id;
                                                   this.props.subjective.allergy = {id: id};
                                               }
                                               this.props.subjective.allergy.none = value;
                                               this.forceUpdate();
                                           }}/>
                        </div>
                        <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>None Known</div>
                    </div>

                    <br/><br/>

                    <div className={isNone ? "not-allowed" : ""}>
                        <div className={isNone ? "disable-pointer" : ""}>
                            <div className={"row no-margin"}>
                                <div className={"col-6"}>
                                    <div style={{fontSize: "12px", fontWeight: "bold"}}>Allergy Type</div>
                                </div>
                                <div className={"col-6"}>
                                    <div style={{fontSize: "12px", fontWeight: "bold"}}>Allergen Type</div>
                                </div>
                            </div>
                            <div className={"row no-margin"}>
                                <div className={"col-6"}>
                                    <Select value={this.state.allergyType}
                                            options={this.props.allergyTypes}
                                            getOptionValue={v => v.id}
                                            getOptionLabel={v => v.name}
                                            className={"emr-white-select-container"}
                                            style={{width: '100%'}}
                                            classNamePrefix={"emr-white-select"}
                                            placeholder={"Allergy Type"}
                                            onChange={(val) => {
                                                this.setState({allergyType: val})
                                            }}
                                    />
                                </div>
                                <div className={"col-6"}>
                                    <Select value={this.state.allergen}
                                            options={this.props.allergenTypes}
                                            getOptionValue={v => v.id}
                                            getOptionLabel={v => v.name}
                                            className={"emr-white-select-container"}
                                            style={{width: '100%'}}
                                            classNamePrefix={"emr-white-select"}
                                            placeholder={"Allergen Type"}
                                            onChange={(val) => {
                                                this.setState({allergen: val})
                                            }}
                                    />
                                </div>
                            </div>
                            {isOtherOption(this.state.allergyType) || isOtherOption(this.state.allergen) ?
                                <div className={"row no-margin"}>
                                    <div className={"col-6"}>
                                        {isOtherOption(this.state.allergyType) ?
                                            <FormField
                                                ref={formField => this.formFields.push(formField)}
                                                required validateOn={this.state.otherAllergyType}>
                                                <TextInput value={this.state.otherAllergyType || ''}
                                                           placeholder={"Other Allergy Type"}
                                                           onChange={value => this.setState({otherAllergyType: value})}
                                                           style={{fontSize: "14px", width: "100%"}}
                                                /></FormField> : null}
                                    </div>
                                    <div className={"col-6"}>
                                        {isOtherOption(this.state.allergen) ?
                                            <FormField
                                                ref={formField => this.formFields.push(formField)}
                                                required validateOn={this.state.otherAllergen}>
                                                <TextInput value={this.state.otherAllergen || ''}
                                                           placeholder={"Other Allergen Type"}
                                                           onChange={value => this.setState({otherAllergen: value})}
                                                           style={{fontSize: "14px", width: "100%"}}
                                                /></FormField> : null}
                                    </div>
                                </div> : null}

                            <div className={"row no-margin"}>
                                <div className={"col-6"}>
                                    <div style={{fontSize: "12px", fontWeight: "bold"}}>Medication</div>
                                </div>
                            </div>
                            <div className={"row no-margin"}>

                                <div className={"col-6"}>
                                    <Tooltip title={this.state.selectedMedication != null ?
                                        this.state.selectedMedication.name : ""}>
                                        <AsyncSelect
                                            className={"emr-in-table-select-container"}
                                            classNamePrefix={"emr-in-table-select"}
                                            noOptionsMessage={RemotingService.noOptionsMessage}
                                            loadOptions={this.loadMedicationsWithName}
                                            placeholder={"Active Ingredient"}
                                            value={this.state.selectedMedication != null ?
                                                {
                                                    value: this.state.selectedMedication,
                                                    label: this.state.selectedMedication.name,
                                                } : null}
                                            onChange={event => {
                                                if (typeof event.value === "string") {
                                                    this.handleMedicationBrandSelected(event.value);
                                                } else {
                                                    this.handleMedicationSelected(event.value);
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                                <div className={"col-6"}>
                                    <Tooltip
                                        title={(this.state.selectedMedication && this.state.selectedMedication.activeIngredient) ?
                                            this.state.selectedMedication.activeIngredient : ""}>
                                        <AsyncSelect
                                            className={"emr-in-table-select-container"}
                                            classNamePrefix={"emr-in-table-select"}
                                            placeholder={"Brand Name"}
                                            noOptionsMessage={RemotingService.noOptionsMessage}
                                            loadOptions={this.loadMedicationsWithActiveIngredient}
                                            value={this.state.selectedMedication != null ?
                                                {
                                                    value: this.state.selectedMedication,
                                                    label: this.state.selectedMedication.activeIngredient,
                                                } : null}
                                            onChange={event => {
                                                if (typeof event.value === "string") {
                                                    this.handleMedicationIngredientSelected(event.value);
                                                } else {
                                                    this.handleMedicationSelected(event.value);
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                </div>
                            </div>

                            <div className={"row no-margin"}>
                                <div className={"col-6"}>
                                    <div style={{fontSize: "12px", fontWeight: "bold"}}>Severity</div>
                                </div>
                                <div className={"col-6"}>
                                    <div style={{fontSize: "12px", fontWeight: "bold"}}>Reaction Type</div>
                                </div>
                            </div>
                            <div className={"row no-margin"}>
                                <div className={"col-6"}>
                                    <FormField
                                        ref={formField => this.formFields.push(formField)}
                                        required disabled={basicDataNotEntered}
                                        validateOn={this.state.allergenSeverity}>
                                        <Select value={this.state.allergenSeverity}
                                                options={this.props.allergySeverities}
                                                getOptionValue={v => v.id}
                                                getOptionLabel={v => v.name}
                                                className={"emr-white-select-container"}
                                                style={{width: '100%'}}
                                                classNamePrefix={"emr-white-select"}
                                                placeholder={"Severity"}
                                                menuPlacement="top"
                                                onChange={(val) => this.setState({allergenSeverity: val})}
                                        />
                                    </FormField>
                                </div>
                                <div className={"col-6"}>
                                    <FormField
                                        ref={formField => this.formFields.push(formField)}
                                        required disabled={basicDataNotEntered}
                                        validateOn={this.state.allergenReaction}>
                                        <Select value={this.state.allergenReaction}
                                                options={this.props.allergenReactionTypes}
                                                getOptionValue={v => v.id}
                                                getOptionLabel={v => v.name}
                                                className={"emr-white-select-container"}
                                                style={{width: '100%'}}
                                                classNamePrefix={"emr-white-select"}
                                                placeholder={"Reaction Type"}
                                                onChange={(val) => this.setState({allergenReaction: val})}
                                        />
                                    </FormField>
                                </div>
                            </div>
                            {isOtherOption(this.state.allergenSeverity) || isOtherOption(this.state.allergenReaction) ?
                                <div className={"row no-margin"}>
                                    <div className={"col-6"}>
                                        {isOtherOption(this.state.allergenSeverity) ?
                                            <FormField
                                                ref={formField => this.formFields.push(formField)}
                                                required validateOn={this.state.otherAllergenSeverity}>
                                                <TextInput value={this.state.otherAllergenSeverity || ''}
                                                           placeholder={"Other Severity"}
                                                           onChange={val => this.setState({otherAllergenSeverity: val})}
                                                           style={{fontSize: "14px", width: "100%"}}
                                                /></FormField> : null}
                                    </div>
                                    <div className={"col-6"}>
                                        {isOtherOption(this.state.allergenReaction) ?
                                            <FormField
                                                ref={formField => this.formFields.push(formField)}
                                                required validateOn={this.state.otherAllergenReaction}>
                                            <TextInput value={this.state.otherAllergenReaction || ''}
                                                       placeholder={"Other Reaction Type"}
                                                       onChange={val => this.setState({otherAllergenReaction: val})}
                                                       style={{fontSize: "14px", width: "100%"}}
                                            /></FormField> : null}
                                    </div>
                                </div> : null}

                            <div className={"row no-margin"}>
                                <div className={"col-3"}>
                                    <div style={{fontSize: "12px", fontWeight: "bold"}}>Date Allergy Discovered</div>
                                </div>
                                <div className={"col-8"}>
                                    <div style={{fontSize: "12px", fontWeight: "bold"}}>Details</div>
                                </div>
                            </div>
                            <div className={"row no-margin"}>
                                <div className={"col-3"}>
                                    <FormField
                                        ref={formField => this.formFields.push(formField)}
                                        required disabled={basicDataNotEntered} validateOn={this.state.discoveredDate}>
                                        <DateInput value={this.state.discoveredDate}
                                                   disableFuture="true" placeholder={"Date Allergy Discovered"}
                                                   style={{width: '100%'}}
                                                   onChange={val => this.setState({discoveredDate: val})}
                                        />
                                    </FormField>
                                </div>
                                <div className={"col-8"}>
                                    <TextInput value={this.state.details || ''}
                                               placeholder={"Details"}
                                               onChange={val => this.setState({details: val})}
                                               style={{fontSize: "14px", width: "100%"}}
                                    />
                                </div>

                                <div className={"col-1"} style={{flexFlow: "row-reverse", display: "flex"}}>
                                    <Button className={"emr-plus-button"}
                                            variant="success"
                                            style={{fontSize: "12px"}}
                                            disabled={addButtonDisabled}
                                            onClick={this.addAllergy}>
                                        <FontAwesomeIcon icon={["fas", "plus"]}/>
                                    </Button>
                                </div>
                            </div>

                            <div className={"row no-margin"}>
                                <div className={"div-list-wrapper"}>
                                    {allergyDivs}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        allergyTypes: state.base.allergyTypes,
        allergenTypes: state.base.allergenTypes,
        allergenReactionTypes: state.base.allergenReactionTypes,
        allergySeverities: state.base.allergySeverities,
    };
}

export default connect(mapStateToProps)(AllergyModal);
