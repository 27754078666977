import React, {Component} from 'react';
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import CheckboxInput from "../../../components/checkbox-input/CheckboxInput";
import FormField from "../../../components/form/FormField";
import {TextInput} from "../../../components";

class IntakeFormDescribeSymptoms extends Component {
    render() {
        const {
            selectedLanguage, readOnly, onChange, formFields,
            symptomsConstant, symptomsComesAndGoes, symptomsStartedSuddenly, symptomsStartedSlowly,
            symptomsImproving, symptomsStayingTheSame, symptomsWorsening, symptomsThrobbing,
            symptomsNumbness, symptomsAching, symptomsBurning, symptomsShooting, symptomsDull,
            symptomsPinsAndNeedles, symptomsSharp, symptomsTightness, symptomsStiffness, symptomsOther,
            symptomsOtherDescription
        } = this.props

        return (
            <>
                <h3 className="text-center mb-3">
                    <b>{IntakeFormTextEnum.Describe_Your_Symptoms[selectedLanguage]}</b>
                </h3>
                <div className="container">
                    <div className="row">
                        <div className="col-4 dim14-form-label py-0">
                            {IntakeFormTextEnum.Describe_Your_Pain_Symptoms[selectedLanguage]}:
                        </div>
                        <div className="col-2 d-flex py-1">
                            <CheckboxInput
                                value={symptomsConstant}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomsConstant", value)
                                }
                            />
                            <label>
                                {IntakeFormTextEnum.Constant[selectedLanguage]}
                            </label>
                        </div>
                        <div className="col-2 d-flex py-1">
                            <CheckboxInput
                                value={symptomsComesAndGoes}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomsComesAndGoes", value)
                                }
                            />
                            <label>
                                {IntakeFormTextEnum.Comes_And_Goes[selectedLanguage]}
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4 dim14-form-label"></div>
                        <div className="col-2 d-flex py-1">
                            <CheckboxInput
                                value={symptomsStartedSuddenly}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomsStartedSuddenly", value)
                                }
                            />
                            <label>
                                {IntakeFormTextEnum.Started_Suddenly[selectedLanguage]}
                            </label>
                        </div>
                        <div className="col-3 d-flex py-1">
                            <CheckboxInput
                                value={symptomsStartedSlowly}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomsStartedSlowly", value)
                                }
                            />
                            <label>
                                {IntakeFormTextEnum.Started_Slowly[selectedLanguage]}
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4 dim14-form-label"></div>
                        <div className="col-2 d-flex py-1">
                            <CheckboxInput
                                value={symptomsImproving}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomsImproving", value)
                                }
                            />
                            <label>
                                {IntakeFormTextEnum.Improving[selectedLanguage]}
                            </label>
                        </div>
                        <div className="col-2 d-flex py-1">
                            <CheckboxInput
                                value={symptomsStayingTheSame}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomsStayingTheSame", value)
                                }
                            />
                            <label>
                                {IntakeFormTextEnum.Staying_Same[selectedLanguage]}
                            </label>
                        </div>
                        <div className="col-2 d-flex py-1">
                            <CheckboxInput
                                value={symptomsWorsening}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomsWorsening", value)
                                }
                            />
                            <label>
                                {IntakeFormTextEnum.Worsening[selectedLanguage]}
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4 dim14-form-label"></div>
                        <div className="col-2 d-flex py-1">
                            <CheckboxInput
                                value={symptomsThrobbing}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomsThrobbing", value)
                                }
                            />
                            <label>
                                {IntakeFormTextEnum.Throbbing[selectedLanguage]}
                            </label>
                        </div>
                        <div className="col-2 d-flex py-1">
                            <CheckboxInput
                                value={symptomsNumbness}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomsNumbness", value)
                                }
                            />
                            <label>
                                {IntakeFormTextEnum.Numbness[selectedLanguage]}
                            </label>
                        </div>
                        <div className="col-2 d-flex py-1">
                            <CheckboxInput
                                value={symptomsAching}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomsAching", value)
                                }
                            />
                            <label>
                                {IntakeFormTextEnum.Aching[selectedLanguage]}
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4 dim14-form-label"></div>
                        <div className="col-2 d-flex py-1">
                            <CheckboxInput
                                value={symptomsBurning}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomsBurning", value)
                                }
                            />
                            <label>
                                {IntakeFormTextEnum.Burning[selectedLanguage]}
                            </label>
                        </div>
                        <div className="col-2 d-flex py-1">
                            <CheckboxInput
                                value={symptomsShooting}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomsShooting", value)
                                }
                            />
                            <label>
                                {IntakeFormTextEnum.Shooting[selectedLanguage]}
                            </label>
                        </div>
                        <div className="col-2 d-flex py-1">
                            <CheckboxInput
                                value={symptomsDull}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomsDull", value)
                                }
                            />
                            <label>
                                {IntakeFormTextEnum.Dull[selectedLanguage]}
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4 dim14-form-label"></div>
                        <div className="col-2 d-flex py-1">
                            <CheckboxInput
                                value={symptomsPinsAndNeedles}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomsPinsAndNeedles", value)
                                }
                            />
                            <label>
                                {IntakeFormTextEnum.Pins_Needles[selectedLanguage]}
                            </label>
                        </div>
                        <div className="col-2 d-flex py-1">
                            <CheckboxInput
                                value={symptomsSharp}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomsSharp", value)
                                }
                            />
                            <label>
                                {IntakeFormTextEnum.Sharp[selectedLanguage]}
                            </label>
                        </div>

                        <div className="col-2 d-flex py-1">
                            <CheckboxInput
                                value={symptomsTightness}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomsTightness", value)
                                }
                            />
                            <label>
                                {IntakeFormTextEnum.Tightness[selectedLanguage]}
                            </label>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4 dim14-form-label"></div>
                        <div className="col-2 d-flex py-1">
                            <CheckboxInput
                                value={symptomsStiffness}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomsStiffness", value)
                                }
                            />
                            <label>
                                {IntakeFormTextEnum.Stiffness[selectedLanguage]}
                            </label>
                        </div>
                        <div className="col-6 d-flex py-1">
                            <CheckboxInput
                                value={symptomsOther}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomsOther", value)
                                }
                            />
                            <label>
                                {IntakeFormTextEnum.Other[selectedLanguage]}
                            </label>

                            {symptomsOther ? (
                                <FormField
                                    ref={(formField) => formFields.push(formField)}
                                    required
                                    validateOn={
                                        readOnly ? true : symptomsOtherDescription
                                    }
                                >
                                    <TextInput
                                        className="mx-2"
                                        value={symptomsOtherDescription}
                                        disabled={readOnly}
                                        onChange={(value) =>
                                            onChange("symptomsOtherDescription", value)
                                        }
                                    />
                                </FormField>
                            ) : null}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default IntakeFormDescribeSymptoms;