import Enum from "../../../enums/Enum";

const EnumReferenceType = new Enum({
    FAMILYFRIENDS: "Family/Friend",
    DRHOSPITAL: "Doctor",
    INSTAGRAM: "Instagram",
    FACEBOOK: "Facebook",
    TWITTER: "Twitter",
    OTHER: "Other",
});

EnumReferenceType.getLabel = function (referenceType) {
    switch (referenceType) {
        case "FAMILYFRIENDS":
            return "Family / Friend Name";
        case "DRHOSPITAL":
            return "Doctor Name";
        case "INSTAGRAM":
            return "Account Name";
        case "FACEBOOK":
            return "Account Name";
        case "TWITTER":
            return "Account Name";
        case "OTHER":
            return "Other";
        default:
    }
};

export default EnumReferenceType;