import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {default as MuiList} from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import {Divider} from "@material-ui/core";
import _uniqueId from "lodash/uniqueId";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
}));

/*
 * data: the data to display in the list - mandatory
 * selectable: renders checkboxes for items - optional default false
 * selected: initial selected items if selectable set to true - optional default []
 * selectAll: flag that determines whether to generate a select-all checkbox at top, default false
 * onChange: callback func to be called with the selected items when selection changes - optional
 * keyField: unique field for items in data, if data is a list of object - optional default 'id'
 * labelField: display text field for items in data, if data is a list of object
 * labelFunc: display text func for items in data, item will be passed as parameter
 * listItemFunc: display element func for items in data, item and index will be passed as parameter
 */
const List = (props) => {
    const {
        data,
        singleSelection = false,
        selectable,
        selected,
        selectAll,
        onChange,
        keyField,
        labelField,
        labelFunc,
        listItemFunc,
        additionalItemLabel,
        additionalItemOnClick,
        additionalItemChecked
    } = props;

    const [checked, setChecked] = React.useState(selected || []);

    const findIndex = (item) => {
        return selected.findIndex(i => {
            if (typeof item === 'object') {
                if (keyField) {
                    return i[keyField] === item[keyField];
                }
                return i.id === item.id;
            }
            return i === item;
        });
    };

    const handleToggle = (item) => () => {
        const currentIndex = findIndex(item);
        const newChecked = singleSelection ? [] : [...selected];

        if (currentIndex === -1) {
            newChecked.push(item);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);

        if (onChange) {
            onChange(newChecked);
        }
    };

    const allSelected = () => {
        return selected.length === data.length;
    };

    const handleToggleAll = (e) => {
        if (onChange) {
            onChange(allSelected() ? [] : [...data]);
        }
    };

    const classes = useStyles();
    return (
        <MuiList dense className={classes.root}>
            {
                selectable && selectAll && !singleSelection &&
                <ListItem key={_uniqueId('list-')} button>
                    <ListItemText id={`list-item--1`}>
                        Select All
                    </ListItemText>
                    <ListItemSecondaryAction>
                        <Checkbox
                            edge="end"
                            onClick={e => handleToggleAll(e)}
                            checked={allSelected()}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
            }
            {
                additionalItemLabel && additionalItemOnClick &&
                <ListItem key={_uniqueId('list-')} button>
                    <ListItemText id={`list-item--2`}>
                        {additionalItemLabel}
                    </ListItemText>
                    <ListItemSecondaryAction>
                        <Checkbox
                            edge="end"
                            onClick={additionalItemOnClick}
                            checked={additionalItemChecked}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
            }
            {
                selectable && selectAll && !singleSelection &&
                <Divider/>
            }
            {data.map((item, index) => {
                return (
                    <ListItem key={keyField ? item[keyField] : index} button>
                        {
                            listItemFunc ? listItemFunc(item, index) :
                                <ListItemText id={`list-item-${index}`}>
                                    {
                                        labelFunc ? labelFunc(item) : labelField ? item[labelField] : item
                                    }
                                </ListItemText>
                        }
                        {
                            selectable &&
                            <ListItemSecondaryAction>
                                <Checkbox
                                    edge="end"
                                    onChange={handleToggle(item)}
                                    checked={findIndex(item) !== -1}
                                />
                            </ListItemSecondaryAction>
                        }
                    </ListItem>
                );
            })}
        </MuiList>
    );
};

export default List;