import React, {Component} from "react";
import EnumBlockType from "../enums/EnumBlockType";
import "./CalendarEvent.css";
import ContextMenu from "../../../components/context-menu/ContextMenu";
import ContextMenuItem from "../../../components/context-menu/ContextMenuItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RotationBlockRemoteService from "../RotationBlockRemoteService";
import dialog from "../../../components/dialog/Dialog";
import {UpdateRotationBlockModal} from "../../staff/staffprofile/schedule/UpdateRotationBlockModal";
import DateUtil from "../../../utils/DateUtil";

export default class LunchEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            event,
            timeZoneGetter,
            showEventContextMenu,
            eventContextMenuVisible,
            smallView,
            appointmentActionsVisible,
            deleteVisible,
            deleteFunc
        } = this.props;
        const timeZone = timeZoneGetter();

        return (
            <div className="calendar-event"
                 style={{
                     borderColor: EnumBlockType[event.type].bgColor,
                     color: EnumBlockType[event.type].textColor,
                     backgroundColor: EnumBlockType[event.type].bgColor,
                     ...(smallView ?
                         {
                             fontSize: 'small',
                             flexDirection: 'row-reverse',
                             justifyContent: 'space-around',
                             paddingLeft: 10,
                             paddingRight: 10,
                         } : {})
                 }}
                 onContextMenu={e => {
                     if (eventContextMenuVisible && showEventContextMenu) {
                         e.preventDefault();
                         showEventContextMenu(e, event);
                     }
                 }}
                 onMouseEnter={() => this.setState({mouseover: true})}
                 onMouseLeave={() => this.setState({mouseover: false})}
            >
                <div style={{
                    display: 'flex',
                    position: 'absolute',
                    top: smallView ? 0 : 5,
                    right: smallView ? 0 : 5
                }}>
                    {appointmentActionsVisible &&
                    <div onClick={e => this.contextMenu.show(e)}>
                        <FontAwesomeIcon icon={["fas", "ellipsis-v"]} style={{marginRight: 4, marginLeft: 4}}/>
                    </div>}
                    {appointmentActionsVisible && this.renderActionMenu()}
                    {this.state.mouseover && deleteVisible &&
                    <FontAwesomeIcon icon={["fas", "trash"]} size="sm"
                                     onClick={() => deleteFunc(event)}/>}
                </div>
                <div style={{marginRight: smallView ? 5 : null}}>
                    {event.startMoment.tz(timeZone).format("HH:mm")} - {event.endMoment.tz(timeZone).format("HH:mm")}
                </div>
                <div>
                    <b>{EnumBlockType[event.type].name}</b>
                </div>
            </div>
        )
    }

    renderActionMenu() {
        const {event} = this.props;

        return (
            <ContextMenu ref={el => this.contextMenu = el}>
                <ContextMenuItem icon={<FontAwesomeIcon icon={["fas", "check-circle"]} style={{color: EnumBlockType.MANAGEMENT.textColor}}/>}
                                 label="Set as Management Time"
                                 onClick={() => {
                                     RotationBlockRemoteService.updateBlockType(event, EnumBlockType.MANAGEMENT.key, () => this.props.refreshCalendarData());
                                 }}/>
                <ContextMenuItem icon={<FontAwesomeIcon icon={["fas", "check-circle"]} style={{color: EnumBlockType.NEW_PATIENT.textColor}}/>}
                                 label="Set as New Complaint"
                                 onClick={() => this.updateBlock(EnumBlockType.NEW_PATIENT)}/>
                <ContextMenuItem icon={<FontAwesomeIcon icon={["fas", "check-circle"]} style={{color: EnumBlockType.FOLLOW_UP.textColor}}/>}
                                 label="Set as Follow up"
                                 onClick={() => this.updateBlock(EnumBlockType.FOLLOW_UP)}/>
            </ContextMenu>
        )
    }

    updateBlock(typeEnum) {
        const {event, timeZoneGetter, staffRoomMapGetter, refreshCalendarData} = this.props;

        if (!staffRoomMapGetter().get(event.staffId, DateUtil.formatTzDate(event.start, timeZoneGetter(), "YYYYMMDD"))) {
            dialog({
                title: "Update Block",
                component: <UpdateRotationBlockModal
                    blockEvent={event}
                    type={typeEnum}
                    timezone={timeZoneGetter()}
                    onSave={() => refreshCalendarData()}/>
            });
        } else {
            RotationBlockRemoteService.updateBlockType(event, typeEnum.key, () => refreshCalendarData());
        }
    }

}