import React, {Component} from "react";
import {Menubar} from "primereact/menubar";
import MenuItem from "./MenuItem";
import AuthService from "../../services/auth-service/AuthService";
import PropTypes from "prop-types";

export default class Menu extends Component {

    static defaultProps = {
        history: null
    };

    static propTypes = {
        history: PropTypes.object
    }

    fillMenuItems(root, menuItemElements) {
        React.Children.forEach(menuItemElements, (menuItemElement) => {
            if (menuItemElement
                && menuItemElement.type === MenuItem
                && (menuItemElement.props.authority == null || AuthService.userHasAuthority(menuItemElement.props.authority))) {

                const item = {
                    label: menuItemElement.props.label,
                    icon: menuItemElement.props.icon,
                    url: menuItemElement.props.externalUrl,
                    items: menuItemElement.props.items,
                    disabled: menuItemElement.props.disabled,
                    style: menuItemElement.props.style,
                    className: menuItemElement.props.className,
                    command: menuItemElement.props.url ? () => this.props.history.push(menuItemElement.props.url) : menuItemElement.command
                };

                if (!root.items) {
                    root.items = [];
                }

                root.items.push(item);

                this.fillMenuItems(item, menuItemElement.props.children);
            }
        });
    }

    removeEmptyItems(item, parent) {
        if (!item) {
            return;
        }

        if (this.itemIsEmpty(item)) {
            if (parent && parent.items && parent.items.length > 0) {
                parent.items = parent.items.filter(i => i !== item);
            }
        }

        if (item.items) {
            item.items.forEach(childItem => this.removeEmptyItems(childItem, item));
        }
    }

    itemIsEmpty(item) {
        return !item.url && !item.externalUrl && !item.command && (!item.items || item.items.length === 0);
    }

    render() {
        const root = {items: []};

        this.fillMenuItems(root, this.props.children);
        this.removeEmptyItems(root);

        return (
            <Menubar style={{border: 'none'}} model={root.items}/>
        )
    }

}