import React, {Component} from "react";
import './CommunicationMethod.css';
import PatientInformedEnum from "../../modules/emr/recordpage/treatment/enums/result/PatientInformedEnum";
import {DateInput} from "../index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DateUtil from "../../utils/DateUtil";

const calendarSymbol = <a>&#128197;</a>;
const emailSymbol = <a>&#128231;</a>;
const phoneSymbol = <a>&#128222;</a>;

const calendarValue = PatientInformedEnum.CALENDAR.key;
const emailValue = PatientInformedEnum.EMAIL.key;
const telephoneValue = PatientInformedEnum.TELEPHONE.key;

export default class CommunicationMethod extends Component {

    state = {
        value: [],
        informedDate: new Date()
    }

    constructor(props) {
        super(props);
        this.state.value = this.props.value;
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;
        if (oldProps.value !== newProps.value || oldProps.informedDate !== newProps.informedDate) {
            this.setState({value: newProps.value, informedDate: newProps.informedDate})
        }
    }

    render() {
        if (this.props.disabled) {
            return this.viewMode();
        }

        const {value, informedDate} = this.state;
        let selected = "selected selectable";
        let unselected = "unselected selectable";

        let calendar = value?.includes(calendarValue) ? selected : unselected;
        let email = value?.includes(emailValue) ? selected : unselected;
        let telephone = value?.includes(telephoneValue) ? selected : unselected;

        return (
            <>
                <div className={"row no-margin"} style={{fontSize: "16px"}}>
                    <div className={"col-4 no-padding"}>
                        <div className={calendar} onClick={() => {
                            this.onValueChanged(calendarValue)
                        }}>
                            {calendarSymbol}
                        </div>
                    </div>
                    <div className={"col-4 no-padding"}>
                        <div className={email} onClick={() => {
                            this.onValueChanged(emailValue)
                        }}>
                            {emailSymbol}
                        </div>

                    </div>
                    <div className={"col-4 no-padding"}>
                        <div className={telephone} onClick={() => {
                            this.onValueChanged(telephoneValue)
                        }}>
                            {phoneSymbol}
                        </div>
                    </div>
                </div>
                <div className={"row justify-content-center mt-2"}>
                    {value?.includes(calendarValue) && <DateInput className="mx-2"
                                                                  disableFuture={true}
                                                                  disableToolbar="true" variant="dialog"
                                                                  value={informedDate || null}
                                                                  onChange={date => {
                                                                      this.setState((state) => {
                                                                          state.informedDate = date;
                                                                          return state;
                                                                      }, () => {
                                                                          this.props.onInformedDateChanged(date)
                                                                      });
                                                                  }}
                    />}
                </div>
            </>);
    }

    onValueChanged = (clickedValue) => {
        if (this.state.value == null) {
            this.state.value = []
        }

        let [...value] = this.state.value;

        if (!value.includes(clickedValue)) {
            value.push(clickedValue);
        } else {
            value.splice(value.indexOf(clickedValue), 1);
        }
        this.setState({value: value}, () => {
            this.props.onChange(value)
        });
    }

    viewMode() {
        const {value} = this.state;
        const {informedDate} = this.props;

        if (value == null) {
            return null;
        }

        const symbols = [
            value?.includes(calendarValue) ? calendarSymbol : null,
            value?.includes(emailValue) ? emailSymbol : null,
            value?.includes(telephoneValue) ? phoneSymbol : null
        ].filter(Boolean);

        return (
            <>
                <div className={"row selected"} style={{fontSize: "16px"}}>
                    {symbols.map(symbol => <div className={"col"}>{symbol}</div>)}
                </div>
                {value?.includes(calendarValue) && informedDate != null &&
                <div>
                    <FontAwesomeIcon icon={["fas", "calendar-alt"]} style={{cursor: "default"}}/>
                    {DateUtil.formatDateMMM(informedDate)}
                </div>
                }
            </>)
    }
}
