import Enum from "../../../../../../enums/Enum";

const TopicEnum = new Enum({
            ERGONOMICS: {name: "Ergonomics",
                    rationale: "Educated the patient on proper computer " +
                    "screen, keyboard, mousing, table height and chair " +
                    "positioning to reduce tissue strain and decrease the risk of further exacerbation of injury."},
            SMR: {name: "Self Myofascial Release (SMR)",
                    rationale: "Patient instructed to perform SMR with a foam " +
                        "roller or trigger point ball, daily, " +
                        "to help reduce muscle tension, improve muscle imbalances, improve joint range of motion, " +
                        "relieve muscle tension and joint stress."},
            CRYOTHERAPY: {name: "Cryotherapy",
                    rationale: "Patient instructed to apply ice packs to the affected area " +
                        "to reduce inflammation and swelling."},
            THERMOTHERAPY: {name: "Thermotherapy",
                    rationale: "Heat therapy promotes circulation, reduces " +
                        "pain and muscle spasm and " +
                        "helps in the recovery of injured tissue."},
            AVOID_AGGRAVATING_ACTIVITIES: {name: "Avoid aggravating activities",
                    rationale: "Recommend avoiding any " +
                        "aggravating activities that will exacerbate " +
                        "your injury such as repetitive activities, prolonged sitting and " +
                        "so forth.  This will result in a faster recovery."},
            REST: {name: "Rest",
                    rationale: "Recommend rest from activity or overuse patterns " +
                        "for your injury to allow optimal " +
                        "healing and avoid the risk of re-injury."},
            DYNAMIC_MOBILITY_WARM_UP: {name: "Dynamic mobility warm up",
                    rationale: "Patient educated on how to do a " +
                        "daily mobility exercise for muscle " +
                        "lengthening and improve circulation. These exercises involve dynamic movements " +
                        "to warm up the muscles, increase joint mobility and improve overall freedom of movements"},
            CORE_MUSCLE_ACTIVATION: {name: "Core muscle activation",
                    rationale: "Patient educated on how to activate the core, " +
                        "by doing specific breathing and movement drills to activate the deep " +
                        "core muscles for better posture and off-loading the low back."},
            CORRECTIVE_MOVEMENT: {name: "Corrective movement pattern exercise",
                    rationale: "Patient was " +
                        "taught how to perform different movements " +
                        "in a progression from non weight bearing to weight bearing to specific loading to " +
                        "improve body awareness; coordination, mobility and exercise techniques."}
});

export default TopicEnum;
