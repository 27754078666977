import Enum from "../../../enums/Enum";

const EnumBreakTimePM = new Enum({
    NO_BREAK: "No Break",
    AFTER_LUNCH_BREAK: "After Lunch Break",
    MID_AFTERNOON: "Mid-Afternoon",
    AFTER_THE_LAST_PATIENT: "After the last patient"
});

export default EnumBreakTimePM;
