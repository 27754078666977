import Enum from "../../enums/Enum";

const EnumLeaveType = new Enum({
    'ANNUAL': {
        name: 'Annual Leave', code: 'A', unit: 'd',
        color: '#70A363', bgColor: '#84c6b7', textColor: '#ffffff'
    },
    'SICK': {
        name: 'Sick Leave', code: 'S', unit: 'd',
        color: '#E85840', bgColor: '#f5b4be', textColor: '#ffffff'
    },
    'FLEXIBLE': {
        name: 'Flexible Hours', code: 'F', unit: 'h',
        color: '#FF7702', bgColor: '#FF7702',
        textColor: '#ad5208', hourly: true
    },
    'UNPAID': {
        name: 'Unpaid Leave', code: 'U', unit: 'd',
        color: '#999999', bgColor: '#999999', textColor: '#ffffff'
    },
    'COURSE': {
        name: 'Course Leave', code: 'C', unit: 'h',
        color: '#F5BB43', bgColor: '#f6c8a8', textColor: '#ffffff',
        hourly: true
    },
    'MATERNITY': {
        name: 'Maternity Leave', code: 'M', unit: 'd',
        color: '#E85840', bgColor: '#f5b4be', textColor: '#ffffff'
    },
    'PATERNITY': {
        name: 'Paternity Leave', code: 'P', unit: 'd',
        color: '#227499', bgColor: '#227499', textColor: '#ffffff',
    },
    'COMPASSIONATE': {
        name: 'Compassionate Leave', code: 'C', unit: 'd',
        color: '#F5BB43', bgColor: '#f6c8a8', textColor: '#ffffff'
    },
    'HAJJ': {
        name: 'Hajj Leave', code: 'H', unit: 'd',
        color: '#70A363', bgColor: '#84c6b7', textColor: '#ffffff'
    },
    'EXTRA': {
        name: 'Extra Day', code: 'E', unit: 'd',
        color: '#7D67A0', bgColor: '#7D67A0', textColor: '#ffffff',
        showRemaining: false, isUnlimited: true, resizable: false,
        hidden: true
    },
    'CLOSED': {
        name: 'Clinic Closed', code: 'CC', unit: 'd',
        color: '#999999', bgColor: '#999999', textColor: '#ffffff',
        hidden: true
    }
});

export default EnumLeaveType;
