import React, {Component} from "react";
import EnumBlockType from "../enums/EnumBlockType";
import "./CalendarEvent.css";

export default class DefaultEvent extends Component {

    render() {
        const {event, timeZoneGetter, smallView} = this.props;
        const timeZone = timeZoneGetter();

        return (
            <div className="calendar-event"
                 style={{
                     borderColor: 'black',
                     color: 'black',
                     background: 'none',
                     ...(smallView ?
                         {
                             fontSize: 'small',
                             flexDirection: 'row-reverse',
                             justifyContent: 'space-around',
                             paddingLeft: 10,
                             paddingRight: 10,
                         } : {})
                 }}>
                <div style={{marginRight: smallView ? 5 : null}}>
                    {event.startMoment.tz(timeZone).format("HH:mm")} - {event.endMoment.tz(timeZone).format("HH:mm")}
                </div>
                <div>
                    <b>{EnumBlockType[event.type].name}</b>
                </div>
            </div>
        )
    }

}