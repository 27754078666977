import {AlertConstants} from '../constants';

export const alertActions = {
    success,
    warn,
    error,
    clear
};

function success(summary, detail, sticky=false) {
    return {type: AlertConstants.SUCCESS, message: {summary, detail, sticky}};
}

function warn(summary, detail, sticky=false) {
    return {type: AlertConstants.WARN, message: {summary, detail, sticky}};
}

function error(summary, detail, sticky=false) {
    return {type: AlertConstants.ERROR, message: {summary, detail, sticky}};
}

function clear() {
    return {type: AlertConstants.CLEAR};
}