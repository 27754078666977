import React, {Component} from 'react';
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import {RadioInput, TextareaInput, TextInput} from "../../../components";
import EtiologyEnum from "../../emr/recordpage/subjective/enums/complaint/EtiologyEnum";
import FormField from "../../../components/form/FormField";
import IntakeFormYesNoEnum from "./enum/IntakeFormYesNoEnum";
import TreatmentsForThisConditionEnum from "../enum/TreatmentsForThisConditionEnum";
import {PainSlider, RespondValueLabelComponent} from "../PainSlider";
import RespondLastTreatmentOptions from "../RespondLastTreatmentOptions";
import PainSliderOptions from "../PainSliderOptions";

class IntakeFormCurrentPatientCondition extends Component {

    setRespondLastTreatment(value) {
        const painValue = PainSliderOptions.getPainInfo(value);
        this.props.onChange("respondLastTreatment", painValue)
    }

    render() {
        const {
            selectedLanguage, readOnly, enumLabel, onChange, formFields,
            complaintRelatedTo, complaintRelatedToOther, happenedBefore, happenedBeforeYear,
            treatmentsForThisCondition, treatmentsForThisConditionOther, respondLastTreatment,
            hopeAccomplishFromTreatment
        } = this.props

        return (
            <>
                <h3 className="text-center mb-3">
                    <b>{IntakeFormTextEnum.Current_Patient_Condition[selectedLanguage]}</b>
                </h3>
                <div className="container dim14-form-row-align">
                    <div className="row">
                        <div className="col-4 dim14-form-label">
                            {IntakeFormTextEnum.Complaint_Related_To[selectedLanguage]}
                        </div>
                        <div className="col-8">
                            <div className="d-flex flex-column">
                                <RadioInput
                                    id="relatedRadioInput"
                                    value={complaintRelatedTo}
                                    disabled={readOnly}
                                    options={EtiologyEnum.toArray()}
                                    valueProperty={"key"}
                                    displayProperty={enumLabel}
                                    onChange={(value) =>
                                        onChange("complaintRelatedTo", value)
                                    }
                                />
                                <div className="d-flex">
                                    {complaintRelatedTo === EtiologyEnum.OTHER.key ? (
                                        <FormField
                                            className="w-100"
                                            ref={(formField) => formFields.push(formField)}
                                            required
                                            validateOn={
                                                readOnly ? true : complaintRelatedToOther
                                            }
                                        >
                                            <TextInput
                                                className="mb-1"
                                                value={complaintRelatedToOther}
                                                disabled={readOnly}
                                                onChange={(value) =>
                                                    onChange(
                                                        "complaintRelatedToOther",
                                                        value
                                                    )
                                                }
                                            />
                                        </FormField>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4 dim14-form-label">
                            {IntakeFormTextEnum.Happened_Before[selectedLanguage]}
                        </div>
                        <div className="col-8">
                            <div className="d-flex">
                                <RadioInput
                                    id="happenedBeforeRadioInput"
                                    value={happenedBefore}
                                    disabled={readOnly}
                                    options={IntakeFormYesNoEnum.toArray()}
                                    displayProperty={enumLabel}
                                    valueProperty={"key"}
                                    onChange={(value) =>
                                        onChange("happenedBefore", value)
                                    }
                                />
                                {happenedBefore === "Yes" ? (
                                    <FormField
                                        ref={(formField) => formFields.push(formField)}
                                        required
                                        validateOn={
                                            readOnly ? true : happenedBeforeYear
                                        }
                                    >
                                        <div style={{paddingRight: '20px'}} className="d-flex">
                                            {IntakeFormTextEnum.If_Yes_How_Long_Ago[selectedLanguage]}
                                            <TextInput
                                                className="ml-3"
                                                value={happenedBeforeYear}
                                                disabled={readOnly}
                                                onChange={(value) =>
                                                    onChange("happenedBeforeYear", value)
                                                }
                                            />
                                        </div>
                                    </FormField>
                                ) : null}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4 dim14-form-label">
                            {IntakeFormTextEnum.What_Have_You_Tried_To_Treat_This_Problem[selectedLanguage]}
                        </div>
                        <div className="col-8">
                            <div className="d-flex flex-column">
                                <RadioInput
                                    id="treatmentRadioInput"
                                    value={treatmentsForThisCondition}
                                    disabled={readOnly}
                                    options={TreatmentsForThisConditionEnum.toArray()}
                                    valueProperty={"key"}
                                    displayProperty={enumLabel}
                                    onChange={(value) =>
                                        onChange("treatmentsForThisCondition", value)
                                    }
                                />
                                <div className="d-flex">
                                    {treatmentsForThisCondition == TreatmentsForThisConditionEnum.OTHER.key ? (
                                        <FormField
                                            ref={(formField) => formFields.push(formField)}
                                            required
                                            className={"w-100"}
                                            validateOn={
                                                readOnly
                                                    ? true
                                                    : treatmentsForThisConditionOther
                                            }
                                        >
                                            <TextInput
                                                value={treatmentsForThisConditionOther}
                                                disabled={readOnly}
                                                onChange={(value) =>
                                                    onChange(
                                                        "treatmentsForThisConditionOther",
                                                        value
                                                    )
                                                }
                                            />
                                        </FormField>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-4 dim14-form-label">
                            {IntakeFormTextEnum.How_Did_You_Respond_Last_Treatment[selectedLanguage]}
                        </div>
                        <div className="col-8">
                            <PainSlider
                                ValueLabelComponent={RespondValueLabelComponent}
                                aria-label="custom thumb label"
                                defaultValue={
                                    respondLastTreatment
                                        ? respondLastTreatment
                                        : 0
                                }
                                min={0}
                                max={4}
                                step={1}
                                marks={RespondLastTreatmentOptions.respondMarks(enumLabel)}
                                valueLabelDisplay="auto"
                                disabled={readOnly}
                                onChange={(event, value) => {
                                    this.setRespondLastTreatment(value);
                                }}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4 dim14-form-label">
                            {IntakeFormTextEnum.What_Do_You_Hope_From_Treatment[selectedLanguage]}
                        </div>
                        <div className="col-8">
                            <TextareaInput
                                className="w-100"
                                value={hopeAccomplishFromTreatment}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("hopeAccomplishFromTreatment", value)
                                }
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default IntakeFormCurrentPatientCondition;