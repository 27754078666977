import React, {Component} from "react";
import "../../VisitHistory.css"

export default class RiskViewHistory extends Component {

    evaluateRiskValues (riskValue) {
        let noRisk = 0;
        let minimalRiskRange = [1, 2, 3];
        let moderateRiskRange = [4, 5, 6];
        let highRiskRange = [7, 8, 9, 10];

        if (riskValue === noRisk) {
            return "No Risk";
        } else if (minimalRiskRange.includes(riskValue)) {
            return "Minimal Risk";
        } else if (moderateRiskRange.includes(riskValue)) {
            return "Moderate Risk";
        } else if (highRiskRange.includes(riskValue)) {
            return "High Risk";
        }
    }

    render() {
        if (this.props.risk == null) {
            return <div className="pl-3">No information available</div>;
        }

        return (
            <div className="pl-3">
                <div className={"display-block"}>
                    <span> Patient clinical risk including presence of co-morbidities: </span>
                </div>

                <div className={"display-block"}>
                    <span>
                        <b> Medical Risk: </b>
                        {this.props.risk?.medical + "/10 " + this.evaluateRiskValues(this.props.risk?.medical)}
                    </span>
                </div>

                <div className={"display-block"}>
                    <span>
                        <b> Surgical Risk: </b>
                        {this.props.risk?.surgical + "/10 " + this.evaluateRiskValues(this.props.risk?.surgical)}
                    </span>
                </div>

                <div className={"display-block"}>
                    <span>
                        <b> Activity Risk: </b>
                        {this.props.risk?.activity + "/10 " + this.evaluateRiskValues(this.props.risk?.activity)}
                    </span>
                </div>

                <div className={"display-block"}>
                    <span>
                        <b> Social Risk: </b>
                        {this.props.risk?.social + "/10 " + this.evaluateRiskValues(this.props.risk?.social)}
                    </span>
                </div>

                {this.props.risk?.details && <div className={"display-block"}>
                    <span>
                        {this.props.risk?.details}
                    </span>
                </div>}
            </div>
        );
    }
}