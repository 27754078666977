import React, {Component} from 'react';
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import FormField from "../../../components/form/FormField";
import {PhoneInput, SelectInput, TextInput} from "../../../components";
import {connect} from "react-redux";

class IntakeFormEmergencyContactInformation extends Component {
    render() {
        const {
            selectedLanguage, readOnly, onChange,
            emergencyContactName, emergencyContactMobilePhone, emergencyContactRelationshipType, pushFormFieldRef
        } = this.props

        return (
            <div className="container dim14-form-row-align">
                <h3 className="text-center my-3">
                    <b>{IntakeFormTextEnum.Emergency_Contact_Information[selectedLanguage]}</b>
                </h3>
                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Emergency_Contact_Name[selectedLanguage]}:
                    </div>
                    <div className="col-8">
                        <FormField
                            ref={(ref) => pushFormFieldRef(ref)}
                            required
                            validateOn={readOnly ? true : emergencyContactName}
                        >
                            <TextInput
                                value={emergencyContactName}
                                alphabeticOnly
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("emergencyContactName", value)
                                }
                            />
                        </FormField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Emergency_Contact_Mobile_Number[selectedLanguage]}:
                    </div>
                    <div className="col-8">
                        <FormField
                            ref={(ref) => pushFormFieldRef(ref)}
                            required
                            validateOn={readOnly ? true : emergencyContactMobilePhone}
                        >
                            <PhoneInput
                                inputClass="w-100"
                                containerClass={
                                    readOnly ? "PhoneInputDisabledBackground" : ""
                                }
                                value={emergencyContactMobilePhone}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("emergencyContactMobilePhone", value)
                                }
                            />
                        </FormField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Emergency_Contact_Relationship[selectedLanguage]}:
                    </div>
                    <div className="col-8">
                        <SelectInput value={emergencyContactRelationshipType}
                                     options={this.props.relationshipTypes}
                                     displayProperty={"name"}
                                     disabled={readOnly}
                                     className={`DropdownBorderColor w-100 ${
                                         readOnly
                                             ? "DropdownDisabledBackground"
                                             : "DropdownBorderColor"
                                     }`}
                                     onChange={(val) => onChange('emergencyContactRelationshipType', val)}
                        />
                    </div>
                </div>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        relationshipTypes: state.base.relationshipTypes
    };
}

export default connect(mapStateToProps)(IntakeFormEmergencyContactInformation);
