import React, {Component} from 'react'
import './InsuranceEligibilityScreen.css'
import {Image} from "react-bootstrap";

export default class InsuranceDocuments extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const item = this.props.item;
        const formImgName = 'COMPLETE' === item.claimFormStatus ? 'form-completed.png' : 'form-pending.png';
        const reportImgName = 'COMPLETE' === item.medicalReportStatus ? 'report-completed.png' : 'report-pending.png';

        return <div className="display-flex flex-row">
            <Image src={`/assets/insurance/${formImgName}`} width={"13px"} onmouseover='' style={{cursor: 'pointer'}}
                   onClick={() => this.props.onClaimFormStatusChange('COMPLETE' === item.claimFormStatus ? "PENDING" : "COMPLETE")}/>
            <Image src={`/assets/insurance/${reportImgName}`} width={"13px"} onmouseover='' style={{cursor: 'pointer'}}
                   onClick={() => this.props.onMedicalReportStatusChange('COMPLETE' === item.medicalReportStatus ? "PENDING" : "COMPLETE")}
                   style={{marginLeft: '5px'}}/>
        </div>
    }
}
