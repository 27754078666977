import React, {Component} from "react";
import "../../VisitHistory.css"
import LocationEnum from "../../../assessment/enums/rangeofmotion/LocationEnum";
import MuscleGroupsEnum from "../../../assessment/enums/rangeofmotion/MuscleGroupsEnum";
import GradeEnum from "../../../assessment/enums/rangeofmotion/GradeEnum";
import IndividualMusclesEnum from "../../../assessment/enums/rangeofmotion/IndividualMusclesEnum";
import MuscleStrengthResultEnum from "../../../assessment/enums/rangeofmotion/MuscleStrengthResultEnum";

export default class MuscleStrengthViewHistory extends Component {
    isMuscleGroupDataEmpty() {
        return !this.props?.muscleGroup?.items?.length > 0;
    }

    isIndividualMuscleDataEmpty() {
        return !this.props?.individualMuscle?.items?.length > 0;
    }

    render() {
        if (this.isMuscleGroupDataEmpty() && this.isIndividualMuscleDataEmpty()) {
            return <div className="pl-3">
                {this.props.muscleGroup && this.props.muscleGroup.none ? "Normal" : "No information available"}
            </div>;
        }

        return (
            <>
                {!this.isMuscleGroupDataEmpty() &&
                <table className={"visit-history-table"}>
                    <thead>
                    <tr key={"muscleGroupTableHeader"} style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                        <th>Muscle Group</th>
                        <th>Location</th>
                        <th>Result</th>
                        <th>Grade</th>
                        <th>Details</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.muscleGroup.items.map((rowValue, index) => {
                        return (
                            <tr key={"muscleGroupTableItem" + index} style={{backgroundColor: "white"}}>
                                <td>{rowValue.muscle ? MuscleGroupsEnum[rowValue.muscle].name : null}</td>
                                <td>{rowValue.location ? LocationEnum[rowValue.location].name : null}</td>
                                <td>{rowValue.result ? MuscleStrengthResultEnum[rowValue.result].name : null}</td>
                                <td>{rowValue.grade ? GradeEnum[rowValue.grade].name : null}</td>
                                <td>{rowValue.details}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>}

                {!this.isIndividualMuscleDataEmpty() &&
                <table className={"visit-history-table"}>
                    <thead>
                    <tr key={"individualMuscleTableHeader"} style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                        <th>Individual Muscle</th>
                        <th>Location</th>
                        <th>Result</th>
                        <th>Grade</th>
                        <th>Details</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.individualMuscle.items.map((rowValue, index) => {
                        return (
                            <tr key={"individualMuscleTableItem" + index} style={{backgroundColor: "white"}}>
                                <td>{rowValue.muscle ? IndividualMusclesEnum[rowValue.muscle].name : null}</td>
                                <td>{rowValue.location ? LocationEnum[rowValue.location].name : null}</td>
                                <td>{rowValue.result ? MuscleStrengthResultEnum[rowValue.result].name : null}</td>
                                <td>{rowValue.grade ? GradeEnum[rowValue.grade].name : null}</td>
                                <td>{rowValue.details}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>}
            </>
        );
    }
}