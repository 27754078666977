import React, {Component} from "react";
import '../EMR.css';
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AsyncSelect from "react-select/async";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import Select from "react-select";
import LevelEnum from "./enums/LevelEnum";
import TypeEnum from "./enums/TypeEnum";
import BillTypeEnum from "./enums/BillTypeEnum";
import Button from "react-bootstrap/Button";
import AuthService from "../../../../services/auth-service/AuthService";
import DateUtil from "../../../../utils/DateUtil";
import {CheckboxInput} from "../../../../components";
import AddButton from "../common/table/AddButton";
import RemoveButton from "../common/table/RemoveButton";
import NotificationService from "../../../../services/notification-service/NotificationService";
import RedAsterisk from "../../../../components/asterisk/RedAsterisk";

export default class ActiveDiagnosisTab extends Component {

    initialState = {
        activeKey: 0,
        previousSelected: [],
        icd10Code: null,
        level: null,
        type: null,
        billType: null
    }

    constructor(props) {
        super(props);
        this.MINIMUM_SEARCH_LENGTH = 2;
        this.assignAllDiagnosis();

        const user = AuthService.getUser();
        this.isProvider = ["DOCTOR", "THERAPIST"].includes(user.type);
        this.isNurse = "NURSE" === user.type;

        let isNewComplaintOrNewPatient = this.props.visits[this.props.currentVisitIndex].appointmentType === "NEW_COMPLAINT" ||
            this.props.visits[this.props.currentVisitIndex].appointmentType === "NEW_PATIENT";

        this.initialState = {
            ...this.initialState,
            billType: this.props.isDirectBilling ? "DIRECT_BILLING" : "SELF_PAY",
            level: isNewComplaintOrNewPatient ? "PRINCIPAL" : null,
            type: isNewComplaintOrNewPatient ? "FINAL" : null
        }

        this.state = {
            ...this.initialState
        };
    }

    assignAllDiagnosis() {
        this.allDiagnosis = [];
        for (let i = 0; i < this.props.visits.length; i++) {
            let visit = this.props.visits[i];
            visit.diagnosis.diagnosisGroups.forEach((dg) => {
                Array.prototype.push.apply(this.allDiagnosis, dg.diagnosisItems);
            });
        }
    }

    render() {
        let diagnosisGroups = this.props.diagnosis.diagnosisGroups;
        const isDirectBilling = this.props.isDirectBilling;
        let tabs = [];

        if (diagnosisGroups.length == 0) {
            diagnosisGroups.push({diagnosisItems: []});
        }

        diagnosisGroups.forEach((diagnosisGroup, index) => {
            tabs.push(
                <Tab key={index} eventKey={index} title={
                    <div style={{display: "flex"}}>
                        {this.getDiagnosisGroupTitle(diagnosisGroup)}&nbsp;
                        <RemoveButton
                            onClick={() => {
                                diagnosisGroups.splice(index, 1);
                            }}/>
                    </div>
                }>
                    {this.activeDiagnosisTable(diagnosisGroup, isDirectBilling)}
                </Tab>
            );
        });

        tabs.push(
            <Tab key={"+"} eventKey={"+"} title={"+"}>
            </Tab>
        );

        return (
            <div>
                <div className="content-label">Diagnosis <RedAsterisk renderForAllTypes/></div>
                <Tabs activeKey={this.state.activeKey}
                      id="emr-tabs"
                      style={{fontSize: "12px"}}
                      onSelect={(tabName) => {
                          if (tabName == "+") {
                              diagnosisGroups.push({diagnosisItems: []});
                              let activeTab = diagnosisGroups.length - 1;
                              this.setState({activeKey: activeTab})
                          } else {
                              this.setState({activeKey: tabName})
                          }
                      }}>
                    {tabs}
                </Tabs>

                <br/>
                <br/>

                <div style={{display: "flex"}}>
                    <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Previous Diagnosis</div>
                    <Button variant="outline-secondary"
                            size="sm"
                            disabled={isDirectBilling}
                            onClick={() => {
                                if (this.state.previousSelected.length == 0) {
                                    return;
                                }
                                this.state.previousSelected.forEach((item) => {
                                    let copied = Object.assign({}, this.allDiagnosis[item]);
                                    copied.provider = AuthService.getUser().name;
                                    copied.visitDate = new Date();
                                    copied.id = null;
                                    this.props.diagnosis.diagnosisGroups[this.state.activeKey].diagnosisItems.push(copied);
                                });
                                this.setState({previousSelected: []});
                            }}>
                        Repeat Selected
                    </Button>
                </div>
                {this.previousDiagnosisTable()}
            </div>
        );
    }

    activeDiagnosisTable(diagnosisGroup, directBilling) {
        const diagnosisLevelOptions = LevelEnum.toArray().filter(level => !this.props.isDirectBilling || !this.isProvider || level.key !== 'PRINCIPAL').map(v => {
                return {value: v.key, label: v.name}
            }
        );
        const currentVisit = this.props.visits[this.props.currentVisitIndex];
        return (
            <div className={"content-card"}>
                <table className={"emr-table"}>
                    <tbody>
                    <tr>
                        <th>ICD</th>
                        <th>Description</th>
                        <th style={{width: 100}}>Level</th>
                        <th style={{width: 120}}>Type</th>
                        <th style={{width: 70}}>Bill Type</th>
                        <th style={{color: "red"}}>ⓧ</th>
                    </tr>
                    {(diagnosisGroup.diagnosisItems == null || diagnosisGroup.diagnosisItems.length == 0) ? null :
                        diagnosisGroup.diagnosisItems.map((diagnosisItem, diagnosisItemIndex) => {
                            return (
                                <tr key={"activeDiagnosisTab" + diagnosisItemIndex} style={{backgroundColor: "white"}}>
                                    <td>{diagnosisItem.code}</td>
                                    <td>{diagnosisItem.description}</td>
                                    <td>{diagnosisItem.level != null ? LevelEnum[diagnosisItem.level].name : ""}</td>
                                    <td>{diagnosisItem.type != null ? TypeEnum[diagnosisItem.type].name : ""}</td>
                                    <td>{diagnosisItem.billType != null ? BillTypeEnum[diagnosisItem.billType].name : ""}</td>
                                    <td>
                                        {BillTypeEnum[diagnosisItem.billType] !== BillTypeEnum.DIRECT_BILLING &&
                                        <RemoveButton
                                            onClick={() => {
                                                diagnosisGroup.diagnosisItems.splice(diagnosisItemIndex, 1);
                                                this.forceUpdate();
                                            }}
                                        />
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                    <tr style={{backgroundColor: "white"}}>
                        <td style={{minWidth: "80px"}}>
                            <AsyncSelect
                                className={"emr-in-table-select-container"}
                                classNamePrefix={"emr-in-table-select"}
                                noOptionsMessage={this.noOptionsMessage.bind(this)}
                                loadOptions={this.loadICD10CodesByCode}
                                value={this.state.icd10Code != null ?
                                    {
                                        value: this.state.icd10Code,
                                        label: this.state.icd10Code.code
                                    } : null}
                                onChange={(event) => {
                                    this.setState({icd10Code: event.value});
                                }}
                            />
                        </td>
                        <td>
                            <AsyncSelect
                                className={"emr-in-table-select-container"}
                                classNamePrefix={"emr-in-table-select"}
                                noOptionsMessage={this.noOptionsMessage.bind(this)}
                                loadOptions={this.loadICD10CodesByDescription}
                                value={this.state.icd10Code != null ?
                                    {
                                        value: this.state.icd10Code,
                                        label: this.state.icd10Code.description
                                    } : null}
                                onChange={(event) => {
                                    this.setState({icd10Code: event.value});
                                }}
                            />
                        </td>
                        <td>
                            <Select
                                className={"emr-in-table-select-container"}
                                classNamePrefix={"emr-in-table-select"}
                                value={this.state.level != null ?
                                    {
                                        value: this.state.level,
                                        label: LevelEnum[this.state.level].name
                                    } : null}
                                onChange={(event) => {
                                    this.setState({level: event.value});
                                }}
                                options={diagnosisLevelOptions}
                            />
                        </td>
                        <td>
                            <Select
                                className={"emr-in-table-select-container"}
                                classNamePrefix={"emr-in-table-select"}
                                value={this.state.type != null ?
                                    {
                                        value: this.state.type,
                                        label: TypeEnum[this.state.type].name
                                    } : null}
                                onChange={(event) => {
                                    this.setState({type: event.value});
                                }}
                                options={TypeEnum.toArray().map(v => {
                                        return {value: v.key, label: v.name}
                                    }
                                )}
                            />
                        </td>
                        <td>{this.state.billType != null ? BillTypeEnum[this.state.billType].name : ""}</td>
                        <td>
                            <AddButton
                                onClick={() => {
                                    if (diagnosisGroup.diagnosisItems == null) {
                                        diagnosisGroup.diagnosisItems = [];
                                    }
                                    if (directBilling && (
                                        this.allDiagnosis.find(item => item.code === this.state.icd10Code?.code) ||
                                        diagnosisGroup.diagnosisItems.find(item => item.code === this.state.icd10Code?.code)
                                    )) {
                                        NotificationService.showNotification({
                                            severity: 'warn',
                                            summary: 'Warning',
                                            detail: 'Diagnosis code is already selected for the direct billing visit'
                                        });
                                        return;
                                    }
                                    diagnosisGroup.diagnosisItems.push(
                                        {
                                            code: this.state.icd10Code != null ? this.state.icd10Code.code : null,
                                            description: this.state.icd10Code != null ? this.state.icd10Code.description : null,
                                            level: this.state.level,
                                            type: this.state.type,
                                            billType: this.state.billType,
                                            provider: this.isNurse ? currentVisit.appointment.staff.name : AuthService.getUser().name,
                                            visitDate: new Date()
                                        }
                                    );
                                    this.setState({
                                        icd10Code: this.initialState.icd10Code,
                                        level: this.initialState.level,
                                        type: this.initialState.type,
                                        billType: this.initialState.billType
                                    });
                                }}
                                disabled={!this.state.icd10Code || !this.state.level || !this.state.type}
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    getDiagnosisGroupTitle(diagnosisGroup) {
        if (diagnosisGroup.diagnosisItems == null || diagnosisGroup.diagnosisItems.length == 0) {
            return "\<Add a principal\>";
        }
        let principal = diagnosisGroup.diagnosisItems.find(i => i.level == "PRINCIPAL");
        return principal == null ? "\<Add a principal\>" : principal.description;
    }

    previousDiagnosisTable() {
        if (this.allDiagnosis == null || this.allDiagnosis.length == 0) {
            return "No previous diagnosis";
        }

        return (
            <div className={"content-card"}>
                <table className={"emr-table"}>
                    <tbody>
                    <tr>
                        <th>Visit Date</th>
                        <th>Provider</th>
                        <th>Diagnosis Name</th>
                        <th>ICD</th>
                        <th>Level</th>
                        <th>Type</th>
                        <th>Bill Type</th>
                        <th style={{color: "#7BAC16"}}>☑</th>
                    </tr>
                    {this.allDiagnosis.map((diagnosisItem, diagnosisItemIndex) => {
                        return (
                            <tr key={"previousDiagnosisTab" + diagnosisItemIndex} style={{backgroundColor: "white"}}>
                                <td>{DateUtil.formatDate(diagnosisItem.visitDate)}</td>
                                <td>{diagnosisItem.provider}</td>
                                <td>{diagnosisItem.description}</td>
                                <td>{diagnosisItem.code}</td>
                                <td>{diagnosisItem.level != null ? LevelEnum[diagnosisItem.level].name : ""}</td>
                                <td>{diagnosisItem.type != null ? TypeEnum[diagnosisItem.type].name : ""}</td>
                                <td>{diagnosisItem.billType != null ? BillTypeEnum[diagnosisItem.billType].name : ""}</td>
                                <td>
                                    <CheckboxInput
                                        value={this.state.previousSelected.includes(diagnosisItemIndex)}
                                        onChange={(newValue) => {
                                            if (newValue) {
                                                this.state.previousSelected.push(diagnosisItemIndex);
                                                this.forceUpdate();
                                            } else {
                                                this.state.previousSelected.splice(
                                                    this.state.previousSelected.indexOf(diagnosisItemIndex), 1);
                                                this.forceUpdate();
                                            }
                                        }}/>
                                </td>
                            </tr>
                        );
                    })}

                    </tbody>
                </table>
            </div>
        );
    }

    loadICD10CodesByCode = (inputValue, callback) => {
        if (inputValue.length < this.MINIMUM_SEARCH_LENGTH) {
            return callback(null);
        }
        RemotingService.getRemoteCall('api/icd10/code/' + inputValue, null, (response) => {
            if (response == null || response.length == 0) {
                return callback(null);
            }
            let options = response.map(r => {
                return {value: r, label: r.code}
            });
            return callback(options);
        });
    }

    loadICD10CodesByDescription = (inputValue, callback) => {
        if (inputValue.length < this.MINIMUM_SEARCH_LENGTH) {
            return callback(null);
        }
        RemotingService.getRemoteCall('api/icd10/description/' + inputValue, null, (response) => {
            if (response == null || response.length == 0) {
                return callback(null);
            }
            let options = response.map(r => {
                return {value: r, label: r.description}
            });
            return callback(options);
        });
    }

    noOptionsMessage(input) {
        if (input.inputValue == null || input.inputValue.length < this.MINIMUM_SEARCH_LENGTH) {
            return <div>Type {this.MINIMUM_SEARCH_LENGTH} characters</div>;
        } else {
            return <div>No options</div>;
        }
    }
}
