import React from 'react';
import {withRouter} from "react-router";
import {Button, ButtonGroup} from 'react-bootstrap';
import {DateInput, ProgressBar} from "../../../components";
import DateUtil from "../../../utils/DateUtil";
import RoomCalendar from "./RoomCalendar";
import SockJsClient from "react-stomp";
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SchedulingService from "../../../services/scheduling-service/SchedulingService";
import RemotingService from "../../../services/remoting-service/RemotingService";
import EnumScheduleJobStatus from "../enums/EnumScheduleJobStatus";
import AuthService from "../../../services/auth-service/AuthService";
import dialog from "../../../components/dialog/Dialog";
import GenerateRotationModal from "../GenerateRotationModal";
import ScheduleGenerationMissingDataScreen from "../ScheduleGenerationMissingDataScreen";

class RoomCalendarTopBar extends React.Component {

    state = {};

    componentDidMount() {
        this.retrieveScheduleJob();
    }

    getTitle = () => {
        const {selectedDate, selectedView} = this.props;

        if (selectedView.id === 'month') {
            return DateUtil.formatDate(selectedDate, "MMMM YYYY");
        }
        if (selectedView.id === 'week') {
            let startDate = selectedDate.clone().startOf(selectedView.id);
            let endDate = selectedDate.clone().endOf(selectedView.id);

            if (startDate.isSame(endDate, 'month')) {
                return DateUtil.formatDate(startDate, "MMMM Do") + " - " + DateUtil.formatDate(endDate, "Do YYYY");
            } else {
                return DateUtil.formatDate(startDate, "MMMM Do") + " - " + DateUtil.formatDate(endDate, "MMMM Do YYYY");
            }
        }
        return DateUtil.formatDateDo(this.state.selectedDate);
    };

    handlePrevClick = () => {
        const {selectedDate, selectedView} = this.props;
        this.props.onDateChange(selectedDate.subtract(1, selectedView.unit));
    };

    handleTodayClick = () => {
        this.props.onDateChange(DateUtil.today());
    };

    handleNextClick = () => {
        const {selectedDate, selectedView} = this.props;
        this.props.onDateChange(selectedDate.add(1, selectedView.unit));
    };

    retrieveScheduleJob = () => {
        SchedulingService.getScheduleJob(this.updateScheduleJob);
    };

    preparePreferencesContainMissingData = (staffPreferences) => {
        let resultList = [];

        staffPreferences.forEach(staffPreference => {
            if(staffPreference && staffPreference.dayPreferences && staffPreference.dayPreferences.length > 0) {
                staffPreference.dayPreferences.forEach(dayPreference => {
                    if((dayPreference.breakTimeAM == null || dayPreference.breakTimePM == null ||
                        dayPreference.followUp == null || dayPreference.newPatient == null ||
                        dayPreference.noOfPatientPerDay == null) && !resultList.some(item => item.id === staffPreference.id)){
                        resultList.push(staffPreference);
                    }
                })
            }
        })

        return resultList;
    }

    generateRotation = (e) => {
        let staffIds = this.props.staffList.map(staff => staff.id);
        RemotingService.getRemoteCall(`api/staff/getStaffPreferencesOfNotExcludedStaff`, {staffIds}, staffPreferences => {
            let preferencesContainMissingData = this.preparePreferencesContainMissingData(staffPreferences);
            if(preferencesContainMissingData.length > 0){
                dialog({
                    title: "Generate Rotation Error",
                    style: {width: 750, height: 550},
                    component: <ScheduleGenerationMissingDataScreen preferencesContainMissingData={preferencesContainMissingData}/>
                });
            }else{
                dialog({
                    title: "Generate Rotation",
                    component: <GenerateRotationModal onGenerate={
                        (scheduleJob) => {
                            this.setState({scheduleJob}, () => {
                                this.toggleLogs(e, true);
                            });
                        }
                    }/>
                });
            }
        });
    };

    updateScheduleJob = (scheduleJob) => {
        this.setState({scheduleJob});

        if (scheduleJob && scheduleJob.status === 'COMPLETED') {
            this.props.onScheduleJobUpdate();
        }

        if (scheduleJob && scheduleJob.status === 'CANCELLED') {
            this.setState({showLogs: true});
        }
    };

    rejectScheduleJob = () => {
        SchedulingService.rejectScheduleJob(this.state.scheduleJob.id, (scheduleJob) => {
            this.setState({scheduleJob}, () => {
                this.props.onScheduleJobUpdate();
            });
        });
    };

    confirmScheduleJob = (force = false) => {
        SchedulingService.confirmScheduleJob(this.state.scheduleJob.id, force, (scheduleJob) => {
            this.setState({scheduleJob}, () => {
                this.props.onScheduleJobConfirm();
            });
        });
    };

    canGenerateRotation = (scheduleJob) => {
        return !scheduleJob || ['CANCELLED', 'REJECTED', 'CONFIRMED'].includes(scheduleJob.status);
    };

    toggleLogs = (e, open) => {
        if (e.type === 'keydown' && (e.key === 'Tab' || e.key === 'Shift')) {
            return;
        }

        this.setState({showLogs: open});
    };

    render() {
        const {scheduleJob} = this.state;

        return (
            <div className="RoomCalendarTopBar">
                <div className={"col-auto"}>
                    <h4>{this.getTitle()}</h4>
                </div>

                <div className={"col-auto"}>
                    <>
                        <ButtonGroup className="RoomCalendarTopBarSettingsButtonGroup">
                            <Button variant="outline-secondary"
                                    className="RoomCalendarTopBarButton"
                                    onClick={() => this.props.onSwitchSettingsMode()}>
                                <FontAwesomeIcon icon={["fas", "exchange-alt"]}/> {'Settings'}
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup className="RoomCalendarTopBarButtonGroup">
                            <Button variant="outline-secondary"
                                    className="RoomCalendarTopBarButton"
                                    onClick={() => this.handlePrevClick()}>
                                Back
                            </Button>
                            <Button variant="outline-secondary"
                                    className={`RoomCalendarTopBarButton ${DateUtil.isToday(this.props.selectedDate) ? "Active" : ""}`}
                                    onClick={() => this.handleTodayClick()}>
                                Today
                            </Button>
                            <Button variant="outline-secondary"
                                    className="RoomCalendarTopBarButton"
                                    onClick={() => this.handleNextClick()}>
                                Next
                            </Button>
                        </ButtonGroup>
                        <ButtonGroup className="RoomCalendarTopBarButtonGroup">
                            {
                                RoomCalendar.VIEW_TYPES.map(view => {
                                    return (
                                        <Button variant="outline-secondary"
                                                className={`RoomCalendarTopBarButton ${this.props.selectedView.id === view.id ? "Active" : ""}`}
                                                onClick={() => this.props.onViewChange(view)}>
                                            {view.label}
                                        </Button>
                                    );
                                })
                            }
                        </ButtonGroup>
                        <DateInput disableToolbar="true" variant="dialog"
                                   value={this.props.selectedDate}
                                   onChange={date => this.props.onDateChange(date)}
                                   TextFieldComponent={
                                       ({inputProps, onClick, inputRef}) =>
                                           <Button variant="outline-secondary"
                                                   className="RoomCalendarTopBarButtonGroup"
                                                   inputProps={inputProps} onClick={onClick} inputRef={inputRef}>
                                               <FontAwesomeIcon icon={["fas", "calendar-alt"]}/> Go to Date
                                           </Button>
                                   }>
                        </DateInput>
                        {AuthService.userHasAuthority("UPDATE_SCHEDULING") && this.renderButtonGroup(scheduleJob)}
                    </>
                </div>
            </div>
        );
    }

    renderButtonGroup(scheduleJob) {
        return (
            this.canGenerateRotation(scheduleJob) ?
                <ButtonGroup
                    style={{width: 250}}>
                    {scheduleJob && scheduleJob.status === 'CANCELLED' &&
                    <Button variant="outline-info" className="RoomCalendarTopBarButton" style={{margin: 5}}>
                        <FontAwesomeIcon icon={["fas", "file-contract"]}
                                         onClick={e => this.toggleLogs(e, true)}/>
                        {this.renderScheduleJobLogs(scheduleJob)}
                    </Button>
                    }
                    <Button variant="success" onClick={e => this.generateRotation(e)}
                            className="GenerateRotationButton RoomCalendarTopBarButtonGroup"
                            disabled={!this.canGenerateRotation(scheduleJob)} style={{width: 200}}>
                        <FontAwesomeIcon icon={["fas", "thumbs-up"]} style={{marginRight: 10}}/>
                        Generate Rotation
                    </Button>
                </ButtonGroup>
                :
                <ButtonGroup className="GenerateRotationButton RoomCalendarTopBarButtonGroup"
                             style={{width: 400}}>
                    <Button variant="outline-info" className="RoomCalendarTopBarButton">
                        <FontAwesomeIcon icon={["fas", "file-contract"]} style={{marginRight: 5}}
                                         onClick={e => this.toggleLogs(e, true)}/>
                        {this.renderScheduleJobLogs(scheduleJob)}
                        <SockJsClient
                            url={RemotingService.getApiBaseUrl() + "websocket"}
                            topics={["/topic/schedule-job/" + scheduleJob.id]}
                            onMessage={(msg) => this.updateScheduleJob(msg.body.data)}
                            ref={client => this.clientRef = client}
                        />
                    </Button>
                    {
                        scheduleJob.status === 'IN_PROGRESS' &&
                        <Button variant="info" className="RoomCalendarTopBarButton"
                                onClick={e => this.toggleLogs(e, true)} style={{width: 200}}>
                            Generating... (%{scheduleJob.progress})
                        </Button>
                    }
                    {
                        ['COMPLETED', 'IN_PROGRESS'].includes(scheduleJob.status) &&
                        <Button variant="danger" className="RoomCalendarTopBarButton"
                                onClick={() => this.rejectScheduleJob()}>
                            <FontAwesomeIcon icon={["fas", "eraser"]} style={{marginRight: 5}}/>
                            {scheduleJob.status === 'IN_PROGRESS' ? 'Cancel' : 'Reject'}
                        </Button>
                    }
                    {
                        scheduleJob.status === 'COMPLETED' &&
                        <Button variant="success" className="RoomCalendarTopBarButton"
                                onClick={() => this.confirmScheduleJob()}>
                            <FontAwesomeIcon icon={["fas", "check-double"]} style={{marginRight: 5}}/>
                            Accept
                        </Button>
                    }
                </ButtonGroup>
        )
    }

    renderScheduleJobLogs = (scheduleJob) => {
        return (
            <Drawer anchor="top" open={this.state.showLogs} onClose={e => this.toggleLogs(e, false)}>
                <ProgressBar variant="determinate"
                             value={scheduleJob.status === 'COMPLETED' ? 100 : scheduleJob.progress}/>
                <div className="RotationLogsDrawer">
                    <Grid className={"RotationLogsDrawerContent"} xs={12} sm={6}>
                        <h2>Rotation Generation is {EnumScheduleJobStatus[scheduleJob.status]}</h2>
                        {
                            scheduleJob.logs.map((log, i) => (
                                <label key={"rotation-log" + i}>
                                    {log}
                                </label>
                            ))
                        }
                    </Grid>
                </div>
                <div className={"RotationLogsDrawerFooter"}>
                    {'CANCELLED' === scheduleJob.status ? 'Rotation generation has failed, please update provider preferences and try again.' :
                        'You can accept or reject the rotation once the generation completed.'}
                </div>
                <Button variant="secondary" className="RoomCalendarTopBarButton"
                        onClick={() => this.toggleLogs({}, false)}>
                    <FontAwesomeIcon icon={["fas", "angle-double-up"]} style={{marginRight: 5}}/>
                    Close
                </Button>
            </Drawer>
        );
    };
}

export default withRouter(RoomCalendarTopBar);
