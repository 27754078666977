import React, {Component} from "react";
import PatientUtil from "../../../../utils/PatientUtil";
import StringUtil from "../../../../utils/StringUtil";
import MoneyUtil from "../../../../utils/MoneyUtil";
import DateUtil from "../../../../utils/DateUtil";
import {Messages} from "primereact/messages";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Tooltip from "../../../../components/tooltip/Tooltip";
import {DateInput, NumberInput} from "../../../../components";
import PropTypes from "prop-types";
import EnumGiftStatus from "./enums/EnumGiftStatus";

export default class CreditGiftDetail extends Component {

    static propTypes = {
        creditGiftForm: PropTypes.object,
        editable: PropTypes.bool,
        onEdit: PropTypes.func
    }

    static defaultProps = {
        creditGiftForm: null,
        editable: false,
        onEdit: () => {}
    }

    constructor(props) {
        super(props);
        this.state = {
            isExpired: props.creditGiftForm.status === "SENT" && DateUtil.isBefore(props.creditGiftForm.credit.validityDate, DateUtil.now()),
            giftedAmount: props.creditGiftForm.credit.balance,
            validityDate: props.creditGiftForm.credit.validityDate
        };
    }

    componentDidMount() {
        if (this.state.isExpired) {
            this.messages.show({severity: 'error', detail: 'Validity date is expired.', sticky: true});
        }
    }

    render() {
        const {className, style, creditGiftForm} = this.props;
        const credit = creditGiftForm.credit;
        const sentBy = credit.patient;

        return (
            <div style={style} className={className}>
                <div className="d-flex p-justify-center mb-3">
                    <img width={125} height={35} src={process.env.PUBLIC_URL + '/assets/disc.png'} alt=""/>
                </div>
                <Messages ref={el => this.messages = el}/>
                <div style={{border: '1px solid rgba(0,0,0,.1)'}} className="container">
                    <div className="row">
                        <div className="col font-weight-bold">
                            Sent by
                        </div>
                        <div className="col d-flex flex-column text-right">
                            <span className="font-weight-bold">{PatientUtil.getPatientsName(sentBy)}</span>
                            <span className="disc-grey">{sentBy.email}</span>
                            <span className="disc-grey">{sentBy.mobilePhone}</span>
                            <span className="font-weight-bold">{StringUtil.mrn(sentBy.mrn)}</span>
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col font-weight-bold">
                            Receiver
                        </div>
                        <div className="col d-flex flex-column text-right">
                            <span className="font-weight-bold">{creditGiftForm.firstName} {creditGiftForm.lastName}</span>
                            <span className="disc-grey">{creditGiftForm.email}</span>
                            <span className="disc-grey">{creditGiftForm.phone}</span>
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col font-weight-bold">
                            Gifted Amount
                        </div>
                        <div className="col text-right">
                            {this.props.editable ?
                            <div>
                                <NumberInput style={{width: 90}} className="p-0 mr-1 text-right"
                                             value={this.state.giftedAmount}
                                             onChange={giftedAmount => {
                                                 this.setState({giftedAmount});
                                                 this.props.onEdit(giftedAmount, this.state.validityDate);
                                             }}/>
                                <small>{MoneyUtil.getCurrency(credit.country)}</small>
                            </div>
                            :
                            <span className="disc-green">
                                {MoneyUtil.format(credit.balance, MoneyUtil.getCurrency(credit.country))}
                                <small>{MoneyUtil.getCurrency(credit.country)}</small>
                            </span>
                            }
                        </div>
                    </div>
                    {
                    creditGiftForm.status == EnumGiftStatus.REDEEMED.key && creditGiftForm.redeemedAmount != credit.balance &&
                    <div className="row">
                        <div className="col font-weight-bold">
                            Redeemed Amount
                        </div>
                        <div className="col text-right">
                            <span className="disc-green">
                                {MoneyUtil.format(creditGiftForm.redeemedAmount, MoneyUtil.getCurrency(credit.country))}
                                <small>{MoneyUtil.getCurrency(credit.country)}</small>
                            </span>
                        </div>
                    </div>
                    }
                    <div className="row">
                        <div className="col font-weight-bold">
                            Validity
                        </div>
                        <div className="col d-flex p-justify-end">
                            {this.props.editable ?
                            <DateInput style={{width: 120}}
                                       disablePast
                                       value={this.state.validityDate}
                                       onChange={validityDate => {
                                           this.setState({validityDate});
                                           this.props.onEdit(this.state.giftedAmount, validityDate);
                                       }}/>
                           :
                            <span className={this.state.isExpired ? "disc-danger" : null}>
                                {DateUtil.formatDateMMM(credit.validityDate)}
                            </span>
                            }
                            {this.state.isExpired &&
                            <Tooltip title="Expired">
                                <FontAwesomeIcon icon={["fas", "exclamation-circle"]}
                                                 style={{color: "#dc3545"}}/>
                            </Tooltip>
                            }
                        </div>
                    </div>
                    <hr/>
                    <div className="row">
                        <div className="col d-flex flex-column text-center font-weight-bold">
                            <span>Unique Code</span>
                            <span className="font-size-larger">{creditGiftForm.giftCode}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}