import "../MedicalReports.css"
import React, {Component} from "react";
import ReportTemplate from "../ReportTemplate";
import PatientUtil from "../../../../../utils/PatientUtil";
import {Checkbox} from "primereact/checkbox";
import DateUtil from "../../../../../utils/DateUtil";
import {DateInput} from "../../../../../components";
import moment from "moment";
import NotificationService from "../../../../../services/notification-service/NotificationService";


const GraySpan = (props) =>
    <span style={{
        fontSize: 12,
        backgroundColor: '#f2f2f2',
        border: '1px solid #d8d8d8'
    }}>
        {props.children}
    </span>;

export default class MedicalExcuseTemplate extends Component {

    constructor(props) {
        super(props);
        const currentVisit = this.props.emr.visits[this.props.currentVisitIndex];
        this.state = {
            excuseStartDate: moment(currentVisit.appointment.startTime).toDate(),
            excuseEndDate: moment(currentVisit.appointment.startTime).toDate(),
            visit: currentVisit
        }
    }

    onStartDateChanged = (value) => {
        if(!DateUtil.isSameOrBefore(value, this.state.excuseEndDate)){
            this.showDateWarning();
            return;
        }
        this.setState({excuseStartDate: value});
    }

    onEndDateChanged = (value) => {
        if(!DateUtil.isSameOrBefore(this.state.excuseStartDate, value)){
            this.showDateWarning();
            return;
        }
        this.setState({excuseEndDate: value});
    }

    showDateWarning = () => {
        NotificationService.showNotification({
            severity: 'warn',
            summary: 'Date Problem',
            detail: 'The start time cannot be later than the end time.'
        });
    }

    onVisitChanged = (visit) => {
        this.setState({
            excuseStartDate: moment(visit.appointment.startTime).toDate(),
            excuseEndDate: moment(visit.appointment.startTime).toDate(),
            visit: visit
        })
    }

    render() {
        const patientName = PatientUtil.getPatientsName(this.props.emr.patient);
        const visit = this.state.visit;
        const excuseDuration = DateUtil.diff(this.state.excuseStartDate, this.state.excuseEndDate, 'days') + 1;

        return (
            <ReportTemplate {...this.props} generateUrl={'api/medical-report/generate/medical-excuse'}
                            onVisitSelected={(visit) => {
                                this.onVisitChanged(visit)
                            }}
                            extraParams={() => {
                                return {
                                    excuseStartDate: this.state.hasExcuse ? this.state.excuseStartDate : null,
                                    excuseEndDate: this.state.hasExcuse ? this.state.excuseEndDate : null
                                }
                            }}>
                <div>
                    <br/>
                    This letter is to clarify that <b>{patientName}</b>, has had an appointment at our clinic
                    on <GraySpan>{DateUtil.getTimeStringAtClinicZone(visit.appointment.startTime, visit.clinic.name, 'Do MMMM YYYY, hh:mma')}</GraySpan>.
                    <br/>
                    I have examined <b>{patientName}</b> and recommend that he/she
                    <br/>
                    <br/>
                    <Checkbox checked={this.state.hasExcuse} disabled={this.props.readOnly}
                              onChange={e => this.setState({hasExcuse: e.checked, noExcuse: !e.checked})}/>
                    &nbsp;be excused from any form of strenuous physical activity from
                    <DateInput value={this.state.excuseStartDate} disabled={this.props.readOnly}
                               style={{backgroundColor: '#f2f2f2', margin: '-8px 5px 0px 5px'}}
                               minDate={visit.appointment.startTime}
                               onChange={(value) => this.onStartDateChanged(value)}/>
                    through
                    <DateInput value={this.state.excuseEndDate} disabled={this.props.readOnly}
                               style={{backgroundColor: '#f2f2f2', margin: '-8px 5px 0px 5px'}}
                               minDate={visit.appointment.startTime}
                               onChange={(value) => this.onEndDateChanged(value)}/> , a period
                    of <GraySpan>{excuseDuration || ''}</GraySpan> day{excuseDuration === 1 ? '' : 's'}.
                    <br/>
                    <Checkbox checked={this.state.noExcuse} disabled={this.props.readOnly}
                              onChange={e => this.setState({noExcuse: e.checked, hasExcuse: !e.checked})}/>
                    &nbsp;may return to work/school without any limitations.
                </div>
            </ReportTemplate>
        );
    }

}
