import React, { Component } from "react";
import _uniqueId from "lodash/uniqueId";
import {Badge as BootstrapBadge} from 'react-bootstrap';

export default class Badge extends Component {
    constructor(props){
        super(props);
        if(props.id){
            this.id = props.id;
        }
        else{
            this.id = _uniqueId("badge-id-");
        }
    }

    render() {

        let componentClassName = "dim14badge"
         if(this.props.className){
            componentClassName += " " + this.props.className;
         }

        return (
            <BootstrapBadge
                id={this.id}
                pill={this.props.pill}
                className={componentClassName}
                variant={this.props.variant}
                title={this.props.title}
                hidden={this.props.hidden}
                style={this.props.style}
            >
                {this.props.children}
            </BootstrapBadge>
        )
    }
}
