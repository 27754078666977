import React, {Component} from "react";
import '../../EMR.css';
import NeurologicGaitEnum from "../enums/neurologicGait/NeurologicGaitEnum";

export default class NeurologicGaitView extends Component {

    render() {
        if (this.props.neurologicGait == null || this.props.neurologicGait.items == null || this.props.neurologicGait.items.length == 0) {
            if (!this.props.neurologicGait?.details)
                return null;
        }

        return (
            <div className={"row content-card"} style={{marginTop: "20px"}}>
                <div>
                    {this.props.neurologicGait.items == null || this.props.neurologicGait.items.length == 0 ?
                        <div style={{display: "inline-block"}}>
                            <div style={{display: "inline-block", fontWeight: "bold"}}>
                                Details :
                            </div>
                            <div style={{display: "inline-block", marginLeft: "10px"}}>
                                {this.props.neurologicGait.details}
                            </div>
                        </div> :
                        this.props.neurologicGait.items.map((item, index) => {
                                if (index == this.props.neurologicGait.items.length - 1) {
                                    return (<div style={{display: "inline-block"}}>
                                        <div style={{display: "inline-block", fontWeight: "bold"}}>
                                            {NeurologicGaitEnum[item].name} :
                                        </div>
                                        <div style={{display: "inline-block", marginLeft: "10px"}}>
                                            {this.props.neurologicGait.details}

                                        </div>
                                    </div>);
                                }
                                return (
                                    <div style={{display: "inline-block", fontWeight: "bold"}}>
                                        {NeurologicGaitEnum[item].name}: <div
                                        style={{display: "inline-block", marginLeft: "5px", marginRight: "5px"}}>|</div>
                                    </div>
                                );
                            }
                        )}
                </div>
            </div>
        );
    }
}