import React, {Component} from "react";
import {CheckboxInput, Modal, SelectInput, TextareaInput, TextInput} from "../../components";
import RemotingService from "../../services/remoting-service/RemotingService";
import EnumRestrictionReason from "./enums/EnumRestrictionReason";
import EnumRestrictionType from "./enums/EnumRestrictionType";
import {connect} from "react-redux";
import DateUtil from "../../utils/DateUtil";
import moment from "moment";
import {FormField} from "../../components/form";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import NotificationService from "../../services/notification-service/NotificationService";

class AddRestriction extends Component {

    constructor(props) {
        super(props);
        this.state = {
            departments: [],
            staffs: []
        };
    }

    componentDidMount() {
        RemotingService.getRemoteCall('api/clinic/department/list-department-contains-provider', null, (departments) =>{
            departments = departments.filter(d => d.name != 'Reception' && d.name != 'Management');
            this.setState({departments: departments});
        });

        RemotingService.getRemoteCall('/api/staff/list', null, (queryResult) => {
            this.setState({staffs: queryResult.items});
        });
    }

    render() {
        return <Modal visible={true}
                      header="Restrictions"
                      submitContent="Add"
                      submitAction={this.handleAddRestriction}
                      closeAction={this.props.close}>
            {this.addRestrictionModalBody()}
        </Modal>;
    }

    addRestrictionModalBody = () => {
        this.formFields = [];
        return (
            <>
                <div className="container padding-10" style={{width: '600px'}}>
                    <div className="row mt-1">
                        <div className="col-4 border">
                            <div>Reported By</div>
                        </div>
                        <div className="col-8 border">
                            {this.props.user.name}
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-4 border">
                            <div>Reason</div>
                        </div>
                        <div className="col-8 border">
                            <FormField ref={formField => this.formFields.push(formField)}
                                       required
                                       validateOn={this.state.reason}>
                                <SelectInput displayProperty="name" valueProperty="key" searchEnabled={false}
                                             value={this.state.reason}
                                             options={EnumRestrictionReason.toArray()}
                                             onChange={(reason) => this.setState({reason})} />
                            </FormField>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-4 border">Restricted From</div>
                        <div className="col-8 border">
                            <div style={{display: "flex", flexFlow: "column", justifyContent: "space-between"}}>
                                <FormField ref={formField => this.formFields.push(formField)}
                                           required
                                           validateOn={this.state.type}>
                                    <SelectInput className="w-100" searchEnabled={false}
                                                 displayProperty="name"
                                                 valueProperty="key"
                                                 value={this.state.type}
                                                 options={EnumRestrictionType.toArray()}
                                                 onChange={(type) => this.setState({
                                                     type: type,
                                                     staffId: null,
                                                     departmentId: null
                                                 })} />
                                </FormField>
                                {
                                    this.state.type == "STAFF" &&
                                    <FormField ref={formField => this.formFields.push(formField)}
                                               required
                                               validateOn={this.state.staffId}>
                                        <SelectInput className="w-100"
                                                     displayProperty="name"
                                                     valueProperty="id"
                                                     value={this.state.staffId}
                                                     options={this.state.staffs}
                                                     placeholder="Select Provider"
                                                     onChange={(value => {
                                                         this.setState({staffId: value})
                                                     })}/>
                                    </FormField>
                                }
                                {
                                    this.state.type == "DEPARTMENT" &&
                                    <FormField ref={formField => this.formFields.push(formField)}
                                               required
                                               validateOn={this.state.departmentId}>
                                        <SelectInput className="w-100"
                                                     displayProperty="combinedName"
                                                     valueProperty="id"
                                                     value={this.state.departmentId}
                                                     options={this.state.departments}
                                                     placeholder="Select Department"
                                                     onChange={(value => {
                                                         this.setState({departmentId: value})
                                                     })}/>
                                    </FormField>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-4 border">Expiry</div>
                        <div className="col-8 border">
                            <div style={{display: "flex", justifyContent: "space-between"}}>
                                {
                                    this.state.period != -1 ?
                                    <div style={{display: "flex"}}>
                                        <FormField ref={formField => this.formFields.push(formField)}
                                                   required min={1}
                                                   validateOn={this.state.period}>
                                            <TextInput style={{width: '30px'}}
                                                       value={this.state.period}
                                                       onChange={(period) => this.setState({period})}/>
                                        </FormField>
                                        <div style={{paddingTop: '7px'}}>&nbsp;days</div>
                                    </div> : null
                                }
                                <div style={{paddingTop: '7px'}}><b>{this.getExpiryDate()}</b></div>
                                <div style={{paddingTop: '7px'}}>
                                    <CheckboxInput value={this.state.period == -1}
                                                   onChange={(value) => this.setState({
                                                       period: value ? -1 : 1
                                                   })}/>
                                    &nbsp;Never
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-1">
                        <div className="col-4 border">Details</div>
                        <div className="col-8 border">
                            <TextareaInput className="w-100"
                                           value={this.state.details}
                                           maxLength={4000}
                                           onChange={(details)=>this.setState({details})}/>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    getExpiryDate = () => {
        return this.state.period == -1 ? 'Never' :
            DateUtil.formatDateMMM(moment().add(this.state.period, 'day'));
    }

    handleAddRestriction = () => {
        if (!ValidationUtil.isValid(this.formFields)) {
            NotificationService.showNotification({ severity: 'error', summary: 'Validation Error', detail: 'There are missing fields.' });
            return;
        }

        let restrictionDto = {
            reason: this.state.reason,
            type: this.state.type,
            staffId: this.state.staffId,
            departmentId: this.state.departmentId,
            expiryDate: this.state.period == -1 ? null : moment().add(this.state.period, 'day'),
            details: this.state.details
        };
        RemotingService.postRemoteCall('api/restriction/' + this.props.patientId + '/restriction', restrictionDto, (result) => {
            this.props.onRestrictionAdded(result);
        });
    }

}

const mapStateToProps = state => {
    return {
        user: state.authentication.user
    };
};

export default connect(mapStateToProps)(AddRestriction);