import DateUtil from "../../utils/DateUtil";
import moment from "moment";
import TimezoneUtil from "../../utils/TimezoneUtil";
import {isEmpty} from "../../utils/ArrayUtil";

class CalendarEventService {

    createEvents(rooms, staff, schedules, manualRotations, periodStart, periodEnd) {
        let events = [];
        let roomMapByDate = {};

        for (let date = moment(periodStart); date.diff(periodEnd, 'days') <= 0; date.add(1, 'days')) {
            const rotation = this.findRotation(staff, date, schedules, manualRotations)

            if (!rotation) {
                continue;
            }

            const timezone = TimezoneUtil.getTimeZoneForCountry(rotation.country);
            const tzDate = moment.tz(timezone)
                .year(date.year()).month(date.month()).date(date.date());

            const DUMMY_ROOM_ID = -1;
            let daysWithDummyRooms = new Set(rotation.blocks.filter(b => b.roomId === DUMMY_ROOM_ID).map(b => b.dayOffset));

            rotation.blocks.forEach(block => {
                // block offsets sunday to monday(1-7), js date.day() sunday to monday (0-6)
                if (!daysWithDummyRooms.has(block.dayOffset) && this.toDayOffset(date) === block.dayOffset) {
                    const event = this.createEvent(rooms, tzDate, block, staff, timezone);
                    events.push(event);

                    const dateString = DateUtil.formatDate(tzDate, "YYYYMMDD");
                    if (!roomMapByDate[dateString]) {
                        roomMapByDate[dateString] = event.room;
                    }
                }
            });
        }

        return {events, roomMapByDate};
    }

    findRotation(staff, date, schedules, manualRotations) {
        let rotation = manualRotations.find(r => DateUtil.isSameDay(DateUtil.formatDate(date, "YYYYMMDD") , DateUtil.formatDate(r.date, "YYYYMMDD")));

        if (rotation) {
            return rotation;
        }

        const schedulesAtDate = schedules.filter(schedule => DateUtil.isInRange(date, moment(schedule.startDate), moment(schedule.endDate)));
        if (isEmpty(schedulesAtDate)) {
            return null;
        }

        let latestScheduleAtDate = schedulesAtDate[0];
        for (let s of schedulesAtDate) {
            if (moment(s.creationDate).isAfter(moment(latestScheduleAtDate.creationDate))) {
                latestScheduleAtDate = s;
                break;
            }
        }
        ;

        const rotationAtDate = latestScheduleAtDate.rotations
            .filter(r => r.staffId === staff.id)
            .find(r => DateUtil.isInRange(date, moment(r.startDate), moment(r.endDate)) && r.periods.some(p => DateUtil.isInRange(date, moment(p.startDate), moment(p.endDate))));

        return rotationAtDate;
    }

    createEventsInPeriod(rooms, rotation, periodStart, periodEnd, staff) {
        let events = [];
        const roomMapByDate = {};
        for (let cursor = moment(periodStart); cursor.diff(periodEnd, 'days') < 0; cursor.add(1, 'days')) {
            const timezone = TimezoneUtil.getTimeZoneForCountry(rotation.country);
            const cursorDate = moment.tz(timezone)
                .year(cursor.year()).month(cursor.month()).date(cursor.date());

            rotation.blocks.forEach(block => {
                // block offsets sunday to monday(1-7), js date.day() sunday to monday (0-6)
                if (this.toDayOffset(cursor) === block.dayOffset) {
                    const event = this.createEvent(rooms, cursorDate, block, staff, timezone);
                    events.push(event);

                    const dateString = DateUtil.formatDate(cursorDate, "YYYYMMDD");
                    if (!roomMapByDate[dateString]) {
                        roomMapByDate[dateString] = event.room;
                    }
                }
            });
        }
        return {events, roomMapByDate};
    }

    createEvent(rooms, date, block, staff, timezone) {
        const startMoment = date.clone()
            .hour(block.startTime.split(':')[0])
            .minute(block.startTime.split(':')[1])
            .second(0)
            .millisecond(0);

        const endMoment = date.clone()
            .hour(block.endTime.split(':')[0])
            .minute(block.endTime.split(':')[1])
            .second(0)
            .millisecond(0);

        return {
            startMoment, endMoment,
            start: startMoment.toDate(),
            end: endMoment.toDate(),
            staff,
            staffId: staff.id,
            room: block.roomId ? rooms.find(r => r.id == block.roomId) : undefined,
            type: block.type,
            resizable: false,
            isMerged: block.isMerged,
            nativeTimezone: timezone,
            scheduleId: block.scheduleId
        };
    }

    toDayOffset(date) {
        return moment(date).day() + 1;
    }

    getHostBlock(events, newStart) {
        const sortedEvents = events.filter(e => e.startMoment.isSame(newStart, 'day'))
            .sort((e1, e2) => e1.start - e2.start);

        if (!sortedEvents || sortedEvents.length === 0) {
            return undefined;
        }

        const firstEvent = sortedEvents[0];
        const lastEvent = sortedEvents[sortedEvents.length - 1];

        let block;
        if (firstEvent.startMoment.isAfter(newStart)) {
            block = firstEvent;
        } else if (lastEvent.endMoment.isSameOrBefore(newStart)) {
            block = lastEvent;
        } else {
            block = sortedEvents.filter(e => e.startMoment.isSameOrBefore(newStart) && e.endMoment.isAfter(newStart))[0];
        }

        return block;
    }

    getHostBlockForSameStaffAndDay(events, selectedEvent, newStart) {
        const filteredEvents = events.filter(
            e => selectedEvent.staff.id === e.staff.id && selectedEvent.startMoment.isSame(e.start, "day"));

        return this.getHostBlock(filteredEvents, newStart);
    }

    getHostBlockForStaff(events, newStart, staffId) {
        const filteredEvents = events.filter(e => e.staff.id === staffId);

        return this.getHostBlock(filteredEvents, newStart);
    }

    getCovidGapDuration(event, parameterMap) {
        if (event.room && parameterMap) {
            if (event.room.clinicName === "Kuwait") {
                return parameterMap.KUWAIT_COVID_GAP_DURATION;
            } else if (event.room.clinicName === "DHCC" || event.room.clinicName === "JLT") {
                return parameterMap.DUBAI_COVID_GAP_DURATION;
            }
        } else {
            return 0;
        }
    }

    eventTypeRequiresRoom(type) {
        return type === "NEW_PATIENT" || type === "FOLLOW_UP";
    }

}

export default new CalendarEventService();
