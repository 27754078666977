import React, {Component} from "react";
import {Dropdown} from "primereact/dropdown";
import "./UpdateGeneralStaffPreferences.css";
import EnumRotation from "../../enums/EnumRotation";
import EnumConsecutiveDaysOrSplitWeek from "../../enums/EnumConsecutiveDaysOrSplitWeek";
import {MultiSelect} from "primereact/multiselect";
import {FormField} from "../../../../components/form";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import {extract} from "../../../../utils/ArrayUtil";

class UpdateGeneralStaffPreferences extends Component {

    constructor(props) {
        super(props);
        this.removeInvalidPreferences(props.staffPreference);
        this.state = {
            staffPreference: props.staffPreference
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.staffPreference != this.props.staffPreference) {
            this.removeInvalidPreferences(this.props.staffPreference);
            this.setState({
                staffPreference: this.props.staffPreference,
                synchronizeWithStaff: this.props.staffPreference.synchronizeWith,
                autoCompleted: this.props.staffPreference.synchronizeWith ? true : false
            });
        }
    }

    removeInvalidPreferences(staffPreference) {
        if (staffPreference && !staffPreference.allowedNoOfDaysPerWeeks?.includes(staffPreference.noOfDaysPerWeek)) {
            staffPreference.noOfDaysPerWeek = null;
        }
        if (staffPreference && !staffPreference.allowedWorkingHoursPerDays?.includes(staffPreference.workingHoursPerDay)) {
            staffPreference.workingHoursPerDay = null;
        }
        if (staffPreference?.preferredRoomIds?.length > 0) {
            staffPreference.preferredRoomIds = staffPreference.preferredRoomIds.filter(roomId => staffPreference.allowedRooms?.some(room => room.id == roomId));
        }
    }

    handleFieldChange = (field, value) => {
        this.state.staffPreference[field] = value;
        this.setState({staffPreference: this.state.staffPreference}, () => {
            this.props.onChange(this.state.staffPreference);
        });
    }

    validate() {
        return ValidationUtil.isValid(this.formFields);
    }

    clear() {
        ValidationUtil.clear(this.formFields);
    }

    render() {
        this.formFields = [];
        return <>
            <form>
                <div className={"form-group row"}>
                    <label className={"col-form-label col-sm-3 font-weight-bold"}>
                        Number of Days Per Week
                    </label>
                    <div className={"col-4"}>
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required
                                   validateOn={this.state.staffPreference.noOfDaysPerWeek}>
                            <Dropdown options={this.state.staffPreference.allowedNoOfDaysPerWeeks ?
                                this.state.staffPreference.allowedNoOfDaysPerWeeks.sort() : []}
                                      value={this.state.staffPreference.noOfDaysPerWeek}
                                      onChange={event => this.handleFieldChange("noOfDaysPerWeek", event.value)}/>
                        </FormField>
                    </div>
                </div>
                <div className="form-group row">
                    <label className={"col-form-label col-sm-3 font-weight-bold"}>
                        Working Hours Per Day
                    </label>
                    <div className="col-4">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required
                                   validateOn={this.state.staffPreference.workingHoursPerDay}>
                            <Dropdown options={this.state.staffPreference.allowedWorkingHoursPerDays}
                                      value={this.state.staffPreference.workingHoursPerDay}
                                      onChange={event => this.handleFieldChange("workingHoursPerDay", event.value)}/>
                        </FormField>
                    </div>
                </div>
                <div className="form-group row">
                    <label className={"col-form-label col-sm-3 font-weight-bold"}>
                        Consecutive Days / Split Week
                    </label>
                    <div className="col-4">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required
                                   validateOn={this.state.staffPreference.consecutiveOrSplit}>
                            <Dropdown options={EnumConsecutiveDaysOrSplitWeek.toArray()}
                                      optionValue="key"
                                      optionLabel="name"
                                      value={this.state.staffPreference.consecutiveOrSplit}
                                      onChange={event => this.handleFieldChange("consecutiveOrSplit", event.value)}/>
                        </FormField>
                    </div>
                </div>
                <div className="form-group row">
                    <label className={"col-form-label col-sm-3 font-weight-bold"}>
                        Preferred Rooms
                    </label>
                    <div className="col-4">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required
                                   validateOn={this.state.staffPreference.preferredRoomIds}>
                            <MultiSelect filter={true} filterPlaceholder="Search" placeholder="Choose"
                                         options={this.state.staffPreference.allowedRooms}
                                         optionValue="id"
                                         optionLabel="info"
                                         value={this.state.staffPreference.preferredRoomIds}
                                         onChange={event => this.handleFieldChange("preferredRoomIds", event.value)}/>
                        </FormField>
                    </div>
                </div>
                <div className="form-group row">
                    <label className={"col-form-label col-sm-3 font-weight-bold"}>
                        Rotation
                    </label>
                    <div className="col-4">
                        {EnumRotation[this.state.staffPreference.rotationPreference]}
                    </div>
                </div>
            </form>

            {
                this.state.staffPreference.rotationPreference === "IN_ROTATION" ?
                    <div className="form-group row">
                        <label className={"col-form-label col-sm-3 font-weight-bold"}>
                            Rotation With
                        </label>
                        <div className="col-4">
                            {extract(this.state.staffPreference.rotationWith, "name").join(", ")}
                        </div>
                    </div> : null
            }
            {
                this.state.staffPreference.synchronizeWith ?
                    <div className="form-group row">
                        <label className={"col-form-label col-sm-3 font-weight-bold"}>
                            Synchronize With
                        </label>
                        <div className="col-4">
                            {this.state.staffPreference.synchronizeWith.name}
                        </div>
                    </div> : null
            }
        </>
    }

}

export default UpdateGeneralStaffPreferences;
