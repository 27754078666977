import React, {Component} from "react";
import '../../../EMR.css';
import DateUtil from "../../../../../../utils/DateUtil";
import ScheduledForEnum from "../../../treatment/enums/treatmentschedule/ScheduledForEnum";
import NextAppointmentInEnum from "../../../treatment/enums/treatmentschedule/NextAppointmentInEnum";

export default class TreatmentScheduleHistoryView extends Component {

    render() {
        const schedule = this.props.treatmentSchedule;

        if (!schedule) {
            return <div className="pl-3">No information available</div>;
        }

        return (
            <div className="pl-3">
                {schedule.numberOfRecommendedSessions &&
                <div>
                    <b>The patient is recommended for: </b>
                    {schedule.numberOfRecommendedSessions} Sessions
                </div>}

                {schedule.numberOfScheduledSessions && <>
                    <div>
                        <b>The patient is scheduled for: </b>
                        {schedule.numberOfScheduledSessions} Sessions  {schedule.scheduledSessionsFrequency && <> /{ScheduledForEnum[schedule.scheduledSessionsFrequency].name} </>}
                    </div>
                </>}

                {schedule.nextAppointmentIsIn && <>
                    <div>
                        <b>Next appointment is in: </b>
                        {schedule.nextAppointmentIsIn}  {schedule.nextAppointmentFrequency && <> /{NextAppointmentInEnum[schedule.nextAppointmentFrequency].name} </>}
                    </div>
                </>}

                {(schedule.numberOfReassessedSessions || schedule.reassessedSessionsDate) &&
                    <div>
                        <b>The patient will be reassessed in: </b>
                        {schedule.numberOfReassessedSessions && (<>After {this.props.treatmentSchedule.numberOfReassessedSessions} sessions </>)}
                        {DateUtil.formatDateDoMMYY(this.props.treatmentSchedule.reassessedSessionsDate)}
                    </div>}

            </div>
        );
    }
}
