import GreenSwitchWithMessage from "../../../../../components/switch/GreenSwitchWithMessage";
import React from "react";
import PosNegRadioInput from "../../../../../components/radio-input/PosNegRadioInput";

let DermatomesViewTable = (source, firstTableColumnName, nameEnum, dropdownEnum, color, leftDisabled, customRightColumnName = "Right") => {
    let leftDisabledRowColSpan = leftDisabled ? {colSpan: 2} : {};
    return (
        source && source.length > 0 &&
        (<>
            <thead>
            <tr style={{background: color}}>
                <th style={{width: '15vw'}}>{firstTableColumnName}</th>
                <th style={{width: '80px'}} {...leftDisabledRowColSpan}>{customRightColumnName}</th>
                {!leftDisabled && <th style={{width: '80px'}}>Left</th>}
                <th style={{width: '80px'}}>Details</th>
            </tr>
            </thead>
            <tbody>
            {
                source.map((rowValue, index) => {
                    let posNegRadioInputStyle = {position: "relative"};
                    return (
                        <tr key={`${nameEnum[rowValue.name].key}${index}`}>
                            <th>{nameEnum[rowValue.name].value}</th>
                            <th {...leftDisabledRowColSpan} style={{...posNegRadioInputStyle}}>
                                {rowValue.rightBoolValue != null ? <PosNegRadioInput value={rowValue.rightBoolValue}
                                                                                      readOnly={true}/> : rowValue.rightValue ? dropdownEnum[rowValue.rightValue].name : null}
                            </th>
                            {!leftDisabled && <th style={{...posNegRadioInputStyle}}>
                                {rowValue.leftBoolValue != null ? <PosNegRadioInput value={rowValue.leftBoolValue}
                                                                                     readOnly={true}/> : rowValue.leftValue ? dropdownEnum[rowValue.leftValue].name : null}
                            </th>}
                            <th>
                                {rowValue.details}
                            </th>
                        </tr>
                    )
                })
            }
            </tbody>
        </>)

    )
        ;
}

let DermatomesViewReflexExtraTable = ((source, color, isHeaderExist, dropdownEnum) => {
    return (
        source && source.items && (<>
            {isHeaderExist && source.items.length !== 0 && <thead>
            <tr style={{background: color}}>
                <th style={{width: '15vw'}}>Reflex</th>
                <th style={{width: '80px'}}>Right</th>
                <th style={{width: '80px'}}>Left</th>
                <th style={{width: '80px'}}>Details</th>
            </tr>
            </thead>}
            <tbody>
            {
                source.items.map((rowValue, index) => {
                    return (
                        <tr key={`${rowValue.name}${index}`}>
                            <th>{rowValue.name}</th>
                            <th>
                                {rowValue.rightValue ? dropdownEnum[rowValue.rightValue].name : null}
                            </th>
                            <th>
                                {rowValue.leftValue ? dropdownEnum[rowValue.leftValue].name : null}
                            </th>
                            <th>
                                {rowValue.details}
                            </th>
                        </tr>
                    )
                })
            }
            </tbody>
        </>)
    )
})

let DermatomesViewTableHeader = (tableName, checked = true, divStyle = {}, message) => {
    return (
        <div style={{display: "flex", ...divStyle}}>
            <div className="content-label" style={{margin: "auto", marginLeft: "0px", marginTop: 8}}>{tableName}</div>
            <GreenSwitchWithMessage
                message={message}
                checked={checked}
                onChange={() => {}}/>
        </div>
    )
}

let checkIfHaveValidItems = (source) => {
    let isValid = false;
    if (source === null || source === undefined) {
        return isValid;
    } else {
        for (let property in source) {
            isValid = isValid || (source[property] !== null && source[property] !== undefined && source[property].items !== null)
        }
    }
    return isValid
}

export default {DermatomesViewTable, DermatomesViewTableHeader, DermatomesViewReflexExtraTable, checkIfHaveValidItems};