import React, {Component} from 'react';
import {Image} from "react-bootstrap";

class ReportStamp extends Component {
    render() {
        let {stamp} = this.props;

        if (stamp == null)
            return <div style={{minHeight: "100px", display: "flex", alignItems: "center"}}>No Stamp Information
                Available</div>;

        let photoSrc = 'data:image/jpeg;base64,' + stamp;

        return (
            <div className={"ml-5 pt2"}>
                <Image rounded src={photoSrc} onmouseover='' style={{cursor: 'pointer',  height: "80px"}}/>
            </div>
        );
    }
}

export default ReportStamp;