import React from "react";
import Reference from "./Reference";
import {Button} from "react-bootstrap";
import {Modal, TextInput} from "../../../../components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import NotificationService from "../../../../services/notification-service/NotificationService";
import ViewReferenceModal from "./ViewReferenceModal";
import EditReferenceModal from "./EditReferenceModal";
import {AutoComplete} from "primereact/autocomplete";
import "./References.css";
import {Dropdown} from "primereact/dropdown";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";

class References extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            addReferenceModalVisible: false,
            packagePanelExpanded: false,
            referenceList: this.props.reference,

            viewedReference: null,
            viewedReferenceModalVisible: false,

            editedReference: null,
            editedReferenceModalVisible: false,

            newName: "",
            newHospital: "",
            //      newSubject: "",

            newReferenceType: null,
            selectedViewId: null,
            isShow: false,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.reference) !== JSON.stringify(this.props.reference)) {
            this.setState({...this.state, referenceList: this.props.reference})
        }
    }

    getLabelFormAccordingToReferenceType(referenceType) {
        switch (referenceType) {
            case "FAMILYFRIENDS":
                return "Family / Friend Name";
            case "DRHOSPITAL":
                return "Doctor Name";
            case "INSTAGRAM":
                return "Account Name";
            case "FACEBOOK":
                return "Account Name";
            case "TWITTER":
                return "Account Name";
            case "OTHER":
                return "Other";
            default:
        }
    }

    renderEditButton() {
        return this.props.editable ? (
            <Button variant={"primary"}
                    size={"sm"}
                    onClick={() => this.setState({addReferenceModalVisible: true})}>
                Add Reference
            </Button>
        ) : null;
    }

    render() {
        return (
            <>
                <div
                    className="card reference-panel"
                    style={{minWidth: "210px"}}
                >
                    <div className="card-header">
                        <div className="card-header-title">
                            <strong style={{verticalAlign: "middle", paddingRight: 5}}>
                                Reference
                            </strong>
                        </div>
                        <div className="card-header-actions">{this.renderEditButton()}</div>
                    </div>
                    <div className="card-body line-wrapper small-font text-left">
                        <div className="row">
                            <div className="col-4">Type</div>
                            <div className="col-5">Name</div>
                            <div className="col-2 text-center">Actions</div>
                        </div>
                        <hr/>
                        {this.state.referenceList && this.state.referenceList.length ? (
                            <Reference reference={this.state.referenceList ? this.state.referenceList[0] : null}
                                       patientId={this.props.patientId}
                                       handleDelete={this.handleDeleteReference}
                                       onClickViewReference={this.onClickViewReference}
                                       onClickEditReference={this.onClickEditReference}
                                       editable={this.props.editable}
                            />
                        ) : (
                            <div className="row">
                                <div className="col-12">No reference info found.</div>
                            </div>
                        )}
                        {this.state.referenceList &&
                        this.state.referenceList.length &&
                        this.state.referenceList.length > 1 ? (
                            <FontAwesomeIcon
                                icon={[
                                    "fa",
                                    `angle-double-${
                                        this.state.packagePanelExpanded ? "up" : "down"
                                    }`,
                                ]}
                                style={{
                                    float: "right",
                                    marginTop: "-20px",
                                    marginRight: "-10px",
                                }}
                                onClick={() =>
                                    this.setState({
                                        packagePanelExpanded: !this.state.packagePanelExpanded,
                                    })
                                }
                            />
                        ) : null}
                    </div>
                    {this.state.referenceList &&
                    this.state.referenceList.length &&
                    this.state.referenceList.length > 1 ? (
                        <div
                            className={`card-body small-font expandable text-left ${
                                this.state.packagePanelExpanded ? "expanded" : ""
                            }`}
                        >
                            {this.state.referenceList.map((reference, index) => {
                                if (index === 0) return null;
                                return (
                                    <Reference
                                        reference={
                                            this.state.referenceList
                                                ? this.state.referenceList[index]
                                                : null
                                        }
                                        patientId={this.props.patientId}
                                        handleDelete={this.handleDeleteReference}
                                        style={{marginBottom: "10px"}}
                                        onClickViewReference={this.onClickViewReference}
                                        onClickEditReference={this.onClickEditReference}
                                        editable={this.props.editable}
                                    />
                                );
                            })}
                        </div>
                    ) : null}
                </div>

                {this.state.viewedReferenceModalVisible ? (
                    <ViewReferenceModal
                        reference={this.state.viewedReference}
                        hideModal={this.hideViewModal}
                        visible={this.state.viewedReferenceModalVisible}
                    />
                ) : null}

                {this.state.editedReferenceModalVisible ? (
                    <EditReferenceModal
                        reference={this.state.editedReference}
                        patientId={this.props.patientId}
                        hideModal={this.hideEditModal}
                        submitAction={this.handleReferenceUpdate}
                        visible={this.state.editedReferenceModalVisible}
                    />
                ) : null}
                {this.renderAddModal()}
            </>
        );
    }

    handleReferenceUpdate = (updatedReference) => {
        const oldReferenceIndex = this.state.referenceList.findIndex(
            (reference) => reference.id === updatedReference.id
        );
        let oldReferenceList = [...this.state.referenceList];
        oldReferenceList.splice(oldReferenceIndex, 1);
        oldReferenceList.unshift(updatedReference);
        this.setState({referenceList: oldReferenceList});
    };

    hideViewModal = () => {
        this.setState({
            viewedReferenceModalVisible: false,
            viewedReference: null,
        });
    };

    onClickViewReference = (reference) => {
        this.setState({
            viewedReferenceModalVisible: true,
            viewedReference: reference,
        });
    };

    hideEditModal = () => {
        this.setState({
            editedReferenceModalVisible: false,
            editedReference: null,
        });
    };

    onClickEditReference = (reference) => {
        this.setState({
            editedReferenceModalVisible: true,
            editedReference: reference,
        });
    };

    renderAddModal = () => {
        return this.state.addReferenceModalVisible ? (
            <Modal
                visible={this.state.addReferenceModalVisible}
                header="Reference"
                submitContent="Add Reference"
                style={{overflowY: 'visible'}}
                paperStyle={{overflowY: 'visible'}}
                contentStyle={{overflowY: 'visible'}}
                submitAction={() => {
                    let dto = {
                        name: this.state.newName === "" ? null : this.state.newName,
                        hospital: this.state.newHospital,
                        //                            subject: this.state.newSubject,
                        referenceType: this.state.newReferenceType,
                    };

                    return this.handleAddReference(dto);
                }}
                submitDisabled={!this.state.newReferenceType}
                closeAction={() =>
                    this.setState({
                        newName: "",
                        newHospital: "",
                        //                        newSubject: "",
                        newReferenceType: null,
                        addReferenceModalVisible: false,
                        autoCompleted: false,
                    })
                }
            >
                {this.addReferenceModalBody()}
            </Modal>
        ) : null;
    };

    handleAddReference = (dto) => {
        if (!ValidationUtil.isValid(this.formFields)) {
            NotificationService.showNotification({
                severity: "error",
                summary: "Validation Error",
                detail: "There are missing fields.",
            });
            return;
        }

        RemotingService.postRemoteCall(
            "api/patient/" + this.props.patientId + "/reference",
            dto,
            (result) => {
                let oldReferenceList = [...this.state.referenceList];
                oldReferenceList.unshift(result);
                this.setState({
                    referenceList: oldReferenceList,
                    addReferenceModalVisible: false,
                    newName: "",
                    newHospital: "",
                    //          newSubject: "",
                    newReferenceType: null,
                    autoCompleted: false,
                });
            },
            (error) => {
                this.setState({addReferenceModalVisible: false});
                NotificationService.showNotification({
                    severity: "error",
                    summary: "Invalid name",
                    detail:
                        this.getLabelFormAccordingToReferenceType(
                            this.state.newReferenceType
                        ) + " field should not be empty.",
                });
            }
        );
    };

    handleDeleteReference = (referenceId) => {
        RemotingService.postRemoteCall(
            "api/patient/" +
            this.props.patientId +
            "/" +
            referenceId +
            "/delete-reference",
            referenceId,
            (result) => {
                let oldReferenceList = [...this.state.referenceList];
                oldReferenceList = oldReferenceList.filter(
                    (reference) => reference.id != referenceId
                );
                this.setState({
                    referenceList: oldReferenceList,
                });
            },
            (error) => {
                NotificationService.showNotification({
                    severity: "error",
                    summary: "Deleting Problem",
                    detail: "Some problem happened when deleting reference.",
                });
            }
        );
    };

    referenceTypeTemplate = (option) => {
        let itemIcon = <div style={{marginLeft: "19px"}}></div>;

        if (option.label === "Twitter") {
            itemIcon = (
                <FontAwesomeIcon
                    icon={["fab", "twitter"]}
                    style={{marginTop: "4px"}}
                    size={"lg"}
                />
            );
        } else if (option.label === "Facebook") {
            itemIcon = (
                <FontAwesomeIcon
                    icon={["fab", "facebook"]}
                    style={{marginTop: "2px"}}
                    size={"lg"}
                />
            );
        } else if (option.label === "Instagram") {
            itemIcon = (
                <FontAwesomeIcon
                    icon={["fab", "instagram-square"]}
                    style={{marginLeft: "2px", marginTop: "2px"}}
                    size={"lg"}
                />
            );
        }

        return (
            <div style={{display: "flex", flexDirection: "row"}}>
                {itemIcon}
                <div style={{marginLeft: "5px"}}>{option.label}</div>
            </div>
        );
    };

    addReferenceModalBody = () => {
        this.formFields = [];
        return (
            <div className={"add-reference-modal dim14-form-row-align"}>
                <div className={"container"} style={{maxWidth: "100%"}}>
                    <div className="row">
                        <div className="col-md-4 dim14-form-label">Reference Type</div>
                        <div className="col-md-8 dim14-base-input">
                            <Dropdown
                                className={"w-100 light-color-bordered"}
                                value={this.state.newReferenceType}
                                options={[
                                    {label: "Family/Friends", value: "FAMILYFRIENDS"},
                                    {label: "Dr/Hospital", value: "DRHOSPITAL"},
                                    {label: "Instagram", value: "INSTAGRAM"},
                                    {label: "Facebook", value: "FACEBOOK"},
                                    {label: "Twitter", value: "TWITTER"},
                                    {label: "Other", value: "OTHER"},
                                ]}
                                itemTemplate={this.referenceTypeTemplate}
                                displayProperty="label"
                                valueProperty="value"
                                onChange={(event) => {
                                    this.setState({
                                        newName: "",
                                        newHospital: "",
                                        //                      newSubject: "",
                                        newReferenceType: event.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    {this.state.newReferenceType ? (
                        <div className="row">
                            <div className="col-md-4 dim14-form-label">
                                {this.getLabelFormAccordingToReferenceType(
                                    this.state.newReferenceType
                                )}
                            </div>
                            <div className="col-md-8 dim14-base-input">
                                {this.state.newReferenceType === "DRHOSPITAL" ? (
                                    <div style={{display: "flex"}}>
                                        <AutoComplete
                                            value={this.state.newName}
                                            placeholder="Enter doctor name"
                                            onChange={(e) => {
                                                this.setState({newName: e.value});
                                            }}
                                            suggestions={this.state.staffNameSuggestions}
                                            itemTemplate={this.staffNameSearchItemTemplate}
                                            completeMethod={this.staffNameCompleteMethod.bind(this)}
                                            onSelect={this.handleStaffSearchResultSelect}
                                            disabled={this.state.autoCompleted}
                                            className="AddReferenceAutoComplete"
                                            style={{minWidth: "100px"}}
                                        />

                                        {this.state.autoCompleted ? (
                                            <FontAwesomeIcon
                                                icon={["fas", "times-circle"]}
                                                size={"2x"}
                                                color={"red"}
                                                style={{marginTop: "5px", marginLeft: "5px"}}
                                                onClick={() => this.deselectStaffSearchResult()}
                                            />
                                        ) : null}
                                    </div>
                                ) : (
                                    <TextInput
                                        value={this.state.newName}
                                        placeholder={
                                            " Enter " +
                                            this.getLabelFormAccordingToReferenceType(
                                                this.state.newReferenceType
                                            )
                                        }
                                        onChange={(value) => this.setState({newName: value})}
                                    />
                                )}
                            </div>
                        </div>
                    ) : null}

                    {this.state.newReferenceType === "DRHOSPITAL" ? (
                        <div className="row">
                            <div className="col-md-4 dim14-form-label">Hospital Name</div>
                            <div className="col-md-8 dim14-base-input">
                                <div style={{display: "flex"}}>
                                    <AutoComplete
                                        value={this.state.newHospital}
                                        placeholder="Enter hospital name"
                                        onChange={(e) => {
                                            this.setState({newHospital: e.value});
                                        }}
                                        disabled={this.state.autoCompleted}
                                        className="AddReferenceAutoComplete"
                                    />

                                    {this.state.autoCompleted ? (
                                        <FontAwesomeIcon
                                            icon={["fas", "times-circle"]}
                                            size={"2x"}
                                            color={"red"}
                                            style={{marginTop: "5px", marginLeft: "5px"}}
                                            onClick={() => this.deselectStaffSearchResult()}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    ) : null}
                    {/*
          {this.state.newReferenceType &&
          this.state.newReferenceType !== "OTHER" ? (
            <div className="row">
              <div className="col-md-4 dim14-form-label">Subject</div>
              <div className="col-md-8 dim14-base-input">
                <TextInput
                  value={this.state.newSubject}
                  onChange={(value) => this.setState({ newSubject: value })}
                />
              </div>
            </div>
          ) : null} */}
                </div>
            </div>
        );
    };

    staffNameSearchItemTemplate = (staff) => {
        return <>{staff.name + (staff.hospital ? " - " + staff.hospital  : "")}</>;
    };

    staffNameCompleteMethod(event) {
        setTimeout(() => {
            let name = event.query;
            let patientId = this.props.patientId;
            RemotingService.getRemoteCall(
                `api/patient/list-references?name=${name}&patientId=${patientId}`,
                null,
                (result) => {
                    this.setState({staffNameSuggestions: result});
                }
            );
        }, 250);
    }

    handleStaffSearchResultSelect = (event) => {
        let staff = event.value;

        this.setState({
            newName: staff.name,
            newHospital: staff.hospital,
            autoCompleted: true,
        });
    };

    deselectStaffSearchResult = () => {
        this.setState({
            newName: "",
            newHospital: "",
            autoCompleted: false,
        });
    };
}

export default References;