import React, {Component} from 'react'
import Logo from "./Logo";
import moment from "moment";
import confirmDialog from "../../../components/dialog/ConfirmDialog";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import RemotingService from "../../../services/remoting-service/RemotingService";
import {TextInput, Wizard} from "../../../components";
import FormField from "../../../components/form/FormField";
import SignatureInput from "../../../components/signature-input/SignatureInput";
import ConsentFormsUtil from "../utils/ConsentFormsUtil";

export default class PublicUltrasoundDiagnosticForm extends Component {
    constructor(props) {
        super(props);

        this.ULTRASOUND_FEE_KWI = "45 KWD";
        this.ULTRASOUND_FEE_DXB = "550 AED";
        this.state = {}
    }

    createHeader = (form) => {
        return <div style={{padding: "0px 20px 0px 20px"}}>
            <div className="row text-left">
                <div className="col-12 no-padding">
                    <Logo/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center no-padding" style={{fontSize: 20, fontWeight: "bold"}}>
                    ULTRASOUND DIAGNOSTIC FORM
                </div>
            </div>
            <div className="row" style={{fontSize: 11}}>
                <div className="col-4 text-left no-padding">
                    <b>Patient Name: </b> {form.patientFullName}
                </div>
                <div className="col-4 text-center no-padding">
                    <b>MRN No: </b> {form.patientMrn}
                </div>

                <div className="col-4 text-right no-padding">
                    <b>D.O.B: </b> {form.patientDOB}
                </div>
            </div>
        </div>
    };

    fieldChanged = (fieldName, value) => {
        this.props.form[fieldName] = value;
        this.forceUpdate();
    };

    close = () => {
        confirmDialog(
            'Confirmation',
            'Are you sure you want to close this form? All input will be lost.',
            this.props.close);
    };

    submitForm = () => {
        if (ValidationUtil.checkWithNotification(this.formFields)) {
            const {consentFormCode: code, form} = this.props;
            RemotingService.postRemoteCall(`api/public/consent-form/code/${code}/ultrasound-diagnostic-form`, form);
            this.props.onFormSubmit(form);
        }
    };

    render() {
        this.formFields = [];
        const {form} = this.props;
        let consultationFee = form.patientCountry === "UAE" ? this.ULTRASOUND_FEE_DXB : this.ULTRASOUND_FEE_KWI;

        return (
            <Wizard
                show={true} fullScreen
                footerStyle={{backgroundColor: '#f5f5f5'}}
                buttonStyle={{backgroundColor: '#84BE56', border: 'none'}}
                header={this.createHeader(form)}
                close={this.close}
                onSubmit={this.submitForm}>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>
                    <div style={{margin: "20px 20px 20px 20px"}}>

                        <div style={{backgroundColor: "white", marginBottom: "20px", padding: "20px"}}>
                            <div style={{margin: "0px 15px"}}>
                                <p><b> Name of proposed investigation: </b></p>
                            </div>
                            <div className={"ultrasound-diagnostic-large-text-field"} style={{margin: "0px 15px"}}>
                                <FormField ref={formField => this.formFields.push(formField)}
                                           required
                                           validateOn={form.nameOfProposedInvestigation}>
                                    <TextInput
                                        value={form.nameOfProposedInvestigation}
                                        style={{width: "100%"}}
                                        maxLength={255}
                                        onChange={(value) => this.fieldChanged('nameOfProposedInvestigation', value)}/>
                                </FormField>
                            </div>
                        </div>

                        <div style={{backgroundColor: "white", marginBottom: "20px", padding: "20px"}}>
                            <div style={{margin: "0px 15px"}}>
                                <p><b> Fee for the proposed investigation: </b></p>
                            </div>
                            <div style={{margin: "0px 15px"}}>
                                <p>Included in this fee is the medical report prepared after the investigation.
                                    This is in addition to the consultation fee of {consultationFee}.</p>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>

                    <div style={{margin: "20px 20px 20px 20px"}}>

                        <div style={{backgroundColor: "white", marginBottom: "20px", padding: "20px"}}>
                            <div style={{margin: "0px 15px"}}>
                                <p><b> Statement of health professional: </b></p>
                            </div>

                            <div style={{margin: "0px 15px"}}>
                                <p>(to be filled in by health professional
                                    with appropriate
                                    knowledge of proposed investigation)</p>
                                <p>I have explained the investigation to the patient. In particular:</p>

                                <p><u>The intended benefits:</u><br/>
                                    Diagnostic image of musculoskeletal structure is used to detail the pathology and
                                    plan the treatment.<br/>
                                    This investigation is generally painless and does not require needles, injections,
                                    or
                                    incisions.<br/>
                                    Patients aren't exposed to ionizing radiation, making the procedure safer than
                                    diagnostic techniques such as X-rays and CT scans.<br/>
                                    The clinician can interact in real-time with the patient who can then direct the
                                    examination toward the symptomatic area<br/></p>

                                <p><u>Known side effects:</u><br/>
                                    None</p>
                            </div>
                        </div>

                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    Provider Name
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    {": " + (form.providerName ? form.providerName : " ")}
                                </div>

                                <div className="col-md-3 dim14-form-label">
                                    Job Title
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    {": " + (form.providerSpeciality ? form.providerSpeciality : " ")}
                                </div>
                            </div>

                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    Date/Time
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    {": " + moment(new Date(form.healthProfessionalDate)).format('MMMM DD YYYY, h:mm:ss a')}
                                </div>

                                <div className="col-md-6">
                                    <div className="col-md-12 dim14-form-label">
                                        Signature of Provider
                                    </div>

                                    <div className="col-md-12">
                                        <FormField
                                            ref={(ref) => this.formFields.push(ref)}
                                            required requiredMessage="Signature required"
                                            validateOn={form.providerSignature}
                                        >
                                            <SignatureInput signature={form.providerSignature}
                                                            onClear={() => {
                                                                form.providerSignature = null;
                                                                this.forceUpdate();
                                                            }}
                                                            onSave={(signature) => {
                                                                form.providerSignature = signature;
                                                                this.forceUpdate();
                                                            }}/>
                                        </FormField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>

                    <div style={{margin: "20px 20px 20px 20px"}}>

                        <div style={{backgroundColor: "white", marginBottom: "20px", padding: "20px"}}>
                            <div style={{margin: "0px 15px"}}>
                                <p><b> Statement of patient: </b></p>
                            </div>

                            <div style={{margin: "0px 15px"}}>
                                <p> Please read this form carefully. If you
                                    have any further questions, do ask. You have the right to change your mind at any
                                    time, including after you have signed this form. </p>

                                <p>
                                    <b>I understand</b> the investigation which has been explained to me.<br/>
                                    <b>I agree</b> to the investigation described in this form.<br/>
                                    <b>I agree</b> that information and/or images kept in my records may be used
                                    anonymously for education, audit and research approved by an ethics committee,
                                    to improve the quality of patient care.<br/>
                                    <b> I understand</b> that the person performing the investigation has the appropriate
                                    experience or is training under direct supervision.<br/>
                                    <b>I agree</b> that I have had a chance to ask appropriate questions.<br/>
                                    <b>I agree</b> with the offered investigation fee.<br/>
                                </p>
                            </div>

                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 dim14-form-label">
                                            Date/Time
                                        </div>
                                        <div className="col-md-6 dim14-base-input">
                                            {": " + moment(new Date(form.patientDate)).format('MMMM DD YYYY, h:mm:ss a')}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 dim14-form-label">
                                            Patient Name
                                        </div>
                                        <div className="col-md-6 dim14-base-input">
                                            {": " + form.patientFullName}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="col-md-12 dim14-form-label">
                                        Signature of Patient
                                    </div>

                                    <div className="col-md-12">
                                        <SignatureInput signature={form.patientSignature}
                                                        onClear={() => {
                                                            form.patientSignature = null;
                                                            this.forceUpdate();
                                                        }}
                                                        onSave={(signature) => {
                                                            form.patientSignature = signature;
                                                            this.forceUpdate();
                                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onPrevious={() => (this.formFields = [])}>

                    <div style={{margin: "20px 20px 20px 20px"}}>
                        <div className={"row"}>
                            <p><b>A witness should sign below if the patient is unable to sign but has indicated
                                his or her consent. <br/>
                                Young people (under {ConsentFormsUtil.getWitnessAgeText()} years of age) and children should also have a Parent
                                or Guardian sign here.</b></p>
                        </div>
                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    Print Name
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    <TextInput value={form.guardianName} alphabeticOnly
                                               onChange={(value) => this.fieldChanged('guardianName', value)}/>
                                </div>

                                <div className="col-md-3 dim14-form-label">
                                    Relationship to Patient
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    <TextInput value={form.guardianRelationship} alphabeticOnly
                                               onChange={(value) => this.fieldChanged('guardianRelationship', value)}/>
                                </div>
                            </div>

                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    Date/Time
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    {": " + moment(new Date(form.guardianDate)).format('MMMM DD YYYY, h:mm:ss a')}
                                </div>

                                <div className="col-md-6">
                                    <div className="col-md-12 dim14-form-label">
                                        Signature of Witness/Parent or Guardian
                                    </div>

                                    <div className="col-md-12">
                                        <FormField
                                            ref={(ref) => this.formFields.push(ref)}
                                            disabled={form.patientSignature != null}
                                            required requiredMessage="Signature required"
                                            validateOn={form.guardianSignature}
                                        >
                                            <SignatureInput signature={form.guardianSignature}
                                                            onClear={() => {
                                                                form.guardianSignature = null;
                                                                this.forceUpdate();
                                                            }}
                                                            onSave={(signature) => {
                                                                form.guardianSignature = signature;
                                                                this.forceUpdate();
                                                            }}/>
                                        </FormField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>
            </Wizard>
        )
    }
}
