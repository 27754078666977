import React, {Component} from "react";
import GreenSwitchWithEmptyMessage from "../../../../components/switch/GreenSwitchWithEmptyMessage";
import ResultView from "../treatment/views/ResultView";
import cloneDeep from "lodash/cloneDeep";
import Modal from "../../../../components/modal/Modal";
import ResultModal from "../treatment/modals/ResultModal";
import AuthService from "../../../../services/auth-service/AuthService";
import noop from "lodash-es/noop";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import DateUtil from "../../../../utils/DateUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import ReduxService from "../../../../services/redux-service/ReduxService";
import CommunicationModeEnum from "./CommunicationModeEnum";
import CommunicationModal from "../treatment/modals/CommunicationModal";
import dialog from "../../../../components/dialog/Dialog";
import AddCommentModal from "../treatment/modals/AddCommentModal";
import ActionMenuItem from "../../../../components/action-menu/ActionMenuItem";
import ActionMenu from "../../../../components/action-menu/ActionMenu";
import confirmDialog from "../../../../components/dialog/ConfirmDialog";
import Tooltip from "../../../../components/tooltip/Tooltip";
import {Divider} from "@material-ui/core";
import EMRDashboardUtil from "../dashboard/utils/EMRDashboardUtil";
import AttachmentUtils from "../treatment/utils/AttachmentUtils";
import ViewIcon from "../../../../components/action-menu/icons/ViewIcon";
import moment from "moment/moment";

export function taskComparatorByNameAsc() {
    return (t1, t2) => t1.name > t2.name ? 1 : -1;
}

export function commentComparatorByDateAsc() {
    return (c1, c2) => moment(c1.logDate).diff(moment(c2.logDate))
}

export default class Result extends Component {

    constructor(props) {
        super(props);

        this.emptyCommunication = {
            communicationMode: 'PHONE_CALL',
            communicationText: '',
            communicationDate: new Date(),
            files: null,
            comments: ''
        }
    }

    state = {
        index: this.props.currentVisitIndex,
        resultModalVisible: false,
        communicationModalVisible: false,
        communication: this.emptyCommunication,
        viewerVisible: false,
        viewerHeader: null,
        blobUrl: null,
        addCommentModalVisible: false,
        selectedItem: null
    }

    openResultModal = () => {
        this.copiedResult = cloneDeep(this.props.treatment.results);
        if (this.props.treatment.results == null) {
            this.props.treatment.results = [];
        }
        this.setState({resultModalVisible: true});
    }

    openCommunicationModal = () => {
        this.copiedCommunication = cloneDeep(this.props.treatment.communications);
        if (this.props.treatment.communications == null) {
            this.props.treatment.communications = [];
        }
        this.setState({communicationModalVisible: true});
    }

    saveResultModal = () => {
        this.props.onModalSave();
        this.setState({
            resultModalVisible: false,
            index: this.props.currentVisitIndex
        });
    }

    saveCommunicationModal = (newCommunication) => {
        let attachedCommunication = this.props.treatment.communications.filter(comm => comm.id === newCommunication.id);

        let updatedCommunicationList;
        if (attachedCommunication.length > 0) {
            updatedCommunicationList = this.props.treatment.communications.filter(comm => comm.id !== newCommunication.id);
            this.props.treatment.communications = [newCommunication, ...updatedCommunicationList]
        } else {
            updatedCommunicationList = [newCommunication, ...this.props.treatment.communications]
            this.props.treatment.communications = updatedCommunicationList;
        }
        this.props.onModalSave();
    }

    saveCommentModal = (communication, newComment) => {
        this.props.treatment.communications.map((comm) => {
            if(comm.id === communication.id){
                comm.comments = [newComment, ...comm.comments];
            }
        })

        this.props.onModalSave();
        this.setState({
            addCommentModalVisible: false,
            index: this.props.currentVisitIndex,
            selectedItem: null,
        });
    }

    closeResultModal = () => {
        this.props.treatment.results = this.copiedResult;
        this.setState({resultModalVisible: false});
    }

    renderResultModal = () => {
        const historicResults = EMRDashboardUtil.getHistoricResultsEntity(this.props.visits , this.state.index);

        if (!this.state.resultModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.resultModalVisible}
                   className="ResultModal"
                   submitContent="Save"
                   submitAction={this.saveResultModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeResultModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   fullWidth
                   paperStyle={{overflowY: 'visible'}}
                   contentStyle={{overflowY: 'visible', height: "70%", maxHeight: "95%",}}>
                <ResultModal results={this.props.treatment.results}
                             historicResults={historicResults}
                             visits={this.props.visits}
                             emrId={this.props.emrId}/>
            </Modal>
        );
    }

    renderCommunicationModal = () => {
        if (!this.state.communicationModalVisible) {
            return (<></>);
        }

        dialog({
            title: "Add Addendum",
            onClose: () => {
                this.setState({communicationModalVisible: false, selectedItem: null})
            },
            component: <CommunicationModal staff={AuthService.getUser().name}
                                           communicationId={this.state.selectedItem ? this.state.selectedItem.id : null}
                                           communications={this.props.treatment.communications}
                                           visitMetaData={this.props.visitMetaData}
                                           saveCommunicationModal={this.saveCommunicationModal}/>
        });
    }

    renderAddCommentModal = () => {
        if (!this.state.addCommentModalVisible || this.state.selectedItem == null) {
            return (<></>);
        }

        dialog({
            title: "Make Comment",
            onClose: () => {
                this.setState({addCommentModalVisible: false, selectedItem: null})
            },
            component: <AddCommentModal staffId={AuthService.getStaffId()}
                                        comments={this.state.selectedItem.comments}
                                        saveCommentModal={(activeComment) => this.saveCommentModal(this.state.selectedItem, activeComment)}/>
        });
    }

    renderCloseConfirmModal = () => {
        return (
            <Modal visible={this.state.closeConfirmModalVisible}
                   submitContent="Yes"
                   closeContent="No"
                   closeAction={this.closeCloseConfirmModal}
                   submitAction={this.submitCloseConfirmModal}
                   header='Are you sure? '
                   maxWidth={"md"}
                   contentStyle={{maxHeight: "95%", width: "20vw"}}>
                Unsaved changes may be lost!
            </Modal>
        );
    }

    submitCloseConfirmModal = () => {
        this.state.closeModalFunctionCallback();
        this.setState({closeConfirmModalVisible: false});
    }

    closeCloseConfirmModal = () => {
        this.setState({closeConfirmModalVisible: false});
    }

    openCloseConfirmModal = (functionToBeExecuted) => {
        this.setState({closeConfirmModalVisible: true, closeModalFunctionCallback: functionToBeExecuted});
    }

    addedByTemplate(item) {
        return (
            <table>
                <tbody>
                    <tr>
                        {item.addedBy}
                    </tr>
                    <tr>
                        {DateUtil.formatDateTimeSeparately(item.logDate)}
                    </tr>
                </tbody>
            </table>
        );
    }

    commentTemplate(comments) {
        if (!comments) {
            return (<></>);
        }

        return (
            <table>
                <tbody>
                {comments.sort(commentComparatorByDateAsc()).map((comment, index) => {
                        return(
                            <>
                                <tr>
                                    <span>{DateUtil.formatDateTimeSeparately(comment?.logDate)}</span>
                                </tr>
                                <tr>
                                    <span style={{fontWeight: "bold"}}>{comment?.addedBy}</span><span>: {comment?.comment}</span>
                                </tr>
                                {index < comments.length-1 && <tr>
                                    <Divider/>
                                </tr>}
                            </>
                        );
                    })}
                </tbody>
            </table>
        )
    }

    deleteCommunication(item) {
        confirmDialog("Delete Communication",
            "Do you want to delete the communication?",
            () => this.handleDeleteCommunication(item));
    }

    handleDeleteCommunication(item) {
        let updatedComm = this.props.treatment.communications.filter((comm) => comm.id !== item.id);
        this.props.treatment.communications = updatedComm;

        RemotingService.deleteRemoteCall(`api/emr/communication/delete/${item.id}`, null,
            () => {
                this.setState({
                    selectedItem: null,
                    index: this.props.currentVisitIndex,
                });
            });
    }

    actionsTemplate(item, readOnly) {
        let currentUserIsCreatorOfCommunication = item.addedBy === AuthService.getUser().name;

        return (
            <table>
                <tbody>
                    <tr>
                        <ActionMenu id="resultActionMenu">
                            <ActionMenuItem icon={<FontAwesomeIcon icon={["fas", "trash-alt"]}
                                                                   style={{marginLeft: '5px', color: 'red'}}/>}
                                            disabled={!currentUserIsCreatorOfCommunication} onClick={() => {
                                this.deleteCommunication(item)
                            }} text={"Delete"}/>
                            <ActionMenuItem icon={<FontAwesomeIcon icon={["fas", "edit"]}
                                                                   style={{marginLeft: '5px', color: 'grey'}}/>}
                                            disabled={!currentUserIsCreatorOfCommunication} onClick={() => {
                                                this.setState({communicationModalVisible: true, selectedItem: item});
                            }} text={"Edit"}/>
                            <ActionMenuItem icon={<FontAwesomeIcon icon={["fas", "comment"]}
                                                                   style={{marginLeft: '5px', color: '#7BAC16'}}/>}
                                                                   onClick={() => {
                                                                       this.setState({addCommentModalVisible: true, selectedItem: item})}}
                                            disabled={readOnly}
                                            text={"Make Comment"}/>
                        </ActionMenu>
                    </tr>
                </tbody>
            </table>
        );
    }

    setTaskStatus(communication, task, status) {
        this.props.treatment.communications.map((comm) => {
            if(comm.id === communication.id){
                comm.tasks.map((t) => {
                    if(t.name === task.name){
                        t.status = status;
                    }
                })
            }
        })

        this.props.onModalSave();
        this.setState({
            selectedItem: null,
            index: this.props.currentVisitIndex,
        });
    }

    taskListTemplate(item, readOnly) {
        return (
            <table>
                <tbody>
                {item.tasks.sort(taskComparatorByNameAsc()).map((t, index) => {
                    return (
                        <>
                            <tr>
                                <td style={{width: "240px"}}>
                                    {t.name}
                                </td>
                                <th>
                                    <Tooltip title="Alert">
                                        <FontAwesomeIcon icon={["fas", "flag"]} className={t.status === 'ALERT' ? "alertTask" : "pendingTask"}
                                                         onClick={() => {
                                                             if(!readOnly){
                                                                 let status = t.status !== 'ALERT' ? 'ALERT' : 'PENDING';
                                                                 this.setTaskStatus(item, t, status)
                                                             }}}/>
                                    </Tooltip>
                                </th>
                                <th>
                                    <Tooltip title="Mark as Done">
                                        <FontAwesomeIcon icon={["fas", "check-circle"]} className={t.status === 'DONE' ? "doneTask" : "pendingTask"}
                                                         onClick={() => {
                                                             if(!readOnly){
                                                                 let status = t.status !== 'DONE' ? 'DONE' : 'PENDING';
                                                                 this.setTaskStatus(item, t, status)
                                                             }}}/>
                                    </Tooltip>
                                </th>
                            </tr>
                            {index < item.tasks.length-1 && <tr>
                                <Divider/>
                            </tr>}
                        </>
                    )
                })}

                </tbody>
            </table>
        );
    }

    dateTemplate(date) {
        return date ? DateUtil.formatDateTimeSeparately(date) : "";
    }

    attachment = () => {
        return (
            <label className={"attachment-label"}>
                <FontAwesomeIcon icon={["fas", "paperclip"]} style={{marginLeft: '5px'}}/>
                <input type="file"
                       id="upload-attachment"
                       accept=".jpg, .jpeg, .png, .pdf, .xls, .xlsx, .doc, .docx, .txt"
                       onChange={(e) => {
                           if (e == null
                               || e.target == null
                               || e.target.files == null
                               || e.target.files[0] == null) {
                               return;
                           }
                           let fileName = e.target.files[0].name;
                           RemotingService.uploadFile(e.target.files[0], (response, err) => {
                               let currentFiles = this.state.communication.files;
                               if (err != null) {
                                   return;
                               }
                               if (currentFiles == null) {
                                   currentFiles = [];
                               }
                               currentFiles.push({name: fileName, uuid: response.data});
                               this.onFieldChange("files", currentFiles)
                               this.forceUpdate();
                           });
                       }}/>
            </label>
        );
    }

    view = (file) => {
        ReduxService.incrementRemotingOperationCount();

        RemotingService.instance.get('api/file/download/' + file.uuid, {responseType: 'blob'})
            .then((response) => {
                let blobUrl = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                this.setState({
                    viewerVisible: true,
                    viewerHeader: file.name,
                    blobUrl: blobUrl
                });
            })
            .finally(() => {
                ReduxService.decrementRemotingOperationCount();
            });
    }

    attachmentTable = (files) => {
        if (files == null || files.length == 0) {
            return null;
        }
        return (
            <table className={"communication-files-list"}>
                <tbody>
                {files.map((f, index) => {
                    return (
                        <tr>
                            <td className={"row"}>
                                <span className={"communication-files-link-column col-10"}
                                      onClick={() => {
                                          this.view(f);
                                      }}>{f.name}</span>
                                <ActionMenu className={"col-2"}>
                                    <ActionMenuItem text="View" icon={<ViewIcon/>} onClick={() => this.view(f)}
                                                    disabled={!AttachmentUtils.canView(f.name)}/>
                                    <ActionMenuItem text="Download"
                                                    icon={<FontAwesomeIcon icon={["fas", "file-download"]}/>}
                                                    onClick={() => AttachmentUtils.download(f)}/>
                                </ActionMenu>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        );
    }

    renderAttachmentModal() {
        return(
            <Modal visible={true}
                   header={this.state.viewerHeader}
                   closeAction={() => this.setState({
                       viewerVisible: false
                   })}>
                <iframe id="documentViewer" src={this.state.blobUrl} width="800px" height="600px"></iframe>
            </Modal>
        );
    }

    communicationModeTemplate = (data) => {
        return CommunicationModeEnum[data]?.name;
    }

    onFieldChange = (fieldName, value) => {
        let updatedCommunication = {...this.state.communication, [fieldName]: value}
        this.setState({communication: updatedCommunication})
    }

    render() {
        const currVisitIndex = this.state.index;
        const visit = this.props.visits[currVisitIndex];
        const accumulatedResults = EMRDashboardUtil.getAccumulatedResultsEntity(this.props.visits);

        const treatment = visit.treatment;
        const accumulatedCommunications =visit.treatment.communications;

        const readOnly = AuthService.isAdminOrManager();

        return(
            <div>
                <div className={"content-row"}>
                    <div className={"content-col-12"}>
                        <div className={"content-wrapper content-clickable"}
                             onClick={!readOnly ? this.openResultModal : noop}>
                            <div className="d-flex justify-content-between">
                                <div className="content-label m-0">Result</div>
                                <GreenSwitchWithEmptyMessage
                                    emptyMessage={""}
                                    checked={treatment.results && treatment.results.length !== 0}/>
                            </div>
                            {accumulatedResults != null
                            && accumulatedResults.length > 0 &&
                            <div className="mt-3">
                                <ResultView results={accumulatedResults}/>
                            </div>
                            }
                        </div>

                    </div>
                </div>
                <div className={"content-row"}>
                    <div className={"content-col-12"}>
                        <div className={"content-wrapper"}>
                            <div className="d-flex justify-content-between">
                                <div className="content-label m-0">Addendum</div>
                                <Button style={{minWidth: '160px'}} variant="success"
                                        onClick={!readOnly ? this.openCommunicationModal : noop}
                                        disabled={readOnly}>
                                    <FontAwesomeIcon icon={["fas", "plus"]}/>
                                    <span className="ml-1">Add Addendum</span>
                                </Button>
                            </div>
                            <div className="mt-3">
                                <DataTable id="communicationsTable" value={accumulatedCommunications} >
                                    <Column field="addedBy" header="Added By" body={(item)=>this.addedByTemplate(item)} style={{width: "200px"}}/>
                                    <Column field="communicationMode" header="Type"
                                            body={(item) => this.communicationModeTemplate(item.communicationMode)}
                                            style={{width: "100px"}}/>
                                    <Column field="communicationDate" header="Date & Time"
                                            body={(item) => this.dateTemplate(item.communicationDate)}
                                            style={{width: "150px"}}/>
                                    <Column field="communicationText" header="Notes" style={{width: "150px"}}/>
                                    <Column field="files" header="Attach" body={(item) => this.attachmentTable(item.files)} style={{width: "300px"}}/>
                                    <Column field="tasks" header="Task List" body={(item) => this.taskListTemplate(item, readOnly)} style={{width: "300px"}}/>
                                    <Column field="comments" header="Comments" body={(item)=>this.commentTemplate(item.comments)} style={{width: "300px"}}/>
                                    <Column header="" body={(item)=>this.actionsTemplate(item, readOnly)} style={{width: "30px"}}/>
                                </DataTable>
                            </div>
                        </div>
                    </div>
                </div>

                {this.renderCloseConfirmModal()}
                {this.renderResultModal()}
                {this.renderCommunicationModal()}
                {this.renderAddCommentModal()}
                {this.state.viewerVisible && this.renderAttachmentModal()}
            </div>

        );
    }
}
