import Enum from "../../../../../../enums/Enum";

const EndFeelEnum = new Enum({
    None: {name: "None"},
    Normal: {name: "Normal"},
    Hard: {name: "Hard"},
    Soft: {name: "Soft"},
    Capsular: {name: "Capsular"},
    Firm: {name: "Firm"},
    Empty: {name: "Empty"},
    Sparingly_Block: {name: "Sparingly Block"},
    Spasm: {name: "Spasm"},
    Painful: {name: "Painful"},
    Dysfunctional_Painful: {name: "Dysfunctional Painful"},
});

export default EndFeelEnum;