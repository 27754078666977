import React, {Component} from "react";
import '../EMR.css';
import RemotingService from "../../../../services/remoting-service/RemotingService";
import CheckboxInput from "../../../../components/checkbox-input/CheckboxInput";
import {NumberInput} from "../../../../components";
import {showWarning} from "../../../../services/notification-service/NotificationService";
import DateUtil from "../../../../utils/DateUtil";
import AddButton from "../common/table/AddButton";
import RemoveButton from "../common/table/RemoveButton";
import {Dropdown} from "primereact/dropdown";

export default class ProcedureTables extends Component {

    constructor(props) {
        super(props);

        this.state = {
            directBillingProcedure: this.newDirectBillingProcedure(),
            selfPayProcedure: this.newSelfPayProcedure(),
            directBillingCptList: [],
            selfPayCptList: []
        };
    }

    componentDidMount() {
        this.cptCodeSearchForDirectBilling();
        this.cptCodeSearchForSelfPay();
    }

    newDirectBillingProcedure = () => {
        return {type: 'DB', quantity: 1, approved: false};
    }

    newSelfPayProcedure = () => {
        return {type: 'SP', quantity:1, approved: false};
    }

    isItemValid = (item) => {
        const result = item.cpt;

        if (!result) {
            showWarning('Please select a CPT item.')
        }

        return result;
    }

    insuranceValidToday = (insurance) => {
        return insurance.validityFrom && insurance.validityTo &&
            DateUtil.isBetweenInclusive(new Date(), insurance.validityFrom, insurance.validityTo);
    }

    setGrossAndNet = (item, cpt) => {
        item.gross = cpt.cash;
        item.net = cpt.cash;

        const {insurance} = this.props;

        if (item.type === 'DB') {
            let cptInsurance;

            if (insurance && this.insuranceValidToday(insurance) && insurance.tpa) {
                const insuranceName = insurance.tpa.name;
                cptInsurance = cpt.insuranceList.find(i => i.type === 'COMPANY' && i.insuranceCompanyId != null && i.insuranceCompanyId == insurance.company?.id); // company cpt is priority
                if (!cptInsurance) {
                    cptInsurance = cpt.insuranceList.find(i => i.insurance.toUpperCase() === insuranceName);
                }
            } else {
                cptInsurance = cpt.insuranceList.find(i => i.insuranceCompanyId != null && i.insuranceCompanyId == insurance.company?.id);
            }

            if (cptInsurance) {
                item.gross = cptInsurance.gross;
                item.net = cptInsurance.net;
            }
        }
    }

    cptCodeSearch(params, callback) {
        RemotingService.getRemoteCall(`api/cpt/code-or-description/${this.props.currentVisit.clinic.country}`, params, cptCodes => {
            cptCodes.forEach(cpt => cpt.label = (cpt.cptCode || cpt.description));
            return callback(cptCodes);
        });
    }

    cptCodeSearchForDirectBilling = () => {
        const params = {
            filterWebinarAndClass: true,
            scopes: ["ALL", "DIRECT_BILLING"],
            speciality: this.props.currentVisit.appointment.staff.speciality.name,
            insurance: this.props.insurance?.tpa?.name,
            insuranceCompanyId: this.props.insurance?.company?.id
        };
        this.cptCodeSearch(params, cptCodes => this.setState({directBillingCptList: cptCodes}));
    }

    cptCodeSearchForSelfPay = () => {
        const params = {
            scopes: ["ALL", "SELF_PAY"],
            speciality: this.props.currentVisit.appointment.staff.speciality.name
        }
        this.cptCodeSearch(params, cptCodes => this.setState({selfPayCptList: cptCodes}));
    }

    render() {
        const {directBillingProcedures, selfPayProcedures} = this.props.diagnosis;

        directBillingProcedures.forEach(item => this.setGrossAndNet(item, item.cpt));
        selfPayProcedures.forEach(item => this.setGrossAndNet(item, item.cpt));

        return (
            <div>
                <div className="d-flex justify-content-between">
                    <h6 className={"dim14-form-label m-0 ml-2"}>Procedure</h6>
                    <div style={{color: "#7BAC16", fontWeight: "bold"}}>
                        Bill Type: Direct Billing
                    </div>
                </div>

                <div className={"content-card"}>
                    <table className={"emr-table"}>
                        <tbody>
                        <tr>
                            <th>Code</th>
                            <th>Description</th>
                            <th>Gross</th>
                            <th>Net</th>
                            <th>Quantity</th>
                            <th>Select For Billing</th>
                            <th style={{color: "#e85e5e"}}>ⓧ</th>
                        </tr>
                        {directBillingProcedures.map((procedure, index) =>
                            <tr key={"directBillingProcedures" + index} style={{backgroundColor: "white"}}>
                                <td>{procedure.cpt.cptCode}</td>
                                <td>{procedure.cpt.description}</td>
                                <td>{procedure.gross}</td>
                                <td>{procedure.net}</td>
                                <td>{procedure.quantity}</td>
                                <td>
                                    <CheckboxInput value={procedure.approved}
                                                   onChange={value => {
                                                       procedure.approved = value;
                                                       this.forceUpdate();
                                                   }}/>
                                </td>
                                <td>
                                    {!procedure.readOnly &&
                                    <RemoveButton
                                        onClick={() => {
                                            directBillingProcedures.splice(index, 1);
                                            this.forceUpdate();
                                        }}
                                    />
                                    }
                                </td>
                            </tr>
                        )}
                        <tr style={{backgroundColor: "white"}}>
                            <td style={{maxWidth: 100}}>
                                <Dropdown
                                    className={"emr-in-table-select-container w-100 text-left"}
                                    panelStyle={{minWidth: 250}}
                                    filter filterBy="label" dataKey="id"
                                    placeholder="Select..."
                                    itemTemplate={cpt => `${cpt.cptCode} - ${cpt.description}`}
                                    optionLabel="label"
                                    options={this.state.directBillingCptList}
                                    value={this.state.dbCpt}
                                    onChange={(event) => {
                                        this.state.dbCpt = event.value;
                                        this.state.directBillingProcedure.cpt = event.value;
                                        this.setGrossAndNet(this.state.directBillingProcedure, event.value);
                                        this.forceUpdate();
                                    }}
                                />
                            </td>
                            <td>
                                {this.state.directBillingProcedure.cpt ? this.state.directBillingProcedure.cpt.description : ''}
                            </td>
                            <td>{this.state.directBillingProcedure.cpt ? this.state.directBillingProcedure.gross : ''}</td>
                            <td>{this.state.directBillingProcedure.cpt ? this.state.directBillingProcedure.net : ''}</td>
                            <td>
                                <NumberInput value={this.state.directBillingProcedure.quantity || ''}
                                             min={1} max={10}
                                             onChange={value => {
                                                 if(value > 0){
                                                     this.state.directBillingProcedure.quantity = value;
                                                     this.forceUpdate();
                                                 }
                                             }}/>
                            </td>
                            <td></td>
                            <td>
                                <AddButton
                                    onClick={() => {
                                        if (this.isItemValid(this.state.directBillingProcedure)) {
                                            directBillingProcedures.push(this.state.directBillingProcedure);
                                            this.setState({
                                                dbCpt: null,
                                                directBillingProcedure: this.newDirectBillingProcedure()
                                            });
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <br/>
                <br/>

                <div className="d-flex justify-content-between">
                    <h6 className={"dim14-form-label m-0 ml-2"}>Procedure
                    </h6>
                    <div style={{color: "#7BAC16", fontWeight: "bold"}}>
                        Bill Type: Self Pay
                    </div>
                </div>

                <div className={"content-card"}>
                    <table className={"emr-table"}>
                        <tbody>
                        <tr>
                            <th>Code</th>
                            <th>Description</th>
                            <th>Gross</th>
                            <th>Net</th>
                            <th>Quantity</th>
                            <th>Select For Billing</th>
                            <th style={{color: "#E85E5E"}}>ⓧ</th>
                        </tr>
                        {selfPayProcedures.map((procedure, index) =>
                            <tr key={"selfPayProcedures" + index} style={{backgroundColor: "white"}}>
                                <td>{procedure.cpt.cptCode}</td>
                                <td>{procedure.cpt.description}</td>
                                <td>{procedure.gross}</td>
                                <td>{procedure.net}</td>
                                <td>{procedure.quantity}</td>
                                <td>
                                    <CheckboxInput value={procedure.approved}
                                                   onChange={value => {
                                                       procedure.approved = value;
                                                       this.forceUpdate();
                                                   }}/>
                                </td>
                                <td>
                                    <RemoveButton
                                        onClick={() => {
                                            selfPayProcedures.splice(index, 1);
                                            this.forceUpdate();
                                        }}
                                    />
                                </td>
                            </tr>
                        )}
                        <tr style={{backgroundColor: "white"}}>
                            <td style={{maxWidth: 100}}>
                                <Dropdown
                                    className={"emr-in-table-select-container w-100 text-left"}
                                    panelStyle={{minWidth: 250}}
                                    filter filterBy="label" dataKey="id"
                                    placeholder="Select..."
                                    itemTemplate={cpt => `${cpt.cptCode} - ${cpt.description}`}
                                    optionLabel="label"
                                    options={this.state.selfPayCptList}
                                    value={this.state.spCpt}
                                    onChange={(event) => {
                                        this.state.spCpt = event.value;
                                        this.state.selfPayProcedure.cpt = event.value;
                                        this.setGrossAndNet(this.state.selfPayProcedure, event.value);
                                        this.forceUpdate();
                                    }}
                                />
                            </td>
                            <td>
                                {this.state.selfPayProcedure.cpt ? this.state.selfPayProcedure.cpt.description : ''}
                            </td>
                            <td>{this.state.selfPayProcedure.cpt ? this.state.selfPayProcedure.gross : ''}</td>
                            <td>{this.state.selfPayProcedure.cpt ? this.state.selfPayProcedure.net : ''}</td>
                            <td>
                                <NumberInput value={this.state.selfPayProcedure.quantity || ''}
                                             min={1} max={10}
                                             onChange={value => {
                                                 if(value > 0){
                                                     this.state.selfPayProcedure.quantity = value;
                                                     this.forceUpdate();
                                                 }
                                             }}/>
                            </td>
                            <td></td>
                            <td>
                                <AddButton
                                    onClick={() => {
                                        if (this.isItemValid(this.state.selfPayProcedure)) {
                                            selfPayProcedures.push(this.state.selfPayProcedure);
                                            this.setState({
                                                spCpt: null,
                                                selfPayProcedure: this.newSelfPayProcedure()
                                            });
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        );
    }
}
