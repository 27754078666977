import React from "react";
import {withStyles} from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    header: {
        fontWeight: "bold",
        minHeight: 28,
    }
});

const DialogTitle = withStyles(styles)(props => {
    const {children, classes,textColor, onClose, ...other} = props;
    const WhiteTextTypography = withStyles({
        root: {
            color: textColor,
            MozUserSelect:'none',
            WebkitUserSelect:'none',
            msUserSelect:'none'
        }
    })(Typography);
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <WhiteTextTypography className={classes.header} variant="h6" >{children}</WhiteTextTypography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

export default DialogTitle;