import {GenericChecker} from "../GenericChecker";

export class LabsImagingChecker extends GenericChecker {
    static isDataEntered(entity) {
        if (entity == null) {
            return false;
        }

        return entity.radiology || entity.labs || (entity.reportStatus && entity.reportStatus.length > 0);
    }
}