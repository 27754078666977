import "./LeaveCalendar.css";
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import React, {Component} from "react";
import {Badge, Button} from "react-bootstrap";
import RemotingService from "../../services/remoting-service/RemotingService";
import {DateInput, SelectInput, TextareaInput} from "../../components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import EnumLeaveErrorCode from "./EnumLeaveErrorCode";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import EnumLeaveType from "./EnumLeaveType";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DateUtil from "../../utils/DateUtil";
import HRRemoteService from "./HRRemoteService";
import DurationCalculator from "./DurationCalculator";
import CalendarEventService from "../appointment/CalendarEventService";
import {noop} from "lodash-es";
import NumberUtil from "../../utils/NumberUtil";
import LeaveCommentTable from "./LeaveCommentTable";
import ExtraDayUtil from "./util/ExtraDayUtil";
import {FormField} from "../../components/form";
import EnumExtraDayType from "./EnumExtraDayType";
import NotificationService from "../../services/notification-service/NotificationService";
import {downloadBlob} from "../../utils/FileDownloader";
import moment from "moment";
import {currentTimezone} from "../../utils/CalendarDateUtil";

export default class LeaveModal extends Component {

    constructor(props) {
        super(props);

        let {startTime, endTime, description, attachment, room, isFlexibleHoursCompensation} = this.props.leave;

        this.state = {
            startTime,
            endTime,
            description,
            attachment,
            room,
            isFlexibleHoursCompensation,
            blockEventsByDate: new Map(),
            courseStartTimes: [],
            courseEndTimes: [],
            durationExcludingLunch: null
        };
    }

    componentDidMount() {
        const leave = this.props.leave;

        if (leave.type === 'EXTRA') {
            this.retrieveRoomList(leave.startTime, leave.endTime);
        } else if (leave.type === "SICK" || leave.type === "ANNUAL" || leave.type === "COURSE") {
            this.retrieveBlockEvents(this.state.startTime, this.state.endTime, blockEventsByDate => {
                const {courseStartTimes, courseEndTimes} = this.getCourseOptions(this.state.startTime, blockEventsByDate);
                const startOption = courseStartTimes.find(s => DateUtil.isSame(this.state.startTime, s.value));
                const endOption = courseEndTimes.find(s => DateUtil.isSame(this.state.endTime, s.value));

                const newState = {
                    blockEventsByDate,
                    courseStartTimes,
                    courseEndTimes
                };

                if (startOption) {
                    newState.startTime = startOption.value;
                }

                if (endOption) {
                    newState.endTime = endOption.value;
                }

                if(leave.type === "COURSE") {
                    this.calculateDurationExcludingBreaks();
                }

                this.setState(newState);
            });
        }
    }

    getCourseOptions(date, blockEventsByDate) {
        const courseStartTimes = [];
        const courseEndTimes = [];

        const key = DateUtil.formatDate(date);
        const events = blockEventsByDate.get(key);

        events && events.forEach(event => {
            const start = DateUtil.switchZone(moment.tz(event.start, event.nativeTimezone), currentTimezone).toDate();
            const end = DateUtil.switchZone(moment.tz(event.end, event.nativeTimezone), currentTimezone).toDate();
            courseStartTimes.push({
                label: DateUtil.getHourMinute24H(start),
                value: start
            });
            courseEndTimes.push({
                label: DateUtil.getHourMinute24H(end),
                value: end
            });
        });

        courseStartTimes.sort((o1, o2) => o1.label.localeCompare(o2.label));
        courseEndTimes.sort((o1, o2) => o1.label.localeCompare(o2.label));

        return {courseStartTimes, courseEndTimes};
    }

    retrieveRoomList = (startDate, endDate) => {
        const params = {
            startDate: DateUtil.formatDate(startDate, "YYYY-MM-DD"),
            endDate: DateUtil.formatDate(endDate, "YYYY-MM-DD")
        };

        RemotingService.getRemoteCall('api/room/available-rooms', params, (resultList) => {
            let roomList = resultList.map(item => item.room)
            this.setState({roomList});
        });
    }

    retrieveBlockEvents = (start, end, callback = noop) => {
        const leaveType = this.props.leave.type;
        if (leaveType !== "SICK" && leaveType !== "ANNUAL" && leaveType !== "COURSE") {
            return;
        }

        const staffId = this.props.leave.staffId;
        const periodStart = start || this.state.startTime;
        const periodEnd = end || this.state.endTime;

        RemotingService.postRemoteCall(
            'api/staff/rotation/manual',
            {
                startDate: DateUtil.getUtcDateAtStartOfDay(periodStart),
                endDate: DateUtil.getUtcDateAtStartOfDay(periodEnd),
                staffIds: [staffId]
            },
            (manualRotations) => {
                const result = CalendarEventService.createEvents([], {id: staffId}, this.props.schedules, manualRotations, periodStart, periodEnd);

                let events = result.events;
                let blockEventsByDate = new Map();
                events.forEach(e => {
                    const key = DateUtil.formatDate(e.start);
                    if (blockEventsByDate.has(key)) {
                        blockEventsByDate.get(key).push(e);
                    } else {
                        blockEventsByDate.set(key, [e]);
                    }
                });

                callback(blockEventsByDate);
            });
    }

    renderRoomSelectInput(field, options, className = "") {
        return (
            <FormField ref={formField => this.formFields.push(formField)}
                       required requiredMessage="Room required"
                       validateOn={this.state[field]}>
                    <SelectInput showClear={false}
                                    className={className}
                                    options={options}
                                    displayProperty="info"
                                    value={this.state[field]}
                                    onChange={(val) => this.setState({[field]: val})}
                                    disabled={!this.props.isAdminPage}/>
            </FormField>
        )
    }

    onSave = () => {
        if (ValidationUtil.checkWithNotification(this.formFields)) {
            this.props.close();
            this.props.onClose();
            this.props.onSave({
                ...this.state,
                startTime: DateUtil.switchZone(this.state.startTime, this.props.timezone).toDate(),
                endTime: DateUtil.switchZone(this.state.endTime, this.props.timezone).toDate()
            });
        }
    }

    submit = () => {
        const id = this.props.leave.id;
        const params = {description: this.state.description , staffId: this.props.leave.staffId};

        HRRemoteService.submit(id, params, (leave) => {
            this.props.close();
            this.props.onClose();
            this.props.onSubmitted(leave);
        });
        this.showBusinessRuleNotification();
    }

    showBusinessRuleNotification = () => {
        const businessRulesErrors = this.props.leave.errors;
        if(businessRulesErrors && businessRulesErrors.length) {
            const businessRulesAlert = businessRulesErrors.map((error) =>  EnumLeaveErrorCode[error].name + '\n');
            NotificationService.showNotification({ severity: 'warn', summary: 'Notification', detail: businessRulesAlert });
        }
    }

    approve = () => {
        const id = this.props.leave.id;
        const params = {description: this.state.description, room: this.state.room};

        if (this.state.attachment == null && "SICK" == this.props.leave.type) {
            HRRemoteService.approveSickLeaveWithoutAttachment(id, params, (leave) => {
                this.props.close();
                this.props.onClose();
                this.props.onApproved(leave);
            });
        } else {
            HRRemoteService.approve(id, params, (leave) => {
                this.props.close();
                this.props.onClose();
                this.props.onApproved(leave);
            });
        }

        this.showBusinessRuleNotification();
    }

    reject = () => {
        const id = this.props.leave.id;
        const params = {description: this.state.description};

        HRRemoteService.reject(id, params, (leave) => {
            this.props.close();
            this.props.onClose();
            this.props.onRejected(leave);
        });
    }

    delete = () => {
        const leave = this.props.leave;

        HRRemoteService.delete(leave, () => {
            this.props.close();
            this.props.onClose();
            this.props.onDeleted(leave);
        })
    }

    startTimeChanged(startTime) {
        const leaveType = this.props.leave.type;
        if (leaveType == "SICK" || leaveType == "ANNUAL" || leaveType == "COURSE") {
            this.retrieveBlockEvents(startTime, this.state.endTime, blockEventsByDate => {
                this.setState({startTime, blockEventsByDate})
            });
        } else if (leaveType == "EXTRA") {
            this.setExtraDayStartEndDates(startTime);
        } else {
            this.setState({startTime});
        }
    }

    endTimeChanged(endTime) {
        const leaveType = this.props.leave.type;
        if (leaveType == "SICK" || leaveType == "ANNUAL" || leaveType == "COURSE") {
            this.retrieveBlockEvents(this.state.startTime, endTime, blockEventsByDate => {
                this.setState({endTime, blockEventsByDate})
            });
        } else if (leaveType == "EXTRA") {
            this.setExtraDayStartEndDates(endTime);
        } else {
            this.setState({endTime});
        }
    }

    setExtraDayStartEndDates(date) {
        const {start, end} = ExtraDayUtil.calculateStartEndDates(date, this.props.timezone);
        this.setState({startTime: start, endTime: end, room: null});
        this.retrieveRoomList(date, date);
    }

    renderCourseSelectInput(field, options, dateReadOnly, className = "w-100") {
        return <SelectInput showClear={false}
                            searchEnabled={false}
                            disabled={dateReadOnly}
                            className={className}
                            options={options}
                            displayProperty="label"
                            valueProperty="value"
                            value={this.state[field]}
                            onChange={(val) => this.handleDateInputChange(field, val)}/>
    }

    handleDateInputChange = (field, val) => {
        this.setState({[field]: val});
        this.calculateDurationExcludingBreaks(field, val);
    }

    calculateDurationExcludingBreaks = (field, val) => {
        const params = {
            startTime: field === "startTime" ? DateUtil.formatDateTime(val) : DateUtil.formatDateTime(this.state.startTime),
            endTime: field === "endTime" ? DateUtil.formatDateTime(val) : DateUtil.formatDateTime(this.state.endTime)}
        HRRemoteService.getDurationExcludingBreaks(this.props.leave.staffId, params, (result) => {
            this.setState({ durationExcludingLunch: DateUtil.formatHoursDuration(result / 60)});
        });
    }

    render() {
        const leave = this.props.leave;
        const newLeave = !leave.id;
        const params = EnumLeaveType[leave.type];
        const dateReadOnly = 'PENDING' !== leave.status || !!leave.isFlexibleHoursCompensation;
        const isAttachmentEnable = "PENDING" == leave.status || ("SUBMITTED" == leave.status && "SICK" == leave.type);

        const descriptionReadOnly = !this.props.isAdminPage && !newLeave && (leave.status === "SUBMITTED" || leave.status === "REJECTED" || leave.status === "APPROVED");
        const showRemaining = params.showRemaining !== false;
        const isHourly = params.hourly;

        this.formFields = [];

        return (
            <div>
                <div className={"row justify-content-end mt-n2 mb-2"}>
                    <IconButton className={"p-0"} aria-label="close" onClick={() => {this.props.close(); this.props.onClose();}}>
                        <CloseIcon/>
                    </IconButton>
                </div>

                <div className="display-flex flex-column p-overflow-hidden LeaveModal" style={{minWidth: 300}}>
                    <div className="display-flex p-justify-between text-right">
                        <div>
                            <span className="circle-background" style={{background: params.color}}>{params.code}
                            </span> <b>{params.name}</b>
                        </div>
                        {leave.extraDayType &&
                            <div>
                                {leave.extraDayType === EnumExtraDayType.EXTRA_PAY.key ?
                                    <Badge pill style={{color: EnumLeaveType.EXTRA.color ,backgroundColor: EnumLeaveType.EXTRA.bgColor, width: '100px', height: '25px'}}>
                                        <div className={"display-flex flex-row text-center mt-1 ml-2"}>
                                                <FontAwesomeIcon icon={["fas", "dollar-sign"]} style={{color: EnumLeaveType.EXTRA.textColor, marginRight: '5px'}}/>
                                            <div style={{color: EnumLeaveType.EXTRA.textColor}}>{EnumExtraDayType[leave.extraDayType].name}</div>
                                        </div>
                                    </Badge> :
                                    <Badge pill style={{color: EnumLeaveType.EXTRA.color ,backgroundColor: EnumLeaveType.EXTRA.bgColor, width: '130px', height: '25px'}}>
                                        <div className={"display-flex flex-row text-center mt-1 ml-2"}>
                                                <FontAwesomeIcon icon={["fas", "clock"]} style={{color: EnumLeaveType.EXTRA.textColor, marginRight: '5px'}}/>
                                            <div style={{color: EnumLeaveType.EXTRA.textColor}}>{EnumExtraDayType[leave.extraDayType].name}</div>
                                        </div>
                                    </Badge>
                                }

                            </div>
                        }
                    </div>

                    <div className="display-flex p-justify-between border-bottom py-2 mb-3">
                        {showRemaining ?
                            <div className="display-flex flex-column text-center"
                                 style={{backgroundColor: "#F9FBFC", fontWeight: "bold"}}>
                                <div className={'text-nowrap'}>
                                    <span>
                                        {leave.type == "COURSE" ?
                                                <span>{this.state.durationExcludingLunch}</span> :
                                                <span>{DurationCalculator.getDuration(leave, this.state.startTime, this.state.endTime, this.state.blockEventsByDate)}{params.unit}</span>
                                        }
                                        <span>/</span>
                                        {leave.type == "ANNUAL" ?
                                            <span>{isHourly ? DateUtil.formatHoursDuration(leave.meta.remaining)
                                                : NumberUtil.toFixed(leave.meta.remaining, 2)}
                                                {params.unit}
                                            </span>
                                            : <span>{leave.meta.remaining < 0 ? 0 : isHourly ?
                                                DateUtil.formatHoursDuration(leave.meta.remaining)
                                                : NumberUtil.toFixed(leave.meta.remaining, 2)}
                                                {params.unit}
                                            </span>
                                        }
                                    </span>
                                </div>
                                <div style={{color: "#CDCFD0", fontSize: 10}}>
                                    REMAINING
                                </div>
                            </div> :
                            <div></div>
                        }
                    </div>
                    {leave.type !== 'EXTRA' &&
                    <div className="display-flex w-100 mb-1 align-items-center">
                        <div style={{paddingLeft: 5, width: '45%'}}>
                            {isHourly ?
                                this.renderCourseSelectInput('startTime', this.state.courseStartTimes, dateReadOnly) :
                                <DateInput disabled={dateReadOnly} utcTime={false}
                                           value={this.state.startTime}
                                           onChange={(startTime) => this.startTimeChanged(startTime)}/>}
                        </div>
                        <div className="mx-3" style={{textAlign: "center", fontWeight: "bold"}}>to</div>
                        <div style={{width: '45%'}}>
                            {isHourly ?
                                this.renderCourseSelectInput('endTime', this.state.courseEndTimes, dateReadOnly) :
                                <DateInput disabled={dateReadOnly} utcTime={false}
                                           value={this.state.endTime}
                                           onChange={(endTime) => this.endTimeChanged(endTime)}/>}
                        </div>
                    </div>}
                    {leave.type === 'EXTRA' && <div>
                        <DateInput className="col-12" disabled={!this.props.isAdminPage || dateReadOnly}
                                   utcTime={false}
                                   value={this.state.startTime}
                                   onChange={(startTime) => this.startTimeChanged(startTime)}/>
                    </div>}
                    {leave.type === 'EXTRA' && <div className={'my-2'} style={{fontSize: 13}}>
                        <div className={'font-weight-bold'}>Select Room</div>
                        {this.renderRoomSelectInput('room', this.state.roomList || [], "ml-1")}
                    </div>}
                    {leave.comments && <LeaveCommentTable comments={leave.comments}/>}
                    <div className="display-flex p-justify-between mb-2">
                        <div className="col-9" style={{fontSize: 13}}>
                            <div className={'font-weight-bold mb-1'}>Add Description</div>
                            <FormField
                                ref={(formField) => this.formFields.push(formField)}
                                required
                                validateOn={leave.type !== "COURSE" ? true : this.state.description}
                            >
                                <TextareaInput className="w-100" style={{minHeight: "100px"}}
                                               prefix={leave.isFlexibleHoursCompensation ? leave.description : undefined}
                                               disabled={descriptionReadOnly} value={this.state.description || ""}
                                               onChange={(description) => this.setState({description})}/>
                            </FormField>
                        </div>
                        <div className="col-3">
                            <label style={{float:"right", marginRight: 20}}>
                                <input type="file" disabled={!isAttachmentEnable}
                                       id="upload-attachment"
                                       accept=".jpg, .jpeg, .png, .pdf, .xls, .xlsx, .doc, .docx, .txt"
                                       onChange={(e) => {
                                           const fileName = e.target.files[0].name;
                                           RemotingService.uploadFile(e.target.files[0], (response, err) => {
                                               if (err == null) {
                                                   this.setState({
                                                       attachment: {name: fileName, uuid: response.data}
                                                   });
                                               }
                                           });
                                       }}/>
                                <FontAwesomeIcon icon={["fas", "paperclip"]} size={"sm"}
                                                 className={isAttachmentEnable ? "cursor-pointer" : ""}
                                                 style={{float: "right", marginTop: 5}}/>
                                <br/>
                            </label>
                            <div style={{fontSize: 12, textAlign: "left"}}>
                                {this.state.attachment ?
                                    <span
                                        className={isAttachmentEnable && this.props.isAdminPage ? "cursor-pointer" : ""}
                                        onClick={() => {
                                            if (this.props.isAdminPage) {
                                                RemotingService.instance.get('api/file/download/' + this.state.attachment.uuid, {responseType: 'blob'})
                                                    .then((response) => {
                                                        downloadBlob(new Blob([response.data]), this.state.attachment.name);
                                                    })
                                            }
                                        }}
                                    >
                                        {this.state.attachment.name}</span> : 'Attachment'}
                            </div>
                        </div>
                    </div>
                    {(leave.errors && leave.errors.length) ? <>
                        <div style={{padding: 0}}>
                            <hr className="solid"/>
                        </div>
                        <div style={{color: '#dc3545', fontSize: 12, paddingLeft: 5, marginBottom: 10}}>
                            {leave.errors.map((error, index) => <li key={index}>{EnumLeaveErrorCode[error].name}</li>)}
                        </div>
                    </> : <></>}

                    <DialogActions>
                        {!this.props.isAdminPage && newLeave &&
                        <Button variant={"light"} size={"sm"} onClick={() => {this.props.close(); this.props.onClose();}}>Discard</Button>
                        }
                        {!this.props.isAdminPage && !newLeave && leave.status !== "APPROVED" &&
                        <Button variant={"light"} size={"sm"} onClick={this.delete}>Delete</Button>
                        }
                        {((!this.props.isAdminPage && (leave.status === "PENDING" || ("SUBMITTED" == leave.status && "SICK" == leave.type))) ||
                            (this.props.isAdminPage && ((leave.status === "SUBMITTED" || leave.status === "REJECTED") && "EXTRA" == leave.type)))
                        &&
                        <Button variant={"success"} size={"sm"} onClick={this.onSave}>Save</Button>
                        }
                        {!this.props.isAdminPage && !newLeave && leave.status === "PENDING" &&
                        <Button variant={"success"} size={"sm"} onClick={this.submit}>Submit For Approval</Button>
                        }
                        {this.props.isAdminPage && (leave.status === "SUBMITTED" || leave.status === "APPROVED" || leave.status === "REJECTED" || leave.status === "COMPLETED") &&
                        <Button variant={"light"} size={"sm"} onClick={this.delete}>Delete</Button>
                        }
                        {this.props.isAdminPage && (leave.status === "SUBMITTED" || leave.status === "APPROVED" || leave.status === "COMPLETED") &&
                        <Button variant={"light"} size={"sm"} onClick={this.reject}>Reject</Button>
                        }
                        {this.props.isAdminPage && (leave.status === "SUBMITTED" || leave.status === "REJECTED") &&
                        <Button variant={"success"} size={"sm"} onClick={this.approve}>Approve</Button>
                        }
                    </DialogActions>
                </div>

            </div>
        );
    }
}
