import React, {Component} from 'react';
import {ListItemIcon, MenuItem, Typography, Divider} from "@material-ui/core";
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import DefaultActionMenuIcon from "./icons/DefaultActionMenuIcon";
import AuthService from "../../services/auth-service/AuthService";

class ActionMenuItem extends Component {

    state = {}

    static defaultProps = {
        id: null,
        style: null,
        disabled: null,
        onClick: null,
        text: "",
        icon: null,
        isLastItem: false,
        closeOnClick: true,
        authority: null
    };

    static propTypes = {
        id: PropTypes.string,
        style: PropTypes.object,
        disabled: PropTypes.bool,
        onClick: PropTypes.func,
        text: PropTypes.string,
        icon: PropTypes.any,
        isLastItem: PropTypes.bool,
        authority: PropTypes.string
    };

    render() {
        if (this.props.authority && !AuthService.userHasAuthority(this.props.authority)) {
            return null;
        }

        return (
            <div>
                <StyledMenuItem disabled={this.props.disabled} onClick={() => {
                    this.props.onClick();
                    if(this.props.closeOnClick){
                        this.props.closeMenuOnClick();
                    }
                }}>
                    <ListItemIcon>
                        {this.props.icon ? this.props.icon : <DefaultActionMenuIcon/>}
                    </ListItemIcon>
                    <Typography variant="inherit" style={{marginRight:"10px"}}>{this.props.text}</Typography>
                </StyledMenuItem>
                {this.props.isLastItem ? null : <Divider/>}
            </div>
        );
    }
}

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '& .MuiListItemIcon-root': {
            minWidth: "30px"
        },
        '&MuiTypography-root': {}
        ,
        '&:focus': {
            '& .MuiListItemIcon-root, & .MuiTypography-root': {
                color: "#89b431",
            },
        },
    },
}))(MenuItem);

export default ActionMenuItem;