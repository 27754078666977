import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export const painMarks = (enumLabel) => [
    {value: 0,
        label:
            <div className="text-center">
                <FontAwesomeIcon icon={["fas", "laugh"]} size={"2x"} color={"#00a900"}/>
                <p>{painMarkLabels.No_Pain[enumLabel]}</p>
            </div>},
    {value: 3, label:
            <div className="text-center">
                <FontAwesomeIcon icon={["fas", "smile-beam"]} size={"2x"} color={"#82e05d"}/>
                <p>{painMarkLabels.Mild[enumLabel]}</p>
            </div>},
    {value: 5, label:
            <div className="text-center">
                <FontAwesomeIcon icon={["fas", "meh"]} size={"2x"} color={"#fbd237"}/>
                <p>{painMarkLabels.Moderate[enumLabel]}</p>
            </div>},
    {value: 7, label:
            <div className="text-center">
                <FontAwesomeIcon icon={["fas", "frown-open"]} size={"2x"} color={"#f86d39"}/>
                <p>{painMarkLabels.Severe[enumLabel]}</p>
            </div>},
    {value: 10, label:
            <div className="text-center">
                <FontAwesomeIcon icon={["fas", "sad-cry"]} size={"2x"} color={"#e92200"}/>
                <p>{painMarkLabels.Maddening[enumLabel]}</p>
            </div>}
];

const painMarkLabels = {
    No_Pain: {name: "No Pain", nameInArabic: "بدون ألم"},
    Mild: {name: "Mild", nameInArabic: "طفيف"},
    Moderate: {name: "Moderate", nameInArabic: "متوسط"},
    Severe: {name: "Severe", nameInArabic: "شديد"},
    Maddening: {name: "Maddening", nameInArabic: "غير محتمل"}
}

export const getPainInfo = (value) => {
    return value;
};

export default {painMarks, getPainInfo};