import React, {Component} from "react";
import '../../EMR.css';
import DermatomesScreenRowColorsEnum from "../enums/dermatomes/DermatomesScreenRowColorsEnum";
import DermatomesCervicalEnum from "../enums/dermatomes/DermatomesCervicalEnum";
import TypeEnum from "../enums/dermatomes/TypeEnum";
import DermatomesThoracicEnum from "../enums/dermatomes/DermatomesThoracicEnum";
import DermatomesLumbarEnum from "../enums/dermatomes/DermatomesLumbarEnum";
import DermatomesSacralEnum from "../enums/dermatomes/DermatomesSacralEnum";
import ReflexesLeftSideEnum from "../enums/dermatomes/ReflexesLeftSideEnum";
import CranialNervesEnum from "../enums/dermatomes/CranialNervesEnum";
import ReflexesRightSideEnum from "../enums/dermatomes/ReflexesRightSideEnum";
import DermatomesScreenUtil from "../utils/DermatomesScreenUtil";
import DermatomesDropdownValues from "../enums/dermatomes/DermatomesDropdownValues";
import ReflexesDropdownValues from "../enums/dermatomes/ReflexesDropdownValues";
import CranialNervesDropdownValues from "../enums/dermatomes/CranialNervesDropdownValues";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import {isEmpty} from "../../../../../utils/ArrayUtil";
import MyotomesCervicalEnum from "../enums/dermatomes/MyotomesCervicalEnum";
import MyotomesDropdownValues from "../enums/dermatomes/MyotomesDropdownValues";
import MyotomesThoracicEnum from "../enums/dermatomes/MyotomesThoracicEnum";
import MyotomesLumbarEnum from "../enums/dermatomes/MyotomesLumbarEnum";
import MyotomesSacralEnum from "../enums/dermatomes/MyotomesSacralEnum";
import NeurodynamicsUpperLimbEnum from "../enums/dermatomes/NeurodynamicsUpperLimbEnum";
import NeurodynamicsLowerLimbEnum from "../enums/dermatomes/NeurodynamicsLowerLimbEnum";

export default class DermatomesView extends Component {

    constructor(props) {
        super(props);

        const dermatomes = this.splitDermatomes(props);
        const reflexes = this.splitReflexes(props);
        const myotomes = this.splitMyotomes(props);
        const neurodynamics = this.splitNeurodynamics(props);

        this.state = {
            dermatomes: dermatomes,
            reflexes: reflexes,
            myotomes: myotomes,
            neurodynamics: neurodynamics
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.assessment.dermatomes !== this.props.assessment.dermatomes) {
            const dermatomes = this.splitDermatomes(this.props);
            this.setState({dermatomes});
        }
        if (prevProps.assessment.reflexes !== this.props.assessment.reflexes) {
            const reflexes = this.splitReflexes(this.props);
            this.setState({reflexes});
        }
        if (prevProps.assessment.myotomes !== this.props.assessment.myotomes) {
            const myotomes = this.splitMyotomes(this.props);
            this.setState({myotomes});
        }
        if (prevProps.assessment.neurodynamics !== this.props.assessment.neurodynamics) {
            const neurodynamics = this.splitNeurodynamics(this.props);
            this.setState({neurodynamics});
        }
    }

    splitDermatomes = (props) => {
        let dermatomes = {};

        if (! props?.assessment?.dermatomes?.items) {
            return dermatomes;
        }

        let cervical = props.assessment.dermatomes.items.filter(item => item.type === TypeEnum.CERVICAL);
        let thoracic = props.assessment.dermatomes.items.filter(item => item.type === TypeEnum.THORACIC);
        let lumbar = props.assessment.dermatomes.items.filter(item => item.type === TypeEnum.LUMBAR);
        let sacral = props.assessment.dermatomes.items.filter(item => item.type === TypeEnum.SACRAL);

        Object.assign(dermatomes, {cervical, thoracic, lumbar, sacral});

        return dermatomes;
    }

    splitReflexes = (props) => {
        let reflexes = {};

        if (! props?.assessment?.reflexes?.items) {
            return reflexes;
        }

        props.assessment.reflexes.items.forEach(item => {
            if (item.type === TypeEnum.REFLEX_LEFT) {
                if (reflexes.reflexLeftSide == null)
                    reflexes.reflexLeftSide = []
                reflexes.reflexLeftSide.push(item)
            } else if (item.type === TypeEnum.REFLEX_RIGHT) {
                if (reflexes.reflexRightSide == null)
                    reflexes.reflexRightSide = []
                reflexes.reflexRightSide.push(item)
            } else if (item.type === TypeEnum.CRANIAL_NERVES) {
                if (reflexes.cranialNerves == null)
                    reflexes.cranialNerves = []
                reflexes.cranialNerves.push(item)
            }
        })

        return reflexes;
    }

    splitMyotomes = (props) => {
        let myotomes = {};

        if (! props?.assessment?.myotomes?.items) {
            return myotomes;
        }

        let cervical = props.assessment.myotomes.items.filter(item => item.type === TypeEnum.CERVICAL);
        let thoracic = props.assessment.myotomes.items.filter(item => item.type === TypeEnum.THORACIC);
        let lumbar = props.assessment.myotomes.items.filter(item => item.type === TypeEnum.LUMBAR);
        let sacral = props.assessment.myotomes.items.filter(item => item.type === TypeEnum.SACRAL);

        Object.assign(myotomes, {cervical, thoracic, lumbar, sacral});

        return  myotomes;
    }

    splitNeurodynamics = (props) => {
        let neurodynamics = {};

        if (!props?.assessment?.neurodynamics?.items) {
            return neurodynamics;
        }

        props.assessment.neurodynamics.items.forEach(item => {
            if (item.type === TypeEnum.UPPER_LIMB) {
                if (neurodynamics.upperLimb == null)
                    neurodynamics.upperLimb = []
                neurodynamics.upperLimb.push(item)
            } else if (item.type === TypeEnum.LOWER_LIMB) {
                if (neurodynamics.lowerLimb == null)
                    neurodynamics.lowerLimb = []
                neurodynamics.lowerLimb.push(item)
            }
        })
        return neurodynamics;
    }

    checkMyotomesSwitch = () => {
        return (
            DermatomesScreenUtil.checkIfHaveValidItems(this.state.myotomes)
        )
    }

    checkNeurodynamicsSwitch = () => {
        return (
            DermatomesScreenUtil.checkIfHaveValidItems(this.state.neurodynamics)
        )
    }

    render() {
        return (
            <div>
                <div className={"content-row"} style={{display: "block"}}>
                    {DermatomesScreenUtil.DermatomesViewTableHeader(
                        <span>Dermatomes <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></span>,
                        this.props.assessment.dermatomes && (!isEmpty(this.props.assessment.dermatomes.items) || this.props.assessment.dermatomes.none),
                        {},
                        this.props.assessment.dermatomes && this.props.assessment.dermatomes.none ? "Normal" : null
                    )}
                    {this.state.dermatomes && <table className={"emr-table content-card text-align-left"}>
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.dermatomes.cervical, "Cervical", DermatomesCervicalEnum, DermatomesDropdownValues, DermatomesScreenRowColorsEnum.DERMATOMESCERVICAL.color)}
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.dermatomes.thoracic, "Thoracic", DermatomesThoracicEnum, DermatomesDropdownValues, DermatomesScreenRowColorsEnum.DERMATOMESTHORACIC.color)}
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.dermatomes.lumbar, "Lumbar", DermatomesLumbarEnum, DermatomesDropdownValues, DermatomesScreenRowColorsEnum.DERMATOMESLUMBAR.color)}
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.dermatomes.sacral, "Sacral", DermatomesSacralEnum, DermatomesDropdownValues, DermatomesScreenRowColorsEnum.DERMATOMESSACRAL.color)}
                    </table>}

                    {DermatomesScreenUtil.DermatomesViewTableHeader(
                        <span>Myotomes <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></span>,
                        this.props.assessment.myotomes && (!isEmpty(this.props.assessment.myotomes.items) || this.props.assessment.myotomes.none),
                        {},
                        this.props.assessment.myotomes && this.props.assessment.myotomes.none ? "Normal" : null
                    )}
                    {this.state.myotomes && <table className={"emr-table content-card"}>
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.myotomes.cervical, "Cervical", MyotomesCervicalEnum, MyotomesDropdownValues, DermatomesScreenRowColorsEnum.DERMATOMESCERVICAL.color)}
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.myotomes.thoracic, "Thoracic", MyotomesThoracicEnum, MyotomesDropdownValues, DermatomesScreenRowColorsEnum.DERMATOMESTHORACIC.color)}
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.myotomes.lumbar, "Lumbar", MyotomesLumbarEnum, MyotomesDropdownValues, DermatomesScreenRowColorsEnum.DERMATOMESLUMBAR.color)}
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.myotomes.sacral, "Sacral", MyotomesSacralEnum, MyotomesDropdownValues, DermatomesScreenRowColorsEnum.DERMATOMESSACRAL.color)}
                    </table>}
                </div>
                <div className={"content-row"} style={{display: "block"}}>
                    {DermatomesScreenUtil.DermatomesViewTableHeader(
                        <span>Reflexes <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></span>,
                        this.props.assessment.reflexes && (!isEmpty(this.props.assessment.reflexes.items) || this.props.assessment.reflexes.none),
                        {marginTop: "10px"},
                        this.props.assessment.reflexes && this.props.assessment.reflexes.none ? "Normal" : null
                    )}
                    {this.state.reflexes && <table className={"emr-table content-card text-align-left"}>
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.reflexes.reflexLeftSide, "Reflex", ReflexesLeftSideEnum, ReflexesDropdownValues, DermatomesScreenRowColorsEnum.REFLEXLEFTSIDE.color)}
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.reflexes.cranialNerves, "Cranial Nerves", CranialNervesEnum, CranialNervesDropdownValues, DermatomesScreenRowColorsEnum.CARNIALNERVES.color, true, "Result")}
                    </table>}

                    {this.state.reflexes && (this.state.reflexes.reflexRightSide || (this.props.assessment.reflexesExtra && this.props.assessment.reflexesExtra.items)) &&
                    <table className={"emr-table content-card text-align-left"} style={{marginTop: "20px"}}>
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.reflexes.reflexRightSide, "Reflex", ReflexesRightSideEnum, ReflexesDropdownValues, DermatomesScreenRowColorsEnum.REFLEXRIGHTSIDE.color)}
                        {DermatomesScreenUtil.DermatomesViewReflexExtraTable(this.props.assessment.reflexesExtra, DermatomesScreenRowColorsEnum.REFLEXRIGHTSIDE.color,
                            this.state.reflexes.reflexRightSide === null || this.state.reflexes.reflexRightSide === undefined || this.state.reflexes.reflexRightSide.length === 0, ReflexesDropdownValues)}
                    </table>}

                    {DermatomesScreenUtil.DermatomesViewTableHeader(
                        "Neurodynamics",
                        this.checkNeurodynamicsSwitch(),
                        {marginTop: "10px"}
                    )}
                    {this.state.neurodynamics && this.state.neurodynamics.upperLimb &&
                    <table className={"emr-table content-card"}>
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.neurodynamics.upperLimb, "Upper Limb", NeurodynamicsUpperLimbEnum, null, DermatomesScreenRowColorsEnum.NEURODYNAMICSUPPERLIMB.color)}
                    </table>}

                    {this.state.neurodynamics && this.state.neurodynamics.lowerLimb &&
                    <table className={"emr-table content-card"} style={{marginTop: "20px"}}>
                        {DermatomesScreenUtil.DermatomesViewTable(this.state.neurodynamics.lowerLimb, "Lower Limb", NeurodynamicsLowerLimbEnum, null, DermatomesScreenRowColorsEnum.NEURODYNAMICSLOWERLIMB.color)}
                    </table>}
                </div>
            </div>
        );
    }
}
