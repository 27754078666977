import React, {Component} from "react";
import "../../VisitHistory.css"
import PostureEnum from "../../../assessment/enums/posture/PostureEnum";

export default class PostureViewHistory extends Component {

    render() {
        if (!this.props.posture || !this.props.posture.items || this.props.posture.items.length === 0) {
            return <div className="pl-3">
                {this.props.posture && this.props.posture.none ? "Normal" : "No information available"}
            </div>;
        }

        return(
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Standing</th>
                    <th>Sitting</th>
                    <th>Spine</th>
                    <th>Prone</th>
                </tr>
                {this.props.posture.items.length !== 0 ?
                    this.props.posture.items.map((item,index) =>
                    {
                        return (
                            <tr key={"postureViewHistory" + index} style={{backgroundColor: "white"}}>
                                <td> {item.standing == null && item.standingComment == null ? "" : PostureEnum[item.standing] != null? PostureEnum[item.standing].name : item.standingComment}</td>
                                <td> {item.sitting == null && item.sittingComment == null ? "" : PostureEnum[item.sitting] != null? PostureEnum[item.sitting].name : item.sittingComment}</td>
                                <td> {item.spine == null && item.spineComment == null ? "" : PostureEnum[item.spine] != null? PostureEnum[item.spine].name : item.spineComment}</td>
                                <td> {item.prone == null && item.proneComment == null ? "" : PostureEnum[item.prone] != null? PostureEnum[item.prone].name : item.proneComment}</td>
                            </tr> );
                    })
                    : ''
                }
                </tbody>
            </table>
        );
    }
}