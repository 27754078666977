import React, {Component} from 'react';
import moment from "moment";
import StringUtil from "../../utils/StringUtil";
import {RadioInput, TextInput, Wizard} from "../../components";
import RemotingService from "../../services/remoting-service/RemotingService";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import SignatureInput from "../../components/signature-input/SignatureInput";
import Logo from "../../layout/Logo";
import FormField from "../../components/form/FormField";
import PatientUtil from "../../utils/PatientUtil";
import ConsentFormsUtil from "./utils/ConsentFormsUtil";
import DateUtil from "../../utils/DateUtil";

class TeleconsultationConsentForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            healthProfessionalDate: new Date(),
            patientDate: new Date(),
            guardianDate: new Date(),
        }
    }

    onSubmit = () => {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        if (this.props.form !== null && this.props.form !== undefined) {
            this.props.close();

        } else {
            let patient = this.props.patient;
            let patientFullName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
            let patientMrn = StringUtil.mrn(patient.mrn);
            let patientDOB = patient.birthday;

            const {
                participateInTeleconsultation, patientDate, guardianDate, guardianName, guardianRelationship,
                patientSignature, guardianSignature
            } = this.state;

            RemotingService.postRemoteCall('api/patient/' + this.props.patient.id + '/teleconsultation-consent-form',
                {
                    participateInTeleconsultation,
                    patientDate,
                    guardianDate,
                    guardianName,
                    guardianRelationship,
                    patientSignature,
                    guardianSignature,
                    patientFullName,
                    patientMrn,
                    patientDOB
                },
                (result) => {
                    this.props.afterSave(result);
                    this.props.close();
                });
        }
    }

    onSaveGuardianSignature = (signature) => {
        this.setState({guardianSignature: signature})
    }

    onSavePatientSignature = (signature) => {
        this.setState({patientSignature: signature})
    }

    onSaveProviderSignature = (signature) => {
        this.setState({providerSignature: signature})
    }

    render() {
        if (!this.props.show)
            return null;

        let {patient, form, providerName} = this.props;
        let readOnly = form !== null && form !== undefined;
        let mrnNo = StringUtil.mrn(patient.mrn);
        let patientName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
        this.formFields = []

        return (
            <Wizard
                show={this.props.show}
                close={this.props.close}
                title="Teleconsultation Consent Form"
                onSubmit={this.onSubmit}
                isReadOnly={readOnly}
            >
                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>
                    <div style={{maxWidth: '950px'}}>
                        <div style={{
                            marginTop: '15px', marginBottom: '15px', width: '100%', display: 'flex',
                            flexFlow: 'row', justifyContent: 'space-between', alignItems: 'center'
                        }}>
                            <Logo/>
                            <div>
                                <h2>TELECONSULTATION CONSENT FORM</h2>
                            </div>
                        </div>

                        <hr style={{
                            borderTop: "2px solid #000000",
                            marginTop: "-10px",
                            boxShadow: "0px 2px 2px #797979"
                        }}/>

                        <div style={{border: "1px solid #000000"}}>
                            <div style={{margin: "15px 15px"}}>
                                <h5>Patient information:</h5>
                                <div className={"row"} style={{marginTop: "10px", marginLeft: "0px"}}>
                                    <div className="col-md-5">
                                        <b>Name:</b> {patientName}
                                    </div>
                                    <div className="col-md-4">
                                        <b>MRN:</b> {mrnNo}
                                    </div>
                                    <div className="col-md-3">
                                        <b>D.O.B:</b> {DateUtil.formatDate(patient.birthday)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                            <div style={{margin: "15px 15px"}}>
                                <p><b>
                                    The following consent is to be filled & signed electronically. If you were unable to do so, kindly reply to the same email with the following:
                                    I {patientName}, born on   {DateUtil.formatDate(patient.birthday)}, consent to participate in the
                                    teleconsultation for the service(s)/procedure(s) mentioned above.
                                </b></p>
                            </div>
                        </div>

                        <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                            <div style={{margin: "15px 15px"}}>
                                It is important for you to consider the benefits, risks and alternatives to the teleconsultation treatment offered by our licensed healthcare professionals in order to make an informed decision about proceeding with treatment.
                                These services include diagnostic procedures, exercise and prescribing medication.
                                To be able to provide you these services, we would like to obtain your consent to access your medical records & have a live audio and video interaction


                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>
                    <div style={{maxWidth: '950px'}}>
                        <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                            <div style={{margin: "10px 15px"}}>
                                <p><b>Electronic systems:</b><br/>
                                    Electronic systems used will incorporate network and software security protocols to protect confidentiality of patient identification and imaging data and will include measures to safeguard the data and to ensure its integrity against intentional and unintentional corruption in accordance with the Laws and Regulations.
                                </p>

                                <p><b>Nature of Teleconsultation:</b><br/>
                                    Upon booking, you will be receiving a payment link to collect the payment before the scheduled teleconsultation session. During the teleconsultation
                                    <ul className={"list-unstyled"}>
                                        <li>
                                            <p><span className={"mr-2 d-inline font-weight-bold"}>a.</span>Details of your medical history, examinations, x-rays and tests may be discussed with other healthcare professionals with interactive videos, audio and telecommunication technology.</p>
                                        </li>
                                        <li>
                                            <p><span className={"mr-2 d-inline font-weight-bold"}>b.</span>Upon your approval, audio and/or photo recordings may be taken for accurate diagnosis, treatment and quality control.</p>
                                        </li>
                                    </ul>
                                </p>

                                <p><b>Medical Information and Records: </b>All existing federal laws and local regulations/policies/guidelines regarding access to medical
                                    information and copies of your Health Records apply to this teleconsultation.
                                    Dissemination of any patient identifiable images or information for this telehealth interaction to other entities will not take place without your consent.
                                </p>

                                <p><b>Confidentiality: </b>Responsible and appropriate efforts have been made to eliminate any confidentiality risks associated with the teleconsultation and all
                                    existing confidentiality protections under the federal laws and local regulation apply to information disclosed during this teleconsultation.
                                </p>

                                <p><b>Rights: </b>You may withhold or withdraw consent to teleconsultation at any time without affecting your right to future care or treatment.
                                </p>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>
                    <div style={{maxWidth: '950px'}}>
                        <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                            <div style={{margin: "10px 15px"}}>
                                <p><b>Questions or concerns</b><br/>
                                    You are encouraged to ask questions at any time regarding your assessment and treatment.
                                    Bring any concerns you have to your Doctor or Therapist’s attention.
                                    If you are not comfortable, you may stop treatment at any time.
                                    You have the right to have your concerns heard and resolved when possible.
                                </p>

                                <p><b>Disputes: </b>You agree that any disputes that arise from the teleconsultation will be resolved as per the local laws and regulations and will be raised with the telehealth provider in the first instance.
                                </p>

                                <p><b>Expected Benefits:</b><br/>
                                    <ul className={"list-unstyled"}>
                                        <li>
                                            <p><span className={"mr-2 d-inline font-weight-bold"}>a.</span>Improve access to medical care by enabling a patient to remain in their home, office (or a remote site) while your licensed healthcare provider offers you the needed care.</p>
                                        </li>
                                        <li>
                                            <p><span className={"mr-2 d-inline font-weight-bold"}>b.</span>More efficient medical evaluation and management.</p>
                                        </li>
                                    </ul>
                                </p>

                                <p><b>Possible Risks:</b><br/>
                                    As with any medical procedures there are potential risks associated with the use of Telehealth, which may include, but not limited to the following:
                                    <ul className={"list-unstyled"}>
                                        <li>
                                            <p><span className={"mr-2 d-inline font-weight-bold"}>a.</span>Information transmission may not be sufficient (e.g. poor resolution of images) to allow appropriate decision making by the consulted physician.</p>
                                        </li>
                                        <li>
                                            <p><span className={"mr-2 d-inline font-weight-bold"}>b.</span>Delays in medical evaluation and treatment could occur due to deficiencies or failure of internet.</p>
                                        </li>
                                        <li>
                                            <p><span className={"mr-2 d-inline font-weight-bold"}>c.</span>In rare instances, security protocol could fail causing a breach of privacy of personal medical information. </p>
                                        </li>
                                        <li>
                                            <p><span className={"mr-2 d-inline font-weight-bold"}>d.</span>In rare cases, a lack of access to complete health records may result in adverse drug interactions, allergic reactions, or other judgement errors.  </p>
                                        </li>
                                    </ul>
                                </p>

                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>
                    <div style={{maxWidth: '950px'}}>
                        <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                            <div style={{margin: "10px 15px"}}>
                                <p><b>
                                    You have been advised of all the risks, consequences and benefits of telehealth.
                                    Your treating physician has discussed with you the information provided in a
                                    language you can understand. You have had the opportunity to ask questions about
                                    the information presented in this form and about the teleconsultation. All your
                                    questions have been answered and you understand the written information provided
                                    above.
                                </b></p>
                                <p className="display-flex">I
                                    <FormField ref={formField => this.formFields.push(formField)} required
                                               validateOn={readOnly ? true : this.state.participateInTeleconsultation}>
                                        <RadioInput id="participateInTeleconsultation"
                                                    value={readOnly ? form.participateInTeleconsultation :
                                                        this.state.participateInTeleconsultation}
                                                    disabled={readOnly} options={["agree", "refuse"]}
                                                    onChange={(value) => this.setState({participateInTeleconsultation: value})}/>
                                    </FormField>
                                    &nbsp;to participate in the teleconsultation for the service(s)/procedure(s) mentioned above.</p>
                            </div>
                        </div>

                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    DATE/TIME:
                                </div>

                                <div className="col-md-3">
                                    {moment(readOnly && form.patientDate ?
                                        new Date(form.patientDate)
                                        :
                                        this.state.patientDate).format('MMMM DD YYYY, h:mm:ss a')
                                    }
                                </div>
                            </div>

                            <div className="row" style={{marginTop: "15px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    PATIENT NAME:
                                </div>
                                <div className="col-md-3">
                                    {patientName}
                                </div>

                                <div className="col-md-6 dim14-form-label">
                                    PATIENT SIGNATURE:
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <SignatureInput
                                        signature={readOnly ? form.patientSignature : this.state.patientSignature}
                                        onSave={this.onSavePatientSignature} readOnly={readOnly}/>
                                </div>
                            </div>
                        </div>

                    </div>

                </Wizard.Page>

                {<Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                              onPrevious={() => this.formFields = []}>
                    <div style={{width: '1000px'}}>
                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <p><b>A witness should sign below if the patient is unable to sign but has indicated
                                    his or her consent. <br/>
                                    Young people (under {ConsentFormsUtil.getWitnessAgeText()} years of age) and children should also have a Parent
                                    or Guardian sign here.</b></p>
                            </div>

                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    PRINT NAME:
                                </div>
                                <div className="col-md-3">
                                    <TextInput value={readOnly ? form.guardianName : this.state.guardianName}
                                               disabled={readOnly} alphabeticOnly style={{textAlign: "center"}}
                                               onChange={(val) => this.setState({guardianName: val})}/>
                                </div>

                                <div className="col-md-3 dim14-form-label">
                                    Relationship to Patient
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    <TextInput
                                        value={readOnly ? form.guardianRelationship : this.state.guardianRelationship}
                                        disabled={readOnly} alphabeticOnly style={{textAlign: "center"}}
                                        onChange={(val) => this.setState({guardianRelationship: val})}/>
                                </div>
                            </div>


                            <div className={"row"} style={{marginTop: "15px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    DATE/TIME:
                                </div>

                                <div className="col-md-3">
                                    {moment(readOnly && form.guardianDate ?
                                        new Date(form.guardianDate)
                                        :
                                        this.state.guardianDate).format('MMMM DD YYYY, h:mm:ss a')
                                    }
                                </div>

                                <div className="col-md-6 dim14-form-label">
                                    SIGNATURE OF WITNESS/PARENT OR GUARDIAN:
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <FormField ref={formField => this.formFields.push(formField)}
                                               disabled={readOnly || this.state.patientSignature != null}
                                               required requiredMessage="Signature required"
                                               validateOn={this.state.guardianSignature}>
                                        <SignatureInput
                                            signature={readOnly ? form.guardianSignature : this.state.guardianSignature}
                                            onSave={this.onSaveGuardianSignature} readOnly={readOnly}/>
                                    </FormField>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>}
            </Wizard>
        );
    }
}

export default TeleconsultationConsentForm;
