import * as ArrayUtils from "../../../../../utils/ArrayUtil";

const getLastValidData = (visits, field) => {
    visits.forEach(visit => {
        console.log(visit)
    })
    return null;
}

const getFilledDiagnosisEntity = (visits, diagnosisGroupsObject) => {
    const diagnosis = {...diagnosisGroupsObject[0]};

        if (isFieldEmpty(diagnosis.diagnosisItems)) {
            for (let i = visits.length - 1; i >= 0; i--) {
                if (!isFieldEmpty(visits[i].diagnosis.diagnosisGroups[0]) && !isFieldEmpty(visits[i].diagnosis.diagnosisGroups[0].diagnosisItems)) {
                    diagnosis.diagnosisItems = visits[i].diagnosis.diagnosisGroups[0].diagnosisItems;
                    break;
                }
            }
        }

    return diagnosis;
}

const getFilledClinicalImpressionString = (visits, clinicalImpressionString) => {
    let lastFilledString = clinicalImpressionString;

    for (let i = visits.length - 1; i >= 0; i--) {
        if (!isFieldEmpty(visits[i].diagnosis.clinicalImpression)) {
            lastFilledString = visits[i].diagnosis.clinicalImpression;
            break;
        }
    }

    return lastFilledString;
}

const getFilledRiskEntity = (visits, riskObject) => {
    let risk = null;
    if (riskObject != null) {
        risk = {...riskObject};
    }

    for (let i = visits.length - 1; i >= 0; i--) {
        if (visits[i].diagnosis.risk != null) {
            risk = visits[i].diagnosis.risk;
            break;
        }
    }

    return risk;
}

const getFilledComplaintEntity = (visits, complaintObject) => {
    const complaint = {...complaintObject};
    const excludeFields = ["id", "creationDate", "updateDate", "version"];

    for (const property in complaint) {
        if (!excludeFields.includes(property) && isFieldEmpty(complaint[property])) {
            for (let i = visits.length - 1; i >= 0; i--) {
                if (!isFieldEmpty(visits[i].subjective.complaint[property])) {
                    complaint[property] = visits[i].subjective.complaint[property];
                    break;
                }
            }
        }
    }

    return complaint;
}

const getFilledTreatmentReceivedEntity = (visits, treatmentReceivedObject) => {
    let treatmentReceived = {...treatmentReceivedObject};

    for (let i = visits.length - 1; i >= 0; i--) {
        if (!isTreatmentInfoEmpty(visits[i].treatment.treatmentReceived)) {
            treatmentReceived = {...visits[i].treatment.treatmentReceived};
            break;
        }
    }

    return treatmentReceived;
}

const getFilledTreatmentPlanEntity = (visits, treatmentPlanObject) => {
    let treatmentPlan = {...treatmentPlanObject};

    for (let i = visits.length - 1; i >= 0; i--) {
        if (!isTreatmentInfoEmpty(visits[i].treatment.treatmentPlan)) {
            treatmentPlan = {...visits[i].treatment.treatmentPlan};
            break;
        }
    }

    return treatmentPlan;
}

const getFilledGoalsEntity = (visits, goalObject) => {
    let goal = {...goalObject};

    for (let i = visits.length - 1; i >= 0; i--) {
        if (!isGoalEmpty(visits[i].treatment.goal)) {
            goal = {...visits[i].treatment.goal};
            break;
        }
    }

    return goal;
}

const getFilledRiskOfHarmFromTreatmentEntity = (visits, riskOfHarmFromTreatmentObject) => {
    let riskOfHarmFromTreatment = {...riskOfHarmFromTreatmentObject};

    for (let i = visits.length - 1; i >= 0; i--) {
        if (!isRiskOfHarmChanged(visits[i].treatment.riskOfHarmFromTreatment)) {
            riskOfHarmFromTreatment = {...visits[i].treatment.riskOfHarmFromTreatment};
            break;
        }
    }

    return riskOfHarmFromTreatment;
}

const getFilledReferralsEntity = (visits, referralsObject) => {
    const referral = {...referralsObject};
    const excludeFields = ["id", "creationDate", "updateDate", "version"];

    for (const property in referral) {
        if (!excludeFields.includes(property) && isFieldEmpty(referral[property])) {
            for (let i = visits.length - 1; i >= 0; i--) {
                if (!isFieldEmpty(visits[i].treatment.referral[property])) {
                    referral[property] = visits[i].treatment.referral[property];
                    break;
                }
            }
        }
    }

    return referral;
}

const getFilledResultsEntity = (visits, resultsObject) => {
    let results = [];
    if (resultsObject != null){
        results = [...resultsObject];
    }

    for (let i = visits.length - 1; i >= 0; i--) {
        if (!isFieldEmpty(visits[i].treatment.results)) {
            results = [...visits[i].treatment.results];
            break;
        }
    }

    return results;
}

const getAccumulatedResultsEntity = (visits) => {
    let results = [];

    for (let i = visits.length - 1; i >= 0; i--) {
        if (!isFieldEmpty(visits[i].treatment.results)) {
            results.push(...visits[i].treatment.results);
        }
    }

    return results;
}

const getHistoricResultsEntity = (visits , currVisitIndex) => {
    let results = [];

    for (let i = visits.length - 1; i >= 0; i--) {
        if (i !== currVisitIndex && !isFieldEmpty(visits[i].treatment.results)) {
            results.push(...visits[i].treatment.results);
        }
    }

    return results;
}

const getAccumulatedCommunicationsEntity = (visits) => {
    let communications = [];
    //DSC-218: display all communications from all treatments in all visits
    for (let i = visits.length - 1; i >= 0; i--) {
        if (!isFieldEmpty(visits[i].treatment.communications)) {
            communications.push(...visits[i].treatment.communications);
        }
    }
    //DSC-218: filter out duplicated communications since currently the BE copies old
    // communications to new visits with New Ids
    //todo change BE behavior of copying communications
    communications = communications.filter((value, index, self) =>
        index === self.findIndex((c) => (
            c.addedBy === value.addedBy && c.communicationMode === value.communicationMode
                && c.addedBy === value.addedBy && c.communicationText === value.communicationText
        ))
    )
    return communications;
}

const getFilledAssessmentField = (visits, assessmentFieldObject, field) => {
    let filledObject = {}
    if(isItemsFilledOrNormal(assessmentFieldObject)){
        filledObject = {...assessmentFieldObject}
        return filledObject;
    }

    for (let i = visits.length - 1; i >= 0; i--) {
        if (isItemsFilledOrNormal(visits[i].assessment[field])) {
            filledObject = {...visits[i].assessment[field]};
            break;
        }
    }

    return filledObject;

}

const getFilledMuscleStrength = (visits, muscleGroupObject, individualMuscleObject) => {
    let muscleGroup = {}
    let individualMuscle = {}
    if(isItemsFilledOrNormal(muscleGroupObject) || isItemsFilledOrNormal(individualMuscleObject)){
        muscleGroup = {...muscleGroupObject}
        individualMuscle = {...individualMuscleObject}
        return {muscleGroup, individualMuscle};
    }

    for (let i = visits.length - 1; i >= 0; i--) {
        if (isItemsFilledOrNormal(visits[i].assessment.muscleGroup) || isItemsFilledOrNormal(visits[i].assessment.individualMuscle)) {
            muscleGroup = {...visits[i].assessment.muscleGroup}
            individualMuscle = {...visits[i].assessment.individualMuscle}
            break;
        }
    }

    return {muscleGroup, individualMuscle};
}

const getFilledReflexes = (visits, reflexesObject, reflexesExtraObject) => {
    let reflexes = {}
    let reflexesExtra = {}
    if(isItemsFilledOrNormal(reflexesObject) || isItemsFilledOrNormal(reflexesExtraObject)){
        reflexes = {...reflexesObject}
        reflexesExtra = {...reflexesExtraObject}
        return {reflexes, reflexesExtra};
    }

    for (let i = visits.length - 1; i >= 0; i--) {
        if (isItemsFilledOrNormal(visits[i].assessment.reflexes) || isItemsFilledOrNormal(visits[i].assessment.reflexesExtra)) {
            reflexes = {...visits[i].assessment.reflexes}
            reflexesExtra = {...visits[i].assessment.reflexesExtra}
            break;
        }
    }

    return {reflexes, reflexesExtra};
}

const isFieldEmpty = (field) => {
    return (field == null || field.length == 0);
}

const isItemsFilledOrNormal = (field) => {
    return field != null && (!ArrayUtils.isEmpty(field.items) || field.none)
}

const isTreatmentInfoEmpty = (treatmentInfo) => {
    if (treatmentInfo == null) {
        return true;
    }
    return !((treatmentInfo.acupuncture && treatmentInfo.acupuncture.length != 0) ||
        (treatmentInfo.breathingPatterns && treatmentInfo.breathingPatterns.length != 0) ||
        (treatmentInfo.cupping && treatmentInfo.cupping.length != 0) ||
        (treatmentInfo.dryNeedling && treatmentInfo.dryNeedling.length != 0) ||
        (treatmentInfo.electrotherapy && treatmentInfo.electrotherapy.length != 0) ||
        (treatmentInfo.gaitTraining && treatmentInfo.gaitTraining.length != 0) ||
        (treatmentInfo.lymphaticDrainage && treatmentInfo.lymphaticDrainage.length != 0) ||
        (treatmentInfo.manipulation && treatmentInfo.manipulation.length != 0) ||
        (treatmentInfo.mobilization && treatmentInfo.mobilization.length != 0) ||
        (treatmentInfo.other && treatmentInfo.other.length != 0) ||
        (treatmentInfo.procedures && treatmentInfo.procedures.length != 0) ||
        (treatmentInfo.shockwave && treatmentInfo.shockwave.length != 0) ||
        (treatmentInfo.stretching && treatmentInfo.stretching.length != 0) ||
        (treatmentInfo.taping && treatmentInfo.taping.length != 0) ||
        (treatmentInfo.therapeuticExercise && treatmentInfo.therapeuticExercise.length != 0) ||
        (treatmentInfo.therapeuticMassage && treatmentInfo.therapeuticMassage.length != 0) ||
        (treatmentInfo.thermalTherapy && treatmentInfo.thermalTherapy.length != 0));
}

const isGoalEmpty = (goalObject) => {
    if (goalObject == null) {
        return true;
    }

    return !(
        (goalObject.details && goalObject.details.length != 0) ||
        (goalObject.specialRemarks && goalObject.specialRemarks.length != 0) ||
        (goalObject.shortTermItems && goalObject.shortTermItems.length != 0) ||
        (goalObject.longTermItems && goalObject.longTermItems.length != 0)
    );
}


const isRiskOfHarmChanged = (riskOfHarmFromTreatment) => {
    if (riskOfHarmFromTreatment == null) {
        return true;
    }

    return !(
        (riskOfHarmFromTreatment.whatRisk && riskOfHarmFromTreatment.whatRisk.length != 0) ||
        (riskOfHarmFromTreatment.patientInformedDetail && riskOfHarmFromTreatment.patientInformedDetail.length != 0) ||
        (riskOfHarmFromTreatment.patientGiveConsentDetail && riskOfHarmFromTreatment.patientGiveConsentDetail.length != 0) ||
        (riskOfHarmFromTreatment.precautionsNecessaryDetail && riskOfHarmFromTreatment.precautionsNecessaryDetail.length != 0) ||
        riskOfHarmFromTreatment.hasPatientInformed ||
        riskOfHarmFromTreatment.didPatientGiveConsent ||
        riskOfHarmFromTreatment.arePrecautionsNecessary ||
        riskOfHarmFromTreatment.isAllGood
    )
}

export default {
    getFilledDiagnosisEntity,
    getLastValidData,
    getFilledComplaintEntity,
    getFilledClinicalImpressionString,
    getFilledRiskEntity,
    getFilledTreatmentReceivedEntity,
    getFilledTreatmentPlanEntity,
    getFilledGoalsEntity,
    getFilledRiskOfHarmFromTreatmentEntity,
    getFilledReferralsEntity,
    getFilledResultsEntity,
    getAccumulatedResultsEntity,
    getHistoricResultsEntity,
    getAccumulatedCommunicationsEntity,
    isRiskOfHarmChanged,
    getFilledAssessmentField,
    getFilledMuscleStrength,
    getFilledReflexes
};
