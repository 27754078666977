import NotificationService from "../../services/notification-service/NotificationService";
import CalendarEventService from "./CalendarEventService";
import DateUtil from "../../utils/DateUtil";
import RemotingService from "../../services/remoting-service/RemotingService";
import {noop} from "lodash-es";
import TimezoneUtil from "../../utils/TimezoneUtil";

class RotationBlockRemoteService {

    moveBlock(events, selectedEvent, newStaffId, newStart, successCallback = noop) {
        if (selectedEvent.staff.id !== newStaffId || !selectedEvent.startMoment.isSame(newStart, "day")) {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Cannot move to another staff or day.'
            });
            return;
        }

        if (selectedEvent.startMoment.isSame(newStart)) {
            return;
        }

        const hostBlock = CalendarEventService.getHostBlockForSameStaffAndDay(events, selectedEvent, newStart);

        if (selectedEvent === hostBlock) { // Not moved
            return;
        }

        const staff = selectedEvent.staff;
        const params = this.createDto(selectedEvent, hostBlock);

        RemotingService.postRemoteCall(`api/staff/${staff.id}/move-rotation-block`, params, successCallback);
    }

    mergeBlock = (selectedEvent, hostBlock, successCallback = noop) => {
        const staff = selectedEvent.staff;
        const params = this.createDto(selectedEvent, hostBlock);

        RemotingService.postRemoteCall(`api/staff/${staff.id}/merge-rotation-block`, params, successCallback);
    }

    splitBlock = (selectedEvent, minutesToSplit, successCallback = noop) => {
        const staff = selectedEvent.staff;
        const clinicTimezone = TimezoneUtil.getClinicZoneInfo(selectedEvent.room.clinicName).zone;
        const params = {
            date: selectedEvent.startMoment,
            blockStart: DateUtil.getTimeStringAtZone(selectedEvent.startMoment, clinicTimezone),
            blockEnd: DateUtil.getTimeStringAtZone(selectedEvent.endMoment, clinicTimezone),
            minutesToSplit: minutesToSplit,
            timezone: clinicTimezone
        };

        RemotingService.postRemoteCall(`api/staff/${staff.id}/split-rotation-block`, params, successCallback);
    }

    addBlock = ({staffId, type, roomId, date, start, end, timezone}, successCallback = noop) => {
        const params = {
            type: type,
            roomId: roomId,
            date: date,
            blockStart: DateUtil.getTimeStringAtZone(start, timezone),
            blockEnd: DateUtil.getTimeStringAtZone(end, timezone),
        };

        RemotingService.postRemoteCall(`api/staff/${staffId}/add-rotation-block`, params, successCallback);
    }

    deleteBlock = (selectedEvent, successCallback = noop) => {
        const staff = selectedEvent.staff;
        const clinicTimezone = selectedEvent.room ? TimezoneUtil.getClinicZoneInfo(selectedEvent.room.clinicName).zone : selectedEvent.nativeTimezone;

        const params = {
            date: selectedEvent.startMoment,
            blockStart: DateUtil.getTimeStringAtZone(selectedEvent.startMoment, clinicTimezone),
            blockEnd: DateUtil.getTimeStringAtZone(selectedEvent.endMoment, clinicTimezone),
            timezone: clinicTimezone
        };

        RemotingService.postRemoteCall(`api/staff/${staff.id}/delete-rotation-block`, params, successCallback);
    }

    changeRoom = (staffId, roomId, date, successCallback = noop) => {
        const params = {
            roomId,
            date
        }

        RemotingService.postRemoteCall(`api/staff/${staffId}/change-rotation-block-room`, params, successCallback);
    }

    updateBlockType = (selectedEvent, newType, successCallback = noop) => {
        const staff = selectedEvent.staff;
        const clinicTimezone = selectedEvent.room ? TimezoneUtil.getClinicZoneInfo(selectedEvent.room.clinicName).zone : selectedEvent.nativeTimezone;

        const params = {
            date: selectedEvent.startMoment,
            startTime: DateUtil.getTimeStringAtZone(selectedEvent.startMoment, clinicTimezone),
            endTime: DateUtil.getTimeStringAtZone(selectedEvent.endMoment, clinicTimezone),
            type: newType,
            roomId: selectedEvent.room ? selectedEvent.room.id : null,
            timezone: clinicTimezone
        };

        RemotingService.postRemoteCall(`api/staff/${staff.id}/update-block-type`, params, successCallback);
    }

    createDto(selectedEvent, hostBlock) {
        const block1StartTime = selectedEvent.startMoment;
        const block1EndTime = selectedEvent.endMoment;
        const block2StartTime = hostBlock.startMoment;
        const block2EndTime = hostBlock.endMoment;

        const clinicTimezone = selectedEvent.room ? TimezoneUtil.getClinicZoneInfo(selectedEvent.room.clinicName).zone : selectedEvent.nativeTimezone;

        return {
            date: block1StartTime,
            block1Start: DateUtil.getTimeStringAtZone(block1StartTime, clinicTimezone),
            block1End: DateUtil.getTimeStringAtZone(block1EndTime, clinicTimezone),
            block2Start: DateUtil.getTimeStringAtZone(block2StartTime, clinicTimezone),
            block2End: DateUtil.getTimeStringAtZone(block2EndTime, clinicTimezone),
            timezone: clinicTimezone
        };
    }

}

export default new RotationBlockRemoteService();
