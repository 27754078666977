import React, {Component} from "react";
import '../../EMR.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GreenSwitchWithMessage from "../../../../../components/switch/GreenSwitchWithMessage";
import DermatomesCervicalEnum from "../enums/dermatomes/DermatomesCervicalEnum";
import Select from "react-select";
import StatefulTextInput from "../../../../../components/text-input/StatefulTextInput";
import DermatomesThoracicEnum from "../enums/dermatomes/DermatomesThoracicEnum";
import DermatomesLumbarEnum from "../enums/dermatomes/DermatomesLumbarEnum";
import DermatomesSacralEnum from "../enums/dermatomes/DermatomesSacralEnum";
import MyotomesCervicalEnum from "../enums/dermatomes/MyotomesCervicalEnum";
import MyotomesThoracicEnum from "../enums/dermatomes/MyotomesThoracicEnum";
import MyotomesLumbarEnum from "../enums/dermatomes/MyotomesLumbarEnum";
import MyotomesSacralEnum from "../enums/dermatomes/MyotomesSacralEnum";
import DermatomesScreenRowColorsEnum from "../enums/dermatomes/DermatomesScreenRowColorsEnum";
import ReflexesLeftSideEnum from "../enums/dermatomes/ReflexesLeftSideEnum";
import CranialNervesEnum from "../enums/dermatomes/CranialNervesEnum";
import ReflexesRightSideEnum from "../enums/dermatomes/ReflexesRightSideEnum";
import NeurodynamicsUpperLimbEnum from "../enums/dermatomes/NeurodynamicsUpperLimbEnum";
import NeurodynamicsLowerLimbEnum from "../enums/dermatomes/NeurodynamicsLowerLimbEnum";
import TypeEnum from "../enums/dermatomes/TypeEnum";
import PosNegRadioInput from "../../../../../components/radio-input/PosNegRadioInput";
import DermatomesDropdownValues from "../enums/dermatomes/DermatomesDropdownValues";
import MyotomesDropdownValues from "../enums/dermatomes/MyotomesDropdownValues";
import ReflexesDropdownValues from "../enums/dermatomes/ReflexesDropdownValues";
import CranialNervesDropdownValues from "../enums/dermatomes/CranialNervesDropdownValues";
import CheckboxInput from "../../../../../components/checkbox-input/CheckboxInput";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import DermatomesUtil from "../utils/DermatomesUtil";

export default class DermatomesModal extends Component {

    state = {
        cervicalExpanded: false,
        thoracicExpanded: false,
        lumbarExpanded: false,
        sacralExpanded: false,
        cervicalMyotomesExpanded: false,
        thoracicMyotomesExpanded: false,
        lumbarMyotomesExpanded: false,
        sacralMyotomesExpanded: false,
        reflexExpanded: false,
        cranialNervesExpanded: false,
        reflexRightExpanded: false,
        neurodynamicsUpperLimbExpanded: false,
        neurodynamicsLowerLimbExpanded: false,
        disparateReflexRightSideObject: {}
    }

    constructor(props) {
        super(props);
        this.initializeTables();
        this.prefillTablesWithProps(props);

        this.props.assessment.dermatomes = this.state.dermatomes;
        this.props.assessment.myotomes = this.state.myotomes;
        this.props.assessment.reflexes = this.state.reflexes;
        this.props.assessment.neurodynamics = this.state.neurodynamics;
        this.props.assessment.reflexesExtra = this.state.reflexesExtra;

        this.customStyles = {
            option: (provided, state) => ({
                ...provided,
                textAlign:'left',
                whiteSpace:'nowrap',

            }),

            menu:(provided, state)=>({
                ...provided,
                position:'absolute',
                width:'100wv',

            }),
            menuList: (provided, state) => ({
                ...provided,


            }),


        }
    }


    componentDidMount() {
        this.UpdateExpandedTables();
    }

    UpdateExpandedTables = () => {
        const {dermatomes , myotomes , reflexes, neurodynamics, reflexesExtra} = this.state;

        this.expandOnHasValue(dermatomes.cervical, "cervicalExpanded");
        this.expandOnHasValue(dermatomes.thoracic, "thoracicExpanded");
        this.expandOnHasValue(dermatomes.lumbar, "lumbarExpanded");
        this.expandOnHasValue(dermatomes.sacral, "sacralExpanded");

        this.expandOnHasValue(myotomes.cervical, "cervicalMyotomesExpanded");
        this.expandOnHasValue(myotomes.thoracic, "thoracicMyotomesExpanded");
        this.expandOnHasValue(myotomes.lumbar, "lumbarMyotomesExpanded");
        this.expandOnHasValue(myotomes.sacral, "sacralMyotomesExpanded");

        this.expandOnHasValue(reflexes.reflexLeftSide, "reflexExpanded");
        this.expandOnHasValue(reflexes.cranialNerves, "cranialNervesExpanded");
        this.expandOnHasValue(reflexes.reflexRightSide, "reflexRightExpanded");
        this.expandOnHasValue(reflexesExtra.items , "reflexRightExpanded" );

        this.expandOnHasValue(neurodynamics.upperLimb, "neurodynamicsUpperLimbExpanded");
        this.expandOnHasValue(neurodynamics.lowerLimb, "neurodynamicsLowerLimbExpanded");

    }

    expandOnHasValue = (table, flagName) => {
        const expansionFlag = {
            [flagName]: true
        };
        for (const item of table) {
            if (item.id) {
                this.setState(expansionFlag);
                return;
            }
        }
    }


    prefillTablesWithProps = (props) => {
        this.copyValueToRelatedTable(props.assessment.dermatomes, this.state.dermatomes);
        this.state.dermatomes.id = props.assessment.dermatomes.id;
        this.state.dermatomes.none = props.assessment.dermatomes.none;

        this.copyValueToRelatedTable(props.assessment.myotomes, this.state.myotomes);
        this.state.myotomes.id = props.assessment.myotomes.id;
        this.state.myotomes.none = props.assessment.myotomes.none;

        this.copyValueToRelatedTable(props.assessment.reflexes, this.state.reflexes);
        this.state.reflexes.id = props.assessment.reflexes.id;
        this.state.reflexes.none = props.assessment.reflexes.none;

        if (props.assessment.reflexesExtra && props.assessment.reflexesExtra.items !== undefined && props.assessment.reflexesExtra.items !== null) {
            this.state.reflexesExtra.items = [...props.assessment.reflexesExtra.items];
            this.state.reflexesExtra.id = props.assessment.reflexesExtra.id
        }

        this.copyValueToRelatedTable(props.assessment.neurodynamics, this.state.neurodynamics);
        this.state.neurodynamics.id = props.assessment.neurodynamics.id;
    }

    copyValueToRelatedTable = (sourceProp, targetTable) => {
        if (sourceProp.items !== undefined && sourceProp.items !== null) {
            sourceProp.items.forEach(item => {
                let targetRow = null;
                for (let property in targetTable) {
                    targetRow = targetTable[property].find(row => row.name === item.name);
                    if (targetRow !== null && targetRow !== undefined) break;
                }
                this.copyPropertiesFromDtoToRowObject(targetRow, item);
            })
        }
    }

    copyPropertiesFromDtoToRowObject = (targetRow, item) => {
        targetRow.name = item.name;
        targetRow.rightValue = item.rightValue;
        targetRow.leftValue = item.leftValue;
        targetRow.rightBoolValue = item.rightBoolValue;
        targetRow.leftBoolValue = item.leftBoolValue;
        targetRow.details = item.details;
        targetRow.id = item.id;
        targetRow.version = item.version;
        targetRow.creationDate = item.creationDate;
    }

    initializeTables = () => {
        this.initializeDermatomesTable();
        this.initializeMyotomesTable();
        this.initializeReflexesTable();
        this.initializeNeurodynamicsTable();
    }

    initializeDermatomesTable = () => {
        let dermatomes = {};

        dermatomes.cervical = [];
        DermatomesCervicalEnum.toArray().map((rowValue) => {
            dermatomes.cervical.push({type: TypeEnum.CERVICAL, name: rowValue.key})
        })

        dermatomes.thoracic = [];
        DermatomesThoracicEnum.toArray().map((rowValue) => {
            dermatomes.thoracic.push({type: TypeEnum.THORACIC, name: rowValue.key})
        })

        dermatomes.sacral = [];
        DermatomesSacralEnum.toArray().map((rowValue) => {
            dermatomes.sacral.push({type: TypeEnum.SACRAL, name: rowValue.key})
        })

        dermatomes.lumbar = [];
        DermatomesLumbarEnum.toArray().map((rowValue) => {
            dermatomes.lumbar.push({type: TypeEnum.LUMBAR, name: rowValue.key})
        })

        this.state.dermatomes = {...dermatomes};
    }

    initializeMyotomesTable = () => {
        let myotomes = {};

        myotomes.cervical = [];
        MyotomesCervicalEnum.toArray().map((rowValue) => {
            myotomes.cervical.push({type: TypeEnum.CERVICAL, name: rowValue.key})
        })

        myotomes.thoracic = [];
        MyotomesThoracicEnum.toArray().map((rowValue) => {
            myotomes.thoracic.push({type: TypeEnum.THORACIC, name: rowValue.key})
        })

        myotomes.lumbar = [];
        MyotomesLumbarEnum.toArray().map((rowValue) => {
            myotomes.lumbar.push({type: TypeEnum.LUMBAR, name: rowValue.key})
        })

        myotomes.sacral = [];
        MyotomesSacralEnum.toArray().map((rowValue) => {
            myotomes.sacral.push({type: TypeEnum.SACRAL, name: rowValue.key})
        })

        this.state.myotomes = {...myotomes};
    }

    initializeReflexesTable = () => {
        let reflexes = {};
        let reflexesExtra = {};
        reflexesExtra.items = [];
        reflexesExtra.id = this.props.assessment.reflexesExtra &&
        this.props.assessment.reflexesExtra.id ? this.props.assessment.reflexesExtra.id : null;

        reflexes.reflexLeftSide = [];
        ReflexesLeftSideEnum.toArray().map((rowValue) => {
            reflexes.reflexLeftSide.push({type: TypeEnum.REFLEX_LEFT, name: rowValue.key})
        })

        reflexes.reflexRightSide = [];
        ReflexesRightSideEnum.toArray().map((rowValue) => {
            reflexes.reflexRightSide.push({type: TypeEnum.REFLEX_RIGHT, name: rowValue.key})
        })

        reflexes.cranialNerves = [];
        CranialNervesEnum.toArray().map((rowValue) => {
            reflexes.cranialNerves.push({type: TypeEnum.CRANIAL_NERVES, name: rowValue.key})
        })

        this.state.reflexesExtra = {...reflexesExtra};
        this.state.reflexes = {...reflexes};
    }

    initializeNeurodynamicsTable = () => {
        let neurodynamics = {};
        neurodynamics.upperLimb = [];
        NeurodynamicsUpperLimbEnum.toArray().map((rowValue) => {
            neurodynamics.upperLimb.push({type: TypeEnum.UPPER_LIMB, name: rowValue.key})
        })

        neurodynamics.lowerLimb = [];
        NeurodynamicsLowerLimbEnum.toArray().map((rowValue) => {
            neurodynamics.lowerLimb.push({type: TypeEnum.LOWER_LIMB, name: rowValue.key})
        })

        this.state.neurodynamics = {...neurodynamics};
    }

    collapseDermatomes = () => {
        this.setState({
            cervicalExpanded: false,
            thoracicExpanded: false,
            lumbarExpanded: false,
            sacralExpanded: false,
        });
    }

    collapseMyotomes = () => {
        this.setState({
            cervicalMyotomesExpanded: false,
            thoracicMyotomesExpanded: false,
            lumbarMyotomesExpanded: false,
            sacralMyotomesExpanded: false
        });
    }

    collapseReflexes = () => {
        this.setState({
            reflexExpanded: false,
            cranialNervesExpanded: false,
            reflexRightExpanded: false
        });
    }

    changeExpandedRow = (expandedRow) => {
        if (this.state[expandedRow] === true) {
            this.setState({[expandedRow]: false});
        } else {
            this.setState({
                cervicalExpanded: false,
                thoracicExpanded: false,
                lumbarExpanded: false,
                sacralExpanded: false,
                cervicalMyotomesExpanded: false,
                thoracicMyotomesExpanded: false,
                lumbarMyotomesExpanded: false,
                sacralMyotomesExpanded: false,
                reflexExpanded: false,
                cranialNervesExpanded: false,
                reflexRightExpanded: false,
                neurodynamicsUpperLimbExpanded: false,
                neurodynamicsLowerLimbExpanded: false,
                [expandedRow]: true,
            })
        }
    }

    clearDermatomes = () => {
        this.initializeDermatomesTable();
        let idOfTable = this.props.assessment.dermatomes.id ? this.props.assessment.dermatomes.id : null;
        this.props.assessment.dermatomes = this.state.dermatomes;
        this.props.assessment.dermatomes.id = idOfTable;
    }

    clearMyotomes = () => {
        this.initializeMyotomesTable();
        let idOfTable = this.props.assessment.myotomes.id ? this.props.assessment.myotomes.id : null;
        this.props.assessment.myotomes = this.state.myotomes;
        this.props.assessment.myotomes.id = idOfTable;
    }

    clearReflexes = () => {
        this.initializeReflexesTable();
        let idOfTable = this.props.assessment.reflexes.id ? this.props.assessment.reflexes.id : null;
        this.props.assessment.reflexes = this.state.reflexes;
        this.props.assessment.reflexes.id = idOfTable;

        this.state.reflexesExtra.items = [];
        this.props.assessment.reflexesExtra.items = [];
    }

    render() {
        const {dermatomes, myotomes, reflexes} = this.props.assessment;
        const isDermatomesNone = dermatomes.none || false;
        const isMyotomesNone = myotomes.none || false;
        const isReflexesNone = reflexes.none || false;

        return (
            <div className={"emr-modal content-row emr-background"} style={{width: "100%"}}>
                <div className="col-7">

                        <div style={{display: "flex"}}>
                            <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Dermatomes <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                            <GreenSwitchWithMessage
                                message={this.state.dermatomes.none ? "Normal" : null}
                                checked={DermatomesUtil.isDermatomesEntered(this.state.dermatomes)}
                                onChange={(e) => {
                                    if (!e.target.checked) {
                                        this.clearDermatomes();
                                        this.forceUpdate();
                                    }
                                }}/>
                        </div>

                        <div className={"row m-0"}>
                            <div style={{display: "flex", marginLeft: "5px"}}>
                                <div>
                                    <CheckboxInput value={isDermatomesNone}
                                                   onChange={(checked) => {
                                                       if (this.state.dermatomes == null) {
                                                           this.state.dermatomes = {};
                                                       }
                                                       if (checked) {
                                                           this.clearDermatomes();
                                                           this.collapseDermatomes();
                                                       }
                                                       this.state.dermatomes.none = checked;
                                                       this.props.assessment.dermatomes.none = checked;
                                                       this.forceUpdate();
                                                   }}/>
                                </div>
                                <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Normal</div>
                            </div>
                        </div>

                        <table
                            className={`emr-table content-card text-align-left height-unset ${isDermatomesNone ? 'not-allowed disabled-pointer' : ''}`}>
                            <thead>
                            <tr style={{background: DermatomesScreenRowColorsEnum.DERMATOMESCERVICAL.color}}>
                                <th style={{width: '150px'}}>Cervical</th>
                                <th style={{width: '120px'}}>Right</th>
                                <th style={{width: '120px'}}>Left</th>
                                <th style={{width: '190px'}}>
                                    <FontAwesomeIcon
                                        icon={["fa", `chevron-${this.state.cervicalExpanded ? 'down' : 'right'}`]}
                                        onClick={() => this.changeExpandedRow("cervicalExpanded")}
                                        style={{marginTop: "8px"}}
                                    />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.cervicalExpanded && (
                                this.state.dermatomes.cervical.map((rowValue, index) => {
                                    return (
                                        <tr key={`dermatomesCervical${index}`}>
                                            <th>{DermatomesCervicalEnum[rowValue.name].value}</th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    styles={this.customStyles}
                                                    classNamePrefix={"emr-white-select"}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={DermatomesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.rightValue != null ?
                                                        {
                                                            value: DermatomesDropdownValues[rowValue.rightValue].key,
                                                            label: DermatomesDropdownValues[rowValue.rightValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.dermatomes.cervical[index];
                                                            target.rightValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    styles={this.customStyles}
                                                    classNamePrefix={"emr-white-select"}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={DermatomesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.leftValue != null ?
                                                        {
                                                            value: DermatomesDropdownValues[rowValue.leftValue].key,
                                                            label: DermatomesDropdownValues[rowValue.leftValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.dermatomes.cervical[index];
                                                            target.leftValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th colSpan={2}>
                                                <StatefulTextInput
                                                    value={rowValue.details || ''}
                                                    onChange={value => {
                                                        const target = this.state.dermatomes.cervical[index];
                                                        if (target.details === undefined || target.details === null || target.details.length === 0 || value.length === 0) {
                                                            this.setState((state) => {
                                                                target.details = value;
                                                                return state;
                                                            })
                                                        }
                                                        target.details = value;
                                                    }}
                                                    placeholder={"Details"}
                                                    style={{width: '-webkit-fill-available'}}
                                                />
                                            </th>
                                        </tr>
                                    )
                                }))
                            }
                            </tbody>

                            <thead>
                            <tr style={{background: DermatomesScreenRowColorsEnum.DERMATOMESTHORACIC.color}}>
                                <th style={{width: '150px'}}>Thoracic</th>
                                <th style={{width: '120px'}}>Right</th>
                                <th style={{width: '120px'}}>Left</th>
                                <th style={{width: '190px'}}>
                                    <FontAwesomeIcon
                                        icon={["fa", `chevron-${this.state.thoracicExpanded ? 'down' : 'right'}`]}
                                        onClick={() => this.changeExpandedRow("thoracicExpanded")}
                                        style={{marginTop: "8px"}}
                                    />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.thoracicExpanded && (
                                this.state.dermatomes.thoracic.map((rowValue, index) => {
                                    return (
                                        <tr key={`dermatomesCervical${index}`}>
                                            <th>{DermatomesThoracicEnum[rowValue.name].value}</th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={DermatomesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.rightValue != null ?
                                                        {
                                                            value: DermatomesDropdownValues[rowValue.rightValue].key,
                                                            label: DermatomesDropdownValues[rowValue.rightValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.dermatomes.thoracic[index];
                                                            target.rightValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={DermatomesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.leftValue != null ?
                                                        {
                                                            value: DermatomesDropdownValues[rowValue.leftValue].key,
                                                            label: DermatomesDropdownValues[rowValue.leftValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.dermatomes.thoracic[index];
                                                            target.leftValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th colSpan={2}>
                                                <StatefulTextInput
                                                    value={rowValue.details || ''}
                                                    onChange={value => {
                                                        const target = this.state.dermatomes.thoracic[index];
                                                        if (target.details === undefined || target.details === null || target.details.length === 0 || value.length === 0) {
                                                            this.setState((state) => {
                                                                target.details = value;
                                                                return state;
                                                            })
                                                        }
                                                        target.details = value;
                                                    }}
                                                    placeholder={"Details"}
                                                    style={{width: '-webkit-fill-available'}}
                                                />
                                            </th>
                                        </tr>
                                    )
                                }))
                            }
                            </tbody>

                            <thead>
                            <tr style={{background: DermatomesScreenRowColorsEnum.DERMATOMESLUMBAR.color}}>
                                <th style={{width: '150px'}}>Lumbar</th>
                                <th style={{width: '120px'}}>Right</th>
                                <th style={{width: '120px'}}>Left</th>
                                <th style={{width: '190px'}}>
                                    <FontAwesomeIcon
                                        icon={["fa", `chevron-${this.state.lumbarExpanded ? 'down' : 'right'}`]}
                                        onClick={() => this.changeExpandedRow("lumbarExpanded")}
                                        style={{marginTop: "8px"}}
                                    />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.lumbarExpanded && (
                                this.state.dermatomes.lumbar.map((rowValue, index) => {
                                    return (
                                        <tr key={`dermatomesLumbar${index}`}>
                                            <th>{DermatomesLumbarEnum[rowValue.name].value}</th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={DermatomesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.rightValue != null ?
                                                        {
                                                            value: DermatomesDropdownValues[rowValue.rightValue].key,
                                                            label: DermatomesDropdownValues[rowValue.rightValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.dermatomes.lumbar[index];
                                                            target.rightValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={DermatomesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.leftValue != null ?
                                                        {
                                                            value: DermatomesDropdownValues[rowValue.leftValue].key,
                                                            label: DermatomesDropdownValues[rowValue.leftValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.dermatomes.lumbar[index];
                                                            target.leftValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th colSpan={2}>
                                                <StatefulTextInput
                                                    value={rowValue.details || ''}
                                                    onChange={value => {
                                                        const target = this.state.dermatomes.lumbar[index];
                                                        if (target.details === undefined || target.details === null || target.details.length === 0 || value.length === 0) {
                                                            this.setState((state) => {
                                                                target.details = value;
                                                                return state;
                                                            })
                                                        }
                                                        target.details = value;
                                                    }}
                                                    placeholder={"Details"}
                                                    style={{width: '-webkit-fill-available'}}
                                                />
                                            </th>
                                        </tr>
                                    )
                                }))
                            }
                            </tbody>

                            <thead>
                            <tr style={{background: DermatomesScreenRowColorsEnum.DERMATOMESSACRAL.color}}>
                                <th style={{width: '150px'}}>Sacral</th>
                                <th style={{width: '120px'}}>Right</th>
                                <th style={{width: '120px'}}>Left</th>
                                <th style={{width: '190px'}}>
                                    <FontAwesomeIcon
                                        icon={["fa", `chevron-${this.state.sacralExpanded ? 'down' : 'right'}`]}
                                        onClick={() => this.changeExpandedRow("sacralExpanded")}
                                        style={{marginTop: "8px"}}
                                    />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.sacralExpanded && (
                                this.state.dermatomes.sacral.map((rowValue, index) => {
                                    return (
                                        <tr key={`dermatomesSacral${index}`}>
                                            <th>{DermatomesSacralEnum[rowValue.name].value}</th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={DermatomesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.rightValue != null ?
                                                        {
                                                            value: DermatomesDropdownValues[rowValue.rightValue].key,
                                                            label: DermatomesDropdownValues[rowValue.rightValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.dermatomes.sacral[index];
                                                            target.rightValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={DermatomesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.leftValue != null ?
                                                        {
                                                            value: DermatomesDropdownValues[rowValue.leftValue].key,
                                                            label: DermatomesDropdownValues[rowValue.leftValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.dermatomes.sacral[index];
                                                            target.leftValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th colSpan={2}>
                                                <StatefulTextInput
                                                    value={rowValue.details || ''}
                                                    onChange={value => {
                                                        const target = this.state.dermatomes.sacral[index];
                                                        if (target.details === undefined || target.details === null || target.details.length === 0 || value.length === 0) {
                                                            this.setState((state) => {
                                                                target.details = value;
                                                                return state;
                                                            })
                                                        }
                                                        target.details = value;
                                                    }}
                                                    placeholder={"Details"}
                                                    style={{width: '-webkit-fill-available'}}
                                                />
                                            </th>
                                        </tr>
                                    )
                                }))
                            }
                            </tbody>
                        </table>

                        <div style={{display: "flex", marginTop: "20px"}}>
                            <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Myotomes <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                            <GreenSwitchWithMessage
                                message={this.state.myotomes.none ? "Normal" : null}
                                checked={DermatomesUtil.isMyotomesEntered(this.state.myotomes)}
                                onChange={(e) => {
                                    if (!e.target.checked) {
                                        this.clearMyotomes();
                                        this.forceUpdate();
                                    }
                                }}/>
                        </div>

                        <div className={"row m-0"}>
                            <div style={{display: "flex", marginLeft: "5px"}}>
                                <div>
                                    <CheckboxInput value={isMyotomesNone}
                                                   onChange={(value) => {
                                                       if (this.state.myotomes == null) {
                                                           this.state.myotomes = {};
                                                       }
                                                       if (value) {
                                                           this.clearMyotomes();
                                                           this.collapseMyotomes();
                                                       }
                                                       this.state.myotomes.none = value;
                                                       this.props.assessment.myotomes.none = value;
                                                       this.forceUpdate();
                                                   }}/>
                                </div>
                                <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Normal</div>
                            </div>
                        </div>

                        <table
                            className={`emr-table content-card text-align-left height-unset ${isMyotomesNone ? 'not-allowed disabled-pointer' : ''}`}>
                            <thead>
                            <tr style={{background: DermatomesScreenRowColorsEnum.MYOTOMESCERVICAL.color}}>
                                <th style={{width: '150px'}}>Cervical</th>
                                <th style={{width: '120px'}}>Right</th>
                                <th style={{width: '120px'}}>Left</th>
                                <th style={{width: '190px'}}>
                                    <FontAwesomeIcon
                                        icon={["fa", `chevron-${this.state.cervicalMyotomesExpanded ? 'down' : 'right'}`]}
                                        onClick={() => this.changeExpandedRow("cervicalMyotomesExpanded")}
                                        style={{marginTop: "8px"}}
                                    />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.cervicalMyotomesExpanded && (
                                this.state.myotomes.cervical.map((rowValue, index) => {
                                    return (
                                        <tr key={`myotomesCervical${index}`}>
                                            <th>{MyotomesCervicalEnum[rowValue.name].value}</th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={MyotomesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.rightValue != null ?
                                                        {
                                                            value: MyotomesDropdownValues[rowValue.rightValue].key,
                                                            label: MyotomesDropdownValues[rowValue.rightValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.myotomes.cervical[index];
                                                            target.rightValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={MyotomesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.leftValue != null ?
                                                        {
                                                            value: MyotomesDropdownValues[rowValue.leftValue].key,
                                                            label: MyotomesDropdownValues[rowValue.leftValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.myotomes.cervical[index];
                                                            target.leftValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th colSpan={2}>
                                                <StatefulTextInput
                                                    value={rowValue.details || ''}
                                                    onChange={value => {
                                                        const target = this.state.myotomes.cervical[index];
                                                        if (target.details === undefined || target.details === null || target.details.length === 0 || value.length === 0) {
                                                            this.setState((state) => {
                                                                target.details = value;
                                                                return state;
                                                            })
                                                        }
                                                        target.details = value;
                                                    }}
                                                    placeholder={"Details"}
                                                    style={{width: '-webkit-fill-available'}}
                                                />
                                            </th>
                                        </tr>
                                    )
                                }))
                            }
                            </tbody>

                            <thead>
                            <tr style={{background: DermatomesScreenRowColorsEnum.MYOTOMESTHORACIC.color}}>
                                <th style={{width: '150px'}}>Thoracic</th>
                                <th style={{width: '120px'}}>Right</th>
                                <th style={{width: '120px'}}>Left</th>
                                <th style={{width: '190px'}}>
                                    <FontAwesomeIcon
                                        icon={["fa", `chevron-${this.state.thoracicMyotomesExpanded ? 'down' : 'right'}`]}
                                        onClick={() => this.changeExpandedRow("thoracicMyotomesExpanded")}
                                        style={{marginTop: "8px"}}
                                    />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.thoracicMyotomesExpanded && (
                                this.state.myotomes.thoracic.map((rowValue, index) => {
                                    return (
                                        <tr key={`myotomesThoracic${index}`}>
                                            <th>{MyotomesThoracicEnum[rowValue.name].value}</th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={MyotomesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.rightValue != null ?
                                                        {
                                                            value: MyotomesDropdownValues[rowValue.rightValue].key,
                                                            label: MyotomesDropdownValues[rowValue.rightValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.myotomes.thoracic[index];
                                                            target.rightValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={MyotomesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.leftValue != null ?
                                                        {
                                                            value: MyotomesDropdownValues[rowValue.leftValue].key,
                                                            label: MyotomesDropdownValues[rowValue.leftValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.myotomes.thoracic[index];
                                                            target.leftValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th colSpan={2}>
                                                <StatefulTextInput
                                                    value={rowValue.details || ''}
                                                    onChange={value => {
                                                        const target = this.state.myotomes.thoracic[index];
                                                        if (target.details === undefined || target.details === null || target.details.length === 0 || value.length === 0) {
                                                            this.setState((state) => {
                                                                target.details = value;
                                                                return state;
                                                            })
                                                        }
                                                        target.details = value;
                                                    }}
                                                    placeholder={"Details"}
                                                    style={{width: '-webkit-fill-available'}}
                                                />
                                            </th>
                                        </tr>
                                    )
                                }))
                            }
                            </tbody>

                            <thead>
                            <tr style={{background: DermatomesScreenRowColorsEnum.MYOTOMESLUMBAR.color}}>
                                <th style={{width: '150px'}}>Lumbar</th>
                                <th style={{width: '120px'}}>Right</th>
                                <th style={{width: '120px'}}>Left</th>
                                <th style={{width: '190px'}}>
                                    <FontAwesomeIcon
                                        icon={["fa", `chevron-${this.state.lumbarMyotomesExpanded ? 'down' : 'right'}`]}
                                        onClick={() => this.changeExpandedRow("lumbarMyotomesExpanded")}
                                        style={{marginTop: "8px"}}
                                    />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.lumbarMyotomesExpanded && (
                                this.state.myotomes.lumbar.map((rowValue, index) => {
                                    return (
                                        <tr key={`myotomesLumbar${index}`}>
                                            <th>{MyotomesLumbarEnum[rowValue.name].value}</th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={MyotomesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.rightValue != null ?
                                                        {
                                                            value: MyotomesDropdownValues[rowValue.rightValue].key,
                                                            label: MyotomesDropdownValues[rowValue.rightValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.myotomes.lumbar[index];
                                                            target.rightValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={MyotomesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.leftValue != null ?
                                                        {
                                                            value: MyotomesDropdownValues[rowValue.leftValue].key,
                                                            label: MyotomesDropdownValues[rowValue.leftValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.myotomes.lumbar[index];
                                                            target.leftValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th colSpan={2}>
                                                <StatefulTextInput
                                                    value={rowValue.details || ''}
                                                    onChange={value => {
                                                        const target = this.state.myotomes.lumbar[index];
                                                        if (target.details === undefined || target.details === null || target.details.length === 0 || value.length === 0) {
                                                            this.setState((state) => {
                                                                target.details = value;
                                                                return state;
                                                            })
                                                        }
                                                        target.details = value;
                                                    }}
                                                    placeholder={"Details"}
                                                    style={{width: '-webkit-fill-available'}}
                                                />
                                            </th>
                                        </tr>
                                    )
                                }))
                            }
                            </tbody>

                            <thead>
                            <tr style={{background: DermatomesScreenRowColorsEnum.MYOTOMESSACRAL.color}}>
                                <th style={{width: '150px'}}>Sacral</th>
                                <th style={{width: '120px'}}>Right</th>
                                <th style={{width: '120px'}}>Left</th>
                                <th style={{width: '190px'}}>
                                    <FontAwesomeIcon
                                        icon={["fa", `chevron-${this.state.sacralMyotomesExpanded ? 'down' : 'right'}`]}
                                        onClick={() => this.changeExpandedRow("sacralMyotomesExpanded")}
                                        style={{marginTop: "8px"}}
                                    />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.sacralMyotomesExpanded && (
                                this.state.myotomes.sacral.map((rowValue, index) => {
                                    return (
                                        <tr key={`myotomesSacral${index}`}>
                                            <th>{MyotomesSacralEnum[rowValue.name].value}</th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={MyotomesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.rightValue != null ?
                                                        {
                                                            value: MyotomesDropdownValues[rowValue.rightValue].key,
                                                            label: MyotomesDropdownValues[rowValue.rightValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.myotomes.sacral[index];
                                                            target.rightValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={MyotomesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.leftValue != null ?
                                                        {
                                                            value: MyotomesDropdownValues[rowValue.leftValue].key,
                                                            label: MyotomesDropdownValues[rowValue.leftValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.myotomes.sacral[index];
                                                            target.leftValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th colSpan={2}>
                                                <StatefulTextInput
                                                    value={rowValue.details || ''}
                                                    onChange={value => {
                                                        const target = this.state.myotomes.sacral[index];
                                                        if (target.details === undefined || target.details === null || target.details.length === 0 || value.length === 0) {
                                                            this.setState((state) => {
                                                                target.details = value;
                                                                return state;
                                                            })
                                                        }
                                                        target.details = value;
                                                    }}
                                                    placeholder={"Details"}
                                                    style={{width: '-webkit-fill-available'}}
                                                />
                                            </th>
                                        </tr>
                                    )
                                }))
                            }
                            </tbody>
                        </table>

                        <div style={{display: "flex", marginTop: "20px"}}>
                            <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Reflexes <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                            <GreenSwitchWithMessage
                                message={this.state.reflexes.none ? "Normal" : null}
                                checked={DermatomesUtil.isReflexesEntered(this.state.reflexes)}
                                onChange={(e) => {
                                    if (!e.target.checked) {
                                        this.clearReflexes();
                                        this.forceUpdate();
                                    }
                                }}/>
                        </div>

                        <div className={"row m-0"}>
                            <div style={{display: "flex", marginLeft: "5px"}}>
                                <div>
                                    <CheckboxInput value={isReflexesNone}
                                                   onChange={(value) => {
                                                       if (this.state.reflexes == null) {
                                                           this.state.reflexes = {};
                                                       }
                                                       if (this.state.reflexesExtra == null) {
                                                           this.state.reflexesExtra = {};
                                                       }
                                                       if (value) {
                                                           this.clearReflexes();
                                                           this.collapseReflexes();
                                                       }
                                                       this.state.reflexes.none = value;
                                                       this.props.assessment.reflexes.none = value;
                                                       this.forceUpdate();
                                                   }}/>
                                </div>
                                <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Normal</div>
                            </div>
                        </div>

                        <table
                            className={`emr-table content-card text-align-left height-unset ${isReflexesNone ? 'not-allowed disabled-pointer' : ''}`}>
                            <thead>
                            <tr style={{background: DermatomesScreenRowColorsEnum.REFLEXLEFTSIDE.color}}>
                                <th style={{width: '150px'}}>Reflex</th>
                                <th style={{width: '120px'}}>Right</th>
                                <th style={{width: '120px'}}>Left</th>
                                <th style={{width: '190px'}}>
                                    <FontAwesomeIcon
                                        icon={["fa", `chevron-${this.state.reflexExpanded ? 'down' : 'right'}`]}
                                        onClick={() => this.changeExpandedRow("reflexExpanded")}
                                        style={{marginTop: "8px"}}
                                    />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.reflexExpanded && (
                                this.state.reflexes.reflexLeftSide.map((rowValue, index) => {
                                    return (
                                        <tr key={`reflexLeftSide${index}`}>
                                            <th>{ReflexesLeftSideEnum[rowValue.name].value}</th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={ReflexesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.rightValue != null ?
                                                        {
                                                            value: ReflexesDropdownValues[rowValue.rightValue].key,
                                                            label: ReflexesDropdownValues[rowValue.rightValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.reflexes.reflexLeftSide[index];
                                                            target.rightValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={ReflexesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.leftValue != null ?
                                                        {
                                                            value: ReflexesDropdownValues[rowValue.leftValue].key,
                                                            label: ReflexesDropdownValues[rowValue.leftValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.reflexes.reflexLeftSide[index];
                                                            target.leftValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th colSpan={2}>
                                                <StatefulTextInput
                                                    value={rowValue.details || ''}
                                                    onChange={value => {
                                                        const target = this.state.reflexes.reflexLeftSide[index];
                                                        if (target.details === undefined || target.details === null || target.details.length === 0 || value.length === 0) {
                                                            this.setState((state) => {
                                                                target.details = value;
                                                                return state;
                                                            })
                                                        }
                                                        target.details = value;
                                                    }}
                                                    placeholder={"Details"}
                                                    style={{width: '-webkit-fill-available'}}
                                                />
                                            </th>
                                        </tr>
                                    )
                                }))
                            }
                            </tbody>

                            <thead>
                            <tr style={{background: DermatomesScreenRowColorsEnum.CARNIALNERVES.color}}>
                                <th style={{width: '150px'}}>Cranial Nerves</th>
                                <th colSpan={2}>Result</th>
                                <th>
                                    <FontAwesomeIcon
                                        icon={["fa", `chevron-${this.state.cranialNervesExpanded ? 'down' : 'right'}`]}
                                        onClick={() => this.changeExpandedRow("cranialNervesExpanded")}
                                        style={{marginTop: "8px"}}
                                    />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.cranialNervesExpanded && (
                                this.state.reflexes.cranialNerves.map((rowValue, index) => {
                                    return (
                                        <tr key={`cranialNerves${index}`}>
                                            <th>{CranialNervesEnum[rowValue.name].value}</th>
                                            <th colSpan={2}>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={CranialNervesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.rightValue != null ?
                                                        {
                                                            value: CranialNervesDropdownValues[rowValue.rightValue].key,
                                                            label: CranialNervesDropdownValues[rowValue.rightValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.reflexes.cranialNerves[index];
                                                            target.rightValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th>
                                                <StatefulTextInput
                                                    value={rowValue.details || ''}
                                                    onChange={value => {
                                                        const target = this.state.reflexes.cranialNerves[index];
                                                        if (target.details === undefined || target.details === null || target.details.length === 0 || value.length === 0) {
                                                            this.setState((state) => {
                                                                target.details = value;
                                                                return state;
                                                            })
                                                        }
                                                        target.details = value;
                                                    }}
                                                    placeholder={"Details"}
                                                    style={{width: '-webkit-fill-available'}}
                                                />
                                            </th>
                                        </tr>
                                    )
                                }))
                            }
                            </tbody>

                            <thead>
                            <tr style={{background: DermatomesScreenRowColorsEnum.REFLEXRIGHTSIDE.color}}>
                                <th style={{width: '150px'}}>Reflex</th>
                                <th style={{width: '120px'}}>Right</th>
                                <th style={{width: '120px'}}>Left</th>
                                <th style={{width: '190px'}}>
                                    <FontAwesomeIcon
                                        icon={["fa", `chevron-${this.state.reflexRightExpanded ? 'down' : 'right'}`]}
                                        onClick={() => this.changeExpandedRow("reflexRightExpanded")}
                                        style={{marginTop: "8px"}}
                                    />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.reflexRightExpanded && (
                                this.state.reflexes.reflexRightSide.map((rowValue, index) => {
                                    return (
                                        <tr key={`reflexRightSide${index}`}>
                                            <th>{ReflexesRightSideEnum[rowValue.name].value}</th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={ReflexesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.rightValue != null ?
                                                        {
                                                            value: ReflexesDropdownValues[rowValue.rightValue].key,
                                                            label: ReflexesDropdownValues[rowValue.rightValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.reflexes.reflexRightSide[index];
                                                            target.rightValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={ReflexesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.leftValue != null ?
                                                        {
                                                            value: ReflexesDropdownValues[rowValue.leftValue].key,
                                                            label: ReflexesDropdownValues[rowValue.leftValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.reflexes.reflexRightSide[index];
                                                            target.leftValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th colSpan={2}>
                                                <StatefulTextInput
                                                    value={rowValue.details || ''}
                                                    onChange={value => {
                                                        const target = this.state.reflexes.reflexRightSide[index];
                                                        if (target.details === undefined || target.details === null || target.details.length === 0 || value.length === 0) {
                                                            this.setState((state) => {
                                                                target.details = value;
                                                                return state;
                                                            })
                                                        }
                                                        target.details = value;
                                                    }}
                                                    placeholder={"Details"}
                                                    style={{width: '-webkit-fill-available'}}
                                                />
                                            </th>
                                        </tr>
                                    )
                                })
                            )
                            }

                            {this.state.reflexRightExpanded && this.state.reflexesExtra && (
                                this.state.reflexesExtra.items.map((rowValue, index) => {
                                    return (
                                        <tr key={`reflexExtra${index}`}>
                                            <th>{rowValue.name}</th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={ReflexesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.rightValue != null ?
                                                        {
                                                            value: ReflexesDropdownValues[rowValue.rightValue].key,
                                                            label: ReflexesDropdownValues[rowValue.rightValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.reflexesExtra.items[index];
                                                            target.rightValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th>
                                                <Select
                                                    className={"emr-white-select-container"}
                                                    classNamePrefix={"emr-white-select"}
                                                    styles={this.customStyles}
                                                    isClearable={true}
                                                    placeholder={""}
                                                    options={ReflexesDropdownValues.toArray().map(v => {
                                                            return {value: v.key, label: v.name}
                                                        }
                                                    )}
                                                    value={rowValue.leftValue != null ?
                                                        {
                                                            value: ReflexesDropdownValues[rowValue.leftValue].key,
                                                            label: ReflexesDropdownValues[rowValue.leftValue].name
                                                        } : null}
                                                    onChange={(event) => {
                                                        let r = event == null ? null : event.value;
                                                        this.setState((state) => {
                                                            const target = state.reflexesExtra.items[index];
                                                            target.leftValue = r;
                                                            return state;
                                                        });
                                                    }}
                                                />
                                            </th>
                                            <th colSpan={2}>
                                                <StatefulTextInput
                                                    value={rowValue.details || ''}
                                                    onChange={value => {
                                                        const target = this.state.reflexesExtra.items[index];
                                                        if (target.details === undefined || target.details === null || target.details.length === 0 || value.length === 0) {
                                                            this.setState((state) => {
                                                                target.details = value;
                                                                return state;
                                                            })
                                                        }
                                                        target.details = value;
                                                    }}
                                                    placeholder={"Details"}
                                                    style={{width: '-webkit-fill-available'}}
                                                />
                                            </th>
                                        </tr>
                                    )
                                })
                            )
                            }

                            {this.state.reflexRightExpanded && (
                                <tr style={{backgroundColor: "white"}}>
                                    <td style={{minWidth: "80px", padding: "5px"}}>
                                        <StatefulTextInput
                                            id={"disparateReflexRightSideObject"}
                                            value={this.state.disparateReflexRightSideObject.name || ''}
                                            onChange={value => {
                                                this.state.disparateReflexRightSideObject.name = value;
                                            }}
                                            placeholder={"Details"}
                                            style={{width: '-webkit-fill-available'}}
                                        />
                                    </td>
                                    <td style={{padding: "5px"}}>
                                        <Select
                                            className={"emr-white-select-container"}
                                            classNamePrefix={"emr-white-select"}
                                            styles={this.customStyles}
                                            isClearable={true}
                                            placeholder={""}
                                            options={ReflexesDropdownValues.toArray().map(v => {
                                                    return {value: v.key, label: v.name}
                                                }
                                            )}
                                            value={this.state.disparateReflexRightSideObject.rightValue != null ?
                                                {
                                                    value: ReflexesDropdownValues[this.state.disparateReflexRightSideObject.rightValue].key,
                                                    label: ReflexesDropdownValues[this.state.disparateReflexRightSideObject.rightValue].name
                                                } : null}
                                            onChange={(event) => {
                                                let r = event == null ? null : event.value;
                                                this.setState((state) => {
                                                    this.state.disparateReflexRightSideObject.rightValue = r;
                                                    return state;
                                                });
                                            }}
                                        />
                                    </td>
                                    <td style={{padding: "5px"}}>
                                        <Select
                                            className={"emr-white-select-container"}
                                            classNamePrefix={"emr-white-select"}
                                            styles={this.customStyles}
                                            isClearable={true}
                                            placeholder={""}
                                            options={ReflexesDropdownValues.toArray().map(v => {
                                                    return {value: v.key, label: v.name}
                                                }
                                            )}
                                            value={this.state.disparateReflexRightSideObject.leftValue != null ?
                                                {
                                                    value: ReflexesDropdownValues[this.state.disparateReflexRightSideObject.leftValue].key,
                                                    label: ReflexesDropdownValues[this.state.disparateReflexRightSideObject.leftValue].name
                                                } : null}
                                            onChange={(event) => {
                                                let r = event == null ? null : event.value;
                                                this.setState((state) => {
                                                    this.state.disparateReflexRightSideObject.leftValue = r;
                                                    return state;
                                                });
                                            }}
                                        />
                                    </td>
                                    <td className="position-relative p-1">
                                        <div className="emr-round-button-middle-right-add-wrapper"
                                             onClick={() => {
                                                 if (this.state.disparateReflexRightSideObject.name && this.state.disparateReflexRightSideObject.name.length !== 0) {
                                                     this.setState(state => {
                                                         this.state.disparateReflexRightSideObject.key = this.state.disparateReflexRightSideObject.name.replace(/\s+/g, '');
                                                         state.reflexesExtra.items.push(this.state.disparateReflexRightSideObject);
                                                         return state;
                                                     }, () => {
                                                         this.setState({disparateReflexRightSideObject: {}})
                                                     })
                                                 }
                                             }}>
                                            <div className="emr-round-button plus">+</div>
                                        </div>
                                        <div className="w-full-20">
                                            <StatefulTextInput
                                                id={"disparateReflexRightSideObjectDetails"}
                                                value={this.state.disparateReflexRightSideObject.details || ''}
                                                onChange={value => {
                                                    this.state.disparateReflexRightSideObject.details = value;
                                                }}
                                                placeholder={"Details"}
                                                style={{width: '-webkit-fill-available'}}
                                            />
                                        </div>
                                    </td>
                                </tr>)}
                            </tbody>
                        </table>


                    <div className={"content-col-12"}>

                        <div style={{display: "flex", marginTop: "20px"}}>
                            <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Neurodynamics
                            </div>
                            <GreenSwitchWithMessage
                                checked={DermatomesUtil.isNeuroDynamicsEntered(this.state.neurodynamics)}
                                onChange={(e) => {
                                    if (!e.target.checked) {
                                        this.initializeNeurodynamicsTable();
                                        let idOfTable = this.props.assessment.neurodynamics.id ? this.props.assessment.neurodynamics.id : null;
                                        this.props.assessment.neurodynamics = this.state.neurodynamics;
                                        this.props.assessment.neurodynamics.id = idOfTable;
                                        this.forceUpdate();
                                    }
                                }}/>
                        </div>

                        <table className={"emr-table content-card text-align-left"} style={{height: 'unset'}}>
                            <thead>
                            <tr style={{background: DermatomesScreenRowColorsEnum.NEURODYNAMICSUPPERLIMB.color}}>
                                <th style={{width: '150px'}}>Upper Limb</th>
                                <th style={{width: '90px'}}>Right</th>
                                <th style={{width: '90px'}}>Left</th>
                                <th style={{width: '190px'}}>
                                    <FontAwesomeIcon
                                        icon={["fa", `chevron-${this.state.neurodynamicsUpperLimbExpanded ? 'down' : 'right'}`]}
                                        onClick={() => this.changeExpandedRow("neurodynamicsUpperLimbExpanded")}
                                        style={{marginTop: "8px"}}
                                    />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.neurodynamicsUpperLimbExpanded && (
                                this.state.neurodynamics.upperLimb.map((rowValue, index) => {
                                    return (
                                        <tr key={`upperLimb${index}`}>
                                            <th>{NeurodynamicsUpperLimbEnum[rowValue.name].value}</th>
                                            <th style={{position: "relative", minWidth: "120px"}}>
                                                <PosNegRadioInput value={rowValue.rightBoolValue}
                                                                  onChange={(value) => {
                                                                      this.setState((state) => {
                                                                          const target = state.neurodynamics.upperLimb[index];
                                                                          target.rightBoolValue = value;
                                                                          return state;
                                                                      })
                                                                  }}/>
                                            </th>
                                            <th style={{position: "relative", minWidth: "120px"}}>
                                                <PosNegRadioInput value={rowValue.leftBoolValue}
                                                                  onChange={(value) => {
                                                                      this.setState((state) => {
                                                                          const target = state.neurodynamics.upperLimb[index];
                                                                          target.leftBoolValue = value;
                                                                          return state;
                                                                      })
                                                                  }}/>
                                            </th>
                                            <th colSpan={2}>
                                                <StatefulTextInput
                                                    value={rowValue.details || ''}
                                                    onChange={value => {
                                                        const target = this.state.neurodynamics.upperLimb[index];
                                                        if (target.details === undefined || target.details === null || target.details.length === 0 || value.length === 0) {
                                                            this.setState((state) => {
                                                                target.details = value;
                                                                return state;
                                                            })
                                                        }
                                                        target.details = value;
                                                    }}
                                                    placeholder={"Details"}
                                                    style={{width: '-webkit-fill-available'}}
                                                />
                                            </th>
                                        </tr>
                                    )
                                }))
                            }
                            </tbody>

                            <thead>
                            <tr style={{background: DermatomesScreenRowColorsEnum.NEURODYNAMICSLOWERLIMB.color}}>
                                <th style={{width: '150px'}}>Lower Limb</th>
                                <th style={{width: '90px'}}>Right</th>
                                <th style={{width: '90px'}}>Left</th>
                                <th style={{width: '190px'}}>
                                    <FontAwesomeIcon
                                        icon={["fa", `chevron-${this.state.neurodynamicsLowerLimbExpanded ? 'down' : 'right'}`]}
                                        onClick={() => this.changeExpandedRow("neurodynamicsLowerLimbExpanded")}
                                        style={{marginTop: "8px"}}
                                    />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.neurodynamicsLowerLimbExpanded && (
                                this.state.neurodynamics.lowerLimb.map((rowValue, index) => {
                                    return (
                                        <tr key={`lowerLimb${index}`}>
                                            <th>{NeurodynamicsLowerLimbEnum[rowValue.name].value}</th>
                                            <th style={{position: "relative", minWidth: "120px"}}>
                                                <PosNegRadioInput value={rowValue.rightBoolValue}
                                                                  onChange={(value) => {
                                                                      this.setState((state) => {
                                                                          const target = state.neurodynamics.lowerLimb[index];
                                                                          target.rightBoolValue = value;
                                                                          return state;
                                                                      })
                                                                  }}/>
                                            </th>
                                            <th style={{position: "relative", minWidth: "120px"}}>
                                                <PosNegRadioInput value={rowValue.leftBoolValue}
                                                                  onChange={(value) => {
                                                                      this.setState((state) => {
                                                                          const target = state.neurodynamics.lowerLimb[index];
                                                                          target.leftBoolValue = value;
                                                                          return state;
                                                                      })
                                                                  }}/>
                                            </th>
                                            <th colSpan={2}>
                                                <StatefulTextInput
                                                    value={rowValue.details || ''}
                                                    onChange={value => {
                                                        const target = this.state.neurodynamics.lowerLimb[index];
                                                        if (target.details === undefined || target.details === null || target.details.length === 0 || value.length === 0) {
                                                            this.setState((state) => {
                                                                target.details = value;
                                                                return state;
                                                            })
                                                        }
                                                        target.details = value;
                                                    }}
                                                    placeholder={"Details"}
                                                    style={{width: '-webkit-fill-available'}}
                                                />
                                            </th>
                                        </tr>
                                    )
                                }))
                            }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className={"col-5 vertical-align-center"}>
                    <img src={process.env.PUBLIC_URL + '/assets/emr/dermatomes/dermatomes_screen_image.png'}
                    />
                </div>
            </div>
        );
    }
}
