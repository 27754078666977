import React, {Component} from "react";
import '../../EMR.css';
import TextInput from "../../../../../components/text-input/TextInput";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";
import ResponseEnum from "../enums/treatmentreceived/ResponseEnum";
import ActivityLimitationEnum from "../enums/treatmentreceived/ActivityLimitationEnum";
import SeverityEnum from "../enums/treatmentreceived/SeverityEnum";
import BorderedRadioEnumInput from "../../../../../components/radio-input/BorderedRadioEnumInput";
import YesNoEnum from "../../../../genericenums/YesNoEnum";
import BorderedCheckboxInput from "../../../../../components/checkbox-input/BorderedCheckboxInput";
import MechanismEnum from "../enums/treatmentreceived/MechanismEnum";
import InjuryTypeEnum from "../enums/treatmentreceived/InjuryTypeEnum";
import BooleanCheckboxInput from "../../../../../components/checkbox-input/BooleanCheckboxInput";
import ReportStatusEnum from "../enums/treatmentreceived/ReportStatusEnum";
import PrimeDateInput from "../../../../../components/date-input/PrimeDateInput";
import {TreatmentReceivedChecker} from "../checkers/TreatmentReceivedChecker";
import GreenSwitchWithMessage from "../../../../../components/switch/GreenSwitchWithMessage";
import CheckboxInput from "../../../../../components/checkbox-input/CheckboxInput";
import {ActivityLimitationChecker} from "../checkers/ActivityLimitationChecker";
import {OtherInjuriesChecker} from "../checkers/OtherInjuriesChecker";
import StatelessBorderedCheckboxInput from "../../../../../components/checkbox-input/StatelessBorderedCheckboxInput";
import {LabsImagingChecker} from "../checkers/LabsImagingChecker";
import ConservativeTreatmentReceivedEnum from "../enums/treatmentreceived/ConservativeTreatmentReceivedEnum";
import OtherTreatmentReceivedEnum from "../enums/treatmentreceived/OtherTreatmentReceivedEnum";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import moment from "moment";

export default class TreatmentReceivedModal extends Component {
    state = {
        delete: false,

        conservativeTreatmentReceivedEnum: null,
        conservativeResponseToTreatment: null,
        conservativeTreatmentReceivedDetail: null,

        otherTreatmentReceivedEnum: null,
        otherResponseToTreatment: null,
        otherTreatmentReceivedDetail: null,

        activityLimitationActivity: null,
        activityLimitationSeverity: null,
        activityLimitationDetail: null
    };

    constructor(props) {
        super(props);
    }

    render() {
        let conservativeTreatmentReceivedDivs = this.getTreatmentReceivedDivs(
            this.props.subjective.treatmentReceived.conservative, ConservativeTreatmentReceivedEnum);
        let otherTreatmentReceivedDivs = this.getTreatmentReceivedDivs(
            this.props.subjective.treatmentReceived.other, OtherTreatmentReceivedEnum);
        let activityLimitationDivs = this.getActivityLimitationDivs();

        let treatmentToggleOn = TreatmentReceivedChecker.isToggleOn(this.props.subjective.treatmentReceived);
        let treatmentIsNone = TreatmentReceivedChecker.isNone(this.props.subjective.treatmentReceived);
        let activityLimitationToggleOn = ActivityLimitationChecker.isToggleOn(this.props.subjective.activityLimitation);
        let activityLimitationIsNone = ActivityLimitationChecker.isNone(this.props.subjective.activityLimitation);
        let otherInjuriesToggleOn = OtherInjuriesChecker.isToggleOn(this.props.subjective.otherInjuries);
        let otherInjuriesIsNone = OtherInjuriesChecker.isNone(this.props.subjective.otherInjuries);
        let labsImagingToggleOn = LabsImagingChecker.isToggleOn(this.props.subjective.labsImaging);
        let labsImagingIsNone = LabsImagingChecker.isNone(this.props.subjective.labsImaging);

        return (
            <div className={"emr-modal content-row emr-background"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px"}}>
                    <div>
                        <div style={{display: "flex"}}>
                            <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>
                                Previous Treatment Received <RedAsterisk appointmentType={this.props.appointmentType}
                                                                         firstVisit newComplaint/>
                            </div>
                            <GreenSwitchWithMessage
                                message={treatmentIsNone ? "No Treatment Received" : ""}
                                checked={treatmentToggleOn}
                                onChange={() => {
                                    if (treatmentToggleOn) {
                                        let id = this.props.subjective.treatmentReceived.id;
                                        this.props.subjective.treatmentReceived = {id: id, none: false};
                                        this.forceUpdate();
                                    }
                                }}/>
                        </div>

                        <div style={{display: "flex", marginLeft: "5px"}}>
                            <div>
                                <CheckboxInput value={treatmentIsNone}
                                               onChange={(value) => {
                                                   if (this.props.subjective.treatmentReceived == null) {
                                                       this.props.subjective.treatmentReceived = {
                                                           conservative: [],
                                                           other: []
                                                       };
                                                   }
                                                   if (value) {
                                                       let id = this.props.subjective.treatmentReceived.id;
                                                       this.props.subjective.treatmentReceived = {
                                                           id: id,
                                                           conservative: [],
                                                           other: []
                                                       };
                                                   }
                                                   this.props.subjective.treatmentReceived.none = value;
                                                   this.forceUpdate();
                                               }}/>
                            </div>
                            <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>No Treatment
                                Received
                            </div>
                        </div>

                        <br/>

                        <div className={treatmentIsNone ? "not-allowed" : ""}>
                            <div className={treatmentIsNone ? "disable-pointer" : ""}>
                                <div>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3"}>
                                            <div style={{fontSize: "12px", fontWeight: "bold"}}>Conservative</div>
                                        </div>
                                        <div className={"col-3"}>
                                            <div style={{fontSize: "12px", fontWeight: "bold"}}>Response to Treatment {
                                                treatmentToggleOn && <RedAsterisk appointmentType={this.props.appointmentType} firstVisit
                                                             newComplaint/>}
                                            </div>
                                        </div>
                                        <div className={"col-5"}>
                                            <div style={{fontSize: "12px", fontWeight: "bold"}}>Details</div>
                                        </div>
                                    </div>

                                    <div className={"row no-margin"}>
                                        <div className={"col-3"}>
                                            <Select
                                                className={"emr-white-select-container"}
                                                classNamePrefix={"emr-white-select"}
                                                placeholder={"Treatment Received"}
                                                value={this.state.conservativeTreatmentReceivedEnum != null ?
                                                    {
                                                        value: ConservativeTreatmentReceivedEnum[this.state.conservativeTreatmentReceivedEnum].key,
                                                        label: ConservativeTreatmentReceivedEnum[this.state.conservativeTreatmentReceivedEnum].name
                                                    } : null}
                                                onChange={(event) => {
                                                    this.setState({conservativeTreatmentReceivedEnum: event.value});
                                                }}
                                                options={ConservativeTreatmentReceivedEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                            />
                                        </div>
                                        <div className={"col-3"}>
                                            <Select
                                                className={"emr-white-select-container"}
                                                classNamePrefix={"emr-white-select"}
                                                placeholder={"Response to Treatment"}
                                                value={this.state.conservativeResponseToTreatment != null ?
                                                    {
                                                        value: ResponseEnum[this.state.conservativeResponseToTreatment].key,
                                                        label: ResponseEnum[this.state.conservativeResponseToTreatment].name
                                                    } : null}
                                                onChange={(event) => {
                                                    let r = event.value;
                                                    this.setState({conservativeResponseToTreatment: r});
                                                }}
                                                options={ResponseEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                            />
                                        </div>
                                        <div className={"col-5"}>
                                            <TextInput value={this.state.conservativeTreatmentReceivedDetail || ''}
                                                       placeholder={"Details"}
                                                       onChange={value => this.setState({conservativeTreatmentReceivedDetail: value})}
                                                       style={{fontSize: "14px", width: "100%"}}
                                            />
                                        </div>
                                        <div className={"col-1"} style={{flexFlow: "row-reverse", display: "flex"}}>
                                            <Button className={"emr-plus-button"}
                                                    variant="success"
                                                    disabled={!(this.state.conservativeTreatmentReceivedEnum != null
                                                        && this.state.conservativeResponseToTreatment != null)}
                                                    style={{fontSize: "12px"}}
                                                    onClick={this.addConservativeTreatmentReceived.bind(this)}>
                                                <FontAwesomeIcon icon={["fas", "plus"]}/>
                                            </Button>
                                        </div>

                                        <br/>
                                        <div className={"row no-margin"}
                                             style={{width: "100%", marginTop: "10px !important"}}>
                                            <div className={"div-list-wrapper"}>
                                                {conservativeTreatmentReceivedDivs}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <br/>

                                <div>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3"}>
                                            <div style={{fontSize: "12px", fontWeight: "bold"}}>Other</div>
                                        </div>
                                        <div className={"col-3"}>
                                            <div style={{fontSize: "12px", fontWeight: "bold"}}>Response to Treatment {
                                                treatmentToggleOn && <RedAsterisk appointmentType={this.props.appointmentType} firstVisit
                                                                                  newComplaint/>}
                                            </div>
                                        </div>
                                        <div className={"col-5"}>
                                            <div style={{fontSize: "12px", fontWeight: "bold"}}>Details</div>
                                        </div>
                                    </div>

                                    <div className={"row no-margin"}>
                                        <div className={"col-3"}>
                                            <Select
                                                className={"emr-white-select-container"}
                                                classNamePrefix={"emr-white-select"}
                                                placeholder={"Treatment Received"}
                                                value={this.state.otherTreatmentReceivedEnum != null ?
                                                    {
                                                        value: OtherTreatmentReceivedEnum[this.state.otherTreatmentReceivedEnum].key,
                                                        label: OtherTreatmentReceivedEnum[this.state.otherTreatmentReceivedEnum].name
                                                    } : null}
                                                onChange={(event) => {
                                                    this.setState({otherTreatmentReceivedEnum: event.value});
                                                }}
                                                options={OtherTreatmentReceivedEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                            />
                                        </div>
                                        <div className={"col-3"}>
                                            <Select
                                                className={"emr-white-select-container"}
                                                classNamePrefix={"emr-white-select"}
                                                placeholder={"Response to Treatment"}
                                                value={this.state.otherResponseToTreatment != null ?
                                                    {
                                                        value: ResponseEnum[this.state.otherResponseToTreatment].key,
                                                        label: ResponseEnum[this.state.otherResponseToTreatment].name
                                                    } : null}
                                                onChange={(event) => {
                                                    let r = event.value;
                                                    this.setState({otherResponseToTreatment: r});
                                                }}
                                                options={ResponseEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                            />
                                        </div>
                                        <div className={"col-5"}>
                                            <TextInput value={this.state.otherTreatmentReceivedDetail || ''}
                                                       placeholder={"Details"}
                                                       onChange={value => this.setState({otherTreatmentReceivedDetail: value})}
                                                       style={{fontSize: "14px", width: "100%"}}
                                            />
                                        </div>
                                        <div className={"col-1"} style={{flexFlow: "row-reverse", display: "flex"}}>
                                            <Button className={"emr-plus-button"}
                                                    variant="success"
                                                    disabled={!(this.state.otherTreatmentReceivedEnum != null
                                                        && this.state.otherResponseToTreatment != null)}
                                                    style={{fontSize: "12px"}}
                                                    onClick={this.addOtherTreatmentReceived.bind(this)}>
                                                <FontAwesomeIcon icon={["fas", "plus"]}/>
                                            </Button>
                                        </div>

                                        <br/>
                                        <div className={"row no-margin"}
                                             style={{width: "100%", marginTop: "10px !important"}}>
                                            <div className={"div-list-wrapper"}>
                                                {otherTreatmentReceivedDivs}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <br/>

                    <div>
                        <div style={{display: "flex"}}>
                            <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Activity
                                Limitation
                            </div>
                            <GreenSwitchWithMessage
                                message={activityLimitationIsNone ? "No Activity Limitation" : ""}
                                checked={activityLimitationToggleOn}
                                onChange={() => {
                                    if (activityLimitationToggleOn) {
                                        let id = this.props.subjective.activityLimitation.id;
                                        this.props.subjective.activityLimitation = {id: id, none: false};
                                        this.forceUpdate();
                                    }
                                }}/>
                        </div>

                        <div style={{display: "flex", marginLeft: "5px"}}>
                            <div>
                                <CheckboxInput value={activityLimitationIsNone}
                                               onChange={(value) => {
                                                   if (this.props.subjective.activityLimitation == null) {
                                                       this.props.subjective.activityLimitation = {items: []};
                                                   }
                                                   if (value) {
                                                       let id = this.props.subjective.activityLimitation.id;
                                                       this.props.subjective.activityLimitation = {id: id, items: []}
                                                   }
                                                   this.props.subjective.activityLimitation.none = value;
                                                   this.forceUpdate();
                                               }}/>
                            </div>
                            <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>No Activity
                                Limitation
                            </div>
                        </div>

                        <br/>

                        <div className={activityLimitationIsNone ? "not-allowed" : ""}>
                            <div className={activityLimitationIsNone ? "disable-pointer" : ""}>
                                <div className={"row no-margin"}>
                                    <div className={"col-3"}>
                                        <div style={{fontSize: "12px", fontWeight: "bold"}}>Activity Limitation</div>
                                    </div>
                                    <div className={"col-3"}>
                                        <div style={{fontSize: "12px", fontWeight: "bold"}}>Severity</div>
                                    </div>
                                    <div className={"col-5"}>
                                        <div style={{fontSize: "12px", fontWeight: "bold"}}>Details</div>
                                    </div>
                                </div>
                                <div className={"row no-margin"}>
                                    <div className={"col-3"}>
                                        <Select
                                            className={"emr-white-select-container"}
                                            classNamePrefix={"emr-white-select"}
                                            placeholder={"Activity Limitation"}
                                            value={this.state.activityLimitationActivity != null ?
                                                {
                                                    value: ActivityLimitationEnum[this.state.activityLimitationActivity].key,
                                                    label: ActivityLimitationEnum[this.state.activityLimitationActivity].name
                                                } : null}
                                            onChange={(event) => {
                                                this.setState({activityLimitationActivity: event.value});
                                            }}
                                            options={ActivityLimitationEnum.toArray().map(v => {
                                                    return {value: v.key, label: v.name}
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className={"col-3"}>
                                        <Select
                                            className={"emr-white-select-container"}
                                            classNamePrefix={"emr-white-select"}
                                            placeholder={"Severity"}
                                            value={this.state.activityLimitationSeverity != null ?
                                                {
                                                    value: SeverityEnum[this.state.activityLimitationSeverity].key,
                                                    label: SeverityEnum[this.state.activityLimitationSeverity].name
                                                } : null}
                                            onChange={(event) => {
                                                let r = event.value;
                                                this.setState({activityLimitationSeverity: r});
                                            }}
                                            options={SeverityEnum.toArray().map(v => {
                                                    return {value: v.key, label: v.name}
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className={"col-5"}>
                                        <TextInput value={this.state.activityLimitationDetail || ''}
                                                   placeholder={"Details"}
                                                   onChange={value => this.setState({activityLimitationDetail: value})}
                                                   style={{fontSize: "14px", width: "100%"}}
                                        />
                                    </div>
                                    <div className={"col-1"} style={{flexFlow: "row-reverse", display: "flex"}}>
                                        <Button className={"emr-plus-button"}
                                                variant="success"
                                                disabled={!(this.state.activityLimitationActivity != null
                                                    && this.state.activityLimitationSeverity != null)}
                                                style={{fontSize: "12px"}}
                                                onClick={this.addActivityLimitation.bind(this)}>
                                            <FontAwesomeIcon icon={["fas", "plus"]}/>
                                        </Button>
                                    </div>

                                    <br/>
                                    <div className={"row no-margin"}
                                         style={{width: "100%", marginTop: "10px !important"}}>
                                        <div className={"div-list-wrapper"}>
                                            {activityLimitationDivs}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>

                    <div>
                        <div style={{display: "flex"}}>
                            <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Other Injuries <RedAsterisk appointmentType={this.props.appointmentType} firstVisit
                                                                                                                                   newComplaint/>
                            </div>
                            <GreenSwitchWithMessage
                                message={otherInjuriesIsNone ? "No Other Injuries" : ""}
                                checked={otherInjuriesToggleOn}
                                onChange={() => {
                                    if (otherInjuriesToggleOn) {
                                        let id = this.props.subjective.otherInjuries.id;
                                        this.props.subjective.otherInjuries = {
                                            id: id,
                                            none: false,
                                            type: [],
                                            mechanism: []
                                        };
                                        this.forceUpdate();
                                    }
                                }}/>
                        </div>

                        <div style={{display: "flex", marginLeft: "5px"}}>
                            <div>
                                <CheckboxInput value={otherInjuriesIsNone}
                                               onChange={(value) => {
                                                   if (this.props.subjective.otherInjuries == null) {
                                                       this.props.subjective.otherInjuries = {type: [], mechanism: []};
                                                   }
                                                   if (value) {
                                                       let id = this.props.subjective.otherInjuries.id;
                                                       this.props.subjective.otherInjuries = {
                                                           id: id,
                                                           type: [],
                                                           mechanism: []
                                                       }
                                                   }
                                                   this.props.subjective.otherInjuries.none = value;
                                                   this.forceUpdate();
                                               }}/>
                            </div>
                            <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>No Other Injuries
                            </div>
                        </div>

                        <div className={otherInjuriesIsNone ? "not-allowed" : ""}>
                            <div className={otherInjuriesIsNone ? "disable-pointer" : ""}>
                                <div className={"row no-margin"} style={{width: "100%"}}>
                                    <div className={"col-2 no-padding"}
                                         style={{marginTop: "auto", marginBottom: "auto"}}>
                                        <div style={{fontWeight: "bold", fontSize: "12px"}}>Related Injuries <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                                    </div>
                                    <div className={"col-2 no-padding"}>
                                        <BorderedRadioEnumInput
                                            values={YesNoEnum.toArray()}
                                            selected={this.props.subjective.otherInjuries != null ?
                                                this.props.subjective.otherInjuries.relatedInjuries : null}
                                            onSelect={(value) => {
                                                this.props.subjective.otherInjuries.relatedInjuries = value;
                                                this.forceUpdate();
                                            }}
                                        />
                                    </div>
                                    <div className={"col-8 no-padding"} style={{margin: "auto"}}>
                                        <PrimeDateInput clearable
                                                        value={this.props.subjective.otherInjuries != null ?
                                                            moment.utc(this.props.subjective.otherInjuries.relatedInjuriesTime).toDate() : null}
                                                        onChange={date => {
                                                            this.props.subjective.otherInjuries.relatedInjuriesTime = date;
                                                            this.forceUpdate();
                                                        }}
                                                        placeholder={"Date.."}
                                                        style={{width: "100%"}}
                                                        inputStyle={{padding: "1px"}}
                                        />
                                    </div>
                                </div>
                                <div className={"row no-margin"}>
                                    <div className={"col-6"}>
                                        <div>
                                            <div style={{fontWeight: "bold"}}>Type</div>
                                            <StatelessBorderedCheckboxInput
                                                values={InjuryTypeEnum.toArray()}
                                                selected={this.props.subjective.otherInjuries.type}
                                                onChange={() => {
                                                    this.forceUpdate();
                                                }}
                                            />
                                            <TextInput value={this.props.subjective.otherInjuries.typeDetail || ''}
                                                       placeholder={"Details"}
                                                       style={{width: '-webkit-fill-available'}}
                                                       onChange={value => {
                                                           this.props.subjective.otherInjuries.typeDetail = value;
                                                           this.forceUpdate();
                                                       }}
                                            />
                                        </div>
                                    </div>
                                    <div className={"col-6"}>
                                        <div>
                                            <div style={{fontWeight: "bold"}}>Mechanism</div>
                                            <StatelessBorderedCheckboxInput
                                                values={MechanismEnum.toArray()}
                                                selected={this.props.subjective.otherInjuries.mechanism}
                                                onChange={() => {
                                                    this.forceUpdate();
                                                }}
                                            />
                                            <TextInput value={this.props.subjective.otherInjuries.mechanismDetail || ''}
                                                       placeholder={"Details"}
                                                       style={{width: '-webkit-fill-available'}}
                                                       onChange={value => {
                                                           this.props.subjective.otherInjuries.mechanismDetail = value;
                                                           this.forceUpdate();
                                                       }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <br/>

                    <div>
                        <div style={{display: "flex"}}>
                            <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Labs/Imaging <RedAsterisk appointmentType={this.props.appointmentType} firstVisit
                                                                                                                                 newComplaint/>
                            </div>
                            <GreenSwitchWithMessage
                                message={labsImagingIsNone ? "No Lab/Imaging" : ""}
                                checked={labsImagingToggleOn}
                                onChange={() => {
                                    if (labsImagingToggleOn) {
                                        let id = this.props.subjective.labsImaging.id;
                                        this.props.subjective.labsImaging = {id: id, none: false, reportStatus: []};
                                        this.forceUpdate();
                                    }
                                }}/>
                        </div>

                        <div style={{display: "flex", marginLeft: "5px"}}>
                            <div>
                                <CheckboxInput value={labsImagingIsNone}
                                               onChange={(value) => {
                                                   if (this.props.subjective.labsImaging == null) {
                                                       this.props.subjective.labsImaging = {reportStatus: []};
                                                   }
                                                   if (value) {
                                                       let id = this.props.subjective.labsImaging.id;
                                                       this.props.subjective.labsImaging = {id: id, reportStatus: []}
                                                   }
                                                   this.props.subjective.labsImaging.none = value;
                                                   this.forceUpdate();
                                               }}/>
                            </div>
                            <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>No Lab/Imaging</div>
                        </div>

                        <div className={labsImagingIsNone ? "not-allowed" : ""}>
                            <div className={labsImagingIsNone ? "disable-pointer" : ""}>
                                <div className={"row no-margin"} style={{width: "100%"}}>
                                    <div className={"col-6"}>
                                        <div className={"row no-margin"} style={{width: "100%"}}>
                                            <div className={"col-3"}>
                                                <BooleanCheckboxInput label={"Lab"}
                                                                      value={this.props.subjective.labsImaging.labs}
                                                                      style={{width: "100%"}}
                                                                      onChange={(event) => {
                                                                          this.props.subjective.labsImaging.labs = event;
                                                                          this.forceUpdate();
                                                                      }}/>
                                            </div>
                                            <div className={"col-9"} style={{margin: "auto"}}>
                                                <TextInput value={this.props.subjective.labsImaging.labDetail || ''}
                                                           placeholder={"Details"}
                                                           onChange={value => {
                                                               this.props.subjective.labsImaging.labDetail = value;
                                                               this.forceUpdate();
                                                           }}
                                                           style={{fontSize: "14px", width: "100%"}}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-6"} style={{margin: "auto"}}>
                                        <BorderedCheckboxInput
                                            values={ReportStatusEnum.toArray()}
                                            selected={this.props.subjective.labsImaging.reportStatus}
                                        />
                                    </div>
                                </div>
                                <div className={"row no-margin"} style={{width: "100%"}}>
                                    <div className={"col-6"}>
                                        <div className={"row no-margin"} style={{width: "100%"}}>
                                            <div className={"col-3"}>
                                                <BooleanCheckboxInput label={<span>Radiology</span>}
                                                                      value={this.props.subjective.labsImaging.radiology}
                                                                      style={{width: "100%"}}
                                                                      onChange={(event) => {
                                                                          this.props.subjective.labsImaging.radiology = event;
                                                                          this.forceUpdate();
                                                                      }}/>
                                            </div>
                                            <div className={"col-9"} style={{margin: "auto"}}>
                                                <TextInput
                                                    value={this.props.subjective.labsImaging.radiologyDetail || ''}
                                                    placeholder={"Details"}
                                                    onChange={value => {
                                                        this.props.subjective.labsImaging.radiologyDetail = value;
                                                        this.forceUpdate();
                                                    }}
                                                    style={{fontSize: "14px", width: "100%"}}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"col-6"} style={{margin: "auto"}}>
                                        <TextInput value={this.props.subjective.labsImaging.reportDetail || ''}
                                                   placeholder={"Details"}
                                                   onChange={value => {
                                                       this.props.subjective.labsImaging.reportDetail = value;
                                                       this.forceUpdate();
                                                   }}
                                                   style={{fontSize: "14px", width: "100%"}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        );
    }

    addConservativeTreatmentReceived() {
        this.createTreatmentReceivedIfNecessary();
        this.props.subjective.treatmentReceived.conservative.push({
            treatmentReceived: this.state.conservativeTreatmentReceivedEnum,
            response: this.state.conservativeResponseToTreatment,
            detail: this.state.conservativeTreatmentReceivedDetail
        });
        this.setState({
            conservativeTreatmentReceivedEnum: null,
            conservativeResponseToTreatment: null,
            conservativeTreatmentReceivedDetail: null
        });
    }

    addOtherTreatmentReceived() {
        this.createTreatmentReceivedIfNecessary();
        this.props.subjective.treatmentReceived.other.push({
            treatmentReceived: this.state.otherTreatmentReceivedEnum,
            response: this.state.otherResponseToTreatment,
            detail: this.state.otherTreatmentReceivedDetail
        });
        this.setState({
            otherTreatmentReceivedEnum: null,
            otherResponseToTreatment: null,
            otherTreatmentReceivedDetail: null
        });
    }

    createTreatmentReceivedIfNecessary() {
        if (this.props.subjective.treatmentReceived == null) {
            this.props.subjective.treatmentReceived = {conservative: [], other: []};
        }
        if (this.props.subjective.treatmentReceived.conservative == null) {
            this.props.subjective.treatmentReceived.conservative = [];
        }
        if (this.props.subjective.treatmentReceived.other == null) {
            this.props.subjective.treatmentReceived.other = [];
        }
    }

    getTreatmentReceivedDivs(list, enumObj) {
        if (this.props.subjective.treatmentReceived == null
            || list == null
            || list.length == 0) {
            return;
        }
        let divs = [];
        list.forEach((item, index) => divs.push(
            <div className={"div-list-item"} key={"treatmentReceivedItem" + index}>
                <div>
                    {enumObj[item.treatmentReceived]?.name}, {ResponseEnum[item.response]?.name}{item.detail ? (", " + item.detail) : null}
                </div>
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize: "10px", marginLeft: "2px"}}
                        onClick={() => {
                            list.splice(index, 1);
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </Button>
            </div>
        ));
        return divs;
    }

    addActivityLimitation() {
        this.createActivityLimitationIfNecessary();
        this.props.subjective.activityLimitation.items.push({
            activityLimitation: this.state.activityLimitationActivity,
            severity: this.state.activityLimitationSeverity,
            detail: this.state.activityLimitationDetail
        });
        this.setState({
            activityLimitationActivity: null,
            activityLimitationSeverity: null,
            activityLimitationDetail: null
        });
    }

    createActivityLimitationIfNecessary() {
        if (this.props.subjective.activityLimitation == null) {
            this.props.subjective.activityLimitation = {items: []};
        } else if (this.props.subjective.activityLimitation.items == null) {
            this.props.subjective.activityLimitation.items = [];
        }
    }

    getActivityLimitationDivs() {
        if (this.props.subjective.activityLimitation == null
            || this.props.subjective.activityLimitation.items == null
            || this.props.subjective.activityLimitation.items.length == 0) {
            return;
        }
        let divs = [];
        this.props.subjective.activityLimitation.items.forEach((item, index) => divs.push(
            <div className={"div-list-item"} key={"activityLimitationItem" + index}>
                <div>
                    {ActivityLimitationEnum[item.activityLimitation].name}, {SeverityEnum[item.severity].name}{item.detail == null ? "" : (", " + item.detail)}
                </div>
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize: "10px", marginLeft: "2px"}}
                        onClick={() => {
                            this.props.subjective.activityLimitation.items.splice(index, 1);
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </Button>
            </div>
        ));
        return divs;
    }

}
