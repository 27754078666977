import React, { Component } from "react";
import './BorderedCheckboxInput.css';

export default class StatelessBorderedCheckboxInput extends Component {

    render() {
        const items = []

        for (const enumObject of this.props.values) {
            if(this.props.selected.includes(enumObject.key)) {
                items.push(
                    <div key={enumObject.key}
                         className={"bordered-checkbox-selected"}
                         style={this.props.itemStyle}
                         onClick={()=> {
                             this.props.selected.splice(this.props.selected.indexOf(enumObject.key), 1);
                             this.props.onChange();
                         }}>
                        {enumObject.name}
                    </div>)
            }else {
                items.push(
                    <div key={enumObject.key}
                         className={"bordered-checkbox-unselected"}
                         style={this.props.itemStyle}
                         onClick={()=> {
                             this.props.selected.push(enumObject.key);
                             this.props.onChange();
                         }}>
                        {enumObject.name}
                    </div>)
            }
        }
        return (<>
                <div className={"disc-checkbox-container"}>
                {items}
                </div>
            </>);
        }
}
