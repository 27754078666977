import React, {Component} from "react";
import '../../EMR.css';
import TextInput from "../../../../../components/text-input/TextInput";
import Select from "react-select";
import GreenSwitchWithEmptyMessage from "../../../../../components/switch/GreenSwitchWithEmptyMessage";
import AreaEnum from "../enums/specialtests/AreaEnum";
import TestEnum from "../enums/specialtests/TestEnum";
import PosNegRadioInput from "../../../../../components/radio-input/PosNegRadioInput";
import AddButton from "../../common/table/AddButton";
import RemoveButton from "../../common/table/RemoveButton";
import {showWarning} from "../../../../../services/notification-service/NotificationService";

export default class SpecialTestsModal extends Component {
    state = {
        switch: true,
    };

    testMap = {
        NECK: ["ADSONS_TEST",
            "ROOS_TEST",
            "CERVICAL_COMPRESSION_TEST",
            "CERVICAL_DISTRACTION_TEST",
            "ALAR_LIGAMENT_TEST",
            "CERVICAL_FLEXION_ROTATION_TEST",
            "SHARPS_PURSER_TEST"],
        SHOULDER: ["CROSS_ARM_ADDUCTION_TEST_AC_JOINT",
            "APPREHENSION_RELOCATION",
            "CLUNKY_TEST_LABRUM",
            "PASSIVE_COMPRESSION_TEST",
            "EMPTY_CAN_TEST_SUPRASPINATUS",
            "FULL_CAN_TEST",
            "HAWKINS_KENNEDY_TEST",
            "NEER_IMPINGEMENT_TEST",
            "OBRIENS_TEST_SLAP_LESION",
            "RESISTED_ER_ROTATOR_CUFF_LESION",
            "RESISTED_IR_ROTATOR_CUFF_LESION",
            "SPEEDS_TEST_BICEPS",
            "TOS",
            "PAINFUL_ARC_TEST",
            "ER_STRENGTH_TEST",
            "DROP_ARM_TEST",
            "LIFT_OFF_TEST",
            "LOAD_AND_SHIT_TEST",
            "ADDUCTION_TEST",
            "CRANK_TEST",
            "BICEPS_LOAD_TEST"],
        ELBOW: ["GOLFERS_ELBOW_MEDIAL_EPICONDYLITIS",
            "RESISTED_MIDDLE_FINGER_EXTENSION_LATERAL_EPICONDYLITIS",
            "COZENS_TEST_LATERAL_EPICONDYLITIS"],
        WRIST: ["ALLEN_TEST_VASCULAR",
            "FINKELSTEIN_TEST_DEQUERVAIN_SYNDROME",
            "TINEL_SIGN_CTS",
            "WATSON_TEST",
            "PIANO_KEY_TEST_TFCC"],
        LUMBAR_SPINE: ["SLUMP_TEST",
            "SEATED_KEMPS_TEST",
            "SAHRMANN_ABDOMINAL_TEST"],
        HIP: ["FABER_TEST",
            "PELVIC_COMPRESSION_TEST",
            "OBER_TEST_ITB",
            "PELVIC_ROCKING",
            "QUADRANT_TEST_LABRUM",
            "THOMAS_TEST_PSOAS",
            "ANTERIOR_IMPINGEMENT_TEST",
            "FLEXION_INTERNATIONAL_ROTATION_TEST",
            "FADIR_TEST",
            "SCOUR_TEST"],
        SACROILIAC: ["GAENSLENS_TEST",
            "SI_DISTRACTION_TEST",
            "THIGH_THRUST_TEST",
            "SI_COMPRESSION_TEST",
            "SACRAL_THRUST_TEST"],
        KNEE: ["ANTERIOR_DRAWER_TEST",
            "POSTERIOR_DRAWER_TEST",
            "APLEYS_TEST_MENISCUS",
            "LACHMANNS_TEST",
            "MCMURRYS_TEST",
            "PATELLAR_APPREHENSION_TEST",
            "VALGUS_STRESS_TEST",
            "VARUS_STRESS_TEST"],
        ANKLE: ["KNEE_TO_WALL_TEST_MOBILITY",
            "SYNDESMOSIS_SQUEEZE_TEST",
            "EXTERNAL_ROTATION_TEST_DELTOID_SYNDESMOSIS",
            "TALUS_TILT_TEST",
            "ANTERIO_DRAWER_TEST",
            "FORCED_DORSIFLEXION_TEST"],
        VISCERAL: ["MCBURNEYS_POINT_REBOUND_TENDERNESS_TEST",
            "ROSENBACH_SIGN",
            "MURPHY_SIGN",
            "VOCAL_FREMITUS",
            "BOAS_POINT"],
        WADDELLS_SIGN: ["TENDERNESS",
            "SIMULATION",
            "DISTRACTION",
            "REGIONAL",
            "OVERREACTION"]
    }

    constructor(props) {
        super(props);
        this.MINIMUM_SEARCH_LENGTH = 2;
    }

    render() {
        let formList = [];
        this.props.formFields.set('specialTests', formList)
        return (
            <div className={"emr-modal content-row emr-background p-4 pb-5 w-100"}>
                <div className="w-100 m-3">
                    <div style={{display: "flex"}}>
                        <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Special Tests</div>
                        <GreenSwitchWithEmptyMessage
                            emptyMessage={""}
                            checked={this.props.assessment.specialTests != null && this.props.assessment.specialTests.items.length != 0}
                            onChange={() => {
                                if (this.props.assessment.specialTests == null) {
                                    this.props.assessment.specialTests = {};
                                    this.props.assessment.specialTests.items = [];
                                } else {
                                    this.props.assessment.specialTests = null;
                                }
                                this.forceUpdate();
                            }}/>
                    </div>
                </div>

                <div className={"content-card mb-5"}>
                    <table className={"emr-table"}>
                        <tbody>
                        <tr>
                            <th>Area</th>
                            <th>Test</th>
                            <th>Right</th>
                            <th>Left</th>
                            <th>Radiating</th>
                            <th>Details</th>
                            <th style={{color: "red"}}>ⓧ</th>
                        </tr>
                        {(this.props.assessment.specialTests == null || this.props.assessment.specialTests.items == null)
                            ? null : this.props.assessment.specialTests.items.map((m, index) => {
                                return (<tr key={"specialTestsModal" + index} style={{backgroundColor: "white"}}>
                                    <td>{m.area == null ? "" : AreaEnum[m.area].name}</td>
                                    <td>{m.test == null ? "" : TestEnum[m.test].name}</td>
                                    <td style={{position: "relative", minWidth: "120px"}}>
                                        <PosNegRadioInput value={m.rightBool} readOnly={true}/>
                                    </td>
                                    <td style={{position: "relative", minWidth: "120px"}}>
                                        <PosNegRadioInput value={m.leftBool} readOnly={true}/>
                                    </td>
                                    <td>{m.radiating}</td>
                                    <td>
                                        <div style={{minHeight: "30px"}}>
                                            <div>{m.detail == null ? " " : m.detail}</div>
                                        </div>
                                    </td>
                                    <td>
                                        <RemoveButton
                                            onClick={() => {
                                                this.props.assessment.specialTests.items.splice(index, 1);
                                                this.forceUpdate();
                                            }}
                                        />
                                    </td>
                                </tr>)
                            })}
                        <tr style={{backgroundColor: "white"}}>
                            <td style={{minWidth: "80px"}}>
                                <Select
                                    className={"emr-in-table-select-container"}
                                    classNamePrefix={"emr-in-table-select"}
                                    placeholder={"Type"}

                                    value={this.props.activeSpecialTest.area != null ?
                                        {
                                            value: this.props.activeSpecialTest.area,
                                            label: AreaEnum[this.props.activeSpecialTest.area].name
                                        } : null}
                                    onChange={(event) => {
                                        this.props.activeSpecialTest.area = event.value;
                                        this.props.activeSpecialTest.test = null;
                                        this.forceUpdate();
                                    }}
                                    options={AreaEnum.toArray().map(v => {
                                            return {value: v.key, label: v.name}
                                        }
                                    )}
                                />
                            </td>
                            <td style={{minWidth: "150px"}}>
                                {this.props.activeSpecialTest.area != null &&
                                <Select
                                    className={"emr-in-table-select-container"}
                                    classNamePrefix={"emr-in-table-select"}
                                    placeholder={"Type"}
                                    value={this.props.activeSpecialTest.test != null ?
                                        {
                                            value: this.props.activeSpecialTest.test,
                                            label: TestEnum[this.props.activeSpecialTest.test].name
                                        } : null}
                                    onChange={(event) => {
                                        this.props.activeSpecialTest.test = event.value;
                                        this.forceUpdate();
                                    }}
                                    options={this.testMap[this.props.activeSpecialTest.area].map(v => {
                                        return {value: v, label: TestEnum[v].name}
                                    })}
                                />
                                }
                            </td>
                            <td style={{position: "relative", minWidth: "80px"}}>
                                <PosNegRadioInput value={this.props.activeSpecialTest.rightBool}
                                                  onChange={(event) => {
                                                      this.props.activeSpecialTest.rightBool = event;
                                                      this.forceUpdate();
                                                  }}/>
                            </td>
                            <td style={{position: "relative", minWidth: "80px"}}>
                                <PosNegRadioInput value={this.props.activeSpecialTest.leftBool}
                                                  onChange={(event) => {
                                                      this.props.activeSpecialTest.leftBool = event;
                                                      this.forceUpdate();
                                                  }}/>
                            </td>
                            <td>
                                <TextInput value={this.props.activeSpecialTest.radiating || ''}
                                           placeholder={""}
                                           onChange={value => {
                                               this.props.activeSpecialTest.radiating = value;
                                               this.forceUpdate();
                                           }}
                                />
                            </td>

                            <td>
                                <div>
                                    <TextInput value={this.props.activeSpecialTest.detail || ''}
                                               placeholder={""}
                                               onChange={value => {
                                                   this.props.activeSpecialTest.detail = value;
                                                   this.forceUpdate();
                                               }}
                                    />
                                </div>
                            </td>
                            <td>
                                <AddButton
                                    onClick={() => {
                                        if (this.props.activeSpecialTest.area && this.props.activeSpecialTest.test) {
                                            let copy = Object.assign({}, this.props.activeSpecialTest);
                                            if (this.props.assessment.specialTests == null) {
                                                this.props.assessment.specialTests = {items: []};
                                            }
                                            this.props.assessment.specialTests.items.push(copy);
                                            for (var prop in this.props.activeSpecialTest) {
                                                if (this.props.activeSpecialTest.hasOwnProperty(prop)) {
                                                    delete this.props.activeSpecialTest[prop];
                                                }
                                            }
                                            this.forceUpdate();
                                        } else {
                                            showWarning("Enter area and test fields.")
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }


}
