import React, {Component} from "react";
import {FileUploader, Modal, TextInput} from "../../../components";
import {FormField} from "../../../components/form";
import RemotingService from "../../../services/remoting-service/RemotingService";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";

class UploadAppointmentDocument extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleDocumentChange = (value) => {
        this.setState({document: value, documentChanged: true});
    }

    handleDocumentUpload = () => {
        if (!ValidationUtil.isValid(this.formFields)) {
            ValidationUtil.showNotification();
            return;
        }

        if (this.state.documentChanged && this.state.document) {
            let documentType = "APPOINTMENT_DOCUMENT";
            let appointmentDocument = {...this.state.document, description: this.state.documentDescription};
            RemotingService.postRemoteCall(`api/appointment/${this.props.appointmentId}/document/${documentType}`, appointmentDocument, () => {
                this.setState({
                    documentDescription: undefined,
                    document: undefined
                });
                this.props.onDocumentUploaded(this.props.appointmentId);
            });
        }
    }

    render() {
        this.formFields = [];
        return <Modal visible={true}
                      header="Document"
                      submitContent="Upload"
                      submitAction={this.handleDocumentUpload}
                      closeAction={this.props.close}>
            <div className="container">
                <div className="row">
                    <div className="col-4 dim14-form-label">Description</div>
                    <div className="col-8">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required
                                   validateOn={this.state.documentDescription}>
                            <TextInput value={this.state.documentDescription} onChange={(value) => this.setState({documentDescription: value})}/>
                        </FormField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 dim14-form-label">File</div>
                    <div className="col-8">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required
                                   validateOn={this.state.document}>
                            <FileUploader
                                value={this.state.document}
                                onChange={(value) => {this.handleDocumentChange(value)}}/>
                        </FormField>
                    </div>
                </div>
            </div>
        </Modal>
    }

}

export default UploadAppointmentDocument;