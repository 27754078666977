import React, {Component} from "react";
import StringUtil from "../../../../../utils/StringUtil";
import ReportViewUtils from "./ReportViewUtils";

export default class LabImagingReportView extends Component {
    render() {
        let divs = [];

        let li = this.props.labsImaging;

        if (ReportViewUtils.isLabImagingNull(li)) {
            return null;
        }

        if (li.none) {
            return (<div>No Lab/Imaging</div>);
        }

        divs.push(
            <div key={"labImagingReportView"}>
                <div className={"display-flex"} style={{margin: "3px"}}>
                    <div className={"col-sm-12 no-padding overflow-wrap-anywhere"}>Lab. {li.labs === true
                        ? "Yes" : "No"}{StringUtil.isNullOrEmpty(li.labDetail) ? "" : (", " + li.labDetail)}</div>
                </div>

                <div className={"display-flex"} style={{margin: "3px"}}>
                    <div className={"col-sm-12 no-padding overflow-wrap-anywhere"}>Radiology. {li.radiology === true
                        ? "Yes" : "No"}{StringUtil.isNullOrEmpty(li.radiologyDetail) ? "" : (", " + li.radiologyDetail)}</div>
                </div>

                <div className={"display-flex"} style={{margin: "3px"}}>
                    <div className={"col-sm-12 no-padding overflow-wrap-anywhere"}>Report
                        Present. {li.reportStatus.includes("REPORT_PRESENT") ? "Yes" : "No"}</div>
                </div>

                <div className={"display-flex"} style={{margin: "3px"}}>
                    <div className={"col-sm-12 no-padding overflow-wrap-anywhere"}>Report
                        Scanned. {li.reportStatus.includes("REPORT_SCANNED") ? "Yes" : "No"}</div>
                </div>
                {!StringUtil.isNullOrEmpty(li.reportDetail) &&
                <div className={"display-flex"} style={{margin: "3px"}}>
                    <div className={"col-sm-12 no-padding overflow-wrap-anywhere"}>Report
                        Detail. {li.reportDetail}</div>
                </div>
                }
            </div>
        );

        return (
            <div className={"visit-history-multiple-content-wrapper"}>
                {divs}
            </div>
        );
    }

    checkLabImagingDataAvailability(labImaging) {
        return labImaging.labs == null && labImaging.labDetail == null
            && labImaging.radiology == null && labImaging.radiologyDetail == null
            && labImaging.reportStatus.length === 0 && labImaging.reportDetail == null;
    }
}