import {BaseConstants} from "../constants/BaseConstants";
import SchedulingService from "../scheduling-service/SchedulingService";
import ParameterService from "../parameter/ParameterService";
import LookupService from "../lookup/LookupService";

export * from './alert.actions';

export * from './user.actions';

export * from './emr.actions';

export const getSchedules = () => {
    return dispatch => {
        dispatch({type: BaseConstants.SCHEDULES, value: []});

        SchedulingService.getSchedules((schedules) => {
            dispatch({type: BaseConstants.SCHEDULES, value: schedules});
        });
    };
}

export const getParameters = () => {
    return dispatch => {
        dispatch({type: BaseConstants.PARAMETERS, value: {}});

        ParameterService.getParameters(parameterMap => {
            dispatch({type: BaseConstants.PARAMETERS, value: parameterMap});
        });
    };
}

export const getNationalities = () => {
    return dispatch => {
        dispatch({type: BaseConstants.NATIONALITIES, value: {}});

        LookupService.getNationalities(nationalities => {
            dispatch({type: BaseConstants.NATIONALITIES, value: nationalities});
        });
    };
}

export const getCountries = () => {
    return dispatch => {
        dispatch({type: BaseConstants.COUNTRIES, value: {}});

        LookupService.getCountries(countries => {
            dispatch({type: BaseConstants.COUNTRIES, value: countries});
        });
    };
}

export const getLanguages = () => {
    return dispatch => {
        dispatch({type: BaseConstants.LANGUAGES, value: {}});

        LookupService.getLanguages(languages => {
            dispatch({type: BaseConstants.LANGUAGES, value: languages});
        });
    };
}

export const getReligions = () => {
    return dispatch => {
        dispatch({type: BaseConstants.RELIGIONS, value: {}});

        LookupService.getReligions(religions => {
            dispatch({type: BaseConstants.RELIGIONS, value: religions});
        });
    };
}

export const getMaritalStatuses = () => {
    return dispatch => {
        dispatch({type: BaseConstants.MARITAL_STATUS, value: {}});

        LookupService.getMaritalStatuses(maritalStatuses => {
            dispatch({type: BaseConstants.MARITAL_STATUS, value: maritalStatuses});
        });
    };
}

export const getRelationshipTypes = () => {
    return dispatch => {
        dispatch({type: BaseConstants.RELATIONSHIP_TYPES, value: {}});

        LookupService.getRelationshipTypes(relationshipTypes => {
            dispatch({type: BaseConstants.RELATIONSHIP_TYPES, value: relationshipTypes});
        });
    };
}

export const getAllergyTypes = () => {
    return dispatch => {
        dispatch({type: BaseConstants.ALLERGY_TYPES, value: {}});

        LookupService.getAllergyTypes(relationshipTypes => {
            dispatch({type: BaseConstants.ALLERGY_TYPES, value: relationshipTypes});
        });
    };
}

export const getAllergenTypes = () => {
    return dispatch => {
        dispatch({type: BaseConstants.ALLERGEN_TYPES, value: {}});

        LookupService.getAllergenTypes(allergenTypes => {
            dispatch({type: BaseConstants.ALLERGEN_TYPES, value: allergenTypes});
        });
    };
}

export const getAllergenReactionTypes = () => {
    return dispatch => {
        dispatch({type: BaseConstants.ALLERGEN_REACTION_TYPES, value: {}});

        LookupService.getAllergenReactionTypes(reactionTypes => {
            dispatch({type: BaseConstants.ALLERGEN_REACTION_TYPES, value: reactionTypes});
        });
    };
}

export const getAllergySeverityTypes = () => {
    return dispatch => {
        dispatch({type: BaseConstants.ALLERGY_SEVERITIES, value: {}});

        LookupService.getAllergySeverityTypes(severities => {
            dispatch({type: BaseConstants.ALLERGY_SEVERITIES, value: severities});
        });
    };
}

export const getGenders = () => {
    return dispatch => {
        dispatch({type: BaseConstants.LOOKUP_GENDERS, value: {}});

        LookupService.getGenders(genders => {
            dispatch({type: BaseConstants.LOOKUP_GENDERS, value: genders});
        });
    };
}

export const getEmploymentStatuses = () => {
    return dispatch => {
        dispatch({type: BaseConstants.EMPLOYMENT_STATUSES, value: {}});

        LookupService.getEmploymentStatuses(employmentStatuses => {
            dispatch({type: BaseConstants.EMPLOYMENT_STATUSES, value: employmentStatuses});
        });
    };
}

export const getEducationLevels = () => {
    return dispatch => {
        dispatch({type: BaseConstants.EDUCATION_LEVELS, value: {}});

        LookupService.getEducationLevels(educationLevels => {
            dispatch({type: BaseConstants.EDUCATION_LEVELS, value: educationLevels});
        });
    };
}

export const getSmokingDevices = () => {
    return dispatch => {
        dispatch({type: BaseConstants.SMOKING_DEVICES, value: {}});

        LookupService.getSmokingDevices(smokingDevices => {
            dispatch({type: BaseConstants.SMOKING_DEVICES, value: smokingDevices});
        });
    };
}

export const getSmokingStatuses = () => {
    return dispatch => {
        dispatch({type: BaseConstants.SMOKING_STATUSES, value: {}});

        LookupService.getSmokingStatuses(smokingStatuses => {
            dispatch({type: BaseConstants.SMOKING_STATUSES, value: smokingStatuses});
        });
    };
}

export const getDrinkingStatuses = () => {
    return dispatch => {
        dispatch({type: BaseConstants.DRINKING_STATUSES, value: {}});

        LookupService.getDrinkingStatuses(drinkingStatuses => {
            dispatch({type: BaseConstants.DRINKING_STATUSES, value: drinkingStatuses});
        });
    };
}

export const getSubstanceAbuseStatuses = () => {
    return dispatch => {
        dispatch({type: BaseConstants.SUBSTANCE_ABUSE_STATUSES, value: {}});

        LookupService.getSubstanceAbuseStatuses(substanceAbuseStatuses => {
            dispatch({type: BaseConstants.SUBSTANCE_ABUSE_STATUSES, value: substanceAbuseStatuses});
        });
    };
}

export const getMedicationRoutes = () => {
    return dispatch => {
        dispatch({type: BaseConstants.MEDICATION_ROUTES, value: {}});

        LookupService.getMedicationRoutes(routes => {
            dispatch({type: BaseConstants.MEDICATION_ROUTES, value: routes});
        });
    };
}

export const getMedicationUnits = () => {
    return dispatch => {
        dispatch({type: BaseConstants.MEDICATION_UNITS, value: {}});

        LookupService.getMedicationUnits(units => {
            dispatch({type: BaseConstants.MEDICATION_UNITS, value: units});
        });
    };
}
