import React, {Component} from "react";
import DateUtil from "../../../utils/DateUtil";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "react-bootstrap";
import RemotingService from "../../../services/remoting-service/RemotingService";
import {base64ToBlob, downloadBlob} from "../../../utils/FileDownloader";
import PrimeDateInput from "../../../components/date-input/PrimeDateInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class WaitingTimeReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            date: DateUtil.startOfMonth()
        };
    }

    waitingTimeToGetAppointment() {
        const params = {
            startDate: DateUtil.formatIsoDate(DateUtil.startOfMonth(this.state.date)),
            endDate: DateUtil.formatIsoDate(DateUtil.endOfMonth(this.state.date))
        };

        RemotingService.getRemoteCall('api/appointment/waiting-time-to-get-appointment-report', params, (file) => {
            downloadBlob(base64ToBlob(file.data, file.contentType), file.name);
        });
    }

    lobbyWaitingTime() {
        const params = {
            startDate: DateUtil.formatIsoDate(DateUtil.startOfMonth(this.state.date)),
            endDate: DateUtil.formatIsoDate(DateUtil.endOfMonth(this.state.date))
        };

        RemotingService.getRemoteCall('api/appointment/lobby-waiting-time-report', params, (file) => {
            downloadBlob(base64ToBlob(file.data, file.contentType), file.name);
        });
    }

    render() {
        return (
            <div style={{width: 450}}>
                <div className="container">
                    <div className="row">
                        <div className="col-3">
                            Month
                        </div>
                        <div className="col-9">
                            <PrimeDateInput containerStyle={{width: "100%"}}
                                            view="month" dateFormat="MM yy"
                                            disableFuture
                                            value={this.state.date}
                                            onChange={date => this.setState({date})}/>
                        </div>
                    </div>
                </div>
                <DialogActions>
                    <Button variant="success" size="sm" onClick={() => this.waitingTimeToGetAppointment()}>
                        <FontAwesomeIcon icon={["fas", "file-excel"]}/>
                        <span className="ml-2">Waiting Time To Get Appointment</span>
                    </Button>
                    <Button variant="success" size="sm" onClick={() => this.lobbyWaitingTime()}>
                        <FontAwesomeIcon icon={["fas", "file-excel"]}/>
                        <span className="ml-2">Lobby Waiting Time</span>
                    </Button>
                </DialogActions>
            </div>
        )
    }
}