import React, {Component} from "react";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import DateUtil from "../../../../utils/DateUtil";
import PageTitle from "../../../../layout/PageTitle";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import EnumCommunicationMethod from "./EnumCommunicationMethod";
import TimeOfDay from "./TimeOfDay";
import {Button, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal, TextareaInput} from "../../../../components";
import {noop, pick} from "lodash-es";
import EnumAppointmentRequestType from "./EnumAppointmentRequestType";
import ActionMenu from "../../../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../../../components/action-menu/ActionMenuItem";
import EditIcon from "../../../../components/action-menu/icons/EditIcon";
import {DateNavigation} from "../../../../components/datenavigation/DateNavigation";
import moment from "moment";

class DirectBillingRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            notesModalVisible: false,
            errorMessages: []
        }
    }

    componentDidMount() {
        this.retrieveAppointmentRequests();
    }

    retrieveAppointmentRequests(date = DateUtil.today()) {
        const dateValue = moment(date).format('YYYY-MM-DD');
        RemotingService.getRemoteCall(
            'api/insurance/coordinator/direct-billing-request/list',
            date? {startDate:dateValue , endDate:dateValue}: null,
            (result) => {
                this.setState({items: result.items});
            });
    }

    openNotesModal = (item) => {
        this.setState({
            selectedItem: item,
            notes: item.notes,
            notesModalVisible: true
        });
    }

    notesTemplate = (item) => {
        return <Button variant={"link"} style={{color: "#84BE56", fontSize: "12px"}}
                       onClick={() => this.openNotesModal(item)}>
            <FontAwesomeIcon icon={["fas", "edit"]} size={"sm"}/> Add Note
        </Button>;
    }

    dateSubmittedTemplate = (data) => {
        return DateUtil.formatDate(data.creationDate, "Do MMMM YYYY / hh:mm A");
    }

    patientNameTemplate = (data) => {
        return `${data.patient.firstName}${data.patient.middleName ? ` ${data.patient.middleName}`: ''} ${data.patient.lastName}`;
    }

    updateItemNotes = () => {
        const {selectedItem: item, notes} = this.state;
        this.updateItem(item, 'notes', notes, () => {
            this.setState({notesModalVisible: false});
        });
    }

    updateItem = (item, updatedField, newValue, callback = noop) => {
        const updateDto = {
            ...pick(item,
                ['notes', 'status']),
            [updatedField]: newValue
        };

        RemotingService.postRemoteCall(
            `api/insurance/coordinator/direct-billing-request/${item.id}`,
            updateDto,
            () => {
                item[updatedField] = newValue && Array.isArray(newValue) ? [...newValue] : newValue;
                let updatedItems = this.state.items.map(i => i.id === item.id ? item : i);
                callback();
                this.forceUpdate();
                this.setState({items: updatedItems});
            }, null, true, false);
    }


    renderNotesModal = () => {
        return (
            <Modal visible={this.state.notesModalVisible}
                   header="Notes"
                   submitContent="Save"
                   submitAction={() => this.updateItemNotes()}
                   closeAction={() => this.setState({notesModalVisible: false})}
                   submitDisabled={this.state.errorMessages.length > 0}
                   contentStyle={{minWidth: 400}}>

                <Container className={"InputModal"}>
                    <Row>
                        <Col xs={12}>
                            <TextareaInput style={{width: "100%", maxHeight: "100px"}}
                                           value={this.state.notes}
                                           onChange={(value) => this.setState({notes: value})}/>
                        </Col>
                    </Row>
                </Container>
            </Modal>
        )
    };

    actionsTemplate = (document) => {
        return (
            <ActionMenu>
                <ActionMenuItem text="Pending" icon={<EditIcon/>}
                                onClick={() => this.updateItem(document, 'status', "PENDING")}/>
                <ActionMenuItem text="Patient Contacted" icon={<EditIcon/>}
                                onClick={() => this.updateItem(document, 'status', "CONTACTED")}/>
                <ActionMenuItem text="Appointment Booked" icon={<EditIcon/>}
                                onClick={() => this.updateItem(document, 'status', "BOOKED")}/>
            </ActionMenu>
        );
    };

    handleDateStateChange = (date) => {
        this.retrieveAppointmentRequests(date);
    }

    render() {
        let filteredDirectBillingRequests = this.state.items.map(item => {
            const mappedBillingRequest = {...item};
            mappedBillingRequest.patientName = this.patientNameTemplate(item);
            mappedBillingRequest.insuranceName = item.company ? item.company.name : item.customCompany;
            mappedBillingRequest.tpaName = item.tpa ? item.tpa.name : item.customTpa;
            return mappedBillingRequest;
        })

        return (
            <>
                <div style={{padding: '10px', height: 78}}
                     className="data-table-header-wrapper">
                    <div className="float-left">
                        <PageTitle title="Direct Billing Appointment Requests" className="bg-white"/>
                    </div>
                    <div className="float-right">
                        <DateNavigation onDateChange={this.handleDateStateChange} displayDate={true}/>
                    </div>
                </div>
                <div style={{padding: '10px'}}>
                    <DataTable className="DirectBillingRequestsTable"
                               emptyMessage={"No items found"}
                               value={filteredDirectBillingRequests}
                               rows={20}
                               paginator={true}
                               paginatorPosition="bottom"
                               scrollable
                               style={{ maxWidth: '100%' }}
                    >
                        <Column header="Date Submitted"
                                headerStyle={{ width: '210px' }}
                                body={this.dateSubmittedTemplate}
                                sortable/>
                        <Column header="Patient Name"
                                headerStyle={{ width: '200px' }}
                                body={this.patientNameTemplate} sortable filter={true} filterMatchMode="contains" field="patientName"/>
                        <Column headerStyle={{ width: '80px' }} header="TPA" field="tpa.name" filter={true} filterMatchMode="contains" body={(data) => data.tpa ? data.tpa.name : data.customTpa} sortable/>
                        <Column header="Insurance"
                                headerStyle={{ width: '190px' }} field="insuranceName" filter={true} filterMatchMode="contains"
                                body={(data) => data.company ? data.company.name : data.customCompany} sortable/>
                        <Column header="Network" headerStyle={{ width: '140px' }} body={(data) => data.network ? data.network.name : data.customNetwork}
                                sortable/>
                        <Column header="Policy Name"    headerStyle={{ width: '120px' }} body={(data) => data.policyName} sortable/>
                        <Column header="Policy Number"    headerStyle={{ width: '110px' }} body={(data) => data.policyNumber} sortable/>
                        <Column header="Member ID"    headerStyle={{ width: '100px' }} filter={true} filterMatchMode="contains" field="memberId" body={(data) => data.memberId} sortable/>
                        <Column header="Mobile Phone"    headerStyle={{ width: '130px' }} filter={true} filterMatchMode="contains" field="patient.mobilePhone" sortable/>
                        <Column header="Email Address"    headerStyle={{ width: '190px' }} field="patient.email" sortable/>
                        <Column header="Preferred Communication Methods"
                                headerStyle={{ width: '200px' }}
                                body={data =>
                                    <div className="d-flex flex-column">
                                        {data.communicationMethods.map(key =>
                                            <div>{EnumCommunicationMethod[key].name}</div>
                                        )}
                                    </div>
                                }
                        />
                        <Column header="Preferred Contact Time"
                                headerStyle={{ width: '230px' }}
                                body={data =>
                                    <div className="d-flex flex-column">
                                        {data.bestTimesToContact.map(key =>
                                            <div>{`${TimeOfDay[key].name} ${TimeOfDay[key].dayPart} (${TimeOfDay[key].timeRange})`}</div>)}
                                    </div>
                                }/>
                        <Column header="Note" style={{width: '100px'}} body={this.notesTemplate}/>
                        <Column header="Status"
                                style={{width: '100px', textAlign: "center"}}
                                body={data => data.status ? EnumAppointmentRequestType[data.status].name : ''}/>
                        <Column header="Actions"
                                body={this.actionsTemplate}
                                style={{width: "60px", textAlign: "center"}}/>
                    </DataTable>
                </div>
                {this.renderNotesModal()}
            </>
        )
    }

}

export default DirectBillingRequest;