import {MultipleHeaderAccordion} from "../../../../../../components/accordion/MultipleHeaderAccordion";
import React from "react";
import ComplaintViewHistory from "./ComplaintViewHistory";
import SymptomViewHistory from "./SymptomViewHistory";
import TreatmentReceivedViewHistory from "./TreatmentReceivedViewHistory";
import ActivityLimitationViewHistory from "./ActivityLimitationViewHistory";
import OtherInjuriesViewHistory from "./OtherInjuriesViewHistory";
import LabImagingViewHistory from "./LabImagingViewHistory";
import ReviewOfSystemViewHistory from "./ReviewOfSystemViewHistory";
import MedicalViewHistory from "./MedicalViewHistory";
import FamilyViewHistory from "./FamilyViewHistory";
import SurgicalViewHistory from "./SurgicalViewHistory";
import LifeStyleViewHistory from "./LifeStyleViewHistory";
import PsychoSocialViewHistory from "./PsychoSocialViewHistory";
import BirthViewHistory from "./BirthViewHistory";
import MedicationUseViewHistory from "./MedicationUseViewHistory";
import GrayPageTitle from "../../GrayPageTitle";

export class SubjectiveHistory extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    render = () => {
        const visit = this.props.visit;

        return <>
            <MultipleHeaderAccordion
                headerText={{firstHeaderText: "Subjective"}}
                headerExist={{secondHeader: false, thirdHeader: false}}
                defaultExpanded={false}>

                {visit ?
                  <>
                    <div className="col-12">
                        <div className="row mb-2">
                            <div className="col-12">
                                <GrayPageTitle title="Complaints"/>
                                <ComplaintViewHistory complaint={visit.subjective.complaint}/>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-12">
                                <GrayPageTitle title="Symptoms"/>
                                <SymptomViewHistory complaint={visit.subjective.complaint}/>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-4">
                                <GrayPageTitle title="Previous Treatment Received"/>
                                <TreatmentReceivedViewHistory treatmentReceived={visit.subjective.treatmentReceived}/>
                            </div>

                            <div className="col-4">
                                <GrayPageTitle title="Activity Limitations"/>
                                <ActivityLimitationViewHistory activityLimitation={visit.subjective.activityLimitation}/>
                            </div>

                            <div className="col-4">
                                <GrayPageTitle title="Other Injuries"/>
                                <OtherInjuriesViewHistory otherInjuries={visit.subjective.otherInjuries}/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-4">
                                <GrayPageTitle title="Labs/Imaging"/>
                                <LabImagingViewHistory labsImaging={visit.subjective.labsImaging}/>
                            </div>

                            <div className="col-8">
                                <GrayPageTitle title="Review of Systems"/>
                                <ReviewOfSystemViewHistory reviewOfSystem={visit.subjective.reviewOfSystem}/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-4">
                                <GrayPageTitle title="Medical History"/>
                                <MedicalViewHistory medicalHistory={visit.subjective.medicalHistory}/>
                            </div>

                            <div className="col-4">
                                <GrayPageTitle title="Family History"/>
                                <FamilyViewHistory familyHistory={visit.subjective.familyHistory}/>
                            </div>

                            <div className="col-4">
                                <GrayPageTitle title="Surgical History"/>
                                <SurgicalViewHistory surgicalHistory={visit.subjective.surgicalHistory}/>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-4">
                                <GrayPageTitle title="Lifestyle"/>
                                <LifeStyleViewHistory lifestyle={visit.subjective.lifestyle}/>
                            </div>

                            <div className="col-4">
                                <GrayPageTitle title="Pyscho-Social History"/>
                                <PsychoSocialViewHistory lifestyle={visit.subjective.lifestyle}/>
                            </div>

                            <div className="col-4">
                                <GrayPageTitle title="Birth History"/>
                                <BirthViewHistory birthHistory={visit.subjective.birthHistory}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <GrayPageTitle title="Medications"/>
                                <MedicationUseViewHistory medicationUse={visit.subjective.medicationUse} prescriptions={visit.prescriptions}/>
                            </div>
                        </div>
                    </div>
                  </> : <div className="font-italic">No visit selected!</div>
                }
            </MultipleHeaderAccordion>
        </>
    }
}
