import React, {Component} from "react";
import EnumPatientSpecialStatus from "../../../enums/EnumPatientSpecialStatus";
import Tooltip from "../../../../../components/tooltip/Tooltip";
import FormField from "../../../../../components/form/FormField";
import {SelectInput} from "../../../../../components";
import ValidationUtil from "../../../../../components/form/validator/ValidationUtil";
import NotificationService from "../../../../../services/notification-service/NotificationService";
import RemotingService from "../../../../../services/remoting-service/RemotingService";
import {DialogActions} from "@material-ui/core";
import {Button} from "react-bootstrap";

export default class SpecialStatusUpdateModal extends Component {

    static LANGUAGE = "LANGUAGE";
    static MALE = "MALE";
    static FEMALE = "FEMALE";

    constructor(props) {
        super(props);
        let patientSpecialStatusList = [];
        let patientLanguageLimitList = [];

        if (this.props.specialStatusList) {
            this.props.specialStatusList.map(status => {
                if (status.specialStatus === "LANGUAGE" && status.languageLimits) {
                    patientLanguageLimitList = status.languageLimits.split(", ");
                }
                patientSpecialStatusList.push(status.specialStatus)
            })
        }

        this.state = {
            specialStatus: patientSpecialStatusList,
            newSpecialStatus: patientSpecialStatusList,

            specialStatusLanguageLimit: patientLanguageLimitList,
            newSpecialStatusLanguageLimit: patientLanguageLimitList
        }
    }

    update() {
        if (!ValidationUtil.isValid(this.formFields)) {
            NotificationService.showNotification({ severity: 'error', summary: 'Validation Error', detail: 'There are missing fields.' });
            return;
        }

        let dtoList = []
        let specialStatuslist = [...this.state.newSpecialStatus]
        specialStatuslist.map(specialStatus => {
            let dto = {
                specialStatus: specialStatus,
                languageLimits: specialStatus === SpecialStatusUpdateModal.LANGUAGE && this.state.newSpecialStatusLanguageLimit
                && this.state.newSpecialStatusLanguageLimit.length ? this.state.newSpecialStatusLanguageLimit.join(", ") : null
            }
            dtoList.push(dto)
        })

        RemotingService.postRemoteCall(`api/patient/${this.props.patientId}/update-special-status`, dtoList, (specialStatusList) => {
            this.props.close();
            this.props.onUpdate(specialStatusList);
        });
    }

    setSelectionOfNewSpecialStatus(ss) {
        const {newSpecialStatus} = this.state;

        if (newSpecialStatus.includes(ss)) {
            this.setState({newSpecialStatus: newSpecialStatus.filter(s => s !== ss)});

            if (ss === SpecialStatusUpdateModal.LANGUAGE) {
                this.setState({newSpecialStatusLanguageLimit: null});
            }
        } else {
            let tempCopy = [...newSpecialStatus];
            if (newSpecialStatus.includes(SpecialStatusUpdateModal.MALE) && ss === SpecialStatusUpdateModal.FEMALE){
                tempCopy = tempCopy.filter(specialStatus => specialStatus !== SpecialStatusUpdateModal.MALE)
            }
            else if (newSpecialStatus.includes(SpecialStatusUpdateModal.FEMALE) && ss === SpecialStatusUpdateModal.MALE){
                tempCopy = tempCopy.filter(specialStatus => specialStatus !== SpecialStatusUpdateModal.FEMALE)
            }
            tempCopy.push(ss);
            this.setState({newSpecialStatus: tempCopy});
        }
    }

    render() {
        this.formFields = [];

        return (
            <div>
                <label style={{margin: '0 0 15px 30px'}}>Please select special statuses of the patient:</label>
                {this.renderUpdateModalBodyIcons()}
                {this.renderUpdateModalLanguageLimitSelection()}
                <DialogActions>
                    <Button variant={"success"} onClick={() => this.update()}>Update</Button>
                </DialogActions>
            </div>
        )
    }

    renderUpdateModalBodyIcons() {
        const icons = [];
        for (const ss of EnumPatientSpecialStatus.keys()) {
            const specialStatusEnum = EnumPatientSpecialStatus[ss];
            icons.push(
                <Tooltip title={specialStatusEnum.name} key={specialStatusEnum.key}>
                    <specialStatusEnum.icon fill="#9aca3c"
                                            className={`special-status-icon selectable ${this.state.newSpecialStatus.includes(ss) ? 'selected' : ''}`}
                                            onClick={() => this.setSelectionOfNewSpecialStatus(ss)}/>
                </Tooltip>
            );
        }
        return (<div className="special-status-icon-wrapper" style={{margin: "0 20px"}}>{icons}</div>);
    }

    renderUpdateModalLanguageLimitSelection() {
        return (
            this.state.newSpecialStatus.includes("LANGUAGE") &&
            <div style={{margin: '20px 0 10px 30px', width: "470px"}}>
                <label>Please select preferred language of the patient:</label>
                <FormField ref={formField => this.formFields.push(formField)}
                           required
                           validateOn={this.state.newSpecialStatusLanguageLimit}>
                    <SelectInput searchEnabled={false} multiSelect={true}
                                 value={this.state.newSpecialStatusLanguageLimit} options={this.props.languages}
                                 onChange={(val) => {this.setState({newSpecialStatusLanguageLimit: val})}}/>
                </FormField>
            </div>
        );
    }

}