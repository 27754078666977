import React, {Component} from "react";
import "../../visithistory/VisitHistory.css";
import CurrentPastEnum from "../../subjective/enums/history/CurrentPastEnum";
import DateUtil from "../../../../../utils/DateUtil";
import DiagnosisEnum from "../../subjective/enums/history/DiagnosisEnum";
import VaccinationHistoryEnum from "../../subjective/enums/history/VaccinationHistoryEnum";
import ReportViewUtils from "./ReportViewUtils";

export default class MedicalHistoryReportView extends Component {
    render() {
        const {medicalHistory, isPrintVaccination} = this.props;

        if (ReportViewUtils.isMedicalHistoryNull(medicalHistory ) && ReportViewUtils.isVaccinationHistoryNull(medicalHistory)) {
            return null;
        }

        if(medicalHistory.none){
            return (<div>No Medical History</div>)
        }

        return (
            <div style={{paddingLeft: 10}}>
                {medicalHistory.diagnosisItems &&
                medicalHistory.diagnosisItems.map(item =>
                    <div className={"row"}>
                        <div className={"col-3"}>
                            <b>{CurrentPastEnum[item.currentPast]?.name}</b>
                        </div>
                        <div className={"col-9"}>
                            : {item.diagnosis}
                            {item.detail == null ? "" : (", " + item.detail)}
                            {item.date == null ? "" : (", " + DateUtil.formatDate(item.date, "YYYY"))}
                            ;{item.stableActive ? DiagnosisEnum[item.stableActive].name : ''}
                        </div>
                    </div>
                )}
                {medicalHistory.vaccinationHistory && isPrintVaccination &&
                <div className={"row"}>
                    <div className={"col-3"}>
                        <b>Vaccination</b>
                    </div>
                    <div className={"col-9"}>
                        : {VaccinationHistoryEnum[medicalHistory.vaccinationHistory].name}
                        {medicalHistory.vaccinationHistoryDetail ? '; ' + medicalHistory.vaccinationHistoryDetail : ''}
                    </div>
                </div>
                }
            </div>
        );
    }

}
