import Enum from "../../../../../../enums/Enum";

const PalpationLocationEnum = new Enum({
        ANTERIOR: {name: "Anterior"},
        POSTERIOR: {name: "Posterior"},
        LATERAL: {name: "Lateral"},
        MEDIAL: {name: "Medial"},
        SUPERIOR: {name: "Superior"},
        INFERIOR: {name: "Inferior"}
});

export default PalpationLocationEnum;