import React, {Component} from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import CreditGiftDataTable from "./CreditGiftDataTable";

export default class CreditGiftHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: "SENT"
        };
    }

    render() {
        const {patientId} = this.props;

        return (
            <Tabs activeKey={this.state.selectedTab}
                  onSelect={(key) => {this.setState({selectedTab: key})}}>
                <Tab eventKey="SENT" title="Sent">
                    {this.state.selectedTab === "SENT" && <SentGiftHistory patientId={patientId}/>}
                </Tab>
                <Tab eventKey="RECEIVED" title="Received">
                    {this.state.selectedTab === "RECEIVED" && <ReceivedGiftHistory patientId={patientId}/>}
                </Tab>
            </Tabs>
        )
    }

}

class SentGiftHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sentGifts: []
        };
    }

    componentDidMount() {
        const {patientId} = this.props;

        RemotingService.getRemoteCall('api/credit/sent-gift', {patientId}, (sentGifts) => {
            this.setState({sentGifts});
        });
    }

    render() {
        return (
            <CreditGiftDataTable data={this.state.sentGifts}/>
        )
    }
}

class ReceivedGiftHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            receivedGifts: []
        };
    }

    componentDidMount() {
        const {patientId} = this.props;

        RemotingService.getRemoteCall('api/credit/received-gift', {patientId}, (receivedGifts) => {
            this.setState({receivedGifts});
        });
    }

    render() {
        return (
            <CreditGiftDataTable data={this.state.receivedGifts}/>
        )
    }
}
