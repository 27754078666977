import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {Slider} from "@material-ui/core";
import Tooltip from "../../components/tooltip/Tooltip";
import PainSliderOptions from "./PainSliderOptions";
import RespondLastTreatmentOptions from "./RespondLastTreatmentOptions";

export const PainValueLabelComponent = (props) => {
    const { children, open, value } = props;
    const painValue = PainSliderOptions.getPainInfo(value);
    const painScaleMap = {
        0: "No Pain",
        3: "Mild",
        5: "Moderate",
        7: "Severe",
        10: "Maddening"
    };
    const title = painScaleMap[painValue];

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={title}>
            {children}
        </Tooltip>
    );
};

export const RespondValueLabelComponent = (props) => {
    const { children, open, value } = props;
    const painValue = RespondLastTreatmentOptions.getRespondInfo(value);
    const painScaleArray = ["Very Poor", "Poor", "Average", "Good", "Excellent"]
    const title = painScaleArray[painValue];

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={title}>
            {children}
        </Tooltip>
    );
};

export const PainSlider = withStyles({
    root: {
        color: '#c40202',
        height: 8,
    },
    thumb: {
        height: 24,
        width: 24,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -2,
        marginLeft: -12,
        '&:focus, &:hover, &$active': {
            boxShadow: 'inherit',
        },
    },
    active: {},
    markLabel: {
        paddingTop: "10px",
        fontSize: "9px",
    },
    track: {
        height: 20,
        borderRadius: 5,
    },
    rail: {
        height: 20,
        borderRadius: 5,
    },
})(Slider);

export default PainSlider;