import React, {Component} from 'react';
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import CheckboxInput from "../../../components/checkbox-input/CheckboxInput";
import FormField from "../../../components/form/FormField";
import {TextInput} from "../../../components";

class MakesSymptomsWorse extends Component {

    render() {
        const {
            selectedLanguage, readOnly, onChange, formFields,
            symptomWorseMovement, symptomWorseCold, symptomWorseStanding, symptomWorseSleep, symptomWorseDriving,
            symptomWorseSitting, symptomWorseClimbingStairs, symptomWorseOther, symptomsWorseOtherDetail
        } = this.props
        return (
            <div className="container">
                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Make_Symptoms_Worse[selectedLanguage]}
                    </div>
                    <div className="col-8 d-flex">
                        <div className="d-flex">
                            <CheckboxInput
                                value={symptomWorseMovement}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomWorseMovement", value)
                                }
                            />
                            <label className="mx-2">
                                {IntakeFormTextEnum.Movement_Exercise[selectedLanguage]}
                            </label>
                        </div>
                        <div className="d-flex">
                            <CheckboxInput
                                value={symptomWorseCold}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomWorseCold", value)
                                }
                            />
                            <label className="mx-2">
                                {IntakeFormTextEnum.Cold[selectedLanguage]}
                            </label>
                        </div>
                        <div className="d-flex">
                            <CheckboxInput
                                value={symptomWorseStanding}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomWorseStanding", value)
                                }
                            />
                            <label className="mx-2">
                                {IntakeFormTextEnum.Standing[selectedLanguage]}
                            </label>
                        </div>
                        <div className="d-flex">
                            <CheckboxInput
                                value={symptomWorseSleep}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomWorseSleep", value)
                                }
                            />
                            <label className="mx-2">
                                {IntakeFormTextEnum.Sleeping[selectedLanguage]}
                            </label>
                        </div>
                        <div className="d-flex">
                            <CheckboxInput
                                value={symptomWorseDriving}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomWorseDriving", value)
                                }
                            />
                            <label className="mx-2">
                                {IntakeFormTextEnum.Driving[selectedLanguage]}
                            </label>
                        </div>
                        <div className="d-flex">
                            <CheckboxInput
                                value={symptomWorseSitting}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomWorseSitting", value)
                                }
                            />
                            <label className="mx-2">
                                {IntakeFormTextEnum.Sitting[selectedLanguage]}
                            </label>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-4 dim14-form-label"></div>
                    <div className="col-8 d-flex pt-0">
                        <div className="d-flex">
                            <CheckboxInput
                                value={symptomWorseClimbingStairs}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("symptomWorseClimbingStairs", value)
                                }
                            />
                            <label className="mx-2">
                                {IntakeFormTextEnum.Climbing_Stairs[selectedLanguage]}
                            </label>
                        </div>
                        <CheckboxInput
                            value={symptomWorseOther}
                            disabled={readOnly}
                            onChange={(value) =>
                                onChange("symptomWorseOther", value)
                            }
                        />
                        <label className="mx-2">
                            {IntakeFormTextEnum.Symptoms_Other[selectedLanguage]}
                        </label>
                        {symptomWorseOther ? (
                            <FormField
                                ref={(formField) => formFields.push(formField)}
                                required
                                validateOn={
                                    readOnly ? true : symptomsWorseOtherDetail
                                }
                            >
                                <TextInput
                                    className="mx-2"
                                    value={symptomsWorseOtherDetail}
                                    disabled={readOnly}
                                    onChange={(value) =>
                                        onChange("symptomsWorseOtherDetail", value)
                                    }
                                />
                            </FormField>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default MakesSymptomsWorse;