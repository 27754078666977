import React, {Component} from "react";
import './DateSelect.css';
import {Button} from "react-bootstrap";
import DateUtil from "../../utils/DateUtil";
import PropTypes from "prop-types";
import moment from "../../utils/moment";
import {noop} from "lodash-es";

export default class DateSelect extends Component {

    static defaultProps = {
        dates: null,
        timezone: moment.tz.guess(),
        selectedIndex: null,
        onChange: noop
    };

    static propTypes = {
        dates: PropTypes.array,
        timezone: PropTypes.string,
        selectedIndex: PropTypes.number,
        onChange: PropTypes.func
    };

    render() {
        if (this.props.dates == null || this.props.dates.length === 0) {
            return (<></>);
        }

        let selectedIndex = this.props.selectedIndex != null ? this.props.selectedIndex : (this.props.dates.length - 1);

        return (
            <div className={"date-select-root"}>
                <Button className="direction-button"
                        variant="outline-secondary"
                        style={{marginRight: "5px"}}
                        disabled={selectedIndex === 0}
                        onClick={(event) => {
                            event.stopPropagation();
                            this.props.onChange(0);
                        }}>
                    ≪
                </Button>

                <Button className="direction-button"
                        variant="outline-secondary"
                        style={{marginRight: "5px"}}
                        disabled={selectedIndex === 0}
                        onClick={(event) => {
                            event.stopPropagation();
                            this.props.onChange(selectedIndex - 1);
                        }}>
                    &lt;
                </Button>

                <div className={"date-select-div"}>
                    <div className={"date-select-text"}>
                        {DateUtil.formatTzDate(this.props.dates[selectedIndex], this.props.timezone, "DD MMMM YYYY | hh:mm A")}
                    </div>
                </div>

                <Button className="direction-button"
                        variant="outline-secondary"
                        style={{marginLeft: "5px"}}
                        disabled={selectedIndex == (this.props.dates.length - 1)}
                        onClick={(event) => {
                            event.stopPropagation();
                            this.props.onChange(selectedIndex + 1);
                        }}>
                    &gt;
                </Button>

                <Button className="direction-button"
                        variant="outline-secondary"
                        style={{marginLeft: "5px"}}
                        disabled={selectedIndex == (this.props.dates.length - 1)}
                        onClick={(event) => {
                            event.stopPropagation();
                            this.props.onChange(this.props.dates.length - 1);
                        }}>
                    ≫
                </Button>

            </div>
        );
    }
}
