import React, {Component} from "react";
import '../Subjective.css';
import '../../EMR.css';
import './ComplaintView.css';
import DateUtil from "../../../../../utils/DateUtil";
import CurrentPastEnum from "../enums/history/CurrentPastEnum";
import DiagnosisEnum from "../enums/history/DiagnosisEnum";
import VaccinationHistoryEnum from "../enums/history/VaccinationHistoryEnum";
import {MedicalHistoryChecker} from "../checkers/MedicalHistoryChecker";
import {Grid} from "@material-ui/core";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";

export default class MedicalHistoryView extends Component {

    render() {
        let divs = this.getDivs();
        let vaccination = this.getVaccinationHistory();
        return (
            <Grid container alignItems={"center"} className={"content-card content-row m-0 w-100 p-2"}>
                <Grid item xs style={{maxWidth: 200}} className={"content-card-title"}>
                    <h6 className={"no-margin"}>Medical History {!this.props.dashboardView && <RedAsterisk className={"font-weight-bold"}
                                                                              appointmentType={this.props.appointmentType}
                                                                              firstVisit newComplaint/>}</h6>
                </Grid>
                <Grid item xs>
                    <div className="p-2 pl-3" style={{borderLeft: "1px gainsboro solid"}}>
                        {MedicalHistoryChecker.isNone(this.props.medicalHistory) ?
                            <div>
                                No Medical History
                            </div> : (
                                (divs != null && divs.length != 0) ? divs :
                                    <div style={{minHeight: "1rem"}}>
                                    </div>
                            )}
                        {vaccination}
                    </div>
                </Grid>
            </Grid>
        );
    }

    getDivs() {
        if (this.props.medicalHistory == null) {
            return null;
        }
        let divs = [];
        if (this.props.medicalHistory.diagnosisItems != null
            && this.props.medicalHistory.diagnosisItems.length != 0) {
            this.props.medicalHistory.diagnosisItems.forEach((item, index) => {
                divs.push(
                    <div key={"medicalHistoryViewDiv" + index}>
                        {[CurrentPastEnum[item.currentPast]?.name,
                        item.stableActive ? DiagnosisEnum[item.stableActive].name : null,
                        item.diagnosis,
                        item.date == null ? "" : (DateUtil.formatDate(item.date)),
                        item.detail == null ? "" : (item.detail)].filter(Boolean).join(", ")}
                    </div>);
            });
        }

        return divs;
    }

    getVaccinationHistory() {
        if (this.props.medicalHistory == null) {
            return null;
        }
        let text = "";
        if (this.props.medicalHistory.vaccinationHistory != null) {
            text += VaccinationHistoryEnum[this.props.medicalHistory.vaccinationHistory].name;
        }
        if (this.props.medicalHistory.vaccinationHistoryDetail != null
            && this.props.medicalHistory.vaccinationHistoryDetail != "") {
            if (text != "") {
                text += ", ";
            }
            text += this.props.medicalHistory.vaccinationHistoryDetail;
        }
        if (text != "") {
            return (
                <div key={"medicalHistoryVaccinationHistory"}>
                    Vaccination: {text}
                </div>);
        }
        return null;
    }
}