import React, {Component} from "react";
import {Modal, NumberInput} from "../../../components";
import moment from "moment";
import CreditConsentForm from "../../patient/patientdetail/credit/CreditConsentForm";
import {CreditSlider, ValueLabelComponent} from "../../patient/patientdetail/credit/CreditSlider";
import CreditOptions from "../../patient/patientdetail/credit/PatientCreditOptions";
import {Dropdown} from "primereact/dropdown";
import DateUtil from "../../../utils/DateUtil";
import RemotingService from "../../../services/remoting-service/RemotingService";
import AuthService from "../../../services/auth-service/AuthService";

export default class PublicCreditConsentForm extends Component {

    constructor(props) {
        super(props);
        this.maxAmounts = {'UAE': 10000, 'KUWAIT': 800};
        this.minAmounts = {'UAE': 1000, 'KUWAIT': 100};

        const {uaeSelected} = AuthService.getSelectedClinicCountriesInfo();
        const selectedCountry = uaeSelected ? "UAE" : "KUWAIT";
        this.cleanCreditState = {
            newCreditModalVisible: true,
            creditCountry: selectedCountry,
            creditAmount: this.minAmounts[selectedCountry],
            creditDiscountRate: 1,
            creditValidityPeriod: 8
        };
        this.state = {
            ...this.cleanCreditState
        };
    }

    componentDidMount() {
       const {form, consentFormCode} = this.props;

        if (form.creditId != -1) {
            RemotingService.getRemoteCall(
                `api/public/consent-form/code/${consentFormCode}/credit/${form.creditId}`,
                null,
                (credit) => {
                    this.setState({
                        creditCountry: credit.country,
                        creditAmount: credit.amount,
                        creditDiscountRate: credit.discountRate,
                        creditValidityPeriod: credit.validityPeriod
                    });
                });
        }
    };

    onClose = () => {
        this.setState({
            language: undefined,
        });
        this.props.close();
    }

    render() {
        let {form} = this.props;

        return (
            <>
                {this.renderNewCreditModal()}
                <CreditConsentForm
                    show={this.state.consentFormVisible}
                    close={() => this.setState({consentFormVisible: false})}
                    onFormComplete={this.handleConsentFormComplete}
                    form={form} fullScreen={true}
                    credit={this.state.credit}
                    language={this.props.language}
                />
            </>
        );
    }

    renderNewCreditModal() {
        return this.state.newCreditModalVisible ?
            <Modal visible={this.state.newCreditModalVisible}
                   fullScreen={true}
                   maxWidth={"lg"}
                   paperStyle={{overflowY: "auto"}}
                   scroll={'paper'}
                   header="New Credit"
                   submitContent="Validate"
                   submitAction={() => this.handleCreditInfoComplete()}
                   closeAction={() => this.hideNewCreditModal()}>
                {
                    <div className="col-11 mx-auto">
                        <div className="row mt-2">
                            <div className="col-12 text-center h3">
                                <span>Choose your Credit Amount for Discounted Rates</span>
                            </div>
                        </div>
                        <div className="row mt-1">

                            <div className="col-9 ">
                                <div className={"container-fluid h-100 border-2"}>


                                <div className="text-left mt-5">More commitment, more savings</div>
                                <div className="col-12 px-4">
                                    <CreditSlider
                                        ValueLabelComponent={ValueLabelComponent(this.state.creditCountry)}
                                        aria-label="custom thumb label"
                                        value={this.state.creditAmount}
                                        defaultValue={this.getMinAmountForCountry(this.state.creditCountry)}
                                        min={this.getMinAmountForCountry(this.state.creditCountry)}
                                        max={this.getMaxAmountForCountry(this.state.creditCountry)}
                                        step={50}
                                        marks={CreditOptions.creditMarks(this.state.creditCountry)}
                                        valueLabelDisplay="on"
                                        onChange={(event, value) => {
                                            this.setCreditInfo(value)
                                        }}
                                    />
                                </div>
                                </div>


                            </div>
                            <div className="col-3 p-0">

                           <div className={"container-fluid"}>
                               <div className={"col"}>


                            <div className={"row mb-3"}>
                                <div className={"col p-3 border-2 "}>
                                    <div className="text-center">Country</div>
                                    <div className="text-center">
                                        <Dropdown className={"w-100"} value={this.state.creditCountry}
                                                  options={[
                                                      {label: 'UAE', value: 'UAE'},
                                                      {label: 'Kuwait', value: 'KUWAIT'}
                                                  ]}
                                                  displayProperty="label" valueProperty="value"
                                                  onChange={(event) => {
                                                      this.setState({
                                                          creditCountry: event.value,
                                                          creditAmount: this.getMinAmountForCountry(event.value),
                                                          creditDiscountRate: 1,
                                                          creditValidityPeriod: 8
                                                      })
                                                  }}
                                        />
                                    </div>

                                </div>
                            </div>
                               <div className={"row mb-3"}>
                                   <div className={"col  p-3 border-2 "}>

                                           <div className="text-center">Credit</div>
            <div className={"d-flex align-items-center"}>
                <div className={"col  "}>
                    <NumberInput style={{
                        fontWeight: 'bold',
                        textAlign: 'center',
                        paddingLeft:'inherit',
                        padding:'4px 8px',
                        minWidth:'100%'

                    }}
                                 min={0}
                                 max={this.getMaxAmountForCountry(this.state.creditCountry)}
                                 value={this.state.creditAmount}
                                 onChange={(value) => {
                                     this.setCreditInfo(value)
                                 }}/>
                </div>
                <div className={"col-auto"}>
                    <b>{getCurrency(this.state.creditCountry)}</b>
                </div>

            </div>


                                   </div>
                               </div>
                               <div className={"row mb-3"}>
                                   <div className={"col  border-2 "}>

                                       <div className="text-center">Discount</div>
                                       <div className="text-center"><b>{this.state.creditDiscountRate}%</b></div>

                                   </div>
                               </div>
                               <div className={"row"}>
                                   <div className={"col  border-2 "}>
                                       <div className="text-center">Validity</div>
                                       <div className="text-center">
                                           <b>{DateUtil.formatDateMMM(moment().add(this.state.creditValidityPeriod, 'week'))}</b>
                                       </div>
                                       <div className="text-center">({this.state.creditValidityPeriod} weeks)</div>
                                   </div>
                               </div>

                               </div>
                           </div>
                            </div>


                        </div>
                    </div>
                }
            </Modal>
            : null;
    }

    handleConsentFormComplete = (consentForm) => {
        this.state.credit.consentForm = consentForm;
        const patientId = this.props.form.patientId;
        const code = this.props.consentFormCode;
        const targetUrl = `api/public/consent-form/code/${code}/patient/${patientId}/${this.state.credit.id === -1 ? "create-credit" : "update-credit"}`;

        RemotingService.postRemoteCall(targetUrl, this.state.credit,
            () => {
                this.setState({
                    ...this.cleanCreditState,
                    consentFormVisible: false,
                    newCreditModalVisible : false
                });
            });
    }

    handleCreditInfoComplete = () => {
        const credit = {
            id:this.props.form.creditId,
            country: this.state.creditCountry,
            amount: this.state.creditAmount,
            discountRate: this.state.creditDiscountRate,
            validityDate: moment.utc().startOf('day').add(this.state.creditValidityPeriod, "week").toDate(),
            validityPeriod: this.state.creditValidityPeriod
        };

        this.setState({
            credit,
            newCreditModalVisible: false,
            consentFormVisible: true,
        });
    }

    hideNewCreditModal() {
        this.setState({newCreditModalVisible : false});
    }

    setCreditInfo(inputValue) {
        let maxAmountForCountry = this.getMaxAmountForCountry(this.state.creditCountry);
        let value = inputValue > maxAmountForCountry ? maxAmountForCountry : inputValue;
        const creditInfo = CreditOptions.getCreditInfo(value, this.state.creditCountry);
        this.setState({...creditInfo});
    }

    getMinAmountForCountry(country) {
        return this.minAmounts[country];
    }

    getMaxAmountForCountry(country) {
        return this.maxAmounts[country];
    }

}

const getCurrency = (country) => {
    switch (country) {
        case 'UAE':
            return 'AED';
        case 'KUWAIT':
            return 'KWD';
        default:
            return 'Unknown'
    }
}