import EventAppointmentMap from "../EventAppointmentMap";
import React from "react";
import AppointmentEvent from "./AppointmentEvent";
import BreakEvent from "./BreakEvent";
import LunchEvent from "./LunchEvent";
import AvailableSlotEvent from "./AvailableSlotEvent";
import DefaultEvent from "./DefaultEvent";
import {Views} from "react-big-calendar";
import ManagementEvent from "./ManagementEvent";
import EnumLeaveType from "../../hr/EnumLeaveType";
import EnumLeaveStatus from "../../hr/EnumLeaveStatus";

export default function AppointmentCalendarEventFactory({
                                                            timeZoneGetter,
                                                            viewGetter,
                                                            highlightedAppointmentIdGetter,
                                                            covidGapExists,
                                                            appointmentActionsVisible,
                                                            refreshCalendarData,
                                                            cancelAppointment,
                                                            confirmAppointment,
                                                            checkInAppointment,
                                                            uncheckInAppointment,
                                                            deleteAppointment,
                                                            showAppointmentContextMenu,
                                                            showEventContextMenu,
                                                            eventContextMenuVisible,
                                                            deleteVisible,
                                                            deleteFunc,
                                                            leaveExists,
                                                            staffRoomMapGetter
                                                        }) {

    return (props) => {
        const event = props.event;
        const appointment = EventAppointmentMap ? EventAppointmentMap.get(event.id) : undefined;
        const smallView = viewGetter() === Views.MONTH;
        covidGapExists = covidGapExists == undefined ? true : covidGapExists;

        if (event.isLeaveOverlayEvent) {
            return <div className="calendar-event"
                        style={{
                            backgroundColor: "gray",
                            opacity: "70%",
                            cursor: "default",
                            alignItems: "center"
                        }}
                        onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}>
                <span className="font-size-medium font-weight-bold">{EnumLeaveType[event.type].name.toUpperCase()}</span>
                {event.type !== "CLOSED" ? <span className="font-size-small">{EnumLeaveStatus[event.status].name}</span> : null}
            </div>
        } else if (appointment) {
            return <AppointmentEvent {...props}
                                     appointment={appointment}
                                     highlighted={appointment.id == highlightedAppointmentIdGetter()}
                                     timeZoneGetter={timeZoneGetter}
                                     smallView={smallView}
                                     covidGapExists={covidGapExists}
                                     appointmentActionsVisible={appointmentActionsVisible}
                                     cancelAppointment={cancelAppointment}
                                     confirmAppointment={confirmAppointment}
                                     checkInAppointment={checkInAppointment}
                                     uncheckInAppointment={uncheckInAppointment}
                                     deleteAppointment={deleteAppointment}
                                     showAppointmentContextMenu={showAppointmentContextMenu}
                                     showEventContextMenu={showEventContextMenu}
                                     eventContextMenuVisible={eventContextMenuVisible}
                                     deleteVisible={deleteVisible}
                                     deleteFunc={deleteFunc}
                                     leaveExists={leaveExists}/>
        } else if (event.type === "NEW_PATIENT" || event.type === "FOLLOW_UP") {
            return <AvailableSlotEvent {...props}
                                       timeZoneGetter={timeZoneGetter}
                                       smallView={smallView}
                                       covidGapExists={covidGapExists}
                                       appointmentActionsVisible={appointmentActionsVisible}
                                       showEventContextMenu={showEventContextMenu}
                                       eventContextMenuVisible={eventContextMenuVisible}
                                       refreshCalendarData={refreshCalendarData}
                                       deleteVisible={deleteVisible}
                                       deleteFunc={deleteFunc}/>
        } else if (event.type === "LUNCH") {
            return <LunchEvent {...props}
                               timeZoneGetter={timeZoneGetter}
                               smallView={smallView}
                               appointmentActionsVisible={appointmentActionsVisible}
                               showEventContextMenu={showEventContextMenu}
                               eventContextMenuVisible={eventContextMenuVisible}
                               refreshCalendarData={refreshCalendarData}
                               deleteVisible={deleteVisible}
                               deleteFunc={deleteFunc}
                               staffRoomMapGetter={staffRoomMapGetter}/>
        } else if (event.type === "MANAGEMENT") {
            return <ManagementEvent {...props}
                                    timeZoneGetter={timeZoneGetter}
                                    smallView={smallView}
                                    appointmentActionsVisible={appointmentActionsVisible}
                                    showEventContextMenu={showEventContextMenu}
                                    eventContextMenuVisible={eventContextMenuVisible}
                                    refreshCalendarData={refreshCalendarData}
                                    deleteVisible={deleteVisible}
                                    deleteFunc={deleteFunc}
                                    staffRoomMapGetter={staffRoomMapGetter}/>
        } else if (event.type === "BREAK") {
            return <BreakEvent {...props}
                               timeZoneGetter={timeZoneGetter}
                               smallView={smallView}
                               appointmentActionsVisible={appointmentActionsVisible}
                               showEventContextMenu={showEventContextMenu}
                               eventContextMenuVisible={eventContextMenuVisible}
                               deleteVisible={deleteVisible}
                               deleteFunc={deleteFunc}
                               staffRoomMapGetter={staffRoomMapGetter}/>
        } else {
            return <DefaultEvent {...props}
                                 timeZoneGetter={timeZoneGetter}
                                 smallView={smallView}
                                 deleteVisible={deleteVisible}
                                 deleteFunc={deleteFunc}/>
        }
    }
}
