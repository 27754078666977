import {ItemsChecker} from "../../../../recordpage/assessment/checkers/ItemsChecker";
import {ClinicalImpressionChecker} from "../../../../recordpage/diagnosis/checkers/ClinicalImpressionChecker";
import {TreatmentInfoChecker} from "../../../../recordpage/treatment/checkers/TreatmentInfoChecker";
import {RedFlagChecker} from "../../../../recordpage/subjective/checkers/RedFlagChecker";
import {DiagnosisProcedureChecker} from "../../../../recordpage/diagnosis/checkers/DiagnosisProcedureChecker";
import {DiagnosisChecker} from "../../../../recordpage/diagnosis/checkers/DiagnosisChecker";
import {NotEmptyChecker} from "../../../../recordpage/common/checkers/NotEmptyChecker";
import {OrChecker} from "../../../../recordpage/common/checkers/OrChecker";
import {RiskChecker} from "../../../../recordpage/diagnosis/checkers/RiskChecker";
import {MedicalHistoryChecker} from "../../../../recordpage/subjective/checkers/MedicalHistoryChecker";
import {FamilyHistoryChecker} from "../../../../recordpage/subjective/checkers/FamilyHistoryChecker";
import {SurgicalHistoryChecker} from "../../../../recordpage/subjective/checkers/SurgicalHistoryChecker";

const MissingDataFollowUp = [
    {path: "subjective.medicalHistory", checker: MedicalHistoryChecker},
    {path: "subjective.familyHistory", checker: FamilyHistoryChecker},
    {path: "subjective.surgicalHistory", checker: SurgicalHistoryChecker},
    {path: "subjective.lifestyle.drinkingStatus", checker: NotEmptyChecker},
    {path: "subjective.lifestyle.smokingStatus", checker: NotEmptyChecker},
    {
        path: "subjective.lifestyle.physicalActivity",
        checker: NotEmptyChecker,
        labels: ["Subjective", "Lifestyle", "Physical Activity / Exercise"]
    },
    {path: "subjective.lifestyle.occupation", checker: NotEmptyChecker},
    {path: "subjective.lifestyle.employmentStatus", checker: NotEmptyChecker},
    {path: "subjective.lifestyle.educationLevel", checker: NotEmptyChecker},
    {
        path: "subjective.lifestyle.psychoSocialHistory",
        checker: NotEmptyChecker,
        labels: ["Subjective", "Lifestyle", "Psycho - Social History"]
    },
    {path: "subjective.complaint.complaint", checker: NotEmptyChecker},
    {path: "subjective.complaint.pain", checker: NotEmptyChecker},
    {path: "subjective.complaint", checker: RedFlagChecker, labels: ["Subjective", "Complaint", "Red Flag"]},

    {path: "assessment.vitalSigns.temperature", checker: NotEmptyChecker},
    {path: "assessment.vitalSigns.pulse", checker: NotEmptyChecker},
    {path: "assessment.vitalSigns.bloodPressureAbove", checker: NotEmptyChecker},
    {path: "assessment.vitalSigns.bloodPressureBelow", checker: NotEmptyChecker},
    {path: "assessment.vitalSigns.height", checker: NotEmptyChecker},
    {path: "assessment.vitalSigns.weight", checker: NotEmptyChecker},
    {path: "assessment.observation", checker: ItemsChecker},

    {path: "diagnosis.diagnosisGroups", checker: DiagnosisChecker},
    {path: "diagnosis.clinicalImpression", checker: ClinicalImpressionChecker},
    {path: "diagnosis.risk", checker: RiskChecker},
    {
        path: "diagnosis",
        checker: OrChecker([
            {path: "directBillingProcedures", checker: DiagnosisProcedureChecker},
            {path: "selfPayProcedures", checker: DiagnosisProcedureChecker}
        ]),
        labels: ["Diagnosis", "Procedures"]
    },

    {path: "treatment.response.overallImproveValue", checker: NotEmptyChecker, labels: ["Treatment", "Response To Treatment", "Overall Improve By"]},
    {path: "treatment.response.romImproveValue", checker: NotEmptyChecker, labels: ["Treatment", "Response To Treatment", "Rom Improve By"]},
    {path: "treatment.response.painScoreValue", checker: NotEmptyChecker, labels: ["Treatment", "Response To Treatment", "Pain Score"]},
    {path: "treatment.treatmentReceived", checker: TreatmentInfoChecker},
    {path: "treatment.patientEducation", checker: ItemsChecker}
];

export default MissingDataFollowUp;
