import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import {FileUploader, Modal} from "../../../../components";

const PHOTO = 'PHOTO';

class PatientProfileImage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            photoModalVisible: false,
            photoValue: null,
            newPhotoValue: null,
            photoChanged: false,
        };
    }

    componentDidMount() {
        this.retrievePhoto();
    }

    retrievePhoto = () => {
        RemotingService.getRemoteCall('api/patient/' + this.props.patientId + '/document/' + PHOTO, null, (dto) => {
            this.setState({photoValue: dto, newPhotoValue: dto});
        });
    };

    showPhotoUpdate = () => {
        this.setState({photoModalVisible: true, photoChanged: false});
    }

    renderPhotoUpdateModal = () => {
        return this.state.photoModalVisible ?
            (<Modal visible={this.state.photoModalVisible}
                    header="Photo"
                    submitContent="Update"
                    submitAction={this.handlePhotoUpdate}
                    closeAction={() => this.setState({
                        photoModalVisible: false,
                        newPhotoValue: this.state.photoValue
                    })}
            >
                {this.photoUpdateModalBody()}
            </Modal>)
            : null;
    }

    photoUpdateModalBody = () => {
        return (
            <div style={{paddingTop: '5px'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignContent: 'space-between',
                        justifyContent: 'center'
                    }}>
                        <FileUploader
                            value={this.state.newPhotoValue}
                            onChange={(value) => this.handlePhotoChange(value)}/>
                    </div>
                </div>
            </div>
        );
    }

    handlePhotoChange = (value) => {
        this.setState({newPhotoValue: value, photoChanged: true});
    }

    handlePhotoUpdate = () => {
        if (this.state.photoChanged) {
            this.uploadFile(PHOTO, this.state.newPhotoValue, (fileValue) => {
                this.setState({photoValue: fileValue})
            });
        }

        this.setState({photoModalVisible: false});
    }

    uploadFile = (documentType, fileValue, callBack = () => {
    }) => {
        if (fileValue) {
            RemotingService.postRemoteCall('api/patient/' + this.props.patientId + '/document/' + documentType, fileValue, () => {
                this.setState({photoModalVisible: false});
                callBack(fileValue);
            });
        } else {
            RemotingService.deleteRemoteCall('api/patient/' + this.props.patientId + '/document/' + documentType, null, () => {
                this.setState({photoModalVisible: false});
                callBack(fileValue);
            });
        }
    }

    render() {
        const photoValue = this.state.photoValue;
        const photoSrc = photoValue ? `data:image/jpeg;base64,${photoValue.content}` : `${process.env.PUBLIC_URL}/assets/patient_profile.png`;

        return (
            <div className="profile-image-container">
                <img className="profile-image" src={photoSrc} alt=""/>
                <div className="profile-image-overlay">
                    <FontAwesomeIcon icon={["fas", "camera"]} onClick={this.showPhotoUpdate}/>
                </div>

                {this.renderPhotoUpdateModal()}
            </div>
        );
    }

}

export default PatientProfileImage;