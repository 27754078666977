import Enum from "../../../../../../enums/Enum";

const ShortTermGoalEnum = new Enum({
    G1: {name: "Improve function/reduce pain"},
    G2: {name: "Return to normal activities of daily living (ADLs) with little to no pain"},
    G3: {name: "To have a safe and gradual return to sport with minimal risk of re-injury"},
    G4: {name: "To improve quality of life and return to normal activities"}
});

export default ShortTermGoalEnum;
