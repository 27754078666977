import React, {Component} from "react";
import '../../EMR.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TopicEnum from "../enums/patienteducation/TopicEnum";
import FocusEnum from "../enums/patienteducation/FocusEnum";

export default class PatientEducationView extends Component {

    render() {
        return (
            <div className="content-card pl-2">
                {this.props.patientEducation.items &&
                <div className={"content-card m-0 mt-2"}>
                    <table className={"emr-table"}>
                        <tbody>
                        <tr>
                            <th style={{minWidth: "100px"}}>Topic</th>
                            <th style={{minWidth: "100px"}}>Focus</th>
                            <th>Rationale</th>
                        </tr>
                        {this.props.patientEducation.items.map((m, index) => {
                            return (<tr key={"patientEducationView" + index} style={{backgroundColor: "white"}}>
                                <td>{m.topic == null ? "" : TopicEnum[m.topic].name}</td>
                                <td>{m.focus == null ? "" : FocusEnum[m.focus].name}</td>
                                <td>{m.topic == null ? "" : TopicEnum[m.topic].rationale}</td>
                            </tr>)
                        })}
                        </tbody>
                    </table>
                </div>
                }

                {this.props.patientEducation && this.props.patientEducation.understoodContent != null &&
                <div className={"row m-0 mt-2"}>
                    <div className={"col-7 font-weight-bold"}>Has the patient understood the education content provided?</div>
                    <div className={"col-2"}>
                        {this.yesNoTemplate(this.props.patientEducation.understoodContent)}
                    </div>
                    <div className={"col-3"}>{this.props.patientEducation.understoodContentDetail}</div>
                </div>
                }

                {this.props.patientEducation && this.props.patientEducation.moreExplanation != null &&
                <div className={"row m-0 mt-2"}>
                    <div className={"col-7 font-weight-bold"}>Does the patient need more explanation during the next visit?</div>
                    <div className={"col-2"}>
                        {this.yesNoTemplate(this.props.patientEducation.moreExplanation)}
                    </div>
                    <div className={"col-3"}>{this.props.patientEducation.moreExplanationDetail}</div>
                </div>
                }
            </div>
        );
    }

    yesNoTemplate = (field) => {
        let yesNoDiv;
        if (field) {
            yesNoDiv = (
                <div>
                    <FontAwesomeIcon icon={["fas", "check"]} color={"#7aae00"} style={{marginRight: "5px"}}/>
                    Yes
                </div>
            )
        } else {
            yesNoDiv = (
                <div>
                    <FontAwesomeIcon icon={["fas", "times"]} color={"red"} style={{marginRight: "8px"}}/>
                    No
                </div>
            )
        }

        return yesNoDiv;
    }
}