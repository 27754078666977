import React, {Component} from "react";
import '../../../EMR.css';

export default class TreatmentResponseHistoryView extends Component {

    state = {};

    render() {
        const response = this.props.response;

        if (!response) {
            return null;
        }

        return (
            <div className='row no-margin'>
                <div className="col-4 d-flex flex-column pl-3">
                    <div><b>Overall Improve By: </b>{response.overallImproveValue}%</div>
                    {response.overallImproveDetails}
                </div>
                <div className="col-4 d-flex flex-column pl-3">
                    <div><b>ROM Improve By: </b>{response.romImproveValue}%</div>
                    {response.romImproveDetails}
                </div>
                <div className="col-4 d-flex flex-column pl-3">
                    <div><b>Pain Score (VAS): </b>{response.painScoreValue}</div>
                    {response.painScoreDetails}
                </div>
            </div>
        );
    }

}
