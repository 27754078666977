import React from 'react'
import {Button, ButtonGroup} from "react-bootstrap";
import "./DateNavigation.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DateInput} from "../index";
import DateUtil from "../../utils/DateUtil";

export class DateNavigation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {date: this.props.date || DateUtil.today()};
        this.displayDate = props.displayDate;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.date !== this.props.date) {
            this.setState({date: this.props.date});
        }
    }

    calendarText() {
        return this.displayDate ? DateUtil.formatDateMMM(this.state.date) : "Go to Date"
    }

    setDateStateAndNotifyChange = (date) => {
        this.setState({date}, this.props.onDateChange(date))
    }

    render() {
        const date = this.state.date;
        const activeClassName = "DateNavigationActiveButton";
        const classBack = DateUtil.isYesterday(date) ? activeClassName : "";
        const classToday = DateUtil.isToday(date) ? activeClassName : "";
        const classNext = DateUtil.isTomorrow(date) ? activeClassName : "";

        return (
            <>
                <ButtonGroup aria-label="Date Buttons" className="DateNavigationButtonGroup">
                    <Button variant="outline-secondary"
                            className={classBack}
                            onClick={() => {
                                this.setDateStateAndNotifyChange(DateUtil.addDays(this.state.date, -1))
                            }}>
                        Back
                    </Button>
                    <Button variant="outline-secondary"
                            className={classToday}
                            onClick={() => {
                                this.setDateStateAndNotifyChange(DateUtil.today())
                            }}>
                        Today
                    </Button>
                    <Button variant="outline-secondary"
                            className={classNext}
                            onClick={() => {
                                this.setDateStateAndNotifyChange(DateUtil.addDays(this.state.date, 1))
                            }}>
                        Next
                    </Button>
                </ButtonGroup>

                <ButtonGroup aria-label="Date Buttons" className="DateNavigationButtonGroup">
                    <DateInput disableToolbar="true"
                               variant="dialog"
                               value={this.state.date}
                               onChange={this.setDateStateAndNotifyChange}
                               TextFieldComponent={
                                   ({inputProps, onClick, inputRef}) =>
                                       <Button variant="outline-secondary"
                                               className="RoomCalendarTopBarButtonGroup"
                                               inputProps={inputProps} onClick={onClick} inputRef={inputRef}>
                                           <FontAwesomeIcon icon={["fas", "calendar-alt"]}/> {this.calendarText()}
                                       </Button>
                               }>
                    </DateInput>
                </ButtonGroup>
            </>
        );
    }
}
