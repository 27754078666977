import Enum from "../../../../../../enums/Enum";

const MuscleStrengthResultEnum = new Enum({
    Normal: {name: "Normal"},
    Weak: {name: "Weak"},
    Absent: {name: "Absent"},
    Hypertonic: {name: "Hypertonic"},
    Hypotonic: {name: "Hypotonic"}
});

export default MuscleStrengthResultEnum;