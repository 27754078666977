import React, {Component} from "react";
import '../../EMR.css';
import TypeEnum from "../enums/respiratorypattern/TypeEnum";
import AreaEnum from "../enums/respiratorypattern/AreaEnum";
import FindingEnum from "../enums/respiratorypattern/FindingEnum";
import StringUtil from "../../../../../utils/StringUtil";

export default class RespiratoryPatternView extends Component {

    render() {
        if(this.props.respiratoryPattern == null
            || this.props.respiratoryPattern.items == null) {
            return null;
        }

        return(
            <div style={{backgroundColor: "rgb(245,245,245)"}}>
                {this.props.respiratoryPattern.items.map(item => {
                    return (
                        <div>
                            {TypeEnum[item.type].name}, {AreaEnum[item.area].name}, {FindingEnum[item.finding].name}{StringUtil.isNullOrEmpty(item.detail) ? "" : (", " + item.detail)}
                        </div>
                    );
                })}
            </div>
        );
    }
}