import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ListItemText} from "@material-ui/core";
import {AccordionBody, AccordionHeader, AccordionPanel, List} from "../../components";
import RemotingService from "../../services/remoting-service/RemotingService";

class SpecialityAndStaffFilter extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            specialityList: [],
            selectedSpecialityList: this.props.selectedSpecialityList || [],
            staffList: [],
            selectedStaffList: this.props.selectedStaffList || [],
        };
    }

    componentDidMount() {
        this.retrieveInitialData(({specialities, staffs}) => {
            this.setState({
                specialityList: specialities,
                staffList: staffs,
                selectedStaffList: this.props.singleStaffSelection ? [] : staffs,
                selectedSpecialityList: this.props.singleStaffSelection ? specialities : []
            }, () => {
                this.props.onStaffSelectionChange(staffs);
            });
        })
    }

    retrieveInitialData(callback) {
        const specialityPromise = new Promise((resolve, reject) => {
            RemotingService.getRemoteCall('api/speciality/list', null, (specialities) => {
                resolve(specialities);
            });
        });

        const staffPromise = new Promise((resolve, reject) => {
            RemotingService.getRemoteCall('api/staff/list-base', null, (result) => {
                resolve(result.items);
            })
        });

        Promise.all([specialityPromise, staffPromise]).then((values) => {
            callback({specialities: values[0], staffs: values[1]});
        });
    }

    handleExpand = (panel) => (event, newExpanded) => {
        this.setState({expandedFilterPanel: newExpanded ? panel : false});
    };

    renderStaff = (staff, index) => {
        return (
            <ListItemText id={`list-item-${index}`} primary={staff.name} secondary={staff.specialityName}/>
        );
    };

    render() {
        const {specialityList, selectedSpecialityList, staffList, selectedStaffList, expandedFilterPanel} = this.state;

        let specialityFilteredStaffList = staffList.filter(staff => selectedSpecialityList.some(selectedSpeciality => selectedSpeciality.name === staff.specialityName)===true)

        return (
            <div className="SpecialityAndStaffFilter" style={{marginTop: 19}}>
                <AccordionPanel square expanded={expandedFilterPanel === 'speciality'}
                                onChange={this.handleExpand('speciality')}>
                    <AccordionHeader
                        expandIcon={<FontAwesomeIcon icon={["fas", "chevron-down"]}/>}>Speciality</AccordionHeader>
                    <AccordionBody>
                        <List data={specialityList} selectAll={true}
                              selectable={true} selected={selectedSpecialityList}
                              onChange={(values) => {
                                  this.setState({selectedSpecialityList: values});
                                  this.props.onSpecialitySelectionChange(values)
                              }}
                              labelField="name"/>
                    </AccordionBody>
                </AccordionPanel>
                <AccordionPanel square expanded={expandedFilterPanel === 'staff'}
                                onChange={this.handleExpand('staff')}>
                    <AccordionHeader
                        expandIcon={<FontAwesomeIcon icon={["fas", "chevron-down"]}/>}>Staff</AccordionHeader>
                    <AccordionBody>
                        {
                            !this.props.singleStaffSelection && (
                                <List data={staffList} selectAll={true}
                                      selectable={true} selected={selectedStaffList}
                                      onChange={(values) => {
                                          this.setState({selectedStaffList: values});
                                          this.props.onStaffSelectionChange(values)
                                      }}
                                      listItemFunc={(staff, index) => this.renderStaff(staff, index)}/>
                            )
                        }
                        {
                            this.props.singleStaffSelection && (
                                <List data={specialityFilteredStaffList} selectAll={false}
                                      selectable={true} selected={selectedStaffList}
                                      onChange={(values) => {
                                          let updatedValues = values.length > 0 ? values[values.length-1] : []
                                          this.setState({selectedStaffList: [updatedValues]});
                                          this.props.onStaffSelectionChange([updatedValues])
                                      }}
                                      listItemFunc={(staff, index) => this.renderStaff(staff, index)}/>
                            )
                        }
                    </AccordionBody>
                </AccordionPanel>
            </div>
        )
    }
}

export default SpecialityAndStaffFilter;
