import Enum from "../../../../../../enums/Enum";

const SleepingPatternEnum = new Enum({
    LIGHT_SLEEPER: {name: "Light Sleeper"},
    GOOD_SLEEPER: {name: "Good Sleeper"},
    DEEP_SLEEPER: {name: "Deep Sleeper"},
    WAKE_UP_FEELING_TIRED: {name: "Wake-Up Feeling Tired"},
    WAKE_UP_FEELING_FRESH: {name: "Wake-Up Feeling Fresh"}
});

export default SleepingPatternEnum;