import React, {Component} from 'react';
import PropTypes from "prop-types";
import {InputNumber} from "primereact/inputnumber";

class PrimeInputNumber extends Component {

    static defaultProps = {
        id: null,
        value: null,
        min: null,
        max: null,
        placeholder: null,
        disabled: false,
        mode: "decimal",
        allowNegativeNumber: true,
        allowOnlyIntegers: false,
        step: null,
        inputClassName: "",
        inputStyle: null
    };

    static propTypes = {
        id: PropTypes.string,
        value: PropTypes.number,
        min: PropTypes.number,
        max: PropTypes.number,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
        mode: PropTypes.string,
        onChange: PropTypes.func,
        allowNegativeNumber: PropTypes.bool,
        allowOnlyIntegers: PropTypes.bool,
        step: PropTypes.number,
        inputClassName: PropTypes.string,
        inputStyle: PropTypes.object
    }

    render() {
        const {id, value, min, max, placeholder, mode, disabled, step, inputClassName, inputStyle} = this.props;

        return (
            <InputNumber id={id}
                         value={value}
                         onChange={(e) => this.onChange(e.value)}
                         min={min}
                         max={max}
                         placeholder={placeholder}
                         mode={mode}
                         disabled={disabled}
                         step={step}
                         inputClassName={inputClassName}
                         inputStyle={inputStyle}
            />
        );
    }

    onChange = (value) => {
        const {onChange} = this.props;
        if (this.isValueFollowingRules(value)) {
            onChange(value);
        } else {
            this.forceUpdate();
        }
    }

    isValueFollowingRules = (value) => {
        const {allowNegativeNumber, allowOnlyIntegers} = this.props;
        let isValid = true;

        if (allowNegativeNumber === false && value < 0) {
            isValid = false;
        }

        if (allowOnlyIntegers === true && (value != null && !Number.isInteger(value))) {
            isValid = false;
        }

        return isValid;
    }
}

export default PrimeInputNumber;