import React from 'react';
import {connect} from "react-redux";
import StringUtil from "../../utils/StringUtil";
import RemotingService from "../../services/remoting-service/RemotingService";
import ActionMenu from "../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../components/action-menu/ActionMenuItem";
import EditIcon from "../../components/action-menu/icons/EditIcon";
import DeleteIcon from "../../components/action-menu/icons/DeleteIcon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './SponsorshipPanel.css'
import EditSponsorship from "./EditSponsorship";
import SponsorshipUtil from "./SponsorshipUtil";
import Tooltip from "../../components/tooltip/Tooltip";
import AuthService from "../../services/auth-service/AuthService";
import MoneyUtil from "../../utils/MoneyUtil";

class SponsorshipPanel extends React.Component {

    state = {
        addSponsorshipModalVisible: false
    };

    componentDidMount() {
        RemotingService.getRemoteCall('api/clinic/department/list-department-contains-provider', null, (departmentResult) => {
            let departments = departmentResult.filter(d => d.name != 'Reception' && d.name != 'Management');

            RemotingService.getRemoteCall('api/clinic/list', null, (clinicResult) => {
                let sponsorData = {
                    departments,
                    staffs: this.props.staffs,
                    clinics: clinicResult.items
                };

                this.setState({sponsorData});
                this.retrieveSponsorships();
            });
        });
    }

    retrieveSponsorships = () => {
        RemotingService.getRemoteCall('api/patient/' + this.props.patientId + '/sponsorship', null, (sponsorships) => {
            this.setState({sponsorships, addSponsorshipModalVisible: false});
        });
    }

    showAddSponsorshipModal() {
        this.setState({addSponsorshipModalVisible: true});
    }

    render() {
        const items = this.state.sponsorships;

        return (
            <div className="card sponsorship-panel">
                <div className="card-header">
                    <div className="card-header-title">
                        <strong style={{verticalAlign: "middle"}}>Sponsorship</strong>
                    </div>
                    <div className="card-header-actions">
                        {(this.props.editable || AuthService.isProvider()) &&
                        <button type="button" className="btn btn-primary btn-sm"
                                onClick={() => this.showAddSponsorshipModal()}>
                            Add Sponsorship
                        </button>
                        }
                        {this.state.addSponsorshipModalVisible &&
                        <SponsorshipDetailPanel patientId={this.props.patientId} sponsorData={this.state.sponsorData}
                                                handleSponsorshipUpdate={() => {
                                                    this.retrieveSponsorships()
                                                }}
                                                editModalVisible={this.state.addSponsorshipModalVisible}/>}
                    </div>
                </div>
                <div className="card-body line-wrapper small-font">
                    <div className="row">
                        <div className="col-2">Discount</div>
                        <div className="col-2">Sessions</div>
                        <div className="col-3">Validity</div>
                        <div className="col-3">Coverage</div>
                        <div className="col-1 text-center">Notes</div>
                    </div>
                    <hr/>
                    {(items && items.length) ?
                        <div className="row">
                            <SponsorshipDetailPanel patientId={this.props.patientId}
                                                    sponsorData={this.state.sponsorData}
                                                    handleSponsorshipUpdate={() => {
                                                        this.retrieveSponsorships()
                                                    }}
                                                    sponsorship={items[0]}/>
                            {items.length > 1 ?
                                <FontAwesomeIcon
                                    className={"angle-double-icon"}
                                    icon={["fa", `angle-double-${this.state.panelExpanded ? 'up' : 'down'}`]}
                                    onClick={() => this.setState({panelExpanded: !this.state.panelExpanded})}/> : null
                            }
                        </div>
                        :
                        <div className="row">
                            <div className="col-12">
                                No sponsorship info found.
                            </div>
                        </div>
                    }
                </div>
                {
                    items && items.length && items.length > 1 ?
                        <div
                            className={`card-body small-font text-right expandable ${this.state.panelExpanded ? 'expanded' : ''}`}>
                            {items.map((item, inx) => {
                                if (inx === 0) return;
                                return (
                                    <div className="row" key={'sponsorship-detail-row-' + inx}>
                                        <SponsorshipDetailPanel key={'sponsorship-detail-panel-' + inx}
                                                                patientId={this.props.patientId}
                                                                sponsorData={this.state.sponsorData}
                                                                handleSponsorshipUpdate={() => {
                                                                    this.retrieveSponsorships()
                                                                }}
                                                                sponsorship={item} index={inx}/>
                                    </div>
                                )
                            })}
                        </div> : null
                }
            </div>
        );
    }
}

class SponsorshipDetail extends React.Component {

    state = {};

    componentDidMount() {
        const {editable} = SponsorshipUtil.getCapabilities(this.props.sponsorship);
        this.setState({editModalVisible: this.props.editModalVisible, editable})
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.state.editModalVisible = this.props.editModalVisible;
    }

    showEditModal = () => {
        this.setState({editModalVisible: true});
    }

    deleteSponsorship = () => {
        RemotingService.deleteRemoteCall(
            'api/patient/' + this.props.patientId + '/sponsorship/' + this.props.sponsorship.id,
            null, () => {
                this.props.handleSponsorshipUpdate();
            });
    }

    getCoverage = (sponsorship) => {
        const {departments, staffs} = this.props.sponsorData;
        const {coverAll, departmentIds, staffIds} = sponsorship;
        let coverage = "";

        if (coverAll) {
            coverage = "All";
        }

        if (departmentIds && departmentIds.length) {
            coverage = departments.filter(i => departmentIds.includes(i.id)).map(i => i.combinedName).join(", ") + ' ';
        }

        if (staffIds && staffIds.length) {
            coverage = coverage + staffs.filter(i => staffIds.includes(i.id)).map(i => i.name).join(", ");
        }

        return coverage;
    }

    render() {
        const sponsorship = this.props.sponsorship;
        const coverage = sponsorship && this.getCoverage(sponsorship);

        return (
            <>
                {sponsorship &&
                <>
                    <div className="col-2 text-right">
                        {sponsorship.discountRate > 0 ? sponsorship.discountRate : MoneyUtil.format((sponsorship.discountAmount?.amount ? sponsorship.discountAmount?.amount : 0), sponsorship.discountAmount?.currency)}
                        {sponsorship.discountRate > 0 ? <small>%</small> :
                            <small>{sponsorship.discountAmount?.currency}</small>}
                    </div>
                    <div className="col-2 text-right">{SponsorshipUtil.getSessions(sponsorship)}</div>
                    <div className="col-3 text-right">{SponsorshipUtil.getValidityDate(sponsorship)}</div>
                    <div className="col-3 text-right">
                        <Tooltip key={"coverage" + this.props.index} title={coverage}>
                            {StringUtil.shorten(coverage, 15)}
                        </Tooltip>
                    </div>
                    <div className="col-2 text-right d-flex">
                        <Tooltip className="sponsorship-notes" key={"notes" + this.props.index}
                                 title={sponsorship.notes}>
                            {sponsorship.notes}
                        </Tooltip>
                        {this.state.editable &&
                        <ActionMenu id="sponsorshipPanelActionMenu">
                            <ActionMenuItem icon={<EditIcon/>} onClick={() => {
                                this.showEditModal()
                            }} text={"Edit"}/>
                            <ActionMenuItem icon={<DeleteIcon/>} disabled={!this.state.editable} onClick={() => {
                                this.deleteSponsorship()
                            }} text={"Delete"}/>
                        </ActionMenu>
                        }
                    </div>
                </>
                }
                {
                    this.state.editModalVisible &&
                    <EditSponsorship
                        visible={this.state.editModalVisible}
                        patientId={this.props.patientId}
                        sponsorship={this.props.sponsorship}
                        sponsorData={this.props.sponsorData}
                        handleSponsorshipUpdate={() => {
                            this.props.handleSponsorshipUpdate()
                        }}
                    />
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.authentication.user
    };
};

const SponsorshipDetailPanel = connect(mapStateToProps)(SponsorshipDetail)

export default SponsorshipPanel;
