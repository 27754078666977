import React, {Component} from 'react'
import './InsuranceEligibilityScreen.css'
import {Col, Container, Nav, Row, Tab} from "react-bootstrap";
import PayAndClaim from "./claim/PayAndClaim";
import InsuranceDirect from "./direct/InsuranceDirectPay";
import InsuranceAppointment from "./appointment/InsuranceAppointment";
import DirectBillingRequest from "./request/DirectBillingRequest";
import ClaimList from "./claimList/ClaimList";
import queryString from "query-string";
import ClaimRemittance from "./claimRemittance/ClaimRemittance";

export default class InsuranceEligibilityScreen extends Component {
    constructor(props) {
        super(props);
        const values = queryString.parse(this.props.location.search);
        const selectedTab = values.selectedTab || "todaysAppointments";
        this.state = {selectedTab}
    }

    render() {
        const {selectedTab} = this.state;

        return (
            <Container style={{maxWidth: '100%'}}>
                <Row>
                    <Col xs={12}>
                        <div className="InsuranceScreen">
                            <Tab.Container defaultActiveKey={selectedTab} id="insurance-tabs"
                                           className="InsuranceTabs"
                                           onSelect={(key) => {
                                               this.setState({selectedTab: key})
                                               this.props.history.push({
                                                   search: "?" + new URLSearchParams({selectedTab: key}).toString()
                                               })
                                           }}>
                                <Row>
                                    <Col sm={12}>
                                        <Nav variant="tabs" className="flex-row">
                                            <Nav.Item>
                                                <Nav.Link eventKey="todaysAppointments">Appointments</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="directBilling">Pre Approval</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="payAndClaim">Pay&Claim</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="directBillingRequests">Direct Billing Requests</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="claimList">Claim</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="claimRemittance">Claim Remittance</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="todaysAppointments">
                                                <InsuranceAppointment selected={selectedTab === "todaysAppointments"} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="directBilling">
                                                <InsuranceDirect selected={selectedTab === "directBilling"} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="payAndClaim">
                                                <PayAndClaim
                                                    selected={selectedTab === "payAndClaim"} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="directBillingRequests">
                                                <DirectBillingRequest selected={selectedTab === "directBillingRequests"} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="claimList">
                                                <ClaimList selected={selectedTab === "claimList"} />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="claimRemittance">
                                                <ClaimRemittance selected={selectedTab === "claimRemittance"} />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}
