import React from 'react';
import { Link } from 'react-router-dom';
export const BreadCrumb = props => {
    return (
        <nav aria-label="breadcrumb">
                <ol className="breadcrumb" style={{backgroundColor: 'white'}}>
                   {props.children}
                </ol>
        </nav>
    );
};

export const Item = props =>{
    if(props.active){
        return (
            <li className="breadcrumb-item active" aria-current="page">{props.label}</li>
        );
    }else{
        return (
            <li className="breadcrumb-item" ><Link to={props.to}>{props.label}</Link></li>
        );
    }
};
