import React, {Component} from "react";
import {CircularProgressbar} from "react-circular-progressbar";
import RemotingService from "../../../../services/remoting-service/RemotingService";

class PatientProfileStatus extends Component{

    constructor(props) {
        super(props);
        this.dxbFields = ["firstName","eid","birthday","gender","nationality","visaStatus","mobilePhone","email","address"]
        this.kwiFields = ["firstName","eid","birthday","gender","nationality","mobilePhone","email"]

        this.location = this.props.patient?.mrn?.country;

        this.state = {
            patient:this.props.patient,
            dxbNewPatientForm: null,
            kwiNewPatientForm: null,
            formsUpdated: false
        }
    }

    componentDidMount() {
        if(this.location === "UAE"){
            RemotingService.getRemoteCall(
                "api/patient/" + this.state.patient.id + "/new-patient-form-dxb",
                null,
                (form) => {
                    if (form !== null){
                        this.setState({dxbNewPatientForm: form});
                    }
                    this.calculateTotalValue();
                }
            );
        }

        if(this.location === "KUWAIT"){
            RemotingService.getRemoteCall(
                "api/patient/" + this.state.patient.id + "/new-patient-form-kwt",
                null,
                (form) => {
                    if (form !== null){
                        this.setState({kwiNewPatientForm: form});
                    }
                    this.calculateTotalValue();
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((JSON.stringify(prevProps.patient) !== JSON.stringify(this.props.patient)) || (prevProps.formUpdateSwitch !== this.props.formUpdateSwitch)){
            this.setState({patient: this.props.patient})
        }

        if(prevProps.formUpdateSwitch !== this.props.formUpdateSwitch){
            this.setState({formsUpdated: true})
        }
    }

    calculateTotalValue() {
        let fields = this.location === "UAE" ? this.dxbFields : this.kwiFields;
        let newPatientForm = this.location === "UAE" ? this.state.dxbNewPatientForm : this.state.kwiNewPatientForm;

        const weightForEachField = 100 / (fields.length + 1);
        let total = 0;

        fields.map(field => {
            if(this.state.patient[field] != null && !(typeof this.state.patient[field] === "string" && this.state.patient[field].trim().length === 0)){
                total += weightForEachField;
            }
        })

        if(this.state.patient.migrated || newPatientForm != null || this.state.formsUpdated){
            total += weightForEachField
        }

        return (Math.round(total * 100) / 100);
    }

    render() {
        return(
            <div>
                <label style={{marginTop: '25px', fontWeight: 500}}>Profile Status</label>
                <div style={{width: "80px", display: 'flex', margin: '0 auto',}}>
                    <CircularProgressbar value={this.calculateTotalValue()} text={`${this.calculateTotalValue()}%`}/>
                </div>
            </div>
        )
    };
}

export default PatientProfileStatus;