import React, {Component} from "react";
import {Progress} from 'react-sweet-progress';

export default class MissingPointGraph extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let percent = 0;
        const missingPoints = this.props.missingPoints;
        const totalPoints = this.props.totalPoints;

        if (missingPoints != null && totalPoints != null) {
            let completed = totalPoints - missingPoints;
            percent = ~~(completed / totalPoints * 100);

            if (percent === 100 && this.props.updatedByStaffType === "NURSE") {
                percent--;
            }
        }

        return (
            <>
                <div className={"d-flex flex-column px-4 align-items-center"}>
                    <strong className={"d-block"}>EMR Status</strong>
                    <Progress
                        style={{fontSize: "12px"}}
                        type="circle"
                        percent={percent}
                        width="100px"
                    />
                </div>

            </>
        );
    }
}