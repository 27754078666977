import React, {Component} from "react";
import CommunicationModeEnum from "../../result/CommunicationModeEnum";
import {Dropdown} from "primereact/dropdown";
import DateUtil from "../../../../../utils/DateUtil";
import PrimeDateTimeInput from "../../../../../components/date-input/PrimeDateTimeInput";
import TextareaInput from "../../../../../components/textarea-input/TextareaInput";
import AuthService from "../../../../../services/auth-service/AuthService";
import Grid from "@material-ui/core/Grid";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RemotingService from "../../../../../services/remoting-service/RemotingService";
import Modal from "../../../../../components/modal/Modal";
import ReduxService from "../../../../../services/redux-service/ReduxService";
import {Button} from "react-bootstrap";
import Tooltip from "../../../../../components/tooltip/Tooltip";
import moment from "moment";
import NotificationService from "../../../../../services/notification-service/NotificationService";
import {commentComparatorByDateAsc, taskComparatorByNameAsc} from "../../result/Result";

export default class CommunicationModal extends Component {

    state = {
        activeCommunication: {
            tasks: [],
            comments: [],
            files: [],
            addedBy: AuthService.getUser().name,
            logDate: DateUtil.now()
        },
        viewerVisible: false,
        viewerHeader: null,
        blobUrl: null,
        activeTask: null,
        activeComment: null,
    }

    componentDidMount() {
        if (this.props.communicationId) {
            RemotingService.getRemoteCall(`api/emr/communication/${this.props.communicationId}`, null,
                (activeCommunication) => {
                    activeCommunication.communicationDate = DateUtil.parse(moment(activeCommunication.communicationDate),
                        "YYYY-MM-DD hh-mm-ss").toDate();
                    this.setState({activeCommunication});
                });
        }
    };

    renderAttachment = (resultItem) => {
        return (
            <label className={"attachment-label"}>
                <div style={{border: '1px solid darkgrey', minWidth: '75px', minHeight: "35px"}}>
                    <FontAwesomeIcon icon={["fas", "upload"]} className="ml-1 mt-2"/>
                    <span className="ml-2 mt-2 font-size-small">Upload</span>
                </div>
                <input type="file"
                       id="upload-attachment"
                       accept=".jpg, .jpeg, .png, .pdf, .xls, .xlsx, .doc, .docx, .txt"
                       onChange={(e) => {
                           if (e == null
                               || e.target == null
                               || e.target.files == null
                               || e.target.files[0] == null) {
                               return;
                           }
                           let fileName = e.target.files[0].name;
                           RemotingService.uploadFile(e.target.files[0], (response, err) => {
                               if (err != null) {
                                   return;
                               }
                               if (resultItem.files == null) {
                                   resultItem.files = [];
                               }
                               resultItem.files.push({name: fileName, uuid: response.data});
                               this.forceUpdate();
                           });
                       }}
                       onClick={(e) => e.target.value = null}/>
            </label>
        );
    }

    view = (file) => {
        ReduxService.incrementRemotingOperationCount();

        RemotingService.instance.get('api/file/download/' + file.uuid, {responseType: 'blob'})
            .then((response) => {
                let blobUrl = window.URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                this.setState({
                    viewerVisible: true,
                    viewerHeader: file.name,
                    blobUrl: blobUrl
                });
            })
            .finally(() => {
                ReduxService.decrementRemotingOperationCount();
            });
    }

    renderAttachmentModal() {
        return (
            <Modal visible={true}
                   header={this.state.viewerHeader}
                   closeAction={() => this.setState({
                       viewerVisible: false
                   })}>
                <iframe id="documentViewer" src={this.state.blobUrl} width="800px" height="600px"></iframe>
            </Modal>
        );
    }

    renderAttachmentTable = (files) => {
        if (files == null || files.length === 0) {
            return null;
        }
        return (
            <table className={"communication-files-list"}>
                <tbody>
                {files.map((f, index) => {
                    return (
                        <tr>
                            <Tooltip title={`File`}>
                                    <span>
                                        <FontAwesomeIcon icon={["fas", "paperclip"]} style={{color: "black"}}/>
                                    </span>
                            </Tooltip>
                            <td className={"communication-files-link-column"}
                                onClick={() => {
                                    this.view(f);
                                }}>
                                {f.name}
                            </td>
                            <td>
                                <Tooltip title={`Delete`}>
                                    <span>
                                        <FontAwesomeIcon icon={["fas", "trash"]}
                                                         onClick={() => {
                                                             files.splice(index, 1);
                                                             this.forceUpdate();
                                                         }}/>
                                    </span>
                                </Tooltip>
                            </td>
                        </tr>
                    );
                })}
                </tbody>
            </table>
        );
    }

    renderCommunicationTasksTable = (tasks) => {
        if (tasks == null || tasks.length === 0) {
            return null;
        }
        return (
            <table className={"communication-tasks-list"}>
                <tbody>
                {tasks.sort(taskComparatorByNameAsc()).map((i, index) => {
                    return (
                        <tr className={"content-card content-row m-0 w-100 p-2"}>
                            <td className={"communication-tasks-column"}>
                                {i.name}
                            </td>
                            <td>
                                <Tooltip title={`Delete`}>
                                    <span>
                                        <FontAwesomeIcon icon={["fas", "trash"]}
                                                         onClick={() => {
                                                             tasks.splice(index, 1);
                                                             let updatedCommunication = this.state.activeCommunication;
                                                             updatedCommunication.tasks = tasks;
                                                             this.setState({
                                                                 activeCommunication: updatedCommunication,
                                                                 activeTask: null
                                                             });
                                                             this.forceUpdate();
                                                         }}/>
                                    </span>
                                </Tooltip>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        );
    }

    communicationCommentsTable = (comments) => {
        if (comments == null || comments.length === 0) {
            return null;
        }
        let ownComments = comments.filter(i => i.addedBy === this.props.staff);

        return (
            <table className={"communication-tasks-list"}>
                <tbody>
                {ownComments.sort(commentComparatorByDateAsc()).map((i, index) => {
                    return (
                        <tr className={"content-card content-row m-0 w-100 p-2"}>
                            <td className={"communication-tasks-column"}>
                                {i.comment}
                            </td>
                            <td>
                                <Tooltip title={`Delete`}>
                                    <span>
                                        <FontAwesomeIcon icon={["fas", "trash"]}
                                                         onClick={() => {
                                                             let currentComments = comments;
                                                             currentComments.splice(index, 1);
                                                             let updatedCommunication = this.state.activeCommunication;
                                                             updatedCommunication.comments = currentComments;
                                                             this.setState({activeCommunication: updatedCommunication});
                                                             this.forceUpdate();
                                                         }}/>
                                    </span>
                                </Tooltip>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        );
    }

    handleTaskAddClick = () => {
        let task = {
            ...this.state.activeTask,
            status: 'PENDING',
            addedBy: AuthService.getUser().name
        };
        let activeCommunication = this.state.activeCommunication;

        if (task.name != null && task.name.length > 0) {
            let tasks = activeCommunication?.tasks;
            activeCommunication.tasks = [task, ...tasks];
        }

        this.setState({activeTask: {...this.state.activeTask, name: ""}, activeCommunication});
    }

    handleCommentAddClick() {
        let comment = {
            ...this.state.activeComment,
            logDate: DateUtil.now(),
            addedBy: AuthService.getUser().name
        };
        let activeCommunication = this.state.activeCommunication;

        if (comment.comment != null && comment.comment.length > 0) {
            let comments = activeCommunication?.comments;
            activeCommunication.comments = [comment, ...comments];
        }

        this.setState({activeComment: {...this.state.activeComment, comment: ""}, activeCommunication});
    }

    handleAddCommunication = () => {
        const comm = this.state.activeCommunication
        if (!(comm.communicationMode || comm.communicationDate || comm.communicationText ||
            comm.comments.length > 0 || comm.tasks.length > 0 || comm.files.length > 0)) {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Please fill in at least one section.'
            });
        } else {
            this.props.saveCommunicationModal(comm);
            this.props.close();
        }
    }

    render() {
        return <Modal visible={true}
                      header="Add Addendum"
                      submitContent="Save"
                      contentStyle={{overflowY: 'visible'}}
                      submitAction={this.handleAddCommunication}
                      closeAction={() => this.props.close()}>
            {this.renderCommunicationModalBody()}
        </Modal>
    }

    renderCommunicationModalBody = () => {
        const readOnly = AuthService.isAdminOrManager();
        return (
            <div className={"CommunicationModal"}>
                <Grid
                    container
                    direction="row"
                    className={"content-card content-row m-0 w-100 p-2"}>
                    <Grid item alignItems={"center"} className={"communication-grid"}>
                        <Grid container direction="row" justify="space-between">
                            <div className={"col-4"}>
                                <div className={"row"}>
                                    <h6>Type</h6>
                                </div>
                                <div className={"row w-100"}>
                                    <Dropdown
                                        value={this.state.activeCommunication?.communicationMode}
                                        options={CommunicationModeEnum.toArray()
                                            .map(r => {
                                                return {label: r.name, value: r.key}
                                            })
                                        }
                                        className={"w-100"}
                                        displayProperty="label"
                                        valueProperty="value"
                                        searchEnabled={false}
                                        onChange={(event) => {
                                            this.setState((state) => {
                                                state.activeCommunication.communicationMode = event.target.value;
                                                return state;
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={"col-4"}>
                                <div className={"row"}>
                                    <h6>Date & Time</h6>
                                </div>
                                <div className={"row"}>
                                    <PrimeDateTimeInput
                                        value={this.state.activeCommunication?.communicationDate}
                                        onChange={(value) => {
                                            this.setState((state) => {
                                                state.activeCommunication.communicationDate = value;
                                                return state;
                                            });
                                        }}
                                        disableFuture="true"
                                        viewDate={DateUtil.timeOf(DateUtil.now().hour(), DateUtil.now().minutes())}
                                    />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className={"communication-grid"}>
                        <Grid direction="row" style={{width: "100%"}}>
                            <div className={"col-!2"}>
                                <div className={"row"}>
                                    <h6 style={{marginLeft: "5px"}}>Notes</h6>
                                </div>
                                <div className={"row w-100"}>
                                    <TextareaInput
                                        className={"w-100"}
                                        value={this.state.activeCommunication.communicationText}
                                        onChange={(value) => {
                                            this.setState((state) => {
                                                state.activeCommunication.communicationText = value;
                                                return state;
                                            });
                                        }}
                                        maxLength={2000}
                                        readOnly={readOnly}
                                    />
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className={"communication-grid"}>
                        <Grid direction="row" style={{width: "100%"}}>
                            <div className={"col-!2"}>
                                <div className={"row"}>
                                    <h6 style={{marginLeft: "5px"}}>Comment</h6>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-10"}>
                                        <TextareaInput
                                            value={this.state.activeComment?.comment}
                                            onChange={(value) => {
                                                this.setState((state) => {
                                                    state.activeComment = {
                                                        ...state.activeComment,
                                                        comment: value
                                                    };
                                                    return state;
                                                });
                                            }}
                                            maxLength={2000}
                                            style={{
                                                fontSize: "14px", width: "100%", minHeight: "30px",
                                                background: "white", cursor: "pointer", resize: "none"
                                            }}
                                            readOnly={readOnly}
                                        />
                                    </div>
                                    <div className={"col-2"}>
                                        <Button style={{minWidth: '80px', maxHeight: "35px", marginTop: "10px"}}
                                                variant="success"
                                                onClick={() => this.handleCommentAddClick()}>
                                            <FontAwesomeIcon icon={["fas", "plus"]}/>
                                            <span className="ml-1 font-size-small">Add</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className={"communication-grid"}>
                        <Grid container direction="row" justify="space-between">
                            <div className={"col-12"}>
                                <div className={"row"}>
                                    {this.communicationCommentsTable(this.state.activeCommunication?.comments, true)}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className={"communication-grid"}>
                        <Grid direction="row" style={{width: "100%"}}>
                            <div className={"col-!2"}>
                                <div className={"row"}>
                                    <h6 style={{marginLeft: "5px"}}>Add Tasks</h6>
                                </div>
                                <div className={"row"}>
                                    <div className={"col-10"}>
                                        <TextareaInput
                                            value={this.state.activeTask?.name}
                                            onChange={(value) => {
                                                this.setState((state) => {
                                                    state.activeTask = {
                                                        ...state.activeTask,
                                                        name: value
                                                    };
                                                    return state;
                                                });
                                            }}
                                            maxLength={2000}
                                            style={{
                                                fontSize: "14px", width: "100%", minHeight: "30px",
                                                background: "white", cursor: "pointer", resize: "none"
                                            }}
                                            readOnly={readOnly}
                                        />
                                    </div>
                                    <div className={"col-2"}>
                                        <Button style={{minWidth: '80px', maxHeight: "35px", marginTop: "10px"}}
                                                variant="success"
                                                onClick={this.handleTaskAddClick}>
                                            <FontAwesomeIcon icon={["fas", "plus"]}/>
                                            <span className="ml-1 font-size-small">Add</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className={"communication-grid"}>
                        <Grid container direction="row" justify="space-between">
                            <div className={"col-12"}>
                                <div className={"row"}>
                                    {this.renderCommunicationTasksTable(this.state.activeCommunication?.tasks, true)}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className={"communication-grid"}>
                        <Grid container direction="row" justify="space-between">
                            <div className={"col-3"}>
                                <div className={"row"}>
                                    <h6 style={{marginLeft: "5px"}}>Attachment</h6>
                                </div>
                            </div>
                            <div className={"col-9"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        {this.renderAttachment(this.state.activeCommunication)}
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid className={"communication-grid"}>
                        <Grid container direction="row" justify="space-between">
                            <div className={"col-3"}>
                                <div className={"row"}>

                                </div>
                            </div>
                            <div className={"col-9"}>
                                <div className={"row"}>
                                    {this.renderAttachmentTable(this.state.activeCommunication.files)}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    {this.state.viewerVisible && this.renderAttachmentModal()}
                </Grid>
            </div>
        );
    }

}
