import React, {Component} from "react";
import "../../visithistory/VisitHistory.css"
import DateUtil from "../../../../../utils/DateUtil";
import TypeEnum from "../../treatment/enums/result/TypeEnum";
import ReportViewUtils from "./ReportViewUtils";

export default class TreatmentResultReportView extends Component {

    render() {
        if (ReportViewUtils.isTreatmentResultNull(this.props.results)) {
            return null;
        }

        return (
            <div style={{marginTop: 5}}>
                <div className="font-weight-bold mt-2">Interventions</div>
                <table className={"visit-history-table"}>
                    <tbody>
                    <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                        <th>Type</th>
                        <th>Details</th>
                        <th>Findings</th>
                        <th>Remarks</th>
                        <th>Date Ordered</th>
                        <th>Ordered By</th>
                        <th>Result Received</th>
                    </tr>
                    {this.props.results.map((m, index) => {
                        return (<tr key={"resultView" + index} style={{backgroundColor: "white"}}>
                            <td>{m.type == null ? "" : TypeEnum[m.type].name}</td>
                            <td>{m.detail}</td>
                            <td>{m.findings}</td>
                            <td>{m.remarks}</td>
                            <td>{m.dateOrdered == null ? "" : DateUtil.formatDateMMM(m.dateOrdered)}</td>
                            <td>{m.orderedBy}</td>
                            <td>{m.resultReceived == null ? "" : DateUtil.formatDateMMM(m.resultReceived)}</td>
                        </tr>)
                    })}
                    </tbody>
                </table>
            </div>
        );
    }

}
