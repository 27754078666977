export default class MaxLengthValidator {

    getErrorMessage(prop) {
        return `Maximum ${prop} option must be selected`;
    }

    validate(value, prop) {
        if (value === null || value === undefined) {
            return true;
        }
        if (Array.isArray(value) && value.length <= prop) {
            return true;
        }
        return false;
    }

}