import React, {Component} from "react";
import '../Subjective.css';
import '../../EMR.css';
import './ComplaintView.css';
import PsychoSocialHistoryEnum from "../enums/lifestyle/PsychoSocialHistoryEnum";
import {Grid} from "@material-ui/core";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";

export default class PsychoSocialHistoryView extends Component {

    render() {
        let divs = this.getDivs();
        return (
            <Grid container alignItems={"center"} className={"content-card content-row m-0 w-100 p-2"}>
                <Grid item xs style={{maxWidth: 200}} className={"content-card-title"}>
                    <h6 className={"no-margin"}>Psycho - Social History <RedAsterisk className={"font-weight-bold"}
                                                                                     appointmentType={this.props.appointmentType}
                                                                                     firstVisit newComplaint/></h6>
                </Grid>
                <Grid item xs>
                    <div className="p-2 pl-3" style={{borderLeft: "1px gainsboro solid"}}>
                        {(divs != null && divs.length != 0) ? divs :
                            <div>
                                No information
                            </div>
                        }
                    </div>
                </Grid>
            </Grid>
        );
    }

    getDivs() {
        if (this.props.lifestyle == null) {
            return null;
        }
        let lifestyle = this.props.lifestyle;
        let divs = [];
        if ((lifestyle.psychoSocialHistory != null && lifestyle.psychoSocialHistory.length > 0)
            || (lifestyle.psychoSocialHistoryDetail != null && lifestyle.psychoSocialHistoryDetail != "")) {
            divs.push(<div key={"psychoSocialHistoryViewDiv"}>
                {this.enumString(PsychoSocialHistoryEnum, lifestyle.psychoSocialHistory, lifestyle.psychoSocialHistoryDetail)}
            </div>);
        }
        return divs;
    }

    enumString(_class, enumObject, detail) {
        if (enumObject == null || enumObject.length == 0) {
            return (detail && detail != null) ? detail : 'NA';
        }
        let res;
        if (Array.isArray(enumObject)) {
            res = enumObject.map(e => _class[e].name);
        } else {
            res = [];
            res.push(_class[enumObject].name);
        }
        if (detail && detail != null && detail != '') {
            res.push(detail);
        }
        return res.join(', ');
    }
}