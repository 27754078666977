import React, {Component} from "react";
import "../../visithistory/VisitHistory.css";
import ActivityLimitationEnum from "../../subjective/enums/treatmentreceived/ActivityLimitationEnum";
import SeverityEnum from "../../subjective/enums/treatmentreceived/SeverityEnum";
import ReportViewUtils from "./ReportViewUtils";

export default class ActivityLimitationReportView extends Component {

    render() {
        let divs = [];

        if(ReportViewUtils.isActivityLimitationNull(this.props.activityLimitation)){
            return null;
        }

        if(this.props.activityLimitation.none)
            return (<div>No Activity Limitation</div>)

        this.props.activityLimitation.items.forEach((activity,index) => {
            divs.push(
                <div key={"activityLimitationReportView" + index}>
                    <div className={"display-flex"} style={{margin: "3px"}}>
                        <div className={"col-sm-12 no-padding overflow-wrap-anywhere"}>
                            {[
                                ActivityLimitationEnum[activity.activityLimitation]?.name,
                                activity.detail
                            ].filter(Boolean).join(", ")}. Severity: {SeverityEnum[activity.severity].name}
                        </div>
                    </div>
                </div>
            );
        });

        return(
            <div className={"visit-history-multiple-content-wrapper"}>
                {divs}
            </div>
        );
    }
}
