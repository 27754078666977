import Enum from "../../../enums/Enum";

const EnumInsurancePaymentStatus = new Enum({

    PENDING: "Pending",
    PAYMENT_RECEIVED: "Payment Received",
    RESUBMITTED: "Resubmitted",
    REJECTED: "Rejected",
});

export default EnumInsurancePaymentStatus;
