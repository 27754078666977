import React, {Component} from "react";
import '../../EMR.css';
import TreatmentInfoEnum from "../modals/TreatmentInfoEnum";
import TreatmentInfoUtils from "../utils/TreatmentInfoUtils";
import DiagnosisGroup from "./DiagnosisGroup";
import TreatmentReceivedEnum from "../enums/treatmentrecieved/TreatmentReceivedEnum";
import DateUtil from "../../../../../utils/DateUtil";

export default class TreatmentInfoView extends Component {

    constructor(props) {
        super(props);

        this.state = {}
    }


    render() {

        return (
            <div>
                {this.treatmentInfoTypeAView(TreatmentReceivedEnum.MANIPULATION)}
                {this.treatmentInfoTypeAView(TreatmentReceivedEnum.MOBILIZATION)}
                {this.treatmentInfoTypeBView(TreatmentReceivedEnum.STRETCHING)}
                {this.treatmentInfoTypeBView(TreatmentReceivedEnum.DRY_NEEDLING)}
                {this.treatmentInfoTypeBView(TreatmentReceivedEnum.ACUPUNCTURE)}
                {this.treatmentInfoTypeBView(TreatmentReceivedEnum.ELECTROTHERAPY)}
                {this.treatmentInfoTypeBView(TreatmentReceivedEnum.SHOCKWAVE, this.props.type === TreatmentInfoEnum.TREATMENT_RECEIVED)}
                {this.treatmentInfoTypeBView(TreatmentReceivedEnum.CUPPING)}
                {this.treatmentInfoTypeBView(TreatmentReceivedEnum.TAPING)}
                {this.treatmentInfoTypeBView(TreatmentReceivedEnum.THERAPEUTIC_MASSAGE)}
                {this.treatmentInfoTypeBView(TreatmentReceivedEnum.LYMPHATIC)}
                {this.treatmentInfoTypeAView(TreatmentReceivedEnum.THERMAL)}
                {this.treatmentInfoTypeCView(TreatmentReceivedEnum.THERAPEUTIC_EXERCISE)}
                {this.treatmentInfoTypeBView(TreatmentReceivedEnum.GAIT)}
                {this.treatmentInfoTypeBView(TreatmentReceivedEnum.BREATHING)}
                {this.props.type === TreatmentInfoEnum.TREATMENT_RECEIVED && this.treatmentReceivedProcedureView()}
                {this.props.type === TreatmentInfoEnum.TREATMENT_PLAN && this.treatmentPlanProcedureView()}
                {this.treatmentInfoTypeBView(TreatmentReceivedEnum.OTHER)}
            </div>
        );
    }

    treatmentInfoTypeAView = (treatmentType) => {
        const field = treatmentType.field
        const dropdownEnum = treatmentType.enum

        if (this.props.treatmentInfo[field] == null || this.props.treatmentInfo[field].length === 0) {
            return;
        }

        return (
            <div className={"content-card content-row treatment-received-card"}>
                <div className={"col-3 content-card-title"}>
                    <h6 className={"no-margin"}>{treatmentType.name}</h6>
                </div>
                <div className={"col-9"}>
                    <div style={{borderLeft: "1px gainsboro solid", paddingLeft: "30px"}}>
                        {this.props.treatmentInfo[field].map((item, index) => {
                            let paddingBetweenItems = this.props.treatmentInfo[field].length !== 1 &&
                            index !== this.props.treatmentInfo[field].length - 1 ? {paddingBottom: "10px"} : {};
                            return (
                                <div key={index} style={{...paddingBetweenItems}}>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Type</div>
                                        <div className={"col-1"}>:</div>
                                        {this.props.type === TreatmentInfoEnum.TREATMENT_PLAN &&
                                        field === TreatmentReceivedEnum.PROCEDURES.field ?
                                            <div className={"col-8"}>{item.type}</div> :
                                            <div className={"col-8"}>{dropdownEnum[item.type].name}</div>}
                                    </div>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Area of Focus</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.areaOfFocus}</div>
                                    </div>
                                    {(item.details != null && item.details.length !== 0) &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Details</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.details}</div>
                                    </div>}
                                </div>
                            )
                        })}
                    </div>
                </div>
                {this.renderTreatmentReceivedCptList(treatmentType)}
            </div>
        )
    }

    treatmentInfoTypeBView = (treatmentType, patientConsent = false) => {
        const field = treatmentType.field
        if (this.props.treatmentInfo[field] == null || this.props.treatmentInfo[field].length === 0)
            return;

        return (
            <div className={"content-card content-row treatment-received-card"}>
                <div className={"col-3 content-card-title"}>
                    <h6 className={"no-margin"}>{treatmentType.name}</h6>
                </div>
                <div className={"col-9"}>
                    <div style={{borderLeft: "1px gainsboro solid", paddingLeft: "30px"}}>
                        {this.props.treatmentInfo[field].map((item, index) => {
                            let paddingBetweenItems = this.props.treatmentInfo[field].length !== 1 &&
                            index !== this.props.treatmentInfo[field].length - 1 ? {paddingBottom: "10px"} : {};
                            return (
                                <div key={index} style={{...paddingBetweenItems}}>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Area of Focus</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.areaOfFocus}</div>
                                    </div>
                                    {(item.details != null && item.details.length !== 0) &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Details</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.details}</div>
                                    </div>}
                                    {patientConsent &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Patient Consent</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.patientConsent ? "Yes" : "No"}</div>
                                    </div>}
                                </div>
                            )
                        })}
                    </div>
                </div>
                {this.renderTreatmentReceivedCptList(treatmentType)}
            </div>
        )
    }

    treatmentInfoTypeCView = (treatmentType) => {
        const field = treatmentType.field
        const dropdownEnum = treatmentType.enum

        if (this.props.treatmentInfo[field] == null || this.props.treatmentInfo[field].length === 0)
            return;

        return (
            <div className={"content-card content-row treatment-received-card"}>
                <div className={"col-3 content-card-title"}>
                    <h6 className={"no-margin"}>{treatmentType.name}</h6>
                </div>
                <div className={"col-9"}>
                    <div style={{borderLeft: "1px gainsboro solid", paddingLeft: "30px"}}>
                        {this.props.treatmentInfo[field].map((item, index) => {
                            let paddingBetweenItems = this.props.treatmentInfo[field].length !== 1 &&
                            index !== this.props.treatmentInfo[field].length - 1 ? {paddingBottom: "10px"} : {};
                            return (
                                <div style={{...paddingBetweenItems}}>
                                    {item.focus != null &&
                                        <div className={"row no-margin"}>
                                            <div className={"col-3 font-weight-bold"}>Focus</div>
                                            <div className={"col-1"}>:</div>
                                            <div className={"col-8"}>{dropdownEnum[item.focus].name}</div>
                                        </div>
                                    }
                                    {item.exercise != null &&<div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Exercise</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.exercise}</div>
                                    </div>}
                                    {item.weight != null &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Weight</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.weight}</div>
                                    </div>}
                                    {item.sets != null &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Sets</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.sets}</div>
                                    </div>}
                                    {item.reps != null &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Reps</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.reps}</div>
                                    </div>}
                                    {(item.details != null && item.details.length !== 0) &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Details</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.details}</div>
                                    </div>}
                                </div>
                            )
                        })}
                    </div>
                </div>
                {this.renderTreatmentReceivedCptList(treatmentType)}
            </div>
        )
    }

    treatmentReceivedProcedureView = () => {
        const title = TreatmentReceivedEnum.PROCEDURES.name
        const field = TreatmentReceivedEnum.PROCEDURES.field
        const dropdownEnum = TreatmentReceivedEnum.PROCEDURES.enum

        if (this.props.treatmentInfo[field] == null || this.props.treatmentInfo[field].length === 0) {
            return;
        }

        return (
            <div className={"content-card content-row treatment-received-card"}>
                <div className={"col-3 content-card-title"}>
                    <h6 className={"no-margin"}>{title}</h6>
                </div>
                <div className={"col-9"}>
                    <div style={{borderLeft: "1px gainsboro solid", paddingLeft: "30px"}}>
                        {this.props.treatmentInfo[field].map((item, index) => {
                            let paddingBetweenItems = this.props.treatmentInfo[field].length !== 1 &&
                            index !== this.props.treatmentInfo[field].length - 1 ? {paddingBottom: "10px"} : {};
                            return (
                                <div key={index} style={{...paddingBetweenItems}}>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Type</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{dropdownEnum[item.type].name}</div>
                                    </div>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Area of Focus</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.areaOfFocus}</div>
                                    </div>
                                    <div className={"row no-margin"}>
                                        {TreatmentInfoUtils.getTreatmentReceivedProcedureList(item)}
                                    </div>
                                    {(item.details != null && item.details.length !== 0) &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Details</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.details}</div>
                                    </div>}
                                </div>
                            )
                        })}
                    </div>
                </div>
                {this.renderTreatmentReceivedCptList(TreatmentReceivedEnum.PROCEDURES)}
            </div>
        )
    }

    treatmentPlanProcedureView = () => {
        const title = TreatmentReceivedEnum.PROCEDURES.name
        const field = TreatmentReceivedEnum.PROCEDURES.field
        const dropdownEnum = TreatmentReceivedEnum.PROCEDURES.enum

        if (this.props.treatmentInfo[field] == null || this.props.treatmentInfo[field].length === 0) {
            return;
        }

        return (
            <div className={"content-card content-row treatment-received-card"}>
                <div className={"col-3 content-card-title"}>
                    <h6 className={"no-margin"}>{title}</h6>
                </div>
                <div className={"col-9"}>
                    <div style={{borderLeft: "1px gainsboro solid", paddingLeft: "30px"}}>
                        {this.props.treatmentInfo[field].map((item, index) => {
                            let paddingBetweenItems = this.props.treatmentInfo[field].length !== 1 &&
                            index !== this.props.treatmentInfo[field].length - 1 ? {paddingBottom: "10px"} : {};
                            return (
                                <div key={index} style={{...paddingBetweenItems}}>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Diagnosis</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}><DiagnosisGroup diagnosisGroup={item.diagnosisGroup} showSecondaryItems/></div>
                                    </div>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Type</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{dropdownEnum[item.type] == null ? item.type : dropdownEnum[item.type].name}</div>
                                    </div>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Area of Focus</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.areaOfFocus}</div>
                                    </div>
                                    {(item.details != null && item.details.length !== 0) &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Details</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.details}</div>
                                    </div>}
                                    {item.noOfSessions != null && <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Number of Sessions</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.noOfSessions}</div>
                                    </div>}
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Approval Required</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.approvalRequired ? "Yes" : "No"}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }

    renderTreatmentReceivedCptList(treatmentType) {
        return this.props.type === TreatmentInfoEnum.TREATMENT_RECEIVED &&
            this.props.procedureList?.filter(cpt => cpt.treatments?.includes(treatmentType.key)).map((procedure) =>
                <>
                    <div className="row no-margin w-100">
                        <div className="col-8"><span className="font-weight-bold">Cpt Code:</span>
                            &nbsp;{procedure.cpt.cptCode} - {procedure.cpt.description}
                        </div>
                        <div className="col-2"><span className="font-weight-bold">Start:</span>
                            &nbsp;{DateUtil.getHourMinute24H(procedure.startTime)}
                        </div>
                        <div className="col-2"><span className="font-weight-bold">End:</span>
                            &nbsp;{DateUtil.getHourMinute24H(procedure.endTime)}
                        </div>
                    </div>
                </>
            )
    }
}
