import React, {Component} from "react";
import "./Treatment.css";
import Modal from "../../../../components/modal/Modal";
import FallRiskManagementModal from "./modals/FallRiskManagementModal";
import GreenSwitchWithEmptyMessage from "../../../../components/switch/GreenSwitchWithEmptyMessage";
import FallRiskManagementView from "./views/FallRiskManagementView";
import TreatmentInfoModal from "./modals/TreatmentInfoModal";
import TreatmentInfoView from "./views/TreatmentInfoView";
import TreatmentScheduleModal from "./modals/TreatmentScheduleModal";
import TreatmentScheduleView from "./views/TreatmentScheduleView";
import TreatmentGoalsModal from "./modals/TreatmentGoalsModal";
import TreatmentGoalsView from "./views/TreatmentGoalsView";
import TreatmentResponseModal from "./modals/TreatmentResponseModal";
import TreatmentResponseView from "./views/TreatmentResponseView";
import DischargePlanModal from "./modals/DischargePlanModal";
import DischargePlanView from "./views/DischargePlanView";
import ReferralModal from "./modals/ReferralModal";
import ReferralView from "./views/ReferralView";
import PatientEducationModal from "./modals/PatientEducationModal";
import PatientEducationView from "./views/PatientEducationView";
import VisitNavigator from "../common/VisitNavigator";
import TreatmentInfoEnum from "./modals/TreatmentInfoEnum";
import RedAsterisk from "../../../../components/asterisk/RedAsterisk";
import cloneDeep from 'lodash/cloneDeep';
import moment from "moment/moment";

export default class Treatment extends Component {

    constructor(props) {
        super(props);

        const visit = this.props.visits[this.props.currentVisitIndex];
        this.procedureList = [...visit.diagnosis.selfPayProcedures, ...visit.diagnosis.directBillingProcedures]
            .sort((a, b) => moment(a.startTime).diff(moment(b.startTime)))

        this.state = {
            index: 0,
            fallRiskManagementModalVisible: false,
            referralModalVisible: false,
            treatmentPlanModalVisible: false,
            treatmentReceivedModalVisible: false,
            patientEducationModalVisible: false
        }
    }


    componentDidMount() {
        //FIXME set previous index if data exists, after modals implemented
        this.setState({
            index: this.props.currentVisitIndex
        });
    }

    findPreviousVisit(visits, visitIndex) {
        if (visitIndex === 0 ||
            this.isNewComplaintOrNewPatient(visits[visitIndex])) {
            return null;

        } else {
            return visits[visitIndex - 1];
        }
    }

    isNewComplaintOrNewPatient(visit) {
        return ["NEW_PATIENT", "NEW_COMPLAINT"].includes(visit.appointmentType);
    }

    render() {
        const currVisitIndex = this.state.index;
        const visit = this.props.visits[currVisitIndex];
        const previousVisit = this.findPreviousVisit(this.props.visits, currVisitIndex);

        const treatment = visit.treatment;
        const goal = treatment.goal;
        const response = treatment.response;

        const hasGoal = goal && (
            goal.details || goal.specialRemarks ||
            goal.shortTermItems?.length !== 0 ||
            goal.longTermItems?.length !== 0);

        const hasResponse = response &&
            (response.overallImproveValue || response.overallImproveDetails ||
                response.romImproveValue || response.romImproveDetails ||
                response.painScoreValue || response.painScoreDetails
            );

        return (
            <div>
                <VisitNavigator
                    visits={this.props.visits}
                    currentVisitIndex={this.props.currentVisitIndex}
                    selectedIndex={this.state.index}
                    onDateChange={(index) => {
                        this.setState({index: index});
                    }}
                />
                <div className={`content-row ${this.props.disabled ? "disable-pointer" : ""}`}>
                    <div className={"content-col-6"}>
                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openTreatmentReceivedModal}>
                            <div style={{display: "flex"}}>
                                <div className="content-label">Treatment Received <RedAsterisk renderForAllTypes/></div>
                                <GreenSwitchWithEmptyMessage
                                    emptyMessage={""}
                                    checked={this.isTreatmentReceivedChanged(treatment.treatmentReceived)}/>
                            </div>
                            {this.isTreatmentReceivedChanged(treatment.treatmentReceived) &&
                            <div className="mt-3">
                                <TreatmentInfoView type={TreatmentInfoEnum.TREATMENT_RECEIVED}
                                                   treatmentInfo={treatment.treatmentReceived}
                                                   procedureList={this.procedureList}/>
                            </div>
                            }
                        </div>

                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openTreatmentResponseModal}>
                            <div style={{display: "flex"}}>
                                <div className="content-label">Response To Treatment <RedAsterisk renderForAllTypes/></div>
                                <GreenSwitchWithEmptyMessage
                                    emptyMessage={""}
                                    checked={hasResponse}/>
                            </div>
                            {hasResponse &&
                            (<div className="mt-3">
                                <TreatmentResponseView response={response}
                                                       previousResponse={previousVisit ? previousVisit.treatment.response : {}}/>
                            </div>)
                            }
                        </div>

                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openFallRiskManagementModal}>
                            <div style={{display: "flex"}}>
                                <div className="content-label">Fall Risk Management</div>
                                <GreenSwitchWithEmptyMessage
                                    emptyMessage={""}
                                    checked={treatment.fallRiskManagement && treatment.fallRiskManagement.items && treatment.fallRiskManagement.items.length !== 0}/>
                            </div>
                            {(treatment.fallRiskManagement && treatment.fallRiskManagement.items &&
                                treatment.fallRiskManagement.items.length !== 0) &&
                            (<div className="mt-3">
                                <FallRiskManagementView fallRiskManagement={treatment.fallRiskManagement}/>
                            </div>)
                            }
                        </div>


                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openPatientEducationModal}>
                            <div style={{display: "flex"}}>
                                <div className="content-label">Patient Education <RedAsterisk renderForAllTypes/></div>
                                <GreenSwitchWithEmptyMessage
                                    emptyMessage={""}
                                    checked={treatment.patientEducation &&
                                    ((treatment.patientEducation.items && treatment.patientEducation.items.length > 0) ||
                                        treatment.patientEducation.understoodContent !== null ||
                                        treatment.patientEducation.moreExplanation !== null)}/>
                            </div>
                            {(treatment.patientEducation &&
                                ((treatment.patientEducation.items && treatment.patientEducation.items.length > 0) ||
                                    treatment.patientEducation.understoodContent !== null ||
                                    treatment.patientEducation.moreExplanation !== null)) &&
                            (<div>
                                <PatientEducationView patientEducation={treatment.patientEducation}/>
                            </div>)
                            }
                        </div>
                    </div>
                    <div className={"content-col-6"}>
                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openTreatmentGoalsModal}>
                            <div className="d-flex justify-content-between">
                                <div className="content-label m-0">Goals <RedAsterisk renderForAllTypes/></div>
                                <GreenSwitchWithEmptyMessage
                                    emptyMessage={""}
                                    checked={hasGoal}/>
                            </div>
                            <div>
                                {hasGoal &&
                                <div className="mt-1">
                                    <TreatmentGoalsView treatment={treatment}/>
                                </div>}
                            </div>
                        </div>
                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openTreatmentPlanModal}>
                            <div className="d-flex justify-content-between">
                                <div className="content-label m-0">Treatment Plan <RedAsterisk appointmentType={visit.appointmentType} firstVisit newComplaint/></div>
                                <GreenSwitchWithEmptyMessage
                                    emptyMessage={""}
                                    checked={this.isTreatmentReceivedChanged(treatment.treatmentPlan)}/>
                            </div>
                            {this.isTreatmentReceivedChanged(treatment.treatmentPlan) &&
                            <div className="mt-3">
                                <TreatmentInfoView type={TreatmentInfoEnum.TREATMENT_PLAN}
                                                   treatmentInfo={treatment.treatmentPlan}
                                                   procedureList={this.procedureList}/>
                            </div>
                            }
                        </div>

                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openTreatmentScheduleModal}>
                            {
                                <TreatmentScheduleView treatmentSchedule = {treatment.treatmentSchedule}
                                                       treatmentPlanCompliance = {treatment.treatmentPlanCompliance}
                                                       riskOfHarmFromTreatment = {treatment.riskOfHarmFromTreatment}
                                                       appointmentType={visit.appointmentType}/>
                            }
                        </div>

                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openReferralModal.bind(this)}>
                            <div style={{display: "flex"}}>
                                <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Referrals
                                </div>
                                <GreenSwitchWithEmptyMessage
                                    emptyMessage={""}
                                    checked={treatment.referral != null && (
                                        treatment.referral.internals.length > 0
                                        || treatment.referral.externals.length > 0
                                        || treatment.referral.interventions.length > 0
                                    )
                                    }
                                />
                            </div>
                            <div>
                                <ReferralView referral={treatment.referral}/>
                            </div>
                        </div>


                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openDischargePlanModal}>
                            <div className="d-flex justify-content-between">
                                <div className="content-label m-0">Discharge Plan</div>
                                <GreenSwitchWithEmptyMessage
                                    emptyMessage={""}
                                    checked={treatment.dischargePlan && this.isDischargePlanChanged(treatment.dischargePlan)}/>
                            </div>
                            <div>
                                <DischargePlanView dischargePlan={treatment.dischargePlan}/>
                            </div>

                        </div>
                    </div>
                </div>

                {this.renderCloseConfirmModal()}
                {this.renderFallRiskManagementModal()}
                {this.renderTreatmentGoalsModal()}
                {this.renderTreatmentResponseModal()}
                {this.renderTreatmentReceivedModal()}
                {this.renderTreatmentPlanModal()}
                {this.renderTreatmentScheduleModal()}
                {this.renderReferralModal()}
                {this.renderPatientEducationModal()}
                {this.renderDischargePlanModal()}
            </div>
        );
    }

    isTreatmentReceivedChanged(treatmentInfo) {
        if (treatmentInfo == null) {
            return false;
        }
        return (treatmentInfo.acupuncture?.length !== 0) ||
            (treatmentInfo.breathingPatterns?.length !== 0) ||
            (treatmentInfo.cupping?.length !== 0) ||
            (treatmentInfo.dryNeedling?.length !== 0) ||
            (treatmentInfo.electrotherapy?.length !== 0) ||
            (treatmentInfo.gaitTraining?.length !== 0) ||
            (treatmentInfo.lymphaticDrainage?.length !== 0) ||
            (treatmentInfo.manipulation?.length !== 0) ||
            (treatmentInfo.mobilization?.length !== 0) ||
            (treatmentInfo.other?.length !== 0) ||
            (treatmentInfo.procedures?.length !== 0) ||
            (treatmentInfo.shockwave?.length !== 0) ||
            (treatmentInfo.stretching?.length !== 0) ||
            (treatmentInfo.taping?.length !== 0) ||
            (treatmentInfo.therapeuticExercise?.length !== 0) ||
            (treatmentInfo.therapeuticMassage?.length !== 0) ||
            (treatmentInfo.thermalTherapy?.length !== 0);
    }

    renderCloseConfirmModal = () => {
        return (
            <Modal visible={this.state.closeConfirmModalVisible}
                   submitContent="Yes"
                   closeContent="No"
                   closeAction={this.closeCloseConfirmModal}
                   submitAction={this.submitCloseConfirmModal}
                   header='Are you sure? '
                   maxWidth={"md"}
                   contentStyle={{maxHeight: "95%", width: "20vw"}}>
                Unsaved changes may be lost!
            </Modal>
        );
    }
    submitCloseConfirmModal = () => {
        this.state.closeModalFunctionCallback();
        this.setState({closeConfirmModalVisible: false});
    }

    closeCloseConfirmModal = () => {
        this.setState({closeConfirmModalVisible: false});
    }

    openCloseConfirmModal = (functionToBeExecuted) => {
        this.setState({closeConfirmModalVisible: true, closeModalFunctionCallback: functionToBeExecuted});
    }

    openFallRiskManagementModal = () => {
        this.copiedFallRiskManagement = cloneDeep(this.props.treatment.fallRiskManagement);

        if (this.props.treatment.fallRiskManagement == null) {
            this.props.treatment.fallRiskManagement = {};
            this.props.treatment.fallRiskManagement.items = [];
        }

        this.setState({fallRiskManagementModalVisible: true});
    }

    saveFallRiskManagementModal = () => {
        this.props.onModalSave();
        this.setState({
            fallRiskManagementModalVisible: false,
            index: this.props.currentVisitIndex
        });
    }

    closeFallRiskManagementModal = () => {
        this.props.treatment.fallRiskManagement = this.copiedFallRiskManagement;
        this.setState({fallRiskManagementModalVisible: false});
    }

    renderFallRiskManagementModal = () => {
        if (!this.state.fallRiskManagementModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.fallRiskManagementModalVisible}
                   className="FallRiskManagementModal"
                   submitContent="Save"
                   submitAction={this.saveFallRiskManagementModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeFallRiskManagementModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   paperStyle={{overflowY: 'visible'}}
                   contentStyle={{overflowY: 'visible', height: "70%", maxHeight: "95%", width: "52vw"}}>
                <FallRiskManagementModal treatment={this.props.treatment} appointmentType={this.props.currentVisit.appointmentType}/>
            </Modal>
        );
    }

    openTreatmentPlanModal = () => {
        this.copiedTreatmentPlan = cloneDeep(this.props.treatment.treatmentPlan);
        if (this.props.treatment.treatmentPlan == null) {
            this.props.treatment.treatmentPlan = {};
        }
        this.setState({treatmentPlanModalVisible: true});
    }

    saveTreatmentPlanModal = () => {
        this.props.onModalSave();
        this.setState({
            treatmentPlanModalVisible: false,
            index: this.props.currentVisitIndex
        });
    }

    closeTreatmentPlanModal = () => {
        this.props.treatment.treatmentPlan = this.copiedTreatmentPlan;
        this.setState({treatmentPlanModalVisible: false});
    }

    renderTreatmentPlanModal = () => {
        if (!this.state.treatmentPlanModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.treatmentPlanModalVisible}
                   className="TreatmentPlanModal"
                   submitContent="Save"
                   submitAction={this.saveTreatmentPlanModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeTreatmentPlanModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   contentStyle={{height: "70%", maxHeight: "95%", width: "65vw", overflowY: "auto"}}>
                <TreatmentInfoModal type={TreatmentInfoEnum.TREATMENT_PLAN}
                                    treatmentInfo={this.props.treatment.treatmentPlan}
                                    appointmentType={this.props.currentVisit.appointmentType}
                                    visit={this.props.currentVisit}
                                    insurance={this.props.insurance}/>
            </Modal>
        );
    }

    openTreatmentReceivedModal = () => {
        this.copiedTreatmentReceived = cloneDeep(this.props.treatment.treatmentReceived);
        if (this.props.treatment.treatmentReceived == null) {
            this.props.treatment.treatmentReceived = {};
        }
        this.setState({treatmentReceivedModalVisible: true});
    }

    saveTreatmentReceivedModal = () => {
        this.props.onModalSave();
        this.setState({
            treatmentReceivedModalVisible: false,
            index: this.props.currentVisitIndex
        });
    }

    closeTreatmentReceivedModal = () => {
        this.props.treatment.treatmentReceived = this.copiedTreatmentReceived;
        this.setState({treatmentReceivedModalVisible: false});
    }

    renderTreatmentReceivedModal = () => {
        if (!this.state.treatmentReceivedModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.treatmentReceivedModalVisible}
                   className="TreatmentReceivedModal"
                   submitContent="Save"
                   submitAction={this.saveTreatmentReceivedModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeTreatmentReceivedModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   contentStyle={{height: "70%", maxHeight: "95%", width: "65vw", overflowY: "auto"}}>
                <TreatmentInfoModal type={TreatmentInfoEnum.TREATMENT_RECEIVED}
                                    treatmentInfo={this.props.treatment.treatmentReceived}
                                    appointmentType={this.props.currentVisit.appointmentType}
                                    visit={this.props.currentVisit}
                                    onModalSave={this.props.onModalSave}/>
            </Modal>
        );
    }

    openReferralModal = () => {
        this.setState({referralModalVisible: true});
    }

    closeReferralModal = () => {
        this.setState({referralModalVisible: false});
    }

    renderReferralModal = () => {
        return this.state.referralModalVisible ?
            <ReferralModal visit={this.props.currentVisit}
                           insurance={this.props.insurance}
                           referral={this.props.treatment.referral}
                           save={() => this.props.onModalSave()}
                           close={() => this.openCloseConfirmModal(this.closeReferralModal)}
                           forceClose={() => this.closeReferralModal()}
            /> : null;
    }

    openTreatmentGoalsModal = () => {
        this.copiedTreatmentGoal = cloneDeep(this.props.treatment.goal);
        if (this.props.treatment.goal == null) {
            this.props.treatment.goal = {shortTermItems: [], longTermItems: []};

        } else {
            if (this.props.treatment.goal.shortTermItems == null) {
                this.props.treatment.goal.shortTermItems = [];
            }
            if (this.props.treatment.goal.longTermItems == null) {
                this.props.treatment.goal.longTermItems = [];
            }
        }
        this.setState({treatmentGoalsModalVisible: true});
    }

    saveTreatmentGoalsModal = () => {
        this.props.onModalSave();
        this.setState({
            treatmentGoalsModalVisible: false,
            index: this.props.currentVisitIndex
        });
    }

    closeTreatmentGoalsModal = () => {
        this.props.treatment.goal = this.copiedTreatmentGoal;
        this.setState({treatmentGoalsModalVisible: false});
    }

    renderTreatmentGoalsModal = () => {
        if (!this.state.treatmentGoalsModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.treatmentGoalsModalVisible}
                   className="TreatmentGoalsModal"
                   submitContent="Save"
                   submitAction={this.saveTreatmentGoalsModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeTreatmentGoalsModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   contentStyle={{height: "70%", maxHeight: "95%", width: "75vw", overflowY: "scroll"}}>
                <TreatmentGoalsModal treatment={this.props.treatment} appointmentType={this.props.currentVisit.appointmentType}/>
            </Modal>
        );
    }

    openTreatmentResponseModal = () => {
        this.copiedTreatmentResponse = cloneDeep(this.props.treatment.response);
        if (this.props.treatment.response == null) {
            this.props.treatment.response = {};
        }
        this.setState({treatmentResponseModalVisible: true});
    }

    saveTreatmentResponseModal = () => {
        this.props.onModalSave();
        this.setState({
            treatmentResponseModalVisible: false,
            index: this.props.currentVisitIndex
        });
    }

    closeTreatmentResponseModal = () => {
        this.props.treatment.response = this.copiedTreatmentResponse;
        this.setState({treatmentResponseModalVisible: false});
    }

    renderTreatmentResponseModal = () => {
        if (!this.state.treatmentResponseModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.treatmentResponseModalVisible}
                   className="TreatmentResponseModal"
                   submitContent="Save"
                   submitAction={this.saveTreatmentResponseModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeTreatmentResponseModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   contentStyle={{height: "70%", maxHeight: "95%", width: "55vw", overflowY: "auto"}}>
                <TreatmentResponseModal treatment={this.props.treatment}/>
            </Modal>
        );
    }


    openTreatmentScheduleModal = () => {
        this.copiedTreatmentSchedule = cloneDeep(this.props.treatment.treatmentSchedule);
        this.copiedTreatmentPlanCompliance = cloneDeep(this.props.treatment.treatmentPlanCompliance);
        this.copiedRiskOfHarmFromTreatment = cloneDeep(this.props.treatment.riskOfHarmFromTreatment);

        if (this.props.treatment.treatmentSchedule == null) {
            this.props.treatment.treatmentSchedule = {};
        }

        if (this.props.treatment.treatmentPlanCompliance == null) {
            this.props.treatment.treatmentPlanCompliance = {};
        }

        if (this.props.treatment.riskOfHarmFromTreatment == null) {
            this.props.treatment.riskOfHarmFromTreatment = {};
        }

        this.setState({treatmentScheduleModalVisible: true});
    }

    saveTreatmentScheduleModal = () => {
        this.props.onModalSave();
        this.setState({
            treatmentScheduleModalVisible: false,
            index: this.props.currentVisitIndex
        });
    }

    closeTreatmentScheduleModal = () => {
        this.props.treatment.treatmentSchedule = this.copiedTreatmentSchedule;
        this.props.treatment.treatmentPlanCompliance = this.copiedTreatmentPlanCompliance;
        this.props.treatment.riskOfHarmFromTreatment = this.copiedRiskOfHarmFromTreatment;

        this.setState({treatmentScheduleModalVisible: false});
    }

    renderTreatmentScheduleModal = () => {
        if (!this.state.treatmentScheduleModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.treatmentScheduleModalVisible}
                   className="TreatmentScheduleModal"
                   submitContent="Save"
                   submitAction={this.saveTreatmentScheduleModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeTreatmentScheduleModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   contentStyle={{height: "70%", maxHeight: "95%", width: "52vw", overflowY: "auto"}}>
                <TreatmentScheduleModal treatment = {this.props.treatment} appointmentType={this.props.currentVisit.appointmentType}/>
            </Modal>
        );
    }

    openDischargePlanModal = () => {
        this.copiedDischargePlan = cloneDeep(this.props.treatment.dischargePlan);

        if (this.props.treatment.dischargePlan == null) {
            this.props.treatment.dischargePlan = {};
        }

        this.setState({dischargePlanModalVisible: true});
    }

    saveDischargePlanModal = () => {
        this.props.onModalSave();
        this.setState({
            dischargePlanModalVisible: false,
            index: this.props.currentVisitIndex
        });
    }

    closeDischargePlanModal = () => {
        this.props.treatment.dischargePlan = this.copiedDischargePlan;
        this.setState({dischargePlanModalVisible: false});
    }

    renderDischargePlanModal = () => {
        if (!this.state.dischargePlanModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.dischargePlanModalVisible}
                   className="DischargePlanModal"
                   submitContent="Save"
                   submitAction={this.saveDischargePlanModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeDischargePlanModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   contentStyle={{height: "70%", maxHeight: "95%", width: "52vw", overflowY: "auto"}}>
                <DischargePlanModal treatment={this.props.treatment}/>
            </Modal>
        );
    }

    isDischargePlanChanged = (dischargePlan) => {
        return (
            dischargePlan.anticipatedDischargeDate ||
            (dischargePlan.anticipatedDischargeDetails && dischargePlan.anticipatedDischargeDetails.length != 0) ||
            dischargePlan.dateOfDischarge ||
            (dischargePlan.dischargePlanDetails && dischargePlan.dischargePlanDetails.length != 0) ||
            (dischargePlan.discontinuationPlanDetails && dischargePlan.discontinuationPlanDetails.length != 0) ||
            dischargePlan.dateOfDiscontinuation ||
            dischargePlan.reasonForDiscontinuation ||
            dischargePlan.goalAchieved ||
            (dischargePlan.goalAchievedDetails && dischargePlan.goalAchievedDetails.length != 0) ||
            (dischargePlan.reasonForGoalNotAchieved && dischargePlan.reasonForGoalNotAchieved.length != 0) ||
            (dischargePlan.homeProgram && dischargePlan.homeProgram.length != 0) ||
            (dischargePlan.referrals && dischargePlan.referrals.length != 0) ||
            (dischargePlan.recommendations && dischargePlan.recommendations.length != 0)
        );
    }

    openPatientEducationModal = () => {
        this.copiedPatientEducation = cloneDeep(this.props.treatment.patientEducation);
        if (this.props.treatment.patientEducation == null) {
            this.props.treatment.patientEducation = {items: []}
        }
        if (this.props.treatment.patientEducation.items == null) {
            this.props.treatment.patientEducation.items = [];
        }

        this.setState({patientEducationModalVisible: true});
    }

    savePatientEducationModal = () => {
        this.props.onModalSave();
        this.setState({
            patientEducationModalVisible: false,
            index: this.props.currentVisitIndex
        });
    }

    closePatientEducationModal = () => {
        this.props.treatment.patientEducation = this.copiedPatientEducation;
        this.setState({patientEducationModalVisible: false});
    }

    renderPatientEducationModal = () => {
        if (!this.state.patientEducationModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.patientEducationModalVisible}
                   className="PatientEducationModal"
                   submitContent="Save"
                   submitAction={this.savePatientEducationModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closePatientEducationModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   paperStyle={{overflowY: 'visible'}}
                   contentStyle={{height: "70%", maxHeight: "95%", width: "52vw", overflowY: "visible"}}>
                <PatientEducationModal patientEducation={this.props.treatment.patientEducation}/>
            </Modal>
        );
    }

}
