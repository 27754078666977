import Enum from "../../../../../../enums/Enum";

const EtiologyEnum = new Enum({
    UNKNOWN: {name: "Unknown", nameInArabic: "مجهول"},
    SPORTS: {name: "Sports", nameInArabic: "إصابة رياضية"},
    SLIPFALL: {name: "Slip/Fall", nameInArabic: "انزلاق / سقوط"},
    ACCIDENT: {name: "Accident", nameInArabic: "حادث"},
    WORK: {name: "Work", nameInArabic: "العمل"},
    SURGERY: {name: "Surgery", nameInArabic: "عملية جراحية"},
    WRONGPOSTURE: {name: "Wrong Posture/Movement", nameInArabic: "وضعية/ حركة خاطئة"},
    OTHER: {name: "Other", nameInArabic: "آخر"}
});

export default EtiologyEnum;