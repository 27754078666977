import React from "react";

export default function SignatureDisplay(props) {
    const signature = props.signature;

    return (
        signature ?
            <div>
                <img
                    className="profile-image"
                    src={`data:image/jpeg;base64,${signature}`}
                    alt=""
                />
            </div> :
            <>No Signature</>
    );
}
