import React, {Component} from "react";
import "../../VisitHistory.css"
import BloodPressureLocationEnum from "../../../assessment/enums/vitalSigns/BloodPressureLocationEnum";
import PulsePositionEnum from "../../../assessment/enums/vitalSigns/PulsePositionEnum";
import TemperatureLocationEnum from "../../../assessment/enums/vitalSigns/TemperatureLocationEnum";
import PulseLocationEnum from "../../../assessment/enums/vitalSigns/PulseLocationEnum";
import BloodPressurePositionEnum from "../../../assessment/enums/vitalSigns/BloodPressurePositionEnum";
import BMIEnum from "../../../assessment/enums/vitalSigns/BMIEnum";
import VitalSignsUtil from "../../../assessment/utils/VitalSignsUtil";

export default class VitalSignsViewHistory extends Component {

    render() {
        if (!this.props.vitalSigns) {
            return <div className="pl-3">No information available</div>;
        }

        let {bmi, bmiStatus} = this.props.vitalSigns;
        if (bmi != null && bmiStatus == null) {
            bmiStatus = VitalSignsUtil.getBMIStatus(bmi);
        }

        return (
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Temperature <br/>(°C)</th>
                    <th>Pulse <br/>(Beats/Min)</th>
                    <th>Blood Pressure <br/> (mm/Hg)</th>
                    <th>Respiratory Rate <br/>(Breaths/Min)</th>
                    <th>Height <br/>(cm - ft)</th>
                    <th>Weight <br/>(kg - lbs)</th>
                    <th>BMI <br/>(kg/㎡)</th>
                    <th>Body Fat<br/> (%)</th>

                </tr>
                <tr key={"vitalSignsViewHistory"}>
                    {this.renderTemperatureValueColumn()}
                    {this.renderPulseValueColumn()}
                    {this.renderBloodPressureValueColumn()}
                    {this.renderRespirationValueColumn()}
                    {this.renderHeightValueColumn()}
                    {this.renderWeightValueColumn()}
                    {this.renderBMIValueColumn(bmi, bmiStatus)}
                    {this.renderBodyFatValueColumn()}
                </tr>
                </tbody>
            </table>
        );
    }

    renderBodyFatValueColumn() {
        return (
            <td>
                {this.renderColumn([
                    this.props.vitalSigns.bodyFat,
                    this.getDetailsText(this.props.vitalSigns.bodyFatDetails)
                ])}
            </td>);
    }

    renderBMIValueColumn(bmi, bmiStatus) {
        return (
            <td>
                {this.renderColumn([
                    bmi,
                    bmiStatus != null ? BMIEnum[bmiStatus].name : null
                ])}
            </td>);
    }

    renderWeightValueColumn() {
        return (
            <td>
                {this.renderColumn([
                    this.props.vitalSigns.weight,
                    this.getDetailsText(this.props.vitalSigns.weightDetails)
                ])}
            </td>);
    }

    renderHeightValueColumn() {
        return (
            <td>
                {this.renderColumn([
                    this.props.vitalSigns.height,
                    this.getDetailsText(this.props.vitalSigns.heightDetails)
                ])}
            </td>);
    }

    renderRespirationValueColumn() {
        return (
            <td>
                {this.renderColumn([
                    this.props.vitalSigns.respiration,
                    this.getDetailsText(this.props.vitalSigns.respirationDetails)
                ])}
            </td>);
    }

    renderBloodPressureValueColumn() {
        return (
            <td>
                {this.renderColumn([
                    [this.props.vitalSigns.bloodPressureAbove, this.props.vitalSigns.bloodPressureBelow].filter(Boolean).join(" / "),
                    this.getLocationText(this.props.vitalSigns.bloodPressureLocation, BloodPressureLocationEnum),
                    this.getPositionText(this.props.vitalSigns.bloodPressurePosition, BloodPressurePositionEnum),
                    this.getDetailsText(this.props.vitalSigns.bloodPressureDetails)
                ])}
            </td>);
    }

    renderPulseValueColumn() {
        return (
            <td>
                {this.renderColumn([
                    this.props.vitalSigns.pulse,
                    this.getLocationText(this.props.vitalSigns.pulseLocation, PulseLocationEnum),
                    this.getPositionText(this.props.vitalSigns.pulsePosition, PulsePositionEnum),
                    this.getDetailsText(this.props.vitalSigns.pulseDetails)
                ])}
            </td>);
    }

    renderTemperatureValueColumn() {
        return (
            <td>
                {this.renderColumn([
                    this.props.vitalSigns.temperature,
                    this.getLocationText(this.props.vitalSigns.temperatureLocation, TemperatureLocationEnum),
                    this.getDetailsText(this.props.vitalSigns.temperatureDetails)
                ])}
            </td>);
    }

    getLocationText(value, locationEnum){
        return this.getTextValue("Location", value, locationEnum);
    }

    getPositionText(value, positionEnum){
        return this.getTextValue("Position", value, positionEnum);
    }

    getDetailsText(value){
        return this.getTextValue("Details", value);
    }

    getTextValue(boldText, value, relatedEnum){
        return (value != null ? <><b>{boldText}: </b> {relatedEnum != null ? relatedEnum[value]?.name : value}</> : null);
    }

    renderColumn = (itemList) => {
        return (itemList.filter(Boolean).map(item => <p className="m-0">{item}</p>))
    }
}