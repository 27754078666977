import React, {Component} from 'react'
import Logo from "./Logo";
import moment from "moment";
import confirmDialog from "../../../components/dialog/ConfirmDialog";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import RemotingService from "../../../services/remoting-service/RemotingService";
import {NumberInput, TextInput, Wizard} from "../../../components";
import FormField from "../../../components/form/FormField";
import SignatureInput from "../../../components/signature-input/SignatureInput";
import ConsentFormsUtil from "../utils/ConsentFormsUtil";

export default class PublicIVInfusionConsentForm extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    createHeader = (form) => {
        return <div style={{padding: "0px 20px 0px 20px"}}>
            <div className="row text-left">
                <div className="col-12 no-padding">
                    <Logo/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center no-padding" style={{fontSize: 20, fontWeight: "bold"}}>
                    IV INFUSION CONSENT FORM
                </div>
            </div>
            <div className="row" style={{fontSize: 11}}>
                <div className="col-4 text-left no-padding">
                    <b>Patient Name: </b> {form.patientFullName}
                </div>
                <div className="col-4 text-center no-padding">
                    <b>MRN No: </b> {form.patientMrn}
                </div>

                <div className="col-4 text-right no-padding">
                    <b>D.O.B: </b> {form.patientDOB}
                </div>
            </div>
        </div>
    };

    fieldChanged = (fieldName, value) => {
        this.props.form[fieldName] = value;
        this.forceUpdate();
    };

    close = () => {
        confirmDialog(
            'Confirmation',
            'Are you sure you want to close this form? All input will be lost.',
            this.props.close);
    };

    submitForm = () => {
        if (ValidationUtil.checkWithNotification(this.formFields)) {
            const {consentFormCode: code, form} = this.props;
            RemotingService.postRemoteCall(`api/public/consent-form/code/${code}/iv-infusion-consent-form`, form);
            this.props.onFormSubmit(form);
        }
    };

    render() {
        this.formFields = [];
        const {form} = this.props;
        return (
            <Wizard
                show={true} fullScreen
                footerStyle={{backgroundColor: '#f5f5f5'}}
                buttonStyle={{backgroundColor: '#84BE56', border: 'none'}}
                header={this.createHeader(form)}
                close={this.close}
                onSubmit={this.submitForm}>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}>
                    <div style={{margin: "20px 20px 20px 20px"}}>
                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-4 dim14-form-label">
                                    Fee for the proposed procedure
                                </div>
                                <div className="col-md-4 dim14-base-input">
                                    <NumberInput value={form.feeForProposedProcedure}
                                                 min={0}
                                                 allowDecimal
                                                 maxFractionDigits={form.patientCountry === "UAE" ? 2 : 3}
                                                 onChange={(value) => this.fieldChanged('feeForProposedProcedure', value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>

                    <div style={{margin: "20px 20px 20px 20px"}}>

                        <div style={{backgroundColor: "white", marginBottom: "20px", padding: "20px"}}>
                            <h4 className={"mb-0"}>Statement of health professional:</h4>
                            <small>(to be filled in by health professional with appropriate knowledge of proposed procedure) </small>
                            <p>I have explained the procedure to the patient. In particular:</p>
                            <p><u>The intended benefits:</u> reduce the stress hormones, improve immune system, restoring antioxidant levels. </p>
                            <h6><u>The side effects may include:</u></h6>
                            <p><u>Common:</u> pain at the site of injection, headache, nausea, vomiting, bruising.</p>
                            <p><u>Uncommon:</u> altered skin coloration at the site of injection, phlebitis of the injected vein, temporary ﬂuid retention/swelling/increased blood pressure, mood changes.</p>
                            <p><u>Very rarely encountered:</u> shock, anaphylactic reactions.</p>
                            <p>I have confirmed with the patient that he/she has no further questions and wishes the procedure to go ahead.</p>
                        </div>

                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    Provider Name
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    {": " + (form.providerName ? form.providerName : " ")}
                                </div>

                                <div className="col-md-3 dim14-form-label">
                                    Job Title
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    {": " + (form.providerSpeciality ? form.providerSpeciality : " ")}
                                </div>
                            </div>

                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    Date/Time
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    {": " + moment(new Date(form.healthProfessionalDate)).format('MMMM DD YYYY, h:mm:ss a')}
                                </div>

                                <div className="col-md-6">
                                    <div className="col-md-12 dim14-form-label">
                                        Signature of Provider
                                    </div>

                                    <div className="col-md-12">
                                        <FormField
                                            ref={(ref) => this.formFields.push(ref)}
                                            required requiredMessage="Signature required"
                                            validateOn={form.providerSignature}
                                        >
                                            <SignatureInput signature={form.providerSignature}
                                                            onClear={() => {
                                                                form.providerSignature = null;
                                                                this.forceUpdate();
                                                            }}
                                                            onSave={(signature) => {
                                                                form.providerSignature = signature;
                                                                this.forceUpdate();
                                                            }}/>
                                        </FormField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>

                    <div style={{margin: "20px 20px 20px 20px"}}>

                        <div style={{backgroundColor: "white", marginBottom: "20px", padding: "20px"}}>
                            <div style={{margin: "0px 15px"}}>
                            <h4 className={"mb-0"}>Statement of patient:</h4>
                                <p>Please read this form carefully.
                                    <br/>If you have any further questions, do ask.
                                    <br/>You have the right to change your mind at any time, including after you have signed this form.
                                </p>

                                    <p><b>I agree</b> to the procedure or course of treatment described on this form.</p>
                                    <p> <b>I agree</b> that information and/or surgical images kept in my records may be used anonymously for education,
                                        audit and research approved by an ethics committee, to improve the quality of patient care. </p>
                                    <p><b>I understand</b> that the person performing the procedure has the appropriate experience or is training under direct supervision.</p>
                                    <p><b>I understand</b> that any procedure in addition to those described on this form will only be carried out if it is necessary to save my life or to prevent serious harm to my health.</p>
                                    <p><b>I agree</b> that I have had a chance to ask appropriate questions.</p>
                                    <p><b>I agree</b> to the contents of this form and I agree to receive the treatment. </p>


                            </div>

                        </div>

                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row">
                                        <div className="col-md-6 dim14-form-label">
                                            Date/Time
                                        </div>
                                        <div className="col-md-6 dim14-base-input">
                                            {": " + moment(new Date(form.patientDate)).format('MMMM DD YYYY, h:mm:ss a')}
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-6 dim14-form-label">
                                            Patient Name
                                        </div>
                                        <div className="col-md-6 dim14-base-input">
                                            {": " + form.patientFullName}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="col-md-12 dim14-form-label">
                                        Signature of Patient
                                    </div>

                                    <div className="col-md-12">
                                        <SignatureInput signature={form.patientSignature}
                                                        onClear={() => {
                                                            form.patientSignature = null;
                                                            this.forceUpdate();
                                                        }}
                                                        onSave={(signature) => {
                                                            form.patientSignature = signature;
                                                            this.forceUpdate();
                                                        }}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onPrevious={() => this.formFields = []}>

                    <div style={{margin: "20px 20px 20px 20px"}}>
                        <div className={"row"}>
                            <p><b>A witness should sign below if the patient is unable to sign but has indicated
                                his or her consent. <br/>
                                Young people (under {ConsentFormsUtil.getWitnessAgeText()} years of age) and children should also have a Parent
                                or Guardian sign here.</b></p>
                        </div>
                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    Print Name
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    <TextInput value={form.guardianName} alphabeticOnly
                                               onChange={(value) => this.fieldChanged('guardianName', value)}/>
                                </div>

                                <div className="col-md-3 dim14-form-label">
                                    Relationship to Patient
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    <TextInput value={form.guardianRelationship} alphabeticOnly
                                               onChange={(value) => this.fieldChanged('guardianRelationship', value)}/>
                                </div>
                            </div>

                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    Date/Time
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    {": " + moment(new Date(form.guardianDate)).format('MMMM DD YYYY, h:mm:ss a')}
                                </div>

                                <div className="col-md-6">
                                    <div className="col-md-12 dim14-form-label">
                                        Signature of Witness/Parent or Guardian
                                    </div>

                                    <div className="col-md-12">
                                        <FormField
                                            ref={(ref) => this.formFields.push(ref)}
                                            disabled={form.patientSignature != null}
                                            required requiredMessage="Signature required"
                                            validateOn={form.guardianSignature}
                                        >
                                            <SignatureInput signature={form.guardianSignature}
                                                            onClear={() => {
                                                                form.guardianSignature = null;
                                                                this.forceUpdate();
                                                            }}
                                                            onSave={(signature) => {
                                                                form.guardianSignature = signature;
                                                                this.forceUpdate();
                                                            }}/>
                                        </FormField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>
            </Wizard>
        )
    }
}
