import Enum from "../../../../../../enums/Enum";

const RelievingFactorsEnum = new Enum({
    MEDICATION: {name: "Medication"},
    REST: {name: "Rest"},
    HEAT: {name: "Heat"},
    SLEEP: {name: "Sleep"},
    MOVEMENTEXERCISE: {name: "Movement/Exercise"},
    MASSAGE: {name: "Massage"},
    OTHER: {name: "Other"}
});

export default RelievingFactorsEnum;