import React, {Component} from 'react';
import IntakeFormTextEnum from "../enum/IntakeFormTextEnum";

class PublicMakesSymptomsBetter extends Component {
    render() {
        const {form, selectedLanguage, checkBoxField, validatedTextField} = this.props

        return (
            <>
                <div className="row mt-3">
                    <label className="col-form-label">
                        {IntakeFormTextEnum.Make_Symptoms_Better[selectedLanguage]}
                    </label>
                </div>
                <div className="row">
                    <div className="col-12">
                        {checkBoxField(
                            form,
                            "symptomBetterMovement",
                            IntakeFormTextEnum.Movement_Exercise[selectedLanguage]
                        )}
                        {checkBoxField(
                            form,
                            "symptomBetterMedication",
                            IntakeFormTextEnum.Medication[selectedLanguage]
                        )}
                        {checkBoxField(form, "symptomBetterRest", IntakeFormTextEnum.Rest[selectedLanguage])}
                        {checkBoxField(form, "symptomBetterSleep", IntakeFormTextEnum.Sleep[selectedLanguage])}
                        {checkBoxField(form, "symptomBetterHeat", IntakeFormTextEnum.Heat[selectedLanguage])}
                        {checkBoxField(form, "symptomBetterMassage", IntakeFormTextEnum.Massage[selectedLanguage])}
                        {checkBoxField(form, "symptomBetterOther", IntakeFormTextEnum.Other[selectedLanguage])}
                    </div>
                </div>

                {form.symptomBetterOther && (
                    <div className="row">
                        <div className="container-fluid p-0">
                            <div className="panel bg-grey px-3 py-0 mb-3">
                                <div className="form-group">
                                    <label
                                        className="col-form-label">{IntakeFormTextEnum.Other[selectedLanguage]}</label>
                                    {validatedTextField(form, "symptomBetterOtherDetail")}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default PublicMakesSymptomsBetter;