import React from "react";
import IntakeFormWizard from "./IntakeFormWizard";
import RemotingService from "../../services/remoting-service/RemotingService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ActionMenu from "../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../components/action-menu/ActionMenuItem";
import ViewIcon from "../../components/action-menu/icons/ViewIcon";
import EditIcon from "../../components/action-menu/icons/EditIcon";
import "./ConsentFormWizard.css";
import InformedConsentFormDxb from "./InformedConsentFormDxb";
import InformedConsentFormKwt from "./InformedConsentFormKwt";
import AuthService from "../../services/auth-service/AuthService";
import CancellationPolicyKwt from "./CancellationPolicyKwt";
import CancellationPolicyDxb from "./CancellationPolicyDxb";
import ReleaseOfMedicalRecordsConsentForm from "./ReleaseOfMedicalRecordsConsentForm";
import Tooltip from "../../components/tooltip/Tooltip";
import Modal from "../../components/modal/Modal";
import {Button} from "react-bootstrap";
import TeleconsultationConsentForm from "./TeleconsultationConsentForm";
import QrIcon from "../../components/action-menu/icons/QrIcon";
import dialog from "../../components/dialog/Dialog";
import NewPatientFormDxb from "./NewPatientFormDxb";
import NewPatientFormKwt from "./NewPatientFormKwt";
import ConsentFormCode from "./ConsentFormCode";
import FormLanguages from "./enum/FormLanguages";
import SockJsClient from "react-stomp";

const NEW_PATIENT_FORM_DXB = "New Patient Form Dubai";
const NEW_PATIENT_FORM_KWT = "New Patient Form Kuwait";
const INTAKE_FORM = "Intake Form";
const GENERAL_CONSENT_FORM_DXB = "Informed Consent Form Dubai";
const GENERAL_CONSENT_FORM_KWT = "Informed Consent Form Kuwait";
const CANCELLATION_POLICY_FORM_DXB = "Cancellation Policy Form Dubai";
const CANCELLATION_POLICY_FORM_KWT = "Cancellation Policy Form Kuwait";
const RELEASE_OF_MEDICAL_RECORDS_FORM = "Release Of Medical Records Form";
const TELECONSULTATION_CONSENT_FORM = "Teleconsultation Consent Form";

class ConsentForms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dxbNewPatientForm: {
                consentForIntakeForm: null,
                consentForInformedConsent: null,
                cancellationPolicy: null,
            },
            kwtNewPatientForm: {
                consentForIntakeForm: null,
                consentForInformedConsent: null,
                cancellationPolicy: null,
            },
            intakeForm: null,
            generalConsentForm: null,
            dxbInformedConsentForm: null,
            kwiInformedConsentForm: null,
            kwtCancellationPolicyForm: null,
            dxbCancellationPolicyForm: null,
            releaseOfMedicalRecordsForm: null,
            teleconsultationConsentForm: null,
            selectedForm: null,
            languageSelectionModalVisible: false,
        };
    }

    componentDidMount() {
        RemotingService.getRemoteCall(
            "api/patient/" + this.props.patient.id + "/new-patient-form-dxb",
            null,
            (form) => {
                if (form !== null) this.setState({dxbNewPatientForm: form});
            }
        );

        RemotingService.getRemoteCall(
            "api/patient/" + this.props.patient.id + "/new-patient-form-kwt",
            null,
            (form) => {
                if (form !== null) this.setState({kwtNewPatientForm: form});
            }
        );

        RemotingService.getRemoteCall(
            "api/patient/" + this.props.patient.id + "/intake-form-consent",
            null,
            (form) => {
                this.setState({
                    intakeForm: form,
                    dxbNewPatientForm: {
                        ...this.state.dxbNewPatientForm,
                        consentForIntakeForm: form !== null ? form : null,
                    },
                    kwtNewPatientForm: {
                        ...this.state.kwtNewPatientForm,
                        consentForIntakeForm: form !== null ? form : null,
                    },
                });
            }
        );

        RemotingService.getRemoteCall(
            "api/patient/" + this.props.patient.id + "/general-info-consent",
            null,
            (form) => {
                this.setState({generalConsentForm: form});
            }
        );

        RemotingService.getRemoteCall(
            "api/patient/" + this.props.patient.id + "/general-info-consent-dxb",
            null,
            (form) => {
                this.setState({
                    dxbInformedConsentForm: form,
                    dxbNewPatientForm: {
                        ...this.state.dxbNewPatientForm,
                        consentForInformedConsent: form !== null ? form : null,
                    },
                });
            }
        );

        RemotingService.getRemoteCall(
            "api/patient/" + this.props.patient.id + "/general-info-consent-kwi",
            null,
            (form) => {
                this.setState({
                    kwiInformedConsentForm: form,
                    kwtNewPatientForm: {
                        ...this.state.kwtNewPatientForm,
                        consentForInformedConsent: form !== null ? form : null,
                    },
                });
            }
        );

        RemotingService.getRemoteCall(
            "api/patient/" + this.props.patient.id + "/cancellation-policy-kwt",
            null,
            (form) => {
                this.setState({
                    kwtCancellationPolicyForm: form,
                    kwtNewPatientForm: {
                        ...this.state.kwtNewPatientForm,
                        cancellationPolicy: form !== null ? form : null,
                    },
                });
            }
        );

        RemotingService.getRemoteCall(
            "api/patient/" + this.props.patient.id + "/cancellation-policy-dxb",
            null,
            (form) => {
                this.setState({
                    dxbCancellationPolicyForm: form,
                    dxbNewPatientForm: {
                        ...this.state.dxbNewPatientForm,
                        cancellationPolicy: form !== null ? form : null,
                    },
                });
            }
        );

        RemotingService.getRemoteCall(
            "api/patient/" + this.props.patient.id + "/release-of-medical-records",
            null,
            (form) => {
                this.setState({releaseOfMedicalRecordsForm: form});
            }
        );

        RemotingService.getRemoteCall(
            "api/patient/" + this.props.patient.id + "/teleconsultation-consent-form",
            null,
            (form) => {
                this.setState({teleconsultationConsentForm: form}); //TODO: This will be removed after moving the forms to EMR
            }
        );

        const selectedClinicCountriesInfo = AuthService.getSelectedClinicCountriesInfo();
        this.dubaiSelected = selectedClinicCountriesInfo.uaeSelected;
        this.kuwaitSelected = selectedClinicCountriesInfo.kuwaitSelected;
    }

    updateNewPatientFormDxb(form) {
        this.setState({
            ...this.state.dxbNewPatientForm,
            consentForIntakeForm: form.consentForIntakeForm,
            consentForInformedConsent: form.consentForInformedConsent,
            cancellationPolicy: form.cancellationPolicy,
        });

        this.setState({
            intakeForm: form.consentForIntakeForm,
            dxbInformedConsentForm: form.consentForInformedConsent,
            dxbCancellationPolicyForm: form.cancellationPolicy,
        });
    }

    updateNewPatientFormKwt(form) {
        this.setState({
            ...this.state.kwtNewPatientForm,
            consentForIntakeForm: form.consentForIntakeForm,
            consentForInformedConsent: form.consentForInformedConsent,
            cancellationPolicy: form.cancellationPolicy,
        });

        this.setState({
            intakeForm: form.consentForIntakeForm,
            kwiInformedConsentForm: form.consentForInformedConsent,
            kwtCancellationPolicyForm: form.cancellationPolicy,
        });
    }

    refreshScreen() {
        window.location.reload(false);
    }

    render() {
        const cardCornerClassName =
            this.state.intakeForm && (this.state.dxbInformedConsentForm || this.state.kwiInformedConsentForm)
                ? "card green-corner"
                : "card red-corner";
        return (
            <>
                <div className={cardCornerClassName + " h-100"}>
                    <div className="card-header" style={{padding: "1rem 1.25rem"}}>
                        <div className="card-header-title">
                            <strong style={{verticalAlign: "middle"}}>Forms</strong>
                        </div>
                        <div className="card-header-actions"></div>
                    </div>
                    <div
                        className="card-body line-wrapper small-font pt-3"
                        style={{marginRight: "5px"}}
                    >
                        {this.dubaiSelected && !this.kuwaitSelected && (
                            <div>
                                <div>
                                    <ConsentFormItem
                                        form={NEW_PATIENT_FORM_DXB}
                                        formType={"NEW_PATIENT_FORM_DXB"}
                                        patientId={this.props.patient.id}
                                        status={this.state.dxbNewPatientForm &&
                                        this.state.dxbNewPatientForm.consentForIntakeForm != null &&
                                        this.state.dxbNewPatientForm.consentForInformedConsent != null &&
                                        this.state.dxbNewPatientForm.cancellationPolicy != null
                                        }
                                        handler={() =>
                                            this.setState({
                                                languageSelectionModalVisible: true,
                                                selectedForm: "dxbNewPatientFormModalVisible",
                                            })
                                        }
                                        readOnly={this.state.dxbNewPatientForm &&
                                        this.state.dxbNewPatientForm.consentForIntakeForm != null &&
                                        this.state.dxbNewPatientForm.consentForInformedConsent != null &&
                                        this.state.dxbNewPatientForm.cancellationPolicy != null
                                        }
                                    />
                                </div>
                                <div>
                                    <hr/>
                                    <ConsentFormItem
                                        form={INTAKE_FORM}
                                        formType={"INTAKE_FORM"}
                                        patientId={this.props.patient.id}
                                        status={this.state.intakeForm != null}
                                        handler={() =>
                                            this.setState({
                                                languageSelectionModalVisible: true,
                                                selectedForm: "intakeFormModalVisible",
                                            })
                                        }
                                        readOnly={
                                            this.state.intakeForm != null
                                        }
                                    />
                                    <FontAwesomeIcon
                                        icon={[
                                            "fa",
                                            `angle-double-${
                                                this.state.creditPanelExpanded ? "up" : "down"
                                            }`,
                                        ]}
                                        style={{
                                            position: "absolute",
                                            margin: "0",
                                            right: "3px",
                                            bottom: "3px",
                                        }}
                                        onClick={() =>
                                            this.setState({
                                                creditPanelExpanded: !this.state.creditPanelExpanded,
                                            })
                                        }
                                    />
                                </div>
                                <div
                                    className={`card-body scrollbar small-font text-right expandable ${
                                        this.state.creditPanelExpanded ? "expanded" : ""
                                    }`}
                                    id={"dim14-consent-form-scrollbar"}
                                    style={{
                                        marginLeft: "-20px",
                                        maxHeight: "400px",
                                        overflowY: "auto",
                                        display: "inline-block",
                                    }}
                                >
                                    <ConsentFormItem
                                        form={GENERAL_CONSENT_FORM_DXB}
                                        formType={"GENERAL_CONSENT_FORM_DXB"}
                                        patientId={this.props.patient.id}
                                        status={this.state.dxbInformedConsentForm != null}
                                        handler={() =>
                                            this.setState({
                                                languageSelectionModalVisible: true,
                                                selectedForm: "generalConsentFormModalVisible",
                                            })
                                        }
                                        readOnly={
                                            this.state.dxbInformedConsentForm != null
                                        }
                                    />
                                    <hr/>
                                    <ConsentFormItem
                                        form={CANCELLATION_POLICY_FORM_DXB}
                                        formType={"CANCELLATION_POLICY_DXB"}
                                        patientId={this.props.patient.id}
                                        status={this.state.dxbCancellationPolicyForm != null}
                                        handler={() =>
                                            this.setState({
                                                languageSelectionModalVisible: true,
                                                selectedForm: "dxbCancellationPolicyFormModalVisible",
                                            })
                                        }
                                        readOnly={
                                            this.state.dxbCancellationPolicyForm != null
                                        }
                                        rowStyle={{marginRight: "-10px"}}
                                    />
                                    <hr/>
                                    <ConsentFormItem
                                        form={RELEASE_OF_MEDICAL_RECORDS_FORM}
                                        formType={"RELEASE_OF_MEDICAL_RECORDS_FORM"}
                                        patientId={this.props.patient.id}
                                        status={this.state.releaseOfMedicalRecordsForm != null}
                                        handler={() =>
                                            this.setState({
                                                languageSelectionModalVisible: true,
                                                selectedForm: "releaseOfMedicalRecordsFormModalVisible",
                                            })
                                        }
                                        readOnly={
                                            this.state.releaseOfMedicalRecordsForm != null
                                        }
                                    />

                                    <hr/>
                                    <ConsentFormItem
                                        form={TELECONSULTATION_CONSENT_FORM}
                                        formType={"TELECONSULTATION_CONSENT_FORM"}
                                        patientId={this.props.patient.id}
                                        status={this.state.teleconsultationConsentForm != null}
                                        handler={() =>
                                            this.setState({
                                                teleconsultationConsentFormModalVisible: true,
                                                selectedForm: "teleconsultationConsentForm",
                                            })
                                        }
                                        readOnly={
                                            this.state.teleconsultationConsentForm != null
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {this.kuwaitSelected && !this.dubaiSelected && (
                            <div>
                                <div>
                                    <ConsentFormItem
                                        form={NEW_PATIENT_FORM_KWT}
                                        formType={"NEW_PATIENT_FORM_KWT"}
                                        patientId={this.props.patient.id}
                                        status={this.state.kwtNewPatientForm &&
                                        this.state.kwtNewPatientForm.consentForIntakeForm !=
                                        null &&
                                        this.state.kwtNewPatientForm.consentForInformedConsent !=
                                        null &&
                                        this.state.kwtNewPatientForm.cancellationPolicy != null
                                        }
                                        handler={() =>
                                            this.setState({
                                                languageSelectionModalVisible: true,
                                                selectedForm: "kwtNewPatientFormModalVisible",
                                            })
                                        }
                                        readOnly={this.state.kwtNewPatientForm &&
                                        this.state.kwtNewPatientForm.consentForIntakeForm !=
                                        null &&
                                        this.state.kwtNewPatientForm.consentForInformedConsent !=
                                        null &&
                                        this.state.kwtNewPatientForm.cancellationPolicy !=
                                        null
                                        }
                                    />
                                </div>
                                <div>
                                    <hr/>
                                    <ConsentFormItem
                                        form={INTAKE_FORM}
                                        formType={"INTAKE_FORM"}
                                        patientId={this.props.patient.id}
                                        status={this.state.intakeForm != null}
                                        handler={() =>
                                            this.setState({
                                                languageSelectionModalVisible: true,
                                                selectedForm: "intakeFormModalVisible",
                                            })
                                        }
                                        readOnly={
                                            this.state.intakeForm != null
                                        }
                                    />

                                    <FontAwesomeIcon
                                        icon={[
                                            "fa",
                                            `angle-double-${
                                                this.state.creditPanelExpanded ? "up" : "down"
                                            }`,
                                        ]}
                                        style={{
                                            position: "absolute",
                                            margin: "0",
                                            right: "3px",
                                            bottom: "3px",
                                        }}
                                        onClick={() =>
                                            this.setState({
                                                creditPanelExpanded: !this.state.creditPanelExpanded,
                                            })
                                        }
                                    />
                                </div>
                                <div
                                    className={`card-body scrollbar small-font text-right expandable ${
                                        this.state.creditPanelExpanded ? "expanded" : ""
                                    }`}
                                    id={"dim14-consent-form-scrollbar"}
                                    style={{
                                        marginLeft: "-20px",
                                        maxHeight: "400px",
                                        overflowY: "auto",
                                        display: "inline-block",
                                    }}
                                >
                                    <ConsentFormItem
                                        form={GENERAL_CONSENT_FORM_KWT}
                                        formType={"GENERAL_CONSENT_FORM_KWT"}
                                        patientId={this.props.patient.id}
                                        status={this.state.kwiInformedConsentForm != null}
                                        handler={() =>
                                            this.setState({
                                                languageSelectionModalVisible: true,
                                                selectedForm: "kwiConsentFormModalVisible",
                                            })
                                        }
                                        readOnly={
                                            this.state.kwiInformedConsentForm != null
                                        }
                                    />
                                    <hr/>
                                    <ConsentFormItem
                                        form={CANCELLATION_POLICY_FORM_KWT}
                                        formType={"CANCELLATION_POLICY_KWT"}
                                        patientId={this.props.patient.id}
                                        status={this.state.kwtCancellationPolicyForm != null}
                                        handler={() =>
                                            this.setState({
                                                languageSelectionModalVisible: true,
                                                selectedForm: "kwtCancellationPolicyFormModalVisible",
                                            })
                                        }
                                        readOnly={
                                            this.state.kwtCancellationPolicyForm != null
                                        }
                                        rowStyle={{marginRight: "-10px"}}
                                    />
                                    <hr/>
                                    <ConsentFormItem
                                        form={RELEASE_OF_MEDICAL_RECORDS_FORM}
                                        formType={"RELEASE_OF_MEDICAL_RECORDS_FORM"}
                                        patientId={this.props.patient.id}
                                        status={this.state.releaseOfMedicalRecordsForm != null}
                                        handler={() =>
                                            this.setState({
                                                languageSelectionModalVisible: true,
                                                selectedForm: "releaseOfMedicalRecordsFormModalVisible",
                                            })
                                        }
                                        readOnly={
                                            this.state.releaseOfMedicalRecordsForm != null
                                        }
                                    />

                                    <hr/>
                                    <ConsentFormItem
                                        form={TELECONSULTATION_CONSENT_FORM}
                                        formType={"TELECONSULTATION_CONSENT_FORM"}
                                        patientId={this.props.patient.id}
                                        status={this.state.teleconsultationConsentForm != null}
                                        handler={() =>
                                            this.setState({
                                                teleconsultationConsentFormModalVisible: true,
                                                selectedForm: "teleconsultationConsentForm",
                                            })
                                        }
                                        readOnly={
                                            this.state.teleconsultationConsentForm != null
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        {this.kuwaitSelected && this.dubaiSelected && (
                            <div>
                                <div>
                                    <ConsentFormItem
                                        form={NEW_PATIENT_FORM_DXB}
                                        formType={"NEW_PATIENT_FORM_DXB"}
                                        patientId={this.props.patient.id}
                                        status={
                                            this.state.dxbNewPatientForm?.consentForIntakeForm != null &&
                                            this.state.dxbNewPatientForm?.consentForInformedConsent != null &&
                                            this.state.dxbNewPatientForm?.cancellationPolicy != null
                                        }
                                        handler={() =>
                                            this.setState({
                                                languageSelectionModalVisible: true,
                                                selectedForm: "dxbNewPatientFormModalVisible",
                                            })
                                        }
                                        readOnly={
                                            this.state.dxbNewPatientForm?.consentForIntakeForm != null &&
                                            this.state.dxbNewPatientForm?.consentForInformedConsent != null &&
                                            this.state.dxbNewPatientForm?.cancellationPolicy != null
                                        }
                                    />
                                </div>
                                <div>
                                    <hr/>
                                    <ConsentFormItem
                                        form={NEW_PATIENT_FORM_KWT}
                                        formType={"NEW_PATIENT_FORM_KWT"}
                                        patientId={this.props.patient.id}
                                        status={
                                            this.state.kwtNewPatientForm?.consentForIntakeForm != null &&
                                            this.state.kwtNewPatientForm?.consentForInformedConsent != null &&
                                            this.state.kwtNewPatientForm?.cancellationPolicy != null
                                        }
                                        handler={() =>
                                            this.setState({
                                                languageSelectionModalVisible: true,
                                                selectedForm: "kwtNewPatientFormModalVisible",
                                            })
                                        }
                                        readOnly={
                                            this.state.kwtNewPatientForm?.consentForIntakeForm != null &&
                                            this.state.kwtNewPatientForm?.consentForInformedConsent != null &&
                                            this.state.kwtNewPatientForm?.cancellationPolicy != null
                                        }
                                    />
                                    <FontAwesomeIcon
                                        icon={[
                                            "fa",
                                            `angle-double-${
                                                this.state.creditPanelExpanded ? "up" : "down"
                                            }`,
                                        ]}
                                        style={{
                                            position: "absolute",
                                            margin: "0",
                                            right: "3px",
                                            bottom: "3px",
                                        }}
                                        onClick={() =>
                                            this.setState({
                                                creditPanelExpanded: !this.state.creditPanelExpanded,
                                            })
                                        }
                                    />
                                </div>
                                <div
                                    className={`card-body scrollbar small-font text-right expandable ${
                                        this.state.creditPanelExpanded ? "expanded" : ""
                                    }`}
                                    id={"dim14-consent-form-scrollbar"}
                                    style={{
                                        marginLeft: "-20px",
                                        maxHeight: "400px",
                                        overflowY: "auto",
                                        display: "inline-block",
                                    }}
                                >
                                    <ConsentFormItem
                                        form={INTAKE_FORM}
                                        formType={"INTAKE_FORM"}
                                        patientId={this.props.patient.id}
                                        status={this.state.intakeForm != null}
                                        handler={() =>
                                            this.setState({
                                                languageSelectionModalVisible: true,
                                                selectedForm: "intakeFormModalVisible",
                                            })
                                        }
                                        readOnly={
                                            this.state.intakeForm != null
                                        }
                                    />
                                    <hr/>
                                    <ConsentFormItem
                                        form={GENERAL_CONSENT_FORM_DXB}
                                        formType={"GENERAL_CONSENT_FORM_DXB"}
                                        patientId={this.props.patient.id}
                                        status={this.state.dxbInformedConsentForm != null}
                                        handler={() =>
                                            this.setState({
                                                languageSelectionModalVisible: true,
                                                selectedForm: "generalConsentFormModalVisible",
                                            })
                                        }
                                        readOnly={
                                            this.state.dxbInformedConsentForm != null
                                        }
                                    />
                                    <hr/>
                                    <ConsentFormItem
                                        form={GENERAL_CONSENT_FORM_KWT}
                                        formType={"GENERAL_CONSENT_FORM_KWT"}
                                        patientId={this.props.patient.id}
                                        status={this.state.kwiInformedConsentForm != null}
                                        handler={() =>
                                            this.setState({
                                                languageSelectionModalVisible: true,
                                                selectedForm: "kwiConsentFormModalVisible",
                                            })
                                        }
                                        readOnly={
                                            this.state.kwiInformedConsentForm != null
                                        }
                                    />
                                    <hr/>
                                    <ConsentFormItem
                                        form={CANCELLATION_POLICY_FORM_DXB}
                                        formType={"CANCELLATION_POLICY_DXB"}
                                        patientId={this.props.patient.id}
                                        status={this.state.dxbCancellationPolicyForm != null}
                                        handler={() =>
                                            this.setState({
                                                languageSelectionModalVisible: true,
                                                selectedForm: "dxbCancellationPolicyFormModalVisible",
                                            })
                                        }
                                        readOnly={
                                            this.state.dxbCancellationPolicyForm != null
                                        }
                                    />
                                    <hr/>
                                    <ConsentFormItem
                                        form={CANCELLATION_POLICY_FORM_KWT}
                                        formType={"CANCELLATION_POLICY_KWT"}
                                        patientId={this.props.patient.id}
                                        status={this.state.kwtCancellationPolicyForm != null}
                                        handler={() =>
                                            this.setState({
                                                languageSelectionModalVisible: true,
                                                selectedForm: "kwtCancellationPolicyFormModalVisible",
                                            })
                                        }
                                        readOnly={
                                            this.state.kwtCancellationPolicyForm != null
                                        }
                                    />
                                    <hr/>
                                    <ConsentFormItem
                                        form={RELEASE_OF_MEDICAL_RECORDS_FORM}
                                        formType={"RELEASE_OF_MEDICAL_RECORDS_FORM"}
                                        patientId={this.props.patient.id}
                                        status={this.state.releaseOfMedicalRecordsForm != null}
                                        handler={() =>
                                            this.setState({
                                                languageSelectionModalVisible: true,
                                                selectedForm: "releaseOfMedicalRecordsFormModalVisible",
                                            })
                                        }
                                        readOnly={
                                            this.state.releaseOfMedicalRecordsForm != null
                                        }
                                    />

                                    <hr/>
                                    <ConsentFormItem
                                        form={TELECONSULTATION_CONSENT_FORM}
                                        formType={"TELECONSULTATION_CONSENT_FORM"}
                                        patientId={this.props.patient.id}
                                        status={this.state.teleconsultationConsentForm != null}
                                        handler={() =>
                                            this.setState({
                                                teleconsultationConsentFormModalVisible: true,
                                                selectedForm: "teleconsultationConsentForm",
                                            })
                                        }
                                        readOnly={
                                            this.state.teleconsultationConsentForm != null
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <Modal
                        visible={this.state.languageSelectionModalVisible}
                        header="Language Selection"
                        closeAction={() => {
                            this.setState({languageSelectionModalVisible: false});
                        }}
                    >
                        <div className="container px-5" style={{width: "232px"}}>
                            <div className="row p-justify-center">
                                <Button
                                    className="btn-block"
                                    variant={"primary"}
                                    size={"sm"}
                                    onClick={() =>
                                        this.setState({
                                            languageSelectionModalVisible: false,
                                            selectedLanguage: FormLanguages.ENGLISH,
                                            [this.state.selectedForm]: true,
                                        })
                                    }
                                >
                                    {FormLanguages.ENGLISH.name}
                                </Button>
                            </div>
                            <div className="row p-justify-center mt-2">
                                <Button
                                    className="btn-block"
                                    variant={"primary"}
                                    size={"sm"}
                                    onClick={() =>
                                        this.setState({
                                            languageSelectionModalVisible: false,
                                            selectedLanguage: FormLanguages.ARABIC,
                                            [this.state.selectedForm]: true,
                                        })
                                    }
                                >
                                    {FormLanguages.ARABIC.name}
                                </Button>
                            </div>
                        </div>
                    </Modal>

                    <SockJsClient
                        url={RemotingService.getApiBaseUrl() + "websocket"}
                        topics={[
                            "/topic/new-patient-form-dxb/" + this.props.patient.id,
                            "/topic/new-patient-form-kwt/" + this.props.patient.id,
                            "/topic/intake-form-dxb/" + this.props.patient.id,
                            "/topic/informed-consent-kwi/" + this.props.patient.id,
                            "/topic/informed-consent-dxb/" + this.props.patient.id,
                            "/topic/cancellation-policy-kwt/" + this.props.patient.id,
                            "/topic/cancellation-policy-dxb/" + this.props.patient.id,
                            "/topic/consent-for-release-of-medical-records/" + this.props.patient.id,
                            "/topic/teleconsultation-consent-form/" + this.props.patient.id
                        ]}
                        onMessage={this.refreshScreen}
                        ref={client => this.clientRef = client}
                    />

                    {this.state.intakeFormModalVisible && <IntakeFormWizard
                        show={this.state.intakeFormModalVisible}
                        close={() => this.setState({intakeFormModalVisible: false})}
                        afterSave={(form) =>
                            this.setState({intakeForm: form}, () => {
                                this.props.onFormUpdate();
                            })
                        }
                        selectedLanguage={this.state.selectedLanguage}
                        form={this.state.intakeForm}
                        patient={this.props.patient}
                    />}

                    {this.state.teleconsultationConsentFormModalVisible && <TeleconsultationConsentForm
                        show={this.state.teleconsultationConsentFormModalVisible}
                        close={() =>
                            this.setState({teleconsultationConsentFormModalVisible: false})
                        }
                        afterSave={(form) =>
                            this.setState({teleconsultationConsentForm: form}, () => {
                                this.props.onFormUpdate();
                            })
                        }
                        form={this.state.teleconsultationConsentForm}
                        patient={this.props.patient}
                    />}

                    {this.state.releaseOfMedicalRecordsFormModalVisible && <ReleaseOfMedicalRecordsConsentForm
                        show={this.state.releaseOfMedicalRecordsFormModalVisible}
                        close={() =>
                            this.setState({releaseOfMedicalRecordsFormModalVisible: false})
                        }
                        afterSave={(form) =>
                            this.setState({releaseOfMedicalRecordsForm: form}, () => {
                                this.props.onFormUpdate();
                            })
                        }
                        selectedLanguage={this.state.selectedLanguage}
                        form={this.state.releaseOfMedicalRecordsForm}
                        patient={this.props.patient}
                    />}

                    {this.dubaiSelected && (
                        <>
                            {this.state.dxbNewPatientFormModalVisible && <NewPatientFormDxb
                                show={this.state.dxbNewPatientFormModalVisible}
                                close={() =>
                                    this.setState({dxbNewPatientFormModalVisible: false})
                                }
                                afterSave={(form) =>
                                    this.setState({dxbNewPatientForm: form}, () => {
                                        this.props.onFormUpdate();
                                        this.updateNewPatientFormDxb(form);
                                    })
                                }
                                selectedLanguage={this.state.selectedLanguage}
                                form={this.state.dxbNewPatientForm}
                                patient={this.props.patient}
                            />}

                            {this.state.generalConsentFormModalVisible && <InformedConsentFormDxb
                                show={this.state.generalConsentFormModalVisible}
                                close={() =>
                                    this.setState({generalConsentFormModalVisible: false})
                                }
                                afterSave={(form) =>
                                    this.setState({dxbInformedConsentForm: form}, () => {
                                        this.props.onFormUpdate();
                                    })
                                }
                                selectedLanguage={this.state.selectedLanguage}
                                form={this.state.dxbInformedConsentForm}
                                patient={this.props.patient}
                            />}

                            {this.state.dxbCancellationPolicyFormModalVisible && <CancellationPolicyDxb
                                show={this.state.dxbCancellationPolicyFormModalVisible}
                                close={() =>
                                    this.setState({
                                        dxbCancellationPolicyFormModalVisible: false,
                                    })
                                }
                                afterSave={(form) =>
                                    this.setState({dxbCancellationPolicyForm: form}, () => {
                                        this.props.onFormUpdate();
                                    })
                                }
                                selectedLanguage={this.state.selectedLanguage}
                                form={this.state.dxbCancellationPolicyForm}
                                patient={this.props.patient}
                            />}
                        </>
                    )}

                    {this.kuwaitSelected && (
                        <>
                            {this.state.kwtNewPatientFormModalVisible && <NewPatientFormKwt
                                show={this.state.kwtNewPatientFormModalVisible}
                                close={() =>
                                    this.setState({kwtNewPatientFormModalVisible: false})
                                }
                                afterSave={(form) =>
                                    this.setState({kwtNewPatientForm: form}, () => {
                                        this.props.onFormUpdate();
                                        this.updateNewPatientFormKwt(form);
                                    })
                                }
                                selectedLanguage={this.state.selectedLanguage}
                                form={this.state.kwtNewPatientForm}
                                patient={this.props.patient}
                            />}

                            {this.state.kwiConsentFormModalVisible && <InformedConsentFormKwt
                                show={this.state.kwiConsentFormModalVisible}
                                close={() =>
                                    this.setState({kwiConsentFormModalVisible: false})
                                }
                                afterSave={(form) =>
                                    this.setState({kwiInformedConsentForm: form}, () => {
                                        this.props.onFormUpdate();
                                    })
                                }
                                selectedLanguage={this.state.selectedLanguage}
                                form={this.state.kwiInformedConsentForm}
                                patient={this.props.patient}
                            />}

                            {this.state.kwtCancellationPolicyFormModalVisible && <CancellationPolicyKwt
                                show={this.state.kwtCancellationPolicyFormModalVisible}
                                close={() =>
                                    this.setState({
                                        kwtCancellationPolicyFormModalVisible: false,
                                    })
                                }
                                afterSave={(form) =>
                                    this.setState({kwtCancellationPolicyForm: form}, () => {
                                        this.props.onFormUpdate();
                                    })
                                }
                                selectedLanguage={this.state.selectedLanguage}
                                form={this.state.kwtCancellationPolicyForm}
                                patient={this.props.patient}
                            />}
                        </>
                    )}
                </div>
            </>
        );
    }
}

const ConsentFormItem = (props) => {
    let statusIcon = props.status ? (
        <Tooltip title={"Up to Date"}>
      <span>
        <FontAwesomeIcon
            icon={["fas", "check-circle"]}
            style={{color: "#7bac16"}}
        />
      </span>
        </Tooltip>
    ) : (
        <Tooltip title={"Mandatory"}>
      <span>
        <FontAwesomeIcon
            icon={["fas", "exclamation-circle"]}
            style={{color: "#dc3545"}}
        />
      </span>
        </Tooltip>
    );

    return (
        <div className="row mr-n2" style={{...props.rowStyle}}>
            <div
                className="col-10"
                style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "left",
                }}
            >
                <Tooltip title={props.form}>
                    <FontAwesomeIcon
                        icon={props.status ? ["fas", "check"] : ["fa", "times"]}
                        size={"1x"}
                        style={{
                            color: props.status ? "#7bac16" : "#dc3545",
                            marginRight: "2px",
                            marginLeft: "-5px",
                            padding: "0px",
                            cursor: "default",
                        }}
                    />
                    {props.form}
                </Tooltip>
            </div>
            <div className="col-1">{statusIcon}</div>
            <div className="col-1">
                <ActionMenu id={"formActionMenu"}>
                    {
                        props.readOnly &&
                        <ActionMenuItem
                            text="View"
                            icon={<ViewIcon/>}
                            onClick={props.handler}
                        />
                    }
                    {
                        !props.readOnly && AuthService.userHasAuthority("UPDATE_PATIENT_FORMS") &&
                        <ActionMenuItem
                            text="Edit"
                            icon={<EditIcon/>}
                            onClick={props.handler}
                        />
                    }
                    <ActionMenuItem
                        text={"Generate Tablet Code"}
                        icon={<QrIcon/>}
                        onClick={() => {
                            const {formType, patientId, form: formName} = props;

                            if (!formType || !patientId) {
                                alert("In progress...");
                                return;
                            }

                            RemotingService.postRemoteCall(
                                "api/consent-form/code",
                                {formType, patientId},
                                (code) => {
                                    dialog({
                                        style: {width: 450, height: 150},
                                        component: <ConsentFormCode code={code} formName={formName}/>,
                                        disableBackdropClick: false
                                    });
                                }
                            );
                        }}
                    />
                </ActionMenu>
            </div>
        </div>
    );
};

export default ConsentForms;
