import Enum from "../../../../../enums/Enum";

const EnumInsuranceReferralStatus = new Enum({
    COMPLETED: {name: "Completed"},
    PENDING: {name: "Pending"},
    DENIED: {name: "Denied"},
    APPROVED: {name: "Approved"},
});

export default EnumInsuranceReferralStatus;
