import React, {Component} from "react";
import "../../VisitHistory.css"
import AreaEnum from "../../../assessment/enums/specialtests/AreaEnum";
import TestEnum from "../../../assessment/enums/specialtests/TestEnum";
import PosNegRadioInput from "../../../../../../components/radio-input/PosNegRadioInput";

export default class SpecialTestsViewHistory extends Component {
    render() {
        if (!this.props.specialTests || !this.props.specialTests.items || this.props.specialTests.items.length === 0) {
            return <div className="pl-3">No information available</div>;
        }

        return (
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Area</th>
                    <th>Test</th>
                    <th>Right</th>
                    <th>Left</th>
                    <th>Radiating</th>
                    <th>Details</th>
                </tr>
                {this.props.specialTests.items.map((item, index) => {
                    return (<tr key={"specialTestsViewHistory" + index} style={{backgroundColor: "white"}}>
                        <td>{AreaEnum[item.area]?.name}</td>
                        <td>{TestEnum[item.test]?.name}</td>
                        <td style={{position: "relative", minWidth: "80px"}}>
                            <PosNegRadioInput value={item.rightBool} readOnly={true}/>
                        </td>
                        <td style={{position: "relative", minWidth: "80px"}}>
                            <PosNegRadioInput value={item.leftBool} readOnly={true}/>
                        </td>
                        <td>{item.radiating}</td>
                        <td>{item.detail}</td>
                    </tr>)
                })}
                </tbody>
            </table>
        );
    }
}