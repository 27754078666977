import Enum from "../../../../../../enums/Enum";

const AggravatingFactorEnum = new Enum({
    STANDING: {name: "Standing"},
    SLEEPING: {name: "Sleeping"},
    COLD: {name: "Cold"},
    SITTING: {name: "Sitting"},
    CLIMBINGSTAIRS: {name: "Climbing Stairs"},
    MOVEMENTEXERCISE: {name: "Movement/Exercise"},
    DRIVING: {name: "Driving"},
    MASSAGE: {name: "Massage"},
    OTHER: {name: "Other"}
});

export default AggravatingFactorEnum;