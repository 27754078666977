import React, {Component} from 'react'
import "./EMRHome.css"
import PageTitle from "../../../layout/PageTitle";
import {DataTable} from "primereact/datatable";
import {Badge, SelectInput} from "../../../components";
import RemotingService from "../../../services/remoting-service/RemotingService";
import DateUtil from "../../../utils/DateUtil";
import {Column} from "primereact/column";
import {AppointmentInfo} from "./datatable/AppoinmentInfo";
import {EMRHeader} from "./header/EMRHeader";
import queryString from "query-string";
import {DateNavigation} from "../../../components/datenavigation/DateNavigation";
import EnumAppointmentStatus from "../../appointment/enums/EnumAppointmentStatus";
import AuthService from "../../../services/auth-service/AuthService";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import {noop} from "lodash-es";
import NotificationService from "../../../services/notification-service/NotificationService";
import Header from "../recordpage/header/Header";
import TextInput from "../../../components/text-input/TextInput";
import {connect} from "react-redux";
import PatientUtil from "../../../utils/PatientUtil";

class EMRHome extends Component {

    state = {
        selectedDate: new Date(),
        queried: false,
        appointments: [],
        totalRecords: 0,
        first: 0,
        emrList: [],
        queryOptions: {
            ignoredStatuses: ['NO_SHOW', 'CANCELLED'],
            orderDirection: "ASC",
            orderBy: "startTime",
            size: "250",
            page: 0
        }
    };

    componentDidMount() {
        const values = queryString.parse(this.props.location.search);
        let selectedDate = values.selectedDate ? DateUtil.parse(values.selectedDate).toDate() : new Date();
        this.requestData(1, 0, selectedDate);
    }

    requestData(page, first, selectedDate) {
        let startTime = new Date(selectedDate);
        let endTime = new Date(selectedDate);

        startTime.setHours(0, 0, 0, 0);
        endTime.setHours(23, 59, 0, 0);

        let timeOptions = {
            startTime: startTime.toISOString(),
            endTime: endTime.toISOString(),
        };

        let queryOptions = {...this.state.queryOptions, ...timeOptions};
        queryOptions.page = page;
        if (!AuthService.isManagerOrSuperUser() && !AuthService.isNurse()) {
            queryOptions.staffIds = [AuthService.getStaffId()];
        }

        RemotingService.getRemoteCall('api/emr/list', queryOptions, (result) => {
            let appointmentDTOList = result.items;
            this.sortAppointments(appointmentDTOList);

            this.setState((state) => {
                state.queried = true;
                state.first = first;
                state.appointments = appointmentDTOList;
                state.queryOptions.page = page;
                state.totalRecords = result.totalCount;
                state.selectedDate = selectedDate;
                state.emrList = [];
                return state;
            });
        });
    }

    getMissingPercentage = (appointmentList) => {
        let totalAppointmentCount = appointmentList.length;
        let missingAppointmentCount = 0;

        appointmentList.forEach(appointment => {
            if (appointment.visit?.missingPoints !== 0 ) {
                ++missingAppointmentCount;
            }
        })

        return ((missingAppointmentCount / totalAppointmentCount).toFixed(2) * 100) || 0
    }

    render() {
        if (!this.state.queried) {
            return (<div></div>);
        }

        let patientTypeMap = new Map();
        let completedAppointmentCount = 0;

        this.state.appointments.forEach(appointment => {
            const patientType = appointment.visit ? appointment.visit.appointmentType : appointment.patientType;

            if (patientTypeMap.has(patientType)) {
                let n = patientTypeMap.get(patientType);
                patientTypeMap.set(patientType, n+1);
            } else {
                patientTypeMap.set(patientType, 1);
            }

            if (appointment.visit?.missingPoints === 0) {
                completedAppointmentCount++;
            }
            appointment.patientName = PatientUtil.getPatientsName(appointment.patient)
        })

        const missingPercentage = this.getMissingPercentage(this.state.appointments);

        return (
            <>
                <div className="dim14EMRHeaderPanel">
                    <EMRHeader patientTypeMap={patientTypeMap} completedAppointmentCount={completedAppointmentCount} emrList={this.state.appointments}
                               missingPercentage={missingPercentage}/>
                </div>

                <EMRDataTableHeader date={this.state.selectedDate}
                                    roomDataGridGlobalFilter={this.state.roomDataGridGlobalFilter}
                                    onDateChange={this.handleDateStateChange}
                                    onFilterChange={this.onFilterChange.bind(this)}/>

                <div>
                    <DataTable className="emr-home-table"
                               ref={(el) => this.patientsDt = el}
                               value={this.state.appointments}
                               rows={this.state.queryOptions.size}
                               paginator={true}
                               lazy={false}
                               paginatorPosition="bottom"
                               first={this.state.first}
                               responsive autoLayout
                               paginatorLeft={
                                   <div className="paginatorLeftDiv">
                                       <span>Show:&nbsp;&nbsp;</span>
                                       <SelectInput className="pageSizeSelectInput"
                                                    value={this.state.queryOptions.size}
                                                    options={["10", "25", "50", "100", "250"]}
                                                    searchEnabled={false}
                                                    showClear={false}
                                                    onChange={(val) => this.handleQueryOptionChange("size", val, true)}/>
                                       <span>&nbsp;&nbsp;Total: {this.state.totalRecords} Records</span>
                                   </div>
                               }
                               totalRecords={this.state.totalRecords}
                               onPage={this.onPage.bind(this)}
                               emptyMessage={this.state.queried ? "There are no patients" : ""}
                               noHeader={true}
                               globalFilter={this.state.roomDataGridGlobalFilter}
                               selectionMode="single"
                               onSelectionChange={this.onChange.bind(this)}
                    >
                        <Column body={this.patientColumnTemplate}
                                field={"patientName"}
                        />
                        <Column body={this.appointmentInfoTemplate}
                        />
                        <Column body={this.statusTemplate}
                                style={{

                                    minWidth: 100,
                                }}
                        />
                        <Column
                            style={{

                                minWidth: 150,
                            }}
                            body={this.startVisitColumnTemplate.bind(this)}/>
                    </DataTable>
                </div>
            </>
        )
    }

    onChange(event) {
        const appointment = event.value;
        this.props.history.push('/emr/view/' + appointment.patient.id
            + '/' + appointment.id);
    }

    onPage(event) {
        let first = event.first;
        let page = Math.floor(first / this.state.queryOptions.size) + 1;
        this.requestData(page, first, this.state.selectedDate);
    }

    handleQueryOptionChange(key, val, requestData = false) {
        this.setState((state) => {
            let newState = {...state};
            newState.queryOptions[key] = val;
            return newState;
        }, () => {
            if (requestData) {
                this.requestData(1, 0, this.state.selectedDate);
            }
        });
    }

    sortAppointments(list) {
        list.sort((a, b) => {
            let order = EnumAppointmentStatus[a.status].order - EnumAppointmentStatus[b.status].order;
            if (order == 0) {
                const d1 = new Date(a.start);
                const d2 = new Date(b.start);
                order = d1 > d2 ? 1 : 0;
            }

            return order;
        });
    }

    patientColumnTemplate(rowData) {
        return (
            <div className={"d-flex col flex-row"}>
                <Header key={"header-"+rowData.id} appointment={rowData} patient={rowData.patient} emrHomeView/>
            </div>
        );
    }

    appointmentInfoTemplate(rowData) {
        return (
            <div className={"d-flex col flex-row justify-content-end"}>
                <AppointmentInfo key={"appointment-"+rowData.id} appointment={rowData}/>
            </div>
        );
    }

    statusTemplate(rowData) {
        let rowColor = EnumAppointmentStatus[rowData.status];
        return (
                <Badge key={"status-"+rowData.id}
                       className="TableColBadge"
                       style={{
                           backgroundColor: rowColor.bgColor,
                           color: rowColor.textColor,
                           padding: "12px 0",
                           fontSize: 15,
                       }}>
                    {EnumAppointmentStatus[rowData.status].name}
                </Badge>
        );
    }

    isVisitInEditableStatus = (status) => {
        return EnumAppointmentStatus[status] === EnumAppointmentStatus.ARRIVED
            || EnumAppointmentStatus[status] === EnumAppointmentStatus.BEINGSEEN
            || EnumAppointmentStatus[status] === EnumAppointmentStatus.CONSULTED
            || EnumAppointmentStatus[status] === EnumAppointmentStatus.COMPLETED;
    }

    isVisitDateInOneDay(visitDate) {
        const now = DateUtil.now();
        let emrEditPeriod = this.props.parameterMap ? this.props.parameterMap.EMR_EDIT_PERIOD_IN_HOURS : 0;
        return now.isSame(visitDate, "day")
            || (moment(visitDate).isBefore(now) && moment(visitDate).add(emrEditPeriod, "hours").isAfter(now));
    }

    startVisitColumnTemplate(rowData) {
        let isButtonEnable = this.isVisitInEditableStatus(rowData.status) && this.isVisitDateInOneDay(rowData.start);
        isButtonEnable |= AuthService.isSuperUser();
        if (!isButtonEnable) {
            return (<div style={{minWidth: "100px"}}/>);
        }
        let disabledTextColor = !isButtonEnable ? {color: "rgb(102, 102, 102)"} : {};
        return (
            <Button key={"start-button-"+rowData.id}
                    className={"start-visit-button w-100"}
                    style={{fontSize: "13px", ...disabledTextColor}}
                    variant="success"
                    onClick={e => this.handleStartVisit(e, rowData)}
                    disabled={!isButtonEnable}>
                <div className="my-1">
                    <FontAwesomeIcon icon="play"/>
                    <span className="pl-1">Start Visit</span>
                </div>
            </Button>
        );
    }

    handleDateStateChange = (date) => {
        this.setState({selectedDate: date});
        this.updateSearchUrl(DateUtil.formatDate(date));
        this.requestData(1, 0, date);
    }

    handleStartVisit(event, rowData) {
        event.stopPropagation(); // prevent view mode clicked
        RemotingService.postRemoteCall(`api/emr/visit/${rowData.patient.id}/${rowData.id}/check`, null, () => {
            this.props.history.push(`/emr/visit/${rowData.patient.id}/${rowData.id}`);
        }, noop, true, false, (messages) => {
            const errorMessages = RemotingService.extractMessages(messages, "ERROR");
            if (errorMessages.length > 0) {
                NotificationService.showNotification({
                    severity: 'error',
                    summary: 'Operation Unsuccessful',
                    detail: `${errorMessages.join('\n')}`
                });
            }
        });
    }

    updateSearchUrl = (selectedDate) => {
        const searchText = "selectedDate=" + selectedDate;
        this.props.history.push({search: searchText});
    }

    onFilterChange(val) {
        this.setState({roomDataGridGlobalFilter: val});
    }
}

function EMRDataTableHeader(props) {
    return (
        <div className="data-table-header-wrapper">
            <div className="float-left">
                <PageTitle title={DateUtil.formatDate(props.date, "dddd MMMM Do yyyy")} className="bg-white"/>
            </div>
            <div className="float-right">
                <DateNavigation date={props.date} onDateChange={props.onDateChange}/>

                <TextInput className="p-1" placeholder="Patient name ..."
                           value={props.roomDataGridGlobalFilter}
                           onChange={(val) => props.onFilterChange(val)}/>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        parameterMap: state.base.parameterMap
    };
}

export default connect(mapStateToProps)(EMRHome);