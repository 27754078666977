import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

export default class InsuranceIcon extends Component {

    static defaultProps = {
        insuranceExists: null,
        isCustomInsurance: null,
    };

    static propTypes = {
        insuranceExists: PropTypes.string,
        isCustomInsurance: PropTypes.bool
    };

    render() {
        const {insuranceExists, isCustomInsurance} = this.props;

        if(!insuranceExists)
            return null;

        const color = isCustomInsurance != true ? "2ECC71" : "E74C3C";

        return (
            isCustomInsurance != null ?
            <>
                <FontAwesomeIcon icon={["fas", "umbrella"]} size="2x"
                                 style={{color: color, maxHeight: "20px"}}/>
            </> : null
        );
    }

}