import React, {Component} from "react";
import {AccordionBody, AccordionHeader, AccordionPanel, Badge} from "../../../../../components";
import MissingDataChecker from "../MissingDataChecker";
import StringUtil from "../../../../../utils/StringUtil";
import ObjectUtil from "../../../../../utils/ObjectUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class MissingPointsModal extends Component {

    constructor(props) {
        super(props);
        const {missingItemsMap, missingItemCounts} = this.getMissingItemsMap(props.visit);
        this.state = {missingItemsMap, missingItemCounts};
    }

    getMissingItemsMap(visit) {
        const {missingItems} = MissingDataChecker.getMissingPointDetailsForVisit(visit);

        const missingItemsMap = {};
        const missingItemCounts = {};

        for (const missingItem of missingItems) {
            const missingItemLabels = missingItem.labels ?? missingItem.path.split(".").map(l => StringUtil.camelCaseToWords(l));
            ObjectUtil.set(missingItemsMap, missingItemLabels, null);
            if (missingItemCounts[missingItemLabels[0]] == null) {
                missingItemCounts[missingItemLabels[0]] = 1;
            } else {
                missingItemCounts[missingItemLabels[0]]++;
            }
        }

        return {missingItemsMap, missingItemCounts};
    }

    openEmr(event, tab) {
        event.stopPropagation();
        event.preventDefault();

        const {patient, visit} = this.props;
        window.open(`/emr/visit/${patient.id}/${visit.appointment.id}?tab=${tab}`, "_blank");
    }

    render() {
        return (
            <div style={{minWidth: 600}}>
                {
                    Object.keys(this.state.missingItemsMap).map(firstLevel =>
                        <AccordionPanel square defaultExpanded className="mb-4">
                            <AccordionHeader style={{backgroundColor: "#e0e0e0"}}>
                                <div className="d-flex p-justify-between">
                                    <div/>
                                    <div>
                                        <Badge pill variant="danger">{this.state.missingItemCounts[firstLevel]}</Badge>
                                        <span className="ml-1">{firstLevel}</span>
                                    </div>
                                    <FontAwesomeIcon icon="external-link-alt"
                                                     onClick={(event) => this.openEmr(event, firstLevel.toLowerCase())}/>
                                </div>
                            </AccordionHeader>
                            <AccordionBody className="d-flex flex-column">
                                {
                                    this.state.missingItemsMap[firstLevel]
                                    && Object.keys(this.state.missingItemsMap[firstLevel])
                                        .map(secondLevel =>
                                            <>
                                                <div style={{backgroundColor: "#f0f0f0"}}
                                                     className="px-2 py-1">{secondLevel}</div>
                                                {
                                                    this.state.missingItemsMap[firstLevel][secondLevel]
                                                    && Object.keys(this.state.missingItemsMap[firstLevel][secondLevel])
                                                        .map((thirdLevel, i) =>
                                                            <div style={{borderColor: "#f0f0f0"}}
                                                                 className="border-bottom px-4 py-2">
                                                                {thirdLevel}
                                                            </div>
                                                        )
                                                }
                                            </>
                                        )
                                }
                            </AccordionBody>
                        </AccordionPanel>
                    )
                }
            </div>
        )
    }

}