import React, {Component} from 'react';
import {Fade, Menu} from "@material-ui/core";
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './ActionMenu.css'

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d3d3',
        borderRadius: 0
    },
    list: {
        padding: 0
    }
})(props => (
    <Menu
        elevation={0}
        keepMounted={false}
        getContentAnchorEl={null}
        {...props}
    />
));

class ActionMenu extends Component {

    state = {};

    static defaultProps = {
        id: null,
        icon: <FontAwesomeIcon icon={["fas", "ellipsis-v"]}/>,
        anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        },
        transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
        }
    };

    static propTypes = {
        id: PropTypes.string,
        icon: PropTypes.element,
        style: PropTypes.object,
        anchorOrigin: PropTypes.object,
        transformOrigin: PropTypes.object
    };

    toggleMenu = (menuAnchor) => {
        this.setState({menuAnchor})
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        return this.state.menuAnchor !== nextState.menuAnchor;
    };

    closeMenuOnClick = () => {
        this.toggleMenu(null)
    }

    render() {
        let childComponents = null;

        if (Array.isArray(this.props.children)) {
            childComponents = this.props.children.map(child => {
                return child ? React.cloneElement(child, {closeMenuOnClick: this.closeMenuOnClick}) : null;
            })
        } else {
            childComponents = this.props.children ?
                React.cloneElement(this.props.children, {closeMenuOnClick: this.closeMenuOnClick}) : null;
        }

        return (
            <div className="dim14-action-menu">
                <div className="icon-wrapper"
                     style={
                         this.state.menuAnchor ? {
                             border: '1px solid #d3d3d3',
                             borderBottom: 'none',
                             textAlign: 'center'
                         } : {
                             textAlign: 'center'
                         }
                     }
                     onClick={e => {
                         e.stopPropagation();
                         this.toggleMenu(e.currentTarget);
                     }}>
                    {this.props.icon}
                </div>
                <StyledMenu
                    id={this.props.id}
                    anchorEl={this.state.menuAnchor}
                    open={Boolean(this.state.menuAnchor)}
                    anchorOrigin={this.props.anchorOrigin}
                    transformOrigin={this.props.transformOrigin}
                    TransitionComponent={Fade}
                    onClose={(e) => {
                        e.stopPropagation();
                        this.toggleMenu(null);
                    }}>
                    {childComponents}
                </StyledMenu>
            </div>
        );
    }
}

export default ActionMenu;