import Enum from "../../../../../../enums/Enum";

const LowRiskInterventionEnum = new Enum({
    Footwear_Guidance: {name: "Footwear guidance"},
    Ambulatory_Aid_Training: {name: "Ambulatory aid training"},
    Transfer_Training: {name: "Transfer training"},
    Hearing_Visual_Aid: {name: "Hearing/visual aid"},
    Environmental_Consideration: {name: "Environmental consideration"},
    Patient_Family_Education: {name: "Patient/family education"}
});

export default LowRiskInterventionEnum;