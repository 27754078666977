import parsePhoneNumber from 'libphonenumber-js';

class PhoneNumberUtil {

    formatInternational(value) {
        if (!value) {
            return value;
        }

        try {
            return parsePhoneNumber(value).formatInternational();
        } catch(err) {
            console.log("Failed to parse phone number: " + value);
            return value;
        }
    }

}

export default new PhoneNumberUtil();