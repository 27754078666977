import React, {Component} from "react";
import {Button as MuiButton} from "@material-ui/core";
import _uniqueId from "lodash/uniqueId";

export default class Button extends Component {

    render() {
        const {id, children, ...rest} = this.props;

        return (
            <MuiButton
                id={id || _uniqueId("button-id-")}
                {...rest}
            >
                {children}
            </MuiButton>
        )
    }
}
