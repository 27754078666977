import React, {Component} from "react";
import {FormField} from "../../components/form";
import ValidationUtil from "../../components/form/validator/ValidationUtil"
import {Modal} from "../../components";
import RemotingService from "../../services/remoting-service/RemotingService";
import EnumCoPayUnit from "./enums/EnumCoPayUnit";
import PrimeDateInput from "../../components/date-input/PrimeDateInput";
import {Dropdown} from "primereact/dropdown";
import {InputNumber} from "primereact/inputnumber";
import {InputText} from "primereact/inputtext";
import DateUtil from "../../utils/DateUtil";

class AddInsurance extends Component {

    constructor(props) {
        super(props);
        this.noTpaOption = {id:null, name:"Not Applicable"};
        this.noNetworkOption = {id:null, name:"Not Applicable"};

        if(this.props.insurance != null){
            this.props.insurance.validityFrom = DateUtil.getUtcDateAtStartOfDay(this.props.insurance.validityFrom);
            this.props.insurance.validityTo = DateUtil.getUtcDateAtStartOfDay(this.props.insurance.validityTo);
        }

        this.state = {
            insurance: {...this.props.insurance},
            tpas: [],
            companies: [],
            networks: [],
            policies: [],
            selectedTpa: null,
            selectedCompany: null,
            selectedNetwork: null,
            selectedPolicy: null
        };
    }

    componentDidMount() {
        RemotingService.getRemoteCall('api/insurance/tpa/list', null, result => {
            result.unshift(this.noTpaOption);
            this.setState({tpas: result});
        });

        if (this.props.insurance) {
            const {tpa, company, network, policyName} = this.props.insurance;
            if (tpa) {
                RemotingService.getRemoteCall(`api/insurance/tpa/company`, {tpaId:tpa.id}, companies => {
                    this.setState({
                        selectedTpa: tpa,
                        companies: companies
                    });
                });
            } else {
                this.setState({
                    selectedTpa: this.noTpaOption
                });
            }

            if (company) {
                RemotingService.getRemoteCall(`api/insurance/company/${company.id}/accepted-networks`, null, networks => {
                   if(networks.length === 0) networks.push(this.noNetworkOption);
                    this.setState({
                        selectedCompany: company,
                        networks: networks
                    });
                });
            }

            if (network) {
                if (network.policyRequired) {
                    RemotingService.getRemoteCall(`api/insurance/network/policy`, {networkId:network.id}, policies => {
                        this.setState({
                            selectedNetwork: network,
                            selectedPolicy: policies.find(p => p.name === policyName),
                            policies: policies
                        });
                    });
                } else {
                    this.setState({
                        selectedNetwork: network
                    });
                }
            } else {
                RemotingService.getRemoteCall(`api/insurance/network/policy`, {networkId:null}, policies => {
                    this.setState({
                        selectedNetwork: this.noNetworkOption,
                        selectedPolicy: policies.find(p => p.name === policyName),
                        policies: policies
                    });
                });
            }
        }
    }

    updateInsuranceField(value, field) {
        this.setState(prevState => {
                let newInsurance = {...prevState.insurance};
                newInsurance[field] = value;
                return {
                    insurance: newInsurance
                }}
        );
    }

    handleTpaChange = (value) => {
        if (typeof value == "string") {
            this.handleCustomTpa(value);
        } else {
            this.handleTpaSelected(value);
        }
    }

    handleCustomTpa(value) {
        this.setState(state => {
            state.selectedTpa = null;
            state.insurance.customTpa = value;
            state.selectedCompany = null;
            state.insurance.customCompany = null;
            state.selectedNetwork = null;
            state.insurance.customNetwork = null;
            state.selectedPolicy = null;
            state.insurance.policyName = null;
            state.companies = [];
            return {...state};
        });
    }

    handleTpaSelected(tpa) {
        this.setState(state => {
            state.selectedTpa = tpa;
            state.insurance.customTpa = null;
            state.selectedCompany = null;
            state.insurance.customCompany = null;
            state.selectedNetwork = null;
            state.insurance.customNetwork = null;
            state.selectedPolicy = null;
            state.insurance.policyName = null;
            return {...state};
        });

        if (tpa) {
            RemotingService.getRemoteCall('api/insurance/tpa/company', {tpaId:tpa.id}, result => {
                this.setState({companies: result});
            });
        }
    }

    handleCompanyChange = (value) => {
        if (typeof value == "string") {
            this.handleCustomCompany(value);
        } else {
            this.handleCompanySelected(value);
        }
    }

    handleCustomCompany(value) {
        this.setState(state => {
            state.selectedCompany = null;
            state.insurance.customCompany = value;
            state.selectedNetwork = null;
            state.insurance.customNetwork = null;
            state.selectedPolicy = null;
            state.insurance.policyName = null;
            state.networks = [];
            return {...state};
        });
    }

    handleCompanySelected(company) {
        this.setState(state => {
            state.selectedCompany = company;
            state.insurance.customCompany = null;
            state.selectedNetwork = null;
            state.insurance.customNetwork = null;
            state.selectedPolicy = null;
            state.insurance.policyName = null;
            return {...state};
        });

        if (company && company.id) {
            RemotingService.getRemoteCall('api/insurance/company/' + company.id + '/accepted-networks', null, result => {
                if(result.length === 0) result.push(this.noNetworkOption);
                this.setState({networks: result});
            });
        }
    }

    handleNetworkChange(value) {
        if (typeof value == "string") {
            this.handleCustomNetwork(value);
        } else {
            this.handleNetworkSelected(value);
        }
    }

    handleCustomNetwork(value) {
        this.setState(state => {
            state.selectedNetwork = null;
            state.insurance.customNetwork = value;
            state.selectedPolicy = null;
            state.insurance.policyName = null;
            return {...state};
        });
    }

    handleNetworkSelected(network) {
        this.setState(state => {
            state.selectedNetwork = network;
            state.insurance.customNetwork = null;
            state.selectedPolicy = null;
            state.insurance.policyName = null;
            return {...state};
        });

        if (network.id == null || network.policyRequired) {
            RemotingService.getRemoteCall(`api/insurance/network/policy`, {networkId: network.id}, policies => {
                this.setState({policies});
            });
        }
    }

    handlePolicySelected(policy) {
        this.setState(state => {
            state.insurance.policyName = policy ? policy.name : null;
            state.selectedPolicy = policy;
            return {...state};
        });
    }

    handleAddInsurance = () => {
        if (!ValidationUtil.isValid(this.formFields)) {
            ValidationUtil.showNotification();
            return;
        }

        const {selectedTpa, selectedCompany, selectedNetwork} = this.state;
        let insuranceDto = {
            ...this.state.insurance,
            tpaId: selectedTpa ? selectedTpa.id : null,
            companyId: selectedCompany ? selectedCompany.id : null,
            networkId: selectedNetwork ? selectedNetwork.id : null
        };

        RemotingService.postRemoteCall(`api/insurance/${this.props.patientId}/insurance`, insuranceDto, (insurance) => {
            this.props.onInsuranceAdded(insurance);
            this.setState({insurance: {}, selectedTpa: null, selectedCompany: null, selectedNetwork: null, selectedPolicy: null});
        });
    }

    handleClose = () => {
        this.props.closeAction();
        this.setState({
            insurance: {},
            selectedTpa: null,
            selectedCompany: null,
            selectedNetwork: null
        });
    }

    render() {
        this.formFields = [];
        return <Modal visible={true}
                      header="Insurance"
                      submitContent="Save"
                      contentStyle={{overflowY: 'visible'}}
                      submitAction={this.handleAddInsurance}
                      closeAction={this.handleClose}>
            {this.renderAddInsuranceModalBody()}
        </Modal>
    }

    renderAddInsuranceModalBody() {
        return (
            <div className="container dim14-dialog-body">
                <div className="row">
                    <div className="col-4 dim14-form-label">TPA</div>
                    <div className="col-8">
                        <FormField ref={formField => this.formFields.push(formField)}>
                            <Dropdown optionLabel="name" editable
                                      options={this.state.tpas}
                                      value={this.state.selectedTpa ? this.state.selectedTpa.name : this.state.insurance.customTpa}
                                      onChange={e => this.handleTpaChange(e.value)}/>
                        </FormField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 dim14-form-label">Insurance</div>
                    <div className="col-8">
                        <FormField ref={formField => this.formFields.push(formField)} required validateOn={this.state.selectedCompany || this.state.insurance.customCompany}>
                            <Dropdown optionLabel="name" filter editable
                                      disabled={!this.state.selectedTpa && !this.state.insurance.customTpa}
                                      options={this.state.companies}
                                      value={this.state.selectedCompany ? this.state.selectedCompany.name : this.state.insurance.customCompany}
                                      onChange={e => this.handleCompanyChange(e.value)}/>
                        </FormField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 dim14-form-label">Network</div>
                    <div className="col-8">
                        <FormField ref={formField => this.formFields.push(formField)}>
                            <Dropdown optionLabel="name" editable
                                      disabled={!this.state.selectedCompany && !this.state.insurance.customCompany}
                                      options={this.state.networks}
                                      value={this.state.selectedNetwork ? this.state.selectedNetwork.name : this.state.insurance.customNetwork}
                                      onChange={e => this.handleNetworkChange(e.value)}/>
                        </FormField>
                    </div>
                </div>
                {
                    this.state.selectedNetwork && (this.state.selectedNetwork.id == null || this.state.selectedNetwork.policyRequired) &&
                    <div className="row">
                        <div className="col-4 dim14-form-label">Policy Name</div>
                        <div className="col-8">
                            <FormField ref={formField => this.formFields.push(formField)} required validateOn={this.state.selectedPolicy}>
                                <Dropdown optionLabel="name"
                                          options={this.state.policies}
                                          value={this.state.selectedPolicy}
                                          onChange={e => this.handlePolicySelected(e.value)}/>
                            </FormField>
                        </div>
                    </div>
                }
                <div className="row">
                    <div className="col-4 dim14-form-label">Policy Number</div>
                    <div className="col-8">
                        <InputText value={this.state.insurance.policyNumber} style={{width: "inherit"}}
                                   onChange={e => this.updateInsuranceField(e.target.value, "policyNumber")}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 dim14-form-label">Member ID</div>
                    <div className="col-8">
                        <FormField ref={formField => this.formFields.push(formField)} required validateOn={this.state.insurance.memberId}>
                            <InputText value={this.state.insurance.memberId}
                                       onChange={e => this.updateInsuranceField(e.target.value, "memberId")}/>
                        </FormField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 dim14-form-label">Validity From</div>
                    <div className="col-8">
                        <FormField ref={formField => this.formFields.push(formField)} required validateOn={this.state.insurance.validityFrom}>
                            <PrimeDateInput clearable
                                            value={this.state.insurance.validityFrom ? this.state.insurance.validityFrom : null}
                                            onChange={value => this.updateInsuranceField(value, "validityFrom")}/>
                        </FormField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 dim14-form-label">Validity To</div>
                    <div className="col-8">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required minDate={this.state.insurance.validityFrom}
                                   validateOn={this.state.insurance.validityTo}>
                            <PrimeDateInput clearable
                                            value={this.state.insurance.validityTo ? this.state.insurance.validityTo : null}
                                            onChange={value => this.updateInsuranceField(value, "validityTo")}/>
                        </FormField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 dim14-form-label">Co-Pay</div>
                    <div className="col-8">
                        <FormField ref={formField => this.formFields.push(formField)} required
                                   validateOn={this.state.insurance.coPay} min={0}>
                            <InputNumber value={this.state.insurance.coPay}
                                         onChange={e => this.updateInsuranceField(e.value, "coPay")}/>
                        </FormField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 dim14-form-label">Co-Pay Unit</div>
                    <div className="col-8">
                        <FormField ref={formField => this.formFields.push(formField)} required validateOn={this.state.insurance.coPayUnit}>
                            <Dropdown optionLabel="name" optionValue="key"
                                      value={this.state.insurance.coPayUnit}
                                      options={EnumCoPayUnit.toArray()}
                                      onChange={e => this.updateInsuranceField(e.value, "coPayUnit")}/>
                        </FormField>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 dim14-form-label">Max Co-Pay</div>
                    <div className="col-8">
                        <InputNumber value={this.state.insurance.coPayMax}
                                     onChange={e => this.updateInsuranceField(e.value, "coPayMax")}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddInsurance;
