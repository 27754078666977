import Enum from "../../../enums/Enum";

const TreatmentsForThisConditionEnum = new Enum({
    NO: {name: "No", nameInArabic: "لا"},
    PHYSIOTHERAPY: {name: "Physiotherapy", nameInArabic: "العلاج الطبيعي"},
    CHIROPRACTIC: {name: "Chiropractic", nameInArabic: "علاج يدوي لتقويم العظام"},
    SURGERY: {name: "Surgery", nameInArabic: "الجراحة"},
    MEDICATION: {name: "Medication", nameInArabic: "الادوية"},
    INJECTION: {name: "Injection", nameInArabic: "الحقن"},
    OTHER: {name: "Other", nameInArabic: "آخر"}
});

export default TreatmentsForThisConditionEnum;