import React, {Component} from "react";
import './GreenSwitchWithEmptyMessage.css';
import GreenSwitch from "./GreenSwitch";
export default class GreenSwitchWithEmptyMessage extends Component {
    render() {
        return(
            <div className={"green-switch-container"}>
                {!this.props.checked &&
                <div className={"empty-message"}>
                    {this.props.emptyMessage || ''}
                </div>
                }
                <div className={"empty-message"}>
                    {this.props.message || ''}
                </div>
                <div>
                    <GreenSwitch checked={!!this.props.checked}
                                 onChange={this.props.onChange}/>
                </div>
            </div>
        );
    }
}
