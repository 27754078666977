import React, {Component} from "react";
import EnumRotation from "../../enums/EnumRotation";
import EnumConsecutiveDaysOrSplitWeek from "../../enums/EnumConsecutiveDaysOrSplitWeek";
import {extract} from "../../../../utils/ArrayUtil";

class GeneralStaffPreferences extends Component {

    constructor(props) {
        super(props);
        this.state = {
            staffPreference: props.staffPreference
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.staffPreference != this.props.staffPreference) {
            this.setState({staffPreference: this.props.staffPreference ? this.props.staffPreference : {}});
        }
    }

    render() {
        return <>
                <div className="container" style={{fontSize: 'small', width: 500, marginLeft: 0, marginTop: 25}}>
                    <div className="row">
                        <div className="col-6 dim14-form-label">
                            Number of Days Per Week
                        </div>
                        <div className="col-6">
                            {this.state.staffPreference.noOfDaysPerWeek}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 dim14-form-label">
                            Working Hours Per Day
                        </div>
                        <div className="col-6">
                            {this.state.staffPreference.workingHoursPerDay}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 dim14-form-label">
                            Consecutive Days / Split Week
                        </div>
                        <div className="col-6">
                            {EnumConsecutiveDaysOrSplitWeek[this.state.staffPreference.consecutiveOrSplit]}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 dim14-form-label">
                            Preferred Rooms
                        </div>
                        <div className="col-6">
                            {extract(this.state.staffPreference.preferredRooms, "info").join(", ")}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 dim14-form-label">
                            Rotation
                        </div>
                        <div className="col-6">
                            {EnumRotation[this.state.staffPreference.rotationPreference]}
                        </div>
                    </div>
                    {
                        this.state.staffPreference.rotationPreference === "IN_ROTATION" ?
                        <div className="row">
                            <div className="col-6 dim14-form-label">
                                Rotation With
                            </div>
                            <div className="col-6">
                                {extract(this.state.staffPreference.rotationWith, "name").join(", ")}
                            </div>
                        </div> : null
                    }
                    {
                        this.state.staffPreference.synchronizeWith ?
                        <div className="row">
                            <div className="col-6 dim14-form-label">
                                Synchronize With
                            </div>
                            <div className="col-6">
                                {this.state.staffPreference.synchronizeWith.name}
                            </div>
                        </div> : null
                    }
                </div>
            </>
    }

}

export default GeneralStaffPreferences;
