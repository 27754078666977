import Enum from "../../../enums/Enum";

const EnumInvoiceCancellationReason = new Enum({

    PATIENT_REFUTED_PAID_BILL: "Patient Refuted Paid Bill",
    PATIENT_REFUTED_PAYMENT: "Patient Refuted Payment",
    PATIENT_UNSATISFIED_WITH_TREATMENT: "Patient Unsatisfied with Treatment",
    PAYMENT_ERROR: "Payment Error",
    ITEM_ERROR: "Item Error",
    AMOUNT_ERROR: "Amount Error",
    QUANTITY_ERROR:  "Quantity Error",
    PROVIDER_ERROR:  "Provider Error",
    TEST_TRAINING_ACTION: "Test/ Training Action",
    OTHER: "Other",

});

export default EnumInvoiceCancellationReason;