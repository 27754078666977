import React, {Component} from 'react';
import IntakeFormTextEnum from "../enum/IntakeFormTextEnum";
import {RadioInput} from "../../../../components";
import TypeEnum from "../../../emr/recordpage/subjective/enums/lifestyle/TypeEnum";
import SleepingPatternEnum from "../../enum/SleepingPatternEnum";
import ExerciseTypeEnum from "../../enum/ExerciseTypeEnum";
import SignatureInput from "../../../../components/signature-input/SignatureInput";

class PublicIntakeFormCurrentHealthPage2 extends Component {
    render() {
        const {
            form, selectedLanguage, enumLabel, textField, yesNoDescribeFieldShort,
            forceFormUpdate, onChange
        } = this.props;
        return (
            <>
                <div className="row">
                    <div className="col-3 d-flex justify-content-start align-items-center">
                        <label
                            className="col-form-label">{IntakeFormTextEnum.Lifestyle_Pattern[selectedLanguage]}</label>
                    </div>
                    <div className="col d-flex align-items-center">
                        <RadioInput
                            id="lifestylePatternRadio"
                            value={form.lifestylePattern}
                            options={TypeEnum.toArray()}
                            valueProperty={"key"}
                            displayProperty={enumLabel}
                            onChange={(value) =>
                                onChange("lifestylePattern", value)
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 d-flex justify-content-start align-items-center">
                        <label
                            className="col-form-label">{IntakeFormTextEnum.Sleeping_Pattern[selectedLanguage]}</label>
                    </div>
                    <div className="col d-flex align-items-center">
                        <RadioInput
                            id="sleepingPatternRadio"
                            value={form.sleepingPattern}
                            options={SleepingPatternEnum.toArray()}
                            valueProperty={"key"}
                            displayProperty={enumLabel}
                            onChange={(value) =>
                                onChange("sleepingPattern", value)
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-3 d-flex justify-content-start align-items-center">
                        <label
                            className="col-form-label">{IntakeFormTextEnum.Exercise_Type[selectedLanguage]}</label>
                    </div>
                    <div className="col d-flex align-items-center">
                        <RadioInput
                            id="exerciseTypeRadio"
                            value={form.exerciseType}
                            options={ExerciseTypeEnum.toArray()}
                            valueProperty={"key"}
                            displayProperty={enumLabel}
                            onChange={(value) => onChange("exerciseType", value)}
                        />
                    </div>
                </div>

                {yesNoDescribeFieldShort(form, "doYouSmoke", IntakeFormTextEnum.Do_You_Smoke[selectedLanguage], enumLabel)}
                {"Yes" === form.doYouSmoke && (
                    <div className="row">
                        <div className="container-fluid p-0">
                            <div className="panel bg-grey mb-3 ">
                                <div className="form-group">
                                    <label
                                        className="col-form-label">{IntakeFormTextEnum.Packs_per_Day[selectedLanguage]}</label>
                                    {textField(form, "doYouSmokeHowOften")}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {yesNoDescribeFieldShort(
                    form,
                    "haveYouEverSmoked",
                    IntakeFormTextEnum.Have_You_Ever_Smoked[selectedLanguage],
                    enumLabel
                )}
                {yesNoDescribeFieldShort(
                    form,
                    "doYouConsumeAlcohol",
                    IntakeFormTextEnum.Do_You_Consume_Alcohol[selectedLanguage],
                    enumLabel
                )}
                {"Yes" === form.doYouConsumeAlcohol && (
                    <div className="row">
                        <div className="container-fluid p-0">
                            <div className="panel bg-grey mb-3 ">
                                <div className="form-group">
                                    <label
                                        className="col-form-label">{IntakeFormTextEnum.Units_per_Week[selectedLanguage]}</label>
                                    {textField(form, "doYouConsumeAlcoholHowOften")}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="container-fluid p-0">
                        <div className="form-group panel bg-grey">
                            <label
                                className="col-form-label">{IntakeFormTextEnum.Signature[selectedLanguage]}</label>
                            <SignatureInput
                                selectedLanguage={selectedLanguage}
                                textEnum={IntakeFormTextEnum}
                                signature={form.patientSignature}
                                onClear={() => {
                                    form.patientSignature = null;
                                    forceFormUpdate();
                                }}
                                onSave={(signature) => {
                                    form.patientSignature = signature;
                                    forceFormUpdate();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PublicIntakeFormCurrentHealthPage2;