import * as ArrayUtils from "../../../../../utils/ArrayUtil";
import {GenericChecker} from "../GenericChecker";

export class ReviewOfSystemChecker extends GenericChecker {
    static isDataEntered(entity) {
        if (entity == null) {
            return false;
        }
        return !ArrayUtils.isEmpty(entity.cardiovascularCirculatory)
            || !ArrayUtils.isEmpty(entity.respiratory)
            || !ArrayUtils.isEmpty(entity.headNeckEyes)
            || !ArrayUtils.isEmpty(entity.ent)
            || !ArrayUtils.isEmpty(entity.skinHairNails)
            || !ArrayUtils.isEmpty(entity.lymphaticImmune)
            || !ArrayUtils.isEmpty(entity.endocrine)
            || !ArrayUtils.isEmpty(entity.gastrointestinal)
            || !ArrayUtils.isEmpty(entity.genitourinary)
            || !ArrayUtils.isEmpty(entity.musculoskeletal)
            || !ArrayUtils.isEmpty(entity.neurological);
    }
}