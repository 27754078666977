import React, {Component} from 'react';
import moment from "moment";
import StringUtil from "../../utils/StringUtil";
import {TextInput, Wizard} from "../../components";
import RemotingService from "../../services/remoting-service/RemotingService";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import SignatureInput from "../../components/signature-input/SignatureInput";
import Logo from "../../layout/Logo";
import PatientUtil from "../../utils/PatientUtil";
import ConsentFormsUtil from "./utils/ConsentFormsUtil";
import {FormField} from "../../components/form";

class UltrasoundDiagnosticForm extends Component {

    constructor(props) {
        super(props)

        this.ULTRASOUND_FEE_KWI = "45 KWD";
        this.ULTRASOUND_FEE_DXB = "550 AED";
        this.state = {
            healthProfessionalDate: new Date(),
            patientDate: new Date(),
            guardianDate: new Date(),
            providerName: this.props.staff.name,
            providerSpeciality: this.props.staff.speciality.name
        }
    }

    onSubmit = () => {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        if (this.props.form !== null && this.props.form !== undefined) {
            this.props.close();

        } else {
            let patient = this.props.patient;
            let patientFullName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
            let patientMrn = StringUtil.mrn(patient.mrn);
            let patientDOB = patient.birthday;

            const {
                healthProfessionalDate,
                patientDate,
                guardianDate,
                nameOfProposedInvestigation,
                guardianName,
                guardianRelationship,
                patientSignature,
                providerSignature,
                guardianSignature,
                providerName, providerSpeciality
            } = this.state;

            RemotingService.postRemoteCall('api/visit/form/ultrasound-diagnostic-form/' + this.props.visitId,
                {
                    healthProfessionalDate,
                    patientDate,
                    guardianDate,
                    nameOfProposedInvestigation,
                    guardianName,
                    guardianRelationship,
                    patientSignature,
                    providerSignature,
                    guardianSignature,
                    patientFullName,
                    patientMrn,
                    patientDOB,
                    providerName, providerSpeciality
                },
                (result) => {
                    this.props.afterSave(result);
                    this.props.close();
                });
        }
    }

    onSaveGuardianSignature = (signature) => {
        this.setState({guardianSignature: signature})
    }

    onSavePatientSignature = (signature) => {
        this.setState({patientSignature: signature})
    }

    onSaveProviderSignature = (signature) => {
        this.setState({providerSignature: signature})
    }

    render() {
        if (!this.props.show)
            return null;

        let {patient, form} = this.props;
        const {providerName, providerSpeciality} = this.state;
        let readOnly = form !== null && form !== undefined;
        let mrnNo = StringUtil.mrn(patient.mrn);
        let patientName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
        let consultationFee = patient.mrn.country === "UAE" ? this.ULTRASOUND_FEE_DXB : this.ULTRASOUND_FEE_KWI;
        this.formFields = []

        return (
            <Wizard
                show={this.props.show}
                close={this.props.close}
                title="Ultrasound Diagnostic Form"
                onSubmit={this.onSubmit}
                isReadOnly={readOnly}

            >
                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}>
                    <div style={{width: '1000px'}}>
                        <div style={{
                            marginTop: '15px', marginBottom: '15px', width: '100%', display: 'flex',
                            flexFlow: 'row', justifyContent: 'space-between', alignItems: 'center'
                        }}>
                            <Logo/>
                            <div>
                                <h2>ULTRASOUND DIAGNOSTIC FORM</h2>
                            </div>
                        </div>

                        <hr style={{
                            borderTop: "2px solid #000000",
                            marginTop: "-10px",
                            boxShadow: "0px 2px 2px #797979"
                        }}/>

                        <div style={{border: "1px solid #000000"}}>
                            <div style={{margin: "15px 15px"}}>
                                <h5>Patient information:</h5>
                                <div className={"row"} style={{marginTop: "10px", marginLeft: "0px"}}>
                                    <div className="col-md-5">
                                        <b>Name:</b> {patientName}
                                    </div>
                                    <div className="col-md-4">
                                        <b>MRN:</b> {mrnNo}
                                    </div>
                                    <div className="col-md-3">
                                        <b>D.O.B:</b> {patient.birthday}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                            <div style={{margin: "15px 15px"}}>
                                <h5>Name of proposed investigation:</h5>
                                <FormField ref={formField => this.formFields.push(formField)}
                                           disabled={readOnly}
                                           required
                                           validateOn={this.state.nameOfProposedInvestigation}>
                                    <TextInput
                                        value={readOnly ? form.nameOfProposedInvestigation : this.state.nameOfProposedInvestigation}
                                        disabled={readOnly} style={{width: "100%"}}
                                        maxLength={255}
                                        onChange={(val) => this.setState({nameOfProposedInvestigation: val})}/>
                                </FormField>
                            </div>
                        </div>

                        <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                            <div style={{margin: "15px 15px"}}>
                                <h5>Fee for the proposed investigation:</h5>
                                <p>Included in this fee is the medical report prepared after the investigation.
                                    This is in addition to the consultation fee of {consultationFee}.</p>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>
                    <div style={{width: '1000px'}}>
                        <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                            <div style={{margin: "10px 15px"}}>
                                <h5>
                                    Statement of health professional:
                                </h5>
                                <p style={{fontSize: "12px", marginTop: "-5px"}}>(to be filled in by health professional
                                    with appropriate
                                    knowledge of proposed investigation)</p>
                                <p>I have explained the investigation to the patient. In particular:</p>
                                <p><u>The intended benefits:</u><br/>
                                    Diagnostic image of musculoskeletal structure is used to detail the pathology and
                                    plan the treatment.<br/>
                                    This investigation is generally painless and does not require needles, injections,
                                    or
                                    incisions.<br/>
                                    Patients aren't exposed to ionizing radiation, making the procedure safer than
                                    diagnostic techniques such as X-rays and CT scans.<br/>
                                    The clinician can interact in real-time with the patient who can then direct the
                                    examination toward the symptomatic area<br/></p>
                                <p><u>Known side effects:</u><br/>
                                    None</p>
                            </div>
                        </div>
                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    PROVIDER NAME:
                                </div>
                                <div className="col-md-3">
                                    {providerName}
                                </div>

                                <div className="col-md-3 dim14-form-label">
                                    JOB TITLE:
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    {providerSpeciality}
                                </div>
                            </div>

                            <div className="row" style={{marginTop: "15px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    DATE/TIME:
                                </div>

                                <div className="col-md-3">
                                    {moment(readOnly && form.healthProfessionalDate ?
                                        new Date(form.healthProfessionalDate)
                                        :
                                        this.state.healthProfessionalDate).format('MMMM DD YYYY, h:mm:ss a')
                                    }
                                </div>

                                <div className="col-md-6 dim14-form-label">
                                    PROVIDER SIGNATURE:
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <FormField ref={formField => this.formFields.push(formField)}
                                               disabled={readOnly}
                                               required requiredMessage="Signature required"
                                               validateOn={this.state.providerSignature}>
                                        <SignatureInput
                                            signature={readOnly ? form.providerSignature : this.state.providerSignature}
                                            onSave={this.onSaveProviderSignature} readOnly={readOnly}/>
                                    </FormField>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                             onPrevious={() => this.formFields = []}>
                    <div style={{width: '1000px'}}>
                        <div style={{border: "1px solid #000000", margin: "10px 0px"}}>
                            <div style={{margin: "10px 15px"}}>
                                <h5>
                                    Statement of patient:
                                </h5>
                                <p style={{fontSize: "12px", marginTop: "-5px"}}>Please read this form carefully. If you
                                    have any further questions, do ask. You have the right to change your mind at any
                                    time, including after you have signed this form.</p>
                                <p>
                                    <b>I understand</b> the investigation which has been explained to me.<br/>
                                    <b>I agree</b> to the investigation described in this form.<br/>
                                    <b>I agree</b> that information and/or images kept in my records may be used
                                    anonymously for education, audit and research approved by an ethics committee,
                                    to improve the quality of patient care.<br/>
                                    <b>I understand</b> that the person performing the investigation has the appropriate
                                    experience or is training under direct supervision.<br/>
                                    <b>I agree</b> that I have had a chance to ask appropriate questions.<br/>
                                    <b>I agree</b> with the offered investigation fee.<br/>
                                </p>
                            </div>
                        </div>
                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    DATE/TIME:
                                </div>

                                <div className="col-md-3">
                                    {moment(readOnly && form.healthProfessionalDate ?
                                        new Date(form.healthProfessionalDate)
                                        :
                                        this.state.healthProfessionalDate).format('MMMM DD YYYY, h:mm:ss a')
                                    }
                                </div>
                            </div>

                            <div className="row" style={{marginTop: "15px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    PATIENT NAME:
                                </div>
                                <div className="col-md-3">
                                    {patientName}
                                </div>

                                <div className="col-md-6 dim14-form-label">
                                    PATIENT SIGNATURE:
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <SignatureInput
                                        signature={readOnly ? form.patientSignature : this.state.patientSignature}
                                        onSave={this.onSavePatientSignature} readOnly={readOnly}/>
                                </div>
                            </div>
                        </div>

                    </div>
                </Wizard.Page>
                <Wizard.Page onPrevious={() => this.formFields = []}>
                    <div style={{width: '1000px'}}>
                        <div className={"container"}>
                            <div className={"row"} style={{marginTop: "10px"}}>
                                <p><b>A witness should sign below if the patient is unable to sign but has indicated
                                    his or her consent. <br/>
                                    Young people (under {ConsentFormsUtil.getWitnessAgeText()} years of age) and children should also have a Parent
                                    or Guardian sign here.</b></p>
                            </div>

                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    PRINT NAME:
                                </div>
                                <div className="col-md-3">
                                    <TextInput value={readOnly ? form.guardianName : this.state.guardianName}
                                               disabled={readOnly} alphabeticOnly style={{textAlign: "center"}}
                                               onChange={(val) => this.setState({guardianName: val})}/>
                                </div>

                                <div className="col-md-3 dim14-form-label">
                                    Relationship to Patient
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    <TextInput
                                        value={readOnly ? form.guardianRelationship : this.state.guardianRelationship}
                                        disabled={readOnly} alphabeticOnly style={{textAlign: "center"}}
                                        onChange={(val) => this.setState({guardianRelationship: val})}/>
                                </div>
                            </div>


                            <div className={"row"} style={{marginTop: "15px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    DATE/TIME:
                                </div>

                                <div className="col-md-3">
                                    {moment(readOnly && form.guardianDate ?
                                        new Date(form.guardianDate)
                                        :
                                        this.state.guardianDate).format('MMMM DD YYYY, h:mm:ss a')
                                    }
                                </div>

                                <div className="col-md-6 dim14-form-label">
                                    SIGNATURE OF WITNESS/PARENT OR GUARDIAN:
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <FormField ref={formField => this.formFields.push(formField)}
                                               disabled={readOnly || this.state.patientSignature != null}
                                               required requiredMessage="Signature required"
                                               validateOn={this.state.guardianSignature}>
                                        <SignatureInput
                                            signature={readOnly ? form.guardianSignature : this.state.guardianSignature}
                                            onSave={this.onSaveGuardianSignature} readOnly={readOnly}/>
                                    </FormField>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>
            </Wizard>
        );
    }
}

export default UltrasoundDiagnosticForm;
