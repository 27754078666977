import React, {Component} from "react";
import '../../EMR.css';
import TextInput from "../../../../../components/text-input/TextInput";
import TextareaInput from "../../../../../components/textarea-input/TextareaInput";
import BorderedCheckboxInput from "../../../../../components/checkbox-input/BorderedCheckboxInput";
import DescriptionEnum from "../enums/complaint/DescriptionEnum";
import EtiologyEnum from "../enums/complaint/EtiologyEnum";
import CharacterEnum from "../enums/complaint/CharacterEnum";
import AggravatingFactorEnum from "../enums/complaint/AggravatingFactorEnum";
import FrequencyEnum from "../enums/complaint/FrequencyEnum";
import FeelEnum from "../enums/complaint/FeelEnum";
import OnsetEnum from "../enums/complaint/OnsetEnum";
import TimingEnum from "../enums/complaint/TimingEnum";
import TimingSensitivityEnum from "../enums/complaint/TimingSensitivityEnum";
import RelievingFactorsEnum from "../enums/complaint/RelievingFactorsEnum";
import RedFlagEnum from "../enums/complaint/RedFlagEnum";
import NatureEnum from "../enums/complaint/NatureEnum";
import BorderedRadioEnumInput from "../../../../../components/radio-input/BorderedRadioEnumInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import DurationEnum from "../enums/complaint/DurationEnum";
import Select from "react-select";
import DurationTimeEnum from "../enums/complaint/DurationTimeEnum";
import MomentUtils from '@date-io/moment';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateUtil from "../../../../../utils/DateUtil";
import GradientSlider10Mark3Category from "../../../../../components/slider/GradientSlider10Mark3Category";
import BodySilhouetteStaffCanvas from "../../../../consentform/BodySilhouette/BodySilhouetteStaffCanvas";
import {Arrow, Circle, Cross} from "../../../../consentform/BodySilhouette";
import EnumBodySilhouetteSignTypes from "../../../../consentform/BodySilhouette/EnumBodySilhouetteSignTypes";
import {Button} from "react-bootstrap";
import "../../../../../modules/consentform/BodySilhouette/BodySilhouette.css";
import noop from "lodash-es/noop";
import withStyles from "@material-ui/core/styles/withStyles";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import MaterialRadioButton from "../../../../../components/radio-input/MaterialRadioButton";
import LookupService from "../../../../../services/lookup/LookupService";
import AsyncSelect from "react-select/async";
import RemotingService from "../../../../../services/remoting-service/RemotingService";

class ComplaintModal extends Component {
    state = {
        onClickCanvasFunction: noop(),
    };

    constructor(props) {
        super(props);
        this.state.complaint = props.complaint;
        this.state.complaint.bodySilhouetteChilds = [];
        if(this.state.complaint.bodySilhouetteMarkList != null){
            this.state.complaint.bodySilhouetteChilds = this.state.complaint.bodySilhouetteMarkList.map((mark) => {
                return this.findMarkType(mark)
            });
        }

    }

    addItemToBodySilhouetteCanvas = (item) => {
        const bodySilhouetteComponents = [...this.state.complaint.bodySilhouetteChilds];
        bodySilhouetteComponents.push(item)
        const bodySilhouetteChilds = bodySilhouetteComponents.map(child => child != null ? React.cloneElement(child, {}) : null)
        this.setState(state => {
            state.complaint.bodySilhouetteChilds = bodySilhouetteChilds;
            return state
        });
    }

    deleteItemFromBodySilhouetteCanvas = () => {
        const bodySilhouetteComponents = [...this.state.complaint.bodySilhouetteChilds];
        bodySilhouetteComponents.pop()
        const bodySilhouetteChilds = bodySilhouetteComponents.map(child => child != null ? React.cloneElement(child, {}) : null)
        this.setState(state => {
            state.complaint.bodySilhouetteChilds = bodySilhouetteChilds
            return state
        });
    }

    findMarkType = (mark) => {
        const props = {
            top: mark.topDistance ? mark.topDistance : mark.top,
            left: mark.leftDistance ? mark.leftDistance : mark.left,
            angle: mark.angle,
            scaleX: mark.scaleX,
            scaleY: mark.scaleY,
            key: mark.type + Math.floor(Math.random() * 9999)
        }
        if (mark.type === EnumBodySilhouetteSignTypes.CROSS)
            return <Cross {...props}/>
        else if (mark.type === EnumBodySilhouetteSignTypes.ARROW)
            return <Arrow {...props}/>
        else if (mark.type === EnumBodySilhouetteSignTypes.CIRCLE)
            return <Circle {...props}/>
    }

    onCursorLeaveBodySilhouetteCanvas = () => {
        this.setState({symptomsCursorClassName : null, selectedSign: null, onClickCanvasFunction: noop})
    }

    calculateDurationTime(number, timeEnum) {
        if (!number || !timeEnum) {
            return null;
        }
        const today = new Date();
        if (timeEnum == "DAY") {
            return DateUtil.addDays(today, -1 * number);
        } else if (timeEnum == "WEEK") {
            return DateUtil.addWeeks(today, -1 * number);
        } else if (timeEnum == "MONTH") {
            return DateUtil.addMonths(today, -1 * number);
        } else if (timeEnum == "YEAR") {
            return DateUtil.addYears(today, -1 * number);
        }
        return null;
    }

    getDurationTypeAccordingToDayDifference(durationTime) {
        let durationType = null;

        if(durationTime != null){
            let numberOfDaysBetweenToDates = DateUtil.getDifferenceInDays(DateUtil.now(), durationTime);
            DurationEnum.toArray().forEach(item => {
                if(item.minDurationInDays <= numberOfDaysBetweenToDates && item.maxDurationInDays > numberOfDaysBetweenToDates){
                    durationType = item.key;
                }
            })
        }

        return durationType;
    }

    generateValueLabelArray(arraySize) {
        const foo = new Array(arraySize);
        for (let i = 1; i <= arraySize; i++) {
            foo.push({value: i, label: i});
        }
        return foo;
    }

    onCurrentPreviousChanged = (value) => {
        this.setState((state) => {
            state.complaint.currentPrevious = value;
            return state;
        });
    }

    onHistoryOfTraumaChanged = (value) => {
        let r = value != null ? (value === 'true') : null ;
        this.setState((state) => {
            state.complaint.historyOfTrauma = r;
            return state;
        });
    }

    render() {
        let valueLabelUntilHundred = this.generateValueLabelArray(100);

        return (
            <div className={"emr-modal content-row"}>
                <div className={"content-col-6 m-0 mr-2 p-3"}>
                    <div className="content-label">Complaint <RedAsterisk renderForAllTypes/></div>
                    <TextareaInput value={this.state.complaint.complaint || ''}
                                   placeholder={"Complaint"}
                                   onChange={value => this.setState(state => {
                                       state.complaint.complaint = value;
                                       return state;
                                   })
                                   }
                                   style={{width: "100%"}}
                    />

                    <AsyncSelect
                        className={"emr-in-table-select-container"}
                        classNamePrefix={"emr-in-table-select"}
                        style={{fontSize: "14px", width: "100%"}}
                        placeholder={"SNOMED code"}
                        noOptionsMessage={RemotingService.minCharsMessage}
                        loadOptions={LookupService.searchClinicalFindingSnomedCodes}
                        getOptionValue={v => v.id}
                        getOptionLabel={v => v.name}
                        value={this.state.complaint.secondaryComplaint}
                        onChange={value => this.setState(state => {
                            state.complaint.secondaryComplaint = value;
                            return state;
                        })
                        }
                    />

                    <div>
                        <MaterialRadioButton className={"mt-3"} name="currentPrevious"
                                             value={this.state.complaint.currentPrevious}
                                             onChange={this.onCurrentPreviousChanged.bind(this)}>
                            <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "10px"}}
                                              value="CURRENT" control={<Radio/>}
                                              label={<span>Current <RedAsterisk className={"font-weight-bold"} appointmentType={this.props.appointmentType} newComplaint/></span>}

                            />
                            <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "10px"}}
                                              value="PREVIOUS" control={<Radio/>}
                                              label={<span>Previous <RedAsterisk className={"font-weight-bold"} appointmentType={this.props.appointmentType} newComplaint/></span>}

                            />
                        </MaterialRadioButton>
                    </div>

                    <div className={"content-row"}>
                        <div className={"content-col-6"}>
                            <div>
                                <div className="font-weight-bold mt-3 mb-2">How does this make you feel?</div>
                                <BorderedCheckboxInput
                                    values={FeelEnum.toArray()}
                                    selected={this.state.complaint.feel}
                                    itemStyle={{marginLeft: "3px", marginRight: "3px"}}
                                />
                            </div>
                        </div>
                        <div className={"content-col-6 ml-2"}>
                            <div>
                                <div className="font-weight-bold mb-2">Etiology <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                                <div className={"content-row"} style={{justifyContent: "left", alignItems: "baseline"}}>
                                    <div className={"font-weight-bold"}>
                                        History of Trauma
                                    </div>
                                    <div>
                                        <MaterialRadioButton name="historyOfTraumaYesNo"
                                                             classes={{
                                                                 root: this.props.classes.formGroup
                                                             }}
                                                             value={this.state.complaint.historyOfTrauma}
                                                             onChange={this.onHistoryOfTraumaChanged.bind(this)}>
                                            <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "10px"}}
                                                              value={true} control={<Radio/>}
                                                              label="Yes"
                                            />
                                            <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "10px"}}
                                                              value={false} control={<Radio/>}
                                                              label="No"
                                            />
                                        </MaterialRadioButton>
                                    </div>
                                </div>
                                <BorderedCheckboxInput
                                    values={EtiologyEnum.toArray()}
                                    selected={this.state.complaint.etiology}
                                />
                                <TextInput value={this.state.complaint.etiologyDetail || ''}
                                           placeholder={"Details"}
                                           className="mt-2"
                                           style={{width: '-webkit-fill-available'}}
                                           onChange={value => this.setState(state => {
                                               state.complaint.etiologyDetail = value;
                                               return state;
                                           })
                                           }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"content-row"}>
                        {/*Frequency Description*/}
                        <div className={"content-col-6"}>
                            <div>
                                <div className="font-weight-bold mb-2">Frequency <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                                <BorderedCheckboxInput
                                    values={FrequencyEnum.toArray()}
                                    selected={this.state.complaint.frequency}
                                    itemStyle={{marginLeft: "3px", marginRight: "3px"}}
                                />
                                <TextInput value={this.state.complaint.frequencyDetail || ''}
                                           placeholder={"Details"}
                                           className="mt-2"
                                           style={{width: '-webkit-fill-available'}}
                                           onChange={value => this.setState(state => {
                                               state.complaint.frequencyDetail = value;
                                               return state;
                                           })
                                           }
                                />
                            </div>
                        </div>
                        <div className={"content-col-6 ml-2"}>
                            <div>
                                <div className="font-weight-bold mb-2">Description <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                                <BorderedRadioEnumInput
                                    values={DescriptionEnum.toArray()}
                                    selected={this.state.complaint.description}
                                    onSelect={(value) => {
                                        this.setState((state) => {
                                            state.complaint.description = value;
                                            return state;
                                        })
                                    }}
                                />
                                <TextInput value={this.state.complaint.descriptionDetail || ''}
                                           placeholder={"Details"}
                                           className="mt-2"
                                           style={{width: '-webkit-fill-available'}}
                                           onChange={value => this.setState(state => {
                                               state.complaint.descriptionDetail = value;
                                               return state;
                                           })
                                           }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"content-row"}>
                        {/*Duration Onset*/}
                        <div className={"content-col-6"}>
                            <div>
                                <h5 className="font-weight-bold">Duration <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></h5>
                                <BorderedRadioEnumInput
                                    values={DurationEnum.toArray()}
                                    selected={this.state.complaint.durationType}
                                    onSelect={(value) => {
                                        this.setState((state) => {
                                            state.complaint.durationType = value;
                                            return state;
                                        })
                                    }}
                                />

                                <div className={"row no-margin"}>
                                    <div className={"col-3 no-padding"}>
                                        <Select
                                            className={"emr-gray-select-container"}
                                            classNamePrefix={"emr-gray-select"}
                                            placeholder={"Number"}
                                            value={this.state.complaint.durationNumber != null ?
                                                {
                                                    value: this.state.complaint.durationNumber,
                                                    label: this.state.complaint.durationNumber
                                                } : null}
                                            onChange={(event) => {
                                                let r = event.value;
                                                this.setState((state) => {
                                                    let durationTime = this.calculateDurationTime(r, state.complaint.durationTimeEnum)
                                                    state.complaint.durationNumber = r;
                                                    state.complaint.durationTime = durationTime;
                                                    if(durationTime != null){
                                                        state.complaint.durationType = this.getDurationTypeAccordingToDayDifference(durationTime);
                                                    }
                                                    return state;
                                                });
                                            }}
                                            options={valueLabelUntilHundred}
                                        />
                                    </div>
                                    <div className={"col-3 no-padding"}>
                                        <Select
                                            className={"emr-gray-select-container"}
                                            classNamePrefix={"emr-gray-select"}
                                            placeholder={"Duration"}
                                            value={this.state.complaint.durationTimeEnum != null ?
                                                {
                                                    value: DurationTimeEnum[this.state.complaint.durationTimeEnum].key,
                                                    label: DurationTimeEnum[this.state.complaint.durationTimeEnum].name
                                                } : null}
                                            onChange={(event) => {
                                                let r = event.value;
                                                this.setState((state) => {
                                                    let durationTime = this.calculateDurationTime(state.complaint.durationNumber, r);
                                                    state.complaint.durationTimeEnum = r;
                                                    state.complaint.durationTime = durationTime;
                                                    if(durationTime != null){
                                                        state.complaint.durationType = this.getDurationTypeAccordingToDayDifference(durationTime);
                                                    }
                                                    return state;
                                                });
                                            }}
                                            options={DurationTimeEnum.toArray().map(v => {
                                                    return {value: v.key, label: v.name}
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className={"col-6 no-padding"}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <KeyboardDatePicker className={"emr-readonly-date"}
                                                                value={this.state.complaint.durationTime
                                                                    ? this.state.complaint.durationTime : null}
                                                                disabled={true}
                                                                format={"DD-MMM-YYYY"}
                                                                placeholder={". . ."}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>

                                <TextInput value={this.state.complaint.durationDetails || ''}
                                           placeholder={"Details"}
                                           className="mt-2"
                                           style={{width: '-webkit-fill-available'}}
                                           onChange={value => this.setState(state => {
                                               state.complaint.durationDetails = value;
                                               return state;
                                           })
                                           }
                                />
                            </div>
                        </div>
                        <div className={"content-col-6 ml-2"}>
                            <div>
                                <h5 className="font-weight-bold">Onset <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></h5>
                                <BorderedRadioEnumInput
                                    values={OnsetEnum.toArray()}
                                    selected={this.state.complaint.onset}
                                    onSelect={(value) => {
                                        this.setState((state) => {
                                            state.complaint.onset = value;
                                            return state;
                                        })
                                    }}
                                />
                                <TextInput value={this.state.complaint.onsetDetail || ''}
                                           placeholder={"Details"}
                                           className="mt-2"
                                           style={{width: '-webkit-fill-available'}}
                                           onChange={value => this.setState(state => {
                                               state.complaint.onsetDetail = value;
                                               return state;
                                           })
                                           }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"content-row"}>
                        {/*Character Timing*/}
                        <div className={"content-col-6"}>
                            <div>
                                <h5 className="font-weight-bold">Character</h5>
                                <BorderedCheckboxInput
                                    values={CharacterEnum.toArray()}
                                    selected={this.state.complaint.character}
                                    itemStyle={{marginLeft: "3px", marginRight: "3px"}}
                                />
                                <TextInput value={this.state.complaint.characterDetail || ''}
                                           placeholder={"Details"}
                                           className="mt-2"
                                           style={{width: '-webkit-fill-available'}}
                                           onChange={value => this.setState(state => {
                                               state.complaint.characterDetail = value;
                                               return state;
                                           })
                                           }
                                />
                            </div>
                        </div>
                        <div className={"content-col-6 ml-2"}>
                            <div>
                                <h5 className="font-weight-bold">Timing</h5>
                                <BorderedCheckboxInput
                                    values={TimingEnum.toArray()}
                                    selected={this.state.complaint.timing}
                                />
                                <div className={"row no-margin pt-3"}>
                                    <div className={"col-3 no-padding font-weight-bold"}
                                         style={{margin: "auto", fontSize: "15px"}}>
                                        Sensitivity
                                    </div>
                                    <div className={"col-9 no-padding"}>
                                        <BorderedCheckboxInput
                                            values={TimingSensitivityEnum.toArray()}
                                            selected={this.state.complaint.timingSensitivity}
                                        />
                                    </div>
                                </div>
                                <TextInput value={this.state.complaint.timingDetail || ''}
                                           placeholder={"Details"}
                                           className="mt-2"
                                           style={{width: '-webkit-fill-available'}}
                                           onChange={value => this.setState(state => {
                                               state.complaint.timingDetail = value;
                                               return state;
                                           })
                                           }
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"content-row"}>
                        {/*Aggravating Factors - Relieving Factors*/}
                        <div className={"content-col-6"}>
                            <div>
                                <BorderedCheckboxInput
                                    title={<span style={{ fontSize: "18px" }}>Aggravating Factors <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></span>}
                                    values={AggravatingFactorEnum.toArray()}
                                    selected={this.state.complaint.aggravatingFactor}
                                    obj={this.state.complaint}
                                    field={"aggravatingFactorDetail"}
                                    emptyMessage= {this.state.complaint.aggravatingFactorNormal ? "No Agg. Factors" : "" }
                                    normalCheckbox
                                    normalCheckboxLabel = {"No Agg. Factors"}
                                    normalCheckboxValue={this.state.complaint.aggravatingFactorNormal}
                                    onChangeNormalCheckbox={(value) => {
                                        this.setState(state => {
                                            state.complaint.aggravatingFactorNormal = value;
                                            return state;
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"content-col-6 ml-2"}>
                            <div>
                                <BorderedCheckboxInput
                                    title={<span style={{fontSize: "18px"}}>Relieving Factors <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></span>}
                                    values={RelievingFactorsEnum.toArray()}
                                    selected={this.state.complaint.relievingFactors}
                                    obj={this.state.complaint}
                                    field={"relievingFactorsDetail"}
                                    emptyMessage= {this.state.complaint.relievingFactorsNormal ? "No Relieving Factor" : "" }
                                    normalCheckboxLabel = {"No Relieving Factor"}
                                    normalCheckbox
                                    normalCheckboxValue={this.state.complaint.relievingFactorsNormal}
                                    onChangeNormalCheckbox={(value) => {
                                        this.setState(state => {
                                            state.complaint.relievingFactorsNormal = value;
                                            return state;
                                        })
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="content-col-6 m-0 ml-2 pl-0 pt-0">
                    <div className={`content-row p-3 bg-white ${this.state.symptomsCursorClassName}`}>
                        <div className="col no-padding">
                            <BodySilhouetteStaffCanvas
                                onRemoveItem={() => this.deleteItemFromBodySilhouetteCanvas()}
                                onPageChange={(objects) => {
                                    this.state.complaint.bodySilhouetteObjects = [...objects];
                                }}
                                symptomsCursorClassName={this.state.symptomsCursorClassName}
                                onLeave={this.onCursorLeaveBodySilhouetteCanvas}
                                onClickFunction={this.state.onClickCanvasFunction}
                            >
                                {this.state.complaint.bodySilhouetteChilds}
                            </BodySilhouetteStaffCanvas>
                        </div>
                    </div>


                    {/*Symptoms*/}
                    <div className="p-3 mt-3">
                        <h5 className="font-weight-bold">Symptoms</h5>
                        <div className="content-row p-justify-start">
                            <div className="content-col-6 m-0">
                                <div className="display-flex p-justify-between">
                                    <h6 className="font-weight-bold">Pain (VAS) <RedAsterisk renderForAllTypes/></h6>
                                    <Button className={"complaint-modal-transparent-button"}
                                            disabled={this.state.selectedSign === EnumBodySilhouetteSignTypes.CROSS}
                                            onClick={() => {
                                                this.setState({
                                                    selectedSign: EnumBodySilhouetteSignTypes.CROSS,
                                                    onClickCanvasFunction: (top, left) => this.addItemToBodySilhouetteCanvas(
                                                        <Cross top={top}
                                                               left={left}/>),
                                                    symptomsCursorClassName: "cross-cursor"
                                                })
                                            }}
                                    >
                                        <div style={{fontSize: "10pt"}}>
                                            <img src={process.env.PUBLIC_URL + '/assets/forms/cross.png'}
                                                 style={{
                                                     height: "16px",
                                                     marginTop: "-5px",
                                                     marginLeft: "-5px"
                                                 }}/> {' Add Mark '}
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="content-row p-justfiy-between align-items-baseline">
                            <div className="content-col-6 mt-0">
                                <GradientSlider10Mark3Category
                                    value={this.state.complaint.pain}
                                    onChange={(e, val) =>
                                        this.setState(state => {
                                            state.complaint.pain = val;
                                            return state;
                                        })}
                                />
                            </div>
                            <div className="content-col-6 ml-0 ml-2">
                                <TextInput value={this.state.complaint.painDetail || ''}
                                           placeholder={"Details"}
                                           className="mt-2"
                                           style={{width: '-webkit-fill-available'}}
                                           onChange={value => this.setState(state => {
                                               state.complaint.painDetail = value;
                                               return state;
                                           })
                                           }
                                />
                            </div>
                        </div>
                        <div className="content-row p-justify-start">
                            <div className="content-col-6 m-0">
                                <div className="display-flex p-justify-between">
                                    <h6 className="font-weight-bold">Swelling</h6>
                                    <Button className={"complaint-modal-transparent-button"}
                                            disabled={this.state.selectedSign === EnumBodySilhouetteSignTypes.CIRCLE}
                                            onClick={() => {
                                                this.setState({
                                                    selectedSign: EnumBodySilhouetteSignTypes.CIRCLE,
                                                    onClickCanvasFunction: (top, left) => this.addItemToBodySilhouetteCanvas(
                                                        <Circle top={top}
                                                                left={left}/>),
                                                    symptomsCursorClassName: "circle-cursor"
                                                })
                                            }}
                                    >
                                        <div style={{fontSize: "10pt"}}>
                                            <img src={process.env.PUBLIC_URL + '/assets/forms/circle.png'}
                                                 style={{
                                                     height: "16px",
                                                     marginTop: "-5px",
                                                     marginLeft: "-5px"
                                                 }}/> {' Add Mark '}
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="content-row p-justfiy-between align-items-baseline">
                            <div className="content-col-6 mt-0">
                                <GradientSlider10Mark3Category
                                    value={this.state.complaint.swelling}
                                    onChange={(e, val) =>
                                        this.setState(state => {
                                            state.complaint.swelling = val;
                                            return state;
                                        })}
                                />
                            </div>
                            <div className="content-col-6 mt-0 ml-2">
                                <TextInput value={this.state.complaint.swellingDetail || ''}
                                           placeholder={"Details"}
                                           className="mt-2"
                                           style={{width: '-webkit-fill-available'}}
                                           onChange={value => this.setState(state => {
                                               state.complaint.swellingDetail = value;
                                               return state;
                                           })
                                           }
                                />
                            </div>
                        </div>
                        <div className="content-row p-justify-between">
                            <div className="content-col-6 m-0">
                                <h6 className="font-weight-bold">Nature <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></h6>
                            </div>
                            <div className="content-col-6 m-0 ml-2">
                                <div className="display-flex p-justify-between">
                                    <h6 className="font-weight-bold">Referral</h6>
                                    <Button className={"complaint-modal-transparent-button"}
                                            disabled={this.state.selectedSign === EnumBodySilhouetteSignTypes.ARROW}
                                            onClick={() => {
                                                this.setState({
                                                    selectedSign: EnumBodySilhouetteSignTypes.ARROW,
                                                    onClickCanvasFunction: (top, left) => this.addItemToBodySilhouetteCanvas(
                                                        <Arrow top={top}
                                                               left={left}/>),
                                                    symptomsCursorClassName: "arrow-cursor"
                                                })
                                            }}
                                    >
                                        <div style={{fontSize: "10pt"}}>
                                            <img src={process.env.PUBLIC_URL + '/assets/forms/arrow.png'}
                                                 style={{
                                                     height: "16px",
                                                     marginTop: "-5px",
                                                     marginLeft: "-5px"
                                                 }}/> {' Add Mark '}
                                        </div>
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="content-row">
                            <div className="content-col-6 mt-0">
                                <div style={{display: "flex", flexDirection: 'column'}}>
                                    <BorderedRadioEnumInput
                                        values={NatureEnum.toArray()}
                                        selected={this.state.complaint.nature}
                                        onSelect={(value) => {
                                            this.setState((state) => {
                                                state.complaint.nature = value;
                                                return state;
                                            })
                                        }}
                                    />
                                    <TextInput value={this.state.complaint.natureDetail || ''}
                                               placeholder={"Details"}
                                               className="mt-2"
                                               style={{width: '-webkit-fill-available'}}
                                               onChange={value => this.setState(state => {
                                                   state.complaint.natureDetail = value;
                                                   return state;
                                               })
                                               }
                                    />
                                </div>
                            </div>
                            <div className="content-col-6 mt-0 ml-2">
                                <TextInput value={this.state.complaint.referral || ''}
                                           placeholder={"Details"}
                                           className="mt-2"
                                           style={{width: '-webkit-fill-available', marginTop: "7px"}}
                                           onChange={value => this.setState(state => {
                                               state.complaint.referral = value;
                                               return state;
                                           })
                                           }
                                />
                            </div>
                        </div>
                    </div>

                    {/*Red Flags*/}
                    <div className="p-3 mt-3">
                        <BorderedCheckboxInput
                            title={<span>Red Flags <RedAsterisk appointmentType={this.props.appointmentType} renderForAllTypes/></span>}
                            values={RedFlagEnum.toArray()}
                            selected={this.state.complaint.redFlag}
                            obj={this.state.complaint}
                            field={"redFlagDetail"}
                            emptyMessage= {this.state.complaint.redFlagNormal ? "No Red Flag" : "" }
                            normalCheckbox
                            normalCheckboxLabel = {"No Red Flag"}
                            normalCheckboxValue={this.state.complaint.redFlagNormal}
                            onChangeNormalCheckbox={(value) => {
                                this.setState(state => {
                                    state.complaint.redFlagNormal = value;
                                    return state;
                                })
                            }}
                        />

                    </div>

                </div>
            </div>
        );
    }
}

const styles = theme => ({
    formGroup: {
        display: "unset",
    },
});

export default withStyles(styles)(ComplaintModal);
