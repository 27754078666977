import React, {Component} from "react";
import '../../EMR.css';
import TextInput from "../../../../../components/text-input/TextInput";
import GreenSwitchWithEmptyMessage from "../../../../../components/switch/GreenSwitchWithEmptyMessage";
import TextareaInput from "../../../../../components/textarea-input/TextareaInput";
import Select from "react-select";
import TimelinePeriodEnum from "../enums/goal/TimelinePeriodEnum";
import ProgressEnum from "../enums/goal/ProgressEnum";
import ShortTermGoalEnum from "../enums/goal/ShortTermGoalEnum";
import NotificationService from "../../../../../services/notification-service/NotificationService";
import AddButton from "../../common/table/AddButton";
import RemoveButton from "../../common/table/RemoveButton";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import EnumAppointmentType from "../../../../appointment/enums/EnumAppointmentType";

export default class TreatmentGoalsModal extends Component {

    state = {
        activeShortTermItem: {},
        activeLongTermItem: {}
    };

    generateValueLabelArray(arraySize) {
        const foo = new Array(arraySize);
        for (let i = 1; i <= arraySize; i++) {
            foo.push({value: i, label: i});
        }
        return foo;
    }

    render() {
        const goal = this.props.treatment.goal;

        return (
            <div className={" content-row emr-background w-100 py-4 px-5"}>
                <div className="w-100">
                    <div className="row d-flex align-items-baseline">
                        <div className="content-label mb-0">Goals <RedAsterisk renderForAllTypes/></div>
                        <GreenSwitchWithEmptyMessage
                            checked={goal && (
                                goal.details || goal.specialRemarks ||
                                goal.shortTermItems && goal.shortTermItems.length ||
                                goal.longTermItems && goal.longTermItems.length)
                            }
                            onChange={() => {
                                if (this.props.treatment.goal == null) {
                                    this.props.treatment.goal = this.createNewGoal();
                                } else {
                                    this.props.treatment.goal.details = null;
                                    this.props.treatment.goal.specialRemarks = null;
                                    this.props.treatment.goal.shortTermItems = [];
                                    this.props.treatment.goal.longTermItems = [];
                                }
                                this.forceUpdate();
                            }}/>
                    </div>
                    <div className={"row mt-3"}>
                        <h6 className="font-weight-bold ml-0">Patient Goal <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></h6>
                    </div>
                    <div className={"row mt-3"}>
                        <TextareaInput
                            value={goal && goal.details || ''}
                            placeholder={""}
                            rows={5}
                            className="w-100 ml-0"
                            style={{fontSize: "12px", minHeight: "30px"}}
                            onChange={value => {
                                this.props.treatment.goal.details = value;
                                this.forceUpdate();
                            }}
                        />
                    </div>
                    <div className={"row mt-3"}>
                        <div className={"col-12"}>
                            {this.renderGoalsTable('short')}
                        </div>
                    </div>
                    <div className={"row mt-3"}>
                        <div className={"col-12"}>
                            {this.renderGoalsTable('long')}
                        </div>
                    </div>
                    <div className={"row mt-5"}>
                        <div className={"col-12"}>
                            <h6 className="font-weight-bold">Special Remarks</h6>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"w-100"} style={{padding: "5px 0px"}}>
                            <TextareaInput
                                value={goal && goal.specialRemarks || ''}
                                placeholder={""}
                                rows={5}
                                style={{fontSize: "12px", width: "100%", minHeight: "30px"}}
                                onChange={value => {
                                    this.props.treatment.goal.specialRemarks = value;
                                    this.forceUpdate();
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    checkItemFields = (item) => {
        const appointmentType = this.props.appointmentType;
        const firstVisit = EnumAppointmentType.NEW_PATIENT === appointmentType;
        const newComplaint = EnumAppointmentType.NEW_COMPLAINT === appointmentType;
        const followUp = EnumAppointmentType.FOLLOW_UP === appointmentType;

        const validationResult =
            ((!firstVisit && !newComplaint) || item.areaOfConcern) &&
            (item.shortTermGoal || item.longTermGoal) &&
            ((!firstVisit && !newComplaint) || item.target) &&
            ((!firstVisit && !newComplaint) || item.duration) &&
            ((!firstVisit && !newComplaint) || item.period) &&
            (!followUp || item.achieved);

        if (!validationResult) {
            NotificationService.showNotification({
                severity: 'warn',
                summary: 'Missing Item Fields',
                detail: 'Enter missing fields to save'
            });
        }

        return validationResult;
    }

    renderGoalsTable = (goalType) => {
        const goal = this.props.treatment.goal;
        const valueLabelUpto100 = this.generateValueLabelArray(100);
        const forShortGoals = 'short' === goalType;
        const sourceItem = forShortGoals ? this.state.activeShortTermItem : this.state.activeLongTermItem;
        const goalItems = !goal ? [] : forShortGoals ? goal.shortTermItems : goal.longTermItems;

        return (
            <div className="content-card m-0">
                <table className={"emr-table"}>
                    <thead>
                    <tr>
                        <th>Area of Focus</th>
                        <th>{forShortGoals ? 'Short' : 'Long'} Term Goals</th>
                        <th>Target</th>
                        <th colSpan="2">Timeline</th>
                        <th>%Ach</th>
                        <th>Progress</th>
                        <th style={{color: "red"}}>ⓧ</th>
                    </tr>
                    </thead>
                    <tbody>
                    {goal && goalItems &&
                    goalItems.map((item, index) => {
                        return (<tr key={"shortTermGoalItem" + index}
                                    style={{backgroundColor: "white"}}>
                            <td>{item.areaOfConcern}</td>
                            <td style={{maxWidth: '150px'}}>{item.shortTermGoal ?
                                (ShortTermGoalEnum[item.shortTermGoal] ? ShortTermGoalEnum[item.shortTermGoal].name : item.shortTermGoal) : item.longTermGoal}</td>
                            <td>{item.target && `${item.target}%`}</td>
                            <td>{item.duration}</td>
                            <td>{item.period && TimelinePeriodEnum[item.period].name}</td>
                            <td>
                                {this.props.appointmentType === "FOLLOW_UP" ?
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        placeholder={"Achieved"}
                                        menuPlacement="top"
                                        value={item.achieved != null ?
                                            {
                                                value: item.achieved,
                                                label: `${item.achieved}%`
                                            } : null}
                                        onChange={(event) => {
                                            item.achieved = event.value;
                                            this.forceUpdate();
                                        }}
                                        options={valueLabelUpto100}
                                    /> :
                                    item.achieved ? `${item.achieved}%`: ''
                                }
                            </td>
                            <td>
                                {this.props.appointmentType === "FOLLOW_UP" ?
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        placeholder={"Progress"}
                                        menuPlacement="top"
                                        value={item.progress != null ?
                                            {
                                                value: ProgressEnum[item.progress].key,
                                                label: ProgressEnum[item.progress].name
                                            } : null}
                                        onChange={(event) => {
                                            item.progress = event.value;
                                            this.forceUpdate();
                                        }}
                                        options={ProgressEnum.toArray().map(v => {
                                                return {value: v.key, label: v.name}
                                            }
                                        )}
                                    /> :
                                    item.progress && ProgressEnum[item.progress].name
                                }
                            </td>
                            <td>
                                <RemoveButton
                                    onClick={() => {
                                        goalItems.splice(index, 1);
                                        this.forceUpdate();
                                    }}
                                />
                            </td>
                        </tr>)
                    })
                    }
                    <tr style={{backgroundColor: "white"}}>
                        <td className="p-2">
                            <TextInput value={sourceItem.areaOfConcern || ''}
                                       placeholder={""}
                                       onChange={value => {
                                           sourceItem.areaOfConcern = value;
                                           this.forceUpdate();
                                       }}
                                       style={{width: "100%"}}
                            />
                        </td>
                        <td className="px-2" style={{maxWidth: '150px'}}>
                            {forShortGoals &&
                            <AsyncSelect
                                className={"emr-white-select-container"}
                                classNamePrefix={"emr-white-select"}
                                noOptionsMessage={this.noOptionsMessage.bind(this)}
                                loadOptions={this.getShortTermOptions}
                                defaultOptions
                                menuPlacement="top"
                                value={sourceItem.shortTermGoal != null ?
                                    (ShortTermGoalEnum[sourceItem.shortTermGoal] != null ?
                                        {
                                            value: ShortTermGoalEnum[sourceItem.shortTermGoal].key,
                                            label: ShortTermGoalEnum[sourceItem.shortTermGoal].name
                                        } :
                                        {
                                            value: sourceItem.shortTermGoal,
                                            label: sourceItem.shortTermGoal
                                        }) : null}
                                onChange={(event) => {
                                    let r = event == null ? null : event.value;
                                    this.setState((state) => {
                                        const target = sourceItem;
                                        target.shortTermGoal = r;
                                        return state;
                                    });
                                }}
                            />}
                            {!forShortGoals &&
                            <TextInput value={sourceItem.longTermGoal || ''}
                                       placeholder={""}
                                       onChange={value => {
                                           sourceItem.longTermGoal = value;
                                           this.forceUpdate();
                                       }}
                                       style={{width: "100%"}}
                            />}
                        </td>
                        <td className="px-2">
                            <Select
                                className={"emr-white-select-container"}
                                classNamePrefix={"emr-white-select"}
                                placeholder={"Target"}
                                menuPlacement="top"
                                value={sourceItem.target != null ?
                                    {
                                        value: sourceItem.target,
                                        label: `${sourceItem.target}%`
                                    } : null}
                                onChange={(event) => {
                                    sourceItem.target = event.value;
                                    this.forceUpdate();
                                }}
                                options={valueLabelUpto100}
                            />
                        </td>
                        <td className="px-2">
                            <Select
                                className={"emr-white-select-container"}
                                classNamePrefix={"emr-white-select"}
                                placeholder={"Duration"}
                                menuPlacement="top"
                                value={sourceItem.duration != null ?
                                    {
                                        value: sourceItem.duration,
                                        label: sourceItem.duration
                                    } : null}
                                onChange={(event) => {
                                    sourceItem.duration = event.value;
                                    this.forceUpdate();
                                }}
                                options={valueLabelUpto100}
                            />
                        </td>
                        <td className="px-2">
                            <Select
                                className={"emr-white-select-container"}
                                classNamePrefix={"emr-white-select"}
                                placeholder={"Period"}
                                menuPlacement="top"
                                value={sourceItem.period != null ?
                                    {
                                        value: TimelinePeriodEnum[sourceItem.period].key,
                                        label: TimelinePeriodEnum[sourceItem.period].name
                                    } : null}
                                onChange={(event) => {
                                    sourceItem.period = event.value;
                                    this.forceUpdate();
                                }}
                                options={TimelinePeriodEnum.toArray().map(v => {
                                        return {value: v.key, label: v.name}
                                    }
                                )}
                            />
                        </td>
                        <td className="px-2">
                            <Select
                                className={"emr-white-select-container"}
                                classNamePrefix={"emr-white-select"}
                                placeholder={"Achieved"}
                                menuPlacement="top"
                                value={sourceItem.achieved != null ?
                                    {
                                        value: sourceItem.achieved,
                                        label: `${sourceItem.achieved}%`
                                    } : null}
                                onChange={(event) => {
                                    sourceItem.achieved = event.value;
                                    this.forceUpdate();
                                }}
                                options={valueLabelUpto100}
                            />
                        </td>
                        <td className="position-relative px-2">
                            <Select
                                className={"emr-white-select-container"}
                                classNamePrefix={"emr-white-select"}
                                placeholder={"Progress"}
                                menuPlacement="top"
                                value={sourceItem.progress != null ?
                                    {
                                        value: ProgressEnum[sourceItem.progress].key,
                                        label: ProgressEnum[sourceItem.progress].name
                                    } : null}
                                onChange={(event) => {
                                    sourceItem.progress = event.value;
                                    this.forceUpdate();
                                }}
                                options={ProgressEnum.toArray().map(v => {
                                        return {value: v.key, label: v.name}
                                    }
                                )}
                            />
                        </td>
                        <td>
                            <AddButton
                                onClick={() => {
                                    if (this.checkItemFields(sourceItem)) {
                                        goalItems.push(sourceItem);
                                        if (forShortGoals) {
                                            this.state.activeShortTermItem = {};
                                        } else {
                                            this.state.activeLongTermItem = {};
                                        }
                                        this.forceUpdate();
                                    }
                                }}
                            />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    createNewGoal = () => {
        return {
            shortTermItems: [],
            longTermItems: []
        };
    }

    getShortTermOptions = (inputValue, callback) => {
        let options = [];
        options = ShortTermGoalEnum.toArray().map(item => {
            if ((inputValue == null || inputValue.length == 0) || item.name.toLowerCase().includes(inputValue.toLowerCase()))
                return {value: item.key, label: item.name}
            else
                return null;
        }).filter(Boolean);
        if (options.length == 0)
            options.push({value: inputValue, label: inputValue});
        callback(options);
    }

    noOptionsMessage(input) {
        return <div>No options</div>;
    }

}
