import Enum from "../../../../../../enums/Enum";

const PhysicalActivityEnum = new Enum({
    NONE: {name: "None"},
    RECREATIONAL: {name: "Recreational"},
    CLASSES: {name: "Classes"},
    PERSONAL_TRAINER: {name: "Personal Trainer"},
    PERFORMANCE_SPORT: {name: "Performance Sports"},
    OTHER: {name: "Other"}
});

export default PhysicalActivityEnum;