import React, {Component} from 'react';
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import CheckboxInput from "../../../components/checkbox-input/CheckboxInput";
import FormField from "../../../components/form/FormField";
import {TextInput} from "../../../components";

class SymptomsInterfereWith extends Component {
    render() {
        const {
            selectedLanguage, readOnly, onChange, formFields,
            interfereWithSleep, interfereWithWork, interfereWithRest, interfereWithDailyRoutine, interfereWithSports,
            interfereWithOther, interfereWithOtherDetail
        } = this.props

        return (
            <div className="container">
                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Symptoms_Interfere_With[selectedLanguage]}
                    </div>
                    <div className="col-8 d-flex">
                        <div className="d-flex">
                            <CheckboxInput
                                value={interfereWithSleep}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("interfereWithSleep", value)
                                }
                            />
                            <label className="mx-2">
                                {IntakeFormTextEnum.Sleep[selectedLanguage]}
                            </label>
                        </div>
                        <div className="d-flex">
                            <CheckboxInput
                                value={interfereWithWork}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("interfereWithWork", value)
                                }
                            />
                            <label className="mx-2">
                                {IntakeFormTextEnum.Work[selectedLanguage]}
                            </label>
                        </div>
                        <div className="d-flex">
                            <CheckboxInput
                                value={interfereWithRest}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("interfereWithRest", value)
                                }
                            />
                            <label className="mx-2">
                                {IntakeFormTextEnum.Rest[selectedLanguage]}
                            </label>
                        </div>
                        <div className="d-flex">
                            <CheckboxInput
                                value={interfereWithDailyRoutine}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("interfereWithDailyRoutine", value)
                                }
                            />
                            <label className="mx-2">
                                {IntakeFormTextEnum.Daily_Routine[selectedLanguage]}
                            </label>
                        </div>
                        <div className="d-flex">
                            <CheckboxInput
                                value={interfereWithSports}
                                disabled={readOnly}
                                onChange={(value) =>
                                    onChange("interfereWithSports", value)
                                }
                            />
                            <label className="mx-2">
                                {IntakeFormTextEnum.Sports_Exercise[selectedLanguage]}
                            </label>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-4 dim14-form-label"></div>
                    <div className="col-8 d-flex pt-0">
                        <CheckboxInput
                            value={interfereWithOther}
                            disabled={readOnly}
                            onChange={(value) =>
                                onChange("interfereWithOther", value)
                            }
                        />
                        <label className="mx-2">
                            {IntakeFormTextEnum.Symptoms_Other[selectedLanguage]}
                        </label>
                        {interfereWithOther ? (
                            <FormField
                                ref={(formField) => formFields.push(formField)}
                                required
                                validateOn={
                                    readOnly ? true : interfereWithOtherDetail
                                }
                            >
                                <TextInput
                                    className="mx-2"
                                    value={interfereWithOtherDetail}
                                    disabled={readOnly}
                                    onChange={(value) =>
                                        onChange("interfereWithOtherDetail", value)
                                    }
                                />
                            </FormField>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default SymptomsInterfereWith;