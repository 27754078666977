import React, {Component} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import DateUtil from "../../../../utils/DateUtil";
import EnumGiftStatus from "./enums/EnumGiftStatus";
import MoneyUtil from "../../../../utils/MoneyUtil";
import dialog from "../../../../components/dialog/Dialog";
import CreditGiftDetail from "./CreditGiftDetail";
import PhoneNumberUtil from "../../../../utils/PhoneNumberUtil";

export default class CreditGiftDataTable extends Component {

    render() {
        const {data} = this.props;

        return (
            <DataTable value={data}
                       dataKey="id"
                       emptyMessage="No records found.">
                <Column header="First Name" field="firstName"/>
                <Column header="Last Name" field="lastName"/>
                <Column header="E-Mail" field="email" className="text-break"/>
                <Column header="Phone" field="phone" body={gift => this.phoneTemplate(gift)}/>
                <Column header="Sent Time" field="sentTime" body={gift => this.sentTimeTemplate(gift)}/>
                <Column header="Status" field="status" body={gift => this.statusTemplate(gift)}/>
                <Column header="Code" field="giftCode" body={gift => this.codeTemplate(gift)}/>
                <Column header="Validity" field="credit.validityDate" body={gift => this.validityTemplate(gift)}/>
                <Column header="Discount" field="credit.discountRate" body={gift => this.discountTemplate(gift)}/>
                <Column header="Activation Time" field="redeemTime" body={gift => this.redeemTimeTemplate(gift)}/>
                <Column header="Sent Amount" field="credit.amount" body={gift => this.amountTemplate(gift)}/>
            </DataTable>
        )
    }

    phoneTemplate(gift) {
        return PhoneNumberUtil.formatInternational(gift.phone);
    }

    sentTimeTemplate(gift) {
        return DateUtil.formatDateTime12H(gift.sentTime);
    }

    statusTemplate(gift) {
        const statusEnum = EnumGiftStatus[gift.status];
        return <span className={statusEnum.colorClass}>{statusEnum.name}</span>
    }

    codeTemplate(gift) {
        return <span className="link" onClick={() => this.showGiftDetail(gift)}>{gift.giftCode}</span>
    }

    showGiftDetail(gift) {
        dialog({
            title: "",
            disableBackdropClick: false,
            component: <CreditGiftDetail creditGiftForm={gift} style={{width: 400}}/>
        });
    }

    validityTemplate(gift) {
        return DateUtil.formatDate(gift.credit.validityDate);
    }

    discountTemplate(gift) {
        return <span>{gift.credit.discountRate}<small>%</small></span>
    }

    redeemTimeTemplate(gift) {
        return DateUtil.formatDateTime12H(gift.redeemTime);
    }

    amountTemplate(gift) {
        const currency = MoneyUtil.getCurrency(gift.credit.country);
        return <span>{MoneyUtil.format(gift.credit.balance, currency)}<small>{currency}</small></span>
    }
}