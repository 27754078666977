import React, {Component} from "react";
import {Button, Col, Container, Row} from "react-bootstrap";
import RemotingService from "../../../../../services/remoting-service/RemotingService";
import DialogActions from "@material-ui/core/DialogActions";
import AttachmentUtils from "./AttachmentUtils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FileUtil from "../../../../../utils/FileUtil";
import ActionMenu from "../../../../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../../../../components/action-menu/ActionMenuItem";
import ViewIcon from "../../../../../components/action-menu/icons/ViewIcon";
import DeleteIcon from "../../../../../components/action-menu/icons/DeleteIcon";
import DateUtil from "../../../../../utils/DateUtil";
import PropTypes from "prop-types";
import {noop} from "lodash-es";
import MedicalReportTypeEnum from "../../report/MedicalReportTypeEnum";

export default class AttachmentModal extends Component {

    static defaultProps = {
        files: [],
        maxNumberOfFilesInRow: 3,
        saveAction: noop,
        visitId: null,
        showReports: true
    };

    static propTypes = {
        files: PropTypes.array,
        maxNumberOfFilesInRow: PropTypes.number,
        saveAction: PropTypes.func,
        visitId: PropTypes.number,
        showReports: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            medicalReports: [],
            files: [...this.props.files]
        }
    }

    componentDidMount() {
        if (this.props.showReports) {
            this.retrieveMedicalReports();
        }
    }

    retrieveMedicalReports() {
        const visitId = this.props.visitId;

        RemotingService.getRemoteCall(`api/medical-report/${visitId}`, null,
            (reportMetaData) => {
                this.setState({
                    medicalReports: reportMetaData
                        .filter(r => r.pdfPath)
                        .map(r => {
                            return {
                                uuid: r.pdfPath,
                                date: r.lastGenerationTime,
                                name: MedicalReportTypeEnum[r.type].name + '.pdf'
                            }
                        })
                });
            });
    }

    save() {
        this.props.saveAction(this.state.files, () => this.props.close());
    }

    viewFile(file) {
        AttachmentUtils.downloadAsBlob(file.uuid, blob => {
            AttachmentUtils.viewFile({fileName: file.name, blob: blob, id: 'attachmentViewer'});
        });
    }

    renderAttachments(files, enableDelete = true) {
        const maxNumberOfFilesInRow = this.props.maxNumberOfFilesInRow;

        return (files.map((file, index) => {
                    return <>
                        <div className={"col-4"}>
                            <div className={"container"} style={{marginBottom: '10px', fontSize: 11}}>
                                <div className={"row"}>
                                    <span style={{margin: 'auto'}}>
                                        <ActionMenu icon={<FontAwesomeIcon icon={["fas", FileUtil.getFileIconName(file.name)]} size={"3x"}/>}>
                                            <ActionMenuItem text="View" icon={<ViewIcon/>} onClick={() => this.viewFile(file)}
                                                            disabled={!AttachmentUtils.canView(file.name)}/>
                                            <ActionMenuItem text="Download"
                                                            icon={<FontAwesomeIcon icon={["fas", "file-download"]}/>}
                                                            onClick={() => AttachmentUtils.download(file)}/>
                                            {enableDelete === true ? <ActionMenuItem text="Delete" icon={<DeleteIcon/>} onClick={() => {
                                                files.splice(index, 1);
                                                this.forceUpdate();
                                            }}/> : <></>}
                                        </ActionMenu>
                                    </span>
                                </div>
                                <div className={"row"}>
                                    <span style={{margin: 'auto', overflowWrap: 'anywhere'}}>{file.name}</span>
                                </div>
                                <div className={"row"}>
                                    <span style={{margin: 'auto'}}>{DateUtil.formatDateMMM(file.date)}</span>
                                </div>
                            </div>
                        </div>
                        {(index + 1) % maxNumberOfFilesInRow === 0 && <div className="w-100"></div>}
                    </>;
                }
            )
        );
    }

    render() {
        return (
            <>
                <Container className={"InputModal"} style={{width: 500}}>
                    {
                        this.props.showReports &&
                        [
                            <Row style={{padding: '10px'}}>
                                <b>Provider Generated Reports</b>
                            </Row>,
                            <Row style={{padding: '10px'}}>
                                {this.renderAttachments(this.state.medicalReports, false)}
                            </Row>
                        ]
                    }
                    <Row style={{padding: '10px'}}>
                        <b>Attachments</b>
                    </Row>
                    <Row style={{padding: '10px'}}>
                        {this.renderAttachments(this.state.files)}
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <div style={{marginTop: "15px"}}>
                                <label className={"attachment-link"}>
                                    <input type="file"
                                           id="upload-attachment"
                                           accept=".jpg, .jpeg, .png, .pdf, .xls, .xlsx, .doc, .docx, .txt"
                                           onChange={(e) => {
                                               if (e == null
                                                   || e.target == null
                                                   || e.target.files == null
                                                   || e.target.files[0] == null) {
                                                   return;
                                               }
                                               let fileName = e.target.files[0].name;
                                               RemotingService.uploadFile(e.target.files[0], (response, err) => {
                                                   if (err != null) {
                                                       return;
                                                   }
                                                   this.state.files.push({
                                                       name: fileName,
                                                       uuid: response.data,
                                                       date: new Date()
                                                   });
                                                   this.forceUpdate();
                                               });
                                           }}/>
                                    + Add Attachment
                                </label>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <DialogActions>
                    <Button variant="success" onClick={() => this.save()}>Save</Button>
                </DialogActions>
            </>
        )
    }

}
