import React, {Component} from "react";
import {DialogActions} from "@material-ui/core";
import {Button} from "react-bootstrap";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import AuthService from "../../../../services/auth-service/AuthService";
import CreditGiftDetail from "./CreditGiftDetail";
import SockJsClient from "react-stomp";


export default class RedeemCreditGiftModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            credit: null,
            amount: null,
            validityDate: null,
            editable: AuthService.isFromManagement()
        };
    }

    onCreditGiftReceived(creditGiftForm) {
        this.setState({
            creditGiftForm,
            amount: creditGiftForm.credit.balance,
            validityDate: creditGiftForm.credit.validityDate
        });
    }

    redeem() {
        const params = {
            patientId: this.props.patientId,
            secret: this.state.creditGiftForm.secret,
            amount: this.state.amount,
            validityDate: this.state.validityDate
        }

        RemotingService.postRemoteCall(`/api/credit/redeem-gift`, params, () => {
            this.props.close();
            this.props.onRedeemed();
        });
    }

    giftFormChanged(amount, validityDate) {
        this.setState({amount, validityDate});
    }

    render() {
        const {creditGiftForm} = this.state;
        const creditGiftFormReceived = !!creditGiftForm;

        return (
            <>
                {creditGiftFormReceived ?
                    <div style={{width: 400}}>
                        <CreditGiftDetail creditGiftForm={this.state.creditGiftForm}
                                          editable={this.state.editable}
                                          onEdit={(amount, validityDate) => this.giftFormChanged(amount, validityDate)}/>
                        <DialogActions>
                            <Button variant="success" onClick={() => this.redeem()}>Redeem</Button>
                        </DialogActions>
                    </div>
                    :
                    <WaitingQR onCreditGiftReceived={creditGiftForm => this.onCreditGiftReceived(creditGiftForm)}/>
                }
            </>
        )
    }

}

class WaitingQR extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: AuthService.getUser(),
            dotCount: 0
        };
    }

    componentDidMount() {
        setInterval(() => this.setState({dotCount: (this.state.dotCount+1)%4}), 1000);
    }

    render() {
        const {user, dotCount} = this.state;

        return (
            <div className="text-center" style={{width: 300}}>
                <SockJsClient
                    url={RemotingService.getApiBaseUrl() + "websocket"}
                    topics={[`/topic/credit-gift/${user.email}`]}
                    onMessage={msg => this.props.onCreditGiftReceived(msg.body.data)}
                    ref={client => {this.clientRef = client}}
                />
                Waiting for QR read{'.'.repeat(dotCount)}
            </div>
        )
    }
}