import Enum from "../../../../../../enums/Enum";

const TypeEnum = new Enum({
    CERVICAL: ("CERVICAL"),
    THORACIC: ("THORACIC"),
    LUMBAR: ("LUMBAR"),
    SACRAL: ("SACRAL"),
    REFLEX_LEFT: ("REFLEX_LEFT"),
    REFLEX_RIGHT: ("REFLEX_RIGHT"),
    CRANIAL_NERVES: ("CRANIAL_NERVES"),
    UPPER_LIMB: ("UPPER_LIMB"),
    LOWER_LIMB: ("LOWER_LIMB"),
});

export default TypeEnum;