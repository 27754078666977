import React, {Component} from "react";
import DateUtil from "../../../../../utils/DateUtil";
import EligibilityStatusEnum from "../../../../insurance/enums/EligibilityStatusEnum";
import DirectBillingStatus from "../../../../insurance/enums/DirectBillingStatus";
import RemotingService from "../../../../../services/remoting-service/RemotingService";
import ProcedureViewHistory from "./ProcedureViewHistory";
import {isEmpty} from "../../../../../utils/ArrayUtil";

export default class BillingViewHistory extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.retrieveInsuranceApproval();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.visit.id !== this.props.visit.id) {
            this.retrieveInsuranceApproval();
        }
    }

    retrieveInsuranceApproval() {
        RemotingService.getRemoteCall('api/insurance/coordinator/visit', {visitId: this.props.visit.id}, insuranceApproval => {
            this.setState({insuranceApproval});
        });
    }

    render() {
        const {directBillingProcedures, selfPayProcedures} = this.props.diagnosis;
        const {insuranceApproval} = this.state;
        let insuranceRecord = undefined;
        let directBilling = undefined;
        let externalReferral = undefined;

        if (insuranceApproval) {
            insuranceRecord = insuranceApproval.insuranceRecord;
            if (insuranceRecord) {
                if ('DIRECT_BILLING' === insuranceRecord.type) {
                    directBilling = insuranceRecord;
                }

                if ('EXTERNAL_REFERRAL' === insuranceRecord.type) {
                    externalReferral = insuranceRecord;
                }
            }
        }

        if (!insuranceApproval && isEmpty(directBillingProcedures) && isEmpty(selfPayProcedures)) {
            return <div className="pl-3">No information available</div>
        }

        return (
            <>
                {insuranceApproval &&
                <div className="pl-3">
                    <div className="row">
                        <div className={"col-6"}>
                            <div className="row no-padding no-margin">
                                <div className={"col-3 dim14-form-label pl-0"}>
                                    Provider:
                                </div>
                                <div className={"col-9"}>
                                    {directBilling?.referral?.providerName || insuranceApproval?.provider}
                                </div>
                            </div>
                        </div>
                        <div className={"col-6"}>
                            <div className="row no-padding no-margin">
                                <div className={"col-5 dim14-form-label"}>
                                    Referring Physician:
                                </div>
                                <div className={"col-7"}>
                                    {directBilling ? directBilling.referral?.referrerName : externalReferral? externalReferral.referredBy : ''}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-sm-4 dim14-form-label"}>Insurance Status:</div>
                        <div className={"col-sm-8 overflow-wrap-anywhere"}>
                            {EligibilityStatusEnum[insuranceApproval.eligibilityStatus]?.name}
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-sm-4 dim14-form-label"}>Reference No:</div>
                        <div className={"col-sm-8 overflow-wrap-anywhere"}>
                            {insuranceRecord?.referenceNo}
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-sm-4 dim14-form-label"}>Diagnosis:</div>
                        <div className={"col-sm-8 overflow-wrap-anywhere"}>
                            <span>{directBilling?.diagnosisItem?.code} {directBilling?.diagnosisItem?.description}</span>
                            <span>{externalReferral?.icd?.code} {externalReferral?.icd?.description}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-sm-4 dim14-form-label"}>Claim Status:</div>
                        <div className={"col-sm-8 overflow-wrap-anywhere"}>
                            {directBilling && 'Direct Billing: '}
                            {externalReferral && 'External Referral: '}
                            {insuranceRecord && insuranceRecord.status ? DirectBillingStatus[insuranceRecord.status].name : ''}
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-sm-4 dim14-form-label"}>Sessions:</div>
                        <div className={"col-sm-8 overflow-wrap-anywhere"}>
                            {insuranceRecord ?
                                (insuranceRecord.usedAppointmentIds.indexOf(this.props.visit.appointment.id) + 1) +
                                '/' + insuranceRecord.noOfSessions : ''}
                        </div>
                    </div>
                    <div className="row">
                        <div className={"col-sm-4 dim14-form-label"}>Validity:</div>
                        <div className={"col-sm-8 overflow-wrap-anywhere"}>
                            {insuranceRecord && insuranceRecord.validUntil ?
                                DateUtil.formatDate(insuranceRecord.validUntil, 'Do MMM YYYY') : ''}
                        </div>
                    </div>
                </div>
                }
                <div>
                    {directBillingProcedures && directBillingProcedures.length > 0 &&
                    <ProcedureViewHistory billType="Direct Billing"
                                          procedures={directBillingProcedures}
                                          visit={this.props.visit}
                                          insurance={this.props.insurance}/>
                    }
                    {selfPayProcedures && selfPayProcedures.length > 0 &&
                    <ProcedureViewHistory billType="Self Pay"
                                          procedures={selfPayProcedures}
                                          visit={this.props.visit}
                                          insurance={this.props.insurance}/>
                    }
                </div>
            </>
        );
    }
}
