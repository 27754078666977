import React, {Component} from "react";
import _uniqueId from "lodash/uniqueId";
import {DateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {DateTimeInputToolbar} from "./DateTimeInputToolbar"
import moment from "moment";
import "./DateTimeInput.css";
import MomentUtils from "@date-io/moment";

export default class DateTimeInput extends Component {

    constructor(props) {
        super(props);
        if (props.dateFormat) {
            this.dateFormat = props.dateFormat;
        } else {
            this.dateFormat = "DD-MMM-YYYY hh:mm A";
        }

        if (props.id) {
            this.id = props.id;
        } else {
            this.id = _uniqueId("dim14-date-id-");
        }
    }

    handleOnChange(date) {
        this.props.onChange(moment(date).utc(true));
    }

    render() {
        const {id, onChange, ...rest} = this.props;
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker
                    id={this.id}
                    className="dim14-datepicker"
                    variant="inline"
                    minutesStep={5}
                    autoOk="true"
                    format={this.dateFormat}
                    inputVariant="outlined"
                    ToolbarComponent={DateTimeInputToolbar}
                    onChange={date => this.handleOnChange(date)}
                    {...rest}
                />
            </MuiPickersUtilsProvider>);
    }
}

