import React from "react";
import moment from "moment";

export class Timer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {now: moment(), end: this.props.end};
    }

    incrementTimer = () => {
        setTimeout(() => {
            if (this.state.now.isBefore(this.state.end)) {
                this.setState({now: moment()}, () => {
                    this.incrementTimer();
                })
            }
        }, 1000);
    }

    componentDidMount() {
        this.incrementTimer();
    }

    render() {
        const {end, now} = this.state;
        const diffInSeconds = end.diff(now, 'seconds');
        const minutes = Math.floor(diffInSeconds / 60);
        const seconds = diffInSeconds % 60;

        return diffInSeconds <= 0 ?
            this.props.expiredText || 'Expired' :
            this.props.validText || 'Valid for ' + (minutes ? minutes + ' mins' : '') + (seconds ? ' ' + seconds + ' secs' : '');
    }

}
