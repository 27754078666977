import React, {Component} from 'react';
import IntakeFormTextEnum from "../enum/IntakeFormTextEnum";
import {YesNoRadioInput} from "../../public/YesNoRadioInput";

class PublicIntakeFormCurrentHealth extends Component {
    render() {
        const {
            form, selectedLanguage, enumLabel, yesNoDescribeFieldEqualWidth,
            validatedTextField, onChange
        } = this.props;

        return (
            <>
                <h6 className="my-3">{IntakeFormTextEnum.Are_You_Currently_Do_You_Have[selectedLanguage]}</h6>
                {yesNoDescribeFieldEqualWidth(
                    form,
                    "pregnantNursing",
                    IntakeFormTextEnum.Pregnant_Nursing[selectedLanguage],
                    enumLabel
                )}
                {yesNoDescribeFieldEqualWidth(
                    form,
                    "historyOfTrauma",
                    IntakeFormTextEnum.History_of_Trauma[selectedLanguage],
                    enumLabel
                )}
                {yesNoDescribeFieldEqualWidth(
                    form,
                    "oftenBotheredByLittleInterest",
                    IntakeFormTextEnum.Little_Interest[selectedLanguage],
                    enumLabel
                )}

                {yesNoDescribeFieldEqualWidth(
                    form,
                    "dietaryNutritionalConcerns",
                    IntakeFormTextEnum.Dietary_or_Nutritional_Concerns[selectedLanguage],
                    enumLabel
                )}
                <div className="row">
                    <div class="col-8 d-flex justify-content-start align-items-center">
                        <label
                            className="col-form-label">{IntakeFormTextEnum.Have_Children[selectedLanguage]}</label>
                    </div>
                    <div class="col d-flex align-items-center">
                        <YesNoRadioInput
                            value={form.haveChildren}
                            onValueChange={(value) => {
                                onChange("haveChildren", value);
                            }}
                            enumLabel={enumLabel}
                        />
                    </div>
                </div>
                {"Yes" === form.haveChildren && (
                    <div className="row">
                        <div class="container-fluid p-0">
                            <div class="panel bg-grey">
                                <div class="form-group">
                                    {validatedTextField(form, "howManyChildren")}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {yesNoDescribeFieldEqualWidth(
                    form,
                    "fearOfNeedles",
                    IntakeFormTextEnum.Fear_of_needles[selectedLanguage],
                    enumLabel
                )}
                {yesNoDescribeFieldEqualWidth(
                    form,
                    "oftenFeelingDown",
                    IntakeFormTextEnum.Depressed_or_Hopeless[selectedLanguage],
                    enumLabel
                )}
                {yesNoDescribeFieldEqualWidth(
                    form,
                    "underPhysicalEmotionalAbuse",
                    IntakeFormTextEnum.Under_Physical_Emotional_Abuse[selectedLanguage],
                    enumLabel
                )}
                {yesNoDescribeFieldEqualWidth(
                    form,
                    "experienceSexualDysfunction",
                    IntakeFormTextEnum.Experience_Sexual_Dysfunction[selectedLanguage],
                    enumLabel
                )}
            </>
        );
    }
}

export default PublicIntakeFormCurrentHealth;