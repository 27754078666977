import MissingDataFirstVisit from "./missingpoints/missingdataenums/MissingDataFirstVisit";
import MissingDataFollowUp from "./missingpoints/missingdataenums/MissingDataFollowUp";
import MissingDataNewComplaint from "./missingpoints/missingdataenums/MissingDataNewComplaint";
import ObjectUtil from "../../../../utils/ObjectUtil";

class MissingDataChecker {

    getMissingPointDetailsForVisit = (visit) => {
        if(visit == null) {
            return [];
        }

        const missingMeta = this.getEnumAccordingToAppointmentType(visit.appointmentType);
        const totalCount = missingMeta.length;

        const missingItems = [];
        missingMeta.forEach(meta => {
            const val = ObjectUtil.get(visit, meta.path);
            if (meta.checker.isMissing(val)) {
                missingItems.push(meta);
            }
        });

        return {missingItems, totalCount};
    }

    getMissingPointsForVisit = (visit) => {
        if(visit == null) {
            return [];
        }

        const missingMeta = this.getEnumAccordingToAppointmentType(visit.appointmentType);
        const totalCount = missingMeta.length;
        let missingPointCount = 0;

        missingMeta.forEach(meta => {
            const val = ObjectUtil.get(visit, meta.path);
            if (meta.checker.isMissing(val)) {
                missingPointCount++;
            }
        });

        return [missingPointCount, totalCount];
    }

    getEnumAccordingToAppointmentType = (appointmentType) => {
        if (appointmentType === 'NEW_PATIENT') {
            return MissingDataFirstVisit;
        } else if (appointmentType === 'FOLLOW_UP') {
            return MissingDataFollowUp;
        } else if (appointmentType === 'NEW_COMPLAINT') {
            return MissingDataNewComplaint;
        } else {
            throw new Error('Unknown appointment type: ' + appointmentType);
        }
    }

}

export default new MissingDataChecker();