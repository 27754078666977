class DermatomesUtil {

    isDermatomesEntered(dermatomes) {
        return dermatomes.none
            || this.isArrayEntered(dermatomes.cervical)
            || this.isArrayEntered(dermatomes.thoracic)
            || this.isArrayEntered(dermatomes.lumbar)
            || this.isArrayEntered(dermatomes.sacral)
    }

    isMyotomesEntered(myotomes) {
        return this.isDermatomesEntered(myotomes);
    }

    isReflexesEntered(reflexes) {
        return reflexes.none
            || this.isArrayEntered(reflexes.reflexLeftSide)
            || this.isArrayEntered(reflexes.reflexRightSide)
            || this.isArrayEntered(reflexes.cranialNerves);
    }

    isNeuroDynamicsEntered(neurodynamics) {
        return this.isArrayEntered(neurodynamics.upperLimb) || this.isArrayEntered(neurodynamics.lowerLimb);
    }

    isArrayEntered = (array) => {
        if (!array) {
            return false;
        }

        for (let object of array) {
            if (
                (object.rightValue !== undefined && object.rightValue !== null) ||
                (object.leftValue !== undefined && object.leftValue !== null) ||
                (object.rightBoolValue !== undefined && object.rightBoolValue !== null) ||
                (object.leftBoolValue !== undefined && object.leftBoolValue !== null) ||
                (object.details !== undefined && object.details !== null && object.details.length !== 0)
            ) {
                return true;
            }
        }

        return false;
    }
}

export default new DermatomesUtil();