import Enum from "../../../enums/Enum";

const EnumDayOfWeek = new Enum({
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday",
    SATURDAY: "Saturday",
    SUNDAY: "Sunday"
});

export default EnumDayOfWeek;