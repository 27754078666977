export default class StaffRoomMap {

    constructor(map) {
        this.map = map || {};
    }

    put(staffId, dateStr, room) {
        this.map[this.generateKey(staffId, dateStr)] = room;
    }

    putAll(staffId, roomMapByDate) {
        for (let dateStr in roomMapByDate) {
            if (roomMapByDate.hasOwnProperty(dateStr)) {
                const room = roomMapByDate[dateStr];
                this.map[staffId + "_" + dateStr] = room;
            }
        }
    }

    get(staffId, dateStr) {
        return this.map[this.generateKey(staffId, dateStr)];
    }

    clear() {
        this.map = {};
    }

    generateKey(staffId, dateStr) {
        return staffId + "_" + dateStr;
    }

    clone() {
        return new StaffRoomMap({...this.map});
    }

}