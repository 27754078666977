import React, {Component} from "react";
import '../../EMR.css';
import TextInput from "../../../../../components/text-input/TextInput";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";
import StringUtil from "../../../../../utils/StringUtil";
import GreenSwitchWithEmptyMessage from "../../../../../components/switch/GreenSwitchWithEmptyMessage";
import TypeEnum from "../enums/respiratorypattern/TypeEnum";
import AreaEnum from "../enums/respiratorypattern/AreaEnum";
import FindingEnum from "../enums/respiratorypattern/FindingEnum";

export default class RespiratoryPatternModal extends Component {

    state = {
        type: null,
        area: null,
        finding: null,
        detail: null
    }

    constructor(props) {
        super(props);
    }

    render() {
        let divs = this.getDivs();

        return (
            <div className={"emr-modal content-row emr-background"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px"}}>
                    <div>
                        <div style={{display: "flex"}}>
                            <div className="content-label" style={{margin:"auto", marginLeft:"0px"}}>Respiratory Patterns</div>
                            <GreenSwitchWithEmptyMessage
                                emptyMessage={""}
                                checked={this.props.assessment.respiratoryPattern != null && this.props.assessment.respiratoryPattern.items.length !=0 }
                                onChange={() => {
                                    if(this.props.assessment.respiratoryPattern == null) {
                                        this.props.assessment.respiratoryPattern = {};
                                        this.props.assessment.respiratoryPattern.items = [];
                                    } else {
                                        this.props.assessment.respiratoryPattern = null;
                                    }
                                    this.forceUpdate();
                                }}/>
                        </div>
                        <div className={"row no-margin"}>
                            <div className={"col-3"}>
                                <div className="font-weight-bold">Type</div>
                            </div>
                            <div className={"col-3"}>
                                <div className="font-weight-bold">Area</div>
                            </div>
                            <div className={"col-2"}>
                                <div className="font-weight-bold">Findings</div>
                            </div>
                            <div className={"col-3"}>
                                <div className="font-weight-bold">Details</div>
                            </div>
                        </div>
                        <div className={"row no-margin"}>
                            <div className={"col-3"}>
                                <Select
                                    className={"emr-white-select-container"}
                                    classNamePrefix={"emr-white-select"}
                                    placeholder={"Type"}
                                    value={this.state.type != null ?
                                        {value: TypeEnum[this.state.type].key,
                                            label: TypeEnum[this.state.type].name}: null}
                                    onChange={(event) => {
                                        let r = event.value;
                                        this.setState({ type: r });
                                    }}
                                    options={TypeEnum.toArray().map(v=> {
                                            return {value: v.key, label:v.name}
                                        }
                                    )}
                                />
                            </div>
                            <div className={"col-3"}>
                                <Select
                                    className={"emr-white-select-container"}
                                    classNamePrefix={"emr-white-select"}
                                    placeholder={"Area"}
                                    value={this.state.area != null ?
                                        {value: AreaEnum[this.state.area].key,
                                            label: AreaEnum[this.state.area].name}: null}
                                    onChange={(event) => {
                                        let r = event.value;
                                        this.setState({ area: r });
                                    }}
                                    options={AreaEnum.toArray().map(v=> {
                                            return {value: v.key, label:v.name}
                                        }
                                    )}
                                />
                            </div>
                            <div className={"col-2"}>
                                <Select
                                    className={"emr-white-select-container"}
                                    classNamePrefix={"emr-white-select"}
                                    placeholder={"Finding"}
                                    value={this.state.finding != null ?
                                        {value: FindingEnum[this.state.finding].key,
                                            label: FindingEnum[this.state.finding].name}: null}
                                    onChange={(event) => {
                                        let r = event.value;
                                        this.setState({ finding: r });
                                    }}
                                    options={FindingEnum.toArray().map(v=> {
                                            return {value: v.key, label:v.name}
                                        }
                                    )}
                                />
                            </div>
                            <div className={"col-3"}>
                                <TextInput value={this.state.detail || ''}
                                           placeholder={"Details"}
                                           onChange={value =>  this.setState({detail: value})}
                                           style={{fontSize: "14px", width: "100%"}}
                                />
                            </div>
                            <div className={"col-1"} style={{flexFlow: "row-reverse", display: "flex"}}>
                                <Button className={"emr-plus-button"}
                                        variant="success"
                                        disabled={this.state.type == null
                                                    || this.state.area == null
                                                    || this.state.finding == null}
                                        style={{fontSize:"12px"}}
                                        onClick={this.addRespiratoryPattern}>
                                    <FontAwesomeIcon icon={["fas", "plus"]}/>
                                </Button>
                            </div>
                        </div>
                        <br/>
                        <div className={"row no-margin"} style={{width: "100%", marginTop: "10px !important"}}>
                            <div className={"div-list-wrapper"}>
                                {divs}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    addRespiratoryPattern = () => {
        this.createRespiratoryPatternIfNecessary();
        this.props.assessment.respiratoryPattern.items.push({
            type: this.state.type,
            area: this.state.area,
            finding: this.state.finding,
            detail: this.state.detail,
        });
        this.setState({
            type: null,
            area: null,
            finding: null,
            detail: null
        });
    }

    createRespiratoryPatternIfNecessary() {
        if(this.props.assessment.respiratoryPattern == null) {
            this.props.assessment.respiratoryPattern = {};
            this.props.assessment.respiratoryPattern.items = [];
        }
    }

    getDivs = () => {
        if (this.props.assessment.respiratoryPattern == null
            || this.props.assessment.respiratoryPattern.items == null
            || this.props.assessment.respiratoryPattern.items.length == 0) {
            return;
        }
        let divs = [];
        this.props.assessment.respiratoryPattern.items.forEach((item, index) => divs.push(
            <div className={"div-list-item"} key={"respiratoryPatternItem" + index}>
                <div>
                    {TypeEnum[item.type].name}, {AreaEnum[item.area].name}, {FindingEnum[item.finding].name}{StringUtil.isNullOrEmpty(item.detail) ? "" : (", " + item.detail)}
                </div>
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize:"10px", marginLeft: "2px"}}
                        onClick={() => {
                            this.props.assessment.respiratoryPattern.items.splice(index, 1);
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas","times"]}/>
                </Button>
            </div>
        ));
        return divs;
    }
}