import Enum from "../../../../enums/Enum";

const ClaimStatus = new Enum({
    PENDING: {name: "Pending"},
    SUBMITTED: {name: "Submitted"},
    REJECTED: {name: "Rejected"},
    APPROVED: {name: "Approved"}
});

ClaimStatus.remittanceStatus = [ClaimStatus.APPROVED, ClaimStatus.REJECTED];

export default ClaimStatus;
