import * as ArrayUtils from "../../../../../utils/ArrayUtil";

const isActivityLimitationNull = (activityLimitation) => {
    return (activityLimitation == null
        || (activityLimitation.items == null && activityLimitation.none == false)
        || (activityLimitation.items.length == 0 && activityLimitation.none == false));
}

const isAllergyNull = (allergy) => {
    return allergy == null;
}

const checkSymptomDataAvailability = (symptomData) => {
    return (symptomData.currentPrevious == null
        && symptomData.onset == null && symptomData.onsetDetail == null
        && symptomData.description == null && symptomData.descriptionDetail == null
        && symptomData.frequency.length == 0 && symptomData.frequencyDetail == null
        && symptomData.etiology.length == 0 && symptomData.etiologyDetail == null
        && symptomData.durationType == null && symptomData.durationTime == null && symptomData.durationTimeEnum == null
        && symptomData.timing.length == 0 && symptomData.timingDetail == null
        && symptomData.character.length == 0 && symptomData.characterDetail == null
        && symptomData.aggravatingFactor.length == 0 && symptomData.aggravatingFactorDetail == null
        && symptomData.redFlag.length == 0 && symptomData.redFlagDetail == null
        && symptomData.feel.length == 0);
}

const checkSymptomsDataAvailability = (symptomData) => {
    return (symptomData.pain == null && symptomData.painDetail == null
        && symptomData.swelling == null && symptomData.swellingDetail == null
        && symptomData.referral == null
        && symptomData.nature == null && symptomData.natureDetail == null);
}

const checkSymptomsPainReferralDataAvailability = (symptomData) => {
    return (symptomData.pain == null && symptomData.painDetail == null
        && symptomData.referral == null);
}


const isComplaintNull = (complaint) => {
    return complaint == null || checkSymptomDataAvailability(complaint)
}

const isSymptomsNull = (complaint) => {
    return complaint == null || checkSymptomsDataAvailability(complaint)
}

const isSymptomsPainReferralNull = (complaint) => {
    return complaint == null || checkSymptomsPainReferralDataAvailability(complaint)
}

const isDiagnosisNull = (diagnosis) => {
    return (diagnosis.diagnosisGroups == null
        || diagnosis.diagnosisGroups.length == 0);
}

const isDiagnosisItemsNull = (diagnosisItems) => {
    return (diagnosisItems == undefined
        || diagnosisItems == null
        || diagnosisItems.length == 0);
}

const isDischargePlanInstructionsNull = (plan) => {
    return !plan || plan == null || (plan.homeProgram == null && plan.recommendations == null);
}

const isDischargePlanReadinessNull = (plan) => {
    return !plan || plan == null;
}

const isDischargePlanNull = (plan) => {
    return !plan || plan == null || plan.dateOfDischarge == null;
}

const isFamilyHistoryNull = (familyHistory) => {
    return (familyHistory == null
        || (familyHistory.items == null && familyHistory.none == false)
        || (familyHistory.items.length == 0 && familyHistory.none == false));
}

const checkLabImagingDataAvailability = (labImaging) => {
    return (labImaging.labs == null && labImaging.labDetail == null
        && labImaging.radiology == null && labImaging.radiologyDetail == null
        && labImaging.reportStatus.length == 0 && labImaging.reportDetail == null && labImaging.none == false);
}

const isLabImagingNull = (labsImaging) => {
    return labsImaging == null || checkLabImagingDataAvailability(labsImaging)
}

const isMedicalHistoryNull = (medicalHistory) => {
    return (medicalHistory == null
        || (medicalHistory.diagnosisItems == null && medicalHistory.none == false)
        || (medicalHistory.diagnosisItems && medicalHistory.diagnosisItems.length == 0 && medicalHistory.none == false));
}

const isVaccinationHistoryNull = (medicalHistory) => {
    return (medicalHistory == null
        || (medicalHistory.vaccinationHistory == null && medicalHistory.vaccinationHistoryDetail == null));
}

const isMskDiagnosisNull = (mskDiagnosis) => {
    return (!mskDiagnosis?.items
        || mskDiagnosis.items.length === 0);
}

const isPreviousTreatmentReceivedNull = (treatmentReceived) => {
    return (treatmentReceived == null
        || (treatmentReceived.conservative == null && treatmentReceived.none == false)
        || (treatmentReceived.conservative.length == 0 && treatmentReceived.none == false))
}

const isResponseToTreatmentNull = (response) => {
    return (!response || response == null);
}

const isSurgicalHistoryNull = (surgicalHistory) => {
    return (surgicalHistory == null
        || (surgicalHistory.items == null && surgicalHistory.none == false)
        || (surgicalHistory.items.length == 0 && surgicalHistory.none == false));
}

const isTreatmentInfoNull = (treatmentInfo) => {
    return (!treatmentInfo || treatmentInfo == null);
}

const isTreatmentResultNull = (results) => {
    return (!results
        || results == null
        || results.length == 0);
}

const isReviewOfSystemNull = (reviewOfSystem) => {
    return reviewOfSystem == null || (
        ArrayUtils.isEmpty(reviewOfSystem.cardiovascularCirculatory)
        && ArrayUtils.isEmpty(reviewOfSystem.respiratory)
        && ArrayUtils.isEmpty(reviewOfSystem.headNeckEyes)
        && ArrayUtils.isEmpty(reviewOfSystem.ent)
        && ArrayUtils.isEmpty(reviewOfSystem.skinHairNails)
        && ArrayUtils.isEmpty(reviewOfSystem.lymphaticImmune)
        && ArrayUtils.isEmpty(reviewOfSystem.endocrine)
        && ArrayUtils.isEmpty(reviewOfSystem.gastrointestinal)
        && ArrayUtils.isEmpty(reviewOfSystem.genitourinary)
        && ArrayUtils.isEmpty(reviewOfSystem.musculoskeletal)
        && ArrayUtils.isEmpty(reviewOfSystem.neurological) && reviewOfSystem.none == false
    );
}

const isReferralsNull = (referral) => {
    return (referral == null
        || referral.internals == null
        || referral.interventions == null
        || (referral.internals.length == 0 && referral.interventions.length == 0));
}

const isReferralInterventionsNull = (referral) => {
    return (referral == null
        || referral.interventions == null
        || referral.interventions.length == 0);
}

const isAssessmentNull = (assessment) => {
    return (
        (isAssessmentEntityNull(assessment.observation))
        && (isAssessmentEntityNull(assessment.palpation))
        && (isAssessmentEntityNull(assessment.muscleGroup))
        && (isAssessmentEntityNull(assessment.individualMuscle))
        && (isAssessmentEntityNull(assessment.dermatomes))
        && (isAssessmentEntityNull(assessment.mytomes))
        && (isAssessmentEntityNull(assessment.rangeOfMotion))
    );
}

const isAssessmentObservationPalpationNull = (assessment) => {
    return (
        (isAssessmentEntityNull(assessment.observation))
        && (isAssessmentEntityNull(assessment.palpation))
    );
}

const isAssessmentEntityNull = (entity) => {
    return (!entity ||
        (ArrayUtils.isEmpty(entity.items) && entity.none == false)
    );
}

const isMedicationUseNull = (medicationUse) => {
    return (medicationUse == null
        || (medicationUse.items == null && medicationUse.none == false)
        || (medicationUse.items.length == 0 && medicationUse.none == false))
}

const isPrescriptionsNull = (prescriptions) => {
    return ArrayUtils.isEmpty(prescriptions);
}

const isProcedureNull = (procedure) => {
    return (procedure == null ||
        (procedure.type == null && procedure.areaOfFocus == null && procedure.details == null))
}

const isOtherInjuriesNull = (otherInjuries) => {
    return (otherInjuries == null || checkOtherInjuryDataAvailability(otherInjuries))
}

const checkOtherInjuryDataAvailability = (otherInjury) => {
    return (otherInjury.mechanism.length == 0 && otherInjury.mechanismDetail == null
        && otherInjury.relatedInjuries == null && otherInjury.relatedInjuriesTime == null
        && otherInjury.type.length == 0 && otherInjury.typeDetail == null && otherInjury.none == false);

}

export default {
    isActivityLimitationNull, isAllergyNull, isComplaintNull, isSymptomsNull, isDiagnosisNull, isDiagnosisItemsNull,
    isDischargePlanInstructionsNull, isDischargePlanReadinessNull, isDischargePlanNull, isFamilyHistoryNull,
    isLabImagingNull, isMedicalHistoryNull, isMskDiagnosisNull, isPreviousTreatmentReceivedNull, isResponseToTreatmentNull,
    isSurgicalHistoryNull, isTreatmentInfoNull, isTreatmentResultNull, isReviewOfSystemNull, isReferralsNull,
    isReferralInterventionsNull, isAssessmentNull, isAssessmentEntityNull, isMedicationUseNull, isProcedureNull,
    isOtherInjuriesNull, isAssessmentObservationPalpationNull, isSymptomsPainReferralNull, isPrescriptionsNull,
    isVaccinationHistoryNull
};