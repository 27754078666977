import React from "react";

export class FormLine extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className="form-group row">
                <label className="col-3 col-form-label">{this.props.label}</label>
                <div className="col">{this.props.children}</div>
            </div>
        );
    }
}
