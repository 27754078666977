import Enum from "../../../enums/Enum";
import EnumGender from "../../../enums/EnumGender";

const EnumSalutation = new Enum({
    MR: {name: "Mr."},
    MRS: {name: "Mrs."},
    MISS: {name: "Miss."},
    MS: {name: "Ms."},
    DR: {name: "Dr."},
    PROF: {name: "Prof."}
});

EnumSalutation.getByGender = (gender) => {
    if (gender === "MALE" || gender === EnumGender.MALE) {
        return [
            EnumSalutation.MR,
            EnumSalutation.DR,
            EnumSalutation.PROF
        ];
    } else if (gender === "FEMALE" || gender === EnumGender.FEMALE) {
        return [
            EnumSalutation.MRS,
            EnumSalutation.MISS,
            EnumSalutation.MS,
            EnumSalutation.DR,
            EnumSalutation.PROF
        ];
    } else {
        return [];
    }
}

export default EnumSalutation;