import React, {Component} from "react";
import "../../VisitHistory.css"
import TypeEnum from "../../../assessment/enums/dermatomes/TypeEnum";
import NeurodynamicsUpperLimbEnum from "../../../assessment/enums/dermatomes/NeurodynamicsUpperLimbEnum";
import NeurodynamicsLowerLimbEnum from "../../../assessment/enums/dermatomes/NeurodynamicsLowerLimbEnum";
import PosNegRadioInput from "../../../../../../components/radio-input/PosNegRadioInput";

export default class NeurodynamicsViewHistory extends Component {
    constructor(props) {
        super(props);

        this.createNeurodynamicsTable = this.createNeurodynamicsTable.bind(this);
    }

    createNeurodynamicsTable() {
        let tableEntriesUpper = [];
        let tableEntriesLower = [];
        let headerState = {
            upperLimbHeader: false,
            lowerLimbHeader: false,
        };

        this.props.neurodynamics.items.forEach((item, index) => {
            if (item.type === TypeEnum.UPPER_LIMB) {
                if (!headerState.upperLimbHeader) {
                    tableEntriesUpper.push(
                        <tr key={"upperLimbHeaderNeuro" + index}
                            style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                            <th>Upper Limb</th>
                            <th>Right</th>
                            <th>Left</th>
                            <th>Details</th>
                        </tr>
                    );
                    headerState.upperLimbHeader = true;
                }
                tableEntriesUpper.push(
                    <tr key={"upperLimbTableNeuro" + index} style={{backgroundColor: "white"}}>
                        <td>{NeurodynamicsUpperLimbEnum[item.name]?.value}</td>
                        <td style={{position: "relative", minWidth: "80px"}}>
                            <PosNegRadioInput value={item.rightBoolValue} readOnly={true}/>
                        </td>
                        <td style={{position: "relative", minWidth: "80px"}}>
                            <PosNegRadioInput value={item.leftBoolValue} readOnly={true}/>
                        </td>
                        <td>{item.details}</td>
                    </tr>
                );
            } else if (item.type === TypeEnum.LOWER_LIMB) {
                if (!headerState.lowerLimbHeader) {
                    tableEntriesLower.push(
                        <tr key={"lowerLimbHeaderNeuro" + index}
                            style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                            <th>Lower Limb</th>
                            <th>Right</th>
                            <th>Left</th>
                            <th>Details</th>
                        </tr>
                    );
                    headerState.lowerLimbHeader = true;
                }
                tableEntriesLower.push(
                    <tr key={"lowerLimbTableNeuro" + index} style={{backgroundColor: "white"}}>
                        <td>{NeurodynamicsLowerLimbEnum[item.name]?.value}</td>
                        <td style={{position: "relative", minWidth: "80px"}}>
                            <PosNegRadioInput value={item.rightBoolValue} readOnly={true}/>
                        </td>
                        <td style={{position: "relative", minWidth: "80px"}}>
                            <PosNegRadioInput value={item.leftBoolValue} readOnly={true}/>
                        </td>
                        <td>{item.details}</td>
                    </tr>
                );
            }
        });

        return tableEntriesUpper.concat(tableEntriesLower);
    }

    render() {
        if (this.props?.neurodynamics?.items?.length > 0) {
            return (
                <table className={"visit-history-table"}>
                    <tbody>
                    {this.createNeurodynamicsTable()}
                    </tbody>
                </table>
            );
        } else {
            return <div className="pl-3">No information available</div>;
        }
    }
}