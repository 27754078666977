import RemotingService from "../remoting-service/RemotingService";
import {noop} from "lodash-es";

class LookupService {

    getNationalities(callback) {
        RemotingService.getRemoteCall('api/public/lookup/nationalities', null, (nationalities) => {
            callback(nationalities);
        }, noop, false);
    }

    getCountries(callback) {
        RemotingService.getRemoteCall('api/public/lookup/countries', null, (countries) => {
            callback(countries);
        }, noop, false);
    }

    getLanguages(callback) {
        RemotingService.getRemoteCall('api/public/lookup/languages', null, (languages) => {
            callback(languages);
        }, noop, false);
    }

    getReligions(callback) {
        RemotingService.getRemoteCall('api/lookup/religions', null, (religions) => {
            callback(religions);
        }, noop, false);
    }

    getMaritalStatuses(callback) {
        RemotingService.getRemoteCall('api/lookup/marital-statuses', null, (maritalStatuses) => {
            callback(maritalStatuses);
        }, noop, false);
    }

    getRelationshipTypes(callback) {
        RemotingService.getRemoteCall('api/public/lookup/relationship-types', null, (relationshipTypes) => {
            callback(relationshipTypes);
        }, noop, false);
    }

    getAllergyTypes(callback) {
        RemotingService.getRemoteCall('api/lookup/allergy-types', null, (allergyTypes) => {
            callback(allergyTypes);
        }, noop, false);
    }

    getAllergenTypes(callback) {
        RemotingService.getRemoteCall('api/lookup/allergen-types', null, (allergenTypes) => {
            callback(allergenTypes);
        }, noop, false);
    }

    getAllergenReactionTypes(callback) {
        RemotingService.getRemoteCall('api/lookup/allergen-reaction-types', null, (reactionTypes) => {
            callback(reactionTypes);
        }, noop, false);
    }

    getAllergySeverityTypes(callback) {
        RemotingService.getRemoteCall('api/lookup/allergy-severity-types', null, (severityTypes) => {
            callback(severityTypes);
        }, noop, false);
    }

    getGenders(callback) {
        RemotingService.getRemoteCall('api/lookup/genders', null, (genders) => {
            callback(genders);
        }, noop, false);
    }

    getEducationLevels(callback) {
        RemotingService.getRemoteCall('api/lookup/education-levels', null, (educationLevels) => {
            callback(educationLevels);
        }, noop, false);
    }

    getEmploymentStatuses(callback) {
        RemotingService.getRemoteCall('api/lookup/employment-statuses', null, (employmentStatuses) => {
            callback(employmentStatuses);
        }, noop, false);
    }

    getSmokingDevices(callback) {
        RemotingService.getRemoteCall('api/lookup/smoking-devices', null, (smokingDevices) => {
            callback(smokingDevices);
        }, noop, false);
    }

    getSmokingStatuses(callback) {
        RemotingService.getRemoteCall('api/lookup/smoking-statuses', null, (smokingStatuses) => {
            callback(smokingStatuses);
        }, noop, false);
    }

    getDrinkingStatuses(callback) {
        RemotingService.getRemoteCall('api/lookup/drinking-statuses', null, (drinkingStatuses) => {
            callback(drinkingStatuses);
        }, noop, false);
    }

    getSubstanceAbuseStatuses(callback) {
        RemotingService.getRemoteCall('api/lookup/substance-abuse-statuses', null, (substanceAbuseStatuses) => {
            callback(substanceAbuseStatuses);
        }, noop, false);
    }

    getMedicationRoutes(callback) {
        RemotingService.getRemoteCall('api/lookup/medication-routes', null, (routes) => {
            callback(routes);
        }, noop, false);
    }

    getMedicationUnits(callback) {
        RemotingService.getRemoteCall('api/lookup/medication-units', null, (routes) => {
            callback(routes);
        }, noop, false);
    }

    searchClinicalFindingSnomedCodes = (inputValue, callback, minimumSearchLength = 2) => {
        if (inputValue.length < minimumSearchLength) {
            return callback(null);
        }
        RemotingService.getRemoteCall('api/snomed/clinical-findings?key=' + inputValue, null,
            (response) => callback(response == null || response.length === 0 ? null : response)
        );
    }

    searchFamilyHistorySnomedCodes = (inputValue, callback, minimumSearchLength = 2) => {
        if (inputValue.length < minimumSearchLength) {
            return callback(null);
        }
        RemotingService.getRemoteCall('api/snomed/family-history?key=' + inputValue, null,
            (response) => callback(response == null || response.length === 0 ? null : response)
        );
    }
}

export default new LookupService();
