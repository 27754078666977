import Enum from "../../../../../../enums/Enum";

const GradeEnum = new Enum({
    Flicker_Of_Movement: {name: "1= Flicker of movement"},
    Gravity_Counterbalanced: {name: "2= Full range actively with gravity counterbalanced"},
    Against_Gravity: {name: "3= Actively against gravity"},
    Against_Some_Resistance: {name: "4= Actively against some resistance"},
    Against_Strong_Resistance: {name: "5= Actively against strong resistance"},
});

export default GradeEnum;