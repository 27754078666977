import {Component} from "react";
import PropTypes from "prop-types";

export default class MenuItem extends Component {

    static defaultProps = {
        label: '',
        icon: null,
        url: null,
        items: null,
        disabled: false,
        style: {},
        className: null,
        command: null,
        externalUrl: null,
        authority: null
    };

    static propTypes = {
        label: PropTypes.string,
        icon: PropTypes.string,
        url: PropTypes.string,
        items: PropTypes.array,
        disabled: PropTypes.bool,
        style: PropTypes.object,
        className: PropTypes.string,
        command: PropTypes.func,
        externalUrl: PropTypes.string,
        authority: PropTypes.string
    };

}
