import React, {Component} from "react";
import {FormField} from "../../../../components/form";
import {SelectInput, TextInput} from "../../../../components";
import EnumInvoiceCancellationReason from "../../../billing/enums/EnumInvoiceCancellationReason";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import {DialogActions} from "@material-ui/core";
import {Button} from "react-bootstrap";
import RemotingService from "../../../../services/remoting-service/RemotingService";

export default class CancelInvoiceModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cancelReason: "",
            cancelDetail: ""
        };
    }

    confirm() {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        const {invoice} = this.props;

        RemotingService.postRemoteCall(
            'api/billing/invoice/' + invoice.id
            + '/cancel?reason=' + this.state.cancelReason + '&detail=' + this.state.cancelDetail,
            null,
            (invoice) => {
                this.props.close();
                this.props.onSave(invoice);
            });
    }

    render() {
        const billedInvoice = this.props.invoice && 'BILLED' === this.props.invoice.status;
        const label = "You are about to cancel " + (billedInvoice ? 'a billed invoice.' : 'an invoice.') + " Are you sure?";
        this.formFields = [];

        return (
            <>
                <div className="container padding-10" style={{width: '600px'}}>
                    <div className="row mt-1">
                        {label}
                    </div>
                    {billedInvoice &&
                    <div className="row mt-1">
                        <div className="col-4 border">
                            <div>Reason</div>
                        </div>
                        <div className="col-8 border">
                            <FormField ref={formField => this.formFields.push(formField)}
                                       required
                                       validateOn={this.state.cancelReason}>
                                <SelectInput displayProperty="name" valueProperty="key" searchEnabled={false}
                                             value={this.state.cancelReason}
                                             options={EnumInvoiceCancellationReason.toArray()}
                                             onChange={(cancelReason) => this.setState({cancelReason})}/>
                            </FormField>
                        </div>
                    </div>
                    }
                    {(!billedInvoice || 'OTHER' === this.state.cancelReason) &&
                    <div className="row mt-1">
                        <div className="col-4 border">
                            <div>{billedInvoice ? 'Details' : 'Reason'}</div>
                        </div>
                        <div className="col-8 border">
                            <FormField ref={formField => this.formFields.push(formField)}
                                       required
                                       validateOn={this.state.cancelDetail}>
                                <TextInput value={this.state.cancelDetail}
                                           onChange={(value) => this.setState({cancelDetail: value})}/>
                            </FormField>
                        </div>
                    </div>
                    }
                </div>
                <DialogActions>
                    <Button variant="success" onClick={() => this.confirm()}>Confirm</Button>
                </DialogActions>
            </>
        );
    }

}