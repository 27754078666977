import React, {Component} from "react";
import RemotingService from "../../services/remoting-service/RemotingService";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import PatientUtil from "../../utils/PatientUtil";
import EnumBodySilhouetteSignTypes from "./BodySilhouette/EnumBodySilhouetteSignTypes";
import {Arrow, Circle, Cross} from "./BodySilhouette";
import StringUtil from "../../utils/StringUtil";
import {Wizard} from "../../components";
import moment from "moment";
import BodySilhouettePatientCanvas from "./BodySilhouette/BodySilhouettePatientCanvas";
import TreatmentsForThisConditionEnum from "./enum/TreatmentsForThisConditionEnum";
import ConsentFormPartsKwt from "./utils/ConsentFormPartsKwt";
import EtiologyEnum from "../emr/recordpage/subjective/enums/complaint/EtiologyEnum";
import IntakeFormHeader from "./intakeform/IntakeFormHeader";
import IntakeFormTextEnum from "./intakeform/enum/IntakeFormTextEnum";
import FormLanguages from "./enum/FormLanguages";
import IntakeFormPatientInformation from "./intakeform/IntakeFormPatientInformation";
import IntakeFormReferralInformation from "./intakeform/IntakeFormReferralInformation";
import IntakeFormContactInformation from "./intakeform/IntakeFormContactInformation";
import IntakeFormEmergencyContactInformation from "./intakeform/IntakeFormEmergencyContactInformation";
import IntakeFormComplaint from "./intakeform/IntakeFormComplaint";
import IntakeFormCurrentPatientCondition from "./intakeform/IntakeFormCurrentPatientCondition";
import IntakeFormDescribeSymptoms from "./intakeform/IntakeFormDescribeSymptoms";
import SymptomsWorseIn from "./intakeform/SymptomsWorseIn";
import MakesSymptomsWorse from "./intakeform/MakesSymptomsWorse";
import MakesSymptomsBetter from "./intakeform/MakesSymptomsBetter";
import SymptomsInterfereWith from "./intakeform/SymptomsInterfereWith";
import HadAnyXray from "./intakeform/HadAnyXray";
import IntakeFormHaveYouExperienced from "./intakeform/IntakeFormHaveYouExperienced";
import IntakeFormHealthHistory from "./intakeform/IntakeFormHealthHistory";
import IntakeFormHealthHistoryPage2 from "./intakeform/IntakeFormHealthHistoryPage2";
import IntakeFormCurrentHealth from "./intakeform/IntakeFormCurrentHealth";
import IntakeFormCurrentHealthPage2 from "./intakeform/IntakeFormCurrentHealthPage2";
import InformedConsentFormKwtTextEnum from "./informedconsent/enum/InformedConsentFormKwtTextEnum";
import InformedConsentSignatureInputs from "./informedconsent/InformedConsentSignatureInputs";
import CancellationPolicyKwtTextEnum from "./cancellationpolicy/enum/CancellationPolicyKwtTextEnum";
import CancellationPolicyPartsKwt from "./cancellationpolicy/CancellationPolicyPartsKwt";
import {connect} from "react-redux";

class NewPatientFormKwt extends Component {

    constructor(props) {
        super(props);

        this.state = {
            consentDate: new Date(),
            firstName: "",
            middleName: "",
            lastName: "",
            dob: null,
            age: "",
            gender: "",
            eid: "",
            nationality: null,
            occupation: "",
            jobPhysicality: "",
            haveAllergies: "",
            allergy: {},
            referenceName: "",
            referenceHospital: "",
            referenceSubject: "",
            referenceType: null,
            patientSignature: "",

            doYouUseThisNumberForWhatsapp: "",
            contactOtherPhone: "",
            contactMobilePhone: "",
            contactEmail: "",
            address: "",
            country: null,
            city: "",
            postalCode: "",
            emergencyContactName: "",
            emergencyContactSurname: "",
            emergencyContactMobilePhone: "",
            emergencyContactRelationshipType: null,

            describeMainProblem: "",
            hopeAccomplishFromTreatment: "",
            painAmount: "",
            complaintRelatedTo: "",
            complaintRelatedToOther: "",
            happenedBefore: "",
            happenedBeforeYear: "",
            treatmentsForThisCondition: "",
            treatmentsForThisConditionOther: "",
            respondLastTreatment: "",
            whenDidItStart: "",
            whenDidItStartOption: null,

            symptomsConstant: false,
            symptomsComesAndGoes: false,
            symptomsStartedSuddenly: false,
            symptomsStartedSlowly: false,
            symptomsImproving: false,
            symptomsStayingTheSame: false,
            symptomsWorsening: false,
            symptomsThrobbing: false,
            symptomsNumbness: false,
            symptomsAching: false,
            symptomsBurning: false,
            symptomsShooting: false,
            symptomsDull: false,
            symptomsPinsAndNeedles: false,
            symptomsSharp: false,
            symptomsTightness: false,
            symptomsStiffness: false,
            symptomsOther: false,
            symptomsOtherDescription: "",

            symptomsAreWorseIn: null,
            symptomWorseMovement: false,
            symptomWorseCold: false,
            symptomWorseStanding: false,
            symptomWorseSleep: false,
            symptomWorseDriving: false,
            symptomWorseSitting: false,
            symptomWorseClimbingStairs: false,
            symptomWorseOther: false,
            symptomsWorseOtherDetail: "",
            symptomBetterMovement: false,
            symptomBetterMedication: false,
            symptomBetterRest: false,
            symptomBetterSleep: false,
            symptomBetterHeat: false,
            symptomBetterMassage: false,
            symptomBetterOther: false,
            symptomBetterOtherDetail: "",
            interfereWithSleep: false,
            interfereWithWork: false,
            interfereWithRest: false,
            interfereWithDailyRoutine: false,
            interfereWithSports: false,
            interfereWithOther: false,
            interfereWithOtherDetail: "",
            hadAnyXraysTaken: "",
            hadAnyXraysTakenDetails: "",
            changeInGeneralHealth: "",
            haveFeverChillsNightSweats: "",
            unexplainedWeightChange: "",
            numbnessOrTingling: "",
            bowelIncontinence: "",
            difficultySleeping: "",
            unexplainedFalls: "",

            cancerSelf: "",
            cancerFamily: "",
            cancerFamilyWho: null,
            diabetesSelf: "",
            diabetesFamily: "",
            diabetesFamilyWho: null,
            highBloodPressureSelf: "",
            highBloodPressureFamily: "",
            highBloodPressureFamilyWho: null,
            heartDiseaseSelf: "",
            heartDiseaseFamily: "",
            heartDiseaseFamilyWho: null,
            osteoporosisSelf: "",
            osteoporosisFamily: "",
            osteoporosisFamilyWho: null,
            osteoarthritisSelf: "",
            osteoarthritisFamily: "",
            osteoarthritisFamilyWho: null,
            rheumatoidArthritisSelf: "",
            rheumatoidArthritisFamily: "",
            rheumatoidArthritisFamilyWho: null,
            neurologicDiseaseSelf: "",
            neurologicDiseaseFamily: "",
            neurologicDiseaseFamilyWho: null,
            ulcersGerdRefluxSelf: "",
            ulcersGerdRefluxFamily: "",
            ulcersGerdRefluxFamilyWho: null,
            kidneyLiverDiseaseSelf: "",
            kidneyLiverDiseaseFamily: "",
            kidneyLiverDiseaseFamilyWho: null,
            sufferFromOtherSelf: "",
            sufferFromOtherFamily: null,

            anyMedicationSupplements: "",
            anyMedicationSupplementsIfYes: "",
            hadSurgery: "",
            hadSurgeryIfYes: "",
            receivingHealthCare: "",
            receivingHealthCareIfYes: "",
            pregnantNursing: "",
            haveChildren: "",
            howManyChildren: "",
            historyOfTrauma: "",
            oftenFeelingDown: "",
            oftenBotheredByLittleInterest: "",
            underPhysicalEmotionalAbuse: "",
            dietaryNutritionalConcerns: "",
            experienceSexualDysfunction: "",
            fearOfNeedles: "",

            lifestylePattern: "",
            sleepingPattern: "",
            exerciseType: "",
            doYouSmoke: "",
            doYouSmokeHowOften: "",
            haveYouEverSmoked: null,
            doYouConsumeAlcohol: "",
            doYouConsumeAlcoholHowOften: "",

            componentUpdated: false,
            nationalities: [],
            bodySilhouetteChilds: [],
            bodySilhouetteObjects: [],
            height: null,
            weight: null
        };
    }

    componentDidMount() {
        const {patient, form} = this.props;
        const readOnly = this.isFormReadonly(form);

        RemotingService.getRemoteCall('api/appointment/first-provider/KUWAIT/' + patient.id, {},
            (firstProvider) => {
                if (firstProvider) {
                    this.setState({providerName: firstProvider.name, providerSignature: firstProvider.signature});
                }
            });

        if (!form.consentForIntakeForm) {
            let activeEmergencyContact = null;
            if (
                patient.emergencyContact &&
                patient.emergencyContact.length
            ) {
                activeEmergencyContact = patient.emergencyContact.find(
                    (emergencyContact) => emergencyContact.isActiveContact === true
                );
            }

            this.setState({
                firstName: patient.firstName ? patient.firstName : null,
                middleName: patient.middleName ? patient.middleName : null,
                lastName: patient.lastName ? patient.lastName : null,
                eid: patient.eid ? patient.eid : null,
                gender: patient.gender ? patient.gender : "",
                dob: patient.birthday,
                nationality: patient.nationality ? patient.nationality : null,
                city: "",
                contactEmail: patient.email ? patient.email : "",
                address: patient.address ? patient.address : "",
                postalCode: patient.postalCode ? patient.postalCode : "",
                contactMobilePhone: patient.mobilePhone ? patient.mobilePhone : "",
                emergencyContactName: activeEmergencyContact ? activeEmergencyContact.name : "",
                emergencyContactMobilePhone: activeEmergencyContact ? activeEmergencyContact.mobilePhone : "",
            });
        }
        this.retrievePhoto(); // this should recall after photo changed.
    }

    retrievePhoto = () => {
        RemotingService.getRemoteCall(
            "api/patient/" + this.props.patient.id + "/document/" + "PHOTO",
            null,
            (dto) => {
                this.setState({photoValue: dto, newPhotoValue: dto});
            }
        );
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            JSON.stringify(this.props.form.consentForIntakeForm) !== JSON.stringify(prevState) &&
            this.props.form.consentForIntakeForm &&
            !this.state.componentUpdated
        ) {
            this.setState({
                ...this.props.form.consentForIntakeForm,
                nationality: this.props.form.consentForIntakeForm.nationality,
                componentUpdated: true,
            });
        }
    }

    onSubmit = () => {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        const bodySilhouetteMarkList = [];
        const bodySilhouetteObject = [...this.state.bodySilhouetteObjects];
        bodySilhouetteObject.filter(obj => obj.type !== "textbox").forEach((mark) =>
            bodySilhouetteMarkList.push({
                type: mark.type,
                topDistance: mark.top,
                leftDistance: mark.left,
                angle: mark.angle,
                scaleX: mark.scaleX,
                scaleY: mark.scaleY,
            })
        );

        const bodySilhouette = bodySilhouetteMarkList;

        let patient = this.props.patient;
        let patientFullName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
        let patientMrn = StringUtil.mrn(patient.mrn);
        let patientDOB = patient.birthday;
        let patientEId = patient.eid;

        const consentForIntakeForm = {
            consentDate: this.state.consentDate,
            firstName: this.state.firstName,
            middleName: this.state.middleName,
            lastName: this.state.lastName,
            dob: this.state.dob,
            age: this.state.age,
            gender: this.state.gender,
            eid: this.state.eid,
            nationality: this.state.nationality,
            occupation: this.state.occupation,
            jobPhysicality: this.state.jobPhysicality,
            haveAllergies: this.state.haveAllergies,
            allergy:
                this.state.haveAllergies === "Yes" ? this.state.allergy : null,
            referenceName: this.state.referenceName,
            referenceHospital: this.state.referenceHospital,
            referenceSubject: this.state.referenceSubject,
            referenceType: this.state.referenceType,

            contactOtherPhone:
                this.state.doYouUseThisNumberForWhatsapp === "No"
                    ? this.state.contactOtherPhone
                    : "",
            doYouUseThisNumberForWhatsapp: this.state.doYouUseThisNumberForWhatsapp,
            contactMobilePhone: this.state.contactMobilePhone,
            contactEmail: this.state.contactEmail,
            address: this.state.address,
            country: this.state.country,
            city: this.state.city,
            postalCode: this.state.postalCode,
            emergencyContactName: this.state.emergencyContactName,
            emergencyContactMobilePhone: this.state.emergencyContactMobilePhone,
            emergencyContactRelationshipType: this.state
                .emergencyContactRelationshipType,
            bodySilhouette: bodySilhouette,

            describeMainProblem: this.state.describeMainProblem,
            hopeAccomplishFromTreatment: this.state.hopeAccomplishFromTreatment,
            painAmount: this.state.painAmount,
            complaintRelatedTo: this.state.complaintRelatedTo,
            complaintRelatedToOther:
                this.state.complaintRelatedTo == EtiologyEnum.OTHER.key
                    ? this.state.complaintRelatedToOther
                    : "",
            happenedBefore: this.state.happenedBefore,
            happenedBeforeYear:
                this.state.happenedBefore === "Yes"
                    ? this.state.happenedBeforeYear
                    : "",
            treatmentsForThisCondition: this.state.treatmentsForThisCondition,
            treatmentsForThisConditionOther:
                this.state.treatmentsForThisCondition == TreatmentsForThisConditionEnum.OTHER.key
                    ? this.state.treatmentsForThisConditionOther
                    : "",
            respondLastTreatment: this.state.respondLastTreatment,
            whenDidItStart: this.state.whenDidItStart,
            whenDidItStartOption: this.state.whenDidItStartOption,

            symptomsConstant: this.state.symptomsConstant,
            symptomsComesAndGoes: this.state.symptomsComesAndGoes,
            symptomsStartedSuddenly: this.state.symptomsStartedSuddenly,
            symptomsStartedSlowly: this.state.symptomsStartedSlowly,
            symptomsImproving: this.state.symptomsImproving,
            symptomsStayingTheSame: this.state.symptomsStayingTheSame,
            symptomsWorsening: this.state.symptomsWorsening,
            symptomsThrobbing: this.state.symptomsThrobbing,
            symptomsNumbness: this.state.symptomsNumbness,
            symptomsAching: this.state.symptomsAching,
            symptomsBurning: this.state.symptomsBurning,
            symptomsShooting: this.state.symptomsShooting,
            symptomsDull: this.state.symptomsDull,
            symptomsPinsAndNeedles: this.state.symptomsPinsAndNeedles,
            symptomsSharp: this.state.symptomsSharp,
            symptomsTightness: this.state.symptomsTightness,
            symptomsStiffness: this.state.symptomsStiffness,
            symptomsOther: this.state.symptomsOther,
            symptomsOtherDescription: this.state.symptomsOther
                ? this.state.symptomsOtherDescription
                : "",

            symptomsAreWorseIn: this.state.symptomsAreWorseIn,
            symptomWorseMovement: this.state.symptomWorseMovement,
            symptomWorseCold: this.state.symptomWorseCold,
            symptomWorseStanding: this.state.symptomWorseStanding,
            symptomWorseSleep: this.state.symptomWorseSleep,
            symptomWorseDriving: this.state.symptomWorseDriving,
            symptomWorseSitting: this.state.symptomWorseSitting,
            symptomWorseClimbingStairs: this.state.symptomWorseClimbingStairs,
            symptomWorseOther: this.state.symptomWorseOther,
            symptomsWorseOtherDetail: this.state.symptomWorseOther
                ? this.state.symptomsWorseOtherDetail
                : "",
            symptomBetterMovement: this.state.symptomBetterMovement,
            symptomBetterMedication: this.state.symptomBetterMedication,
            symptomBetterRest: this.state.symptomBetterRest,
            symptomBetterSleep: this.state.symptomBetterSleep,
            symptomBetterHeat: this.state.symptomBetterHeat,
            symptomBetterMassage: this.state.symptomBetterMassage,
            symptomBetterOther: this.state.symptomBetterOther,
            symptomBetterOtherDetail: this.state.symptomBetterOther
                ? this.state.symptomBetterOtherDetail
                : "",
            interfereWithSleep: this.state.interfereWithSleep,
            interfereWithWork: this.state.interfereWithWork,
            interfereWithRest: this.state.interfereWithRest,
            interfereWithDailyRoutine: this.state.interfereWithDailyRoutine,
            interfereWithSports: this.state.interfereWithSports,
            interfereWithOther: this.state.interfereWithOther,
            interfereWithOtherDetail: this.state.interfereWithOther
                ? this.state.interfereWithOtherDetail
                : "",
            hadAnyXraysTaken: this.state.hadAnyXraysTaken,
            hadAnyXraysTakenDetails:
                this.state.hadAnyXraysTaken === "Yes"
                    ? this.state.hadAnyXraysTakenDetails
                    : "",
            changeInGeneralHealth: this.state.changeInGeneralHealth,
            haveFeverChillsNightSweats: this.state.haveFeverChillsNightSweats,
            unexplainedWeightChange: this.state.unexplainedWeightChange,
            numbnessOrTingling: this.state.numbnessOrTingling,
            bowelIncontinence: this.state.bowelIncontinence,
            difficultySleeping: this.state.difficultySleeping,
            unexplainedFalls: this.state.unexplainedFalls,

            cancerSelf: this.state.cancerSelf,
            cancerFamily: this.state.cancerFamily,
            cancerFamilyWho: this.state.cancerFamilyWho,
            diabetesSelf: this.state.diabetesSelf,
            diabetesFamily: this.state.diabetesFamily,
            diabetesFamilyWho: this.state.diabetesFamilyWho,
            highBloodPressureSelf: this.state.highBloodPressureSelf,
            highBloodPressureFamily: this.state.highBloodPressureFamily,
            highBloodPressureFamilyWho: this.state.highBloodPressureFamilyWho,
            heartDiseaseSelf: this.state.heartDiseaseSelf,
            heartDiseaseFamily: this.state.heartDiseaseFamily,
            heartDiseaseFamilyWho: this.state.heartDiseaseFamilyWho,
            osteoporosisSelf: this.state.osteoporosisSelf,
            osteoporosisFamily: this.state.osteoporosisFamily,
            osteoporosisFamilyWho: this.state.osteoporosisFamilyWho,
            osteoarthritisSelf: this.state.osteoarthritisSelf,
            osteoarthritisFamily: this.state.osteoarthritisFamily,
            osteoarthritisFamilyWho: this.state.osteoarthritisFamilyWho,
            rheumatoidArthritisSelf: this.state.rheumatoidArthritisSelf,
            rheumatoidArthritisFamily: this.state.rheumatoidArthritisFamily,
            rheumatoidArthritisFamilyWho: this.state.rheumatoidArthritisFamilyWho,
            neurologicDiseaseSelf: this.state.neurologicDiseaseSelf,
            neurologicDiseaseFamily: this.state.neurologicDiseaseFamily,
            neurologicDiseaseFamilyWho: this.state.neurologicDiseaseFamilyWho,
            ulcersGerdRefluxSelf: this.state.ulcersGerdRefluxSelf,
            ulcersGerdRefluxFamily: this.state.ulcersGerdRefluxFamily,
            ulcersGerdRefluxFamilyWho: this.state.ulcersGerdRefluxFamilyWho,
            kidneyLiverDiseaseSelf: this.state.kidneyLiverDiseaseSelf,
            kidneyLiverDiseaseFamily: this.state.kidneyLiverDiseaseFamily,
            kidneyLiverDiseaseFamilyWho: this.state.kidneyLiverDiseaseFamilyWho,
            sufferFromOtherSelf: this.state.sufferFromOtherSelf,
            sufferFromOtherFamily: this.state.sufferFromOtherFamily,

            anyMedicationSupplements: this.state.anyMedicationSupplements,
            anyMedicationSupplementsIfYes:
                this.state.anyMedicationSupplements !== "No"
                    ? this.state.anyMedicationSupplementsIfYes
                    : "",
            hadSurgery: this.state.hadSurgery,
            hadSurgeryIfYes:
                this.state.hadSurgery === "Yes" ? this.state.hadSurgeryIfYes : "",
            receivingHealthCare: this.state.receivingHealthCare,
            receivingHealthCareIfYes:
                this.state.receivingHealthCare === "Yes"
                    ? this.state.receivingHealthCareIfYes
                    : "",
            pregnantNursing: this.state.pregnantNursing,
            haveChildren: this.state.haveChildren,
            howManyChildren:
                this.state.haveChildren === "Yes" ? this.state.howManyChildren : "",
            historyOfTrauma: this.state.historyOfTrauma,
            oftenFeelingDown: this.state.oftenFeelingDown,
            oftenBotheredByLittleInterest: this.state.oftenBotheredByLittleInterest,
            underPhysicalEmotionalAbuse: this.state.underPhysicalEmotionalAbuse,
            dietaryNutritionalConcerns: this.state.dietaryNutritionalConcerns,
            experienceSexualDysfunction: this.state.experienceSexualDysfunction,
            fearOfNeedles: this.state.fearOfNeedles,

            lifestylePattern: this.state.lifestylePattern,
            sleepingPattern: this.state.sleepingPattern,
            exerciseType: this.state.exerciseType,
            doYouSmoke: this.state.doYouSmoke,
            doYouSmokeHowOften:
                this.state.doYouSmoke === "Yes" ? this.state.doYouSmokeHowOften : "",
            haveYouEverSmoked: this.state.haveYouEverSmoked,
            doYouConsumeAlcohol: this.state.doYouConsumeAlcohol,
            doYouConsumeAlcoholHowOften:
                this.state.doYouConsumeAlcohol === "Yes"
                    ? this.state.doYouConsumeAlcoholHowOften
                    : "",
            patientSignature: this.state.patientSignature,
            height: this.state.height,
            weight: this.state.weight
        };

        const consentForInformedConsent = {
            consentDate: this.state.consentDate,
            providerName: this.state.providerName,
            guardianName: this.state.guardianName,
            guardianRelationship: this.state.guardianRelationship,
            providerSignature: this.state.providerSignature,
            patientSignature: this.state.patientSignature,
            patientFullName: patientFullName,
            patientMrn: patientMrn,
            patientDOB: patientDOB,
            patientEId: patientEId
        };

        const cancellationPolicy = {
            consentDate: this.state.consentDate,
            providerName: this.state.providerName,
            guardianName: this.state.guardianName,
            guardianRelationship: this.state.guardianRelationship,
            patientSignature: this.state.patientSignature,
            patientFullName: patientFullName,
            patientMrn: patientMrn
        };

        RemotingService.postRemoteCall(
            "api/patient/" + this.props.patient.id + "/new-patient-form-kwt",
            {consentForIntakeForm, consentForInformedConsent, cancellationPolicy},
            (result) => {
                this.props.afterSave(result);
                this.props.close();
            }
        );
    };

    fieldChanged = (fieldName, value, validationCallback) => {
        this.setState({[fieldName]: value}, () => {
            if (validationCallback) {
                validationCallback(this);
            }
        });
    };

    fieldGroupChanged = (fieldGroupObject, value, validationCallback) => {
        this.setState(fieldGroupObject, () => {
            if (validationCallback) {
                validationCallback(this);
            }
        });
    };

    addItemToBodySilhouetteCanvas = (item) => {
        const bodySilhouetteComponents = [...this.state.bodySilhouetteChilds];
        bodySilhouetteComponents.push(item);
        const bodySilhouetteChilds = bodySilhouetteComponents.map((child) =>
            child != null ? React.cloneElement(child, {}) : null
        );
        this.setState({bodySilhouetteChilds: bodySilhouetteChilds});
    };

    deleteItemFromBodySilhouetteCanvas = () => {
        const bodySilhouetteComponents = [...this.state.bodySilhouetteChilds];
        bodySilhouetteComponents.pop();
        const bodySilhouetteChilds = bodySilhouetteComponents.map((child) =>
            child != null ? React.cloneElement(child, {}) : null
        );
        this.setState({bodySilhouetteChilds: bodySilhouetteChilds});
    };

    findMarkType = (mark) => {
        const props = {
            top: mark.topDistance ? mark.topDistance : mark.top,
            left: mark.leftDistance ? mark.leftDistance : mark.left,
            angle: mark.angle,
            scaleX: mark.scaleX,
            scaleY: mark.scaleY,
        };
        if (mark.type === EnumBodySilhouetteSignTypes.CROSS)
            return <Cross {...props} />;
        else if (mark.type === EnumBodySilhouetteSignTypes.ARROW)
            return <Arrow {...props} />;
        else if (mark.type === EnumBodySilhouetteSignTypes.CIRCLE)
            return <Circle {...props} />;
    };

    onSavePatientSignature = (signature) => {
        this.setState({patientSignature: signature})
    };

    isFormReadonly = (form) => {
        return form.consentForIntakeForm !== null && form.consentForIntakeForm !== undefined
            && form.consentForInformedConsent !== null && form.consentForInformedConsent !== undefined
            && form.cancellationPolicy !== null && form.cancellationPolicy !== undefined;
    }

    pushFormFieldRef = (ref) => this.formFields.push(ref);

    render() {
        if (!this.props.show) return null;

        const {patient, form} = this.props;
        const readOnly = this.isFormReadonly(form);
        const mrnNo = StringUtil.mrn(patient.mrn);
        const selectedLanguage = this.props.selectedLanguage.name;
        const patientName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
        this.formFields = [];

        const savedBodySilhouetteChilds = readOnly
            ? this.props.form.consentForIntakeForm.bodySilhouette.map((mark) => {
                return this.findMarkType(mark);
            })
            : null;

        const photoValue = this.state.photoValue;
        const photoSrc = photoValue
            ? `data:image/jpeg;base64,${photoValue.content}`
            : `${process.env.PUBLIC_URL}/assets/patient_profile.png`;

        const formBodyClass = this.props.selectedLanguage == FormLanguages.ARABIC ? "arabic-form" : "";
        const maxWidthClass = "form-body";
        const enumLabel = this.props.selectedLanguage == FormLanguages.ARABIC ? "nameInArabic" : "name";
        const nationalityLabel = this.props.selectedLanguage == FormLanguages.ARABIC ? "nameInArabic" : "nameInEnglish";
        const commonProps = {
            selectedLanguage,
            enumLabel,
            nationalityLabel,
            readOnly,
            onChange: this.fieldChanged,
            onFieldGroupChanged: this.fieldGroupChanged,
            formFields: this.formFields,
            pushFormFieldRef: this.pushFormFieldRef
        }

        const commonInformedConsentProps = {
            selectedLanguage,
            pushFormFieldRef: this.pushFormFieldRef
        }

        return (
            <Wizard
                show={this.props.show}
                close={this.props.close}
                title={IntakeFormTextEnum.New_Patient_Form_Header[selectedLanguage]}
                onSubmit={this.onSubmit}
                isReadOnly={readOnly}
                previousBtnText={IntakeFormTextEnum.Wizard_Previous[selectedLanguage]}
                nextBtnText={IntakeFormTextEnum.Wizard_Next[selectedLanguage]}
                saveBtnText={IntakeFormTextEnum.Wizard_Save[selectedLanguage]}
            >
                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}>
                    <div
                        className={`${readOnly ? "TextInputDisabled" : ""}`}
                        style={{minHeight: "580px", width: "1000px"}}
                    >
                        <IntakeFormHeader {...commonProps}
                                          photoSrc={photoSrc}
                                          mrnNo={mrnNo}
                                          consentDate={this.state.consentDate}
                                          title={IntakeFormTextEnum.New_Patient_Form_Title[selectedLanguage]}
                        />

                        <div className={formBodyClass}>
                            <IntakeFormPatientInformation {...commonProps}
                                                          firstName={this.state.firstName}
                                                          middleName={this.state.middleName}
                                                          lastName={this.state.lastName}
                                                          dob={this.state.dob}
                                                          gender={this.state.gender}
                                                          eid={this.state.eid}
                                                          nationality={this.state.nationality}
                                                          nationalities={this.props.nationalities}
                                                          occupation={this.state.occupation}
                                                          jobPhysicality={this.state.jobPhysicality}
                                                          haveAllergies={this.state.haveAllergies}
                                                          allergy={this.state.allergy}
                                                          height={this.state.height}
                                                          weight={this.state.weight}
                            />

                            <IntakeFormReferralInformation {...commonProps}
                                                           referenceType={this.state.referenceType}
                                                           referenceName={this.state.referenceName}
                                                           autoCompleted={this.state.autoCompleted}
                                                           referenceHospital={this.state.referenceHospital}
                                                           patientId={this.props.patient.id}
                            />
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass + " my-2"} style={{width: "1000px"}}>
                        <IntakeFormContactInformation {...commonProps}
                                                      contactMobilePhone={this.state.contactMobilePhone}
                                                      doYouUseThisNumberForWhatsapp={this.state.doYouUseThisNumberForWhatsapp}
                                                      contactOtherPhone={this.state.contactOtherPhone}
                                                      contactEmail={this.state.contactEmail}
                                                      address={this.state.address}
                                                      country={this.state.country}
                                                      state={this.state.stateOfResidence}
                                                      countries={this.props.countries}
                                                      city={this.state.city}
                                                      postalCode={this.state.postalCode}
                        />

                        <IntakeFormEmergencyContactInformation {...commonProps}
                                                               emergencyContactName={this.state.emergencyContactName}
                                                               emergencyContactMobilePhone={this.state.emergencyContactMobilePhone}
                                                               emergencyContactRelationshipType={this.state.emergencyContactRelationshipType}

                        />
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={`${formBodyClass} ${maxWidthClass}`}>
                        <h3 style={{textAlign: "center", marginBottom: "10px"}}>
                            <b>{CancellationPolicyKwtTextEnum.Cancellation_Policy_Public_Title[selectedLanguage]}</b>
                        </h3>
                        <CancellationPolicyPartsKwt.Part1 selectedLanguage={selectedLanguage}/>
                        <div style={{border: "1px solid #000000", borderRadius: "35px"}}>
                            <CancellationPolicyPartsKwt.Part2 selectedLanguage={selectedLanguage}/>
                        </div>
                        <div className="mt-4">
                            <CancellationPolicyPartsKwt.Part3 selectedLanguage={selectedLanguage}
                                                              patientName={patientName}/>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={`${formBodyClass} ${maxWidthClass}`}>
                        <h3 className={'text-center mb-4'}>
                            <b>{InformedConsentFormKwtTextEnum.Informed_Consent[selectedLanguage]}</b>
                        </h3>

                        <ConsentFormPartsKwt.Introduction {...commonInformedConsentProps}/>
                        <ConsentFormPartsKwt.Benefits {...commonInformedConsentProps}/>
                        <ConsentFormPartsKwt.Risks {...commonInformedConsentProps}/>
                        <ConsentFormPartsKwt.Alternatives {...commonInformedConsentProps}/>
                        <ConsentFormPartsKwt.InjectionAndMedication {...commonInformedConsentProps}/>
                        <ConsentFormPartsKwt.DataCollectionAndPrivacy {...commonInformedConsentProps}/>
                        <ConsentFormPartsKwt.QuestionsOfConcerns {...commonInformedConsentProps}/>
                        <ConsentFormPartsKwt.Complaints {...commonInformedConsentProps}/>

                        <ConsentFormPartsKwt.Conclusion {...commonInformedConsentProps}/>
                        <InformedConsentSignatureInputs {...commonInformedConsentProps}
                                                        readOnly={readOnly}
                                                        formFields={this.formFields}
                                                        textEnum={InformedConsentFormKwtTextEnum}
                                                        consentDate={moment(readOnly ? new Date(form.consentForInformedConsent.consentDate) : this.state.consentDate).format('LL')}
                                                        patientName={patientName}
                                                        providerName={readOnly ? form.consentForInformedConsent.providerName : this.state.providerName}
                                                        guardianName={readOnly ? form.consentForInformedConsent.guardianName : this.state.guardianName}
                                                        guardianRelationship={readOnly ? form.consentForInformedConsent.guardianRelationship : this.state.guardianRelationship}
                                                        patientSignature={readOnly ? form.consentForInformedConsent.patientSignature : this.state.patientSignature}
                                                        providerSignature={readOnly ? form.consentForInformedConsent.providerSignature : this.state.providerSignature}
                                                        onSavePatientSignature={this.onSavePatientSignature}
                                                        onChange={this.fieldChanged}/>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className="my-2" style={{width: "1000px"}}>
                        <div className="container">
                            <div className="row">
                                <h3>
                                    <b>{IntakeFormTextEnum.Please_Mark_Where_It_Hurts[selectedLanguage]}</b>
                                </h3>
                            </div>
                            <div className="row">
                                <div className={"col " + formBodyClass}>
                                    <IntakeFormComplaint {...commonProps}
                                                         describeMainProblem={this.state.describeMainProblem}
                                                         whenDidItStart={this.state.whenDidItStart}
                                                         whenDidItStartOption={this.state.whenDidItStartOption}
                                                         painAmount={this.state.painAmount}
                                    />
                                </div>
                                <div className="col-lg-7">
                                    <BodySilhouettePatientCanvas
                                        selectedLanguage={selectedLanguage}
                                        onClickAddCross={(top, left) =>
                                            this.addItemToBodySilhouetteCanvas(
                                                <Cross top={top} left={left}/>
                                            )
                                        }
                                        onClickAddCircle={(top, left) =>
                                            this.addItemToBodySilhouetteCanvas(
                                                <Circle top={top} left={left}/>
                                            )
                                        }
                                        onClickAddArrow={(top, left) =>
                                            this.addItemToBodySilhouetteCanvas(
                                                <Arrow top={top} left={left}/>
                                            )
                                        }
                                        onRemoveItem={() => this.deleteItemFromBodySilhouetteCanvas()}
                                        onPageChange={(objects) => {
                                            let bodySilhouetteChilds = objects.map((mark) => {
                                                return this.findMarkType(mark);
                                            });
                                            this.setState({
                                                bodySilhouetteObjects: [...objects],
                                                bodySilhouetteChilds: bodySilhouetteChilds,
                                            });
                                        }}
                                        disabled={readOnly}
                                    >
                                        {savedBodySilhouetteChilds
                                            ? savedBodySilhouetteChilds
                                            : this.state.bodySilhouetteChilds}
                                    </BodySilhouettePatientCanvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass + " my-2"} style={{width: "1000px"}}>
                        <IntakeFormCurrentPatientCondition {...commonProps}
                                                           complaintRelatedTo={this.state.complaintRelatedTo}
                                                           complaintRelatedToOther={this.state.complaintRelatedToOther}
                                                           happenedBefore={this.state.happenedBefore}
                                                           happenedBeforeYear={this.state.happenedBeforeYear}
                                                           treatmentsForThisCondition={this.state.treatmentsForThisCondition}
                                                           treatmentsForThisConditionOther={this.state.treatmentsForThisConditionOther}
                                                           respondLastTreatment={this.state.respondLastTreatment}
                                                           hopeAccomplishFromTreatment={this.state.hopeAccomplishFromTreatment}
                        />
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass + ' describe-symptoms my-2'} style={{width: "1000px"}}>
                        <IntakeFormDescribeSymptoms {...commonProps}
                                                    symptomsConstant={this.state.symptomsConstant}
                                                    symptomsComesAndGoes={this.state.symptomsComesAndGoes}
                                                    symptomsStartedSuddenly={this.state.symptomsStartedSuddenly}
                                                    symptomsStartedSlowly={this.state.symptomsStartedSlowly}
                                                    symptomsImproving={this.state.symptomsImproving}
                                                    symptomsStayingTheSame={this.state.symptomsStayingTheSame}
                                                    symptomsWorsening={this.state.symptomsWorsening}
                                                    symptomsThrobbing={this.state.symptomsThrobbing}
                                                    symptomsNumbness={this.state.symptomsNumbness}
                                                    symptomsAching={this.state.symptomsAching}
                                                    symptomsBurning={this.state.symptomsBurning}
                                                    symptomsShooting={this.state.symptomsShooting}
                                                    symptomsDull={this.state.symptomsDull}
                                                    symptomsPinsAndNeedles={this.state.symptomsPinsAndNeedles}
                                                    symptomsSharp={this.state.symptomsSharp}
                                                    symptomsTightness={this.state.symptomsTightness}
                                                    symptomsStiffness={this.state.symptomsStiffness}
                                                    symptomsOther={this.state.symptomsOther}
                                                    symptomsOtherDescription={this.state.symptomsOtherDescription}
                        />

                        <SymptomsWorseIn {...commonProps}
                                         symptomsAreWorseIn={this.state.symptomsAreWorseIn}
                        />

                        <MakesSymptomsWorse {...commonProps}
                                            symptomWorseMovement={this.state.symptomWorseMovement}
                                            symptomWorseCold={this.state.symptomWorseCold}
                                            symptomWorseStanding={this.state.symptomWorseStanding}
                                            symptomWorseSleep={this.state.symptomWorseSleep}
                                            symptomWorseDriving={this.state.symptomWorseDriving}
                                            symptomWorseSitting={this.state.symptomWorseSitting}
                                            symptomWorseClimbingStairs={this.state.symptomWorseClimbingStairs}
                                            symptomWorseOther={this.state.symptomWorseOther}
                                            symptomsWorseOtherDetail={this.state.symptomsWorseOtherDetail}
                        />

                        <MakesSymptomsBetter {...commonProps}
                                             symptomBetterMovement={this.state.symptomBetterMovement}
                                             symptomBetterMedication={this.state.symptomBetterMedication}
                                             symptomBetterRest={this.state.symptomBetterRest}
                                             symptomBetterSleep={this.state.symptomBetterSleep}
                                             symptomBetterHeat={this.state.symptomBetterHeat}
                                             symptomBetterMassage={this.state.symptomBetterMassage}
                                             symptomBetterOther={this.state.symptomBetterOther}
                                             symptomBetterOtherDetail={this.state.symptomBetterOtherDetail}
                        />

                        <SymptomsInterfereWith {...commonProps}
                                               interfereWithSleep={this.state.interfereWithSleep}
                                               interfereWithWork={this.state.interfereWithWork}
                                               interfereWithRest={this.state.interfereWithRest}
                                               interfereWithDailyRoutine={this.state.interfereWithDailyRoutine}
                                               interfereWithSports={this.state.interfereWithSports}
                                               interfereWithOther={this.state.interfereWithOther}
                                               interfereWithOtherDetail={this.state.interfereWithOtherDetail}
                        />

                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass + ' my-2'} style={{width: "1000px"}}>
                        <HadAnyXray {...commonProps}
                                    hadAnyXraysTaken={this.state.hadAnyXraysTaken}
                                    hadAnyXraysTakenDetails={this.state.hadAnyXraysTakenDetails}
                        />
                        <IntakeFormHaveYouExperienced {...commonProps}
                                                      experienceMarkAll={this.state.experienceMarkAll}
                                                      changeInGeneralHealth={this.state.changeInGeneralHealth}
                                                      haveFeverChillsNightSweats={this.state.haveFeverChillsNightSweats}
                                                      unexplainedWeightChange={this.state.unexplainedWeightChange}
                                                      numbnessOrTingling={this.state.numbnessOrTingling}
                                                      bowelIncontinence={this.state.bowelIncontinence}
                                                      difficultySleeping={this.state.difficultySleeping}
                                                      unexplainedFalls={this.state.unexplainedFalls}
                        />
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass + ' my-2'} style={{width: "1000px"}}>
                        <IntakeFormHealthHistory {...commonProps}
                                                 markAllSelf={this.state.markAllSelf}
                                                 markAllFamily={this.state.markAllFamily}
                                                 cancerSelf={this.state.cancerSelf}
                                                 cancerFamily={this.state.cancerFamily}
                                                 cancerFamilyWho={this.state.cancerFamilyWho}
                                                 diabetesSelf={this.state.diabetesSelf}
                                                 diabetesFamily={this.state.diabetesFamily}
                                                 diabetesFamilyWho={this.state.diabetesFamilyWho}
                                                 highBloodPressureSelf={this.state.highBloodPressureSelf}
                                                 highBloodPressureFamily={this.state.highBloodPressureFamily}
                                                 highBloodPressureFamilyWho={this.state.highBloodPressureFamilyWho}
                                                 heartDiseaseSelf={this.state.heartDiseaseSelf}
                                                 heartDiseaseFamily={this.state.heartDiseaseFamily}
                                                 heartDiseaseFamilyWho={this.state.heartDiseaseFamilyWho}
                                                 osteoporosisSelf={this.state.osteoporosisSelf}
                                                 osteoporosisFamily={this.state.osteoporosisFamily}
                                                 osteoporosisFamilyWho={this.state.osteoporosisFamilyWho}
                                                 osteoarthritisSelf={this.state.osteoarthritisSelf}
                                                 osteoarthritisFamily={this.state.osteoarthritisFamily}
                                                 osteoarthritisFamilyWho={this.state.osteoarthritisFamilyWho}
                                                 rheumatoidArthritisSelf={this.state.rheumatoidArthritisSelf}
                                                 rheumatoidArthritisFamily={this.state.rheumatoidArthritisFamily}
                                                 rheumatoidArthritisFamilyWho={this.state.rheumatoidArthritisFamilyWho}
                                                 neurologicDiseaseSelf={this.state.neurologicDiseaseSelf}
                                                 neurologicDiseaseFamily={this.state.neurologicDiseaseFamily}
                                                 neurologicDiseaseFamilyWho={this.state.neurologicDiseaseFamilyWho}
                                                 ulcersGerdRefluxSelf={this.state.ulcersGerdRefluxSelf}
                                                 ulcersGerdRefluxFamily={this.state.ulcersGerdRefluxFamily}
                                                 ulcersGerdRefluxFamilyWho={this.state.ulcersGerdRefluxFamilyWho}
                                                 kidneyLiverDiseaseSelf={this.state.kidneyLiverDiseaseSelf}
                                                 kidneyLiverDiseaseFamily={this.state.kidneyLiverDiseaseFamily}
                                                 kidneyLiverDiseaseFamilyWho={this.state.kidneyLiverDiseaseFamilyWho}
                                                 sufferFromOtherSelf={this.state.sufferFromOtherSelf}
                                                 sufferFromOtherFamily={this.state.sufferFromOtherFamily}
                        />
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass + ' my-2'} style={{width: "1000px"}}>
                        <IntakeFormHealthHistoryPage2 {...commonProps}
                                                      anyMedicationSupplements={this.state.anyMedicationSupplements}
                                                      anyMedicationSupplementsIfYes={this.state.anyMedicationSupplementsIfYes}
                                                      hadSurgery={this.state.hadSurgery}
                                                      hadSurgeryIfYes={this.state.hadSurgeryIfYes}
                                                      receivingHealthCare={this.state.receivingHealthCare}
                                                      receivingHealthCareIfYes={this.state.receivingHealthCareIfYes}
                        />

                        <IntakeFormCurrentHealth {...commonProps}
                                                 pregnantNursing={this.state.pregnantNursing}
                                                 fearOfNeedles={this.state.fearOfNeedles}
                                                 historyOfTrauma={this.state.historyOfTrauma}
                                                 oftenFeelingDown={this.state.oftenFeelingDown}
                                                 oftenBotheredByLittleInterest={this.state.oftenBotheredByLittleInterest}
                                                 underPhysicalEmotionalAbuse={this.state.underPhysicalEmotionalAbuse}
                                                 dietaryNutritionalConcerns={this.state.dietaryNutritionalConcerns}
                                                 experienceSexualDysfunction={this.state.experienceSexualDysfunction}
                                                 haveChildren={this.state.haveChildren}
                                                 howManyChildren={this.state.howManyChildren}
                        />
                    </div>
                </Wizard.Page>

                <Wizard.Page onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass + ' my-2'} style={{width: "1000px"}}>
                        <IntakeFormCurrentHealthPage2 {...commonProps}
                                                      lifestylePattern={this.state.lifestylePattern}
                                                      sleepingPattern={this.state.sleepingPattern}
                                                      exerciseType={this.state.exerciseType}
                                                      doYouSmoke={this.state.doYouSmoke}
                                                      doYouSmokeHowOften={this.state.doYouSmokeHowOften}
                                                      haveYouEverSmoked={this.state.haveYouEverSmoked}
                                                      doYouConsumeAlcohol={this.state.doYouConsumeAlcohol}
                                                      doYouConsumeAlcoholHowOften={this.state.doYouConsumeAlcoholHowOften}
                        />
                    </div>
                </Wizard.Page>
            </Wizard>
        );
    }
}

function mapStateToProps(state) {
    return {
        nationalities: state.base.lookupNationalities,
        countries: state.base.lookupCountries
    };
}

export default connect(mapStateToProps)(NewPatientFormKwt);
