import "../MedicalReports.css"
import React, {Component} from "react";
import ReportTemplate from "../ReportTemplate";
import ReportSection from "../ReportSection";
import ComplaintReportView from "../view/ComplaintReportView";
import DateUtil from "../../../../../utils/DateUtil";
import PreviousTreatmentReceivedReportView from "../view/PreviousTreatmentReceivedReportView";
import ActivityLimitationReportView from "../view/ActivityLimitationReportView";
import LabImagingReportView from "../view/LabImagingReportView";
import AllergiesReportView from "../view/AllergiesReportView";
import AssessmentReportView from "../view/AssessmentReportView";
import TreatmentReceivedReportView from "../view/TreatmentReceivedReportView";
import ResponseToTreatmentReportView from "../view/ResponseToTreatmentReportView";
import ReferralsReportView from "../view/ReferralsReportView";
import ReportViewUtils from "../view/ReportViewUtils";
import SymptomPainReferralReportView from "../view/SymptomPainReferralReportView";
import DiagnosisReportView from "../view/DiagnosisReportView";
import moment from "moment/moment";

export default class ProgressReportTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {visit: this.props.emr.visits[this.props.emr.visits.length - 1]}; // TODO remove
    }

    render() {
        const emr = this.props.emr;
        const firstVisit = emr.visits[0];
        const visit = this.state.visit;
        const subjective = visit.subjective;
        const assessment = visit.assessment;
        const treatment = visit.treatment;

        const procedureList = [...visit.diagnosis.selfPayProcedures, ...visit.diagnosis.directBillingProcedures]
            .sort((a, b) => moment(a.startTime).diff(moment(b.startTime)))

        return (
            <ReportTemplate {...this.props} generateUrl={'api/medical-report/generate/progress'}
                            onVisitSelected={(visit) => {
                                this.setState({visit})
                            }}>

                <ReportSection header1={"Complaint"}>
                    {!ReportViewUtils.isComplaintNull(subjective.complaint) && <ComplaintReportView complaint={subjective.complaint}/>}
                </ReportSection>

                <ReportSection header1={"Symptoms"} header2={"Date Of First Visit"}>
                    {!ReportViewUtils.isSymptomsPainReferralNull(subjective.complaint) && <SymptomPainReferralReportView complaint={subjective.complaint}/>}
                    {firstVisit.appointment.startTime != null && <div>{DateUtil.formatDateDoMMYY(firstVisit.appointment.startTime)}</div>}
                </ReportSection>

                <ReportSection header1={"Previous Treatment Received"} header2={"Activity Limitations"}>
                    {!ReportViewUtils.isPreviousTreatmentReceivedNull(subjective.treatmentReceived) && <PreviousTreatmentReceivedReportView treatmentReceived={subjective.treatmentReceived}/>}
                    {!ReportViewUtils.isActivityLimitationNull(subjective.activityLimitation) && <ActivityLimitationReportView activityLimitation={subjective.activityLimitation}/>}
                </ReportSection>

                <ReportSection header1={"Labs/Imaging"} header2={"Allergies"}>
                    {!ReportViewUtils.isLabImagingNull(subjective.labsImaging) && <LabImagingReportView labsImaging={subjective.labsImaging}/>}
                    {!ReportViewUtils.isAllergyNull(subjective.allergy) && <AllergiesReportView allergy={subjective.allergy}/>}
                </ReportSection>

                <ReportSection header1={"Assessment"}>
                    {!ReportViewUtils.isAssessmentNull(assessment) && <AssessmentReportView assesment={assessment}/>}
                </ReportSection>

                <ReportSection header1={"Diagnosis"}>
                    {!ReportViewUtils.isDiagnosisNull(visit.diagnosis) && !ReportViewUtils.isDiagnosisItemsNull(visit.diagnosis.diagnosisGroups[0].diagnosisItems) && <DiagnosisReportView diagnosis={visit.diagnosis}/>}
                </ReportSection>

                <ReportSection header1={"Treatment"}>
                    {!ReportViewUtils.isTreatmentInfoNull(treatment.treatmentReceived) && <div>
                        <div style={{marginBottom: "15px"}}><b style={{marginBottom: "15px"}}>Treatment Received</b>
                        </div>
                        <div>
                            The patient received the following treatment
                        </div>
                        <TreatmentReceivedReportView treatmentInfo={treatment.treatmentReceived}
                                                     procedureList={procedureList}/>
                    </div>}
                </ReportSection>

                <ReportSection header1={"Response To Treatment"} header2={"Treatment Plan"}>
                    {!ReportViewUtils.isResponseToTreatmentNull(treatment.response) && <ResponseToTreatmentReportView response={treatment.response}/>}
                    {!ReportViewUtils.isTreatmentInfoNull(treatment.treatmentPlan) && <div>
                        The patient is recommended for the following treatment plan.
                        <TreatmentReceivedReportView treatmentInfo={treatment.treatmentPlan}/>
                    </div>}
                </ReportSection>

                <ReportSection header1={"Referrals"}>
                    {!ReportViewUtils.isReferralsNull(treatment.referral) && <ReferralsReportView referral={treatment.referral}/>}
                </ReportSection>

            </ReportTemplate>
        );
    }

}
