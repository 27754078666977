import React, {Component} from "react";
import "../../VisitHistory.css"
import JointEnum from "../../../assessment/enums/rangeofmotion/JointEnum";
import LocationEnum from "../../../assessment/enums/rangeofmotion/LocationEnum";
import GlideEnum from "../../../assessment/enums/rangeofmotion/GlideEnum";
import ResultEnum from "../../../assessment/enums/rangeofmotion/ResultEnum";

export default class JointIntegrityViewHistory extends Component {
    render() {
        if (!this.props.jointIntegrity || !this.props.jointIntegrity.items || this.props.jointIntegrity.items.length === 0) {
            return <div className="pl-3">
                {this.props.jointIntegrity && this.props.jointIntegrity.none ? "Normal" : "No information available"}
            </div>;
        }

        return (
            <table className={"visit-history-table"}>
                <thead>
                <tr key={"jointIntegrityHistoryHeader"} style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th style={{width: "130px"}}>Joint</th>
                    <th style={{width: "75px"}}>Location</th>
                    <th style={{width: "140px"}}>Glide</th>
                    <th style={{width: "95px"}}>Result</th>
                    <th>Details</th>
                </tr>
                </thead>
                <tbody>
                {this.props.jointIntegrity.items.map((rowValue, index) => {
                    return (
                        <tr key={"jointIntegrityHistoryItem" + index} style={{backgroundColor: "white"}}>
                            <td>{rowValue.joint ? JointEnum[rowValue.joint].name : null}</td>
                            <td>{rowValue.location ? LocationEnum[rowValue.location].name : null}</td>
                            <td>{rowValue.glide ? GlideEnum[rowValue.glide].name : null}</td>
                            <td>{rowValue.result ? ResultEnum[rowValue.result].name : null}</td>
                            <td>{rowValue.details}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        );
    }
}
