import Enum from "../../../../../../enums/Enum";

const MyotomesDropdownValues = new Enum({
    M0: {name: "No contraction "},
    M1: {name: "Flicker of movement"},
    M2: {name: "Active movement with gravity eliminated"},
    M3: {name: "Active movement against gravity"},
    M4: {name: "Active movement against gravity and resistance"},
    M5: {name: "Normal power"},
    MNT: {name: "Not testable"}
});

export default MyotomesDropdownValues;