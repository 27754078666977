import React, {Component} from "react";
import EnumRestrictionReason from "./enums/EnumRestrictionReason";
import RemotingService from "../../services/remoting-service/RemotingService";
import EnumRestrictionStatus from "./enums/EnumRestrictionStatus";
import Tooltip from "../../components/tooltip/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import DateUtil from "../../utils/DateUtil";
import './Restrictions.css'
import ActionMenu from "../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../components/action-menu/ActionMenuItem";
import AddRestriction from "./AddRestriction";
import EnumRestrictionType from "./enums/EnumRestrictionType";

class Restrictions extends Component {

    constructor(props) {
        super(props);
        this.state = {
            restrictions: null,
            addRestrictionModalVisible: false
        }
    }

    componentDidMount() {
        this.retrieveRestrictions();
    }

    retrieveRestrictions = () => {
        let patientId = this.props.patientId;
        RemotingService.getRemoteCall('api/restriction/' + patientId + '/restriction', null, (result) => {
            this.setState({...this.state, restrictions: result})
        });
    }

    hasAcceptedRestrictions() {
        const {restrictions} = this.state;
        return restrictions && restrictions.length > 0 && restrictions.filter(r => "ACCEPTED" === r.status ).length > 0;
    }

    render() {
        const cardCornerClassName = this.hasAcceptedRestrictions() ? "card red-corner restriction-panel" : "card green-corner restriction-panel"
        return (
            <>
                <div className={cardCornerClassName}>
                    <div className="card-header">
                        <div className="card-header-title">
                            <strong style={{verticalAlign: "middle"}}>Restrictions</strong>
                        </div>
                        <div className="card-header-actions">
                            <button type="button" className="btn btn-primary btn-sm"
                                    onClick={() => this.showAddRestriction()}
                                    style={{
                                        'marginRight': '10px',
                                        'padding': '0.2rem .5rem',
                                        'borderRadius': '0'
                                    }}>
                                Add Restriction
                            </button>
                        </div>
                    </div>
                    <div className="card-body line-wrapper small-font">
                        <div className="row" style={{marginRight: 5}}>
                            <div className="col-3">Status</div>
                            <div className="col-2">Reason</div>
                            <div className="col-2">Expiry</div>
                            <div className="col-3">Requested By</div>
                            <div className="col-1 text-center">Actions</div>
                        </div>
                        <hr/>

                        {this.renderRestrictions()}
                    </div>
                </div>

                {
                    this.state.addRestrictionModalVisible &&
                    <AddRestriction patientId={this.props.patientId}
                                    onRestrictionAdded={this.onRestrictionAdded}
                                    close={() => {this.setState({addRestrictionModalVisible: false})}} />
                }
            </>
        );
    }

    renderRestrictions() {
        return (this.state.restrictions && this.state.restrictions.length) ?
            <>
                <div className="row" key={this.state.restrictions[0].id} style={{marginRight: 5}}>
                    <RestrictionRow restriction={this.state.restrictions[0]} editable={this.props.editable} onRestrictionStatusUpdated={this.onRestrictionStatusUpdated.bind(this)}/>
                </div>
                {
                    this.state.restrictions && this.state.restrictions.length && this.state.restrictions.length > 1 &&
                    <FontAwesomeIcon
                        icon={["fa", `angle-double-${this.state.restrictionPanelExpanded ? 'up' : 'down'}`]}
                        style={{position: "absolute", margin: "-20px", right: "30px"}}
                        onClick={() => this.setState({restrictionPanelExpanded: !this.state.restrictionPanelExpanded})}
                    />
                }
                {
                    this.state.restrictions && this.state.restrictions.length && this.state.restrictions.length > 1 &&
                    <div style={{marginLeft: -20}} className={`card-body small-font expandable ${this.state.restrictionPanelExpanded ? 'expanded' : ''}`}>
                        {this.state.restrictions.map((restriction, inx) => {
                            if (inx === 0) return null;
                            return (
                                <div className="row" key={restriction.id} style={{marginRight: 5}}>
                                    <RestrictionRow restriction={restriction} editable={this.props.editable} onRestrictionStatusUpdated={this.onRestrictionStatusUpdated.bind(this)}/>
                                </div>
                            )})}
                    </div>
                }
            </>
            :
            <div className="row">
                <div className="col-12">
                    No record found.
                </div>
            </div>
    }

    showAddRestriction = () => {
        this.setState({addRestrictionModalVisible: true});
    }

    onRestrictionAdded = (restriction) => {
        this.setState(state => {
            const restrictions = state.restrictions.concat(restriction);
            return {
                restrictions,
                addRestrictionModalVisible: false
            }
        });
    }

    onRestrictionStatusUpdated() {
        this.setState({...this.state});
    }
}



class RestrictionRow extends React.Component {

    state = {};

    acceptRestriction = (restriction) => {
        RemotingService.postRemoteCall('api/restriction/' + restriction.id + '/accept', null, (result) => {
            restriction.status = result.status;
            this.setState({...this.state});
            this.props.onRestrictionStatusUpdated();
        });
    }

    rejectRestriction = (restriction) => {
        RemotingService.postRemoteCall('api/restriction/' + restriction.id + '/reject', null, (result) => {
            restriction.status = result.status;
            this.setState({...this.state});
            this.props.onRestrictionStatusUpdated();
        });
    }

    revokeRestriction = (restriction) => {
        RemotingService.postRemoteCall('api/restriction/' + restriction.id + '/revoke', null, (result) => {
            restriction.status = result.status;
            this.setState({...this.state});
            this.props.onRestrictionStatusUpdated();
        });
    }

    restrictionDetails = (restriction) => {
        let reason = EnumRestrictionReason[restriction.reason];
        let type = EnumRestrictionType[restriction.type];
        let restrictedFrom = "";

        if (restriction.type == "STAFF") {
            restrictedFrom = restriction.staff.name;
        } else if (restriction.type == "DEPARTMENT") {
            restrictedFrom = restriction.department.combinedName;
        } else {
            restrictedFrom = type;
        }

        return <>
                    <div><b>Reason:</b> {reason}</div>
                    <div><b>Restricted From:</b> {restrictedFrom}</div>
                    {restriction.details && <div><b>Details:</b> {restriction.details}</div>}
                </>;
    }

    render() {
        const restriction = this.props.restriction;
        return (
            <>
                <div className="col-3">{EnumRestrictionStatus[restriction.status]}</div>
                <div className="col-2">
                        <Tooltip title={this.restrictionDetails(restriction)}>
                            <u>Details</u>
                        </Tooltip>
                </div>
                <div className="col-2">{restriction.expiryDate ? DateUtil.formatDateMMM(restriction.expiryDate) : 'Never'}</div>
                <div className="col-3">{restriction.requestedBy.name}</div>
                {this.props.editable &&
                <div className="col-1 text-right">
                    {
                        restriction.status == "REQUESTED" ?
                            <ActionMenu>
                                <ActionMenuItem icon={<FontAwesomeIcon icon={["fas", "check"]}/>}
                                                text="Accept"
                                                onClick={() => {
                                                    this.acceptRestriction(restriction)
                                                }}/>
                                <ActionMenuItem icon={<FontAwesomeIcon icon={["fas", "times"]}/>}
                                                text="Reject"
                                                onClick={() => {
                                                    this.rejectRestriction(restriction)
                                                }}/>
                            </ActionMenu> : null
                    }
                    {
                        restriction.status == "ACCEPTED" ?
                            <ActionMenu>
                                <ActionMenuItem icon={<FontAwesomeIcon icon={["fas", "times"]}/>}
                                                text="Revoke"
                                                onClick={() => {
                                                    this.revokeRestriction(restriction)
                                                }}/>
                            </ActionMenu> : null
                    }
                    {
                        restriction.status == "REJECTED" || restriction.status == "REVOKED" ?
                            <div className="dim14-action-menu">
                                <div className="icon-wrapper" style={{textAlign: 'center'}}>
                                    <FontAwesomeIcon icon={["fas", "ellipsis-v"]} color="gray"/>
                                </div>
                            </div> : null
                    }
                </div>
                }
            </>
        );
    }

}

export default Restrictions;