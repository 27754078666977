import React from "react";
import CancellationPolicyDxbTextEnum from "./enum/CancellationPolicyDxbTextEnum";

const Part1 = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <p>{CancellationPolicyDxbTextEnum.Paragraph1[selectedLanguage]}</p>
            <p>{CancellationPolicyDxbTextEnum.Paragraph2_Part1[selectedLanguage]}
                <b> {CancellationPolicyDxbTextEnum.Paragraph2_Bold_Part[selectedLanguage]} </b>
                {CancellationPolicyDxbTextEnum.Paragraph2_Part2[selectedLanguage]}
            </p>
        </>
    )
}

const Part2 = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <h4 className="text-center"
                style={{textAlign: "center"}}>{CancellationPolicyDxbTextEnum.Title3[selectedLanguage]}</h4>
            <div style={{margin: "0px 15px"}}>
                <p>{CancellationPolicyDxbTextEnum.Paragraph3_Part1[selectedLanguage]}
                    <b><u> {CancellationPolicyDxbTextEnum.Paragraph3_Bold_Part[selectedLanguage]} </u></b>
                    {CancellationPolicyDxbTextEnum.Paragraph3_Part2[selectedLanguage]}</p>
                <p>{CancellationPolicyDxbTextEnum.Paragraph4[selectedLanguage]}
                    <b><u
                        style={{fontSize: "18px"}}> {CancellationPolicyDxbTextEnum.Paragraph4_Bold_Part[selectedLanguage]}</u></b>
                </p>
                <p>{CancellationPolicyDxbTextEnum.Paragraph5_Part1[selectedLanguage]}
                    <b> {CancellationPolicyDxbTextEnum.Paragraph5_Bold_Part[selectedLanguage]} </b>
                    {CancellationPolicyDxbTextEnum.Paragraph5_Part2[selectedLanguage]}</p>
            </div>
        </>
    );

}

const Part3 = (props) => {
    const {selectedLanguage, patientName} = props;

    return (
        <>
            <p>{CancellationPolicyDxbTextEnum.Paragraph6[selectedLanguage]}</p>
            <p>{CancellationPolicyDxbTextEnum.Paragraph7[selectedLanguage]}</p>
            <p>{CancellationPolicyDxbTextEnum.Paragraph8_Part1[selectedLanguage]}<b> {patientName}</b>
                {CancellationPolicyDxbTextEnum.Paragraph8_Part2[selectedLanguage]}
            </p>
        </>
    );
}

export default {
    Part1,
    Part2,
    Part3,
};