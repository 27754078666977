import Enum from "../../../enums/Enum";

const EnumBlockType = new Enum({
    WORK: {name: "Work", bgColor: "#BEDBF1", textColor: "#1C4280", cutBgColor: "#d3ecf2"}, // TODO ???
    NEW_PATIENT: {name: "New Complaint", bgColor: "#3691D4", textColor: "#3691D4", cutBgColor: "#dcf7ac"},
    FOLLOW_UP: {name: "Follow Up", bgColor: "#87B42C", textColor: "#87B42C", cutBgColor: "#ececbb"},
    LUNCH: {name: "Lunch", bgColor: "#BCBCBC", textColor: "#FFFFFF", cutBgColor: "#ececbb"},
    BREAK: {name: "Break", bgColor: "#BCBCBC", textColor: "#FFFFFF", cutBgColor: "#dcf7ac"},
    MANAGEMENT: {name: "Management", bgColor: "#c69684", textColor: "#632b08", cutBgColor: "#d3ecf2"},
    PROCEDURE: {name: "Procedure", bgColor: "#9083F4", textColor: "#FFFFFF", cutBgColor: "#d3ecf2"}
});

EnumBlockType.TYPES_FOR_SCHEDULE_EDIT = [
    EnumBlockType.NEW_PATIENT,
    EnumBlockType.FOLLOW_UP,
    EnumBlockType.LUNCH,
    EnumBlockType.BREAK,
    EnumBlockType.MANAGEMENT
];

export default EnumBlockType;