import React, {Component} from "react";
import '../../../EMR.css';
import FocusEnum from "../../../treatment/enums/patienteducation/FocusEnum";
import TopicEnum from "../../../treatment/enums/patienteducation/TopicEnum";

export default class PatientEducationHistoryView extends Component {

    render() {
        const patientEducation = this.props.patientEducation;

        if (!patientEducation) {
            return <div className="pl-3">No information available</div>;
        }

        return (
            <div className="pl-3">
                {patientEducation.items &&
                <table className={"visit-history-table mb-3"}>
                    <tbody>
                    <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                        <th style={{minWidth: "100px"}}>Topic</th>
                        <th style={{minWidth: "100px"}}>Focus</th>
                        <th>Rationale</th>
                    </tr>
                    {patientEducation.items.map((m, index) => {
                        return (<tr key={"patientEducationView" + index} style={{backgroundColor: "white"}}>
                            <td>{m.topic == null ? "" : TopicEnum[m.topic].name}</td>
                            <td>{m.focus == null ? "" : FocusEnum[m.focus].name}</td>
                            <td>{m.topic == null ? "" : TopicEnum[m.topic].rationale}</td>
                        </tr>)
                    })}
                    </tbody>
                </table>
                }

                {patientEducation && patientEducation.understoodContent != null &&
                <div className={"row no-margin"}>
                    <div className={"col-3 font-weight-bold"}>Has the patient understood the education content
                        provided?
                    </div>
                    <div className={"col-1"}>
                        {this.yesNoTemplate(patientEducation.understoodContent)}
                    </div>
                    <div className={"col-8"}>{patientEducation.understoodContentDetail}</div>
                </div>
                }

                {patientEducation && patientEducation.moreExplanation != null &&
                <div className={"row no-margin"}>
                    <div className={"col-3 font-weight-bold"}>Does the patient need more explanation during the next
                        visit?
                    </div>
                    <div className={"col-1"}>
                        {this.yesNoTemplate(patientEducation.moreExplanation)}
                    </div>
                    <div className={"col-8"}>{patientEducation.moreExplanationDetail}</div>
                </div>
                }
            </div>
        );
    }

    yesNoTemplate = (field) => {
        return field ? 'Yes' : 'No';
    }
}
