export const BaseConstants = {
    SCHEDULES: 'SCHEDULES',
    PRESCRIPTIONS: 'PRESCRIPTIONS',
    PARAMETERS: 'PARAMETERS',
    NATIONALITIES: 'NATIONALITIES',
    COUNTRIES: 'COUNTRIES',
    LANGUAGES: 'LANGUAGES',
    RELIGIONS: 'RELIGIONS',
    MARITAL_STATUS: 'MARITAL_STATUS',
    RELATIONSHIP_TYPES: 'RELATIONSHIP_TYPES',
    ALLERGY_TYPES: 'ALLERGY_TYPES',
    ALLERGEN_TYPES: 'ALLERGEN_TYPES',
    ALLERGEN_REACTION_TYPES: 'ALLERGEN_REACTION_TYPES',
    ALLERGY_SEVERITIES: 'ALLERGY_SEVERITIES',
    LOOKUP_GENDERS: 'LOOKUP_GENDERS',
    EMPLOYMENT_STATUSES: 'EMPLOYMENT_STATUSES',
    EDUCATION_LEVELS: 'EDUCATION_LEVELS',
    SMOKING_STATUSES: 'SMOKING_STATUSES',
    SMOKING_DEVICES: 'SMOKING_DEVICES',
    DRINKING_STATUSES: 'DRINKING_STATUSES',
    SUBSTANCE_ABUSE_STATUSES: 'SUBSTANCE_ABUSE_STATUSES',
    MEDICATION_ROUTES: 'MEDICATION_ROUTES',
    MEDICATION_UNITS: 'MEDICATION_UNITS'
};
