import {Component} from "react";
import PropTypes from "prop-types";

export default class ContextMenuItem extends Component {

    static defaultProps = {
        onClick: () => {},
        label: "",
        icon: null,
        visible: true
    };

    static propTypes = {
        onClick: PropTypes.func,
        label: PropTypes.string,
        icon: PropTypes.any,
        visible: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.func
        ]),
    };

}