import React from "react";
import DateUtil from "../../../utils/DateUtil";
import TimezoneUtil from "../../../utils/TimezoneUtil";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {withRouter} from "react-router";
import Modal from "../../../components/modal/Modal";

class RoomCalendarEventModal extends React.Component {

    state = {
        visible: false,
        room: Object.assign({}, this.props.room),
        staff: Object.assign({}, this.props.staff),
        schedule: Object.assign({}, this.props.schedule),
    };

    show = () => {
        this.setState({visible: true});
    };

    hide = () => {
        this.setState({visible: false});
    };

    goToCalendar = () => {
        let link = '/appointment?selectedView=day' +
            '&selectedDate=' + DateUtil.formatDate(this.state.schedule.startMoment.toDate()) +
            '&selectedStaff=' + this.state.staff.id;

        this.props.history.push(link);
    };

    goToCalendarIcon = () => {
        return (
            <a className={"btn btn-success  text-white "} onClick={() => this.goToCalendar()}>
                <FontAwesomeIcon className={"text-white mr-1"} icon={["fas", "calendar-alt"]}/>
                <span>Show Appointment </span>

            </a>

        );
    }

    render() {
        const init = this.props.init;
        const onClick = this.show;
        const {room, staff, schedule} = this.props;

        return (
            <>
                {React.cloneElement(init, {onClick}, init.props.children)}
                <Modal visible={this.state.visible}
                       header="Room Schedule Detail"
                       fullWidth
                       maxWidth={"sm"}
                       closeAction={this.hide}
                       additionalActions={this.goToCalendarIcon()}>
                    <div className={"modal-body"}>
                        <div className={"row"}><label className={"col-sm-4 col-form-label font-weight-bold col-form-label-sm"}>Staff Name</label>
                            <div className={"col-sm-8"}>
                                {staff.name}
                            </div>
                        </div>
                        <div className={"row"}><label className={"col-sm-4 col-form-label font-weight-bold col-form-label-sm"}>Staff Speciality</label>
                            <div className={"col-sm-8"}>
                                {staff.specialityName}
                            </div>
                        </div>
                        <div className={"row"}><label className={"col-sm-4 col-form-label font-weight-bold col-form-label-sm"}>Room Info</label>
                            <div className={"col-sm-8"}>
                                {room.info}
                            </div>
                        </div>
                        <div className={"row"}><label className={"col-sm-4 col-form-label font-weight-bold col-form-label-sm"}>Room Specs</label>
                            <div className={"col-sm-8"}>
                                {room.specs}
                            </div>
                        </div>
                        <div className={"row"}><label className={"col-sm-4 col-form-label font-weight-bold col-form-label-sm"}>Date</label>
                            <div className={"col-sm-18"}>
                                {DateUtil.formatDateDo(schedule.startMoment)}
                            </div>
                        </div>

                        <div className={"row"}><label className={"col-sm-4 col-form-label font-weight-bold col-form-label-sm"}>Schedule</label>
                            <div className={"col-sm-8"}>
                                <ul className={"list-unstyled"}>
                                    <li className={"mb-1"}>
                                        <span>{DateUtil.formatDate(TimezoneUtil.atClinicZone(schedule.startMoment, room.clinicName), "hh:mm A")}</span>
                                        <span className={"mx-2 text-muted"}>to</span>
                                        <span>  {DateUtil.formatDate(TimezoneUtil.atClinicZone(schedule.endMoment, room.clinicName), "hh:mm A")}</span>
                                    </li>

                                        {
                                            schedule.mergedWith && schedule.mergedWith.map(r => (
                                                <li className={"mb-1"}>
                                                    <span>{DateUtil.formatDate(TimezoneUtil.atClinicZone(r.startMoment, room.clinicName), "hh:mm A")}</span>
                                                    <span className={"mx-2 text-muted"}>to</span>
                                                    <span>{DateUtil.formatDate(TimezoneUtil.atClinicZone(r.endMoment, room.clinicName), "hh:mm A")}</span>
                                                </li>
                                            ))
                                        }




                                </ul>



                            </div>
                        </div>




                    </div>
                </Modal>
            </>
        );
    }
}

export default withRouter(RoomCalendarEventModal);
