import React, {Component} from "react";
import '../../EMR.css';
import EndFeelEnum from "../../assessment/enums/rangeofmotion/EndFeelEnum";
import QualityEnum from "../../assessment/enums/rangeofmotion/QualityEnum";
import MovementEnum from "../../assessment/enums/rangeofmotion/MovementEnum";
import MuscleGroupsEnum from "../../assessment/enums/rangeofmotion/MuscleGroupsEnum";
import LocationEnum from "../../assessment/enums/rangeofmotion/LocationEnum";
import GradeEnum from "../../assessment/enums/rangeofmotion/GradeEnum";
import IndividualMusclesEnum from "../../assessment/enums/rangeofmotion/IndividualMusclesEnum";
import GreenSwitchWithMessage from "../../../../../components/switch/GreenSwitchWithMessage";
import MuscleStrengthResultEnum from "../../assessment/enums/rangeofmotion/MuscleStrengthResultEnum";

export default class RangeOfMotionEMRDashboardView extends Component {

    state = {}

    render() {

        const {rangeOfMotion, muscleGroup, individualMuscle} = this.props;

        return (
            <div>
                <div style={{display: "flex"}}>
                    <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Range Of Motion</div>
                    <GreenSwitchWithMessage
                        message={(rangeOfMotion && rangeOfMotion.none) && "Normal"}
                        checked={(rangeOfMotion && rangeOfMotion.items && rangeOfMotion.items.length !== 0) || (rangeOfMotion && rangeOfMotion.none)}/>
                </div>
                {rangeOfMotion && rangeOfMotion.items && <table className={"range-of-motion-table content-card overflow-wrap-anywhere"}>
                    <thead>
                    <tr>
                        <th colSpan={6}>Left</th>
                        <th className={"rangeOfMotionDarkColumn"}></th>
                        <th colSpan={6}>Right</th>
                    </tr>
                    <tr>
                        <th style={{width: "50px"}}>Details</th>
                        <th style={{width: "60px"}}>End Feel</th>
                        <th style={{width: "56px"}}>Quality</th>
                        <th style={{width: "35px"}}>Pain</th>
                        <th style={{width: "47px"}}>AROM</th>
                        <th style={{width: "47px"}}>PROM</th>

                        <th className={"rangeOfMotionDarkColumn"} style={{width: "85px"}}>Movement</th>

                        <th style={{width: "47px"}}>PROM</th>
                        <th style={{width: "47px"}}>AROM</th>
                        <th style={{width: "35px"}}>Pain</th>
                        <th style={{width: "56px"}}>Quality</th>
                        <th style={{width: "60px"}}>End Feel</th>
                        <th style={{width: "50px"}}>Details</th>
                    </tr>
                    </thead>
                    <tbody>
                    {rangeOfMotion.items.map((rowValue, index) => {
                        return (
                            <tr key={`${rowValue.movement?.trim()}`} style={{background: "white"}}>
                                <td>{rowValue.leftDetails}</td>
                                <td>{rowValue.leftEndFeel ? EndFeelEnum[rowValue.leftEndFeel].name : null}</td>
                                <td>{rowValue.leftQuality ? QualityEnum[rowValue.leftQuality].name : null}</td>
                                <td>{rowValue.leftPain}</td>
                                <td>{rowValue.leftAROM}</td>
                                <td>{rowValue.leftPROM}</td>

                                <td className={"rangeOfMotionDarkColumn"}>{`${rowValue.area} ${rowValue.movement == null && rowValue.movementComment == null ?
                                    "" :
                                    MovementEnum[rowValue.movement] != null ?
                                        MovementEnum[rowValue.movement].name :
                                        rowValue.movementComment}`}</td>

                                <td>{rowValue.rightPROM}</td>
                                <td>{rowValue.rightAROM}</td>
                                <td>{rowValue.rightPain}</td>
                                <td>{rowValue.rightQuality ? QualityEnum[rowValue.rightQuality].name : null}</td>
                                <td>{rowValue.rightEndFeel ? EndFeelEnum[rowValue.rightEndFeel].name : null}</td>
                                <td>{rowValue.rightDetails}</td>
                            </tr>
                        )
                    })
                    }
                    </tbody>
                </table>}

                <div style={{display: "flex", marginTop: "10px"}}>
                    <div className="content-label"
                         style={{margin: "auto", marginLeft: "0px", marginTop: 8, marginBottom: 6}}>Muscle Strength
                    </div>
                    <GreenSwitchWithMessage
                        message={(muscleGroup && muscleGroup.none) && "Normal"}
                        checked={(muscleGroup && muscleGroup.items && muscleGroup.items.length !== 0) ||
                        (individualMuscle && individualMuscle.items && individualMuscle.items.length !== 0)
                        || (muscleGroup && muscleGroup.none)}/>
                </div>
                {((muscleGroup && muscleGroup.items) || (individualMuscle && individualMuscle.items)) &&
                <div className={"row"} style={{margin: "-5px"}}>
                    {muscleGroup && muscleGroup.items &&
                    <div className={"col-6"}>
                        <div className="content-card m-0 h-auto">
                            <table className={"emr-table overflow-wrap-anywhere"}>
                                <thead>
                                <tr>
                                    <th style={{width: "90px"}}>Group Muscle</th>
                                    <th style={{width: "60px"}}>Location</th>
                                    <th style={{width: "70px"}}>Result</th>
                                    <th style={{width: "45px"}}>Grade</th>
                                    <th>Details</th>
                                </tr>
                                </thead>
                                <tbody>
                                {muscleGroup.items.map((rowValue, idx) => {
                                    return (
                                        <tr key={idx} style={{backgroundColor: "white"}}>
                                            <td>{rowValue.muscle ? MuscleGroupsEnum[rowValue.muscle].name : null}</td>
                                            <td>{rowValue.location ? LocationEnum[rowValue.location].name : null}</td>
                                            <td>{rowValue.result ? MuscleStrengthResultEnum[rowValue.result].name : null}</td>
                                            <td>{rowValue.grade ? GradeEnum[rowValue.grade].name : null}</td>
                                            <td>{rowValue.details}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>}

                    {individualMuscle && individualMuscle.items &&
                    <div className={"col-6"}>
                        <div className="content-card m-0 h-auto">
                            <table className={"emr-table overflow-wrap-anywhere"}>
                                <thead>
                                <tr>
                                    <th style={{width: "90px"}}>Individual Muscle</th>
                                    <th style={{width: "60px"}}>Location</th>
                                    <th style={{width: "70px"}}>Result</th>
                                    <th style={{width: "45px"}}>Grade</th>
                                    <th>Details</th>
                                </tr>
                                </thead>
                                <tbody>
                                {individualMuscle.items.map((rowValue, idx) => {
                                    return (
                                        <tr key={idx} style={{backgroundColor: "white"}}>
                                            <td>{rowValue.muscle ? IndividualMusclesEnum[rowValue.muscle].name : null}</td>
                                            <td>{rowValue.location ? LocationEnum[rowValue.location].name : null}</td>
                                            <td>{rowValue.result ? MuscleStrengthResultEnum[rowValue.result].name : null}</td>
                                            <td>{rowValue.grade ? GradeEnum[rowValue.grade].name : null}</td>
                                            <td>{rowValue.details}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>}
                </div>}
            </div>
        );
    }
}