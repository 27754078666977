import React from 'react';
import {Link} from 'react-router-dom';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {SelectInput} from "../../../components";
import EnumGender from "../../../enums/EnumGender";
import DateUtil from "../../../utils/DateUtil";
import {onlyUnique} from "../../../utils/ArrayUtil";
import {Image} from 'react-bootstrap';
import "./StaffTableView.css";
import PhoneNumberUtil from "../../../utils/PhoneNumberUtil";

class StaffTableView extends React.Component {
    state={}

    onGenderFilterChange(val) {
        this.staffListDt.filter(val, 'gender', 'equals');
        this.setState({genderFilterVal: val});
    }
    onSpecialityFilterChange(val) {
        this.staffListDt.filter(val, 'specialitiesString', 'contains');
        this.setState({specialityFilterVal: val});
    }

    onDepartmentFilterChange(val) {
        this.staffListDt.filter(val, 'departmentsString', 'contains');
        this.setState({departmentFilterVal: val});
    }

    onClinicFilterChange(val) {
        this.staffListDt.filter(val, 'clinicsString', 'contains');
        this.setState({clinicFilterVal: val});
    }

    indexTemplate(rowData, column){
        return <>{column.rowIndex + 1} </>;
    }

    staffNameTemplate(rowData, column) {
        let photoSrc = process.env.PUBLIC_URL + '/assets/patient_profile.png';
        if(rowData.thumbnail){
            photoSrc = 'data:image/jpeg;base64,' + rowData.thumbnail;
        }
        return (<>
            <Image  src={photoSrc} rounded width={"40px"}/>
            <Link to={'/staffprofile/'+rowData.id}>{rowData.name}</Link>
        </>);
    }

    genderTemplate(rowData, column) {
        return <>{EnumGender[rowData.gender]}</>;
    }

    mobilePhoneTemplate(rowData) {
        return PhoneNumberUtil.formatInternational(rowData.mobilePhone);
    }

    render(){
        //specialitiesString
        let genderFilter = (<SelectInput className="w-100" value={this.state.genderFilterVal} options={EnumGender.toArray()}
        displayProperty="name" valueProperty="key" searchEnabled={false}
        onChange={(val) => this.onGenderFilterChange(val)} />);

        let mappedStaffList = this.props.staffList.map((staff) => {
            let mappedStaff = {...staff};
            mappedStaff.age = staff.birthday ? DateUtil.getAge(staff.birthday) : '-';

            mappedStaff.specialitiesString = staff.speciality.name;
            mappedStaff.departmentsString = staff.departments.map(d=>d.name).join(", ");
            mappedStaff.clinicsString = staff.departments.map(d=>d.clinic.name).join(", ");
            return mappedStaff;
        });

        let allSpecialities = [];
        if(this.props.staffList){
            this.props.staffList.forEach(staff => {
                if(staff.speciality){
                     allSpecialities.push(staff.speciality.name);
                }
            });
        }

        let uniqueSpecialities = allSpecialities.filter(onlyUnique);

        let specialityFilter = (<SelectInput className="w-100" value={this.state.specialityFilterVal} options={uniqueSpecialities}
        onChange={(val) => this.onSpecialityFilterChange(val)} />);

        let uniqueDepartments = [...new Set(this.props.staffList.flatMap(staff => staff.departments).map(department => department.name))];
        let departmentFilter = (<SelectInput className="w-100" value={this.state.departmentFilterVal} options={uniqueDepartments}
            onChange={(val) => this.onDepartmentFilterChange(val)} />);
        let uniqueClinics = [...new Set(this.props.staffList.flatMap(staff => staff.departments).map(department => department.clinic.name))];
        let clinicFilter = (<SelectInput className="w-100" value={this.state.clinicFilterVal} options={uniqueClinics}
            onChange={(val) => this.onClinicFilterChange(val)} />);


        return (
            <>
                <div style={{marginLeft: '10px', marginRight: '30px'}}>
                    <DataTable
                        className="StaffTable"
                        value={mappedStaffList}
                        globalFilter={this.state.staffDataGridGlobalFilter}
                        ref={(el) => this.staffListDt = el}  responsive autoLayout
                        emptyMessage={<div style={{textAlign: "center"}}>There are no staff</div>} style={{minWidth:'450px', height:"100%", width:"100%"}}>
                        <Column field="id" header="STAFF ID" filter={true} filterMatchMode="contains" sortable={true} style={{maxWidth: "100px"}} />
                        <Column field="name" header="STAFF NAME" body={this.staffNameTemplate} filter={true} filterMatchMode="contains" sortable={true} style={{maxWidth: "300px"}}/>
                        <Column field="clinicsString" header="CLINIC" filter={true} filterElement={clinicFilter} sortable={true} style={{maxWidth: "125px"}}/>
                        <Column field="departmentsString" header="DEPARTMENT" filter={true} filterElement={departmentFilter} sortable={true} style={{maxWidth: "200px"}}/>
                        <Column field="specialitiesString" header="SPECIALITY" filter={true} filterElement={specialityFilter} sortable={true} style={{maxWidth: "200px"}}/>
                        <Column field="age" header="AGE" filter={true} filterMatchMode="contains" sortable={true} style={{maxWidth: "80px"}}/>
                        <Column field="gender" header="GENDER" body={this.genderTemplate} filter={true} filterElement={genderFilter} sortable={true} style={{maxWidth: "100px"}} />
                        <Column field="mobilePhone" header="MOBILE PHONE" body={this.mobilePhoneTemplate} filter={true} filterMatchMode="contains" sortable={true} />
                        <Column field="joiningDate" header="JOINING DATE" body={this.joiningDateTemplate} filterMatchMode="contains" sortable={true} style={{maxWidth: "125px"}}/>
                    </DataTable>
                </div>
            </>
        );
    }

    joiningDateTemplate(rowData) {
        if(rowData.joiningDate){
            return DateUtil.formatDateMMM(rowData.joiningDate);
        }
        return "";
    }
}

export default StaffTableView;