export default class RequiredValidator {

    getErrorMessage(prop) {
        return "Field is not equal to " + prop;
    }

    validate(value, prop) {
        return value === prop;
    }

}