import React, {Component} from 'react';
import {withRouter} from "react-router";
import {Button} from 'react-bootstrap';
import Modal from '../../../components/modal/Modal';
import DateUtil from "../../../utils/DateUtil";
import AppointmentUtil from '../../../utils/AppointmentUtil';
import RemotingService from "../../../services/remoting-service/RemotingService";
import EnumAppointmentStatus from "../enums/EnumAppointmentStatus";
import EnumAppointmentType from "../enums/EnumAppointmentType";
import EnumGender from "../../../enums/EnumGender";
import EnumNoteState from "../enums/EnumNoteState";
import PatientUtil from "../../../utils/PatientUtil";
import SpecialStatusIcons from "../../patient/SpecialStatusIcons";
import StringUtil from "../../../utils/StringUtil";

class ViewAppointment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedAppointment: this.props.selectedAppointment
        }
    }

    componentDidMount() {
        this.retrieveLanguages();
    }

    retrieveLanguages = () => {
        RemotingService.getRemoteCall('api/reference/language', null, (result) => {
            let languages = [];
            result.forEach(element => languages.push(element.name));
            this.setState({...this.state, languages: languages})
        });
    }

    appointmentViewModalBody = () => {
        if (this.props.selectedAppointment) {
            let openNotes = this.props.selectedAppointment.notes ? this.props.selectedAppointment.notes.filter(note => note.state === 'OPEN') : [];

            return (
                <div style={{minWidth:"500px", maxWidth:"520px"}}>
                    <div className={"container"}>
                        <div className={"row"} style={{marginBottom: "3px"}}>
                            <div className="col-md-4 dim14-form-label">
                                Appointment ID
                            </div>
                            <div className="col-md-8">
                                {this.props.selectedAppointment.id}
                            </div>
                        </div>

                        <div className={"row"} style={{marginBottom: "3px"}}>
                            <div className="col-md-4 dim14-form-label">
                                Staff Name
                            </div>
                            <div className="col-md-8">
                                {this.props.selectedAppointment.staff.name}
                            </div>
                        </div>

                        <div className={"row"} style={{marginBottom: "3px"}}>
                            <div className="col-md-4 dim14-form-label">
                                Start - End
                            </div>
                            <div className="col-md-8">
                                {DateUtil.formatDateMMM(this.props.selectedAppointment.start)
                                + ' ' + AppointmentUtil.appointmentTimeSlot(this.props.selectedAppointment)}
                            </div>
                        </div>

                        <div className={"row"} style={{marginBottom: "3px"}}>
                            <div className="col-md-4 dim14-form-label">
                                Appointment Status
                            </div>
                            <div className="col-md-8">
                                {EnumAppointmentStatus[this.props.selectedAppointment.status].name}
                            </div>
                        </div>

                        <div className={"row"} style={{marginBottom: "3px"}}>
                            <div className="col-md-4 dim14-form-label">
                                Appointment Type
                            </div>
                            <div className="col-md-8">
                                {EnumAppointmentType[this.props.selectedAppointment.patientType].name}
                            </div>
                        </div>

                        <div className={"row"} style={{marginBottom: "3px"}}>
                            <div className="col-md-4 dim14-form-label">
                                MRN
                            </div>
                            <div className="col-md-8">
                                {StringUtil.mrn(this.props.selectedAppointment.patient.mrn)}
                            </div>
                        </div>

                        <div className={"row"} style={{marginBottom: "3px"}}>
                            <div className="col-md-4 dim14-form-label">
                                Patient Name
                            </div>
                            <div className="col-md-8">
                                {PatientUtil.getPatientName(this.props.selectedAppointment.patient.firstName,
                                    this.props.selectedAppointment.patient.middleName,
                                    this.props.selectedAppointment.patient.lastName)}
                            </div>
                        </div>

                        <div className={"row"} style={{marginBottom: "3px"}}>
                            <div className="col-md-4 dim14-form-label">
                                Mobile Phone
                            </div>
                            <div className="col-md-8">
                                {this.props.selectedAppointment.patient.mobilePhone}
                            </div>
                        </div>

                        <div className={"row"} style={{marginBottom: "3px"}}>
                            <div className="col-md-4 dim14-form-label">
                                ID No
                            </div>
                            <div className="col-md-8">
                                {PatientUtil.getPatientId(this.props.selectedAppointment.patient)}
                            </div>
                        </div>

                        <div className={"row"} style={{marginBottom: "3px"}}>
                            <div className="col-md-4 dim14-form-label">
                                Gender
                            </div>
                            <div className="col-md-8">
                                {EnumGender[this.props.selectedAppointment.patient.gender]}
                            </div>
                        </div>

                        <div className={"row"} style={{marginBottom: "3px"}}>
                            <div className="col-md-4 dim14-form-label">
                                Date of Birth
                            </div>
                            <div className="col-md-8">
                                {this.props.selectedAppointment.patient.birthday ? DateUtil.formatDateMMM(this.props.selectedAppointment.patient.birthday) : null}
                            </div>
                        </div>

                        <div className={"row"} style={{marginBottom: "3px"}}>
                            <div className="col-md-4 dim14-form-label">
                                Age
                            </div>
                            <div className="col-md-8">
                                {this.props.selectedAppointment.patient.birthday ? DateUtil.getAge(this.props.selectedAppointment.patient.birthday) : null}
                            </div>
                        </div>

                        <div className={"row"} style={{marginBottom: "3px"}}>
                            <div className="col-md-4 dim14-form-label">
                                Direct Billing
                            </div>
                            <div className="col-md-8">
                                {this.props.selectedAppointment.insuranceExists ? "Yes" : "No"}
                            </div>
                        </div>

                        <div className={"row"} style={{marginBottom: "3px"}}>
                            <div className="col-md-4 dim14-form-label">
                                Special Status
                            </div>
                            <div className="col-md-8">
                                <div className="d-flex flex-wrap">
                                    <SpecialStatusIcons specialStatusList={this.props.selectedAppointment.patient.specialStatusList}/>
                                </div>
                            </div>
                        </div>

                        <div className={"row"} style={{marginBottom: "3px"}}>
                            <div className="col-md-4 dim14-form-label">
                                Desired Start Time
                            </div>
                            <div className="col-md-8">
                                {this.props.selectedAppointment.desiredStart ?
                                    DateUtil.formatDateMMM(new Date(this.props.selectedAppointment.desiredStart)) + ' ' +
                                    AppointmentUtil.formatWithClinicTime(this.props.selectedAppointment.desiredStart, this.props.selectedAppointment.room.clinicName) : ""}
                            </div>
                        </div>

                        <div className={"row"} style={{marginBottom: "3px"}}>
                            <div className="col-md-4 dim14-form-label">
                                Room Name
                            </div>
                            <div className="col-md-8">
                                {this.props.selectedAppointment.room.info}
                            </div>
                        </div>

                        <div className={"row"} style={{marginBottom: "3px"}}>
                            <div className="col-md-4 dim14-form-label">
                                Notes
                            </div>
                            <div className="col-md-8">
                                {openNotes.length > 0 ?
                                    openNotes.map((note, i) => {
                                        return (<li> {note.text + ' (' + EnumNoteState[note.state] + ')'} </li>)
                                    }) : <span className="font-italic text-black-50">There is no note in open state.</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return <></>;
        }
    }

    handleShowPatientDetail = () => {
        this.props.history.push("/patientdetail/" + this.props.selectedAppointment.patient.id + "/" + this.props.selectedAppointment.id + "/" +
            this.props.selectedView + "/" + DateUtil.formatDate(this.props.selectedDate));
    }

    renderViewAppointmentFooter = () => {
        return (
            <div className="dim14modal dim14modal-footer">
                <Button variant="primary" onClick={() => this.handleShowPatientDetail()}>Appointment Details</Button>
            </div>
        );
    }

    render() {
        return <Modal visible={true}
                      contentStyle={{maxHeight: 600, overflowY: 'auto'}}
                      header={"Appointment - " + (this.props.selectedAppointment ? PatientUtil.getPatientName(this.props.selectedAppointment.patient.firstName,
                          this.props.selectedAppointment.patient.middleName,
                          this.props.selectedAppointment.patient.lastName) : "")}
                      footer={this.renderViewAppointmentFooter()}
                      closeAction={() => this.props.handleViewAppointmentClose()}>
            {this.appointmentViewModalBody()}
        </Modal>
    }

}

export default withRouter(ViewAppointment);