import React, {Component} from 'react'
import RemotingService from "../../../../services/remoting-service/RemotingService";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import moment from "moment";
import {Button, Row} from "react-bootstrap";
import ClaimStatusEnum from "./ClaimStatus";
import {ColumnGroup} from 'primereact/columngroup';
import PrimeDateInput from "../../../../components/date-input/PrimeDateInput";
import ClaimActivities from "./ClaimActivities";
import {SelectInput} from "../../../../components";


export default class ClaimList extends Component {

    constructor(props) {
        super(props);
        this.dataTableRef = React.createRef();
        this.state = {
            items: [],
            errorMessages: [],
            selectedFromDate: new Date(),
            selectedToDate: new Date(),
            expandedClaims: null,
            selectedItems: []
        };
    }

    componentDidMount() {
        this.retrieveItems();
    }

    onStatusFilterChange = (event) => {
        console.log("event" , event);
        this.dataTableRef.current.filter(event, 'status', 'equals');
        this.setState({selectedStatusFilter: event  });
    }


    retrieveItems = () => {
        const fromDateValue = moment(this.state.selectedFromDate).format('YYYY-MM-DD');
        const toDateValue = moment(this.state.selectedToDate).format('YYYY-MM-DD');

        const params = {
            fromDate: fromDateValue,
            toDate: toDateValue
        };

        RemotingService.getRemoteCall(
            'api/insurance/coordinator/claimList',
            params,
            (items) => {
                this.setState({items: items});
            });
    }

    submitClaims = () => {
        const selectedClaimIds = this.state.selectedItems.map(claim => claim.id);
        RemotingService.postRemoteCall(`api/insurance/coordinator/claims/claims-submission`, selectedClaimIds, () => {
            this.retrieveItems();
        });
    }

    renderHeaders = () => {
        return <ColumnGroup>
            <Row>
                <Column selectionMode="multiple" headerStyle={{width: '3em'}} />
                <Column header="" colSpan={1}/>
                <Column header="Claim ID" colSpan={1}/>
                <Column header="Member ID" colSpan={1}/>
                <Column header="Payer ID" colSpan={1}/>
                <Column header="Emirates ID" colSpan={1}/>
                <Column header="Gross" colSpan={1}/>
                <Column header="Patient Payable" colSpan={1}/>
                <Column header="Net" colSpan={1}/>
                <Column header="Encounter" colSpan={5}/>
                <Column header="Diagnosis" colSpan={2}/>
                <Column header="Status" colSpan={1}/>
            </Row>
            <Row>
                <Column header="" colSpan={1}/>
                <Column header="" headerStyle={{width: '40px'}}/>
                <Column field="claimId" filter={true} filterMatchMode="contains"/>
                <Column field="memberId"/>
                <Column field="payerId" filter={true} filterMatchMode="contains"/>
                <Column field="emiratesId" filter={true} filterMatchMode="contains"/>
                <Column field="gross"/>
                <Column field="patientPayable"/>
                <Column field="net"/>

                <Column header="Facility ID" field="facilityID" filter={true} filterMatchMode="contains"/>
                <Column header="Type" field="type"/>
                <Column header="Patient ID" field="patientID"/>
                <Column header="Start" field="start"/>
                <Column header="End" field="end"/>


                <Column header="Level" field="level"/>
                <Column header="Code" field="code"/>

                <Column field="status" filter={true} filterMatchMode="custom"
                        filterElement={<SelectInput className="w-100" displayProperty="name" valueProperty="key"
                                                    options={ClaimStatusEnum.toArray()} searchEnabled={false}
                                                    value={this.state.selectedStatusFilter}
                                                    onChange={event => this.onStatusFilterChange(event)}
                        />}/>

            </Row>
        </ColumnGroup>;
    }


    claimRecordExpansionTemplate(claim) {
        return (
            <div className="appointment-expansion-wrapper row" style={{padding: "20px", animation: "fadeAnimation 1s"}}>
                <div className="col-12">
                    <ClaimActivities claim={claim} onClaimSubmitted={this.retrieveItems}/>
                </div>
            </div>
        );
    }

    statusTemplate(item) {
        return ClaimStatusEnum[item.status].name;
    }

    render() {

        return (
            <>
                <div className="float-left " style={{display: "flex"}}>
                    <PrimeDateInput showCalendarIcon variant="dialog" value={this.state.selectedFromDate} placeholder="Start Date"
                                    onChange={date => this.setState({selectedFromDate: date}, () => this.retrieveItems())}
                    />

                    <PrimeDateInput showCalendarIcon variant="dialog" value={this.state.selectedToDate} placeholder="End Date"
                                    onChange={date => this.setState({selectedToDate: date}, () => this.retrieveItems())}
                    />
                </div>
                <div className="float-right" style={{display: "flex"}}>
                    <Button disabled={this.state.selectedItems.length === 0}
                            variant="success"
                            onClick={() => this.submitClaims()}>
                        Submit Claims
                    </Button>
                </div>
                <div style={{padding: '10px'}} className="data-table-header-wrapper">
                    <div className="float-left">
                    </div>
                </div>
                <div style={{padding: '10px'}}>
                    <DataTable className="claimListTable" responsive headerColumnGroup={this.renderHeaders()}
                               emptyMessage={"No items found"}
                               scrollable ref={this.dataTableRef}
                               value={this.state.items}
                               onRowToggle={(e) => {
                                   this.setState({
                                       ...this.state,
                                       expandedClaims: e.data
                                   })
                               }}
                               expandedRows={this.state.expandedClaims}
                               rowExpansionTemplate={(row) => this.claimRecordExpansionTemplate(row)}
                               dataKey="id"
                               selection={this.state.selectedItems}
                               onSelectionChange={e => this.setState({selectedItems: e.value})}>
                        <Column selectionMode="multiple" headerStyle={{width: '3em'}}/>
                        <Column expander={true} style={{width: '30px'}}/>
                        <Column field="claimId" headerStyle={{width: '350px'}}/>
                        <Column field="memberId" style={{width: "7%" , wordWrap : "break-word"}}/>
                        <Column field="payerId"/>
                        <Column field="emiratesId" style={{width: "7%" , wordWrap : "break-word"}}/>
                        <Column field="gross"/>
                        <Column field="patientPayable"/>
                        <Column field="net"/>
                        <Column field="facilityId"/>
                        <Column field="type"/>
                        <Column field="patientId"/>
                        <Column field="start"/>
                        <Column field="end"/>
                        <Column field="level"/>
                        <Column field="code"/>
                        <Column field="status" body={this.statusTemplate}/>
                    </DataTable>
                </div>
            </>
        )
    }
}
