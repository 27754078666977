import React, {Component} from "react";
import '../Subjective.css';
import '../../EMR.css';
import './ComplaintView.css';
import {Grid} from "@material-ui/core";

export default class ReviewOfSystemView extends Component {

    dataFields = [
        {
            title: "Cardiovascular / Circulatory",
            field: "cardiovascularCirculatory"
        },{
            title: "Respiratory",
            field: "respiratory"
        },{
            title: "Head, Neck, Eyes",
            field: "headNeckEyes"
        },{
            title: "ENT",
            field: "ent"
        },{
            title: "Skin, Hair, Nails",
            field: "skinHairNails"
        },{
            title: "Lymphatic / Immune",
            field: "lymphaticImmune"
        },{
            title: "Endocrine",
            field: "endocrine"
        },{
            title: "Gastrointestinal",
            field: "gastrointestinal"
        },{
            title: "Genitourinary",
            field: "genitourinary"
        },{
            title: "Musculoskeletal",
            field: "musculoskeletal"
        },{
            title: "Neurological",
            field: "neurological"
        },
    ]

    render() {
        if(this.props.reviewOfSystem == null) {
            return (<></>);
        }

        let contents = this.getContents();

        return(
                <div style={{margin: "0px 5px", padding: "15px 0px"}}>
                    {contents}
                </div>
        );
    }

    getContents() {
        let contents = [];
        this.dataFields.map(((data, idx) => {
            if(this.props.reviewOfSystem[data.field] != null
                && this.props.reviewOfSystem[data.field].length > 0) {
                contents.push(
                    <Grid key={idx} container alignItems={"center"} className={"content-card content-row no-margin"}>
                        <Grid item xs style={{maxWidth: 240}} className={"content-card-title"}>
                            <h6 className={"no-margin"}>{data.title}</h6>
                        </Grid>
                        <Grid item xs>
                            <div style={{borderLeft: "1px gainsboro solid", padding: "10px"}}>
                                <div>
                                    {this.props.reviewOfSystem[data.field].join(", ")}
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                );
                contents.push(<br key={"key"+idx}/>);
            }
        }));

        contents.push(<br key={"key"}/>);
        return contents;
    }
}