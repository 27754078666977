import React from 'react';
import clsx from "clsx";

class PageTitle extends React.Component {

    render() {
        return (
            <div className={clsx("PageTitle", this.props.className)} style={this.props.style}>
                <h5 className={clsx(this.props.labelClassName, "m-0")}>{this.props.title}&nbsp;</h5>
            </div>
        );
    }
}

export default PageTitle;