import React, {Component} from 'react';
import {FormSectionHeader} from "../../public/FormSectionHeader";
import IntakeFormTextEnum from "../enum/IntakeFormTextEnum";
import {YesNoRadioInput} from "../../public/YesNoRadioInput";
import FamilySelect from "../../FamilySelect";

class PublicIntakeFormHealthHistory extends Component {
    state = {}

    render() {
        const {
            form, selectedLanguage, enumLabel, forceFormUpdate, selfFamilyDescribeField,
            textField, onChange
        } = this.props

        return (
            <>
                <FormSectionHeader header={IntakeFormTextEnum.Health_History[selectedLanguage]}/>

                <div className="row">
                    <label className="col-form-label">
                        {IntakeFormTextEnum.You_or_Family_Suffer[selectedLanguage]}
                    </label>
                </div>
                <div className="row justify-content-end border-top">
                    <div
                        className="col-2 text-center font-weight-bold">{IntakeFormTextEnum.Self[selectedLanguage]}</div>
                    <div
                        className="col-2 text-center font-weight-bold">{IntakeFormTextEnum.Family[selectedLanguage]}</div>
                    <div className="col-2"></div>
                </div>

                <div className="row border-top border-bottom">
                    <div className="col-6 d-flex justify-content-end align-items-center">
                        <label
                            className="font-weight-bold">{IntakeFormTextEnum.Mark_All[selectedLanguage]}</label>
                    </div>
                    <div className="col-2 d-flex align-items-center justify-content-center">
                        <YesNoRadioInput
                            value={this.state.markAllSelf}
                            onValueChange={(value) => {
                                form.cancerSelf = value;
                                form.diabetesSelf = value;
                                form.highBloodPressureSelf = value;
                                form.heartDiseaseSelf = value;
                                form.osteoporosisSelf = value;
                                form.osteoarthritisSelf = value;
                                form.rheumatoidArthritisSelf = value;
                                form.neurologicDiseaseSelf = value;
                                form.ulcersGerdRefluxSelf = value;
                                form.kidneyLiverDiseaseSelf = value;
                                forceFormUpdate();
                            }}
                            enumLabel={enumLabel}
                        />
                    </div>
                    <div className="col-2 d-flex align-items-center justify-content-center">
                        <YesNoRadioInput
                            value={this.state.markAllFamily}
                            onValueChange={(value) => {
                                form.cancerFamily = value;
                                form.diabetesFamily = value;
                                form.highBloodPressureFamily = value;
                                form.heartDiseaseFamily = value;
                                form.osteoporosisFamily = value;
                                form.osteoarthritisFamily = value;
                                form.rheumatoidArthritisFamily = value;
                                form.neurologicDiseaseFamily = value;
                                form.ulcersGerdRefluxFamily = value;
                                form.kidneyLiverDiseaseFamily = value;
                                if (value === "No") {
                                    form.cancerFamilyWho = null;
                                    form.diabetesFamilyWho = null;
                                    form.highBloodPressureFamilyWho = null;
                                    form.heartDiseaseFamilyWho = null;
                                    form.osteoporosisFamilyWho = null;
                                    form.osteoarthritisFamilyWho = null;
                                    form.rheumatoidArthritisFamilyWho = null;
                                    form.neurologicDiseaseFamilyWho = null;
                                    form.ulcersGerdRefluxFamilyWho = null;
                                    form.kidneyLiverDiseaseFamilyWho = null;
                                }
                                forceFormUpdate();
                            }}
                            enumLabel={enumLabel}
                        />
                    </div>
                    <div className="col-2"></div>
                </div>

                {selfFamilyDescribeField(
                    form,
                    "cancerSelf",
                    "cancerFamily",
                    "cancerFamilyWho",
                    IntakeFormTextEnum.Cancer[selectedLanguage],
                    enumLabel
                )}
                {selfFamilyDescribeField(
                    form,
                    "diabetesSelf",
                    "diabetesFamily",
                    "diabetesFamilyWho",
                    IntakeFormTextEnum.Diabetes[selectedLanguage],
                    enumLabel
                )}
                {selfFamilyDescribeField(
                    form,
                    "highBloodPressureSelf",
                    "highBloodPressureFamily",
                    "highBloodPressureFamilyWho",
                    IntakeFormTextEnum.High_Blood_Pressure[selectedLanguage],
                    enumLabel
                )}
                {selfFamilyDescribeField(
                    form,
                    "heartDiseaseSelf",
                    "heartDiseaseFamily",
                    "heartDiseaseFamilyWho",
                    IntakeFormTextEnum.Heart_Disease[selectedLanguage],
                    enumLabel
                )}
                {selfFamilyDescribeField(
                    form,
                    "osteoporosisSelf",
                    "osteoporosisFamily",
                    "osteoporosisFamilyWho",
                    IntakeFormTextEnum.Osteoporosis[selectedLanguage],
                    enumLabel
                )}
                {selfFamilyDescribeField(
                    form,
                    "osteoarthritisSelf",
                    "osteoarthritisFamily",
                    "osteoarthritisFamilyWho",
                    IntakeFormTextEnum.Osteoarthritis[selectedLanguage],
                    enumLabel
                )}
                {selfFamilyDescribeField(
                    form,
                    "rheumatoidArthritisSelf",
                    "rheumatoidArthritisFamily",
                    "rheumatoidArthritisFamilyWho",
                    IntakeFormTextEnum.Rheumatoid_Arthritis[selectedLanguage],
                    enumLabel
                )}
                {selfFamilyDescribeField(
                    form,
                    "neurologicDiseaseSelf",
                    "neurologicDiseaseFamily",
                    "neurologicDiseaseFamilyWho",
                    IntakeFormTextEnum.Neurologic_Disease[selectedLanguage],
                    enumLabel
                )}
                {selfFamilyDescribeField(
                    form,
                    "ulcersGerdRefluxSelf",
                    "ulcersGerdRefluxFamily",
                    "ulcersGerdRefluxFamilyWho",
                    IntakeFormTextEnum.Ulcers_GERD_Acid_Reflux[selectedLanguage],
                    enumLabel
                )}
                {selfFamilyDescribeField(
                    form,
                    "kidneyLiverDiseaseSelf",
                    "kidneyLiverDiseaseFamily",
                    "kidneyLiverDiseaseFamilyWho",
                    IntakeFormTextEnum.Kidney_Liver_Disease[selectedLanguage],
                    enumLabel
                )}
                <div className="row">
                    <div class="container-fluid p-0">
                        <div class="panel bg-grey d-flex">
                            <div class="col">
                                <div class="form-group">
                                    <label
                                        className="col-form-label">{IntakeFormTextEnum.Other[selectedLanguage]}</label>
                                    {textField(form, "sufferFromOtherSelf")}
                                </div>
                            </div>
                            <div class="col">
                                <div className="form-group">
                                    <label
                                        className="col-form-label">{IntakeFormTextEnum.If_Family_Who[selectedLanguage]}</label>
                                    <FamilySelect value={form.sufferFromOtherFamily}
                                                  style={{width: 150}}
                                                  enumLabel={enumLabel}
                                                  onChange={(value) =>
                                                      onChange("sufferFromOtherFamily", value)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PublicIntakeFormHealthHistory;