import React, {Component} from "react";
import InterventionTypeEnum from "../../treatment/enums/referral/InterventionTypeEnum";

export default class ReferralInterventionsReportView extends Component {

    render() {
        return (
            <>
                {
                    this.props.referral != null && this.props.referral.interventions.length > 0 &&
                    <div>
                        <div>
                            <b>Interventions</b>
                            <div>The following interventions are requested</div>
                            <table className={"visit-history-table"}>
                                <tbody>
                                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                                    <th>Type</th>
                                    <th>Details</th>
                                    <th>Recommendations</th>
                                    <th>Remarks</th>
                                </tr>
                                {
                                    this.props.referral.interventions.map((item, index) => {
                                            return (
                                                <tr key={index} style={{backgroundColor: "white"}}>
                                                    <td>{item.type ? InterventionTypeEnum[item.type].name : null}</td>
                                                    <td>{item.details}</td>
                                                    <td>{item.recommendations}</td>
                                                    <td>{item.remarks}</td>
                                                </tr>);
                                        }
                                    )
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </>
        )
    }

}
