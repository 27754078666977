import React, {Component} from "react";
import '../../VisitHistory.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class RiskOfHarmHistory extends Component {

    render() {
        const {risk} = this.props;

        if (!risk) {
            return <div className="pl-3">No information available</div>;
        }

        return (
            <div className="pl-3">
                <div className={"row no-margin"}>
                    <div className={"col-4 font-weight-bold"}>Is there risk of harm from planned care or treatment:
                    </div>
                    <div className={"col-8 display-flex"}>{risk.isAllGood ? 'No' : 'Yes'}</div>
                </div>

                {risk && risk.whatRisk &&
                <div className={"row no-margin"}>
                    <div className={"col-4 font-weight-bold"}>If yes, what risk?</div>
                    <div className={"col-8 display-flex"}>{risk.whatRisk}</div>
                </div>
                }

                {risk && risk.hasPatientInformed != null &&
                <div className={"row no-margin"}>
                    <div className={"col-4 font-weight-bold"}>If yes, has the patient been informed?</div>
                    <div className={"col-2"}>
                        {this.riskOfHarmYesNoTemplate(risk.hasPatientInformed)}
                    </div>
                    <div className={"col-6"}>{risk.patientInformedDetail}</div>
                </div>
                }

                {risk && risk.didPatientGiveConsent != null &&
                <div className={"row no-margin"}>
                    <div className={"col-4 font-weight-bold"}>If yes, did the patient give consent?</div>
                    <div className={"col-2"}>
                        {this.riskOfHarmYesNoTemplate(risk.didPatientGiveConsent)}
                    </div>
                    <div className={"col-6"}>{risk.patientGiveConsentDetail}</div>
                </div>
                }

                {risk && risk.arePrecautionsNecessary != null &&
                <div className={"row no-margin"}>
                    <div className={"col-4 font-weight-bold"}>Are extra precautions necessary?</div>
                    <div className={"col-2"}>
                        {this.riskOfHarmYesNoTemplate(risk.arePrecautionsNecessary)}
                    </div>
                    <div className={"col-6"}>{risk.precautionsNecessaryDetail}</div>
                </div>
                }
            </div>
        );
    }

    riskOfHarmYesNoTemplate = (field) => {
        let yesNoDiv;
        if (field) {
            yesNoDiv = (
                <div>
                    <FontAwesomeIcon icon={["fas", "check"]} color={"#7aae00"} style={{marginRight: "5px"}}/>
                    Yes
                </div>
            )
        } else {
            yesNoDiv = (
                <div>
                    <FontAwesomeIcon icon={["fas", "times"]} color={"red"} style={{marginRight: "8px"}}/>
                    No
                </div>
            )
        }
        return yesNoDiv;
    }

}
