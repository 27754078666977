import React, {Component} from 'react';
import TextInput from "../../components/text-input/TextInput";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Select from "react-select";
import StringUtil from "../../utils/StringUtil";
import IntakeFormTextEnum from "./intakeform/enum/IntakeFormTextEnum";

class FormAllergiesSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.state.allergy = this.props.allergy;
    }

    render() {

        let allergenDivs = this.getAllergenDivs();
        let medicationDivs = this.getMedicationDivs();

        return (
            <div className={"container"}>
                {this.getMedicationSection()}
                <div className={"row no-margin"}>
                    <div className={"div-list-wrapper"}>
                        {medicationDivs}
                    </div>
                </div>
                {this.getAllergenSection()}
                <div className={"row no-margin"}>
                    <div className={"div-list-wrapper"}>
                        {allergenDivs}
                    </div>
                </div>
            </div>
        );
    }

    getMedicationSection = () => {
        const {selectedLanguage} = this.props;
        return (
            <div className={"row mx-0 mt-0 mb-1"}>
                <label className={"col-md-3"}><b>{IntakeFormTextEnum.Allergy_Medication[selectedLanguage]}</b></label>
                <div className={"col-md-4"}>
                    <TextInput value={this.state.activeIngredient || ''}
                               placeholder={IntakeFormTextEnum.Medication_Allergy_Name[selectedLanguage]}
                               disabled={this.props.readOnly}
                               onChange={value => this.setState({activeIngredient: value})}
                               style={{fontSize: "14px", width: "100%"}}
                    />
                </div>
                <div className={"col-md-4"}>
                    <TextInput value={this.state.ingredientDetail || ''}
                               placeholder={IntakeFormTextEnum.Detail[selectedLanguage]}
                               disabled={this.props.readOnly}
                               onChange={value => this.setState({ingredientDetail: value})}
                               style={{fontSize: "14px", width: "100%"}}
                    />
                </div>
                <div className={"col-md-1"} style={{flexFlow: "row-reverse", display: "flex"}}>
                    {!this.props.readOnly && <Button className={"emr-plus-button"}
                            variant="success"
                            disabled={(StringUtil.isNullOrEmpty(this.state.activeIngredient))}
                            style={{fontSize: "12px"}}
                            onClick={this.addMedication.bind(this)}>
                        <FontAwesomeIcon icon={["fas", "plus"]}/>
                    </Button>}
                </div>
            </div>
        )
    }

    getAllergenSection = () => {
        const {selectedLanguage} = this.props;
        return (
            <div className={"row mx-0 mt-3 mb-1"}>
                <label className={"col-md-3"}><b>{IntakeFormTextEnum.Allergen[selectedLanguage]}</b></label>
                <div className={"col-md-4"}>
                    <Select
                        className={"emr-white-select-container"}
                        classNamePrefix={"emr-white-select"}
                        placeholder={IntakeFormTextEnum.Select_Type[selectedLanguage]}
                        value={this.state.allergen}
                        isDisabled={this.props.readOnly}
                        menuPlacement="top"
                        onChange={(event) => {
                            this.setState({
                                allergen: event
                            })
                        }}
                        options={[
                            {label: IntakeFormTextEnum.Food[selectedLanguage], value: 'Food'},
                            {label: IntakeFormTextEnum.Pet[selectedLanguage], value: 'Pet'},
                            {label: IntakeFormTextEnum.Seasonal[selectedLanguage], value: 'Seasonal'},
                            {label: IntakeFormTextEnum.Environmental[selectedLanguage], value: 'Environmental'},
                            {label: IntakeFormTextEnum.Other[selectedLanguage], value: 'Other'}
                        ]}
                    />
                </div>
                <div className={"col-md-4"}>
                    <TextInput value={this.state.allergenDetail || ''}
                               placeholder={IntakeFormTextEnum.Detail[selectedLanguage]}
                               disabled={this.props.readOnly}
                               onChange={value => this.setState({allergenDetail: value})}
                               style={{fontSize: "14px", width: "100%"}}
                    />
                </div>
                <div className={"col-1"} style={{flexFlow: "row-reverse", display: "flex"}}>
                    {!this.props.readOnly && <Button className={"emr-plus-button"}
                            variant="success"
                            disabled={this.state.allergen == null || StringUtil.isNullOrEmpty(this.state.allergenDetail)}
                            style={{fontSize: "12px"}}
                            onClick={this.addAllergen.bind(this)}>
                        <FontAwesomeIcon icon={["fas", "plus"]}/>
                    </Button>}
                </div>
            </div>
        )
    }

    addMedication() {
        if(this.state.allergy == null){
            this.state.allergy = {}
        }

        if (this.state.allergy.ingredients == null) {
            this.state.allergy.ingredients = [];
        }
        let text = this.state.activeIngredient;
        if (this.state.ingredientDetail != null) {
            text += ' (' + this.state.ingredientDetail + ')';
        }
        if (this.state.allergy.ingredients.indexOf(text) == -1) {
            this.state.allergy.ingredients.push(text);
        }

        this.setState({activeIngredient: null, activeMedication: null, ingredientDetail: null});
    }

    addAllergen() {
        if(this.state.allergy == null){
            this.state.allergy = {}
        }

        this.doAddAllergen(this.state.allergen.value);
        this.setState({allergen:null, allergenDetail: null});
    }

    doAddAllergen(fieldName) {
        let field = fieldName.toLowerCase();
        if (this.state.allergy[field] == null) {
            this.state.allergy[field] = [];
        }
        this.state.allergy[field].push(this.state.allergenDetail);
    }

    getAllergenDivs() {
        let divs = [];
        const {selectedLanguage} = this.props;
        this.addAllergenDivs(divs, IntakeFormTextEnum.Food[selectedLanguage], "food");
        this.addAllergenDivs(divs, IntakeFormTextEnum.Pet[selectedLanguage], "pet");
        this.addAllergenDivs(divs, IntakeFormTextEnum.Seasonal[selectedLanguage], "seasonal");
        this.addAllergenDivs(divs, IntakeFormTextEnum.Environmental[selectedLanguage], "environmental");
        this.addAllergenDivs(divs, IntakeFormTextEnum.Other[selectedLanguage], "other");
        return divs;
    }

    addAllergenDivs(divs, label, field) {
        if (this.state.allergy == null || this.state.allergy[field] == null) {
            return;
        }
        this.state.allergy[field].forEach((v, index) => divs.push(
            <div className={"div-list-item"} key={label + v + index}>
                <div>
                    {label}: {v}
                </div>
                {!this.props.readOnly && <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize: "10px", marginLeft: "2px"}}
                        onClick={() => {
                            const field = label.toLowerCase();
                            let i = this.state.allergy[field].indexOf(v);
                            this.state.allergy[field].splice(i, 1);
                            if (this.state.allergy[field].length == 0) {
                                this.state.allergy[field] = null;
                            }
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </Button>}
            </div>
        ));
    }

    getMedicationDivs() {
        if (this.state.allergy == null || this.state.allergy.ingredients == null) {
            return;
        }
        let divs = [];
        this.state.allergy.ingredients.forEach((v, index) => divs.push(
            <div className={"div-list-item"} key={v + index}>
                <div>
                    {v}
                </div>
                {!this.props.readOnly && <Button className={"emr-transparent-delete-button"}
                         variant="warning"
                         style={{fontSize: "10px", marginLeft: "2px"}}
                         onClick={() => {
                             let i = this.state.allergy.ingredients.indexOf(v);
                             this.state.allergy.ingredients.splice(i, 1);
                             if (this.state.allergy.ingredients.length == 0) {
                                 this.state.allergy.ingredients = null;
                             }
                             this.forceUpdate();
                         }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </Button>}
            </div>
        ));
        return divs;
    }
}

export default FormAllergiesSection;