import Enum from "../../../../../../enums/Enum";

const QualityEnum = new Enum({
    Smooth: {name: "Smooth"},
    Segmented: {name: "Segmented"},
    Restricted: {name: "Restricted"},
    Painful: {name: "Painful"},
    Shaking: {name: "Shaking"},
});

export default QualityEnum;