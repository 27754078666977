import Switch from "@material-ui/core/Switch";
import withStyles from "@material-ui/core/styles/withStyles";

const GreenSwitch = withStyles({
    switchBase: {
        color: "#B5B5B5",
        '&$checked': {
            color: "#FFFFFF",
        },
        '&$checked + $track': {
            backgroundColor: "#7BAC16",
            opacity: 1,
        },
    },
    checked: {},
    track: {
        backgroundColor: "#B5B5B5",
    },
})(Switch);

export default GreenSwitch;

