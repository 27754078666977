import React, {Component} from 'react';
import ObservationView from "../assessment/views/ObservationView";
import PalpationView from "../assessment/views/PalpationView";
import RangeOfMotionEMRDashboardView from "./assessment/RangeOfMotionEMRDashboardView";
import DermatomesEMRDashboardView from "./assessment/DermatomesEMRDashboardView";
import MyotomesEMRDashboardView from "./assessment/MyotomesEMRDashboardView";
import ReflexesEMRDashboardView from "./assessment/ReflexesEMRDashboardView";
import GreenSwitchWithMessage from "../../../../components/switch/GreenSwitchWithMessage";
import EMRDashboardUtil from "./utils/EMRDashboardUtil";

class EMRDashboardAssessment extends Component {
    render() {

        const lastVisit = this.props.visits[this.props.visits.length - 1];
        const {assessment} = lastVisit;

        const observation = EMRDashboardUtil.getFilledAssessmentField(this.props.visits, assessment.observation, "observation");
        const palpation = EMRDashboardUtil.getFilledAssessmentField(this.props.visits, assessment.palpation, "palpation");
        const rangeOfMotion = EMRDashboardUtil.getFilledAssessmentField(this.props.visits, assessment.rangeOfMotion, "rangeOfMotion");
        const {muscleGroup, individualMuscle} = EMRDashboardUtil.getFilledMuscleStrength(this.props.visits, assessment.muscleGroup, assessment.individualMuscle);
        const dermatomes = EMRDashboardUtil.getFilledAssessmentField(this.props.visits, assessment.dermatomes, "dermatomes");
        const myotomes = EMRDashboardUtil.getFilledAssessmentField(this.props.visits, assessment.myotomes, "myotomes");
        const {reflexes, reflexesExtra} = EMRDashboardUtil.getFilledReflexes(this.props.visits, assessment.reflexes, assessment.reflexesExtra);

        return (
            <div>
                <div className="content-label large mt-3">Assessment</div>
                {this.getObservationView(observation)}
                {this.getPalpationView(palpation)}
                {this.getRangeOfMotionView(rangeOfMotion, muscleGroup, individualMuscle)}
                {this.getDermatomesView(dermatomes, myotomes, reflexes, reflexesExtra)}
            </div>
        );
    }

    getPalpationView(palpation) {
        return <div className={"content-wrapper"}>
            <div style={{display: "flex"}}>
                <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Palpation</div>
                <GreenSwitchWithMessage
                    message={(palpation && palpation.none) ? "Normal" : ""}
                    checked={palpation
                            && (palpation.none || (palpation.items && palpation.items.length > 0))
                    }/>
            </div>
            <div>
                <PalpationView palpation={palpation}/>
            </div>
        </div>;
    }

    getObservationView(observation) {
        return <div className={"content-wrapper"}>
            <div style={{display: "flex"}}>
                <div className="content-label" style={{margin: "auto", marginLeft: "0px", marginBottom: 6}}>Observation</div>
                <GreenSwitchWithMessage
                    message={observation && observation.none ? "Normal" : null}
                    checked={observation
                            && (observation.none || (observation.items && observation.items.length > 0))
                    }/>
            </div>
            <div style={{marginTop: "-20px"}}>
                <ObservationView observation={observation}/>
            </div>
        </div>;
    }

    getRangeOfMotionView(rangeOfMotion, muscleGroup, individualMuscle) {
        return (
            <div className={"content-wrapper"}>
                <div>
                    <RangeOfMotionEMRDashboardView rangeOfMotion={rangeOfMotion} muscleGroup={muscleGroup} individualMuscle={individualMuscle}/>
                </div>
            </div>
        )
    }

    getDermatomesView(dermatomes, myotomes, reflexes, reflexesExtra){
        return (
            <div>
                <div className={"content-wrapper"}>
                    <div className={"row"} style={{margin: "-5px"}}>
                        <div className={"col-6"}>
                            <DermatomesEMRDashboardView dermatomes={dermatomes}/>
                        </div>
                        <div className={"col-6"}>
                            <MyotomesEMRDashboardView myotomes={myotomes}/>
                        </div>
                    </div>
                </div>
                <div className={"content-wrapper"}>
                    <div>
                        <ReflexesEMRDashboardView reflexes={reflexes} reflexesExtra={reflexesExtra}/>
                    </div>
                </div>
            </div>
        )
    }

}

export default EMRDashboardAssessment;