import React, {Component} from "react";
import EnumBlockType from "../enums/EnumBlockType";
import "./CalendarEvent.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class BreakEvent extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {event, timeZoneGetter, showEventContextMenu, eventContextMenuVisible, smallView, appointmentActionsVisible, deleteVisible, deleteFunc} = this.props;
        const timeZone = timeZoneGetter();

        return (
            <div className="calendar-event"
                 style={{
                     borderColor: EnumBlockType[event.type].bgColor,
                     color: EnumBlockType[event.type].textColor,
                     backgroundColor: EnumBlockType[event.type].bgColor,
                     ...(smallView ?
                         {
                             fontSize: 'small',
                             flexDirection: 'row-reverse',
                             justifyContent: 'space-around',
                             paddingLeft: 10,
                             paddingRight: 10,
                         } : {})
                 }}
                 onContextMenu={e => {
                     if (eventContextMenuVisible && showEventContextMenu) {
                         e.preventDefault();
                         showEventContextMenu(e, event);
                     }
                 }}
                 onMouseEnter={() => this.setState({mouseover: true})}
                 onMouseLeave={() => this.setState({mouseover: false})}
            >
                <div style={{marginRight: smallView ? 5 : null}}>
                    {event.startMoment.tz(timeZone).format("HH:mm")} - {event.endMoment.tz(timeZone).format("HH:mm")}
                </div>
                <div>
                    <b>{EnumBlockType[event.type].name}</b>
                </div>
                <div style={{
                    display: 'flex',
                    position: 'absolute',
                    top: smallView ? 0 : 5,
                    right: smallView ? 0 : 5
                }}>
                    {this.state.mouseover && deleteVisible &&
                    <FontAwesomeIcon icon={["fas", "trash"]} size="sm"
                                     onClick={() => deleteFunc(event)}/>}
                </div>
            </div>
        )
    }

}