import axios from 'axios';
import jwt_decode from 'jwt-decode';
import ReduxService from '../redux-service/ReduxService';
import RemotingService from "../remoting-service/RemotingService";
import {onlyUnique} from "../../utils/ArrayUtil";

let AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;

function login(username, password) {
    ReduxService.incrementRemotingOperationCount();
    let params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('username', username);
    params.append('password', password);

    return axios.post(AUTH_API_URL + 'oauth/token', params,
        {
            auth: {
                username: 'clientId',
                password: 'secret'
            }
        }).then(response => {
        let user = response.data;
        let decodedToken = jwt_decode(user.access_token);
        user.token = user.access_token;
        user.email = decodedToken.user_name;
        user.authorities = decodedToken.authorities ?? [];

        localStorage.setItem('user', JSON.stringify(user));

        return user;

    }).catch(error => {
        logout();
        let response = error.response;
        let msg = 'Authentication server is not available, try again after a while.';

        if (response) {
            if (response.data && response.data.error) {
                switch (response.data.error) {
                    case 'invalid_grant':
                    case 'unauthorized':
                        msg = 'Bad Credentials';
                        break;
                    default:
                        msg = '';
                }
            } else {
                msg = response.statusText;
            }
        }

        return Promise.reject(msg);

    }).finally(() => {
        ReduxService.decrementRemotingOperationCount();
    });
}

function logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('clinics');
    localStorage.removeItem('countries');
}

function setPassword(secret, password, successCallback = () => {}, errorCallback = () => {}) {
    const params = {secret, password};
    axios.post(AUTH_API_URL + 'set-password', params)
        .then(response => successCallback(response))
        .catch(error => errorCallback(error));
}

function retrieveUserData(callback) {
    RemotingService.getRemoteCall('api/user/me', null, callback);
}

function getClinicsByCountry() {
    let item = localStorage.getItem('clinicsByCountry');
    return item ? JSON.parse(item) : [];
}

function getClinics() {
    let item = localStorage.getItem('clinics');
    return item ? JSON.parse(item) : [];
}

function getCountries() {
    let item = localStorage.getItem('countries');
    return item ? JSON.parse(item) : [];
}

function getSelectedClinicCountriesInfo() {
    const clinics = getClinics();
    let uaeSelected = true;
    let kuwaitSelected = true;

    if (clinics && clinics.length > 0) {
        uaeSelected = false;
        kuwaitSelected = false;

        clinics.forEach(id => {
            if (id == 3) {
                kuwaitSelected = true;
            } else {
                uaeSelected = true;
            }
        });
    }

    return {uaeSelected, kuwaitSelected};
}

function getUser() { // TODO do not parse each time
    return JSON.parse(localStorage.getItem('user'));
}

function getAuthToken() {
    let user = getUser();

    return user ? user.token : undefined;
}

function isAuthenticated() {
    return !!getAuthToken();
}

function userHasAuthority(authority) {
    let user = getUser();

    return user && user.authorities.includes(authority);
}

function isSuperUser() {
    return getUser().type === 'SUPER_USER';
}

function isAdmin() {
    const user = getUser();
    return user.type === "ADMIN";
}

function isAdminOrManager() {
    const user = getUser();
    return user.type === "ADMIN"
        || user.type === "MANAGEMENT";
}

function isNurse() {
    const user = getUser();
    return user.type === "NURSE";
}

function isProvider() {
    const user = getUser();
    return user.type === "DOCTOR"
        || user.type === "THERAPIST";
}

function isAdminOrFromManagement() {
    const user = getUser();
    return user.type === "ADMIN"
        || user.type === "CLINIC_MANAGER"
        || user.type === "MANAGEMENT"
        || user.type === "SUPER_USER";
}

function isFromManagement() {
    const user = getUser();
    return user.type === "CLINIC_MANAGER"
        || user.type === "MANAGEMENT"
        || user.type === "SUPER_USER";
}

function getStaffId() {
    return getUser().staffId;
}

function getStaffSpeciality() {
    return getUser().specialityName;
}

function getSelectableClinics() {
    if (!getUser().clinics) return [];

    const clinicsByCountry = getClinicsByCountry();
    const userCountries =  getUser().clinics.map(clinic => clinic.country).filter(onlyUnique);

    return clinicsByCountry == null ? getUser().clinics :
        userCountries.map(c => clinicsByCountry[c]).reduce((c1, c2) => c1.concat(c2), []);
}

export default {
    login, logout, setPassword, retrieveUserData, getClinics, getCountries, getUser, getAuthToken, isAuthenticated,
    userHasAuthority, isSuperUser, isAdminOrManager, isProvider, isNurse, isManagerOrSuperUser: isAdminOrFromManagement,
    isFromManagement, getStaffSpeciality, getStaffId, getSelectedClinicCountriesInfo, getSelectableClinics,
    getClinicsByCountry, isAdmin
};