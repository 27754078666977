import React, {Component} from "react";
import PropTypes from "prop-types";
import {fabric} from "fabric";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./BodySilhouette.css";
import EnumBodySilhouetteSignTypes from "./EnumBodySilhouetteSignTypes";
import IntakeFormTextEnum from "../intakeform/enum/IntakeFormTextEnum";

class BodySilhouettePatientCanvas extends Component {
    state = {};

    static defaultProps = {
        width: 415,
        height: 345,
        zoom: 0.6,
    };

    static propTypes = {
        width: PropTypes.number,
        height: PropTypes.number,
        zoom: PropTypes.number,
    };

    componentDidMount() {
        const canvas = this.props.disabled
            ? new fabric.StaticCanvas(this.c)
            : new fabric.Canvas(this.c);
        const backgroundImagePath = this.props.selectedLanguage === "English"
            ? "/assets/forms/4body.png"
            : "/assets/forms/4body-ar.png";
        canvas.setBackgroundImage(
            process.env.PUBLIC_URL + backgroundImagePath,
            canvas.renderAll.bind(canvas)
        );
        canvas.setZoom(this.props.zoom);
        canvas.selection = false;
        canvas.moveCursor = "default";
        canvas.hoverCursor = "default";

        if (!this.props.disabled) {
            canvas.upperCanvasEl.className = "cross-cursor";
            let self = this;
            // canvas.upperCanvasEl.onclick = function (event) {
            //     self.props.onClickAddCross((event.offsetY / self.props.zoom), (event.offsetX / self.props.zoom));
            // }

            // Event above is not caught at mobile (tablet)
            canvas.on({
                "mouse:down": function (o) {
                    let pointer = canvas.getPointer(o.e);
                    self.props.onClickAddCross(pointer.y, pointer.x);
                },
            });
        }
        this.setState({canvas});
    }

    componentWillUnmount() {
        this.props.onPageChange(this.state.canvas.toJSON().objects);
    }

    render() {
        this.children = React.Children.map(this.props.children, (child) => {
            return child ? React.cloneElement(child, {
                canvas: this.state.canvas,
                selectable: false,
            }) : null;
        });

        if(this.state.canvas) {
            this.labels = [
     /*           <CanvasLabel canvas={this.state.canvas} label="Right" />,
                <CanvasLabel canvas={this.state.canvas} label="Front" left={207} />,
                <CanvasLabel canvas={this.state.canvas} label="Back" left={434} />,
                <CanvasLabel canvas={this.state.canvas} label="Left" left={615} />*/
            ];
        }

        const {width, height} = this.props;
        return (
            <div class="position-relative">
                <canvas
                    ref={(c) => (this.c = c)}
                    width={width}
                    height={height}
                    disabled={true}
                />
                {this.state.canvas && this.children}
      {/*          {this.state.canvas && this.labels}*/}
                {!this.props.disabled && (
                    <Button
                        className="undo-button"
                        variant="danger"
                        onClick={() => {
                            const {canvas} = this.state;
                            const item = canvas._objects[canvas._objects.length - 1];
                            if(item?.type != EnumBodySilhouetteSignTypes.LABEL){
                                canvas.remove(item);
                                this.props.onRemoveItem();
                            }
                        }}
                    >
                        <FontAwesomeIcon icon={["fas", "undo"]}/> {IntakeFormTextEnum.Undo[this.props.selectedLanguage]}
                    </Button>
                )}
            </div>
        );
    }
}

export default BodySilhouettePatientCanvas;
