import React from "react";
import {TextInput} from "../../../../components";
import EnumReferenceType from "../../enums/EnumReferenceType";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import Modal from "../../../../components/modal/Modal";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import NotificationService from "../../../../services/notification-service/NotificationService";

class EditReferenceModal extends React.Component {
  state = {
    reference: Object.assign({}, this.props.reference),
  };

  fieldChanged = (fieldName, value, validationCallback) => {
    const { reference } = this.state;
    reference[fieldName] = value;
    this.setState({ reference });
  };

  submit = () => {
    if (!ValidationUtil.isValid(this.formFields)) {
      NotificationService.showNotification({
        severity: "error",
        summary: "Validation Error",
        detail: "There are missing fields.",
      });
      return;
    }

    RemotingService.postRemoteCall(
      "api/patient/" + this.props.patientId + "/update-reference",
      this.state.reference,
      () => {
        this.props.hideModal();
        this.props.submitAction(this.state.reference);
      }
    );
  };

  render() {
    const { reference } = this.state;
    this.formFields = [];
    return (
      <>
        <Modal
          visible={this.props.visible}
          header="Update Reference"
          submitContent="Update"
          submitAction={this.submit}
          closeAction={this.props.hideModal}
        >
          <div className={"add-reference-modal dim14-form-row-align"}>
            <div className={"container"} style={{ maxWidth: "100%" }}>
              <div className="row" style={{ marginBottom: "5px" }}>
                <div className="col-md-4 dim14-form-label">Reference Type</div>
                <div className="col-md-8 dim14-base-input">
                  {EnumReferenceType[reference.referenceType]}
                </div>
              </div>

              {reference.referenceType && (
                <div className="row">
                  <div className="col-md-4 dim14-form-label">
                    {EnumReferenceType.getLabel(reference.referenceType)}
                  </div>
                  <div className="col-md-8 dim14-base-input">
                      <TextInput
                        value={reference.name}
                        onChange={(value) => this.fieldChanged("name", value)}
                      />
                  </div>
                </div>
              )}

              {reference.referenceType === "DRHOSPITAL" && (
                <div className="row">
                  <div className="col-md-4 dim14-form-label">Hospital Name</div>
                  <div className="col-md-8 dim14-base-input">
                      <TextInput
                        value={reference.hospital}
                        onChange={(value) =>
                          this.fieldChanged("hospital", value)
                        }
                      />
                  </div>
                </div>
              )}

              {/* {reference.referenceType && reference.referenceType !== "OTHER" && (
                <div className="row">
                  <div className="col-md-4 dim14-form-label">Subject</div>
                  <div className="col-md-8 dim14-base-input">
                    <TextInput
                      value={reference.subject}
                      onChange={(value) => this.fieldChanged("subject", value)}
                    />
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default EditReferenceModal;
