import React, {Component} from "react";
import '../Assessment.css';
import TextInput from "../../../../../components/text-input/TextInput";
import {NumberInput} from "../../../../../components";
import PulseLocationEnum from "../enums/vitalSigns/PulseLocationEnum.js";
import BloodPressureLocationEnum from "../enums/vitalSigns/BloodPressureLocationEnum.js";
import BloodPressurePositionEnum from "../enums/vitalSigns/BloodPressurePositionEnum.js";
import PulsePositionEnum from "../enums/vitalSigns/PulsePositionEnum.js";
import TemperatureLocationEnum from "../enums/vitalSigns/TemperatureLocationEnum.js";

import Select from "react-select";
import AuthService from "../../../../../services/auth-service/AuthService";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import BMIEnum from "../enums/vitalSigns/BMIEnum";
import DateUtil from "../../../../../utils/DateUtil";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import FormField from "../../../../../components/form/FormField";
import MaterialRadioButton from "../../../../../components/radio-input/MaterialRadioButton";
import VitalSignsUtil from "../utils/VitalSignsUtil";


export default class VitalSignsModal extends Component {
    state = {
        selectedTemperatureUnit: "CELSIUS",
        selectedHeightUnit:"CENTIMETER",
        selectedWeightUnit:"KILOGRAMS",
        temperatureAlertText:"",
        pulseAlertText:""
    };

    constructor(props) {
        super(props);
        this.state.vitalSigns = props.vitalSigns;
        this.state.vitalSigns.date = new Date();
        this.state.vitalSigns.doneBy = AuthService.getUser().name;

        if(this.state.vitalSigns.heightUnit == null)
            this.state.vitalSigns.heightUnit = this.state.selectedHeightUnit;

        if(this.state.vitalSigns.weightUnit == null)
        this.state.vitalSigns.weightUnit = this.state.selectedWeightUnit;

        if(this.state.vitalSigns.temperatureUnit == null)
        this.state.vitalSigns.temperatureUnit = this.state.selectedTemperatureUnit;
    }

    render() {
        return (
            <div className={"content-row emr-background w-100 px-3"}>

                <div className="w-100 m-4">
                    <div className={"row d-flex"}>
                        <div className="ml-0 mb-2 text-color-red"><i>* are mandatory fill fields</i></div>
                    </div>

                    <div className={"row d-flex"}>
                        <div className="content-label ml-0">Vital Signs <RedAsterisk renderForAllTypes/></div>
                    </div>

                    <div className={"row mt-3 justify-content-left align-items-center"}>
                        <div className={"col-1 p-0"}>
                            <div className="font-weight-bold" >Time Taken </div>
                        </div>
                        <div className={"col-2 p-0 m-0"}>
                            {DateUtil.formatDateTime12H(DateUtil.now())}
                        </div>
                    </div>

                    <div className={"row mt-3 justify-content-left align-items-center"} >
                        <div className={"col-1 p-0"}>
                            <div className="font-weight-bold">Temperature <RedAsterisk renderForAllTypes/></div>
                        </div>
                        <div className={"col-2 p-0 m-0"} style={{height: "25px"}}>
                            <FormField ref={formField => this.props.formFields.push(formField)} required
                                       min={this.state.vitalSigns.temperatureUnit === "FAHRENHEIT" ? 68 : 20}
                                       max={this.state.vitalSigns.temperatureUnit === "FAHRENHEIT" ? 130 : 50}
                                       validateOn={this.state.vitalSigns.temperature}>
                                <NumberInput value={this.state.vitalSigns.temperature}
                                             className = 'rowTextBox'
                                             placeholder={"Temperature"}
                                             id = {"temperatureInput"}
                                             min={this.state.vitalSigns.temperatureUnit === "FAHRENHEIT" ? 68 : 20}
                                             max={this.state.vitalSigns.temperatureUnit === "FAHRENHEIT" ? 130 : 50}
                                             allowDecimal
                                             maxFractionDigits={2}
                                             step=".1"
                                             onChange={(value) =>  this.setState(state => {
                                                 state.vitalSigns.temperature = value;
                                                 this.handleTemperatureAlert(value);
                                                 return state;
                                             })
                                             }
                                />
                            </FormField>
                            <span style={{color:"orange"}}>{this.state.temperatureAlertText}</span>
                        </div>
                        <div className={"col-2"}>
                            <MaterialRadioButton name="PoundKilogram"
                                                 value={this.state.vitalSigns.temperatureUnit}
                                                 onChange={this.onCFChanged.bind(this)}>
                                <div className={"row"}>
                                    <div className={"col-6"} >
                                        <FormControlLabel style={{margin: "0px", padding: "0px", fontSize:"4px"}}
                                                          value="CELSIUS" control={<Radio />}
                                                          label="°C"
                                        />
                                    </div>
                                    <div className={"col-6"} >
                                        <FormControlLabel style={{margin: "0px", padding: "0px", fontSize:"4px"}}
                                                          value="FAHRENHEIT" control={<Radio />}
                                                          label="°F"
                                        />
                                    </div>
                                </div>
                            </MaterialRadioButton>

                        </div>
                        <div className={"col-3 p-0 m-0"} style={{width:"100%"}}>
                            <TextInput value={this.state.vitalSigns.temperatureDetails}
                                       placeholder={"Details"}
                                       className = 'rowTextBox'
                                       onChange={value =>  this.setState(state => {
                                           state.vitalSigns.temperatureDetails = value;
                                           return state;
                                       })
                                       }
                                       style={{width:"100%"}}
                            />
                        </div>
                        <div className={"col-1"}>
                            <div className={"font-weight-bold d-flex justify-content-end w-100"}>
                                <div>Location</div>
                            </div>
                        </div>
                        <div className={"col-1 p-0 m-0  "}>
                            <Select
                                className={"emr-white-select-container"}
                                classNamePrefix={"emr-white-select"}
                                placeholder={"Location"}
                                onChange={event => {
                                    let r = event.value;
                                    this.setState(state => {
                                        state.vitalSigns.temperatureLocation = r;
                                        return state;
                                    })}}
                                value={this.state.vitalSigns.temperatureLocation!= null ?
                                    {value: TemperatureLocationEnum[this.state.vitalSigns.temperatureLocation].key,
                                        label: TemperatureLocationEnum[this.state.vitalSigns.temperatureLocation].name}: null}
                                options={TemperatureLocationEnum.toArray().map(v=> {
                                        return {value: v.key, label:v.name}
                                    }
                                )}
                            />
                        </div>
                    </div>

                    <div className={"row mt-3 justify-content-left align-items-center"} >
                        <div className={"col-1 p-0"}>
                            <div className="font-weight-bold">Pulse <RedAsterisk renderForAllTypes/></div>
                        </div>
                        <div className={"col-2 p-0 m-0"} style={{height: "25px"}}>
                            <FormField ref={formField => this.props.formFields.push(formField)} required min={0} max ={300}
                                       validateOn={this.state.vitalSigns.pulse}>
                                <NumberInput value={this.state.vitalSigns.pulse}
                                             className = 'rowTextBox'
                                             min={"0"}
                                             max={"300"}
                                             placeholder={"Pulse"}
                                             onChange={event => {
                                                 event = Number.isInteger(Number(event)) ? event : "" ;
                                                 this.setState(state => {
                                                     state.vitalSigns.pulse = event;
                                                     this.handlePulseAlert(event);
                                                     return state;
                                                 })}}
                                />
                            </FormField>
                            <span style={{color: "orange"}}>{this.state.pulseAlertText}</span>
                        </div>
                        <div className={"col-2 pl-2"} >
                            <div>
                                beats/min
                            </div>
                        </div>

                        <div className={"col-3 p-0 m-0"}>

                            <TextInput value={this.state.vitalSigns.pulseDetails}
                                       placeholder={"Details"}
                                       className = 'rowTextBox'
                                       onChange={event => {
                                           this.setState(state => {
                                               state.vitalSigns.pulseDetails = event;
                                               return state;
                                           })}}
                                       style={{width:"100%"}}
                            />
                        </div>
                        <div className={"col-1"}>
                            <div className={"font-weight-bold d-flex justify-content-end w-100"}>
                                <div>Location</div>
                            </div>
                        </div>
                        <div className={"col-1 p-0 m-0"}>
                            <Select
                                className={"emr-white-select-container"}
                                classNamePrefix={"emr-white-select"}
                                placeholder={"Location"}
                                maxMenuHeight={"200px"}
                                onChange={event => {
                                    let r = event.value;
                                    this.setState(state => {
                                        state.vitalSigns.pulseLocation = r;
                                        return state;
                                    });

                                }}
                                value={this.state.vitalSigns.pulseLocation!= null ?
                                    {value: PulseLocationEnum[this.state.vitalSigns.pulseLocation].key,
                                        label: PulseLocationEnum[this.state.vitalSigns.pulseLocation].name}: null}
                                options={PulseLocationEnum.toArray().map(v=> {
                                        return {value: v.key, label:v.name}
                                    }
                                )}

                            />
                        </div>
                        <div className={"col-1"}>
                            <div className={"font-weight-bold d-flex justify-content-end w-100"}>
                                <div>Position</div>
                            </div>
                        </div>
                        <div className={"col-1 p-0  "}>
                            <Select
                                className={"emr-white-select-container"}
                                classNamePrefix={"emr-white-select"}
                                placeholder={"Position"}
                                maxMenuHeight={"200px"}
                                onChange={event => {
                                    let r = event.value;
                                    this.setState(state => {
                                        state.vitalSigns.pulsePosition = r;
                                        return state;
                                    })}}
                                value={this.state.vitalSigns.pulsePosition!= null ?
                                    {value: PulsePositionEnum[this.state.vitalSigns.pulsePosition].key,
                                        label: PulsePositionEnum[this.state.vitalSigns.pulsePosition].name}: null}
                                options={PulsePositionEnum.toArray().map(v=> {
                                        return {value: v.key, label:v.name}
                                    }
                                )}
                            />
                        </div>

                    </div>
                    <div className={"row mt-3 justify-content-left align-items-center"} >
                        <div className={"col-1 p-0"}>
                            <div className="font-weight-bold" >Blood Pressure <RedAsterisk renderForAllTypes/></div>
                        </div>
                        <div className={"col-2 p-0"}>
                            <div className={"row m-0 p-0"}>
                                <div className={"col-5 m-0 p-0"}>
                                    <FormField ref={formField => this.props.formFields.push(formField)} required min={10} max={300}
                                               validateOn={this.state.vitalSigns.bloodPressureAbove}>
                                        <NumberInput value={this.state.vitalSigns.bloodPressureAbove}
                                                     className = 'rowTextBox'
                                                     min = {"10"}
                                                     max = {"300"}
                                                     onChange={event  => {
                                                         this.setState(state => {
                                                             event = Number.isInteger(Number(event)) ? event : "" ;
                                                             state.vitalSigns.bloodPressureAbove = event;
                                                             return state;
                                                         })}}
                                        />
                                    </FormField>
                                </div>
                                <div className={"col-2 text-center"}>
                                    <span>/</span>
                                </div>
                                <div className={"col-5 m-0 p-0"}>
                                    <FormField ref={formField => this.props.formFields.push(formField)} required  min={10} max={300}
                                               validateOn={this.state.vitalSigns.bloodPressureBelow}>
                                        <NumberInput value={this.state.vitalSigns.bloodPressureBelow}
                                                     className = 'rowTextBox'
                                                     min = {"10"}
                                                     max = {"300"}
                                                     onChange={event  => {
                                                         event = Number.isInteger(Number(event)) ? event : "" ;
                                                         this.setState(state => {
                                                             state.vitalSigns.bloodPressureBelow = event;
                                                             return state;
                                                         })}}
                                        />
                                    </FormField>
                                </div>
                            </div>
                        </div>

                        <div className={"col-2 pl-2" } >
                            <div>
                                mm/Hg
                            </div>
                        </div>
                        <div className={"col-3 p-0 m-0"}>
                            <TextInput value={this.state.vitalSigns.bloodPressureDetails}
                                       placeholder={"Details"}
                                       className = 'rowTextBox'
                                       style={{width:"100%"}}
                                       onChange={event => {
                                           this.setState(state => {
                                               state.vitalSigns.bloodPressureDetails = event;
                                               return state;
                                           })}}
                            />
                        </div>
                        <div className={"col-1"}>
                            <div className={"font-weight-bold d-flex justify-content-end w-100"}>
                                <div>Location</div>
                            </div>
                        </div>
                        <div className={"col-1 p-0 m-0"}>
                            <Select
                                className={"emr-white-select-container"}
                                classNamePrefix={"emr-white-select"}
                                placeholder={"Location"}
                                maxMenuHeight={"200px"}
                                onChange={event => {
                                    let r = event.value;
                                    this.setState(state => {
                                        state.vitalSigns.bloodPressureLocation = r;
                                        return state;
                                    })}}
                                value={this.state.vitalSigns.bloodPressureLocation!= null ?
                                    {value: BloodPressureLocationEnum[this.state.vitalSigns.bloodPressureLocation].key,
                                        label: BloodPressureLocationEnum[this.state.vitalSigns.bloodPressureLocation].name}: null}
                                options={BloodPressureLocationEnum.toArray().map(v=> {
                                        return {value: v.key, label:v.name}
                                    }
                                )}

                            />
                        </div>
                        <div className={"col-1"}>
                            <div className={"font-weight-bold d-flex justify-content-end w-100"}>
                                <div>Position</div>
                            </div>
                        </div>
                        <div className={"col-1 p-0  m-0"}>
                            <Select
                                className={"emr-white-select-container"}
                                classNamePrefix={"emr-white-select"}
                                placeholder={"Position"}
                                maxMenuHeight={"200px"}
                                onChange={event => {
                                    let r = event.value;
                                    this.setState(state => {
                                        state.vitalSigns.bloodPressurePosition = r;
                                        return state;
                                    })}}
                                value={this.state.vitalSigns.bloodPressurePosition!= null ?
                                    {value: BloodPressurePositionEnum[this.state.vitalSigns.bloodPressurePosition].key,
                                        label: BloodPressurePositionEnum[this.state.vitalSigns.bloodPressurePosition].name}: null}
                                options={BloodPressurePositionEnum.toArray().map(v=> {
                                        return {value: v.key, label:v.name}
                                    }
                                )}
                            />
                        </div>

                    </div>

                    <div className={"row mt-3 justify-content-left align-items-center"} >
                        <div className={"col-1 p-0"}>
                            <div className="font-weight-bold">Respiration</div>
                        </div>
                        <div className={"col-2 p-0 m-0"}>
                            <FormField ref={formField => this.props.formFields.push(formField)}  min={0} max={50}
                                       validateOn={this.state.vitalSigns.respiration}>
                            <NumberInput value={this.state.vitalSigns.respiration}
                                         className = 'rowTextBox mr-2'
                                         placeholder={"Respiration"}
                                         min = "0"
                                         max = "50"
                                         onChange={(event)  => {
                                             event = Number.isInteger(Number(event)) ? event : "" ;
                                             this.setState(state => {
                                                 state.vitalSigns.respiration = event;
                                                 return state;
                                             })}}
                            />
                            </FormField>
                        </div>
                        <div className={"col-2 pl-2" }>
                            <div>
                                breaths/min
                            </div>
                        </div>
                        <div className={"col-3 p-0 m-0"}>

                            <TextInput value={this.state.vitalSigns.respirationDetails}
                                       placeholder={"Details"}
                                       className = 'rowTextBox'
                                       style={{width:"100%"}}
                                       onChange={(event)  => {
                                           this.setState(state => {
                                               state.vitalSigns.respirationDetails = event;
                                               return state;
                                           })}}
                            />
                        </div>

                    </div>
                    <div className={"row mt-3 justify-content-left align-items-center"} >
                        <div className={"col-1 p-0"}>
                            <div className="font-weight-bold">Height <RedAsterisk renderForAllTypes/></div>
                        </div>
                        <div className={"col-2 p-0 m-0"}>
                            <FormField ref={formField => this.props.formFields.push(formField)} required
                                       min={this.state.vitalSigns.heightUnit === "FEET" ? 0.1 : 5}
                                       max={this.state.vitalSigns.heightUnit === "FEET" ? 9.9 : 300}
                                       validateOn={this.state.vitalSigns.height}>
                                <NumberInput value={this.state.vitalSigns.height}
                                             className='rowTextBox'
                                             id="heightInput"
                                             placeholder={"Height"}
                                             min={this.state.vitalSigns.heightUnit === "FEET" ? 0.1 : 5}
                                             max={this.state.vitalSigns.heightUnit === "FEET" ? 9.9 : 300}
                                             allowDecimal
                                             maxFractionDigits={2}
                                             step=".1"
                                             onChange={(event)  => {
                                                 this.setState(state => {
                                                     state.vitalSigns.height = event;
                                                     return state;
                                                 }, this.calculateBMI)}}
                                />
                            </FormField>
                        </div>
                        <div className={"col-2 pl-2" }>
                            <MaterialRadioButton name="CmFeet"
                                                 value={this.state.vitalSigns.heightUnit}
                                                 onChange={this.onCmFeetChanged.bind(this)}>
                                <div className={"row"} >
                                    <div className={"col-6"}>
                                        <FormControlLabel style={{margin: "0px", padding: "0px", fontSize:"4px"}}
                                                          value="CENTIMETER" control={<Radio />}
                                                          label="cm"
                                        />
                                    </div>
                                    <div className={"col-6"}>
                                        <FormControlLabel style={{margin: "0px", padding: "0px", fontSize:"4px"}}
                                                          value="FEET" control={<Radio />}
                                                          label="feet"
                                        />
                                    </div>
                                </div>
                            </MaterialRadioButton>
                        </div>
                        <div className={"col-3 p-0 m-0"}>

                            <TextInput value={this.state.vitalSigns.heightDetails}
                                       placeholder={"Details"}
                                       className = 'rowTextBox'
                                       style={{width:"100%"}}
                                       onChange={event => {
                                           this.setState(state => {
                                               state.vitalSigns.heightDetails = event;
                                               return state;
                                           })}}
                            />
                        </div>

                    </div>
                    <div className={"row mt-3 justify-content-left align-items-center"} >
                        <div className={"col-1 p-0"}>
                            <div className="font-weight-bold">Weight <RedAsterisk renderForAllTypes/></div>
                        </div>
                        <div className={"col-2 p-0 m-0"}>
                            <FormField ref={formField => this.props.formFields.push(formField)} required
                                       min={this.state.vitalSigns.weightUnit === "POUNDS" ? 6 : 3}
                                       max={this.state.vitalSigns.weightUnit === "POUNDS" ? 1000 : 500}
                                       validateOn={this.state.vitalSigns.weight}>
                                <NumberInput value={this.state.vitalSigns.weight}
                                             className = 'rowTextBox'
                                             placeholder={"Weight"}
                                             allowDecimal
                                             maxFractionDigits={2}
                                             step=".1"
                                             min={this.state.vitalSigns.weightUnit === "POUNDS" ? 6 : 3}
                                             max={this.state.vitalSigns.weightUnit === "POUNDS" ? 1000 : 500}
                                             id={"weightInput"}
                                             onChange={(event)  => {
                                                 this.setState(state => {
                                                     state.vitalSigns.weight = event;
                                                     return state;
                                                 },  this.calculateBMI)}}
                                />
                            </FormField>
                        </div>
                        <div className={"col-2 pl-2" }>
                            <MaterialRadioButton name="PoundKilogram"
                                                 value={this.state.vitalSigns.weightUnit}
                                                 onChange={this.onPoundKilogramsChanged.bind(this)}>
                                <div className={"row"}>
                                    <div className={"col-6"}>
                                        <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "10px"}}
                                                          value="KILOGRAMS" control={<Radio/>}
                                                          label="kgs"
                                        />
                                    </div>
                                    <div className={"col-6"}>
                                        <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "10px"}}
                                                          value="POUNDS" control={<Radio/>}
                                                          label="lbs"
                                        />
                                    </div>
                                </div>
                            </MaterialRadioButton>
                        </div>
                        <div className={"col-3 p-0 m-0"}>
                            <TextInput value={this.state.vitalSigns.weightDetails}
                                       placeholder={"Details"}
                                       className = 'rowTextBox'
                                       onChange={event => {
                                           this.setState(state => {
                                               state.vitalSigns.weightDetails = event;
                                               return state;
                                           })}}
                            />
                        </div>

                    </div>
                    <div className={"row mt-3 justify-content-left align-items-center"} >
                        <div className={"col-1 p-0"}>
                            <div className="font-weight-bold">BMI</div>
                        </div>
                        <div className={"col-2 p-0 m-0"}>
                            <FormField ref={formField => this.props.formFields.push(formField)}>
                                <NumberInput value={this.state.vitalSigns.bmi}
                                             className='rowTextBox'
                                             placeholder={"BMI"}
                                             disabled={true}
                                />
                            </FormField>
                        </div>

                        <div className={"col-2 pl-2"} >
                            <div>
                                kg/㎡
                            </div>
                        </div>
                        <div id ={"BMILabel"} className={"col-3 rowOverweightKeyword"}>
                            {this.getBmiStatusText(this.state.vitalSigns.bmi, this.state.vitalSigns.bmiStatus)}
                        </div>

                    </div>
                    <div className={"row mt-3 justify-content-left align-items-center"} >
                        <div className={"col-1 p-0"}>
                            <div  className="font-weight-bold">Body Fat</div>
                        </div>
                        <div className={"col-2 p-0 m-0"}>
                            <FormField ref={formField => this.props.formFields.push(formField)} min={0}  max ={100}
                                       validateOn={this.state.vitalSigns.bodyFat}>
                            <NumberInput value={this.state.vitalSigns.bodyFat}
                                         className = 'rowTextBox'
                                         placeholder={"Body Fat"}
                                         allowDecimal
                                         min ={0}
                                         max ={100}
                                         step={0.1}
                                         onChange={(event)  => {
                                             this.setState(state => {
                                                 state.vitalSigns.bodyFat = event;
                                                 return state;
                                             })}}
                            />
                            </FormField>
                        </div>
                        <div className={"col-2 pl-2" }>
                            <div>
                                %
                            </div>
                        </div>
                        <div className={"col-3 p-0 m-0"}>

                            <TextInput value={this.state.vitalSigns.bodyFatDetails}
                                       placeholder={"Details"}
                                       className = 'rowTextBox'
                                       onChange={event => {
                                           this.setState(state => {
                                               state.vitalSigns.bodyFatDetails = event;
                                               return state;
                                           })}}
                            />
                        </div>

                    </div>

                </div>
            </div>
        );
    }

    onCmFeetChanged = (value) => {
        this.setState((state) => {
            state.vitalSigns.heightUnit = value;
            return state;
        }, this.calculateBMI);
    }

    onPoundKilogramsChanged = (value) => {
        this.setState((state) => {
            state.vitalSigns.weightUnit = value;
            return state;
        }, this.calculateBMI);
    }

    onCFChanged = (value) => {
        this.setState((state) => {
            state.vitalSigns.temperatureUnit = value;
            return state;
        });
    }

    calculateBMI = () => {
        let {weight, weightUnit, height, heightUnit} = this.state.vitalSigns;

        if (weight && weightUnit == 'POUNDS') {
            weight *= 0.453592;
        }
        if (height && heightUnit == 'FEET') {
            height *= 30.48;
        }

        let bmi = (weight / ((height / 100) * (height / 100)))?.toFixed(1);
        let status;
        status = VitalSignsUtil.getBMIStatus(bmi);
        this.setState(state => {
            state.vitalSigns.bmi = bmi;
            state.vitalSigns.bmiStatus = status;
            return state;
        })
    }

    handleTemperatureAlert = (value) => {
        let text = "";
        if (this.state.vitalSigns.temperatureUnit !== "FAHRENHEIT") {
            if (value < 35) {
                text = "Temperature is too low.";
            } else if (value > 38) {
                text = "Temperature is too high.";
            }
        } else {
            if (value < 95) {
                text = "Temperature is too low.";
            } else if (value > 100.4) {
                text = "Temperature is too high.";
            }
        }
        this.setState(state => {
            state.temperatureAlertText = text;
        })
    }

    handlePulseAlert = (value) => {
        let text = value > 100 ? "Pulse is too high.": "";
        this.setState(state => {
            state.pulseAlertText = text;
        })
    }

    getBmiStatusText = (bmi, bmiStatus) => {
        if(bmiStatus != null)
            return BMIEnum[bmiStatus]?.name;
        else if (bmi != null && bmiStatus == null){
            return BMIEnum[VitalSignsUtil.getBMIStatus(bmi)]?.name
        }
        else
            return "";
    }

}