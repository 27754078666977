import "../MedicalReports.css"
import React, {Component} from "react";
import ReportTemplate from "../ReportTemplate";
import ReportSection from "../ReportSection";
import TextInput from "../../../../../components/text-input/TextInput";
import DateUtil from "../../../../../utils/DateUtil";
import ReviewOfSystemViewHistory from "../../visithistory/views/subjective/ReviewOfSystemViewHistory";
import TreatmentResultHistory from "../../visithistory/views/treatment/TreatmentResultHistory";
import OtherInjuriesViewHistory from "../../visithistory/views/subjective/OtherInjuriesViewHistory";
import MedicationUseViewHistory from "../../visithistory/views/subjective/MedicationUseViewHistory";
import TreatmentReceivedReportView from "../view/TreatmentReceivedReportView";
import AssessmentReportView from "../view/AssessmentReportView";
import SymptomReportView from "../view/SymptomReportView";
import AllergiesReportView from "../view/AllergiesReportView";
import ActivityLimitationReportView from "../view/ActivityLimitationReportView";
import PreviousTreatmentReceivedReportView from "../view/PreviousTreatmentReceivedReportView";
import ResponseToTreatmentReportView from "../view/ResponseToTreatmentReportView";
import MedicalHistoryReportView from "../view/MedicalHistoryReportView";
import FamilyHistoryReportView from "../view/FamilyHistoryReportView";
import SurgicalHistoryReportView from "../view/SurgicalHistoryReportView";
import MSKDiagnosisReportView from "../view/MSKDiagnosisReportView";
import ReportViewUtils from "../view/ReportViewUtils";
import LabImagingReportView from "../view/LabImagingReportView";
import ComplaintReferralReportView from "../view/ComplaintReferralReportView";
import DiagnosisReportView from "../view/DiagnosisReportView";
import moment from "moment/moment";

export default class ReferralLetterTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {visit: this.props.emr.visits[this.props.emr.visits.length - 1]}; // TODO remove
    }

    render() {
        const emr = this.props.emr;
        const firstVisit = emr.visits[0];
        const visit = this.state.visit;
        const subjective = visit.subjective;
        const assessment = visit.assessment;
        const treatment = visit.treatment;
        const prescriptions = visit.prescriptions;
        const procedureList = [...visit.diagnosis.selfPayProcedures, ...visit.diagnosis.directBillingProcedures]
            .sort((a, b) => moment(a.startTime).diff(moment(b.startTime)))

        return (
            <ReportTemplate {...this.props} generateUrl={'api/medical-report/generate/referral-letter'}
                            onVisitSelected={(visit) => {
                                this.setState({visit})
                            }}
                            extraParams={() => {
                                return {
                                    whomItMayConcern: this.state.whomItMayConcern || null
                                }
                            }}>

                <div>
                    <div className="w-100, d-flex" style={{marginTop: "20px"}}>
                        <div style={{alignSelf: "center"}}>Dear</div>
                        <TextInput
                            disabled={this.props.readOnly}
                            value={this.state.whomItMayConcern || ''}
                            onChange={value => this.setState(state => {
                                state.whomItMayConcern = value;
                                return state;
                            })}
                            style={{height: "25px", marginLeft: "5px", minWidth: "200px", background: "#f2f2f2"}}
                        />&nbsp;,
                    </div>
                    <div className="my-3">
                        I am referring the above patient for your assessment and evaluation
                    </div>
                </div>

                <ReportSection header1={"Complaint"}>
                    {!ReportViewUtils.isComplaintNull(subjective.complaint) && <ComplaintReferralReportView complaint={subjective.complaint}/>}
                </ReportSection>

                <ReportSection header1={"Symptoms"} header2={"Date Of First Visit"}>
                    {!ReportViewUtils.isSymptomsNull(subjective.complaint) && <SymptomReportView complaint={subjective.complaint}/>}
                    {firstVisit.appointment.startTime != null && <div>{DateUtil.formatDateMonthAndYear(firstVisit.appointment.startTime, 'DD MMMM YYYY')}</div>}
                </ReportSection>

                <ReportSection header1={"Previous Treatment Received"} header2={"Activity Limitations"}>
                    {!ReportViewUtils.isPreviousTreatmentReceivedNull(subjective.treatmentReceived) && <PreviousTreatmentReceivedReportView treatmentReceived={subjective.treatmentReceived}/>}
                    {!ReportViewUtils.isActivityLimitationNull(subjective.activityLimitation) && <ActivityLimitationReportView activityLimitation={subjective.activityLimitation}/>}
                </ReportSection>

                <ReportSection header1={"Labs/Imaging"} header2={"Other Injuries"}>
                    {!ReportViewUtils.isLabImagingNull(subjective.labsImaging) && <LabImagingReportView labsImaging={subjective.labsImaging}/>}
                    {!ReportViewUtils.isOtherInjuriesNull(subjective.otherInjuries) && <OtherInjuriesViewHistory otherInjuries={subjective.otherInjuries} reportView/>}
                </ReportSection>

                <ReportSection header1={"Allergies"}>
                    {!ReportViewUtils.isAllergyNull(subjective.allergy) && <AllergiesReportView allergy={subjective.allergy}/>}
                </ReportSection>

                <ReportSection header1={"Medications"}>
                    {(!ReportViewUtils.isMedicationUseNull(subjective.medicationUse) || !ReportViewUtils.isPrescriptionsNull(prescriptions))
                    && <MedicationUseViewHistory medicationUse={subjective.medicationUse} prescriptions={prescriptions}/>}
                </ReportSection>

                <ReportSection header1={"Medical History"} header2={"Family History"}>
                    {(!ReportViewUtils.isMedicalHistoryNull(subjective.medicalHistory) || !ReportViewUtils.isVaccinationHistoryNull(subjective.medicalHistory)) && <MedicalHistoryReportView medicalHistory={subjective.medicalHistory} isPrintVaccination/>}
                    {!ReportViewUtils.isFamilyHistoryNull(subjective.familyHistory) && <FamilyHistoryReportView familyHistory={subjective.familyHistory}/>}
                </ReportSection>

                <ReportSection header1={"Surgical History"} header2={"Review Of Systems"}>
                    {!ReportViewUtils.isSurgicalHistoryNull(subjective.surgicalHistory) && <SurgicalHistoryReportView surgicalHistory={subjective.surgicalHistory}/>}
                    {!ReportViewUtils.isReviewOfSystemNull(subjective.reviewOfSystem) && <ReviewOfSystemViewHistory reviewOfSystem={subjective.reviewOfSystem} reportView/>}
                </ReportSection>

                <ReportSection header1={"Assessment"}>
                    {!ReportViewUtils.isAssessmentNull(assessment) && <AssessmentReportView assesment={assessment}/>}
                </ReportSection>

                <ReportSection header1={"MSK Diagnostic Ultrasound"}>
                    {!ReportViewUtils.isMskDiagnosisNull(assessment.mskDiagnosis) && <MSKDiagnosisReportView mskDiagnosis={assessment.mskDiagnosis}/>}
                </ReportSection>

                <ReportSection header1={"Diagnosis"}>
                    {!ReportViewUtils.isDiagnosisNull(visit.diagnosis) && !ReportViewUtils.isDiagnosisItemsNull(visit.diagnosis.diagnosisGroups[0].diagnosisItems) && <DiagnosisReportView diagnosis={visit.diagnosis}/>}
                </ReportSection>

                <ReportSection header1={"Treatment"}>
                    {!ReportViewUtils.isTreatmentInfoNull(treatment.treatmentReceived) && <div>
                        <div style={{marginBottom: "15px"}}><b style={{marginBottom: "15px"}}>Treatment Received</b>
                        </div>
                        <div>
                            The patient received the following treatment
                        </div>
                        <TreatmentReceivedReportView treatmentInfo={treatment.treatmentReceived}
                                                     procedureList={procedureList}/>
                    </div>}
                </ReportSection>

                <ReportSection header1={"Response To Treatment"}>
                    {!ReportViewUtils.isResponseToTreatmentNull(treatment.response) && <ResponseToTreatmentReportView response={treatment.response}/>}
                </ReportSection>

                <ReportSection header1={"Results"}>
                    {!ReportViewUtils.isTreatmentResultNull(treatment.results) && <div>
                        The Following Interventions Were Ordered With The Findings Documented Noted As Below:
                        <TreatmentResultHistory results={treatment.results}/>
                    </div>}
                </ReportSection>

            </ReportTemplate>
        );
    }

}
