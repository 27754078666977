import React, {Component} from "react";
import "./PublicForm.css";
import {NumberInput, TextInput, Wizard,} from "../../../components";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import {YesNoRadioInput} from "./YesNoRadioInput";
import Logo from "./Logo";
import RemotingService from "../../../services/remoting-service/RemotingService";
import DateUtil from "../../../utils/DateUtil";
import moment from "moment";
import FormField from "../../../components/form/FormField";
import {Arrow, Circle, Cross} from "../BodySilhouette";
import BodySilhouettePatientCanvas from "../BodySilhouette/BodySilhouettePatientCanvas";
import EnumBodySilhouetteSignTypes from "../BodySilhouette/EnumBodySilhouetteSignTypes";
import confirmDialog from "../../../components/dialog/ConfirmDialog";
import CheckboxInput from "../../../components/checkbox-input/CheckboxInput";
import FamilySelect from "../FamilySelect";
import FormLanguages from "../enum/FormLanguages";
import PublicIntakeFormPatientInformation from "../intakeform/public/PublicIntakeFormPatientInformation";
import PublicIntakeFormReferralInformation from "../intakeform/public/PublicIntakeFormReferralInformation";
import PublicIntakeFormContactInformation from "../intakeform/public/PublicIntakeFormContactInformation";
import PublicIntakeFormEmergencyContactInformation
    from "../intakeform/public/PublicIntakeFormEmergencyContactInformation";
import PublicIntakeFormComplaint from "../intakeform/public/PublicIntakeFormComplaint";
import PublicIntakeFormHaveYouExperienced from "../intakeform/public/PublicIntakeFormHaveYouExperienced";
import PublicIntakeFormCurrentPatientCondition from "../intakeform/public/PublicIntakeFormCurrentPatientCondition";
import PublicHadAnyXray from "../intakeform/public/PublicHadAnyXray";
import PublicIntakeFormDescribeSymptoms from "../intakeform/public/PublicIntakeFormDescribeSymptoms";
import PublicSymptomsWorseIn from "../intakeform/public/PublicSymptomsWorseIn";
import PublicMakesSymptomsWorse from "../intakeform/public/PublicMakesSymptomsWorse";
import PublicMakesSymptomsBetter from "../intakeform/public/PublicMakesSymptomsBetter";
import PublicSymptomsInterfereWith from "../intakeform/public/PublicSymptomsInterfereWith";
import PublicIntakeFormHealthHistory from "../intakeform/public/PublicIntakeFormHealthHistory";
import PublicIntakeFormHealthHistoryPage2 from "../intakeform/public/PublicIntakeFormHealthHistoryPage2";
import PublicIntakeFormCurrentHealth from "../intakeform/public/PublicIntakeFormCurrentHealth";
import PublicIntakeFormCurrentHealthPage2 from "../intakeform/public/PublicIntakeFormCurrentHealthPage2";
import IntakeFormTextEnum from "../intakeform/enum/IntakeFormTextEnum";
import {connect} from "react-redux";

class PublicIntakeForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bodySilhouetteChilds: [],
            bodySilhouetteObjects: [],
        };
    }

    componentDidMount() {
        this.retrievePatientPhoto();

        const {form} = this.props;

        if (form.dob && !form.age) {
            form.age = DateUtil.getAgeWithMonths(moment(form.dob));
        }

        if (form.bodySilhouette && form.bodySilhouette.length) {
            const childs = form.bodySilhouette.map((mark) => {
                return this.findMarkType(mark);
            });
            this.state.bodySilhouetteChilds = [...childs];
        }

        this.forceUpdate();
    }

    retrievePatientPhoto = () => {
        const {consentFormCode: code} = this.props;

        RemotingService.getRemoteCall(
            `api/public/consent-form/code/${code}/patient-photo`,
            null,
            (profilePhoto) => {
                this.setState({
                    photoValue: profilePhoto,
                    newPhotoValue: profilePhoto,
                });
            }
        );
    };

    close = () => {
        confirmDialog(
            "Confirmation",
            "Are you sure you want to close this form? All input will be lost.",
            this.props.close
        );
    };

    submitForm = () => {
        if (ValidationUtil.checkWithNotification(this.formFields)) {
            const {consentFormCode: code, form} = this.props;
            this.updateFormBodySilhouette(form);
            RemotingService.postRemoteCall(
                `api/public/consent-form/code/${code}/intake-form`,
                form
            );
            this.props.onFormSubmit(form);
        }
    };

    createHeader = (form , selectedLanguage) => {
        let headerClass = selectedLanguage === "Arabic"
            ? "arabic-form d-flex justify-content-between align-items-center"
            : "d-flex justify-content-between align-items-center";

        return (
            <div style={{padding: "0px 20px 0px 20px"}}>
                <div className="row text-left">
                    <div className="col-12 no-padding">
                        <Logo/>
                    </div>
                </div>
                <div className="row">
                    <div
                        className="col-12 text-center no-padding"
                        style={{fontSize: 20, fontWeight: "bold"}}
                    >
                        {IntakeFormTextEnum.Intake_Form_Title[selectedLanguage]}
                    </div>
                </div>
                <div className={headerClass} style={{fontSize: 11}}>
                    <div className="mb-0">
                        <b>{IntakeFormTextEnum.Date[selectedLanguage]}: </b> <span dir="ltr">{DateUtil.formatDateMMM(form.consentDate)}</span>
                    </div>
                    <div className="mb-0">
                        <b>{IntakeFormTextEnum.MRN[selectedLanguage]}: </b> {form.patientMrn}
                    </div>
                </div>
            </div>
        );
    };

    fieldChanged = (fieldName, value) => {
        this.props.form[fieldName] = value;
        this.forceUpdate();
    };

    textField = (form, fieldName) => {
        return (
            <TextInput
                className="form-control"
                value={form[fieldName]}
                onChange={(value) => fieldName !== "postalCode" || !isNaN(value) ? this.fieldChanged(fieldName, value) : null}
            />
        );
    };

    numberInput = (form, fieldName, props= {}) => {
        return (
            <NumberInput
                className="form-control"
                min={0}
                value={form[fieldName]}
                {...props}
                onChange={(value) => this.fieldChanged(fieldName, value)}/>
        )
    }

    validatedTextField = (form, fieldName) => {
        return (
            <FormField
                ref={(ref) => this.formFields.push(ref)}
                required
                validateOn={form[fieldName]}
            >
                <TextInput
                    className="form-control"
                    value={form[fieldName]}
                    alphabeticOnly={fieldName === "emergencyContactName"}
                    onChange={(value) => this.fieldChanged(fieldName, value)}
                />
            </FormField>
        );
    };

    checkBoxField = (form, fieldName, label) => {
        return (
            <div className="form-check form-check-inline">
                <CheckboxInput
                    className="form-check-input"
                    value={form[fieldName]}
                    onChange={(value) => this.fieldChanged(fieldName, value)}
                />
                <label className="form-check-label">{label}</label>
            </div>
        );
    };

    updateFormBodySilhouette = (form) => {
        const bodySilhouetteMarkList = [];
        const bodySilhouetteObject = [...this.state.bodySilhouetteObjects];

        bodySilhouetteObject.filter(obj => obj.type !== "textbox").forEach((mark) =>
            bodySilhouetteMarkList.push({
                type: mark.type,
                topDistance: mark.top,
                leftDistance: mark.left,
                angle: mark.angle,
                scaleX: mark.scaleX,
                scaleY: mark.scaleY,
            })
        );

        form.bodySilhouette = bodySilhouetteMarkList;
    };

    addBodyMark = (item) => {
        const bodySilhouetteComponents = [...this.state.bodySilhouetteChilds];
        bodySilhouetteComponents.push(item);
        const bodySilhouetteChilds = bodySilhouetteComponents.map((child) =>
            child != null ? React.cloneElement(child, {}) : null
        );
        this.setState({bodySilhouetteChilds: bodySilhouetteChilds});
    };

    deleteBodyMark = () => {
        const bodySilhouetteComponents = [...this.state.bodySilhouetteChilds];
        bodySilhouetteComponents.pop();
        const bodySilhouetteChilds = bodySilhouetteComponents.map((child) =>
            child != null ? React.cloneElement(child, {}) : null
        );
        this.setState({bodySilhouetteChilds: bodySilhouetteChilds});
    };

    findMarkType = (mark) => {
        const props = {
            top: mark.topDistance ? mark.topDistance : mark.top,
            left: mark.leftDistance ? mark.leftDistance : mark.left,
            angle: mark.angle,
            scaleX: mark.scaleX,
            scaleY: mark.scaleY,
        };
        if (mark.type === EnumBodySilhouetteSignTypes.CROSS)
            return <Cross {...props} />;
        else if (mark.type === EnumBodySilhouetteSignTypes.ARROW)
            return <Arrow {...props} />;
        else if (mark.type === EnumBodySilhouetteSignTypes.CIRCLE)
            return <Circle {...props} />;
    };

    forceFormUpdate = () => {
        this.forceUpdate();
    }

    pushFormFieldRef = (ref) => this.formFields.push(ref);

    render() {
        this.formFields = [];

        const {form} = this.props;
        const {photoValue} = this.state;
        const photoSrc = photoValue
            ? `data:image/jpeg;base64,${photoValue.content}`
            : `${process.env.PUBLIC_URL}/assets/patient_profile.png`;

        const selectedLanguage = this.props.language;
        const formBodyClass = selectedLanguage == FormLanguages.ARABIC.name ? "arabic-form" : "";
        const enumLabel = selectedLanguage == FormLanguages.ARABIC.name ? "nameInArabic" : "name";
        const nationalityLabel = selectedLanguage == FormLanguages.ARABIC.name ? "nameInArabic" : "nameInEnglish";
        const commonProps = {
            selectedLanguage,
            enumLabel,
            nationalityLabel,
            form, // TODO: This should split to the components
            textField: this.textField,
            validatedTextField: this.validatedTextField,
            onChange: this.fieldChanged,
            formFields: this.formFields,
            forceFormUpdate: this.forceFormUpdate,
            pushFormFieldRef: this.pushFormFieldRef,
            patientId: this.props.patientId
        }

        return (
            <Wizard
                show={true}
                fullScreen
                footerStyle={{backgroundColor: "#f5f5f5"}}
                buttonStyle={{backgroundColor: "#84BE56", border: "none"}}
                previousButtonStyle={{
                    backgroundColor: "#fff",
                    color: "rgb(132, 190, 86)",
                    borderColor: "rgb(132, 190, 86)"
                }}
                header={this.createHeader(form , selectedLanguage)}
                close={this.close}
                onSubmit={this.submitForm}
                previousBtnText={IntakeFormTextEnum.Wizard_Previous[selectedLanguage]}
                nextBtnText={IntakeFormTextEnum.Wizard_Next[selectedLanguage]}
                saveBtnText={IntakeFormTextEnum.Wizard_Save[selectedLanguage]}
            >
                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}>
                    <div className={formBodyClass}>
                        <PublicIntakeFormPatientInformation {...commonProps}
                                                            photoSrc={photoSrc}
                                                            nationalities={this.props.nationalities}
                                                            numberInput={this.numberInput}/>
                        <PublicIntakeFormReferralInformation {...commonProps}
                                                             consentFormCode={this.props.consentFormCode}/>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass}>
                        <PublicIntakeFormContactInformation {...commonProps}
                                                            countries={this.props.countries}/>
                        <PublicIntakeFormEmergencyContactInformation {...commonProps}/>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass}>
                        <PublicIntakeFormComplaint {...commonProps} />
                        <div class="panel bg-white">
                            <BodySilhouettePatientCanvas
                                selectedLanguage={selectedLanguage}
                                disabled={false}
                                onClickAddCross={(top, left) =>
                                    this.addBodyMark(<Cross top={top} left={left}/>)
                                }
                                onClickAddCircle={(top, left) =>
                                    this.addBodyMark(<Circle top={top} left={left}/>)
                                }
                                onClickAddArrow={(top, left) =>
                                    this.addBodyMark(<Arrow top={top} left={left}/>)
                                }
                                onRemoveItem={() => this.deleteBodyMark()}
                                onPageChange={(objects) => {
                                    let bodySilhouetteChilds = objects.map((mark) => {
                                        return this.findMarkType(mark);
                                    });
                                    this.setState({
                                        bodySilhouetteObjects: [...objects],
                                        bodySilhouetteChilds: bodySilhouetteChilds,
                                    });
                                }}
                            >
                                {this.state.bodySilhouetteChilds}
                            </BodySilhouettePatientCanvas>
                        </div>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass}>
                        <PublicIntakeFormCurrentPatientCondition {...commonProps} />
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass}>
                        <PublicIntakeFormDescribeSymptoms {...commonProps} checkBoxField={this.checkBoxField}/>
                        <PublicSymptomsWorseIn {...commonProps}/>
                        <PublicMakesSymptomsWorse {...commonProps} checkBoxField={this.checkBoxField}/>
                        <PublicMakesSymptomsBetter {...commonProps} checkBoxField={this.checkBoxField}/>
                        <PublicSymptomsInterfereWith {...commonProps} checkBoxField={this.checkBoxField}/>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass}>
                        <PublicHadAnyXray {...commonProps}/>
                        <PublicIntakeFormHaveYouExperienced {...commonProps}
                                                            yesNoDescribeField={this.yesNoDescribeField}/>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass}>
                        <PublicIntakeFormHealthHistory {...commonProps}
                                                       selfFamilyDescribeField={this.selfFamilyDescribeField}/>
                    </div>
                </Wizard.Page>

                <Wizard.Page
                    onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                    onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass}>
                        <PublicIntakeFormHealthHistoryPage2 {...commonProps}
                                                            yesNoDescribeFieldEqualWidth={this.yesNoDescribeFieldEqualWidth}/>
                        <PublicIntakeFormCurrentHealth {...commonProps}
                                                       yesNoDescribeFieldEqualWidth={this.yesNoDescribeFieldEqualWidth}/>
                    </div>
                </Wizard.Page>

                <Wizard.Page onPrevious={() => this.formFields = []}>
                    <div className={formBodyClass}>
                        <PublicIntakeFormCurrentHealthPage2 {...commonProps}
                                                            yesNoDescribeFieldShort={this.yesNoDescribeFieldShort}/>
                    </div>
                </Wizard.Page>
            </Wizard>
        );
    }

    yesNoDescribeField = (form, fieldName, label, enumLabel) => {
        return (
            <div className="row">
                <div className="col-8 d-flex justify-content-start align-items-center">
                    <label className="col-form-label">{label}</label>
                </div>
                <div class="col d-flex align-items-center">
                    <YesNoRadioInput
                        value={form[fieldName]}
                        onValueChange={(value) => {
                            this.fieldChanged(fieldName, value);
                        }}
                        enumLabel={enumLabel}
                    />
                </div>
            </div>
        );
    };

    yesNoDescribeFieldEqualWidth = (form, fieldName, label, enumLabel) => {
        return (
            <div className="row">
                <div className="col-8 d-flex justify-content-start align-items-center">
                    <label className="col-form-label">{label}</label>
                </div>
                <div class="col d-flex align-items-center">
                    <YesNoRadioInput
                        value={form[fieldName]}
                        onValueChange={(value) => {
                            this.fieldChanged(fieldName, value);
                        }}
                        enumLabel={enumLabel}
                    />
                </div>
            </div>
        );
    };

    yesNoDescribeFieldShort = (form, fieldName, label, enumLabel) => {
        return (
            <div className="row">
                <div className="col-3 d-flex justify-content-start align-items-center">
                    <label className="col-form-label">{label}</label>
                </div>
                <div class="col d-flex align-items-center">
                    <YesNoRadioInput
                        value={form[fieldName]}
                        onValueChange={(value) => {
                            this.fieldChanged(fieldName, value);
                        }}
                        enumLabel={enumLabel}
                    />
                </div>
            </div>
        );
    };

    selfFamilyDescribeField = (form, selfFieldName, familyFieldName, familyWhoFieldName, label, enumLabel) => {
        return (
            <div className="row">
                <div className="col-6 d-flex justify-content-start align-items-center">
                    <label className="col-form-label">{label}</label>
                </div>
                <div class="col-2 d-flex align-items-center justify-content-center">
                    <YesNoRadioInput
                        value={form[selfFieldName]}
                        onValueChange={(value) => {
                            this.fieldChanged(selfFieldName, value);
                        }}
                        enumLabel={enumLabel}
                    />
                </div>
                <div class="col-2 d-flex align-items-center justify-content-center">
                    <YesNoRadioInput
                        value={form[familyFieldName]}
                        onValueChange={(value) => {
                            this.fieldChanged(familyFieldName, value);
                            if (value === "No") {
                                this.fieldChanged(familyWhoFieldName, null);
                            }
                        }}
                        enumLabel={enumLabel}
                    />
                </div>
                <div className="col-2">
                    {form[familyFieldName] === "Yes" &&
                    <FamilySelect value={form[familyWhoFieldName]}
                                  style={{width: 150}}
                                  enumLabel={enumLabel}
                                  onChange={(value) => {
                                      this.fieldChanged(familyWhoFieldName, value);
                                  }}/>
                    }
                </div>
            </div>
        );
    };
}

function mapStateToProps(state) {
    return {
        nationalities: state.base.lookupNationalities,
        countries: state.base.lookupCountries
    };
}

export default connect(mapStateToProps)(PublicIntakeForm);
