import Enum from "../../../../../../enums/Enum";
import ManipulationEnum from "./ManipulationEnum";
import MobilizationEnum from "./MobilizationEnum";
import ThermalTherapyEnum from "./ThermalTherapyEnum";
import TherapeuticExerciseEnum from "./TherapeuticExerciseEnum";
import ProceduresEnum from "./ProceduresEnum";

const TreatmentReceivedEnum = new Enum({
    MANIPULATION: {name: "Manipulation", field: "manipulation", enum: ManipulationEnum},
    MOBILIZATION: {name: "Mobilization", field: "mobilization", enum: MobilizationEnum},
    STRETCHING: {name: "Stretching", field: "stretching"},
    DRY_NEEDLING: {name: "Dry Needling", field: "dryNeedling"},
    ACUPUNCTURE: {name: "Acupuncture", field: "acupuncture"},
    ELECTROTHERAPY: {name: "Electrotherapy", field: "electrotherapy"},
    SHOCKWAVE: {name: "Shockwave", field: "shockwave"},
    CUPPING: {name: "Cupping", field: "cupping"},
    TAPING: {name: "Taping", field: "taping"},
    THERAPEUTIC_MASSAGE: {name: "Therapeutic Massage", field: "therapeuticMassage"},
    LYMPHATIC: {name: "Lymphatic Drainage", field: "lymphaticDrainage"},
    THERMAL: {name: "Thermal Therapy", field: "thermalTherapy", enum: ThermalTherapyEnum},
    THERAPEUTIC_EXERCISE: {name: "Therapeutic Exercise", field: "therapeuticExercise", enum: TherapeuticExerciseEnum},
    GAIT: {name: "Gait Training", field: "gaitTraining"},
    BREATHING: {name: "Breathing Patterns", field: "breathingPatterns"},
    PROCEDURES: {name: "Procedures", field: "procedures", enum: ProceduresEnum},
    OTHER: {name: "Other", field: "other"}
});

export default TreatmentReceivedEnum;
