import React from "react";
import {connect} from "react-redux";
import {Route} from "react-router-dom";
import {LoginPage} from "./login/LoginPage";
import {showWarning} from "../../services/notification-service/NotificationService";

function getTargetComponent(Component, props, rest) {
    const user = rest.user;

    if (!user || !user.token) {
        return <LoginPage/>;
    }

    const hasRightsToAccessComponent = !rest.authority || (user.authorities && user.authorities.includes(rest.authority));

    if(!hasRightsToAccessComponent && props.location.pathname !== '/' && props.location.pathname !== '/login') {
        showWarning("You don't have required permissions to access this page.")
    }

    return hasRightsToAccessComponent ? <Component {...props} /> : null;
}

function PrivateRoute({component: Component, ...rest}) {
    return (
        <Route
            {...rest}
            render={props => getTargetComponent(Component, props, rest)
            }
        />
    );
}

const mapStateToProps = state => {
    return {
        user: state.authentication.user
    };
};

export default connect(mapStateToProps)(PrivateRoute);
