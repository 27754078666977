import React, {Component} from "react";
import '../../EMR.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default class TreatmentResponseView extends Component {

    state = {};

    render() {
        const response = this.props.response;
        const previousResponse = this.props.previousResponse;

        if (!response || response == null) {
            return null;
        }

        return (
            <div className={"content-row content-card"} style={{width: "99%"}}>
                <div style={{width: "100%", margin: "20px"}}>
                    {this.renderValue(response, previousResponse, 'Overall Improve By', 'overallImproveValue', 'overallImproveDetails', true, false)}
                    {this.renderValue(response, previousResponse, 'ROM Improve By', 'romImproveValue', 'romImproveDetails', true, false)}
                    {this.renderValue(response, previousResponse, 'Pain Score (VAS)', 'painScoreValue', 'painScoreDetails', false, true)}
                </div>
            </div>
        );
    }

    renderValue(response, previousResponse, label, valueProp, detailsProps, isPercentage, isPain) {
        return <div className={"row"}>
            <div className={"col-4 font-weight-bold border"}>
                {label}
            </div>
            <div className={"col-2 border text-right"} style={{background: "white"}}>
                {response[valueProp]}{response[valueProp] && isPercentage ? '%' : ''}
                &nbsp;
                {this.renderChange(response, previousResponse, valueProp, isPain)}
            </div>
            <div className={"col-6 border"} style={{background: "white"}}>
                {response[detailsProps]}
            </div>
        </div>
    }

    renderChange(response, previousResponse, valueProp, isPain) {
        if (!previousResponse || !previousResponse[valueProp] || !response[valueProp]) {
            return null;
        }

        const diff = response[valueProp] - previousResponse[valueProp];

        if (diff > 0) {
            return <FontAwesomeIcon icon={["fa", "arrow-up"]} style={{color: isPain ? "#AC1616" : "#7BAC16"}}/>;
        } else if (diff < 0) {
            return <FontAwesomeIcon icon={["fa", "arrow-down"]} style={{color: isPain ? "#7BAC16" : "#AC1616"}}/>;
        } else {
            return <FontAwesomeIcon icon={["fa", "arrows-alt-h"]}/>;
        }
    }

}
