import React, {Component} from 'react';
import moment from "moment";
import StringUtil from "../../utils/StringUtil";
import {SelectInput, TextareaInput, TextInput, Wizard} from "../../components";
import RemotingService from "../../services/remoting-service/RemotingService";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import SignatureInput from "../../components/signature-input/SignatureInput";
import Logo from "../../layout/Logo";
import FormField from "../../components/form/FormField";
import {Table} from "react-bootstrap";
import PatientUtil from "../../utils/PatientUtil";
import FormLanguages from "./enum/FormLanguages";
import MedicalPhotographyConsentFormTextEnum from "./photographyconsent/MedicalPhotographyConsentFormTextEnum";

class MedicalPhotographyConsentForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            patientDate: new Date(),
            guardianDate: new Date(),
            providerName: this.props.staff.name,
            providerSpeciality: this.props.staff.speciality.name
        }
    }

    onSubmit = () => {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        if (this.props.form !== null && this.props.form !== undefined) {
            this.props.close();

        } else {
            let patient = this.props.patient;
            let patientFullName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
            let patientMrn = StringUtil.mrn(patient.mrn);
            let patientDOB = patient.birthday;
            const {
                patientDate, guardianDate, guardianName, guardianRelationship, patientSignature, guardianSignature,
                educationalPurposesDisclosingName, educationalPurposesAnonymously, socialMediaPurposesDisclosingName,
                socialMediaPurposesAnonymously, extraRestrictions, providerName, providerSpeciality
            } = this.state;

            RemotingService.postRemoteCall('api/visit/form/medical-photography-consent-form/' + this.props.visitId,
                {
                    patientDate,
                    guardianDate,
                    guardianName,
                    guardianRelationship,
                    patientSignature,
                    guardianSignature,
                    educationalPurposesDisclosingName,
                    educationalPurposesAnonymously,
                    socialMediaPurposesDisclosingName,
                    socialMediaPurposesAnonymously,
                    extraRestrictions,
                    patientFullName,
                    patientMrn,
                    patientDOB,
                    providerName, providerSpeciality
                },
                (result) => {
                    this.props.afterSave(result);
                    this.props.close();
                });
        }
    }

    onSaveGuardianSignature = (signature) => {
        this.setState({guardianSignature: signature})
    }

    onSavePatientSignature = (signature) => {
        this.setState({patientSignature: signature})
    }

    render() {
        if (!this.props.show)
            return null;

        let {patient, form} = this.props;
        let readOnly = form !== null && form !== undefined;
        let mrnNo = StringUtil.mrn(patient.mrn);
        let patientName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
        this.formFields = []

        const selectedLanguage = this.props.selectedLanguage.name;

        const formBodyClass = this.props.selectedLanguage == FormLanguages.ARABIC ? "arabic-form" : "";
        const enumLabel = this.props.selectedLanguage == FormLanguages.ARABIC ? "nameInArabic" : "name";
        const yesNoOptions = [{key: true, name: "Yes", nameInArabic: "نعم"}, {key: false, name: "No", nameInArabic: "لا"}];

        return (
            <>
                <Wizard
                    show={this.props.show}
                    close={this.props.close}
                    title={MedicalPhotographyConsentFormTextEnum.Medical_Photography_Consent_Title[selectedLanguage]}
                    onSubmit={this.onSubmit}
                    isReadOnly={readOnly}
                    previousBtnText={MedicalPhotographyConsentFormTextEnum.Wizard_Previous[selectedLanguage]}
                    nextBtnText={MedicalPhotographyConsentFormTextEnum.Wizard_Next[selectedLanguage]}
                    saveBtnText={MedicalPhotographyConsentFormTextEnum.Wizard_Save[selectedLanguage]}
                >
                    <Wizard.Page
                        onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}>
                        <div className={formBodyClass} style={{width: '1000px'}}>
                            <div style={{
                                marginTop: '15px', marginBottom: '15px', width: '100%', display: 'flex',
                                flexFlow: 'row', justifyContent: 'space-between', alignItems: 'center'
                            }}>
                                <Logo/>
                                <div>
                                    <h2>{MedicalPhotographyConsentFormTextEnum.Medical_Photography_Consent_Form[selectedLanguage]}</h2>
                                </div>
                            </div>

                            <hr style={{
                                borderTop: "2px solid #000000",
                                marginTop: "-10px",
                                boxShadow: "0px 2px 2px #797979"
                            }}/>

                            <div style={{border: "1px solid #000000"}}>
                                <div style={{margin: "15px 15px"}}>
                                    <h5>{MedicalPhotographyConsentFormTextEnum.Patient_Information[selectedLanguage]}:</h5>
                                    <div className={"row"} style={{marginTop: "10px", marginLeft: "0px"}}>
                                        <div className="col-md-5">
                                            <b>{MedicalPhotographyConsentFormTextEnum.Name[selectedLanguage]}:</b> {patientName}
                                        </div>
                                        <div className="col-md-4">
                                            <b>{MedicalPhotographyConsentFormTextEnum.MRN[selectedLanguage]}:</b> {mrnNo}
                                        </div>
                                        <div className="col-md-3">
                                            <b>{MedicalPhotographyConsentFormTextEnum.DOB[selectedLanguage]}:</b> {patient.birthday}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-2 p-3" style={{border: "1px solid #000000"}}>
                                <p>
                                    {MedicalPhotographyConsentFormTextEnum.Paragraph1[selectedLanguage]}<br/>
                                    {MedicalPhotographyConsentFormTextEnum.Paragraph2[selectedLanguage]}
                                </p>
                            </div>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page
                        onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                        onPrevious={() => this.formFields = []}>
                        <div className={formBodyClass + "mt-3"} style={{width: '1000px'}}>
                            <p><b>{MedicalPhotographyConsentFormTextEnum.Proceed_Filling_Below[selectedLanguage]}</b>
                            </p>
                            <Table bordered hover size="sm">
                                <thead>
                                <tr>
                                    <th></th>
                                    <th style={{width: '120px'}}>{MedicalPhotographyConsentFormTextEnum.Anonymously[selectedLanguage]}</th>
                                    <th style={{width: '120px'}}>{MedicalPhotographyConsentFormTextEnum.Disclosing_My_Name[selectedLanguage]}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>{MedicalPhotographyConsentFormTextEnum.List_Item_1[selectedLanguage]}</td>
                                    <td>
                                        <div>
                                            <FormField ref={formField => this.formFields.push(formField)} required
                                                       validateOn={readOnly ? true : this.state.educationalPurposesAnonymously}>
                                                <SelectInput
                                                    value={readOnly ? form.educationalPurposesAnonymously : this.state.educationalPurposesAnonymously}
                                                    options={yesNoOptions} displayProperty={enumLabel} valueProperty="key"
                                                    disabled={readOnly}
                                                    style={{width: '120px'}}
                                                    searchEnabled={false}
                                                    showClear={false}
                                                    onChange={(value) => this.setState({
                                                        educationalPurposesAnonymously: value,
                                                        educationalPurposesDisclosingName: !value
                                                    })}/>
                                            </FormField>
                                        </div>
                                    </td>

                                    <td>
                                        <div>
                                            <FormField ref={formField => this.formFields.push(formField)} required
                                                       validateOn={readOnly ? true : this.state.educationalPurposesDisclosingName}>
                                                <SelectInput
                                                    value={readOnly ? form.educationalPurposesDisclosingName : this.state.educationalPurposesDisclosingName}
                                                    options={yesNoOptions} displayProperty={enumLabel} valueProperty="key"
                                                    disabled={readOnly}
                                                    style={{width: '120px'}}
                                                    searchEnabled={false}
                                                    showClear={false}
                                                    onChange={(value) => this.setState({
                                                        educationalPurposesDisclosingName: value,
                                                        educationalPurposesAnonymously: !value
                                                    })}/>
                                            </FormField>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td>{MedicalPhotographyConsentFormTextEnum.List_Item_2[selectedLanguage]}</td>
                                    <td>
                                        <div>
                                            <FormField ref={formField => this.formFields.push(formField)} required
                                                       validateOn={readOnly ? true : this.state.socialMediaPurposesAnonymously}>
                                                <SelectInput
                                                    value={readOnly ? form.socialMediaPurposesAnonymously : this.state.socialMediaPurposesAnonymously}
                                                    options={yesNoOptions} displayProperty={enumLabel} valueProperty="key"
                                                    disabled={readOnly}
                                                    style={{width: '120px'}}
                                                    searchEnabled={false}
                                                    showClear={false}
                                                    onChange={(value) => this.setState({
                                                        socialMediaPurposesAnonymously: value,
                                                        socialMediaPurposesDisclosingName: !value
                                                    })}/>
                                            </FormField>
                                        </div>
                                    </td>

                                    <td>
                                        <div>
                                            <FormField ref={formField => this.formFields.push(formField)} required
                                                       validateOn={readOnly ? true : this.state.socialMediaPurposesDisclosingName}>
                                                <SelectInput
                                                    value={readOnly ? form.socialMediaPurposesDisclosingName : this.state.socialMediaPurposesDisclosingName}
                                                    options={yesNoOptions} displayProperty={enumLabel} valueProperty="key"
                                                    disabled={readOnly}
                                                    style={{width: '120px'}}
                                                    searchEnabled={false}
                                                    showClear={false}
                                                    onChange={(value) => this.setState({
                                                        socialMediaPurposesDisclosingName: value,
                                                        socialMediaPurposesAnonymously: !value
                                                    })}/>
                                            </FormField>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={3}>
                                        <p>{MedicalPhotographyConsentFormTextEnum.List_Item_3[selectedLanguage]}:</p>
                                        <TextareaInput
                                            value={readOnly ? form.extraRestrictions : this.state.extraRestrictions}
                                            disabled={readOnly}
                                            style={{width: "100%"}}
                                            maxLength={255}
                                            onChange={(value) => this.setState({extraRestrictions: value})}/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>

                        </div>
                    </Wizard.Page>

                    <Wizard.Page
                        onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                        onPrevious={() => this.formFields = []}>
                        <div className={formBodyClass} style={{width: '1000px'}}>
                            <div className={"container"} style={{border: "1px solid #000000", margin: "10px 0px"}}>
                                <div className={"row"} style={{marginTop: "10px", marginLeft: "0px"}}>
                                    <p>{MedicalPhotographyConsentFormTextEnum.Please_Sign_This_Form[selectedLanguage]}</p>
                                </div>

                                <div className={"row"} style={{marginTop: "10px", marginLeft: "0px"}}>
                                    <div className="col-md-3 dim14-form-label">
                                        {MedicalPhotographyConsentFormTextEnum.Date_Time[selectedLanguage]}:
                                    </div>

                                    <div className="col-md-3">
                                        {moment(readOnly && form.patientDate ?
                                            new Date(form.patientDate)
                                            :
                                            this.state.patientDate).format('MMMM DD YYYY, h:mm:ss a')
                                        }
                                    </div>
                                </div>

                                <div className="row" style={{marginTop: "15px", marginLeft: "0px"}}>
                                    <div className="col-md-3 dim14-form-label">
                                        {MedicalPhotographyConsentFormTextEnum.Patient_Name[selectedLanguage]}:
                                    </div>
                                    <div className="col-md-3">
                                        {patientName}
                                    </div>

                                    <div className="col-md-6 dim14-form-label">
                                        {MedicalPhotographyConsentFormTextEnum.Patient_Signature[selectedLanguage]}:
                                    </div>
                                </div>

                                <div className="row" style={{marginLeft: "0px"}}>
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <SignatureInput
                                            selectedLanguage={selectedLanguage}
                                            textEnum={MedicalPhotographyConsentFormTextEnum}
                                            signature={readOnly ? form.patientSignature : this.state.patientSignature}
                                            onSave={this.onSavePatientSignature} readOnly={readOnly}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page
                        onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                        onPrevious={() => this.formFields = []}>
                        <div className={formBodyClass} style={{width: '1000px'}}>
                            <div className={"container"} style={{border: "1px solid #000000", margin: "10px 0px"}}>
                                <div className={"row"} style={{marginTop: "10px", marginLeft: "0px"}}>
                                    <p>
                                        <b>{MedicalPhotographyConsentFormTextEnum.Witness_Paragraph1[selectedLanguage]}<br/>
                                            {MedicalPhotographyConsentFormTextEnum.Witness_Paragraph2[selectedLanguage]}
                                        </b></p>
                                </div>

                                <div className={"row"} style={{marginTop: "10px", marginLeft: "0px"}}>
                                    <div className="col-md-3 dim14-form-label">
                                        {MedicalPhotographyConsentFormTextEnum.Print_Name[selectedLanguage]}:
                                    </div>
                                    <div className="col-md-3">
                                        <TextInput value={readOnly ? form.guardianName : this.state.guardianName}
                                                   alphabeticOnly disabled={readOnly} style={{textAlign: "center"}}
                                                   onChange={(val) => this.setState({guardianName: val})}/>
                                    </div>

                                    <div className="col-md-3 dim14-form-label">
                                        {MedicalPhotographyConsentFormTextEnum.Relationship_To_Patient[selectedLanguage]}:
                                    </div>
                                    <div className="col-md-3 dim14-base-input">
                                        <TextInput
                                            value={readOnly ? form.guardianRelationship : this.state.guardianRelationship}
                                            alphabeticOnly disabled={readOnly} style={{textAlign: "center"}}
                                            onChange={(val) => this.setState({guardianRelationship: val})}/>
                                    </div>
                                </div>

                                <div className={"row"} style={{marginTop: "15px", marginLeft: "0px"}}>
                                    <div className="col-md-3 dim14-form-label">
                                        {MedicalPhotographyConsentFormTextEnum.Date_Time[selectedLanguage]}:
                                    </div>

                                    <div className="col-md-3">
                                        {moment(readOnly && form.guardianDate ?
                                            new Date(form.guardianDate)
                                            :
                                            this.state.guardianDate).format('MMMM DD YYYY, h:mm:ss a')
                                        }
                                    </div>

                                    <div className="col-md-6 dim14-form-label">
                                        {MedicalPhotographyConsentFormTextEnum.Signature_Of_Witness[selectedLanguage]}:
                                    </div>
                                </div>


                                <div className="row" style={{marginLeft: "0px"}}>
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <FormField ref={(ref) => this.formFields.push(ref)}
                                                   disabled={readOnly || this.state.patientSignature != null}
                                                   required requiredMessage="Signature required"
                                                   validateOn={this.state.guardianSignature}>
                                            <SignatureInput
                                                selectedLanguage={selectedLanguage}
                                                textEnum={MedicalPhotographyConsentFormTextEnum}
                                                signature={readOnly ? form.guardianSignature : this.state.guardianSignature}
                                                onSave={this.onSaveGuardianSignature} readOnly={readOnly}/>
                                        </FormField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Wizard.Page>
                </Wizard>
            </>
        );
    }

}

export default MedicalPhotographyConsentForm;
