import {MultipleHeaderAccordion} from "../../../../../components/accordion/MultipleHeaderAccordion";
import React from "react";
import CommunicationModeEnum from "../../result/CommunicationModeEnum";
import DateUtil from "../../../../../utils/DateUtil";
import RemotingService from "../../../../../services/remoting-service/RemotingService";
import {downloadBlob} from "../../../../../utils/FileDownloader";
import AttachmentUtils from "../../treatment/utils/AttachmentUtils";
import {Column} from "primereact/column";
import ActionMenu from "../../../../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../../../../components/action-menu/ActionMenuItem";
import ViewIcon from "../../../../../components/action-menu/icons/ViewIcon";
import DownloadIcon from "../../../../../components/action-menu/icons/DownloadIcon";
import {DataTable} from "primereact/datatable";
import {commentComparatorByDateAsc} from "../../result/Result";
import {Divider} from "@material-ui/core";

export class AddendumHistory extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    downloadFile(file) {
        RemotingService.instance.get('api/file/download/' + file.uuid, {responseType: 'blob'})
            .then((response) => {
                downloadBlob(new Blob([response.data]), file.name);
            });
    }

    viewFile(file) {
        RemotingService.instance.get('api/file/download/' + file.uuid, {responseType: 'blob'})
            .then((response) => {
                AttachmentUtils.viewFile({
                    fileName: file.name, blob: response.data, id: "attachmentViewer",
                    windowWidth: "1200x", windowHeight: "800px", allowedFileExtensions: null
                });
            });
    }

    render = () => {
        const visit = this.props.visit;

        return <>
            <MultipleHeaderAccordion
                headerText={{firstHeaderText: "Addendum"}}
                headerExist={{secondHeader: false, thirdHeader: false}}
                defaultExpanded={false}>
                {visit?.treatment?.communications?.length > 0 ?
                    <div className="w-100">
                        {visit.treatment.communications.sort(commentComparatorByDateAsc).map(addendum =>
                            <div>
                                <div className="row">
                                    <div className="col-2"><b>Type:</b></div>
                                    <div
                                        className="col-8">{addendum.communicationMode ? CommunicationModeEnum[addendum.communicationMode].name : ''}</div>
                                </div>
                                <div className="row">
                                    <div className="col-2"><b>Date/Time/Name:</b></div>
                                    <div
                                        className="col-8">{DateUtil.formatDateTimeSeparatelyForReports(addendum.communicationDate)} / {addendum.addedBy}</div>
                                </div>
                                <div className="row">
                                    <div className="col-2"><b>Notes:</b></div>
                                    <div className="col-8">{addendum.communicationText}</div>
                                </div>
                                <div className="row">
                                    <div className="col-2"><b>Comment:</b></div>
                                    <div className="col-8">{addendum.comments.map((t, index) =>
                                        <span>{t.comment}{index < addendum.comments.length - 1 && ", "}</span>)}</div>
                                </div>
                                <div className="row">
                                    <div className="col-2"><b>Task:</b></div>
                                    <div className="col-8">{addendum.tasks.map((t, index) =>
                                        <span>{t.name}{index < addendum.tasks.length - 1 && ", "}</span>)}</div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <DataTable className="attachmentTable" responsive paginator={true} rows={10}
                                                   emptyMessage={"No results found"}
                                                   value={addendum.files}
                                                   dataKey="uuid">
                                            <Column style={{width: '145px'}} header="Attachment"
                                                    body={(file) => file.name} sortable/>
                                            <Column style={{width: '50px'}} header="Actions" body={(file) =>
                                                <ActionMenu id="attachmentActionPanel">
                                                    <ActionMenuItem
                                                        icon={<ViewIcon/>}
                                                        onClick={() => {
                                                            this.viewFile(file)
                                                        }} text={"View"}/>
                                                    <ActionMenuItem
                                                        icon={<DownloadIcon/>}
                                                        onClick={() => {
                                                            this.downloadFile(file)
                                                        }} text={"Download"}/>
                                                </ActionMenu>}/>
                                        </DataTable>
                                    </div>
                                </div>
                                <Divider/>
                            </div>
                        )}

                    </div> : <div className="font-italic">No data</div>
                }
            </MultipleHeaderAccordion>
        </>
    }
}
