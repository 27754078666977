import {Button, Col, Container, Row} from "react-bootstrap";
import React, {Component} from "react";
import SpecialityAndStaffFilter from "./SpecialityAndStaffFilter";
import EnumLeaveType from "./EnumLeaveType";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {NumberInput} from "../../components";
import {extract} from "../../utils/ArrayUtil";
import RemotingService from "../../services/remoting-service/RemotingService";
import {noop} from "lodash-es";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NotificationService from "../../services/notification-service/NotificationService";
import NumberUtil from "../../utils/NumberUtil";

export default class LeaveSetup extends Component{

    constructor(props){
        super(props);

        this.state = {
            metaDatas: [],
            leaveTypes: [],
            changedLeaveTypes: [],
            selectedSpecialityList: [],
            selectedStaffList: [],
            editMode: false
        }
    }

    handleSpecialitySelectionChange = (selectedSpecialityList) => {
        this.setState({selectedSpecialityList});
    };

    handleStaffSelectionChange = (selectedStaffList) => {
        this.setState({selectedStaffList, changedLeaveTypes: []});

        const staffIds = extract(selectedStaffList, "id");

        staffIds && staffIds.length === 1 && staffIds[0] !== undefined && RemotingService.getRemoteCall(
            'api/hr/leave/meta', {staffIds}, (metaDatas) => {
                this.prepareLeaveTypes(metaDatas);
            });

        if(staffIds[0] === undefined) {
            this.prepareLeaveTypes([]);
        }
    };

    prepareLeaveTypes = (metaDatas) => {
        let leaveTypes = metaDatas.map(metaData => {
            return {
                id: metaData.id,
                staffId: metaData.staffId,
                type: EnumLeaveType[metaData.type],
                quota: metaData.quota,
                used: metaData.used,
                remaining: metaData.remaining
            }
        })

        let sortedLeaveTypes = this.sortByLeaveType(leaveTypes)

        this.setState({leaveTypes: sortedLeaveTypes.filter(leaveType => leaveType.type != undefined), editMode: false})
    }

    sortByLeaveType = (leaveTypes) => {
        let sortedResult = [];
        EnumLeaveType.keys().map(enumKey => {
            leaveTypes.map(leaveType => {
                if(enumKey===leaveType.type?.key){
                    sortedResult.push(leaveType)
                }
            })
        });

        return sortedResult;
    }

    getMetaDataForType = (type, staffId) => {
        const metaData = this.state.metaDatas.find(i => i.type === type && i.staffId === staffId);
        return metaData || {remaining: '...'};
    }

    typeTemplate(type) {
        return type && (
            <div className="d-flex align-items-center">
                <span className="circle mr-1" style={{backgroundColor: EnumLeaveType[type.key].color}}/>
                <span>{EnumLeaveType[type.key].name}</span>
            </div>
        )
    }

    daysHoursTemplate(leaveType, field) {
        return (<div className="d-flex align-items-center">
                {this.state.editMode && field === "quota" ?
                    (leaveType[field] || leaveType[field] == 0) &&(<div className="d-flex align-items-center">
                        <NumberInput style={{maxWidth:"100px"}} min={0}
                                   onChange={(e)=>this.handleQuotaChange(e, leaveType)} value={leaveType[field]}
                                   disabled={!this.checkLeaveTypeEditability(leaveType)}/><div style={{maxWidth:"1px"}}>{EnumLeaveType[leaveType.type.key].unit}</div>
                    </div>) :
                    (<div style={{maxWidth:"100px"}}>{leaveType.quota === "" ? 0 : NumberUtil.toFixed(leaveType[field], 2)}{EnumLeaveType[leaveType.type.key].unit}</div>)}
            </div>)
    }

    handleQuotaChange = (e, lType) => {
        lType.quota = e === "" ? 0 : e ;
        let updatedChangedLeaveTypes = this.state.changedLeaveTypes
        let changedItem = updatedChangedLeaveTypes.filter(leaveType => leaveType.type===lType.type)
        changedItem.length > 0 ?
            updatedChangedLeaveTypes.map(updatedType => updatedType.type===lType.type ? updatedType.quota = lType.quota : noop):
            updatedChangedLeaveTypes.push(lType)
        this.setState({changedLeaveTypes: updatedChangedLeaveTypes})
    }

    checkLeaveTypeEditability(leaveType){
        if(leaveType.type.key === "MATERNITY"){
            return this.state.selectedStaffList[0].gender === "FEMALE";
        }

        if(leaveType.type.key === "PATERNITY"){
            return this.state.selectedStaffList[0].gender === "MALE";
        }

        return true;
    }

    handleEditClick = () => {
        this.setState({editMode: true})
    }

    handleSaveClick = () => {
        let quotaLessThanUsedItems = this.state.changedLeaveTypes.filter(leaveType => leaveType.quota < leaveType.used);
        if(quotaLessThanUsedItems.length > 0) {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Used amount can not exceed quota value'
            });
            return;
        }

        let updatedLeaveTypes = this.state.changedLeaveTypes.map(leaveType => {return{
            ...leaveType,
            type: leaveType.type.key,
            quota: leaveType.quota,
            used: leaveType.used,
            remaining: leaveType.remaining
        }})

        updatedLeaveTypes.length > 0 && RemotingService.postRemoteCall(
            'api/hr/leave/updateLeaveQuota', updatedLeaveTypes, () => {
                this.handleStaffSelectionChange(this.state.selectedStaffList)
                this.setState({editMode: false, changedLeaveTypes: []});
            });
    }

    handleCancelClick = () => {
        this.handleStaffSelectionChange(this.state.selectedStaffList)
        this.setState({editMode: false})
    }

    render(){
        return(
            <Container style={{maxWidth: '100%', fontSize: 16}}>
                <Row>
                    <Col xs={2}>
                        <SpecialityAndStaffFilter selectedSpecialityList={this.state.selectedSpecialityList}
                                                  selectedStaffList={this.state.selectedStaffList}
                                                  onSpecialitySelectionChange={this.handleSpecialitySelectionChange}
                                                  onStaffSelectionChange={this.handleStaffSelectionChange}
                                                  singleStaffSelection
                        />
                    </Col>
                    <Col xs={7}>
                        <DataTable style={{marginTop: '20px', marginLeft: '20px'}} value={this.state.leaveTypes}
                                emptyMessage={<div style={{textAlign: "center"}}>Please select staff from menu</div>}>
                            <Column header="Leave Type" field="type" body={leaveType => this.typeTemplate(leaveType?.type)} style={{width: "400px", height: "50px", fontSize: "13px"}}/>
                            <Column header="Quota" field="quota" body={leaveType => this.daysHoursTemplate(leaveType, "quota")} style={{width: "100px", height: "50px", fontSize: "13px"}}/>
                            <Column header="Remaining" field="remaining" body={leaveType => this.daysHoursTemplate(leaveType, "remaining")} style={{width: "100px", height: "50px", fontSize: "13px"}}/>
                            <Column header="Used" field="used" body={leaveType => this.daysHoursTemplate(leaveType, "used")} style={{width: "100px", height: "50px", fontSize: "13px"}}/>
                        </DataTable>
                    </Col>
                    <Col xs={3}>
                        <Button variant="outline-secondary" style={{width: 100, border: "1px solid #ccc", color: "#84BE56", backgroundColor: "white", fontSize: "12px", marginTop: '20px'}}
                                onClick={this.handleEditClick}>
                            <FontAwesomeIcon icon={["fas", "edit"]} size={"sm"}/> Edit
                        </Button>
                        <Button variant="success" onClick={e => this.handleSaveClick(e)}
                                disabled={false} style={{width: 100, fontSize: "12px", marginTop: '20px'}}>
                            Save
                        </Button>
                        <Button variant="secondary" style={{width: 100, fontSize: "12px", marginTop: '20px', backgroundColor: '#E85840'}}
                                onClick={this.handleCancelClick}>
                            Cancel
                        </Button>

                    </Col>
                </Row>
            </Container>

        )

    }
}
