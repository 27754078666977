import React, {Component} from "react";
import DateUtil from "../../utils/DateUtil";
import RemotingService from "../../services/remoting-service/RemotingService";
import "./AvailableRooms.css";
import {ScrollPanel} from "primereact/scrollpanel";
import clsx from "clsx";

export default class AvailableRooms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: props.startDate,
            endDate: props.endDate,
            availableRooms: []
        }
    }

    componentDidMount() {
        this.retrieveAvailableRooms();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!DateUtil.isSame(prevProps.startDate, this.props.startDate)
            || !DateUtil.isSame(prevProps.endDate, this.props.endDate)
            || prevProps.staffId !== this.props.staffId||prevProps.leaves!==this.props.leaves) {

            this.setState({
                startDate: this.props.startDate,
                endDate: this.props.endDate
            }, () => this.retrieveAvailableRooms());
        }
    }

    retrieveAvailableRooms() {
        const params = {
            startDate: DateUtil.formatDate(this.state.startDate, "YYYY-MM-DD"),
            endDate: DateUtil.formatDate(this.state.endDate, "YYYY-MM-DD"),
            staffId: this.props.staffId
        };

        RemotingService.getRemoteCall('api/room/available-rooms', params, (availableRooms) => {
            this.setState({availableRooms: availableRooms.sort((r1, r2) => new Date(r1.date) - new Date(r2.date))});
        });
    }

    handleRoomClicked(availability) {
        if(!this.props.isAdmin){
            this.props.availableRoomClicked(availability)
        }
    }

    render() {
        return (
            <div className="AvailableRooms">
                <div className="font-weight-bold p-1">Available Rooms</div>
                {
                    this.state.availableRooms.length > 0 ?
                        <ScrollPanel style={{width: '100%', height: 250}}>
                            {this.state.availableRooms.map((availability, i) =>
                                <div onClick={() => this.handleRoomClicked(availability)}>
                                    <Availability key={i} availability={availability} clickable={!this.props.isAdmin}/>
                                </div>
                            )}
                        </ScrollPanel> :
                        <div>No room available</div>
                }
            </div>
        )
    }

}

class Availability extends Component {

    dateFormat = "DD/MM/YYYY";

    render() {
        const {availability, clickable} = this.props;

        return (
            <div className={clsx("AvailableRoomItem", clickable ? "clickable" : null)}>
                <span className="room-info">{availability.room.info}</span>
                <span className="date">{DateUtil.formatDate(availability.date, this.dateFormat)} {DateUtil.getDayName(availability.date)}</span>
            </div>
        )
    }

}