import React, {Component} from "react";
import "../../VisitHistory.css"
import FunctionEnum from "../../../assessment/enums/functionalTesting/FunctionEnum";
import ResultEnum from "../../../assessment/enums/functionalTesting/ResultEnum";

export default class FunctionalTestingViewHistory extends Component {
    render() {
        if (!this.props.functionalTesting || !this.props.functionalTesting.items || this.props.functionalTesting.items.length === 0) {
            return <div className="pl-3">No information available</div>;
        }

        return (
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Function</th>
                    <th>Result</th>
                    <th>Details</th>
                </tr>
                {this.props.functionalTesting.items.map((item, index) => {
                    return (<tr key={"functionalTestingViewHistory" + index} style={{backgroundColor: "white"}}>
                        <td>{FunctionEnum[item.function]?.name}</td>
                        <td>{ResultEnum[item.result]?.name}</td>
                        <td>{item.detail}</td>
                    </tr>)
                })}
                </tbody>
            </table>
        );
    }
}
