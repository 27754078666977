import React, {Component} from "react";
import PropTypes from "prop-types";
import {noop} from "lodash-es";
import _uniqueId from "lodash/uniqueId";
import clsx from "clsx";
import NotificationService from "../../services/notification-service/NotificationService";

export default class FileInput extends Component {

    static defaultProps = {
        id: _uniqueId("file-input-"),
        className: null,
        accept: null,
        maxFileSize: null,
        onChange: noop
    };

    static propTypes = {
        id: PropTypes.string,
        className: PropTypes.string,
        accept: PropTypes.string,
        maxFileSize: PropTypes.number,
        onChange: PropTypes.func
    }

    onChange(event) {
        const files = event.target.files;
        if (files && files.length > 0) {
            this.createFileDto(files[0]).then(fileDto => {
                this.props.onChange(fileDto);
                this.inputRef.value = "";
            });
        }
    }

    async createFileDto(file) {
        const fileType = file.type ?? 'application/octet-stream';
        const fileName = file.name ?? 'file';
        const fileSize = file.size;

        const maxFileSize =this.props.maxFileSize;
        if (fileSize > maxFileSize) {
            NotificationService.showNotification({
                severity: "error",
                summary: "Error",
                detail: `Maximum allowed file size is ${maxFileSize} bytes.`
            });
            return Promise.resolve(null);
        }

        let reader = new FileReader();

        return new Promise ((resolve) => {
            reader.readAsDataURL(file);
            reader.onloadend = (evt) => {
                let dataAsDataUrl = evt.target.result;
                let base64String = dataAsDataUrl.replace(/^data:.+(;base64)?,/,'');
                resolve({
                    content: base64String,
                    mimeType: fileType,
                    name: fileName,
                    fileSize: fileSize
                });
            };
        });
    }

    render() {
        const {accept, children, className} = this.props;

        return (
            <>
                <label htmlFor={this.props.id} className={clsx("cursor-pointer", className)}>
                    {children}
                </label>
                <input ref={ref => this.inputRef = ref}
                       id={this.props.id}
                       type="file"
                       hidden
                       accept={accept}
                       onChange={event => this.onChange(event)}/>
            </>
        )
    }

}