import Enum from "../../../../../../enums/Enum";

const IndividualMusclesEnum = new Enum({
    Deltoid: {name: "Deltoid"},
    Erector_Spinae: {name: "Erector spinae "},
    Infraspinatus: {name: "Infraspinatus"},
    Interspinales: {name: "Interspinales"},
    Intertransversarii: {name: "Intertransversarii"},
    Latissimus_Dorsi: {name: "Latissimus dorsi"},
    Levator_Scapulae: {name: "Levator scapulae"},
    Levatores_Costarum: {name: "Levatores costarum"},
    Obliquus_Capitis_Inferior: {name: "Obliquus capitis inferior"},
    Obliquus_Capitis_Superior: {name: "Obliquus capitis superior"},
    Rectus_Capitus_Posterior_Major: {name: "Rectus capitus posterior major"},
    Rectus_Capitus_Posterior_Minor: {name: "Rectus capitus posterior minor"},
    Rhomboid_Major: {name: "Rhomboid major"},
    Rhomboid_Minor: {name: "Rhomboid minor"},
    Serratus_Posterior_Inferior: {name: "Serratus posterior inferior"},
    Serratus_Posterior_Superior: {name: "Serratus posterior superior"},
    Splenius_Capitis: {name: "Splenius capitis"},
    Splenius_Cervicis: {name: "Splenius cervicis"},
    Supraspinatus: {name: "Supraspinatus"},
    Teres_Major: {name: "Teres major"},
    Teres_Minor: {name: "Teres minor"},
    Transversospinalis: {name: "Transversospinalis "},
    Trapezius: {name: "Trapezius"},
    Abductor_Digiti_Minimi_Hand: {name: "Abductor digiti minimi (hand)"},
    Abductor_Pollicis_Brevis: {name: "Abductor pollicis brevis"},
    Abductor_Pollicis_Longus: {name: "Abductor pollicis longus"},
    Adductor_Pollicis: {name: "Adductor pollicis"},
    Anconeus: {name: "Anconeus"},
    Articularis_Cubiti: {name: "Articularis cubiti"},
    Biceps_Brachii: {name: "Biceps brachii"},
    Brachialis: {name: "Brachialis"},
    Brachioradialis: {name: "Brachioradialis"},
    Coracobrachialis: {name: "Coracobrachialis"},
    Extensor_Carpi_Radialis_Brevis: {name: "Extensor carpi radialis brevis"},
    Extensor_Carpi_Radialis_Longus: {name: "Extensor carpi radialis longus"},
    Extensor_Carpi_Ulnaris: {name: "Extensor carpi ulnaris"},
    Extensor_Digiti_Minimi_Hand: {name: "Extensor digiti minimi (hand)"},
    Extensor_Digitorum_Hand: {name: "Extensor digitorum (hand)"},
    Extensor_Indicis: {name: "Extensor indicis"},
    Extensor_Pollicis_Brevis: {name: "Extensor pollicis brevis"},
    Extensor_Pollicis_Longus: {name: "Extensor pollicis longus"},
    Flexor_Carpi_Radialis: {name: "Flexor carpi radialis"},
    Flexor_Carpi_Ulnaris: {name: "Flexor carpi ulnaris"},
    Flexor_Digiti_Minimi_Brevi: {name: "Flexor digiti minimi brevis (hand)"},
    Flexor_Digitorum_Profundus: {name: "Flexor digitorum profundus"},
    Flexor_Digitorum_Superficialis: {name: "Flexor digitorum superficialis"},
    Flexor_Pollicis_Brevis: {name: "Flexor pollicis brevis"},
    Flexor_Pollicis_Longus: {name: "Flexor pollicis longus"},
    Interossei_Dorsal_Of_Hand: {name: "Interossei - dorsal of hand"},
    Interossei_Palmar_Of_Hand: {name: "Interossei - palmar of hand"},
    Lumbricals_Of_Hand: {name: "Lumbricals of hand"},
    Opponens_Digiti_Minimi_Hand: {name: "Opponens digiti minimi (hand)"},
    Opponens_Pollicis: {name: "Opponens pollicis"},
    Palmaris_Brevis: {name: "Palmaris brevis"},
    Palmaris_Longus: {name: "Palmaris longus"},
    Pectoralis_Major: {name: "Pectoralis major"},
    Pectoralis_Minor: {name: "Pectoralis minor"},
    Pronator_Quadratus: {name: "Pronator quadratus"},
    Pronator_Teres: {name: "Pronator teres"},
    Serratus_Anterior: {name: "Serratus anterior"},
    Subscapularis: {name: "Subscapularis"},
    Supinator: {name: "Supinator"},
    Triceps: {name: "Triceps"},
    Aryepiglotticus: {name: "Aryepiglotticus"},
    Auricularis: {name: "Auricularis"},
    Buccinator: {name: "Buccinator"},
    Constrictor_Of_Pharynx_Inferior: {name: "Constrictor of pharynx - inferior"},
    Constrictor_Of_Pharynx_Middle: {name: "Constrictor of pharynx - middle"},
    Constrictor_Of_Pharynx_Superior: {name: "Constrictor of pharynx - superior"},
    Corrugator_Supercilii: {name: "Corrugator supercilii"},
    Cricothyroid: {name: "Cricothyroid"},
    Depressor_Anguli_Oris: {name: "Depressor anguli oris"},
    Depressor_Labii_Inferioris: {name: "Depressor labii inferioris"},
    Digastric: {name: "Digastric"},
    Frontalis: {name: "Frontalis"},
    Genioglossus: {name: "Genioglossus"},
    Geniohyoid: {name: "Geniohyoid"},
    Hyoglossus: {name: "Hyoglossus"},
    Inferior_Oblique: {name: "Inferior oblique"},
    Inferior_Rectus: {name: "Inferior rectus"},
    Intrinsic_Muscles_Of_Tongue: {name: "Intrinsic muscles of tongue"},
    Lateral_Cricoarytenoid: {name: "Lateral cricoarytenoid"},
    Lateral_Pterygoid: {name: "Lateral pterygoid"},
    Lateral_Rectus: {name: "Lateral rectus"},
    Levator_Anguli_Oris: {name: "Levator anguli oris"},
    Levator_Labii_Superioris: {name: "Levator labii superioris"},
    Alaeque_Nasi: {name: "Alaeque nasi"},
    Levator_Palpebrae_Superioris: {name: "Levator palpebrae superioris"},
    Levator_Veli_Palatini: {name: "Levator veli palatini"},
    Longus_Capitis: {name: "Longus capitis"},
    Longus_Colli: {name: "Longus colli"},
    Masseter: {name: "Masseter"},
    Medial_Pterygoid: {name: "Medial pterygoid"},
    Medial_Rectus: {name: "Medial rectus"},
    Mentalis: {name: "Mentalis"},
    M_Uvulae: {name: "M. uvulae"},
    Mylohyoid: {name: "Mylohyoid"},
    Nasalis: {name: "Nasalis"},
    Oblique_Arytenoid: {name: "Oblique arytenoid"},
    Omohyoid: {name: "Omohyoid"},
    Orbicularis_Oculi: {name: "Orbicularis oculi"},
    Orbicularis_Oris: {name: "Orbicularis oris"},
    Palatoglossus: {name: "Palatoglossus"},
    Palatopharyngeus: {name: "Palatopharyngeus"},
    Platysma: {name: "Platysma"},
    Posterior_Cricoarytenoid: {name: "Posterior cricoarytenoid"},
    Procerus: {name: "Procerus"},
    Rectus_Capitus_Anterior: {name: "Rectus capitus anterior"},
    Rectus_Capitus_Lateralis: {name: "Rectus capitus lateralis"},
    Risorius: {name: "Risorius"},
    Salpingopharyngeus: {name: "Salpingopharyngeus"},
    Scalenus_Anterior: {name: "Scalenus anterior"},
    Scalenus_Medius: {name: "Scalenus medius"},
    Scalenus_Minimus: {name: "Scalenus minimus"},
    Scalenus_Posterior: {name: "Scalenus posterior"},
    Stapedius: {name: "Stapedius"},
    Sternocleidomastoid: {name: "Sternocleidomastoid"},
    Sternohyoid: {name: "Sternohyoid"},
    Sternothyroid: {name: "Sternothyroid"},
    Styloglossus: {name: "Styloglossus"},
    Stylohyoid: {name: "Stylohyoid"},
    Stylopharyngeus: {name: "Stylopharyngeus"},
    Superior_Oblique: {name: "Superior oblique"},
    Superior_Rectus: {name: "Superior rectus"},
    Temporalis: {name: "Temporalis"},
    Temporoparietalis: {name: "Temporoparietalis"},
    Tensor_Tympani: {name: "Tensor tympani"},
    Tensor_Veli_Palatini: {name: "Tensor veli palatini"},
    Thyro_Arytenoid_Vocalis: {name: "Thyro - arytenoid & vocalis"},
    Thyro_Epiglotticus: {name: "Thyro - epiglotticus"},
    Thyrohyoid: {name: "Thyrohyoid"},
    Transverse_Arytenoid: {name: "Transverse arytenoid"},
    Zygomaticus_Major: {name: "Zygomaticus major"},
    Zygomaticus_Minor: {name: "Zygomaticus minor"},
    Intercostals_External: {name: "Intercostals external"},
    Intercostals_Innermost: {name: "Intercostals innermost"},
    Intercostals_Internal: {name: "Intercostals internal"},
    Subcostalis: {name: "Subcostalis"},
    Transversus_Thoracis: {name: "Transversus thoracis"},
    Diaphragm: {name: "Diaphragm"},
    External_Oblique_Abdominis: {name: "External oblique abdominis"},
    Internal_Oblique_Abdominis: {name: "Internal oblique abdominis"},
    Psoas_Major: {name: "Psoas major"},
    Psoas_Minor: {name: "Psoas minor"},
    Pyramidalis: {name: "Pyramidalis"},
    Quadratus_Lumborum: {name: "Quadratus lumborum"},
    Rectus_Abdominis: {name: "Rectus abdominis"},
    Transversus_Abdominis: {name: "Transversus abdominis"},
    Bulbospongiosus: {name: "Bulbospongiosus"},
    Cremaster: {name: "Cremaster"},
    Dartos: {name: "Dartos"},
    Deep_Transverse_Perinei: {name: "Deep transverse perinei"},
    Gemellus_Inferior: {name: "Gemellus inferior"},
    Gemellus_Superior: {name: "Gemellus superior"},
    Gluteus_Maximus: {name: "Gluteus maximus"},
    Gluteus_Medius: {name: "Gluteus medius"},
    Gluteus_Minimus: {name: "Gluteus minimus"},
    Ischiocavernosus: {name: "Ischiocavernosus"},
    Levator_Ani: {name: "Levator ani"},
    Obturator_Internus: {name: "Obturator internus "},
    Piriformis: {name: "Piriformis "},
    Sphincter_Ani: {name: "Sphincter ani"},
    Sphincter_Urethrae: {name: "Sphincter urethrae"},
    Superficial_Transverse_Perinei: {name: "Superficial transverse perinei"},
    Abductor_Digiti_Minimi_Foot: {name: "Abductor digiti minimi (foot)"},
    Abductor_Hallucis: {name: "Abductor hallucis"},
    Adductor_Brevis: {name: "Adductor brevis"},
    Adductor_Hallucis: {name: "Adductor hallucis"},
    Adductor_Longus: {name: "Adductor longus"},
    Adductor_Magnus: {name: "Adductor magnus"},
    Articularis_Genu: {name: "Articularis genu"},
    Biceps_Femoris: {name: "Biceps femoris"},
    Extensor_Digitorum_Brevis_Foot: {name: "Extensor digitorum brevis (foot)"},
    Extensor_Digitorum_Longus_Foot: {name: "Extensor digitorum longus (foot)"},
    Extensor_Hallucis_Brevis: {name: "Extensor hallucis brevis"},
    Extensor_Hallucis_Longus: {name: "Extensor hallucis longus"},
    Flexor_Digiti_Minimi_Brevis_Foot: {name: "Flexor digiti minimi brevis (foot)"},
    Flexor_Digitorum_Brevis: {name: "Flexor digitorum brevis"},
    Flexor_Digitorum_Longus_Foot: {name: "Flexor digitorum longus (foot)"},
    Flexor_Hallucis_Brevis: {name: "Flexor hallucis brevis"},
    Flexor_Hallucis_Longus: {name: "Flexor hallucis longus"},
    Gastrocnemius: {name: "Gastrocnemius"},
    Gracilis: {name: "Gracilis"},
    Iliacus: {name: "Iliacus"},
    Interossei_Dorsal_Of_Foot: {name: "Interossei - dorsal of foot"},
    Interossei_Plantar_Of_Foot: {name: "Interossei - plantar of foot"},
    Lumbricals_Of_Foot_4: {name: "Lumbricals of foot (4)"},
    Obturator_Externus: {name: "Obturator externus"},
    Pectineus: {name: "Pectineus"},
    Peroneus_Brevis: {name: "Peroneus brevis"},
    Peroneus_Longus: {name: "Peroneus longus"},
    Peroneus_Tertius: {name: "Peroneus tertius"},
    Plantaris: {name: "Plantaris"},
    Popliteus: {name: "Popliteus"},
    Quadratus_Femoris: {name: "Quadratus femoris"},
    Quadratus_Plantae: {name: "Quadratus plantae"},
    Rectus_Femoris: {name: "Rectus femoris"},
    Sartorius: {name: "Sartorius"},
    Semimembranosus: {name: "Semimembranosus"},
    Semitendinosus: {name: "Semitendinosus"},
    Soleus: {name: "Soleus"},
    Tensor_Fasciae_Lata: {name: "Tensor fasciae lata"},
    Tibialis_Anterior: {name: "Tibialis anterior"},
    Tibialis_Posterior: {name: "Tibialis posterior"},
    Vastus_Intermedius: {name: "Vastus intermedius"},
    Vastus_Lateralis: {name: "Vastus lateralis"},
    Vastus_Medialis: {name: "Vastus medialis"},
    Coccygeus: {name: "Coccygeus"},
});

export default IndividualMusclesEnum;