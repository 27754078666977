import React, {Component} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import {base64ToBlob, downloadBlob} from "../../../../utils/FileDownloader";
import DirectBillingResubmissionTypeEnum from "../../enums/DirectBillingResubmissionTypeEnum";

export default class ResubmissionsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            resubmissions: []
        };
    }

    componentDidMount() {
        this.retrieveResubmissions();
    }

    retrieveResubmissions() {
        if(this.props.directBillingItemId != null){
            RemotingService.getRemoteCall(`api/insurance/coordinator/direct-billing-item/${this.props.directBillingItemId}/resubmission`, null, resubmissions => {
                this.setState({resubmissions});
            });
        }

        if(this.props.claimActivityId != null){
            RemotingService.getRemoteCall(`api/insurance/coordinator/claim-activity/${this.props.claimActivityId}/resubmission`, null, resubmissions => {
                this.setState({resubmissions});
            });
        }

    }

    typeTemplate(resubmission) {
        return DirectBillingResubmissionTypeEnum[resubmission.type]?.name;
    }

    attachmentTemplate(attachment) {
        return <span className="cursor-pointer"
                     onClick={() => this.downloadFile(attachment)}>
                <u>{attachment?.name}</u>
                {attachment?.name && <FontAwesomeIcon className="ml-1" icon="file-download"/>}
            </span>
    }

    downloadFile(attachment) {
        downloadBlob(base64ToBlob(attachment.data, attachment.contentType), attachment.name);
    }

    render() {
        return (
            <div style={{width: 700}}>
                <DataTable emptyMessage={"No items found"}
                           value={this.state.resubmissions}
                           columnResizeMode="fit"
                           dataKey="id">
                    <Column header="Type" style={{width: 150}}
                            body={(rowData) => this.typeTemplate(rowData)}/>
                    <Column header="Comments" style={{width: "50%" , wordWrap : "break-word"}} field="comments"/>
                    <Column header="Attachment"
                            body={(rowData) => this.attachmentTemplate(rowData.attachment)}/>
                </DataTable>
            </div>
        )
    }
}