import RiskLevelEnum from "../enums/morseFallRisk/RiskLevelEnum";

class MorseFallRiskUtils {

    getRiskLevel(riskScore) {
        if (riskScore === null || riskScore === undefined) {
            return riskScore;
        }
        if (riskScore < 25) {
            return RiskLevelEnum.LOW;
        }
        if (riskScore < 45) {
            return RiskLevelEnum.MODERATE;
        }
        return RiskLevelEnum.HIGH;
    }
}

export default new MorseFallRiskUtils();