import React, {Component} from "react";
import '../../EMR.css';
import Select from "react-select";
import TextInput from "../../../../../components/text-input/TextInput";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import LowRiskInterventionEnum from "../enums/fallriskmanagement/LowRiskInterventionEnum";
import HighRiskInterventionEnum from "../enums/fallriskmanagement/HighRiskInterventionEnum";
import FallRiskManagementUtils from "../utils/FallRiskManagementUtils";

export default class FallRiskManagementModal extends Component {

    state = {}

    constructor(props) {
        super(props);
        this.state.fallRiskManagement = this.props.treatment.fallRiskManagement;
        if (this.state.fallRiskManagement.items == null) {
            this.state.fallRiskManagement.items = [];
        }
    }

    addFallRiskManagementItem = () => {
        this.state.fallRiskManagement.items.push({
            lowRiskIntervention: this.state.lowRiskIntervention,
            highRiskIntervention: this.state.highRiskIntervention,
            details: this.state.details
        })
        this.setState({
            lowRiskIntervention: null,
            highRiskIntervention: null,
            details: null
        })
    }

    getFallRiskManagementDivs = () => {
        if (this.state.fallRiskManagement == null || this.state.fallRiskManagement.items.length == 0) {
            return;
        }
        let divs = [];
        this.state.fallRiskManagement.items.forEach((item, index) => divs.push(
            <div className={"div-list-item w-100"} key={"fallRiskManagementItem" + index}>
                {FallRiskManagementUtils.getFallRiskItemDiv(item)}
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize: "10px", marginLeft: "2px"}}
                        onClick={() => {
                            this.state.fallRiskManagement.items.splice(index, 1);
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </Button>
            </div>
        ));
        return divs;
    }

    render() {
        let fallRiskManagementDivs = this.getFallRiskManagementDivs();
        return (
            <div className={"emr-modal content-row emr-background"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px", minHeight: "20vw"}}>
                    <div>
                        <div style={{display: "flex"}}>
                            <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Fall Risk Management</div>
                        </div>
                        <div className={"row"} style={{marginBottom: "10px", marginLeft: "0px"}}>
                            <div className={"col-6"}>
                                <div className="font-weight-bold">Risk Level</div>
                            </div>
                            <div className={"col-6"}>
                                <div className="font-weight-bold">Intervention</div>
                            </div>
                        </div>
                        <div className={"row no-margin"}>
                            <div className={"col-6"}>
                                <div className="font-weight-bold">Low Risk</div>
                            </div>
                            <div className={"col-6"}>
                                <div>
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        isClearable={true}
                                        placeholder={""}
                                        options={LowRiskInterventionEnum.toArray().map(v => {
                                                return {value: v.key, label: v.name}
                                            }
                                        )}
                                        value={this.state.lowRiskIntervention != null ?
                                            {
                                                value: LowRiskInterventionEnum[this.state.lowRiskIntervention].key,
                                                label: LowRiskInterventionEnum[this.state.lowRiskIntervention].name
                                            } : null}
                                        onChange={(event) => {
                                            let r = event == null ? null : event.value;
                                            this.setState((state) => {
                                                state.lowRiskIntervention = r;
                                                return state;
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"row no-margin"}>
                            <div className={"col-6"}>
                                <div className="font-weight-bold">Moderate/High Risk</div>
                            </div>
                            <div className={"col-6"}>
                                <div>
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        isClearable={true}
                                        placeholder={""}
                                        options={HighRiskInterventionEnum.toArray().map(v => {
                                                return {value: v.key, label: v.name}
                                            }
                                        )}
                                        value={this.state.highRiskIntervention != null ?
                                            {
                                                value: HighRiskInterventionEnum[this.state.highRiskIntervention].key,
                                                label: HighRiskInterventionEnum[this.state.highRiskIntervention].name
                                            } : null}
                                        onChange={(event) => {
                                            let r = event == null ? null : event.value;
                                            this.setState((state) => {
                                                state.highRiskIntervention = r;
                                                return state;
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"row"} style={{position: "relative", marginLeft: "5px", marginTop: "5px"}}>
                            <TextInput value={this.state.details || ''}
                                       placeholder={"Details"}
                                       onChange={value => this.setState(state => {
                                           state.details = value;
                                           return state;
                                       })}
                                       style={{fontSize: "14px", width: "90%"}}
                            />
                            <Button className={"emr-plus-button"}
                                    variant="success"
                                    disabled={this.state.lowRiskIntervention == null && this.state.highRiskIntervention == null}
                                    style={{fontSize: "12px", position: "absolute", right: "20px"}}
                                    onClick={() => this.addFallRiskManagementItem()}
                            >
                                <FontAwesomeIcon icon={["fas", "plus"]}/>
                            </Button>
                        </div>
                        <div className={"row"} style={{marginLeft: "5px", marginRight: "5px", marginTop: "10px"}}>
                            <div className={"div-list-wrapper"}>
                                {fallRiskManagementDivs}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
