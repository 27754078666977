import Enum from "../../../../../../enums/Enum";

const ProceduresEnum = new Enum({
    Cortisone_Injection: {name: "Cortisone Injection"},
    Platelet_Rich_Plasma: {name: "Platelet Rich Plasma (PRP) Injection with Anesthesia (Lidocaine 1%)"},
    Joint_Effusion_Aspiration: {name: "Joint Effusion Aspiration"},
    Hyaluronic_Acid_Joint_Injection: {name: "Hyaluronic Acid Joint Injection"},
    Sciatic_Nerve_Block: {name: "Sciatic Nerve Block"},
    IV_Infusion: {name: "IV Infusion"},
    Cyst_Excision_Aspiration: {name: "Cyst Excision Aspiration"},
    Consussion_Testing: {name: "Consussion Testing"},
    VitaminB_Complex_Injection: {name: "Vitamin B Complex Injection"},
    Detox_Injection: {name: "Detox Injection"},
    Quaddle_Neural_Therapy: {name: "Quaddle Neural Therapy"},
    Autohemotherapy: {name: "Autohemotherapy"},
    VitaminD_Injection: {name: "Vitamin D Injection"},
    IM_Injection: {name: "IM Injection"},
    Other: {name: "Other"}
});

export default ProceduresEnum;