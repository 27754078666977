import React, {Component} from "react";
import {TextInput} from "../../../components";
import {Button} from "react-bootstrap";
import RemotingService from "../../../services/remoting-service/RemotingService";
import {FormField} from "../../../components/form";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import AuthService from "../../../services/auth-service/AuthService";

export default class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            email: null,
            readOnly: false
        }
    }

    componentDidMount() {
        if (AuthService.isAuthenticated()) {
            AuthService.logout();
            window.location.reload();
        }
    }

    resetPassword() {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        RemotingService.postRemoteCall('api/public/auth/forgot-password', {email: this.state.email}, () => {
            this.setState({readOnly: true});
        });
    }

    render() {
        this.formFields = [];

        return (
            <div className="container" style={{width: "24rem"}}>
                <div className="row d-flex p-justify-center mb-4">
                    <h3>Forgot Your Password?</h3>
                </div>
                <div className="row">
                    <div className="col-2">
                        Email
                    </div>
                    <div className="col-10">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required email
                                   validateOn={this.state.email}>
                            <TextInput className="w-100"
                                       disabled={this.state.readOnly}
                                       value={this.state.email}
                                       onChange={value => this.setState({email: value})}/>
                        </FormField>
                    </div>
                </div>
                <div className="row">
                    <div className="col d-flex p-justify-end">
                        <Button disabled={this.state.readOnly}
                                onClick={() => this.resetPassword()}>Reset My Password</Button>
                    </div>
                </div>
            </div>
        )
    }

}