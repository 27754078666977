import confirmDialog from "../../components/dialog/ConfirmDialog";
import RemotingService from "../../services/remoting-service/RemotingService";
import noop from "lodash-es/noop";

class HrRemoteService {

    submit = (id, params, callback) => {
        confirmDialog("Submit Leave For Approval",
            "You are about to submit this leave for approval. Are you sure?",
            () => {
                RemotingService.postRemoteCall(`api/hr/leave/submit/${id}`, params,
                    (leave) => {
                        callback(leave);
                    });
            });
    }

    approve = (id, params, callback) => {
        confirmDialog("Approve Leave",
            "You are about to approve this leave. Are you sure?",
            () => {
                RemotingService.postRemoteCall(`api/hr/leave/approve/${id}`, params,
                    (leave) => {
                        callback(leave);
                    })
            });
    }

    approveSickLeaveWithoutAttachment = (id, params, callback) => {
        confirmDialog("Attachment Missing",
            "Attachment missing for Sick Leave. Do you want to confirm?",
            () => {
                RemotingService.postRemoteCall(`api/hr/leave/approve/${id}`, params,
                    (leave) => {
                        callback(leave);
                    })
            }, noop(), null, "Yes", "No");
    }

    reject = (id, params, callback) => {
        confirmDialog("Reject Leave",
            "You are about to reject this leave. Are you sure?",
            () => {
                RemotingService.postRemoteCall(`api/hr/leave/reject/${id}`, params,
                    (leave) => {
                        callback(leave);
                    });
            });
    }

    delete = (leave, callback) => {
        confirmDialog("Discard Leave",
            "You are about to delete this leave. Are you sure?",
            () => {
                RemotingService.deleteRemoteCall(`api/hr/leave/${leave.id}`, null,
                    () => {
                        callback();
                    });
            });
    }

    deleteList = (leaveList, callback) => {
        confirmDialog("Discard Leaves",
            "You are about to delete all pending leaves. Are you sure?",
            () => {
            leaveList.map(leave => {
                RemotingService.deleteRemoteCall(`api/hr/leave/${leave.id}`, null,
                    () => {
                        callback();
                    });
                })
            });
    }

    submitList = (leaveList, callback) => {
        confirmDialog("Submit Leaves For Approval",
            "You are about to submit selected leaves for approval. Are you sure?",
            () => {
                RemotingService.postRemoteCall(`api/hr/leave/submitList`, leaveList,
                    (leaves) => {
                        callback(leaves);
                    }, (leaves) => {
                        callback(leaves);
                    });
            });
    }

    createClinicClosedLeave = (params, callback = noop) => {
        RemotingService.postRemoteCall('api/hr/leave/clinic-closed', params, (leave) => {
            callback(leave);
        });
    }

    updateClinicClosedLeave = (params, callback = noop) => {
        RemotingService.postRemoteCall('api/hr/leave/clinic-closed/update', params, (leave) => {
            callback(leave);
        });
    }

    getDurationExcludingBreaks = (id, params, callback = noop) => {
        RemotingService.getRemoteCall(`api/hr/leave/getDurationExcludingBreaks/${id}`, params, (result) => {
            callback(result);
        });
    }

}

export default new HrRemoteService();