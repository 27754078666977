import React, {Component} from 'react';
import moment from "moment";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import LogoDubai from "../../../../layout/LogoDubai";
import SignatureInput from "../../../../components/signature-input/SignatureInput";
import {pick} from "lodash-es";
import {TextInput, Wizard} from "../../../../components";
import LogoKuwait from "../../../../layout/LogoKuwait";
import {FormField} from "../../../../components/form";
import PropTypes from "prop-types";

class CreditConsentForm extends Component {

    static propTypes = {
        show: PropTypes.func,
        close: PropTypes.func,
        onFormComplete: PropTypes.func,
        form: PropTypes.object,
        credit: PropTypes.object,
        language: PropTypes.string,
        readOnly: PropTypes.bool
    }

    constructor(props) {
        super(props)
        const {form} = this.props;
        this.state = {
            consentDate: new Date(),
            guardianName : form?  form.guardianName : "",
            guardianRelationship : form?  form.guardianRelationship : "",
            patientSignature : form? form.patientSignature : null,
        };
    }

    onClose = () => {
        this.setState({
            language: undefined,

        });
        this.props.close();
    }

    onSubmit = () => {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        this.setState({language: undefined})

        if (this.props.readOnly) {
            this.props.close();

        } else {
            const consentForm = pick(this.state, ['consentDate', 'guardianName', 'guardianRelationship', 'patientSignature']);
            this.props.onFormComplete(consentForm);
        }
    }

    onSavePatientSignature = (signature) => {
        this.setState({patientSignature: signature})
    }

    render() {
        if (!this.props.show) {
            return null;
        }

        let {credit} = this.props;
        let form = this.props.form? this.props.form: pick(this.state, ['consentDate', 'guardianName', 'guardianRelationship', 'patientSignature']);
        let {readOnly} = this.props;
        let patientName = form.patientName;
        this.formFields = [];

        const forUAE = credit.country === 'UAE';
        const inEnglish = this.props.language === 'English';

        return (
                <Wizard
                    fullScreen={this.props.fullScreen}
                    show={this.props.show}
                    close={this.onClose}
                    title="Terms and Conditions"
                    onSubmit={this.onSubmit}
                    isReadOnly={readOnly}
                >
                    <Wizard.Page>
                        <div style={{maxWidth: '950px'}}>
                            <div style={{
                                marginTop: '15px', marginBottom: '15px', width: '100%', display: 'flex',
                                flexFlow: 'row', justifyContent: 'space-between', alignItems: 'center'
                            }}>
                                {forUAE ? <LogoDubai/> : <LogoKuwait/>}
                                <div>
                                    <h2>{inEnglish ? 'TERMS AND CONDITIONS' : 'البنود والشروط'}</h2>
                                </div>
                            </div>

                            <hr style={{ borderTop: "2px solid #000000", marginTop: "-15px", boxShadow: "0px 2px 2px #797979" }}/>

                            {inEnglish && <>
                                <p>Please read this Terms & Conditions (the <b>“Terms & Conditions”</b>) carefully before using the DISC Wallet. </p>
                                <p>Your access to and use of the DISC Wallet is conditioned on your acceptance of and  compliance with these Terms & Conditions.</p>
                                <p><b>By accessing or using the DISC Wallet you agree to be bound by these Terms & Conditions. If you disagree with any part of the Terms & Conditions, you may not access the DISC Wallet.</b></p>
                                <p>You also acknowledge that DISC is permitted to revise these Terms & Conditions at any time as it sees fit, and by using DISC Wallet you are expected to review these Terms & Conditions on a regular basis.</p>
                                {forUAE ?
                                    <p>These Terms & Conditions will be governed by and interpreted in accordance with the laws of the UAE, and you agree to the jurisdiction of the UAE Courts for the resolution of any disputes.</p>:
                                    <p>These Terms & Conditions will be governed by and interpreted in accordance with the laws of the State of Kuwait, and you agree to the jurisdiction of the Kuwait Courts for the resolution of any disputes.</p>
                                }
                            </>}

                            {!inEnglish && <>
                                <p style={{direction: "rtl", textAlign: "right"}}>
                                    الرجاء قراءة هذه البنود والشروط بعناية قبل استخدام محفظة ديسك. تستند إمكانية الوصول إلى واستخدام محفظة ديسك على شرط موافقتكم والتزامكم بتلك البنود والشروط المنصوص عليها.
                                </p>
                                <p style={{direction: "rtl", textAlign: "right"}}>
                                    بالوصول إلى واستخدام محفظة ديسك، توافقون على اذعانكم لتلك البنود والشروط. في حالة عدم الموافقة على أي جزء من البنود والشروط التي نحن بصددها، لن يمكن بموجبه الوصول إلى واستخدام محفظة ديسك.
                                </p>
                                {forUAE ? <>
                                    <p style={{direction: "rtl", textAlign: "right"}}>
                                        بالإضافة إلى ما سبق، تتعهدون بموجبه بأنه مسموح إلى ديسك - دبي مراجعة وتغيير البنود والشروط في أي وقت وفقاً لما تراه مناسبا ومتى دعت الحاجة لذلك، وباستخدامكم محفظة ديسك، من المتوقع منكم الاطلاع على تلك المراجعات الخاصة بالبنود والشروط بصورة منتظمة.
                                    </p>
                                    <p style={{direction: "rtl", textAlign: "right"}}>
                                        تخضع هذه البنود والشروط وتفسر وفقاً للقوانين المعمول بها في دولة الإمارات العربية المتحدة، وتوافقون على أن نطاق السلطة القضائية ومحاكم الإمارات العربية المتحدة ستكون المختصة في حل أي نزاع او خلاف.
                                    </p>
                                </> : <>
                                    <p style={{direction: "rtl", textAlign: "right"}}>
                                        بالإضافة إلى ما سبق، تتعهدون بموجبه بأنه مسموح إلى ديسك - كويت مراجعة وتغيير البنود والشروط في أي وقت وفقاً لما تراه مناسبا ومتى دعت الحاجة لذلك، وباستخدامكم محفظة ديسك، من المتوقع منكم الاطلاع على تلك المراجعات الخاصة بالبنود والشروط بصورة منتظمة.
                                    </p>
                                    <p style={{direction: "rtl", textAlign: "right"}}>
                                        تخضع هذه البنود والشروط وتفسر وفقاً للقوانين المعمول بها في دولة الكويت، وتوافقون على أن نطاق السلطة القضائية ومحاكم دولة الكويت ستكون المختصة في حل أي نزاع او خلاف.
                                    </p>
                                </>}
                            </>}
                            {inEnglish && <>
                                <p>I <b>{patientName}</b> agree to credit the sum of {credit.amount} {forUAE ? 'AED' : 'KWD'} into my  DISC Wallet, which entitles me to a {credit.discountRate}% discount
                                    on DISC-{forUAE ? 'Dubai' : 'Kuwait'} services and valid for {credit.validityPeriod} weeks. The discount is not applicable to any retail items.
                                </p>
                                <p>
                                    I also understand that:
                                    <ul>
                                        <li>I can Gift my DISC Wallet credit to friends and family, entitling them to {credit.discountRate}% discount on DISC-{forUAE ? 'Dubai' : 'Kuwait'} services; or </li>
                                        <li>I can choose to terminate my DISC Wallet and redeem any remaining balance at a prorated refund.</li>
                                    </ul>
                                </p>
                            </>}

                            {!inEnglish && forUAE && <>
                                <p style={{direction: "rtl", textAlign: "right"}}>
                                    أنا/
                                    <b>{patientName}</b>
                                    أوافق على إيداع آجل بمبلغ وقدره
                                    {credit.amount}
                                    درهم إماراتي في محفظة ديسك ، وهو ما يؤهلني لحق الحصول على
                                    {credit.discountRate}
                                    % كخصم على خدمات ديسك - دبي، والساري لمدة
                                    {credit.validityPeriod}
                                    أسبوع.
                                </p>
                                <p style={{direction: "rtl", textAlign: "right"}}>
                                    كما أفهم أيضاً أن:
                                    <ul>
                                        <li>
                                            بإمكاني اهداء هذا الإيداع الآجل في محفظة ديسك - دبي لأصدقائي، عائلتي، ويحق لهم بذلك نسبة خصم تصل إلى
                                            {credit.discountRate}
                                            % على خدمات ديسك - دبي
                                        </li>
                                        <li>
                                            بإمكاني إنهاء اشتراكي في خدمات محفظة ديسك، واسترداد رصيدي المتبقي لديهم بشكل تناسبي.
                                        </li>
                                    </ul>
                                </p>
                            </>}

                            {!inEnglish && !forUAE && <>
                                <p style={{direction: "rtl", textAlign: "right"}}>
                                    أنا/
                                    <b>{patientName}</b>
                                    أوافق على إيداع آجل بمبلغ وقدره
                                    {credit.amount}
                                    دينار كويتي في محفظة ديسك، وهو ما يؤهلني لحق الحصول على
                                    {credit.discountRate}
                                    % كخصم على خدمات ديسك -كويت ، والساري
                                    {credit.validityPeriod}
                                    أسبوع.
                                </p>
                                <p style={{direction: "rtl", textAlign: "right"}}>
                                    كما أفهم أيضاً أن:
                                    <ul>
                                        <li>
                                            بإمكاني اهداء هذا الإيداع الآجل في محفظة ديسك لأصدقائي، عائلتي، ويحق لهم بذلك نسبة خصم تصل إلى
                                            {credit.discountRate}
                                            % على خدمات ديسك - كويت
                                        </li>
                                        <li>
                                            إمكاني إنهاء اشتراكي في خدمات محفظة ديسك، واسترداد رصيدي المتبقي لديهم بشكل تناسبي.
                                        </li>
                                    </ul>
                                </p>
                            </>}

                            <div className={"container"} style={inEnglish ? {} : {direction: "rtl", textAlign: "right"}}>
                                <div className={"row"} style={{marginBottom: "20px"}}>
                                    <div className="col-md-3 dim14-form-label">
                                        {inEnglish ? 'Date' : 'التاريخ'}
                                    </div>
                                    <div className="col-md-3 dim14-base-input" style={{textAlign: "center"}}>
                                        {moment(readOnly ? new Date(form.consentDate) : this.state.consentDate).format('LL')}
                                    </div>
                                </div>

                                <div className={"row"} style={{marginTop: "10px"}}>
                                    <div className="col-md-3 dim14-form-label">
                                        {inEnglish ? ' Legal Guardian Name' : 'اﺳﻢ اﻟﻮﺻﻲ'}
                                    </div>
                                    <div className="col-md-3">
                                        <TextInput value={readOnly ? form.guardianName : this.state.guardianName}
                                                   disabled={readOnly} alphabeticOnly style={{width: 140, textAlign: "center"}}
                                                   onChange={(val) => this.setState({guardianName: val})}/>
                                    </div>

                                    <div className="col-md-3 dim14-form-label">
                                        {inEnglish ? 'Relationship to Patient' : 'ﺻﻠﺔ اﻟﻌﻼﻗﺔ ﺑﺎﻟﻣرﯾض'}
                                    </div>
                                    <div className="col-md-3 dim14-base-input">
                                        <TextInput
                                            value={readOnly ? form.guardianRelationship : this.state.guardianRelationship}
                                            disabled={readOnly} alphabeticOnly style={{textAlign: "center"}}
                                            onChange={(val) => this.setState({guardianRelationship: val})}/>
                                    </div>
                                </div>

                                <div className="row" style={{marginTop: "15px"}}>
                                    <div className="col-md-6 dim14-form-label">
                                        {inEnglish ? 'Signature of Patient (or legal guardian)' : 'توقيع المريض (أو الوصي القانوني)'}
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <FormField ref={formField => this.formFields.push(formField)}
                                                   disabled={readOnly}
                                                   required requiredMessage="Signature required"
                                                   validateOn={this.state.patientSignature}>
                                            <SignatureInput
                                                signature={readOnly ? form.patientSignature : this.state.patientSignature}
                                                onSave={this.onSavePatientSignature} readOnly={readOnly}/>
                                        </FormField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Wizard.Page>
                </Wizard>
        );
    }
}

export default CreditConsentForm;
