import React, {Component} from "react";
import '../../EMR.css';
import BorderedRadioEnumInput from "../../../../../components/radio-input/BorderedRadioEnumInput";
import TextInput from "../../../../../components/text-input/TextInput";
import TypeEnum from "../enums/lifestyle/TypeEnum";
import NutritionEnum from "../enums/lifestyle/NutritionEnum";
import Select from "react-select";
import PhysicalActivityEnum from "../enums/lifestyle/PhysicalActivityEnum";
import OccupationEnum from "../enums/lifestyle/OccupationEnum";
import JobPhysicalityEnum from "../enums/lifestyle/JobPhysicalityEnum";
import BorderedCheckboxInput from "../../../../../components/checkbox-input/BorderedCheckboxInput";
import SleepingPatternEnum from "../enums/lifestyle/SleepingPatternEnum";
import PsychoSocialHistoryEnum from "../enums/lifestyle/PsychoSocialHistoryEnum";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import {DateInput} from "../../../../../components";
import {connect} from "react-redux";
import BorderedRadioLookupInput from "../../../../../components/radio-input/BorderedRadioLookupInput";
import {FormField} from "../../../../../components/form";
import moment from "moment";

class LifestyleModal extends Component {

    state= {}

    constructor(props) {
        super(props);
        this.state.lifestyle = props.lifestyle;
        // DateInput component stores dates as epoch millis
        this.state.lifestyle.drinkingStartDate = this.state.lifestyle.drinkingStartDate ?
            moment(this.state.lifestyle.drinkingStartDate).utc(true).toDate() : null
        this.state.lifestyle.drinkingStopDate = this.state.lifestyle.drinkingStopDate ?
            moment(this.state.lifestyle.drinkingStopDate).utc(true).toDate() : null
        this.state.lifestyle.smokingStartDate = this.state.lifestyle.smokingStartDate ?
            moment(this.state.lifestyle.smokingStartDate).utc(true).toDate() : null
        this.state.lifestyle.smokingStopDate = this.state.lifestyle.smokingStopDate ?
            moment(this.state.lifestyle.smokingStopDate).utc(true).toDate() : null
    }

    render() {
        this.formFields = [];
        const valueLabelUntilTwenty = this.generateValueLabelArray(20);
        const valueLabelUntilTen = this.generateValueLabelArray(10);

        const nonDrinker = this.state.lifestyle.drinkingStatus?.name?.startsWith("Non")
        const drinkingStartDateDisabled = !this.state.lifestyle.drinkingStatus || nonDrinker;

        const drinkingStopDateDisabled = !this.state.lifestyle.drinkingStatus?.name?.startsWith("Ex-");
        const nonSmoker = this.state.lifestyle.smokingStatus?.name?.startsWith("Non")
        const smokingStartDateDisabled = !this.state.lifestyle.smokingStatus || nonSmoker;

        const smokingStopDateDisabled = !this.state.lifestyle.smokingStatus?.name?.startsWith("Ex-");

        return (<div className={"emr-background"}>
                <div className="content-label" style={{marginLeft: "40px"}}>Lifestyle <RedAsterisk renderForAll/></div>
                <div className={"emr-modal content-row"} style={{width: "100%"}}>
                    <div className={"content-col-6"}
                         style={{maxWidth: "42%",marginLeft:"0px", marginRight:"40px", marginTop:"0px"}}>
                        <div>
                            <div style={{fontWeight: "bold", fontSize: "14px", marginBottom: "0.2rem"}}>Type</div>
                            <BorderedRadioEnumInput
                                values={TypeEnum.toArray()}
                                selected={this.state.lifestyle.type}
                                onSelect={(value) => {
                                    this.setState((state) => {
                                        state.lifestyle.type = value;
                                        return state;
                                    })
                                }}
                            />
                            <TextInput value={this.state.lifestyle.typeDetail || ''}
                                       placeholder={"Details"}
                                       style={{width: '-webkit-fill-available'}}
                                       onChange={value =>  this.setState(state => {
                                           state.lifestyle.typeDetail = value;
                                           return state;
                                       })
                                       }
                            />
                        </div>

                        <br/>

                        <div>
                            <div style={{fontWeight: "bold", fontSize: "14px", marginBottom: "0.2rem"}}>Nutrition</div>
                            <BorderedRadioEnumInput
                                values={NutritionEnum.toArray()}
                                selected={this.state.lifestyle.nutrition}
                                onSelect={(value) => {
                                    this.setState((state) => {
                                        state.lifestyle.nutrition = value;
                                        return state;
                                    })
                                }}
                            />
                            <TextInput value={this.state.lifestyle.nutritionDetail || ''}
                                       placeholder={"Details"}
                                       style={{width: '-webkit-fill-available'}}
                                       onChange={value =>  this.setState(state => {
                                           state.lifestyle.nutritionDetail = value;
                                           return state;
                                       })
                                       }
                            />
                        </div>

                        <br/>

                        <div>
                            <div style={{
                                fontWeight: "bold",
                                fontSize: "14px",
                                marginBottom: "0.2rem"
                            }}>Alcohol <RedAsterisk renderForAll/></div>
                            <div className={"row no-margin"}>
                                <div>
                                    <FormField ref={field => this.props.formFields.push(field)}
                                               required validateOn={this.state.lifestyle.drinkingStatus}>
                                        <BorderedRadioLookupInput
                                            values={this.props.drinkingStatuses}
                                            selected={this.state.lifestyle.drinkingStatus}
                                            onSelect={(val) =>
                                                this.setState((state) => {
                                                    state.lifestyle.drinkingStatus = val
                                                    if (!val || val.name.startsWith("Non")) {
                                                        state.lifestyle.drinkingStartDate = null
                                                        state.lifestyle.drinkingStopDate = null
                                                    } else if (!val.name.startsWith("Ex-")) {
                                                        state.lifestyle.drinkingStopDate = null
                                                    }
                                                    return state;
                                                })
                                            }
                                        />
                                    </FormField>
                                </div>
                                <div className={"pl-3 col-8"}>
                                    <div className={"row"}>
                                        <div>
                                            Date Started
                                        </div>
                                        <div className={"pl-2 pr-4"}>
                                            <FormField ref={field => this.props.formFields.push(field)}
                                                       max={drinkingStartDateDisabled || drinkingStopDateDisabled
                                                           ? undefined : this.state.lifestyle.drinkingStopDate}
                                                       maxMessage={"Should be before stop date"}
                                                       disabled={drinkingStartDateDisabled}
                                                       required validateOn={this.state.lifestyle.drinkingStartDate}>
                                                <DateInput className={"treatment-schedule-date"}
                                                           variant="dialog"
                                                           emptyLabel="Date..."
                                                           utcTime={false}
                                                           disableFuture={true}
                                                           disabled={drinkingStartDateDisabled}
                                                           value={this.state.lifestyle.drinkingStartDate ?
                                                               this.state.lifestyle.drinkingStartDate : null}
                                                           onChange={val => this.setState((state) => {
                                                               state.lifestyle.drinkingStartDate = val
                                                               return state
                                                           })}
                                                />
                                            </FormField>
                                        </div>
                                        <div>
                                            Date Stopped
                                        </div>
                                        <div className={"pl-2"}>
                                            <FormField ref={field => this.props.formFields.push(field)}
                                                       min={drinkingStartDateDisabled || drinkingStopDateDisabled
                                                           ? undefined : this.state.lifestyle.drinkingStartDate}
                                                       minMessage={"Should be after start date"}
                                                       disabled={drinkingStopDateDisabled}
                                                       required validateOn={this.state.lifestyle.drinkingStopDate}>
                                                <DateInput className={"treatment-schedule-date"}
                                                           variant="dialog"
                                                           emptyLabel="Date..."
                                                           utcTime={false}
                                                           disableFuture={true}
                                                           disabled={drinkingStopDateDisabled}
                                                           value={this.state.lifestyle.drinkingStopDate ?
                                                               this.state.lifestyle.drinkingStopDate : null}
                                                           onChange={val => this.setState((state) => {
                                                               state.lifestyle.drinkingStopDate = val
                                                               return state
                                                           })}
                                                />
                                            </FormField>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"row no-margin p-1"}>
                                <div className={"col-3 no-padding"}
                                     style={{
                                         marginTop: "auto",
                                         marginBottom: "auto",
                                         fontWeight: "bold",
                                         fontSize: "11px"
                                     }}>
                                    Units Per Week
                                </div>
                                <div className={"col-4 no-padding"}>
                                    <FormField ref={field => this.props.formFields.push(field)}
                                               required disabled={drinkingStartDateDisabled}
                                               validateOn={this.state.lifestyle.alcoholUnitsPerWeek}>
                                        <Select
                                            className={"emr-white-select-container"}
                                            classNamePrefix={"emr-white-select"}
                                            value={this.state.lifestyle.alcoholUnitsPerWeek != null ?
                                                {
                                                    value: this.state.lifestyle.alcoholUnitsPerWeek,
                                                    label: this.state.lifestyle.alcoholUnitsPerWeek
                                                } : null}
                                            onChange={(event) => {
                                                let r = event.value;
                                                this.setState((state) => {
                                                    state.lifestyle.alcoholUnitsPerWeek = r;
                                                    return state;
                                                });
                                            }}
                                            options={valueLabelUntilTwenty}/>
                                    </FormField>
                                </div>
                            </div>

                            <TextInput value={this.state.lifestyle.alcoholDetail || ''}
                                       placeholder={"Details"}
                                       style={{width: '-webkit-fill-available'}}
                                       onChange={value => this.setState(state => {
                                           state.lifestyle.alcoholDetail = value;
                                           return state;
                                       })
                                       }
                            />
                        </div>

                        <br/>

                        <div>
                            <div style={{fontWeight: "bold", fontSize: "14px", marginBottom: "0.2rem"}}>Physical
                                Activity / Exercise <RedAsterisk renderForAll/></div>
                            <FormField ref={field => this.props.formFields.push(field)}
                                       required validateOn={this.state.lifestyle.physicalActivity}>
                                <BorderedRadioEnumInput
                                    values={PhysicalActivityEnum.toArray()}
                                    selected={this.state.lifestyle.physicalActivity}
                                    onSelect={(value) => {
                                        this.setState((state) => {
                                            state.lifestyle.physicalActivity = value;
                                            return state;
                                        })
                                    }}/>
                            </FormField>
                            <TextInput value={this.state.lifestyle.physicalActivityDetail || ''}
                                       placeholder={"Details"}
                                       style={{width: '-webkit-fill-available'}}
                                       onChange={value =>  this.setState(state => {
                                           state.lifestyle.physicalActivityDetail = value;
                                           return state;
                                       })
                                       }
                            />
                        </div>


                    </div>
                    <div className={"content-col-6"}
                         style={{maxWidth: "42%",marginLeft:"20px",marginRight:"0px", marginTop:"0px"}}>
                        <div>
                            <div className={"row no-margin"}>
                                <div className={"col-6"} style={{paddingLeft: "0px", paddingTop: "0px"}}>
                                    <div style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        marginBottom: "0.2rem"
                                    }}>Occupation <RedAsterisk renderForAll/>
                                    </div>
                                    <FormField ref={field => this.props.formFields.push(field)}
                                               required validateOn={this.state.lifestyle.occupation}>
                                        <Select
                                            className={"emr-white-select-container"}
                                            classNamePrefix={"emr-white-select"}
                                            placeholder={"Occupation"}
                                            value={this.state.lifestyle.occupation != null ?
                                                {
                                                    value: OccupationEnum[this.state.lifestyle.occupation].key,
                                                    label: OccupationEnum[this.state.lifestyle.occupation].name
                                                } : null}
                                            onChange={(event) => {
                                                let r = event.value;
                                                this.setState((state) => {
                                                    state.lifestyle.occupation = r;
                                                    return state;
                                                });
                                            }}
                                            options={OccupationEnum.toArray().map(v => {
                                                    return {value: v.key, label: v.name}
                                                }
                                            )}/>
                                    </FormField>
                                </div>
                                <div className={"col-6"} style={{paddingLeft: "0px", paddingTop: "0px"}}>
                                    <div style={{fontWeight: "bold", fontSize: "14px", marginBottom: "0.2rem"}}>Job
                                        Physicality
                                    </div>
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        placeholder={"Job Physicality"}
                                        value={this.state.lifestyle.jobPhysicality != null ?
                                            {
                                                value: JobPhysicalityEnum[this.state.lifestyle.jobPhysicality].key,
                                                label: JobPhysicalityEnum[this.state.lifestyle.jobPhysicality].name
                                            } : null}
                                        onChange={(event) => {
                                            let r = event.value;
                                            this.setState((state) => {
                                                state.lifestyle.jobPhysicality = r;
                                                return state;
                                            });
                                        }}
                                        options={JobPhysicalityEnum.toArray().map(v => {
                                            return {value: v.key, label: v.name}
                                            }
                                        )}
                                    />
                                </div>
                            </div>

                            <TextInput value={this.state.lifestyle.occupationDetail || ''}
                                       placeholder={"Details"}
                                       style={{width: '-webkit-fill-available'}}
                                       onChange={value => this.setState(state => {
                                           state.lifestyle.occupationDetail = value;
                                           return state;
                                       })
                                       }
                            />

                            <div className={"row no-margin"}>
                                <div className={"col-6"} style={{paddingLeft: "0px", paddingTop: "0px"}}>
                                    <div style={{
                                        fontWeight: "bold",
                                        fontSize: "14px",
                                        marginBottom: "0.2rem"
                                    }}>Employment Status <RedAsterisk renderForAll/>
                                    </div>
                                    <FormField ref={field => this.props.formFields.push(field)}
                                               required validateOn={this.state.lifestyle.employmentStatus}>
                                        <Select
                                            className={"emr-white-select-container"}
                                            classNamePrefix={"emr-white-select"}
                                            placeholder={"Employment Status"}
                                            value={this.state.lifestyle.employmentStatus}
                                            onChange={(val) => {
                                                this.setState((state) => {
                                                    state.lifestyle.employmentStatus = val;
                                                    return state;
                                                });
                                            }}
                                            getOptionValue={v => v.id}
                                            getOptionLabel={v => v.name}
                                            options={this.props.employmentStatuses}
                                        />
                                    </FormField>
                                </div>
                                <div className={"col-6"} style={{paddingLeft: "0px", paddingTop: "0px"}}>
                                    <div
                                        style={{fontWeight: "bold", fontSize: "14px", marginBottom: "0.2rem"}}>Education
                                        Level <RedAsterisk renderForAll/>
                                    </div>
                                    <FormField ref={field => this.props.formFields.push(field)}
                                               required validateOn={this.state.lifestyle.educationLevel}>
                                        <Select
                                            className={"emr-white-select-container"}
                                            classNamePrefix={"emr-white-select"}
                                            placeholder={"Education Level"}
                                            value={this.state.lifestyle.educationLevel}
                                            getOptionValue={v => v.id}
                                            getOptionLabel={v => v.name}
                                            onChange={(val) => {
                                                this.setState((state) => {
                                                    state.lifestyle.educationLevel = val;
                                                    return state;
                                                });
                                            }}
                                            options={this.props.educationLevels}
                                        />
                                    </FormField>
                                </div>
                            </div>
                        </div>

                        <br/>

                        <div>
                            <div style={{fontWeight: "bold", fontSize: "14px", marginBottom: "0.3rem"}}>Sleeping
                                Pattern
                            </div>
                            <BorderedCheckboxInput
                                values={SleepingPatternEnum.toArray()}
                                selected={this.state.lifestyle.sleepingPattern}
                            />
                            <TextInput value={this.state.lifestyle.sleepingPatternDetail || ''}
                                       placeholder={"Details"}
                                       style={{width: '-webkit-fill-available'}}
                                       onChange={value => this.setState(state => {
                                           state.lifestyle.sleepingPatternDetail = value;
                                           return state;
                                       })
                                       }
                            />
                        </div>

                        <br/>

                        <div>
                            <div style={{fontWeight: "bold", fontSize: "14px", marginBottom: "0.2rem"}}>Smoking
                                <RedAsterisk renderForAll/></div>
                            <div className={"row no-margin"}>
                                <div className={"col-7 no-padding"}>
                                    <FormField ref={field => this.props.formFields.push(field)}
                                               required validateOn={this.state.lifestyle.smokingStatus}>
                                        <BorderedRadioLookupInput
                                            values={this.props.smokingStatuses}
                                            selected={this.state.lifestyle.smokingStatus}
                                            onSelect={(val) => {
                                                this.setState((state) => {
                                                    state.lifestyle.smokingStatus = val
                                                    if (val?.name.startsWith("Non")) {
                                                        state.lifestyle.smokingStartDate = null
                                                        state.lifestyle.smokingStopDate = null
                                                    } else if (val?.name.startsWith("Passive") || val?.name === "Smoker") {
                                                        state.lifestyle.smokingStopDate = null
                                                    }

                                                    return state;
                                                })
                                            }}
                                        />
                                    </FormField>
                                </div>
                                <div className={"col-2 no-padding"}
                                     style={{
                                         marginTop: "auto",
                                         marginBottom: "auto",
                                         fontWeight: "bold",
                                         fontSize: "11px"
                                     }}>
                                    Smoking Device
                                </div>
                                <div className={"col-3 no-padding"}
                                     style={{
                                         marginTop: "auto",
                                         marginBottom: "auto"
                                     }}>
                                    <FormField ref={field => this.props.formFields.push(field)}
                                               required disabled={nonSmoker}
                                               validateOn={this.state.lifestyle.smokingDevice}>
                                        <Select
                                            className={"emr-white-select-container"}
                                            classNamePrefix={"emr-white-select"}
                                            placeholder={"Device"}
                                            value={this.state.lifestyle.smokingDevice}
                                            getOptionValue={v => v.id}
                                            getOptionLabel={v => v.name}
                                            onChange={(val) => {
                                                this.setState((state) => {
                                                    state.lifestyle.smokingDevice = val;
                                                    return state;
                                                });
                                            }}
                                            options={this.props.smokingDevices}
                                        />
                                    </FormField>
                                </div>
                            </div>

                            <div className={"pl-3 pt-2 row"}>
                                <div>
                                    Date Started
                                </div>
                                <div className={"pl-2 pr-4"}>
                                    <FormField ref={field => this.props.formFields.push(field)}
                                               max={smokingStartDateDisabled || smokingStopDateDisabled
                                                   ? undefined : this.state.lifestyle.smokingStopDate}
                                               maxMessage={"Should be before stop date"}
                                               required disabled={smokingStartDateDisabled}
                                               validateOn={this.state.lifestyle.smokingStartDate}>
                                        <DateInput className={"treatment-schedule-date"}
                                                   variant="dialog"
                                                   emptyLabel="Date..."
                                                   utcTime={false}
                                                   disableFuture={true}
                                                   disabled={smokingStartDateDisabled}
                                                   value={this.state.lifestyle.smokingStartDate ?
                                                       this.state.lifestyle.smokingStartDate : null}
                                                   onChange={val => this.setState((state) => {
                                                       state.lifestyle.smokingStartDate = val
                                                       return state
                                                   })}
                                        />
                                    </FormField>
                                </div>

                                <div>
                                    Date Stopped
                                </div>
                                <div className={"pl-2"}>
                                    <FormField ref={field => this.props.formFields.push(field)}
                                               min={smokingStartDateDisabled || smokingStopDateDisabled
                                                   ? undefined : this.state.lifestyle.smokingStartDate}
                                               minMessage={"Should be after start date"}
                                               required disabled={smokingStopDateDisabled}
                                               validateOn={this.state.lifestyle.smokingStopDate}>
                                        <DateInput className={"treatment-schedule-date"}
                                                   variant="dialog"
                                                   emptyLabel="Date..."
                                                   utcTime={false}
                                                   disableFuture={true}
                                                   disabled={smokingStopDateDisabled}
                                                   value={this.state.lifestyle.smokingStopDate ?
                                                       this.state.lifestyle.smokingStopDate : null}
                                                   onChange={val => this.setState((state) => {
                                                       state.lifestyle.smokingStopDate = val
                                                       return state
                                                   })}
                                        />
                                    </FormField>
                                </div>
                            </div>

                            <div className={"row no-margin p-1"}>
                                <div className={"col-3 no-padding"}
                                     style={{
                                         marginTop: "auto",
                                         marginBottom: "auto",
                                         fontWeight: "bold",
                                         fontSize: "11px"
                                     }}>
                                    Packs Per Day
                                </div>
                                <div className={"col-4 no-padding"}>
                                    <FormField ref={field => this.props.formFields.push(field)}
                                               required disabled={smokingStartDateDisabled}
                                               validateOn={this.state.lifestyle.smokingPacksPerDay}>
                                        <Select
                                            className={"emr-white-select-container"}
                                            classNamePrefix={"emr-white-select"}
                                            value={this.state.lifestyle.smokingPacksPerDay != null ?
                                                {
                                                    value: this.state.lifestyle.smokingPacksPerDay,
                                                    label: this.state.lifestyle.smokingPacksPerDay
                                                } : null}
                                            onChange={(event) => {
                                                let r = event.value;
                                                this.setState((state) => {
                                                    state.lifestyle.smokingPacksPerDay = r;
                                                    return state;
                                                });
                                            }}
                                            options={valueLabelUntilTen}/>
                                    </FormField>
                                </div>
                            </div>

                            <div className={"col-6"} style={{paddingLeft: "0px", paddingTop: "0px"}}>
                                <div style={{fontWeight: "bold", fontSize: "14px", marginBottom: "0.2rem"}}>
                                    Substance Abuse <RedAsterisk renderForAll/>
                                </div>
                                <FormField ref={field => this.props.formFields.push(field)}
                                           required validateOn={this.state.lifestyle.substanceAbuse}>
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        placeholder={"Substance Type"}
                                        value={this.state.lifestyle.substanceAbuse}
                                        getOptionValue={v => v.id}
                                        getOptionLabel={v => v.name}
                                        onChange={(val) => {
                                            this.setState((state) => {
                                                state.lifestyle.substanceAbuse = val;
                                                return state;
                                            });
                                        }}
                                        options={this.props.substanceAbuseStatuses}
                                    />
                                </FormField>
                            </div>

                            <TextInput value={this.state.lifestyle.smokingDetail || ''}
                                       placeholder={"Details"}
                                       style={{width: '-webkit-fill-available'}}
                                       onChange={value => this.setState(state => {
                                           state.lifestyle.smokingDetail = value;
                                           return state;
                                       })
                                       }
                            />
                        </div>

                        <br/>

                        <div>
                            <div style={{fontWeight: "bold", fontSize: "18px", marginBottom: "0.3rem"}}>Psycho - Social
                                History <RedAsterisk renderForAll/></div>
                            <FormField ref={field => this.props.formFields.push(field)}
                                       required validateOn={this.state.lifestyle.psychoSocialHistory}>
                                <BorderedCheckboxInput
                                    values={PsychoSocialHistoryEnum.toArray()}
                                    selected={this.state.lifestyle.psychoSocialHistory}/>
                            </FormField>
                            <TextInput value={this.state.lifestyle.psychoSocialHistoryDetail || ''}
                                       placeholder={"Details"}
                                       style={{width: '-webkit-fill-available'}}
                                       onChange={value => this.setState(state => {
                                           state.lifestyle.psychoSocialHistoryDetail = value;
                                           return state;
                                       })
                                       }
                            />
                        </div>
                    </div>
                </div>

            </div>
        );
    }

    generateValueLabelArray(arraySize) {
        const foo = new Array(arraySize);
        for (let i = 1; i <= arraySize; i++) {
            foo.push({value: i, label: i});
        }
        return foo;
    }
}

function mapStateToProps(state) {
    return {
        employmentStatuses: state.base.employmentStatuses,
        educationLevels: state.base.educationLevels,
        smokingStatuses: state.base.smokingStatuses,
        smokingDevices: state.base.smokingDevices,
        drinkingStatuses: state.base.drinkingStatuses,
        substanceAbuseStatuses: state.base.substanceAbuseStatuses,
    };
}

export default connect(mapStateToProps)(LifestyleModal);
