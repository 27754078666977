import React, {Component} from "react";
import "../../VisitHistory.css"
import TypeEnum from "../../../assessment/enums/dermatomes/TypeEnum";
import ReflexesLeftSideEnum from "../../../assessment/enums/dermatomes/ReflexesLeftSideEnum";
import ReflexesRightSideEnum from "../../../assessment/enums/dermatomes/ReflexesRightSideEnum";
import ReflexesDropdownValues from "../../../assessment/enums/dermatomes/ReflexesDropdownValues";
import CranialNervesEnum from "../../../assessment/enums/dermatomes/CranialNervesEnum";
import CranialNervesDropdownValues from "../../../assessment/enums/dermatomes/CranialNervesDropdownValues";

export default class ReflexesViewHistory extends Component {

    constructor(props) {
        super(props);
        this.createReflexesTable = this.createReflexesTable.bind(this);
    }

    createReflexesTable() {
        let tableEntriesReflexes = [];
        let tableEntriesReflexesExtra = [];
        let tableEntriesCranialNerves = [];
        let headerState = {
            reflexHeader: false,
            cranialNervesHeader: false
        };

        if (this.props?.reflexesExtra?.items) {
            this.props.reflexesExtra.items.forEach((item, index) => {
                if (!headerState.reflexHeader) {
                    tableEntriesReflexes.push(
                        <tr key={"reflexExtraHeader" + index}
                            style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                            <th>Reflex</th>
                            <th>Right</th>
                            <th>Left</th>
                            <th>Details</th>
                        </tr>
                    );
                    headerState.reflexHeader = true;
                }
                tableEntriesReflexesExtra.push(
                    <tr key={"reflexExtraTable" + index} style={{backgroundColor: "white"}}>
                        <td>{item.name}</td>
                        <td>{ReflexesDropdownValues[item.rightValue]?.name}</td>
                        <td>{ReflexesDropdownValues[item.leftValue]?.name}</td>
                        <td>{item.details}</td>
                    </tr>
                );
            });
        }

        if (this.props?.reflexes?.items?.length > 0) {
            this.props.reflexes.items.forEach((item, index) => {
                if ((item.type === TypeEnum.REFLEX_LEFT)
                    || item.type === TypeEnum.REFLEX_RIGHT) {
                    if (!headerState.reflexHeader) {
                        tableEntriesReflexes.push(
                            <tr key={"reflexHeader" + index}
                                style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                                <th>Reflex</th>
                                <th>Right</th>
                                <th>Left</th>
                                <th>Details</th>
                            </tr>
                        );
                        headerState.reflexHeader = true;
                    }
                    tableEntriesReflexes.push(
                        <tr key={"reflexTable" + index} style={{backgroundColor: "white"}}>
                            <td>{item.name == null ? "" :
                                (item.type === TypeEnum.REFLEX_LEFT
                                    ? ReflexesLeftSideEnum[item.name].value
                                    : ReflexesRightSideEnum[item.name].value)}
                            </td>
                            <td>{ReflexesDropdownValues[item.rightValue]?.name}</td>
                            <td>{ReflexesDropdownValues[item.leftValue]?.name}</td>
                            <td>{item.details}</td>
                        </tr>
                    );
                } else if (item.type === TypeEnum.CRANIAL_NERVES) {
                    if (!headerState.cranialNervesHeader) {
                        tableEntriesCranialNerves.push(
                            <tr key={"cranialNervesHeader" + index}
                                style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                                <th>Cranial Nerves</th>
                                <th colSpan={"2"}>Result</th>
                                <th>Details</th>
                            </tr>
                        );
                        headerState.cranialNervesHeader = true;
                    }
                    tableEntriesCranialNerves.push(
                        <tr key={"cranialNervesTable" + index} style={{backgroundColor: "white"}}>
                            <td>{CranialNervesEnum[item.name]?.value}</td>
                            <td colSpan={"2"}>{CranialNervesDropdownValues[item.rightValue]?.name}</td>
                            <td>{item.details}</td>
                        </tr>
                    );
                }
            });
        }
        return tableEntriesReflexes.concat(tableEntriesReflexesExtra, tableEntriesCranialNerves);
    };

    render() {
        if (!(this.props.reflexes?.items?.length > 0 || this.props.reflexesExtra?.items?.length > 0)) {
            return <div className="pl-3">
                {this.props.reflexes && this.props.reflexes.none ? "Normal" : "No information available"}
            </div>;
        }

        return (
            <table className={"visit-history-table"}>
                <tbody>
                {this.createReflexesTable()}
                </tbody>
            </table>
        );
    }
}