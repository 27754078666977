import React from 'react';
import {connect} from 'react-redux';
import routes from '../routes';
import Header from './Header';
import Footer from './Footer';
import PrivateRoute from '../modules/user/PrivateRoute';
import {BrowserRouter as Router, Route} from 'react-router-dom';
import {checkForTimeDifferenceFromServer} from "../services/time-checker-service/TimeCheckerService";
import IdleTimer from "react-idle-timer";
import AuthService from "../services/auth-service/AuthService";
import LoginPopup from "../modules/user/login/LoginPopup";
import dialog from "../components/dialog/Dialog";
import LoginData from "./LoginData";
import ScrollToTop from "./ScrollToTop";

class Layout extends React.Component {

    componentDidMount() {
        checkForTimeDifferenceFromServer();
    }

    getLoginUser(loginUser) {
        this.setState({loginUser: loginUser});
    }

    routesMap = routes.map((element, index) => {
        let relativePath = undefined;
        if (process.env.PUBLIC_URL) {
            relativePath = `${process.env.PUBLIC_URL}${element.path}`;
        } else {
            relativePath = `${element.path}`;
        }

        let props = {
            path: relativePath,
            component: element.component,
            key: index,
            exact: element.exact,
            authority: element.authority
        };

        return element.authority ? <PrivateRoute {...props} /> : <Route {...props} />;
    });

    handleOnIdle() {
        if (!this.loginPopupVisible) {
            this.loginPopupVisible = true;

            if (AuthService.getUser()) {
                const username = this.state.loginUser;
                AuthService.logout();
            }

            dialog({
                component: <LoginPopup username={this.state.loginUser}/>,
                style: {width: 800, height: 450},
                onClose: () => {
                    this.loginPopupVisible = false
                },
                closeButtonDisabled: true
            });
        }
    }

    render() {
        return (
            <div id="layout-wrapper" className="Wrapper">
                {AuthService.isAuthenticated() &&
                <>
                    <IdleTimer timeout={15 * 60 * 1000} // 15 minutes
                               debounce={250}
                               onIdle={this.handleOnIdle.bind(this)}
                               crossTab={{
                                   emitOnAllTabs: true
                               }}
                    />
                    <LoginData loginTrigger={this.getLoginUser.bind(this)}/>
                </>
                }
                <Router>
                    <ScrollToTop/>
                    <Header/>
                    <div className="Content">
                        {this.routesMap}
                    </div>
                </Router>
                <Footer/>
            </div>
        )
    };
}

const mapStateToProps = state => {
    return {
        user: state.authentication.user
    };
};

export default connect(mapStateToProps)(Layout);

