import Enum from "../../../../../../enums/Enum";

const InjuryTypeEnum = new Enum({
        UNKNOWN: {name: "Unknown"},
        SOFT_TISSUE: {name: "Soft-Tissue"},
        LIGAMENT: {name: "Ligament"},
        MUSCLE: {name: "Muscle"},
        TENDON: {name: "Tendon"},
        OTHER: {name: "Other"}
});

export default InjuryTypeEnum;