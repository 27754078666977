import React, {Component} from "react";
import {Fade, ListItemIcon, Menu, MenuItem} from "@material-ui/core";
import DefaultActionMenuIcon from "../action-menu/icons/DefaultActionMenuIcon";

export default class ContextMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            menuPositionTop: 0,
            menuPositionLeft: 0,
            selectedItem: null
        };
    }

    show(event, selectedItem) {
        if (event && event.preventDefault) {
            event.preventDefault();
        }
        if (event && event.stopPropagation) {
            event.stopPropagation();
        }
        this.setState({
            open: true,
            menuPositionTop: event.clientY,
            menuPositionLeft: event.clientX,
            selectedItem: selectedItem
        });
    }

    hide() {
        this.setState({open: false});
    }

    onMenuItemClick(menuItem, event) {
        event.preventDefault();
        event.stopPropagation();
        this.hide();
        menuItem.props.onClick(this.state.selectedItem);
        this.setState({selectedItem: null});
    }

    render() {
        const menuItems = [];
        React.Children.forEach(this.props.children, menuItem => {
            if (typeof menuItem.props.visible === "boolean" && menuItem.props.visible) {
                menuItems.push(menuItem);
            } else if (typeof menuItem.props.visible === "function"
                        && this.state.selectedItem
                        && menuItem.props.visible(this.state.selectedItem)) {
                menuItems.push(menuItem);
            }
        });

        return <Menu id={this.props.id}
                     anchorReference="anchorPosition"
                     anchorPosition={{top: this.state.menuPositionTop, left: this.state.menuPositionLeft}}
                     open={this.state.open && menuItems.length > 0}
                     TransitionComponent={Fade}
                     onClose={(event) => {
                         event.preventDefault();
                         event.stopPropagation();
                         this.hide();
                     }}
                     onContextMenu={event => {
                         event.preventDefault();
                         event.stopPropagation();
                         this.hide();
                     }}>
            {
                menuItems.map((menuItem, idx) => {
                    return <MenuItem key={idx} onClick={event => this.onMenuItemClick(menuItem, event)}
                                     onContextMenu={event => this.onMenuItemClick(menuItem, event)}>
                        <ListItemIcon style={{minWidth: 30}}>
                            {menuItem.props.icon ? menuItem.props.icon : <DefaultActionMenuIcon/>}
                        </ListItemIcon>
                        {menuItem.props.label}
                    </MenuItem>
                })
            }
        </Menu>
    }

}