import React, {Component} from "react";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import {Button, ButtonGroup, Dropdown, DropdownButton} from "react-bootstrap";
import DateUtil from "../../../../utils/DateUtil";
import "./ClaimRemittanceDetail.css";
import ClaimStatus from "../claimList/ClaimStatus";


export default class ClaimRemittanceDetail extends Component {

    constructor(props) {
        super(props);
        this.state = {
            claimRemittance: null,
            selectedItems: [],
            expandedItems: []
        }
    }

    componentDidMount() {
        const id = this.props.claimRemittanceId;
        RemotingService.getRemoteCall(`api/insurance/coordinator/claim-remittance/${id}`, null, (claimRemittance) => {
            this.setState({claimRemittance})
        });
    }

    dateTemplate(dateStr) {
        return DateUtil.formatDateMMM(dateStr);
    }

    cptTemplate(claim) {
        return (
            <ul className="mb-0">
                {claim.claimActivityList.map(activity => <li>{activity.code}</li>)}
            </ul>
        );
    }

    statusTemplate(claim) {
        return ClaimStatus[claim.status]?.name;
    }

    render() {
        return (
            <div>
                <div className="d-flex flex-row p-justify-between">
                    <div>
                        <div className="d-flex flex-row">
                            <div className="claim-remittance-label">
                                Remittance No
                            </div>
                            <div className="claim-remittance-value">
                                {this.state.claimRemittance?.id}
                            </div>
                        </div>
                        <div className="d-flex flex-row">
                            <div className="claim-remittance-label">
                                Transaction Date
                            </div>
                            <div className="claim-remittance-value">
                                {DateUtil.formatDateTime12H(this.state.claimRemittance?.transactionDate)}
                            </div>
                        </div>
                        <div className="d-flex flex-row">
                            <div className="claim-remittance-label">
                                No of Claims
                            </div>
                            <div className="claim-remittance-value">
                                {this.state.claimRemittance?.claimCount}
                            </div>
                        </div>
                        <div className="d-flex flex-row">
                            <div className="claim-remittance-label">
                                File Name
                            </div>
                            <div className="claim-remittance-value">
                                {this.state.claimRemittance?.fileName}
                            </div>
                        </div>
                        {this.state.claimRemittance?.denialCode &&
                            <div className="d-flex flex-row">
                                <div className="claim-remittance-label">
                                    Denial Code
                                </div>
                                <div className="claim-remittance-value">
                                    {this.state.claimRemittance?.denialCode}
                                </div>
                            </div>
                        }
                        {this.state.claimRemittance?.comments &&
                        <div className="d-flex flex-row">
                            <div className="claim-remittance-label">
                                Denial Description
                            </div>
                            <div className="claim-remittance-value">
                                {this.state.claimRemittance?.comments}
                            </div>
                        </div>
                        }
                    </div>
                    <div>
                        <Button className="btn-sm mr-2">Excel Export</Button>
                        <DropdownButton
                            as={ButtonGroup}
                            variant="primary btn-sm"
                            menuAlign="right"
                            title="Resubmit"
                            onClick={() => {}}>
                            <Dropdown.Item onClick={() => {}}>Correction</Dropdown.Item>
                            <Dropdown.Item onClick={() => {}}>Internal Complaint</Dropdown.Item>
                        </DropdownButton>
                    </div>
                </div>
                <DataTable responsive emptyMessage="No records found."
                           value={this.state.claimRemittance?.claims}
                           selection={this.state.selectedItems}
                           onSelectionChange={e => this.setState({selectedItems: e.value})}
                           expandedRows={this.state.expandedItems}
                           onRowToggle={(e) => this.setState({expandedItems: e.data})}
                           rowExpansionTemplate={(claim) => <ClaimActivityTable claim={claim}/>}>
                    <Column expander headerStyle={{width: 20}} />
                    <Column selectionMode="multiple" headerStyle={{width: '3em'}}/>
                    <Column field="invoice.id" header="Bill No"/>
                    <Column field="invoice.invoiceDate" header="Bill Date" body={claim => this.dateTemplate(claim.invoice?.invoiceDate)}/>
                    <Column field="paymentReference" header="Reference No"/>
                    <Column header="CPT Code" body={claim => this.cptTemplate(claim)}/>
                    <Column field="denialCode" header="Denial Code"/>
                    <Column field="comments" header="Denial Description"/>
                    <Column field="idPayer" header="ID Payer"/>
                    <Column field="net" header="Claim Amount"/>
                    <Column field="paymentAmount" header="Paid Amount"/>
                    <Column field="rejectedAmount" header="Rejected Amount"/>
                    <Column field="status" header="Status" body={claim => this.statusTemplate(claim)}/>
                </DataTable>
            </div>
        )
    }

}

class ClaimActivityTable extends Component {

    render() {
        return (
            <DataTable value={this.props.claim.claimActivityList}>
                <Column field="code" header="Cpt Code"/>
                <Column field="quantity" header="Quantity"/>
                <Column field="net" header="Claim Amount"/>
                <Column field="paymentAmount" header="Paid Amount"/>
                <Column field="rejectedAmount" header="Rejected Amount"/>
                <Column field="denialCode" header="Denial Code"/>
            </DataTable>
        )
    }

}