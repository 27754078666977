import React, {Component} from "react";
import '../../EMR.css';
import MoodEnum from "../enums/mood/MoodEnum";

export default class MoodView extends Component {

    render() {
        if(this.props.mood == null) {
            return null;
        }

        return(
            <div style={{backgroundColor: "rgb(245,245,245)"}}>
                <div>
                    {this.props.mood.type == null ? "" : (MoodEnum[this.props.mood.type].name + ": ")}{this.props.mood.detail}
                </div>
            </div>
        );
    }
}