import Enum from "../../../../../../enums/Enum";

const OtherTreatmentReceivedEnum = new Enum({
    SURGERY: {name: "Surgery"},
    MEDICATION: {name: "Medication"},
    INJECTION: {name: "Injection"},
    HOSPITALISED: {name: "Hospitalised"},
    OTHER: {name: "Other"}

});

export default OtherTreatmentReceivedEnum;