import React, {Component} from 'react';
import './FormSection.css';

class FormSection extends Component {

    render(){

        let labelPercent = this.props.labelPercent;
        let inputPercent = this.props.inputPercent;

        if(!labelPercent || !inputPercent){
            labelPercent = 25;
            inputPercent = 75;
        }else{
            let total = labelPercent + inputPercent;
            let ratio = total / 100;
            labelPercent = Math.round(labelPercent / ratio);
            inputPercent = 100 - labelPercent;
        }

        let noLabel = false;
        if(this.props.labelHidden === true || !this.props.label){
            noLabel = true;
        }

        if(noLabel){
            labelPercent = 0;
            inputPercent = 100;
        }

        let labelStyle = {
            "flex": "0 0 " + labelPercent + "%",
            "maxWidth": "" + labelPercent + "%",
            "textAlign": (this.props.labelPosition) ? this.props.labelPosition : "left"
        };

        let inputStyle = {
            "flex": "0 0 " + inputPercent + "%",
            "maxWidth": "" + inputPercent + "%"
        };

        let labelElement = undefined;
        if(!noLabel){
            let labelClassNames = "dim14-form-label";
            labelElement = <label className={labelClassNames} style={labelStyle} >{this.props.label}</label>;
        }

        let inputElement = undefined;
        if(this.props.children){
            let inputClassNames = "dim14-form-input";

            if(noLabel){
                inputElement = <div className={inputClassNames} style={inputStyle} ><div className="dim14-base-input" >{this.props.children}</div></div>;
            }
            else{
                inputElement = <div className={inputClassNames} style={inputStyle} ><div className="dim14-colon" ></div><div className="dim14-base-input" >{this.props.children}</div></div>;
            }
        }

        return <div className="dim14-form-element">
                {labelElement}
                {inputElement}
            </div>
    }

}

export default FormSection;