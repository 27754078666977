import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

export const respondMarks = (enumLabel) => [
    {value: 0,
        label:
            <div style={{textAlign: "center"}}>
                <FontAwesomeIcon icon={["fas", "sad-cry"]} size={"2x"} color={"#e92200"}/>
                <p>{respondMarkLabels.Very_Poor[enumLabel]}</p>
            </div>},
    {value: 1, label:
            <div style={{textAlign: "center"}}>
                <FontAwesomeIcon icon={["fas", "frown-open"]} size={"2x"} color={"#f86d39"}/>
                <p>{respondMarkLabels.Poor[enumLabel]}</p>
            </div>},
    {value: 2, label:
            <div style={{textAlign: "center"}}>
                <FontAwesomeIcon icon={["fas", "meh"]} size={"2x"} color={"#fbd237"}/>
                <p>{respondMarkLabels.Average[enumLabel]}</p>
            </div>},
    {value: 3, label:
            <div style={{textAlign: "center"}}>
                <FontAwesomeIcon icon={["fas", "smile-beam"]} size={"2x"} color={"#82e05d"}/>
                <p>{respondMarkLabels.Good[enumLabel]}</p>
            </div>},
    {value: 4, label:
            <div style={{textAlign: "center"}}>
                <FontAwesomeIcon icon={["fas", "laugh"]} size={"2x"} color={"#00a900"}/>
                <p>{respondMarkLabels.Excellent[enumLabel]}</p>
            </div>}
];

export const getRespondInfo = (value) => {
    return value;
};

const respondMarkLabels = {
    Very_Poor: {name: "Very Poor", nameInArabic: "ضعيفة جدا"},
    Poor: {name: "Poor", nameInArabic: "ضعيفة"},
    Average: {name: "Average", nameInArabic: "متوسطة"},
    Good: {name: "Good", nameInArabic: "جيدة"},
    Excellent: {name: "Excellent", nameInArabic: "ممتازة"}
}

export default {respondMarks, getRespondInfo};