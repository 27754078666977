import React from 'react';
import {SelectInput} from "../../components";
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import RemotingService from '../../services/remoting-service/RemotingService';
import {Button} from "react-bootstrap";
import EnumTemperature from "./enums/EnumTemperature";
import ActionMenu from "../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../components/action-menu/ActionMenuItem";
import ViewIcon from "../../components/action-menu/icons/ViewIcon";
import EditIcon from "../../components/action-menu/icons/EditIcon";
import EditRoom from "./EditRoom";
import ViewRoom from "./ViewRoom";
import AddRoom from "./AddRoom";
import AuthService from "../../services/auth-service/AuthService";

class RoomList extends React.Component {

    state = {
        roomList: [],
        clinicList: [],
        staffList: [],
        addRoomModalVisible: false,
        viewRoomModalVisible: false,
        editRoomModalVisible: false,
        editedRoom: {}
    }

    componentDidMount() {
        this.getRoomList();
        this.getClinicList();
        this.getStaffList();
    }

    getRoomList = () => {
        RemotingService.getRemoteCall('/api/room/list', null, (roomDTOList) => {
            this.setState({roomList: roomDTOList});
        });
    }

    getClinicList = () => {
        RemotingService.getRemoteCall('api/clinic/list', null, (result) => {
            this.setState({clinicList: result.items});
        });
    }

    getStaffList = () => {
        RemotingService.getRemoteCall('api/staff/list-base', null, (result) => {
            this.setState({staffList: result.items});
        });
    }

    onTemperatureFilterChange(val) {
        this.roomListDt.filter(val, 'temperature', 'equals');
        this.setState({temperatureFilterVal: val});
    }

    addRoomButtonClicked = () => {
        this.setState({addRoomModalVisible: true})
    }

    render() {
        let temperatureFilter = (
            <SelectInput className="w-100" value={this.state.temperatureFilterVal} options={["Hot", "Cold"]} searchEnabled={false}
                         onChange={(val) => this.onTemperatureFilterChange(val)}/>);

        return (
            <>
                <div className="container-fluid">
                    <div className="row justify-content-end pb-2">
                        {AuthService.userHasAuthority("UPDATE_ROOM") &&
                        <Button onClick={() => {
                            this.addRoomButtonClicked()
                        }}>Add Room</Button>
                        }
                    </div>
                    <div className="row">
                        <DataTable
                            className="RoomTable"
                            value={this.state.roomList}
                            sortField={"name"}
                            sortOrder={1}
                            globalFilter={this.state.roomDataGridGlobalFilter}
                            ref={(el) => this.roomListDt = el}
                            emptyMessage={<div style={{textAlign: "center"}}>There are no rooms</div>}>
                            <Column field="id" header="ID" filter={true} filterMatchMode="contains" sortable={true}
                                    style={{width: "100px"}}/>
                            <Column field="name" header="NAME" filter={true} filterMatchMode="contains" sortable={true}/>
                            <Column field="clinicName" header="CLINIC" filter={true} filterMatchMode="contains"
                                    sortable={true}/>
                            <Column field="temperature" header="TEMPERATURE" filter={true} filterElement={temperatureFilter}
                                    sortable={true} body={(room) => EnumTemperature[room.temperature]}/>
                            <Column field="specs" header="SPECS" filter={true} filterMatchMode="contains" sortable={true}/>
                            <Column field="providerCapacity" header="PROVIDER CAPACITY" filter={true} filterMatchMode="contains" sortable={true}/>
                            <Column header="ACTIONS" body={this.renderActions.bind(this)} style={{textAlign: "center", width:"75px"}}/>
                        </DataTable>
                    </div>
                </div>

                <AddRoom visible={this.state.addRoomModalVisible}
                         clinicList={this.state.clinicList}
                         staffList={this.state.staffList}
                         onRoomAdded={this.onRoomAdded}
                         closeAction={() =>
                             this.setState({addRoomModalVisible: false})
                         }/>

                <ViewRoom visible={this.state.viewRoomModalVisible}
                          room={this.state.viewedRoom}
                          closeAction={() => this.setState({
                              viewRoomModalVisible: false
                          })}/>

                {
                    this.state.editRoomModalVisible &&
                    <EditRoom room={this.state.editedRoom}
                              clinicList={this.state.clinicList}
                              staffList={this.state.staffList}
                              onRoomEdited={this.onRoomEdited}
                              hideModal={this.hideEditModal}/>
                }
            </>
        );
    }

    onRoomAdded = (room) => {
        let oldRoomList = [...this.state.roomList];
        oldRoomList.push(room);
        this.setState({addRoomModalVisible: false, roomList: oldRoomList});
        this.getRoomList();
    }

    onRoomEdited = (editedRoom) => {
        let roomList = [...this.state.roomList];
        let index = roomList.findIndex(room => room.id === editedRoom.id);
        roomList.splice(index, 1, editedRoom);
        this.setState({...this.state, editRoomModalVisible: false, roomList: roomList});
    }

    hideEditModal = () => {
        this.setState({editedRoom: null, editRoomModalVisible: false});
    }

    renderActions = (rowData) => {
        return (<ActionMenu id="packagepanelactionmenu">
            <ActionMenuItem text={"View Room"} icon={<ViewIcon/>}
                            onClick={() => {
                                this.setState({viewedRoom: rowData, viewRoomModalVisible: true})
                            }}/>
            <ActionMenuItem authority="UPDATE_ROOM"
                            text="Edit Room" icon={<EditIcon/>}
                            onClick={() => {
                                this.setState({editedRoom: rowData, editRoomModalVisible: true})
                            }} isLastItem/>
        </ActionMenu>)
    }
}

export default RoomList;
