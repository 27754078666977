import React, {Component} from 'react';
import {Card, Image} from 'react-bootstrap';
import {withRouter} from "react-router";

class StaffCard extends Component {

    handleShowStaffDetail = () => {
        const id = this.props.id;
        this.props.history.push("/staffprofile/" + id);
    };

    render() {
        let photoSrc = process.env.PUBLIC_URL + '/assets/patient_profile.png';
        if (this.props.photo) {
            photoSrc = 'data:image/jpeg;base64,' + this.props.photo;
        }
        return (

            <div className={"col-xl-3 col-md-4 col-sm-6 col-12"}>


            <Card className={"h-100"}>
                <Card.Body className={'selectable'} onClick={this.handleShowStaffDetail.bind(this)}>
                    <div style={{display: 'flex'}}>
                        <div>
                            <Image src={photoSrc} rounded width={"40px"}/>
                        </div>
                        <div style={{marginLeft: '15px'}}>
                            <Card.Title>{this.props.name}</Card.Title>
                            {
                                this.props.departments.map((d, idx) => {
                                    return (<div key={idx}>{d.clinic.name + ' / ' + d.name}</div>);
                                })
                            }
                            <Card.Text>
                                {this.props.speciality}
                                <div>{this.props.mobilePhone}</div>
                                {this.props.email || ''}
                            </Card.Text>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            </div>
                );
    }
}

export default withRouter(StaffCard);
