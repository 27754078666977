import React, {Component} from "react";
import '../EMR.css';
import DirectBillingStatus from "../../../insurance/enums/DirectBillingStatus";
import DateUtil from "../../../../utils/DateUtil";
import {Button} from "react-bootstrap";
import {range} from "lodash-es";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import {Dropdown as PrimeDropdown} from "primereact/components/dropdown/Dropdown";
import EligibilityStatusEnum from "../../../insurance/enums/EligibilityStatusEnum";
import {Modal} from "../../../../components";

export default class InsuranceStatus extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    createNewDirectBillingItem = () => {
        const insuranceRecord = this.props.insuranceApproval.insuranceRecord;
        const params = {
            recordType: insuranceRecord.type,
            type: this.state.newDB.type,
            noOfSessions: this.state.newDB.additionalSessions,
            visitId: this.props.currentVisit.id
        };

        RemotingService.postRemoteCall(
            `api/insurance/coordinator/insurance-record/${insuranceRecord.id}/copy`,
            params,
            () => {
                insuranceRecord.status = 'COMPLETED';
                this.setState({newDirectBillingModalVisible: false});
            }
        );
    }

    render() {
        const {insuranceApproval} = this.props;
        let insuranceRecord = undefined;
        let directBilling = undefined;
        let externalReferral = undefined;

        if (insuranceApproval) {
            insuranceRecord = insuranceApproval.insuranceRecord;

            if (insuranceRecord) {
                if ('DIRECT_BILLING' === insuranceRecord.type) {
                    directBilling = insuranceRecord;
                }

                if ('EXTERNAL_REFERRAL' === insuranceRecord.type) {
                    externalReferral = insuranceRecord;
                }
            }
        }

        return (
            <div>
                <div className={"pl-3 row no-margin"}>
                    <div className={"col-3 flex-column no-padding"}>
                        <div className={"col-6 no-padding dim14-form-label"}>
                            Visit Date:
                        </div>
                        <div className={"col-6 no-padding"}>
                            {DateUtil.formatDate(this.props.currentVisit.appointment.startTime)}
                        </div>
                    </div>
                    <div className={"col-4 flex-column no-padding"}>
                        <div className={"col-3 no-padding dim14-form-label"}>
                            Provider:
                        </div>
                        <div className={"col-9 no-padding"}>
                            {directBilling ? directBilling.referral.providerName : insuranceApproval?.provider}
                        </div>
                    </div>
                    <div className={"col-5 flex-column no-padding"}>
                        <div className={"col-6 no-padding dim14-form-label"}>
                            Referring Physician:
                        </div>
                        <div className={"col-6 no-padding"}>
                            {directBilling ? directBilling.referral.referrerName : externalReferral? externalReferral.referredBy : ''}
                        </div>
                    </div>
                </div>
                <br/>

                <div className={"content-card"}>
                    <div className={"complaints-wrapper"}>
                        <div className={"display-flex"}>
                            <div className={"col-sm-5 no-padding dim14-form-label"}>Insurance Status:</div>
                            <div className={"col-sm-7 no-padding overflow-wrap-anywhere"}>
                                {insuranceApproval && EligibilityStatusEnum[insuranceApproval.eligibilityStatus]?.name}
                            </div>
                        </div>
                        <div className={"display-flex"}>
                            <div className={"col-sm-5 no-padding dim14-form-label"}>Reference No:</div>
                            <div className={"col-sm-7 no-padding overflow-wrap-anywhere"}>
                                {insuranceRecord ? insuranceRecord.referenceNo : ''}
                            </div>
                        </div>
                        <div className={"display-flex"}>
                            <div className={"col-sm-5 no-padding dim14-form-label"}>Diagnosis:</div>
                            <div className={"col-sm-7 no-padding overflow-wrap-anywhere"}>
                                {directBilling && directBilling.diagnosisItem ? directBilling.diagnosisItem.code + ' ' + directBilling.diagnosisItem.description : ''}
                                {externalReferral ? externalReferral.icd.code + ' ' + externalReferral.icd.description : ''}
                            </div>
                        </div>
                        <div className={"display-flex"}>
                            <div className={"col-sm-5 no-padding dim14-form-label"}>Claim Status:</div>
                            <div className={"col-sm-7 no-padding overflow-wrap-anywhere"}>
                                {directBilling && 'Direct Billing: '}
                                {externalReferral && 'External Referral: '}
                                {insuranceRecord && insuranceRecord.status ? DirectBillingStatus[insuranceRecord.status].name : ''}
                            </div>
                        </div>
                        <div className={"display-flex"}>
                            <div className={"col-sm-5 no-padding dim14-form-label"}>Sessions:</div>
                            <div className={"col-sm-7 no-padding overflow-wrap-anywhere"}>
                                {insuranceRecord ?
                                    (insuranceRecord.usedAppointmentIds.indexOf(this.props.currentVisit.appointment.id) + 1) +
                                    '/' + insuranceRecord.noOfSessions : ''}
                            </div>
                        </div>
                        <div className={"display-flex"}>
                            <div className={"col-sm-5 no-padding dim14-form-label"}>Validity:</div>
                            <div className={"col-sm-7 no-padding overflow-wrap-anywhere"}>
                                {insuranceRecord && insuranceRecord.validUntil ?
                                    DateUtil.formatDate(insuranceRecord.validUntil, 'Do MMM YYYY') : ''}
                            </div>
                        </div>
                        {insuranceRecord && 'COMPLETED' !== insuranceRecord.status && insuranceRecord.noOfUsedSessions === insuranceRecord.noOfSessions &&
                        <Button variant={"light"} size={"sm"}
                                onClick={() => {
                                    this.setState({
                                        newDirectBillingModalVisible: true,
                                        additionalSessionsRange: range(1, 21),
                                        newDB: {
                                            text: 'Request More Sessions',
                                            type: 'ADDITIONAL_SESSIONS',
                                            additionalSessions: 1
                                        }
                                    })
                                }}>
                            Request More Sessions
                        </Button>}
                        {insuranceRecord && 'COMPLETED' !== insuranceRecord.status
                            && insuranceRecord.validUntil
                            && DateUtil.getDifferenceInDays(insuranceRecord.validUntil, new Date()) < 7 &&
                        <Button variant={"light"} size={"sm"}
                                onClick={() => {
                                    const remainingSessions = insuranceRecord.noOfSessions - insuranceRecord.noOfUsedSessions;
                                    this.setState({
                                        newDirectBillingModalVisible: true,
                                        additionalSessionsRange: range(1, (remainingSessions > 0 ? remainingSessions : 1) + 1),
                                        newDB: {
                                            text: 'Extend Validity',
                                            type: 'VALIDITY_EXTENSION',
                                            additionalSessions: remainingSessions > 0 ? remainingSessions : 1
                                        }
                                    })
                                }}>
                            Extend Validity
                        </Button>}
                    </div>
                </div>
                {this.state.newDirectBillingModalVisible &&
                <Modal visible={this.state.newDirectBillingModalVisible}
                       header={this.state.newDB.text}
                       submitContent="Confirm"
                       submitAction={this.createNewDirectBillingItem}
                       closeAction={() => this.setState({newDirectBillingModalVisible: false})}
                       contentStyle={{width: '300px', minHeight:'500px',}}>
                    <PrimeDropdown className="w-100"
                                   options={this.state.additionalSessionsRange}
                                   value={this.state.newDB.additionalSessions}
                                   onChange={event => {
                                       this.state.newDB.additionalSessions = event.value;
                                       this.forceUpdate();
                                   }}/>
                </Modal>}
            </div>
        );
    }
}
