import Enum from "../../../../../../enums/Enum";

const ReasonForDiscontinuationEnum = new Enum({
    Risk_Of_Harm: {name: "Risk of harm from planned care or treatment"},
    Lack_Of_Compliance: {name: "Lack of compliance to Treatment Plan"},
    Requires_Additional_Care: {name: "Patient requires additional care"},
    Patient_Request: {name: "Patient Request"},
    Patient_Is_Unstable: {name: "Patient is unstable"},
    Patient_Is_Getting_Worse: {name: "Patient is getting worse"},
    Patient_Not_Improving: {name: "Patient is not improving"},
    Other: {name: "Other"}
});

export default ReasonForDiscontinuationEnum;