import React, {Component} from "react";
import BorderedRadioEnumInput from "../radio-input/BorderedRadioEnumInput";
import EnumTimeZone from "../../enums/EnumTimeZone";
import {noop} from "lodash-es";
import PropTypes from "prop-types";

class TimeZoneSelector extends Component {

    static defaultProps = {
        options: EnumTimeZone.toArray(),
        value: null,
        onChange: noop
    };

    static propTypes = {
        options: PropTypes.array, // Array of Enum Object
        value: PropTypes.string,
        onChange: PropTypes.func
    };

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <BorderedRadioEnumInput
                values={this.props.options}
                selected={this.props.value}
                onSelect={value => this.props.onChange(value)}
            />
        )
    }
}

export default TimeZoneSelector;
