import React, {Component} from "react";
import '../../EMR.css';
import MovementEnum from "../enums/rangeofmotion/MovementEnum";
import JointEnum from "../enums/rangeofmotion/JointEnum";
import LocationEnum from "../enums/rangeofmotion/LocationEnum";
import GlideEnum from "../enums/rangeofmotion/GlideEnum";
import ResultEnum from "../enums/rangeofmotion/ResultEnum";
import EndFeelEnum from "../enums/rangeofmotion/EndFeelEnum";
import QualityEnum from "../enums/rangeofmotion/QualityEnum";
import MuscleGroupsEnum from "../enums/rangeofmotion/MuscleGroupsEnum";
import GradeEnum from "../enums/rangeofmotion/GradeEnum";
import IndividualMusclesEnum from "../enums/rangeofmotion/IndividualMusclesEnum";
import GreenSwitchWithMessage from "../../../../../components/switch/GreenSwitchWithMessage";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import MuscleStrengthResultEnum from "../enums/rangeofmotion/MuscleStrengthResultEnum";

export default class RangeOfMotionView extends Component {

    state = {}

    render() {

        const {rangeOfMotion, jointIntegrity, muscleGroup, individualMuscle} = this.props.assessment;

        return (
            <>
                <div style={{display: "flex"}}>
                    <div className="content-label ml-0">Range Of Motion <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                    <GreenSwitchWithMessage
                        message={(rangeOfMotion && rangeOfMotion.none) && "Normal"}
                        checked={(rangeOfMotion && rangeOfMotion.items && rangeOfMotion.items.length !== 0) || (rangeOfMotion && rangeOfMotion.none)}/>
                </div>
                {rangeOfMotion && rangeOfMotion.items && !rangeOfMotion.none &&
                <div className={"content-card"}>
                    <table className={"range-of-motion-table content-card overflow-wrap-anywhere"}>
                        <thead>
                        <tr>
                            <th colSpan={6}>Left</th>
                            <th className={"rangeOfMotionDarkColumn"}></th>
                            <th colSpan={6}>Right</th>
                        </tr>
                        <tr>
                            <th style={{width: "50px"}}>Details</th>
                            <th style={{width: "60px"}}>End Feel</th>
                            <th style={{width: "56px"}}>Quality</th>
                            <th style={{width: "35px"}}>Pain</th>
                            <th style={{width: "47px"}}>AROM</th>
                            <th style={{width: "47px"}}>PROM</th>

                            <th className={"rangeOfMotionDarkColumn"} style={{width: "85px"}}>Movement</th>

                            <th style={{width: "47px"}}>PROM</th>
                            <th style={{width: "47px"}}>AROM</th>
                            <th style={{width: "35px"}}>Pain</th>
                            <th style={{width: "56px"}}>Quality</th>
                            <th style={{width: "60px"}}>End Feel</th>
                            <th style={{width: "50px"}}>Details</th>
                        </tr>
                        </thead>
                        <tbody>
                        {rangeOfMotion.items.map((rowValue, index) => {
                            return (
                                <tr key={`${rowValue.movement?.trim()}`} style={{background: "white"}}>
                                    <td>{rowValue.leftDetails}</td>
                                    <td>{rowValue.leftEndFeel ? EndFeelEnum[rowValue.leftEndFeel].name : null}</td>
                                    <td>{rowValue.leftQuality ? QualityEnum[rowValue.leftQuality].name : null}</td>
                                    <td>{rowValue.leftPain}</td>
                                    <td>{rowValue.leftAROM}</td>
                                    <td>{rowValue.leftPROM}</td>

                                    <td className={"rangeOfMotionDarkColumn"}>{`${rowValue.area} ${rowValue.movement == null && rowValue.movementComment == null ?
                                        "" :
                                        MovementEnum[rowValue.movement] != null ?
                                            MovementEnum[rowValue.movement].name :
                                            rowValue.movementComment}`}</td>

                                    <td>{rowValue.rightPROM}</td>
                                    <td>{rowValue.rightAROM}</td>
                                    <td>{rowValue.rightPain}</td>
                                    <td>{rowValue.rightQuality ? QualityEnum[rowValue.rightQuality].name : null}</td>
                                    <td>{rowValue.rightEndFeel ? EndFeelEnum[rowValue.rightEndFeel].name : null}</td>
                                    <td>{rowValue.rightDetails}</td>
                                </tr>
                            )
                        })
                        }
                        </tbody>
                    </table>
                </div>}

                <div className="d-flex mt-3">
                    <div className="content-label ml-0">Joint Integrity</div>
                    <GreenSwitchWithMessage
                        message={(jointIntegrity && jointIntegrity.none) && "Normal"}
                        checked={(jointIntegrity && jointIntegrity.items && jointIntegrity.items.length !== 0) ||
                        (jointIntegrity && jointIntegrity.none)}/>
                </div>
                {jointIntegrity && jointIntegrity.items &&
                <div className={"content-card"}>
                    <table className={"emr-table"}>
                        <thead>
                        <tr>
                            <th style={{width: "130px"}}>Joint</th>
                            <th style={{width: "75px"}}>Location</th>
                            <th style={{width: "140px"}}>Glide</th>
                            <th style={{width: "95px"}}>Result</th>
                            <th>Details</th>
                        </tr>
                        </thead>
                        <tbody>
                        {jointIntegrity.items.map((rowValue, index) => {
                            return (
                                <tr key={index} style={{backgroundColor: "white"}}>
                                    <td>{rowValue.joint ? (JointEnum[rowValue.joint] ? JointEnum[rowValue.joint].name : rowValue.joint ) : null}</td>
                                    <td>{rowValue.location ? LocationEnum[rowValue.location].name : null}</td>
                                    <td>{rowValue.glide ? GlideEnum[rowValue.glide].name : null}</td>
                                    <td>{rowValue.result ? ResultEnum[rowValue.result].name : null}</td>
                                    <td>{rowValue.details}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>}

                <div style={{display: "flex", marginTop: "15px"}}>
                    <div className="content-label ml-0">Muscle Strength <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                    <GreenSwitchWithMessage
                        message={(muscleGroup && muscleGroup.none) && "Normal"}
                        checked={(muscleGroup && muscleGroup.items && muscleGroup.items.length !== 0) ||
                        (individualMuscle && individualMuscle.items && individualMuscle.items.length !== 0)
                        || (muscleGroup && muscleGroup.none)}/>
                </div>

                {muscleGroup && muscleGroup.items &&
                <div className={"row"} style={{margin: "0px -5px 10px -5px"}}>
                    <div className={"content-card"}>
                        <table className={"emr-table"}>
                            <thead>
                            <tr>
                                <th style={{width: "90px"}}>Group Muscle</th>
                                <th style={{width: "60px"}}>Location</th>
                                <th style={{width: "80px"}}>Result</th>
                                <th style={{width: "65px"}}>Grade</th>
                                <th>Details</th>
                            </tr>
                            </thead>
                            <tbody>
                            {muscleGroup.items.map((rowValue, index) => {
                                return (
                                    <tr key={index} style={{backgroundColor: "white"}}>
                                        <td>{rowValue.muscle ? MuscleGroupsEnum[rowValue.muscle].name : null}</td>
                                        <td>{rowValue.location ? LocationEnum[rowValue.location].name : null}</td>
                                        <td>{rowValue.result ? MuscleStrengthResultEnum[rowValue.result].name : null}</td>
                                        <td>{rowValue.grade ? GradeEnum[rowValue.grade].name : null}</td>
                                        <td>{rowValue.details}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>}

                {individualMuscle && individualMuscle.items &&
                <div className={"row"} style={{margin: "0px -5px 10px -5px"}}>

                    <div className={"content-card"}>
                        <table className={"emr-table overflow-wrap-anywhere"}>
                            <thead>
                            <tr>
                                <th style={{width: "90px"}}>Individual Muscle</th>
                                <th style={{width: "60px"}}>Location</th>
                                <th style={{width: "80px"}}>Result</th>
                                <th style={{width: "65px"}}>Grade</th>
                                <th>Details</th>
                            </tr>
                            </thead>
                            <tbody>
                            {individualMuscle.items.map((rowValue, index) => {
                                return (
                                    <tr key={index} style={{backgroundColor: "white"}}>
                                        <td>{rowValue.muscle ? IndividualMusclesEnum[rowValue.muscle].name : null}</td>
                                        <td>{rowValue.location ? LocationEnum[rowValue.location].name : null}</td>
                                        <td>{rowValue.result ? MuscleStrengthResultEnum[rowValue.result].name : null}</td>
                                        <td>{rowValue.grade ? GradeEnum[rowValue.grade].name : null}</td>
                                        <td>{rowValue.details}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>}
            </>
        );
    }
}