import React, {Component} from 'react';
import TetherComponent from 'react-tether';
import './FormSection.css';
import {ValidatorRegistry} from "./validator/ValidatorRegistry";
import clsx from "clsx";
import PropTypes from "prop-types";

class FormField extends Component {

    static propTypes = {
        validateOn: PropTypes.any,
        disabled: PropTypes.bool
    }

    static defaultProps = {
        validateOn: null,
        disabled: false
    };

    constructor(props) {
        super(props);
        this.validators = this.getValidators();
        this.state = {
            showError: false,
            errorMessages: []
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.disabled !== prevProps.disabled && this.props.disabled === true) {
            this.setState({showError: false, errorMessages: []});
        }
        if (this.props.validateOn !== prevProps.validateOn) {
            this.validate();
        }
        for (let i = 0; i < this.validators.length; i++) {
            let validatorProp = this.props[this.validators[i].name];
            let prevValidatorProp = prevProps[this.validators[i].name];
            if (validatorProp !== prevValidatorProp) {
                this.validate();
                break;
            }
        }
    }

    clear() {
        this.setState({showError: false, errorMessages: []});
    }

    validate(showErrorOnValidate = true) {
        if (this.validators.length === 0 || this.props.disabled) {
            this.setState({showError: false, errorMessages: []});
            return true;
        }

        let errorMessages = [];
        this.validators.forEach(validationDef => {
            let validator = validationDef.validator;
            let validatorProp = this.props[validationDef.name];
            let validationObject = this.props.validateOn;

            let isValid = validator.validate(validationObject, validatorProp);
            if (!isValid) {
                errorMessages.push(this.props[validationDef.name+'Message'] || validator.getErrorMessage(validatorProp));
            }
        });

        let isFieldValid = errorMessages.length === 0;
        this.setState({
            showError: !isFieldValid && showErrorOnValidate,
            errorMessages: errorMessages
        });

        return isFieldValid;
    }

    getValidators() {
        let validators = [];
        Object.keys(ValidatorRegistry).map(validatorName => {
            if (this.props.hasOwnProperty(validatorName)) {
                validators.push({name: validatorName, validator: ValidatorRegistry[validatorName]})
            }
        });
        return validators;
    }

    showError(){
        this.setState({showError: true});
    }

    hideError(){
        this.setState({showError: false});
    }

    hasError = () => {
        return this.state.errorMessages && this.state.errorMessages.length > 0;
    }

    render(){
        return <TetherComponent attachment="bottom center"
                             renderTarget={ref => (
                                 <div ref={ref}
                                      style={this.props.style}
                                      className={clsx("dim14-base-input ", this.props.className, this.hasError() ? "dim14-has-error" : "")}
                                      onMouseEnter={() => this.showError()}
                                      onMouseLeave={() => this.hideError()}
                                      onFocus={() => this.showError()}
                                      onBlur={() => this.hideError()}
                                 >
                                     {this.props.children}
                                 </div>
                             )}
                             renderElement={(ref) => {
                                 if (this.hasError() && this.state.showError) {
                                     let errorMessageElements = this.state.errorMessages.map((errorMessage, index) => {
                                         return <tr key={index} className="dim14-validation-message">
                                             <td>{errorMessage}</td>
                                         </tr>;
                                     });

                                     return <div ref={ref} className="dim14-validation-message-div">
                                         <table className="dim14-validation-message-table">
                                             <tbody>{errorMessageElements}</tbody>
                                         </table>
                                     </div>;
                                 }
                                 return undefined;
                             }}
        />
    }

}

export default FormField;