import React, {Component} from "react";
import {AutoComplete} from "primereact/autocomplete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import RemotingService from "../../../../services/remoting-service/RemotingService";

export default class IcdCodeSelector extends Component {

    constructor(props) {
        super(props);

        this.state = {
            icd: this.props.icd,
            disableIcdCodeInput: this.props.icd != null
        };
    }

    update = (icd) => {
        this.setState(state => {
            let newState = {...state}
            // newState.icd = icd ? icd.code + " " + icd.description : '';
            newState.icd = icd;
            newState.disableIcdCodeInput = icd != null;
            return newState;
        });

        this.props.onIcdSelected(icd);
    }

    clear = () => {
        this.update(null);
    }

    icdCodeSearch = (event) => {
        RemotingService.getRemoteCall(`api/icd10/code-or-description/${event.query}`, null,
            icdCodes => {
                this.setState({icdCodeSuggestions: icdCodes});
            });
    }

    render() {
        let value = this.props.icd ? this.props.icd : this.state.icd;
        return (
            <div style={{position: 'relative'}}>
                <AutoComplete className="w-100"
                              placeholder="Search..."
                              minLength={2}
                              value={value}
                              field="code"
                              onChange={event => this.setState({icd: event.value})}
                              suggestions={this.state.icdCodeSuggestions}
                              itemTemplate={icdCode =>
                                  <span>{icdCode.code} - {icdCode.description}</span>}
                              completeMethod={this.icdCodeSearch}
                              onSelect={event => {
                                  this.update(event.value);
                              }}
                              disabled={this.state.disableIcdCodeInput}
                              inputStyle={{width: 'inherit'}}
                />
                {
                    this.state.disableIcdCodeInput &&
                    <FontAwesomeIcon icon={["fas", "times"]}
                                     size={"sm"}
                                     onClick={() => {
                                         this.clear();
                                     }}
                                     style={{
                                         position: 'absolute',
                                         top: 10,
                                         right: 8
                                     }}/>
                }
            </div>
        );
    }
}
