import React, {Component} from "react";
import '../../VisitHistory.css'
import {AllergyChecker} from "../../../subjective/checkers/AllergyChecker";
import moment from "moment/moment";

export default class AllergyViewHistory extends Component {

    render() {
        if(this.props.allergy == null || !AllergyChecker.isToggleOn(this.props.allergy)) {
            return <div className="pl-3">
                {this.props.allergy && this.props.allergy.none ? "None Known" : "No information available"}
            </div>;
        }

        if(this.props.allergy.none) {
            return <div className="pl-3">None Known</div>;
        }
        return (
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Allergy</th>
                    <th>Allergen</th>
                    <th>Medication</th>
                    <th>Severity</th>
                    <th>Date</th>
                    <th>Reaction</th>
                </tr>
                {this.props.allergy.items.map((item) =>
                    <tr>
                        <td>{item.otherAllergyType || item.allergyType?.name}</td>
                        <td>{item.otherAllergen || item.allergen?.name}</td>
                        <td>{item.medicationBrand}</td>
                        <td>{item.otherAllergenSeverity || item.allergenSeverity?.name}</td>
                        <td>{moment(item.discoveredDate).format('YYYY-MM-DD')}</td>
                        <td>{item.otherAllergenReaction || item.allergenReaction?.name}</td>
                    </tr>
                )}
                </tbody>
            </table>


        );
    }
}
