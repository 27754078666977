import Enum from "../../../../enums/Enum";


const IntakeFormReferenceTypeEnum = new Enum({
    FAMILYFRIENDS: {name: "Family/Friends", nameInArabic: "من أحد أفراد العائلة/ الأصدقاء"},
    DRHOSPITAL: {name: "Dr/Hospital", nameInArabic: "طبيب/ من المستشفى"},
    INSTAGRAM: {name: "Instagram", nameInArabic: "تطبيق \"انستجرام\""},
    FACEBOOK: {name: "Facebook", nameInArabic: "موقع \"فيس بوك\""},
    TWITTER: {name: "Twitter", nameInArabic: "تطبيق \"تويتر\""},
    OTHER: {name: "Other", nameInArabic: "أخر"},
    }
)

export default IntakeFormReferenceTypeEnum;