import React from "react";
import ReactDOM from "react-dom";
import Modal from "../modal/Modal";
import {noop} from "lodash-es";
import {DialogActions} from "@material-ui/core";

export default function dialog({title, component, style, onClose = noop, closeButtonDisabled, disableBackdropClick = true}) {

    const dialogContainer = document.getElementById('dialog-container');
    const confirmationDialogDiv = dialogContainer.appendChild(document.createElement('div'));

    let dialogActions = null;
    React.Children.forEach(component.children, (child) => {
        if (child.type === DialogActions) {
            dialogActions = child;
        }
    });

    function dispose() {
        setTimeout(() => {
            ReactDOM.unmountComponentAtNode(confirmationDialogDiv);
            setTimeout(() => {
                if (dialogContainer.contains(confirmationDialogDiv)) {
                    dialogContainer.removeChild(confirmationDialogDiv)
                }
            });
        }, 200);
    }

    function closeDialog() {
        dispose();
        onClose();
    }

    function renderModal() {
        return <Modal visible={true}
                      header={title}
                      contentStyle={style}
                      disableBackdropClick={disableBackdropClick}
                      closeAction={closeButtonDisabled ? noop : closeDialog}
                      dialogActions={dialogActions}>
            {
                React.cloneElement(component, {
                    close: () => closeDialog()
                })
            }
        </Modal>
    }

    ReactDOM.render(renderModal(), confirmationDialogDiv);

    return closeDialog;
}
