import Enum from "../../../../../../enums/Enum";

const NeurodynamicsUpperLimbEnum = new Enum({
    NUL_ULTT1: {value: "ULTT1, Median nerve bias"},
    NUL_ULTT2: {value: "ULTT2, Radial nerve bias"},
    NUL_ULTT3: {value: "ULTT3, Ulnar nerve bias"},
    NUL_ULLT: {value: "ULLT musculocutaneous"},
    NUL_OTHER: {value: "Other"},
});

export default NeurodynamicsUpperLimbEnum;