import React from 'react';
import moment from "moment";
import DateUtil from "../../../../utils/DateUtil";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import Modal from "../../../../components/modal/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ActionMenu from "../../../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../../../components/action-menu/ActionMenuItem";
import ConfirmIcon from "../../../../components/action-menu/icons/ConfirmIcon";
import EnumCreditStatus from "../../enums/EnumCreditStatus";
import DeleteIcon from "../../../../components/action-menu/icons/DeleteIcon";
import GiftIcon from "../../../../components/action-menu/icons/GiftIcon";
import Tooltip from "../../../../components/tooltip/Tooltip";
import CreditConsentForm from "./CreditConsentForm";
import AuthService from "../../../../services/auth-service/AuthService";
import dialog from "../../../../components/dialog/Dialog";
import ConsentFormCode from "../../../consentform/ConsentFormCode";
import ViewIcon from "../../../../components/action-menu/icons/ViewIcon";
import QrIcon from "../../../../components/action-menu/icons/QrIcon";
import RefundIcon from "../../../../components/action-menu/icons/RefundIcon";
import SockJsClient from "react-stomp";
import RedeemCreditGiftModal from "./RedeemCreditGiftModal";
import {Dropdown, SplitButton} from "react-bootstrap";
import CreditGiftHistory from "./CreditGiftHistory";
import MoneyUtil from "../../../../utils/MoneyUtil";
import CancelIcon from "../../../../components/action-menu/icons/CancelIcon";
import confirmDialog from "../../../../components/dialog/ConfirmDialog";
import GreenSwitch from "../../../../components/switch/GreenSwitch";

class PatientCreditPanel extends React.Component {

    constructor(props) {
        super(props);
        this.maxAmounts = {'UAE': 10000, 'KUWAIT': 800};
        this.minAmounts = {'UAE': 1000, 'KUWAIT': 100};

        const {uaeSelected} = AuthService.getSelectedClinicCountriesInfo();

        this.cleanCreditState = {
            newCreditModalVisible: false,
            creditCountry: uaeSelected ? "UAE" : "KUWAIT",
            creditAmount: 1000,
            creditDiscountRate: 1,
            creditValidityPeriod: 8
        };
        this.state = {
            credits: [],
            ...this.cleanCreditState
        };
    }

    componentDidMount() {
       this.retrieveCreditList();
    }

    retrieveCreditList() {
        RemotingService.getRemoteCall('api/credit/list', {patientId: this.props.patient.id},
            (credits) => {
                this.setState({credits});
            });
    }

    redeemGift() {
        dialog({
            title: "",
            disableBackdropClick: false,
            component: <RedeemCreditGiftModal patientId={this.props.patient.id}
                                              onRedeemed={() => this.retrieveCreditList()}/>
        });
    }

    giftHistory() {
        dialog({
            title: "Gift History",
            component: <CreditGiftHistory patientId={this.props.patient.id}/>
        });
    }

    generateTabletCode = (creditId=-1) => {
        RemotingService.postRemoteCall(
            "api/consent-form/code",
            {formType: "CREDIT_CONSENT_FORM", patientId: this.props.patient.id, creditId: creditId},
            (code) => {
                dialog({
                    style: {width: 450, height: 150},
                    component: <ConsentFormCode code={code} formName={"Credit Consent Form"}/>,
                    disableBackdropClick: false
                });
            }
        );
    }

    onDeleteWalletCredit = () =>{
        this.retrieveCreditList();
    }

    onGiftCancelled = () =>{
        this.retrieveCreditList();
    }

    onCreditActiveModeChanged(credits) {
        this.setState({credits});
    }

    refreshScreen() {
        setTimeout(() => window.location.reload(false), 1000);
    }

    render() {
        return (
            <div className="card">
                <SockJsClient
                    url={RemotingService.getApiBaseUrl() + "websocket"}
                    topics={["/topic/credit-form/" + this.props.patient.id]}
                    onMessage={this.refreshScreen}
                    ref={client => this.clientRef = client}
                />

                <div className="card-header">
                    <div className="card-header-title">
                        <strong style={{verticalAlign: "middle"}}>DISC Wallet</strong>
                    </div>
                    <div className="card-header-actions">
                        {this.props.editable ?
                        <SplitButton
                            id="addCreditBtnGrp"
                            variant="primary btn-sm"
                            title="Add Credit"
                            onClick={() => this.generateTabletCode()}>
                            <Dropdown.Item onClick={() => this.redeemGift()}>Redeem Gift</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.giftHistory()}>Gift History</Dropdown.Item>
                        </SplitButton>
                        :
                        <button type="button" className="btn btn-primary btn-sm" onClick={() => this.giftHistory()}>
                            Gift History
                        </button>
                        }
                    </div>
                </div>


                    <table className="table table-borderless mb-0 calculated-table table-sm ml-2">
                        <thead>
                        <tr className="border-bottom">
                            <td className="text-left" >ID</td>
                            <td className="text-left" >Status</td>
                            <td className="text-right" >Amount</td>
                            <td className="text-right">Balance</td>
                            <td className="text-center">%</td>
                            <td className="text-left">Validity</td>
                            <td className="text-center" >Form</td>
                            <td className="text-center">Actions</td>
                            <td className="text-right">Active</td>
                        </tr>
                        </thead>



                    {
                        (this.state.credits && this.state.credits.length) ?
                            <>
                                <CreditDetail patient={this.props.patient} credit={this.state.credits[0]}
                                              editable={this.props.editable}
                                              onConfirmWalletCredit={this.props.onConfirmWalletCredit}
                                              onGenerateTabletCode={this.generateTabletCode}
                                              onDelete={this.onDeleteWalletCredit}
                                              onGiftCancelled={this.onGiftCancelled}
                                              onCreditActiveModeChanged={credits => this.onCreditActiveModeChanged(credits)}/>
                                {
                                    this.state.credits.length > 1 ?
                                        <FontAwesomeIcon
                                            icon={["fa", `angle-double-${this.state.creditPanelExpanded ? 'up' : 'down'}`]}
                                            style={{position: "absolute", bottom:"4px", right: "8px"}}
                                            onClick={() => this.setState({creditPanelExpanded: !this.state.creditPanelExpanded})}
                                        /> : null
                                }
                            </>
                            :
                            <div className="row">
                                <div className="col-12">
                                    No credit info found.
                                </div>
                            </div>
                    }
                    </table>
                {
                    (this.state.credits && this.state.credits.length && this.state.credits.length > 1) ?
                        <div
                            className={`card-body small-font text-right expandable p-0 ${this.state.creditPanelExpanded ? 'expanded' : ''}`}>
                            <table className="table table-borderless mb-0 calculated-table table-sm ml-2">
                            {this.state.credits.map((credit, inx) => {
                                if (inx === 0) return null;
                                return (
                                    <>
                                        <CreditDetail patient={this.props.patient} credit={credit}
                                                      editable={this.props.editable}
                                                      onConfirmWalletCredit={this.props.onConfirmWalletCredit}
                                                      onGenerateTabletCode={this.generateTabletCode}
                                                      onDelete={this.onDeleteWalletCredit}
                                                      onGiftCancelled={this.onGiftCancelled}
                                                      onCreditActiveModeChanged={credits => this.onCreditActiveModeChanged(credits)}/>
                                    </>
                                )
                            })}
                            </table>
                        </div> : null
                }
            </div>
        );
    }
}

class CreditDetail extends React.Component {

    state = {
        confirmModalVisible: false,
        refundModalVisible: false,
        consentFormVisible: false,
        isActivateButtonEnabled: AuthService.isManagerOrSuperUser()
    };

    handleCancel() {
        this.setState({
            confirmModalVisible: false,
            refundModalVisible: false,
            actionItem: null
        });
    }

    confirm(credit) {
        this.setState({confirmModalVisible: true, actionItem: credit, menuAnchor: null})
    }

    renderConfirmModal = (credit) => {
        return <Modal visible={this.state.confirmModalVisible}
                      header="Confirm Credit"
                      submitContent="Confirm"
                      submitAction={() => this.handleConfirmOk()}
                      closeAction={() => this.handleCancel()}>
            {"You are about to confirm credit with id: " + credit.walletId + ". Are you sure?"}
        </Modal>;
    }

    handleConfirmOk() {
        const credit = this.state.actionItem;

        RemotingService.postRemoteCall('api/credit/' + credit.id + '/confirm', null, () => {
            credit.status = 'CONFIRMED';
            this.setState({confirmModalVisible: false, actionItem: null});
            this.props.onConfirmWalletCredit();
        });
    }

    generateTabletCode() {
        this.props.onGenerateTabletCode(this.props.credit.id);
    }

    refund(credit) {
        this.setState({refundModalVisible: true, actionItem: credit, menuAnchor: null})
    }

    calculateRefundAmount(credit) {
        return credit.balance;
    }

    renderRefundModal = (credit) => {
        return <Modal visible={this.state.refundModalVisible}
                      header="Refund Credit"
                      submitContent="Confirm"
                      submitAction={() => this.handleRefundOk()}
                      closeAction={() => this.handleCancel()}>
            {this.state.refundModalVisible &&
                <div style={{display: "flex", flexFlow: "row", justifyContent: "space-around"}}>
                    You will be refunded&nbsp;
                    <div style={{fontWeight: 'bold'}}>{MoneyUtil.format(this.calculateRefundAmount(credit))}</div>
                    &nbsp;
                    amount of your credit. Are you sure?
                </div>
            }
        </Modal>;
    }

    handleRefundOk() {
        const credit = this.state.actionItem;

        RemotingService.postRemoteCall('api/credit/' + credit.id + '/refund', null, () => {
            credit.status = 'REFUNDED';
            this.setState({refundModalVisible: false, actionItem: null});
        });
    }

    gift(credit) {
        RemotingService.postRemoteCall(
            "api/consent-form/code",
            {formType: "CREDIT_GIFT_FORM", patientId: this.props.patient.id, creditId: credit.id},
            (code) => {
                dialog({
                    style: {width: 450, height: 150},
                    component: <ConsentFormCode code={code} formName="Gift Wallet"/>,
                    disableBackdropClick: false
                });
            }
        );
    }

    cancelGift(credit) {
        confirmDialog("Confirm Cancel", `Gift request will be cancelled. Do you want to continue?`, () => {
            RemotingService.postRemoteCall(`api/credit/cancel-gift/${credit.id}`, null, () => {
                this.props.onGiftCancelled();
            });
        });
    }

    delete(creditId) {
        RemotingService.deleteRemoteCall("api/credit/" + creditId, null, result => {
            this.props.onDelete();
        });
    }

    renderConsentForm = (patient, credit) => {
        return <CreditConsentForm
            show={this.state.consentFormVisible}
            close={() => this.setState({consentFormVisible: false})}
            credit={credit}
            form={credit.consentForm}
            readOnly={true}
            language={"English"}
        />
    }

    renderCreditStatus(creditStatusEnum) {
        return (
            <Tooltip title={creditStatusEnum.name}>
                <span>{creditStatusEnum.icon}</span>
            </Tooltip>
        )
    }

    handleCreditActiveModeChange = (credit) => {
        if (moment(credit.validityDate).isSameOrAfter(moment(DateUtil.yesterday())) || credit.isActiveMode === true) {
            const params = {
                id: credit.id,
                isActiveMode: !credit.isActiveMode
            };
            RemotingService.postRemoteCall(`api/credit/changeCreditActivationMode?patientId=${this.props.patient.id}`, params, (credits) => {
                this.props.onCreditActiveModeChanged(credits);
            });
        }
    }

    render() {
        const {patient, credit} = this.props;
        const currency = MoneyUtil.getCurrency(credit.country);

        return (


            <>

                <thead className={"invisible-hero"}>
                <tr className="border-bottom">
                    <td className="text-left" >ID</td>
                    <td className="text-left" >Status</td>
                    <td className="text-right" >Amount</td>
                    <td className="text-right">Balance</td>
                    <td className="text-center">%</td>
                    <td className="text-left">Validity</td>
                    <td className="text-center" >Form</td>
                    <td className="text-center">Actions</td>
                    <td className="text-right">Active</td>
                </tr>
                </thead>

                        <tr>
                            <td className="text-left">{credit.status != 'PENDING' ? credit.walletId : ''}</td>
                            <td className="text-center">{this.renderCreditStatus(EnumCreditStatus[credit.status])}</td>
                            <td className="text-right">{MoneyUtil.format(credit.amount, currency)}<small>{currency}</small>
                            </td>
                            <td className="text-right">{MoneyUtil.format(credit.balance, currency)}<small>{currency}</small>
                            </td>
                            <td className="text-left" >{credit.discountRate}<small>%</small></td>
                            <td className="text-left" >{DateUtil.formatDateMMM(credit.validityDate)}</td>
                            <td className="text-center" >
                                {!credit.consentForm ? '' :
                                    <FontAwesomeIcon icon={["fas", "paperclip"]}
                                                     onClick={() => this.setState({consentFormVisible: true})}/>
                                }
                            </td>
                            <td className="text-center" >
                                {this.props.editable &&
                                <ActionMenu id="creditpanelactionmenu">
                                    <ActionMenuItem icon={<ViewIcon/>}
                                                    onClick={() => this.setState({consentFormVisible: true})} text={"View"}/>
                                    <ActionMenuItem disabled={credit.status != 'PENDING'} icon={<QrIcon/>}
                                                    onClick={() => {
                                                        this.generateTabletCode()
                                                    }} text={"Generate Tablet Code"}/>
                                    <ActionMenuItem disabled={credit.status != 'PENDING'} icon={<ConfirmIcon/>}
                                                    onClick={() => this.confirm(credit)}
                                                    text={"Confirm"}/>
                                    <ActionMenuItem disabled={credit.status != 'BILLED'} icon={<RefundIcon/>}
                                                    onClick={() => this.refund(credit)}
                                                    text={"Get Refund"}/>
                                    <ActionMenuItem disabled={credit.status != 'BILLED'} icon={<GiftIcon/>}
                                                    onClick={() => this.gift(credit)}
                                                    text={"Gift Balance"} />
                                    {credit.status == 'GIFT_PENDING' &&
                                    <ActionMenuItem onClick={() => this.cancelGift(credit)} icon={<CancelIcon/>}
                                                    text="Cancel Gift" />
                                    }
                                    <ActionMenuItem disabled={credit.status != 'PENDING'} icon={<DeleteIcon/>}
                                                    onClick={() => {
                                                        this.delete(credit.id)
                                                    }} text={"Delete"} isLastItem/>
                                </ActionMenu>
                                }
                            </td>
                            {this.state.isActivateButtonEnabled ?
                                (<td className="text-right" >
                                    <GreenSwitch checked={credit.isActiveMode}
                                                 onChange={() => this.handleCreditActiveModeChange(credit)}/>
                                </td>) :
                                credit.isActiveMode ?
                                    (
                                        <td className="text-left">
                                            <FontAwesomeIcon icon={["fas", "check"]} color={"#7aae00"} style={{marginRight: "5px"}}/>
                                        </td>
                                    ) :
                                    (
                                        <td className="text-left" >
                                            <FontAwesomeIcon icon={["fas", "times"]} color={"red"} style={{marginRight: "8px"}}/>
                                        </td>
                                    )
                            }
                        </tr>







                {this.renderConfirmModal(credit)}
                {this.renderRefundModal(credit)}
                {this.renderConsentForm(patient, credit)}
            </>
        );
    }
}

export default PatientCreditPanel;
