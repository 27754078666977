import React, {Component} from 'react';
import moment from "moment";
import StringUtil from "../../utils/StringUtil";
import {NumberInput, TextInput, Wizard} from "../../components";
import RemotingService from "../../services/remoting-service/RemotingService";
import FormField from "../../components/form/FormField";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import SignatureInput from "../../components/signature-input/SignatureInput";
import Logo from "../../layout/Logo";
import PatientUtil from "../../utils/PatientUtil";

class DoubleSessionRequestForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            consentDate: new Date(),
            providerName: this.props.staff.name,
            providerSpeciality: this.props.staff.speciality.name
        }
    }

    onSubmit = () => {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        if (this.props.form !== null && this.props.form !== undefined) {
            this.props.close();

        } else {
            const {
                consentDate,
                providerName,
                providerSpeciality,
                guardianName,
                guardianRelationship,
                patientSignature,
                providerSignature,
                billRate
            } = this.state;
            let patient = this.props.patient;
            let patientFullName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
            let patientMrn = StringUtil.mrn(patient.mrn);
            RemotingService.postRemoteCall('api/visit/form/double-session-request-form/' + this.props.visitId,
                {
                    consentDate,
                    providerName,
                    providerSpeciality,
                    guardianName,
                    guardianRelationship,
                    patientSignature,
                    providerSignature,
                    billRate,
                    patientFullName,
                    patientMrn
                },
                (result) => {
                    this.props.afterSave(result);
                    this.props.close();
                });
        }
    }

    onSavePatientSignature = (signature) => {
        this.setState({patientSignature: signature})
    }

    onSaveProviderSignature = (signature) => {
        this.setState({providerSignature: signature})
    }

    render() {
        if (!this.props.show)
            return null;

        let {patient, form} = this.props;
        let providerName = this.state.providerName;
        let readOnly = form !== null && form !== undefined;
        let mrnNo = StringUtil.mrn(patient.mrn);
        let patientName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
        this.formFields = []

        return (
            <Wizard
                show={this.props.show}
                close={this.props.close}
                title="Double Session Request Form"
                onSubmit={this.onSubmit}
                isReadOnly={readOnly}
            >
                <Wizard.Page>
                    <div style={{maxWidth: '950px'}}>
                        <div style={{
                            marginTop: '15px', marginBottom: '15px', width: '100%', display: 'flex',
                            flexFlow: 'row', justifyContent: 'space-between', alignItems: 'center'
                        }}>
                            <Logo/>
                            <div>
                                <h2>DOUBLE SESSION REQUEST FORM</h2>
                            </div>
                        </div>
                        <div className="display-flex p-justify-between text-right" style={{marginBottom: "-10px"}}>
                            <p><b>Date
                                :</b> {moment(readOnly ? new Date(form.consentDate) : this.state.consentDate).format('LL')}
                            </p>
                        </div>

                        <div className="display-flex p-justify-between text-right">
                            <p><b>Patient Name :</b> {patientName}</p>
                            <p><b>MRN :</b> {mrnNo}</p>
                            <p><b>Provider Name :</b> {providerName}</p>
                        </div>

                        <hr style={{
                            borderTop: "2px solid #000000",
                            marginTop: "-15px",
                            boxShadow: "0px 2px 2px #797979"
                        }}/>

                        <div style={{border: "1px solid #000000"}}>
                            <div style={{margin: "0px 15px"}}>
                                <p>I hereby certify that I request my healthcare provider to perform 2 consecutive
                                    sessions on the present day for more thorough treatment of more than one area
                                    of complaint.</p>
                                <div className={"row"} style={{marginLeft: "0px"}}>
                                    <p>I understand, and I consent that these 2 sessions will be billed at the following
                                        rate:</p>
                                    <NumberInput
                                        value={readOnly ? form.billRate : this.state.billRate}
                                        disabled={readOnly} style={{textAlign: "center"}}
                                        style={{marginLeft: "10px"}}
                                        min={0}
                                        allowDecimal
                                        maxFractionDigits={patient.mrn.country === "UAE" ? 2 : 3}
                                        onChange={(val) => this.setState({billRate: val})}/>
                                </div>
                                <p>I’m also aware that some insurance policies may not accept to reimburse the second
                                    session depending on my policy coverage and I take full responsibility for the
                                    costs incurred.</p>
                            </div>
                        </div>

                        <div className={"container"}>

                            <div className={"row"} style={{marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    Legal Guardian Name
                                </div>
                                <div className="col-md-3">
                                    <TextInput value={readOnly ? form.guardianName : this.state.guardianName}
                                               disabled={readOnly} alphabeticOnly style={{textAlign: "center"}}
                                               onChange={(val) => this.setState({guardianName: val})}/>
                                </div>

                                <div className="col-md-3 dim14-form-label">
                                    Relationship to Patient
                                </div>
                                <div className="col-md-3 dim14-base-input">
                                    <TextInput
                                        value={readOnly ? form.guardianRelationship : this.state.guardianRelationship}
                                        disabled={readOnly} alphabeticOnly style={{textAlign: "center"}}
                                        onChange={(val) => this.setState({guardianRelationship: val})}/>
                                </div>
                            </div>

                            <div className="row" style={{marginTop: "15px"}}>
                                <div className="col-md-6 dim14-form-label">
                                    Signature of Patient (or legal guardian)
                                </div>

                                <div className="col-md-6 dim14-form-label">
                                    Signature of Provider
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-md-6">
                                    <FormField ref={formField => this.formFields.push(formField)}
                                               disabled={readOnly}
                                               required requiredMessage="Signature required"
                                               validateOn={this.state.patientSignature}>
                                        <SignatureInput
                                            signature={readOnly ? form.patientSignature : this.state.patientSignature}
                                            onSave={this.onSavePatientSignature} readOnly={readOnly}/>
                                    </FormField>
                                </div>

                                <div className="col-md-6">
                                    <FormField ref={formField => this.formFields.push(formField)}
                                               disabled={readOnly}
                                               required requiredMessage="Signature required"
                                               validateOn={this.state.providerSignature}>
                                        <SignatureInput
                                            signature={readOnly ? form.providerSignature : this.state.providerSignature}
                                            onSave={this.onSaveProviderSignature} readOnly={readOnly}/>
                                    </FormField>
                                </div>
                            </div>
                        </div>
                    </div>
                </Wizard.Page>
            </Wizard>
        );
    }
}

export default DoubleSessionRequestForm;
