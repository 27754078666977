import React, {Component} from "react";
import {Button} from "react-bootstrap";
import {DialogActions} from "@material-ui/core";
import PrimeDateInput from "../../components/date-input/PrimeDateInput";
import SchedulingService from "../../services/scheduling-service/SchedulingService";
import {FormField} from "../../components/form";
import ValidationUtil from "../../components/form/validator/ValidationUtil";

export default class GenerateRotationModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: null
        }
    }

    generateRotation() {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        SchedulingService.createScheduleJob(this.state.startDate, (scheduleJob) => {
            this.props.close();
            this.props.onGenerate(scheduleJob);
        });
    }

    render() {
        this.formFields = [];

        return (
            <>
                <div className="container" style={{minWidth: 300}}>
                    <div className="row">
                        <div className="col-4">
                            Start Date
                        </div>
                        <div className="col-8">
                            <FormField ref={formField => this.formFields.push(formField)}
                                       required validateOn={this.state.startDate}>
                                <PrimeDateInput containerStyle={{width: "100%"}}
                                                disablePast disabledDays={[1,2,3,4,5,6]}
                                                value={this.state.startDate}
                                                onChange={value => this.setState({startDate: value})}/>
                            </FormField>
                        </div>
                    </div>
                </div>
                <DialogActions>
                    <Button variant="light" onClick={() => this.props.close()}>Cancel</Button>
                    <Button variant="success" onClick={() => this.generateRotation()}>Generate Rotation</Button>
                </DialogActions>
            </>
        )
    }

}