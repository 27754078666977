import Enum from "../../../../../../enums/Enum";

const FindingEnum = new Enum({
        NORMAL: {name: "Normal"},
        PARADOXAL: {name: "Paradoxal"},
        DECREASE: {name: "Decrease"},
        INCREASE: {name: "Increase"},
        OTHER: {name: "Other"}

});

export default FindingEnum;