import React, {Component} from "react";
import '../../EMR.css';
import AreaEnum from "../enums/specialtests/AreaEnum";
import TestEnum from "../enums/specialtests/TestEnum";
import PosNegRadioInput from "../../../../../components/radio-input/PosNegRadioInput";

export default class SpecialTestsView extends Component {

    state = {
        list: [],
        index: null,
        listSize: null
    }

    render() {
        if(this.props.specialTests == null
            || this.props.specialTests.items == null
            || this.props.specialTests.items.length == 0) {
            return null;
        }

        return(
            <div className={"content-card"}>
                <table className={"emr-table"}>
                    <tbody>
                    <tr>
                        <th>Area</th>
                        <th>Test</th>
                        <th>Right</th>
                        <th>Left</th>
                        <th>Radiating</th>
                        <th>Details</th>
                    </tr>
                    {this.props.specialTests.items.map((item, index) => {
                        return (<tr key={"specialTestsView" + index} style={{backgroundColor: "white"}}>
                            <td>{AreaEnum[item.area]?.name}</td>
                            <td>{TestEnum[item.test]?.name}</td>
                            <td style={{position: "relative", minWidth: "80px"}}>
                                <PosNegRadioInput value={item.rightBool} readOnly={true}/>
                            </td>
                            <td style={{position: "relative", minWidth: "80px"}}>
                                <PosNegRadioInput value={item.leftBool} readOnly={true}/>
                            </td>
                            <td>{item.radiating}</td>
                            <td>{item.detail}</td>
                        </tr>)
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
}