import React, {Component} from 'react';
import RemotingService from '../../../../services/remoting-service/RemotingService';
import Tabs from "react-bootstrap/Tabs";
import './StaffSchedule.css';
import StaffRotation from "./StaffRotation";
import Tab from "react-bootstrap/Tab";
import DateUtil from "../../../../utils/DateUtil";

class StaffSchedule extends Component {

    state = {
        selectedTab: 'rotation-1',
        activeRotationTab: 'rotation-1',
        rooms: [],
        rotations: []
    };

    refreshSchedule(staffId) {
        RemotingService.getRemoteCall('api/room/list', null, (rooms) => {
            RemotingService.getRemoteCall(`api/staff/${staffId}/rotation`, null, (rotationsDto) => {
                const rotations = this.filterOldPeriods(rotationsDto.rotations);
                const {rotationIndex, periodIndex} = this.getCurrentRotationAndPeriod(rotations);

                this.setState({
                    rooms,
                    rotations,
                    currentRotationIndex: rotationIndex,
                    currentPeriodIndex: periodIndex,
                    activeRotationTab: 'rotation-' + (rotationIndex + 1)
                });
            });
        });
    }

    componentDidMount() {
        this.refreshSchedule(this.props.staffId);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {refresh, staffId} = this.props;

        if (refresh != nextProps.refresh) {
            this.refreshSchedule(staffId);
        }
    }

    filterOldPeriods(rotations) {
        const filteredRotations = [];
        rotations.forEach(rotation => {
            rotation.periods = rotation.periods.filter(period => DateUtil.isBetweenInclusive(DateUtil.now(), period.startDate, period.endDate) || DateUtil.isSameOrBefore(DateUtil.now(), period.startDate));
            filteredRotations.push(rotation);
        });

        return filteredRotations;
    }

    getCurrentRotationAndPeriod(rotations) {
        const today = new Date();
        for (let rotationIndex = 0; rotationIndex < rotations.length; rotationIndex++) {
            const rotation = rotations[rotationIndex];
            for (let periodIndex = 0; periodIndex < rotation.periods.length; periodIndex++) {
                const period = rotation.periods[periodIndex];
                if (DateUtil.isBetweenInclusive(today, period.startDate, period.endDate)) {
                    return {rotationIndex, periodIndex};
                }
            }
        }

        return {rotationIndex: 0, periodIndex: 0};
    }

    retrieveShiftedRotations() {
        let shiftedRotations = [];

        let rotationsLength = this.state.rotations.length;
        for(let currentIndex = 0; currentIndex < rotationsLength; currentIndex++ ) {
            let shiftedIndex = (this.state.currentRotationIndex + currentIndex) % rotationsLength;
            shiftedRotations.push(this.state.rotations[shiftedIndex])
        }

        return shiftedRotations;
    }

    render() {
        const isFixedRotation = this.state.rotations.length === 1;
        let shiftedRotations = this.retrieveShiftedRotations();

        return <Tabs activeKey={`${this.state.selectedTab}`} id="rotations-tabs" style={{paddingTop: 10}}
                     onSelect={(tab) => this.tabSelected(tab)}>
            {shiftedRotations.map(
                (rotation, index) => {
                    const tabIndex = index + 1;
                    return <Tab eventKey={`rotation-${tabIndex}`}
                                tabClassName={tabIndex === 1 ? 'd14-selected-tab' : ''}
                                title={isFixedRotation ? 'Fixed Rotation' : `rotation-${tabIndex}`}>
                        <StaffRotation staffId={this.props.staffId}
                                       rotation={rotation}
                                       isFixedRotation={isFixedRotation}
                                       rooms={this.state.rooms}
                                       currentPeriodIndex={this.state.currentPeriodIndex}/>
                    </Tab>
                }
            )}
        </Tabs>
    }

    tabSelected(selectedTab) {
        this.setState({selectedTab: selectedTab});
    }
}

export default StaffSchedule;
