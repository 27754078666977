import React, { Component } from "react";
import "../../EMR.css";
import TextInput from "../../../../../components/text-input/TextInput";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import StringUtil from "../../../../../utils/StringUtil";
import GreenSwitchWithEmptyMessage from "../../../../../components/switch/GreenSwitchWithEmptyMessage";
import FunctionEnum from "../enums/functionalTesting/FunctionEnum";
import ResultEnum from "../enums/functionalTesting/ResultEnum";

export default class FunctionalTestingModal extends Component {
  state = {
    function: null,
    result: null,
    detail: null,
  };

  constructor(props) {
    super(props);
  }

  render() {
    let functionalTestingDivs = this.getFunctionalTestingDivs();

    return (
      <div
        className={"emr-modal content-row emr-background"}
        style={{ width: "100%" }}
      >
        <div style={{ width: "100%", margin: "20px" }}>
          <div>
            <div style={{ display: "flex" }}>
              <div
                className="content-label"
                style={{ margin: "auto", marginLeft: "0px" }}
              >
                Functional Testing
              </div>
              <GreenSwitchWithEmptyMessage
                checked={
                  this.props.assessment.functionalTesting != null &&
                  this.props.assessment.functionalTesting.items.length != 0
                }
                onChange={() => {
                  if (this.props.assessment.functionalTesting == null) {
                    this.props.assessment.functionalTesting = {};
                    this.props.assessment.functionalTesting.items = [];
                  } else {
                    this.props.assessment.functionalTesting = null;
                  }
                  this.forceUpdate();
                }}
              />
            </div>
            <div className={"row no-margin"}>
              <div className={"col-4"}>
                <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                  Function
                </div>
              </div>
              <div className={"col-4"}>
                <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                  Result
                </div>
              </div>
              <div className={"col-3"}>
                <div style={{ fontSize: "12px", fontWeight: "bold" }}>
                  Details
                </div>
              </div>
            </div>
            <div className={"row no-margin"}>
              <div className={"col-4"}>
                <Select
                  className={"emr-white-select-container"}
                  classNamePrefix={"emr-white-select"}
                  placeholder={"Function"}
                  value={
                    this.state.function != null
                      ? {
                          value: FunctionEnum[this.state.function].key,
                          label: FunctionEnum[this.state.function].name,
                        }
                      : null
                  }
                  onChange={(event) => {
                    let r = event.value;
                    this.setState({ function: r });
                  }}
                  options={FunctionEnum.toArray().map((v) => {
                    return { value: v.key, label: v.name };
                  })}
                />
              </div>
              <div className={"col-4"}>
                <Select
                  className={"emr-white-select-container"}
                  classNamePrefix={"emr-white-select"}
                  placeholder={"Result"}
                  value={
                    this.state.result != null
                      ? {
                          value: ResultEnum[this.state.result].key,
                          label: ResultEnum[this.state.result].name,
                        }
                      : null
                  }
                  onChange={(event) => {
                    let r = event.value;
                    this.setState({ result: r });
                  }}
                  options={ResultEnum.toArray().map((v) => {
                    return { value: v.key, label: v.name };
                  })}
                />
              </div>
              <div className={"col-3"}>
                <TextInput
                  value={this.state.detail || ""}
                  placeholder={"Details"}
                  onChange={(value) => this.setState({ detail: value })}
                  style={{ fontSize: "14px", width: "100%" }}
                />
              </div>
              <div
                className={"col-1"}
                style={{ flexFlow: "row-reverse", display: "flex" }}
              >
                <Button
                  className={"emr-plus-button"}
                  variant="success"
                  disabled={
                    this.state.function == null || this.state.result == null
                  }
                  style={{ fontSize: "12px" }}
                  onClick={this.addFunctionalTesting}
                >
                  <FontAwesomeIcon icon={["fas", "plus"]} />
                </Button>
              </div>
            </div>
            <br />
            <div
              className={"row no-margin"}
              style={{ width: "100%", marginTop: "10px !important" }}
            >
              <div className={"div-list-wrapper"}>{functionalTestingDivs}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  addFunctionalTesting = () => {
    this.createFunctionalTestingIfNecessary();
    this.props.assessment.functionalTesting.items.push({
      function: this.state.function,
      result: this.state.result,
      detail: this.state.detail,
    });
    this.setState({
      function: null,
      result: null,
      detail: null,
    });
  };

  createFunctionalTestingIfNecessary() {
    if (this.props.assessment.functionalTesting == null) {
      this.props.assessment.functionalTesting = {};
      this.props.assessment.functionalTesting.items = [];
    }
  }

  getFunctionalTestingDivs = () => {
    if (
      this.props.assessment.functionalTesting == null ||
      this.props.assessment.functionalTesting.length == 0
    ) {
      return;
    }
    let divs = [];
    this.props.assessment.functionalTesting.items.forEach((item, index) =>
      divs.push(
        <div className={"div-list-item"} key={"functionalTestingItem" + index}>
          <div>
            {FunctionEnum[item.function].name}, {ResultEnum[item.result].name}
            {StringUtil.isNullOrEmpty(item.detail) ? "" : ", " + item.detail}
          </div>
          <Button
            className={"emr-transparent-delete-button"}
            variant="warning"
            style={{ fontSize: "10px", marginLeft: "2px" }}
            onClick={() => {
              this.props.assessment.functionalTesting.items.splice(index, 1);
              this.forceUpdate();
            }}
          >
            <FontAwesomeIcon icon={["fas", "times"]} />
          </Button>
        </div>
      )
    );
    return divs;
  };
}
