import React, {Component} from "react";
import "../../VisitHistory.css"
import StringUtil from "../../../../../../utils/StringUtil";

export default class LabImagingViewHistory extends Component {
    render() {
        let divs = [];

        if (this.props.labsImaging == null || this.checkLabImagingDataAvailability(this.props.labsImaging)) {
            return <div className="pl-3">
                {this.props.labsImaging.none ? "No Labs/Imaging" : "No information available"}
            </div>;
        }

        let li = this.props.labsImaging;
        divs.push(
            <div key={"labImagingViewHistory"}>
                <div className={"display-flex"} style={{margin: "3px"}}>
                    <div className={"col-sm-3 no-padding dim14-form-label"}>Lab:</div>
                    <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>{li.labs === true
                        ? "Yes" : "No"}{StringUtil.isNullOrEmpty(li.labDetail) ? "" : (", " + li.labDetail)}</div>
                </div>

                <div className={"display-flex"} style={{margin: "3px"}}>
                    <div className={"col-sm-3 no-padding dim14-form-label"}>Radiology:</div>
                    <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>{li.radiology === true
                        ? "Yes" : "No"}{StringUtil.isNullOrEmpty(li.radiologyDetail) ? "" : (", " + li.radiologyDetail)}</div>
                </div>

                <div className={"display-flex"} style={{margin: "3px"}}>
                    <div className={"col-sm-3 no-padding dim14-form-label"}>Report Present:</div>
                    <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>{li.reportStatus.includes("REPORT_PRESENT") ? "Yes" : "No"}</div>
                </div>

                <div className={"display-flex"} style={{margin: "3px"}}>
                    <div className={"col-sm-3 no-padding dim14-form-label"}>Report Scanned:</div>
                    <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>{li.reportStatus.includes("REPORT_SCANNED") ? "Yes" : "No"}</div>
                </div>
                {!StringUtil.isNullOrEmpty(li.reportDetail) &&
                <div className={"display-flex"} style={{margin: "3px"}}>
                    <div className={"col-sm-3 no-padding dim14-form-label"}>Report Detail:</div>
                    <div className={"col-sm-9 no-padding overflow-wrap-anywhere"}>{li.reportDetail}</div>
                </div>
                }
            </div>
        );

        return (
            <div className={"visit-history-multiple-content-wrapper pl-3"}>
                {divs}
            </div>
        );
    }

    checkLabImagingDataAvailability (labImaging) {
        return labImaging.labs == null && labImaging.labDetail == null
            && labImaging.radiology == null && labImaging.radiologyDetail == null
            && labImaging.reportStatus.length === 0 && labImaging.reportDetail == null;
    }
}