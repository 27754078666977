import Enum from "../../../../../../enums/Enum";

const MuscleGroupsEnum = new Enum({
    Knee_Extensors: {name: "Knee Extensors"},
    Knee_Flexors: {name: "Knee Flexors/Hamstrings"},
    Hip_Flexors: {name: "Hip Flexors"},
    Hip_Extenders: {name: "Hip Extenders"},
    Hip_Adductors: {name: "Hip Adductors"},
    Hip_Abductors: {name: "Hip Abductors"},
    Elbow_Flexors: {name: "Elbow Flexors"},
    Elbow_Extensors: {name: "Elbow Extensors"},
    Supinators: {name: "Supinators"},
    Pronators: {name: "Pronators "},
    Shoulder_Abductors: {name: "Shoulder Abductors"},
    Shoulder_Adductors: {name: "Shoulder Adductors"},
    Shoulder_Flexors: {name: "Shoulder Flexors"},
    Shoulder_Extensors: {name: "Shoulder Extensors"},
    Shoulder_External_Rotators: {name: "Shoulder External Rotators"},
    Shoulder_Internal_Rotators: {name: "Shoulder Internal Rotators"},
    Facial_Expression_Muscles: {name: "Facial Expression Muscles"},
    Mastication_Muscles: {name: "Mastication Muscles"},
    Extrinsic_Tongue_Muscles: {name: "Extrinsic Tongue Muscles"},
    Intrinsic_Tongue_Muscles: {name: "Intrinsic Tongue Muscles"},
    Swallowing_Muscles: {name: "Swallowing Muscles"},
    Breathing_Muscles: {name: "Breathing Muscles"},
    Abdominal_Muscles: {name: "Abdominal Muscles"},
    Pelvic_Diaphgram: {name: "Pelvic Diaphgram"},
    Urogenital_Diaphgram: {name: "Urogenital Diaphgram "},
    Superficial_Perineal_Space: {name: "Superficial Perineal Space"},
    Anterior_Thorax: {name: "Anterior Thorax"},
    Posterior_Thorax: {name: "Posterior Thorax"},
    Thenar_Muscles: {name: "Thenar Muscles"},
    Hypothenar_Muscles: {name: "Hypothenar Muscles"},
    Mid_Palmar_Muscles: {name: "Mid-palmar Muscles"},
    Thigh_Adductors: {name: "Thigh Adductors"},
    Thigh_Abductors: {name: "Thigh Abductors"},
    Hip_Lateral_Rotators: {name: "Hip Lateral Rotators"},
    Hip_Medial_Rotators: {name: "Hip Medial Rotators"},
    Dorsiflexors: {name: "Dorsiflexors"},
    Plantarflexors: {name: "Plantarflexors "},
    Sole_of_Foot: {name: "Sole of Foot"},
    Toe_Extensors: {name: "Toe Extensors"},
    Toe_Flexors: {name: "Toe Flexors"},
    Dorsum_of_Foot: {name: "Dorsum of Foot"},
    Rotator_Cuff: {name: "Rotator Cuff "},
    Neck_Flexors: {name: "Neck Flexors"},
    Neck_Rotators: {name: "Neck Rotators"},
    Neck_Extension: {name: "Neck Extension"},
    Neck_Side_Bending: {name: "Neck Side Bending"},
    Back_Extensors: {name: "Back Extensors"},
    Back_Rotators: {name: "Back Rotators"},
    Scapula_Elevators: {name: "Scapula Elevators"},
    Scapula_Depressors: {name: "Scapula Depressors"},
    Wrist_Extension: {name: "Wrist Extension"},
    Wrist_Flexion: {name: "Wrist Flexion"},
    Finger_Flexors: {name: "Finger Flexors"},
    Finger_Extensors: {name: "Finger Extensors"},
    Finger_Abductors: {name: "Finger Abductors"},
    Finger_Adductors: {name: "Finger Adductors"},
    Foot_Eversion: {name: "Foot Eversion"},
    Foot_Inversion: {name: "Foot Inversion"},
    Toe_Extensor: {name: "Toe Extensor"},
    Toe_Abductors: {name: "Toe Abductors"},
    Toe_Adductors: {name: "Toe Adductors"},
    Big_Toe_Abductor: {name: "Big Toe Abductor"},
    Big_Toe_Adductor: {name: "Big Toe Adductor "},
    Big_Toe_Extensor: {name: "Big Toe Extensor"},
    Big_Toe_Flexor: {name: "Big Toe Flexor"},
    Jaw_Elevators: {name: "Jaw Elevators"},
    Jaw_Depressors: {name: "Jaw Depressors"},
    Jaw_Protractors: {name: "Jaw Protractors"},
    Jaw_Retractors: {name: "Jaw Retractors"},
});

export default MuscleGroupsEnum;