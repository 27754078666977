import Enum from "../../../../../../enums/Enum";

const GlideEnum = new Enum({
    Anteroposterior: {name: "Anteroposterior"},
    Posteroanterior: {name: "Posteroanterior"},
    Longitudinal_Caudal: {name: "Longitudinal Caudal"},
    Longitudinal_Cephalad: {name: "Longitudinal Cephalad"},
    Joint_Distraction: {name: "Joint Distraction"},
    Medial_Glide: {name: "Medial Glide"},
    Lateral_Glide: {name: "Lateral Glide"},
});

export default GlideEnum;