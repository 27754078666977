import React, {Component} from "react";
import '../../EMR.css';
import TimelinePeriodEnum from "../enums/goal/TimelinePeriodEnum";
import ProgressEnum from "../enums/goal/ProgressEnum";
import ShortTermGoalEnum from "../enums/goal/ShortTermGoalEnum";
import TextareaInput from "../../../../../components/textarea-input/TextareaInput";

export default class TreatmentGoalsView extends Component {

    state = {};

    render() {
        const goal = this.props.treatment.goal;

        if (!goal || goal == null) {
            return null;
        }

        const hasDetails = goal.details;
        const hasSpecialRemarks = goal.specialRemarks;
        const hasShortGoals = goal.shortTermItems && goal.shortTermItems.length != 0;
        const hasLongGoals = goal.longTermItems && goal.longTermItems.length != 0;

        return (
            <div className={"content-row emr-background"} style={{width: "98%"}}>
                <div style={{marginBottom: "5px",width: "98%"}}>
                    {hasDetails &&
                    <>
                        <div className={"row"}>
                            <h6 className={"col-6 font-weight-bold pl-1"}>
                                Patient Goal
                            </h6>
                        </div>
                        <div className={"row"}>
                            <div className={"col-12 "}>
                                <TextareaInput
                                    value={goal && goal.details || ''}
                                    disabled={true}
                                    style={{fontSize: "14px", width: "100%", minHeight: "30px",
                                        background: "white", cursor: "pointer", resize: "none"}}
                                />
                            </div>
                        </div>
                    </>
                    }
                    {hasShortGoals &&
                    <div className={"row"}>
                        <div className={"col-12"}>
                            {this.renderGoalsTable('short')}
                        </div>
                    </div>
                    }
                    {hasLongGoals &&
                    <div className={"row flex-column"}>
                        <div className={"col-12"}>
                            {this.renderGoalsTable('long')}
                        </div>
                    </div>
                    }
                    {hasSpecialRemarks &&
                    <>
                        <div className={"row mt-4"}>
                            <h6 className={"col-6 font-weight-bold pl-1"}>
                                Special Remarks
                            </h6>
                        </div>
                        <div className={"row mt-1"}>
                            <div className={"col-12 "}>
                                <TextareaInput
                                    value={goal && goal.specialRemarks || ''}
                                    disabled={true}
                                    style={{fontSize: "14px", width: "100%", minHeight: "30px",
                                        background: "white", cursor: "pointer", resize: "none"}}
                                />
                            </div>
                        </div>
                    </>
                    }
                </div>

            </div>
        );
    }

    renderGoalsTable = (goalType) => {
        const forShortGoals = 'short' === goalType;
        const goalItems = forShortGoals ? this.props.treatment.goal.shortTermItems : this.props.treatment.goal.longTermItems;

        return (
            <div className={"content-card m-0"}>
                <table className={"emr-table"}>
                    <tbody>
                    <tr>
                        <th>Area of Focus</th>
                        <th>{forShortGoals ? 'Short' : 'Long'} Term Goals</th>
                        <th>Target</th>
                        <th colSpan="2">Timeline</th>
                        <th>%Ach</th>
                        <th>Progress</th>
                    </tr>
                    {goalItems.map((item, index) => {
                        return (<tr key={"shortTermGoalItem" + index}
                                    style={{backgroundColor: "white"}}>
                            <td>{item.areaOfConcern}</td>
                            <td style={{maxWidth: '150px'}}>{item.shortTermGoal ?
                            (ShortTermGoalEnum[item.shortTermGoal] ? ShortTermGoalEnum[item.shortTermGoal].name : item.shortTermGoal) : item.longTermGoal}</td>
                            <td>{item.target ? `${item.target}%` : ''}</td>
                            <td>{item.duration}</td>
                            <td>{item.period && TimelinePeriodEnum[item.period].name}</td>
                            <td>{item.achieved ? `${item.achieved}%`: ''}</td>
                            <td>{item.progress && ProgressEnum[item.progress].name}</td>
                        </tr>)
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
}
