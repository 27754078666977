import React, {Component} from "react";
import _uniqueId from "lodash/uniqueId";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar";
import ToolbarButton from "@material-ui/pickers/_shared/ToolbarButton";
import {makeStyles} from '@material-ui/core';
import moment from "moment";
import "./DateInput.css";
import MomentUtils from "@date-io/moment";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const CustomToolbar = function (props) {

    const {date, isLandscape, openView, setOpenView, title} = props;

    const classes = makeStyles({
        toolbar: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly"
        }
    })();

    const handleChangeViewClick = (view) => (e) => {
        setOpenView(view);
    };

    return (
        <PickerToolbar className={`dim14-datepicker-toolbar ${classes.toolbar}`} title={title}
                       isLandscape={isLandscape}>
            <ToolbarButton
                onClick={handleChangeViewClick("month")}
                variant="h6"
                label={date.format("MMM")}
                selected={openView === "month"}
                className="dim14-datepicker-month"
            />
            <ToolbarButton
                onClick={handleChangeViewClick("year")}
                variant="h6"
                label={date.format("YYYY")}
                selected={openView === "year"}
                className="dim14-datepicker-year"
            />
        </PickerToolbar>
    );
};

export default class DateInput extends Component {

    static propTypes = {
        id: PropTypes.string,
        variant: PropTypes.string,
        dateFormat: PropTypes.string,
        utcTime: PropTypes.bool
    }

    static defaultProps = {
        id: _uniqueId("dim14-date-id-"),
        variant: "inline",
        dateFormat: "DD-MMM-YYYY",
        utcTime: true
    }

    constructor(props) {
        super(props);
    }

    handleOnChange(date) {
        const {onChange, utcTime} = this.props;
        onChange(utcTime ? moment(date).utc(true) : moment(date));
    }

    handleOnClear() {
        const {onChange} = this.props;
        onChange(null);
    }

    render() {
        const {id, dateFormat, variant, onChange,clearable, ...rest} = this.props;
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                    id={id}
                    className="dim14-datepicker"
                    autoOk="true"
                    format={dateFormat}
                    inputVariant="outlined"
                    variant={variant}
                    ToolbarComponent={CustomToolbar}
                    onChange={date => this.handleOnChange(date)}
                    {...rest}
                />
                {(clearable && this.props.value) ?
                    <FontAwesomeIcon className="dim14-calendar-clear-icon"
                                     icon={["fas", "times"]}
                                     size={"sm"}
                                     onClick={(event) => {this.handleOnClear()}}/> : null}
            </MuiPickersUtilsProvider>);
    }
}
