import Enum from "../../../../enums/Enum";

const CommunicationModeEnum = new Enum({

    PHONE_CALL: {name: "Phone Call"},
    WHATSAPP: {name: "Whatsapp"},
    EMAIL: {name: "Email"},
    IN_PERSON: {name: "In-Person"},
    CORRECTION: {name: "Correction"},
    ADDITIONAL: {name: "Additional Information"},
    OTHER: {name: "Other"}
});

export default CommunicationModeEnum;
