import React, {Component} from "react";
import '../../../EMR.css';
import DateUtil from "../../../../../../utils/DateUtil";
import ReferralSpecialityEnum from "../../../treatment/enums/dischargeplan/ReferralSpecialityEnum";
import ReasonForDiscontinuationEnum from "../../../treatment/enums/dischargeplan/ReasonForDiscontinuationEnum";

export default class DischargePlanHistoryView extends Component {

    render() {
        const plan = this.props.dischargePlan;

        if (!plan) {
            return <div className="pl-3">No information available</div>;
        }

        return (
            <div className="pl-3">
                {plan.anticipatedDischargeDate &&
                <div className="d-flex flex-column">
                    <div><b>Discharge Readiness</b></div>
                    <div>Anticipated Date of Discharge: {DateUtil.formatDate(plan.anticipatedDischargeDate)}</div>
                    <div>{plan.anticipatedDischargeDetails}</div>
                </div>}

                {plan.dateOfDischarge && <>
                    <br/>
                    <div className="d-flex flex-column">
                        <div><b>Discharge Plan</b></div>
                        <div>Date of Discharge: {DateUtil.formatDate(plan.dateOfDischarge)}</div>
                        <div>{plan.dischargePlanDetails}</div>
                    </div>
                </>}

                {plan.dateOfDiscontinuation && <>
                    <br/>
                    <div className="d-flex flex-column">
                        <div><b>Discontinuation Plan</b></div>
                        <div>Date of Discontinuation: {DateUtil.formatDate(plan.dateOfDiscontinuation)}</div>
                        <div>{plan.discontinuationPlanDetails}</div>
                    </div>
                </>}

                {plan.reasonForDiscontinuation && <>
                    <br/>
                    <div className="d-flex flex-column">
                        <div><b>Reason for Discontinuation</b></div>
                        <div>{ReasonForDiscontinuationEnum[plan.reasonForDiscontinuation].name}</div>
                    </div>
                </>}

                {plan.goalAchieved && <>
                    <br/>
                    <div className="d-flex flex-column">
                        <div><b>Treatment Goal % Achieved</b></div>
                        {plan.goalAchieved}%{plan.goalAchievedDetails ? "; " + plan.goalAchievedDetails : null}
                    </div>
                </>}

                {plan.reasonForGoalNotAchieved && <>
                    <br/>
                    <div className="d-flex flex-column">
                        <div><b>Reason for Goal Not Achieved</b></div>
                        {plan.reasonForGoalNotAchieved}
                    </div>
                </>}

                <>
                    <br/>
                    <div className="d-flex flex-column">
                        <div><b>Instructions</b></div>
                        {plan.homeProgram && plan.homeProgram != null && plan.homeProgram.length != 0 &&
                        <div>
                            <b>Home Program: </b>
                            {plan.homeProgram.map(item => item.details).join(", ")}
                        </div>}

                        {plan.referrals && plan.referrals != null && plan.referrals.length != 0 &&
                        <div>
                            <b>Referrals: </b>
                            {plan.referrals
                                .map(item => [
                                    ReferralSpecialityEnum[item.referralSpeciality].name,
                                    item.referralName,
                                    item.referralRecommendation
                                ].filter(Boolean).join(", "))
                                .join("; ")}
                        </div>}

                        {plan.recommendations && plan.recommendations != null && plan.recommendations.length != 0 &&
                        <div>
                            <b>Recommendations: </b>
                            {plan.recommendations.map(item => item.details).join(", ")}
                        </div>}
                    </div>
                </>
            </div>
        );
    }
}
