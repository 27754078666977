import React, {Component} from 'react';
import FormField from "../../../components/form/FormField";
import {TextInput} from "../../../components";
import SignatureInput from "../../../components/signature-input/SignatureInput";
import SignatureDisplay from "../../../components/signature-input/SignatureDisplay";
import InformedConsentFormDxbTextEnum from "./enum/InformedConsentFormDxbTextEnum";

class InformedConsentSignatureInputs extends Component {
    static defaultProps = {
        textEnum: InformedConsentFormDxbTextEnum
    }

    render() {
        const {readOnly, selectedLanguage, textEnum, consentDate, patientName, providerName, guardianName,
            guardianRelationship, patientSignature, providerSignature, onSavePatientSignature, onChange,pushFormFieldRef} = this.props;

        return (
            <div className={"container"}>
                <div className={"row mb-4"}>
                    <div className="col-md-3 dim14-form-label">
                        {textEnum.Date[selectedLanguage]}
                    </div>
                    <div className="col-md-3 dim14-base-input text-center">
                        {consentDate}
                    </div>
                </div>

                <div className={"row"}>
                    <div className="col-md-3 dim14-form-label">
                        {textEnum.Patients_Name[selectedLanguage]}
                    </div>
                    <div className="col-md-3 dim14-base-input text-center">
                        {patientName}
                    </div>

                    <div className="col-md-3 dim14-form-label">
                        {textEnum.Provider_Name[selectedLanguage]}
                    </div>
                    <div className="col-md-3 dim14-base-input">
                        <FormField required requiredMessage={textEnum.Provider_Required[selectedLanguage]}
                                   validateOn={providerName}
                                   ref={ref => pushFormFieldRef(ref)}>
                            <TextInput
                                className="text-center"
                                value={providerName}
                                disabled={true}/>
                        </FormField>
                    </div>
                </div>

                <div className={"row mt-2"}>
                    <div className="col-md-3">
                        <label className="dim14-form-label">
                            {textEnum.Legal_Guardian_Name[selectedLanguage]}
                        </label>
                    </div>
                    <div className="col-md-3 ">
                        <TextInput
                            className="text-center"
                            value={guardianName}
                            disabled={readOnly} alphabeticOnly
                            onChange={(val) => onChange('guardianName', val)}/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-md-3"}>
                        <label className="dim14-form-label">
                            {textEnum.Relationship_To_Patient[selectedLanguage]}
                        </label>
                    </div>
                    <div className={"col-md-3"}>
                        <TextInput
                            className="text-center"
                            value={guardianRelationship}
                            disabled={readOnly} alphabeticOnly
                            onChange={(val) => onChange('guardianRelationship', val)}/>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-md-6 dim14-form-label">
                        {textEnum.Signature_of_Patient[selectedLanguage]}
                    </div>
                    <div className="col-md-6 dim14-form-label">
                        {textEnum.Signature_of_Provider[selectedLanguage]}
                    </div>

                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormField ref={(ref) => pushFormFieldRef(ref)}
                                   disabled={readOnly}
                                   required requiredMessage={textEnum.Signature_Required[selectedLanguage]}
                                   validateOn={patientSignature}>
                            <SignatureInput
                                selectedLanguage={selectedLanguage}
                                textEnum={textEnum}
                                signature={patientSignature}
                                onSave={onSavePatientSignature} readOnly={readOnly}/>
                        </FormField>
                    </div>
                    <div className="col-md-6">
                        <FormField ref={(ref) => pushFormFieldRef(ref)}
                                                          disabled={readOnly}
                                                          required requiredMessage={textEnum.Signature_Required[selectedLanguage]}
                                                          validateOn={providerSignature}>
                           <SignatureDisplay
                               signature={providerSignature}/>
                        </FormField>
                    </div>
                </div>
            </div>
        );
    }
}

export default InformedConsentSignatureInputs;
