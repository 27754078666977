import Enum from "../../../enums/Enum";

const DirectBillingStatus = new Enum({
    PENDING: {name: "Pending"},
    DENIED: {name: "Denied"},
    APPROVED: {name: "Approved"},
    COMPLETED: {name: "Completed"}
});

export default DirectBillingStatus;
