import React, {Component} from "react";
import AuthService from "../../../../../services/auth-service/AuthService";
import DateUtil from "../../../../../utils/DateUtil";
import {Button, Image} from "react-bootstrap";
import TextareaInput from "../../../../../components/textarea-input/TextareaInput";
import RemotingService from "../../../../../services/remoting-service/RemotingService";
import {Divider} from "@material-ui/core";
import NotificationService from "../../../../../services/notification-service/NotificationService";

export default class AddCommentModal extends Component{

    constructor(props) {
        super(props);
    }

    state = {
        activeComment: {
            addedBy: AuthService.getUser().name,
            logDate: DateUtil.now()
        },
        photoValue: null,
        newPhotoValue: null
    }

    componentDidMount() {
        RemotingService.getRemoteCall('api/staff/' + this.props.staffId + '/photo', null, (dto) => {
            this.setState({photoValue: dto, newPhotoValue: dto});
        });
    }

    render() {
        let photoSrc = process.env.PUBLIC_URL + '/assets/user.png';
        if (this.state.photoValue && this.state.photoValue.content) {
            photoSrc = 'data:image/jpeg;base64,' + this.state.photoValue.content;
        }

        return(
            <div className={"CommentModal"}>
                <table>
                    <tbody>
                        {this.props.comments.map((comment, index) => {
                            return(
                                <div style={{margin: '10px'}}>
                                    <tr>
                                        <span style={{fontSize: 12}}>{DateUtil.formatDateTimeSeparately(comment.logDate)}</span>
                                    </tr>
                                    <tr>
                                        <span style={{fontSize: 14, fontWeight: "bold"}}>{comment.addedBy}</span>
                                        <span style={{fontSize: 14}}>: {comment.comment}</span>
                                    </tr>
                                </div>
                            )
                        })}
                    </tbody>
                </table>
                <Divider/>
                <div>
                    <div className={"row"} style={{maxHeight: "35px", margin: "15px"}}>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Image width='40px' rounded src={photoSrc} onmouseover='' style={{cursor: 'pointer'}}/>
                        </div>
                        <TextareaInput
                            autoFocus="true"
                            value={this.state.activeComment?.comment}
                            onChange={(value) => {
                                this.setState((state) => {
                                    if(state.activeComment) {
                                        state.activeComment.comment = value;
                                    }
                                    return state;
                                });
                            }}
                            maxLength={2000}
                            style={{fontSize: "13px", width: "80%", maxHeight: "30px", marginTop: "5px",
                                background: "white", cursor: "pointer", resize: "none", marginLeft: "5px"}}
                        />
                        <Button variant="success" style={{maxHeight: "35px", marginLeft: "5px", marginTop: "5px"}} onClick={() => {
                            if(this.state.activeComment.comment == null){
                                NotificationService.showNotification({
                                    severity: 'error',
                                    summary: 'Validation Error',
                                    detail: 'Please enter comment.'
                                });
                                return;
                            }
                            this.props.close();
                            this.props.saveCommentModal(this.state.activeComment)}}>Add</Button>
                    </div>
                </div>
            </div>
        );
    }

}
