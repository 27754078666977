import Enum from "../../../../enums/Enum";

const VisitFormTypeEnum = new Enum({
    DOUBLE_SESSION_REQUEST_FORM: {name: "Double Session Request Form", English: true, Arabic: false},
    ULTRASOUND_DIAGNOSTIC_FORM: {name: "Ultrasound Diagnostic Form", English: true, Arabic: false},
    PERINEAL_PHYSIOTHERAPY_CONSENT_FORM: {name: "Perineal Physiotherapy Consent Form", English: true, Arabic: false},
    IV_INFUSION_CONSENT_FORM: {name: "IV Infusion Consent Form", English: true, Arabic: false},
    MEDICAL_PHOTOGRAPHY_CONSENT_FORM: {name: "Consent to Medical Photography", English: true, Arabic: true},
    INJECTION_CONSENT_FORM: {name: "Injection Consent Form", English: true, Arabic: true},
    SHOCKWAVE_THERAPY_CONSENT_FORM: {name: "Shockwave Therapy Consent Form", English: true, Arabic: false},
});

export default VisitFormTypeEnum;
