import React, {Component} from "react";
import TreatmentInfoUtils from "../../../treatment/utils/TreatmentInfoUtils";
import TreatmentInfoEnum from "../../../treatment/modals/TreatmentInfoEnum";
import DiagnosisGroup from "../../../treatment/views/DiagnosisGroup";
import TreatmentReceivedEnum from "../../../treatment/enums/treatmentrecieved/TreatmentReceivedEnum";
import DateUtil from "../../../../../../utils/DateUtil";

export default class TreatmentInfoHistoryView extends Component {

    render() {
        if (!this.props.treatmentInfo) {
            return null;
        }

        const tables = [
            this.treatmentInfoTypeATable(TreatmentReceivedEnum.MANIPULATION),
            this.treatmentInfoTypeATable(TreatmentReceivedEnum.MOBILIZATION),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.STRETCHING),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.DRY_NEEDLING),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.ACUPUNCTURE),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.ELECTROTHERAPY),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.SHOCKWAVE),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.CUPPING),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.TAPING),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.THERAPEUTIC_MASSAGE),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.LYMPHATIC),
            this.treatmentInfoTypeATable(TreatmentReceivedEnum.THERMAL),
            this.treatmentInfoTypeCTable(TreatmentReceivedEnum.THERAPEUTIC_EXERCISE),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.GAIT),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.BREATHING),
            this.props.type === TreatmentInfoEnum.TREATMENT_RECEIVED && this.treatmentReceivedProcedureTable(),
            this.props.type === TreatmentInfoEnum.TREATMENT_PLAN && this.treatmentPlanProcedureTable(),
            this.treatmentInfoTypeBTable(TreatmentReceivedEnum.OTHER)
        ].filter(table => !!table);

        return (
            <div className="container" style={{maxWidth: '100%'}}>
                <div className="row">
                    {
                        tables.map((table, index) => {
                                return <>
                                    <div className={index === (tables.length - 1) ? "col-6" : "col"}>
                                        {table}
                                    </div>
                                    {(index + 1) % 2 === 0 && <div className="w-100"></div>}
                                </>;
                            }
                        )
                    }
                </div>
            </div>
        )
    }

    renderTreatmentReceivedCptList(treatmentType) {
        return this.props.type === TreatmentInfoEnum.TREATMENT_RECEIVED &&
            this.props.procedureList?.filter(cpt => cpt.treatments?.includes(treatmentType.key)).map((procedure) =>
                <>
                    <div className="row no-margin w-100">
                        <div className="col-8"><span className="font-weight-bold">Cpt Code:</span>
                            &nbsp;{procedure.cpt.cptCode} - {procedure.cpt.description}
                        </div>
                        <div className="col-2"><span className="font-weight-bold">Start:</span>
                            &nbsp;{DateUtil.getHourMinute24H(procedure.startTime)}
                        </div>
                        <div className="col-2"><span className="font-weight-bold">End:</span>
                            &nbsp;{DateUtil.getHourMinute24H(procedure.endTime)}
                        </div>
                    </div>
                </>
            )
    }

    treatmentInfoTypeATable = (treatmentType) => {
        const field = treatmentType.field
        const dropdownEnum = treatmentType.enum
        const fieldVal = this.props.treatmentInfo && this.props.treatmentInfo[field];

        if (fieldVal == null || fieldVal.length === 0) {
            return null;
        }

        return <div className="pt-2">
            <h5 className="font-weight-bold pl-2">{treatmentType.name}</h5>
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Type</th>
                    <th>Area of Focus</th>
                    <th>Details</th>
                </tr>
                {
                    fieldVal.map((item, index) => {
                            return (
                                <tr key={field + index} style={{backgroundColor: "white"}}>
                                    <td>{dropdownEnum[item.type].name}</td>
                                    <td>{item.areaOfFocus}</td>
                                    <td>{item.details}</td>
                                </tr>);
                        }
                    )
                }
                </tbody>
            </table>
            {this.renderTreatmentReceivedCptList(treatmentType)}
        </div>
    }

    treatmentInfoTypeBTable = (treatmentType) => {
        const field = treatmentType.field
        const fieldVal = this.props.treatmentInfo && this.props.treatmentInfo[field];

        if (fieldVal == null || fieldVal.length === 0) {
            return null;
        }

        return <div className="pt-2">
            <h5 className="font-weight-bold pl-2">{treatmentType.name}</h5>
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Area of Focus</th>
                    <th>Details</th>
                </tr>
                {
                    fieldVal.map((item, index) => {
                            return (
                                <tr key={field + index} style={{backgroundColor: "white"}}>
                                    <td>{item.areaOfFocus}</td>
                                    <td>{item.details}</td>
                                </tr>);
                        }
                    )
                }
                </tbody>
            </table>
            {this.renderTreatmentReceivedCptList(treatmentType)}
        </div>
    }

    treatmentInfoTypeCTable = (treatmentType) => {
        const field = treatmentType.field
        const dropdownEnum = treatmentType.enum
        const fieldVal = this.props.treatmentInfo && this.props.treatmentInfo[field];

        if (fieldVal == null || fieldVal.length === 0) {
            return null;
        }

        return <div className="pt-2">
            <h5 className="font-weight-bold pl-2">{treatmentType.name}</h5>
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Focus</th>
                    <th>Exercise</th>
                    <th>Weight</th>
                    <th>Sets</th>
                    <th>Reps</th>
                    <th>Details</th>
                </tr>
                {
                    fieldVal.map((item, index) => {
                            return (
                                <tr key={field + index} style={{backgroundColor: "white"}}>
                                    <td>{item.focus ? dropdownEnum[item.focus].name : ''}</td>
                                    <td>{item.exercise}</td>
                                    <td>{item.weight}</td>
                                    <td>{item.sets}</td>
                                    <td>{item.reps}</td>
                                    <td>{item.details}</td>
                                </tr>);
                        }
                    )
                }
                </tbody>
            </table>
            {this.renderTreatmentReceivedCptList(treatmentType)}
        </div>
    }

    treatmentPlanProcedureTable = () => {
        const title = TreatmentReceivedEnum.PROCEDURES.name
        const field = TreatmentReceivedEnum.PROCEDURES.field
        const dropdownEnum = TreatmentReceivedEnum.PROCEDURES.enum
        const fieldVal = this.props.treatmentInfo && this.props.treatmentInfo[field];

        if (fieldVal == null || fieldVal.length === 0) {
            return null;
        }

        return <div className="pt-2">
            <h5 className="font-weight-bold pl-2">{title}</h5>
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>Type</th>
                    <th>Area of Focus</th>
                    <th>Details</th>
                    <th>Number of Sessions</th>
                    <th>Approval Required</th>
                </tr>
                {
                    fieldVal.map((item, index) => {
                            return (
                                <tr key={field + index} style={{backgroundColor: "white"}}>
                                    <td>{dropdownEnum[item.type] == null ? item.type : dropdownEnum[item.type].name}</td>
                                    <td>{item.areaOfFocus}</td>
                                    <td>{item.details}</td>
                                    <td>{item.noOfSessions}</td>
                                    <td>{item.approvalRequired ? "Yes" : "No"}</td>
                                </tr>);
                        }
                    )
                }
                </tbody>
            </table>
        </div>
    }

    treatmentReceivedProcedureTable = () => {
        const title = TreatmentReceivedEnum.PROCEDURES.name
        const field = TreatmentReceivedEnum.PROCEDURES.field
        const dropdownEnum = TreatmentReceivedEnum.PROCEDURES.enum
        if (this.props.treatmentInfo[field] == null || this.props.treatmentInfo[field].length === 0) {
            return;
        }

        return (
            <div className={"content-card content-row treatment-received-card"}>
                <div className={"col-4 content-card-title"}>
                    <h6 className={"no-margin"}>{title}</h6>
                </div>
                <div className={"col-8"}>
                    <div style={{borderLeft: "1px gainsboro solid", paddingLeft: "30px"}}>
                        {this.props.treatmentInfo[field].map((item, index) => {
                            let paddingBetweenItems = this.props.treatmentInfo[field].length !== 1 &&
                            index !== this.props.treatmentInfo[field].length - 1 ? {paddingBottom: "10px"} : {};
                            return (
                                <div key={index} style={{...paddingBetweenItems}}>
                                    {item.diagnosisGroup != null && <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Diagnosis</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}><DiagnosisGroup diagnosisGroup={item.diagnosisGroup} showSecondaryItems/></div>
                                    </div>}
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Type</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{dropdownEnum[item.type] != null ? dropdownEnum[item.type].name : item.type}</div>
                                    </div>
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Area of Focus</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.areaOfFocus}</div>
                                    </div>
                                    <div className={"row no-margin"}>
                                        {TreatmentInfoUtils.getTreatmentReceivedProcedureList(item)}
                                    </div>
                                    {(item.details != null && item.details.length !== 0) &&
                                    <div className={"row no-margin"}>
                                        <div className={"col-3 font-weight-bold"}>Details</div>
                                        <div className={"col-1"}>:</div>
                                        <div className={"col-8"}>{item.details}</div>
                                    </div>}
                                </div>
                            )
                        })}
                    </div>
                </div>
                {this.renderTreatmentReceivedCptList(TreatmentReceivedEnum.PROCEDURES)}
            </div>
        )
    }

}
