import React, {Component} from "react";
import {Timer} from "../../components/timer/Timer";
import moment from "moment";

export default class ConsentFormCode extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const {code, formName, language} = this.props;

        return (
            <div
                className="display-flex flex-column text-center"
                style={{paddingTop: "10px"}}
            >
                <div style={{fontSize: 15}}>
                    <b>{formName}</b>
                </div>
                <div
                    style={{
                        letterSpacing: "10px",
                        fontSize: 17,
                        fontWeight: "bold",
                    }}
                >
                    {code}
                </div>
                <div style={{fontSize: 11}}>
                    <Timer end={moment().add(5, "minute")}/>
                </div>
                <div>&nbsp;</div>
                <div style={{fontSize: 11, fontWeight: "bold"}}>
                    Please open <a target="_blank" href={language ? `/forms?language=${language.key}` : "/forms"}> forms </a> on tablet.
                </div>
            </div>
        );
    }

}
