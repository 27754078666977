import React, {Component} from "react";
import '../../EMR.css';
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";
import StatefulTextInput from "../../../../../components/text-input/StatefulTextInput";
import MovementEnum from "../enums/rangeofmotion/MovementEnum";
import QualityEnum from "../enums/rangeofmotion/QualityEnum";
import {NumberInput} from "../../../../../components";
import EndFeelEnum from "../enums/rangeofmotion/EndFeelEnum";
import JointEnum from "../enums/rangeofmotion/JointEnum";
import GlideEnum from "../enums/rangeofmotion/GlideEnum";
import LocationEnum from "../enums/rangeofmotion/LocationEnum";
import ResultEnum from "../enums/rangeofmotion/ResultEnum";
import MuscleGroupsEnum from "../enums/rangeofmotion/MuscleGroupsEnum";
import GradeEnum from "../enums/rangeofmotion/GradeEnum";
import IndividualMusclesEnum from "../enums/rangeofmotion/IndividualMusclesEnum";
import CheckboxInput from "../../../../../components/checkbox-input/CheckboxInput";
import GreenSwitchWithMessage from "../../../../../components/switch/GreenSwitchWithMessage";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import AddButton from "../../common/table/AddButton";
import RemoveButton from "../../common/table/RemoveButton";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import Tooltip from "../../../../../components/tooltip/Tooltip";
import FormField from "../../../../../components/form/FormField";
import MuscleStrengthResultEnum from "../enums/rangeofmotion/MuscleStrengthResultEnum";

export default class RangeOfMotionModal extends Component {

    state = {
        jointIntegrityItem: {},
        muscleGroupItem: {},
        individualMuscleItem: {},
        movement: null,
        movementComment: null
    }

    constructor(props) {
        super(props);
        this.state.rangeOfMotion = this.props.assessment.rangeOfMotion;
        this.state.jointIntegrity = this.props.assessment.jointIntegrity;
        this.state.muscleGroup = this.props.assessment.muscleGroup;
        this.state.individualMuscle = this.props.assessment.individualMuscle;

        this.MINIMUM_SEARCH_LENGTH = 1;

        this.darkColumnColor = "#d2d2d2";
    }

    noOptionsMessage = () => {
        return <div>No options</div>;
    }

    getMovementOptions = (inputValue,callback) => {
        let options = [];
        options = MovementEnum.toArray().map(item => {
            if((inputValue == null || inputValue.length == 0) || item.name.toLowerCase().includes(inputValue.toLowerCase())){
                return {value: item.key, label: item.name};
            }
        }).filter(Boolean);
        options.unshift({value: inputValue, label: inputValue});
        callback(options);
    }
    render() {

        let isRangeOfMotionNone = this.state.rangeOfMotion.none || false;
        let isJointIntegrityNone = this.state.jointIntegrity.none || false;
        let isMuscleStrengthNone = this.state.muscleGroup.none || false;

        return (
            <div className={"emr-modal content-row emr-background w-100 pt-4 pb-5 px-5"}>
                <div className="w-100">
                    <div>
                        <div className="d-flex align-items-baseline">
                            <div className="content-label mb-0">Range of Motion <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                            <GreenSwitchWithMessage
                                message={this.state.rangeOfMotion.none && "Normal"}
                                checked={(this.state.rangeOfMotion != null && this.state.rangeOfMotion.items != null && this.state.rangeOfMotion.items.length !== 0)
                                || (this.state.rangeOfMotion != null && this.state.rangeOfMotion.none == true)}
                                onChange={() => {
                                    if (this.state.rangeOfMotion.items !== null || this.state.rangeOfMotion.items.length !== 0) {
                                        this.state.rangeOfMotion.items = [];
                                    }
                                    this.forceUpdate();
                                }}/>
                        </div>
                        <div className={"row m-0"}>
                            <div style={{display: "flex", marginLeft: "5px"}}>
                                <div>
                                    <CheckboxInput value={isRangeOfMotionNone}
                                                   onChange={(value) => {
                                                       if (this.state.rangeOfMotion == null) {
                                                           this.state.rangeOfMotion = {};
                                                       }
                                                       if (value) {
                                                           this.state.rangeOfMotion.items = [];
                                                       }
                                                       this.state.rangeOfMotion.none = value;
                                                       this.forceUpdate();
                                                   }}/>
                                </div>
                                <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Normal</div>
                            </div>
                        </div>
                        <div className={isRangeOfMotionNone ? "not-allowed" : ""}>
                            <div className={isRangeOfMotionNone ? "disable-pointer" : ""}>
                                <div className={"row m-0 mt-2"}>
                                    <div className={"col-3 d-flex flex-row align-items-baseline"}>
                                        <p className="font-weight-bold mr-3">Area</p>
                                        <StatefulTextInput
                                            value={this.state.area}
                                            onChange={value => {
                                                this.state.area = value;
                                                this.forceUpdate();
                                            }}
                                            placeholder={"Area"}
                                            style={{width: '-webkit-fill-available', height: "25px"}}
                                        />
                                    </div>

                                    <div className={"col-3 d-flex flex-row align-items-baseline"}>
                                        <p className="font-weight-bold ml-3 mr-3">Movement</p>
                                        <AsyncSelect
                                            className={"emr-white-select-container w-100"}
                                            classNamePrefix={"emr-white-select"}
                                            isClearable={true}
                                            placeholder={"Movement"}
                                            noOptionsMessage={this.noOptionsMessage.bind(this)}
                                            loadOptions={this.getMovementOptions}
                                            menuPlacement={"bottom"}
                                            value={this.state.movement != null || this.state.movementComment != null?
                                                (MovementEnum[this.state.movement] != null ?
                                                    {
                                                        value: MovementEnum[this.state.movement]?.key,
                                                        label: MovementEnum[this.state.movement]?.name
                                                    } :
                                                    {
                                                        value: this.state.movementComment,
                                                        label: this.state.movementComment
                                                    }) : null}
                                            onChange={(event) => {
                                                if (event == null) {
                                                    this.state.movement = null;
                                                    this.state.movementComment = null;
                                                } else if (MovementEnum[event.value] != null) {
                                                    this.state.movement = event.value;
                                                    this.state.movementComment= null;
                                                } else {
                                                    this.state.movementComment = event.value;
                                                    this.state.movement= null;
                                                }
                                                this.forceUpdate();
                                            }}
                                        />

                                        <div className={"col-1"}>
                                            <Button className={"emr-plus-button"}
                                                    variant="success"
                                                    disabled={(this.state.area == null ||this.state.area === "")
                                                    || (this.state.movement == null && this.state.movementComment == null)}
                                                    style={{fontSize: "12px"}}
                                                    onClick={this.addRangeOfMotion}>
                                                <FontAwesomeIcon icon={["fas", "plus"]}/>
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                                <div>
                                    <table className={"range-of-motion-table content-card"}>
                                        <thead>
                                        <tr>
                                            <th colSpan={7}>Left</th>
                                            <th className={"rangeOfMotionDarkColumn"}></th>
                                            <th colSpan={7}>Right</th>
                                        </tr>
                                        <tr>
                                            <th style={{color: "red", width: 50}}>ⓧ</th>
                                            <th>Details</th>
                                            <th style={{width: "115px"}}>End Feel</th>
                                            <th style={{width: "125px"}}>Quality</th>
                                            <th style={{width: "50px"}}>Pain</th>
                                            <th style={{width: "60px"}}>AROM</th>
                                            <th style={{width: "60px"}}>PROM</th>

                                            <th className={"rangeOfMotionDarkColumn"}
                                                style={{width: "120px"}}>Movement
                                            </th>

                                            <th style={{width: "60px"}}>PROM</th>
                                            <th style={{width: "60px"}}>AROM</th>
                                            <th style={{width: "50px"}}>Pain</th>
                                            <th style={{width: "125px"}}>Quality</th>
                                            <th style={{width: "115px"}}>End Feel</th>
                                            <th>Details</th>
                                            <th style={{color: "red", width: 50}}>ⓧ</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.rangeOfMotion.items.map((rowValue, index) => {
                                            return (
                                                <tr key={`${rowValue.movement?.trim()}`} style={{background: "white"}}>
                                                    <th>
                                                        <RemoveButton
                                                            onClick={() => {
                                                                this.setState((state) => {
                                                                    state.rangeOfMotion.items.splice(index, 1);
                                                                    return state;
                                                                })
                                                            }}
                                                        />
                                                    </th>
                                                    <th>
                                                        <StatefulTextInput
                                                            value={rowValue.leftDetails}
                                                            onChange={value => {
                                                                rowValue.leftDetails = value;
                                                            }}
                                                            placeholder={""}
                                                            style={{width: '-webkit-fill-available', height: "25px"}}
                                                        />
                                                    </th>
                                                    <th>
                                                        <Select
                                                            className={"emr-white-select-container"}
                                                            classNamePrefix={"emr-white-select"}
                                                            isClearable={true}
                                                            placeholder={""}
                                                            options={EndFeelEnum.toArray().map(v => {
                                                                    return {value: v.key, label: v.name}
                                                                }
                                                            )}
                                                            value={{
                                                                    value: EndFeelEnum[rowValue.leftEndFeel]?.key,
                                                                    label: EndFeelEnum[rowValue.leftEndFeel]?.name
                                                                }}
                                                            onChange={(event) => {
                                                                let r = event == null ? null : event.value;
                                                                this.setState((state) => {
                                                                    const target = state.rangeOfMotion.items[index];
                                                                    target.leftEndFeel = r;
                                                                    return state;
                                                                });
                                                            }}
                                                        />
                                                    </th>
                                                    <th>
                                                        <Select
                                                            className={"emr-white-select-container"}
                                                            classNamePrefix={"emr-white-select"}
                                                            isClearable={true}
                                                            placeholder={""}
                                                            options={QualityEnum.toArray().map(v => {
                                                                    return {value: v.key, label: v.name}
                                                                }
                                                            )}
                                                            value={{
                                                                    value: QualityEnum[rowValue.leftQuality]?.key,
                                                                    label: QualityEnum[rowValue.leftQuality]?.name
                                                                }}
                                                            onChange={(event) => {
                                                                let r = event == null ? null : event.value;
                                                                this.setState((state) => {
                                                                    const target = state.rangeOfMotion.items[index];
                                                                    target.leftQuality = r;
                                                                    return state;
                                                                });
                                                            }}
                                                        />
                                                    </th>
                                                    <th>
                                                        <FormField min={1}  max ={10} ref={formField => this.props.formFields.push(formField)}
                                                                   validateOn={this.state.rangeOfMotion.items[index].leftPain}>
                                                        <NumberInput value={rowValue.leftPain}
                                                                     placeholder={""}
                                                                     id={"leftPainInput"}
                                                                     min={"1"}
                                                                     max={"10"}
                                                                     step="1"
                                                                     style={{height: "25px", width: "40px"}}
                                                                     onChange={(value) => {
                                                                         value = Number.isInteger(Number(value)) ? value : "" ;
                                                                         this.setState(state => {
                                                                             const target = state.rangeOfMotion.items[index];
                                                                             target.leftPain = value;
                                                                             return state;
                                                                         })
                                                                     }}
                                                        />
                                                        </FormField>
                                                    </th>
                                                    <th>
                                                        <FormField min={0}  max ={360} ref={formField => this.props.formFields.push(formField)}
                                                                   validateOn={this.state.rangeOfMotion.items[index].leftAROM}>
                                                        <NumberInput value={rowValue.leftAROM}
                                                                     placeholder={""}
                                                                     id={"leftAROMInput"}
                                                                     min={"0"}
                                                                     max={"360"}
                                                                     step="1"
                                                                     style={{height: "25px", width: "50px"}}
                                                                     onChange={(value) => {
                                                                         value = Number.isInteger(Number(value)) ? value : "" ;
                                                                         this.setState(state => {
                                                                             const target = state.rangeOfMotion.items[index];
                                                                             target.leftAROM = value;
                                                                             return state;
                                                                         })
                                                                     }}
                                                        />
                                                        </FormField>
                                                    </th>
                                                    <th>
                                                        <FormField min={0}  max ={360} ref={formField => this.props.formFields.push(formField)}
                                                                   validateOn={this.state.rangeOfMotion.items[index].leftPROM}>
                                                        <NumberInput value={rowValue.leftPROM}
                                                                     placeholder={""}
                                                                     id={"leftPROMInput"}
                                                                     min={"0"}
                                                                     max={"360"}
                                                                     step="1"
                                                                     style={{height: "25px", width: "50px"}}
                                                                     onChange={(value) => {
                                                                         value = Number.isInteger(Number(value)) ? value : "" ;
                                                                         this.setState(state => {
                                                                             const target = state.rangeOfMotion.items[index];
                                                                             target.leftPROM = value;
                                                                             return state;
                                                                         })
                                                                     }}
                                                        />
                                                        </FormField>
                                                    </th>

                                                    <th className={"rangeOfMotionDarkColumn"}>{`${rowValue.area} ${rowValue.movement == null && rowValue.movementComment == null ?
                                                                                                                        "" : 
                                                                                                                            MovementEnum[rowValue.movement] != null ? 
                                                                                                                                MovementEnum[rowValue.movement].name : 
                                                                                                                                rowValue.movementComment
                                                    }`}</th>

                                                    <th>
                                                        <FormField min={0}  max ={360} ref={formField => this.props.formFields.push(formField)}
                                                                   validateOn={this.state.rangeOfMotion.items[index].rightPROM}>
                                                        <NumberInput value={rowValue.rightPROM}
                                                                     placeholder={""}
                                                                     id={"rightPROMInput"}
                                                                     min={"0"}
                                                                     max={"360"}
                                                                     step="1"
                                                                     style={{height: "25px", width: "50px"}}
                                                                     onChange={(value) => {
                                                                         value = Number.isInteger(Number(value)) ? value : "" ;
                                                                         this.setState(state => {
                                                                             const target = state.rangeOfMotion.items[index];
                                                                             target.rightPROM = value;
                                                                             return state;
                                                                         })
                                                                     }}
                                                        />
                                                        </FormField>
                                                    </th>
                                                    <th>
                                                        <FormField min={0}  max ={360} ref={formField => this.props.formFields.push(formField)}
                                                                   validateOn={this.state.rangeOfMotion.items[index].rightAROM}>
                                                        <NumberInput value={rowValue.rightAROM}
                                                                     placeholder={""}
                                                                     id={"rightAROMInput"}
                                                                     min={"0"}
                                                                     max={"360"}
                                                                     step="1"
                                                                     style={{height: "25px", width: "50px"}}
                                                                     onChange={(value) => {
                                                                         value = Number.isInteger(Number(value)) ? value : "" ;
                                                                         this.setState(state => {
                                                                             const target = state.rangeOfMotion.items[index];
                                                                             target.rightAROM = value;
                                                                             return state;
                                                                         })
                                                                     }}
                                                        />
                                                        </FormField>
                                                    </th>
                                                    <th>
                                                        <FormField min={1}  max ={10} ref={formField => this.props.formFields.push(formField)}
                                                                   validateOn={this.state.rangeOfMotion.items[index].rightPain}>
                                                        <NumberInput value={rowValue.rightPain}
                                                                     placeholder={""}
                                                                     id={"rightPainInput"}
                                                                     min={"1"}
                                                                     max={"10"}
                                                                     step="1"
                                                                     style={{height: "25px", width: "40px"}}
                                                                     onChange={(value) => {
                                                                         value = Number.isInteger(Number(value)) ? value : "" ;
                                                                         this.setState(state => {
                                                                             const target = state.rangeOfMotion.items[index];
                                                                             target.rightPain = value;
                                                                             return state;
                                                                         })
                                                                     }}
                                                        />
                                                        </FormField>
                                                    </th>
                                                    <th>
                                                        <Select
                                                            className={"emr-white-select-container"}
                                                            classNamePrefix={"emr-white-select"}
                                                            isClearable={true}
                                                            placeholder={""}
                                                            options={QualityEnum.toArray().map(v => {
                                                                    return {value: v.key, label: v.name}
                                                                }
                                                            )}
                                                            value={{
                                                                    value: QualityEnum[rowValue.rightQuality]?.key,
                                                                    label: QualityEnum[rowValue.rightQuality]?.name
                                                                }}
                                                            onChange={(event) => {
                                                                let r = event == null ? null : event.value;
                                                                this.setState((state) => {
                                                                    const target = state.rangeOfMotion.items[index];
                                                                    target.rightQuality = r;
                                                                    return state;
                                                                });
                                                            }}
                                                        />
                                                    </th>
                                                    <th>
                                                        <Select
                                                            className={"emr-white-select-container"}
                                                            classNamePrefix={"emr-white-select"}
                                                            isClearable={true}
                                                            placeholder={""}
                                                            options={EndFeelEnum.toArray().map(v => {
                                                                    return {value: v.key, label: v.name}
                                                                }
                                                            )}
                                                            value={{
                                                                    value: EndFeelEnum[rowValue.rightEndFeel]?.key,
                                                                    label: EndFeelEnum[rowValue.rightEndFeel]?.name
                                                                }}
                                                            onChange={(event) => {
                                                                let r = event == null ? null : event.value;
                                                                this.setState((state) => {
                                                                    const target = state.rangeOfMotion.items[index];
                                                                    target.rightEndFeel = r;
                                                                    return state;
                                                                });
                                                            }}
                                                        />
                                                    </th>
                                                    <th>
                                                        <StatefulTextInput
                                                            value={rowValue.rightDetails}
                                                            onChange={value => {
                                                                rowValue.rightDetails = value;
                                                            }}
                                                            placeholder={""}
                                                            style={{width: '-webkit-fill-available', height: "25px"}}
                                                        />
                                                    </th>
                                                    <th>
                                                        <RemoveButton
                                                            onClick={() => {
                                                                this.setState((state) => {
                                                                    state.rangeOfMotion.items.splice(index, 1);
                                                                    return state;
                                                                })
                                                            }}
                                                        />
                                                    </th>
                                                </tr>
                                            )
                                        })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-100">
                    <div className="d-flex align-items-baseline mt-5">
                        <div className="content-label mb-0">Joint Integrity</div>
                        <GreenSwitchWithMessage
                            message={(this.state.jointIntegrity && this.state.jointIntegrity.none) && "Normal"}
                            checked={(this.state.jointIntegrity != null && this.state.jointIntegrity.items != null && this.state.jointIntegrity.items.length !== 0) ||
                            ((this.state.jointIntegrity && this.state.jointIntegrity.none == true))}
                            onChange={() => {
                                if (this.state.jointIntegrity.items !== null || this.state.jointIntegrity.items.length !== 0) {
                                    this.state.jointIntegrity.items = [];
                                }
                                this.forceUpdate();
                            }}/>
                    </div>
                </div>
                <div className={"row m-0 w-100"}>
                    <div style={{display: "flex", marginLeft: "5px"}}>
                        <div>
                            <CheckboxInput value={isJointIntegrityNone}
                                           onChange={(value) => {
                                               if (this.state.jointIntegrity == null) {
                                                   this.state.jointIntegrity = {};
                                               }
                                               if (value) {
                                                   this.state.jointIntegrity.items = [];
                                               }
                                               this.state.jointIntegrity.none = value;
                                               this.forceUpdate();
                                           }}/>
                        </div>
                        <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Normal</div>
                    </div>
                </div>
                <div className={"w-100" + (isJointIntegrityNone ? " not-allowed" : "")}>
                    <div className={isJointIntegrityNone ? "disable-pointer" : ""}>
                        <table className={"emr-table content-card rounded-0 mb-2"}>
                            <thead>
                            <tr>
                                <th style={{width: "15%"}}>Joint</th>
                                <th style={{width: "5%"}}>Location</th>
                                <th style={{width: "15%"}}>Glide</th>
                                <th style={{width: "15%"}}>Result</th>
                                <th>Details</th>
                                <th style={{color: "red", width: 50}}>ⓧ</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.jointIntegrity.items.map((rowValue, index) => {
                                return (
                                    <tr>
                                        <td style={{minWidth: "80px", padding: "5px"}}>
                                            <Select
                                                style={{textAlign:"left"}}
                                                className={"emr-white-select-container"}
                                                classNamePrefix={"emr-white-select"}
                                                isClearable={true}
                                                placeholder={""}
                                                menuPlacement="bottom"
                                                options={JointEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                                value={{
                                                        value: JointEnum[rowValue.joint]?.key,
                                                        label: JointEnum[rowValue.joint]?.name
                                                    }}
                                                onChange={(event) => {
                                                    let r = event == null ? null : event.value;
                                                    this.setState((state) => {
                                                        const target = rowValue;
                                                        target.joint = r;
                                                        return state;
                                                    });
                                                }}
                                            />
                                        </td>
                                        <td style={{padding: "5px"}}>
                                            <Select
                                                className={"emr-white-select-container"}
                                                classNamePrefix={"emr-white-select"}
                                                isClearable={true}
                                                placeholder={""}
                                                options={LocationEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                                value={{
                                                        value: LocationEnum[rowValue.location]?.key,
                                                        label: LocationEnum[rowValue.location]?.name
                                                    }}
                                                onChange={(event) => {
                                                    let r = event == null ? null : event.value;
                                                    this.setState((state) => {
                                                        const target = rowValue;
                                                        target.location = r;
                                                        return state;
                                                    });
                                                }}
                                            />
                                        </td>
                                        <td style={{padding: "5px"}}>
                                            <Select
                                                className={"emr-white-select-container"}
                                                classNamePrefix={"emr-white-select"}
                                                isClearable={true}
                                                placeholder={""}
                                                options={GlideEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                                value={{
                                                        value: GlideEnum[rowValue.glide]?.key,
                                                        label: GlideEnum[rowValue.glide]?.name
                                                    }}
                                                onChange={(event) => {
                                                    let r = event == null ? null : event.value;
                                                    this.setState((state) => {
                                                        const target = rowValue;
                                                        target.glide = r;
                                                        return state;
                                                    });
                                                }}
                                            />
                                        </td>
                                        <td style={{padding: "5px"}}>
                                            <Select
                                                className={"emr-white-select-container"}
                                                classNamePrefix={"emr-white-select"}
                                                isClearable={true}
                                                placeholder={""}
                                                options={ResultEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                                value={{
                                                        value: ResultEnum[rowValue.result]?.key,
                                                        label: ResultEnum[rowValue.result]?.name
                                                    }}
                                                onChange={(event) => {
                                                    let r = event == null ? null : event.value;
                                                    this.setState((state) => {
                                                        const target = rowValue;
                                                        target.result = r;
                                                        return state;
                                                    });
                                                }}
                                            />
                                        </td>
                                        <td style={{padding: "5px"}}>
                                            <StatefulTextInput
                                                id={"disparateReflexRightSideObjectDetails"}
                                                value={rowValue.details || ''}
                                                onChange={value => {
                                                    const target = rowValue;
                                                    target.details = value;
                                                }}
                                                placeholder={""}
                                                style={{width: '-webkit-fill-available'}}
                                            />
                                        </td>
                                        <td>
                                            <RemoveButton
                                                onClick={() => {
                                                    this.setState(state => {
                                                        state.jointIntegrity.items.splice(index, 1);
                                                        return state;
                                                    })
                                                }}
                                            />
                                        </td>
                                    </tr>
                                )
                            })}
                            <tr>
                                <td style={{minWidth: "80px", padding: "5px"}}>
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        isClearable={true}
                                        placeholder={""}
                                        menuPlacement="top"
                                        options={JointEnum.toArray().map(v => {
                                                return {value: v.key, label: v.name}
                                            }
                                        )}
                                        value={{
                                                value: JointEnum[this.state.jointIntegrityItem.joint]?.key,
                                                label: JointEnum[this.state.jointIntegrityItem.joint]?.name
                                            }}
                                        onChange={(event) => {
                                            let r = event == null ? null : event.value;
                                            this.setState((state) => {
                                                const target = this.state.jointIntegrityItem;
                                                target.joint = r;
                                                return state;
                                            });
                                        }}
                                    />
                                </td>
                                <td style={{padding: "5px"}}>
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        isClearable={true}
                                        placeholder={""}
                                        options={LocationEnum.toArray().map(v => {
                                                return {value: v.key, label: v.name}
                                            }
                                        )}
                                        value={{
                                                value: LocationEnum[this.state.jointIntegrityItem.location]?.key,
                                                label: LocationEnum[this.state.jointIntegrityItem.location]?.name
                                            }}
                                        onChange={(event) => {
                                            let r = event == null ? null : event.value;
                                            this.setState((state) => {
                                                const target = this.state.jointIntegrityItem;
                                                target.location = r;
                                                return state;
                                            });
                                        }}
                                    />
                                </td>
                                <td style={{padding: "5px"}}>
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        isClearable={true}
                                        placeholder={""}
                                        options={GlideEnum.toArray().map(v => {
                                                return {value: v.key, label: v.name}
                                            }
                                        )}
                                        value={{
                                                value: GlideEnum[this.state.jointIntegrityItem.glide]?.key,
                                                label: GlideEnum[this.state.jointIntegrityItem.glide]?.name
                                            }}
                                        onChange={(event) => {
                                            let r = event == null ? null : event.value;
                                            this.setState((state) => {
                                                const target = this.state.jointIntegrityItem;
                                                target.glide = r;
                                                return state;
                                            });
                                        }}
                                    />
                                </td>
                                <td style={{padding: "5px"}}>
                                    <Select
                                        className={"emr-white-select-container"}
                                        classNamePrefix={"emr-white-select"}
                                        isClearable={true}
                                        placeholder={""}
                                        options={ResultEnum.toArray().map(v => {
                                                return {value: v.key, label: v.name}
                                            }
                                        )}
                                        value={{
                                                value: ResultEnum[this.state.jointIntegrityItem.result]?.key,
                                                label: ResultEnum[this.state.jointIntegrityItem.result]?.name
                                            }}
                                        onChange={(event) => {
                                            let r = event == null ? null : event.value;
                                            this.setState((state) => {
                                                const target = this.state.jointIntegrityItem;
                                                target.result = r;
                                                return state;
                                            });
                                        }}
                                    />
                                </td>
                                <td style={{padding: "5px"}}>
                                    <StatefulTextInput
                                        id={"disparateReflexRightSideObjectDetails"}
                                        value={this.state.jointIntegrityItem.details || ''}
                                        onChange={value => {
                                            const target = this.state.jointIntegrityItem;
                                            target.details = value;
                                        }}
                                        placeholder={""}
                                        style={{width: '-webkit-fill-available'}}
                                    />
                                </td>
                                <td>
                                    <AddButton
                                        onClick={() => {
                                            if (Object.keys(this.state.jointIntegrityItem).length !== 0)
                                                this.addJointIntegrityItem();
                                        }}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="w-100">
                    <div className="d-flex align-items-baseline mt-5">
                        <div className="content-label mb-0">Muscle Strength <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></div>
                        <GreenSwitchWithMessage
                            message={(this.state.muscleGroup && this.state.muscleGroup.none) && "Normal"}
                            checked={(this.state.muscleGroup != null && this.state.muscleGroup.items != null && this.state.muscleGroup.items.length !== 0) ||
                            (this.state.individualMuscle != null && this.state.individualMuscle.items != null && this.state.individualMuscle.items.length !== 0)
                            || (this.state.muscleGroup && this.state.muscleGroup.none == true)}
                            onChange={() => {
                                if (this.state.muscleGroup.items !== null || this.state.muscleGroup.items.length !== 0) {
                                    this.state.muscleGroup.items = [];
                                }
                                if (this.state.individualMuscle.items !== null || this.state.individualMuscle.items.length !== 0) {
                                    this.state.individualMuscle.items = [];
                                }
                                this.forceUpdate();
                            }}/>
                    </div>
                </div>
                <div className={"row m-0 w-100"}>
                    <div style={{display: "flex", marginLeft: "5px"}}>
                        <div>
                            <CheckboxInput value={isMuscleStrengthNone}
                                           onChange={(value) => {
                                               if (this.state.muscleGroup == null) {
                                                   this.state.muscleGroup = {};
                                               }
                                               if (this.state.individualMuscle == null) {
                                                   this.state.individualMuscle = {};
                                               }
                                               if (value) {
                                                   this.state.muscleGroup.items = [];
                                                   this.state.individualMuscle.items = [];
                                               }
                                               this.state.muscleGroup.none = value;
                                               this.state.individualMuscle.none = value;
                                               this.forceUpdate();
                                           }}/>
                        </div>
                        <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Normal</div>
                    </div>
                </div>
                <div className={"w-100" + (isMuscleStrengthNone ? " not-allowed" : "")}>
                    <div className={isMuscleStrengthNone ? "disable-pointer" : ""}>
                        <div className={"row w-100 m-0"}>
                            <table className={"emr-table content-card rounded-0"}>
                                <thead>
                                    <tr>
                                        <th style={{width: "15%"}}>Group Muscle</th>
                                        <th style={{width: "5%"}}>Location</th>
                                        <th style={{width: "15%", background: this.darkColumnColor}}>Result</th>
                                        <th style={{width: "15%", background: this.darkColumnColor}}>Grade</th>
                                        <th style={{background: this.darkColumnColor}}>Details</th>
                                        <th style={{color: "red", width: 50}}>ⓧ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.muscleGroup.items.map((rowValue, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{minWidth: "80px", padding: "5px", textAlign:"left"}}>
                                                    <Select
                                                        style={{textAlign:"left"}}
                                                        className={"emr-white-select-container"}
                                                        classNamePrefix={"emr-white-select"}
                                                        isClearable={true}
                                                        placeholder={""}
                                                        menuPlacement="bottom"
                                                        options={MuscleGroupsEnum.toArray().map(v => {
                                                                return {value: v.key, label: v.name}
                                                            }
                                                        )}
                                                        value={{
                                                                value: MuscleGroupsEnum[rowValue.muscle]?.key,
                                                                label: MuscleGroupsEnum[rowValue.muscle]?.name
                                                            }}
                                                        onChange={(event) => {
                                                            let r = event == null ? null : event.value;
                                                            this.setState((state) => {
                                                                const target = rowValue;
                                                                target.muscle = r;
                                                                return state;
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td style={{padding: "5px"}}>
                                                    <Select
                                                        className={"emr-white-select-container"}
                                                        classNamePrefix={"emr-white-select"}
                                                        isClearable={true}
                                                        placeholder={""}
                                                        menuPlacement="top"
                                                        options={LocationEnum.toArray().map(v => {
                                                                return {value: v.key, label: v.name}
                                                            }
                                                        )}
                                                        value={{
                                                                value: LocationEnum[rowValue.location]?.key,
                                                                label: LocationEnum[rowValue.location]?.name
                                                            }}
                                                        onChange={(event) => {
                                                            let r = event == null ? null : event.value;
                                                            this.setState((state) => {
                                                                const target = rowValue;
                                                                target.location = r;
                                                                return state;
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td style={{padding: "5px", background: this.darkColumnColor}}>
                                                    <Tooltip title={rowValue.result != null ? MuscleStrengthResultEnum[rowValue.result]?.name : ""}>
                                                        <Select
                                                            className={"emr-white-select-container"}
                                                            classNamePrefix={"emr-white-select"}
                                                            isClearable={true}
                                                            placeholder={""}
                                                            menuPlacement="top"
                                                            options={MuscleStrengthResultEnum.toArray().map(v => {
                                                                    return {value: v.key, label: v.name}
                                                                }
                                                            )}
                                                            value={{
                                                                    value: MuscleStrengthResultEnum[rowValue.result]?.key,
                                                                    label: MuscleStrengthResultEnum[rowValue.result]?.name
                                                                }}
                                                            onChange={(event) => {
                                                                let r = event == null ? null : event.value;
                                                                this.setState((state) => {
                                                                    const target = rowValue;
                                                                    target.result = r;
                                                                    return state;
                                                                });
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </td>
                                                <td style={{padding: "5px", background: this.darkColumnColor}}>
                                                    <Tooltip title={rowValue.grade != null ? GradeEnum[rowValue.grade].name : ""}>
                                                        <Select
                                                            className={"emr-white-select-container"}
                                                            classNamePrefix={"emr-white-select"}
                                                            isClearable={true}
                                                            placeholder={""}
                                                            menuPlacement="top"
                                                            options={GradeEnum.toArray().map(v => {
                                                                    return {value: v.key, label: v.name}
                                                                }
                                                            )}
                                                            value={{
                                                                    value: GradeEnum[rowValue.grade]?.key,
                                                                    label: GradeEnum[rowValue.grade]?.name
                                                                }}
                                                            onChange={(event) => {
                                                                let r = event == null ? null : event.value;
                                                                this.setState((state) => {
                                                                    const target = rowValue;
                                                                    target.grade = r;
                                                                    return state;
                                                                });
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </td>
                                                <td style={{padding: "5px", background: this.darkColumnColor}}>
                                                    <StatefulTextInput
                                                        id={"disparateReflexRightSideObjectDetails"}
                                                        value={rowValue.details || ''}
                                                        onChange={value => {
                                                            const target = rowValue;
                                                            target.details = value;
                                                        }}
                                                        placeholder={""}
                                                        style={{width: '-webkit-fill-available'}}
                                                    />
                                                </td>
                                                <td style={{width: "25px"}}>
                                                    <RemoveButton
                                                        onClick={() => {
                                                            this.setState((state) => {
                                                                state.muscleGroup.items.splice(index, 1);
                                                                return state;
                                                            })
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td style={{minWidth: "80px", padding: "5px"}}>
                                            <Select
                                                className={"emr-white-select-container"}
                                                classNamePrefix={"emr-white-select"}
                                                isClearable={true}
                                                placeholder={""}
                                                menuPlacement="top"
                                                options={MuscleGroupsEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                                value={{
                                                        value: MuscleGroupsEnum[this.state.muscleGroupItem.muscle]?.key,
                                                        label: MuscleGroupsEnum[this.state.muscleGroupItem.muscle]?.name
                                                    }}
                                                onChange={(event) => {
                                                    let r = event == null ? null : event.value;
                                                    this.setState((state) => {
                                                        const target = this.state.muscleGroupItem;
                                                        target.muscle = r;
                                                        return state;
                                                    });
                                                }}
                                            />
                                        </td>
                                        <td style={{padding: "5px"}}>
                                            <Select
                                                className={"emr-white-select-container"}
                                                classNamePrefix={"emr-white-select"}
                                                isClearable={true}
                                                placeholder={""}
                                                menuPlacement="top"
                                                options={LocationEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                                value={{
                                                        value: LocationEnum[this.state.muscleGroupItem.location]?.key,
                                                        label: LocationEnum[this.state.muscleGroupItem.location]?.name
                                                    }}
                                                onChange={(event) => {
                                                    let r = event == null ? null : event.value;
                                                    this.setState((state) => {
                                                        const target = this.state.muscleGroupItem;
                                                        target.location = r;
                                                        return state;
                                                    });
                                                }}
                                            />
                                        </td>
                                        <td style={{padding: "5px", background: this.darkColumnColor}}>
                                            <Select
                                                className={"emr-white-select-container"}
                                                classNamePrefix={"emr-white-select"}
                                                isClearable={true}
                                                placeholder={""}
                                                menuPlacement="top"
                                                options={MuscleStrengthResultEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                                value={{
                                                        value: MuscleStrengthResultEnum[this.state.muscleGroupItem.result]?.key,
                                                        label: MuscleStrengthResultEnum[this.state.muscleGroupItem.result]?.name
                                                    }}
                                                onChange={(event) => {
                                                    let r = event == null ? null : event.value;
                                                    this.setState((state) => {
                                                        const target = this.state.muscleGroupItem;
                                                        target.result = r;
                                                        return state;
                                                    });
                                                }}
                                            />
                                        </td>
                                        <td style={{padding: "5px", background: this.darkColumnColor}}>
                                            <Select
                                                className={"emr-white-select-container"}
                                                classNamePrefix={"emr-white-select"}
                                                isClearable={true}
                                                placeholder={""}
                                                menuPlacement="top"
                                                options={GradeEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                                value={{
                                                        value: GradeEnum[this.state.muscleGroupItem.grade]?.key,
                                                        label: GradeEnum[this.state.muscleGroupItem.grade]?.name
                                                    }}
                                                onChange={(event) => {
                                                    let r = event == null ? null : event.value;
                                                    this.setState((state) => {
                                                        const target = this.state.muscleGroupItem;
                                                        target.grade = r;
                                                        return state;
                                                    });
                                                }}
                                            />
                                        </td>
                                        <td style={{padding: "5px", background: this.darkColumnColor}}>
                                            <StatefulTextInput
                                                id={"disparateReflexRightSideObjectDetails"}
                                                value={this.state.muscleGroupItem.details || ''}
                                                onChange={value => {
                                                    const target = this.state.muscleGroupItem;
                                                    target.details = value;
                                                }}
                                                placeholder={""}
                                                style={{width: '-webkit-fill-available'}}
                                            />
                                        </td>
                                        <td>
                                            <AddButton
                                                onClick={() => {
                                                    if (Object.keys(this.state.muscleGroupItem).length !== 0)
                                                        this.addMuscleGroupItem();
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className={"row w-100 m-0"}>
                            <table className={"emr-table content-card rounded-0"}>
                                <thead>
                                    <tr>
                                        <th style={{width: "15%"}}>Individual Muscle</th>
                                        <th style={{width: "5%"}}>Location</th>
                                        <th style={{width: "15%", background: this.darkColumnColor}}>Result</th>
                                        <th style={{width: "15%", background: this.darkColumnColor}}>Grade</th>
                                        <th style={{background: this.darkColumnColor}}>Details</th>
                                        <th style={{color: "red", width: 50}}>ⓧ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.individualMuscle.items.map((rowValue, index) => {
                                        return (
                                            <tr key={index}>
                                                <td style={{minWidth: "80px", padding: "5px"}}>
                                                    <Select
                                                        className={"emr-white-select-container"}
                                                        classNamePrefix={"emr-white-select"}
                                                        isClearable={true}
                                                        placeholder={""}
                                                        menuPlacement="top"
                                                        options={IndividualMusclesEnum.toArray().map(v => {
                                                                return {value: v.key, label: v.name}
                                                            }
                                                        )}
                                                        value={{
                                                                value: IndividualMusclesEnum[rowValue.muscle]?.key,
                                                                label: IndividualMusclesEnum[rowValue.muscle]?.name
                                                            }}
                                                        onChange={(event) => {
                                                            let r = event == null ? null : event.value;
                                                            this.setState((state) => {
                                                                const target = rowValue;
                                                                target.muscle = r;
                                                                return state;
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td style={{padding: "5px"}}>
                                                    <Select
                                                        className={"emr-white-select-container"}
                                                        classNamePrefix={"emr-white-select"}
                                                        isClearable={true}
                                                        placeholder={""}
                                                        menuPlacement="top"
                                                        options={LocationEnum.toArray().map(v => {
                                                                return {value: v.key, label: v.name}
                                                            }
                                                        )}
                                                        value={{
                                                                value: LocationEnum[rowValue.location]?.key,
                                                                label: LocationEnum[rowValue.location]?.name
                                                            }}
                                                        onChange={(event) => {
                                                            let r = event == null ? null : event.value;
                                                            this.setState((state) => {
                                                                const target = rowValue;
                                                                target.location = r;
                                                                return state;
                                                            });
                                                        }}
                                                    />
                                                </td>
                                                <td style={{padding: "5px", background: this.darkColumnColor}}>
                                                    <Tooltip title={rowValue.result != null ? MuscleStrengthResultEnum[rowValue.result].name : ""}>
                                                        <Select
                                                            className={"emr-white-select-container"}
                                                            classNamePrefix={"emr-white-select"}
                                                            isClearable={true}
                                                            placeholder={""}
                                                            menuPlacement="top"
                                                            options={MuscleStrengthResultEnum.toArray().map(v => {
                                                                    return {value: v.key, label: v.name}
                                                                }
                                                            )}
                                                            value={{
                                                                    value: MuscleStrengthResultEnum[rowValue.result]?.key,
                                                                    label: MuscleStrengthResultEnum[rowValue.result]?.name
                                                                }}
                                                            onChange={(event) => {
                                                                let r = event == null ? null : event.value;
                                                                this.setState((state) => {
                                                                    const target = rowValue;
                                                                    target.result = r;
                                                                    return state;
                                                                });
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </td>
                                                <td style={{padding: "5px", background: this.darkColumnColor}}>
                                                    <Tooltip title={rowValue.grade != null ? GradeEnum[rowValue.grade].name : ""}>
                                                        <Select
                                                            className={"emr-white-select-container"}
                                                            classNamePrefix={"emr-white-select"}
                                                            isClearable={true}
                                                            placeholder={""}
                                                            menuPlacement="top"
                                                            options={GradeEnum.toArray().map(v => {
                                                                    return {value: v.key, label: v.name}
                                                                }
                                                            )}
                                                            value={{
                                                                    value: GradeEnum[rowValue.grade]?.key,
                                                                    label: GradeEnum[rowValue.grade]?.name
                                                                }}
                                                            onChange={(event) => {
                                                                let r = event == null ? null : event.value;
                                                                this.setState((state) => {
                                                                    const target = rowValue;
                                                                    target.grade = r;
                                                                    return state;
                                                                });
                                                            }}
                                                        />
                                                    </Tooltip>
                                                </td>
                                                <td style={{padding: "5px", background: this.darkColumnColor}}>
                                                    <StatefulTextInput
                                                        id={"disparateReflexRightSideObjectDetails"}
                                                        value={rowValue.details || ''}
                                                        onChange={value => {
                                                            const target = rowValue;
                                                            target.details = value;
                                                        }}
                                                        placeholder={""}
                                                        style={{width: '-webkit-fill-available'}}
                                                    />
                                                </td>
                                                <td>
                                                    <RemoveButton
                                                        onClick={() => {
                                                            this.setState((state) => {
                                                                state.individualMuscle.items.splice(index, 1);
                                                                return state;
                                                            })
                                                        }}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    <tr>
                                        <td style={{minWidth: "80px", padding: "5px"}}>
                                            <Select
                                                className={"emr-white-select-container"}
                                                classNamePrefix={"emr-white-select"}
                                                isClearable={true}
                                                placeholder={""}
                                                menuPlacement="top"
                                                options={IndividualMusclesEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                                value={{
                                                        value: IndividualMusclesEnum[this.state.individualMuscleItem.muscle]?.key,
                                                        label: IndividualMusclesEnum[this.state.individualMuscleItem.muscle]?.name
                                                    }}
                                                onChange={(event) => {
                                                    let r = event == null ? null : event.value;
                                                    this.setState((state) => {
                                                        const target = this.state.individualMuscleItem;
                                                        target.muscle = r;
                                                        return state;
                                                    });
                                                }}
                                            />
                                        </td>
                                        <td style={{padding: "5px"}}>
                                            <Select
                                                className={"emr-white-select-container"}
                                                classNamePrefix={"emr-white-select"}
                                                isClearable={true}
                                                placeholder={""}
                                                menuPlacement="top"
                                                options={LocationEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                                value={{
                                                        value: LocationEnum[this.state.individualMuscleItem.location]?.key,
                                                        label: LocationEnum[this.state.individualMuscleItem.location]?.name
                                                    }}
                                                onChange={(event) => {
                                                    let r = event == null ? null : event.value;
                                                    this.setState((state) => {
                                                        const target = this.state.individualMuscleItem;
                                                        target.location = r;
                                                        return state;
                                                    });
                                                }}
                                            />
                                        </td>
                                        <td style={{padding: "5px", background: this.darkColumnColor}}>
                                            <Select
                                                className={"emr-white-select-container"}
                                                classNamePrefix={"emr-white-select"}
                                                isClearable={true}
                                                placeholder={""}
                                                menuPlacement="top"
                                                options={MuscleStrengthResultEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                                value={{
                                                        value: MuscleStrengthResultEnum[this.state.individualMuscleItem.result]?.key,
                                                        label: MuscleStrengthResultEnum[this.state.individualMuscleItem.result]?.name
                                                    }}
                                                onChange={(event) => {
                                                    let r = event == null ? null : event.value;
                                                    this.setState((state) => {
                                                        const target = this.state.individualMuscleItem;
                                                        target.result = r;
                                                        return state;
                                                    });
                                                }}
                                            />
                                        </td>
                                        <td style={{padding: "5px", background: this.darkColumnColor}}>
                                            <Select
                                                className={"emr-white-select-container"}
                                                classNamePrefix={"emr-white-select"}
                                                isClearable={true}
                                                placeholder={""}
                                                menuPlacement="top"
                                                options={GradeEnum.toArray().map(v => {
                                                        return {value: v.key, label: v.name}
                                                    }
                                                )}
                                                value={{
                                                        value: GradeEnum[this.state.individualMuscleItem.grade]?.key,
                                                        label: GradeEnum[this.state.individualMuscleItem.grade]?.name
                                                    }}
                                                onChange={(event) => {
                                                    let r = event == null ? null : event.value;
                                                    this.setState((state) => {
                                                        const target = this.state.individualMuscleItem;
                                                        target.grade = r;
                                                        return state;
                                                    });
                                                }}
                                            />
                                        </td>
                                        <td style={{padding: "5px", background: this.darkColumnColor}}>
                                            <StatefulTextInput
                                                id={"disparateReflexRightSideObjectDetails"}
                                                value={this.state.individualMuscleItem.details || ''}
                                                onChange={value => {
                                                    const target = this.state.individualMuscleItem;
                                                    target.details = value;
                                                }}
                                                placeholder={""}
                                                style={{width: '-webkit-fill-available'}}
                                            />
                                        </td>
                                        <td>
                                            <AddButton
                                                onClick={() => {
                                                    if (Object.keys(this.state.individualMuscleItem).length !== 0)
                                                        this.addIndividualMuscleItem();
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    addRangeOfMotion = () => {
        let rangeOfMotionRow = {};
        rangeOfMotionRow.area = this.state.area;
        rangeOfMotionRow.movement = this.state.movement;
        rangeOfMotionRow.movementComment = this.state.movementComment;
        this.setState(state => {
            state.movement = null;
            state.movementComment = null;
            state.area = "";
            state.rangeOfMotion.items.push(rangeOfMotionRow);
            return state;
        })
    }

    addJointIntegrityItem = () => {
        this.setState(state => {
            state.jointIntegrity.items.push(state.jointIntegrityItem);
            return state;
        }, () => {
            this.setState({jointIntegrityItem: {}})
        })
    }

    addMuscleGroupItem = () => {
        this.setState(state => {
            state.muscleGroup.items.push(state.muscleGroupItem);
            return state;
        }, () => {
            this.setState({muscleGroupItem: {}})
        })
    }

    addIndividualMuscleItem = () => {
        this.setState(state => {
            state.individualMuscle.items.push(state.individualMuscleItem);
            return state;
        }, () => {
            this.setState({individualMuscleItem: {}})
        })
    }

    getJointOptions = (inputValue, callback) => {
        let options = [];
        options = JointEnum.toArray().map(item => {
            if ((inputValue == null || inputValue.length == 0) || item.key.toLowerCase().includes(inputValue.toLowerCase()))
                return {value: item.key, label: item.name}
            else
                return null;
        }).filter(Boolean);
        if (options.length == 0)
            options.push({value: inputValue, label: inputValue});
        callback(options);
    }

    noOptionsMessage(input) {
        if (input.inputValue == null || input.inputValue.length < this.MINIMUM_SEARCH_LENGTH) {
            return <div>Type {this.MINIMUM_SEARCH_LENGTH} characters</div>;
        } else {
            return <div>No options</div>;
        }
    }
}