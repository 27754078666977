import React, {Component} from "react";
import '../../EMR.css';
import GreenSwitchWithEmptyMessage from "../../../../../components/switch/GreenSwitchWithEmptyMessage";
import {DateInput, NumberInput} from "../../../../../components";
import Select from "react-select";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextInput from "../../../../../components/text-input/TextInput";
import CheckboxInput from "../../../../../components/checkbox-input/CheckboxInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import ScheduledForEnum from "../enums/treatmentschedule/ScheduledForEnum";
import NextAppointmentInEnum from "../enums/treatmentschedule/NextAppointmentInEnum";
import GreenSwitchWithMessage from "../../../../../components/switch/GreenSwitchWithMessage";
import GradientSlider100Mark4Category from "../../../../../components/slider/GradientSlider100Mark4Category";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import MaterialRadioButton from "../../../../../components/radio-input/MaterialRadioButton";

export default class TreatmentScheduleModal extends Component {

    state = {}

    constructor(props) {
        super(props);
        this.state.treatmentSchedule = this.props.treatment.treatmentSchedule;
        this.state.treatmentPlanCompliance = this.props.treatment.treatmentPlanCompliance;
        this.state.riskOfHarmFromTreatment = this.props.treatment.riskOfHarmFromTreatment;
    }


    render() {

        let isAllGood = this.state.riskOfHarmFromTreatment.isAllGood;
        let riskOfHarmFromTreatmentToggleOn = isAllGood || this.isRiskOfHarmChanged();

        return (
            <div className={"w-100 p-4 emr-modal content-row emr-background"} >
                <div className="w-100">
                    <div>
                        <div className="d-flex mb-3">
                            <h4 className="font-weight-bold" style={{margin: "auto", marginLeft: "0px"}}>Treatment Schedule <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></h4>
                            <GreenSwitchWithEmptyMessage
                                emptyMessage={""}
                                checked={this.isTreatmentScheduleChanged()}
                                onChange={() => {
                                    this.state.treatmentSchedule.numberOfRecommendedSessions = null;
                                    this.state.treatmentSchedule.numberOfScheduledSessions = null;
                                    this.state.treatmentSchedule.nextAppointmentIsIn = null;
                                    this.state.treatmentSchedule.numberOfReassessedSessions = null;
                                    this.state.treatmentSchedule.scheduledSessionsFrequency = null;
                                    this.state.treatmentSchedule.nextAppointmentFrequency = null;
                                    this.state.treatmentSchedule.reassessedSessionsDate = null;
                                    this.forceUpdate();
                                }}/>
                        </div>
                        {this.treatmentScheduleRow(<span>The patient is recommended for <RedAsterisk className={"font-weight-bold"} appointmentType={this.props.appointmentType} firstVisit newComplaint/></span>, "numberOfRecommendedSessions", true, null)}
                        {this.treatmentScheduleRow(<span>The patient is scheduled for <RedAsterisk className={"font-weight-bold"} appointmentType={this.props.appointmentType} firstVisit newComplaint/></span>, "numberOfScheduledSessions", true, "scheduledSessionsFrequency", ScheduledForEnum)}
                        {this.treatmentScheduleRow(<span>Next appointment is in <RedAsterisk className={"font-weight-bold"} appointmentType={this.props.appointmentType} firstVisit newComplaint/></span>, "nextAppointmentIsIn", null, "nextAppointmentFrequency", NextAppointmentInEnum)}
                        {this.treatmentScheduleRow(<span>The patient will be reassessed in <RedAsterisk className={"font-weight-bold"} appointmentType={this.props.appointmentType} firstVisit newComplaint/></span>, "numberOfReassessedSessions", true, "reassessedSessionsDate", ScheduledForEnum, true)}
                    </div>
                </div>


                <div className="w-100 m-3 py-2">
                    <div>
                        <div className="d-flex mb-3">
                            <h4 className="font-weight-bold" style={{margin: "auto", marginLeft: "0px"}}>Compliance with Treatment Plan</h4>
                            <GreenSwitchWithEmptyMessage
                                emptyMessage={""}
                                checked={this.isTreatmentPlanComplianceChanged()}
                                onChange={() => {
                                    this.state.treatmentPlanCompliance.value = null;
                                    this.state.treatmentPlanCompliance.details = null;
                                    this.forceUpdate();
                                }}/>
                        </div>
                        <div className={"row no-margin"}>
                            <div className={"col-8"}>
                                <GradientSlider100Mark4Category
                                    value={this.state.treatmentPlanCompliance.value
                                        ? this.state.treatmentPlanCompliance.value : null}
                                    onChange={(e, val) =>
                                        this.setState(state => {
                                            state.treatmentPlanCompliance.value = val;
                                            return state;
                                        })}
                                />
                            </div>
                            <div className={"col-4"}>
                                <TextInput value={this.state.treatmentPlanCompliance.details || ''}
                                           placeholder={"Details"}
                                           onChange={value => this.setState(state => {
                                               state.treatmentPlanCompliance.details = value;
                                               return state;
                                           })}
                                           style={{fontSize: "14px", width: "90%"}}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{width: "100%", margin: "20px"}}>
                    <div>
                        <div className="d-flex mb-3">
                            <h4 className="font-weight-bold" style={{margin: "auto", marginLeft: "0px"}}>Is there Risk of Harm from planned Care of
                                Treatment ? <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></h4>
                            <GreenSwitchWithMessage
                                message={isAllGood ? "No Risk" : ""}
                                checked={riskOfHarmFromTreatmentToggleOn}
                                onChange={() => {
                                    this.clearAllRiskOfHarmEntity();
                                    this.forceUpdate();
                                }}/>
                        </div>
                        <div style={{display: "flex", marginLeft: "5px"}}>
                            <CheckboxInput value={isAllGood}
                                           onChange={(value) => {
                                               this.clearAllRiskOfHarmEntity()
                                               this.state.riskOfHarmFromTreatment.isAllGood = value;
                                               this.forceUpdate();
                                           }}/>
                            <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>No Risk</div>
                        </div>
                        <div className={isAllGood ? "not-allowed" : ""} style={{marginTop: "7px"}}>
                            <div className={isAllGood ? "disable-pointer" : ""}>
                                <div className={"row no-margin"}>
                                    <div className={"col-4"}>If yes, what risk?</div>
                                    <div className={"col-8"}>
                                        <TextInput value={this.state.riskOfHarmFromTreatment.whatRisk || ''}
                                                   placeholder={"Details"}
                                                   onChange={value => this.setState(state => {
                                                       state.riskOfHarmFromTreatment.whatRisk = value;
                                                       return state;
                                                   })}
                                                   style={{fontSize: "14px", width: "100%"}}
                                        />
                                    </div>
                                </div>
                                <div className={"row no-margin"}>
                                    <div className={"col-4"}>If yes, has the patient been informed?</div>
                                    <div className={"col-2 no-padding no-margin"}>
                                        <MaterialRadioButton name="patientInformedYesNo"
                                                             value={this.state.riskOfHarmFromTreatment.hasPatientInformed != undefined ? this.state.riskOfHarmFromTreatment.hasPatientInformed : null}
                                                             onChange={this.onPatientInformedChanged.bind(this)}>
                                            <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "10px"}}
                                                              value={true} control={<Radio/>}
                                                              label="Yes"
                                            />
                                            <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "10px"}}
                                                              value={false} control={<Radio/>}
                                                              label="No"
                                            />
                                        </MaterialRadioButton>
                                    </div>
                                    <div className={"col-6"}>
                                        <TextInput
                                            value={this.state.riskOfHarmFromTreatment.patientInformedDetail || ''}
                                            placeholder={"Details"}
                                            onChange={value => this.setState(state => {
                                                state.riskOfHarmFromTreatment.patientInformedDetail = value;
                                                return state;
                                            })}
                                            style={{fontSize: "14px", width: "100%"}}
                                        />
                                    </div>
                                </div>
                                <div className={"row no-margin"}>
                                    <div className={"col-4"}>If yes, did the patient give consent?</div>
                                    <div className={"col-2 no-padding no-margin"}>
                                        <MaterialRadioButton name="patientGiveConsentYesNo"
                                                             value={this.state.riskOfHarmFromTreatment.didPatientGiveConsent != undefined ? this.state.riskOfHarmFromTreatment.didPatientGiveConsent : null}
                                                             onChange={this.onPatientGiveConsentChanged.bind(this)}>
                                            <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "10px"}}
                                                              value={true} control={<Radio/>}
                                                              label="Yes"
                                            />
                                            <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "10px"}}
                                                              value={false} control={<Radio/>}
                                                              label="No"
                                            />
                                        </MaterialRadioButton>
                                    </div>
                                    <div className={"col-6"}>
                                        <TextInput
                                            value={this.state.riskOfHarmFromTreatment.patientGiveConsentDetail || ''}
                                            placeholder={"Details"}
                                            onChange={value => this.setState(state => {
                                                state.riskOfHarmFromTreatment.patientGiveConsentDetail = value;
                                                return state;
                                            })}
                                            style={{fontSize: "14px", width: "100%"}}
                                        />
                                    </div>
                                </div>
                                <div className={"row no-margin"}>
                                    <div className={"col-4"}>Are extra precautions necessary?</div>
                                    <div className={"col-2 no-padding no-margin"}>
                                        <MaterialRadioButton name="precautionsNecessaryYesNo"
                                                             value={this.state.riskOfHarmFromTreatment.arePrecautionsNecessary != undefined ? this.state.riskOfHarmFromTreatment.arePrecautionsNecessary : null}
                                                             onChange={this.onPrecautionsNecessaryChanged.bind(this)}>
                                            <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "10px"}}
                                                              value={true} control={<Radio/>}
                                                              label="Yes"
                                            />
                                            <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "10px"}}
                                                              value={false} control={<Radio/>}
                                                              label="No"
                                            />
                                        </MaterialRadioButton>
                                    </div>
                                    <div className={"col-6"}>
                                        <TextInput
                                            value={this.state.riskOfHarmFromTreatment.precautionsNecessaryDetail || ''}
                                            placeholder={"Details"}
                                            onChange={value => this.setState(state => {
                                                state.riskOfHarmFromTreatment.precautionsNecessaryDetail = value;
                                                return state;
                                            })}
                                            style={{fontSize: "14px", width: "100%"}}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    treatmentScheduleRow = (title, field, hasSessions, sessionField, dropdownEnum, hasIcon = false) => {
        return (
            <div className={"row no-margin"}>
                <div className={"col-4 d-flex justify-content-between align-items-center"}>
                  <div>{title}</div>
                  <div>:</div>
                </div>
                <div className={"col-1 d-flex justify-content-center align-items-center"}>
                    <NumberInput value={this.state.treatmentSchedule[field] ? this.state.treatmentSchedule[field] : ""}
                                 placeholder={""}
                                 id={field + "Input"}
                                 step="1"
                                 min={0}
                                 style={{width: "100%", height: "25px"}}
                                 onChange={(value) => this.setState(state => {
                                     value = value < 0 ? "0" : value;
                                     value = Number.isInteger(Number(value)) ? value : "" ;
                                     state.treatmentSchedule[field] = value;
                                     return state;
                                 })
                                 }
                    />
                </div>
                <div className={"col-2 d-flex justify-content-center align-items-center"}>{hasSessions != null ? "Sessions" : ""}</div>
                <div className={"col-2 d-flex justify-content-center align-items-center"}>
                    {sessionField != null && !hasIcon ? <Select
                            className={"emr-white-select-container w-100"}
                            classNamePrefix={"emr-white-select"}
                            isClearable={true}
                            placeholder={""}
                            options={dropdownEnum.toArray().map(v => {
                                    return {value: v.key, label: v.name}
                                }
                            )}
                            value={this.state.treatmentSchedule[sessionField] != null ?
                                {
                                    value: dropdownEnum[this.state.treatmentSchedule[sessionField]].key,
                                    label: dropdownEnum[this.state.treatmentSchedule[sessionField]].name
                                } : null}
                            onChange={(event) => {
                                let r = event == null ? null : event.value;
                                this.setState((state) => {
                                    state.treatmentSchedule[sessionField] = r;
                                    return state;
                                });
                            }}
                        /> :
                        hasIcon && <DateInput id={sessionField + "date"}
                                              className={"treatment-schedule-date"}
                                              disablePast="true"
                                              disableToolbar="true" variant="dialog"
                                              value={this.state.treatmentSchedule[sessionField] ? this.state.treatmentSchedule[sessionField] : null}
                                              style={{height: "25px"}}
                                              onChange={date => {
                                                  this.state.treatmentSchedule[sessionField] = date;
                                                  this.forceUpdate();
                                              }}
                        />}
                </div>
                <div className={"col-1"}>
                    {hasIcon &&
                    <FontAwesomeIcon icon={["fas", "calendar-alt"]} size={"2x"} color={"#afafaf"}/>}
                </div>
            </div>
        )
    }

    onPatientInformedChanged = (value) => {
        let r = (value != null ? (value === 'true') : null);
        this.setState(state => {
            state.riskOfHarmFromTreatment.hasPatientInformed = r;
            return state;
        })
    }

    onPatientGiveConsentChanged = (value) => {
        let r = (value != null ? (value === 'true') : null);
        this.setState(state => {
            state.riskOfHarmFromTreatment.didPatientGiveConsent = r;
            return state;
        })
    }

    onPrecautionsNecessaryChanged = (value) => {
        let r = (value != null ? (value === 'true') : null);
        this.setState(state => {
            state.riskOfHarmFromTreatment.arePrecautionsNecessary = r;
            return state;
        })
    }

    isTreatmentScheduleChanged = () => {
        return (
            (this.state.treatmentSchedule.numberOfRecommendedSessions != null && this.state.treatmentSchedule.numberOfRecommendedSessions.length != 0) ||
            (this.state.treatmentSchedule.numberOfScheduledSessions != null && this.state.treatmentSchedule.numberOfScheduledSessions.length != 0) ||
            (this.state.treatmentSchedule.nextAppointmentIsIn != null && this.state.treatmentSchedule.nextAppointmentIsIn.length != 0) ||
            (this.state.treatmentSchedule.numberOfReassessedSessions != null && this.state.treatmentSchedule.numberOfReassessedSessions.length != 0) ||
            this.state.treatmentSchedule.scheduledSessionsFrequency != null ||
            this.state.treatmentSchedule.nextAppointmentFrequency != null ||
            this.state.treatmentSchedule.reassessedSessionsDate != null
        );
    }

    isTreatmentPlanComplianceChanged = () => {
        return (
            (this.state.treatmentPlanCompliance.details != null && this.state.treatmentPlanCompliance.details.length != 0) ||
            this.state.treatmentPlanCompliance.value != null
        );
    }

    isRiskOfHarmChanged = () => {
        return (
            (this.state.riskOfHarmFromTreatment.whatRisk != null && this.state.riskOfHarmFromTreatment.whatRisk.length != 0) ||
            (this.state.riskOfHarmFromTreatment.patientInformedDetail != null && this.state.riskOfHarmFromTreatment.patientInformedDetail.length != 0) ||
            (this.state.riskOfHarmFromTreatment.patientGiveConsentDetail != null && this.state.riskOfHarmFromTreatment.patientGiveConsentDetail.length != 0) ||
            (this.state.riskOfHarmFromTreatment.precautionsNecessaryDetail != null && this.state.riskOfHarmFromTreatment.precautionsNecessaryDetail.length != 0) ||
            this.state.riskOfHarmFromTreatment.hasPatientInformed != null ||
            this.state.riskOfHarmFromTreatment.didPatientGiveConsent != null ||
            this.state.riskOfHarmFromTreatment.arePrecautionsNecessary != null
        )
    }

    clearAllRiskOfHarmEntity = () => {
        this.state.riskOfHarmFromTreatment.whatRisk = null;
        this.state.riskOfHarmFromTreatment.patientInformedDetail = null;
        this.state.riskOfHarmFromTreatment.patientGiveConsentDetail = null;
        this.state.riskOfHarmFromTreatment.precautionsNecessaryDetail = null;
        this.state.riskOfHarmFromTreatment.hasPatientInformed = null;
        this.state.riskOfHarmFromTreatment.didPatientGiveConsent = null;
        this.state.riskOfHarmFromTreatment.arePrecautionsNecessary = null;
    }
}
