import Enum from "../../../../../../enums/Enum";

const TherapeuticExerciseEnum = new Enum({
    Mobility: {name: "Mobility"},
    Stability: {name: "Stability"},
    Balance_Proprioception: {name: "Balance & Proprioception"},
    Coordination: {name: "Coordination"},
    Strengthening: {name: "Strengthening"},
    Postural_Correction: {name: "Postural Correction"},
    Movement_Forms: {name: "Movement Forms"},
    Other: {name: "Other"},
});

export default TherapeuticExerciseEnum;