import React, {Component} from "react";
import VitalSignsView from "./views/VitalSignsView";
import PalpationView from "./views/PalpationView";
import Modal from "../../../../components/modal/Modal";
import PalpationModal from "./modals/PalpationModal";
import GreenSwitchWithEmptyMessage from "../../../../components/switch/GreenSwitchWithEmptyMessage";
import VitalSignsModal from "./modals/VitalSignsModal";
import NotificationService from "../../../../services/notification-service/NotificationService";
import StringUtil from "../../../../utils/StringUtil";
import FunctionalTestingModal from "./modals/FunctionalTestingModal";
import FunctionalTestingView from "./views/FunctionalTestingView";
import MorseFallRiskView from "./views/MorseFallRiskView";
import MorseFallRiskModal from "./modals/MorseFallRiskModal";
import SpecialTestsView from "./views/SpecialTestsView";
import SpecialTestsModal from "./modals/SpecialTestsModal";
import RespiratoryPatternModal from "./modals/RespiratoryPatternModal";
import RespiratoryPatternView from "./views/RespiratoryPatternView";
import MoodModal from "./modals/MoodModal";
import MoodView from "./views/MoodView";
import DermatomesView from "./views/DermatomesView";
import DermatomesModal from "./modals/DermatomesModal";
import GaitModal from "./modals/GaitModal";
import ObservationView from "./views/ObservationView";
import PostureView from "./views/PostureView";
import GaitAnalysisView from "./views/GaitAnalysisView";
import NeurologicGaitView from "./views/NeurologicGaitView";
import MSKDiagnosisModal from "./modals/MSKDiagnosisModal";
import MSKDiagnosisView from "./views/MSKDiagnosisView";
import RangeOfMotionModal from "./modals/RangeOfMotionModal";
import RangeOfMotionView from "./views/RangeOfMotionView";
import {Grid} from "@material-ui/core";
import VisitNavigator from "../common/VisitNavigator";
import {pick} from "lodash-es";
import cloneDeep from 'lodash/cloneDeep';
import GreenSwitchWithMessage from "../../../../components/switch/GreenSwitchWithMessage";
import RedAsterisk from "../../../../components/asterisk/RedAsterisk";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";

export default class Assessment extends Component {

    state = {
        vitalSignsModalVisible: false,
        closeConfirmModalVisible: false,
        closeModalFunctionCallback: null,
        palpationModalVisible: false,
        MSKDiagnosisModalVisible: false,
        functionalTestingModalVisible: false,
        specialTestsModalVisible: false,
        moodModalVisible: null,
        activeSpecialTest: {},
        dermatomesModalVisible: false,
        gaitModalVisible: false,
        index: 0
    }

    componentDidMount() {
        let lastAssessment = this.props.visits[this.props.visits.length - 1].assessment;
        if (lastAssessment.vitalSigns == null) {
            this.setState({
                index: Math.max((this.props.currentVisitIndex - 1), 0)
            });
        } else {
            this.setState({
                index: this.props.currentVisitIndex
            });
        }
    }

    render() {
        let visit = this.props.visits[this.state.index];
        let assessment = visit.assessment;
        return (
            <div>
                <VisitNavigator
                    visits={this.props.visits}
                    currentVisitIndex={this.props.currentVisitIndex}
                    selectedIndex={this.state.index}
                    onDateChange={(index) => {
                        this.setState({index: index});
                    }}
                />
                <div className={this.props.disabled ? "content-row disable-pointer" : "content-row"}>
                    <div className={"content-col-6"}>
                        <div className={"content-wrapper"}>
                            <div onClick={this.openVitalSignsModal}
                                 className={"content-clickable"}>
                                <div style={{display: "flex"}}>
                                    <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Vital
                                        Signs <RedAsterisk renderForAll/></div>
                                    <GreenSwitchWithEmptyMessage
                                        emptyMessage={""}
                                        checked={assessment.vitalSigns != null}/>
                                </div>
                                {assessment.vitalSigns != null &&
                                <div className={"content-card"}>
                                    <VitalSignsView vitalSigns={assessment.vitalSigns}/>
                                </div>
                                }
                            </div>
                        </div>
                        <div className={"content-wrapper"}>
                            <div className={"content-clickable"}
                                 onClick={this.openGaitModal}>
                                <div>
                                    <div style={{display: "flex"}}>
                                        <div className="content-label"
                                             style={{margin: "auto", marginLeft: "0px"}}>Observation <RedAsterisk
                                            renderForAllTypes/></div>
                                        <GreenSwitchWithMessage
                                            message={(assessment.observation && assessment.observation.none) && "Normal"}
                                            checked={(assessment.observation != null && assessment.observation.items != null && assessment.observation.items.length != 0) ||
                                            (assessment.observation && assessment.observation.none)}
                                            onChange={this.openGaitModal}/>
                                    </div>
                                    {assessment.observation != null &&
                                    <div>
                                        <ObservationView observation={assessment.observation}/>
                                    </div>
                                    }
                                </div>
                                <div>
                                    <div style={{display: "flex", marginTop: "10px"}}>
                                        <div className="content-label" style={{
                                            margin: "auto",
                                            marginLeft: "0px",
                                            marginTop: 8
                                        }}>Posture <RedAsterisk appointmentType={visit.appointmentType} firstVisit
                                                                newComplaint/></div>
                                        <GreenSwitchWithMessage
                                            message={(assessment.posture && assessment.posture.none) && "Normal"}
                                            checked={(assessment.posture != null && assessment.posture.items != null && assessment.posture.items.length != 0) ||
                                            (assessment.posture && assessment.posture.none)}
                                            onChange={this.openGaitModal}/>
                                    </div>
                                    {assessment.posture != null &&
                                    <div>
                                        <PostureView posture={assessment.posture}/>
                                    </div>
                                    }
                                </div>
                                <div>
                                    <div style={{display: "flex", marginTop: "10px"}}>
                                        <div className="content-label"
                                             style={{margin: "auto", marginLeft: "0px", marginTop: 8}}>Gait Analysis
                                        </div>
                                        <GreenSwitchWithMessage
                                            message={(assessment.gaitAnalysis && assessment.gaitAnalysis.none) && "Normal"}
                                            checked={(!this.checkGaitAnalysisEmpty(assessment)) || (assessment.gaitAnalysis && assessment.gaitAnalysis.none)}
                                            onChange={this.openGaitModal}/>
                                    </div>
                                    {!this.checkGaitAnalysisEmpty(assessment) &&
                                    <div>
                                        <GaitAnalysisView gaitAnalysis={assessment.gaitAnalysis}/>
                                    </div>
                                    }
                                </div>
                                <div>
                                    <div style={{display: "flex", marginTop: "10px"}}>
                                        <div className="content-label"
                                             style={{margin: "auto", marginLeft: "0px", marginTop: 8}}>Neurologic Gait
                                        </div>
                                        <GreenSwitchWithEmptyMessage
                                            emptyMessage={""}
                                            checked={assessment.neurologicGait != null ?
                                                !assessment.neurologicGait.details ?
                                                    assessment.neurologicGait.items != null ?
                                                        assessment.neurologicGait.items.length != 0 ?
                                                            true : false : false : true : false}
                                            onChange={this.openGaitModal}/>
                                    </div>
                                    {assessment.neurologicGait != null &&
                                    <div>
                                        <NeurologicGaitView neurologicGait={assessment.neurologicGait}/>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openFunctionalTestingModal}>
                            <div style={{display: "flex", padding: "0px 5px"}}>
                                <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Functional
                                    Testing
                                </div>
                                <GreenSwitchWithEmptyMessage
                                    emptyMessage={""}
                                    checked={assessment.functionalTesting != null ?
                                        assessment.functionalTesting.items != null ?
                                            assessment.functionalTesting.items.length != 0 : false : false
                                    }/>
                            </div>
                            {assessment.functionalTesting != null &&
                            <div>
                                <FunctionalTestingView functionalTesting={assessment.functionalTesting}/>
                            </div>
                            }
                        </div>

                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openDermatomesModal}>
                            {this.props.assessment != null &&
                            <div style={{padding: "0px 5px"}}>
                                <DermatomesView assessment={assessment}
                                                appointmentType={visit.appointmentType}/>
                            </div>
                            }
                        </div>

                        <div className={"content-wrapper"}>
                            <Grid container justify={"space-between"} style={{margin: "10px 0px"}}>
                                <Grid item style={{width: "48%"}} className="content-clickable"
                                      onClick={this.openMoodModal}>
                                    <div style={{display: "flex"}}>
                                        <h6 style={{margin: "auto", marginLeft: "0px"}}>Mood and Well-being of the
                                            Patient <RedAsterisk appointmentType={visit.appointmentType} firstVisit
                                                                 newComplaint/></h6>
                                        <GreenSwitchWithEmptyMessage
                                            emptyMessage={""}
                                            checked={assessment.mood != null}/>
                                    </div>
                                    {assessment.mood != null &&
                                    <div>
                                        <MoodView mood={assessment.mood}/>
                                    </div>
                                    }
                                </Grid>
                                <Grid item style={{width: "48%", paddingRight: 5}} className="content-clickable"
                                      onClick={this.openRespiratoryPatternModal}>
                                    <div style={{display: "flex"}}>
                                        <h6 style={{margin: "auto", marginLeft: "0px"}}>Respiratory Patterns</h6>
                                        <GreenSwitchWithEmptyMessage
                                            emptyMessage={""}
                                            checked={assessment.respiratoryPattern != null ?
                                                assessment.respiratoryPattern.items != null ?
                                                    assessment.respiratoryPattern.items.length != 0 : false : false
                                            }
                                            onChange={this.openRespiratoryPatternModal}/>
                                    </div>
                                    {assessment.respiratoryPattern != null &&
                                    <div>
                                        <RespiratoryPatternView respiratoryPattern={assessment.respiratoryPattern}/>
                                    </div>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div className={"content-col-6"}>
                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openMorseFallRiskModal}>
                            <div style={{display: "flex"}}>
                                <div className="content-label" style={{marginLeft: "0px"}}>Fall Risk Status <RedAsterisk
                                    appointmentType={visit.appointmentType} firstVisit newComplaint/></div>
                                <GreenSwitchWithEmptyMessage
                                    checked={assessment.fallRisk != null}/>
                            </div>


                            <div>
                                <MorseFallRiskView fallRisk={assessment.fallRisk}
                                                   appointmentType={visit.appointmentType}/>
                            </div>

                        </div>
                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openPalpationModal}>
                            <div style={{display: "flex"}}>
                                <div className="content-label"
                                     style={{margin: "auto", marginLeft: "0px"}}>Palpation <RedAsterisk
                                    appointmentType={visit.appointmentType} firstVisit newComplaint/></div>
                                <GreenSwitchWithEmptyMessage
                                    message={(assessment.palpation && assessment.palpation.none) ? "Normal" : ""}
                                    checked={assessment.palpation
                                    && (assessment.palpation.none || (assessment.palpation.items && assessment.palpation.items.length > 0))
                                    }/>
                            </div>
                            {assessment.palpation != null &&
                            <div>
                                <PalpationView palpation={assessment.palpation}/>
                            </div>
                            }
                        </div>

                        <div className="content-wrapper content-clickable"
                             onClick={this.openRangeOfMotionModal}>
                            <RangeOfMotionView assessment={assessment}
                                               appointmentType={visit.appointmentType}/>
                        </div>

                        <div className={"content-wrapper content-clickable"}
                             onClick={this.openSpecialTestsModal}>
                            <div style={{display: "flex"}}>
                                <div className="content-label" style={{margin: "auto", marginLeft: "0px"}}>Special
                                    Tests
                                </div>
                                <GreenSwitchWithEmptyMessage
                                    emptyMessage={""}
                                    checked={assessment.specialTests != null ?
                                        assessment.specialTests.items != null ?
                                            assessment.specialTests.items.length != 0 : false : false}/>
                            </div>
                            {assessment.specialTests != null &&
                            <div>
                                <SpecialTestsView specialTests={assessment.specialTests}/>
                            </div>
                            }
                        </div>

                        <div onClick={this.openMSKDiagnosisModal}
                             className={"content-wrapper content-clickable"}>
                            <div style={{display: "flex"}}>
                                <div className="content-label ml-0">MSK Diagnostic Ultra Sound</div>
                                <GreenSwitchWithEmptyMessage
                                    emptyMessage={""}
                                    checked={assessment.mskDiagnosis != null && assessment.mskDiagnosis.items != null}/>
                            </div>
                            <div>
                                <MSKDiagnosisView MSKDiagnosis={assessment.mskDiagnosis}/>
                            </div>
                        </div>
                    </div>
                    {this.renderCloseConfirmModal()}
                    {this.renderVitalSignsModal()}
                    {this.renderPalpationModal()}
                    {this.renderFunctionalTestingModal()}
                    {this.renderDermatomesModal()}
                    {this.renderSpecialTestsModal()}
                    {this.renderRespiratoryPatternModal()}
                    {this.renderMoodModal()}
                    {this.renderMorseFallRiskModal()}
                    {this.renderMSKDiagnosisModal()}
                    {this.renderRangeOfMotionModal()}
                    {this.renderGaitModal()}
                </div>
            </div>
        );
    }

    renderCloseConfirmModal = () => {
        return (
            <Modal visible={this.state.closeConfirmModalVisible}
                   submitContent="Yes"
                   closeContent="No"
                   closeAction={this.closeCloseConfirmModal}
                   submitAction={this.submitCloseConfirmModal}
                   header='Are you sure? '
                   maxWidth={"md"}
                   contentStyle={{maxHeight: "95%", width: "20vw"}}>
                Unsaved changes may be lost!
            </Modal>
        );
    }

    closeCloseConfirmModal = () => {
        this.setState({closeConfirmModalVisible: false});
    }

    openCloseConfirmModal = (callback) => {
        this.setState({closeConfirmModalVisible: true, closeModalFunctionCallback: callback});
    }

    submitCloseConfirmModal = () => {
        this.state.closeModalFunctionCallback();
        // Object.keys(assessment.fallRisk).map((key, index) => (
        //   key === "furtherAssessmentItem" ?
        //       assessment.fallRisk[key] = [] :
        //       key === "isPhysiotherapist" ? '' :
        //       assessment.fallRisk[key] = null
        // ))
        this.setState({closeConfirmModalVisible: false});
    }

    openVitalSignsModal = () => {
        this.vitalSignsFormFields = [];

        if (this.props.assessment.vitalSigns) {
            this.copiedVitalSigns = cloneDeep(this.props.assessment.vitalSigns);
        } else {
            this.props.assessment.vitalSigns = {};
        }

        this.setState({vitalSignsModalVisible: true});
    }

    saveVitalSignsModal = () => {
        if (!ValidationUtil.isValid(this.vitalSignsFormFields)) {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'There are missing fields.'
            });
            return;
        } else {
            this.props.onModalSave();
            this.setState({
                vitalSignsModalVisible: false,
                index: this.props.currentVisitIndex
            });
        }
    }
    closeVitalSignsModal = () => {
        this.props.assessment.vitalSigns = this.copiedVitalSigns;
        this.setState({vitalSignsModalVisible: false});
    }

    renderVitalSignsModal() {
        return (
            <Modal visible={this.state.vitalSignsModalVisible}
                   submitContent="Save"
                   contentStyle={{maxHeight: 600, overflowY: 'auto'}}
                   submitAction={this.saveVitalSignsModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeVitalSignsModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   contentStyle={{maxHeight: "140%", width: "75vw", overflowY: 'auto'}}>
                <VitalSignsModal
                    vitalSigns={this.props.assessment.vitalSigns}
                    currentVisitIndex={this.props.currentVisitIndex}
                    visits={this.props.visits}
                    formFields={this.vitalSignsFormFields}
                />
            </Modal>
        );
    }

    openMorseFallRiskModal = () => {
        this.copiedFallRisk = cloneDeep(this.props.assessment.fallRisk);
        if (this.props.assessment.fallRisk == null) {
            this.props.assessment.fallRisk = {furtherAssessmentItem: []};
        }
        this.setState({morseFallRiskModalVisible: true});
    }

    saveMorseFallRiskModal = () => {
        if (this.props.assessment.fallRisk.isPhysiotherapist &&
            this.props.assessment.fallRisk.furtherAssessmentItem.length == 0 &&
            this.props.assessment.fallRisk.riskLevel == "HIGH") {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'There are missing fields.'
            });
        } else {
            this.props.onModalSave();
            this.setState({morseFallRiskModalVisible: false});
        }
    }

    closeMorseFallRiskModal = () => {
        this.props.assessment.fallRisk = this.copiedFallRisk;
        this.setState({morseFallRiskModalVisible: false});
    }

    renderMorseFallRiskModal = () => {
        return (
            <Modal visible={this.state.morseFallRiskModalVisible}
                   submitContent="Save"
                   submitAction={this.saveMorseFallRiskModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeMorseFallRiskModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   contentStyle={{height: "70%", maxHeight: "95%", width: "55vw", overflowY: "auto"}}>
                <MorseFallRiskModal assessment={this.props.assessment}
                                    currentVisitIndex={this.props.currentVisitIndex}
                                    visits={this.props.visits}
                                    appointmentType={this.props.currentVisit.appointmentType}
                />
            </Modal>
        );
    }
    openPalpationModal = () => {
        this.copiedPalpation = cloneDeep(this.props.assessment.palpation);
        if (this.props.assessment.palpation == null) {
            this.props.assessment.palpation = {none: false, items: []};
        } else if (this.props.assessment.palpation.items == null) {
            this.props.assessment.palpation.items = [];
        }

        this.setState({palpationModalVisible: true});
    }

    savePalpationModal = () => {
        this.props.onModalSave();
        this.setState({
            palpationModalVisible: false,
            index: this.props.currentVisitIndex
        });
    }

    closePalpationModal = () => {
        this.props.assessment.palpation = this.copiedPalpation;
        this.setState({palpationModalVisible: false});
    }

    renderPalpationModal = () => {
        if (!this.state.palpationModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.palpationModalVisible}
                   className="PalpationModal"
                   submitContent="Save"
                   submitAction={this.savePalpationModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closePalpationModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   fullWidth
                   maxWidth={"xl"}
                   scroll={"body"}
                   contentStyle={{minHeight: "75vh"}}>

                <PalpationModal
                    formFields={this.props.formFields}
                    assessment={this.props.assessment}
                    appointmentType={this.props.currentVisit.appointmentType}
                />
            </Modal>
        );
    }
    openMSKDiagnosisModal = () => {
        this.copiedMskDiagnosis = cloneDeep(this.props.assessment.mskDiagnosis);
        if (this.props.assessment.mskDiagnosis == null) {
            this.props.assessment.mskDiagnosis = {items: []};
        }
        this.setState({MSKDiagnosisModalVisible: true});
    }
    closeMSKDiagnosisModal = () => {
        this.props.assessment.mskDiagnosis = this.copiedMskDiagnosis;
        this.setState({MSKDiagnosisModalVisible: false});
    }
    saveMSKDiagnosisModal = () => {
        this.props.onModalSave();
        this.setState({
            MSKDiagnosisModalVisible: false,
            index: this.props.currentVisitIndex
        });
    }
    renderMSKDiagnosisModal = () => {
        if (!this.state.MSKDiagnosisModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.MSKDiagnosisModalVisible}
                   submitContent="Save"
                   submitAction={this.saveMSKDiagnosisModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeMSKDiagnosisModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   contentStyle={{height: "70%", overflowY: 'auto', maxHeight: "95%", width: "50vw"}}>
                <MSKDiagnosisModal
                    formFields={this.props.formFields}
                    assessment={this.props.assessment}
                />
            </Modal>
        );
    }


    openFunctionalTestingModal = () => {
        this.copiedFunctionalTesting = cloneDeep(this.props.assessment.functionalTesting);
        if (this.props.assessment.functionalTesting == null) {
            this.props.assessment.functionalTesting = {items: []};
        } else if (this.props.assessment.functionalTesting.items == null) {
            this.props.assessment.functionalTesting.items = [];
        }
        this.setState({functionalTestingModalVisible: true});
    }

    saveFunctionalTestingModal = () => {
        this.props.onModalSave();
        this.setState({
            functionalTestingModalVisible: false,
            index: this.props.currentVisitIndex
        });
    }

    closeFunctionalTestingModal = () => {
        this.props.assessment.functionalTesting = this.copiedFunctionalTesting;
        this.setState({functionalTestingModalVisible: false});
    }

    renderFunctionalTestingModal = () => {
        if (!this.state.functionalTestingModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.functionalTestingModalVisible}
                   className="FunctionalTestingModal"
                   submitContent="Save"
                   submitAction={this.saveFunctionalTestingModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeFunctionalTestingModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   paperStyle={{overflowY: 'visible'}}
                   contentStyle={{overflowY: 'visible', height: "70%", maxHeight: "95%", width: "50vw"}}>
                <FunctionalTestingModal
                    formFields={this.props.formFields}
                    assessment={this.props.assessment}
                />
            </Modal>
        );
    }

    openDermatomesModal = () => {
        this.copiedDermatomes = cloneDeep(this.props.assessment.dermatomes);
        this.copiedMyotomes = cloneDeep(this.props.assessment.myotomes);
        this.copiedReflexes = cloneDeep(this.props.assessment.reflexes);
        this.copiedNeurodynamics = cloneDeep(this.props.assessment.neurodynamics);
        this.copiedReflexesExtra = cloneDeep(this.props.assessment.reflexesExtra);

        this.props.assessment.dermatomes = this.props.assessment.dermatomes ? this.props.assessment.dermatomes : {};
        this.props.assessment.myotomes = this.props.assessment.myotomes ? this.props.assessment.myotomes : {};
        this.props.assessment.reflexes = this.props.assessment.reflexes ? this.props.assessment.reflexes : {};
        this.props.assessment.neurodynamics = this.props.assessment.neurodynamics ? this.props.assessment.neurodynamics : {};
        this.setState({dermatomesModalVisible: true});
    }

    isDermatomesModalRowChanged = (row) => {
        if (
            (row.rightValue !== undefined && row.rightValue !== null) ||
            (row.leftValue !== undefined && row.leftValue !== null) ||
            (row.rightBoolValue !== undefined && row.rightBoolValue !== null) ||
            (row.leftBoolValue !== undefined && row.leftBoolValue !== null) ||
            (row.details !== undefined && row.details !== null && row.details.length !== 0)
        )
            return true;
        else
            return false
    }

    findChangedRows = (source) => {
        let changedRows = [];
        for (let expandableRow in source) {
            if (Array.isArray(source[expandableRow])) {
                source[expandableRow].forEach(row => {
                    if (this.isDermatomesModalRowChanged(row)) {
                        changedRows.push(row);
                    }
                })
            }
        }
        return [...changedRows];
    }

    findOnlyChangedRowsOnDermatomesModal = () => {
        let dermatomes = this.findChangedRows(this.props.assessment.dermatomes);
        let myotomes = this.findChangedRows(this.props.assessment.myotomes);
        let reflexes = this.findChangedRows(this.props.assessment.reflexes);
        let neurodynamics = this.findChangedRows(this.props.assessment.neurodynamics);

        return [dermatomes, myotomes, reflexes, neurodynamics];

    }
    saveDermatomesModal = () => {
        this.filterChangedRows();
        this.props.onModalSave();
        this.setState({dermatomesModalVisible: false, index: this.props.currentVisitIndex});
    }

    filterChangedRows() {
        const [dermatomesItems, myotomesItems, reflexesItems, neurodynamicsItems] = this.findOnlyChangedRowsOnDermatomesModal();

        this.props.assessment.dermatomes = pick(this.props.assessment.dermatomes, ['id', 'none']);
        this.props.assessment.dermatomes.items = dermatomesItems;

        this.props.assessment.myotomes = pick(this.props.assessment.myotomes, ['id', 'none']);
        ;
        this.props.assessment.myotomes.items = myotomesItems;

        this.props.assessment.reflexes = pick(this.props.assessment.reflexes, ['id', 'none']);
        this.props.assessment.reflexes.items = reflexesItems;

        const {id} = this.props.assessment.neurodynamics;
        this.props.assessment.neurodynamics = {id, items: neurodynamicsItems};
    }

    closeDermatomesModal = () => {
        this.props.assessment.dermatomes = this.copiedDermatomes;
        this.props.assessment.myotomes = this.copiedMyotomes;
        this.props.assessment.reflexes = this.copiedReflexes;
        this.props.assessment.neurodynamics = this.copiedNeurodynamics;
        this.props.assessment.reflexesExtra = this.copiedReflexesExtra;
        this.setState({dermatomesModalVisible: false});
    }

    renderDermatomesModal = () => {
        if (!this.state.dermatomesModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.dermatomesModalVisible}
                   className="PalpationModal"
                   submitContent="Save"
                   submitAction={this.saveDermatomesModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeDermatomesModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   fullScreen={true}
                   contentStyle={{maxHeight: "95%", overflowY: 'auto'}}>
                <DermatomesModal
                    assessment={this.props.assessment}
                    appointmentType={this.props.currentVisit.appointmentType}
                />
            </Modal>
        );
    }

    openSpecialTestsModal = () => {
        this.copiedSpecialTests = cloneDeep(this.props.assessment.specialTests);
        if (this.props.assessment.specialTests == null) {
            this.props.assessment.specialTests = {items: []};
        } else if (this.props.assessment.specialTests.items == null) {
            this.props.assessment.specialTests.items = [];
        }
        this.setState({specialTestsModalVisible: true, activeSpecialTest: {}});
    }

    saveSpecialTestsModal = () => {
        if (this.state.activeSpecialTest != null
            && this.props.assessment.specialTests != null
            && this.state.activeSpecialTest.area != null) {
            this.props.assessment.specialTests.items.push(this.state.activeSpecialTest);
        }

        this.props.onModalSave();
        this.setState({
            specialTestsModalVisible: false,
            activeSpecialTest: {},
            index: this.props.currentVisitIndex
        });
    }

    closeSpecialTestsModal = () => {
        this.props.assessment.specialTests = this.copiedSpecialTests;
        this.setState({specialTestsModalVisible: false, activeSpecialTest: {}});
    }

    renderSpecialTestsModal = () => {
        if (!this.state.specialTestsModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.specialTestsModalVisible}
                   className="SpecialTestsModal"
                   submitContent="Save"
                   submitAction={this.saveSpecialTestsModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeSpecialTestsModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   fullWidth
                   paperStyle={{overflowY: 'visible'}}
                   contentStyle={{minHeight: '75vh'}}>
                <SpecialTestsModal
                    formFields={this.props.formFields}
                    assessment={this.props.assessment}
                    activeSpecialTest={this.state.activeSpecialTest}
                />
            </Modal>
        );
    }

    openRespiratoryPatternModal = () => {
        this.copiedRespiratoryPattern = cloneDeep(this.props.assessment.respiratoryPattern);
        if (this.props.assessment.respiratoryPattern == null) {
            this.props.assessment.respiratoryPattern = {items: []};
        } else if (this.props.assessment.respiratoryPattern.items == null) {
            this.props.assessment.respiratoryPattern.items = [];
        }
        this.setState({respiratoryPatternModalVisible: true});
    }

    saveRespiratoryPatternModal = () => {
        this.props.onModalSave();
        this.setState({
            respiratoryPatternModalVisible: false,
            index: this.props.currentVisitIndex
        });
    }

    closeRespiratoryPatternModal = () => {
        this.props.assessment.respiratoryPattern = this.copiedRespiratoryPattern;
        this.setState({respiratoryPatternModalVisible: false});
    }

    renderRespiratoryPatternModal = () => {
        if (!this.state.respiratoryPatternModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.respiratoryPatternModalVisible}
                   className="RespiratoryPatternModal"
                   submitContent="Save"
                   submitAction={this.saveRespiratoryPatternModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeRespiratoryPatternModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   paperStyle={{overflowY: 'visible'}}
                   contentStyle={{overflowY: 'visible', height: "70%", maxHeight: "95%", width: "50vw"}}>
                <RespiratoryPatternModal
                    formFields={this.props.formFields}
                    assessment={this.props.assessment}
                />
            </Modal>
        );
    }

    openMoodModal = () => {
        this.copiedMood = cloneDeep(this.props.assessment.mood);
        this.setState({moodModalVisible: true});
    }

    saveMoodModal = () => {
        if (this.props.assessment.mood != null) {
            if (this.props.assessment.mood.type == null && StringUtil.isNullOrEmpty(this.props.assessment.mood.detail)) {
                this.props.assessment.mood = null;
            }
        }
        this.props.onModalSave();
        this.setState({
            moodModalVisible: false,
            index: this.props.currentVisitIndex
        });
    }

    closeMoodModal = () => {
        this.props.assessment.mood = this.copiedMood;
        this.setState({moodModalVisible: false});
    }

    renderMoodModal = () => {
        if (!this.state.moodModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.moodModalVisible}
                   className="MoodModal"
                   submitContent="Save"
                   submitAction={this.saveMoodModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeMoodModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   contentStyle={{height: "70%", maxHeight: "95%", width: "50vw"}}>
                <MoodModal
                    formFields={this.props.formFields}
                    assessment={this.props.assessment}
                />
            </Modal>
        );
    }
    openGaitModal = () => {

        this.copiedNeurologicGait = cloneDeep(this.props.assessment.neurologicGait);
        this.copiedGaitAnalysis = cloneDeep(this.props.assessment.gaitAnalysis);
        this.copiedObservation = cloneDeep(this.props.assessment.observation);
        this.copiedPosture = cloneDeep(this.props.assessment.posture);
        if (this.props.assessment.posture == null) {
            this.props.assessment.posture = {items: []};
        } else if (this.props.assessment.posture.items == null) {
            this.props.assessment.posture.items = [];
        }
        if (this.props.assessment.neurologicGait == null) {
            this.props.assessment.neurologicGait = {items: []};
        } else if (this.props.assessment.neurologicGait.items == null) {
            this.props.assessment.neurologicGait.items = [];
        }
        if (this.props.assessment.observation == null) {
            this.props.assessment.observation = {items: []};
        } else if (this.props.assessment.observation.items == null) {
            this.props.assessment.observation.items = [];
        }

        this.setState({gaitModalVisible: true});
    }
    closeGaitModal = () => {
        this.props.assessment.neurologicGait = this.copiedNeurologicGait;
        this.props.assessment.gaitAnalysis = this.copiedGaitAnalysis;
        this.props.assessment.observation = this.copiedObservation;
        this.props.assessment.posture = this.copiedPosture;
        this.setState({gaitModalVisible: false});
    }
    saveGaitModal = () => {
        if (this.props.assessment.posture.items != null
            && this.props.assessment.posture.items.length == 1) {
            if (this.props.assessment.posture.items[0] === {})
                this.props.assessment.posture.items = [];
        }
        this.props.onModalSave();
        this.setState({
            gaitModalVisible: false,
            index: this.props.currentVisitIndex
        });
    }
    renderGaitModal = () => {
        return (
            <Modal visible={this.state.gaitModalVisible}
                   className="MoodModal"
                   submitContent="Save"
                   submitAction={this.saveGaitModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeGaitModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   maxWidth={"xl"}
                   fullWidth
                   scroll={"body"}
                   contentStyle={{minHeight: '75vh',}}>
                <GaitModal
                    formFields={this.props.formFields}
                    assessment={this.props.assessment}
                    appointmentType={this.props.currentVisit.appointmentType}
                />
            </Modal>
        );
    }
    openRangeOfMotionModal = () => {
        this.rangeOfMotionFormFields = [];
        this.copiedRangeOfMotion = cloneDeep(this.props.assessment.rangeOfMotion);
        if (this.props.assessment.rangeOfMotion == null) {
            this.props.assessment.rangeOfMotion = {items: []};
        }
        if (this.props.assessment.rangeOfMotion.items == null) {
            this.props.assessment.rangeOfMotion.items = [];
        }

        this.copiedJointIntegrity = cloneDeep(this.props.assessment.jointIntegrity);
        if (this.props.assessment.jointIntegrity == null) {
            this.props.assessment.jointIntegrity = {items: []};
        }
        if (this.props.assessment.jointIntegrity.items == null) {
            this.props.assessment.jointIntegrity.items = [];
        }

        this.copiedMuscleGroup = cloneDeep(this.props.assessment.muscleGroup);
        if (this.props.assessment.muscleGroup == null) {
            this.props.assessment.muscleGroup = {items: []};
        }
        if (this.props.assessment.muscleGroup.items == null) {
            this.props.assessment.muscleGroup.items = [];
        }

        this.copiedIndividualMuscle = cloneDeep(this.props.assessment.individualMuscle);
        if (this.props.assessment.individualMuscle == null) {
            this.props.assessment.individualMuscle = {items: []};
        }
        if (this.props.assessment.individualMuscle.items == null) {
            this.props.assessment.individualMuscle.items = [];
        }

        this.setState({rangeOfMotionModalVisible: true});
    }

    saveRangeOfMotionModal = () => {
        if (!ValidationUtil.isValid(this.rangeOfMotionFormFields)) {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'Invalid field values.'
            });
            return;
        } else {
            this.props.onModalSave();
            this.setState({
                rangeOfMotionModalVisible: false,
                index: this.props.currentVisitIndex
            });
        }
    }

    closeRangeOfMotionModal = () => {
        this.props.assessment.rangeOfMotion = this.copiedRangeOfMotion;
        this.props.assessment.jointIntegrity = this.copiedJointIntegrity;
        this.props.assessment.muscleGroup = this.copiedMuscleGroup;
        this.props.assessment.individualMuscle = this.copiedIndividualMuscle;
        this.setState({rangeOfMotionModalVisible: false});
    }

    renderRangeOfMotionModal = () => {
        if (!this.state.rangeOfMotionModalVisible) {
            return (<></>);
        }
        return (
            <Modal visible={this.state.rangeOfMotionModalVisible}
                   className="RangeOfMotionModal"
                   submitContent="Save"
                   submitAction={this.saveRangeOfMotionModal}
                   closeAction={() => {
                       this.openCloseConfirmModal(this.closeRangeOfMotionModal);
                   }}
                   headerTextVisible={false}
                   header=' '
                   fullScreen
                   contentStyle={{minHeight: '75vh',}}
            >

                <RangeOfMotionModal
                    assessment={this.props.assessment}
                    appointmentType={this.props.currentVisit.appointmentType}
                    formFields={this.rangeOfMotionFormFields}
                />
            </Modal>
        );
    }
    checkGaitAnalysisEmpty = (assessment) => {
        if (assessment.gaitAnalysis != null && assessment.gaitAnalysis.items != null && assessment.gaitAnalysis.items.length != 0) {
            for (const item of assessment.gaitAnalysis.items) {
                if (item.details) {
                    return false;
                }
            }
        }
        return true;
    }
}
