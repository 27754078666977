import Enum from "../../../../../../enums/Enum";

const NeurodynamicsLowerLimbEnum = new Enum({
    NLL_ACTIVE_STRAIGHT_LEG_RAISING: {value: "Active Straight leg raising"},
    NLL_FEMORAL_NERVE_TENSION_TEST: {value: "Femoral nerve tension test"},
    NLL_SLUMP_TEST: {value: "Slump Test"},
    NLL_PASSIVE_STRAIGHT_LEG_RAISING: {value: "Passive Straight leg raising"},
    NLL_CROSS_STRAIGHT_LEG_RAISING: {value: "Cross Straight leg raising"},
    NLL_OTHER: {value: "Other"},
});

export default NeurodynamicsLowerLimbEnum;