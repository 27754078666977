import React, {Component} from "react";
import '../EMR.css';
import ActiveDiagnosisTab from "./ActiveDiagnosisTab";
import Risk from "./Risk";
import TextareaInput from "../../../../components/textarea-input/TextareaInput";
import InsuranceStatus from "./InsuranceStatus";
import ProcedureTables from "./ProcedureTables";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import RedAsterisk from "../../../../components/asterisk/RedAsterisk";

export default class Diagnosis extends Component {

    state = {};

    componentDidMount() {
        this.retrieveInsuranceApprovalForVisit();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.currentVisit.id !== this.props.currentVisit.id) {
            this.retrieveInsuranceApprovalForVisit();
        }
    }

    retrieveInsuranceApprovalForVisit = () => {
        RemotingService.getRemoteCall(
            'api/insurance/coordinator/visit',
            {visitId: this.props.currentVisit.id},
            (insuranceApproval) => {
                this.setState({insuranceApproval});
            });
    }

    render() {
        const isDirectBilling = this.state.insuranceApproval && 'DIRECT_BILLING' === this.state.insuranceApproval?.insuranceRecord?.type;

        return (
            <div className={this.props.disabled ? "content-row disable-pointer" : "content-row"}>
                <div className={"content-col-6"}>
                    <div className={"content-wrapper"}>
                        <ActiveDiagnosisTab diagnosis={this.props.diagnosis}
                                            visits={this.props.visits}
                                            isDirectBilling={isDirectBilling}
                                            currentVisitIndex={this.props.currentVisitIndex}/>
                        <br/>
                        <br/>
                        <br/>

                        <div>
                            <div className="content-label">Clinical Impression <RedAsterisk renderForAllTypes/></div>
                            <TextareaInput value={this.props.diagnosis.clinicalImpression || ''}
                                           placeholder={"Clinical Impression"}
                                           onChange={value => {
                                               this.props.diagnosis.clinicalImpression = value;
                                               this.forceUpdate();
                                           }}
                                           autoHeight
                                           maxLength={1024}
                                           style={{width: "100%"}}
                            />
                        </div>
                        <Risk diagnosis={this.props.diagnosis}/>
                    </div>
                </div>

                <div className={"content-col-6"}>
                    <div className={"content-wrapper"}>
                        <div className="content-label">Billing</div>
                        <InsuranceStatus diagnosis={this.props.diagnosis}
                                         insuranceApproval={this.state.insuranceApproval}
                                         currentVisit={this.props.currentVisit}/>
                        <br/>
                        <br/>
                        <ProcedureTables diagnosis={this.props.diagnosis}
                                         insurance={this.props.insurance}
                                         currentVisit={this.props.currentVisit}/>
                        <br/>
                        <br/>
                        <br/>

                        <div>
                            <div className="content-label">Note to Cashier</div>
                            <TextareaInput value={this.props.diagnosis.noteToCashier || ''}
                                           placeholder={"Note to Cashier"}
                                           onChange={value => {
                                               this.props.diagnosis.noteToCashier = value;
                                               this.forceUpdate();
                                           }}
                                           maxLength={1024}
                                           style={{width: "100%"}}
                            />
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
