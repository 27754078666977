import React, {Component} from 'react';
import MedicationUseView from "../subjective/views/MedicationUseView";
import GreenSwitchWithMessage from "../../../../components/switch/GreenSwitchWithMessage";
import {MedicationUseChecker} from "../subjective/checkers/MedicationUseChecker";
import MedicalHistoryView from "../subjective/views/MedicalHistoryView";
import FamilyHistoryView from "../subjective/views/FamilyHistoryView";
import SurgicalHistoryView from "../subjective/views/SurgicalHistoryView";
import ComplaintEMRDashboardView from "./subjective/ComplaintEMRDashboardView";
import EMRDashboardUtil from "./utils/EMRDashboardUtil";

class EMRDashboardSubjective extends Component {
    render() {

        const lastVisit = this.props.visits[this.props.visits.length - 1];
        const {subjective} = lastVisit;

        const complaint = EMRDashboardUtil.getFilledComplaintEntity(this.props.visits, subjective.complaint);

        return (
            <div>
                <div className={"content-label large mt-3"}>
                    Subjective
                </div>
                <div className={"content-wrapper"}>
                    <ComplaintEMRDashboardView complaint={complaint}/>
                </div>
                <div className={"content-wrapper"}>
                    <div style={{display: "flex"}}>
                        <div className="content-label" style={{margin: "auto", marginLeft: "0px", marginBottom: 6}}>Medications</div>
                        <GreenSwitchWithMessage
                            message={MedicationUseChecker.isNone(subjective.medicationUse) ? "No Medication" : ""}
                            checked={MedicationUseChecker.isToggleOn(subjective.medicationUse)}
                        />
                    </div>
                    {MedicationUseChecker.isDataEntered(subjective.medicationUse) &&
                    <div className={"content-card"} style={{margin: "0px"}}>
                        <MedicationUseView medicationUse={subjective.medicationUse}/>
                    </div>
                    }
                </div>
                <div className={"content-wrapper row"}>
                    <div className={"col m-0"}>
                        <MedicalHistoryView medicalHistory={subjective.medicalHistory} dashboardView/>
                    </div>
                    <div className={"col m-0"}>
                        <FamilyHistoryView familyHistory={subjective.familyHistory} dashboardView/>
                    </div>
                </div>


                <div className={"content-wrapper row"}>
                    <div className={"col m-0"}>
                        <SurgicalHistoryView surgicalHistory={subjective.surgicalHistory} dashboardView/>
                    </div>
                </div>
            </div>
        );
    }
}

export default EMRDashboardSubjective;