import React, {Component} from "react";
import "../../VisitHistory.css"
import MovementEnum from "../../../assessment/enums/rangeofmotion/MovementEnum";
import EndFeelEnum from "../../../assessment/enums/rangeofmotion/EndFeelEnum";
import QualityEnum from "../../../assessment/enums/rangeofmotion/QualityEnum";

export default class RangeOfMotionViewHistory extends Component {
    render() {
        if (!this.props.rangeOfMotion || !this.props.rangeOfMotion.items || this.props.rangeOfMotion.items.length === 0) {
            return <div className="pl-3">
                {this.props.rangeOfMotion && this.props.rangeOfMotion.none ? "Normal" : "No information available"}
            </div>;
        }

        return (
            <table className={"visit-history-table"}>
                <thead>
                <tr>
                    <th colSpan={6}>Left</th>
                    <th className={"rangeOfMotionHistoryGreenColumn"}></th>
                    <th colSpan={6}>Right</th>
                </tr>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th style={{width: "50px"}}>Details</th>
                    <th style={{width: "60px"}}>End Feel</th>
                    <th style={{width: "56px"}}>Quality</th>
                    <th style={{width: "35px"}}>Pain</th>
                    <th style={{width: "47px"}}>AROM</th>
                    <th style={{width: "47px"}}>PROM</th>

                    <th className={"rangeOfMotionHistoryGreenColumn"} style={{width: "85px"}}>Movement</th>

                    <th style={{width: "47px"}}>PROM</th>
                    <th style={{width: "47px"}}>AROM</th>
                    <th style={{width: "35px"}}>Pain</th>
                    <th style={{width: "56px"}}>Quality</th>
                    <th style={{width: "60px"}}>End Feel</th>
                    <th style={{width: "50px"}}>Details</th>
                </tr>
                </thead>
                <tbody>
                {this.props.rangeOfMotion.items.map((rowValue, index) => {
                    return (
                        <tr key={"rangeOfMotionRowValue" + index} style={{background: "white"}}>
                            <td>{rowValue.leftDetails}</td>
                            <td>{rowValue.leftEndFeel ? EndFeelEnum[rowValue.leftEndFeel].name : null}</td>
                            <td>{rowValue.leftQuality ? QualityEnum[rowValue.leftQuality].name : null}</td>
                            <td>{rowValue.leftPain}</td>
                            <td>{rowValue.leftAROM}</td>
                            <td>{rowValue.leftPROM}</td>

                            <td className={"rangeOfMotionHistoryGreenColumn"}>{`${rowValue.area} ${rowValue.movement == null && rowValue.movementComment == null ?
                                                                                                                "" :
                                                                                                                MovementEnum[rowValue.movement] != null ?
                                                                                                                    MovementEnum[rowValue.movement].name :
                                                                                                                    rowValue.movementComment}`}
                            </td>

                            <td>{rowValue.rightPROM}</td>
                            <td>{rowValue.rightAROM}</td>
                            <td>{rowValue.rightPain}</td>
                            <td>{rowValue.rightQuality ? QualityEnum[rowValue.rightQuality].name : null}</td>
                            <td>{rowValue.rightEndFeel ? EndFeelEnum[rowValue.rightEndFeel].name : null}</td>
                            <td>{rowValue.rightDetails}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        );
    }
}