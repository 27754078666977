import {Component} from 'react';
import PropTypes from "prop-types";
import {fabric} from "fabric"
import EnumBodySilhouetteSignTypes from "./EnumBodySilhouetteSignTypes";
import omit from "lodash-es/omit";

class Circle extends Component {
    static propTypes = {
        canvas: PropTypes.object,
        top: PropTypes.number,
        left: PropTypes.number,
        scaleX: PropTypes.number,
        scaleY: PropTypes.number,
        angle: PropTypes.number,
        fill: PropTypes.string,
    }

    static defaultProps = {
        top: 320,
        left: 805,
        fill: 'orange',
        scaleX: 0.8,
        scaleY: 0.8,
        type: EnumBodySilhouetteSignTypes.CIRCLE,
    }

    componentDidMount() {
        const options = omit(this.props)
        fabric.Image.fromURL(process.env.PUBLIC_URL + '/assets/forms/circle.png', img => {
            this.props.canvas.add(img)
        }, options)
    }

    render() {
        return null;
    }
}

export default Circle;
