import React, {Component} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import DateUtil from "../../../../utils/DateUtil";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import {Button, Col, Container, Row} from "react-bootstrap";
import {TextInput} from "../../../../components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './PatientBillingInfo.css'
import TextareaInput from "../../../../components/textarea-input/TextareaInput";
import {AutoComplete} from "primereact/autocomplete";
import ActionMenu from "../../../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../../../components/action-menu/ActionMenuItem";
import StringUtil from "../../../../utils/StringUtil";
import ViewIcon from "../../../../components/action-menu/icons/ViewIcon";
import {range} from "lodash-es";
import NotificationService from "../../../../services/notification-service/NotificationService";
import CancelIcon from "../../../../components/action-menu/icons/CancelIcon";
import {withRouter} from "react-router";
import {Dropdown} from "primereact/dropdown";
import ShrinkIcon from "../../../../components/action-menu/icons/ShrinkIcon";
import ExpandIcon from "../../../../components/action-menu/icons/ExpandIcon";
import AuthService from "../../../../services/auth-service/AuthService";
import BillingService from "../../../../services/billing/BillingService";
import EditIcon from "../../../../components/action-menu/icons/EditIcon";
import dialog from "../../../../components/dialog/Dialog";
import DelayReasonModal from "./DelayReasonModal";
import CancelInvoiceModal from "./CancelInvoiceModal";
import CancelInvoiceItemModal from "./CancelInvoiceItemModal";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import {FormField} from "../../../../components/form";
import MoneyUtil from "../../../../utils/MoneyUtil";
import ConfirmIcon from "../../../../components/action-menu/icons/ConfirmIcon";
import NumberUtils from "../../../../utils/NumberUtil";

const CASHIER_ID = 0;

class PatientBillingInfo extends Component {

    constructor(props) {
        super(props);

        const userCanAddRetailItems = AuthService.isManagerOrSuperUser();
        this.staffs = userCanAddRetailItems ?
            [{label: 'Cashier', id: CASHIER_ID}, ...this.props.staffs] :
            [...this.props.staffs]; // Others wont be able to add retail items

        this.state = {
            fullView: !AuthService.userHasAuthority("VIEW_BILLING_INVOICE"),
            items: [],
            selectableItems: [],
            pendingInvoiceItems: [],
            allInvoiceItems: [],
            selectedInvoiceItems: [],
            pendingInvoices: [],
            selectedInvoices: [],
            paymentPendingInvoices: [],
            invoiceItem: {...this.initInvoiceItem()},
            autoCompleted: false,
            staffNameSuggestions: [],
            updateInvoiceItemDto: {},
            selectedInvoiceItemIdForEdit: null
        };
    }

    initInvoiceItem = () => {
        return {
            referrerName: "",
            item: null,
            quantity: 1,
            tax: "",
            note: "",
            referrerId: null,
            providerId: null
        };
    }

    componentDidMount() {
        this.retrievePatientInsurance();
        this.retrieveRetailItems();
        this.retrievePendingInvoices();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.updateInvoicesToggle !== this.props.updateInvoicesToggle)
            this.retrievePendingInvoiceItems();
    }

    retrievePatientInsurance = () => {
        RemotingService.getRemoteCall('api/insurance/' + this.props.patientId + '/insurance', null, (insurance) => {
            this.setState({insurance})
        });
    }

    retrieveCptItems(staffId, callback) {
        RemotingService.getRemoteCall('api/cpt/staff/' + staffId, null, (cptItems) => {
            cptItems.forEach(i => {
                i.category = 'CPT';
                i.code = i.cptCode;
                i.detail = i.code + ' - ' + i.description;
            });
            callback(cptItems);
        });
    }

    retrieveRetailItems() {
        RemotingService.getRemoteCall('api/billing/item', null, (items) => {
            items.forEach(i => {
                i.detail = i.code + ' - ' + i.description;
            });
            this.setState({items}, this.retrievePendingInvoiceItems);
        });
    }

    retrievePendingInvoiceItems() {
        RemotingService.getRemoteCall('api/billing/' + this.props.patientId + '/item/pending', null, (pendingInvoiceItems) => {
            pendingInvoiceItems.map(i => BillingService.hydrateInvoiceItem(i, this.state.items));
            this.setState({pendingInvoiceItems, selectedInvoiceItems: pendingInvoiceItems});
        });
    }

    retrievePendingInvoices() {
        RemotingService.getRemoteCall('api/billing/' + this.props.patientId + '/invoice/pending', null, (pendingInvoices) => {
            pendingInvoices.map(i => BillingService.hydrateInvoice(i, this.state.items));
            this.setState({pendingInvoices});
        });
    }

    retrievePaymentPendingInvoices() {
        RemotingService.getRemoteCall('api/billing/' + this.props.patientId + '/invoice/payment-pending', null, (paymentPendingInvoices) => {
            paymentPendingInvoices.map(i => BillingService.hydrateInvoice(i, this.state.items));
            this.setState({paymentPendingInvoices});
        });
    }

    retrieveNonPendingInvoices() {
        RemotingService.getRemoteCall('api/billing/' + this.props.patientId + '/invoice', null, (allInvoices) => {
            allInvoices.map(i => BillingService.hydrateInvoice(i, this.state.items));
            this.setState({allInvoices});
        });
    }

    toggleView() {
        this.setState({fullView: !this.state.fullView});
    }

    createSelfPayInvoiceItem() {
        if (!ValidationUtil.checkWithNotification(this.invoiceItemFields)) {
            return;
        }

        const {invoiceItem} = this.state;
        const quantity = invoiceItem.quantity;
        const isCptItem = 'CPT' === invoiceItem.item.category;
        const dto = {
            "date": DateUtil.now(),
            "quantity": quantity,
            "itemId": !isCptItem ? invoiceItem.item.id : null,
            "cptId": isCptItem ? invoiceItem.item.id : null,
            "note": invoiceItem.note,
        };

        if (this.state.autoCompleted) {
            dto.referrerId = invoiceItem.referrerId;

        } else {
            dto.referrerName = invoiceItem.referrerName;
        }

        if (invoiceItem.providerId !== CASHIER_ID) {
            dto.providerId = invoiceItem.providerId
        }

        RemotingService.postRemoteCall('api/billing/' + this.props.patientId + '/item', dto, (invoiceItem) => {
            BillingService.hydrateInvoiceItem(invoiceItem, this.state.items);
            this.setState({
                pendingInvoiceItems: [invoiceItem, ...this.state.pendingInvoiceItems],
                selectedInvoiceItems: [invoiceItem, ...this.state.selectedInvoiceItems],
                selectableItems: [],
                invoiceItem: {...this.initInvoiceItem()},
                autoCompleted: false

            }, () => ValidationUtil.clear(this.invoiceItemFields));
        });
    }

    handleProviderChanged = (e) => {
        const providerId = e.value;
        const isCashier = providerId === CASHIER_ID;

        const invoiceItem = {
            ...this.state.invoiceItem,
            item: null,
            providerId: providerId,
        };

        if (isCashier) {
            this.setState({
                invoiceItem: invoiceItem,
                selectableItems: this.state.items
            });
        } else {
            this.retrieveCptItems(providerId, cptItems => {
                this.setState({
                    invoiceItem: invoiceItem,
                    selectableItems: cptItems,
                });
            });
        }
    }

    itemSelected = (item) => {
        this.setState({invoiceItem: {...this.state.invoiceItem, item}});
    }

    staffNameSearchItemTemplate = (staff) => {
        return <>{staff.name}</>;
    }

    staffNameCompleteMethod(event) {
        setTimeout(() => {
            let name = event.query;
            RemotingService.getRemoteCall(`api/staff/list-base?name=${name}`, null, (result) => {
                this.setState({staffNameSuggestions: result.items});
            });
        }, 250)

    }

    handleStaffSearchResultSelect = (event) => {
        let staff = event.value;
        this.setState({
            invoiceItem: {...this.state.invoiceItem, referrerId: staff.id, referrerName: staff.name},
            autoCompleted: true
        })
    }

    deselectStaffSearchResult = () => {
        this.setState({
                invoiceItem: {...this.state.invoiceItem, referrerId: 0, referrerName: ""},
                autoCompleted: false
            }
        );
    }

    cancelInvoiceItem(invoiceItem) {
        dialog({
            title: "Cancel Invoice Item",
            component: <CancelInvoiceItemModal patientId={this.props.patientId}
                                               invoiceItem={invoiceItem}
                                               onSave={(invoiceItem) => this.handleCancelInvoiceItem(invoiceItem)}/>
        });
    }

    editInvoiceItem(invoiceItem) {
        this.setState({updateInvoiceItemDto : invoiceItem});
        this.setState({selectedInvoiceItemIdForEdit: invoiceItem.id});
    }

    saveInvoiceItemChanges(invoiceItem) {
        const validationResult =
            (NumberUtils.isNumeric(invoiceItem.credit) && invoiceItem.credit >= 0 &&
                NumberUtils.isNumeric(invoiceItem.gross) && invoiceItem.gross >= 0 &&
                NumberUtils.isNumeric(invoiceItem.net) && invoiceItem.net >= 0 &&
                NumberUtils.isNumeric(invoiceItem.coPay) && invoiceItem.coPay >= 0 &&
                NumberUtils.isNumeric(invoiceItem.patientPayable) && invoiceItem.patientPayable >= 0
            );

        if (!validationResult) {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Incorrect Values',
                detail: 'Values must be greater than or equal 0'
            });

            return;
        }

        RemotingService.postRemoteCall(
            'api/billing/invoice-item/' + invoiceItem.id + '/update',
            this.state.updateInvoiceItemDto,
            () => {
                this.setState({selectedInvoiceItemIdForEdit: null});
                this.retrievePendingInvoiceItems();
            });
    }

    discardInvoiceItemChanges() {
        this.setState({selectedInvoiceItemIdForEdit: null});
        this.retrievePendingInvoiceItems();
    }

    textInputTemplate = (invoiceItem, fieldName) => {
        return invoiceItem.id === this.state.selectedInvoiceItemIdForEdit ?
            <FormField min={0} validateOn={invoiceItem[fieldName]}>
                <TextInput value={invoiceItem[fieldName]} style={{width: '100%'}}
                           decimals={MoneyUtil.getFractionDigitsWithCountry(invoiceItem["clinic"].country)}
                       onChange={(value) => this.onInvoiceItemFieldChange(invoiceItem, fieldName, value)}/>
            </FormField>
            :
            ((invoiceItem[fieldName] === undefined || invoiceItem[fieldName] === null) ? 0 :
                Number(invoiceItem[fieldName]).toFixed(MoneyUtil.getFractionDigitsWithCountry(invoiceItem["clinic"].country)))

    }

    onInvoiceItemFieldChange(invoiceItem, fieldName , value){
        invoiceItem[fieldName] = value === undefined || value === null ? "" : value;
        if(invoiceItem[fieldName] > 1 && invoiceItem[fieldName] % 1 !== 0){
            invoiceItem[fieldName] = this.formatDecimalsForCountry(value, invoiceItem["clinic"].country);
        }
        this.setState({updateInvoiceItemDto: invoiceItem});
    }

    formatDecimalsForCountry(value, country){
        const maxDecimalLengthForCountry = MoneyUtil.getFractionDigitsWithCountry(country)
        return NumberUtils.toFixedMaxLength(value, maxDecimalLengthForCountry)
    }

    handleCancelInvoiceItem(invoiceItem) {
        this.setState(state => {
            state.pendingInvoiceItems = this.state.pendingInvoiceItems.filter(i => i.id !== invoiceItem.id);
            state.selectedInvoiceItems = this.state.selectedInvoiceItems.filter(i => i.id !== invoiceItem.id);
            return {...state};
        });
    }

    cancelInvoice(invoice) {
        dialog({
            title: "Cancel Invoice",
            component: <CancelInvoiceModal invoice={invoice}
                                           onSave={(invoice) => this.handleCancelInvoice(invoice)}/>
        });
    }

    handleCancelInvoice(invoice) {
        this.setState({selectedInvoices: this.state.selectedInvoices.filter(i => i.id !== invoice.id)});
        this.retrieveNonPendingInvoices();
        this.retrievePendingInvoiceItems();
        this.retrievePendingInvoices();
    }

    handleDelayInvoice(invoice) {
        this.retrievePendingInvoices();
    }

    generateInvoices = () => {
        const invoiceItemIds = this.state.selectedInvoiceItems.map(item => item.id);

        RemotingService.postRemoteCall(
            'api/billing/' + this.props.patientId + '/invoice',
            {invoiceItemIds: invoiceItemIds},
            () => {
                this.retrievePendingInvoiceItems();
                this.retrievePendingInvoices();
            });
    }

    generateBill = () => {
        this.props.history.push({
            pathname: '/bill',
            patientId: this.props.patientId,
            patientName: this.props.patientName,
            patientEmail: this.props.patientEmail,
            invoices: this.state.selectedInvoices,
            mrn: this.props.mrn
        })
    }

    handleSelect(eventKey) {
        switch (eventKey) {
            case 'bills':
                this.retrievePendingInvoiceItems();
                this.retrievePendingInvoices();
                break;
            case 'history':
                this.retrieveNonPendingInvoices();
                break;
            case 'payment-pending':
                this.retrievePaymentPendingInvoices();
                break;
            default:
        }
    }

    selectedClinicCurrency() {
        return this.state.selectedInvoices && this.state.selectedInvoices.length > 0 ? this.state.selectedInvoices[0].clinic.currency : '';
    }

    aggregateInvoiceField = (field, currency) => {
        return MoneyUtil.format(this.state.selectedInvoices.reduce(((a, b) => a + b[field]), 0), currency) + ' ' + currency;
    }

    render() {
        this.invoiceItemFields = [];
        let dataTableHeader = (
            <div style={{'textAlign': 'left', display: 'flex', justifyContent: 'flex-end'}}>
                <div>
                    <TextInput type="search" value={this.state.globalFilter}
                               onChange={(value) => this.setState({globalFilter: value})}
                               placeholder="Search by name, id etc." size="50"
                               style={{marginLeft: '15px'}}/>
                    <i className="pi pi-search" style={{margin: '4px 4px 0 0'}}></i>
                </div>
            </div>
        )

        const fullView = this.state.fullView;
        const currency = this.selectedClinicCurrency();

        return (
            <Container style={{maxWidth: '100%', paddingTop: '10px'}}>
                <Row>
                    <Col xs={12}>
                        <div className="card my-3">
                            <Tabs defaultActiveKey="bills" id="billing-tabs" onSelect={(key) => {
                                this.handleSelect(key)
                            }}>
                                <Tab eventKey="bills" title="Bills">
                                    <div className="container-fluid px-3 pt-3 pb-4">
                                        {AuthService.userHasAuthority("VIEW_BILLING_INVOICE") &&
                                        <div>
                                            <Row className="m-1 d-flex flex-row align-items-baseline">
                                                <Col xs={3}>
                                                    <div style={{display: 'flex', marginTop: '5px'}}>
                                                        <FontAwesomeIcon icon={["fas", "calendar-alt"]}
                                                                         style={{
                                                                             marginRight: '5px',
                                                                             marginTop: '2px'
                                                                         }}/>
                                                        <div>
                                                            <strong>Date</strong> {DateUtil.formatDate(DateUtil.now(), "DD/MM/YYYY")}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xs={1}>
                                                    <strong>Provider:</strong>
                                                </Col>
                                                <Col xs={4}>
                                                    <FormField ref={formField => this.invoiceItemFields.push(formField)}
                                                               required validateOn={this.state.invoiceItem.providerId}>
                                                        <Dropdown className="w-100"
                                                                  value={this.state.invoiceItem.providerId}
                                                                  optionLabel={"label"} optionValue={"id"}
                                                                  options={this.staffs ? this.staffs : []}
                                                                  filter filterBy={"label"}
                                                                  onChange={e => this.handleProviderChanged(e)}
                                                                  placeholder="Select Provider"/>
                                                    </FormField>
                                                </Col>
                                                <Col xs={1}>
                                                    <strong>Referred By:</strong>
                                                </Col>
                                                <Col xs={3} className="d-flex flx-row">
                                                    <AutoComplete value={this.state.invoiceItem.referrerName}
                                                                  className="AddBillingAutoComplete w-100"
                                                                  placeholder="Enter Doctor Name"
                                                                  onChange={(e) => {
                                                                      this.setState({
                                                                          invoiceItem: {
                                                                              ...this.state.invoiceItem,
                                                                              referrerName: e.value
                                                                          }
                                                                      })
                                                                  }}
                                                                  suggestions={this.state.staffNameSuggestions}
                                                                  itemTemplate={this.staffNameSearchItemTemplate}
                                                                  completeMethod={this.staffNameCompleteMethod.bind(this)}
                                                                  onSelect={this.handleStaffSearchResultSelect}
                                                                  disabled={this.state.autoCompleted}
                                                    />
                                                    {this.state.autoCompleted ?
                                                        <FontAwesomeIcon icon={["fas", "times-circle"]} size={"2x"}
                                                                         color={"red"}
                                                                         style={{
                                                                             marginTop: "5px",
                                                                             marginLeft: "5px"
                                                                         }}
                                                                         onClick={() => this.deselectStaffSearchResult()}/> : null}
                                                </Col>
                                            </Row>
                                            <Row className="m-1 mt-3">
                                                <Col xs={3} className="ItemDropdown display-flex flex-column">
                                                    <strong>Item</strong>
                                                    <FormField ref={formField => this.invoiceItemFields.push(formField)}
                                                               required validateOn={this.state.invoiceItem.item}>
                                                        <Dropdown value={this.state.invoiceItem.item}
                                                                  options={this.state.selectableItems}
                                                                  optionLabel="detail" dataKey="id"
                                                                  filter filterBy="detail"
                                                                  onChange={(e) => this.itemSelected(e.value)}
                                                                  style={{fontSize: "10px"}}
                                                                  placeholder={"Select Item"}
                                                        />
                                                    </FormField>
                                                </Col>
                                                <Col xs={1} className="display-flex flex-column">
                                                    <strong>Quantity</strong>
                                                    <Dropdown options={range(1, 11)}
                                                              value={this.state.invoiceItem.quantity}
                                                              onChange={e => this.setState({
                                                                  invoiceItem: {
                                                                      ...this.state.invoiceItem,
                                                                      quantity: e.value
                                                                  }
                                                              })}
                                                              style={{maxWidth: 75}}
                                                    />
                                                </Col>
                                                <Col xs={1} className="display-flex flex-column text-center">
                                                    <strong>Code</strong>
                                                    <span>{this.state.invoiceItem.item?.code || "-"}</span>
                                                </Col>
                                                <Col xs={1} className="display-flex flex-column text-center">
                                                    <strong>Gross</strong>
                                                    <span>{this.state.invoiceItem.item ? this.state.invoiceItem.item.cash * this.state.invoiceItem.quantity : "-"}</span>
                                                </Col>
                                                <Col xs={1} className="display-flex flex-column text-center">
                                                    <strong>Net</strong>
                                                    <span>{this.state.invoiceItem.item ? this.state.invoiceItem.item.cash * this.state.invoiceItem.quantity : "-"}</span>
                                                </Col>
                                                <Col xs={1} className="display-flex flex-column text-center">
                                                    <strong>Tax</strong>
                                                    <span>{this.state.invoiceItem.item && this.state.invoiceItem.item.tax ? `${this.state.invoiceItem.item.tax} %` : "-"}</span>
                                                </Col>
                                                <Col xs={1} className="display-flex flex-column">
                                                    <strong>Notes:</strong>
                                                </Col>
                                                <Col xs={3} className="display-flex flex-column">
                                                    <TextareaInput className="BillingNotes"
                                                                   value={this.state.invoiceItem.note}
                                                                   onChange={value =>
                                                                       this.setState({
                                                                           invoiceItem: {
                                                                               ...this.state.invoiceItem,
                                                                               note: value
                                                                           }
                                                                       })}/>
                                                </Col>
                                            </Row>
                                            <div className="ro d-flex p-justify-end">
                                                <Col xs={1} className="display-flex flex-column">
                                                    <Button className="w-100" variant={"success"}
                                                            onClick={() => this.createSelfPayInvoiceItem()}>
                                                        Add Item
                                                    </Button>
                                                </Col>
                                            </div>
                                        </div>
                                        }
                                        <Row>
                                            <Col xs={fullView ? 12 : 10}>
                                                <Row style={{padding: '5px'}}>
                                                    <Col xs={12} style={{marginTop: '5px'}}>
                                                        <div className={"billingSummaryRow"}>
                                                            <h5 className="mt-1 font-weight-bold">Invoice Items</h5>
                                                            {AuthService.userHasAuthority("VIEW_BILLING_INVOICE") &&
                                                            <ActionMenuItem
                                                                icon={fullView ? <ShrinkIcon/> : <ExpandIcon/>}
                                                                text={fullView ? 'Compact View' : 'Full View'}
                                                                closeOnClick={false}
                                                                onClick={() => {
                                                                    this.toggleView()
                                                                }}/>
                                                            }
                                                        </div>
                                                        <DataTable className="InvoiceItemsTable"
                                                                   header={dataTableHeader}
                                                                   globalFilter={this.state.globalFilter}
                                                                   emptyMessage={"No results found"}
                                                                   value={this.state.pendingInvoiceItems}
                                                                   rowHover={true} selectionMode={"multiple"}
                                                                   selection={this.state.selectedInvoiceItems}
                                                                   onSelectionChange={e => {
                                                                       let newList = [...this.state.selectedInvoiceItems];
                                                                       let selectedRow = e.value[0];
                                                                       if (newList.includes(selectedRow)) {
                                                                           newList.splice(newList.indexOf(selectedRow), 1);
                                                                       } else {
                                                                           newList.push(e.value[0]);
                                                                       }
                                                                       this.setState({selectedInvoiceItems: newList})
                                                                   }}
                                                                   onRowToggle={(e) => {
                                                                       let selectedRowIds = Object.keys(e.data);
                                                                       let selectedRows = [];
                                                                       selectedRowIds.forEach((id) => {
                                                                           let toggledRow = this.state.pendingInvoiceItems.filter(i => i.id === id)[0];
                                                                           selectedRows.push(toggledRow)
                                                                       })
                                                                       this.setState({
                                                                           ...this.state,
                                                                           selectedInvoiceItems: selectedRows
                                                                       })
                                                                   }}>
                                                            {BillingService.invoiceItemColumns(this.state.fullView, false, this.textInputTemplate)}
                                                            {AuthService.userHasAuthority("EDIT_BILLING_INVOICE") &&
                                                            <Column header="" body={(invoiceItem) => {
                                                                return (
                                                                    (AuthService.isManagerOrSuperUser()) &&
                                                                    <ActionMenu id="invoiceItemActionPanel">
                                                                        {  ((AuthService.isAdmin() && StringUtil.isNullOrEmpty(invoiceItem.visitId)) || AuthService.isFromManagement())  &&
                                                                        <ActionMenuItem
                                                                            icon={<CancelIcon/>}
                                                                            onClick={() => {
                                                                                this.cancelInvoiceItem(invoiceItem)
                                                                            }} text={"Cancel Invoice"}/>}
                                                                        {(this.state.selectedInvoiceItemIdForEdit === null) && <ActionMenuItem
                                                                            icon={<EditIcon/>}
                                                                            onClick={() => {
                                                                                this.editInvoiceItem(invoiceItem)
                                                                            }} text={"Edit"}/>}
                                                                        {(invoiceItem.id === this.state.selectedInvoiceItemIdForEdit) &&
                                                                        <ActionMenuItem
                                                                            icon={<ConfirmIcon/>}
                                                                            onClick={() => {
                                                                                this.saveInvoiceItemChanges(invoiceItem)
                                                                            }} text={"Save"}/>}
                                                                        {(invoiceItem.id === this.state.selectedInvoiceItemIdForEdit) &&
                                                                        <ActionMenuItem
                                                                            icon={<CancelIcon/>}
                                                                            onClick={() => {
                                                                                this.discardInvoiceItemChanges()
                                                                            }} text={"Discard Changes"}/>}
                                                                    </ActionMenu>
                                                                )
                                                            }}/>
                                                            }
                                                        </DataTable>
                                                    </Col>
                                                </Row>
                                                {AuthService.userHasAuthority("VIEW_BILLING_INVOICE") &&
                                                <Row>
                                                    <Col xs={12} className="text-right" style={{padding: '0px'}}>
                                                        <Button className={"GenerateInvoices"} variant={"success"}
                                                                style={{margin: '10px 15px 0 0'}}
                                                                disabled={this.state.selectedInvoiceItems.length === 0}
                                                                onClick={() => this.generateInvoices()}>
                                                            Generate Invoices
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                }
                                                <Row style={{padding: '5px'}}>
                                                    <Col xs={12}>
                                                        <div className={"billingSummaryRow"}>
                                                            <h5 className="mt-1 font-weight-bold">Invoices</h5>
                                                        </div>
                                                        <DataTable className="InvoiceTable" responsive autoLayout
                                                                   emptyMessage={"No results found"}
                                                                   value={this.state.pendingInvoices}
                                                                   rowHover={true} selectionMode={"multiple"}
                                                                   expandedRows={this.state.expandedInvoices}
                                                                   rowExpansionTemplate={(invoice) => BillingService.invoiceItemsExpansionTemplate(invoice, fullView, this.textInputTemplate)}
                                                                   selection={this.state.selectedInvoices}
                                                                   onSelectionChange={e => {
                                                                       let newList = [...this.state.selectedInvoices];
                                                                       let selectedRow = e.value[0];
                                                                       if (newList.some(value => value.id === selectedRow.id)) {
                                                                           for(const [index, value] of newList.entries()){
                                                                               if(value.id === selectedRow.id)
                                                                                   newList.splice(index, 1);
                                                                           }
                                                                       } else {
                                                                           newList.push(e.value[0]);
                                                                       }
                                                                       let uniqueClinics = [...new Set(newList.map(i => i.clinic.id))];
                                                                       let uniqueVisits = [...new Set(newList.map(i => i.visitId).filter(i => i != null))];
                                                                       if (uniqueClinics.length > 1) {
                                                                           NotificationService.showNotification({
                                                                               severity: 'warn',
                                                                               summary: 'Invoices for Multiple Clinics',
                                                                               detail: 'Select invoices for a single clinic.'
                                                                           });
                                                                       } else if (uniqueVisits.length > 1) {
                                                                           NotificationService.showNotification({
                                                                               severity: 'warn',
                                                                               summary: 'Invoices for Multiple Visits',
                                                                               detail: 'Select invoices for a single visit.'
                                                                           });
                                                                       } else {
                                                                           this.setState({selectedInvoices: newList})
                                                                       }
                                                                   }}
                                                                   onRowToggle={(e) => {
                                                                       this.setState({
                                                                           ...this.state,
                                                                           expandedInvoices: e.data,
                                                                       })
                                                                   }}
                                                                   dataKey="id">
                                                            {BillingService.invoiceColumns()}
                                                            {AuthService.userHasAuthority("VIEW_BILLING_INVOICE") &&
                                                            <Column header="" body={(invoice) => {
                                                                return (<ActionMenu id="invoiceActionPanel">
                                                                    <ActionMenuItem
                                                                        icon={<CancelIcon/>}
                                                                        onClick={() => {
                                                                            this.cancelInvoice(invoice)
                                                                        }} text={"Cancel"}/>
                                                                    <ActionMenuItem
                                                                        icon={<EditIcon/>}
                                                                        onClick={() => {
                                                                            dialog({
                                                                                title: "Set Delay Reason for Invoice",
                                                                                component: <DelayReasonModal
                                                                                    invoice={invoice}
                                                                                    onSave={(invoice) => this.handleDelayInvoice(invoice)}/>
                                                                            });
                                                                        }}
                                                                        text={"Set Delay Reason"}/>
                                                                </ActionMenu>)
                                                            }}/>
                                                            }
                                                        </DataTable>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            {AuthService.userHasAuthority("VIEW_BILLING_INVOICE") && !fullView &&
                                            <Col xs={2} className="p-col-align-end">
                                                <div className="card display-flex flex-column p-justify-between p-2">
                                                    <div className="billingSummaryHeader">
                                                        <span> Billing Summary</span>
                                                        <ActionMenu id="billingSummaryActionPanel">
                                                            <ActionMenuItem icon={<ViewIcon/>} text={"View"}
                                                                            onClick={this.generateBill}/>
                                                        </ActionMenu>
                                                    </div>
                                                    <div className="billingSummary billingSummaryRow">
                                                        <div>Gross</div>
                                                        <div>{this.aggregateInvoiceField('gross', currency)}</div>
                                                    </div>
                                                    <div className="billingSummary billingSummaryRow">
                                                        <div>Net</div>
                                                        <div>{this.aggregateInvoiceField('net', currency)}</div>
                                                    </div>
                                                    <div className="billingSummary billingSummaryRow">
                                                        <div>Co-Pay</div>
                                                        <div>{this.aggregateInvoiceField('coPay', currency)}</div>
                                                    </div>
                                                    <div className="billingSummary billingSummaryRow">
                                                        <div>Sponsorship</div>
                                                        <div>{this.aggregateInvoiceField('sponsorship', currency)}</div>
                                                    </div>
                                                    <div className="billingSummary billingSummaryRow">
                                                        <div>Tax</div>
                                                        <div>{this.aggregateInvoiceField('tax', currency)}</div>
                                                    </div>
                                                    <div className="billingSummary billingSummaryRow">
                                                        <div>Credit</div>
                                                        <div>{this.aggregateInvoiceField('credit', currency)}</div>
                                                    </div>
                                                    <div className="billingSummary billingSummaryRow"
                                                         style={{fontWeight: 'bold', fontSize: '14'}}>
                                                        <div>Patient Payable</div>
                                                        <div style={{color: 'red'}}>
                                                            {this.aggregateInvoiceField('patientPayable', currency)}
                                                        </div>
                                                    </div>
                                                    <div className="text-center">
                                                        <Button className={"AddInvoiceButton"} variant={"success"}
                                                                style={{'marginTop': '20px'}}
                                                                disabled={this.state.selectedInvoices.length === 0}
                                                                onClick={this.generateBill}>
                                                            Generate Bill
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>}
                                        </Row>
                                    </div>
                                </Tab>
                                <Tab eventKey="payment-pending" title="Online/Insurance">
                                    <div className="card-body" style={{padding: '16px'}}>
                                        <DataTable className="p-datatable-striped InvoiceTable" responsive autoLayout
                                                   emptyMessage={"No results found"}
                                                   value={this.state.paymentPendingInvoices}
                                                   expandedRows={this.state.expandedPendingInvoices}
                                                   rowExpansionTemplate={(invoice) => BillingService.invoiceItemsExpansionTemplate(invoice, true , this.textInputTemplate)}
                                                   onRowToggle={(e) => {
                                                       this.setState({
                                                           ...this.state,
                                                           expandedPendingInvoices: e.data,
                                                       })
                                                   }}
                                                   rowClassName={BillingService.disabledRowClassFunction}
                                                   dataKey="id">
                                            {BillingService.invoiceColumns()}
                                            <Column header="Credit Received" field="creditReceived" sortable/>
                                            <Column header="Reconciliation Status" field="insurancePaymentStatus"
                                                    body={BillingService.insurancePaymentStatusTemplate} sortable/>
                                        </DataTable>
                                    </div>
                                </Tab>
                                <Tab eventKey="history" title="History">
                                    <div className="card-body" style={{padding: '16px'}}>
                                        <DataTable className="p-datatable-striped InvoiceTable" responsive autoLayout
                                                   emptyMessage={"No results found"}
                                                   value={this.state.allInvoices}
                                                   expandedRows={this.state.expandedInvoices}
                                                   rowExpansionTemplate={(invoice) => BillingService.invoiceItemsExpansionTemplate(invoice, true , this.textInputTemplate)}
                                                   onRowToggle={(e) => {
                                                       this.setState({
                                                           ...this.state,
                                                           expandedInvoices: e.data,
                                                       })
                                                   }}
                                                   rowClassName={BillingService.disabledRowClassFunction}
                                                   dataKey="id">
                                            {BillingService.invoiceColumns()}
                                            {AuthService.userHasAuthority("VIEW_BILLING_INVOICE") &&
                                            <Column header="" body={(invoice) => {
                                                return (<ActionMenu id="invoiceHistoryActionPanel">
                                                    <ActionMenuItem
                                                        icon={<CancelIcon/>}
                                                        disabled={'BILLED' !== invoice.status}
                                                        onClick={() => {
                                                            this.cancelInvoice(invoice)
                                                        }} text={"Cancel"}/>
                                                </ActionMenu>)
                                            }}/>
                                            }
                                        </DataTable>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withRouter(PatientBillingInfo);
