import React, {Component} from 'react';
import IntakeFormTextEnum from "../enum/IntakeFormTextEnum";
import {Dropdown} from "primereact/dropdown";
import IntakeFormReferenceTypeEnum from "../enum/IntakeFormReferenceTypeEnum";
import {AutoComplete} from "primereact/autocomplete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TextInput} from "../../../../components";
import RemotingService from "../../../../services/remoting-service/RemotingService";

class PublicIntakeFormReferralInformation extends Component {

    state = {}

    referenceTypeTemplate = (option) => {
        let itemIcon = <div style={{marginLeft: "19px"}}></div>;

        if (option.value === IntakeFormReferenceTypeEnum.TWITTER.key) {
            itemIcon = (
                <FontAwesomeIcon
                    icon={["fab", "twitter"]}
                    style={{marginTop: "4px"}}
                    size={"lg"}
                />
            );
        } else if (option.value === IntakeFormReferenceTypeEnum.FACEBOOK.key) {
            itemIcon = (
                <FontAwesomeIcon
                    icon={["fab", "facebook"]}
                    style={{marginTop: "2px"}}
                    size={"lg"}
                />
            );
        } else if (option.value === IntakeFormReferenceTypeEnum.INSTAGRAM.key) {
            itemIcon = (
                <FontAwesomeIcon
                    icon={["fab", "instagram-square"]}
                    style={{marginLeft: "2px", marginTop: "2px"}}
                    size={"lg"}
                />
            );
        }

        return (
            <div className="d-flex">
                {itemIcon}
                <div className="mx-2">{option.label}</div>
            </div>
        );
    };

    referenceLabel = (referenceType, selectedLanguage) => {
        switch (referenceType) {
            case IntakeFormReferenceTypeEnum.FAMILYFRIENDS.key:
                return IntakeFormTextEnum.Family_Friend_Name[selectedLanguage];

            case IntakeFormReferenceTypeEnum.DRHOSPITAL.key:
                return IntakeFormTextEnum.Doctor_Name[selectedLanguage];

            case IntakeFormReferenceTypeEnum.INSTAGRAM.key:
            case IntakeFormReferenceTypeEnum.FACEBOOK.key:
            case IntakeFormReferenceTypeEnum.TWITTER.key:
                return IntakeFormTextEnum.Account_name[selectedLanguage];

            case IntakeFormReferenceTypeEnum.OTHER.key:
                return IntakeFormTextEnum.Other[selectedLanguage];

            default:
        }
    };

    staffNameSearchItemTemplate = (staff) => {
        return <>{staff.name + (staff.hospital ? " - " + staff.hospital  : "")}</>;
    };

    staffNameCompleteMethod = (event) => {
        const {consentFormCode: code,patientId} = this.props;
        setTimeout(() => {
            RemotingService.getRemoteCall(
                `api/public/consent-form/code/${code}/references?name=${event.query}&patientId=${patientId}`,
                null,
                (staffNameSuggestions) => {
                    this.setState({staffNameSuggestions});
                }
            );
        }, 250);
    };

    handleStaffSearchResultSelect = (event) => {
        const staff = event.value;

        const {form} = this.props;

        form.referenceName = staff.name;
        form.referenceHospital = staff.hospital;

        this.setState({
            autoCompleted: true,
        });
    };

    deselectStaffSearchResult = () => {
        const {form} = this.props;

        form.referenceName = "";
        form.referenceHospital = "";

        this.setState({
            autoCompleted: false,
        });
    };

    render() {
        const {selectedLanguage, enumLabel, form, onChange} = this.props;

        return (
            <>
                <div className="form-group row">
                    <label className="col-3 col-form-label">
                        {IntakeFormTextEnum.How_Did_You_Hear_About_The_Clinic[selectedLanguage]}
                    </label>
                    <div className="col">
                        <Dropdown
                            className="reference-dropdown DropdownBorderColor w-100"
                            showClear
                            displayProperty="label"
                            valueProperty="value"
                            value={form.referenceType}
                            options={IntakeFormReferenceTypeEnum.toArray().map(v => {
                                return {value: v.key, label: v[enumLabel]}
                            })}
                            itemTemplate={this.referenceTypeTemplate}
                            onChange={(event) => {
                                form.referenceName = "";
                                form.referenceHospital = "";
                                form.referenceSubject = "";
                                form.referenceType = event.value;
                                this.forceUpdate();
                            }}
                        />
                    </div>
                </div>
                {form.referenceType && (
                    <div className="row form-group">
                        <label className="col-3 col-form-label">
                            {this.referenceLabel(form.referenceType)}
                        </label>
                        <div className="col-9">
                            {IntakeFormReferenceTypeEnum.DRHOSPITAL.key === form.referenceType ? (
                                <div style={{display: "flex"}}>
                                    <AutoComplete
                                        id={"referenceNameAutoComplete"}
                                        value={form.referenceName}
                                        placeholder={IntakeFormTextEnum.Doctor_Name[selectedLanguage]}
                                        onChange={(e) => {
                                            onChange("referenceName", e.value);
                                        }}
                                        suggestions={this.state.staffNameSuggestions}
                                        itemTemplate={this.staffNameSearchItemTemplate}
                                        completeMethod={this.staffNameCompleteMethod}
                                        onSelect={this.handleStaffSearchResultSelect}
                                        disabled={this.state.autoCompleted}
                                        className="AddReferenceAutoComplete"
                                        style={{minWidth: "100px"}}
                                    />
                                    {this.state.autoCompleted && (
                                        <FontAwesomeIcon
                                            icon={["fas", "times-circle"]}
                                            size={"2x"}
                                            color={"red"}
                                            style={{marginTop: "5px", marginLeft: "5px"}}
                                            onClick={this.deselectStaffSearchResult}
                                        />
                                    )}
                                </div>
                            ) : (
                                <TextInput
                                    className="form-control"
                                    value={form.referenceName}
                                    placeholder={this.referenceLabel(form.referenceType, selectedLanguage)}
                                    onChange={(value) =>
                                        onChange("referenceName", value)
                                    }
                                />
                            )}
                        </div>
                    </div>
                )}
                {IntakeFormReferenceTypeEnum.DRHOSPITAL.key === form.referenceType && (
                    <div className="row form-group">
                        <label
                            className="col-3 col-form-label">{IntakeFormTextEnum.Hospital_Name[selectedLanguage]}</label>
                        <div className="col-9">
                            <div className="d-flex">
                                <AutoComplete
                                    id={"referenceHospitalAutoComplete"}
                                    value={form.referenceHospital}
                                    placeholder={IntakeFormTextEnum.Hospital_Name[selectedLanguage]}
                                    onChange={(e) => {
                                        onChange("referenceHospital", e.value);
                                    }}
                                    disabled={this.state.autoCompleted}
                                    className="AddReferenceAutoComplete"
                                />
                                {this.state.autoCompleted ? (
                                    <FontAwesomeIcon
                                        icon={["fas", "times-circle"]}
                                        size={"2x"}
                                        color={"red"}
                                        style={{marginTop: "5px", marginLeft: "5px"}}
                                        onClick={this.deselectStaffSearchResult}
                                    />
                                ) : null}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
}

export default PublicIntakeFormReferralInformation;