import React, {Component} from 'react';
import moment from "moment";
import StringUtil from "../../utils/StringUtil";
import {Wizard} from "../../components";
import RemotingService from "../../services/remoting-service/RemotingService";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import PatientUtil from "../../utils/PatientUtil";
import ConsentFormPartsKwt from "./utils/ConsentFormPartsKwt";
import FormLanguages from "./enum/FormLanguages";
import InformedConsentHeader from "./informedconsent/InformedConsentHeader";
import InformedConsentSignatureInputs from "./informedconsent/InformedConsentSignatureInputs";
import InformedConsentFormKwtTextEnum from "./informedconsent/enum/InformedConsentFormKwtTextEnum";
import LogoKuwait from "../../layout/LogoKuwait";

class InformedConsentFormKwt extends Component {

    constructor(props) {
        super(props)

        this.state = {
            consentDate: new Date()
        }
    }

    componentDidMount() {
        const {patient, form} = this.props;
        const readOnly = form !== null && form !== undefined;

        RemotingService.getRemoteCall('api/appointment/first-provider/KUWAIT/' + patient.id, {},
            (firstProvider) => {
                if (firstProvider) {
                    this.setState({providerName: firstProvider.name, providerSignature: firstProvider.signature});
                }
            });
    }

    onSubmit = () => {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        if (this.props.form !== null && this.props.form !== undefined) {
            this.props.close();

        } else {
            let patient = this.props.patient;
            let patientFullName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
            let patientDOB = patient.birthday;
            let patientEId = patient.eid;
            const {
                consentDate,
                providerName,
                guardianName,
                guardianRelationship,
                providerSignature,
                patientSignature
            } = this.state;

            RemotingService.postRemoteCall('api/patient/' + this.props.patient.id + '/general-info-consent-kwi',
                {
                    consentDate, providerName, guardianName,
                    guardianRelationship, providerSignature, patientSignature,
                    patientFullName, patientDOB, patientEId
                },
                (result) => {
                    this.props.afterSave(result);
                    this.props.close();
                });
        }
    }

    onSavePatientSignature = (signature) => {
        this.setState({patientSignature: signature})
    }

    fieldChanged = (fieldName, value, validationCallback) => {
        this.setState({[fieldName]: value}, () => {
            if (validationCallback) {
                validationCallback(this);
            }
        });
    };

    pushFormFieldRef = (ref) => this.formFields.push(ref);

    render() {
        if (!this.props.show)
            return null;

        let {patient, form, selectedLanguage} = this.props;
        let readOnly = form !== null && form !== undefined;
        let mrnNo = StringUtil.mrn(patient.mrn);
        let patientName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
        this.formFields = []

        const formBodyClass = selectedLanguage == FormLanguages.ARABIC ? "arabic-form form-body" : "form-body";
        const commonProps = {
            selectedLanguage: selectedLanguage.name,
            pushFormFieldRef: this.pushFormFieldRef
        }

        return (
            <>
                <Wizard
                    show={this.props.show}
                    close={this.props.close}
                    title={InformedConsentFormKwtTextEnum.Informed_Consent_Form[selectedLanguage.name]}
                    onSubmit={this.onSubmit}
                    isReadOnly={readOnly}
                    previousBtnText={InformedConsentFormKwtTextEnum.Wizard_Previous[selectedLanguage.name]}
                    nextBtnText={InformedConsentFormKwtTextEnum.Wizard_Next[selectedLanguage.name]}
                    saveBtnText={InformedConsentFormKwtTextEnum.Wizard_Save[selectedLanguage.name]}
                >
                    <Wizard.Page>
                        <div className={formBodyClass}>
                            <InformedConsentHeader Logo={LogoKuwait} mrnNo={mrnNo} patient={patient} {...commonProps}/>

                            <ConsentFormPartsKwt.Introduction {...commonProps}/>
                            <ConsentFormPartsKwt.Benefits {...commonProps}/>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page>
                        <div className={formBodyClass}>
                            <ConsentFormPartsKwt.RisksIntro {...commonProps}/>
                            <ul>
                                <ConsentFormPartsKwt.RisksItem1 {...commonProps}/>
                                <ConsentFormPartsKwt.RisksItem2 {...commonProps}/>
                                <ConsentFormPartsKwt.RisksItem3 {...commonProps}/>
                                <ConsentFormPartsKwt.RisksItem4 {...commonProps}/>
                            </ul>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page>
                        <div className={formBodyClass}>
                            <ul>
                                <ConsentFormPartsKwt.RisksItem5 {...commonProps}/>
                                <ConsentFormPartsKwt.RisksItem6 {...commonProps}/>
                                <ConsentFormPartsKwt.RisksItem7 {...commonProps}/>
                            </ul>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page>
                        <div className={formBodyClass}>
                            <ConsentFormPartsKwt.Alternatives {...commonProps}/>
                            <ConsentFormPartsKwt.InjectionAndMedication {...commonProps}/>
                            <ConsentFormPartsKwt.DataCollectionAndPrivacyTitle {...commonProps}/>
                            <ConsentFormPartsKwt.DataCollectionAndPrivacyParagraph1 {...commonProps}/>
                            <ConsentFormPartsKwt.DataCollectionAndPrivacyParagraph2 {...commonProps}/>
                            <ConsentFormPartsKwt.DataCollectionAndPrivacyParagraph3 {...commonProps}/>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page>
                        <div className={formBodyClass}>
                            <ConsentFormPartsKwt.DataCollectionAndPrivacyParagraph4 {...commonProps}/>
                            <ConsentFormPartsKwt.DataCollectionAndPrivacyParagraph5 {...commonProps}/>
                            <ConsentFormPartsKwt.DataCollectionAndPrivacyParagraph6 {...commonProps}/>
                            <ConsentFormPartsKwt.QuestionsOfConcerns {...commonProps}/>
                            <ConsentFormPartsKwt.Complaints {...commonProps}/>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page onPrevious={() => (this.formFields = [])}>
                        <div className={formBodyClass}>
                            <ConsentFormPartsKwt.Conclusion {...commonProps}/>
                            <InformedConsentSignatureInputs {...commonProps}
                                                            readOnly={readOnly}
                                                            formFields={this.formFields}
                                                            textEnum={InformedConsentFormKwtTextEnum}
                                                            consentDate={moment(readOnly ? new Date(form.consentDate) : this.state.consentDate).format('LL')}
                                                            patientName={patientName}
                                                            providerName={readOnly ? form.providerName : this.state.providerName}
                                                            guardianName={readOnly ? form.guardianName : this.state.guardianName}
                                                            guardianRelationship={readOnly ? form.guardianRelationship : this.state.guardianRelationship}
                                                            patientSignature={readOnly ? form.patientSignature : this.state.patientSignature}
                                                            providerSignature={readOnly ? form.providerSignature : this.state.providerSignature}
                                                            onSavePatientSignature={this.onSavePatientSignature}
                                                            onChange={this.fieldChanged}/>
                        </div>
                    </Wizard.Page>
                </Wizard>
            </>
        );
    }

};

export default InformedConsentFormKwt;
