import React, {Component} from "react";
import '../Subjective.css';
import '../../EMR.css';
import MedicationTypeEnum from "../enums/medicationuse/MedicationTypeEnum";
import FrequencyEnum from "../enums/medicationuse/FrequencyEnum";
import DateUtil from "../../../../../utils/DateUtil";
import * as ArrayUtil from "../../../../../utils/ArrayUtil";
import PrescriptionTimeUnitEnum from "../../prescription/enums/PrescriptionTimeUnitEnum";
import {connect} from "react-redux";

class MedicationUseView extends Component {

    render() {
        if (ArrayUtil.isEmpty(this.props.prescriptions) && ArrayUtil.isEmpty(this.props.medicationUse?.items)) {
            return (<></>);
        }

        let prescriptionItems = [];
        if (this.props.prescriptions) {
            this.props.prescriptions.forEach(p => {
                p.prescriptionItems.forEach(pi => {
                    pi.staff = p.staff;
                    prescriptionItems.push(pi);
                });
            });
        }

        return (
            <table className={"emr-table"}>
                <tbody>
                <tr>
                    <th>Type</th>
                    <th>Name</th>
                    <th>Start Date</th>
                    <th>Prescribing Doctor</th>
                    <th>Condition</th>
                    <th>Dose</th>
                    <th>Unit</th>
                    <th>Frequency</th>
                    <th>Comment</th>
                </tr>
                {this.props.medicationUse.items.map((m, index) => {
                    return (<tr key={"medicationUseView" + index} style={{backgroundColor: "white"}}>
                        <td>{m.type == null ? "" : MedicationTypeEnum[m.type].name}</td>
                        <td>{m.name}</td>
                        <td>{m.startDate == null ? "" : DateUtil.formatDateMonthAndYear(m.startDate)}</td>
                        <td>{m.prescribingDoctor}</td>
                        <td>{m.condition}</td>
                        <td>{m.dose}</td>
                        <td>{m.unit?.name}</td>
                        <td>{m.frequency == null ? "" : FrequencyEnum[m.frequency].name}</td>
                        <td>{m.comment}</td>
                    </tr>)
                })}
                {prescriptionItems?.map((pi, index) => {
                    return (<tr key={"prescriptionItem" + index} style={{backgroundColor: "#E0E0E0"}}>
                        <td>-</td>
                        <td>{pi.brand}</td>
                        <td>{DateUtil.formatDateMonthAndYear(pi.startDate)}</td>
                        <td>{pi.staff.name}</td>
                        <td>-</td>
                        <td>{pi.strength} {pi.form}</td>
                        <td>{pi.uom?.name}</td>
                        <td>{pi.frequency && pi.frequency.value && pi.frequency.unit ?
                            pi.frequency.value + " per " + PrescriptionTimeUnitEnum[pi.frequency.unit].name : null}
                        </td>
                        <td>{pi.instruction}</td>
                    </tr>)
                })}
                </tbody>
            </table>

        );
    }
}

function mapStateToProps(state) {
    return {
        prescriptions: state.base.prescriptions
    };
}

export default connect(mapStateToProps)(MedicationUseView);
