import * as ArrayUtils from "../../../../../utils/ArrayUtil";
import React from "react";


const treatmentInfoSections = [
    {fieldName: "manipulation"},
    {fieldName: "mobilization"},
    {fieldName: "stretching"},
    {fieldName: "dryNeedling"},
    {fieldName: "acupuncture"},
    {fieldName: "electrotherapy"},
    {fieldName: "shockwave"},
    {fieldName: "cupping"},
    {fieldName: "taping"},
    {fieldName: "therapeuticMassage"},
    {fieldName: "lymphaticDrainage"},
    {fieldName: "thermalTherapy"},
    {fieldName: "therapeuticExercise"},
    {fieldName: "gaitTraining"},
    {fieldName: "breathingPatterns"},
    {fieldName: "other"},
]

const getTreatmentReceivedProcedureList = (item) => {
    return (
        <ul className="mb-0">
            {item.patientConsent &&
            <li>Patient consent. Written consent was obtained after discussion of risks, benefits and
                alternatives.</li>}
            {item.vitalSigns && (<li>Vital Signs: <ul>
                {(!ArrayUtils.isEmpty(item.preProcedureTemp) || !ArrayUtils.isEmpty(item.preProcedureBP) || !ArrayUtils.isEmpty(item.preProcedurePulse)) &&
                <li>Pre-Procedure:
                    {!ArrayUtils.isEmpty(item.preProcedureTemp) && <> Temp: <b>{item.preProcedureTemp}</b></>}
                    {!ArrayUtils.isEmpty(item.preProcedureBP) && <> BP: <b>{item.preProcedureBP}</b></>}
                    {!ArrayUtils.isEmpty(item.preProcedurePulse) && <> Pulse: <b>{item.preProcedurePulse}</b></>}
                </li>
                }
                {(!ArrayUtils.isEmpty(item.postProcedureTemp) || !ArrayUtils.isEmpty(item.postProcedureBP) || !ArrayUtils.isEmpty(item.postProcedurePulse)) &&
                <li>Post-Procedure:
                    {!ArrayUtils.isEmpty(item.postProcedureTemp) && <> Temp: <b>{item.postProcedureTemp}</b></>}
                    {!ArrayUtils.isEmpty(item.postProcedureBP) && <> BP: <b>{item.postProcedureBP}</b></>}
                    {!ArrayUtils.isEmpty(item.postProcedurePulse) && <> Pulse: <b>{item.postProcedurePulse}</b></>}
                </li>
                }
            </ul></li>)}
            {item.requiredEquipment &&
            <li>Required equipment and materials prepared (syringe, needles, centrifuge machine)</li>}
            {item.patientPrepared &&
            <li>Patient prepared for procedure. Position of patient during procedure <b>{item.positionOfPatient}</b>
            </li>}
            {item.areaDisinfected &&
            <li>Treatment site was disinfected. Material used to disinfect <b>{item.usedMaterial}</b></li>}
            {item.bloodDrawn &&
            <li>Blood drawn.{!ArrayUtils.isEmpty(item.bloodDrawnSite) ? <> Site: <b>{item.bloodDrawnSite}</b></> : ""}
                {!ArrayUtils.isEmpty(item.bloodDrawnAmount) ? <> Amount: <b>{item.bloodDrawnAmount}</b></> : ""}</li>}
            {item.procedureGuided &&
            <li>Procedure was guided by ultrasound for direct and accurate visualization of the treatment site</li>}
            {item.patientTolerated &&
            <li>Patient tolerated the procedure well and was discharged in stable condition</li>}
            {item.patientInstructed &&
            <li>Patient was instructed to call if any problems or reactions occur (pain, swelling)</li>}
        </ul>
    )
}

const getTreatmentPlanItem = (treatmentReceivedItem) => {
    const {id, creationDate, updateDate, version, ...treatmentPlanItem} = treatmentReceivedItem;
    return treatmentPlanItem;
}

export default {getTreatmentReceivedProcedureList, treatmentInfoSections, getTreatmentPlanItem}