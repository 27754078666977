import React, {Component} from "react";
import {TextInput} from "../../../../components";
import {DialogActions} from "@material-ui/core";
import {Button} from "react-bootstrap";
import RemotingService from "../../../../services/remoting-service/RemotingService";

export default class CancelInvoiceModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cancelReason: "",
            cancelDetail: ""
        };
    }

    confirm() {
        const {invoiceItem} = this.props;

        RemotingService.postRemoteCall(
            'api/billing/' + this.props.patientId + '/item/' + invoiceItem.id + '/cancel?reason=' + this.state.cancelReason,
            null,
            (invoiceItem) => {
                this.props.close();
                this.props.onSave(invoiceItem);
            });
    }

    render() {
        const label = "You are about to cancel " +
            (this.props.invoiceItem && 'BILLED' === this.props.invoiceItem.status ? 'a paid bill.' : 'an invoice item.')
            + " Are you sure?";

        return (
            <>
                <div>
                    <div className="row p-3">
                        <div className="col-12">
                            {label}
                        </div>
                        <div className="col-2 font-weight-bold">Reason</div>
                        <div className="col-10">
                            <TextInput className="w-75" value={this.state.cancelReason}
                                       onChange={(value) => this.setState({cancelReason: value})}/>
                        </div>
                    </div>
                </div>
                <DialogActions>
                    <Button variant="success" onClick={() => this.confirm()}>Confirm</Button>
                </DialogActions>
            </>
        );
    }

}