import React, {Component} from 'react';
import {FormSectionHeader} from "../../public/FormSectionHeader";
import IntakeFormTextEnum from "../enum/IntakeFormTextEnum";
import {RadioInput, TextareaInput} from "../../../../components";
import EtiologyEnum from "../../../emr/recordpage/subjective/enums/complaint/EtiologyEnum";
import {YesNoRadioInput} from "../../public/YesNoRadioInput";
import TreatmentsForThisConditionEnum from "../../enum/TreatmentsForThisConditionEnum";
import {PainSlider, RespondValueLabelComponent} from "../../PainSlider";
import RespondLastTreatmentOptions from "../../RespondLastTreatmentOptions";
import PainSliderOptions from "../../PainSliderOptions";

class PublicIntakeFormCurrentPatientCondition extends Component {
    render() {
        const {
            selectedLanguage, enumLabel, form, onChange, validatedTextField,
            forceFormUpdate
        } = this.props;

        return (
            <>
                <FormSectionHeader header={IntakeFormTextEnum.Current_Patient_Condition[selectedLanguage]}/>

                <div className="row">
                    <div className="form-group">
                        <label
                            className="col-form-label">{IntakeFormTextEnum.Complaint_Related_To[selectedLanguage]}</label>
                        <RadioInput
                            id="complaintRelatedToRadio"
                            value={form.complaintRelatedTo}
                            options={EtiologyEnum.toArray()}
                            valueProperty={"key"}
                            displayProperty={enumLabel}
                            onChange={(value) =>
                                onChange("complaintRelatedTo", value)
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="container-fluid p-0">
                        {EtiologyEnum.OTHER.key === form.complaintRelatedTo && (
                            <div className="panel bg-grey py-0 px-3 mb-3">
                                <div className="form-group">
                                    <label className="col-form-label">{EtiologyEnum.OTHER[enumLabel]}</label>
                                    {validatedTextField(form, "complaintRelatedToOther")}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="form-group">
                        <label className="col-form-label">
                            {IntakeFormTextEnum.Happened_Before[selectedLanguage]}
                        </label>
                        <YesNoRadioInput
                            value={form.happenedBefore}
                            onValueChange={(value) => {
                                onChange("happenedBefore", value);
                            }}
                            enumLabel={enumLabel}
                        />
                    </div>
                </div>
                {"Yes" === form.happenedBefore && (
                    <div className="row">
                        <div className="container-fluid p-0">
                            <div className="panel bg-grey py-0 px-3 mb-3">
                                <div className="form-group">
                                    <label className="col-form-label">
                                        {IntakeFormTextEnum.If_Yes_How_Long_Ago[selectedLanguage]}
                                    </label>
                                    {validatedTextField(form, "happenedBeforeYear")}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="form-group">
                        <label className="col-form-label">
                            {IntakeFormTextEnum.What_Have_You_Tried_To_Treat_This_Problem[selectedLanguage]}
                        </label>
                        <RadioInput
                            id="treatmentsForThisConditionRadio"
                            value={form.treatmentsForThisCondition}
                            options={TreatmentsForThisConditionEnum.toArray()}
                            valueProperty={"key"}
                            displayProperty={enumLabel}
                            onChange={(value) =>
                                onChange("treatmentsForThisCondition", value)
                            }
                        />
                    </div>
                </div>
                {TreatmentsForThisConditionEnum.OTHER.key == form.treatmentsForThisCondition && (
                    <div className="row">
                        <div className="container-fluid p-0">
                            <div className="panel bg-grey px-3 py-0 mb-3">
                                <div className="form-group">
                                    <label
                                        className="col-form-label">{IntakeFormTextEnum.Other[selectedLanguage]}</label>
                                    {validatedTextField(
                                        form,
                                        "treatmentsForThisConditionOther"
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            <label className="col-form-label mb-3">
                                {IntakeFormTextEnum.How_Did_You_Respond_Last_Treatment[selectedLanguage]}
                            </label>
                            <PainSlider
                                ValueLabelComponent={RespondValueLabelComponent}
                                aria-label="custom thumb label"
                                defaultValue={form.respondLastTreatment || 0}
                                min={0}
                                max={4}
                                step={1}
                                marks={RespondLastTreatmentOptions.respondMarks(enumLabel)}
                                valueLabelDisplay="auto"
                                onChange={(e, value) => {
                                    form.respondLastTreatment = PainSliderOptions.getPainInfo(
                                        value
                                    );
                                    forceFormUpdate();
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-12 form-group">
                        <label className="col-form-label">
                            {IntakeFormTextEnum.What_Do_You_Hope_From_Treatment[selectedLanguage]}
                        </label>
                        <TextareaInput
                            value={form.hopeAccomplishFromTreatment}
                            style={{minHeight: "75px", width: "100%"}}
                            onChange={(value) =>
                                onChange("hopeAccomplishFromTreatment", value)
                            }
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default PublicIntakeFormCurrentPatientCondition;