import './PayAndClaim.css'
import React, {Component} from 'react'
import {DateNavigation} from "../../../../components/datenavigation/DateNavigation";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import DateUtil from "../../../../utils/DateUtil";
import PageTitle from "../../../../layout/PageTitle";
import moment from "moment";
import {Button, Col, Container, Row} from "react-bootstrap";
import EnumPayAndClaimStatus from "./EnumPayAndClaimStatus";
import PatientSearchModal from "../../../appointment/modals/PatientSearchModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DateInput, Modal, TextareaInput} from "../../../../components";
import FormField from "../../../../components/form/FormField";
import PatientUtil from "../../../../utils/PatientUtil";
import {Dropdown as PrimeDropdown} from "primereact/components/dropdown/Dropdown";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import dialog from "../../../../components/dialog/Dialog";
import AttachmentModal from "../../../emr/recordpage/treatment/utils/AttachmentModal";
import {noop, pick} from "lodash-es";
import InsuranceDocuments from "../InsuranceDocuments";
import InsuranceDocumentLegend from "../InsuranceDocumentLegend";

export default class PayAndClaim extends Component {

    constructor(props) {
        super(props);

        this.state = {
            items: [],
            sendToOptions: [],
            departments: [],
            errorMessages: [],
            selectedDate: {}
        };
    }

    componentDidMount() {
        this.retrieveStaffs();
        this.retrieveDepartments();
        this.retrieveItems();
    }

    handleDateStateChange = (date) => {
        this.setState({selectedDate: date}, () => this.retrieveItems(date));
    }

    retrieveStaffs() {
        RemotingService.getRemoteCall('api/staff/list-base', null, (result) => {
            const staffs = result.items;
            staffs.map(i => {
                i.label = i.name;
                i.value = i.id;
            });
            const sendToOptions = [
                {label: 'Insurance', value: 'INSURANCE'},
                {label: 'Patient', value: 'PATIENT'},
                ...staffs,
                {label: 'Other', value: 'OTHER'}];
            this.setState({sendToOptions});
        });
    }

    retrieveDepartments() {
        RemotingService.getRemoteCall('api/clinic/department/list', null, (departmentResult) => {
            const departments = departmentResult.items.filter(d => d.name != 'Reception' && d.name != 'Management');
            departments.map(i => {
                i.label = i.combinedName;
                i.value = i.id;
            });
            this.setState({departments})
        });
    }

    retrieveAppointments() {
        if(!this.state.patient) {
            this.setState({appointments: []});
            return;
        }

        RemotingService.getRemoteCall('api/appointment/list', {
            payNClaim: true,
            patientId: this.state.patient.id,
            ignoredStatuses: ['BOOKED', 'CONFIRMED', 'CANCELLED', 'NO_SHOW', 'ARRIVED'], // visit must be started
            orderBy: "startTime",
            orderDirection: "ASC",
            size: 20,
            page: 0
        }, (result) => {
           const appointments = result.items.filter(item => !item.payAndClaimStatus).map(i => ({
                dto: i,
                label: DateUtil.formatDateTime12H(i.start),
                value: i.id
            }));
            this.setState({appointments})
        });
    }

    retrieveItems(date = DateUtil.today()) {
        const dateValue = moment(date).format('YYYY-MM-DD');
        RemotingService.getRemoteCall('api/insurance/coordinator/pay-and-claim/list',
            {
                startDate: dateValue,
                endDate: dateValue
            },
            (result) => {
                this.setState({items: result.items});
            });
    }

    openInputModal = () => {
        this.setState({
            inputModalVisible: true,
            appointmentId: null,
            appointments: [],
            visitTime: null,
            date: new Date(),
            patient: null,
            staffId: null,
            departmentId: null,
            sendTo: null,
            notes: null
        });
    }

    addItem() {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        const {visitTime, date, departmentId, staffId, patient, appointment, sendTo, notes} = this.state;

        RemotingService.postRemoteCall('api/insurance/coordinator/pay-and-claim',
            {
                visitTime, date,
                patientId: patient.id,
                appointmentId: appointment.id,
                departmentId, staffId,
                sendTo,
                notes
            },
            (newItem) => {
                this.setState({inputModalVisible: false, items: [newItem, ...this.state.items]});
            });
    }

    updateItem = (item, updatedField, newValue, callback = noop) => {
        const updateDto = pick(item,
            ['status', 'claimFormStatus', 'medicalReportStatus', 'notes', 'attachments']);

        updateDto[updatedField] = newValue;

        RemotingService.postRemoteCall(
            `api/insurance/coordinator/pay-and-claim/${item.id}`,
            updateDto,
            () => {
                item[updatedField] = newValue && Array.isArray(newValue) ? [...newValue] : newValue;
                callback();
                this.retrieveItems(this.state.selectedDate);
            }, null, true, false);
    }

    updateItemNotes = () => {
        const {selectedItem: item, notes} = this.state;
        this.updateItem(item, 'notes', notes, () => {
            this.setState({notesModalVisible: false});
        });
    }

    documentsTemplate = (item) => {
        return <InsuranceDocuments
            item={item}
            onClaimFormStatusChange={(newStatus) => this.updateItem(item, 'claimFormStatus', newStatus)}
            onMedicalReportStatusChange={(newStatus) => this.updateItem(item, 'medicalReportStatus', newStatus)}
        />
    }

    statusTemplate = (item) => {
        return <PrimeDropdown optionLabel="name" optionValue="key"
                              options={EnumPayAndClaimStatus.toArray()}
                              value={item.status}
                              appendTo={document.body}
                              onChange={event => this.updateItem(item, 'status', event.value)}
                              style={{width: '100%'}} placeholder="Select a Status"/>;
    }

    sendToTemplate = (item) => {
        const option = this.state.sendToOptions.find(o => o.value == item.sendTo);
        return option ? option.label : '';
    }

    openNotesModal = (item) => {
        this.setState({
            selectedItem: item,
            notes: item.notes,
            notesModalVisible: true
        });
    }

    notesTemplate = (item) => {
        return <Button variant={"link"} style={{color: "#84BE56", fontSize: "14px"}}
                       onClick={() => this.openNotesModal(item)}>
            <FontAwesomeIcon icon={["fas", "edit"]} size={"sm"}/> Add Note
        </Button>;
    }

    openAttachmentsModal = (item) => {
        dialog({
            title: "Attachments",
            component: <AttachmentModal
                visitId={item.visitId}
                files={item.attachments || []}
                saveAction={(files, dialogCloseFunc) => {
                    this.updateItem(item, 'attachments', files, () => {
                        dialogCloseFunc();
                        this.retrieveItems();
                    })
                }}/>
        });
    }

    attachmentsTemplate = (item) => {
        return <Button variant={"link"} style={{color: "gray", fontSize: "14px", textAlign: "left"}}
                       onClick={() => this.openAttachmentsModal(item)}>
            <FontAwesomeIcon icon={["fas", "edit"]} size={"sm"}/>
            &nbsp;{item.attachments && item.attachments.length ? item.attachments.length + ' attachments' : 'Add'}
        </Button>;
    }

    openPatientSearch = () => {
        ValidationUtil.clear(this.formFields);
        this.setState({patientSearchVisible: true});
    }

    closePatientSearch = () => {
        this.setState({patientSearchVisible: false});
    }

    handleSelectedPatient = (patient) => {
        this.setState({patient, patientSearchVisible: false}, ()=>{
            this.retrieveAppointments();
        });
    }

    clearSelectedPatient = () => {
        this.setState({patient: null, appointmentId: null, appointment: null, appointments: [], staffId: null, visitTime: null});
    }

    renderPatientSearch = () => {
        return this.state.patientSearchVisible &&
            <PatientSearchModal
                header={"Find a Patient"}
                selectIcon={<FontAwesomeIcon icon={["fas", "user-plus"]} size={"2x"} color={"#b1d93f"}
                                             style={{marginTop: "3px"}}/>}
                handleFindPatientModalSelect={this.handleSelectedPatient}
                close={this.closePatientSearch}
            />;
    }

    handleSelectAppointment = (e) =>{
        const appointmentId = e.target.value;
        const appointment = this.state.appointments.map(appointment=>appointment.dto).find(appointment=> appointment.id === appointmentId);
        this.setState({
            appointmentId: appointmentId,
            appointment: appointment,
            staffId: appointment.staff.id,
            visitTime: appointment.start,
        });
    }

    renderInputModal = () => {
        return <Modal visible={this.state.inputModalVisible}
                      header="Pay & Claim"
                      submitContent="Save"
                      submitAction={() => this.addItem()}
                      closeAction={() => this.setState({inputModalVisible: false})}
                      submitDisabled={this.state.errorMessages.length > 0}
                      paperStyle={{overflowY: 'visible'}}
                      contentStyle={{overflowY: 'visible'}}>

            <Container className={"InputModal px-4 py-3"} style={{minWidth: 550}}>
                <Row style={{marginBottom: "5px"}}>
                    <Col xs={6}>
                        <b>Patient</b>
                        {this.state.patient ?
                            <div className="display-flex flex-row justify-content-between">
                                <FormField ref={formField => this.formFields.push(formField)} required
                                           validateOn={this.state.patient}>
                                    {PatientUtil.getPatientName(this.state.patient.firstName, this.state.patient.middleName, this.state.patient.lastName)}
                                </FormField>

                                <div style={{color: "red", cursor: "pointer", textAlign: "right"}}
                                     onClick={this.clearSelectedPatient}>
                                    ⓧ
                                </div>
                            </div> :
                            <FormField ref={formField => this.formFields.push(formField)} required
                                       validateOn={this.state.patient}>
                                <Button variant={"outline-secondary w-100"} onClick={this.openPatientSearch}
                                        size={"sm"}>
                                    <FontAwesomeIcon icon={["fas", "search"]}/>
                                    <span className="pl-1">Search Patients</span>
                                </Button>
                            </FormField>
                        }
                    </Col>
                    <Col xs={6}>
                        <b>Visit Date/Time</b>
                        <FormField ref={formField => this.formFields.push(formField)} required
                                   validateOn={this.state.appointmentId}>
                            <PrimeDropdown
                                filter={true}
                                options={this.state.appointments}
                                value={this.state.appointmentId}
                                placeholder={
                                    !this.state.patient || !this.state.appointments ? "Select a Patient First" :
                                        (this.state.appointments.length === 0 ?
                                                "No Visit Found" :
                                                "Select a Visit"
                                        )
                                }
                                onChange={this.handleSelectAppointment}
                            />
                        </FormField>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <b>Provider</b>
                        <div>{this.state.appointment ? this.state.appointment.staff.name : '-'}</div>
                    </Col>
                    <Col xs={6}>
                        <b>Date</b>
                        <FormField ref={formField => this.formFields.push(formField)} required
                                   validateOn={this.state.date}>
                            <DateInput className={"whiteBackground"} value={this.state.date}
                                       onChange={date => {
                                           this.setState({date})
                                       }}/>
                        </FormField>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <b>Department</b>
                        <FormField ref={formField => this.formFields.push(formField)} required
                                   validateOn={this.state.departmentId}>
                            <FormField ref={formField => this.formFields.push(formField)} required
                                       validateOn={this.state.departmentId}>
                                <PrimeDropdown
                                    filter={true}
                                    options={this.state.departments}
                                    value={this.state.departmentId}
                                    onChange={(e) => {
                                        this.setState({departmentId: e.target.value})
                                    }}
                                />
                            </FormField>
                        </FormField>
                    </Col>
                    <Col xs={6}>
                        <b>Send To</b>
                        <FormField ref={formField => this.formFields.push(formField)} required
                                   validateOn={this.state.sendTo}>
                            <PrimeDropdown
                                filter={true}
                                options={this.state.sendToOptions}
                                value={this.state.sendTo}
                                onChange={(e) => {
                                    this.setState({sendTo: e.target.value})
                                }}
                            />
                        </FormField>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <b>Notes</b>
                        <TextareaInput style={{width: "100%", maxHeight: "100px"}}
                                       maxLength={1000}
                                       onChange={(value) => this.setState({notes: value})}/>
                    </Col>
                </Row>
            </Container>
        </Modal>;
    }

    renderNotesModal = () => {
        return <Modal visible={this.state.notesModalVisible}
                      header="Notes"
                      submitContent="Save"
                      submitAction={() => this.updateItemNotes()}
                      closeAction={() => this.setState({notesModalVisible: false})}
                      submitDisabled={this.state.errorMessages.length > 0}
                      paperStyle={{overflowY: 'visible'}}
                      contentStyle={{width: 500}}>

            <Container className={"InputModal"}>
                <Row>
                    <Col xs={12}>
                        <TextareaInput style={{width: "100%", minHeight: "150px"}}
                                       maxLength={1000}
                                       value={this.state.notes}
                                       onChange={(value) => this.setState({notes: value})}/>
                    </Col>

                </Row>
            </Container>
        </Modal>;
    }

    render() {
        this.formFields = [];

        return (
            <>
                <div style={{padding: '10px'}}
                     className="data-table-header-wrapper">
                    <div className="float-left">
                        <PageTitle title={"Pay&Claim"} className="bg-white"/>
                    </div>
                    <div className="float-right">
                        <DateNavigation onDateChange={this.handleDateStateChange} displayDate={true}/>
                    </div>
                </div>
                <div style={{padding: '10px'}}>
                    <DataTable className="InsuranceTable" responsive
                               emptyMessage={"No items found"}
                               scrollable
                               value={this.state.items}>
                        <Column header="" headerStyle={{ width: '40px' }} body={this.documentsTemplate}/>
                        <Column header="Visit Date/Time" field="visitTime" headerStyle={{ width: '130px' }} sortable
                                body={(item) => DateUtil.formatDate(item.visitTime, "Do MMMM YYYY / hh:mm A")}/>
                        <Column header="Department" headerStyle={{ width: '200px' }}
                                body={(item) => this.state.departments.find(i => i.id === item.departmentId).combinedName}/>
                        <Column header="Provider" field="staff.name" headerStyle={{ width: '130px' }}
                                body={(item) => item.staff?.name} filter={true} filterMatchMode="contains" sortable/>
                        <Column header="Status" field="status" sortable body={this.statusTemplate} headerStyle={{ width: '130px' }}/>
                        <Column header="Send To" body={this.sendToTemplate} headerStyle={{ width: '150px' }}/>
                        <Column header="Date" headerStyle={{ width: '130px' }} body={(item) => DateUtil.formatDate(item.date)}/>
                        <Column header="Patient" headerStyle={{ width: '130px' }}  field="patientName" filter={true} filterMatchMode="contains" sortable/>
                        <Column header="MRN"  headerStyle={{ width: '130px' }} field="patientMrn" filter={true} filterMatchMode="contains" sortable/>
                        <Column header="Insurance" headerStyle={{ width: '130px' }}  field="patientInsurance" filter={true} filterMatchMode="contains" sortable/>
                        <Column header="Note" headerStyle={{ width: '130px' }}  body={this.notesTemplate}/>
                        <Column header="Attach" headerStyle={{ width: '130px' }}  body={this.attachmentsTemplate}/>
                    </DataTable>
                    <div className="round-button"
                         style={{position: 'absolute', top: 104, marginLeft: -10}}
                         onClick={this.openInputModal}>+
                    </div>
                    <InsuranceDocumentLegend/>
                </div>
                {this.renderInputModal()}
                {this.renderNotesModal()}
                {this.renderPatientSearch()}
            </>
        )
    }
}
