import React from 'react';
import "./EMRHeader.css"
import {StatusGraph} from "./StatusGraph";
import {StatusInfo} from "./StatusInfo";
import {AppointmentProgress} from "./AppointmentProgress";
import {PatientMissingData} from "./missingpoints/PatientMissingData";

export class EMRHeader extends React.Component {

    constructor(props) {
        super(props);
    }
    render() {

        return (
            <div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="card">
                            <div className="row container">
                                <div className="col-6">
                                    <StatusGraph patientTypeMap={this.props.patientTypeMap}/>
                                </div>
                                <div className="col-6 vertical-align-left-center">
                                    <StatusInfo patientTypeMap={this.props.patientTypeMap}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="card">
                            <AppointmentProgress completedAppointmentCount={this.props.completedAppointmentCount} totalAppointmentCount={this.props.emrList.length}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <PatientMissingData emrList={this.props.emrList} missingPercentage={this.props.missingPercentage}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}