import Enum from "../../../enums/Enum";

const EnumCancellationReason = new Enum({
    EMERGENCY: "Emergency",
    CLINICIAN_REQUEST: "Clinician Request",
    MANAGEMENT_REQUEST: "Management Request",
    IN_TIME: "Cancellation in Advance",
    LATE: "Late Cancellation",
    CHARGED_LATE: "Charged Late Cancellation"
});

export default EnumCancellationReason;