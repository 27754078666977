import React, {Component} from "react";
import DateUtil from "../../../../../utils/DateUtil";
import ReportViewUtils from "./ReportViewUtils";

export default class DischargePlanReportView extends Component {

    render() {
        const plan = this.props.dischargePlan;

        if (ReportViewUtils.isDischargePlanNull(plan)) {
            return null;
        }

        return (
            <div>
                {plan.dateOfDischarge && <>
                    <div className="d-flex flex-column">
                        <div>Date of Discharge: {DateUtil.formatDateDoMMYY(plan.dateOfDischarge)}</div>
                        <div>{plan.dischargePlanDetails}</div>
                    </div>
                </>}
            </div>
        );
    }
}
