import Enum from "../../../../../../enums/Enum";

const HighRiskInterventionEnum = new Enum({
    Gait_Training: {name: "Gait training"},
    Balance_Training: {name: "Balance training"},
    Strength_Program: {name: "Strength program"},
    Hip_Protectors: {name: "Hip protectors"},
    Review_Of_Medication: {name: "Review of Medication"},
    Patient_Family_Education: {name: "Patient/family education"}
});

export default HighRiskInterventionEnum;