import React, {Component} from "react";
import '../../EMR.css';
import GreenSwitchWithMessage from "../../../../../components/switch/GreenSwitchWithMessage";
import {DateInput} from "../../../../../components";
import TextInput from "../../../../../components/text-input/TextInput";
import Select from "react-select";
import GradientSlider100Mark4Category from "../../../../../components/slider/GradientSlider100Mark4Category";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ReasonForDiscontinuationEnum from "../enums/dischargeplan/ReasonForDiscontinuationEnum";
import ReferralSpecialityEnum from "../enums/dischargeplan/ReferralSpecialityEnum";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ExpansionPanel from "../../../../../components/expansion-panel/CustomExpansionPanel";

export default class DischargePlanModal extends Component {

    state = {}

    constructor(props) {
        super(props);
        this.state.dischargePlan = this.props.treatment.dischargePlan;
    }

    render() {
        return (
            <div className={"emr-modal content-row emr-background w-100 py-4 px-5"}>
                <div className="w-100 m-3" style={{minHeight: "20vw"}}>
                    <div>
                        <div className="row d-flex m-0 mb-3">
                            <div className="content-label ml-0">Discharge Plan</div>
                            <GreenSwitchWithMessage
                                message={""}
                                checked={this.isDischargePlanChanged(this.state.dischargePlan)}
                                onChange={() => {
                                    this.clearDischargePlan(this.state.dischargePlan)
                                    this.forceUpdate();
                                }}/>
                        </div>
                        <div className={"row m-0 mt-3"}>
                            <h5 className={"ccol-12 font-weight-bold p-1"}>Discharge Readiness</h5>
                        </div>
                        <div className={"row m-0"}>
                            <div className={"col-4 d-flex justify-content-between font-weight-bold"}>
                                <span>Anticipated Discharge Date</span>
                                <span>:</span>
                            </div>
                            <div className={"col-2"}>
                                <DateInput id={"anticipateddischargedate"}
                                           className={"treatment-schedule-date"}
                                           disablePast="true"
                                           disableToolbar="true" variant="dialog"
                                           value={this.state.dischargePlan.anticipatedDischargeDate ? this.state.dischargePlan.anticipatedDischargeDate : null}
                                           style={{height: "25px"}}
                                           onChange={date => {
                                               this.state.dischargePlan.anticipatedDischargeDate = date;
                                               this.forceUpdate();
                                           }}
                                />
                            </div>
                            <div className={"col-6"}>
                                <TextInput
                                    value={this.state.dischargePlan.anticipatedDischargeDetails || ''}
                                    placeholder={"Details"}
                                    onChange={value => this.setState(state => {
                                        state.dischargePlan.anticipatedDischargeDetails = value;
                                        return state;
                                    })}
                                    style={{fontSize: "14px", width: "100%"}}
                                />
                            </div>
                        </div>
                        <div className={"row m-0 mt-3"}>
                            <h5 className={"ccol-12 font-weight-bold p-1"}>Discharge Plan</h5>
                        </div>
                        <div className={"row m-0"}>
                            <div className={"col-4 d-flex justify-content-between font-weight-bold"}>
                                <span>Date of Discharge</span>
                                <span>:</span>
                            </div>
                            <div className={"col-2"}>
                                <DateInput id={"anticipateddischargedate"}
                                           className={"treatment-schedule-date"}
                                           disablePast="true"
                                           disableToolbar="true" variant="dialog"
                                           value={this.state.dischargePlan.dateOfDischarge ? this.state.dischargePlan.dateOfDischarge : null}
                                           style={{height: "25px"}}
                                           onChange={date => {
                                               this.state.dischargePlan.dateOfDischarge = date;
                                               this.forceUpdate();
                                           }}
                                />
                            </div>
                            <div className={"col-6"}>
                                <TextInput
                                    value={this.state.dischargePlan.dischargePlanDetails || ''}
                                    placeholder={"Details"}
                                    onChange={value => this.setState(state => {
                                        state.dischargePlan.dischargePlanDetails = value;
                                        return state;
                                    })}
                                    style={{fontSize: "14px", width: "100%"}}
                                />
                            </div>
                        </div>
                        <div className={"row m-0 mt-3"}>
                            <h5 className={"ccol-12 font-weight-bold p-1"}>Discontinuation Plan</h5>
                        </div>
                        <div className={"row m-0"}>
                            <div className={"col-4 d-flex justify-content-between font-weight-bold"}>
                                <span>Date of Discontinuation</span>
                                <span>:</span>
                            </div>
                            <div className={"col-2"}>
                                <DateInput id={"anticipateddischargedate"}
                                           className={"treatment-schedule-date"}
                                           disablePast="true"
                                           disableToolbar="true" variant="dialog"
                                           value={this.state.dischargePlan.dateOfDiscontinuation ? this.state.dischargePlan.dateOfDiscontinuation : null}
                                           style={{height: "25px"}}
                                           onChange={date => {
                                               this.state.dischargePlan.dateOfDiscontinuation = date;
                                               this.forceUpdate();
                                           }}
                                />
                            </div>
                            <div className={"col-6"}>
                                <TextInput
                                    value={this.state.dischargePlan.discontinuationPlanDetails || ''}
                                    placeholder={"Details"}
                                    onChange={value => this.setState(state => {
                                        state.dischargePlan.discontinuationPlanDetails = value;
                                        return state;
                                    })}
                                    style={{fontSize: "14px", width: "100%"}}
                                />
                            </div>
                        </div>
                        <div className={"row m-0 mt-3"}>
                            <div className={"col-4 d-flex justify-content-between font-weight-bold"}>
                                <span>Reason for Discontinuation</span>
                                <span>:</span>
                            </div>
                            <div className={"col-8"}>
                                <Select
                                    className={"emr-white-select-container"}
                                    classNamePrefix={"emr-white-select"}
                                    isClearable={true}
                                    placeholder={""}
                                    maxMenuHeight={180}
                                    options={ReasonForDiscontinuationEnum.toArray().map(v => {
                                            return {value: v.key, label: v.name}
                                        }
                                    )}
                                    value={this.state.dischargePlan.reasonForDiscontinuation != null ?
                                        {
                                            value: ReasonForDiscontinuationEnum[this.state.dischargePlan.reasonForDiscontinuation].key,
                                            label: ReasonForDiscontinuationEnum[this.state.dischargePlan.reasonForDiscontinuation].name
                                        } : null}
                                    onChange={(event) => {
                                        let r = event == null ? null : event.value;
                                        this.setState((state) => {
                                            state.dischargePlan.reasonForDiscontinuation = r;
                                            return state;
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"row m-0 mt-4"}>
                            <div className={"col-12 font-weight-bold mb-2"}>Treatment Goal % Achieved</div>
                        </div>
                        <div className={"row m-0"}>
                            <div className={"col-8"}>
                                <GradientSlider100Mark4Category
                                    value={this.state.dischargePlan.goalAchieved ? this.state.dischargePlan.goalAchieved : null}
                                    onChange={(e, val) =>
                                        this.setState(state => {
                                            state.dischargePlan.goalAchieved = val;
                                            return state;
                                        })}
                                />
                            </div>
                            <div className={"col-4"}>
                                <TextInput
                                    value={this.state.dischargePlan.goalAchievedDetails || ''}
                                    placeholder={"Details"}
                                    onChange={value => this.setState(state => {
                                        state.dischargePlan.goalAchievedDetails = value;
                                        return state;
                                    })}
                                    style={{fontSize: "14px", width: "100%", height: "32px"}}
                                />
                            </div>
                        </div>
                        <div className={"row m-0 mt-2"}>
                            <div className={"col-4 d-flex justify-content-between font-weight-bold"}>
                                <span>Reason for goal not achieved</span>
                                <span>:</span>
                            </div>
                            <div className={"col-8"}>
                                <TextInput
                                    value={this.state.dischargePlan.reasonForGoalNotAchieved || ''}
                                    placeholder={"Reason for goal not achieved"}
                                    onChange={value => this.setState(state => {
                                        state.dischargePlan.reasonForGoalNotAchieved = value;
                                        return state;
                                    })}
                                    style={{fontSize: "14px", width: "100%"}}
                                />
                            </div>
                        </div>

                        <div style={{marginBottom: 5}}>
                            <div className="font-weight-bold ml-0 mt-4 mb-2">Instructions</div>
                        </div>
                        {this.wrapperPanel("Home Program", this.homeProgramContent())}
                        {this.wrapperPanel("Referrals", this.referralsContent())}
                        {this.wrapperPanel("Recommendations", this.recommendationsContent())}
                    </div>
                </div>
            </div>
        );
    }

    wrapperPanel = (title, content) => {
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                    <Typography>{title}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {content}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }

    homeProgramContent = () => {

        let itemDivs = this.getItemDivForDataItem("homeProgram");

        return (
            <div className={"emr-modal content-row"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px"}}>
                    <div className={"row m-0"}>
                        <div className={"col-12 font-weight-bold"}>Home Program</div>
                    </div>
                    <div className={"row m-0"} style={{marginBottom: "10px"}}>
                        <div className={"col-11"} style={{padding: "5px 0px"}}>
                            <TextInput
                                value={this.state.homeProgramDetails || ''}
                                placeholder={"Home Program"}
                                onChange={value => this.setState(state => {
                                    state.homeProgramDetails = value;
                                    return state;
                                })}
                                style={{fontSize: "14px", width: "100%"}}
                            />
                        </div>
                        <div className={"col-1"} style={{flexFlow: "row-reverse", display: "flex", padding: "5px 0px"}}>
                            <Button className={"emr-plus-button"}
                                    variant="success"
                                    disabled={this.state.homeProgramDetails == null
                                    || this.state.homeProgramDetails.length == 0}
                                    style={{fontSize: "12px", marginTop: "-1px"}}
                                    onClick={() => {
                                        this.addItemToHomeProgram()
                                    }}>
                                <FontAwesomeIcon icon={["fas", "plus"]}/>
                            </Button>
                        </div>
                    </div>
                    <div className={"row m-0"} style={{marginLeft: "5px", marginRight: "13px", marginTop: "10px"}}>
                        <div className={"div-list-wrapper"} style={{minHeight: "75px"}}>
                            {itemDivs}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    addItemToHomeProgram = () => {
        if (this.state.dischargePlan.homeProgram == null)
            this.state.dischargePlan.homeProgram = [];

        this.state.dischargePlan.homeProgram.push({
            details: this.state.homeProgramDetails
        });

        this.setState({
            homeProgramDetails: null
        });
    }

    getItemDivForDataItem = (field) => {
        if (this.state.dischargePlan[field] == null || this.state.dischargePlan[field].length == 0) {
            return;
        }

        let divs = [];
        this.state.dischargePlan[field].forEach((item, index) => divs.push(
            <div className={"div-list-item"} key={field + "Item" + index}>
                <div>
                    {item.details}
                </div>
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize: "10px", marginLeft: "2px"}}
                        onClick={() => {
                            this.state.dischargePlan[field].splice(index, 1);
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </Button>
            </div>
        ));
        return divs;
    }

    referralsContent = () => {

        let itemDivs = this.getItemDivForReferrals();

        return (
            <div className={"emr-modal content-row"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px"}}>
                    <div className={"row m-0"}>
                        <div className={"col-4 font-weight-bold"}>Speciality</div>
                        <div className={"col-4 font-weight-bold"}>Name</div>
                        <div className={"col-4 font-weight-bold"}>Recommendations</div>
                    </div>
                    <div className={"row m-0"} style={{marginBottom: "10px"}}>
                        <div className={"col-4"} style={{padding: "5px 0px"}}>
                            <Select
                                className={"emr-white-select-container"}
                                classNamePrefix={"emr-white-select"}
                                isClearable={true}
                                placeholder={"Speciality"}
                                maxMenuHeight={180}
                                options={ReferralSpecialityEnum.toArray().map(v => {
                                        return {value: v.key, label: v.name}
                                    }
                                )}
                                value={this.state.referralSpeciality != null ?
                                    {
                                        value: ReferralSpecialityEnum[this.state.referralSpeciality].key,
                                        label: ReferralSpecialityEnum[this.state.referralSpeciality].name
                                    } : null}
                                onChange={(event) => {
                                    let r = event == null ? null : event.value;
                                    this.setState((state) => {
                                        state.referralSpeciality = r;
                                        return state;
                                    });
                                }}
                            />
                        </div>
                        <div className={"col-4"}>
                            <TextInput
                                value={this.state.referralName || ''}
                                placeholder={"Name"}
                                onChange={value => this.setState(state => {
                                    state.referralName = value;
                                    return state;
                                })}
                                style={{fontSize: "14px", width: "100%"}}
                            />
                        </div>
                        <div className={"col-3"}>
                            <TextInput
                                value={this.state.referralRecommendation || ''}
                                placeholder={"Recommendations"}
                                onChange={value => this.setState(state => {
                                    state.referralRecommendation = value;
                                    return state;
                                })}
                                style={{fontSize: "14px", width: "100%"}}
                            />
                        </div>
                        <div className={"col-1"} style={{flexFlow: "row-reverse", display: "flex", padding: "5px 0px"}}>
                            <Button className={"emr-plus-button"}
                                    variant="success"
                                    disabled={this.state.referralSpeciality == null}
                                    style={{fontSize: "12px", marginTop: "-1px"}}
                                    onClick={() => {
                                        this.addItemToReferrals()
                                    }}>
                                <FontAwesomeIcon icon={["fas", "plus"]}/>
                            </Button>
                        </div>
                    </div>
                    <div className={"row m-0"} style={{marginLeft: "5px", marginRight: "13px", marginTop: "10px"}}>
                        <div className={"div-list-wrapper"} style={{minHeight: "75px"}}>
                            {itemDivs}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    addItemToReferrals = () => {
        if (this.state.dischargePlan.referrals == null)
            this.state.dischargePlan.referrals = [];

        this.state.dischargePlan.referrals.push({
            referralSpeciality: this.state.referralSpeciality,
            referralName: this.state.referralName,
            referralRecommendation: this.state.referralRecommendation
        });

        this.setState({
            referralSpeciality: null,
            referralName: null,
            referralRecommendation: null,
        });
    }

    getItemDivForReferrals = () => {
        if (this.state.dischargePlan.referrals == null || this.state.dischargePlan.referrals.length == 0) {
            return;
        }

        let divs = [];
        this.state.dischargePlan.referrals.forEach((item, index) => divs.push(
            <div className={"div-list-item"} key={"referralsItem" + index}>
                <div>
                    {`${ReferralSpecialityEnum[item.referralSpeciality].name}${item.referralName ? ", " + item.referralName : ""}${item.referralRecommendation ? ", " + item.referralRecommendation : ""}`}
                </div>
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize: "10px", marginLeft: "2px"}}
                        onClick={() => {
                            this.state.dischargePlan.referrals.splice(index, 1);
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </Button>
            </div>
        ));
        return divs;
    }

    recommendationsContent = () => {

        let itemDivs = this.getItemDivForDataItem("recommendations");

        return (
            <div className={"emr-modal content-row"} style={{width: "100%"}}>
                <div style={{width: "100%", margin: "20px"}}>
                    <div className={"row m-0"}>
                        <div className={"col-12 font-weight-bold"}>Recommendations</div>
                    </div>
                    <div className={"row m-0"} style={{marginBottom: "10px"}}>
                        <div className={"col-11"} style={{padding: "5px 0px"}}>
                            <TextInput
                                value={this.state.recommendationDetails || ''}
                                placeholder={"Recommendations"}
                                onChange={value => this.setState(state => {
                                    state.recommendationDetails = value;
                                    return state;
                                })}
                                style={{fontSize: "14px", width: "100%"}}
                            />
                        </div>
                        <div className={"col-1"} style={{flexFlow: "row-reverse", display: "flex", padding: "5px 0px"}}>
                            <Button className={"emr-plus-button"}
                                    variant="success"
                                    disabled={this.state.recommendationDetails == null
                                    || this.state.recommendationDetails.length == 0}
                                    style={{fontSize: "12px", marginTop: "-1px"}}
                                    onClick={() => {
                                        this.addItemToRecommendations()
                                    }}>
                                <FontAwesomeIcon icon={["fas", "plus"]}/>
                            </Button>
                        </div>
                    </div>
                    <div className={"row m-0"} style={{marginLeft: "5px", marginRight: "13px", marginTop: "10px"}}>
                        <div className={"div-list-wrapper"} style={{minHeight: "75px"}}>
                            {itemDivs}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    addItemToRecommendations = () => {
        if (this.state.dischargePlan.recommendations == null)
            this.state.dischargePlan.recommendations = [];

        this.state.dischargePlan.recommendations.push({
            details: this.state.recommendationDetails
        });

        this.setState({
            recommendationDetails: null
        });
    }

    isDischargePlanChanged = (dischargePlan) => {
        return (
            dischargePlan.anticipatedDischargeDate ||
            (dischargePlan.anticipatedDischargeDetails && dischargePlan.anticipatedDischargeDetails.length != 0) ||
            dischargePlan.dateOfDischarge ||
            (dischargePlan.dischargePlanDetails && dischargePlan.dischargePlanDetails.length != 0) ||
            (dischargePlan.discontinuationPlanDetails && dischargePlan.discontinuationPlanDetails.length != 0) ||
            dischargePlan.dateOfDiscontinuation ||
            dischargePlan.reasonForDiscontinuation ||
            dischargePlan.goalAchieved ||
            (dischargePlan.goalAchievedDetails && dischargePlan.goalAchievedDetails.length != 0) ||
            (dischargePlan.reasonForGoalNotAchieved && dischargePlan.reasonForGoalNotAchieved.length != 0) ||
            (dischargePlan.homeProgram && dischargePlan.homeProgram.length != 0) ||
            (dischargePlan.referrals && dischargePlan.referrals.length != 0)||
            (dischargePlan.recommendations && dischargePlan.recommendations.length != 0)

        );
    }

    clearDischargePlan = (dischargePlan) => {
        dischargePlan.anticipatedDischargeDate = null;
        dischargePlan.anticipatedDischargeDetails = null;
        dischargePlan.dateOfDischarge = null;
        dischargePlan.dischargePlanDetails = null;
        dischargePlan.discontinuationPlanDetails = null;
        dischargePlan.dateOfDiscontinuation = null;
        dischargePlan.reasonForDiscontinuation = null;
        dischargePlan.goalAchieved = null;
        dischargePlan.goalAchievedDetails = null;
        dischargePlan.reasonForGoalNotAchieved = null;
        dischargePlan.homeProgram = null;
        dischargePlan.referrals = null;
        dischargePlan.recommendations = null;
    }
}