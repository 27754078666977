import DateUtil from "../../../utils/DateUtil";

export default class MaxTimeValidator {

    getErrorMessage(prop) {
        return "Field should be before than or equal to " + DateUtil.formatDate(prop, 'HH:mm');
    }

    validate(value, prop) {
        if (value === null || value === undefined) {
            return true;
        }
        if (prop === null || prop === undefined) {
            return true;
        }
        return DateUtil.isSameOrBefore(value, prop);
    }

}