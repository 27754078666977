import React, {Component} from 'react';
import EMRDashboardAssessment from "./EMRDashboardAssessment";
import EMRDashboardSubjective from "./EMRDashboardSubjective";
import EMRDashboardDiagnosis from "./EMRDashboardDiagnosis";
import "./EMRDashboard.css"

class EMRDashboard extends Component {
    render() {
        return (
            <div className={"content-row"}>
                <div className={"content-col-6 scrollable-col emr-dashboard-scrollbar"}
                     id={"dim14-emr-dashboard-scrollbar"}>
                    <EMRDashboardSubjective visits={this.props.emr.visits}/>
                    <EMRDashboardAssessment visits={this.props.emr.visits}/>
                </div>
                <div className={"content-col-6 scrollable-col emr-dashboard-scrollbar"}
                     id={"dim14-emr-dashboard-scrollbar"}>
                    <EMRDashboardDiagnosis visits={this.props.emr.visits}/>
                </div>
            </div>
        );
    }
}

export default EMRDashboard;