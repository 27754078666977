import * as ArrayUtils from "../../../../../utils/ArrayUtil";
import {GenericChecker} from "../GenericChecker";

export class OtherInjuriesChecker extends GenericChecker {
    static isDataEntered(entity) {
        if (entity == null) {
            return false;
        }

        return entity.relatedInjuries != null
            || !ArrayUtils.isEmpty(entity.type)
            || !ArrayUtils.isEmpty(entity.mechanism);
    }
}
