export default class CustomValidator {

    getErrorMessage(prop) {
        throw new Error("Missing validator message");
    }

    validate(value, validatorFn) {
        return validatorFn(value);
    }

}