import React, {Component} from 'react';
import Logo from "./Logo";
import DateUtil from "../../../utils/DateUtil";
import confirmDialog from "../../../components/dialog/ConfirmDialog";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import RemotingService from "../../../services/remoting-service/RemotingService";
import {RadioInput, TextInput, Wizard} from "../../../components";
import FormField from "../../../components/form/FormField";
import SignatureInput from "../../../components/signature-input/SignatureInput";
import FormLanguages from "../enum/FormLanguages";

export default class PublicReleaseOfMedicalRecordsConsentForm extends Component {
    constructor(props) {
        super(props);

        this.state = {}
    }

    componentDidMount() {
        const {form} = this.props;
        this.setState({reportsBy: form.reportsBy, reportsByEmail: form.reportsByEmail});
    };

    createHeader = (form) => {
        return <div style={{padding: "0px 20px 0px 20px"}}>
            <div className="row text-left">
                <div className="col-12 no-padding">
                    <Logo/>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center no-padding" style={{fontSize: 20, fontWeight: "bold"}}>
                    CONSENT FOR RELEASE OF MEDICAL RECORDS
                </div>
            </div>
            <div className="row" style={{fontSize: 11}}>
                <div className="col-3 text-left no-padding">
                    <b>Date of Admission: </b> {DateUtil.formatDateMMM(form.consentDate)}
                </div>
                <div className="col-3 text-left no-padding">
                    <b>MRN No: </b> {form.patientMrn}
                </div>
                <div className="col-3 text-right no-padding">
                    <b>ID No: </b> {form.patientEId}
                </div>
                <div className="col-3 text-right no-padding">
                    <b>Date of Birth: </b> {DateUtil.formatDateMMM(form.patientDOB)}
                </div>
            </div>
        </div>
    };

    fieldChanged = (fieldName, value) => {
        this.props.form[fieldName] = value;
        this.forceUpdate();
    };


    close = () => {
        confirmDialog(
            'Confirmation',
            'Are you sure you want to close this form? All input will be lost.',
            this.props.close);
    };

    submitForm = () => {
        if (ValidationUtil.checkWithNotification(this.formFields)) {
            let {consentFormCode: code, form} = this.props;
            form.reportsBy = this.state.reportsBy;
            form.reportsByEmail = this.state.reportsByEmail;
            RemotingService.postRemoteCall(`api/public/consent-form/code/${code}/release-of-medical-records`, form);
            this.props.onFormSubmit(form);
        }
    };

    render() {
        this.formFields = [];
        const {form} = this.props;
        console.log(form);
        return (
            <>
                {this.props.language === FormLanguages.ENGLISH.name && <Wizard
                    show={true} fullScreen
                    footerStyle={{backgroundColor: '#f5f5f5'}}
                    buttonStyle={{backgroundColor: '#84BE56', border: 'none'}}
                    previousButtonStyle={{
                        backgroundColor: "#fff",
                        color: "rgb(132, 190, 86)",
                        borderColor: "rgb(132, 190, 86)"
                    }}
                    header={this.createHeader(form)}
                    close={this.close}
                    onSubmit={this.submitForm}>

                    <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}>
                        <div style={{margin: "20px 20px 20px 20px"}}>
                            <p style={{marginTop: "10px", fontWeight: "bold"}}>I {form.patientFullName}, born
                                on {form.patientDOB} residing
                                at {form.patientAddress} hereby authorise
                                Diversified Integrated Sports
                            </p>

                            <p style={{marginTop: "10px", fontWeight: "bold"}}>Clinic to release a copy of my complete
                                medical records to;
                            </p>

                            <div className="release-of-medical-records-large-text-field">
                                <FormField ref={ref => this.formFields.push(ref)} required
                                           validateOn={form.medicalRecordsTo}>
                                    <TextInput value={form.medicalRecordsTo}
                                               onChange={(value) => this.fieldChanged('medicalRecordsTo', value)}/>
                                </FormField>
                            </div>

                            <p style={{marginTop: "10px", fontWeight: "bold"}}> I would like these reports to be given
                                to me:
                            </p>

                            <div style={{display: 'flex', flexDirection: 'row'}}>
                                <FormField ref={ref => this.formFields.push(ref)} required
                                           validateOn={this.state.reportsBy}>
                                    <RadioInput id="reportsByRadioInput"
                                                value={this.state.reportsBy}
                                                options={[{
                                                    value: "In person",
                                                    label: "In person"
                                                }, {value: "By email", label: "By email"}]}
                                                displayProperty={"label"} valueProperty={"value"}
                                                onChange={(val) => this.setState({
                                                    reportsBy: val,
                                                    reportsByEmail: ""
                                                })}/>
                                </FormField>
                                {this.state.reportsBy === "By email" ?
                                    <FormField ref={formField => this.formFields.push(formField)} required email
                                               validateOn={this.state.reportsByEmail}>
                                        <TextInput style={{marginLeft: "20px", width: "400px"}}
                                                   value={this.state.reportsByEmail}
                                                   onChange={(val) => this.setState({reportsByEmail: val})}/>
                                    </FormField>
                                    : null}
                            </div>

                            <p style={{marginTop: "10px", fontWeight: "bold"}}> Reason for request:
                            </p>

                            <div className="release-of-medical-records-large-text-field">
                                <FormField ref={ref => this.formFields.push(ref)} required
                                           validateOn={form.reasonForRequest}>
                                    <TextInput value={form.reasonForRequest}
                                               onChange={(value) => this.fieldChanged('reasonForRequest', value)}/>
                                </FormField>
                            </div>

                            <p style={{marginTop: "10px"}}>I hereby declare and confirm that the information given above
                                is accurate and true
                                to the best of my knowledge and belief.
                            </p>

                            <p style={{marginTop: "10px"}}>By reason of the aforesaid, I undertake full responsibility
                                and liability arising
                                from
                                the release of the requisite Information.
                            </p>

                            <p style={{marginTop: "10px"}}>This consent remains valid and in effect until revoked in
                                writing at any time.
                                By signing, I consent to have my medical information released and the facility,
                                it’s employees and clinicians are hereby released from any legal responsibility
                                or liability for the release of the above information to the extent indicated
                                and authorized herein.
                            </p>

                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                Date
                                            </div>
                                            <div className="col-md-6 dim14-base-input">
                                                {": " + DateUtil.formatDateDo(form.consentDate)}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                Name
                                            </div>
                                            <div className="col-md-6 dim14-base-input release-of-medical-records-default-text-field">
                                                <TextInput value={form.name} alphabeticOnly
                                                           onChange={(value) => this.fieldChanged('name', value)}/>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label ">
                                                Relationship to Patient (If Parent or Guardian)
                                            </div>
                                            <div
                                                className="col-md-6 dim14-base-input release-of-medical-records-default-text-field">
                                                <TextInput value={form.guardianRelationship} alphabeticOnly
                                                           onChange={(value) => this.fieldChanged('guardianRelationship', value)}/>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="col-md-12 dim14-form-label">
                                            Signature
                                        </div>

                                        <div className="col-md-12">
                                            <FormField
                                                ref={(ref) => this.formFields.push(ref)}
                                                required requiredMessage="Signature required"
                                                validateOn={form.signature}
                                            >
                                                <SignatureInput signature={form.signature}
                                                                onClear={() => {
                                                                    form.signature = null;
                                                                    this.forceUpdate();
                                                                }}
                                                                onSave={(signature) => {
                                                                    form.signature = signature;
                                                                    this.forceUpdate();
                                                                }}/>
                                            </FormField>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Wizard.Page>

                    <Wizard.Page onPrevious={() => this.formFields = []}>

                        <div style={{border: "1px solid", borderRadius: "5px", padding: "30px"}}>

                            <div className={"col-md-3"} style={{paddingLeft: "0px"}}>
                                <p><i> For official use only </i></p>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6 dim14-form-label ">
                                        Request received by:
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-3 dim14-form-label">
                                        Date
                                    </div>
                                    <div className="col-md-3 dim14-base-input">
                                        {": " + DateUtil.formatDateDo(form.receivedByConsentDate)}
                                    </div>

                                    <div className="col-md-3 dim14-form-label">
                                        Name
                                    </div>
                                    <div className="col-md-3 dim14-base-input release-of-medical-records-default-text-field">
                                        <FormField ref={ref => this.formFields.push(ref)} required
                                                   validateOn={form.receivedByName}>
                                            <TextInput value={form.receivedByName}
                                                       onChange={(value) => this.fieldChanged('receivedByName', value)}/>
                                        </FormField>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 p-0">
                                        <div className="col-md-12 dim14-form-label">
                                            Signature
                                        </div>

                                        <div className="col-md-12">
                                            <FormField
                                                ref={(ref) => this.formFields.push(ref)}
                                                required requiredMessage="Signature required"
                                                validateOn={form.receivedBySignature}
                                            >
                                                <SignatureInput signature={form.receivedBySignature}
                                                                onClear={() => {
                                                                    form.receivedBySignature = null;
                                                                    this.forceUpdate();
                                                                }}
                                                                onSave={(signature) => {
                                                                    form.receivedBySignature = signature;
                                                                    this.forceUpdate();
                                                                }}/>
                                            </FormField>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Wizard.Page>
                </Wizard>}

                {this.props.language === FormLanguages.ARABIC.name && <Wizard
                    show={true} fullScreen
                    contentStyle={{minWidth: 400}}
                    footerStyle={{backgroundColor: '#f5f5f5'}}
                    buttonStyle={{backgroundColor: '#84BE56', border: 'none'}}
                    previousButtonStyle={{
                        backgroundColor: "#fff",
                        color: "rgb(132, 190, 86)",
                        borderColor: "rgb(132, 190, 86)"
                    }}
                    header={this.createHeader(form)}
                    close={this.close}
                    onSubmit={this.submitForm}>

                    <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}>
                        <div style={{margin: "20px 20px 20px 20px"}}>
                            <p style={{
                                marginTop: "10px",
                                fontWeight: "bold",
                                direction: "rtl",
                                textAlign: "right"
                            }}>أنا {form.patientFullName}
                                ,ولدت في {form.patientDOB} المقيم في {form.patientAddress} أؤذن بموجبه لديفيرسفيد
                                إنتغرتد
                                سبورتس كلينك بإصدار نسخة عن
                                سجلاتي
                            </p>

                            <p style={{
                                marginTop: "10px",
                                fontWeight: "bold",
                                direction: "rtl",
                                textAlign: "right"
                            }}> الطبية الكاملة إلى</p>

                            <div className="release-of-medical-records-large-text-field"
                                 style={{display: "flex", direction: "rtl", textAlign: "right"}}>
                                <FormField ref={ref => this.formFields.push(ref)} required
                                           validateOn={form.medicalRecordsTo}>
                                    <TextInput value={form.medicalRecordsTo}
                                               onChange={(value) => this.fieldChanged('medicalRecordsTo', value)}/>
                                </FormField>
                            </div>

                            <p style={{
                                marginTop: "10px",
                                fontWeight: "bold",
                                direction: "rtl",
                                textAlign: "right"
                            }}> أود الحصول على هذه التقارير
                            </p>

                            <div style={{
                                display: 'flex', flexDirection: 'row', direction: "rtl",
                                textAlign: "right"
                            }}>
                                <FormField ref={ref => this.formFields.push(ref)} required
                                           validateOn={this.state.reportsBy}>
                                    <RadioInput id="reportsByRadioInput"
                                                value={this.state.reportsBy}
                                                options={[{
                                                    value: "In person",
                                                    label: "شخصياً"
                                                }, {value: "By email", label: "عبر البريد الإلكتروني"}]}
                                                displayProperty={"label"} valueProperty={"value"}
                                                onChange={(val) => this.setState({
                                                    reportsBy: val,
                                                    reportsByEmail: ""
                                                })}/>
                                </FormField>
                                {this.state.reportsBy === "By email" ?
                                    <FormField ref={formField => this.formFields.push(formField)} required email
                                               validateOn={this.state.reportsByEmail}>
                                        <TextInput style={{marginLeft: "20px", width: "400px"}}
                                                   value={this.state.reportsByEmail}
                                                   onChange={(val) => this.setState({reportsByEmail: val})}/>
                                    </FormField>
                                    : null}
                            </div>

                            <p style={{
                                marginTop: "10px",
                                fontWeight: "bold",
                                direction: "rtl",
                                textAlign: "right"
                            }}> سبب الطلب:
                            </p>

                            <div className="release-of-medical-records-large-text-field"
                                 style={{display: "flex", direction: "rtl", textAlign: "right"}}>
                                <FormField ref={ref => this.formFields.push(ref)} required
                                           validateOn={form.reasonForRequest}>
                                    <TextInput value={form.reasonForRequest}
                                               onChange={(value) => this.fieldChanged('reasonForRequest', value)}/>
                                </FormField>
                            </div>

                            <p style={{
                                marginTop: "10px",
                                direction: "rtl",
                                textAlign: "right"
                            }}> وبذلك اؤكد ان المعلومات التي شاركت فيها في الاعلى دقيقة وصحيحة.
                            </p>

                            <p style={{
                                marginTop: "10px",
                                direction: "rtl",
                                textAlign: "right"
                            }}> وبموجب ذلك أتعهد بتحمل كامل المسؤولية الناتجة عن إصدار المعلومات المطلوبة.
                            </p>

                            <p style={{
                                marginTop: "10px",
                                direction: "rtl",
                                textAlign: "right"
                            }}> تظل هذه الموافقة سارية ونافذة حتى يتم إلغاؤها كتابةً في أي وقت.
                            </p>

                            <div className="container" style={{direction: "rtl", textAlign: "right"}}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                التاريخ
                                            </div>
                                            <div className="col-md-6 dim14-base-input">
                                                {": " + DateUtil.formatDateDo(form.consentDate)}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                الإسم
                                            </div>
                                            <div className="col-md-6 dim14-base-input release-of-medical-records-default-text-field">
                                                <TextInput value={form.name} alphabeticOnly
                                                           onChange={(value) => this.fieldChanged('name', value)}/>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label ">
                                                صلة القرابة
                                            </div>
                                            <div
                                                className="col-md-6 dim14-base-input release-of-medical-records-default-text-field">
                                                <TextInput value={form.guardianRelationship} alphabeticOnly
                                                           onChange={(value) => this.fieldChanged('guardianRelationship', value)}/>

                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="col-md-12 dim14-form-label">
                                            التوقيع
                                        </div>

                                        <div className="col-md-12">
                                            <FormField
                                                ref={(ref) => this.formFields.push(ref)}
                                                required requiredMessage="Signature required"
                                                validateOn={form.signature}
                                            >
                                                <SignatureInput signature={form.signature}
                                                                onClear={() => {
                                                                    form.signature = null;
                                                                    this.forceUpdate();
                                                                }}
                                                                onSave={(signature) => {
                                                                    form.signature = signature;
                                                                    this.forceUpdate();
                                                                }}/>
                                            </FormField>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Wizard.Page>

                    <Wizard.Page onPrevious={() => this.formFields = []}>

                        <div style={{border: "1px solid", borderRadius: "5px", padding: "30px"}}>

                            <div style={{paddingLeft: "0px", direction: "rtl", textAlign: "right"}}>
                                <p><i> للاستخدام الرسمي فقط </i></p>
                            </div>
                            <div className="container" style={{direction: "rtl", textAlign: "right"}}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                التاريخ
                                            </div>
                                            <div className="col-md-6 dim14-base-input">
                                                {": " + DateUtil.formatDateDo(form.receivedByConsentDate)}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label">
                                                الإسم
                                            </div>
                                            <div
                                                className="col-md-6 dim14-base-input release-of-medical-records-default-text-field">
                                                <FormField ref={ref => this.formFields.push(ref)} required
                                                           validateOn={form.receivedByName}>
                                                    <TextInput value={form.receivedByName}
                                                               onChange={(value) => this.fieldChanged('receivedByName', value)}/>
                                                </FormField>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 dim14-form-label ">
                                                تم استلام الطلب عن طريق
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="col-md-12 dim14-form-label">
                                            التوقيع
                                        </div>

                                        <div className="col-md-12">
                                            <FormField
                                                ref={(ref) => this.formFields.push(ref)}
                                                required requiredMessage="Signature required"
                                                validateOn={form.receivedBySignature}
                                            >
                                                <SignatureInput signature={form.receivedBySignature}
                                                                onClear={() => {
                                                                    form.receivedBySignature = null;
                                                                    this.forceUpdate();
                                                                }}
                                                                onSave={(signature) => {
                                                                    form.receivedBySignature = signature;
                                                                    this.forceUpdate();
                                                                }}/>
                                            </FormField>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Wizard.Page>
                </Wizard>}
            </>);
    }
}