import Enum from "../../../../../../enums/Enum";

const ReferralSpecialityEnum = new Enum({
    Orthopedic_Surgery: {name: "Orthopedic Surgery"},
    Rheumatology: {name: "Rheumatology"},
    General_Surgery: {name: "General Surgery"},
    Neurology: {name: "Neurology"},
    Endocrinology: {name: "Endocrinology"},
    Obs_Gyn: {name: "Obs&Gyn"},
    Psychiatry: {name: "Psychiatry"},
    Psychology: {name: "Psychology"},
    Oncology: {name: "Oncology"},
    Gastroenterology: {name: "Gastroenterology"},
    Cardiology: {name: "Cardiology"},
    Pulmonary: {name: "Pulmonary"},
    Sleep_Medicine: {name: "Sleep Medicine"},
    Anesthesiology: {name: "Anesthesiology"},
    Vascular_Surgery: {name: "Vascular Surgery"},
    Urology: {name: "Urology"},
    Allergy_Immunology: {name: "Allergy & Immunology"},
    Dermatology: {name: "Dermatology"},
    Hematology: {name: "Hematology"},
    Pediatrics: {name: "Pediatrics"},
    Ophthalmology: {name: "Ophthalmology"},
    Medical_Genetics: {name: "Medical Genetics"},
    Surgery: {name: "Surgery"},
    Nutrition: {name: "Nutrition"},
});

export default ReferralSpecialityEnum;