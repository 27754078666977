import Enum from "../../../../../../enums/Enum";

const ReflexesDropdownValues = new Enum({
    R0: {name: "Absent"},
    R1: {name: "Trace "},
    R3: {name: "Normal"},
    R4: {name: "Brisk"},
    R2: {name: "Clonus"},
});

export default ReflexesDropdownValues;