import React, {Component} from 'react';
import moment from "moment";
import {RadioInput, TextInput, Wizard} from "../../components";
import RemotingService from "../../services/remoting-service/RemotingService";
import FormField from "../../components/form/FormField";
import ValidationUtil from "../../components/form/validator/ValidationUtil";
import TextareaInput from "../../components/textarea-input/TextareaInput";
import Logo from "../../layout/Logo";
import SignatureInput from "../../components/signature-input/SignatureInput";
import PatientUtil from "../../utils/PatientUtil";
import StringUtil from "../../utils/StringUtil";
import FormLanguages from "./enum/FormLanguages";

class ReleaseOfMedicalRecordsConsentForm extends Component {

    constructor(props) {
        super(props)

        this.state = {
            consentDate: new Date(),
            receivedByConsentDate: new Date()
        }
    }

    onSubmit = () => {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        if (this.props.form !== null && this.props.form !== undefined) {
            this.props.close();

        } else {
            let patient = this.props.patient;
            let patientFullName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
            let patientMrn = StringUtil.mrn(patient.mrn);
            let patientDOB = patient.birthday;
            let patientEId = patient.eid;
            let patientAddress = patient.address;
            const {
                consentDate, receivedByConsentDate, name, guardianRelationship, signature,
                receivedByName: receivedByName, receivedBySignature, medicalRecordsTo, reportsBy, reportsByEmail, reasonForRequest
            } = this.state;

            RemotingService.postRemoteCall('api/patient/' + this.props.patient.id + '/release-of-medical-records',
                {
                    consentDate,
                    receivedByConsentDate,
                    name,
                    guardianRelationship,
                    signature,
                    receivedByName: receivedByName,
                    receivedBySignature,
                    medicalRecordsTo,
                    reportsBy,
                    reportsByEmail,
                    reasonForRequest,
                    patientFullName,
                    patientMrn,
                    patientDOB,
                    patientEId,
                    patientAddress
                },
                (result) => {
                    this.props.afterSave(result);
                    this.props.close();
                });
        }
    }


    onSaveReceivedBySignature = (signature) => {
        this.setState({receivedBySignature: signature})
    }

    onSavePatientSignature = (signature) => {
        this.setState({signature: signature})
    }

    render() {
        if (!this.props.show)
            return null;

        let {patient, form} = this.props;
        let readOnly = form !== null && form !== undefined;
        let patientName = PatientUtil.getPatientName(patient.firstName, patient.middleName, patient.lastName);
        this.formFields = []

        return (
            <>
                {this.props.selectedLanguage === FormLanguages.ENGLISH && <Wizard
                    show={this.props.show}
                    close={this.props.close}
                    title="Consent For Release Of Medical Records"
                    onSubmit={this.onSubmit}
                    isReadOnly={readOnly}
                >
                    <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}>
                        <div style={{maxWidth: '950px'}}>
                            <div style={{
                                marginTop: '15px', marginBottom: '15px', width: '100%', display: 'flex',
                                flexFlow: 'row', justifyContent: 'space-between', alignItems: 'center'
                            }}>
                                <Logo/>
                                <div>
                                    <h2>CONSENT FOR RELEASE OF MEDICAL RECORDS</h2>
                                </div>
                            </div>

                            <hr style={{borderTop: "2px solid #000000", boxShadow: "0px 2px 2px #797979"}}/>

                            <p><b><i>Please note that all reports will be available in English only</i></b></p>
                            <p style={{marginTop: "10px"}}>I {patientName}, born on {patient.birthday} residing
                                at {patient.address} hereby authorise
                                Diversified Integrated Sports Clinic to release a copy
                            </p>
                            <div style={{display: "flex"}}>
                                <p>of my complete medical records to</p>
                                <FormField ref={formField => this.formFields.push(formField)}
                                           disabled={readOnly}
                                           required validateOn={this.state.medicalRecordsTo}>
                                    <TextInput value={readOnly ? form.medicalRecordsTo : this.state.medicalRecordsTo}
                                               disabled={readOnly}
                                               style={{marginLeft: "10px", marginTop: "-10px", width: "644px"}}
                                               onChange={(val) => this.setState({medicalRecordsTo: val})}/>
                                </FormField>
                            </div>
                            <div className="row" style={{marginLeft: "-5px", marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    I would like these reports to be given to me:
                                </div>
                                <div className="col-md-8">
                                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: "15px"}}>
                                        <FormField ref={formField => this.formFields.push(formField)}
                                                   disabled={readOnly}
                                                   required validateOn={this.state.reportsBy}>
                                            <RadioInput id="reportsByRadioInput"
                                                        value={readOnly ? form.reportsBy : this.state.reportsBy}
                                                        disabled={readOnly}
                                                        options={[{
                                                            value: "In person",
                                                            label: "In person"
                                                        }, {value: "By email", label: "By email"}]}
                                                        displayProperty={"label"} valueProperty={"value"}
                                                        onChange={(val) => this.setState({
                                                            reportsBy: val,
                                                            reportsByEmail: ""
                                                        })}/>
                                        </FormField>
                                        {this.state.reportsBy === "By email" || (readOnly && form.reportsBy === "By email") ?
                                            <FormField ref={formField => this.formFields.push(formField)}
                                                       disabled={readOnly}
                                                       required email validateOn={this.state.reportsByEmail}>
                                                <TextInput style={{marginLeft: "20px", width: "350px"}}
                                                           value={readOnly ? form.reportsByEmail : this.state.reportsByEmail}
                                                           disabled={readOnly}
                                                           onChange={(val) => this.setState({reportsByEmail: val})}/>
                                            </FormField>
                                            : null}
                                    </div>
                                </div>
                            </div>

                            <div className="row" style={{marginLeft: "-5px", marginTop: "10px"}}>
                                <div className="col-md-3 dim14-form-label">
                                    Reason for request:
                                </div>
                                <div className="col-md-8">
                                    <FormField ref={formField => this.formFields.push(formField)}
                                               disabled={readOnly}
                                               required validateOn={this.state.reasonForRequest}>
                                        <TextareaInput style={{marginLeft: "20px"}}
                                                       value={readOnly ? form.reasonForRequest : this.state.reasonForRequest}
                                                       disabled={readOnly}
                                                       onChange={(val) => this.setState({reasonForRequest: val})}/>
                                    </FormField>
                                </div>
                            </div>

                            <div style={{marginTop: "15px"}}>
                                <p>I hereby declare and confirm that the information given above is accurate and true
                                    to the best of my knowledge and belief.</p>
                                <p>By reason of the aforesaid, I undertake full responsibility and liability arising
                                    from
                                    the release of the requisite Information.</p>
                            </div>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                                 onPrevious={() => this.formFields = []}>
                        <div style={{maxWidth: '950px'}}>
                            <p>This consent remains valid and in effect until revoked in writing at any time.
                                By signing, I consent to have my medical information released and the facility,
                                it’s employees and clinicians are hereby released from any legal responsibility
                                or liability for the release of the above information to the extent indicated
                                and authorized herein.</p>
                            <div className={"container"}>
                                <div className="row" style={{marginTop: "10px"}}>
                                    <div className="col-md-3 dim14-form-label">
                                        Date
                                    </div>
                                    <div className="col-md-3 dim14-base-input" style={{textAlign: "center"}}>
                                        {moment(readOnly ? new Date(form.consentDate) : this.state.consentDate).format('LL')}
                                    </div>
                                </div>
                                <div className="row" style={{marginTop: "10px"}}>
                                    <div className="col-md-3 dim14-form-label">
                                        Name
                                    </div>
                                    <div className="col-md-3">
                                        <TextInput value={readOnly ? form.name : this.state.name}
                                                   disabled={readOnly} alphabeticOnly style={{textAlign: "center"}}
                                                   onChange={(val) => this.setState({name: val})}/>
                                    </div>

                                    <div className="col-md-3 dim14-form-label">
                                        Relationship to Patient (If Parent or Guardian)
                                    </div>
                                    <div className="col-md-3 dim14-base-input">
                                        <TextInput
                                            value={readOnly ? form.guardianRelationship : this.state.guardianRelationship}
                                            disabled={readOnly} alphabeticOnly style={{textAlign: "center"}}
                                            onChange={(val) => this.setState({guardianRelationship: val})}/>
                                    </div>
                                </div>

                                <div className="row" style={{marginTop: "15px"}}>
                                    <div className="col-md-3 dim14-form-label">
                                        Signature
                                    </div>
                                    <div className="col-md-9">
                                        <FormField ref={formField => this.formFields.push(formField)}
                                                   disabled={readOnly}
                                                   required requiredMessage="Signature required"
                                                   validateOn={this.state.signature}>
                                            <SignatureInput signature={readOnly ? form.signature : this.state.signature}
                                                            onSave={this.onSavePatientSignature} readOnly={readOnly}/>
                                        </FormField>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page onPrevious={() => this.formFields = []}>
                        <div style={{width: '1000px'}}>
                            <div style={{border: "1px solid #000000"}}>
                                <div className={"container"}>
                                    <p style={{fontSize: "12px", marginLeft: "-10px"}}>
                                        <b><i>For official use only</i></b>
                                    </p>
                                    <p style={{marginLeft: "-10px"}}><b>Request received by:</b></p>
                                    <div className={"row"} style={{marginTop: "10px"}}>
                                        <div className="col-md-3 dim14-form-label">
                                            Name
                                        </div>
                                        <div className="col-md-3">
                                            <FormField ref={formField => this.formFields.push(formField)}
                                                       disabled={readOnly}
                                                       required validateOn={this.state.receivedByName}>
                                                <TextInput
                                                    value={readOnly ? form.receivedByName : this.state.receivedByName}
                                                    disabled={readOnly} alphabeticOnly style={{textAlign: "center"}}
                                                    onChange={(val) => this.setState({receivedByName: val})}/>
                                            </FormField>
                                        </div>


                                        <div className="col-md-3 dim14-form-label">
                                            Date
                                        </div>
                                        <div className="col-md-3 dim14-base-input" style={{textAlign: "center"}}>
                                            {moment(readOnly ? new Date(form.receivedByConsentDate) : this.state.receivedByConsentDate).format('LL')}
                                        </div>
                                    </div>

                                    <div className={"row"} style={{marginBottom: "20px", marginTop: "10px"}}>
                                        <div className="col-md-3 dim14-form-label">
                                            Signature:
                                        </div>
                                        <div className="col-md-4">
                                            <FormField ref={formField => this.formFields.push(formField)}
                                                       disabled={readOnly}
                                                       required requiredMessage="Signature required"
                                                       validateOn={this.state.receivedBySignature}>
                                                <SignatureInput
                                                    signature={readOnly ? form.receivedBySignature : this.state.receivedBySignature}
                                                    onSave={this.onSaveReceivedBySignature} readOnly={readOnly}/>
                                            </FormField>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Wizard.Page>
                </Wizard>}
                {this.props.selectedLanguage === FormLanguages.ARABIC && <Wizard
                    show={this.props.show}
                    close={this.props.close}
                    title="Consent For Release Of Medical Records"
                    onSubmit={this.onSubmit}
                    isReadOnly={readOnly}
                >
                    <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}>
                        <div style={{width: '950px'}}>
                            <div style={{
                                marginTop: '15px', marginBottom: '15px', width: '100%', display: 'flex',
                                flexFlow: 'row', justifyContent: 'space-between', alignItems: 'center'
                            }}>
                                <Logo/>
                                <div>
                                    <h2>استمارة الموافقة على اصدار السجلات الطبية</h2>
                                </div>
                            </div>

                            <hr style={{borderTop: "2px solid #000000", boxShadow: "0px 2px 2px #797979"}}/>

                            <p style={{direction: "rtl", textAlign: "right"}}><b><i>يرجى الملاحظة أنه سيتم إصدار جميع
                                التقارير باللغة الإنجليزية فقط</i></b></p>
                            <p style={{marginTop: "10px", direction: "rtl", textAlign: "right"}}>أنا {patientName}
                                ,ولدت في {patient.birthday} المقيم في {patient.address} أؤذن بموجبه لديفيرسفيد إنتغرتد
                                سبورتس كلينك بإصدار نسخة عن
                                سجلاتي
                            </p>
                            <div style={{display: "flex", direction: "rtl", textAlign: "right"}}>
                                <p>الطبية الكاملة إلى</p>
                                <FormField ref={formField => this.formFields.push(formField)}
                                           disabled={readOnly}
                                           required validateOn={this.state.medicalRecordsTo}>
                                    <TextInput value={readOnly ? form.medicalRecordsTo : this.state.medicalRecordsTo}
                                               disabled={readOnly}
                                               style={{marginLeft: "10px", marginTop: "-10px", width: "644px"}}
                                               onChange={(val) => this.setState({medicalRecordsTo: val})}/>
                                </FormField>
                            </div>
                            <div className="row"
                                 style={{marginLeft: "-5px", marginTop: "10px", direction: "rtl", textAlign: "right"}}>
                                <div className="col-md-3 dim14-form-label">
                                    أود الحصول على هذه التقارير
                                </div>
                                <div className="col-md-8">
                                    <div style={{display: 'flex', flexDirection: 'row', marginLeft: "15px"}}>
                                        <FormField ref={formField => this.formFields.push(formField)}
                                                   disabled={readOnly}
                                                   required validateOn={this.state.reportsBy}>
                                            <RadioInput id="reportsByRadioInput"
                                                        value={readOnly ? form.reportsBy : this.state.reportsBy}
                                                        disabled={readOnly}
                                                        options={[{
                                                            value: "In person",
                                                            label: "شخصياً"
                                                        }, {value: "By email", label: "عبر البريد الإلكتروني"}]}
                                                        displayProperty={"label"} valueProperty={"value"}
                                                        onChange={(val) => this.setState({
                                                            reportsBy: val,
                                                            reportsByEmail: ""
                                                        })}/>
                                        </FormField>
                                        {this.state.reportsBy === "By email" ?
                                            <FormField ref={formField => this.formFields.push(formField)}
                                                       disabled={readOnly}
                                                       required email validateOn={this.state.reportsByEmail}>
                                                <TextInput style={{marginRight: "20px", width: "350px"}}
                                                           value={readOnly ? form.reportsByEmail : this.state.reportsByEmail}
                                                           disabled={readOnly}
                                                           onChange={(val) => this.setState({reportsByEmail: val})}/>
                                            </FormField>
                                            : null}
                                    </div>
                                </div>
                            </div>

                            <div className="row"
                                 style={{marginLeft: "-5px", marginTop: "10px", direction: "rtl", textAlign: "right"}}>
                                <div className="col-md-3 dim14-form-label">
                                    سبب الطلب:
                                </div>
                                <div className="col-md-8">
                                    <FormField ref={formField => this.formFields.push(formField)}
                                               disabled={readOnly}
                                               required validateOn={this.state.reasonForRequest}>
                                        <TextareaInput style={{marginLeft: "20px"}}
                                                       value={readOnly ? form.reasonForRequest : this.state.reasonForRequest}
                                                       disabled={readOnly}
                                                       onChange={(val) => this.setState({reasonForRequest: val})}/>
                                    </FormField>
                                </div>
                            </div>

                            <div style={{marginTop: "15px", direction: "rtl", textAlign: "right"}}>
                                <p>وبذلك اؤكد ان المعلومات التي شاركت فيها في الاعلى دقيقة وصحيحة.</p>
                                <p>وبموجب ذلك أتعهد بتحمل كامل المسؤولية الناتجة عن إصدار المعلومات المطلوبة.</p>
                                <p>تظل هذه الموافقة سارية ونافذة حتى يتم إلغاؤها كتابةً في أي وقت. </p>
                            </div>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page onNext={(event) => ValidationUtil.stopEventPropagationIfValidationFails(event, this.formFields)}
                                 onPrevious={() => this.formFields = []}>
                        <div style={{width: '950px', direction: "rtl", textAlign: "right"}}>
                            <p>من خلال التوقيع، أوافق على نشر عن معلوماتي الطبية،
                                ويتم بموجبه إعفاء الموظفين والأطباء والعيادة من أي مسؤولية قانونية عن
                                نشر المعلومات المذكورة أعلاه إلى الحد المصرح به هنا. </p>
                            <div className={"container"}>
                                <div className="row">
                                    <div className="col-md-3 dim14-form-label">
                                        التاريخ
                                    </div>
                                    <div className="col-md-3 dim14-base-input" style={{textAlign: "center"}}>
                                        {moment(readOnly ? new Date(form.consentDate) : this.state.consentDate).format('LL')}
                                    </div>
                                </div>
                                <div className={"row"} style={{marginTop: "10px"}}>
                                    <div className="col-md-3 dim14-form-label">
                                        الإسم
                                    </div>
                                    <div className="col-md-3">
                                        <TextInput value={readOnly ? form.name : this.state.name}
                                                   disabled={readOnly} style={{textAlign: "center"}}
                                                   onChange={(val) => this.setState({name: val})}/>
                                    </div>

                                    <div className="col-md-3 dim14-form-label">
                                        صلة القرابة
                                    </div>
                                    <div className="col-md-3 dim14-base-input">
                                        <TextInput
                                            value={readOnly ? form.guardianRelationship : this.state.guardianRelationship}
                                            disabled={readOnly} style={{textAlign: "center"}}
                                            onChange={(val) => this.setState({guardianRelationship: val})}/>
                                    </div>
                                </div>

                                <div className={"row"} style={{marginBottom: "20px", marginTop: "10px"}}>
                                    <div className="col-md-3 dim14-form-label">
                                        التوقيع
                                    </div>
                                    <div className="col-md-9">
                                        <FormField ref={formField => this.formFields.push(formField)}
                                                   disabled={readOnly}
                                                   required requiredMessage="Signature required"
                                                   validateOn={this.state.signature}>
                                            <SignatureInput signature={readOnly ? form.signature : this.state.signature}
                                                            onSave={this.onSavePatientSignature} readOnly={readOnly}/>
                                        </FormField>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Wizard.Page>

                    <Wizard.Page onPrevious={() => this.formFields = []}>
                        <div style={{width: '950px', direction: "rtl", textAlign: "right"}}>
                            <div style={{border: "1px solid #000000", direction: "rtl", textAlign: "right"}}>
                                <div className={"container"}>
                                    <p style={{fontSize: "12px", marginRight: "-10px"}}><b>للاستخدام الرسمي فقط</b></p>
                                    <p style={{marginRight: "-10px"}}><b>تلقى الطلب:</b></p>
                                    <div className={"row"} style={{marginTop: "10px"}}>
                                        <div className="col-md-3 dim14-form-label">
                                            الإسم
                                        </div>
                                        <div className="col-md-3">
                                            <FormField ref={formField => this.formFields.push(formField)}
                                                       disabled={readOnly}
                                                       required validateOn={this.state.receivedByName}>
                                                <TextInput
                                                    value={readOnly ? form.receivedByName : this.state.receivedByName}
                                                    disabled={readOnly} style={{textAlign: "center"}}
                                                    onChange={(val) => this.setState({receivedByName: val})}/>
                                            </FormField>
                                        </div>

                                        <div className="col-md-3 dim14-form-label">
                                            التاريخ
                                        </div>
                                        <div className="col-md-3 dim14-base-input" style={{textAlign: "center"}}>
                                            {moment(readOnly ? new Date(form.receivedByConsentDate) : this.state.receivedByConsentDate).format('LL')}
                                        </div>
                                    </div>

                                    <div className={"row"} style={{marginBottom: "20px", marginTop: "10px"}}>
                                        <div className="col-md-3 dim14-form-label">
                                            التوقيع
                                        </div>
                                        <div className="col-md-4">
                                            <FormField ref={formField => this.formFields.push(formField)}
                                                       disabled={readOnly}
                                                       required requiredMessage="Signature required"
                                                       validateOn={this.state.receivedBySignature}>
                                                <SignatureInput
                                                    signature={readOnly ? form.receivedBySignature : this.state.receivedBySignature}
                                                    onSave={this.onSaveReceivedBySignature} readOnly={readOnly}/>
                                            </FormField>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Wizard.Page>

                </Wizard>}
            </>
        );
    }
}

export default ReleaseOfMedicalRecordsConsentForm;
