import React, {Component} from "react";
import { Button } from "react-bootstrap";
import MaterialRadioButton from "../../components/radio-input/MaterialRadioButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { Collapse, Divider} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import EnumExtraDayType from "./EnumExtraDayType";
import { Alert, AlertTitle } from '@material-ui/lab';
import DateUtil from "../../utils/DateUtil";


export default class AvailableRoomModal extends Component {

    constructor(props) {
        super(props);

        let remainingFlexiHours = this.props.metaDatas.filter(metaData => metaData.type==='FLEXIBLE')[0].remaining;

        this.state = {
            extraDayType: EnumExtraDayType.EXTRA_PAY.key,
            flexibleWarningOpen: remainingFlexiHours >= 40,
            flexibleDisabled: remainingFlexiHours >= 40
        };
    }

    save() {
        this.props.close();
        this.props.handleAvailableRoomModalSaved(this.state.extraDayType, this.props.availability);
    }

    handleCloseAlert() {
        this.setState({flexibleWarningOpen: false})
    }

    render() {
        let buttonsDisabled = !this.state.extraDayType;

        return(
            <div className="container mt-4" style={{width: 500, height: 400}}>
                <div className={"row justify-content-end mt-n2 mb-2"}>
                    <IconButton className={"p-0"} aria-label="close" onClick={() => {this.props.close();}}>
                        <CloseIcon/>
                    </IconButton>
                </div>
                <Divider/>
                <div className="row align-items-center mt-4 mb-4 ml-2 mr-2 display-flex" style={{justifyContent: 'space-between', fontSize: "18px"}}>
                    <span><b>{this.props.availability.room.info}</b></span>
                    <span>{DateUtil.formatDate(this.props.availability.date)}</span>
                </div>
                <Divider/>
                <div className={"column no-margin"}>
                    <MaterialRadioButton name="extraPayFlexiHours"
                                         value={this.state.extraDayType}
                                         onChange={(value) => this.setState({extraDayType: value})}>
                        <div className={"row"} >
                            <div className={"col-12"}>
                                <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "14px"}}
                                                  value={EnumExtraDayType.EXTRA_PAY.key} control={<Radio/>}
                                                  label={EnumExtraDayType.EXTRA_PAY.name}
                                />
                            </div>
                            <div className={"col-12"}>
                                <FormControlLabel style={{margin: "0px", padding: "0px", fontSize: "14px"}}
                                                  value={EnumExtraDayType.FLEXIBLE.key} control={<Radio/>}
                                                  label={EnumExtraDayType.FLEXIBLE.name}
                                                  disabled={this.state.flexibleDisabled}
                                />
                            </div>
                        </div>
                    </MaterialRadioButton>
                    <Divider/>
                    <div className={"row mt-2"} style={{height: '100px'}}>
                        <Collapse in={this.state.flexibleWarningOpen}>
                            <Alert severity="warning" onClose={() => this.handleCloseAlert()}>
                                <AlertTitle>Flexi-hours have reached the maximum limit</AlertTitle>
                                In order to open an extra day for Flexi hours please utilize at least 10 hours
                            </Alert>
                        </Collapse>
                    </div>
                </div>
                <div className="row" style={{display: 'flex', justifyContent: 'flex-end', margin: '2px', marginTop: '50px'}}>
                    {<Button variant="light" size="sm" className="ml-1 mr-1" onClick={() => this.props.close()} disabled={buttonsDisabled}>Delete</Button>}
                    {<Button variant="success" size="sm" className="ml-1 mr-1" onClick={() => this.save()} disabled={buttonsDisabled}>Save</Button>}
                    {<Button variant="success" size="sm" className="ml-1" onClick={() => this.props.close()} disabled={buttonsDisabled}>Discard</Button>}
                </div>
            </div>
        )
    }
}