import Enum from "../../../../../enums/Enum";

const PrescriptionStatusEnum = new Enum({
    PENDING: {name: "Pending", color: "orange"},
    PRESCRIBED: {name: "Prescribed", color: "green"},
    REORDERED: {name: "Reordered", color: "black"},
    DISCONTINUED: {name: "Discontinued", color: "red"},
    NO_PICKUP: {name: "No Pickup", color: "red"}
});

export default PrescriptionStatusEnum;