import Enum from "../../../../../../enums/Enum";

const CranialNervesEnum = new Enum({
    I: {value: "I - Olfactory"},
    II: {value: "II - Optic"},
    III: {value: "III - Oculomotor"},
    IV: {value: "IV - Trochlear"},
    V: {value: "V - Trigeminal"},
    VI: {value: "VI - Abducens"},
    VII: {value: "VII - Facial"},
    VIII: {value: "VIII - Vestibulocochlear"},
    IX: {value: "IX - Gloossopharyngeal"},
    X: {value: "X - Vagus"},
    XI: {value: "XI - Accesory spinal"},
    XII: {value: "XII - Hypoglossal"},
});

export default CranialNervesEnum;