import React, {Component} from 'react';
import PropTypes from "prop-types";
import {RadioGroup} from "@material-ui/core";

class MaterialRadioButton extends Component {

    static defaultProps = {
        className: "",
        onChange: null,
        onClick: null,
        name: "",
        value: "",
    };

    static propTypes = {
        className: PropTypes.string,
        onChange: PropTypes.func,
        onClick: PropTypes.func,
        name: PropTypes.string,
        value: PropTypes.any,
    };

    onClick = (event) => {
        const target = event.target;
        if(target.checked){
            this.onChange(null);
        }
    }

    onChange = (value) => {
        if(this.props.onChange != null){
            this.props.onChange(value);
        }
    }

    render() {
        const {
            className,
            onChange,
            onClick,
            name,
            value,
            children,
            ...props
        } = this.props;

        return (
            <RadioGroup className={"emr-radio-group " + className}
                        row
                        name={name}
                        aria-label={name}
                        value={value}
                        onClick={onClick ? onClick : this.onClick.bind(this)}
                        onChange={(event) => this.onChange(event.target.value)}
                        {...props}>
                {children}
            </RadioGroup>
        );
    }
}

export default MaterialRadioButton;