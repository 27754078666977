import React, {Component} from "react";
import ReactTagInput from "@pathofdev/react-tag-input";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import {DialogActions} from "@material-ui/core";
import {Button} from "react-bootstrap";
import ObjectUtil from "../../../../utils/ObjectUtil";
import {SelectInput} from "../../../../components";

export default class EditSkillModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            allSkills: [],
            selectableSkills: [],
            selectedSkills: props.skills ? ObjectUtil.clone(props.skills) : []
        };
    }

    componentDidMount() {
        RemotingService.getRemoteCall('/api/skill/list', null, (skills) => {
            const selectedSkillNames = this.state.selectedSkills.map(s => s.name);
            const selectableSkills = skills.filter(s => !selectedSkillNames.includes(s.name));

            this.setState({allSkills: skills, selectableSkills: selectableSkills});
        });
    }

    updateSkills() {
        const staffDTO = {
            id: this.props.staffId,
            skills: this.state.selectedSkills
        };

        RemotingService.postRemoteCall('api/staff/update', staffDTO, () => {
            this.props.close();
            this.props.onSkillsUpdated(this.state.selectedSkills);
        });
    }

    handleSelectInputChange = (skill) => {
        const selectedSkills = [...this.state.selectedSkills, skill];
        const selectableSkills = this.state.selectableSkills.filter(s => s.name !== skill.name);

        this.setState({selectedSkills, selectableSkills});
    }

    handleTagsChange = (selectedSkillNames) => {
        const selectedSkills = this.state.allSkills.filter(s => selectedSkillNames.includes(s.name));
        const selectableSkills = this.state.allSkills.filter(s => !selectedSkills.includes(s));

        this.setState({selectedSkills, selectableSkills});
    }

    render() {
        return (
            <div className="SkillsModal">
                <div className="d-flex align-items-center mb-3">
                    <div className="font-weight-bold text-nowrap">Add Skills</div>
                    <SelectInput className="w-100 ml-3"
                                 displayProperty="name" valueProperty="value" showClear={false}
                                 options={this.state.selectableSkills.map(s => {return {name: s.name, value: s}})}
                                 value={{name: ""}}
                                 onChange={value => this.handleSelectInputChange(value)}/>
                </div>
                <div className="mb-2 react-tag-input-disable-manual-entry">
                    <ReactTagInput
                        tags={this.state.selectedSkills.map(skill => skill.name)}
                        placeholder=" "
                        onChange={this.handleTagsChange}
                    />
                </div>
                <DialogActions>
                    <Button variant="success" onClick={() => this.updateSkills()}>Update</Button>
                </DialogActions>
            </div>
        )
    }

}