import React, {Component} from "react";
import '../../subjective/Subjective.css';
import '../../EMR.css';
import '../../subjective/views/ComplaintView.css';
import DurationEnum from "../../subjective/enums/complaint/DurationEnum";
import TimingEnum from "../../subjective/enums/complaint/TimingEnum";
import TimingSensitivityEnum from "../../subjective/enums/complaint/TimingSensitivityEnum";
import CharacterEnum from "../../subjective/enums/complaint/CharacterEnum";
import OnsetEnum from "../../subjective/enums/complaint/OnsetEnum";
import AggravatingFactorEnum from "../../subjective/enums/complaint/AggravatingFactorEnum";
import DescriptionEnum from "../../subjective/enums/complaint/DescriptionEnum";
import RelievingFactorsEnum from "../../subjective/enums/complaint/RelievingFactorsEnum";
import FrequencyEnum from "../../subjective/enums/complaint/FrequencyEnum";
import RedFlagEnum from "../../subjective/enums/complaint/RedFlagEnum";
import EtiologyEnum from "../../subjective/enums/complaint/EtiologyEnum";
import FeelEnum from "../../subjective/enums/complaint/FeelEnum";
import {Arrow, Circle, Cross} from "../../../../consentform/BodySilhouette";
import EnumBodySilhouetteSignTypes from "../../../../consentform/BodySilhouette/EnumBodySilhouetteSignTypes";
import BodySilhouetteStaffViewCanvas from "../../../../consentform/BodySilhouette/BodySilhouetteStaffViewCanvas";
import StringUtil from "../../../../../utils/StringUtil";
import CurrentPreviousEnum from "../../subjective/enums/complaint/CurrentPreviousEnum";
import DateUtil from "../../../../../utils/DateUtil";
import * as ArrayUtils from "../../../../../utils/ArrayUtil";
import DurationTimeEnum from "../../subjective/enums/complaint/DurationTimeEnum";

export default class ComplaintEMRDashboardView extends Component {

    findMarkType = (mark) => {
        const props = {
            top: mark.topDistance ? mark.topDistance : mark.top,
            left: mark.leftDistance ? mark.leftDistance : mark.left,
            angle: mark.angle,
            scaleX: mark.scaleX,
            scaleY: mark.scaleY,
            key: mark.key
        }
        if (mark.type === EnumBodySilhouetteSignTypes.CROSS)
            return <Cross {...props}/>
        else if (mark.type === EnumBodySilhouetteSignTypes.ARROW)
            return <Arrow {...props}/>
        else if (mark.type === EnumBodySilhouetteSignTypes.CIRCLE)
            return <Circle {...props}/>
    }

    render() {
        let complaints = this.getComplaints();
        if (this.props.complaint.bodySilhouetteMarkList != null) {
            this.bodySilhouetteChilds = this.props.complaint.bodySilhouetteMarkList.map(mark => {
                return this.findMarkType(mark)
            });
        }

        return (
            <div>
                <div className="content-label">Complaint</div>
                <div className={"content-card"} style={{margin: "0px"}}>
                    {complaints.length == 0 && "No info available yet"}
                    {complaints.length != 0 &&
                    <div className={"complaints-wrapper"}>
                        {complaints}
                    </div>
                    }
                </div>

                <br/>

                {!this.props.complaintModalVisible &&
                <div className={"content-card m-0"} style={{overflowX: "auto"}}>
                    <BodySilhouetteStaffViewCanvas dashboardView>
                        {this.bodySilhouetteChilds}
                    </BodySilhouetteStaffViewCanvas>
                </div>
                }
            </div>
        );
    }

    getComplaints() {
        let complaints = [];
        let complaint = this.props.complaint;
        complaints.push(this.getComplaintViewDiv("Complaint", complaint.complaint));
        if (complaint.currentPrevious != null) {
            complaints.push(this.getComplaintViewDiv("Current/Previous",
                CurrentPreviousEnum[complaint.currentPrevious].name));
        }
        if (complaint.durationType != null
            || (complaint.durationDetails != null && complaint.durationDetails != "")
            || (complaint.durationTime != null)
        ) {
            let text = [
                complaint.durationType ? DurationEnum[complaint.durationType].name : null,
                !ArrayUtils.isEmpty(complaint.durationDetails) ? complaint.durationDetails : null,
                complaint.durationNumber != null && complaint.durationTimeEnum != null ?
                    [complaint.durationNumber, DurationTimeEnum[complaint.durationTimeEnum]?.name].join(" ") : null,
                complaint.durationTime ? DateUtil.formatDateMMM(complaint.durationTime) : null
            ].filter(Boolean).join(", ");

            complaints.push(this.getComplaintViewDiv(
                "Duration",
                text));
        }
        if (complaint.timing.length > 0
            || complaint.timingDetail != null) {
            let timingStr = this.enumString(
                TimingEnum,
                complaint.timing,
                complaint.timingDetail);
            if (complaint.timingSensitivity.length > 0) {
                timingStr += '(' + this.enumString(
                    TimingSensitivityEnum,
                    complaint.timingSensitivity) + ')';
            }
            complaints.push(this.getComplaintViewDiv(
                "Timing",
                timingStr
            ));
        }
        if (complaint.character.length > 0
            || complaint.characterDetail != null
                && complaint.characterDetail != "") {
            complaints.push(this.getComplaintViewDiv(
                "Character",
                this.enumString(
                    CharacterEnum,
                    complaint.character,
                    complaint.characterDetail)
                )
            );
        }
        if (complaint.onset != null
            || (complaint.onsetDetail != null
                && complaint.onsetDetail != "")) {
            let detail = complaint.onsetDetail;
            let onset = complaint.onset == null ? "" :
                OnsetEnum[complaint.onset].name;
            let text = onset == "" ? detail : ((detail == null || detail == "") ? onset : (onset + ", " + detail));
            complaints.push(this.getComplaintViewDiv("Onset", text));
        }
        if (complaint.aggravatingFactor.length > 0
            || (complaint.aggravatingFactorDetail != null
                && complaint.aggravatingFactorDetail != "")) {
            complaints.push(this.getComplaintViewDiv(
                "Aggravating Factors",
                this.enumString(
                    AggravatingFactorEnum,
                    complaint.aggravatingFactor,
                    complaint.aggravatingFactorDetail)
                )
            );
        }
        if (complaint.description != null
            || (complaint.descriptionDetail != null
                && complaint.descriptionDetail != "")) {
            let detail = complaint.descriptionDetail;
            let description = complaint.description == null ? "" :
                DescriptionEnum[complaint.description].name;
            let text = description == "" ? detail : ((detail == null || detail == "") ? description : (description + ", " + detail));
            complaints.push(this.getComplaintViewDiv("Description", text));
        }
        if (complaint.relievingFactors.length > 0
            || (complaint.relievingFactorsDetail != null
                && complaint.relievingFactorsDetail != "")) {
            complaints.push(this.getComplaintViewDiv(
                "Relieving Factors",
                this.enumString(
                    RelievingFactorsEnum,
                    complaint.relievingFactors,
                    complaint.relievingFactorsDetail)
                )
            );
        }
        if (complaint.frequency.length > 0
            || complaint.frequencyDetail != null) {
            complaints.push(this.getComplaintViewDiv(
                "Frequency",
                this.enumString(
                    FrequencyEnum,
                    complaint.frequency,
                    complaint.frequencyDetail)
                )
            );
        }
        if (complaint.redFlag.length > 0
            || (complaint.redFlagDetail != null
                && complaint.redFlagDetail != "")) {
            complaints.push(this.getComplaintViewDiv(
                "Red Flags",
                this.enumString(
                    RedFlagEnum,
                    complaint.redFlag,
                    complaint.redFlagDetail)
                )
            );
        }
        if (complaint.etiology.length > 0
            || complaint.etiologyDetail != null
            || complaint.historyOfTrauma != null) {
            let text = "";
            if (complaint.historyOfTrauma != null) {
                let b = complaint.historyOfTrauma ? "Yes" : "No";
                text += "History of Trauma (" + b + ")";
            }
            let enumDetail = this.enumString(EtiologyEnum,
                complaint.etiology,
                complaint.etiologyDetail);
            if (enumDetail != 'NA') {
                if (StringUtil.isNullOrEmpty(text)) {
                    text = enumDetail;
                } else {
                    text += ", " + enumDetail;
                }
            }

            complaints.push(this.getComplaintViewDiv(
                "Etiology",
                text
            ));
        }
        if (complaint.feel.length > 0) {
            complaints.push(this.getComplaintViewDiv(
                "Feel",
                this.enumString(
                    FeelEnum,
                    complaint.feel)
                )
            );
        }
        return complaints;
    }

    getComplaintViewDiv(label, value) {
        return (
            <div key={value} className={"display-flex"}>
                <div className={"col-sm-5 no-padding dim14-form-label"}>{label}:</div>
                <div className={"col-sm-7 no-padding overflow-wrap-anywhere"}>{value}</div>
            </div>);
    }

    enumString(_class, enumObject, detail) {
        if (enumObject == null || enumObject.length == 0) {
            return (detail && detail != null) ? detail : 'NA';
        }
        let res = enumObject.map(e => _class[e]?.name);
        if (detail && detail != null && detail != '') {
            res.push(detail);
        }
        return res.filter(Boolean).join(', ');
    }
}
