import React, {Component} from "react";
import '../Subjective.css';
import '../../EMR.css';
import './ComplaintView.css';
import moment from "moment";

export default class AllergyView extends Component {

    render() {
        if (this.props.allergy == null) {
            return (<></>);
        }
        return (
            <table className={"emr-table"}>
                <tbody>
                    <tr>
                        <th>Allergy</th>
                        <th>Allergen</th>
                        <th>Medication</th>
                        <th>Severity</th>
                        <th>Date</th>
                        <th>Reaction</th>
                    </tr>
                    {this.props.allergy.items.map((item) =>
                    <tr>
                        <td>{item.otherAllergyType || item.allergyType?.name}</td>
                        <td>{item.otherAllergen || item.allergen?.name}</td>
                        <td>{item.medicationBrand}</td>
                        <td>{item.otherAllergenSeverity || item.allergenSeverity?.name}</td>
                        <td>{moment(item.discoveredDate).format('YYYY-MM-DD')}</td>
                        <td>{item.otherAllergenReaction || item.allergenReaction?.name}</td>
                    </tr>
                    )}
                </tbody>
            </table>


        );
    }
}
