import Enum from "../../../../../../enums/Enum";

const AreaEnum = new Enum({
        ABDOMINAL: {name: "Abdominal"},
        INFERIOR_COSTAL: {name: "Inferior Costal"},
        SUPERIOR_COSTAL: {name: "Superior Costal"},
        OTHER: {name: "Other"}
});

export default AreaEnum;
