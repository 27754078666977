import React, {Component} from "react";
import _uniqueId from "lodash/uniqueId";

export default class StatefulTextInput extends Component {
    state = {
        value: ""
    }

    constructor(props) {
        super(props);
        this.state.value = this.props.value;
    }

    handleOnChange(e) {
        let value = e.target.value;
        if (this.props.onChange) {
            this.props.onChange(value);
            this.setState({value: value})
        }
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;
        if (oldProps.value !== newProps.value) {
            this.setState({value: newProps.value})
        }
    }

    render() {
        const {id, className, onChange, value, ...rest} = this.props;
        let componentClassName = "dim14textinput";
        if (className) {
            componentClassName += " " + className;
        }
        return (
            <input
                type="text"
                id={id || _uniqueId("textinput-id-")}
                className={componentClassName}
                onChange={e => this.handleOnChange(e)}
                value={this.state.value}
                {...rest}
            />
        )
    }
}