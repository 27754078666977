import React, {Component} from "react";
import FormBase from "../../components/form/FormBase";
import FormSection from "../../components/form/FormSection";
import {Modal} from "../../components";

class ViewRoom extends Component {

    render = () => {
        return this.props.visible ?
            (<Modal visible="true"
                    header="Room"
                    closeAction={this.props.closeAction}>
                {this.renderModalBody()}
            </Modal>)
            : null;
    }

    renderModalBody() {
        return (
            <div className={"dim14-dialog-body"}>
                <FormBase>
                    <FormSection sectionId={1} labelPercent={4} inputPercent={8} label="Name">
                        <div> {this.props.room.name} </div>
                    </FormSection>
                    <FormSection sectionId={1} labelPercent={4} inputPercent={8} label="Clinic">
                        <div> {this.props.room.clinicName} </div>
                    </FormSection>
                    <FormSection sectionId={1} labelPercent={4} inputPercent={8} label="Temperature">
                        <div> {this.props.room.temperature} </div>
                    </FormSection>
                    <FormSection sectionId={1} labelPercent={4} inputPercent={8} label="Specs">
                        <div> {this.props.room.specs} </div>
                    </FormSection>
                    {/*<FormSection sectionId={1} labelPercent={4} inputPercent={8} label="Preferred By">*/}
                    {/*    <div> {this.props.room.preferences} </div>*/}
                    {/*</FormSection>*/}
                </FormBase>
            </div>
        );
    }

}

export default ViewRoom;