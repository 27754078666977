import React, {Component} from "react";
import "../../VisitHistory.css";
import ConditionActiveEnum from "../../../subjective/enums/history/ConditionActiveEnum";

export default class FamilyViewHistory extends Component {
    render() {
        if (this.props.familyHistory == null) {
            return <div className="pl-3">No information available</div>;
        } else if (this.props.familyHistory.none) {
            return <div className="pl-3">No Family History</div>;
        }

        let familyHistory = this.getFamilyHistoryItems();

        return (
            <div className="pl-3">
                {familyHistory}
            </div>
        );
    }

    getFamilyHistoryItems() {
        if (this.props.familyHistory.items == null
            || this.props.familyHistory.items.length === 0) {
            return null;
        }

        let familyHistoryItems = [];

        this.props.familyHistory.items.forEach((item, index) => {
            familyHistoryItems.push(
                <div id={"familyViewHistoryDiv" + index}>
                    <b>{item.snomedCode?.name}</b>,
                    {item.conditionActive ? ConditionActiveEnum[item.conditionActive].name : ''}
                    {item.relationship ? ", " + item.relationship.name : ""}
                    {item.relationshipGender ? ", " + item.relationshipGender.name : ""}
                    {item.detail == null ? "" : (", " + item.detail)}
                </div>);
        });

        return familyHistoryItems;
    }
}
