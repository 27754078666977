import React, {Component} from "react";
import "../../VisitHistory.css"
import ActivityLimitationEnum from "../../../subjective/enums/treatmentreceived/ActivityLimitationEnum";
import SeverityEnum from "../../../subjective/enums/treatmentreceived/SeverityEnum";

export default class ActivityLimitationViewHistory extends Component {

    render() {
        let divs = [];

        if(this.props.activityLimitation == null
            || this.props.activityLimitation.items == null
            || this.props.activityLimitation.items.length === 0) {
            return <div className="pl-3">
                {this.props.activityLimitation.none ? "No Activity Limitation" : "No information available"}
            </div>;
        }

        this.props.activityLimitation.items.forEach((activity,index) => {
            divs.push(
                <div key={"activityLimitationViewHistory" + index}>
                    <div className={"display-flex"} style={{margin: "3px"}}>
                        <div className={"col-sm-12 no-padding overflow-wrap-anywhere"}>
                            {[
                                ActivityLimitationEnum[activity.activityLimitation]?.name,
                                activity.detail
                            ].filter(Boolean).join(", ")}
                        </div>
                    </div>
                    <div className={"display-flex"} style={{margin: "3px"}}>
                        <div className={"col-sm-2 no-padding dim14-form-label"}>Severity:</div>
                        <div className={"col-sm-10 no-padding overflow-wrap-anywhere"}>{SeverityEnum[activity.severity].name}</div>
                    </div>
                </div>
            );
        });

        return(
            <div className={"visit-history-multiple-content-wrapper pl-3"}>
                {divs}
            </div>
        );
    }
}