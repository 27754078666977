import "../MedicalReports.css"
import React, {Component} from "react";
import ReportTemplate from "../ReportTemplate";
import ReportSection from "../ReportSection";
import AssessmentReportView from "../view/AssessmentReportView";
import ReferralInterventionsReportView from "../view/ReferralInterventionsReportView";
import ComplaintReportView from "../view/ComplaintReportView";
import SymptomReportView from "../view/SymptomReportView";
import LabImagingReportView from "../view/LabImagingReportView";
import AllergiesReportView from "../view/AllergiesReportView";
import PreviousTreatmentReceivedReportView from "../view/PreviousTreatmentReceivedReportView";
import DiagnosisReportView from "../view/DiagnosisReportView";
import ReportViewUtils from "../view/ReportViewUtils";

export default class RadiologyRequestTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {visit: this.props.emr.visits[this.props.emr.visits.length - 1]}; // TODO remove
    }

    render() {
        const visit = this.state.visit;
        const subjective = visit.subjective;
        const assessment = visit.assessment;
        const treatment = visit.treatment;

        return (
            <ReportTemplate {...this.props} generateUrl={'api/medical-report/generate/radiology'}
                            onVisitSelected={(visit) => {
                                this.setState({visit})
                            }}>

                <ReportSection header1={"Complaint"}>
                    {!ReportViewUtils.isComplaintNull(subjective.complaint) && <ComplaintReportView complaint={subjective.complaint}/>}
                </ReportSection>

                <ReportSection header1={"Symptoms"} header2={"Previous Treatment Received"}>
                    {!ReportViewUtils.isSymptomsNull(subjective.complaint) && <SymptomReportView complaint={subjective.complaint}/>}
                    {!ReportViewUtils.isPreviousTreatmentReceivedNull(subjective.treatmentReceived) && <PreviousTreatmentReceivedReportView treatmentReceived={subjective.treatmentReceived}/>}
                </ReportSection>

                <ReportSection header1={"Labs/Imaging"} header2={"Allergies"}>
                    {!ReportViewUtils.isLabImagingNull(subjective.labsImaging) && <LabImagingReportView labsImaging={subjective.labsImaging}/>}
                    {!ReportViewUtils.isAllergyNull(subjective.allergy) && <AllergiesReportView allergy={subjective.allergy}/>}
                </ReportSection>

                <ReportSection header1={"Assessment"}>
                    {!ReportViewUtils.isAssessmentNull(assessment) && <AssessmentReportView assesment={assessment}/>}
                </ReportSection>

                <ReportSection header1={"Diagnosis"}>
                    {!ReportViewUtils.isDiagnosisNull(visit.diagnosis) && !ReportViewUtils.isDiagnosisItemsNull(visit.diagnosis.diagnosisGroups[0].diagnosisItems) && <DiagnosisReportView diagnosis={visit.diagnosis}/>}
                </ReportSection>

                <ReportSection header1={"Referrals"}>
                    {!ReportViewUtils.isReferralInterventionsNull(treatment.referral) && <ReferralInterventionsReportView referral={treatment.referral}/>}
                </ReportSection>

            </ReportTemplate>
        );
    }

}
