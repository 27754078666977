import React, {Component} from "react";
import '../../EMR.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import GreenSwitchWithMessage from "../../../../../components/switch/GreenSwitchWithMessage";

export default class RiskOfHarmFromTreatmentEMRDashboardView extends Component {

    state = {}

    render() {
        return (
            <div>
                {this.riskOfHarmFromTreatment()}
            </div>
        );
    }

    riskOfHarmFromTreatment = () => {
        let isAllGood = this.props.riskOfHarmFromTreatment && this.props.riskOfHarmFromTreatment.isAllGood;
        let riskOfHarmFromTreatmentToggleOn = isAllGood || (this.props.riskOfHarmFromTreatment && this.isRiskOfHarmChanged());

        return (
            <div style={{width: "100%", marginTop: "7px"}}>
                <div>
                    <div style={{display: "flex"}}>
                        <div className="content-label" style={{marginLeft: 0}}>Is there Risk of Harm from planned Care
                            of Treatment?
                        </div>
                        <GreenSwitchWithMessage
                            message={isAllGood ? "No Risk" : ""}
                            checked={this.props.riskOfHarmFromTreatment && riskOfHarmFromTreatmentToggleOn}/>
                    </div>
                    {this.props.riskOfHarmFromTreatment && this.props.riskOfHarmFromTreatment.whatRisk &&
                    <div className={"row no-margin"}>
                        <div className="content-card m-0">
                            <div className={"col-4 font-weight-bold"}>If yes, what risk?</div>
                            <div className={"col-8 display-flex"}>{this.props.riskOfHarmFromTreatment.whatRisk}</div>
                        </div>
                    </div>
                    }

                    {this.props.riskOfHarmFromTreatment && this.props.riskOfHarmFromTreatment.hasPatientInformed != null &&
                    <div className={"row no-margin"}>
                        <div className={"col-4 font-weight-bold"}>If yes, has the patient been informed?</div>
                        <div className={"col-2"}>
                            {this.riskOfHarmYesNoTemplate(this.props.riskOfHarmFromTreatment.hasPatientInformed)}
                        </div>
                        <div className={"col-6"}>{this.props.riskOfHarmFromTreatment.patientInformedDetail}</div>
                    </div>
                    }

                    {this.props.riskOfHarmFromTreatment && this.props.riskOfHarmFromTreatment.didPatientGiveConsent != null &&
                    <div className={"row no-margin"}>
                        <div className={"col-4 font-weight-bold"}>If yes, did the patient give consent?</div>
                        <div className={"col-2"}>
                            {this.riskOfHarmYesNoTemplate(this.props.riskOfHarmFromTreatment.didPatientGiveConsent)}
                        </div>
                        <div className={"col-6"}>{this.props.riskOfHarmFromTreatment.patientGiveConsentDetail}</div>
                    </div>
                    }

                    {this.props.riskOfHarmFromTreatment && this.props.riskOfHarmFromTreatment.arePrecautionsNecessary != null &&
                    <div className={"row no-margin"}>
                        <div className={"col-4 font-weight-bold"}>Are extra precautions necessary?</div>
                        <div className={"col-2"}>
                            {this.riskOfHarmYesNoTemplate(this.props.riskOfHarmFromTreatment.arePrecautionsNecessary)}
                        </div>
                        <div className={"col-6"}>{this.props.riskOfHarmFromTreatment.precautionsNecessaryDetail}</div>
                    </div>
                    }
                </div>
            </div>
        )
    }

    riskOfHarmYesNoTemplate = (field) => {
        let yesNoDiv;
        if (field) {
            yesNoDiv = (
                <div>
                    <FontAwesomeIcon icon={["fas", "check"]} color={"#7aae00"} style={{marginRight: "5px"}}/>
                    Yes
                </div>
            )
        } else {
            yesNoDiv = (
                <div>
                    <FontAwesomeIcon icon={["fas", "times"]} color={"red"} style={{marginRight: "8px"}}/>
                    No
                </div>
            )
        }

        return yesNoDiv;
    }

    isRiskOfHarmChanged = () => {
        return (
            (this.props.riskOfHarmFromTreatment.whatRisk != null && this.props.riskOfHarmFromTreatment.whatRisk.length != 0) ||
            (this.props.riskOfHarmFromTreatment.patientInformedDetail != null && this.props.riskOfHarmFromTreatment.patientInformedDetail.length != 0) ||
            (this.props.riskOfHarmFromTreatment.patientGiveConsentDetail != null && this.props.riskOfHarmFromTreatment.patientGiveConsentDetail.length != 0) ||
            (this.props.riskOfHarmFromTreatment.precautionsNecessaryDetail != null && this.props.riskOfHarmFromTreatment.precautionsNecessaryDetail.length != 0) ||
            this.props.riskOfHarmFromTreatment.hasPatientInformed != null ||
            this.props.riskOfHarmFromTreatment.didPatientGiveConsent != null ||
            this.props.riskOfHarmFromTreatment.arePrecautionsNecessary != null
        )
    }
}