import React, {Component} from "react";
import '../Subjective.css';
import '../../EMR.css';
import './ComplaintView.css';
import OccupationEnum from "../enums/lifestyle/OccupationEnum";
import JobPhysicalityEnum from "../enums/lifestyle/JobPhysicalityEnum";
import TypeEnum from "../enums/lifestyle/TypeEnum";
import NutritionEnum from "../enums/lifestyle/NutritionEnum";
import PhysicalActivityEnum from "../enums/lifestyle/PhysicalActivityEnum";
import SleepingPatternEnum from "../enums/lifestyle/SleepingPatternEnum";
import {Grid} from "@material-ui/core";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";

export default class LifestyleView extends Component {

    render() {
        let divs = this.getDivs();
        return (
            <Grid container alignItems={"center"} className={"content-card content-row m-0 w-100 p-2"}>
                <Grid item xs style={{maxWidth: 200}} className={"content-card-title"}>
                    <h6 className={"no-margin"}>Lifestyle <RedAsterisk className={"font-weight-bold"} appointmentType={this.props.appointmentType} firstVisit newComplaint/></h6>
                </Grid>
                <Grid item xs>
                    <div className="p-2 pl-3" style={{borderLeft: "1px gainsboro solid"}}>
                        {divs ||
                            <div>
                                No information
                            </div>
                        }
                    </div>
                </Grid>
            </Grid>
        );
    }

    getDivs() {
        const lifestyle = this.props.lifestyle;

        if (!lifestyle) {
            return null;
        }

        let divs = [];
        if (lifestyle.occupation) {
            divs.push(this.getComplaintViewDiv("Occupation",
                this.enumString(OccupationEnum, lifestyle.occupation, lifestyle.occupationDetail)));
        }

        if (lifestyle.jobPhysicality != null) {
            divs.push(this.getComplaintViewDiv("Job Physicality", JobPhysicalityEnum[lifestyle.jobPhysicality].name));
        }

        if (lifestyle.employmentStatus) {
            divs.push(this.getComplaintViewDiv("Employment Status", lifestyle.employmentStatus.name));
        }

        if (lifestyle.educationLevel) {
            divs.push(this.getComplaintViewDiv("Education Level", lifestyle.educationLevel.name));
        }

        if (lifestyle.type != null
            || (lifestyle.typeDetail != null
                && lifestyle.typeDetail != "")) {
            divs.push(this.getComplaintViewDiv("Type",
                this.enumString(TypeEnum, lifestyle.type, lifestyle.typeDetail)));
        }

        if (lifestyle.nutrition != null
            || (lifestyle.nutritionDetail != null
                && lifestyle.nutritionDetail != "")) {
            divs.push(this.getComplaintViewDiv("Nutrition",
                this.enumString(NutritionEnum, lifestyle.nutrition, lifestyle.nutritionDetail)));
        }

        if (lifestyle.physicalActivity != null
            || (lifestyle.physicalActivityDetail != null
                && lifestyle.physicalActivityDetail != "")) {
            divs.push(this.getComplaintViewDiv("Physical Activity",
                this.enumString(PhysicalActivityEnum, lifestyle.physicalActivity, lifestyle.physicalActivityDetail)));
        }

        if ((lifestyle.sleepingPattern != null && lifestyle.sleepingPattern.length > 0)
            || (lifestyle.sleepingPatternDetail != null && lifestyle.sleepingPatternDetail != "")) {
            divs.push(this.getComplaintViewDiv("Sleeping Pattern",
                this.enumString(SleepingPatternEnum, lifestyle.sleepingPattern, lifestyle.sleepingPatternDetail)));
        }

        if (lifestyle.drinkingStatus != null) {

            let text = [
                lifestyle.drinkingStatus?.name,
                lifestyle.alcoholDetail
            ].filter(Boolean).join(", ");

            if (lifestyle.alcoholUnitsPerWeek != null) {
                text += "(Alcohol Per Week: " + lifestyle.alcoholUnitsPerWeek + ")";
            }
            divs.push(this.getComplaintViewDiv("Alcohol", text));
        }

        if (lifestyle.smokingStatus != null
            || lifestyle.smokingUseFrequency != null
            || (lifestyle.smokingDetail != null && lifestyle.smokingDetail != "")) {

            let text = [
                lifestyle.smokingStatus?.name,
                lifestyle.smokingDetail
            ].filter(Boolean).join(", ");

            if (lifestyle.smokingPacksPerDay != null) {
                text += "(Packs Per Day: " + lifestyle.smokingPacksPerDay + ")";
            }
            divs.push(this.getComplaintViewDiv("Smoking", text));
        }

        let smoking = null;
        if (lifestyle.smokingUse != null && lifestyle.smokingUse == "NA") {
            smoking = null;
        } else if (lifestyle.smokingUse != null && lifestyle.smokingUse == "NONDRINKER") {
            smoking = false;
        } else if (lifestyle.smokingUseFrequency != null) {
            smoking = true;
        }

        let alcohol = null;
        if (lifestyle.alcoholUse != null && lifestyle.alcoholUse == "NA") {
            alcohol = null;
        } else if (lifestyle.alcoholUse != null && lifestyle.alcoholUse == "NONDRINKER") {
            alcohol = false;
        } else if (lifestyle.alcoholUseFrequency != null) {
            alcohol = true;
        }

        if (smoking != null || alcohol != null) {
            //make sure smoking and drinking always at the right column
            if (divs.length % 2 == 0) {
                divs.push(this.getEmptyViewDiv());
            }
            divs.push(this.getSmokingAlcoholViewDiv(smoking, alcohol));
        }

        if (lifestyle.substanceAbuse) {
            divs.push(this.getComplaintViewDiv("Substance Abuse", lifestyle.substanceAbuse.name));
        }

        return divs.length == 0 ? null : divs;
    }

    getEmptyViewDiv() {
        return (
            <div key={"emptyViewDiv"} className={"display-flex"}>
                <div className={"col-sm-5 no-padding dim14-form-label"}></div>
                <div className={"col-sm-7 no-padding overflow-wrap-anywhere"}></div>
            </div>);
    }

    getComplaintViewDiv(label, value) {
        return (
            <div key={value} className={"display-flex"}>
                <div className={"col-sm-5 no-padding dim14-form-label"}>{label}:</div>
                <div className={"col-sm-7 no-padding overflow-wrap-anywhere"}>{value}</div>
            </div>);
    }

    getSmokingAlcoholViewDiv(smoking, alcohol) {
        return (
            <div key={"smoking-alcohol"} className={"display-flex"}>
                <div className={"col-sm-5 no-padding dim14-form-label"}
                     style={{color: smoking ? "red" : "#7BAC16", marginTop: "auto"}}>
                    {smoking == null ? "" : (smoking ? "+Smoker" : "-Non-Smoker")}
                </div>
                <div className={"col-sm-7 no-padding dim14-form-label"}
                     style={{color: alcohol ? "red" : "#7BAC16", marginTop: "auto"}}>
                    {alcohol == null ? "" : (alcohol ? "+Drinker" : "-Non-Drinker")}
                </div>
            </div>);
    }

    enumString(_class, enumObject, detail) {
        if (enumObject == null || enumObject.length == 0) {
            return (detail && detail != null) ? detail : 'NA';
        }
        let res;
        if (Array.isArray(enumObject)) {
            res = enumObject.map(e => _class[e].name);
        } else {
            res = [];
            res.push(_class[enumObject].name);
        }
        if (detail && detail != null && detail != '') {
            res.push(detail);
        }
        return res.join(', ');
    }


}
