import React, {Component} from "react";
import {FormField} from "../../../components/form";
import {Dropdown as PrimeDropdown} from "primereact/components/dropdown/Dropdown";
import {Modal} from "../../../components";
import RemotingService from "../../../services/remoting-service/RemotingService";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";

class InsuranceEligibility extends Component {

    constructor(props) {
        super(props);
        this.state = {
            insurers: [],
            serviceCategories: [],
            selectedInsurer: null,
            selectedServiceCategory: null,
            selectedConsultationCategory: null
        };
    }

    componentDidMount() {
        RemotingService.getRemoteCall('/api/insurance/insurer/list', null, (result) => {
            this.setState({insurers: result});
        });
        RemotingService.getRemoteCall('/api/insurance/service-category/list', null, (result) => {
            this.setState({serviceCategories: result});
        });
    }

    insuranceEligibilityCheck() {
        if (!ValidationUtil.isValid(this.formFields)) {
            ValidationUtil.showNotification();
            return;
        }

        let params = {
            staffId: this.props.staffId,
            emiratesId: this.props.emiratesId,
            mobilePhone: this.props.mobilePhone,
            insurerId: this.state.selectedInsurer.id,
            serviceCategoryId: this.state.selectedServiceCategory.id,
        };
        if (this.state.selectedConsultationCategory) {
            params.consultationCategoryId = this.state.selectedConsultationCategory.id;
        }
        RemotingService.getRemoteCall('api/insurance/eligibility', params, () => {
            this.props.onEligible(params);
        },
        () => {
            this.props.onNotEligible();
        });
    }

    handleInsurerChanged = (event) => {
        this.setState({
            selectedInsurer: event.value
        });
    }

    handleServiceCategoryChanged = (event) => {
        this.setState({
            selectedServiceCategory: event.value,
            selectedConsultationCategory: null
        });
    }

    handleConsultationCategoryChanged = (event) => {
        this.setState({
            selectedConsultationCategory: event.value
        });
    }

    render() {
        return <Modal visible="true"
                      header="Insurance Eligibility"
                      submitContent="Check"
                      submitAction={() => this.insuranceEligibilityCheck()}
                      closeAction={this.props.close}>
            {this.renderModalBody()}
        </Modal>;
    }

    renderModalBody() {
        this.formFields = [];
        return <div className="container dim14-dialog-body">
            <div className="row">
                <div className="col-4 dim14-form-label">Insurer</div>
                <div className="col-8">
                    <FormField ref={formField => this.formFields.push(formField)}
                               required
                               validateOn={this.state.selectedInsurer}>
                        <PrimeDropdown dataKey="id" optionLabel="name"
                                       options={this.state.insurers}
                                       value={this.state.selectedInsurer}
                                       onChange={this.handleInsurerChanged}/>
                    </FormField>
                </div>
            </div>
            <div className="row">
                <div className="col-4 dim14-form-label">Service Category</div>
                <div className="col-8">
                    <FormField ref={formField => this.formFields.push(formField)}
                               required
                               validateOn={this.state.selectedServiceCategory}>
                        <PrimeDropdown dataKey="id" optionLabel="category"
                                       options={this.state.serviceCategories}
                                       value={this.state.selectedServiceCategory}
                                       onChange={this.handleServiceCategoryChanged}/>
                    </FormField>
                </div>
            </div>
            {
                this.state.selectedServiceCategory &&
                this.state.selectedServiceCategory.consultationCategories &&
                this.state.selectedServiceCategory.consultationCategories.length > 0 &&
                <div className="row">
                    <div className="col-4 dim14-form-label">Consultation Category</div>
                    <div className="col-8">
                        <FormField ref={formField => this.formFields.push(formField)}
                                   required
                                   validateOn={this.state.selectedConsultationCategory}>
                            <PrimeDropdown dataKey="id" optionLabel="category"
                                           options={this.state.selectedServiceCategory.consultationCategories}
                                           value={this.state.selectedConsultationCategory}
                                           onChange={this.handleConsultationCategoryChanged}/>
                        </FormField>
                    </div>
                </div>
            }
        </div>
    }
}


export default InsuranceEligibility;