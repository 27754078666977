import React from 'react'
import {history} from "../../../utils/history";
import AuthService from "../../../services/auth-service/AuthService";
import {Button} from "react-bootstrap";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./ClinicSelect.css";
import {onlyUnique} from "../../../utils/ArrayUtil";
import _isEqual from "lodash/isEqual";

class ClinicSelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            allClinics: Object.values(AuthService.getClinicsByCountry()).reduce((a,b) => a.concat(b), []).map(c => c.id),
            clinics: AuthService.getSelectableClinics(),
            selectedClinicIds: []
        };
    }

    handleSubmit = () => {
        const {selectedClinicIds} = this.state;
        const selectedCountries = this.state.clinics
                                            .filter(clinic => selectedClinicIds.includes(clinic.id))
                                            .map(clinic => clinic.country)
                                            .filter(onlyUnique);
        localStorage.setItem('clinics', JSON.stringify(selectedClinicIds));
        localStorage.setItem('countries', JSON.stringify(selectedCountries));
        history.go(0);
    };

    handleClinicSelected = (clinic) => {
        const {selectedClinicIds} = this.state;
        if (selectedClinicIds.includes(clinic.id)) {
            this.setState({selectedClinicIds: selectedClinicIds.filter(cid => cid !== clinic.id)});
        } else {
            selectedClinicIds.push(clinic.id);
            this.setState({selectedClinicIds: selectedClinicIds});
        }
    };

    handleOpen = event => {
        this.setState({anchorEl: event.currentTarget, selectedClinicIds: AuthService.getClinics()});
    };

    handleClose = () => {
        this.setState({anchorEl: null})
    };

    getSelectedClinicNames = () => {
        const selectedClinics = AuthService.getClinics();
        if (_isEqual(this.state.allClinics?.sort(), selectedClinics?.sort())) {
            return 'All Clinics';
        }

        return this.state.clinics
            .filter(clinic => selectedClinics.includes(clinic?.id))
            .map(clinic => clinic.name)
            .join(', ');
    }

    render() {
        return (
            <div className="clinic-select-wrapper">
                <label>{this.getSelectedClinicNames()}</label>

                {AuthService.getSelectableClinics().length > 1 && <FontAwesomeIcon className="bi" icon={["fas", "clinic-medical"]} onClick={(e) => this.handleOpen(e)} />}

                <Popper id="clinic-menu"
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                        role={undefined} transition disablePortal>
                    {({TransitionProps, placement}) => (
                        <Grow
                            {...TransitionProps}
                            style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={this.handleClose}>
                                    <div className="clinic-select-popover">
                                        <label style={{margin: '0 0 15px 30px'}}>Please select clinics you want to operate:</label>
                                        {this.renderClinicIcons()}

                                        {this.renderModalFooter()}
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    }

    renderClinicIcons() {
        const icons = [];
        const selectableClinics = AuthService.getSelectableClinics();
        for (const clinic of selectableClinics) {
            icons.push(
                <div onClick={() => this.handleClinicSelected(clinic)}>
                    <img className={`clinic-select-icon ${this.state.selectedClinicIds.includes(clinic.id) ? 'selected' : ''}`}
                        src={process.env.PUBLIC_URL + `/assets/clinics/${clinic.country}.png`} alt=""/>
                    <label>{clinic.name}</label>
                </div>
            );
        }
        return (<div className="clinic-select-icon-wrapper" style={{margin: "0 20px"}}>{icons}</div>);
    }

    renderModalFooter() {
        return (
            <div className="clinic-select-modal-footer">
                <Button variant="primary"
                        onClick={() => this.handleSubmit()}
                        disabled={!this.state.selectedClinicIds.length}>
                    Apply
                </Button>
            </div>
        );
    }
}


export default ClinicSelect;
