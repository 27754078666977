import React, {Component} from "react";
import EditIcon from "../../components/action-menu/icons/EditIcon";
import {Dropdown} from "primereact/dropdown";
import ConfirmIcon from "../../components/action-menu/icons/ConfirmIcon";
import TimesIcon from "../../components/action-menu/icons/TimesIcon";
import DateUtil from "../../utils/DateUtil";


export default class AppointmentCalendarRoomInput extends Component {

    constructor(props) {
        super(props);
        this.state = {
            value: null,
            editMode: false,
            mouseover: false
        };
    }

    componentDidMount() {
        // PROBLEM: react-big-calendar does not provide resources (staff in this case) to time header views
        // WORKAROUND: Put staffId on custom resource header, then navigate DOM to get it.
        this.staffId = this.myDiv ?
                                Number(this.myDiv
                                    .closest('.rbc-time-header-content')
                                    .querySelector('.custom-resource-header')
                                    .id)
                                : undefined;

        const {date, staffRoomMap} = this.props;
        this.setState({
            value: staffRoomMap.get(this.staffId, DateUtil.formatTzDate(date, this.state.timezone, "YYYYMMDD"))
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.staffRoomMap !== this.props.staffRoomMap) {
            this.setState({
                value: this.props.staffRoomMap.get(this.staffId, DateUtil.formatTzDate(this.props.date, this.props.timezone, "YYYYMMDD"))
            })
        }
    }

    render() {
        const {rooms, date, staffRoomMap, onChange, disabled, timezone} = this.props;
        const room = staffRoomMap.get(this.staffId, DateUtil.formatTzDate(date, timezone, "YYYYMMDD"));

        if (disabled) {
            return <div ref={ref => this.myDiv = ref}>{room?.info}</div>
        }

        return (
            <div ref={ref => this.myDiv = ref}
                 className="d-flex align-items-center justify-content-center w-100"
                 onMouseEnter={() => this.setState({mouseover: true})}
                 onMouseLeave={() => this.setState({mouseover: false})}>
                {
                    this.state.editMode ?
                        <div className="w-100">
                            <Dropdown appendTo={document.body}
                                      style={{maxWidth: 'calc(100% - 32px)'}}
                                      optionLabel="info"
                                      options={rooms}
                                      value={this.state.value}
                                      onChange={e => {
                                          this.setState({value: e.value});
                                      }}/>
                            <ConfirmIcon className="ml-1 cursor-pointer" onClick={() => {
                                this.setState({editMode: false, mouseover: false}, () => onChange(this.state.value, this.staffId));
                            }}/>
                            <TimesIcon className="ml-1 cursor-pointer" onClick={() => {
                                this.setState({
                                    value: room,
                                    editMode: false,
                                    mouseover: false
                                });
                            }}/>
                        </div> :
                        <div className="cursor-pointer"
                             onClick={() => this.setState({editMode: true})}>
                            {room?.info}
                        </div>
                }
                {
                    !this.state.editMode && this.state.mouseover &&
                    <EditIcon className="ml-1 cursor-pointer" onClick={() => this.setState({editMode: true})}/>
                }
            </div>
        )
    }

}