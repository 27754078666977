import {GenericChecker} from "../../subjective/GenericChecker";
import * as ArrayUtils from "../../../../../utils/ArrayUtil";

export class DiagnosisProcedureChecker extends GenericChecker {
    static isDataEntered(entity) {
        if (entity == null) {
            return false;
        }

        return !ArrayUtils.isEmpty(entity);
    }
}