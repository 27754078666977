import React, {Component} from "react";
import "../../VisitHistory.css"
import NeurologicGaitEnum from "../../../assessment/enums/neurologicGait/NeurologicGaitEnum";

export default class NeurologicGaitViewHistory extends Component {

    render() {
        if (!this.props.neurologicGait || !this.props.neurologicGait.items || this.props.neurologicGait.items.length === 0) {
            return <div className="pl-3">No information available</div>;
        }


        return (
            <div className={"visit-history-content pl-3"}>
                <div>
                    {this.props.neurologicGait.items.map((item, index) => {
                            if (index === this.props.neurologicGait.items.length - 1) {
                                return (
                                    <div key={"neurologicGaitHistory2-" + index} style={{display: "inline-block"}}>
                                        <div style={{display: "inline-block", fontWeight: "bold"}}>
                                            {NeurologicGaitEnum[item].name} :
                                        </div>
                                        <div style={{display: "inline-block", marginLeft: "10px"}}>
                                            {this.props.neurologicGait.details}

                                        </div>
                                    </div>);
                            }
                            return (
                                <div key={"neurologicGaitHistory1-" + index} style={{display: "inline-block", fontWeight: "bold"}}>
                                    {NeurologicGaitEnum[item].name}:
                                    <div style={{display: "inline-block", marginLeft: "5px", marginRight: "5px"}}>|</div>
                                </div>
                            );

                        }
                    )}
                </div>
            </div>
        );
    }
}