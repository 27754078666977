import React, {Component} from 'react';
import IntakeFormTextEnum from "./enum/IntakeFormTextEnum";
import {RadioInput, TextInput} from "../../../components";
import IntakeFormYesNoEnum from "./enum/IntakeFormYesNoEnum";
import FormField from "../../../components/form/FormField";

class IntakeFormCurrentHealth extends Component {
    render() {
        const {
            selectedLanguage, readOnly, enumLabel, onChange, formFields,
            pregnantNursing, fearOfNeedles, historyOfTrauma, oftenFeelingDown, oftenBotheredByLittleInterest,
            underPhysicalEmotionalAbuse, dietaryNutritionalConcerns, experienceSexualDysfunction, haveChildren,
            howManyChildren
        } = this.props

        return (
            <div className="container dim14-form-row-align">
                <div className="row">
                    <div className="col-12 mt-3">
                        <p>
                            <b>
                                <u>{IntakeFormTextEnum.Are_You_Currently_Do_You_Have[selectedLanguage]}</u>
                            </b>
                        </p>
                    </div>
                </div>

                <div className="row">
                    <div
                        className="col-4 dim14-form-label">{IntakeFormTextEnum.Pregnant_Nursing[selectedLanguage]}</div>
                    <div className="col-2">
                        <RadioInput
                            id="pregnantNursingRadioInput"
                            value={pregnantNursing}
                            disabled={readOnly}
                            options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                            valueProperty={"key"}
                            onChange={(value) =>
                                onChange("pregnantNursing", value)
                            }
                        />
                    </div>
                    <div
                        className="col-4 dim14-form-label">{IntakeFormTextEnum.Fear_of_needles[selectedLanguage]}</div>
                    <div className="col-2">
                        <RadioInput
                            id="fearOfNeedlesRadioInput"
                            value={fearOfNeedles}
                            disabled={readOnly}
                            options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                            valueProperty={"key"}
                            onChange={(value) =>
                                onChange("fearOfNeedles", value)
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.History_of_Trauma[selectedLanguage]}
                    </div>
                    <div className="col-2">
                        <RadioInput
                            id="historyOfTraumaRadioInput"
                            value={historyOfTrauma}
                            disabled={readOnly}
                            options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                            valueProperty={"key"}
                            onChange={(value) =>
                                onChange("historyOfTrauma", value)
                            }
                        />
                    </div>
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Depressed_or_Hopeless[selectedLanguage]}
                    </div>
                    <div className="col-2">
                        <RadioInput
                            id="oftenFeelingDownRadioInput"
                            value={oftenFeelingDown}
                            disabled={readOnly}
                            options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                            valueProperty={"key"}
                            onChange={(value) =>
                                onChange("oftenFeelingDown", value)
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Little_Interest[selectedLanguage]}
                    </div>
                    <div className="col-2">
                        <RadioInput
                            id="oftenBotheredByLittleInterestRadioInput"
                            value={oftenBotheredByLittleInterest}
                            disabled={readOnly}
                            options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                            valueProperty={"key"}
                            onChange={(value) =>
                                onChange("oftenBotheredByLittleInterest", value)
                            }
                        />
                    </div>
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Under_Physical_Emotional_Abuse[selectedLanguage]}
                    </div>
                    <div className="col-2">
                        <RadioInput
                            id="underPhysicalEmotionalAbuseRadioInput"
                            value={underPhysicalEmotionalAbuse}
                            disabled={readOnly}
                            options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                            valueProperty={"key"}
                            onChange={(value) =>
                                onChange("underPhysicalEmotionalAbuse", value)
                            }
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Dietary_or_Nutritional_Concerns[selectedLanguage]}
                    </div>
                    <div className="col-2">
                        <RadioInput
                            id="dietaryNutritionalConcernsRadioInput"
                            value={dietaryNutritionalConcerns}
                            disabled={readOnly}
                            options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                            valueProperty={"key"}
                            onChange={(value) =>
                                onChange("dietaryNutritionalConcerns", value)
                            }
                        />
                    </div>
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Experience_Sexual_Dysfunction[selectedLanguage]}
                    </div>
                    <div className="col-2">
                        <RadioInput
                            id="experienceSexualDysfunctionRadioInput"
                            value={experienceSexualDysfunction}
                            disabled={readOnly}
                            options={IntakeFormYesNoEnum.toArray()} displayProperty={enumLabel}
                            valueProperty={"key"}
                            onChange={(value) =>
                                onChange("experienceSexualDysfunction", value)
                            }
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-4 dim14-form-label">
                        {IntakeFormTextEnum.Have_Children[selectedLanguage]}
                    </div>
                    <div className="col-8">
                        <div className="d-flex">
                            <RadioInput
                                id="haveChildrenRadioInput"
                                value={haveChildren}
                                disabled={readOnly}
                                options={IntakeFormYesNoEnum.toArray()}
                                displayProperty={enumLabel}
                                valueProperty={"key"}
                                onChange={(value) =>
                                    onChange("haveChildren", value)
                                }
                            />
                            {haveChildren === "Yes" && (
                                <FormField
                                    ref={(formField) => formFields.push(formField)}
                                    required
                                    validateOn={
                                        readOnly ? true : howManyChildren
                                    }
                                >
                                    <TextInput
                                        className="mx-2"
                                        value={howManyChildren}
                                        disabled={readOnly}
                                        onChange={(value) =>
                                            onChange("howManyChildren", value)
                                        }
                                    />
                                </FormField>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default IntakeFormCurrentHealth;