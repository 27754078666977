import React from "react";
import InformedConsentFormKwtTextEnum from "../informedconsent/enum/InformedConsentFormKwtTextEnum";

const Introduction = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <p>{InformedConsentFormKwtTextEnum.Introduction_Paragraph_1[selectedLanguage]}</p>
            <p>{InformedConsentFormKwtTextEnum.Introduction_Paragraph_2[selectedLanguage]}</p>
            <ul className={props.listClassName}>
                <li><b>{InformedConsentFormKwtTextEnum.Introduction_ListItem1_Title[selectedLanguage]}</b></li>
                <li><b>{InformedConsentFormKwtTextEnum.Introduction_ListItem2_Title[selectedLanguage]}</b> {InformedConsentFormKwtTextEnum.Introduction_ListItem2_Paragraph[selectedLanguage]}</li>
                <li><b>{InformedConsentFormKwtTextEnum.Introduction_ListItem3_Title[selectedLanguage]}</b> {InformedConsentFormKwtTextEnum.Introduction_ListItem3_Paragraph[selectedLanguage]}</li>
                <li><b>{InformedConsentFormKwtTextEnum.Introduction_ListItem4_Title[selectedLanguage]}</b> {InformedConsentFormKwtTextEnum.Introduction_ListItem4_Paragraph[selectedLanguage]}</li>
                <li><b>{InformedConsentFormKwtTextEnum.Introduction_ListItem5_Title[selectedLanguage]}</b> {InformedConsentFormKwtTextEnum.Introduction_ListItem5_Paragraph1[selectedLanguage]} <b>{InformedConsentFormKwtTextEnum.Introduction_ListItem5_Paragraph2[selectedLanguage]}</b></li>
                <li><b>{InformedConsentFormKwtTextEnum.Introduction_ListItem6_Title[selectedLanguage]}</b> {InformedConsentFormKwtTextEnum.Introduction_ListItem6_Paragraph[selectedLanguage]}</li>
            </ul>
        </>
    )
}

const Benefits = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <p class="font-weight-bold">{InformedConsentFormKwtTextEnum.Benefits_Title[selectedLanguage]}</p>
            <p>{InformedConsentFormKwtTextEnum.Benefits_Paragraph[selectedLanguage]}</p>
        </>
    )
}

const Risks = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <RisksIntro selectedLanguage={selectedLanguage}/>
            <ul>
                <RisksItem1 selectedLanguage={selectedLanguage}/>
                <RisksItem2 selectedLanguage={selectedLanguage}/>
                <RisksItem3 selectedLanguage={selectedLanguage}/>
                <RisksItem4 selectedLanguage={selectedLanguage}/>
                <RisksItem5 selectedLanguage={selectedLanguage}/>
                <RisksItem6 selectedLanguage={selectedLanguage}/>
                <RisksItem7 selectedLanguage={selectedLanguage}/>
            </ul>
        </>
    )
}

const RisksIntro = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <p className="font-weight-bold">{InformedConsentFormKwtTextEnum.Risks_Intro_Title[selectedLanguage]}</p>
            <p>{InformedConsentFormKwtTextEnum.Risks_Intro_Paragraph[selectedLanguage]}</p>
        </>
    )
}

const RisksItem1 = (props) => {
    const {selectedLanguage} = props;

    return (
        <li>
            <b>{InformedConsentFormKwtTextEnum.Risks_Item1_Title[selectedLanguage]}</b> {InformedConsentFormKwtTextEnum.Risks_Item1_Paragraph[selectedLanguage]}
        </li>
    )
}

const RisksItem2 = (props) => {
    const {selectedLanguage} = props;

    return (
        <li>
            <b>{InformedConsentFormKwtTextEnum.Risks_Item2_Title[selectedLanguage]}</b> {InformedConsentFormKwtTextEnum.Risks_Item2_Paragraph[selectedLanguage]}
        </li>
    )
}

const RisksItem3 = (props) => {
    const {selectedLanguage} = props;

    return (
        <li>
            <b>{InformedConsentFormKwtTextEnum.Risks_Item3_Title[selectedLanguage]}</b> {InformedConsentFormKwtTextEnum.Risks_Item3_Paragraph[selectedLanguage]}
        </li>
    )
}

const RisksItem4 = (props) => {
    const {selectedLanguage} = props;

    return (
        <li>
            <b>{InformedConsentFormKwtTextEnum.Risks_Item4_Title[selectedLanguage]}</b> {InformedConsentFormKwtTextEnum.Risks_Item4_Paragraph1[selectedLanguage]}<br/>
            {InformedConsentFormKwtTextEnum.Risks_Item4_Paragraph2[selectedLanguage]}<br/>
            {InformedConsentFormKwtTextEnum.Risks_Item4_Paragraph3[selectedLanguage]}
        </li>
    )
}

const RisksItem5 = (props) => {
    const {selectedLanguage} = props;

    return (
        <li>
            <b>{InformedConsentFormKwtTextEnum.Risks_Item5_Title[selectedLanguage]}</b> {InformedConsentFormKwtTextEnum.Risks_Item5_Paragraph1[selectedLanguage]} <br/>
            {InformedConsentFormKwtTextEnum.Risks_Item5_Paragraph2[selectedLanguage]} <br/>
            {InformedConsentFormKwtTextEnum.Risks_Item5_Paragraph3[selectedLanguage]}
        </li>
    )
}

const RisksItem6 = (props) => {
    const {selectedLanguage} = props;

    return (
        <li>
            <b>{InformedConsentFormKwtTextEnum.Risks_Item6_Title[selectedLanguage]}</b> {InformedConsentFormKwtTextEnum.Risks_Item6_Paragraph[selectedLanguage]}
        </li>
    )
}

const RisksItem7 = (props) => {
    const {selectedLanguage} = props;

    return (
        <li>
            <b>{InformedConsentFormKwtTextEnum.Risks_Item7_Title[selectedLanguage]}</b> {InformedConsentFormKwtTextEnum.Risks_Item7_Paragraph[selectedLanguage]}
        </li>
    )
}

const Alternatives = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <p className="font-weight-bold">{InformedConsentFormKwtTextEnum.Alternatives_Title[selectedLanguage]}</p>
            <p>{InformedConsentFormKwtTextEnum.Alternatives_Paragraph1[selectedLanguage]}</p>
        </>
    )
}

const InjectionAndMedication = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <p className="font-weight-bold">{InformedConsentFormKwtTextEnum.Injection_And_Medication_Title[selectedLanguage]}</p>
            <p>{InformedConsentFormKwtTextEnum.Injection_And_Medication_Paragraph1[selectedLanguage]}</p>
        </>
    )
}

const DataCollectionAndPrivacy = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <DataCollectionAndPrivacyTitle selectedLanguage={selectedLanguage}/>
            <DataCollectionAndPrivacyParagraph1 selectedLanguage={selectedLanguage}/>
            <DataCollectionAndPrivacyParagraph2 selectedLanguage={selectedLanguage}/>
            <DataCollectionAndPrivacyParagraph3 selectedLanguage={selectedLanguage}/>
            <DataCollectionAndPrivacyParagraph4 selectedLanguage={selectedLanguage}/>
            <DataCollectionAndPrivacyParagraph5 selectedLanguage={selectedLanguage}/>
            <DataCollectionAndPrivacyParagraph6 selectedLanguage={selectedLanguage}/>
        </>
    )
}

const DataCollectionAndPrivacyTitle = (props) => {
    const {selectedLanguage} = props;

    return (
        <p className="font-weight-bold">{InformedConsentFormKwtTextEnum.Data_Collection_And_Privacy_Title[selectedLanguage]}</p>
    )
}

const DataCollectionAndPrivacyParagraph1 = (props) => {
    const {selectedLanguage} = props;

    return (
        <p>{InformedConsentFormKwtTextEnum.Data_Collection_And_Privacy_Paragraph1[selectedLanguage]}</p>
    )
}

const DataCollectionAndPrivacyParagraph2 = (props) => {
    const {selectedLanguage} = props;

    return (
        <p>{InformedConsentFormKwtTextEnum.Data_Collection_And_Privacy_Paragraph2[selectedLanguage]}</p>
    )
}

const DataCollectionAndPrivacyParagraph3 = (props) => {
    const {selectedLanguage} = props;

    return (
        <p>{InformedConsentFormKwtTextEnum.Data_Collection_And_Privacy_Paragraph3[selectedLanguage]}
            <ul>
                <li>{InformedConsentFormKwtTextEnum.Data_Collection_And_Privacy_Paragraph3_ListItem1[selectedLanguage]}</li>
                <li>{InformedConsentFormKwtTextEnum.Data_Collection_And_Privacy_Paragraph3_ListItem2[selectedLanguage]}</li>
                <li>{InformedConsentFormKwtTextEnum.Data_Collection_And_Privacy_Paragraph3_ListItem3[selectedLanguage]}</li>
                <li>{InformedConsentFormKwtTextEnum.Data_Collection_And_Privacy_Paragraph3_ListItem4[selectedLanguage]}</li>
                <li>{InformedConsentFormKwtTextEnum.Data_Collection_And_Privacy_Paragraph3_ListItem5[selectedLanguage]}</li>
            </ul>
        </p>
    )
}

const DataCollectionAndPrivacyParagraph4 = (props) => {
    const {selectedLanguage} = props;

    return (
        <p>{InformedConsentFormKwtTextEnum.Data_Collection_And_Privacy_Paragraph4[selectedLanguage]}</p>
    )
}

const DataCollectionAndPrivacyParagraph5 = (props) => {
    const {selectedLanguage} = props;

    return (
        <p>{InformedConsentFormKwtTextEnum.Data_Collection_And_Privacy_Paragraph5[selectedLanguage]}</p>
    )
}

const DataCollectionAndPrivacyParagraph6 = (props) => {
    const {selectedLanguage} = props;

    return (
        <p>{InformedConsentFormKwtTextEnum.Data_Collection_And_Privacy_Paragraph6[selectedLanguage]}
            <ul>
                <li>{InformedConsentFormKwtTextEnum.Data_Collection_And_Privacy_Paragraph6_ListItem1[selectedLanguage]}</li>
                <li>{InformedConsentFormKwtTextEnum.Data_Collection_And_Privacy_Paragraph6_ListItem2[selectedLanguage]}</li>
                <li>{InformedConsentFormKwtTextEnum.Data_Collection_And_Privacy_Paragraph6_ListItem3[selectedLanguage]}</li>
                <li>{InformedConsentFormKwtTextEnum.Data_Collection_And_Privacy_Paragraph6_ListItem4[selectedLanguage]}</li>
                <li>{InformedConsentFormKwtTextEnum.Data_Collection_And_Privacy_Paragraph6_ListItem5[selectedLanguage]}</li>
            </ul>
        </p>
    )
}

const QuestionsOfConcerns = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <p className="font-weight-bold">{InformedConsentFormKwtTextEnum.Questions_Of_Concerns_Title[selectedLanguage]}</p>
            <p>{InformedConsentFormKwtTextEnum.Questions_Of_Concerns_Paragraph[selectedLanguage]}</p>
        </>
    )
}

const Complaints = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <p style={{fontWeight: 'bold'}}>{InformedConsentFormKwtTextEnum.Complaints_Title[selectedLanguage]}</p>
            <p>{InformedConsentFormKwtTextEnum.Complaints_Paragraph[selectedLanguage]}</p>
        </>
    )
}

const Conclusion = (props) => {
    const {selectedLanguage} = props;

    return (
        <>
            <p className="font-weight-bold">{InformedConsentFormKwtTextEnum.Conclusion_Paragraph1[selectedLanguage]}</p>
            <p className="font-weight-bold">{InformedConsentFormKwtTextEnum.Conclusion_Paragraph2[selectedLanguage]}</p>
            <p className="font-weight-bold">{InformedConsentFormKwtTextEnum.Conclusion_Paragraph3[selectedLanguage]}</p>
        </>
    )
}

export default {
    Introduction,
    Benefits,
    Risks,
    RisksIntro,
    RisksItem1,
    RisksItem2,
    RisksItem3,
    RisksItem4,
    RisksItem5,
    RisksItem6,
    RisksItem7,
    Alternatives,
    InjectionAndMedication,
    DataCollectionAndPrivacy,
    DataCollectionAndPrivacyTitle,
    DataCollectionAndPrivacyParagraph1,
    DataCollectionAndPrivacyParagraph2,
    DataCollectionAndPrivacyParagraph3,
    DataCollectionAndPrivacyParagraph4,
    DataCollectionAndPrivacyParagraph5,
    DataCollectionAndPrivacyParagraph6,
    QuestionsOfConcerns,
    Complaints,
    Conclusion
};