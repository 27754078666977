import React, {Component} from "react";
import ShortTermGoalEnum from "../../treatment/enums/goal/ShortTermGoalEnum";
import TimelinePeriodEnum from "../../treatment/enums/goal/TimelinePeriodEnum";
import ProgressEnum from "../../treatment/enums/goal/ProgressEnum";

export default class GoalsEMRDashboardView extends Component {

    state = {};

    render() {
        const goal = this.props.goal;

        const hasGoal = goal && (
            goal.details || goal.specialRemarks ||
            goal.shortTermItems && goal.shortTermItems.length ||
            goal.longTermItems && goal.longTermItems.length);
        if (!hasGoal) {
            return null;
        }

        const hasDetails = goal.details;
        const hasSpecialRemarks = goal.specialRemarks;
        const hasShortGoals = goal.shortTermItems && goal.shortTermItems.length;
        const hasLongGoals = goal.longTermItems && goal.longTermItems.length;

        return (
            <div className={"content-row emr-background"} style={{width: "98%"}}>
                <div style={{marginBottom: "5px", width: "98%"}}>
                    {hasDetails &&
                    <>
                        <div className={"row"}>
                            <div className={"col-2 font-weight-bold"}>
                                Patient Goal
                            </div>
                            <div className={"col-1 font-weight-bold"}>
                                :
                            </div>
                            <div className={"col-9"}>
                                {goal && goal.details || ''}
                            </div>
                        </div>
                    </>
                    }
                    {hasShortGoals &&
                    <div className={"row"}>
                        <div className={"col-12"}>
                            {this.renderGoalsTable('short')}
                        </div>
                    </div>
                    }
                    {hasLongGoals &&
                    <div className={"row"}>
                        <div className={"col-12"}>
                            {this.renderGoalsTable('long')}
                        </div>
                    </div>
                    }
                    {hasSpecialRemarks &&
                    <>
                        <div className={"row"}>
                            <div className={"col-2 font-weight-bold"}>
                                Special Remarks
                            </div>
                            <div className={"col-1 font-weight-bold"}>
                                :
                            </div>
                            <div className={"col-9"}>
                                {goal && goal.specialRemarks || ''}
                            </div>
                        </div>
                    </>
                    }
                </div>

            </div>
        );
    }

    renderGoalsTable = (goalType) => {
        const forShortGoals = 'short' === goalType;
        const goalItems = forShortGoals ? this.props.goal.shortTermItems : this.props.goal.longTermItems;

        return (
            <div className="content-card m-0 h-auto">
                <table className={"emr-table"}>
                    <tbody>
                    <tr>
                        <th>Area of Focus</th>
                        <th>{forShortGoals ? 'Short' : 'Long'} Term Goals</th>
                        <th>Target</th>
                        <th colSpan="2">Timeline</th>
                        <th>%Ach</th>
                        <th>Progress</th>
                    </tr>
                    {goalItems.map((item, index) => {
                        return (<tr key={"shortTermGoalItem" + index}
                                    style={{backgroundColor: "white"}}>
                            <td>{item.areaOfConcern}</td>
                            <td style={{maxWidth: '150px'}}>{item.shortTermGoal ?
                                (ShortTermGoalEnum[item.shortTermGoal] ? ShortTermGoalEnum[item.shortTermGoal].name : item.shortTermGoal) : item.longTermGoal}</td>
                            <td>{item.target ? `${item.target}%`: ''}</td>
                            <td>{item.duration}</td>
                            <td>{item.period && TimelinePeriodEnum[item.period].name}</td>
                            <td>{item.achieved ? `${item.achieved}%` : ''}</td>
                            <td>{item.progress && ProgressEnum[item.progress].name}</td>
                        </tr>)
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
}
