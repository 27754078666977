import React, {Component} from "react";
import ReportViewUtils from "./ReportViewUtils";
import moment from "moment";

export default class AllergiesReportView extends Component {
    render() {
        const allergy = this.props.allergy;

        if (ReportViewUtils.isAllergyNull(allergy)) {
            return null;
        }

        if(allergy.none){
            return (<div>None Known</div>)
        }

        return (
            <div className={"visit-history-multiple-content-wrapper"}>
                <div key={"allergyReportView"}>
                    {allergy.items.map((item) =>
                    <div className={"display-flex"} style={{margin: "3px"}}>
                        <div className={"col-sm-12 no-padding overflow-wrap-anywhere"}>
                            {[item.otherAllergyType || item.allergyType?.name,
                                item.otherAllergen || item.allergen?.name,
                                item.medicationBrand,
                                item.otherAllergenSeverity || item.allergenSeverity?.name,
                                moment(item.discoveredDate).format('YYYY-MM-DD'),
                                item.otherAllergenReaction || item.allergenReaction?.name
                            ].join(', ')}</div>
                    </div>
                    )}
                </div>
            </div>
        );
    }

}
