import React, {Component} from 'react';
import './AppointmentFilter.css';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Tooltip from "../../components/tooltip/Tooltip";
import EnumAppointmentType from "./enums/EnumAppointmentType";
import PatientUtil from "../../utils/PatientUtil";

export const ClipOperation = {
    COPY: 'copy',
    CUT: 'cut'
};

export default class AppointmentClipBoard extends Component {

    render() {
        const {appointment, operation} = this.props;
        const appointmentColor = appointment ? EnumAppointmentType[appointment.patientType].bgColor : "white";

        return (
            appointment ?
                <div style={{marginLeft: '10px'}}>
                    <Tooltip title={appointment ? PatientUtil.getPatientName(appointment.patient.firstName,
                        appointment.patient.middleName, appointment.patient.lastName) : ""} open placement={"right"}>
                        <Button onClick={() => this.props.onClick(appointment)}
                                style={{backgroundColor: appointmentColor, color: "#6c757d"}}>
                            <FontAwesomeIcon icon={["fas", operation]}/>
                            {operation === ClipOperation.COPY ? " Copied" : "  Cut "}
                            &nbsp;
                            <FontAwesomeIcon icon={["fas", "info"]}
                                             onClick={e => {
                                                 e.stopPropagation();
                                                 this.props.onClick(appointment);
                                             }}/>
                            &nbsp;
                            <FontAwesomeIcon icon={["fas", "trash"]}
                                             onClick={e => {
                                                 e.stopPropagation();
                                                 this.props.onDelete();
                                             }}/>
                        </Button>
                    </Tooltip>
                </div> :
                <div></div>
        );
    }

}
