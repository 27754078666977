import React from 'react';
import {Button, Card, Col, Form, ListGroup, ListGroupItem, Row} from 'react-bootstrap';
import {CircularProgressbar} from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import AuthService from "../../../../services/auth-service/AuthService";
import {isEmpty} from "../../../../utils/ArrayUtil";
import SchedulingService from "../../../../services/scheduling-service/SchedulingService";


const PROFILE_PERCENTAGE = 20;
const CONTACT_PERCENTAGE = 20;
const LICENCE_PERCENTAGE = 20;
const PREFERENCE_PERCENTAGE = 20;
const BIOGRAPHY_PERCENTAGE = 10;
const SKILLS_PERCENTAGE = 5;
const CONDITION_PERCENTAGE = 5;

class StaffProfileCompletion extends React.Component {


    getTotalPercentage = (allPercentages) => {//rounding > 100
        return Object.values(allPercentages).reduce((p1, p2) => p1 + p2, 0);
    }

    getAllPercentages = () => {
        const {staff} = this.props;
        let percentages = {};
        percentages['profile'] = this.calculateProfilePercentage(staff);
        percentages['contact'] = this.calculateContactPercentage(staff);
        percentages['license'] = this.calculateLicensePercentage(staff);
        percentages['preference'] = this.calculatePreferencePercentage();
        percentages['biography'] = staff.biography ? BIOGRAPHY_PERCENTAGE : 0;
        percentages['skills'] = staff.skills?.length > 0 ? SKILLS_PERCENTAGE : 0;
        percentages['condition'] = staff.specializedConditions?.length > 0 ? CONDITION_PERCENTAGE : 0;
        return percentages;
    }

    calculateProfilePercentage = (staff) => {
        const weight = PROFILE_PERCENTAGE / 8;
        return (Math.ceil((staff.gender ? weight : 0) +
            (staff.speciality ? weight : 0) +
            (staff.birthday ? weight : 0) +
            (staff.nationality ? weight : 0) +
            (staff.languages?.length > 0 ? weight : 0) +
            (staff.joiningDate ? weight : 0)+
            (staff.salutation ? weight : 0)+
            (staff.qualification ? weight : 0)));
    }

    calculateContactPercentage = (staff) => {
        const weight = CONTACT_PERCENTAGE / 3;
        return (Math.ceil((staff.mobilePhone ? weight : 0) + (staff.email ? weight : 0) + (staff.address ? weight : 0)));
    }

    calculateLicensePercentage = (staff) => {
        const weight = LICENCE_PERCENTAGE / 3;
        return (Math.ceil((staff.clinicianLicense ? weight : 0) + (staff.licenceIssueDate ? weight : 0) + (staff.licenceExpiryDate ? weight : 0)));
    }

    calculatePreferencePercentage = () => {
        const generalPrefWeight = (0.2 * PREFERENCE_PERCENTAGE) / 4;
        const preference = this.props.staffPreference;
        console.log("all days",this.calculateDayPreferencePercentage(preference));
        return (Math.ceil((preference.noOfDaysPerWeek ? generalPrefWeight : 0) +
                (preference.workingHoursPerDay ? generalPrefWeight : 0) +
                (preference.consecutiveOrSplit ? generalPrefWeight : 0) +
                (preference.preferredRooms ? generalPrefWeight : 0) +
                this.calculateDayPreferencePercentage(preference))
        );
    }

    calculateDayPreferencePercentage = (preference) => {
        if (isEmpty(preference.dayPreferences))
            return 0;
        let dayPreferencePercentages = 0;
        for (const day of preference.dayPreferences) {
            dayPreferencePercentages += this.calculatePerDay(day);
        }
        return dayPreferencePercentages;
    }

    calculatePerDay = (day) => {
        const dayPrefWeight = parseFloat(((0.8 * PREFERENCE_PERCENTAGE) / 72).toFixed(2));
        const {newPatient, followUp} = SchedulingService.calculateSessionDurations(this.props.staffPreference, day);

        const dayPercentage = ((day.startTime ? dayPrefWeight : 0) +
            (day.lunchBreak?.lunch1Time ? dayPrefWeight : 0) +
            (day.lunchBreak?.lunch1Duration ? dayPrefWeight : 0) +
            (day.lunchBreak?.lunch2Time ? dayPrefWeight : 0) +
            (day.lunchBreak?.lunch2Duration ? dayPrefWeight : 0) +
            (day.breakTimeAM ? dayPrefWeight : 0) +
            (day.breakTimePM ? dayPrefWeight : 0) +
            (this.hasValue(day.noOfPatientPerDay) ? dayPrefWeight : 0) +
            (this.hasValue(day.percentageOfNewPatientPerDay) ? dayPrefWeight : 0) +
            (this.hasValue(day.percentageOfExtraTimeForPatient) ? dayPrefWeight : 0) +
            (this.hasValue(newPatient) ? dayPrefWeight : 0) +
            (this.hasValue(followUp) ? dayPrefWeight : 0));

        return dayPercentage;
    }

    render() {
        const allPercentages = this.getAllPercentages();
        const totalPercentage = this.getTotalPercentage(allPercentages);

        return (
            <Card style={{width: '100%', minWidth:'250px'}} className='small-screen-margin'>
                <Card.Body>
                    <Card.Title>Profile Completion</Card.Title>
                    <div style={{width: "50%", display: 'flex', margin: 'auto', paddingTop: '30px'}}>
                        <CircularProgressbar value={totalPercentage} text={`${totalPercentage}%`}/>
                    </div>
                </Card.Body>
                {this.renderEachSectionsPercentages(allPercentages)}
                {AuthService.userHasAuthority("UPDATE_SELF_PROVIDER") &&
                <ListGroup className="list-group-flush">
                    <ListGroupItem>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <p>Send Reminder for profile update</p>
                        </div>
                        <div style={{display: 'flex', justifyContent: 'center'}}>
                            <Button style={{width: '120px'}} variant="primary" size="sm">Send</Button>
                        </div>
                    </ListGroupItem>
                </ListGroup>
                }
            </Card>
        );
    }

    renderEachSectionsPercentages = (percentages) => {
        return (
            <Card.Body>
                <Form>
                    {this.renderFormGroup('Profile', percentages['profile'])}
                    {this.renderFormGroup('Contact', percentages['contact'])}
                    {this.renderFormGroup('License', percentages['license'])}
                    {this.renderFormGroup('Preferences', percentages['preference'])}
                    {this.renderFormGroup('Biography', percentages['biography'])}
                    {this.renderFormGroup('Skills', percentages['skills'])}
                    {this.renderFormGroup('Conditions', percentages['condition'])}
                </Form>
            </Card.Body>);
    }

    renderFormGroup = (name, val, readOnly = true) => {
        return (
            <Form.Group as={Row} controlId="formPlaintextEmail" style={{marginBottom: '0rem'}}>
                <Form.Label column className="font-weight-bold">
                    {name + ':'}
                </Form.Label>
                <Col>
                    <Form.Control size="sm" class="form-control" plaintext={readOnly} readOnly={readOnly}
                                  value={val+"%"}/>
                </Col>
            </Form.Group>);
    };

    hasValue(field){
        return field || field === 0;
    }

}

export default StaffProfileCompletion;