import {BaseConstants} from "../constants/BaseConstants";

const initialState = {
    baseUrl: "",
    remotingOperationCount: 0
};

export function base(state = initialState, action) {

    if (action.type === 'INCREMENT_REMOTING_OPERATION_COUNT') {
        let newState = {...state};
        newState["remotingOperationCount"] = newState["remotingOperationCount"] + 1;
        return newState;
    } else if (action.type === 'DECREMENT_REMOTING_OPERATION_COUNT') {
        let newState = {...state};
        newState["remotingOperationCount"] = newState["remotingOperationCount"] - 1;
        if (newState["remotingOperationCount"] < 0) {
            newState["remotingOperationCount"] = 0;
        }
        return newState;
    } else if (action.type === 'SET_VALUE') {
        let newState = {...state};
        newState[action.key] = action.value;
        return newState;
    } else { // cached values
        const cachedValues = new Map([
            [BaseConstants.SCHEDULES, "schedules"], [BaseConstants.PRESCRIPTIONS, "prescriptions"],
            [BaseConstants.PARAMETERS, "parameterMap"], [BaseConstants.NATIONALITIES, "lookupNationalities"],
            [BaseConstants.COUNTRIES, "lookupCountries"], [BaseConstants.LANGUAGES, "lookupLanguages"],
            [BaseConstants.RELIGIONS, "lookupReligions"], [BaseConstants.MARITAL_STATUS, "lookupMaritalStatuses"],
            [BaseConstants.RELATIONSHIP_TYPES, "relationshipTypes"], [BaseConstants.ALLERGY_TYPES, "allergyTypes"],
            [BaseConstants.ALLERGEN_TYPES, "allergenTypes"], [BaseConstants.ALLERGEN_REACTION_TYPES, "allergenReactionTypes"],
            [BaseConstants.ALLERGY_SEVERITIES, "allergySeverities"], [BaseConstants.LOOKUP_GENDERS, "lookupGenders"],
            [BaseConstants.EMPLOYMENT_STATUSES, "employmentStatuses"], [BaseConstants.EDUCATION_LEVELS, "educationLevels"],
            [BaseConstants.SMOKING_DEVICES, "smokingDevices"], [BaseConstants.SMOKING_STATUSES, "smokingStatuses"],
            [BaseConstants.DRINKING_STATUSES, "drinkingStatuses"], [BaseConstants.SUBSTANCE_ABUSE_STATUSES, "substanceAbuseStatuses"],
            [BaseConstants.MEDICATION_ROUTES, "medicationRoutes"], [BaseConstants.MEDICATION_UNITS, "medicationUnits"]
        ]);

        const cachedValueLabel = cachedValues.get(action.type);
        if (cachedValueLabel) {
            let newState = {...state};
            newState[cachedValueLabel] = action.value;
            return newState;
        }
    }

    return state;
}
