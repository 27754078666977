import "./MedicalReports.css"
import React, {Component} from "react";

export default class ReportSection extends Component {

    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        let children = this.props.children;
        children = Array.isArray(children) ? children : [children];

        if([1, 2, 3].filter(index => children[index-1]).length == 0)
            return null;

        return (
            <div style={{padding: 10, width: '100%'}}>
                <div className={"row no-padding"}
                     style={{
                         height: '55px',
                         display: "flex",
                         flexFlow: "row",
                         fontWeight: 'bold',
                         fontSize: 24,
                         backgroundColor: 'rgba(0, 0, 0, .05)',
                         paddingLeft: 10
                     }}>

                    {[1, 2, 3].filter(index => children[index-1]).map((i) => {
                        return this.props[`header${i}`] ?
                            <div key={`header${i}`} className={"col pl-3"} style={{paddingTop: 10}}>
                                {this.props[`header${i}`]}
                            </div> : null
                    })}
                </div>

                <div className={"row no-padding"}
                     style={{
                         display: "flex",
                         flexFlow: "row"
                     }}>

                    {[1, 2, 3].filter(index => children[index-1]).map((i) => {
                        return this.props[`header${i}`] ?
                            <div key={`header${i}`} className={"col pl-3"} style={{paddingTop: 10}}>
                                {children[i - 1]}
                            </div> : null
                    })}
                </div>
            </div>
        );
    }

}
