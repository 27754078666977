import React, {Component} from "react";
import {Modal, NumberInput} from "../../../../../components";
import '../../EMR.css';
import Select from "react-select";
import RemotingService from "../../../../../services/remoting-service/RemotingService";
import TextInput from "../../../../../components/text-input/TextInput";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import InternalSpecialityEnum from "../enums/referral/InternalSpecialityEnum";
import ExternalSpecialityEnum from "../enums/referral/ExternalSpecialityEnum";
import InterventionTypeEnum from "../enums/referral/InterventionTypeEnum";
import CheckboxInput from "../../../../../components/checkbox-input/CheckboxInput";
import AddButton from "../../common/table/AddButton";
import RemoveButton from "../../common/table/RemoveButton";
import AuthService from "../../../../../services/auth-service/AuthService";
import ValidationUtil from "../../../../../components/form/validator/ValidationUtil";
import {FormField} from "../../../../../components/form";
import DiagnosisGroup from "../views/DiagnosisGroup";
import {MultiSelect} from "primereact/multiselect";

export default class ReferralModal extends Component {

    constructor(props) {
        super(props);
        this.MINIMUM_SEARCH_LENGTH = 2;

        this.state = {
            internal: {
                cptCodeList: [],
                needsApproval: false
            },
            external: {},
            intervention: {},
            selectedCpt: null,
            cptCodeSuggestions: [],
            diagnosisGroups: this.getDiagnosisGroups()
        };

         this.customStyles = {
            option: (provided, state) => ({
                ...provided,
                textAlign:'left',
                whiteSpace:'nowrap',
            }),
            menu:(provided, state)=>({
                ...provided,
                position:'absolute',
                width:'100wv',
            }),
            menuList: (provided, state) => ({
                ...provided,
            }),
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.visit.diagnosis.diagnosisGroups !== this.props.visit.diagnosis.diagnosisGroups) {
            this.setState({diagnosisGroups: this.getDiagnosisGroups()});
        }
    }

    getDiagnosisGroups() {
        const diagnosisGroups = this.props.visit.diagnosis.diagnosisGroups;

        let diagnosisGroupsWithPrincipalItem = [];
        diagnosisGroups.forEach(dg => {
            const principalItem = dg.diagnosisItems.find(i => i.level == "PRINCIPAL");
            if (principalItem) {
                diagnosisGroupsWithPrincipalItem.push(dg);
            }
        });

        return diagnosisGroupsWithPrincipalItem;
    }

    noOptionsMessage(input) {
        if (input.inputValue == null || input.inputValue.length < this.MINIMUM_SEARCH_LENGTH) {
            return <div>Type {this.MINIMUM_SEARCH_LENGTH} characters</div>;
        } else {
            return <div>No options</div>;
        }
    }

    getStaffsByName(text, callback) {
        if (text.length < this.MINIMUM_SEARCH_LENGTH) {
            return callback(null);
        }
        RemotingService.getRemoteCall(`api/staff/list-base?name=${text}`, null, (result) => {
            const staffs = result.items;
            if (staffs == null || staffs.length === 0) {
                return callback(null);
            }
            let options = staffs.map(s => {
                return {value: s, label: s.name}
            });
            return callback(options);
        });
    }

    cptCodeSearch() {
        const needsApproval = this.state.internal.needsApproval;
        const speciality = InternalSpecialityEnum[this.state.internal.speciality]?.name;
        const params = {
            speciality: speciality,
            insurance: needsApproval ? this.props.insurance?.tpa?.name : null,
            insuranceCompanyId: (needsApproval && !this.props.insurance?.tpa?.name) ? this.props.insurance?.company?.id : null,
            scopes: ["ALL", needsApproval ? "DIRECT_BILLING" : "SELF_PAY"]
        };
        RemotingService.getRemoteCall(`api/cpt/code-or-description/${this.props.visit.clinic.country}`, params, cptCodes => {
            cptCodes.forEach(cpt => cpt.label = (cpt.cptCode || cpt.description));
            this.setState({cptCodeSuggestions: cptCodes});
        });
    }

    save() {
        this.props.save();
        this.props.forceClose();
    }

    render() {
        this.internalFormFields = [];

        return (
            <Modal visible={true}
                   header="Referrals"
                   submitContent="Confirm"
                   submitAction={this.save.bind(this)}
                   closeAction={() => this.props.close()}>
                <div className={"emr-modal content-row emr-background p-4"} style={{width: "100%"}}>
                    <div className={"row no-margin px-4 w-100"} style={{marginTop: "20px"}}>
                        <h5 className="font-weight-bold mb-3">Internal</h5>
                        <table className={"emr-table"}>
                            <tbody>
                            <tr>
                                <th>Diagnosis</th>
                                <th>Speciality</th>
                                <th>Provider Name</th>
                                <th>CPT Code</th>
                                <th>No of Sessions</th>
                                <th>Remarks</th>
                                <th>Approval Required</th>
                                <th style={{color: "#E85E5E"}}>ⓧ</th>
                            </tr>
                            {(this.props.referral == null || this.props.referral.internals == null)
                                ? null : this.props.referral.internals.map((item, index) => {
                                        return (
                                            <tr key={"specialTestsModal" + index} style={{backgroundColor: "white"}}>
                                                <td><DiagnosisGroup diagnosisGroup={item.diagnosisGroup} showSecondaryItems/></td>
                                                <td>{InternalSpecialityEnum[item.speciality]?.name}</td>
                                                <td>{item.provider?.name}</td>
                                                <td>
                                                    {
                                                        item.cptCodeList.map((cpt, index) => {
                                                            return (index === 0 ? "" : ", ") + cpt.cptCode + " " + cpt.description
                                                        })
                                                    }
                                                </td>
                                                <td>{item.noOfSessions}</td>
                                                <td>{item.remarks}</td>
                                                <td>{true === item.needsApproval ? 'Yes' : 'No'}</td>
                                                <td>
                                                    <RemoveButton
                                                        onClick={() => {
                                                            this.props.referral.internals.splice(index, 1);
                                                            this.forceUpdate();
                                                        }}
                                                    />
                                                </td>
                                            </tr>);
                                    }
                                )
                            }
                            <tr style={{backgroundColor: "white"}}>
                                <td>
                                    <FormField ref={formField => this.internalFormFields.push(formField)}
                                               required validateOn={this.state.internal.diagnosisGroup}>
                                        <Select
                                            className="emr-in-table-select-container"
                                            classNamePrefix="emr-in-table-select"
                                            placeholder="Code"
                                            value={this.state.internal.diagnosisGroup != null ? {
                                                label: <DiagnosisGroup diagnosisGroup={this.state.internal.diagnosisGroup}/>,
                                                value: this.state.internal.diagnosisGroup
                                            } : null}
                                            onChange={(event) => {
                                                this.state.internal.diagnosisGroup = event.value;
                                                this.forceUpdate();
                                            }}
                                            options={this.state.diagnosisGroups.map(diagnosisGroup => {
                                                return {
                                                    label: <DiagnosisGroup diagnosisGroup={diagnosisGroup} showSecondaryItems/>,
                                                    value: diagnosisGroup
                                                }
                                            })}
                                        />
                                    </FormField>
                                </td>
                                <td style={{minWidth: "80px"}}>
                                    <Select
                                        className={"emr-in-table-select-container"}
                                        classNamePrefix={"emr-in-table-select"}
                                        placeholder={"Type"}
                                        styles={this.customStyles}
                                        value={this.state.internal.speciality != null ?
                                            {
                                                value: this.state.internal.speciality,
                                                label: InternalSpecialityEnum[this.state.internal.speciality].name
                                            } : null}
                                        onChange={(event) => {
                                            this.state.internal.speciality = event.value;
                                            this.state.internal.cptCodeList = [];
                                            this.forceUpdate(() => this.cptCodeSearch());
                                        }}
                                        options={InternalSpecialityEnum.toArray().map(s => {
                                            return {value: s.key, label: s.name}
                                        })}
                                    />
                                </td>
                                <td>
                                    <FormField ref={formField => this.internalFormFields.push(formField)}
                                               validateOn={this.state.internal.provider}>
                                        <AsyncSelect
                                            className="emr-in-table-select-container"
                                            classNamePrefix="emr-in-table-select"
                                            styles={this.customStyles}
                                            noOptionsMessage={this.noOptionsMessage.bind(this)}
                                            loadOptions={this.getStaffsByName.bind(this)}
                                            value={this.state.internal.provider != null ?
                                                {
                                                    value: this.state.internal.provider,
                                                    label: this.state.internal.provider.name
                                                } : null}
                                            onChange={(event) => {
                                                this.state.internal.provider = event.value;
                                                if (!!event.value) {
                                                    this.state.internal.needsApproval = AuthService.getSelectedClinicCountriesInfo().uaeSelected;
                                                } else {
                                                    this.state.internal.needsApproval = false;
                                                }
                                                this.forceUpdate(() => this.cptCodeSearch());
                                            }}
                                        />
                                    </FormField>
                                </td>
                                <td className={"cpt-style"}>
                                    <FormField ref={formField => this.internalFormFields.push(formField)}
                                               disabled={!this.state.internal.needsApproval}
                                               required validateOn={this.state.internal.cptCodeList}>
                                        <FormField ref={formField => this.internalFormFields.push(formField)}
                                                   disabled={!this.state.internal.needsApproval}
                                                   customValidator={cptCodeList => {
                                                       return cptCodeList.every(cpt => cpt.insuranceList.some(i => i.insurance === this.props.insurance?.tpa?.name
                                                                                                            || (i.insuranceCompanyId != null && i.insuranceCompanyId == this.props.insurance?.company?.id)));
                                                   }}
                                                   customValidatorMessage={`Selected cpt(s) must be applicable for insurance: ${this.props.insurance?.tpa?.name ?? this.props.insurance?.company?.name}`}
                                                   validateOn={this.state.internal.cptCodeList}>
                                            <MultiSelect className={"referral-autocomplete"}
                                                         filter={true} filterPlaceholder="Search" placeholder="Choose"
                                                         options={this.state.cptCodeSuggestions}
                                                         optionLabel="label"
                                                         itemTemplate={cpt => <span>{cpt.cptCode} - {cpt.description}</span>}
                                                         value={this.state.internal.cptCodeList}
                                                         onChange={event => {
                                                             this.setState(state => {
                                                                 let newState = {...state}
                                                                 newState.internal.cptCodeList = event.value;
                                                                 return newState;
                                                             });
                                                         }}/>
                                        </FormField>
                                    </FormField>
                                </td>
                                <td>
                                    <FormField ref={formField => this.internalFormFields.push(formField)}
                                               required min={1} validateOn={this.state.internal.noOfSessions}>
                                        <NumberInput value={this.state.internal.noOfSessions || ''}
                                                     min={1}
                                                     onChange={value => {
                                                         this.state.internal.noOfSessions = value;
                                                         this.forceUpdate();
                                                     }}/>
                                    </FormField>
                                </td>
                                <td>
                                    <TextInput value={this.state.internal.remarks || ''}
                                               maxlength={1024}
                                               onChange={value => {
                                                   this.state.internal.remarks = value;
                                                   this.forceUpdate();
                                               }}/>
                                </td>
                                <td>
                                    <CheckboxInput value={this.state.internal.needsApproval}
                                                   onChange={value => {
                                                       this.state.internal.needsApproval = value;
                                                       this.state.internal.cptCodeList = [];
                                                       this.forceUpdate(() => this.cptCodeSearch());
                                                   }}/>
                                </td>
                                <td>
                                    <AddButton
                                        onClick={() => {
                                            if (!ValidationUtil.checkWithNotification(this.internalFormFields)) {
                                                return;
                                            }

                                            this.props.referral.internals.push(this.state.internal);
                                            this.setState({
                                                cpt: null,
                                                internal: {
                                                    cptCodeList: []
                                                }
                                            }, () => ValidationUtil.clear(this.internalFormFields));
                                        }}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={"row no-margin px-4 mt-2 w-100"} style={{marginTop: "20px"}}>
                        <h5 className="font-weight-bold mt-5 mb-3">External</h5>
                        <table className={"emr-table"}>
                            <tbody>
                            <tr>
                                <th>Speciality</th>
                                <th>Provider Name</th>
                                <th>Recommendations</th>
                                <th>Remarks</th>
                                <th style={{color: "red"}}>ⓧ</th>
                            </tr>
                            {(this.props.referral == null || this.props.referral.externals == null)
                                ? null : this.props.referral.externals.map((item, index) => {
                                        return (
                                            <tr key={"specialTestsModal" + index} style={{backgroundColor: "white"}}>
                                                <td>{ExternalSpecialityEnum[item.speciality]?.name}</td>
                                                <td>{item.provider}</td>
                                                <td>{item.recommendations}</td>
                                                <td>{item.remarks}</td>
                                                <td>
                                                    <RemoveButton
                                                        onClick={() => {
                                                            this.props.referral.externals.splice(index, 1);
                                                            this.forceUpdate();
                                                        }}
                                                    />
                                                </td>
                                            </tr>);
                                    }
                                )
                            }
                            <tr style={{backgroundColor: "white"}}>
                                <td style={{minWidth: "80px"}}>
                                    <Select
                                        className={"emr-in-table-select-container"}
                                        classNamePrefix={"emr-in-table-select"}
                                        placeholder={"Type"}
                                        maxMenuHeight={180}
                                        styles={this.customStyles}
                                        value={this.state.external.speciality != null ?
                                            {
                                                value: this.state.external.speciality,
                                                label: ExternalSpecialityEnum[this.state.external.speciality].name
                                            } : null}
                                        onChange={(event) => {
                                            this.state.external.speciality = event.value;
                                            this.forceUpdate();
                                        }}
                                        options={ExternalSpecialityEnum.toArray().map(s => {
                                            return {value: s.key, label: s.name}
                                        })}
                                    />
                                </td>
                                <td>
                                    <TextInput value={this.state.external.provider || ''}
                                               onChange={value => {
                                                   this.state.external.provider = value;
                                                   this.forceUpdate();
                                               }}/>
                                </td>
                                <td>
                                    <TextInput value={this.state.external.recommendations || ''}
                                               onChange={value => {
                                                   this.state.external.recommendations = value;
                                                   this.forceUpdate();
                                               }}/>
                                </td>
                                <td>
                                    <TextInput value={this.state.external.remarks || ''}
                                               maxlength={1024}
                                               onChange={value => {
                                                   this.state.external.remarks = value;
                                                   this.forceUpdate();
                                               }}/>
                                </td>
                                <td>
                                    <AddButton
                                        disabled={!(this.state.external.speciality || this.state.external.provider || this.state.external.recommendations || this.state.external.remarks)}
                                        onClick={() => {
                                            this.props.referral.externals.push(this.state.external);
                                            this.setState({external: {}});
                                        }}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={"row no-margin px-4 mt-2 w-100"} style={{marginTop: "20px"}}>
                        <h5 className="font-weight-bold mt-5 mb-3">Interventions</h5>
                        <table className={"emr-table"}>
                            <tbody>
                            <tr>
                                <th>Type</th>
                                <th>Details</th>
                                <th>Recommendations</th>
                                <th>Remarks</th>
                                <th style={{color: "red"}}>ⓧ</th>
                            </tr>
                            {(this.props.referral == null || this.props.referral.interventions == null)
                                ? null : this.props.referral.interventions.map((item, index) => {
                                        return (
                                            <tr key={"specialTestsModal" + index} style={{backgroundColor: "white"}}>
                                                <td>{InterventionTypeEnum[item.type]?.name}</td>
                                                <td>{item.details}</td>
                                                <td>{item.recommendations}</td>
                                                <td>{item.remarks}</td>
                                                <td>
                                                    <RemoveButton
                                                        onClick={() => {
                                                            this.props.referral.interventions.splice(index, 1);
                                                            this.forceUpdate();
                                                        }}
                                                    />
                                                </td>
                                            </tr>);
                                    }
                                )
                            }
                            <tr style={{backgroundColor: "white"}}>
                                <td style={{minWidth: "80px"}}>
                                    <Select
                                        className={"emr-in-table-select-container"}
                                        classNamePrefix={"emr-in-table-select"}
                                        placeholder={"Type"}
                                        menuPlacement="top"
                                        value={this.state.intervention.type != null ?
                                            {
                                                value: this.state.intervention.type,
                                                label: InterventionTypeEnum[this.state.intervention.type].name
                                            } : null}
                                        onChange={(event) => {
                                            this.state.intervention.type = event.value;
                                            this.forceUpdate();
                                        }}
                                        options={InterventionTypeEnum.toArray().map(s => {
                                            return {value: s.key, label: s.name}
                                        })}
                                    />
                                </td>
                                <td>
                                    <TextInput value={this.state.intervention.details || ''}
                                               onChange={value => {
                                                   this.state.intervention.details = value;
                                                   this.forceUpdate();
                                               }}/>
                                </td>
                                <td>
                                    <TextInput value={this.state.intervention.recommendations || ''}
                                               onChange={value => {
                                                   this.state.intervention.recommendations = value;
                                                   this.forceUpdate();
                                               }}/>
                                </td>
                                <td>
                                    <TextInput value={this.state.intervention.remarks || ''}
                                               maxlength={1024}
                                               onChange={value => {
                                                   this.state.intervention.remarks = value;
                                                   this.forceUpdate();
                                               }}/>
                                </td>
                                <td>
                                    <AddButton
                                        disabled={!(this.state.intervention.type || this.state.intervention.details || this.state.intervention.recommendations || this.state.intervention.remarks)}
                                        onClick={() => {
                                            this.props.referral.interventions.push(this.state.intervention);
                                            this.setState({intervention: {}});
                                        }}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Modal>
        )
    }

}
