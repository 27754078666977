import React, {Component} from "react";
import EnumLanguage from "../../enums/EnumLanguage";
import EnumPatientSpecialStatus from "./enums/EnumPatientSpecialStatus";
import Tooltip from "../../components/tooltip/Tooltip";
import PropTypes from "prop-types";

export default class SpecialStatusIcons extends Component {

    static propTypes = {
        specialStatusList: PropTypes.array,
        iconSize: PropTypes.string,
        iconColor: PropTypes.string,
        fontSize: PropTypes.string,
        fontColor: PropTypes.string
    }

    static defaultProps = {
        specialStatusList: [],
        iconSize: "30px",
        iconColor: "#9aca3c",
        fontSize: "12px",
        fontColor: "#999999"
    }

    constructor(props) {
        super(props);
        this.state = {
            specialStatusList: this.getExpandedSpecialStatusList(props.specialStatusList)
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.specialStatusList !== this.props.specialStatusList) {
            this.setState({specialStatusList: this.getExpandedSpecialStatusList(this.props.specialStatusList)});
        }
    }

    getExpandedSpecialStatusList(specialStatusList) {
        let expandedSpecialStatusList = [];

        specialStatusList
            .forEach(ss => {
                if (ss.specialStatus === "LANGUAGE") {
                    const languageLimitList = ss.languageLimits.split(", ");
                    languageLimitList.forEach(language => {
                        expandedSpecialStatusList.push({
                            icon: EnumPatientSpecialStatus[ss.specialStatus].icon,
                            tooltip: `${EnumPatientSpecialStatus.LANGUAGE.name}: ${language}`,
                            text: EnumLanguage[language] ? EnumLanguage[language].code : null
                        });
                    });
                } else {
                    expandedSpecialStatusList.push({
                        icon: EnumPatientSpecialStatus[ss.specialStatus].icon,
                        tooltip: EnumPatientSpecialStatus[ss.specialStatus].name,
                        text: null
                    });
                }
            });

        return expandedSpecialStatusList;
    }

    render() {
        const {iconSize, iconColor, fontSize, fontColor} = this.props;

        return this.state.specialStatusList.map((specialStatus, index) =>
            <>
                <Tooltip title={specialStatus.tooltip}>
                    <div style={{position: "relative", textAlign: "center", margin: 3}}>
                        <specialStatus.icon style={{height: iconSize, width: iconSize, fill: iconColor}}/>
                        {specialStatus.text &&
                        <span className="font-weight-bold"
                              style={{
                                  fontSize: fontSize,
                                  color: fontColor,
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                  cursor: "default"
                              }}>
                            {specialStatus.text}
                        </span>
                        }
                    </div>
                </Tooltip>
            </>
        );
    }

}