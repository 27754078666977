import React, {Component} from "react";
import DateUtil from "../../../../utils/DateUtil";
import PrescriptionStatusEnum from "./enums/PrescriptionStatusEnum";
import ActionMenu from "../../../../components/action-menu/ActionMenu";
import ActionMenuItem from "../../../../components/action-menu/ActionMenuItem";
import ConfirmIcon from "../../../../components/action-menu/icons/ConfirmIcon";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PrescriptionTimeUnitEnum from "./enums/PrescriptionTimeUnitEnum";
import NotificationService from "../../../../services/notification-service/NotificationService";
import AuthService from "../../../../services/auth-service/AuthService";
import {noop} from "lodash-es";
import {PrintablePrescription} from "./PrintablePrescription";
import {Modal} from "../../../../components";
import DiagnosisGroup from "../treatment/views/DiagnosisGroup";


export default class PreviousPrescriptionsView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPrescription: null,
            printWindowVisible: false
        };
    }

    getReferenceNo(prescription) {
        if (prescription.prescriptionItems.filter(i => i.medicationId != null).length === 0) {
            NotificationService.showNotification({
                severity: 'error',
                summary: 'Validation Error',
                detail: 'There is no any suitable medication for Eclaim.'
            });
            return;
        }

        RemotingService.postRemoteCall(`api/emr/prescription/${prescription.id}/get-reference-no`, null, (prescription) => {
            if (prescription) {
                this.props.onGetReferenceNo(prescription);
            }
        });
    }

    reorder(prescription) {
        this.props.onReorder(prescription);
    }

    discontinued(prescription) {
        RemotingService.postRemoteCall(`api/emr/prescription/${prescription.id}/discontinued`, null, () => {
            this.props.refreshData();
        });
    }

    noPickup(prescription) {
        RemotingService.postRemoteCall(`api/emr/prescription/${prescription.id}/no-pickup`, null, () => {
            this.props.refreshData();
        });
    }

    print(prescription) {
        RemotingService.postRemoteCall(`api/emr/prescription/${prescription.id}/prescribed`, null, (updatedPrescription) => {
            RemotingService.instance.get('api/file/download/' + updatedPrescription.pdfPath, {responseType: 'blob'})
                .then((response) => {
                    updatedPrescription["pdfData"] = response.data;
                    this.setState({
                        selectedPrescription: updatedPrescription,
                        printWindowVisible: true
                    }, () => this.props.refreshData());
                });
        }, noop, true, false);
    }

    onPrintClosed() {
        this.setState({printWindowVisible: false});
    }

    render() {
        const prescriptions = this.props.prescriptions;
        return (
            <>
                {prescriptions.map(prescription => {
                    return <div className="content-card" style={{marginTop: 15}}>
                        <div className="d-flex m-2 justify-content-start w-100">
                            <div className="d-flex w-100 justify-content-between my-1">
                                <div>
                                    {
                                        this.props.isDubai &&
                                        <Field label="eRx Ref. No:"
                                               value={prescription.erxRefNo ? prescription.erxRefNo : "-"}/>
                                    }
                                </div>
                                <div className="d-flex w-100 justify-content-end mr-2">
                                    <Field label="Ordered Date:"
                                           value={DateUtil.formatDateMMM(prescription.creationDate)}/>
                                    <Field label="Prepared by:" value={prescription.staff.name}/>
                                    <Field label="Status: "
                                           value={PrescriptionStatusEnum[prescription.status].name}
                                           valueStyle={{color: PrescriptionStatusEnum[prescription.status].color}}/>
                                    {!this.props.disabled &&
                                    <ActionMenu id="prescriptionActionMenu">
                                        {prescription.status == "PENDING" && AuthService.userHasAuthority("UPDATE_EMR_PRESCRIPTION") &&
                                        <ActionMenuItem
                                            icon={<ConfirmIcon/>}
                                            onClick={() => this.getReferenceNo(prescription)}
                                            text="Get Eclaim Erx"/>
                                        }
                                        {AuthService.userHasAuthority("UPDATE_EMR_PRESCRIPTION") &&
                                        <ActionMenuItem
                                            icon={<ConfirmIcon/>}
                                            onClick={() => this.reorder(prescription)}
                                            text="Reorder"/>
                                        }
                                        {prescription.status == "PENDING" && AuthService.userHasAuthority("UPDATE_EMR_PRESCRIPTION") &&
                                        <ActionMenuItem
                                            icon={<ConfirmIcon/>}
                                            onClick={() => this.discontinued(prescription)}
                                            text="Discontinued"/>
                                        }
                                        {prescription.status == "PENDING" && AuthService.userHasAuthority("UPDATE_EMR_PRESCRIPTION") &&
                                        <ActionMenuItem
                                            icon={<ConfirmIcon/>}
                                            onClick={() => this.noPickup(prescription)}
                                            text="No Pickup"/>
                                        }
                                        <ActionMenuItem
                                            icon={<FontAwesomeIcon icon={["fas", "print"]}/>}
                                            onClick={() => this.print(prescription)}
                                            text="Print"/>
                                    </ActionMenu>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="mx-2">
                            <table className="emr-table">
                                <tbody>
                                <tr>
                                    <th>ICD</th>
                                    <th>Brand Name</th>
                                    <th>Active Ingredient</th>
                                    <th>Strength</th>
                                    <th>Form</th>
                                    <th>UOM</th>
                                    <th>Frequency</th>
                                    <th>Instruction</th>
                                    <th>Duration</th>
                                    <th>Route</th>
                                    <th>Qty</th>
                                    <th>Refill</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                </tr>
                                {prescription.prescriptionItems.map((item, index) => {
                                    return (
                                        <tr key={index} style={{backgroundColor: "white"}}>
                                            <td><DiagnosisGroup diagnosisGroup={item.diagnosisGroup} showSecondaryItems/></td>
                                            <td>{item.brand}</td>
                                            <td>{item.activeIngredient}</td>
                                            <td>{item.strength}</td>
                                            <td>{item.form}</td>
                                            <td>{item.uom?.name}</td>
                                            <td>{item.frequency && item.frequency.value && item.frequency.unit ?
                                                item.frequency.value + " per " + PrescriptionTimeUnitEnum[item.frequency.unit].name : null}
                                            </td>
                                            <td>{item.instruction}</td>
                                            <td>{item.duration && item.duration.value && item.duration.unit ?
                                                item.duration.value + " " + PrescriptionTimeUnitEnum[item.duration.unit].name : null}
                                            </td>
                                            <td>{item.route?.name}</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.refill}</td>
                                            <td>{DateUtil.formatDateMMM(item.startDate)}</td>
                                            <td>{DateUtil.formatDateMMM(item.endDate)}</td>
                                        </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                })}
                {
                    this.state.printWindowVisible &&
                    <Modal visible={true} header="Prescription Printable" closeAction={() => this.onPrintClosed()}>
                        <PrintablePrescription prescription={this.state.selectedPrescription}/>
                    </Modal>
                }
            </>
        );
    }

}

class Field extends Component {

    render() {
        const {label, value, valueStyle} = this.props;
        return (
            <div style={{marginRight: '30px', whiteSpace: 'pre'}}>
                <b>{label} </b>
                <span style={valueStyle}>{value || '-'}</span>
            </div>
        );
    }

}
