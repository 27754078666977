import Enum from "../../../../../../enums/Enum";

const ActivityLimitationEnum = new Enum({
    DAILY_LIFE_ACTIVITIES: {name: "Daily Life Activities"},
    SPORTS_EXERCISE: {name: "Sports/Exercise"},
    SLEEP: {name: "Sleep"},
    WORK: {name: "Work"},
    REST: {name: "Rest"},
    OTHER: {name: "Other"}
});

export default ActivityLimitationEnum;