import React, {Component} from "react";
import moment from "moment";
import {DialogActions} from "@material-ui/core";
import {Button} from "react-bootstrap";
import TimeInput from "../../../../components/date-input/TimeInput";
import {Dropdown} from "primereact/dropdown";
import EnumBlockType from "../../../appointment/enums/EnumBlockType";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import {FormField} from "../../../../components/form";
import CalendarEventService from "../../../appointment/CalendarEventService";
import NotificationService from "../../../../services/notification-service/NotificationService";
import DateUtil from "../../../../utils/DateUtil";
import RemotingService from "../../../../services/remoting-service/RemotingService";

export class AddRotationBlockModal extends Component {

    constructor(props) {
        super(props);
        const offsetDiff = this.getOffsetDiff(props.timezone);
        this.state = {
            offsetDiff: offsetDiff,
            start: DateUtil.addMinutes(props.start, offsetDiff), // TimeInput works with local timezone only
            end: DateUtil.addMinutes(props.end, offsetDiff),
            room: props.room,
            type: null
        };
    }

    componentDidMount() {
        this.retrieveAvailableRooms();
    }

    getOffsetDiff(timezone) {
        const localOffset = moment().utcOffset();
        const timezoneOffset = moment.tz(new Date(), timezone).utcOffset();

        return timezoneOffset - localOffset;
    }

    add() {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        const {staffId, onAdd, close} = this.props;
        const start = DateUtil.addMinutes(this.state.start, -this.state.offsetDiff);
        const end = DateUtil.addMinutes(this.state.end, -this.state.offsetDiff);

        for (let event of this.props.events) {
            if (staffId === event.staff.id && moment(start).isBefore(event.endMoment) && moment(end).isAfter(event.startMoment)) {
                NotificationService.showNotification({
                    severity: 'error',
                    summary: 'Validation Error',
                    detail: 'Overlapping blocks are not allowed.'
                });
                return;
            }
        }

        const room = CalendarEventService.eventTypeRequiresRoom(this.state.type) ? this.state.room : null;

        const event = {
            startMoment: moment(start),
            endMoment: moment(end),
            start: start,
            end: end,
            staff: {id: staffId},
            staffId: staffId,
            room: room,
            roomId: room?.id,
            type: this.state.type,
            resizable: false,
            isMerged: false
        };

        close();
        onAdd(event);
    }

    retrieveAvailableRooms() {
        const params = {
            startDate: DateUtil.formatDate(this.state.start, "YYYY-MM-DD"),
            endDate: DateUtil.formatDate(this.state.end, "YYYY-MM-DD")
        };

        RemotingService.getRemoteCall('api/room/available-rooms', params, (availableRooms) => {
            this.setState({availableRooms: availableRooms.map(ar => ar.room)});
        });
    }

    render() {
        this.formFields = [];
        let saveDisabled = this.state.availableRooms?.length === 0 && CalendarEventService.eventTypeRequiresRoom(this.state.type);
        return (
            <>
                <div id="container-append" className="container" style={{width: 400}}>
                    <div className="row">
                        <div className="col-5">Start</div>
                        <div className="col-7">
                            <FormField ref={formField => this.formFields.push(formField)}
                                       required validateOn={this.state.start}>
                                <TimeInput stepMinute={5}
                                           viewDate={this.state.start}
                                           readOnlyInput={true}
                                           value={this.state.start}
                                           onChange={e => this.setState({start: e.value})}/>
                            </FormField>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5">End</div>
                        <div className="col-7">
                            <FormField ref={formField => this.formFields.push(formField)}
                                       required validateOn={this.state.start}>
                                <TimeInput stepMinute={5}
                                           viewDate={this.state.end}
                                           readOnlyInput={true}
                                           value={this.state.end}
                                           onChange={e => this.setState({end: e.value})}/>
                            </FormField>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5">Type</div>
                        <div id="type-dropdown" className="col-7">
                            <FormField ref={formField => this.formFields.push(formField)}
                                       required validateOn={this.state.type}>
                                <Dropdown optionLabel="name" optionValue="key"
                                          options={EnumBlockType.TYPES_FOR_SCHEDULE_EDIT}
                                          value={this.state.type}
                                          appendTo = {document.body}
                                          onChange={e => this.setState({type: e.value})}/>
                            </FormField>
                        </div>
                    </div>
                    {CalendarEventService.eventTypeRequiresRoom(this.state.type) &&
                        <div className="row">
                            <div className="col-5">Room</div>
                            <div className="col-7">
                                {this.props.room ?
                                    <span>{this.props.room.info}</span> :
                                    this.state.availableRooms.length === 0 ?
                                        <span style={{color:"red"}}>No rooms available.</span> :
                                        <FormField ref={formField => this.formFields.push(formField)}
                                                   required validateOn={this.state.room}>
                                            <Dropdown optionLabel="info"
                                                      options={this.state.availableRooms}
                                                      value={this.state.room}
                                                      appendTo={document.body}
                                                      onChange={e => this.setState({room: e.value})}/>
                                        </FormField>
                                }
                            </div>
                        </div>
                    }
                </div>
                <DialogActions>
                    <Button variant="success" disabled={saveDisabled} onClick={() => this.add()}>Save</Button>
                </DialogActions>
            </>
        );
    }

}