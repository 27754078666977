import React, {Component} from "react";
import './GreenSwitchWithMessage.css';
import GreenSwitch from "./GreenSwitch";

export default class GreenSwitchWithMessage extends Component {

    render() {
        return(
            <div className={"green-switch-container"}>
                <div>
                    {this.props.message}
                </div>
                <div>
                    <GreenSwitch checked={!!this.props.checked}
                                 onChange={this.props.onChange}/>
                </div>
            </div>
        );
    }

}
