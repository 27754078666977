import React from "react";
import PropTypes from "prop-types";
import {Button, ProgressBar} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./Wizard.css";
import {Modal} from "../index";

export default class Wizard extends React.Component {

    static Page = ({children}) => children;

    static propTypes = {
        initialValues: PropTypes.any,
        show: PropTypes.bool,
        header: PropTypes.string,
        title: PropTypes.string,
        fullScreen: PropTypes.bool,
        contentStyle: PropTypes.object,
        footerStyle: PropTypes.object,
        close: PropTypes.func,
        previousButtonStyle: PropTypes.object,
        buttonStyle: PropTypes.object,
        submitButtonDisabled: PropTypes.bool
    }

    constructor(props) {
        super(props);
        this.wizardRef = React.createRef();
        this.state = {
            page: 0,
            values: props.initialValues || {},
        };
    }

    next = (values) => {
        let children = Array.isArray(this.props.children)
            ? this.props.children
            : React.Children.toArray(this.props.children);
        let currentPage = children[this.state.page];
        if (currentPage.props.onNext) {
            currentPage.props.onNext(values);
            if (values.isPropagationStopped()) {
                return;
            }
        }
        this.scrollToTop();
        this.setState((state) => ({
            page: Math.min(state.page + 1, children.length - 1),
            values,
        }));
    };

    previous = () => {
        this.scrollToTop();
        let children = Array.isArray(this.props.children)
            ? this.props.children
            : React.Children.toArray(this.props.children);
        let currentPage = children[this.state.page];
        if (currentPage.props.onPrevious) currentPage.props.onPrevious();
        this.setState((state) => ({
            page: Math.max(state.page - 1, 0),
        }));
    };

    scrollToTop = () => {
        this.wizardRef.current.parentElement.scrollTo(0,0);
    }

    handleSubmit = (values) => {
        const {onSubmit} = this.props;
        const {page} = this.state;
        let children = Array.isArray(this.props.children)
            ? this.props.children
            : React.Children.toArray(this.props.children);
        children = children.filter((child) => child);
        const isLastPage = page === React.Children.count(children) - 1;
        if (isLastPage) {
            return onSubmit(values);
        } else {
            this.next(values);
        }
    };

    render() {
        if (!this.props.show) {
            return null;
        }

        const {page} = this.state;
        let children = Array.isArray(this.props.children)
            ? this.props.children
            : React.Children.toArray(this.props.children);
        children = children.filter((child) => child);

        let percentage = Math.ceil(
            (page + 1) * (100 / React.Children.toArray(children).length)
        );

        percentage = Math.min(100, percentage);

        return (
            <Modal
                visible={this.props.show}
                closeAction={this.handleClose}
                header={this.props.header || this.props.title}
                maxWidth={"lg"}
                fullScreen={this.props.fullScreen}
                contentStyle={this.props.contentStyle}
                footerStyle={this.props.footerStyle}
                additionalActions={this.renderFooterOfModal()}
                paperStyle={{overflowY: "auto"}}
                scroll={'paper'}
            >
                <div ref={this.wizardRef} class="container-fluid">
                    <div class="row">
                        <div class="col-12">
                            <ProgressBar
                                style={{marginBottom: "20px"}}
                                max={React.Children.toArray(children).length}
                                min={0}
                                now={page + 1}
                                label={
                                    "" +
                                    percentage +
                                    " %"
                                }
                            />
                        </div>
                    </div>
                    <div class="row wizard-modal-grey min-vh-100">
                        <div class="container">{this.renderWizardModalBody()}</div>
                    </div>
                </div>
            </Modal>
        );
    }

    handleClose = () => {
        this.props.close();
    };

    renderWizardModalBody = () => {
        let children = Array.isArray(this.props.children)
            ? this.props.children
            : React.Children.toArray(this.props.children);
        children = children.filter((child) => child);
        const {page} = this.state;
        const activePage = React.Children.toArray(children)[page];
        return <div class="mx-auto">{activePage}</div>;
    };

    renderFooterOfModal = () => {
        let children = Array.isArray(this.props.children)
            ? this.props.children
            : React.Children.toArray(this.props.children);
        children = children.filter((child) => child);
        const {isReadOnly} = this.props;
        const {page} = this.state;
        const isLastPage = page === React.Children.count(children) - 1;
        const{previousBtnText , nextBtnText, saveBtnText} = this.props;

        return (
            <div>
                {page > 0 && (
                    <Button
                        style={{
                            width: "110px",
                            ...(this.props.previousButtonStyle
                                ? this.props.previousButtonStyle
                                : this.props.buttonStyle),
                        }}
                        variant="primary"
                        onClick={this.previous}
                    >
                        <FontAwesomeIcon icon={["fa", "chevron-left"]}/>
                        {previousBtnText ? previousBtnText : " Previous"}
                    </Button>
                )}
                {!isLastPage && (
                    <Button
                        style={{
                            width: "110px",
                            marginLeft: "10px",
                            ...this.props.buttonStyle,
                        }}
                        variant="primary"
                        onClick={this.handleSubmit}
                    >
                        {nextBtnText ? nextBtnText : "Next"}
                        <FontAwesomeIcon icon={["fa", "chevron-right"]}/>
                    </Button>
                )}
                {isLastPage && !isReadOnly && (
                    <Button
                        style={{
                            width: "110px",
                            marginLeft: "10px",
                            ...this.props.buttonStyle,
                        }}
                        variant="success"
                        onClick={this.handleSubmit}
                        disabled={this.props.submitButtonDisabled}
                    >
                        <FontAwesomeIcon icon={["fas", "save"]}/>
                        {saveBtnText ? saveBtnText : "Save"}
                    </Button>
                )}
            </div>
        );
    };
}
