import React, {Component} from "react";
import ReactTagInput from "@pathofdev/react-tag-input";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import {DialogActions} from "@material-ui/core";
import {Button} from "react-bootstrap";
import TextInput from "../../../../components/text-input/TextInput";

export default class EditSpecializedConditionsModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            specializedConditionNames: props.specializedConditions.map(s => s.name)
        };
    }

    updateSpecializedConditions() {
        let specializedConditions = this.state.specializedConditionNames.map(element => ({name: element}));

        const staffDTO = {
            id: this.props.staffId,
            specializedConditions: specializedConditions
        };

        RemotingService.postRemoteCall('api/staff/update', staffDTO, () => {
            this.props.close();
            this.props.onSpecializedConditionsUpdated(specializedConditions);
        });
    }

    handleAddSpecialityToTags = (val) => {
        if(val && val.length){
            let specializedConditionNames = [...this.state.specializedConditionNames, val];
            this.setState({specializedConditionNames, newSpeciality: ""});
        }
    }

    handleTagsChange = (specializedConditionNames) => {
        this.setState({specializedConditionNames});
    }

    render() {
        return (
            <div className="SkillsModal">
                <div className="d-flex align-items-center mb-3">
                    <div className="font-weight-bold text-nowrap">Add Conditions</div>
                    <TextInput value={this.state.newSpeciality}
                               className="ml-3 w-100"
                               onKeyPress={event => {
                                   if(event.charCode === 13){
                                       event.preventDefault();
                                       event.stopPropagation();
                                       this.handleAddSpecialityToTags(this.state.newSpeciality)
                                   }
                               }}
                               onChange={(value) => this.setState({newSpeciality: value})}
                               maxlength={255}
                    />
                </div>
                <div className="mb-2 react-tag-input-disable-manual-entry">
                    <ReactTagInput
                        tags={this.state.specializedConditionNames}
                        placeholder=" "
                        onChange={this.handleTagsChange}
                    />
                </div>
                <DialogActions>
                    <Button variant="success" onClick={() => this.updateSpecializedConditions()}>Update</Button>
                </DialogActions>
            </div>
        )
    }

}