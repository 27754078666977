import Enum from "../../../enums/Enum";
import {ReactComponent as ElevatorIcon} from "../../../icons/ELEVATOR.svg";
import {ReactComponent as FemaleIcon} from "../../../icons/FEMALE.svg";
import {ReactComponent as LanguageIcon} from "../../../icons/LANGUAGE.svg";
import {ReactComponent as MaleIcon} from "../../../icons/MALE.svg";
import {ReactComponent as VipIcon} from "../../../icons/VIP.svg";
import {ReactComponent as WhatsappIcon} from "../../../icons/WHATSAPP.svg";
import {ReactComponent as WheelchairIcon} from "../../../icons/WHEELCHAIR.svg";

const EnumPatientSpecialStatus = new Enum({
    MALE: {name: "Male Clinician only", icon: MaleIcon},
    FEMALE: {name: "Female Clinician only", icon: FemaleIcon},
    VIP: {name: "VIP", icon: VipIcon},
    ELEVATOR: {name: "Elevator user", icon: ElevatorIcon},
    WHEELCHAIR: {name: "Wheelchair user", icon: WheelchairIcon},
    WHATSAPP: {name: "Whatsapp user", icon: WhatsappIcon},
    LANGUAGE: {name: "Language limit", icon: LanguageIcon}
});

export default EnumPatientSpecialStatus;