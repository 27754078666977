import React, {Component} from "react";
import {SelectInput, TextInput} from "../../../components";
import {Button} from "react-bootstrap";
import RemotingService from "../../../services/remoting-service/RemotingService";
import PublicIntakeForm from "./PublicIntakeForm";
import PublicInformedConsentFormDxb from "./PublicInformedConsentFormDxb";
import PublicInformedConsentFormKwt from "./PublicInformedConsentFormKwt";
import PublicCancellationPolicyDxb from "./PublicCancellationPolicyDxb";
import PublicCancellationPolicyKwt from "./PublicCancellationPolicyKwt";
import PublicReleaseOfMedicalRecordsConsentForm from "./PublicReleaseOfMedicalRecordsConsentForm";
import PublicNewPatientFormDxb from "./PublicNewPatientFormDxb";
import PublicNewPatientFormKwt from "./PublicNewPatientFormKwt";
import PublicDoubleSessionRequestForm from "./PublicDoubleSessionRequestForm";
import PublicUltrasoundDiagnosticForm from "./PublicUltrasoundDiagnosticForm";
import PublicPerinealPhysiotherapyConsentForm from "./PublicPerinealPhysiotherapyConsentForm";
import PublicIVInfusionConsentForm from "./PublicIVInfusionConsentForm";
import PublicTeleconsultationConsentForm from "./PublicTeleconsultationConsentForm";
import PublicMedicalPhotographyConsentForm from "./PublicMedicalPhotographyConsentForm";
import PublicInjectionConsentForm from "./PublicInjectionConsentForm";
import PublicShockwaveTherapyConsentForm from "./PublicShockwaveTherapyConsentForm";
import PublicCreditConsentForm from "./PublicCreditConsentForm";
import queryString from "query-string";
import FormLanguages from "../enum/FormLanguages";
import PublicCreditGiftForm from "./PublicCreditGiftForm";

export default class PatientConsent extends Component {

    constructor(props) {
        super(props);

        const values = queryString.parse(props.location.search);
        const language = values.language;
        this.state = {language: language ? FormLanguages[language] : FormLanguages.ENGLISH};
    }

    retrieveConsentForm = () => {
        RemotingService.getRemoteCall(
            `api/public/consent-form/code/${this.state.code}`,
            null,
            (formWrapper) => {
                this.setState({
                    retrievedCode: this.state.code,
                    code: "",
                    formWrapper: formWrapper,
                    patientId: formWrapper.patientId
                });
            }
        );
    };

    clearLoadedForm = () => {
        this.setState({formWrapper: null});
    };

    onFormSubmit = (form) => {
        this.clearLoadedForm();
    };

    renderForm = () => {
        const {formWrapper} = this.state;

        if (!formWrapper) {
            return null;
        }

        const commonProps = {
            consentFormCode: this.state.retrievedCode,
            form: formWrapper.form,
            language: this.state.language.name,
            close: this.clearLoadedForm,
            onFormSubmit: this.onFormSubmit,
            patientId: formWrapper.patientId
        };

        switch (formWrapper.formType) {
            case "INTAKE_FORM":
                return <PublicIntakeForm {...commonProps} />;
            case 'GENERAL_CONSENT_FORM_DXB':
                return <PublicInformedConsentFormDxb {...commonProps}/>;
            case 'GENERAL_CONSENT_FORM_KWT':
                return <PublicInformedConsentFormKwt {...commonProps}/>;
            case 'CANCELLATION_POLICY_DXB':
                return <PublicCancellationPolicyDxb {...commonProps}/>;
            case 'CANCELLATION_POLICY_KWT':
                return <PublicCancellationPolicyKwt {...commonProps}/>;
            case 'RELEASE_OF_MEDICAL_RECORDS_FORM':
                return <PublicReleaseOfMedicalRecordsConsentForm {...commonProps}/>;
            case 'NEW_PATIENT_FORM_DXB':
                return <PublicNewPatientFormDxb {...commonProps}/>;
            case 'NEW_PATIENT_FORM_KWT':
                return <PublicNewPatientFormKwt {...commonProps}/>;
            case 'DOUBLE_SESSION_REQUEST_FORM':
                return <PublicDoubleSessionRequestForm {...commonProps}/>;
            case 'ULTRASOUND_DIAGNOSTIC_FORM':
                return <PublicUltrasoundDiagnosticForm {...commonProps}/>;
            case 'IV_INFUSION_CONSENT_FORM':
                return <PublicIVInfusionConsentForm {...commonProps}/>;
            case 'PERINEAL_PHYSIOTHERAPY_CONSENT_FORM':
                return <PublicPerinealPhysiotherapyConsentForm {...commonProps}/>;
            case 'MEDICAL_PHOTOGRAPHY_CONSENT_FORM':
                return <PublicMedicalPhotographyConsentForm {...commonProps}/>;
            case 'INJECTION_CONSENT_FORM':
                return <PublicInjectionConsentForm {...commonProps}/>;
            case 'TELECONSULTATION_CONSENT_FORM':
                return <PublicTeleconsultationConsentForm {...commonProps} />
            case 'SHOCKWAVE_THERAPY_CONSENT_FORM':
                return <PublicShockwaveTherapyConsentForm {...commonProps} />
            case 'CREDIT_CONSENT_FORM':
                return <PublicCreditConsentForm {...commonProps}/>
            case 'CREDIT_GIFT_FORM':
                return <PublicCreditGiftForm {...commonProps}/>
            default:
                return <div>No component registered for {formWrapper.formType}</div>;
        }
    };

    render() {
        this.formFields = [];

        return (
            <div className="display-flex flex-column text-center">
                <div style={{fontSize: 20, fontWeight: "bold"}}>Forms</div>
                <div>&nbsp;</div>
                <div
                    className="display-flex flex-column text-center"
                    style={{
                        width: "100%",
                        borderTop: "solid 1px",
                        backgroundColor: "rgba(0, 0, 0, .05)",
                        margin: "0 auto",
                    }}
                >
                    <div style={{margin: "0 auto", maxWidth: 250}}>
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>
                        <div style={{fontSize: 14, fontWeight: "bold"}}>
                            Please enter 6 digit codes here
                        </div>
                        <div>&nbsp;</div>

                        <TextInput
                            style={{
                                textAlign: "center",
                                border: "none",
                                backgroundColor: "rgba(0, 0, 0, 0)",
                            }}
                            placeholder={"__   __   __   __   __   __"}
                            maxlength={6}
                            value={this.state.code}
                            onChange={(code) => this.setState({code})}
                        />
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>

                        <div style={{fontSize: 14, fontWeight: "bold"}}>
                            Desired Language
                        </div>
                        <div>&nbsp;</div>

                        <SelectInput
                            className="patient-consent-language-selection"
                            showClear={false}
                            searchEnabled={false}
                            value={this.state.language}
                            options={FormLanguages.toArray()}
                            displayProperty={'name'}
                            onChange={(language) => this.setState({language})}
                        />
                        <div>&nbsp;</div>
                        <div>&nbsp;</div>

                        <Button
                            variant={"success"}
                            style={{width: "120px"}}
                            disabled={!this.state.code || 6 !== this.state.code.length}
                            onClick={this.retrieveConsentForm}
                        >
                            Submit
                        </Button>
                        <div>&nbsp;</div>
                    </div>
                    {this.renderForm()}
                </div>
            </div>
        );
    }
}
