import React, {Component} from "react";
import "../VisitHistory.css"
import TypeEnum from "../../diagnosis/enums/TypeEnum";
import LevelEnum from "../../diagnosis/enums/LevelEnum";
import BillTypeEnum from "../../diagnosis/enums/BillTypeEnum";

export default class DiagnosisViewHistory extends Component {

    render() {
        if (!this.props.diagnosis ||
            !this.props.diagnosis.diagnosisGroups
            || this.props.diagnosis.diagnosisGroups.length === 0) {
            return <div className="pl-3">No information available</div>;
        }


        let diagnosisItems = this.props.diagnosis.diagnosisGroups[0].diagnosisItems;

        if (diagnosisItems == null
            || diagnosisItems.length === 0) {
            return <div className="pl-3">No information available</div>;
        }

        let tableRows = [];
        this.props.diagnosis.diagnosisGroups.forEach((diagnosisGroup, index) => {
            diagnosisGroup.diagnosisItems.forEach((diagnosisItem, index) => {
                tableRows.push(
                    <tr>
                        <td>{diagnosisItem?.code}</td>
                        <td>{diagnosisItem?.description}</td>
                        <td>{LevelEnum[diagnosisItem.level]?.name}</td>
                        <td>{TypeEnum[diagnosisItem.type]?.name}</td>
                        <td>{BillTypeEnum[diagnosisItem.billType]?.name}</td>
                    </tr>
                );

            });
        });

        return (
            <table className={"visit-history-table"}>
                <tbody>
                <tr style={{backgroundColor: "rgba(197, 224, 181, 0.4)"}}>
                    <th>ICD</th>
                    <th>Description</th>
                    <th>Level</th>
                    <th>Type</th>
                    <th>Bill Type</th>
                </tr>
                {tableRows}
                </tbody>
            </table>
        );
    }
}