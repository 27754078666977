import "./MedicalReports.css"
import React, {Component} from "react";
import PropTypes from "prop-types";
import TextareaInput from "../../../../components/textarea-input/TextareaInput";
import DateUtil from "../../../../utils/DateUtil";
import AuthService from "../../../../services/auth-service/AuthService";
import ReportStamp from "./ReportStamp";
import ReportSignature from "./ReportSignature";
import StaffUtils from "../../../staff/utils/StaffUtils";

export default class ReportFooter extends Component {

    static defaultProps = {
        doctorNotes: true,
    };

    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func,
        doctorNotes: PropTypes.bool
    }

    render() {
        const {staff} = this.props;

        return (
            <div style={{width: "100%"}}>
                <div className={"row"} style={{marginBottom: "10px", marginLeft: "0px"}}>
                    <div className={"col-12 font-weight-bold"}>
                        Notes:
                    </div>
                </div>
                <div className={"row no-margin"}>
                    <TextareaInput
                        disabled={this.props.readOnly}
                        value={this.props.value}
                        placeholder={""}
                        style={{width: "98%", minHeight: "150px"}}
                        onChange={value => {
                            this.props.onChange(value);
                        }}
                    />
                </div>
                <div className={"row"} style={{marginTop: "10px", marginLeft: "0px"}}>
                    If you have any queries, please do not hesitate to contact me at the clinic directly
                    at {this.props.clinicAdress.phone}
                </div>
                <div className={"row"} style={{marginBottom: "10px", marginTop: "20px", marginLeft: "0px"}}>
                    Yours Sincerely,
                </div>
                <div className={"row no-margin"}>
                    <ReportSignature signature={staff.signature}/>
                    <ReportStamp stamp={staff.stamp}/>
                </div>

                <div className={"row no-margin"}>
                    <div className={"col-12"} style={{paddingLeft: "0px"}}>
                        <div className={"row no-margin font-weight-bold"}>Signature & Stamp</div>
                        <div className={"row no-margin font-weight-bold"}>{StaffUtils.isDoctor(staff) ? "Dr " : null}{staff.name}</div>
                    </div>
                </div>

                <div className={"row no-margin"}>
                    <div className={"col-12"} style={{paddingLeft: "0px"}}>
                        <div className={"row no-margin"}>
                            <div className={"font-weight-bold"} style={{paddingLeft: "0px"}}>Date:&nbsp;</div>
                            <div>{DateUtil.formatDateTimeSeparatelyForReports(DateUtil.now())}</div>
                        </div>
                        <div className={"row no-margin"}>
                            <div className={"font-weight-bold"} style={{paddingLeft: "0px"}}>User:&nbsp;</div>
                            <div>{AuthService.getUser().name}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
