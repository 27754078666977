import React, {Component} from 'react';
import IntakeFormTextEnum from "../enum/IntakeFormTextEnum";
import {RadioInput} from "../../../../components";
import TimingEnum from "../../../emr/recordpage/subjective/enums/complaint/TimingEnum";

class PublicSymptomsWorseIn extends Component {
    render() {
        const {enumLabel, onChange, selectedLanguage, form} = this.props;

        return (
            <div className="row mt-3">
                <div className="form-group">
                    <label className="col-form-label">
                        {IntakeFormTextEnum.Symptoms_Worse_In[selectedLanguage]}:
                    </label>
                    <RadioInput
                        id="symptomWorseRadio"
                        value={form.symptomsAreWorseIn}
                        options={TimingEnum.toArray()}
                        valueProperty={"key"}
                        displayProperty={enumLabel}
                        onChange={(value) =>
                            onChange("symptomsAreWorseIn", value)
                        }
                    />
                </div>
            </div>
        );
    }
}

export default PublicSymptomsWorseIn;