import React from 'react';
import StringUtil from "../../../../utils/StringUtil";
import DateUtil from "../../../../utils/DateUtil";
import PersonalInformationSpecialStatus from './specialstatus/PersonalInformationSpecialStatus';
import EmergencyContact from './emergencycontact/EmergencyContact';
import IdCardStatus from "../../../insurance/IdCardStatus";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PersonalInformationUpdateModal from "./PersonalInformationUpdateModal";
import EnumGender from "../../../../enums/EnumGender";
import EnumVisaStatus from "./enums/EnumVisaStatus";
import PatientProfileImage from "./PatientProfileImage";
import PatientUtil from "../../../../utils/PatientUtil";
import PatientProfileStatus from "./PatientProfileStatus";
import dialog from "../../../../components/dialog/Dialog";
import PhoneNumberUtil from "../../../../utils/PhoneNumberUtil";
import {connect} from "react-redux";

class PersonalInformation extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            personalInfo: this.props.personalInformation
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.personalInformation) !== JSON.stringify(this.props.personalInformation)) {
            this.setState({...this.state, personalInfo: this.props.personalInformation})
        } // TODO solve it with ref from parent
    }

    showUpdateModal() {
        dialog({
            title: "Personal Information",
            component: <PersonalInformationUpdateModal nationalities={this.props.nationalities}
                                                       countries={this.props.countries}
                                                       languages={this.props.languages}
                                                       religions={this.props.religions}
                                                       maritalStatuses={this.props.maritalStatuses}
                                                       personalInformation={this.state.personalInfo}
                                                       onUpdated={this.handlePersonalInformationUpdate}/>
        });
    }

    handlePersonalInformationUpdate = () => {
        this.props.onPatientInformationUpdate();
    };

    render() {
        const personalInfo = this.state.personalInfo;
        const editable = this.props.editable;

        return (
            <>
                <div className="card" style={{minWidth:"380px", maxWidth:"915px", minHeight:"502px"}}>
                    <div className="card-header">
                        <div className="card-header-title">
                            <strong style={{verticalAlign: "middle"}}>Personal Information</strong>
                        </div>
                        <div className="card-header-actions">
                            <IdCardStatus patientId={personalInfo.id}
                                          editable={editable}/>
                            {
                                editable && <FontAwesomeIcon icon={["fas", "edit"]} onClick={() => this.showUpdateModal()}/>
                            }
                        </div>
                    </div>
                    <div className="card-body line-wrapper">
                            <span style={{fontWeight: 500}}>
                                MRN:
                                <strong style={{color: '#97cd31', fontSize: '1.25em', marginLeft: "5px"}}>
                                    {StringUtil.mrn(personalInfo.mrn)}
                                </strong>
                            </span>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3" style={{minWidth: '80px !important', textAlign: "center"}}>
                                <PatientProfileImage patientId={this.state.personalInfo.id}/>
                                <PatientProfileStatus patient={this.state.personalInfo}
                                                      formUpdateSwitch={this.props.formUpdateSwitch}/>
                            </div>
                            <div className="col-md-9 form-horizontal">
                                <div className="form-group row">
                                    <label className="control-label col-sm-4">Name:</label>
                                    <div className="col-sm-8">
                                        {PatientUtil.getPatientName(personalInfo.firstName, personalInfo.middleName, personalInfo.lastName)}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4">ID No:</label>
                                    <div className="col-sm-8">
                                        {PatientUtil.getPatientId(personalInfo)}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4">Date of Birth:</label>
                                    <div className="col-sm-8">
                                        {
                                            personalInfo.birthday ?
                                                `${DateUtil.formatDateMMM(personalInfo.birthday)} / ${DateUtil.getAge(personalInfo.birthday)}`
                                                : '-'
                                        }
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4">Gender:</label>
                                    <div className="col-sm-8">
                                        {personalInfo.gender ? EnumGender[personalInfo.gender] : '-'}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4">Nationality:</label>
                                    <div className="col-sm-8" style={{whiteSpace:"normal"}}>
                                        {personalInfo.nationality ? personalInfo.nationality.name : '-'}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4">Visa Status:</label>
                                    <div className="col-sm-8">
                                        {personalInfo.visaStatus ? EnumVisaStatus[personalInfo.visaStatus] : '-'}
                                    </div>
                                </div>
                                <br/>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4">Phone:</label>
                                    <div className="col-sm-8">
                                        {PhoneNumberUtil.formatInternational(personalInfo.mobilePhone)}
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="control-label col-sm-4">E-mail:</label>
                                    <div className="col-sm-8">
                                        {personalInfo.email}
                                    </div>
                                </div>
                                <div className="form-group row" style={{whiteSpace: "normal"}}>
                                    <label className="control-label col-sm-4">Address:</label>
                                    <div className="col-sm-8">
                                        {[personalInfo.address, personalInfo.city, personalInfo.postalCode,
                                            personalInfo.state, personalInfo.countryOfResidence?.name]
                                            .filter(i => i).join(', ')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PersonalInformationSpecialStatus specialStatusList={personalInfo.specialStatusList}
                                                      patientId={personalInfo.id}
                                                      editable={editable}/>
                    <EmergencyContact emergencyContact={personalInfo.emergencyContact}
                                      patientId={personalInfo.id}
                                      editable={editable}/>
                </div>
            </>
        );
    }

}

function mapStateToProps(state) {
    return {
        nationalities: state.base.lookupNationalities,
        countries: state.base.lookupCountries,
        languages: state.base.lookupLanguages,
        religions: state.base.lookupReligions,
        maritalStatuses: state.base.lookupMaritalStatuses
    };
}

export default connect(mapStateToProps)(PersonalInformation);
