import Enum from "../../../../../../enums/Enum";

const JointEnum = new Enum({
    Neck: {name: "Neck"},
    Shoulder: {name: "Shoulder"},
    Elbow: {name: "Elbow"},
    Wrist: {name: "Wrist"},
    Hip: {name: "Hip"},
    Knee: {name: "Knee"},
    Ankle: {name: "Ankle"},
    Metacarpophalangeal: {name: "Metacarpophalangeal"},
    Carpal: {name: "Carpal"},
    Tarsal: {name: "Tarsal"},
    Metatarsal: {name: "Metatarsal"},
    Phalangeal: {name: "Phalangeal"},
    Intervertebral: {name: "Intervertebral"},
    Sacroiliac: {name: "Sacroiliac"},
    Thumb: {name: "Thumb"},
    Temporomandibular: {name: "Temporomandibular"},
    Costochondral: {name: "Costochondral"},
});

export default JointEnum;