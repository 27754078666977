import React, {Component} from "react";
import '../Subjective.css';
import '../../EMR.css';
import './ComplaintView.css';
import ResponseEnum from "../enums/treatmentreceived/ResponseEnum";
import ConservativeTreatmentReceivedEnum from "../enums/treatmentreceived/ConservativeTreatmentReceivedEnum";
import OtherTreatmentReceivedEnum from "../enums/treatmentreceived/OtherTreatmentReceivedEnum";

export default class TreatmentReceivedView extends Component {

    render() {
        let divs = [];

        if(this.props.treatmentReceived == null
            || (this.props.treatmentReceived.conservative == null
                && this.props.treatmentReceived.other == null)
            || ((this.props.treatmentReceived.conservative != null && this.props.treatmentReceived.conservative.length == 0)
                && (this.props.treatmentReceived.other != null && this.props.treatmentReceived.other.length == 0))) {
            return (<div style={{padding: "15px 15px"}}></div>);
        }

        this.addDivs(divs, this.props.treatmentReceived.conservative, "Conservative", ConservativeTreatmentReceivedEnum);
        this.addDivs(divs, this.props.treatmentReceived.other, "Other", OtherTreatmentReceivedEnum);

        return(
          <div style={{padding: "15px 15px"}}>
              {divs}
          </div>
        );
    }

    addDivs(divs, list, type, enumObj) {
        if (list == null || list.length == 0) {
           return;
        }

            divs.push(
                <div key={"treatmentReceivedViewL2" + type} style={{fontSize: "11px", marginBottom: "10px"}}>
                    <div className={"display-flex"} style={{margin: "3px"}}>
                        <div className={"col-sm-4 no-padding dim14-form-label"}>{type} :</div>
                        <div className={"col-sm-8 no-padding overflow-wrap-anywhere white-space-pre-line"}>
                            {list.map((treatment) =>
                            [
                                enumObj[treatment.treatmentReceived] ? enumObj[treatment.treatmentReceived].name : treatment.treatmentReceived,
                                ResponseEnum[treatment.response] ? ResponseEnum[treatment.response].name : treatment.response,
                                treatment.detail,
                            ].filter(item => item != null && item.length != 0).join(", "))
                                .reduce((total, value) => total + ";\n" + value)}
                        </div>
                    </div>
                </div>
            );

    }
}