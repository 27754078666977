import React, {Component} from "react";
import '../../EMR.css';
import TextInput from "../../../../../components/text-input/TextInput";
import Select from "react-select";
import ObservationFindingEnum from "../enums/observation/ObservationFindingEnum";
import SeverityEnum from "../../subjective/enums/treatmentreceived/SeverityEnum";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";
import PostureEnum from "../enums/posture/PostureEnum";
import NeurologicGaitEnum from "../enums/neurologicGait/NeurologicGaitEnum";
import BorderedCheckboxInput from "../../../../../components/checkbox-input/BorderedCheckboxInput";
import CheckboxInput from "../../../../../components/checkbox-input/CheckboxInput";
import GreenSwitchWithMessage from "../../../../../components/switch/GreenSwitchWithMessage";
import RemoveButton from "../../common/table/RemoveButton";
import AddButton from "../../common/table/AddButton";
import RedAsterisk from "../../../../../components/asterisk/RedAsterisk";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import StringUtil from "../../../../../utils/StringUtil";


export default class GaitModal extends Component {
    state = {
        finding: null,
        location: null,
        severity: null,
        details: null,
        activePosture: {},
        neurologicGait: {
            details: null,
            neurologicGait: null
        },
        gaitAnalysis: [
            {
                gaitLocation: "ANKLE",
                gaitType: "HEEL_STRIKE",
                details: ""
            },
            {
                gaitLocation: "ANKLE",
                gaitType: "FOOT_FLAT",
                details: ""
            },
            {
                gaitLocation: "ANKLE",
                gaitType: "MID_STANCE",
                details: ""
            },
            {
                gaitLocation: "ANKLE",
                gaitType: "HEEL_OFF",
                details: ""
            },
            {
                gaitLocation: "ANKLE",
                gaitType: "TOE_OFF",
                details: ""
            },
            {
                gaitLocation: "ANKLE",
                gaitType: "EARLY_SWING",
                details: ""
            },
            {
                gaitLocation: "ANKLE",
                gaitType: "MID_SWING",
                details: ""
            },
            {
                gaitLocation: "ANKLE",
                gaitType: "LATE_SWING",
                details: ""
            },
            {
                gaitLocation: "KNEE",
                gaitType: "HEEL_STRIKE",
                details: ""
            },
            {
                gaitLocation: "KNEE",
                gaitType: "FOOT_FLAT",
                details: ""
            },
            {
                gaitLocation: "KNEE",
                gaitType: "MID_STANCE",
                details: ""
            },
            {
                gaitLocation: "KNEE",
                gaitType: "HEEL_OFF",
                details: ""
            },
            {
                gaitLocation: "KNEE",
                gaitType: "TOE_OFF",
                details: ""
            },
            {
                gaitLocation: "KNEE",
                gaitType: "EARLY_SWING",
                details: ""
            },
            {
                gaitLocation: "KNEE",
                gaitType: "MID_SWING",
                details: ""
            },
            {
                gaitLocation: "KNEE",
                gaitType: "LATE_SWING",
                details: ""
            },
            {
                gaitLocation: "PELVIS_AND_HIPS",
                gaitType: "HEEL_STRIKE",
                details: ""
            },
            {
                gaitLocation: "PELVIS_AND_HIPS",
                gaitType: "FOOT_FLAT",
                details: ""
            },
            {
                gaitLocation: "PELVIS_AND_HIPS",
                gaitType: "MID_STANCE",
                details: ""
            },
            {
                gaitLocation: "PELVIS_AND_HIPS",
                gaitType: "HEEL_OFF",
                details: ""
            },
            {
                gaitLocation: "PELVIS_AND_HIPS",
                gaitType: "TOE_OFF",
                details: ""
            },
            {
                gaitLocation: "PELVIS_AND_HIPS",
                gaitType: "EARLY_SWING",
                details: ""
            },
            {
                gaitLocation: "PELVIS_AND_HIPS",
                gaitType: "MID_SWING",
                details: ""
            },
            {
                gaitLocation: "PELVIS_AND_HIPS",
                gaitType: "LATE_SWING",
                details: ""
            },
            {
                gaitLocation: "ARMS",
                gaitType: "HEEL_STRIKE",
                details: ""
            },
            {
                gaitLocation: "ARMS",
                gaitType: "FOOT_FLAT",
                details: ""
            },
            {
                gaitLocation: "ARMS",
                gaitType: "MID_STANCE",
                details: ""
            },
            {
                gaitLocation: "ARMS",
                gaitType: "HEEL_OFF",
                details: ""
            },
            {
                gaitLocation: "ARMS",
                gaitType: "TOE_OFF",
                details: ""
            },
            {
                gaitLocation: "ARMS",
                gaitType: "EARLY_SWING",
                details: ""
            },
            {
                gaitLocation: "ARMS",
                gaitType: "MID_SWING",
                details: ""
            },
            {
                gaitLocation: "ARMS",
                gaitType: "LATE_SWING",
                details: ""
            },
            {
                gaitLocation: "UPPER_BACK_AND_SHOULDERS",
                gaitType: "HEEL_STRIKE",
                details: ""
            },
            {
                gaitLocation: "UPPER_BACK_AND_SHOULDERS",
                gaitType: "FOOT_FLAT",
                details: ""
            },
            {
                gaitLocation: "UPPER_BACK_AND_SHOULDERS",
                gaitType: "MID_STANCE",
                details: ""
            },
            {
                gaitLocation: "UPPER_BACK_AND_SHOULDERS",
                gaitType: "HEEL_OFF",
                details: ""
            },
            {
                gaitLocation: "UPPER_BACK_AND_SHOULDERS",
                gaitType: "TOE_OFF",
                details: ""
            },
            {
                gaitLocation: "UPPER_BACK_AND_SHOULDERS",
                gaitType: "EARLY_SWING",
                details: ""
            },
            {
                gaitLocation: "UPPER_BACK_AND_SHOULDERS",
                gaitType: "MID_SWING",
                details: ""
            },
            {
                gaitLocation: "UPPER_BACK_AND_SHOULDERS",
                gaitType: "LATE_SWING",
                details: ""
            },
        ]

    }

    constructor(props) {

        super(props);
        if (this.props.assessment.gaitAnalysis == null) {
            this.props.assessment.gaitAnalysis = {};
            this.props.assessment.gaitAnalysis.items = this.state.gaitAnalysis;
        } else {
            this.props.assessment.gaitAnalysis.items.forEach((PropsItem, PropsIndex) => {
                this.state.gaitAnalysis.map((item, index) => {
                    if (item.gaitLocation == PropsItem.gaitLocation &&
                        item.gaitType == PropsItem.gaitType) {
                        this.state.gaitAnalysis[index].details = this.props.assessment.gaitAnalysis.items[PropsIndex].details
                        return index;
                    }
                })
            });
        }

        this.customStyles = {
            option: (provided, state) => ({
                ...provided,
                textAlign:'left',
                whiteSpace:'nowrap',

            }),
            menu:(provided, state)=>({
                ...provided,
                position:'absolute',
                width:'100wv',

            }),
            menuList: (provided, state) => ({
                ...provided,


            }),

        }


    }

    render() {
        let formList = [];
        this.props.formFields.set('neurologicgait', formList);
        this.props.formFields.set('posture', formList)
        let observationDivs = this.getObservationDivs();

        let isObservationNone = this.props.assessment.observation?.none || false;
        let isPostureNone = this.props.assessment.posture?.none || false;
        let isGaitAnalysisNone = this.props.assessment.gaitAnalysis?.none || false;

        return (
            <div className={"emr-modal content-row emr-background px-3 py-3"} style={{width: "100%"}}>
                <div className="w-100 m-3">
                    <div>
                        <div className={"row p-0 m-0"} style={{display: "flex"}}>
                            <h5 className="content-label font-weight-bold">Observation <RedAsterisk renderForAllTypes /></h5>
                            <GreenSwitchWithMessage
                                message={(this.props.assessment.observation && this.props.assessment.observation.none) && "Normal"}
                                checked={(this.props.assessment.observation != null && this.props.assessment.observation.items != null && this.props.assessment.observation.items.length != 0)
                                || (this.props.assessment.observation && this.props.assessment.observation.none == true)}
                                onChange={() => {
                                    this.props.assessment.observation.items = [];
                                    this.forceUpdate();
                                }}/>
                        </div>
                        <div className={"row m-0"}>
                            <div style={{display: "flex", marginLeft: "5px"}}>
                                <CheckboxInput value={isObservationNone}
                                               onChange={(value) => {
                                                   if (this.props.assessment.observation == null) {
                                                       this.props.assessment.observation = {};
                                                   }
                                                   if (value) {
                                                       this.props.assessment.observation.items = [];
                                                   }
                                                   this.props.assessment.observation.none = value;
                                                   this.forceUpdate();
                                               }}/>
                                <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Normal</div>
                            </div>
                        </div>
                        <div className={isObservationNone ? "not-allowed" : ""}>
                            <div className={isObservationNone ? "disable-pointer" : ""}>
                                <div className={"row ml-0 gaitRowLabelDiv"}>
                                    <div className={"col-3 gaitRowLabel"}>
                                        Finding
                                    </div>
                                    <div className={"col-3 gaitRowLabel"} style={{marginLeft: "-15px"}}>
                                        Location
                                    </div>
                                    <div className={"col-3 gaitRowLabel"} style={{marginLeft: "-15px"}}>
                                        Severity
                                    </div>
                                    <div className={"col-3 gaitRowLabel"} style={{marginLeft: "-15px"}}>
                                        Details
                                    </div>
                                </div>
                                <div className={"row ml-0 gaitRowLabelDiv"}>
                                    <div className={"col-3"}>
                                        <Select
                                            className={"emr-white-select-container gaitRowTextBox"}
                                            classNamePrefix={"emr-white-select"}
                                            styles={this.customStyles}
                                            placeholder={"Findings"}
                                            value={this.state.finding != null ?
                                                {
                                                    value: ObservationFindingEnum[this.state.finding].key,
                                                    label: ObservationFindingEnum[this.state.finding].name
                                                } : null}
                                            onChange={(event) => {
                                                let r = event.value;
                                                this.setState({finding: r});
                                            }}
                                            options={ObservationFindingEnum.toArray().map(v => {
                                                    return {value: v.key, label: v.name}
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className={"col-3"} style={{marginLeft: "-15px"}}>
                                        <TextInput value={this.state.location || ''}
                                                   placeholder={"Location"}
                                                   onChange={value => this.setState({location: value})}
                                                   style={{fontSize: "14px", width: "90%"}}
                                        />
                                    </div>
                                    <div className={"col-3"} style={{marginLeft: "-15px"}}>
                                        <Select
                                            className={"emr-white-select-container gaitRowTextBox"}
                                            classNamePrefix={"emr-white-select"}
                                            placeholder={"Findings"}
                                            style={{marginLeft: "10px"}}
                                            styles={this.customStyles}
                                            value={this.state.severity != null ?
                                                {
                                                    value: SeverityEnum[this.state.severity].key,
                                                    label: SeverityEnum[this.state.severity].name
                                                } : null}
                                            onChange={(event) => {
                                                let r = event.value;
                                                this.setState({severity: r});
                                            }}
                                            options={SeverityEnum.toArray().map(v => {
                                                    return {value: v.key, label: v.name}
                                                }
                                            )}
                                        />
                                    </div>
                                    <div className={"col-3"} style={{marginLeft: "-4px", marginTop: "-5px"}}>
                                        <div className={"row"}>
                                            <div className={"col-10"}>
                                                <TextInput value={this.state.details || ''}
                                                           placeholder={"Details"}
                                                           onChange={value => this.setState({details: value})}
                                                           style={{fontSize: "14px", width: "100%"}}
                                                />
                                            </div>
                                            <div className={"col-1"} style={{marginLeft: "12px"}}>
                                                <Button className={"emr-plus-button"}
                                                        variant="success"
                                                        disabled={!(this.state.finding || this.state.location || this.state.severity || this.state.details)}
                                                        style={{fontSize: "12px"}}
                                                        onClick={this.addObservation}
                                                >
                                                    <FontAwesomeIcon icon={["fas", "plus"]}/>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"row m-0 w-100 mt-2"}>
                                    <div className={"div-list-wrapper w-100"} style={{borderRadius: 8}}>
                                        {observationDivs}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row d-flex p-0 m-0 mt-5"}>
                            <h5 className="content-label font-weight-bold">Posture <RedAsterisk appointmentType={this.props.appointmentType} firstVisit newComplaint/></h5>
                            <GreenSwitchWithMessage
                                message={(this.props.assessment.posture && this.props.assessment.posture.none) && "Normal"}
                                checked={(this.props.assessment.posture != null && this.props.assessment.posture.items != null && this.props.assessment.posture.items.length != 0) ||
                                (this.props.assessment.posture && this.props.assessment.posture.none == true)}
                                onChange={() => {
                                    this.props.assessment.posture.items = [];
                                    this.forceUpdate();
                                }}/>
                        </div>
                        <div className={"row m-0"}>
                            <div style={{display: "flex", marginLeft: "5px"}}>
                                <CheckboxInput value={isPostureNone}
                                               onChange={(value) => {
                                                   if (this.props.assessment.posture == null) {
                                                       this.props.assessment.posture = {};
                                                   }
                                                   if (value) {
                                                       this.props.assessment.posture.items = [];
                                                   }
                                                   this.props.assessment.posture.none = value;
                                                   this.forceUpdate();
                                               }}/>
                                <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Normal</div>
                            </div>
                        </div>
                        <div className={isPostureNone ? "not-allowed" : ""}>
                            <div className={isPostureNone ? "disable-pointer" : ""}>
                                <div className={"row content-card"} style={{marginTop: "20px"}}>
                                    <table className={"emr-table"}>
                                        <tbody>
                                        <tr>
                                            <th>Standing</th>
                                            <th>Sitting</th>
                                            <th>Supine</th>
                                            <th>Prone</th>
                                            <th style={{color: "red"}}>ⓧ</th>
                                        </tr>
                                        {(this.props.assessment.posture == null || this.props.assessment.posture.items == null)
                                            ? null : this.props.assessment.posture.items.map((item, index) => {
                                                    return (
                                                        <tr key={"specialTestsModal" + index}
                                                            style={{backgroundColor: "white"}}>
                                                            <td> {item.standing == null && item.standingComment == null ? "" : PostureEnum[item.standing] != null? PostureEnum[item.standing].name : item.standingComment}</td>
                                                            <td> {item.sitting == null && item.sittingComment == null ? "" : PostureEnum[item.sitting] != null? PostureEnum[item.sitting].name : item.sittingComment}</td>
                                                            <td> {item.spine == null && item.spineComment == null ? "" : PostureEnum[item.spine] != null? PostureEnum[item.spine].name : item.spineComment}</td>
                                                            <td> {item.prone == null && item.proneComment == null ? "" : PostureEnum[item.prone] != null? PostureEnum[item.prone].name : item.proneComment}</td>                                                            <td>
                                                                <RemoveButton
                                                                    onClick={() => {
                                                                        this.props.assessment.posture.items.splice(index, 1);
                                                                        this.forceUpdate();
                                                                    }}/>
                                                            </td>
                                                        </tr>);
                                                }
                                            )
                                        }
                                        <tr style={{backgroundColor: "white"}}>
                                            <td style={{minWidth: "80px"}}>
                                                <AsyncSelect
                                                    className={"emr-in-table-select-container"}
                                                    classNamePrefix={"emr-in-table-select"}
                                                    styles={this.customStyles}
                                                    placeholder={"Type"}
                                                    noOptionsMessage={this.noOptionsMessage.bind(this)}
                                                    loadOptions={this.getPostureOptions}
                                                    menuPlacement={"top"}
                                                    value={this.state.activePosture.standing != null || this.state.activePosture.standingComment != null?
                                                        (PostureEnum[this.state.activePosture.standing] != null ?
                                                            {
                                                                value: PostureEnum[this.state.activePosture.standing].key,
                                                                label: PostureEnum[this.state.activePosture.standing].name
                                                            } :
                                                            {
                                                                value: this.state.activePosture.standingComment,
                                                                label: this.state.activePosture.standingComment
                                                            }) : null}
                                                    onChange={(event) => {
                                                        if (PostureEnum[event.value] != null) {
                                                            this.state.activePosture.standing = event.value;
                                                            this.state.activePosture.standingComment= null;
                                                        } else {
                                                            this.state.activePosture.standingComment = event.value;
                                                            this.state.activePosture.standing= null;
                                                        }
                                                        this.forceUpdate();
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <AsyncSelect
                                                    className={"emr-in-table-select-container"}
                                                    classNamePrefix={"emr-in-table-select"}
                                                    styles={this.customStyles}
                                                    placeholder={"Type"}
                                                    noOptionsMessage={this.noOptionsMessage.bind(this)}
                                                    loadOptions={this.getPostureOptions}
                                                    menuPlacement={"top"}
                                                    value={this.state.activePosture.sitting != null || this.state.activePosture.sittingComment != null?
                                                        (PostureEnum[this.state.activePosture.sitting] != null ?
                                                            {
                                                                value: PostureEnum[this.state.activePosture.sitting].key,
                                                                label: PostureEnum[this.state.activePosture.sitting].name
                                                            } :
                                                            {
                                                                value: this.state.activePosture.sittingComment,
                                                                label: this.state.activePosture.sittingComment
                                                            }) : null}
                                                    onChange={(event) => {
                                                        if (PostureEnum[event.value] != null) {
                                                            this.state.activePosture.sitting = event.value;
                                                            this.state.activePosture.sittingComment= null;
                                                        } else {
                                                            this.state.activePosture.sittingComment = event.value;
                                                            this.state.activePosture.sitting= null;
                                                        }
                                                        this.forceUpdate();
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <AsyncSelect
                                                    className={"emr-in-table-select-container"}
                                                    classNamePrefix={"emr-in-table-select"}
                                                    styles={this.customStyles}
                                                    placeholder={"Type"}
                                                    noOptionsMessage={this.noOptionsMessage.bind(this)}
                                                    loadOptions={this.getPostureOptions}
                                                    menuPlacement={"top"}
                                                    value={this.state.activePosture.spine != null || this.state.activePosture.spineComment != null?
                                                        (PostureEnum[this.state.activePosture.spine] != null ?
                                                            {
                                                                value: PostureEnum[this.state.activePosture.spine].key,
                                                                label: PostureEnum[this.state.activePosture.spine].name
                                                            } :
                                                            {
                                                                value: this.state.activePosture.spineComment,
                                                                label: this.state.activePosture.spineComment
                                                            }) : null}
                                                    onChange={(event) => {
                                                        if (PostureEnum[event.value] != null) {
                                                            this.state.activePosture.spine = event.value;
                                                            this.state.activePosture.spineComment= null;
                                                        } else {
                                                            this.state.activePosture.spineComment = event.value;
                                                            this.state.activePosture.spine= null;
                                                        }
                                                        this.forceUpdate();
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <AsyncSelect
                                                    className={"emr-in-table-select-container"}
                                                    classNamePrefix={"emr-in-table-select"}
                                                    styles={this.customStyles}
                                                    placeholder={"Type"}
                                                    noOptionsMessage={this.noOptionsMessage.bind(this)}
                                                    loadOptions={this.getPostureOptions}
                                                    menuPlacement={"top"}
                                                    value={this.state.activePosture.prone != null || this.state.activePosture.proneComment != null?
                                                        (PostureEnum[this.state.activePosture.prone] != null ?
                                                            {
                                                                value: PostureEnum[this.state.activePosture.prone].key,
                                                                label: PostureEnum[this.state.activePosture.prone].name
                                                            } :
                                                            {
                                                                value: this.state.activePosture.proneComment,
                                                                label: this.state.activePosture.proneComment
                                                            }) : null}
                                                    onChange={(event) => {
                                                        if (PostureEnum[event.value] != null) {
                                                            this.state.activePosture.prone = event.value;
                                                            this.state.activePosture.proneComment= null;
                                                        } else {
                                                            this.state.activePosture.proneComment = event.value;
                                                            this.state.activePosture.prone= null;
                                                        }
                                                        this.forceUpdate();
                                                    }}
                                                />
                                            </td>
                                            <td>
                                                <AddButton
                                                    disabled={
                                                        StringUtil.isNullOrEmpty(this.state.activePosture.standing) &&
                                                        StringUtil.isNullOrEmpty(this.state.activePosture.standingComment) &&
                                                        StringUtil.isNullOrEmpty(this.state.activePosture.sitting) &&
                                                        StringUtil.isNullOrEmpty(this.state.activePosture.sittingComment) &&
                                                        StringUtil.isNullOrEmpty(this.state.activePosture.spine) &&
                                                        StringUtil.isNullOrEmpty(this.state.activePosture.spineComment) &&
                                                        StringUtil.isNullOrEmpty(this.state.activePosture.prone) &&
                                                        StringUtil.isNullOrEmpty(this.state.activePosture.proneComment)
                                                    }
                                                    onClick={() => {
                                                        let copy = Object.assign({}, this.state.activePosture);
                                                        this.props.assessment.posture.items.push(copy);
                                                        for (var prop in this.state.activePosture) {
                                                            if (this.state.activePosture.hasOwnProperty(prop)) {
                                                                delete this.state.activePosture[prop];
                                                            }
                                                        }
                                                        this.forceUpdate();
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className={"row d-flex p-0 m-0 mt-5"}>
                            <h5 className="content-label font-weight-bold">Gait Analysis</h5>
                            <GreenSwitchWithMessage
                                message={(this.props.assessment.gaitAnalysis && this.props.assessment.gaitAnalysis.none) && "Normal"}
                                checked={!this.checkGaitAnalysisEmpty()}
                                onChange={() => {
                                    this.makeGaitAnalysisEmpty();
                                    this.forceUpdate();
                                }}/>
                        </div>
                        <div className={"row m-0"}>
                            <div style={{display: "flex", marginLeft: "5px"}}>
                                <CheckboxInput value={isGaitAnalysisNone}
                                               onChange={(value) => {
                                                   if (this.props.assessment.gaitAnalysis == null) {
                                                       this.props.assessment.gaitAnalysis = {};
                                                   }
                                                   if (value) {
                                                       this.makeGaitAnalysisEmpty();
                                                   }
                                                   this.props.assessment.gaitAnalysis.none = value;
                                                   this.forceUpdate();
                                               }}/>
                                <div style={{marginTop: "-2px", marginLeft: "5px", marginRight: "5px"}}>Normal</div>
                            </div>
                        </div>
                        <div className={isGaitAnalysisNone ? "not-allowed" : ""}>
                            <div className={isGaitAnalysisNone ? "disable-pointer" : ""}>
                                <div className={"row content-card"} style={{marginTop: "20px", marginBottom: "15px"}}>
                                    <table className={"emr-table"}>
                                        <tbody>
                                        <tr>
                                            <th></th>
                                            <th>Heel Strike</th>
                                            <th>Foot Flat</th>
                                            <th>Mid Stance</th>
                                            <th>Heel Off</th>
                                            <th>Toe Off</th>
                                            <th>Early Swing</th>
                                            <th>Mid Swing</th>
                                            <th>Late Swing</th>
                                            <th style={{color: "red"}}>ⓧ</th>
                                        </tr>
                                        <tr>
                                            <td className={"gaitAnalysisRowHeader"}>
                                                Upper Back & Shoulders
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[32].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[32].details = value;
                                                            this.props.assessment.gaitAnalysis.items = this.state.gaitAnalysis;
                                                            return state;
                                                        });

                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[33].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[33].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[34].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[34].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[35].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[35].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[36].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[36].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[37].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[37].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[38].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[38].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[39].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[39].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <RemoveButton
                                                    onClick={() => {
                                                        this.state.gaitAnalysis[32].details = null;
                                                        this.state.gaitAnalysis[33].details = null;
                                                        this.state.gaitAnalysis[34].details = null;
                                                        this.state.gaitAnalysis[35].details = null;
                                                        this.state.gaitAnalysis[36].details = null;
                                                        this.state.gaitAnalysis[37].details = null;
                                                        this.state.gaitAnalysis[38].details = null;
                                                        this.state.gaitAnalysis[39].details = null;
                                                        this.props.assessment.gaitAnalysis.items = this.state.gaitAnalysis;
                                                        this.forceUpdate();
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"gaitAnalysisRowHeader"}> Arms</td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[24].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[24].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[25].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[25].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[26].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[26].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[27].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[27].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[28].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[28].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[29].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[29].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[30].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[30].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[31].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[31].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <RemoveButton
                                                    onClick={() => {
                                                        this.state.gaitAnalysis[24].details = null;
                                                        this.state.gaitAnalysis[25].details = null;
                                                        this.state.gaitAnalysis[26].details = null;
                                                        this.state.gaitAnalysis[27].details = null;
                                                        this.state.gaitAnalysis[28].details = null;
                                                        this.state.gaitAnalysis[29].details = null;
                                                        this.state.gaitAnalysis[30].details = null;
                                                        this.state.gaitAnalysis[31].details = null;
                                                        this.props.assessment.gaitAnalysis.items = this.state.gaitAnalysis;

                                                        this.forceUpdate();
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"gaitAnalysisRowHeader"}> Pelvis & Hips</td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[16].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[16].details = value;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[17].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[17].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;

                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[18].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[18].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;

                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[19].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[19].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;

                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[20].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[20].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;

                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[21].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[21].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;

                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[22].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[22].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;

                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[23].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[23].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;

                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <RemoveButton
                                                    onClick={() => {
                                                        this.state.gaitAnalysis[16].details = null;
                                                        this.state.gaitAnalysis[17].details = null;
                                                        this.state.gaitAnalysis[18].details = null;
                                                        this.state.gaitAnalysis[19].details = null;
                                                        this.state.gaitAnalysis[20].details = null;
                                                        this.state.gaitAnalysis[21].details = null;
                                                        this.state.gaitAnalysis[22].details = null;
                                                        this.state.gaitAnalysis[23].details = null;
                                                        this.props.assessment.gaitAnalysis.items = this.state.gaitAnalysis;
                                                        this.forceUpdate();
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"gaitAnalysisRowHeader"}>Knee</td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[8].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[8].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[9].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[9].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[10].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[10].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[11].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[11].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[12].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[12].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[13].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[13].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[14].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[14].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[15].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[15].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <RemoveButton
                                                    onClick={() => {
                                                        this.state.gaitAnalysis[8].details = null;
                                                        this.state.gaitAnalysis[9].details = null;
                                                        this.state.gaitAnalysis[10].details = null;
                                                        this.state.gaitAnalysis[11].details = null;
                                                        this.state.gaitAnalysis[12].details = null;
                                                        this.state.gaitAnalysis[13].details = null;
                                                        this.state.gaitAnalysis[14].details = null;
                                                        this.state.gaitAnalysis[15].details = null;
                                                        this.props.assessment.gaitAnalysis.items = this.state.gaitAnalysis;
                                                        this.forceUpdate();
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className={"gaitAnalysisRowHeader"}> Ankle</td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[0].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[0].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[1].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[1].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[2].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[2].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[3].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[3].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[4].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[4].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[5].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[5].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[6].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[6].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <TextInput
                                                    value={this.state.gaitAnalysis[7].details || ''}
                                                    onChange={(value) => {
                                                        this.setState(state => {
                                                            state.gaitAnalysis[7].details = value;
                                                            this.props.assessment.gaitAnalysis.items = state.gaitAnalysis;
                                                            return state;
                                                        });
                                                    }}
                                                    style={{fontSize: "14px", width: "90%"}}
                                                />
                                            </td>
                                            <td>
                                                <RemoveButton
                                                    onClick={() => {
                                                        this.state.gaitAnalysis[0].details = null;
                                                        this.state.gaitAnalysis[1].details = null;
                                                        this.state.gaitAnalysis[2].details = null;
                                                        this.state.gaitAnalysis[3].details = null;
                                                        this.state.gaitAnalysis[4].details = null;
                                                        this.state.gaitAnalysis[5].details = null;
                                                        this.state.gaitAnalysis[6].details = null;
                                                        this.state.gaitAnalysis[7].details = null;
                                                        this.props.assessment.gaitAnalysis.items = this.state.gaitAnalysis;
                                                        this.forceUpdate();
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div style={{width: "100%", marginTop: "30px"}}>
                            <BorderedCheckboxInput
                                title={"Neurologic Gait"}
                                values={NeurologicGaitEnum.toArray()}
                                obj={this.props.assessment.neurologicGait}
                                field={"details"}
                                normalCheckbox = {false}
                                normalCheckboxValue = {false}
                                selected={this.props.assessment.neurologicGait == null ? '' :
                                    (this.props.assessment.neurologicGait.items || '')}
                                switched={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    noOptionsMessage = () => {
            return <div>No options</div>;
    }

    getPostureOptions = (inputValue,callback) => {
        let options = [];
        options = PostureEnum.toArray().map(item => {
                return {value: item.key, label: item.name}
        })
        options.unshift({value: inputValue, label: inputValue});
        callback(options);
    }

    getObservationDivs = () => {
        if (this.props.assessment.observation == null || this.props.assessment.observation.items == null
            || this.props.assessment.observation.items.length == 0) {
            return;
        }
        let divs = [];
        this.props.assessment.observation.items.forEach((item, index) => divs.push(
            <div className={"div-list-item"} key={"observationItem" + index}>
                <div>
                    {
                        [
                            item.finding != null ? ObservationFindingEnum[item.finding].name : null,
                            item.location != null ? item.location : null,
                            item.severity != null ? SeverityEnum[item.severity].name : null,
                            item.details != null ? item.details : null
                        ].filter(Boolean).join(", ")
                    }
                </div>
                <Button className={"emr-transparent-delete-button"}
                        variant="warning"
                        style={{fontSize: "10px", marginLeft: "2px"}}
                        onClick={() => {
                            this.props.assessment.observation.items.splice(index, 1);
                            this.forceUpdate();
                        }}>
                    <FontAwesomeIcon icon={["fas", "times"]}/>
                </Button>
            </div>));
        return divs;
    }
    addObservation = () => {
        this.createObservationIfNecessary();
        this.props.assessment.observation.items.push({
            finding: this.state.finding,
            location: this.state.location,
            severity: this.state.severity,
            details: this.state.details
        });
        this.setState({
            finding: null,
            location: null,
            severity: null,
            details: null
        });
    }

    createObservationIfNecessary() {
        if (this.props.assessment.observation == null) {
            this.props.assessment.observation = {};
            this.props.assessment.observation.items = [];
        }
    }

    checkGaitAnalysisEmpty = () => {
        let result = true;
        if (this.props.assessment.gaitAnalysis != null && this.props.assessment.gaitAnalysis.items != null && this.props.assessment.gaitAnalysis.items.length != 0) {
            this.props.assessment.gaitAnalysis.items.forEach(item => {
                if (item.details) {
                    result = false;
                }
            });
        }
        if (this.props.assessment.gaitAnalysis && this.props.assessment.gaitAnalysis.none)
            result = false;
        return result;
    }
    makeGaitAnalysisEmpty = () => {
        if (this.props.assessment.gaitAnalysis != null) {
            if (this.props.assessment.gaitAnalysis.items != null) {
                if (this.props.assessment.gaitAnalysis.items.length != 0) {
                    this.props.assessment.gaitAnalysis.items.map((item, index) => {
                        if (item.details != "") {
                            this.props.assessment.gaitAnalysis.items[index].details = "";
                            this.state.gaitAnalysis[index].details = "";

                        }
                    });
                }
            }
        }
    }
}