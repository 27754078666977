import React, {Component} from "react";
import Modal from "../../../components/modal/Modal";
import FormField from "../../../components/form/FormField";
import EnumCancellationReason from "../enums/EnumCancellationReason";
import Tooltip from "../../../components/tooltip/Tooltip";
import ValidationUtil from "../../../components/form/validator/ValidationUtil";
import RemotingService from "../../../services/remoting-service/RemotingService";
import "./CancelAppointment.css";

export default class CancelAppointment extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cancellationReason: null
        }
    }

    handleCancelConfirm() {
        if (!ValidationUtil.isValid(this.formFields)) {
            ValidationUtil.showNotification();
            return;
        }

        RemotingService.postRemoteCall(`api/appointment/${this.props.appointmentId}/cancel`, { cancellationReason: this.state.cancellationReason }, () => {
            this.props.close();
            this.props.onAppointmentCancelled(this.props.appointmentId);
        });
    }

    render() {
        this.formFields = [];
        return <Modal visible={true}
                      header="Cancel Appointment"
                      submitContent="Confirm"
                      submitAction={() => this.handleCancelConfirm()}
                      closeAction={() => this.props.close()}>
            <FormField ref={formField => this.formFields.push(formField)} required validateOn={this.state.cancellationReason}>
                <div style={{display:"flex", flexDirection: "column"}}>
                    <label style={{ margin: '0 0 15px 30px' }}>Please select cancellation reason:</label>
                        <div className="special-status-icon-wrapper" style={{ margin: "0 20px" }}>
                            {this.cancelAppointmentModalBodyIcons()}
                        </div>
                </div>
            </FormField>
        </Modal>;
    }

    cancelAppointmentModalBodyIcons() {
        const icons = [];
        for (const cr of EnumCancellationReason.keys()) {
            icons.push(
                <Tooltip key={cr} title={EnumCancellationReason[cr]}>
                    <img
                        className={`cancellation-reason-icon selectable ${this.state.cancellationReason === cr ? 'selected' : ''}`}
                        src={process.env.PUBLIC_URL + `/assets/cancel_reasons/${cr}.png`}
                        onClick={() => this.setState({ cancellationReason: cr })} alt=""/>
                </Tooltip>
            );
        }
        return (icons);
    }
}