import Enum from "../../../../../../enums/Enum";

const PulseLocationEnum = new Enum({

        BRACHIAL: {name: "Brachial"},
        RADIAL: {name: "Radial"},
        CAROTID: {name: "Carotid"},
        FEMORAL: {name: "Femoral"},
        POPLITEAL: {name: "Popliteal"},
        POSTERIOR_TIBIAL: {name: "Posterior Tibial"},
        DORSALIS_PEDIS: {name: "Dorsalis Pedis"}

});

export default PulseLocationEnum;