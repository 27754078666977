import React, {Component} from "react";
import {Modal} from "../../../../components";
import UpdateGeneralStaffPreferences from "./UpdateGeneralStaffPreferences";
import Table from "react-bootstrap/Table";
import DateUtil from "../../../../utils/DateUtil";
import {Dropdown} from "primereact/dropdown";
import {InputNumber} from "primereact/inputnumber";
import EnumBreakTimeAM from "../../enums/EnumBreakTimeAM";
import EnumBreakTimePM from "../../enums/EnumBreakTimePM";
import RemotingService from "../../../../services/remoting-service/RemotingService";
import "./UpdateStaffPreference.css"
import {extract, isEmpty} from "../../../../utils/ArrayUtil";
import {FormField} from "../../../../components/form";
import ValidationUtil from "../../../../components/form/validator/ValidationUtil";
import TimeInput from "../../../../components/date-input/TimeInput";
import AuthService from "../../../../services/auth-service/AuthService";
import {cloneDeep} from "lodash-es";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SchedulingService from "../../../../services/scheduling-service/SchedulingService";
import {Button} from "react-bootstrap";
import dialog from "../../../../components/dialog/Dialog";
import IntraDaySelectionModal from "../modal/IntraDayDaySelectionModal";

export default class UpdateStaffPreference extends Component {

    constructor(props) {
        super(props);
        this.state = {
            staffPreference: {
                allowedRooms: []
            }
        }
    }

    componentDidMount() {
        RemotingService.getRemoteCall(`api/staff/${this.props.staffId}/preference`, null, staffPreference => {
            staffPreference = staffPreference || {};
            staffPreference.dayPreferences = isEmpty(staffPreference.dayPreferences) ? this.createDummyDayPreferences() : staffPreference.dayPreferences;
            staffPreference.preferredRoomIds = extract(staffPreference.preferredRooms, "id");
            this.setState({
                staffPreference: staffPreference,
                sundayPreference: staffPreference.dayPreferences.find(item => item.dayOfWeek === "SUNDAY"),
                mondayPreference: staffPreference.dayPreferences.find(item => item.dayOfWeek === "MONDAY"),
                tuesdayPreference: staffPreference.dayPreferences.find(item => item.dayOfWeek === "TUESDAY"),
                wednesdayPreference: staffPreference.dayPreferences.find(item => item.dayOfWeek === "WEDNESDAY"),
                thursdayPreference: staffPreference.dayPreferences.find(item => item.dayOfWeek === "THURSDAY"),
                fridayPreference: staffPreference.dayPreferences.find(item => item.dayOfWeek === "FRIDAY") ??
                        {...cloneDeep(staffPreference.dayPreferences.find(item => item.dayOfWeek === "MONDAY")), dayOfWeek: "FRIDAY"},
                saturdayPreference: staffPreference.dayPreferences.find(item => item.dayOfWeek === "SATURDAY")
            }, () => ValidationUtil.clear(this.formFields));
        });
    }

    createDummyDayPreferences() {
        return [
            {dayOfWeek: "SUNDAY"},
            {dayOfWeek: "MONDAY"},
            {dayOfWeek: "TUESDAY"},
            {dayOfWeek: "WEDNESDAY"},
            {dayOfWeek: "THURSDAY"},
            {dayOfWeek: "FRIDAY"},
            {dayOfWeek: "SATURDAY"}
        ];
    }

    renderSaveAndUpdateButton = () => {
        return (
            <Button variant="success" onClick={() => this.saveAndUpdateIntraDaySchedule()}>Save and update
                schedule</Button>
        );
    }

    savePreferences() {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        let staffPreference = {
            ...this.state.staffPreference,
            dayPreferences: [
                {...this.state.sundayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.sundayPreference)},
                {...this.state.mondayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.mondayPreference)},
                {...this.state.tuesdayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.tuesdayPreference)},
                {...this.state.wednesdayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.wednesdayPreference)},
                {...this.state.thursdayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.thursdayPreference)},
                {...this.state.fridayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.fridayPreference)},
                {...this.state.saturdayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.saturdayPreference)}
            ]
        };

        RemotingService.postRemoteCall(`api/staff/${this.props.staffId}/preference`, staffPreference, staffPreference => {
            this.props.closeAction(staffPreference);
            this.props.onStaffProfileUpdate();
        });
    }

    saveAndUpdateIntraDaySchedule() {
        if (!ValidationUtil.checkWithNotification(this.formFields)) {
            return;
        }

        RemotingService.getRemoteCall('api/appointment/last-appointment-of-provider/' + this.props.staffId, {},
            (lastAppointmentDate) => {
                dialog({
                    title: "Daily Schedule Change Date",
                    component: <IntraDaySelectionModal
                        startDate={lastAppointmentDate ? DateUtil.addDays(lastAppointmentDate, 1) : new Date()}
                        onSelect={
                            (selectedDate) => {
                                let staffPreference = {
                                    ...this.state.staffPreference,
                                    dayPreferences: [
                                        {...this.state.sundayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.sundayPreference)},
                                        {...this.state.mondayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.mondayPreference)},
                                        {...this.state.tuesdayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.tuesdayPreference)},
                                        {...this.state.wednesdayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.wednesdayPreference)},
                                        {...this.state.thursdayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.thursdayPreference)},
                                        {...this.state.fridayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.fridayPreference)},
                                    {...this.state.saturdayPreference, ...SchedulingService.calculateSessionDurations(this.state.staffPreference, this.state.saturdayPreference)}
                                ]
                            };

                            RemotingService.postRemoteCall(`api/staff/${this.props.staffId}/preference`, staffPreference, staffPreference => {
                                this.props.intraDayAction(selectedDate);
                                this.props.closeAction(staffPreference);
                                this.props.onStaffProfileUpdate();
                            });
                        }
                    }/>
                });
            });
    }

    copySundayToEveryDay() {
        this.setState({
            mondayPreference: this.copyDayPreference(this.state.sundayPreference, this.state.mondayPreference),
            tuesdayPreference: this.copyDayPreference(this.state.sundayPreference, this.state.tuesdayPreference),
            wednesdayPreference: this.copyDayPreference(this.state.sundayPreference, this.state.wednesdayPreference),
            thursdayPreference: this.copyDayPreference(this.state.sundayPreference, this.state.thursdayPreference),
            fridayPreference: this.copyDayPreference(this.state.sundayPreference, this.state.fridayPreference),
            saturdayPreference: this.copyDayPreference(this.state.sundayPreference, this.state.saturdayPreference),
        });
    }

    copyDayPreference(sourcePref, targetPref) {
        return {
            ...targetPref,
            startTime: targetPref.allowedStartTimes
                        && targetPref.allowedStartTimes.includes(sourcePref.startTime) ? sourcePref.startTime : null,
            lunchBreak: cloneDeep(sourcePref.lunchBreak),
            breakTimeAM: sourcePref.breakTimeAM,
            breakTimePM: sourcePref.breakTimePM,
            noOfPatientPerDay: sourcePref.noOfPatientPerDay,
            percentageOfExtraTimeForPatient: sourcePref.percentageOfExtraTimeForPatient,
            percentageOfManagementTime: sourcePref.percentageOfManagementTime,
            percentageOfNewPatientPerDay: sourcePref.percentageOfNewPatientPerDay,
        }
    }

    render() {
        this.formFields = [];
        return <Modal visible={true}
                      header="Preference"
                      fullWidth
                      maxWidth={"xl"}
                      submitContent="Update"
                      submitAction={() => this.savePreferences()}
                      additionalActions={this.renderSaveAndUpdateButton()}
                      closeAction={() => this.props.closeAction()}>
            <div className={"container-fluid"}>
                <UpdateGeneralStaffPreferences ref={formField => this.formFields.push(formField)}
                                               staffPreference={this.state.staffPreference}
                                               onChange={(pref) => {this.state.staffPreference = {...this.state.staffPreference, ...pref}}}/>
            </div>
            <div className="container-fluid">

                <Table id="dayPrefUpdateTable">
                    <thead>
                    <tr>
                        <th rowSpan={2} width={90}></th>
                        <th rowSpan={2} width={60}>Start Time</th>
                        <th colSpan={4}>Lunch Time</th>
                        <th colSpan={2}>Break Time</th>
                        <th rowSpan={2} width={60}>Number of Patient Per Day</th>
                        <th colSpan={2}>New Patient Per Day</th>
                        <th colSpan={2}>Duration Of Session</th>
                    </tr>
                    <tr>
                        <th width={60}>Time</th>
                        <th width={60}>Duration</th>
                        <th width={60}>Time</th>
                        <th width={60}>Duration</th>
                        <th width={60}>AM</th>
                        <th width={60}>PM</th>
                        <th width={60}>%</th>
                        <th width={60}>% of Extra Time</th>
                        <th width={60}>New Patient</th>
                        <th width={60}>Follow Up</th>
                    </tr>
                    </thead>
                    <tbody>
                        <StaffDayPreferenceRow name="Sunday" staffPreference={this.state.staffPreference}
                                               ref={formField => this.formFields.push(formField)}
                                               dayPreference={this.state.sundayPreference}
                                               onChange={(pref) => {this.state.sundayPreference = pref}}/>
                        <StaffDayPreferenceRow name="Monday" staffPreference={this.state.staffPreference}
                                               ref={formField => this.formFields.push(formField)}
                                               dayPreference={this.state.mondayPreference}
                                               onChange={(pref) => {this.state.mondayPreference = pref}}/>
                        <StaffDayPreferenceRow name="Tuesday" staffPreference={this.state.staffPreference}
                                               ref={formField => this.formFields.push(formField)}
                                               dayPreference={this.state.tuesdayPreference}
                                               onChange={(pref) => {this.state.tuesdayPreference = pref}}/>
                        <StaffDayPreferenceRow name="Wednesday" staffPreference={this.state.staffPreference}
                                               ref={formField => this.formFields.push(formField)}
                                               dayPreference={this.state.wednesdayPreference}
                                               onChange={(pref) => {this.state.wednesdayPreference = pref}}/>
                        <StaffDayPreferenceRow name="Thursday" staffPreference={this.state.staffPreference}
                                               ref={formField => this.formFields.push(formField)}
                                               dayPreference={this.state.thursdayPreference}
                                               onChange={(pref) => {this.state.thursdayPreference = pref}}/>

                        <StaffDayPreferenceRow name="Friday" staffPreference={this.state.staffPreference}
                                               ref={formField => this.formFields.push(formField)}
                                               dayPreference={this.state.fridayPreference}
                                               onChange={(pref) => {
                                                   this.state.fridayPreference = pref
                                               }}/>
                        <StaffDayPreferenceRow name="Saturday" staffPreference={this.state.staffPreference}
                                               ref={formField => this.formFields.push(formField)}
                                               dayPreference={this.state.saturdayPreference}
                                               onChange={(pref) => {this.state.saturdayPreference = pref}}/>
                    </tbody>
                </Table>
                <div onClick={() => this.copySundayToEveryDay()} style={{cursor: 'pointer', width: 'fit-content'}}>
                    <FontAwesomeIcon icon={["fas", "copy"]} />
                    <a> Copy sunday's preferences to everyday</a>
                </div>
            </div>
        </Modal>
    }

}

class StaffDayPreferenceRow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dayPreference: props.dayPreference ?? {lunchBreak: {}},
            lunch1DateTime: props.dayPreference ? DateUtil.parseTime(props.dayPreference.lunchBreak?.lunch1Time) : null,
            lunch2DateTime: props.dayPreference ? DateUtil.parseTime(props.dayPreference.lunchBreak?.lunch2Time) : null,
            lunchDurations: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120],
            maxLunchDuration: this.getMaxLunchDuration()
        }
        this.state.dayPreference.lunchBreak = this.state.dayPreference.lunchBreak ?? {};
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.dayPreference !== this.props.dayPreference) {
            this.props.dayPreference.lunchBreak = this.props.dayPreference.lunchBreak || {};
            this.removeInvalidPreferences(this.props.dayPreference);
            const newState = {
                dayPreference: this.props.dayPreference,
                lunch1DateTime: this.props.dayPreference ? DateUtil.parseTime(this.props.dayPreference.lunchBreak?.lunch1Time) : null,
                lunch2DateTime: this.props.dayPreference ? DateUtil.parseTime(this.props.dayPreference.lunchBreak?.lunch2Time) : null,
            };
            newState.dayPreference.lunchBreak = newState.dayPreference.lunchBreak ?? {};
            this.setState(newState);
        }
    }

    removeInvalidPreferences(dayPreference) {
        if (dayPreference && !dayPreference.allowedStartTimes?.includes(dayPreference.startTime)) {
            dayPreference.startTime = null;
        }
        if (dayPreference && !dayPreference.allowedNoOfPatientPerDays?.includes(dayPreference.noOfPatientPerDay)) {
            dayPreference.noOfPatientPerDay = null;
        }
    }

    handleFieldChange = (field, value) => {
        this.state.dayPreference[field] = value;
        this.setState({dayPreference: this.state.dayPreference}, () => {
            this.props.onChange(this.state.dayPreference);
        });
    }

    handleLunch1TimeChange = (value) => {
        this.state.lunch1DateTime = value;
        this.state.dayPreference.lunchBreak.lunch1Time = DateUtil.formatDate(value, "HH:mm");
        this.setState({...this.state}, () => {
            this.props.onChange(this.state.dayPreference);
        });
    }

    handleLunch2TimeChange = (value) => {
        this.state.lunch2DateTime = value;
        if (value === null || value === undefined) {
            this.state.dayPreference.lunchBreak.lunch2Duration = null;
        }
        this.state.dayPreference.lunchBreak.lunch2Time = DateUtil.formatDate(value, "HH:mm");
        this.setState({...this.state}, () => {
            this.props.onChange(this.state.dayPreference);
        });
    }

    handleLunch1DurationChanged = (event) => {
        this.setState(prevState => {
            prevState.dayPreference.lunchBreak.lunch1Duration = event.value;
            return {...prevState}
        }, () => {
            this.props.onChange(this.state.dayPreference);
        });
    };

    handleLunch2DurationChanged = (event) => {
        this.setState(prevState => {
            if (event.value === null || event.value === undefined) {
                prevState.lunch2DateTime = null;
                prevState.dayPreference.lunchBreak.lunch2Time = null;
            }
            prevState.dayPreference.lunchBreak.lunch2Duration = event.value;
            return {...prevState}
        }, () => {
            this.props.onChange(this.state.dayPreference);
        });
    };

    getMaxLunchDuration = () => {
        const uaeClinic = AuthService.getUser().clinics.find(clinic => clinic.country === "UAE");
        return uaeClinic ? 120 : 60;
    }

    lunchDurationValidationValue = () => {
        let duration1 = this.state.dayPreference.lunchBreak?.lunch1Duration ?? 0;
        let duration2 = this.state.dayPreference.lunchBreak?.lunch2Duration ?? 0;
        return duration1 + duration2;
    }
    
    validate() {
        return ValidationUtil.isValid(this.formFields, false);
    }

    clear() {
        ValidationUtil.clear(this.formFields);
    }

    render() {
        const {newPatient, followUp} = SchedulingService.calculateSessionDurations(this.props.staffPreference, this.state.dayPreference);
        this.formFields = [];
        return <tr>
            <td style={{fontWeight: 'bold', textAlign:'left', paddingRight:4}}>{this.props.name}</td>
            <td style={{textAlign: 'left',}}>
                <FormField ref={formField => this.formFields.push(formField)}
                           required
                           validateOn={this.state.dayPreference.startTime}>
                    <Dropdown options={this.state.dayPreference.allowedStartTimes?.sort()}
                              value={this.state.dayPreference.startTime}
                              onChange={event => this.handleFieldChange("startTime", event.value)}/>
                </FormField>
            </td>
            <td>
                <FormField ref={formField => this.formFields.push(formField)}
                           required
                           validateOn={this.state.lunch1DateTime}>
                    <TimeInput panelStyle={{width: 'max-content'}}
                               stepMinute={5}
                               viewDate={DateUtil.timeOf(12, 0)}
                               readOnlyInput={true}
                               value={this.state.lunch1DateTime}
                               onChange={event => this.handleLunch1TimeChange(event.value)}/>
                </FormField>
            </td>
            <td>
                <FormField ref={formField => this.formFields.push(formField)}
                           required max={this.state.maxLunchDuration} maxMessage={`Total of lunch breaks must be less than or equal to ${this.state.maxLunchDuration}`}
                           validateOn={this.lunchDurationValidationValue()}>
                    <Dropdown options={this.state.lunchDurations}
                              value={this.state.dayPreference.lunchBreak.lunch1Duration}
                              onChange={this.handleLunch1DurationChanged}/>
                </FormField>
            </td>
            <td>
                <FormField ref={formField => this.formFields.push(formField)}
                           minTime={DateUtil.addMinutes(this.state.lunch1DateTime, this.state.dayPreference.lunchBreak.lunch1Duration)}
                           validateOn={this.state.lunch2DateTime}>
                    <TimeInput panelStyle={{width: 'max-content'}}
                               clearable
                               stepMinute={5}
                               viewDate={DateUtil.timeOf(12, 0)}
                               value={this.state.lunch2DateTime}
                               onChange={event => this.handleLunch2TimeChange(event.value)}/>
                </FormField>
            </td>
            <td>
                <FormField ref={formField => this.formFields.push(formField)}
                           required max={this.state.maxLunchDuration} maxMessage={`Total of lunch breaks must be less than or equal to ${this.state.maxLunchDuration}`}
                           validateOn={this.lunchDurationValidationValue()}>
                    <Dropdown showClear={true}
                              options={this.state.lunchDurations}
                              value={this.state.dayPreference.lunchBreak.lunch2Duration}F
                              onChange={this.handleLunch2DurationChanged}/>
                </FormField>
            </td>
            <td>
                <FormField ref={formField => this.formFields.push(formField)}
                           required
                           validateOn={this.state.dayPreference.breakTimeAM}>
                    <Dropdown style={{width: 100}} panelStyle={{width: 'max-content'}}
                              options={EnumBreakTimeAM.toArray()}
                              optionValue="key"
                              optionLabel="name"
                              value={this.state.dayPreference.breakTimeAM}
                              onChange={event => this.handleFieldChange("breakTimeAM", event.value)}/>
                </FormField>
            </td>
            <td>
                <FormField ref={formField => this.formFields.push(formField)}
                           required
                           validateOn={this.state.dayPreference.breakTimePM}>
                    <Dropdown style={{width: 100}} panelStyle={{width: 'max-content'}}
                              options={EnumBreakTimePM.toArray()}
                              optionValue="key"
                              optionLabel="name"
                              value={this.state.dayPreference.breakTimePM}
                              onChange={event => this.handleFieldChange("breakTimePM", event.value)}/>
                </FormField>
            </td>
            <td>
                <FormField ref={formField => this.formFields.push(formField)}
                           required
                           validateOn={this.state.dayPreference.noOfPatientPerDay}>
                    <Dropdown options={this.state.dayPreference.allowedNoOfPatientPerDays}
                              value={this.state.dayPreference.noOfPatientPerDay}
                              onChange={event => this.handleFieldChange("noOfPatientPerDay", event.value)}/>
                </FormField>
            </td>
            <td>
                <FormField ref={formField => this.formFields.push(formField)}
                           required
                           min={this.state.dayPreference.minPercentageOfNewPatientPerDay}
                           max={this.state.dayPreference.maxPercentageOfNewPatientPerDay}
                           validateOn={this.state.dayPreference.percentageOfNewPatientPerDay}>
                    <InputNumber prefix="%"
                                 value={this.state.dayPreference.percentageOfNewPatientPerDay}
                                 onChange={event => this.handleFieldChange("percentageOfNewPatientPerDay", event.value)}/>
                </FormField>
            </td>
            <td>
                <FormField ref={formField => this.formFields.push(formField)}
                           required
                           min={this.state.dayPreference.minPercentageOfExtraTimeForPatient}
                           max={this.state.dayPreference.maxPercentageOfExtraTimeForPatient}
                           validateOn={this.state.dayPreference.percentageOfExtraTimeForPatient}>
                    <InputNumber prefix="%"
                                 value={this.state.dayPreference.percentageOfExtraTimeForPatient}
                                 onChange={event => this.handleFieldChange("percentageOfExtraTimeForPatient", event.value)}/>
                </FormField>
            </td>
            <td>{newPatient}</td>
            <td>{followUp}</td>
        </tr>
    }
}
