import React, {Component} from "react";
import {PatientInfo} from "../../home/datatable/PatientInfo";
import MissingPointGraph from "./MissingPointGraph";
import AllergyInfo from "./AllergyInfo";
import './Header.css'
import DateUtil from "../../../../utils/DateUtil";
import EnumAppointmentType from "../../../appointment/enums/EnumAppointmentType";
import OccupationEnum from "../subjective/enums/lifestyle/OccupationEnum";
import RiskLevelEnum from "../assessment/enums/morseFallRisk/RiskLevelEnum";
import RemotingService from "../../../../services/remoting-service/RemotingService";


export default class Header extends Component {

    state = {
        visit: null,
        occupation: null,
        lastAllergy: null,
        diagnosis: null,
        diagnosisCode: null,
        fallRiskClass: null,
        fallRisk: null,
        goal: null,
    }

    componentDidUpdate(oldProps) {
        const newProps = this.props;
        if (oldProps.appointment !== newProps.appointment) {
            const appointmentId = newProps.appointment?.id;

            if (appointmentId != null) {
                this.getAppointmentInfo(appointmentId);
            }
        }
    }

    componentDidMount() {
        const appointmentId = this.props.appointment?.id;
        if (appointmentId != null) {
            this.getAppointmentInfo(appointmentId);
        }
    }

    render() {
        const visit = this.state.visit;

        const appointmentType = visit ? visit.appointmentType : this.props.appointment?.patientType;
        const patientType = EnumAppointmentType[appointmentType]?.name;
        const patientTypeColor = EnumAppointmentType[appointmentType]?.bgColor;
        const missingPoints = visit?.missingPoints;
        const totalPoints = visit?.totalPoints;
        const updatedByStaffType = visit?.updatedByStaffType;

        return (
            <div className={this.props.emrHomeView ? "container-fluid mx-0" : ""}>
                <div className="row justify-content-between">
                    <>
                        <div className={this.props.emrHomeView ? "col-6" : "col-4"}>
                            <PatientInfo patient={this.props.patient} occupation={this.state.occupation} specialStatusList={this.props.patient.specialStatusList}/>
                        </div>
                        <div className={"col-2"}>
                            <AllergyInfo patient={this.props.patient} allergy={this.state.lastAllergy}/>
                        </div>
                    </>
                    {
                        visit &&
                        [
                            <div className={"col-1"}>
                                <ul className={"list-unstyled m-0"}>
                                    <li>
                                        <label
                                            className={"font-weight-bold mb-0 mr-1 d-inline-block align-middle mb-1"}>Diagnosis: </label>
                                        <span className={"d-inline-block align-middle"}
                                              style={{maxWidth: "150px"}}>{this.state.diagnosis} {this.state.diagnosisCode}</span>
                                    </li>
                                    <li>
                                        <label className={"disc-danger text-nowrap font-weight-bold m-0"}>Fall Risk
                                            Status</label><span
                                        className={"d-block " + this.state.fallRiskClass}>{this.state.fallRisk}</span>
                                    </li>
                                </ul>
                            </div>,
                            <div className={"col-1 flex-column d-flex justify-content-between"}>
                                <span><label className={"font-weight-bold"}>Patient Goal:</label> <span>{this.state.goal}</span></span>
                            </div>
                        ]
                    }
                    {!this.props.emrHomeView && <div className={"col-auto d-flex flex-row"}>
                        <ul className={"list-unstyled text-right"}>
                            <li><label className={"font-weight-bold disc-grey"}>Current Visit Date & Time</label></li>
                            <li><span
                                className={"text-right font-weight-bold mb-0"}>{DateUtil.formatDateMMM(this.props.appointment.start)} / {DateUtil.getHourMinute12H(this.props.appointment.start)}</span>
                            </li>
                            <li> {this.props.appointment.room.clinicName} / {this.props.appointment.room.name}</li>
                            <li><span style={{
                                fontSize: "1rem",
                                color: `${patientTypeColor}`,
                                fontWeight: "bold"
                            }}>{patientType}</span></li>
                        </ul>
                        <MissingPointGraph missingPoints={missingPoints} totalPoints={totalPoints} updatedByStaffType={updatedByStaffType}/>
                    </div>}
                </div>
            </div>
        );
    }

    setFallRisk(fallRisk){
        this.fallRisk = "-";
        this.fallRiskClass = "";

        if (fallRisk?.riskLevel != null) {
            this.fallRisk = RiskLevelEnum[fallRisk.riskLevel].name;
            this.fallRiskClass = RiskLevelEnum[fallRisk.riskLevel].headerClassName;
        }
    }

    getDiagnosisGroupTitle(diagnosisGroup) {
        if (diagnosisGroup == null || diagnosisGroup.diagnosisItems == null || diagnosisGroup.diagnosisItems.length === 0) {
            return "-";
        }
        let principal = diagnosisGroup.diagnosisItems.find(i => i.level == "PRINCIPAL");
        return principal == null ? "-" : principal.description;
    }

    getDiagnosisCode(diagnosisGroup){
        if (diagnosisGroup == null || diagnosisGroup.diagnosisItems == null || diagnosisGroup.diagnosisItems.length == 0) {
            return "";
        }
        let principal = diagnosisGroup.diagnosisItems.find(i => i.level == "PRINCIPAL");
        return principal == null ? "" : principal.code;
    }

    getAppointmentInfo(appointmentId) {
        let visit = this.props.visit || this.props.appointment.visit;
        if (visit) {
            this.parseVisit(visit)
        } else {
            RemotingService.getRemoteCall(`api/emr/${appointmentId}/appointment-row-info`, null, (visit) => {
                this.parseVisit(visit)
            });
        }
    }

    parseVisit(visit) {
        this.setState(state => {
            if (visit.subjective) {
                if (visit.subjective.allergy) {
                    state.lastAllergy = visit.subjective.allergy;
                }
                if (visit.subjective.lifestyle) {
                    state.occupation = visit.subjective.lifestyle.occupation != null ?
                        OccupationEnum[visit.subjective.lifestyle.occupation].name : "-";
                }
            }
            if (visit.assessment) {
                if(visit.assessment.fallRisk) {
                    state.fallRisk = "-";
                    state.fallRiskClass = "";

                    const fallRisk = visit.assessment.fallRisk;
                    if (fallRisk.riskLevel != null) {
                        state.fallRisk = RiskLevelEnum[fallRisk.riskLevel].name;
                        state.fallRiskClass = RiskLevelEnum[fallRisk.riskLevel].headerClassName;
                    } else {
                        this.setFallRisk(null);
                    }
                }
            }
            if (visit.treatment) {
                if (visit.treatment.goal) {
                    state.goal = visit.treatment.goal.details;
                } else {
                    this.goal = visit.treatment.goal;
                }
            }
            if (visit.diagnosis) {
                state.diagnosis = this.getDiagnosisGroupTitle(visit.diagnosis.diagnosisGroups[0])
                state.diagnosisCode = this.getDiagnosisCode(visit.diagnosis.diagnosisGroups[0])
            }
            state.visit = visit;
            return state;
        });
    }
}
