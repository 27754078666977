import {combineReducers} from 'redux';

import {base} from './BaseReducer';
import {authentication} from './AuthenticationReducer';
import {alert} from './AlertReducer';

const rootReducer = combineReducers({
    base,
    authentication,
    alert
});

export default rootReducer;