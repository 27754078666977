import {GenericChecker} from "../../subjective/GenericChecker";
import * as ArrayUtils from "../../../../../utils/ArrayUtil";

export class TreatmentScheduleChecker extends GenericChecker {
    static isDataEntered(entity) {
        if (entity == null) {
            return false;
        }
        return !ArrayUtils.isEmpty(entity.numberOfReassessedSessions)
            || !ArrayUtils.isEmpty(entity.reassessedSessionsDate);
    }
}